import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import AssociatedProjects from './AssociatedProjects';
import LoanProjectsList from '../LoanProjects/LoanProjectsList';
import ProjectsList from '../Projects/ProjectsList';

/**
 * Projects Component
 * @class Projects
 * @extends {Component}
 */
class Projects extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, userId, isReadOly, tableActionRequired, orgAccess } = this.props;
        return (
            <Switch >
                {
                    orgAccess && orgAccess === 1 &&
                    <Route path={match.url} render={props => {
                        return (
                            <ProjectsList
                                {...props}
                                title=""
                                userId={userId}
                                isReadOly={isReadOly}
                                page='users'
                                isSubPage={true}
                                removeActions={['ADD', 'UPLOAD', 'MERGE', 'TABLE1_IMPORT', 'UPLOAD_BULK_UTILITYALLOWANCE']}
                                tableActionRequired={tableActionRequired}
                            />
                        )
                    }} />
                }
                {
                    orgAccess && orgAccess === 2 &&
                    <Route path={`${match.url}`} render={props => {
                        return (
                            <LoanProjectsList
                                {...props}
                                // pageAccess={pageAccess}
                                title=""
                                isReadOly={isReadOly}
                                userId={userId}
                                page='users'
                                removeActions={['COPY','DELETE','ADD', 'UPLOAD', 'MERGE', 'TABLE1_IMPORT', 'UPLOAD_BULK_UTILITYALLOWANCE']}
                                isSubPage={true}
                            />
                        )
                    }} />
                }
                {
                    orgAccess && orgAccess === 3 &&
                    <Route path={match.url} render={props => {
                        return (
                            <AssociatedProjects
                                title=""
                                {...props}
                                userId={userId}
                                isReadOly={isReadOly}
                                tableActionRequired={tableActionRequired} />
                        )
                    }} />
                }                
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default Projects;