export const loanProjectsConstants = {

    // project listing
    CHECK_LOAN_PROJECTS_FUNDER_REQUEST: 'CHECK_LOAN_PROJECTS_FUNDER_REQUEST',
    CHECK_LOAN_PROJECTS_FUNDER_SUCCESS: 'CHECK_LOAN_PROJECTS_FUNDER_SUCCESS',
    CHECK_LOAN_PROJECTS_FUNDER_FAILURE: 'CHECK_LOAN_PROJECTS_FUNDER_FAILURE',

    // project listing
    PAGE_GETALL_LOAN_PROJECTS_REQUEST: 'page/loanProjects/request',
    PAGE_GETALL_LOAN_PROJECTS_SUCCESS: 'page/loanProjects/success',
    PAGE_GETALL_LOAN_PROJECTS_FAILURE: 'page/loanProjects/failure',

    // create new project
    CREATE_LOAN_PROJECT_REQUEST: 'page/loanProjects/create/request',
    CREATE_LOAN_PROJECT_SUCCESS: 'page/loanProjects/create/success',
    CREATE_LOAN_PROJECT_FAILURE: 'page/loanProjects/create/failure',

    // report activity listing
    GETALL_REPORT_ACTIVITY_REQUEST: 'GETALL_REPORT_ACTIVITY_REQUEST',
    GETALL_REPORT_ACTIVITY_SUCCESS: 'GETALL_REPORT_ACTIVITY_SUCCESS',
    GETALL_REPORT_ACTIVITY_FAILURE: 'GETALL_REPORT_ACTIVITY_FAILURE',

    // update funder settings
    UPDATE_LOAN_FUNDER_SETTINGS_REQUEST: 'UPDATE_PAGE_LOAN_FUNDER_SETTINGS_REQUEST',
    UPDATE_LOAN_FUNDER_SETTINGS_SUCCESS: 'UPDATE_PAGE_LOAN_FUNDER_SETTINGS_SUCCESS',
    UPDATE_LOAN_FUNDER_SETTINGS_FAILURE: 'UPDATE_PAGE_LOAN_FUNDER_SETTINGS_FAILURE',

    // create new funder settings
    CREATE_LOAN_FUNDER_SETTINGS_REQUEST: 'CREATE_PAGE_LOAN_FUNDER_SETTINGS_REQUEST',
    CREATE_LOAN_FUNDER_SETTINGS_SUCCESS: 'CREATE_PAGE_LOAN_FUNDER_SETTINGS_SUCCESS',
    CREATE_LOAN_FUNDER_SETTINGS_FAILURE: 'CREATE_PAGE_LOAN_FUNDER_SETTINGS_FAILURE',

    //is project name already exists
    IS_LOAN_PROJECT_NAME_EXISTS_REQUEST: 'IS_LOAN_PROJECT_NAME_EXISTS_REQUEST',
    IS_LOAN_PROJECT_NAME_EXISTS_SUCCESS: 'IS_LOAN_PROJECT_NAME_EXISTS_SUCCESS',
    IS_LOAN_PROJECT_NAME_EXISTS_FAILURE: 'IS_LOAN_PROJECT_NAME_EXISTS_FAILURE',

    //get one loan project
    GET_ONE_LPJT_REQUEST: 'GET_ONE_LPJT_REQUEST',
    GET_ONE_LPJT_SUCCESS: 'GET_ONE_LPJT_SUCCESS',
    GET_ONE_LPJT_FAILURE: 'GET_ONE_LPJT_FAILURE',

    //update loan project
    UPDATE_LOAN_PROJECT_REQUEST: 'UPDATE_LOAN_PROJECT_REQUEST',
    UPDATE_LOAN_PROJECT_SUCCESS: 'UPDATE_LOAN_PROJECT_SUCCESS',
    UPDATE_LOAN_PROJECT_FAILURE: 'UPDATE_LOAN_PROJECT_FAILURE',

    // get organization details
    GET_ALL_ORGS_REQUEST: 'PROJECTS_GET_ALL_ORGS_REQUEST',
    GET_ALL_ORGS_SUCCESS: 'PROJECTS_GET_ALL_ORGS_SUCCESS',
    GET_ALL_ORGS_FAILURE: 'PROJECTS_GET_ALL_ORGS_FAILURE',

    // organization users in project
    GET_ORG_USERS_REQUEST: 'PROJECTS_GET_ORG_USERS_REQUEST',
    GET_ORG_USERS_SUCCESS: 'PROJECTS_GET_ORG_USERS_SUCCESS',
    GET_ORG_USERS_FAILURE: 'PROJECTS_GET_ORG_USERS_FAILURE',

    //delete loan project
    DELETE_LOAN_PROJECT_REQUEST: 'DELETE_LOAN_PROJECT_REQUEST',
    DELETE_LOAN_PROJECT_SUCCESS: 'DELETE_LOAN_PROJECT_SUCCESS',
    DELETE_LOAN_PROJECT_FAILURE: 'DELETE_LOAN_PROJECT_FAILURE',

    //funder setting listing
    GET_ALL_LOAN_FUNDERS_REQUEST: 'GET_ALL_LOAN_FUNDERS_REQUEST',
    GET_ALL_LOAN_FUNDERS_SUCCESS: 'GET_ALL_LOAN_FUNDERS_SUCCESS',
    GET_ALL_LOAN_FUNDERS_FAILURE: 'GET_ALL_LOAN_FUNDERS_FAILURE',

    // funder settings configuration
    GET_LOAN_FUNDER_CONFIG_SETTINGS_REQUEST: 'GET_LOAN_PAGE_FUNDER_CONFIG_SETTINGS_REQUEST',
    GET_LOAN_FUNDER_CONFIG_SETTINGS_SUCCESS: 'GET_LOAN_PAGE_FUNDER_CONFIG_SETTINGS_SUCCESS',
    GET_LOAN_FUNDER_CONFIG_SETTINGS_FAILURE: 'GET_LOAN_PAGE_FUNDER_CONFIG_SETTINGS_FAILURE',

    // funder settings data
    GET_LOAN_FUNDER_BASIC_SETTINGS_REQUEST: 'GET_LOAN_PAGE_FUNDER_BASIC_SETTINGS_REQUEST',
    GET_LOAN_FUNDER_BASIC_SETTINGS_SUCCESS: 'GET_LOAN_PAGE_FUNDER_BASIC_SETTINGS_SUCCESS',
    GET_LOAN_FUNDER_BASIC_SETTINGS_FAILURE: 'GET_LOAN_PAGE_FUNDER_BASIC_SETTINGS_SUCCESS',
}
