import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Route, Switch } from 'react-router-dom';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';

// Tab Components
import CertificationForm from './tabs/CertificationForm';
import FinancialActivity from './tabs/FinancialActivity';
import ContractActivity from './tabs/ContractActivity';
import ProgramActivity from './tabs/ProgramActivity';

// import child router components
// import OccupantsRouter from "../../containers/Occupants/Occupants.Router";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { history } from '../../helpers';
import { reportActivityActions } from '../../actions/reportActivity';
import AlertDialog from '../../components/AlertDialog/AlertDialog';


class ReportsActivityView extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            programActivity: true,
            financialActivity: true,
            contractActivity: true,
            certificationFormID: null,
            openFormNotSavedAlert: false,
            tabValue: null
        }
        this.certificationActivityRef = React.createRef();
        this.programActivityRef = React.createRef();
        this.financeActivityRef = React.createRef();
        this.contractActivityRef = React.createRef();
    }

    /** 
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { match } = this.props;
        const reportYear_id = match.params.reportYearId ? match.params.reportYearId : null;
        if (reportYear_id)
            this.props.validationIssue(reportYear_id);
    }

    /**
     * Component Did Mount Event
     */
    componentDidMount() {

    }
    /**
    * Handle Tab Change Event
    */
    handleTabChange = (event, value) => {
        const { location: { state } } = this.props;
        let isFormNotSaved = false;
        if (state && state.page && state.page === "programActivity") {
            isFormNotSaved = this.programActivityRef.current.handleTabChange();
        } else if (state && state.page && state.page === "financialActivity") {
            isFormNotSaved = this.financeActivityRef.current.handleTabChange();
        } else if (state && state.page && (state.page === "contractActivityView" || state.page === "contractActivityNew")) {
            isFormNotSaved = this.contractActivityRef.current.handleTabChange();
        } else if (state && state.page && state.page === "certificationForm") {
            isFormNotSaved = this.certificationActivityRef.current.handleTabChange();
        }
        if (!isFormNotSaved) {
            this.handleTabNavigation(value)
        } else {
            this.setState({ openFormNotSavedAlert: true, tabValue: value })
        }
        return isFormNotSaved
    };
    /**
     * Handle tab navigation
     * @param {*} value 
     */
    handleTabNavigation(value) {
        const { match, pageData, pageTitle } = this.props;
        switch (value) {
            case 1:
                history.push({
                    pathname: `${match.url}`,
                    state: {
                        page: 'certificationForm',
                        reportYear_id: match.params.reportYearId,
                        pageData: {
                            // project_id: projectId,
                            reportYear_id: match.params.reportYearId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 2:
                history.push({
                    pathname: `${match.url}/programActivity`,
                    state: {
                        page: 'programActivity',
                        reportYear_id: match.params.reportYearId,
                        pageData: {
                            // project_id: projectId,
                            reportYear_id: match.params.reportYearId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 3:
                history.push({
                    pathname: `${match.url}/financialActivity`,
                    state: {
                        page: 'financialActivity',
                        reportYear_id: match.params.reportYearId,
                        pageData: {
                            // project_id: projectId,
                            reportYear_id: match.params.reportYearId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 4:
                history.push({
                    pathname: `${match.url}/contractActivity`,
                    state: {
                        page: 'contractActivityList',
                        reportYear_id: match.params.reportYearId,
                        pageData: {
                            // project_id: projectId,
                            reportYear_id: match.params.reportYearId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 5:
                //navigate to general tab if 5
                let path = history.location.pathname.split('/reports')[0]
                history.push(path)
                break;
            default:
                history.push(`${match.url}/reports`);
                break;
        }
        this.setState({ openFormNotSavedAlert: false })
    }
    /**
     * 
     * @param {*} pathname 
     */
    getTabIndex(pathname) {
        // const tabs = ["", "programActivity", "financialActivity", "contractActivity"];
        const tabUrl = pathname.split('/');
        let programIndex = tabUrl.indexOf("programActivity");
        let financialIndex = tabUrl.indexOf("financialActivity");
        let loanIndex = tabUrl.indexOf("contractActivity");
        let currentIndex = 1;
        if (programIndex !== -1)
            currentIndex = currentIndex + 1;
        else if (financialIndex !== -1)
            currentIndex = currentIndex + 2;
        else if (loanIndex !== -1)
            currentIndex = currentIndex + 3;
        return currentIndex;
    }

    /**
    *  Component Will Unmount Event
    */
    componentWillUnmount() {
        // clear table 1 searches
        // this.props.clearTables();
    }

    /**
     * Bind Html to DOM
     */
    render() {
        let { classes, match, projectId, location: { pathname }, validationIssues, pageAccess, title, isSubPage, site_id } = this.props;
        const { openFormNotSavedAlert, tabValue } = this.state;
        match.url = match.url.replace(/\/$/, "");
        const currentTab = this.getTabIndex(pathname);
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        let programActivityColor = 'validationGreenBorder';
        let financialActivityColor = 'validationGreenBorder';
        let contractActivityColor = 'validationGreenBorder';

        if (validationIssues) {
            const programActivityHardStop = validationIssues.programActivity ? validationIssues.programActivity.filter(item => item.errorType === 1) : [];
            const financialActivityHardStop = validationIssues.financialActivity ? validationIssues.financialActivity.filter(item => item.errorType === 1) : [];
            const contractActivityHardStop = validationIssues.contractActivity ? validationIssues.contractActivity.filter(item => item.errorType === 1) : [];
            const programActivityWarning = validationIssues.programActivity ? validationIssues.programActivity.filter(item => item.errorType === 2) : [];
            const financialActivityWarning = validationIssues.financialActivity ? validationIssues.financialActivity.filter(item => item.errorType === 2) : [];
            const contractActivityWarning = validationIssues.contractActivity ? validationIssues.contractActivity.filter(item => item.errorType === 2) : [];
            programActivityColor = programActivityHardStop.length > 0 ? 'validationRedBorder' : programActivityWarning.length > 0 ? 'validationYellowBorder' : 'validationGreenBorder';
            financialActivityColor = financialActivityHardStop.length > 0 ? 'validationRedBorder' : financialActivityWarning.length > 0 ? 'validationYellowBorder' : 'validationGreenBorder';
            contractActivityColor = contractActivityHardStop.length > 0 ? 'validationRedBorder' : contractActivityWarning.length > 0 ? 'validationYellowBorder' : 'validationGreenBorder';
        }

        return (
            <div className='reports'>
                <ContentWrapper
                    classes={classes}
                    pageAccess={pageAccess}
                    title={title}
                    isSubPage={isSubPage}
                    needTitleBar={title ? true : false}
                    needPadding={false}
                    handleClearFilterActionClick={() => {
                        history.replace(`${pathname}#clear`);
                    }}
                    handleBackActionClick={this.handleBackActionClick}
                    handleSaveActionClick={this.handleSaveActionClick}
                >
                    <AppBar position="static" color="secondary">
                        <Tabs
                            name={'tab'}
                            value={currentTab}
                            onChange={this.handleTabChange}
                            indicatorColor={'primary'}
                            textColor="primary"
                            variant="fullWidth"
                            color="secondary"
                            className={'reportTab'}

                        >
                            <Tab classes={{ selected: "reportTabSelected" }} label="CERTIFICATION FORM" className='validationGreenBorder' value={1} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="PROGRAM ACTIVITY" className={validationIssues ? programActivityColor : 'validationGreenBorder'} value={2} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="FINANCIAL ACTIVITY" className={validationIssues ? financialActivityColor : 'validationGreenBorder'} value={3} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="CONTRACT ACTIVITY" className={validationIssues ? contractActivityColor : 'validationGreenBorder'} value={4} />
                        </Tabs>
                    </AppBar>
                    <TabContainer>
                        <Switch>
                            <Route
                                exact
                                path={`${match.url}`}
                                render={(props) => {
                                    return (
                                        <CertificationForm
                                            {...props}
                                            innerRef={this.certificationActivityRef}
                                            pageAccess={pageAccess}
                                            site_id={site_id}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/programActivity`}
                                render={(props) => {
                                    return (
                                        <ProgramActivity
                                            {...props}
                                            innerRef={this.programActivityRef}
                                            pageAccess={pageAccess}
                                            isSubPage={true}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                            projectData={
                                                {
                                                    // reportId: parseInt(match.params.id),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    reportId: parseInt(match.params.id, 10)
                                                }
                                            }
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/financialActivity`}
                                render={(props) => {
                                    return (
                                        <FinancialActivity
                                            {...props}
                                            innerRef={this.financeActivityRef}
                                            pageAccess={pageAccess}
                                            isSubPage={true}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                            projectData={
                                                {
                                                    reportId: parseInt(match.params.id, 10),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    // reportId: parseInt(match.params.id, 10)
                                                }
                                            }
                                        />
                                    )
                                }}
                            />
                            <Route
                                path={`${match.url}/contractActivity`}
                                render={(props) => {
                                    return (
                                        <ContractActivity
                                            {...props}
                                            innerRef={this.contractActivityRef}
                                            pageAccess={pageAccess}
                                            isSubPage={true}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                            projectData={
                                                {
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    reportId: parseInt(match.params.id, 10)
                                                }
                                            }
                                        />
                                    )
                                }}
                            />
                        </Switch>
                    </TabContainer>
                    <AlertDialog
                        open={openFormNotSavedAlert}
                        onSave={() => this.handleTabNavigation(tabValue===undefined?5:tabValue)}
                        onClose={() => { this.setState({ openFormNotSavedAlert: false }) }}
                        saveText='Yes'
                        cancelText='No'
                        title="Warning"
                        saveVisible
                        cancelVisible
                    >
                        You are about to lose input data that has not yet been saved. Would you like to continue? If you press “yes” the unsaved data will be lost. If you press “no,” you will remain on this page and can save the input data.
                    </AlertDialog>
                </ContentWrapper>
            </div>
        );
    }
}

/**
 * Bind Component Property Types
 */
ReportsActivityView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

/**
 * Default Props
 */
ReportsActivityView.defaultProps = {
    needPadding: false,
    isSubPage: false,
    title: '',
    pageAccess: []
};


/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        certificationForm: state.reportActivity.certificationForm.data,
        validationIssues: state.reportActivity.validations.data
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        validationIssue: (reportYear_id) => dispatch(reportActivityActions.validationIssue(reportYear_id))
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ReportsActivityView));