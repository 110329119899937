import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';
import { alertActions } from '../../../actions/alert';
// Import Actions
import { occupantsActions } from '../../../actions/occupants';

// Import Components
import TextBox from '../../../components/TextBox/TextBox';
import EditableTable from '../../../components/EditableTable/EditableTable';
import NumberField from '../../../components/NumberField/NumberField';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import PageActions from '../../../components/PageActions/PageActions';
import TableErrors from '../../../components/TableErrors/TableErrors';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
//import Error display component
// import ErrorDisplay from '../../../components/ErrorDisplay/ErrorDisplay';
// import helpers
import { history } from '../../../helpers';
import { typeOfAssets, incomeTypeCI } from '../../../helpers/appConstants';
import { Table1MRDValidations } from '../../../helpers/TableValidations/Table1';
import { DEFAULT_MEMBERINFO } from "./Validations";
/** Default Data */
const ASSETS_INFO = {
    "_id": 0,
    "table1HouseholdMemberDC_id": null,
    "hhmAssetType_id": null,
    "hhmAssetTypeOtherDescription": null,
    "hhmIncomeType_id": null,
    "cashValue": null,
    "annualAssetIncome": null
}
var occupantAsset=[];

/**
 * EditableTextAmt Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        // console.log('Asset amount',data)
        let className = isReadOly ? `input--disabled textBox--bordered txt-right` : `textBox--bordered txt-right`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                validators={data.fieldName === ('employmentOrWages' || 'socSecPensions') ? [] : ['lessThan5L']}
                errorMessages={['Value should be between 0 to 500,000']}
                disabled={isReadOly}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};


/**
 * EditableTextCashValue Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextCashValue extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        // console.log('Asset amount',data)
        let className = isReadOly ? `input--disabled textBox--bordered txt-right` : `textBox--bordered txt-right`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                validators={['lessThan30L']}
                errorMessages={['Value should be between 0 to 3000,000']}
                disabled={isReadOly}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};


/**
 * EditableTextAssetAmt Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextAssetAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        // console.log('Asset amount',data)
        let className = isReadOly ? `input--disabled textBox--bordered txt-right` : `textBox--bordered txt-right`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                validators={['lessThan10L']}
                errorMessages={['Value should be between 0 to 1000,000']}
                disabled={isReadOly}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * OtherIncomeDesc Component
 * @class OtherIncomeDesc
 * @extends {Component}
 */
class OtherIncomeDesc extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered` : `textBox--bordered`
        return (
            <TextBox
                className={className}
                name={`${fieldName}_${rowIndex}`}
                disabled={data.hhmAssetType_id !== 9 || isReadOly}
                value={data.hhmAssetType_id !== 9 ? "N/A" : data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * AssetTypes Component
 * @class AssetTypes
 * @extends {Component}
 */
class AssetTypes extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { typeOfAssets, isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled autocomplete-marginDense` : `autocomplete-marginDense`
        return (
            <div className="autocomplete-border autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={typeOfAssets}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    className={className}
                    selectedValue={{ id: data[fieldName] }}
                    margin="dense"
                    disabled={isReadOly}
                />
            </div>

        )
    }
};
/**
 * HHMembers Component
 * @class HHMembers
 * @extends {Component}
 */
class HHMembers extends Component {
    state={
        onMemberChange:false,
        // navRestrict:false
    }
    render() {
        let { data, rowIndex, onChange, fieldName, config: { hhMbr, isReadOly } } = this.props;
        //for HOH change CR
        let hhMbrSorted = hhMbr.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        console.log('hhMbr',hhMbrSorted)
        hhMbrSorted.map((item,index)=>{
            item.value = index+1
        })
        var fullname='';
        if(occupantAsset&&occupantAsset[rowIndex]&&occupantAsset[rowIndex].table1HouseholdMemberDC!=undefined&&occupantAsset[rowIndex].table1HouseholdMemberDC.length>0){
             fullname=occupantAsset[rowIndex].table1HouseholdMemberDC[0]&&occupantAsset[rowIndex]?.table1HouseholdMemberDC[0]?.firstName?.toString().concat(" ",occupantAsset[rowIndex].table1HouseholdMemberDC[0].lastName)
        }
        console.log('++++',hhMbrSorted,occupantAsset,fullname,
        
        hhMbrSorted.find((el)=>el.label== fullname)&&this.state.onMemberChange==false?
        "If":data[fieldName]
        )

        let className = isReadOly ? `input--disabled autocomplete-marginDense` : `autocomplete-marginDense`
        return (
            <div className="autocomplete-border autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={hhMbrSorted}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                        console.log(fieldName, rowIndex, selectedOption.value)
                        this.setState({onMemberChange:true})
                    }}
                    className={className}
                    selectedValue={hhMbrSorted.find((data)=>data.label== fullname)&&this.state.onMemberChange==false?{
                        id:hhMbrSorted.find((data)=>data.label== fullname).value
                    }:{
                        id:data[fieldName]
                    }}

                    // selectedValue={{id:data[fieldName]}}
                    margin="dense"
                    disabled={isReadOly}
                />
            </div>
        )
    }
};
/**
 * IncomeTypesCI Component
 * @class IncomeTypesCI
 * @extends {Component}
 */
class IncomeTypesCI extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { incomeTypeCI, isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled autocomplete-marginDense` : `autocomplete-marginDense`
        return (
            <div className="autocomplete-border autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={incomeTypeCI}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    className={className}
                    selectedValue={{ id: data[fieldName] }}
                    margin="dense"
                    disabled={isReadOly}
                />
            </div>
        )
    }
};

const componentMapHHI = {
    employmentOrWages: EditableTextAmt,
    socSecPensions: EditableTextAmt,
    publicAssistance: EditableTextAmt,
    otherIncome: EditableTextAmt
}

const componentMapAssetInfo = {
    table1HouseholdMemberDC_id: HHMembers,
    hhmAssetType_id: AssetTypes,
    hhmIncomeType_id: IncomeTypesCI,
    hhmAssetTypeOtherDescription: OtherIncomeDesc,
    cashValue: EditableTextCashValue,
    annualAssetIncome: EditableTextAssetAmt
}
/**
 * HouseHoldMemberIncome Container
 * @class HouseHoldMemberIncome
 * @extends {Component}
 */
class HouseHoldMemberIncome extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            table1HouseholdMemberDC: [],
            table1HouseholdMemberDCIncomeFromAssets: [],
            hhMbr: [],
            _id: null,
            isCurrentYear:null,
            currentYear:null,
            moveInYear:null        
        };
    }

    UNSAFE_componentWillMount() {
        ValidatorForm.addValidationRule('lessThan5L', (value) => {
            value = value.replace("$", '');
            value = value.replace(/,/g, '');
            let floatValue = parseFloat(value)
            if (floatValue === "NaN" || floatValue > 500000 || floatValue === null) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('lessThan10L', (value) => {
            value = value.replace("$", '');
            value = value.replace(/,/g, '');
            let floatValue = parseFloat(value)
            if (floatValue === "NaN" || floatValue > 1000000 || floatValue === null) {
                return false;
            }
            return true;
        });

        ValidatorForm.addValidationRule('lessThan30L', (value) => {
            value = value.replace("$", '');
            value = value.replace(/,/g, '');
            let floatValue = parseFloat(value)
            if (floatValue === "NaN" || floatValue > 3000000 || floatValue === null) {
                return false;
            }
            return true;
        });
    }

    /**
     * Component Did Mount
     * 
     * [1]  -   get validation error for occupants
     */
    componentDidMount() {
        const { tableId, reportYear_id } = this.props;
        let id = tableId;
        if (id) {
            this.props.getOccupantsDetails(id, reportYear_id);
            // [1]
            this.props.getOneValidationError(id, reportYear_id);
        }
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     * 
     * [1]  -   auto fill head of house hold if the length is 0
     * [2]  -   auto fill other memeber details which is not filled
     * [3]  -   assets item
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne } = nextProps;
        if (getOne.data && this.state._id === null) {
            let table1HouseholdMemberDC = getOne.data.table1HouseholdMemberDC ? [...getOne.data.table1HouseholdMemberDC] : [];
            console.log('asset**',getOne.data)
            getOne.data.length>0&&this.setState({table1HouseholdMemberDCIncomeFromAssets:getOne.data.table1HouseholdMemberDCIncomeFromAssets,isCurrentYear:getOne.data.isCurrentYear,currentYear:getOne.data.currentYear,moveInYear:getOne.data.moveInYear})
            // WBAR-2601----Disabling due to critical impacts----

            // const isNewOccupant = history.location.pathname.includes('new')
            // if(table1HouseholdMemberDC.length > 0){
            // if (( getOne && getOne.data && getOne.data.isCurrentYear) || isNewOccupant) {
                // current year
                // table1HouseholdMemberDC[0].firstName = (getOne.data.firstName === '' || getOne.data.firstName === null) ? 'HOH' : getOne.data.firstName;
                // table1HouseholdMemberDC[0].lastName = getOne.data.lastName;
                // table1HouseholdMemberDC[0].middleInitial = getOne.data.middleInitial;
            // } else {
                //snapshot years
        //         const hoName = getOne.data.headOfHouseholdName;
        //         const hoNameArray = hoName.split(/\ +/);
        //         table1HouseholdMemberDC[0].firstName = hoNameArray[0];
        //         table1HouseholdMemberDC[0].lastName = "HHM";
        //     }
        // }
        //---------------------
            // [1]
            if (table1HouseholdMemberDC.length === 0) {
                console.log('1')
                table1HouseholdMemberDC.push({
                    ...DEFAULT_MEMBERINFO,
                    _id: Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,
                    firstName: getOne.data.firstName,
                    lastName: getOne.data.lastName,
                    familyRelationshipType_id: 1,
                    householdMemberRaceType_id: 14,
                    householdMemberEthnicityType_id: 3,
                    disabilityType_id: 3,
                    employmentOrWages: 0,
                    socSecPensions: 0,
                    publicAssistance: 0,
                    otherIncome: 0,
                });
            }
            // [2]
            let table1HouseholdMemberDCIncomeFromAssets = getOne.data.table1HouseholdMemberDCIncomeFromAssets ? [...getOne.data.table1HouseholdMemberDCIncomeFromAssets] : [];

            if (table1HouseholdMemberDC.length < getOne.data.householdSize) {
                console.log('2')
                if(!table1HouseholdMemberDCIncomeFromAssets.find(item=>item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC.length>0&&item.table1HouseholdMemberDC[0].familyRelationshipType_id == 1)){
                for (let i = table1HouseholdMemberDC.length; i < getOne.data.householdSize; i++) {
                    table1HouseholdMemberDC.push({
                        ...DEFAULT_MEMBERINFO,
                        firstName: `${i + 1}`,
                        lastName: "HHM",
                        _id: Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,
                        familyRelationshipType_id: 7,
                        householdMemberRaceType_id: 14,
                        householdMemberEthnicityType_id: 3,
                        disabilityType_id: 3,
                        employmentOrWages: 0,
                        socSecPensions: 0,
                        publicAssistance: 0,
                        otherIncome: 0,
                    });
                }
            }
            }
            // [3]
            if (table1HouseholdMemberDCIncomeFromAssets.length === 0) {
                console.log('3')

                table1HouseholdMemberDCIncomeFromAssets.push({
                    ...ASSETS_INFO,
                    table1HouseholdMemberDC_id: 1,
                    hhmAssetType_id: 1,
                    hhmIncomeType_id: 1,
                    _id: Math.max(...table1HouseholdMemberDCIncomeFromAssets.map(elt => elt._id), 0) + 1
                });
            }

            // let getHOHRecord = table1HouseholdMemberDC.filter(item=>item.familyRelationshipType_id===1)[0];
            // if(getHOHRecord){
            // delete getHOHRecord._id;
            // }
            // let memberInforFromAsset = [];
            // table1HouseholdMemberDCIncomeFromAssets.map(item=>{
            //     console.log('1232334334534',item.memberInfo)
            //     memberInforFromAsset.concat(item.memberInfo)
            // })

            // let getHOHFromAssets = memberInforFromAsset.filter(item=>item.familyRelationshipType_id===1)[0];
            // if(getHOHFromAssets){
            // delete getHOHFromAssets._id;
            // }

            let table1HouseholdMemberDC_updated = table1HouseholdMemberDC;
            table1HouseholdMemberDC_updated.map(item=>{
            item['label'] = item['firstName']+' '+item['lastName']
            })

            table1HouseholdMemberDC_updated = table1HouseholdMemberDC_updated.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
            let indexOfHOH = table1HouseholdMemberDC_updated.findIndex(x => x.familyRelationshipType_id ===1);
            if(indexOfHOH>=0){
            let HOHObject = []
            //if(getHOHFromAssets===undefined && table1HouseholdMemberDCIncomeFromAssets.findIndex(x => x.familyRelationshipType_id ===1)===-1){
            
            // console.log(table1HouseholdMemberDC_updated[indexOfHOH].label,table1HouseholdMemberDCIncomeFromAssets)
            var insertEle=table1HouseholdMemberDC_updated[indexOfHOH].firstName+" "+table1HouseholdMemberDC_updated[indexOfHOH].lastName;

            // console.log(table1HouseholdMemberDCIncomeFromAssets.map((ele)=>{return ele}))
            var element=[]
            
            element= table1HouseholdMemberDCIncomeFromAssets.filter(
                (item)=> item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC[0]&&(insertEle == item.table1HouseholdMemberDC[0].firstName+" "+item.table1HouseholdMemberDC[0].lastName)
            )
            // console.log('@@@@@@@@',element,table1HouseholdMemberDC_updated[indexOfHOH])


            // if(element.length <= 1){
                if(!table1HouseholdMemberDCIncomeFromAssets.find(item=>item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC.length>0&&item.table1HouseholdMemberDC[0].familyRelationshipType_id == 1)){
                    console.log('4')
                    HOHObject.push({
                        "_id": Math.max(...table1HouseholdMemberDCIncomeFromAssets.map(elt => elt._id), 0) + 1,
                        "table1HouseholdMemberDC_id": indexOfHOH+1,
                        "hhmAssetType_id": 1,
                        "hhmAssetTypeOtherDescription": null,
                        "hhmIncomeType_id": 1,
                        "cashValue": 0,
                        "annualAssetIncome": 0,
                        "balance": "0.00",
                        "table1HouseholdMemberDC":[table1HouseholdMemberDC_updated[indexOfHOH]],
                        "memberInfo":table1HouseholdMemberDC_updated[indexOfHOH]
                    })
                }
            // }
                let table1HouseholdMemberDCIncomeFromAssetsID = table1HouseholdMemberDCIncomeFromAssets[0].table1HouseholdMemberDC_id;
                
                //let lengthOfAsset = table1HouseholdMemberDCIncomeFromAssets.filter(x => x.table1HouseholdMemberDC_id ===1).length;
                
                let ifMemberAlreadyExist = table1HouseholdMemberDCIncomeFromAssets.filter(item=>item.table1HouseholdMemberDC_id===indexOfHOH+1)

                if(((indexOfHOH+1)!==table1HouseholdMemberDCIncomeFromAssetsID)&& ifMemberAlreadyExist.length===0){
                
                //push 
                table1HouseholdMemberDCIncomeFromAssets = HOHObject.concat(table1HouseholdMemberDCIncomeFromAssets);
                let firstObjectID = table1HouseholdMemberDCIncomeFromAssets[0];
                //let allOjectIdWithHOH = table1HouseholdMemberDCIncomeFromAssets.filter(item=>item.table1HouseholdMemberDC_id===firstObjectID.table1HouseholdMemberDC_id);
                
                // allOjectIdWithHOH = allOjectIdWithHOH.filter(item=>item.hhmAssetType_id!==1 && 
                //     item.hhmAssetTypeOtherDescription!==null && item.hhmIncomeType_id!==1 && item.cashValue!==0 && item.annualAssetIncome!==0 &&
                //     item.balance!=='0.00')
                console.log('5',table1HouseholdMemberDCIncomeFromAssets)

                
                // table1HouseholdMemberDCIncomeFromAssets = table1HouseholdMemberDCIncomeFromAssets.filter(item=>item.table1HouseholdMemberDC_id!==firstObjectID.table1HouseholdMemberDC_id)
                
                // table1HouseholdMemberDCIncomeFromAssets = [firstObjectID].concat(table1HouseholdMemberDCIncomeFromAssets);
                // table1HouseholdMemberDCIncomeFromAssets.map((item,index)=>{
                //     item._id = index+1
                // })

                }else{
                    console.log('6')
                    let getCurrentHOHIndex = table1HouseholdMemberDC_updated.findIndex(item=>item.familyRelationshipType_id==1);
                    // let getExistingHOHIndex = table1HouseholdMemberDCIncomeFromAssets[0].table1HouseholdMemberDC_id;

                    // console.log('getCurrentHOHIndex#############',getCurrentHOHIndex)
                    // console.log('getExistingHOHIndex$$$$$$$$$$$$',getExistingHOHIndex)

                    let checkIfAlreadyExists = table1HouseholdMemberDCIncomeFromAssets.filter(item=>item.table1HouseholdMemberDC_id===getCurrentHOHIndex+1)
                    if(checkIfAlreadyExists.length>0){
                        let getNotHOHObjects = table1HouseholdMemberDCIncomeFromAssets.filter(item=>item.table1HouseholdMemberDC_id!==getCurrentHOHIndex+1)
                        table1HouseholdMemberDCIncomeFromAssets=checkIfAlreadyExists.concat(getNotHOHObjects)
                    }

                    // if(getCurrentHOHIndex+1!==getExistingHOHIndex){
                    //     table1HouseholdMemberDCIncomeFromAssets[0].table1HouseholdMemberDC_id=getCurrentHOHIndex+1
                    // }
                    if(this.state.isCurrentYear){
                        table1HouseholdMemberDCIncomeFromAssets = HOHObject.concat(table1HouseholdMemberDCIncomeFromAssets)
                    };
                }  
            }          
            // }else{
            // console.log('getHOHRecord555555555555555555555',getHOHRecord)
            // console.log('getHOHFromAssets6666666666666666666666666',getHOHFromAssets)
            // }
            //Rearrange order for HOH
            let getHOHMember = table1HouseholdMemberDC.filter(item=>item.familyRelationshipType_id===1);
            let getNonHOHMembers = table1HouseholdMemberDC.filter(item=>item.familyRelationshipType_id!==1);
            console.log('mem-sort-------',table1HouseholdMemberDC)
            table1HouseholdMemberDC = getHOHMember.concat(getNonHOHMembers);
            this.setState({
                ...getOne.data,
                table1HouseholdMemberDCIncomeFromAssets,
                table1HouseholdMemberDC: table1HouseholdMemberDC,
                hhMbr: table1HouseholdMemberDC.map((mbr) => {
                    const lastName = mbr.lastName || '';
                    const firstName=mbr.firstName || '';
                    return {
                        value: mbr._id,
                        label: `${firstName} ${lastName}`
                    }
                })
            });
            // occupantAsset=getOne.data.table1HouseholdMemberDCIncomeFromAssets;

            // console.log(table1HouseholdMemberDCIncomeFromAssets.find(item=>item.table1HouseholdMemberDC[0].familyRelationshipType_id == 1))
                // if(!table1HouseholdMemberDCIncomeFromAssets.find(item=>item.table1HouseholdMemberDC[0].familyRelationshipType_id == 1)){
            // console.log(table1HouseholdMemberDCIncomeFromAssets.filter())

        }
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm(3);
    };
    /**
     * Handle SPrev Button
     * @param {*} e
     */
    handlePrevButton = (e) => {
        // const error = { ...this.getHouseHoldAssetError() }
        // if (!error.required) {
        this._saveForm(1);
        // }
    };
    /**
     * Save Form
     * 
     * [1]  -   set up api form submission datas
     * [2]  -   save to local move to next tab
     */
    _saveForm(value) {
        const { totalInc, totalIncomeFromAssests } = this.getCalcValues();
        const {canChangeMoveInDate,moveInAnnualGrossIncome} = this.state;
        console.log('submit---',occupantAsset)
        // [1]
        //As per the request from client on 06-12-2022 the total income value should be updated to annualGross and moveinAnnualGross
        //const annualGrossIncome = getFloat(totalIncomeFromAssests) + getFloat(totalInc) || 0;
        let table1HouseholdMemberDC_updated = this.state.table1HouseholdMemberDC;
        table1HouseholdMemberDC_updated.map(item=>{
            item['label'] = item['firstName']+' '+item['lastName']
        })

        table1HouseholdMemberDC_updated = table1HouseholdMemberDC_updated.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        
        let memAssets = this.state.table1HouseholdMemberDCIncomeFromAssets
        memAssets.map(item=>{
            if(!item['memberInfo'] && !item['table1HouseholdMemberDC']){
                item['memberInfo']=table1HouseholdMemberDC_updated[item.table1HouseholdMemberDC_id-1]
                item['table1HouseholdMemberDC'] = [table1HouseholdMemberDC_updated[item.table1HouseholdMemberDC_id-1]]
            }
        })
        console.log('//////ass',memAssets)

        
        //Logic for delete scenario
        let getMemberDetailsFrommemAssets = [];
        memAssets.map(item=>{
                getMemberDetailsFrommemAssets.push(item.memberInfo)
        })
        // let deletedItem = getMemberDetailsFrommemAssets.map(item=>{
        //     if(this.state.table1HouseholdMemberDC.findIndex(fi=>fi.label===item.label)===-1){
        //         return item
        //     }0.00
        // })

        //delete scenario

        let generalData;
        if (!canChangeMoveInDate) {
            generalData = {
                canChangeMoveInDate: this.state.canChangeMoveInDate,
                annualGrossIncome: totalIncomeFromAssests + totalInc,
                moveInAnnualGrossIncome: Math.round(moveInAnnualGrossIncome),
                table1HouseholdMemberDC: this.state.table1HouseholdMemberDC,
                table1HouseholdMemberDCIncomeFromAssets: memAssets
            };
        } else {
            generalData = {
                canChangeMoveInDate: this.state.canChangeMoveInDate,
                moveInAnnualGrossIncome: totalIncomeFromAssests + totalInc,
                //Math.round(annualGrossIncome),
                annualGrossIncome: totalIncomeFromAssests + totalInc,
                //totalInc === 0 && Math.round(annualGrossIncome) === 0 ? 0 : totalInc === 0 ? Math.round(annualGrossIncome) : Math.round(annualGrossIncome),
                table1HouseholdMemberDC: this.state.table1HouseholdMemberDC,
                table1HouseholdMemberDCIncomeFromAssets: memAssets
            };
        }
        //change HOH order
        let withoutHOH = generalData.table1HouseholdMemberDC.filter(item=>item.familyRelationshipType_id!==1);
        let withHOH = generalData.table1HouseholdMemberDC.filter(item=>item.familyRelationshipType_id===1);
        generalData.table1HouseholdMemberDC = withHOH.concat(withoutHOH)
        
        console.log('********',this.state.table1HouseholdMemberDCIncomeFromAssets)
        console.log('********',generalData)
        // if(this.state.navRestrict == false){
        if (this.props.tableId) {
            console.log('*******if',generalData)
            // this.state.table1HouseholdMemberDCIncomeFromAssets[this.state.table1HouseholdMemberDCIncomeFromAssets.length-1].memberInfo&&
            
            this.props.updateOccupantsHHINCOMEDetails(this.props.tableId, generalData, () => {
                this.props.handleNav(null, value);
                this.props.getAlertOnSuccess("Updated Successfully")
            });
        } else {
            // [2]
            console.log('*******else',generalData)
            this.props.saveOccupantToLocal(generalData);
            this.props.handleNav(null, value);
        }
    }
    // }
    /**
     * Cell Component OnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { table1HouseholdMemberDC } = this.state;
        value = value === undefined ? null : value;
        switch (fieldName) {
            case 'firstName':
                table1HouseholdMemberDC[rowIndex][fieldName] = value;
                this.setState({ table1HouseholdMemberDC });
                break;
            default:
                table1HouseholdMemberDC[rowIndex][fieldName] = value;
                this.setState({ table1HouseholdMemberDC });
        }
    }
    /**
     * Cell Component OnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange2 = async(fieldName, rowIndex, value) => {
        const { table1HouseholdMemberDCIncomeFromAssets } = this.state;
        value = value === undefined ? null : value;
        console.log('ONCHANGE****',fieldName, rowIndex, value)
        occupantAsset[rowIndex][fieldName] = value;
        await this.setState({ table1HouseholdMemberDCIncomeFromAssets:occupantAsset ,
            // navRestrict:false 
        });
        // table1HouseholdMemberDCIncomeFromAssets[rowIndex][fieldName] = value;
        //     await this.setState({ table1HouseholdMemberDCIncomeFromAssets });
        console.log('updated array',this.state.table1HouseholdMemberDCIncomeFromAssets)
        // this.getHouseHoldAssetError();
    }
    /**
     * Display Amount
     * @param {*} value 
     * @param {*} isReadOly 
     * @returns 
     */
    displayAmount(value, isReadOly) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                decimalScale={0}
                value={value}
                prefix={'$'}
                fixedDecimalScale={false}
                thousandSeparator={true}
                displayType={'text'}
                disabled={isReadOly}
            />
        )
    }
    /**
     * Get Reduced Total
     * @param {*} arr 
     * @param {*} key 
     * @param {*} initialValue 
     * @returns 
     */
    getReduceTotal(arr, key, initialValue) {
        return arr.reduce((a, b) => {
            const cVal = b[key];
            const value = cVal === null || cVal === '' || cVal === 'undefined' ? 0 : cVal;
            return a + parseFloat(value);
        }, initialValue);
    }
    /**
     * Get Calculated Values
     */
    getCalcValues() {
        const { table1HouseholdMemberDC, table1HouseholdMemberDCIncomeFromAssets,isImplementFromCurrentYear,isCurrentYear,currentYear,moveInYear } = this.state;
        let isNewOccupant = history.location.pathname.includes('new')
        let table1HouseholdMemberDCValue = table1HouseholdMemberDC ? table1HouseholdMemberDC : []
        const totalEW = this.getReduceTotal(table1HouseholdMemberDCValue, 'employmentOrWages', 0);
        const totalSOC = this.getReduceTotal(table1HouseholdMemberDCValue, 'socSecPensions', 0);
        const totalPA = this.getReduceTotal(table1HouseholdMemberDCValue, 'publicAssistance', 0);
        const totalOT = this.getReduceTotal(table1HouseholdMemberDCValue, 'otherIncome', 0);
        const totalCV = this.getReduceTotal(table1HouseholdMemberDCIncomeFromAssets, 'cashValue', 0);
        const totalAI = this.getReduceTotal(table1HouseholdMemberDCIncomeFromAssets, 'annualAssetIncome', 0);
        const totalInc = totalEW + totalSOC + totalPA + totalOT;
        const imputedValue = totalCV > 5000 ? totalCV : 0;
        //update calculation to 0.06 based for current year and monitoring funder
        const imputedCalc = ((isCurrentYear===true || isImplementFromCurrentYear===true || isNewOccupant === true)&& (isNewOccupant === true? parseInt(moveInYear)>=2023 : parseInt(currentYear)>=2023))?(imputedValue * (0.06/ 100)).toFixed(0):(imputedValue * (2 / 100)).toFixed(0);
        const totalIncomeFromAssests = imputedCalc > totalAI ? parseFloat(imputedCalc) : totalAI;
        return {
            totalEW,
            totalSOC,
            totalPA,
            totalOT,
            totalCV,
            totalAI,
            totalInc,
            imputedValue,
            imputedCalc,
            totalIncomeFromAssests
        }
    }
    // /**
    //  * Get HouseHold Asset errors
    //  * @returns 
    //  */
    // getHouseHoldAssetError() {
    //     const { table1HouseholdMemberDCIncomeFromAssets } = this.state;
    //     if (table1HouseholdMemberDCIncomeFromAssets) {
    //        table1HouseholdMemberDCIncomeFromAssets.map((asset)=>{
    //             if(asset.table1HouseholdMemberDC_id == null ||asset.table1HouseholdMemberDC_id == undefined){
    //                 this.setState({navRestrict:true})
    //             }
    //         })
    //         if(this.state.navRestrict == true){
    //             console.log('***true')
    //             return {
    //             required: true,
    //             message: '*Household member cannot be empty.'
    //         };
    //     }else{
    //         return { required: false, message: null };
    //     }
    //     }
    //    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, pageAccess, getOne, validationErrors } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        let { table1HouseholdMemberDC, table1HouseholdMemberDCIncomeFromAssets, hhMbr, annualGrossIncome, canChangeMoveInDate, householdSize, householdSize_MoveIn,specialNeedOption_id,bedRoom,unitDesignation_id,isCurrentYear, isImplementFromCurrentYear,currentYear,moveInYear } = this.state;
        
        occupantAsset=table1HouseholdMemberDCIncomeFromAssets;

        let hhMbrSortedAsset = table1HouseholdMemberDCIncomeFromAssets.sort(
            (a,b) => a.table1HouseholdMemberDC&&a.table1HouseholdMemberDC.length>0&&b.table1HouseholdMemberDC&&b.table1HouseholdMemberDC.length>0&&(a.table1HouseholdMemberDC[0].familyRelationshipType_id > b.table1HouseholdMemberDC[0].familyRelationshipType_id 
            ) ? 1 : (
                a.table1HouseholdMemberDC&&a.table1HouseholdMemberDC.length>0&&b.table1HouseholdMemberDC&&b.table1HouseholdMemberDC.length>0&&(b.table1HouseholdMemberDC[0].familyRelationshipType_id  > a.table1HouseholdMemberDC[0].familyRelationshipType_id 
                    ) ? -1 : 0
                    )
                );
        console.log('sorted asset***',hhMbrSortedAsset)
        // hhMbrSorted.map((item,index)=>{
        //     item.value = index+1
        // })

        let HOHasset=[]
        let NonHOHAsset=[]
        if(table1HouseholdMemberDC[0]){
             HOHasset=table1HouseholdMemberDCIncomeFromAssets.filter(
            (item)=>item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC[0]&&table1HouseholdMemberDC[0].label === item.table1HouseholdMemberDC[0].label
        )
         NonHOHAsset=table1HouseholdMemberDCIncomeFromAssets.filter(
            (item)=>item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC[0]&&table1HouseholdMemberDC[0].label != item.table1HouseholdMemberDC[0].label||item.table1HouseholdMemberDC === undefined
        )}
        const BlankAsset=table1HouseholdMemberDCIncomeFromAssets.filter(
            (item)=>item.table1HouseholdMemberDC_id === null
        )
        const NoMemDC=table1HouseholdMemberDCIncomeFromAssets.filter(
            (item)=>item.table1HouseholdMemberDC_id != null && item.table1HouseholdMemberDC === undefined
        )
        // console.log('blank---',BlankAsset,NoMemDC)
        var assetData=[...HOHasset,...NonHOHAsset,...NoMemDC,...BlankAsset]
        // occupantAsset=assetData;
        // console.log('ass*****',HOHasset,NonHOHAsset,assetData,occupantAsset)

        table1HouseholdMemberDC.forEach((element, index) => {
            if(element.firstName === null&&element.lastName ===null) {
                table1HouseholdMemberDC[index].firstName = 'HHM';
                table1HouseholdMemberDC[index].lastName = 'HHM';
            }else if(element.firstName === null){
                table1HouseholdMemberDC[index].firstName = 'HHM';
            }else if(element.lastName === null){
                table1HouseholdMemberDC[index].lastName = 'HHM';
            }
        });

        const { totalEW, totalSOC, totalPA, totalOT, totalCV, totalAI, totalInc, imputedValue, imputedCalc, totalIncomeFromAssests } = this.getCalcValues();
        const fielderrors = Table1MRDValidations({
            ...this.state,
            validationErrors
        });
        let allSourcesAmount = (totalIncomeFromAssests + totalInc) || annualGrossIncome || 0;
        //Update lable based on current year and monitoring funder
        let isNewOccupant = history.location.pathname.includes('new')
        
        let imputedText = ((isNewOccupant===true || isCurrentYear===true || isImplementFromCurrentYear===true)&&(isNewOccupant === true? parseInt(moveInYear)>=2023 : parseInt(currentYear)>=2023))?`(${imputedValue} X 0.06%) = Imputed Income: `:`(${imputedValue} X 2%) = Imputed Income: `
        //handle error for largehousehold
        let valErrors = validationErrors;
        if(unitDesignation_id&&unitDesignation_id===1){
        if(!canChangeMoveInDate){
            if(specialNeedOption_id===2 && householdSize_MoveIn<4 && householdSize<4){
                let index = valErrors.findIndex(x => x.message ==="This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                if(index===-1){
                valErrors.push({"message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                "errorType": 2,"tabIndex": 0})
                }
            }
            if(specialNeedOption_id===2 && householdSize>=4){
                if(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))>-1){
                    valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")),1)
                }
            }
        }else{
            if(specialNeedOption_id===2 && householdSize_MoveIn<4 && householdSize<4){
                let index = valErrors.findIndex(x => x.message ==="This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                if(index===-1){
                valErrors.push({"message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                "errorType": 2,"tabIndex": 0})
                }
            }
            if(specialNeedOption_id===2 && (householdSize_MoveIn>=4 || householdSize>=4)){
                if(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))>-1){
                    valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")),1)
                }
            }
        }
    }
        return (
            <div className={classes.rootEdit}>
                <TableErrors
                    fielderrors={fielderrors}
                    validations={validationErrors}
                    data={getOne.data || {}}
                    classes={classes}
                />
                <ValidatorForm
                    name="occupantsDetailsform"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >

                    <div className={'houseHoldINCOME'} >
                        <EditableTable
                            needTitle={false}
                            tableClass={'fieldWidth12'}
                            headerCols={[
                                'First Name',
                                'Last Name',
                                '(A) Employment or Wages',
                                '(B) SOC Sec/Pensions',
                                'Public Assistance',
                                'Other Income'
                            ]}
                            fieldCols={[
                                'firstName',
                                'lastName',
                                'employmentOrWages',
                                'socSecPensions',
                                'publicAssistance',
                                'otherIncome'
                            ]}
                            footerDatas={[
                                {
                                    'employmentOrWages_footer': this.displayAmount(totalEW, isReadOly),
                                    'socSecPensions_footer': this.displayAmount(totalSOC, isReadOly),
                                    'publicAssistance_footer': this.displayAmount(totalPA, isReadOly),
                                    'otherIncome_footer': this.displayAmount(totalOT, isReadOly),
                                }
                            ]}
                            config={{ typeOfAssets, isReadOly, hhMbr, incomeTypeCI, pageAccess }}
                            rowDatas={table1HouseholdMemberDC ? table1HouseholdMemberDC : []}
                            componentMap={componentMapHHI}
                            cellComponentOnChange={this.cellComponentOnChange}
                        />
                    </div>
                    <Grid item xs={12} sm={12} className={"houseHoldTotalIncome"}>
                        {`Total Income: `}
                        {this.displayAmount(totalInc, isReadOly)}
                    </Grid>
                    <div className={'holuseHoldEditableContainer'}>
                        <EditableTable
                            disabled={isReadOly}
                            needTitle={false}
                            tableClass={'fieldWidth12'}
                            actions={isReadOly ? [] : ['DELETE']}
                            headerCols={[
                                'HH Mbr',
                                '(F) Type of Assests',
                                '(G) Current or Imputed',
                                '(H) Cash Value',
                                '(I) Annual  Asset Income',
                                'Other Asset Type Description'
                            ]}
                            fieldCols={[
                                'table1HouseholdMemberDC_id',
                                'hhmAssetType_id',
                                'hhmIncomeType_id',
                                'cashValue',
                                'annualAssetIncome',
                                'hhmAssetTypeOtherDescription'
                            ]}
                            // rowDatas={assetData.length>=1?assetData:table1HouseholdMemberDCIncomeFromAssets}
                            // rowDatas={table1HouseholdMemberDCIncomeFromAssets}
                            rowDatas={hhMbrSortedAsset}

                            componentMap={componentMapAssetInfo}
                            cellComponentOnChange={this.cellComponentOnChange2}
                            onDelete={(item) => {
                                const updatedRows = this.state.table1HouseholdMemberDCIncomeFromAssets.filter(tt => tt._id !== item._id)
                                console.log('=====onDelete',updatedRows)
                                this.setState({
                                    table1HouseholdMemberDCIncomeFromAssets: updatedRows
                                });
                            }}
                            config={{ typeOfAssets, isReadOly, hhMbr, incomeTypeCI, pageAccess }}
                            footerDatas={[
                                {
                                    'hhmIncomeType_id_footer': 'Totals:',
                                    'cashValue_footer': this.displayAmount(totalCV, isReadOly),
                                    'annualAssetIncome_footer': this.displayAmount(totalAI, isReadOly),
                                }
                            ]}
                        />
                        {/* <Grid item xs={12} sm={12}>
                        <ErrorDisplay
                            name="householdAsset-error-display"
                            {...this.getHouseHoldAssetError()}
                        />
                    </Grid> */}
                    </div>
                    <Grid item xs={12} sm={12}>
                        <PageActions
                            isSubPage={true}
                            actions={isReadOly ? [] : ['ADDLINK']}
                            handleAddActionClick={() => {
                                const rowData = table1HouseholdMemberDCIncomeFromAssets;
                                rowData.push({
                                    ...ASSETS_INFO,
                                    table1HouseholdMemberDC_id: 1,
                                    hhmAssetType_id: 1,
                                    hhmIncomeType_id: 1,
                                    _id: Math.max(...table1HouseholdMemberDCIncomeFromAssets.map(elt => elt._id), 0) + 1
                                });
                                this.setState({
                                    data: {
                                        table1HouseholdMemberDCIncomeFromAssets: rowData
                                    }
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} className={"houseHoldImputedIncome"}>
                        {`${imputedText}`}{this.displayAmount(imputedCalc, isReadOly)}
                        <br />
                        {`(Greater of Imputed Income or Annual Asset Income) =Total Income From Assets: `}
                        {this.displayAmount(totalIncomeFromAssests, isReadOly)}
                        <br />
                        {`Total Annual Household Income - All Sources: `}
                        {this.displayAmount(Math.round(allSourcesAmount), isReadOly)}
                    </Grid>
                    {
                        pageAccess.indexOf('SAVE') > -1 ? (
                            <SaveCancel
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { this.handlePrevButton() }}
                                // handleCancelButtonClick={() => { this.props.handleNav(null, 1) }}
                                saveText={'Next'}
                                cancelVisible={true}
                                swap={true}
                                cancelText={'Prev'}
                            />
                        ) : null
                    }
                </ValidatorForm>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
HouseHoldMemberIncome.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}
/** Default props */
HouseHoldMemberIncome.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 */
const mapStateToProps = ({ occupants, pageAccess }) => {
    return {
        getOne: occupants.getOne,
        pageAccess: pageAccess['occhouseholdmemberincome'] || [],
        validationErrors: occupants.validationErrors
    }
};
/**
 * Map Dispach to Props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOccupantsDetails: (id, reportYear_id) => dispatch(occupantsActions.getOccupantsDetails(id, reportYear_id)),
        updateOccupantsHHINCOMEDetails: (id, formData, callBack) => dispatch(occupantsActions.updateOccupantsHHINCOMEDetails(id, formData, callBack)),
        saveOccupantToLocal: (data) => dispatch(occupantsActions.saveOccupantToLocal(data)),
        getOneValidationError: (id, reportYear_id) => dispatch(occupantsActions.getOneValidationError(id, reportYear_id)),
        getAlertOnSuccess: (message) => dispatch(alertActions.success(message))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(HouseHoldMemberIncome));