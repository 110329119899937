import { userConstants  } from '../constants';
import { restoreTimeConstants } from '../constants/restoreTime';


const tableDatas = (sortName) => {
    return {
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: [sortName],
                searchCols: []
            }
        }
    }
}

const initialState = {
    ...tableDatas('firstName'),
    projects: {
        ...tableDatas('name'),
        reset: false,
        isLoading: false
    },
    loanProjects: {
        ...tableDatas('name'),
        reset: false,
        isLoading: false
    },
    getOne: {
        data: null,
        projectNames:{}
    },
    environmentDate:null,
    image: null,
    orgs: [],
    isLoading: false
};

export function users(state = initialState, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                },
                isLoading: true
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                // data: action.users.data.map((data) => {
                //     if (data.isActive === false) data.name = `*${data.name}`;
                //     return data
                // }),
                // data: action.users.data,
                // page: action.users.page,
                ...action.users,
                isLoading: false
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case userConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    data: null
                },
                projects: {
                    ...tableDatas('name')
                },
                image: null,
                isLoading: true
            };
        case userConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.user.data,
                    projectNames:action.user.projectNames,
                    isLoading: false
                }
              
            };
        case userConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case userConstants.UPDATE_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.UPDATE_USER_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.user.data
                },
                isLoading: false
            };
        case userConstants.UPDATE_USER_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case userConstants.GETALL_ORGANIZATION_REQUEST:
            return {
                ...state,
                isLoading: true,
                orgs: [],
            };
        case userConstants.GETALL_ORGANIZATION_SUCCESS:
            return {
                ...state,
                orgs: action.suggestions,
                isLoading: false
            };
        case userConstants.GETALL_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case userConstants.ADD_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.ADD_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case userConstants.ADD_USER_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                data: state.data.map(user =>
                    user.id === action.id
                        ? { ...user, deleting: true }
                        : user
                ),
                isLoading:true
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                data: state.data.filter(user => user._id !== action.id),
                isLoading:false
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state.page,
                data: state.data.map(user => {
                    if (user.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...userCopy } = user;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...userCopy, deleteError: action.error };
                    }

                    return user;
                }),
                isLoading:false
            };
        case userConstants.CLEAR_FILTER_SET:
            return {
                ...state,
                reset: true,
            };
        case userConstants.CLEAR_FILTER_RESET:
            return {
                ...state,
                reset: false,
            };
        case userConstants.GET_USER_PICTURE_SUCCESS:
            return {...state, image: action.url}
        case userConstants.UPDATE_USER_PICTURE_SUCCESS:
            return {
                ...state,
                image: action.url
            };
        case userConstants.GET_MY_PROFILE_PICTURE_SUCCESS:
            return {
                ...state,
                myimage: action.url
            };
        case userConstants.GET_ASSTDPROJECTS_REQUEST:
            return {
                ...state,
                projects:{
                    ...state.projects,
                    isLoading: true
                }
            }

        case userConstants.GET_ASSTDPROJECTS_SUCCESS:
            return {
                ...state,
                projects: {
                    ...action.projects,
                    isLoading: false
                },
                isLoading: false
            };
        case userConstants.GET_ASSTDPROJECTS_FAILURE:
            return {
                ...state,
                projects:{
                    ...state.projects,
                    isLoading: false
                },
                error: action.error,
                isLoading: false
            };
            case userConstants.GET_ASSTDLOANPROJECTS_REQUEST:
                return {
                    ...state,
                    loanProjects:{
                        ...state.loanProjects,
                        isLoading: true
                    }
                }
    
            case userConstants.GET_ASSTDLOANPROJECTS_SUCCESS:
                return {
                    ...state,
                    loanProjects: {
                        ...action.projects,
                        isLoading: false
                    },
                    isLoading: false
                };
            case userConstants.GET_ASSTDLOANPROJECTS_FAILURE:
                return {
                    ...state,
                    loanProjects:{
                        ...state.loanProjects,
                        isLoading: false
                    },
                    error: action.error,
                    isLoading: false
                };
            case userConstants.RETRIEVE_USER_REQUEST:
                return{
                    ...state,
                    isLoading:true
                }
            case userConstants.RETRIEVE_USER_FAILURE:
            case userConstants.RETRIEVE_USER_SUCCESS:
                return{
                    ...state,
                    isLoading:false
                }
            case restoreTimeConstants.GET_RESTORE_DATE_REQUEST:
                return {
                        ...state,
                };
            case restoreTimeConstants.GET_RESTORE_DATE_SUCCESS:
                return {
                        ...state,
                        environmentDate:action.users,
                };
            case restoreTimeConstants.GET_RESTORE_DATE_FAILURE:
                return {
                        ...state,
                        error: action.error,
                };
        default:
            return state
    }
}