import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Import Components
import TextArea from '../../../../components/TextArea/TextArea';
/**
 * Comments Component
 * @class Comments
 * @extends {Component}
 */
class Comments extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {}
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { data, classes, isDisabled } = this.props;
        const { notes } = data;

        return (
                <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextArea
                        name="notes"
                        value={notes === null ? '' : notes}
                        label="Comments"
                        className={classes.textField}
                        margin="dense"
                        handleChange={this.props.onChange}
                        disabled={isDisabled}
                        multiline={true}
                    />
                </Grid>
            </Grid>
        )
    }
}
/** Props Types */
Comments.propTypes = {
    classes: PropTypes.object.isRequired
};
/** Default Props */
Comments.defaultProps = {
    data: {},
    config: {},
    classes: {},
    pageAccess: []
};
/** Export Components */
export default Comments;
