import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Import Material Ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Actions
import { userActions } from '../../actions/user'
import { maintenanceActions } from '../../actions/maintenance';
import { confActions } from '../../actions/configuration';
import { restoreTimeActions } from '../../actions/restoreTime';

// Import Images
import Seattle from '../../assets/img/seattle.png';
import Kingcounty from '../../assets/img/kingcounty.png';
import Cityofspokane from '../../assets/img/cityofspokane.png';
import Cityoftacoma from '../../assets/img/cityoftacoma.png';
import Snohomish from '../../assets/img/snohomish.png';
import Cityofbellingham from '../../assets/img/cityofbellingham.png';
import commerce_logo from '../../assets/img/commerce_logo.png';
import wshfc_logo from '../../assets/img/wshfc_logo.png';

//Import Documants
import Tos from '../../assets/documents/TOS.pdf';
import StandardConfig from '../../assets/documents/configuration.pdf';

// Import Styles
import loginStyle from '../../assets/jss/containers/login';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import AlertDialogFullScreen from '../../components/AlertDialog/AlertDialogFullScreen';

// Import Helpers
import { getUserTOSStatus, getDevEnviromentLabel } from '../../helpers/global';

/**
 * Login Container
 * @class Login
 * @extends {Component}
 */
class Login extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            bannerText: {},
            environment: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        let evnVariable = getDevEnviromentLabel()
        if (evnVariable.includes("-")) {
            evnVariable = evnVariable.split("-")[1].trim()
        }
        this.props.maintanance();
        this.props.getBanner();
        this.props.getRestoreTime(evnVariable);
    }

    /**
     * Component Did Mount Event
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { bannerContent, environment } = nextProps;
        let env = environment && environment.data && environment.data
        this.setState({
            bannerText: {
                ...bannerContent,
            },
            environment: env
        })
    }
    /**
     * Handle change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };

    /**
     * Handle submit
     * @param {*} e 
     */
    handleSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
        const { username, password } = this.state;
        if (username && password) {
            this.props.login(username, password);
        }
    }
    /**
     * Bind HTML to DOM
     */
    render() {
        const { username, password, bannerText, environment } = this.state;
        let restoredTime = environment && environment.restored_time;
        //setting deployment tag in banner
        let tagEnvironment = getDevEnviromentLabel() === "- QA";
        let deploymentTag_ms = (environment && environment.deployment_ms_tag) == null ? "" : environment.deployment_ms_tag;
        let deploymentTag_api = (environment && environment.deployment_api_tag) == null ? "" : environment.deployment_api_tag;
        let deploymentTag_web = (environment && environment.deployment_web_tag) == null ? "" : environment.deployment_web_tag;
        let forEnv = getDevEnviromentLabel() === "UAT" || getDevEnviromentLabel() === "- QA" || getDevEnviromentLabel() === "QA_PERF"|| getDevEnviromentLabel() === "MIRROR";
        let formatDateTime = new Date(restoredTime);
        let ISTOffset = 330;
        let ISTTime = new Date(formatDateTime.getTime() + (ISTOffset + formatDateTime.getTimezoneOffset()) * 60000);
        let hoursIST = (ISTTime.getHours() % 12) || 12;
        let minutesIST = ISTTime.getMinutes();
        let ampm = hoursIST >= 12 ? 'PM' : 'AM';
        let year = formatDateTime.getFullYear();
        let month = (formatDateTime.getMonth() + 1).toString().padStart(2, '0');
        let dt = formatDateTime.getDate().toString().padStart(2, '0');
        //Last Database Refresh Date & Time
        const restoreDateTime = (month + '/' + dt + '/' + year + " " + "&" + " " + hoursIST + ':' + (minutesIST < 10 ? '0' + minutesIST : minutesIST) + ' ' + ampm);
        const reportYear = new Date().getFullYear();
        let user = getUserTOSStatus();
        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="stretch" className="h-100vh">
                    {/* { maintenance && maintenance.maintenance && <div className="maintenance-block">{maintenance.maintenanceText.charAt(0).toUpperCase() + maintenance.maintenanceText.slice(1)}</div>} */}
                    <Grid item xs={6} lg={7} sm={6} className="banner_bg h-100">
                        {/* <Grid container direction="row" justify="flex-start" alignItems="center" className="h-100 banner_container" > */}
                        <Grid container direction="row" justifyContent="flex-start" className="h-100 banner_container" >
                            <Grid item className="banner_block" id="banner_block">
                                {/* <Typography variant="display2" gutterBottom> {reportYear} WBARS Improvements </Typography> 
                            <Typography variant="headline" gutterBottom> we are making wbars easier to use </Typography>  
                             <Button variant="outlined" className="btn_outline m-t-20" > Find out More! </Button>  */}
                                <Typography variant="h3" gutterBottom color="textPrimary">
                                    WBARS {getDevEnviromentLabel()}
                                </Typography>
                                {forEnv && (
                                    <div>
                                        <Typography variant="h6" gutterBottom color="textPrimary">
                                            Last Database Refresh: {restoreDateTime}
                                        </Typography>
                                    </div>
                                )}
                                 {tagEnvironment && (
                                    <div>
                                        <Typography variant="h6" gutterBottom color="textPrimary">
                                            Current Deployment Tag in MS : {deploymentTag_ms}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom color="textPrimary">
                                            Current Deployment Tag in API : {deploymentTag_api}
                                        </Typography>
                                        <Typography variant="h6" gutterBottom color="textPrimary">
                                            Current Deployment Tag in WEB: {deploymentTag_web}
                                        </Typography>
                                    </div>
                                )}
                                <div
                                    className="banner_content"
                                    style={{
                                        color: 'white',
                                        backgroundColor:
                                            bannerText.bannerColor && bannerText.bannerColor.hex,
                                        padding: 12,
                                        maxHeight: document.getElementById('banner_block') && document.getElementById('banner_block').offsetHeight - 270
                                    }}
                                    dangerouslySetInnerHTML={{ __html: bannerText.bannerContent }}>
                                </div>
                                {/* <Typography variant="h6" gutterBottom color="textPrimary" className="headlines"> Web-Based Annual Reporting System <br /> for the Combined Funders of Washington State  </Typography> */}

                            </Grid>
                            <Grid item className="footer_links">
                                <p>
                                    {/* <a href={Questions} target="_blank" rel="noopener noreferrer"> Contact Us </a>
                                | <a target="_blank" href="http://www.wshfc.org/managers/wbars.htm" rel="noopener noreferrer"> Instructions </a>}*/}
                                    <a href={StandardConfig} target="_blank" rel="noopener noreferrer"> Standard Configuration </a>
                                    | <a href={Tos} target="_blank" rel="noopener noreferrer"> Terms of Use </a>
                                </p>
                                <p> Washington State Housing Finance Commission. © {reportYear} All Rights Reserved. </p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} lg={5} sm={6} className="login_block" >
                        <Grid container direction="row">
                            <Grid item xs={6} lg={6} >
                                <a target="_blank" href="http://www.wshfc.org/managers/" title="WSHFC - Compliance & Preservation Division" rel="noopener noreferrer">
                                    <img src={wshfc_logo} alt="WSHFC - Compliance & Preservation Division" className="hfc" />
                                </a>
                            </Grid>
                            <Grid item xs={6} lg={6} className='commerceLogo'>
                                <a target="_blank" href="https://www.commerce.wa.gov/building-infrastructure/housing/" title="Department of Commerce - Housing Trust Fund" rel="noopener noreferrer">
                                    <img src={commerce_logo} alt="Department of Commerce - Housing Trust Fund" className="soh" />
                                </a>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="space-between" className="h-100 login_block_container">
                            {/* <Grid item> */}
                            {/* <Grid container direction="row" >
                                <Grid item >
                                    <a target="_blank" href="http://www.wshfc.org/managers/" title="WSHFC - Compliance & Preservation Division" rel="noopener noreferrer">
                                        <img src={Housingfinancecommission} alt="WSHFC - Compliance & Preservation Division" className="hfc" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="https://www.commerce.wa.gov/building-infrastructure/housing/" title="Department of Commerce - Housing Trust Fund" rel="noopener noreferrer">
                                        <img src={Stateofwashington} alt="Department of Commerce - Housing Trust Fund" className="soh" />
                                    </a>
                                </Grid>
                            </Grid> */}   
                            {/* </Grid> */}
                            <Grid item className="login_form" >
                                <ValidatorForm
                                    name="logInForm"
                                    ref="form"
                                    autoComplete="off"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => console.log(errors)}
                                >
                                    <TextBox
                                        id="name"
                                        label="User Name"
                                        margin="normal"
                                        className="login_ip_field"
                                        name="username"
                                        value={username}
                                        handleChange={this.handleChange}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                    <TextBox
                                        id="password"
                                        type="password"
                                        label="Password"
                                        margin="normal"
                                        className="login_ip_field"
                                        name="password"
                                        value={password}
                                        handleChange={this.handleChange}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                    <Button type="submit" variant="contained" color="primary" className="login_ip_field m-t-30" > Login </Button>
                                    <Button color="default" className="login_ip_field m-t-20 " component={Link} to="/forgot-password"> Forgot Password </Button>
                                    {/*<Link to="/forgot-password" className="login_ip_field m-t-20">Forgot Password</Link>*/}
                                </ValidatorForm>
                            </Grid>
                            <Grid item className="loginbottomcounties">
                                <Grid container direction="row"  >
                                    <Grid item>
                                        <a target="_blank" href="http://www.seattle.gov/housing/property-managers" title="Seattle Office of Housing" rel="noopener noreferrer">
                                            <img src={Seattle} alt="Seattle Office of Housing" />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a target="_blank" href="https://www.kingcounty.gov/depts/community-human-services/housing.aspx" title="King County" rel="noopener noreferrer">
                                            <img src={Kingcounty} alt="King County" />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a target="_blank" href="https://my.spokanecity.org/chhs/programs/" title="Spokane Human Services" rel="noopener noreferrer">
                                            <img src={Cityofspokane} alt="Spokane Human Services" />
                                        </a>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row">
                                    <Grid item>
                                        <a target="_blank" href="http://www.cityoftacoma.org/cms/one.aspx?objectId=22068" title="City of Tacoma" rel="noopener noreferrer">
                                            <img src={Cityoftacoma} alt="City of Tacoma" />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a target="_blank" href="https://snohomishcountywa.gov/429/Housing-and-Community-Services" title="Snohomish County" rel="noopener noreferrer">
                                            <img src={Snohomish} alt="Snohomish County" />
                                        </a>
                                    </Grid>
                                    <Grid item>
                                        <a href="https://www.cob.org/services/housing" title="City of Bellingham" rel="noopener noreferrer" target="_blank">
                                            <img src={Cityofbellingham} alt="City of Bellingham" />
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    !user.tos ? (
                        <AlertDialogFullScreen
                            title="Terms and Conditions"
                            open={user.loggedIn && !user.tos}
                            saveText="I Agree"
                            fullScreen={false}
                            page={'login'}
                            onSave={() => {
                                this.props.acceptTOS();
                            }}
                            onClose={() => {
                                this.props.logout();
                            }}
                        >
                            <div style={{ padding: "12px", fontSize: "16px" }}>
                                <p>
                                    <b>WBARS Terms of Use</b>
                                    These terms of use (the <b>"Terms"</b>) apply to use of the Combined Funders Web-Based Annual Reporting
                                    System (<b>"WBARS"</b>). Your access to and use of WBARS is conditioned on your agreement to comply
                                    with the Terms. If you do not agree to comply with the Terms, you are not authorized to access or use
                                    WBARS.
                                </p>
                                <p>
                                    <b>General</b>. WBARS is an online system operated on behalf of the Washington State Housing Finance
                                    Commission and the Washington Department of Commerce that enables public funders, property
                                    owners and property managers to enter project-related data and maintain and view information
                                    related to compliance and asset management. It is intended to facilitate storage and compilation of
                                    this information, and its design reflects reporting and other legal requirements that apply to
                                    participating agencies with respect to such information.
                                </p>
                                <p>
                                    <b>Authorized Use of WBARS</b>. You may access and use WBARS (including to upload, view, modify or
                                    download any information in WBARS) only as necessary to perform your job responsibilities for an
                                    entity participating in WBARS and not for any other purpose. In addition, you may access and use
                                    WBARS only in ways that are consistent with the role corresponding to your account credentials, and
                                    in accordance with the documentation made available to you concerning use of WBARS.
                                </p>
                                <p>
                                    <b>Account Credentials and WBARS Roles</b>. Users who need to enter and/or use information in WBARS
                                    for compliance or asset management purposes as part of their job responsibilities may be issued
                                    account credentials by the WBARS Organization Administrator for their organization. WBARS account
                                    credentials are specific to the individual authorized user’s assigned role. The role of an Organization
                                    Administrator includes generating account credentials and managing WBARS permissions for
                                    personnel in their organization. Other roles supported by WBARS are On-Site Manager, Property
                                    Manager, Owner, and Funder. For more information on how roles are used in WBARS, please
                                    see http://www.wshfc.org/managers/wbars.htm.
                                </p>
                                <p>
                                    <b>Security</b>. You are responsible for keeping your WBARS account credentials (both username and
                                    password) confidential and secure. WBARS account credentials are only for your individual use to
                                    perform your job responsibilities, and you agree not to share them with anyone. Notify your agency’s
                                    Organization Administrator or WBARS general support (via email at wbars@wshfc.org) immediately if
                                    you believe someone else may be using your account credentials, or if they are lost or stolen.
                                </p>
                                <p>
                                    <b>Malware.</b> You agree to use good faith, reasonable efforts to ensure that you do not expose WBARS or
                                    any equipment or software used to access, host or operate WBARS,to any spyware, malware, virus,
                                    worm, Trojan horse, or other malicious or harmful code.
                                </p>
                                <p>
                                    <b>Confidentiality.</b> You must take all reasonable steps to ensure the confidentiality of information in
                                    WBARS, including by logging off of WBARS before leaving your work area and ensuring that any
                                    computer screen that shows data from WBARS is not easily viewed by others. You may not disclose
                                    information accessed by you in WBARS for any purpose other than as necessary to perform your job
                                    responsibilities.
                                </p>
                                <p>
                                    <b>Data Usage.</b> The information that you or other users enter, store or download in or from WBARS is
                                    subject to all applicable laws and regulations. It may be used and disclosed only in accordance with
                                    such laws and regulations. Subject to the foregoing, you acknowledge that information you enter or
                                    store in WBARS may be viewed and used as necessary to carry out asset management and compliance
                                    functions, administer WBARS, as otherwise necessary to comply with all applicable laws and
                                    regulations, and (in aggregated forms) for activities related to public policy or analysis.
                                </p>
                                <p>
                                    <b>Term and Termination.</b> These Terms apply to you for as long as you access or use WBARS. Your
                                    right to access and use WBARS may be terminated or suspended at any time if you violate these
                                    Terms, or when you no longer need access to perform your job responsibilities.
                                </p>
                                <p>
                                    <b>Disclaimers.</b> WBARS and all software, functionality, data and content made available through or in
                                    connection with WBARS ARE PROVIDED "AS-IS" AND "WITH ALL FAULTS." ALL REPRESENTATIONS,
                                    WARRANTIES AND COVENANTS ARE HEREBY DISCLAIMED IN CONNECTION WITH YOUR ACCESS TO
                                    AND USE OF WBARS (INCLUDING THAT ANY FUNCTIONALITY OR INFORMATION IN WBARS WILL
                                    MEET YOUR REQUIREMENTS OR BE AVAILABLE, TIMELY, SECURE, ERROR FREE OR FREE FROM
                                    SERVICE INTERRUPTIONS OR SYSTEM FAILURES OR OTHER ISSUES THAT MAY DAMAGE OR DESTROY
                                    DATA ENTERED OR STORED IN WBARS).
                                </p>
                                <p>
                                    <b>Modifications to WBARS or these Terms.</b> WBARS may be revised from time to time, and there is
                                    no assurance it will retain any feature or functionality that you may have previously used. These
                                    Terms may also be updated from time to time and you should review all communications (in email, on
                                    the WBARS site and otherwise) regarding such updates. Any changes to these Terms will be effective,
                                    and you are responsible for complying with them, when an updated version is posted on WBARS (or at
                                    a later time if specified in such an update).
                                </p>
                            </div>
                        </AlertDialogFullScreen>
                    ) : null
                }
                {/* </Grid> */}
            </React.Fragment>
        );
    }
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        auth: state.authentication,
        maintenance: state.maintenance.data,
        bannerContent: state.configuration.latestBanner,
        environment: state.users.environmentDate
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        login: (username, password) => dispatch(userActions.login(username, password)),
        logout: () => dispatch(userActions.logout()),
        acceptTOS: () => dispatch(userActions.acceptTOS()),
        maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        getBanner: (bannerText) => dispatch(confActions.getBanner(bannerText)),
        getRestoreTime: (environment) => dispatch(restoreTimeActions.getRestoreTime(environment))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(loginStyle)(Login));
