import { confConstants } from "../constants";
import { configurationConstants } from "../helpers/appConstants";

const initialState = {
    fieldFilter: {},
    configurations: {
        [configurationConstants.bedrooms]: [],
        [configurationConstants.cities]: [],
        [configurationConstants.counties]: [],
        [configurationConstants.expenseCategory]: [],
        [configurationConstants.homeUnitOccupancy]: [],
        [configurationConstants.lenders]: [],
        [configurationConstants.race]: [],
        [configurationConstants.setAsideType]: [],
        [configurationConstants.specialNeedOption]: [],
        [configurationConstants.state]: [],
        [configurationConstants.subsidyType]: [],
        [configurationConstants.utilityAllowanceSource]: [],
        [configurationConstants.utilityAllowanceType]: [],
        [configurationConstants.otherIncome]: [],
        [configurationConstants.unitDesignation]: [],
        [configurationConstants.homeHouseHoldType]: [],
        [configurationConstants.rentLimitIncomeLimitType]: [],
        [configurationConstants.householdMemberRace]: [],
        [configurationConstants.MoveOutReasonExpiryYear]:[]
    },
    FunderReportTable: [],
    latestBanner: {}
}

// action constants
const fieldFilter = confConstants.fieldFilter;

export function configuration(state = initialState, action) {
    switch (action.type) {
        case fieldFilter.ON_CHANGE:
            return {
                ...state,
                fieldFilter: {
                    ...state.fieldFilter,
                    [action.table]: {
                        ...state.fieldFilter[action.table],
                        columnsMap: state.fieldFilter[action.table].columnsMap,
                        visibleColumns: action.visibleColumns ? [...action.visibleColumns] : [],
                        isLoading: false
                    }
                }
            }

        case fieldFilter.GET_FIELDS_REQUEST:
        case fieldFilter.PUT_FIELDS_REQUEST:
            return {
                ...state,
                fieldFilter: {
                    // maintain previous field filter table datas
                    ...state.fieldFilter,
                    [action.table]: {
                        ...state.fieldFilter[action.table],
                        isLoading: true
                    }
                }
            }

        case fieldFilter.GET_FIELDS_SUCCESS:
        case fieldFilter.PUT_FIELDS_SUCCESS:
            return {
                ...state,
                fieldFilter: {
                    // maintain previous field filter table datas
                    ...state.fieldFilter,
                    [action.table]: {
                        ...action.fieldFilter.data,
                        isLoading: false
                    }
                }
            }

        case fieldFilter.GET_FIELDS_FAILURE:
        case fieldFilter.PUT_FIELDS_FAILURE:
            return {
                ...state,
                fieldFilter: {
                    // maintain previous field filter table datas
                    ...state.fieldFilter,
                    [action.table]: {
                        ...state.fieldFilter[action.table],
                        error: action.error,
                        isLoading: false
                    }
                }
            }
        case confConstants.GETONE_REQUEST:
        case confConstants.GETONE_REQUEST_CONTRACTACTIVITY:
            return {
                ...state,
                isLoading: true,
            };
        case confConstants.GETONE_SUCCESS:
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    [action.name]: action.data
                },
                isLoading: false
            };
        case confConstants.GETONE_SUCCESS_CONTRACTACTIVITY:
            return {
                ...state,
                configurations: {
                ...state.configurations,
                [action.name]: action.data
                },
                };
        case confConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };

        case confConstants.GETONE_FAILURE_CONTRACTACTIVITY:
                return {
                    ...state,
                    error: action.error,
                    //isLoading: false,
                    };
        case confConstants.GET_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case confConstants.GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                configurations: {
                    ...state.configurations,
                    [action.name]: action.data
                },

            };
        case confConstants.GET_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };

        case confConstants.DELETE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case confConstants.DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case confConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };

        case confConstants.UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case confConstants.UPDATE_SUCCESS:
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    [action.name]: action.data
                },
                isLoading: false
            };
        case confConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case confConstants.GET_FUNDER_REPORT_TABLE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case confConstants.GET_FUNDER_REPORT_TABLE_SUCCESS:
            return {
                ...state,
                FunderReportTable: {
                    ...action.data
                },
                isLoading: false
            }
        case confConstants.GET_FUNDER_REPORT_TABLE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case confConstants.FUNDER_REPORT_TABLE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case confConstants.FUNDER_REPORT_TABLE_SUCCESS:
            return {
                ...state,
                FunderReportTable: {
                    ...action.data
                },
                isLoading: false
            }
        case confConstants.FUNDER_REPORT_TABLE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
            case confConstants.GET_LATEST_BANNER_REQUEST:
            return {
                ...state,
                error: action.error,
                isLoading: true
            }
        case confConstants.GET_LATEST_BANNER_SUCCESS:
            return {
                ...state,
                latestBanner: action.data,
                isLoading: false
            }
        case confConstants.GET_LATEST_BANNER_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case confConstants.GET_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST:
        case confConstants.UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST:
        case confConstants.SHOW_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case confConstants.GET_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS:
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    ['MoveOutReasonExpiryYear']: action.data
                },
                isLoading: false
            };
        case confConstants.UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS:
        case confConstants.HIDE_LOADING:
            return {
                ...state,
                configurations: {
                    ...state.configurations,
                    ['MoveOutReasonExpiryYear']: action.year
                },
                isLoading:false
            }
        case confConstants.GET_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE:
        case confConstants.UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            }
        default: return state;
    }
}