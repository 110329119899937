import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from '../helpers';

export const dashboardService = {
    getReportStatus,
    getProjectSummary,
    getComplianceIssue,
    getComplianceIssueSummary,
    downloadProjectSummaryReport,
    getProjectList,
    getPMEffectiveDate,
    getOccupancyRate,
    getPMProjectList,
    getFunderReports,
    viewProjectSummaryReport,
    getPSBUCounts,
    getUsersSummary,
    downloadDashboardReport,
    getProjectsList
};

/**
 *Dashnoard Needs Action Section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
function getProjectList(skip, limit, orderBy, order, sfields, sValues) {

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch: false
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/dashboard/projectlist?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 *PM dashboard effective date section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
function getPMEffectiveDate(skip, limit, orderBy, order, sfields, sValues) {

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch: false
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/dashboard/PMeffectivedate?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 *PM Dashboard Needs Action Section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
function getPMProjectList(skip, limit, orderBy, order, sfields, sValues, selectedSites, reportYear, userRole, org_id) {

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch: false,
        reportYear: reportYear,
        selectedSites: selectedSites,
        userRole: userRole,
        org_id: org_id
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/dashboard/PMprojectlist?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 *Dropdown values in Dashboard
 *
 * @returns
 */
function getProjectSummary(limit, search) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/dashboard/project-summary?limit=${limit}&search=${search}`, requestOptions).then(handleResponse);
}

/**
 *Compliance issue in both dashboard section
 *
 * @param {*} year - selected year
 * @param {*} userRole - logged in user
 * @param {*} org_id - organization id
 * @returns
 */
function getComplianceIssue(year, userRole, org_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/dashboard/compliance/${year}/${userRole}/${org_id}`, requestOptions).then(handleResponse);
}

function getComplianceIssueSummary(year, userRole, org_id, selectedSites) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(selectedSites)
    };

    return fetch(`${globalVariable.apiURL}/dashboard/needsAction/${year}/${userRole}/${org_id}`, requestOptions).then(handleResponse);
}

/**
 *Occupancy Rate in PM dashboard section
 *
 * @param {*} year - selected year
 * @returns
 */
function getOccupancyRate(year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboard/occupancyrate/${year}`, requestOptions).then(handleResponse);
}

/**
 *Report Status in both dashboard section
 *
 * @param {*} year - selected year
 * @param {*} userRole - logged in user
 * @param {*} org_id - organization id
 * @returns
 */
function getReportStatus(year, userRole, org_id) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.ms_apiURL}/dashboard/reportStatus/${year}/${userRole}/${org_id}`, requestOptions).then(handleResponse);
}

/**
 * A dispatcher action method to call download pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function downloadProjectSummaryReport(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.ms_apiURL}/dashboard/download/project-summary/pdf`, requestOptions).then(handleDownloadResponse);
}

/**
 * A dispatcher action method to call view pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function viewProjectSummaryReport({ project_id, year }) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.ms_apiURL}/view/project-summary/${project_id}/${year}/html`, requestOptions).then(handleResponse);
}

/**
 * A dispatcher action method to call all the funder export excel
 *
 * @param {*} data contains excel report name and key
 */
function getFunderReports() {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.ms_apiURL}/list/funderexports`, requestOptions).then(handleResponse);
}

/**
 * A dispatcher action method to call PSBU Counts
 *
 */
function getPSBUCounts(userRole, reportYear) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboard/PSBUCounts/${userRole}/${reportYear}`, requestOptions).then(handleResponse);
}


/**
 * A dispatcher action method to call USERS SUMMARY 
 *
 */
function getUsersSummary(userRole) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboard/UsersSummary/${userRole}`, requestOptions).then(handleResponse);
}
/**
 * A dispatcher action method to call download pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function downloadDashboardReport(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.ms_apiURL}/dashboard-report`, requestOptions).then(handleDownloadResponse);
}

function getProjectsList(userRole, org_id, year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };

    return fetch(`${globalVariable.apiURL}/dashboard/projectList/${userRole}/${org_id}/${year}`, requestOptions).then(handleResponse);
}