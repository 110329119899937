import { FinancialActivityMessages, ProgramActivityMessages } from "./messages";

const ContractActivity = (data) => {

    let errors = [];
    let { programActivity, financialActivity, reportData, contractActivitySavedOnce } = data;
    if (programActivity && financialActivity && reportData && reportData.data && ((reportData.data.length > 0 && reportData.data.filter(elem => elem.isContractActivitySaved === true).length > 0) || contractActivitySavedOnce)) {
        let loanNumbers = [];
        let grantNumbers = [];
        let resaleRecordNumbers = [];
        let allRecordNumbers = [];
        let closedLoanNumbers = [];
        let closedGrantNumbers = [];
        let closedAllRecordNumbers = [];
        let openLoanNumbers = [];
        let PAWarning1 = [];
        let PAWarning2 = [];
        let PAWarning3 = [];
        let PAWarning4 = [];
        let PAWarning5 = [];
        let FAWarning1 = [];
        let FAWarning2 = [];
        let PA1 = true;
        let PA2 = true;
        let PA3 = true;
        let PA4 = true;
        let PA5 = true;
        let FA1 = true;
        let FA2 = true;
        // let FA3 = true;
        let errorLoan1 = [];
        let errorLoan2 = [];
        // let errorLoan3 = [];
        let errorLoan4 = [];
        let errorLoan5 = [];

        //financial activity error type 1
        let errorLoan6 = [];
        let errorLoan7 = [];
        let errorLoan8 = [];
        let errorLoan9 = [];

        //REMOVE migrated and DOP from contractactivitydata , programactivity and financial data
        let migratedLoanNumbers = reportData.data.filter(item=>item.isMigrated || new Date(item.dateOfPurchase).getFullYear()<=2009).map(item=>item.loanNumber);
        reportData.data = reportData.data.filter(item=>!item.isMigrated || new Date(item.dateOfPurchase).getFullYear()>2009);

        //program activity data
        //section 1
        let migratedLoansInProgramActivity = programActivity.loansGenerated.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansGenerated = programActivity.loansGenerated.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansGenerated = programActivity.numberOfLoansGenerated - migratedLoansInProgramActivity.length;

        //section 2
        let migratedLoansInLoansGrants = programActivity.loansGrants.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansGrants = programActivity.loansGrants.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansGrants = programActivity.numberOfLoansGrants - migratedLoansInLoansGrants.length;

        //section 3
        let migratedLoansinResaleLoans = programActivity.resaleLoans.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.resaleLoans = programActivity.resaleLoans.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfResales = programActivity.numberOfResales - migratedLoansinResaleLoans.length;

        //section 4
        let migratedLoansinLoansDiferred = programActivity.loansDeferred.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansDeferred = programActivity.loansDeferred.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansDeferred = programActivity.numberOfLoansDeferred - migratedLoansinLoansDiferred.length;

        //section 5
        let migratedLoansinLoansinDefault = programActivity.loansInDefault.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansInDefault = programActivity.loansInDefault.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansInDefault = programActivity.numberOfLoansInDefault - migratedLoansinLoansinDefault.length;

        //section 6
        let migratedLoansinForeClosuresLoans = programActivity.foreClosuresLoans.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.foreClosuresLoans = programActivity.foreClosuresLoans.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfForeclosuresLoans = programActivity.numberOfForeclosuresLoans - migratedLoansinForeClosuresLoans.length;

        //Financial activity data
        //section 4a
        let migratedLoansInHomeOwnersMadePayment = financialActivity.homeOwnersMadePayments?financialActivity.homeOwnersMadePayments.filter(item=>migratedLoanNumbers.includes(item)):[];
        financialActivity.homeOwnersMadePayments = financialActivity.homeOwnersMadePayments?financialActivity.homeOwnersMadePayments.filter(item=>!migratedLoanNumbers.includes(item)):[];
        financialActivity.numberOfHomeOwnersMadePayment = financialActivity.numberOfHomeOwnersMadePayment - migratedLoansInHomeOwnersMadePayment.length;

        //section 4b
        let migratedLoansInloansStatisfied = financialActivity.loansStatisfied?financialActivity.loansStatisfied.filter(item=>migratedLoanNumbers.includes(item)):[];
        financialActivity.loansStatisfied = financialActivity.loansStatisfied?financialActivity.loansStatisfied.filter(item=>!migratedLoanNumbers.includes(item)):[];
        financialActivity.numberOfLoansStatisfied = financialActivity.numberOfLoansStatisfied - migratedLoansInloansStatisfied.length;

        //section 5a
        let migratedLoansInloansBadDebtIncurred = financialActivity.loansBadDebtIncurred?financialActivity.loansBadDebtIncurred.filter(item=>migratedLoanNumbers.includes(item)):[];
        financialActivity.loansBadDebtIncurred = financialActivity.loansBadDebtIncurred?financialActivity.loansBadDebtIncurred.filter(item=>!migratedLoanNumbers.includes(item)):[];
        financialActivity.numberOfLoansBadDebtIncurred = financialActivity.numberOfLoansBadDebtIncurred - migratedLoansInloansBadDebtIncurred.length;

        //************************/

        let contractActivityData = reportData.data ? reportData.data : [];
        let closedResaleData = reportData.data ? reportData.data.filter(item => item.closedLoan === true &&item.typeHTFActivity!==null&& (item.typeHTFActivity === 4 || item.typeHTFActivity.key === 4)).map(item=> item.loanNumber) : [];
        let resaleLoans = contractActivityData ? contractActivityData.filter(item => item.typeHTFActivity!==null&&(item.typeHTFActivity.key === 4 || item.typeHTFActivity === 4)) : [];
        // let openLoan = reportData.data ? reportData.data.filter(item => item.closedLoan === true) : [];
        const loanData = contractActivityData ? contractActivityData.filter(item => (item.isLoan === 'Yes' || item.isLoan === true)) : [];
        const allRecordData = contractActivityData ? contractActivityData: [];
        const allLoanandGrants = contractActivityData ? contractActivityData.map(item => item.loanNumber) : [];
        const HTFClosedLoans = contractActivityData ? contractActivityData.filter(item => ((item.isHTFLoanStatisfied === false || item.isHTFLoanStatisfied === 2 || item.isHTFLoanStatisfied === 3))).map(loan => loan.loanNumber) : [];

        const grantData = contractActivityData ? contractActivityData.filter(item => item.isLoan === 'No' || item.isLoan === false) : [];
        const closedResaleLoans = contractActivityData ? contractActivityData.filter(item => item.typeHTFActivity!==null&&(item.typeHTFActivity === 4 || item.typeHTFActivity.key === 4)) : [];
        const propertyForeclosureLoans = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes')).map(loan => loan.loanNumber) : [];
        const propertyForeclosureCount = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes')) : [];
        const htfSatisfied = contractActivityData ? contractActivityData.filter(item => (item.isHTFLoanStatisfied === 1 || item.isHTFLoanStatisfied === 'Yes' || item.isHTFLoanStatisfied === true)) : [];

        const openLoan = contractActivityData ? contractActivityData: [];

        
        let closedLoan = [...propertyForeclosureCount, ...htfSatisfied];
        closedLoan = closedLoan.filter((elem, index, inputArray) => {
            return inputArray.findIndex(inputElem => inputElem._id === elem._id) === index;
        })
        //let openLoan = [...openPropertyForeclosureCount,...openHtfSatisfied];
        if (loanData.length > 0) {
            loanData.forEach(item => {
                loanNumbers.push(item.loanNumber)
            });
            loanNumbers = loanNumbers.length > 0 ? loanNumbers : [];
        }

        if (grantData.length > 0) {
            grantData.forEach(item => {
                grantNumbers.push(item.loanNumber)
            });
            grantNumbers = grantNumbers.length > 0 ? grantNumbers : [];
        }

        if(allRecordData.length > 0){
            allRecordData.forEach(item => {
                allRecordNumbers.push(item.loanNumber)
            });
            allRecordNumbers = allRecordNumbers.length > 0 ? allRecordNumbers : [];
        }

        if(resaleLoans.length > 0){
            resaleLoans.forEach(item=>{
                resaleRecordNumbers.push(item.loanNumber)
            })
            resaleRecordNumbers = resaleRecordNumbers.length > 0 ? resaleRecordNumbers : [];
        }



        if (closedLoan.length > 0) {
            closedLoan.forEach(item => {
                 if (item.isLoan === true || item.isLoan === 'Yes')
                    closedLoanNumbers.push(item.loanNumber);
                 else if (item.isLoan === false || item.isLoan === 'No')
                    closedGrantNumbers.push(item.loanNumber);
            });
        }

        if(closedLoan.length > 0){
            closedLoan.forEach(item=>{
                closedAllRecordNumbers.push(item.loanNumber)
            })
        }

        if (openLoan.length > 0) {
            openLoan.forEach(item => {
                //if (item.isLoan === true || item.isLoan === 'Yes')
                    openLoanNumbers.push(item.loanNumber);
            });
        }

        // error - 1
        if (loanData.length < programActivity.numberOfLoansGenerated) {
            errors.push({
                msg: ProgramActivityMessages.numberOfLoansGenerated,
                errorType: 2,
                reportActivity: 3,
                tab:'program',
                sectionIndex:1
            });
        }
        const loanNumberPA1 = programActivity.loansGenerated.length > 0 ? programActivity.loansGenerated : [];
        loanNumberPA1.forEach(item => {
            if (!loanNumbers.includes(item))
                errorLoan1.push(item);
        });
        if (errorLoan1.length > 0)
            errors.push({
                units:errorLoan1,
                msg: ProgramActivityMessages.loanNumber1,
                errorType: 1,
                reportActivity: 3,
                tab:'program',
                sectionIndex:1
            });

        // error - 2
        if (grantData.length < programActivity.numberOfLoansGrants) {
            errors.push({
                msg: ProgramActivityMessages.numberofGrantsReportingPeriod,
                errorType: 2,
                reportActivity: 3,
                tab:'program',
                sectionIndex:3
            });
        }
        const loanNumberPA2 = programActivity.loansGrants.length > 0 ? programActivity.loansGrants : [];
        loanNumberPA2.forEach(item => {
            if (!grantNumbers.includes(item))
                errorLoan2.push(item)
        });
            if (errorLoan2.length>0)
                errors.push({
                    units:errorLoan2,
                    msg: ProgramActivityMessages.loanNumber2,
                    errorType: 1,
                    reportActivity: 3,
                    tab:'program',
                    sectionIndex:2
                });

        // error - 3
        if (allRecordData.length < programActivity.numberOfResales) {
            errors.push({
                msg: ProgramActivityMessages.numberofResales,
                errorType: 2,
                reportActivity: 3,
                tab:'program',
                sectionIndex:5
            });
        }
        const loanNumberPA3 = programActivity.resaleLoans.length > 0 ? programActivity.resaleLoans : [];
        const errorLoan3 = [];
        loanNumberPA3.forEach(item => {
            if (!resaleRecordNumbers.includes(item))
                errorLoan3.push(item);
        });
        if (errorLoan3.length > 0)
            errors.push({
                units:errorLoan3,
                msg: ProgramActivityMessages.loanNumber3,
                errorType: 1,
                reportActivity: 3,
                tab:'program',
                sectionIndex:3
            });


        // error - 4
        // if (loanData.length < programActivity.numberOfLoansDeferred) {
        //     errors.push({
        //         msg: ContractActivityMessages.numberofDeferredLoansReportingPeriod,
        //         errorType: 2,
        //         reportActivity: 3
        //     });
        // }
        const loanNumberPA4 = programActivity.loansDeferred.length > 0 ? programActivity.loansDeferred : [];
        loanNumberPA4.forEach(item => {
            if (!allRecordNumbers.includes(item)){
                errorLoan4.push(item)
            }
        });

        if(errorLoan4.length>0){
            errors.push({
                units:errorLoan4,
                msg: ProgramActivityMessages.loanNumber4,
                errorType: 1,
                reportActivity: 3,
                tab:'program',
                sectionIndex:4
            });
        }

        // error - 5
        // if (loanData.length < programActivity.numberOfLoansInDefault) {
        //     errors.push({
        //         msg: ContractActivityMessages.numberofLoansDefaultReportingPeriod,
        //         errorType: 2,
        //         reportActivity: 3
        //     });
        // }
        const loanNumberPA5 = programActivity.loansInDefault.length > 0 ? programActivity.loansInDefault : [];
        loanNumberPA5.forEach(item => {
            if (!allRecordNumbers.includes(item)){
                errorLoan5.push(item)
            }
        });

        if(errorLoan5.length>0){
        errors.push({
            units:errorLoan5,
            msg: ProgramActivityMessages.loanNumber5,
            errorType: 1,
            reportActivity: 3,
            tab:'program',
            sectionIndex:5
        });
    }

        // error - 6
        if (loanData.length < programActivity.numberOfForeclosuresLoans) {
            errors.push({
                msg: ProgramActivityMessages.numberofClosuresReportingPeriod,
                errorType: 2,
                reportActivity: 3,
                tab:'program',
                sectionIndex:11
            });
        }
        const loanNumberPA6 = programActivity.foreClosuresLoans.length > 0 ? programActivity.foreClosuresLoans : [];

        loanNumberPA6.forEach(item => {
            if (!allRecordNumbers.includes(item)){
                errorLoan6.push(item)
            }  
        });

        if(errorLoan6.length>0){
        errors.push({
            units:errorLoan6,
            msg: ProgramActivityMessages.loanNumber6,
            errorType: 1,
            reportActivity: 3,
            tab:'program',
            sectionIndex:12
        });
    }

        // closedLoanNumbers.forEach(item => {
        //     if (loanNumberPA1.indexOf(item) > -1 && PA1) {
        //         // PA1 = false;
        //         PAWarning1.push(item);
        //     }
        // });

        closedAllRecordNumbers.forEach(item=>{
            if (loanNumberPA3.indexOf(item) > -1 && PA3) {
                // PA3 = false;
                PAWarning3.push(item);
            }
            // if (loanNumberPA4.indexOf(item) > -1 && PA4) {
            //     // PA4 = false;
            //     PAWarning4.push(item);
            // }
            // if (loanNumberPA5.indexOf(item) > -1 && PA5) {
            //     // PA5 = false;
            //     PAWarning5.push(item);
            // }
        })
        
        if (PAWarning1.length > 0){
            errors.push({
                units: PAWarning1,
                sectionIndex: 2,
                msg: ProgramActivityMessages.foreClosure1,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });

            errors.push({
                units: PAWarning1,
                sectionIndex: 2,
                msg: ProgramActivityMessages.foreClosure1,
                errorType: 2,
                reportActivity: 3,
                programActivity: programActivity._id,
                tab:'program'
            });
        }
        // if (PAWarning3.length > 0)
        //     errors.push({
        //         units: PAWarning3,
        //         sectionIndex: 6,
        //         msg: ProgramActivityMessages.foreClosure3,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        if (PAWarning4.length > 0){
            errors.push({
                units: PAWarning4,
                sectionIndex: 8,
                msg: ProgramActivityMessages.foreClosure4,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });

            errors.push({
                units: PAWarning4,
                sectionIndex: 8,
                msg: ProgramActivityMessages.foreClosure4,
                errorType: 2,
                reportActivity: 3,
                programActivity: programActivity._id,
                tab:'program'
            });
        }
        // if (PAWarning5.length > 0)
        //     errors.push({
        //         units: PAWarning5,
        //         sectionIndex: 10,
        //         msg: ProgramActivityMessages.foreClosure5,
        //         errorType: 2,
        //         reportActivity: 3,
        //         programActivity: programActivity._id,
        //         tab:'program'
        //     });

        //Foreclosure

        let notForeclosureLoans = []
        programActivity.foreClosuresLoans.forEach(item => {
            if (propertyForeclosureLoans.indexOf(item) === -1) {
                notForeclosureLoans.push(item)
            }
        })
        let isForeclosedGrant = []
        grantData.forEach(item => {
            if (notForeclosureLoans.indexOf(item.loanNumber) > -1) {
                isForeclosedGrant.push(item)
            }
        })

        let isNotLoanorGrant = []
        programActivity.foreClosuresLoans.forEach(item => {
            if (allLoanandGrants.indexOf(item) === -1) {
                isNotLoanorGrant.push(item)
            }
        })


        if (notForeclosureLoans.length > 0) {
            errors.push({
                units: notForeclosureLoans,
                sectionIndex: 12,
                msg: ProgramActivityMessages.foreClosure6,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });

            errors.push({
                units: notForeclosureLoans,
                sectionIndex: 12,
                msg: ProgramActivityMessages.foreClosure6,
                errorType: 2,
                reportActivity: 3,
                programActivity: programActivity._id,
                tab:'program'
            });
        }

        closedGrantNumbers.forEach(item => {
            if (loanNumberPA2.indexOf(item) > -1 && PA2) {
                // PA2 = false;
                PAWarning2.push(item);
            }
        });
        if (PAWarning2.length > 0){
            //Removed based on bug WBAR-2863
            // errors.push({
            //     units: PAWarning2,
            //     sectionIndex: 4,
            //     msg: ProgramActivityMessages.foreClosure2,
            //     errorType: 2,
            //     reportActivity: 1,
            //     programActivity: programActivity._id
            // });

            // errors.push({
            //     units: PAWarning2,
            //     sectionIndex: 4,
            //     msg: ProgramActivityMessages.foreClosure2,
            //     errorType: 2,
            //     reportActivity: 3,
            //     programActivity: programActivity._id,
            //     tab:'program'
            // });
        }

        if (loanData.length < programActivity.numberOfLoansGenerated && programActivity.numberOfLoansGenerated !== 0) {
            // errors.push({
            //     sectionIndex: 1,
            //     msg: ProgramActivityMessages.loanNumberMatch1,
            //     errorType: 2,
            //     reportActivity: 1,
            //     programActivity: programActivity._id
            // });

            errors.push({
                sectionIndex: 1,
                msg: ProgramActivityMessages.loanNumberMatch1,
                errorType: 2,
                reportActivity: 3,
                programActivity: programActivity._id,
                tab:'program'
            });
        }
        if (grantData.length < programActivity.numberOfLoansGrants && programActivity.numberOfLoansGrants !== 0) {
            errors.push({
                sectionIndex: 3,
                msg: ProgramActivityMessages.loanNumberMatch2,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });

            errors.push({
                sectionIndex: 3,
                msg: ProgramActivityMessages.loanNumberMatch2,
                errorType: 2,
                reportActivity: 3,
                programActivity: programActivity._id,
                tab:'program'
            });
        }
        //closedResaleLoans.length < programActivity.numberOfResales && programActivity.numberOfResales !== 0
        if (closedResaleLoans.length < programActivity.numberOfResales && programActivity.numberOfResales !== 0) {
            errors.push({
                msg: ProgramActivityMessages.loanNumberMatch3,
                sectionIndex: 5,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });

            errors.push({
                msg: ProgramActivityMessages.loanNumberMatch3,
                sectionIndex: 5,
                errorType: 2,
                reportActivity: 3,
                programActivity: programActivity._id,
                tab:'program'
            });
        }
        // if (loanData.length !== programActivity.numberOfLoansDeferred) {
        //     errors.push({
        //         msg: ProgramActivityMessages.loanNumberMatch3,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }

        // if (loanData.length !== programActivity.numberOfLoansInDefault) {
        //     errors.push({
        //         msg: ProgramActivityMessages.loanNumberMatch4,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }

        // if (loanData.length !== programActivity.numberOfForeclosuresLoans) {
        //     errors.push({
        //         msg: ProgramActivityMessages.loanNumberMatch5,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }

        //if (financialActivity) {
            // error - 1
            // if (allRecordData.length < financialActivity.numberOfHomeOwnersMadePayment) {
            //     errors.push({
            //         msg: ContractActivityMessages.numberOfHomeOwnersMadePayment,
            //         errorType: 2,
            //         reportActivity: 3
            //     });
            // }
            const loanNumberFA1 = financialActivity.homeOwnersMadePayments ? financialActivity.homeOwnersMadePayments : [];

            loanNumberFA1.forEach(item => {
                if (!allRecordNumbers.includes(item)){
                    errorLoan7.push(item)
                }     
            });

            if(errorLoan7.length>0){
            errors.push({
                units: errorLoan7,
                msg: FinancialActivityMessages.loanNumber4a,
                errorType: 1,
                reportActivity: 3,
                sectionIndex: 2,
                tab: 'finance'
            });
        }

            // error - 2
            // if (allRecordData.length < financialActivity.numberOfLoansStatisfied) {
            //     errors.push({
            //         msg: ContractActivityMessages.numberOfLoansStatisfied,
            //         errorType: 2,
            //         reportActivity: 3
            //     });
            // }
            const loanNumberFA2 = financialActivity.loansStatisfied ? financialActivity.loansStatisfied : [];
            
            loanNumberFA2.forEach(item => {
                if (!allRecordNumbers.includes(item)){
                    errorLoan8.push(item)
                }
            });

            if(errorLoan8.length>0){
            errors.push({
                units: errorLoan8,
                msg: FinancialActivityMessages.loanNumber4b,
                errorType: 1,
                reportActivity: 3,
                sectionIndex: 4,
                tab: 'finance'
            });
        }

            // error - 3
            // if (allRecordData.length < financialActivity.numberOfLoansBadDebtIncurred) {
            //     errors.push({
            //         msg: ContractActivityMessages.numberOfLoansBadDebtIncurred,
            //         errorType: 2,
            //         reportActivity: 3
            //     });
            // }
            const loanNumberFA3 = financialActivity.loansBadDebtIncurred ? financialActivity.loansBadDebtIncurred : [];

            loanNumberFA3.forEach(item => {
                if (!allRecordNumbers.includes(item)){
                    errorLoan9.push(item)
                }
            });

            if(errorLoan9.length>0){
            errors.push({
                units: errorLoan9,
                msg: FinancialActivityMessages.loanNumber5a,
                errorType: 1,
                reportActivity: 3,
                sectionIndex: 6,
                tab:'finance'
            });
        }

            //foreclosure
            closedAllRecordNumbers.forEach(item => {
                // if (loanNumberFA1.indexOf(item) > -1 && FA1) {
                //     // FA1 = false;
                //     FAWarning1.push(item)
                // }
            });

            if (FAWarning1.length > 0)
                errors.push({
                    units: FAWarning1,
                    sectionIndex: 2,
                    msg: FinancialActivityMessages.foreClosure1,
                    errorType: 2,
                    reportActivity: 2,
                    financialActivity: financialActivity._id,
                    tab:'finance'
                });

            //updated on 18/10 considers only HTF -4b financial activity
            HTFClosedLoans.forEach(item => {
                if (loanNumberFA2.indexOf(item) > -1 && FA2) {
                    // FA2 = false;
                    FAWarning2.push(item)
                }
            })

            if (FAWarning2.length > 0)
                errors.push({
                    units: FAWarning2,
                    sectionIndex: 4,
                    msg: FinancialActivityMessages.foreClosure2,
                    errorType: 2,
                    reportActivity: 3,
                    financialActivity: financialActivity._id,
                    tab:'finance'
                });

            if (loanData.length < financialActivity.numberOfHomeOwnersMadePayment) {
                errors.push({
                    msg: FinancialActivityMessages.numberOfHomeOwnersMadePayment,
                    errorType: 2,
                    reportActivity: 3,
                    financialActivity: financialActivity._id,
                    sectionIndex: 1,
                    tab:'finance'
                });
            }

            // if (loanData.length !== financialActivity.numberOfLoansStatisfied) {
            //     errors.push({
            //         msg: FinancialActivityMessages.loanNumberMatch4b,
            //         errorType: 2,
            //         reportActivity: 2,
            //         financialActivity: financialActivity._id
            //     });
            // }

            // if (loanData.length !== financialActivity.numberOfLoansBadDebtIncurred) {
            //     errors.push({
            //         msg: FinancialActivityMessages.loanNumberMatch5a,
            //         errorType: 2,
            //         reportActivity: 2,
            //         financialActivity: financialActivity._id
            //     });
            // }
        //}
    }

    return errors;
}

export default ContractActivity;