import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';
import { Button, ButtonGroup, IconButton, Paper } from '@material-ui/core';

// Components
import ToolTip from '../ToolTip/ToolTip';
import DeleteDialog from '../DeleteDialog/DeleteDialog';

// SVG Icons
import { DeleteIcon } from '../SvgIcons/SvgIcons';

// Map actions Buttons
const actionsMap = {
    DELETE: {
        title: 'Delete',
        className: 'delete_icon',
        iClass: <DeleteIcon/>
    }
};

/**
 *QueryBuilder Component
 *
 * @class QueryBuilder
 * @extends {Component}
 */
class QueryBuilder extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            filterType: 'and',
            open: false,
            deleteItem: null
        }
    }
    /**
     * Handle TextField Events
     * @param {*} event 
     */
    handleChange = event => {
        this.props.handleChange(this.props.name, event.target.value)
    };
    /**
     * Get components for card
     * @param {*} module 
     * @param {*} item 
     * @param {*} index 
     * @returns 
     */
    getModules = (module, item, index) => {
        const { componentMap, config, cellComponentOnChange } = this.props;
        let ComponentName = componentMap[module];
        let placeholder = module[0].toUpperCase() + module.slice(1);;
        let gridSize = 2
        let isDisabled = false;
        switch (module) {
            case 'module':
                gridSize = 2
                break;
            case 'attributes':
                gridSize = 3
                break;
            case 'condition':
                gridSize = 3
                if (Object.keys(item['attributes']).length === 0) {
                    isDisabled = true;
                }
                break;
            case 'value':
                gridSize = 3
                if (!item.condition) {
                    isDisabled = true;
                }
                break;
            case 'by':
                if (!item.condition || (item.condition === 'average' && item.condition === 'percentage' && item.condition === 'count')) {
                    isDisabled = true;
                }
                break
            default:
                gridSize = 3
                break;
        }
        return (
            <Grid item sm={gridSize}>
                <ComponentName
                    fieldName={module}
                    data={item}
                    rowIndex={index}
                    config={config}
                    onChange={cellComponentOnChange}
                    disabled={isDisabled}
                    placeholder={placeholder}
                />
            </Grid>
        )
    }
    /**
     * Handle Delete Click Open
     * @param {*} item 
     * @param {*} rowIndex 
     */
    handleDeleteClickOpen = (item, rowIndex) => {
        this.setState({
            open: true,
            deleteItem: { ...item, rowIndex }
        });
    }
    /**
     * render html
     */
    render() {
        const { componentMap, rowDatas, filterType } = this.props;
        let andButtonVariant = 'outlined';
        let orButtonVariant = 'outlined';
        if (filterType === 'and') {
            andButtonVariant = 'contained'
            orButtonVariant = 'outlined'
        } else {
            orButtonVariant = 'contained'
            andButtonVariant = 'outlined'
        }
        let componentMapFields = [];
        if (Object.keys(componentMap).length > 0) {
            componentMapFields = Object.keys(componentMap);
        }
        const { className, iClass } = actionsMap['DELETE'];
        return (
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                        <Button style={{ minHeight: '36px' }} color="primary" variant={andButtonVariant} onClick={() => { this.props.handleFilterTypeChange('and') }}>AND</Button>
                        <Button style={{ minHeight: '36px' }} color="primary" variant={orButtonVariant} onClick={() => { this.props.handleFilterTypeChange('or') }}>OR</Button>
                    </ButtonGroup>
                </Grid>
                <Grid item sm={12} className="p-t-b-12">
                    <Grid container spacing={2}>
                        {
                            rowDatas.length > 0 && rowDatas.map((item, index) => {
                                return (
                                    <Grid item sm={12} className="p-t-b-12">
                                        <Paper variant="outlined" square>
                                            <Grid container spacing={2} className='alignItemsCenter p-l-r-16'>
                                                {
                                                    componentMapFields.map(module => {
                                                        return (this.getModules(module, item, index))
                                                    })
                                                }
                                                < Grid item sm={1} >
                                                    <ToolTip title={'Delete'} placement="bottom" key={index}>
                                                        <IconButton
                                                            className={className}
                                                            color="primary"
                                                            aria-label='Delete'
                                                            onClick={() => this.handleDeleteClickOpen(item, index)}
                                                        >
                                                            {iClass}
                                                        </IconButton>
                                                    </ToolTip>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                <DeleteDialog
                    open={this.state.open}
                    content={"Are you sure you want to delete this record?"}
                    handleDeleteAction={() => {
                        this.props.onDelete(this.state.deleteItem);
                        this.setState({
                            open: false,
                            deleteItem: null
                        })
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({
                            open: false,
                            deleteItem: null
                        })
                    }}
                />
            </Grid >
        );
    }
}
//Default props
QueryBuilder.defaultProps = {
    name: '',
    value: '',
    label: "",
    className: '',
    errorMessages: [],
    validators: [],
    disabled: false,
    margin: "dense",
    items: [],
    rowDatas: [],
    /**handleOnChange -a method to handle changes as a user adds input to the text box */
    handleChange: () => { },
    fielderrors: [],
    warningfieldname: '',
}
//prop types
QueryBuilder.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    items: PropTypes.any.isRequired,
    handleChange: PropTypes.func.isRequired
};
//Export Component
export default QueryBuilder;
