import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Material UI
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

//import Component
import FileUpload from "../../../../components/FileUpload/FileUpload";
import SaveCancel from "../../../../components/SaveCancel/SaveCancel";
import DeleteDialog from "../../../../components/DeleteDialog/DeleteDialog";
import ToolTip from "../../../../components/ToolTip/ToolTip";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import {DeleteIcon} from "../../../../components/SvgIcons/SvgIcons";


// Actions
import { uploadActions } from "../../../../actions/uploads";
import {alertActions} from "../../../../actions";

// Import Helpers
import { getUserOrgId } from "../../../../helpers";
import ValidatorForm from "../../../../helpers/fieldValidations";
import ProcessBar from "../../../../components/ProcessBar/ProcessBar";
import { history } from "../../../../helpers";
import Option from "./Option";
import { Success } from "./Success";
import { ErrorLog } from "./ErrorLog";
import { SuccessLog } from "./SuccessLog";

/**
 * Validate Container
 * @class Validate
 * @extends {Component}
 */
class Validate extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      bulkAction: "new",
      isDisable: false,
      uploadedFile: null,
      deleteDialog: false,
      fileSize:0
    };
  }
  /**
   * Component will receive props
   * @param {*} newProps
   */
  componentWillReceiveProps = (newProps) => {
    // console.log(this.props, newProps);
    this.setState({ isDisable: newProps.isLoading });

  };
  /**
   * Handle file upload
   * @param {*} acceptedFiles
   * @param {*} rejectedFiles
   */
  handleFileUpload = (acceptedFiles, rejectedFiles) => {
    console.log(acceptedFiles,rejectedFiles)
    if (rejectedFiles.length == 0 && acceptedFiles.length == 1) {
      const data = new FormData();
      const file = acceptedFiles[0];
      // console.log(file)
      data.append("file", file);
      data.append("name", file.name);
      this.setState({
        showError: false,
        fileTypeError: null,
        imageData: data,
        uploadedFile: file,
        fileSize:file.size/1000
      });
      this.props.onUpload(data,this.state.fileSize,file.name,this.state.uploadedFile);
    } else {
      console.log('not accepted')
      this.props.onError("The file format is invalid, Please upload the file in the “.xlsx” format")

      // this.setState({
      //   showError: true,
      //   fileTypeError:
      //     "Invalid File. Please upload a File with extension excel",
      // });
    }
  };
  /**
   * handleDeleteClick
   */
  handleDeleteClick = () => {
    this.setState({ deleteDialog: true });
  };
  /**
   * handleDelete
   */
  handleDelete = () => {
    this.setState({ imageData: null, uploadedFile: null, deleteDialog: false });
  };
  /**
   * handleTableDeleteOnCloseAction
   */
  handleTableDeleteOnCloseAction = () => {
    this.setState({ deleteDialog: false });
  };

  render() {
    const { isDisable, uploadedFile, bulkAction } = this.state;
    return (
      <ValidatorForm
        name="RentIncomeLimitForm"
        ref="form"
        autoComplete="off"
        // onSubmit={this.handleSubmit}
        onError={(errors) => {}}
      >
        <div
          style={{
            marginTop: "15px",
            backgroundColor: "#ffff",
            padding: "30px",
            borderRadius: "7px",
            height: "450px",
          }}
        >
          {uploadedFile ? (
            <Grid item xs={12}>
              <div
                className="uploadfile bolderText"
                style={{ height: "400px", padding: "135px" }}
              >
                {uploadedFile.name}
                <ToolTip title="Remove" placement="bottom">
                  <IconButton
                    className="delete_icon"
                    color="primary"
                    aria-label="Delete"
                    onClick={() => this.handleDeleteClick()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ToolTip>
              </div>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <div className="uploadfile " style={{ height: "400px" }}>
                <FileUpload
                  value={"Upload File"}
                  accept={[".xlsx"]}
                  content={<p style={{fontWeight:'medium'}}>Drag and Drop your template here or you can <b style={{color:'#05c1fa',textDecoration:'underline'}}>browse</b></p>}
                  disabled={isDisable}
                  handleFileUpload={this.handleFileUpload}
                />
              </div>
            </Grid>
          )}
        </div>
        <DeleteDialog
          open={this.state.deleteDialog}
          content={"Are you sure you want to remove this attachment?"}
          handleDeleteAction={this.handleDelete}
          handleDeleteOnCloseAction={this.handleTableDeleteOnCloseAction}
        />
      </ValidatorForm>
    );
  }
}

/**
 * Upload Container
 * @class Upload
 * @extends {Component}
 */
class UploadNew extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: "new",
      open: false,
      dropdown: false,
      imageData: null,
      showUpload: true,
      showError: false,
      bulkAction: "new",
      downloadFile: false,
      fileTypeError: null,
      uploadedFile: null,
      deleteDialog: false,
      org_id: getUserOrgId(),
      step: 0,
      fileName:"",
      fileSize:0
    };
  }
  /**
   * Component will receive props
   * @param {*} newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.errorStatus) {
      this.setState({ showError: true, imageData: null, uploadedFile: null });
    }
    if (newProps.dataStatus == "success") {
      this.setState({ showError: false, imageData: null, uploadedFile: null });
    }
    this.setState({ step: newProps.page });
  }
  /**Handle Close Event */
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  /**
   * Handle the form submit
   */
  handleSubmit = async (data,fileSize,fileName,uploadedFile) => {
    await this.setState({ imageData: data ,fileSize,fileName,uploadedFile});
    if (this.state.imageData  !=  null) {
      // this.props.uploadBulkImport(this.state.imageData, this.state.bulkAction)
    } else {
      this.setState({
        showError: true,
        fileTypeError:
          "The file format is invalid, Please upload the file in the “.xlsx” format.",
      });
    }
  };
  submitExcel=()=>{
    this.state.imageData  !=  null ? this.props.uploadBulkImport(this.state.imageData, this.state.bulkAction):this.props.showErrorAlert("No File uploaded. Please upload a File to continue and save")
  }
  submitImport=()=>{
    if(this.props.dataStatus.type==='Add'){
      this.props.uploadBulkImportSubmit(this.props.dataStatus, "Add")
    }else{
    this.props.uploadBulkImportSubmit(this.props.uploadStatus.data, this.state.bulkAction)
    }
  }
  radioButtonChange = (action) => {
    const {org_id}=this.state;
    var name="";
    if (action == "new" && org_id == 1) {
      name = "WBARS_Create Project_WSHFC.xlsx";
    } else if (action == "new" && org_id == 2) {
      name = "WBARS_Create Project_Commerce.xlsx";
    } else if (action == "update" && org_id == 1) {
      name = "WBARS_Buildings & Units Update.xlsx";
    } else {
      name = "WBARS_Buildings & Units Update.xlsx";
    }
    this.setState({ bulkAction: action ,fileName:name});

  };

  getSaveVisibleStatus = (step) => {
     if (step == 0 || step == 2 || step ==3) { 
      return true 
    } else if (step == 1 && this.state.uploadedFile != null) {
      return true
    } else {
      return false
    }
  }

  downloadLogs=(name)=>{
    this.props.downloadErrorLog(name);
  }

  /**
   * Bind HTML to reactDOM
   * @returns
   */
  render() {
    let { uploadStatus, errorStatus, isLoading } = this.props;
    const {
      fileTypeError,
      step,
      fileName,
      fileSize
    } = this.state;
    return (
      <>
        <Grid item xs={12} sm={8}>
          <PageTitle
            title={"Super Funder Excel Import"}
            subTitle={""}
            actions={[]}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <ProcessBar step={step >1?step-1:step} />
        </Grid>
        {step == 0 ? (
          <Option onAction={(action) => this.radioButtonChange(action)} />
        ) : step == 1 ? (
          <Validate onUpload={(data,fileSize,fileName,uploadedFile) => this.handleSubmit(data,fileSize,fileName,uploadedFile)} onError={(data)=>this.props.showErrorAlert(data)} />
        ) : step == 2 ? (
          <ErrorLog
            uploadStatus={uploadStatus}
            errorStatus={errorStatus}
            fileTypeError={fileTypeError}
            fileName={
              fileName
            }
            fileSize={fileSize}
            stateRes={(name)=>this.downloadLogs(name)}
          />
        ) :step == 3 ?(
          <SuccessLog 
            fileName={fileName}
            fileSize={fileSize}
          />
        ): (
          <Success project={uploadStatus}/>
          // <ErrorLog
          //   uploadStatus={null}
          //   errorStatus={null}
          //   fileTypeError={null}
          //   fileName={
          //     fileName
          //   }
          //   fileSize={fileSize}
          //   // stateRes={(name)=>this.downloadLogs(name)}
          // />
        )}

        {
          <div style={{display:'flex',marginTop:'40px'}}>
          {/* {step == 1 || step == 2 ? <button
          style={{
            color: "#36aebe",
            border: "none",
            backgroundColor: "#fff",
            cursor: "pointer",
            // textDecoration: "underline",
            marginLeft: "3%",
            fontWeight: "700",
            minWidth:'110px'
            // marginTop:'20px'
          }}
          onClick={() =>history.push("/configurations/upload/0")}
        >
          Back to Step 1
        </button>: null} */}
          <SaveCancel
            handleSaveButtonClick={() => {
              if (step == 0) {
                this.setState({uploadedFile:null})
                history.push("/configurations/upload/1");
              } else if (step == 1) {
                this.submitExcel()
              }else if (step == 2) {
                this.setState({imageData:null,uploadedFile:null})
                history.goBack();
              }else if (step == 3) {
                // history.push("/configurations/upload/4");
                this.submitImport()
              }
            }}
            handleCancelButtonClick={() => {
              if(step == 4){
                history.push("/configurations/upload/0");
              } else if (step == 3) {
                history.push("/configurations/upload/0");
              }
              else if (step != 0){
                this.setState({uploadedFile:null})
                history.goBack();
              }
              
            }}
            saveText={step == 0 ? "Continue to Upload" :step == 1 ? "Validate":step == 2 ?"Re-Upload":step == 3 ?"Submit":""}
            saveVisible={this.getSaveVisibleStatus(step)}
            cancelVisible={(step == 0) ? false : true}
          />
          </div> 
        }
        
        <PageLoader show={isLoading} />
      </>
    );
  }
}
/** Props types */
UploadNew.propTypes = {
  classes: PropTypes.object.isRequired,
  needPadding: PropTypes.bool,
  title: PropTypes.string,
  isSubPage: PropTypes.bool,
};

/**
 * Default Props Types
 */
UploadNew.defaultProps = {
  classes: {},
  needPadding: true,
  title: "Upload",
  isSubPage: false,
};
/**
 * Maps state from store to props
 * @param {*} param0
 * @param {*} ownProps
 * @returns
 */
const mapStateToProps = (state, ownProps) => {
  return {
    pageAccess: state.pageAccess["projectNew"] || [],
    uploadStatus: state.bulkUploads,
    errorStatus: state.bulkUploads.error,
    dataStatus: state.bulkUploads.data,
    isLoading: state.bulkUploads.isLoading,
    res:state
  };
};
/**
 * Maps actions to props
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    uploadBulkImport: (uploadData, action) =>
      dispatch(uploadActions.bulkImport(uploadData, action)),
    uploadBulkImportSubmit: (uploadData, action) =>
      dispatch(uploadActions.bulkImportSubmit(uploadData, action)),
    showErrorAlert: (error) => dispatch(alertActions.error(error)),
    downloadErrorLog: (file) => dispatch(uploadActions.downloadErrorLogs(file)),
  };
};
/** Export Upload */
export default connect(mapStateToProps, mapDispatchToProps)(UploadNew);
