import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Mateial UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';

// Tab components
import General from './tabs/General';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { history } from '../../helpers';
import { projectsActions } from '../../actions/projects';

/**
 * ProjectNew Container
 * @class ProjectNew
 * @extends {Component}
 */
class ProjectNew extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        this.setState({ tab: 0 });
    };
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.projectRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            this.props.createProject(formData);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.push('/projects');
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, theme, match: { params }, pageAccess } = this.props;
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={['BACK']}
                title="ADD PROJECT"
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
            >
                <Grid item xs={12} className = "h-100">
                    <Card className = "h-100">
                        <CardContent className={`${classes.nopadding} h-100`}>
                            <AppBar position="static" color="default">
                                <Tabs
                                    name={'tab'}
                                    value={this.state.tab}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label="GENERAL" />
                                    <Tab label="FUNDERS" disabled={true} />
                                    <Tab label="SITES" disabled={true} />
                                    <Tab label="REPORTS" disabled={true} />
                                </Tabs>
                            </AppBar>
                            <TabContainer dir={theme.direction}>
                                    <General
                                        pageAccess={pageAccess}
                                        copyId={params.copyId}
                                        innerRef={(node) => { this.projectRef = node; }}
                                        handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                                    />
                                </TabContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
ProjectNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['projectNew'] || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        createProject: (formData) => dispatch(projectsActions.create(formData))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ProjectNew));
