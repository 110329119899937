import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';

//components
import TableComponent from '../../components/Table/TableComponent';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Redux Actions and Helper Variable
import { unitsActions } from '../../actions/units';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * UnitsHistory Component
 * @class UnitsHistory
 * @extends {Component}
 */
class UnitsHistory extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            fieldFilter: false,
            reset: false
        }
    }
    /**
     * On Sort Request
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { unitid } = this.props;
        this.props.getByUHId(orderBy, order, unitid);
    };
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        if (this.props.unitid) {
            this.props.getByUHId('headOfHouseholdName', 'asc', this.props.unitid);
        }
    }
    /**
     * Bind Html to DOM 
     */
    render() {
        const { unitHistory: { data, page: { fieldFilters, order, orderBy } }, title, classes, isSubPage, pageAccess } = this.props;
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                needPadding={!isSubPage}
            >
                <TableComponent
                    rowsPerPage={data.length}
                    count={data.length}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    order={order}
                    orderBy={orderBy}
                    onSortRequest={this.onSortRequest}
                    data={data}
                    currentPage={'units'}
                    paginationVisible={false}
                    searchVisible={false}
                    noDataErrorMessage={"No Records Found"}
                />
            </ContentWrapper>
        )
    }
}
/** Props Types */
UnitsHistory.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};
/** Default Props */
UnitsHistory.defaultProps = {
    title: "Unit History",
    isSubPage: true,
    pageAccess: []
};
/**
 * Map State to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ units }, ownProps) => {
    return {
        unitHistory: units.getOne.history,
        units,
        pageAccess: []
    }
};
/**
 * Map Dispatch to Props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByUHId: (orderBy, order, unitid) => dispatch(unitsActions.getByUHId(orderBy, order, unitid))
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(UnitsHistory));
