import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
// import ArchivesRouter from './Archives.Router';
// import ArchiveUsersRouter from './ArchiveUsers.Router';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions and Helper Files
import { helpActions } from "../../actions/help";

// Import Helpers
import { history,getUserRole } from '../../helpers';
import { pageAccessActions } from '../../actions/pageAccess';
import ArchivesList from './ArchivesList';
import ArchivesView from './ArchivesView';
import UsersList from './UsersList';
import Funders from './tabs/Funders';


/**
 * OrganizationsView Container
 * @class OrganizationsView
 * @extends {Component}
 */
class Archive extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };

    }


    UNSAFE_componentWillMount() {
        console.log('LOCATION', history.location.pathname)
        this.props.getHelp()
        this.props.getPageAccess('archivesList', {})
    }

    componentDidMount() {
        const { match } = this.props;
        //For archive users
        let isOtherFunder = getUserRole()==='nonFunderWithOA' || 
        getUserRole()==='nonFunderWithOAandHO' || getUserRole()==='nonFunderWithOAandBoth';

        if (history.location.pathname.includes('/view/users')) {
            this.setState({ tab: 1 })
        }

        if(isOtherFunder){
            this.setState({ tab: 1 })
            history.push({
                pathname: `${match.url}/view/users`,
            });
        }

        if(isOtherFunder&&history.location.pathname==='archives'){
            this.setState({ tab: 1 })
            history.push({
                pathname: `${match.url}/view/users`,
            });
        }

    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match } = this.props;
        console.log('', this.props);
        this.setState({ tab: value })
        if (value === 0) {
            history.push({
                pathname: `${match.url}`,
            });
        } else {
            history.push({
                pathname: `${match.url}/view/users`,
            });
        }
    };

    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        const { projects } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter("archives", page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Tab Index Change Event
     * @param {*} index 
     */
    handleTabIndexChange = index => {
        this.setState({ tab: index });
    };
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.orgRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Table Navigation Event - Users View Page
     * @param {*} id 
     */
    handleNavigateAction = (id) => {
        history.push({
            pathname: '/users/' + id,
            state: {
                page: 'userEdit',
                pageData: {}
            }
        });
    }
    /**
     * Bind Html to DOM
     * 
     * [1]  -   for contacts tab
     */
    render() {
        const { classes, theme, orgs, match, location: { pathname }, pageAccess, isSubPage, title, needPadding, projects } = this.props;
        const search = projects;
        //user archive changes
        let otherFunderUser = getUserRole()==='nonFunderWithOA' || 
        getUserRole()==='nonFunderWithOAandHO' || getUserRole()==='nonFunderWithOAandBoth';
        return (
            <ContentWrapper
                classes={classes}
                needTitleBar={true}
                title={'ARCHIVE'}
                isSubPage={false}
                handleHelpActionClick={this.handleHelpActionClick}
                needPadding={true}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={this.state.tab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        {otherFunderUser?<></>:<Tab label="Archived Projects" value={0} />}
                        <Tab label="Archived Users" value={1} />
                    </Tabs>
                </AppBar>
                <TabContainer dir={theme.direction}>
                    <Route exact path={match.url} render={(props) => {
                        console.log('EnteredRouteArchiveList')
                        return <ArchivesList match={props.match} page='archives' {...props} />
                    }} />
                    <Route exact path={`${match.url}/:project_id`} render={(props) => {
                        console.log('EnteredRouteArchiveView1')
                        return <ArchivesView {...this.props} projectId={props.match.params.project_id} />
                    }} />
                    <Route exact path={`${match.url}/:project_id/funders`} render={(props) => {
                        console.log('EnteredRouteArchiveView2')
                        return <ArchivesView {...this.props} projectId={props.match.params.project_id} />
                    }} />
                    <Route exact path={`${match.url}/:project_id/sites`} render={(props) => {
                        console.log('EnteredRouteArchiveView2')
                        return <ArchivesView {...this.props} projectId={props.match.params.project_id} />
                    }} />
                    <Route exact path={`${match.url}/:project_id/reports`} render={(props) => {
                        console.log('EnteredRouteArchiveView2')
                        return <ArchivesView {...this.props} projectId={props.match.params.project_id} />
                    }} />

                    <Route exact path={`${match.url}/view/users`} render={(props) => {
                        console.log('EnteredUserList')
                        return <UsersList match={props.match} page='users' {...props} />
                    }} />

                    <Route exact path={`/users/:id/:page`} render={(props) => {
                        console.log('EnteredUserList')
                        return <ArchivesView match={props.match} page='userEdit' {...props} />
                    }} />

                    <Route exact path={`/archives/:project_id/funders/:funderId`} render={(props) => {
                        return <Funders {...this.props} projectId={props.match.params.project_id} />
                    }} />
                    <Route exact path={`/archives/:project_id/sites/:site_id`} render={props => {
                        console.log('EnteredSitesView')
                        return <ArchivesView {...props}
                            match={match}
                            projectId={props.match.params.project_id}
                            page={'archives'} />
                    }} />
                    <Route exact path={`/archives/:project_id/sites/:site_id/buildings`} render={props => {
                        return (
                            <ArchivesView
                                projectId={props.match.params.project_id}
                                siteId={parseInt(props.match.params.siteId, 10)}
                                {...props}
                                isSubPage={true}
                            />
                        )
                    }} />
                    <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id`} render={props => {
                        return <ArchivesView
                            projectId={props.match.params.project_id}
                            siteId={parseInt(props.match.params.siteId, 10)}
                            {...props}
                            isSubPage={true}
                        />
                    }} />
                    <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id/ua`} render={props => {
                        return <ArchivesView
                            projectId={props.match.params.project_id}
                            siteId={parseInt(props.match.params.siteId, 10)}
                            {...props}
                            isSubPage={true}
                        />
                    }} />
                    <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id/units`} render={props => {
                        return <ArchivesView
                            projectId={props.match.params.project_id}
                            siteId={parseInt(props.match.params.siteId, 10)}
                            {...props}
                            isSubPage={true}
                        />
                    }} />

                    <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id/units/:unit_id`} render={props => {
                        return <ArchivesView
                            projectId={props.match.params.project_id}
                            siteId={parseInt(props.match.params.siteId, 10)}
                            {...props}
                            isSubPage={true}
                        />
                    }} />
                    <Route exact path={`/archives/:project_id/funders/:funderId`} render={props => {
                        return <ArchivesView {...props}
                            match={match}
                            projectId={props.match.params.project_id}
                            page={'archives'} />
                    }}
                    />
                    <Route
                        path={`/archives/:project_id/reports`}
                        render={(props) => {
                            return (
                                <ArchivesView
                                    {...props}
                                    isSubPage={true}
                                    projectId={props.match.params.project_id}
                                />
                            )
                        }}
                    />
                </TabContainer>
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
Archive.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    needPadding: PropTypes.bool
};
/** Default Data */
Archive.defaultProps = {
    isSubPage: false,
    pageAccess: [],
    title: '',
    needPadding: false
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const pageAccess = state.pageAccess['archivesList'] || [];
    const helpData = state.help.data[0];
    const projects = state.archives;
    return {
        projects,
        pageAccess,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getHelp: () => dispatch(helpActions.getAll({ _id: 16 })),
        getPageAccess: (name, pageData) => dispatch(pageAccessActions.getAccess(name, pageData))

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Archive));
