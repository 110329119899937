import React, { Component } from 'react';
import { connect } from "react-redux";

// Action
import { getUserType, getUserOrgId } from '../../../helpers';

// Import Components
import BarChart from '../../../components/Charts/BarChart';
import { Grid } from '@material-ui/core';

/**
 *Compliance Issue Chart Component
 *
 * @class ComplianceIssue
 * @extends {Component}
 */
class ComplianceIssue extends Component {

    /**
     *Creates an instance of ComplianceIssue.
     * @param {*} props
     * @memberof ComplianceIssue
     */
    constructor(props) {
        super(props);
        this.state = {

        };
        this.userRole = getUserType();
        this.org_id = getUserOrgId();
    }

    /**
    *  Component Will Mount
    */
    // UNSAFE_componentWillMount() {
    //     const { userRole, org_id } = this;
    //     this.props.getComplianceIssue(this.props.complianceIssue.year, userRole, org_id);
    // }

    /**
     * Render HTML
     */
    render() {
        const { complianceIssue } = this.props;

        return (
            <Grid xs={12} className="complianceChart">
                <BarChart data={complianceIssue.data.barChartData} />
            </Grid>
        )
    }
}

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
        complianceIssue: state.dashboardHO.complianceNeedsAction
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        // getComplianceIssue: (year, userRole, org_id) => dispatch(dashboardActions.getComplianceIssue(year, userRole, org_id))
    }
};

// export ComplianceIssue
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComplianceIssue);

