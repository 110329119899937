import { toolbar} from './common'

const privateLayoutStyle = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        height:'100%'
    },
    content: {
        height:'100%',
        flexGrow: 1,
        backgroundColor: '#f4f4f4',
        overflowX: 'auto'
    },
    toolbar: {
        height: 48,
        ...toolbar
    },

});

export default privateLayoutStyle;
