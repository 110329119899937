import { orgConstants } from '../constants';

const tableDatas = (sortName) => {
    return {
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: [sortName],
                searchCols: []
            }
        }
    }
};

const initialState = {
    contacts: {
        isLoading: false,
        ...tableDatas('firstName')
    },
    ...tableDatas('name'),
    getOne: {
        data: null
    }
};

export function organizations(state = initialState, action) {
    switch (action.type) {
        case orgConstants.GETALL_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                },
                isLoading: true,
            };
        case orgConstants.GETALL_SUCCESS:
            return {
                ...state,
                // data: action.orgs.data.map((data) => {
                //     if (data.organizationType) data.organizationType = data.organizationType.join(", ");
                //     return data;
                // }),
                // page: action.orgs.page,
                ...action.orgs,
                isLoading: false,
            };
        case orgConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case orgConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    data: null
                },
                contacts: {
                    ...state.contacts,
                    ...tableDatas('name')
                },
                isLoading: true
            };
        case orgConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.org.data,
                    projectList:action.org.projectList
                },
                isLoading: false
            };
        case orgConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case orgConstants.UPDATE_ORGANIZATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case orgConstants.UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.org.data
                },
                isLoading: false
            };
        case orgConstants.UPDATE_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case orgConstants.ADD_ORGANIZATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case orgConstants.ADD_ORGANIZATION_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case orgConstants.ADD_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case orgConstants.DELETE_REQUEST:
            // add 'deleting:true' property to org being deleted
            return {
                ...state,
                data: state.data.map(org =>
                    org.id === action.id
                        ? { ...org, deleting: true }
                        : org
                )
            };


        case orgConstants.DELETE_SUCCESS:
            // remove deleted org from state
            return {
                ...state,
                data: state.data.filter(org => org._id !== action.id),
                page: state.page
            };
        case orgConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to org
            return {
                ...state,
                data: state.data.map(org => {
                    if (org.id === action.id) {
                        // make copy of org without 'deleting:true' property
                        const { deleting, ...orgCopy } = org;
                        // return copy of org with 'deleteError:[error]' property
                        return { ...orgCopy, deleteError: action.error };
                    }

                    return org;
                })
            };
        case orgConstants.CLEAR_FILTER_SET:
            return {
                ...state,
                reset: true,
            };
        case orgConstants.CLEAR_FILTER_RESET:
            return {
                ...state,
                reset: false,
            };
        case orgConstants.ACTIVE_UPDATE_REQUEST:
            return {
                ...state
            }
        case orgConstants.ACTIVE_UPDATE_SUCCESS:
            return {
                ...state,
                data: state.data.map(org => {
                    if (org._id === action.id) {
                        return { ...org, isActive: action.org.result.isActive };
                    }

                    return org;
                })
            }
        case orgConstants.GET_ORG_CONTACT_REQUEST:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    // isLoading: true
                },
                // isLoading: true
            }
        case orgConstants.GET_ORG_CONTACT_SUCCESS:
            return {
                ...state,
                contacts: {
                    ...action.contacts,
                    // isLoading: false
                },
                // isLoading: false
            }
        case orgConstants.ACTIVE_UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                contacts: {
                    ...state.contacts,
                    isLoading: false
                },
                // isLoading: false
            }
        default:
            return state
    }
}