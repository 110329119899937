import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Helpers
import { history } from '../../helpers';

// Style 
import pageStyle from '../../assets/jss/containers/common';

// Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import BuildingEdit from './BuildingEdit';

// Actions
import { buildingActions } from '../../actions/building';

/**
 *Create new building Container
 *
 * @class BuildingNew
 * @extends {Component}
 */
class BuildingNew extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.buildingRef = React.createRef();
    }
    /**
     * Handle form save action
     * @param {*} formData 
     * @memberof BuildingEdit
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            this.props.addNewBuilding({ ...formData, siteId: parseInt(this.props.siteId, 10) });
        }
    };
    /**
     * Handle save action click
     * @memberof ContentWrapper
     */
    handleSaveActionClick = () => {
        this.buildingRef.refs.form.submit();
    };
    /**
     * Handle back action click
     * @memberof ContentWrapper
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     *render html
     *
     * @returns
     * @memberof BuildingNew
     */
    render() {
        let { pageAccess } = this.props;
        const { isSubPage, title } = this.props;
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        const { classes, location: { pathname } } = this.props;
        pageAccess = isSubPage ? pageAccess : [...pageAccess, 'BACK'];
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
                handleBackActionClick={this.handleBackActionClick}
            >
                <BuildingEdit
                    isSubPage={isSubPage}
                    classes={classes}
                    pageAccess={pageAccess}
                    innerRef={(node) => { this.buildingRef = node; }}
                    isReadOly={isReadOly}
                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                />
            </ContentWrapper>
        );
    }
}
// prop types
BuildingNew.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
}
// default props
BuildingNew.defaultProps = {
    title: "",
    isSubPage: false,
    pageAccess: []
}
/**
 * Map state to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['buildingNew'] || []
    }
};
/**
 * Map dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        addNewBuilding: (formData) => dispatch(buildingActions.addNewBuilding(formData))
    }
};
// export BuildingNew
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(BuildingNew));