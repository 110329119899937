import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route, Switch } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import PageLoader from '../../components/PageLoader/PageLoader';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Tab components
import ReportCoverPage from '../../containers/Reports/tabs/ReportCoverPage';
import Table1 from '../../containers/Reports/tabs/Table1';
import Table2Selection from '../../containers/Reports/tabs/Table2Selection';
import Table3 from '../../containers/Reports/tabs/Table3';
import Table4 from './tabs/Table4';
import Table5 from './tabs/Table5';


// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserRole, getUserId, getUserOrgId, history,globalVariable } from '../../helpers';
import { getTitle } from '../../helpers/setTitle';

// Action
import { projectsActions } from '../../actions/projects';
import { sitesActions } from "../../actions/sites";
import { reportTablesActions } from "../../actions/reportTables";
import Table5Edit from './tabs/Table5Sections/Table5Edit';
/**
 * ReportView Component
 * @class ReportView
 * @extends {Component}
 */
class ReportView extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            table1Data:null,
            validations:null,
            siteId:''
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
    }

    /** 
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { pageData, match } = this.props;

        if (pageData.project_id && match.params.id) {
            this.props.validationIssues(0, 0, match.params.id, '');
        }

        const {  match: { path },projectData } = this.props
        const { site_id, reportYear_id } = this.props.pageData;
        const id = site_id;
        this.setState({ siteId: site_id });
        this.props.getTable1Data(1, globalVariable.subPagetableRowSize, 'unit.common_id', 'asc', '', '', false, projectData, {isDelete:false});
        if (id) {
            this.props.getById(id, path.includes('archives') ? 'archives' : null);
        }
        if (this.props.pageData.project_id) {
            this.props.getByProjectId(this.props.pageData.project_id, path.includes('archives') ? 'archives' : null);
        }
        // get report data
        if (reportYear_id) {
            this.props.getReportSettings(reportYear_id);
        }
    }

    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        const {  match: { path },projectData } = this.props
        const { site_id, reportYear_id } = this.props.pageData;
        const id = site_id;
        this.setState({ siteId: site_id });
        this.props.getTable1Data(1, globalVariable.subPagetableRowSize, 'unit.common_id', 'asc', '', '', false, projectData, {isDelete:false});
        if (id) {
            this.props.getById(id, path.includes('archives') ? 'archives' : null);
        }
        if (this.props.pageData.project_id) {
            this.props.getByProjectId(this.props.pageData.project_id, path.includes('archives') ? 'archives' : null);
        }
        // get report data
        if (reportYear_id) {
            this.props.getReportSettings(reportYear_id);
        }
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match, projectId, pageData, pageTitle } = this.props;
        const { siteId } = this.state;
        switch (value) {
            case 0:
                history.push({
                    pathname: `${match.url}`,
                    state: {
                        page: 'reportView',
                        pageData: {
                            isArchived: false,
                            page:"projects",
                            project_id:parseInt( projectId,10),
                            reportYear:parseInt(match.params.year, 10),
                            reportYear_id: parseInt(match.params.id, 10),
                            site_id:siteId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 1:
                history.push({
                    pathname: `${match.url}/table1`,
                    state: {
                        page: 'table1',
                        pageData: {
                            project_id: projectId,
                            reportYear_id: match.params.id,
                            site_id:siteId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 2:
                history.push({
                    pathname: `${match.url}/table2`,
                    state: {
                        page: 'table2',
                        pageData: {
                            project_id: projectId,
                            reportYear_id: match.params.id,
                            site_id:siteId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 3:
                history.push({
                    pathname: `${match.url}/table3`,
                    state: {
                        page: 'table3',
                        pageData: {
                            project_id: projectId,
                            reportYear_id: match.params.id,
                            site_id:siteId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 4:
                history.push({
                    pathname: `${match.url}/table4`,
                    state: {
                        page: 'table4',
                        pageData: {
                            project_id: projectId,
                            reportYear_id: match.params.id,
                            site_id:siteId,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 5: 
                history.push({
                    pathname: `${match.url}/table5`,
                    state: {
                        page: 'table5',
                        pageData: {
                            project_id: projectId,
                            reportYear_id: match.params.id,
                            site_id:siteId,
                            ...pageData
                        },
                        pageTitle
                    }
                })
                break;
            default:
                history.push(`${match.url}/${projectId}/reports`);
                break;
        }
    };
    /**
     * Get Tab Index
     * @param {*} pathname 
     */
    getTabIndex(pathname) {
        const tabs = ["table1", "table2", "table3", "table4", "table5"];
        const tabUrl = pathname.split('/').pop();
        const currentIndex = tabs.indexOf(tabUrl);
        return currentIndex === -1 ? 0 : currentIndex + 1;
    }
    /**
     * Bind Html to DOM
     */
    render() {
        let { classes, match, projectId, location: { pathname },validations,table1,
            pageAccess, title, isSubPage, site_id, reportSubmission } = this.props;
        
        let totalErrors = [];
        let isNextYear = validations.data.isPrevSubmitted;
        totalErrors = validations.data.table1Issues || [];
        
        //totalErrors = totalErrors.filter(item=>!item.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))
        let table1FilterData = table1.data.filter(tbd=>tbd.moveOutDate === null);
        //Handle LHH errors
        table1FilterData.map(item=>{
            if(item.unitDesignation&&item.unitDesignation.desc==="Restricted"){
            if(item.bedroomsSize < 3 && item.specialNeedOption_id === 2){
                let checkSMError = totalErrors.filter(te=>te.unit_id===item.unit._id&&te.message.includes("This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger."))
                if(checkSMError.length===0){
                    totalErrors.push({
                        "reportTable": "1",
                        "table1_id": item._id,
                        "project_id": item.project_id,
                        "building_id": item.building._id,
                        "unit_id": item.unit._id,
                        "common_id":item.unit.common_id,
                        "site_id": item.site._id,
                        "message": "This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.",
                        "errorType": 2,
                        "tabIndex": 0
                    })
                }
            }
        }
        })



        table1FilterData.map(item=>{
            if(item.unitDesignation&&item.unitDesignation.desc==="Restricted"){
            if(item.moveInYear===item.report_Year){
            if(parseInt(item.specialNeedOption_id)===2 && (parseInt(item.householdSize)<4 && parseInt(item.householdSize_MoveIn)<4 )){
                let checkError = totalErrors.filter(te=>te.unit_id===item.unit._id&&te.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))
                if(checkError.length===0){
                totalErrors.push({
                    "reportTable": "1",
                    "table1_id": item._id,
                    "project_id": item.project_id,
                    "building_id": item.building._id,
                    "unit_id": item.unit._id,
                    "common_id":item.unit.common_id,
                    "site_id": item.site._id,
                    "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                    "errorType": 2,
                    "tabIndex": 0
                })
            }
            }
        }else{
            if(parseInt(item.specialNeedOption_id)===2 && (parseInt(item.householdSize)<4 && parseInt(item.householdSize_MoveIn)<4 )){
                let checkError = totalErrors.filter(te=>te.unit_id===item.unit._id&&te.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))
                if(checkError.length===0){
                totalErrors.push({
                    "reportTable": "1",
                    "table1_id": item._id,
                    "project_id": item.project_id,
                    "building_id": item.building._id,
                    "unit_id": item.unit._id,
                    "common_id":item.unit.common_id,
                    "site_id": item.site._id,
                    "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                    "errorType": 2,
                    "tabIndex": 0
                })
            }
            }
        }
        }
        })

        table1FilterData.map(item=>{
            if(item.unitDesignation&&item.unitDesignation.desc!=="Restricted"){
        //     let checkSMError = totalErrors.filter(te=>te.unit_id===item.unt_id&&te.message.includes("This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger."));
        //     if(checkSMError.length===0){
        //         totalErrors = totalErrors.filter(item=>item.message!=="This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.")
        // }
            if(parseInt(item.specialNeedOption_id)!==2 && (parseInt(item.householdSize_MoveIn)>=4 ||  parseInt(item.householdSize)>=4)){
                let checkError = totalErrors.filter(te=>te.unit_id===item.unit._id&&te.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."));
                if(checkError.length===0){
                    totalErrors = totalErrors.filter(item=>item.message!=="This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")
            }
            }
        }
        })
        let errorsToBeRemoved = [];
        //remove unitSM errors
       let getSMErrors = totalErrors.filter(item=>item.message==="This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.")
        getSMErrors.map(item=>{
            let SMData = table1FilterData.filter(sm=>sm.unit._id===item.unit_id)[0];
            if(SMData!==undefined){
            if(!SMData.unitDesignation||(SMData.unitDesignation && SMData.unitDesignation.dec && SMData.unitDesignation.desc!=="Restricted") || SMData.specialNeedOption_id !== 2 || (SMData.bedroomsSize > 3 && item.specialNeedOption_id === 2 && SMData.unitDesignation.desc==="Restricted")){
                errorsToBeRemoved.push(item)
            }
        }
        })

        //remove HouseholdSM errors
        let getHouseHoldSM = totalErrors.filter(item=>item.message==="This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")
        getHouseHoldSM.map(item=>{
            let HSMData = table1FilterData.filter(sm=>sm.unit._id===item.unit_id)[0];
            if(HSMData!==undefined){
            if(!HSMData.unitDesignation||(HSMData.unitDesignation && HSMData.unitDesignation.dec && HSMData.unitDesignation.desc!=="Restricted") || HSMData.specialNeedOption_id !== 2 || (parseInt(HSMData.householdSize_MoveIn)>=4 && parseInt(HSMData.householdSize)>=4 && HSMData.unitDesignation.desc==="Restricted")){
                errorsToBeRemoved.push(item)
            }
        }
        })
        errorsToBeRemoved = errorsToBeRemoved.reduce((unique, o) => {
            if(!unique.some(obj => obj.unit_id === o.unit_id && obj.message === o.message)) {
              unique.push(o);
            }
            return unique;
        },[]);

        totalErrors = totalErrors.reduce((unique, o) => {
            if(!unique.some(obj => obj.unit_id === o.unit_id && obj.message === o.message)) {
              unique.push(o);
            }
            return unique;
        },[]);

        errorsToBeRemoved.map(item=>{
            let index = totalErrors.findIndex(fi=>fi.unit_id===item.unit_id&&fi.message===item.message);
            if(index!==-1){
                totalErrors.splice(index,1)
            }
        })

        validations.data.table1Issues = totalErrors;
        
        //let totalErrors = []
        // let unitsWithHouseHold3 = table1FilterData.filter(item=>parseInt(item.householdSize)>=4);
        // let unitsWithHouseHold3WithHouseHoldError = unitsWithHouseHold3.map(item=>{
        //     if(item.vr.filter(item=>item.message.includes("This household is too small to qualify for Large Household Special Needs")).length>0){
        //         return item.vr.filter(item=>item.message.includes("This household is too small to qualify for Large Household Special Needs"))
        //     }else{
        //         return null
        //     }

        // })
        // let valToBeRemoved = unitsWithHouseHold3WithHouseHoldError.filter(item=>item!==null);
        // valToBeRemoved = valToBeRemoved.flat(1)

        // if(isNextYear){
        // valToBeRemoved.map(rmv=>{
        //     totalErrors = totalErrors.filter(item=>item.complaince_id!==rmv.complaince_id);
        // })
        // }
        let includesHardStop = totalErrors.filter(item=>item.errorType===3 || item.errorType===4);
        let includesWarning = totalErrors.filter(item=>item.errorType===2);
        
        if(includesHardStop.length===0 && includesWarning.length===0){
            validations.data.table1=0
        }
        if(includesWarning.length>0){
            validations.data.table1=2
        }
        if(includesHardStop.length>0){
            validations.data.table1=3
        }
        match.url = match.url.replace(/\/$/, "");
        const currentTab = this.getTabIndex(pathname);
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        return (
            <div className='reports'>
                <ContentWrapper
                    classes={classes}
                    pageAccess={pageAccess}
                    title={title}
                    isSubPage={isSubPage}
                    needTitleBar={title ? true : false}
                    needPadding={false}
                    handleClearFilterActionClick={() => {
                        history.replace(`${pathname}#clear`);
                    }}
                    handleBackActionClick={this.handleBackActionClick}
                    handleSaveActionClick={this.handleSaveActionClick}
                >
                    <AppBar position="static" color="secondary">
                        <Tabs
                            name={'tab'}
                            value={currentTab}
                            onChange={this.handleTabChange}
                            indicatorColor={'primary'}
                            textColor="primary"
                            variant="fullWidth"
                            color="secondary"
                            className={'reportTab'}

                        >
                            <Tab classes={{ selected: "reportTabSelected" }} label="REPORT COVER PAGE" value={0} className='validationGreenBorder' />
                            <Tab classes={{ selected: "reportTabSelected" }} label="TABLE 1" value={1} className={validations.data.table1 === 3 || validations.data.table1 === 4 ? 'validationRedBorder' : (validations.data.table1 === 2 ? 'validationYellowBorder' : 'validationGreenBorder')} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="TABLE 2" value={2} className={validations.data.table2 === 3 || validations.data.table2 === 4 ? 'validationRedBorder' : (validations.data.table2 === 2 ? 'validationYellowBorder' : 'validationGreenBorder')} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="TABLE 3" value={3} className={validations.data.table3 === 3 || validations.data.table3 === 4 ? 'validationRedBorder' : (validations.data.table3 === 2 ? 'validationYellowBorder' : 'validationGreenBorder')} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="TABLE 4,4A & B" value={4} className={validations.data.table4 === 3 || validations.data.table4 === 4 ? 'validationRedBorder' : (validations.data.table4 === 2 ? 'validationYellowBorder' : 'validationGreenBorder')} />
                            <Tab classes={{ selected: "reportTabSelected" }} label="TABLE 5" value={5} className={validations.data.table4 === 3 || validations.data.table4 === 5 ? 'validationRedBorder' : (validations.data.table5 === 2 ? 'validationYellowBorder' : 'validationGreenBorder')} />
                        </Tabs>
                    </AppBar>
                    <TabContainer>
                        <Switch>
                            <Route
                                exact
                                path={`${match.url}`}
                                render={(props) => {
                                    return (
                                        <ReportCoverPage
                                            {...props}
                                            pageAccess={pageAccess}
                                            site_id={site_id}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/table1`}
                                render={(props) => {
                                    return (
                                        <Table1
                                            {...props}
                                            pageAccess={pageAccess}
                                            isReadOly={isReadOly}
                                            projectData={
                                                {
                                                    reportId: parseInt(match.params.id, 10),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                }
                                            }
                                            title=''
                                            isSubPage={true}
                                            projectId={projectId}
                                            site_id={site_id}
                                            reportId={match.params.id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/table2`}
                                render={(props) => {
                                    return (
                                        <Table2Selection
                                            {...props}
                                            pageAccess={pageAccess}
                                            isSubPage={true}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                            projectData={
                                                {
                                                    reportId: parseInt(match.params.id, 10),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    // reportId: parseInt(match.params.id, 10)
                                                }
                                            }
                                        />
                                    )
                                }}
                            />

                            <Route
                                exact
                                path={`${match.url}/table3`}
                                render={(props) => {
                                    return (
                                        <Table3
                                            {...props}
                                            pageAccess={pageAccess}
                                            isSubPage={true}
                                            isReadOly={isReadOly}
                                            reportId={match.params.id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                            projectData={
                                                {
                                                    reportId: parseInt(match.params.id, 10),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    // reportId: parseInt(match.params.id, 10)
                                                }
                                            }
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/table4`}
                                render={(props) => {
                                    return (
                                        <Table4
                                            {...props}
                                            reportId={match.params.id}
                                            projectData={
                                                {
                                                    // reportId: parseInt(match.params.id),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    reportId: parseInt(match.params.id, 10),
                                                }
                                            }
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/table5`}
                                render={(props) => {
                                    return (
                                        <Table5
                                            {...props}
                                            reportId={match.params.id}
                                            projectData={
                                                {
                                                    // reportId: parseInt(match.params.id),
                                                    project_id: parseInt(projectId, 10),
                                                    reportYear: parseInt(match.params.year, 10),
                                                    reportId: parseInt(match.params.id, 10),
                                                }
                                            }
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/table5/:id`}
                                render={(props) => {
                                    return (
                                        <Table5Edit
                                            {...props}
                                        />
                                    )
                                }}
                            />
                        </Switch>
                    </TabContainer>
                </ContentWrapper>
                <PageLoader show={reportSubmission.isLoading} showText={reportSubmission.isLoading}/>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
ReportView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
Table1.defaultProps = {
    needPadding: false,
    isSubPage: false,
    title: '',
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    let { projects, pageAccess, sites, reportTables } = state;
    const pageTitle = getTitle(history.location.pathname, state);
    return {
        table1: state.reportTables.table1,
        details: projects.getOne,
        pageAccess: pageAccess['reportEdit'] || [],
        details2: sites.getOne,
        // validations: reportTables.validations
        validations: { data: reportTables.validationIssues },
        reportSubmission: reportTables.reportSubmission,
        pageTitle
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable1Data: (skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData, query) => dispatch(reportTablesActions.getTable1Data(skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData, query)),
        updateProject: (id, formData) => dispatch(projectsActions.update(id, formData)),
        getById: (site1, page) => dispatch(sitesActions.getById(site1, page)),
        getByProjectId: (projectId, page) => dispatch(projectsActions.getById(projectId, page)),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        getReportSettings: (id) => dispatch(projectsActions.getReportSettings(id)),
        clearTables: () => dispatch(reportTablesActions.clearTables())
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ReportView));
