import { authHeader, globalVariable, handleResponse } from '../helpers';

export const pageAccessService = {
    getAccess
};

/**
 * A service method to get the page access for the passed page name frfom the server
 *
 * @param {*} pageName page name to get the access list
 * @returns
 */
function getAccess(pageName, pageData) {
    pageData = pageData || {};
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(pageData)
    };

    return fetch(`${globalVariable.apiURL}/page-access/${pageName}`, requestOptions).then(handleResponse);
}