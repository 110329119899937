import { ProgramActivityMessages } from "./messages";

const ProgramActivity = (data) => {

    let errors = [];
    let { programActivity, reportData, financialActivity, contractActivitySavedOnce } = data;
    let loanReportActivity = reportData.data;
    if (programActivity && financialActivity && loanReportActivity && ((loanReportActivity.length > 0 && loanReportActivity.filter(elem => elem.isContractActivitySaved === true).length > 0) || contractActivitySavedOnce)) {
        let loanNumbers = [];
        let grantNumbers = [];
        let recordNumbers = [];
        let resaleRecordNumbers = [];
        let closedLoanNumbers = [];
        let closedGrantNumbers = [];
        let closedAllRecordNumbers = [];
        let PAWarning1 = [];
        let PAWarning2 = [];
        let PAWarning3 = [];
        let PAWarning4 = [];
        let PAWarning5 = [];
        // let PAWarning6 = [];
        let PA1 = true;
        let PA2 = true;
        let PA3 = true;
        let PA4 = true;
        let PA5 = true;
        // let PA6 = true;
        let errorLoan1 = [];
        let errorLoan2 = [];
        let errorLoan3 = [];
        let errorLoan4 = [];
        let errorLoan5 = [];
        let errorLoan6 = [];
        
        //REMOVE migrated and DOP from contractactivitydata and programactivity
        let migratedLoanNumbers = loanReportActivity.filter(item=>item.isMigrated || new Date(item.dateOfPurchase).getFullYear()<=2009).map(item=>item.loanNumber);
        loanReportActivity = loanReportActivity.filter(item=>!item.isMigrated || new Date(item.dateOfPurchase).getFullYear()>2009);

        
        //section 1
        let migratedLoansInProgramActivity = programActivity.loansGenerated.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansGenerated = programActivity.loansGenerated.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansGenerated = programActivity.numberOfLoansGenerated - migratedLoansInProgramActivity.length;

        //section 2
        let migratedLoansInLoansGrants = programActivity.loansGrants.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansGrants = programActivity.loansGrants.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansGrants = programActivity.numberOfLoansGrants - migratedLoansInLoansGrants.length;

        //section 3
        let migratedLoansinResaleLoans = programActivity.resaleLoans.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.resaleLoans = programActivity.resaleLoans.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfResales = programActivity.numberOfResales - migratedLoansinResaleLoans.length;

        //section 4
        let migratedLoansinLoansDiferred = programActivity.loansDeferred.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansDeferred = programActivity.loansDeferred.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansDeferred = programActivity.numberOfLoansDeferred - migratedLoansinLoansDiferred.length;

        //section 5
        let migratedLoansinLoansinDefault = programActivity.loansInDefault.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.loansInDefault = programActivity.loansInDefault.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfLoansInDefault = programActivity.numberOfLoansInDefault - migratedLoansinLoansinDefault.length;

        //section 6
        let migratedLoansinForeClosuresLoans = programActivity.foreClosuresLoans.filter(item=>migratedLoanNumbers.includes(item));
        programActivity.foreClosuresLoans = programActivity.foreClosuresLoans.filter(item=>!migratedLoanNumbers.includes(item));
        programActivity.numberOfForeclosuresLoans = programActivity.numberOfForeclosuresLoans - migratedLoansinForeClosuresLoans.length;

        //************************/
        let resaleLoans = loanReportActivity ? loanReportActivity.filter(item => item.typeHTFActivity!==null&&(item.typeHTFActivity === 4 || item.typeHTFActivity.key === 4)) : [];
        //All loans considered irrespective of open and close status
        let contractActivityData = loanReportActivity ? loanReportActivity : [];
        let closedResaleData = loanReportActivity ? loanReportActivity.filter(item => item.closedLoan === true && item.typeHTFActivity === 4).map(item=> item.loanNumber) : [];
        const propertyForeclosureLoans = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes') && (item.isLoan === true || item.isLoan === 'Yes')).map(loan => loan.loanNumber) : [];
        const propertyForeclosureGrants = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes') && (item.isLoan === false || item.isLoan === 'No')).map(loan => loan.loanNumber) : [];
        const allPropertyForeclosure = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes')).map(loan => loan.loanNumber) : [];
        const propertyForeclosureCount = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes')) : [];
        const htfSatisfied = contractActivityData ? contractActivityData.filter(item => (item.isHTFLoanStatisfied === 1 || item.isHTFLoanStatisfied === 'Yes')) : [];
        let closedLoan = [...propertyForeclosureCount, ...htfSatisfied];
        closedLoan = closedLoan.filter((elem, index, inputArray) => {
            return inputArray.findIndex(inputElem => inputElem._id === elem._id) === index;
        })
        const loanData = contractActivityData ? contractActivityData.filter(item => (item.isLoan === true || item.isLoan === 'Yes')) : [];
        const grantData = contractActivityData ? contractActivityData.filter(item => (item.isLoan === false || item.isLoan === 'No')) : [];
        const closedResaleLoans = contractActivityData ? contractActivityData.filter(item => item.typeHTFActivity!==null&&(item.typeHTFActivity === 4 || item.typeHTFActivity.key === 4)) : [];
        const allRecordData = contractActivityData ? contractActivityData : [];
        const allLoanandGrants = contractActivityData ? contractActivityData.map(item => item.loanNumber) : [];
        if (loanData.length > 0) {
            loanData.forEach(item => {
                loanNumbers.push(item.loanNumber);
            });
            loanNumbers = loanNumbers.length > 0 ? loanNumbers : [];
        }

        if (grantData.length > 0) {
            grantData.forEach(item => {
                grantNumbers.push(item.loanNumber);
            });
            grantNumbers = grantNumbers.length > 0 ? grantNumbers : [];
        }

        if(allRecordData.length > 0){
            allRecordData.forEach(item=>{
                recordNumbers.push(item.loanNumber);
            })
            recordNumbers = recordNumbers.length > 0 ? recordNumbers : [];
        }

        if(resaleLoans.length > 0){
            resaleLoans.forEach(item=>{
                resaleRecordNumbers.push(item.loanNumber)
            })
            resaleRecordNumbers = resaleRecordNumbers.length > 0 ? resaleRecordNumbers:[];
        }

        if (closedLoan.length > 0) {
            closedLoan.forEach(item => {
                // if (item.isLoan === true || item.isLoan === 'Yes')
                //     closedLoanNumbers.push(item.loanNumber);
                // else if (item.isLoan === false || item.isLoan === 'No')
                    closedGrantNumbers.push(item.loanNumber);
            });
        }

        if(closedLoan.length > 0){
            closedLoan.forEach(item=>{
                closedAllRecordNumbers.push(item.loanNumber)
            })
        }

        // error - 1
        if (loanData.length < programActivity.numberOfLoansGenerated) {
            errors.push({
                sectionIndex: 1,
                msg: ProgramActivityMessages.numberOfLoansGenerated,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }

        const loanNumberPA1 = programActivity.loansGenerated.length > 0 ? programActivity.loansGenerated : [];
        loanNumberPA1.forEach(item => {
            if (!loanNumbers.includes(item))
                errorLoan1.push(item);
        });
        if (errorLoan1.length > 0)
            errors.push({
                units: errorLoan1,
                sectionIndex: 2,
                msg: ProgramActivityMessages.loanNumber1,
                errorType: 1,
                reportActivity: 1,
                programActivity: programActivity._id
            });

        // error - 2
        if (grantData.length < programActivity.numberOfLoansGrants) {
            errors.push({
                msg: ProgramActivityMessages.numberofGrantsReportingPeriod,
                sectionIndex: 3,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }
        const loanNumberPA2 = programActivity.loansGrants.length > 0 ? programActivity.loansGrants : [];
        loanNumberPA2.forEach(item => {
            if (!grantNumbers.includes(item))
                errorLoan2.push(item);
        });
        if (errorLoan2.length > 0)
            errors.push({
                units: errorLoan2,
                sectionIndex: 4,
                msg: ProgramActivityMessages.loanNumber2,
                errorType: 1,
                reportActivity: 1,
                programActivity: programActivity._id
            });

        // error - 3
        if (allRecordData.length < programActivity.numberOfResales) {
            errors.push({
                msg: ProgramActivityMessages.numberofResales,
                sectionIndex: 5,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }
        const loanNumberPA3 = programActivity.resaleLoans.length > 0 ? programActivity.resaleLoans : [];
        loanNumberPA3.forEach(item => {
            if (!resaleRecordNumbers.includes(item))
                errorLoan3.push(item);
        });
        if (errorLoan3.length > 0)
            errors.push({
                units: errorLoan3,
                sectionIndex: 6,
                msg: ProgramActivityMessages.loanNumber3,
                errorType: 1,
                reportActivity: 1,
                programActivity: programActivity._id
            });


        // error - 4
        // if (allRecordData.length < programActivity.numberOfLoansDeferred) {
        //     errors.push({
        //         msg: ProgramActivityMessages.numberofDeferredLoansReportingPeriod,
        //         sectionIndex: 7,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }
        const loanNumberPA4 = programActivity.loansDeferred.length > 0 ? programActivity.loansDeferred : [];
        loanNumberPA4.forEach(item => {
            if (!recordNumbers.includes(item))
                errorLoan4.push(item);
        });
        if (errorLoan4.length > 0)
            errors.push({
                units: errorLoan4,
                sectionIndex: 8,
                msg: ProgramActivityMessages.loanNumber4,
                errorType: 1,
                reportActivity: 1,
                programActivity: programActivity._id
            });

        // error - 5
        // if (allRecordData.length < programActivity.numberOfLoansInDefault) {
        //     errors.push({
        //         msg: ProgramActivityMessages.numberofLoansDefaultReportingPeriod,
        //         sectionIndex: 9,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }
        const loanNumberPA5 = programActivity.loansInDefault.length > 0 ? programActivity.loansInDefault : [];
        loanNumberPA5.forEach(item => {
            if (!recordNumbers.includes(item))
                errorLoan5.push(item);
        });
        if (errorLoan5.length > 0)
            errors.push({
                units: errorLoan5,
                sectionIndex: 10,
                msg: ProgramActivityMessages.loanNumber5,
                errorType: 1,
                reportActivity: 1,
                programActivity: programActivity._id
            });

        // error - 6
        if (allRecordData.length < programActivity.numberOfForeclosuresLoans) {
            errors.push({
                msg: ProgramActivityMessages.numberofClosuresReportingPeriod,
                sectionIndex: 11,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }
        const loanNumberPA6 = programActivity.foreClosuresLoans.length > 0 ? programActivity.foreClosuresLoans : [];
        loanNumberPA6.forEach(item => {
            if (!recordNumbers.includes(item))
                errorLoan6.push(item);
        });
        if (errorLoan6.length > 0)
            errors.push({
                units: errorLoan6,
                sectionIndex: 12,
                msg: ProgramActivityMessages.loanNumber6,
                errorType: 1,
                reportActivity: 1,
                programActivity: programActivity._id
            });

        
        //Close loan warning removed for section 1 - both open and close considered
        // Warning - 1
        // closedLoanNumbers.forEach(item => {
        //     if (loanNumberPA1.indexOf(item) > -1 && PA1) {
        //         // PA1 = false;
        //         PAWarning1.push(item);
        //     }
        // });

        closedAllRecordNumbers.forEach(item=>{
            if (loanNumberPA3.indexOf(item) > -1 && PA3 && resaleLoans.indexOf(item) > -1) {
                // PA3 = false;
                PAWarning3.push(item);
            }
            // if (loanNumberPA4.indexOf(item) > -1 && PA4) {
            //     // PA4 = false;
            //     PAWarning4.push(item);
            // }
            // if (loanNumberPA5.indexOf(item) > -1 && PA5) {
            //     // PA5 = false;
            //     PAWarning5.push(item);
            // }
        })
        console.log('PAWarning1######################',PAWarning1)
        if (PAWarning1.length > 0){
            errors.push({
                units: PAWarning1,
                sectionIndex: 2,
                msg: ProgramActivityMessages.foreClosure1,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }
        // if (PAWarning3.length > 0)
        //     errors.push({
        //         units: PAWarning3,
        //         sectionIndex: 6,
        //         msg: ProgramActivityMessages.foreClosure3,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        if (PAWarning4.length > 0)
            errors.push({
                units: PAWarning4,
                sectionIndex: 8,
                msg: ProgramActivityMessages.foreClosure4,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        if (PAWarning5.length > 0)
            errors.push({
                units: PAWarning5,
                sectionIndex: 10,
                msg: ProgramActivityMessages.foreClosure5,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });

        //Foreclosure

        let notForeclosureLoans = []
        programActivity.foreClosuresLoans.forEach(item => {
            if (allPropertyForeclosure.indexOf(item)===-1) {
                notForeclosureLoans.push(item)
            }

        })

        let isForeclosedGrant = []
        grantData.forEach(item => {
            if (notForeclosureLoans.indexOf(item.loanNumber) > -1) {
                isForeclosedGrant.push(item)
            }
        })

        let isNotLoanorGrant = []
        programActivity.foreClosuresLoans.forEach(item => {
            if (allLoanandGrants.indexOf(item) === -1) {
                isNotLoanorGrant.push(item)
            }
        })




        if ((notForeclosureLoans.length > 0 )) {
            errors.push({
                units: notForeclosureLoans,
                sectionIndex: 12,
                msg: ProgramActivityMessages.foreClosure6,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }


        closedGrantNumbers.forEach(item => {
            if (loanNumberPA2.indexOf(item) > -1 && PA2) {
                // PA2 = false;
                PAWarning2.push(item);
            }
        });
        //Removed based on bug WBAR-2863
        // if (PAWarning2.length > 0)
        //     errors.push({
        //         units: PAWarning2,
        //         sectionIndex: 4,
        //         msg: ProgramActivityMessages.foreClosure2,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });

        if (loanData.length < programActivity.numberOfLoansGenerated && programActivity.numberOfLoansGenerated !== 0) {
            errors.push({
                sectionIndex: 1,
                msg: ProgramActivityMessages.loanNumberMatch1,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }

        if (grantData.length < programActivity.numberOfLoansGrants && programActivity.numberOfLoansGrants !== 0) {
            errors.push({
                sectionIndex: 3,
                msg: ProgramActivityMessages.loanNumberMatch2,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }
        if (closedResaleLoans.length < programActivity.numberOfResales && programActivity.numberOfResales !== 0) {
            errors.push({
                msg: ProgramActivityMessages.loanNumberMatch3,
                sectionIndex: 5,
                errorType: 2,
                reportActivity: 1,
                programActivity: programActivity._id
            });
        }

        // if (loanData.length !== programActivity.numberOfLoansDeferred) {
        //     errors.push({
        //         msg: ProgramActivityMessages.loanNumberMatch3,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }

        // if (loanData.length !== programActivity.numberOfLoansInDefault) {
        //     errors.push({
        //         msg: ProgramActivityMessages.loanNumberMatch4,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }

        // if (loanData.length !== programActivity.numberOfForeclosuresLoans) {
        //     errors.push({
        //         msg: ProgramActivityMessages.loanNumberMatch5,
        //         errorType: 2,
        //         reportActivity: 1,
        //         programActivity: programActivity._id
        //     });
        // }
    }
    return errors;
}

export default ProgramActivity;