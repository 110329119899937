import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from "../helpers";
import momentTZ from 'moment-timezone'
const timeZone = momentTZ.tz.guess(true);

export const reportActivityServices = {
    getReportActivityAccess,
    getByIdCertificationForm,
    createCertificationForm,
    updateCertificationForm,
    getByIdFinancialActivity,
    createFinancialActivity,
    updateFinancialActivity,
    getByIdProgramActivity,
    createProgramActivity,
    updateProgramActivity,
    getContractActivity,
    getByIdContractActivity,
    createContractActivity,
    updateContractActivity,
    submitLoan,
    unsubmitLoan,
    submissionCheck,
    downloadReport,
    saveContractActivity,
    getIncomeLimit,
    getReportYear,
    getByReportIdContractActivity,
    validationIssue,
    deleteContractActivity
}

/**
 * Get One Certification Form 
 */
function getReportActivityAccess(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/tablesAccess/${reportYear_id}`, requestOptions).then(handleResponse);
}

/**
 * Get One Certification Form 
 */
function getByIdCertificationForm(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/certificateForm/${reportYear_id}`, requestOptions).then(handleResponse);
}

/**
 * Service call for certification form
 */
 function createCertificationForm(generalData) {
    // if (formData) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(generalData)
        };
        return fetch(`${globalVariable.apiURL}/report-activity/certificateForm/create`, requestOptions).then(handleResponse);
    // }
}

/**
 * Service call for certification form
 * @param {*} id 
 * @param {*} formData 
 * @returns 
 */
function updateCertificationForm(generalData) {
    // if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(generalData)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/certificateForm/update`, requestOptions).then(handleResponse);
    // }
}

/**
 * Get One Financial Activity
 */
 function getByIdFinancialActivity(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/financialActivity/${reportYear_id}`, requestOptions).then(handleResponse);
}

/**
 * Service call for creating Financial Activity
 */
 function createFinancialActivity(formData) {
    // if (formData) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        return fetch(`${globalVariable.apiURL}/report-activity/financialActivity`, requestOptions).then(handleResponse);
    // }
}

/**
 * Service call for updating Financial Activity
 * @param {*} id 
 * @param {*} formData 
 * @returns 
 */
function updateFinancialActivity(formData) {
    // if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/financialActivity`, requestOptions).then(handleResponse);
    // }
}

/**
 * Get One Program Activity
 */
 function getByIdProgramActivity(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/programActivity/${reportYear_id}`, requestOptions).then(handleResponse);
}

/**
 * Service call for creating Program Activity
 */
 function createProgramActivity(formData) {
    // if (formData) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        return fetch(`${globalVariable.apiURL}/report-activity/programActivity/create`, requestOptions).then(handleResponse);
    // }
}

/**
 * Service call for updating Contract Activity
 * @param {*} id 
 * @param {*} formData 
 * @returns 
 */
function updateProgramActivity(formData) {
    // if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/programActivity/update`, requestOptions).then(handleResponse);
    // }
}

/**
 * Get One Contract Activity
 */
 function getByIdContractActivity(loan_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/contractActivity/${loan_id}`, requestOptions).then(handleResponse);
}

/**
 * Service call for creating Contract Activity
 */
 function createContractActivity(formData) {
    // if (formData) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        return fetch(`${globalVariable.apiURL}/report-activity/contractActivity/create`, requestOptions).then(handleResponse);
    // }
}

/**
 * Service call for updating Contract Activity
 * @param {*} id 
 * @param {*} formData 
 * @returns 
 */
function updateContractActivity(formData) {
    // if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/contractActivity/update`, requestOptions).then(handleResponse);
    // }
}

/**
 * Get Contract Activity Data
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues  
 */
 function getContractActivity(skip, limit, orderBy, order, sfields, sValues, reportYear_id) {
    let data = {
        fields: sfields,
        values: sValues,
        reportYear_id: reportYear_id
    };

    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/report-activity/contractActivity/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timezone=${timeZone}`, requestOptions).then(handleResponse);
}

function submissionCheck(reportYear_id,comment) {
    if (reportYear_id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(comment)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/${reportYear_id}/submissionCheck`, requestOptions).then(handleResponse);
    }
}

function submitLoan(reportYear_id,comment) {
    if (reportYear_id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(comment)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/${reportYear_id}/submit`, requestOptions).then(handleResponse);
    }
}

/**
 * Service call update table 1 activity unsubmit
 *
 * @param {*} id contains reportyear id
 * @param {*} formData contains form data
 * @returns
 */
function unsubmitLoan(reportYear_id,comment) {
    if (reportYear_id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(comment)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/${reportYear_id}/un-submit`, requestOptions).then(handleResponse);
    }
}

/**
 * download report
 * @param {*} id
 * @returns
 */
function downloadReport(reportYear_id, reportYear) {
    if (reportYear_id) {
        let data = {
            reportYear_id: reportYear_id,
            reportYear
        };
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/downloadReport`, requestOptions).then(handleDownloadResponse);
    }
}

/**
 * Service call update table 1 activity unsubmit
 *
 * @param {*} id contains reportyear id
 * @param {*} formData contains form data
 * @returns
 */
function saveContractActivity(generalData) {
    if (generalData) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(generalData)
        };

        return fetch(`${globalVariable.apiURL}/report-activity/saveContractActivity`, requestOptions).then(handleResponse);
    }
}

/**
 * Get Income Limit
 */
 function getIncomeLimit(generalData) {
    if (generalData) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(generalData)
        };

        return fetch(`${globalVariable.apiURL}/configurations/incomeLimit`, requestOptions).then(handleResponse);
    }
}
// getReportYear

 function getReportYear(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/report/${reportYear_id}`, requestOptions).then(handleResponse);
}

function getByReportIdContractActivity(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/loanReportActivity/${reportYear_id}`, requestOptions).then(handleResponse);
}

function validationIssue(reportYear_id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };
    return fetch(`${globalVariable.apiURL}/report-activity/validationIssue/${reportYear_id}`, requestOptions).then(handleResponse);
}


 
/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
 function deleteContractActivity(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/report-activity/${id}`, requestOptions).then(handleResponse);
}
