import React, { Component } from "react";
import propTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { IconButton } from "@material-ui/core";

// Components
import TextBox from "../../components/TextBox/TextBox";
import DropDown from '../../components/DropDown/DropDown';
import { CloseIcon, SvgLoadIcon } from '../../components/SvgIcons/SvgIcons';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import Button from "../../components/Button/index"

//Helper
import { history, truncateString, orgTypesConstants, getOrgType, getUser,handleURLEscapeCharacters } from '../../helpers';

//Action
import { globalSearchActions } from '../../actions/globalSearch';

//Constants
import { searchConstants, searchHomeProgram, searchMoniteringFunder, searchMoniteringStatus, searchMoniteringStatusHO, searchNHTFProgram, searchConstantsHO, searchMoniteringFunderHO } from '../../constants/globalSearch';


/**
 * GlobalSearch Container
 * @class GlobalSearch
 * @extends {Component}
 */
class GlobalSearch extends Component {
    /**
     * 
     * Constructor
     */
    constructor(props) {

        super(props);
        this.state = {
            SearchText: '',
            SearchDropDown: this.props.access === 3 ? 1 : this.props.access === 2 ? 11 : 1,
            typing: false,
            HOSearch: false,
            mulFamilySearch: true,
            opsearchConstants: [],
        };
        this.typingTimeout = null;

        this.orgType = getOrgType();
        this.orgAccess = getUser().org.organizationAccess
    }
    /**
     * Text Box Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChangeTextBox = (name, value) => {
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }
        this.setState({
            [name]: value,
            typing: false,
        });

        this.typingTimeout = setTimeout(() => {
            this.props.getGlobalSearch(this.state.SearchText, this.state.SearchDropDown);
        }, 500)

    }
    /**
     * Clear Search Text
     */
    handleClear = () => {
        this.props.getGlobalSearch('', this.state.SearchDropDown);

        this.setState({
            SearchText: '',
            typing: false,
        });

    }
    /**
     * Drop Down Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChangeDropDown = (name, value) => {
        this.setState({
            SearchText: (this.state.SearchDropDown !== 7 && this.state.SearchDropDown !== 8 && this.state.SearchDropDown !== 9 && this.state.SearchDropDown !== 2 && this.state.SearchDropDown !== 13 && this.state.SearchDropDown !== 14) ? this.state.SearchText : '',
            [name]: value,
            selectedOption: {}
        }, () => {
            const searchValue = this.state.SearchText;
            const searchKey = this.state.SearchDropDown;
            this.props.getGlobalSearch(searchValue, searchKey);
        });
    }
    /**
     * Handle AutoComplete
     * @param {*} selectedOption 
     */
    handleAutoComplete = (selectedOption) => {
        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.setState({ SearchText: selectedOption.label, selectedOption: { ...selectedOption, id: selectedOption.value } }, () => {
            this.typingTimeout = setTimeout(() => {
                this.props.getGlobalSearch(this.state.SearchText, this.state.SearchDropDown);
            }, 500)
        });
    }
    /**
     * Navigate to project details
     * @param {*} item 
     */
    onClickList = (item) => {
        var { SearchDropDown } = this.state
        // to push to project or loan project
        if (SearchDropDown >= 10) {
            history.push({
                pathname: `/loanProjects/${item._id}`,
                state: {
                    page: 'loanProjectEdit',
                    pageData: {
                        project_id: item._id
                    }
                }
            })
        } else {
            history.push({
                pathname: `/projects/${item._id}`,
                state: {
                    page: 'projectEdit',
                    pageData: {
                        project_id: item._id
                    }
                }
            })
        }

        this.props.toggleDrawer(false);
        this.setState({ SearchText: '' })
        this.props.getGlobalSearch('', '');
    };
    /**
   * handle seach option click for HO
   */
    handleSearchClick = () => {
        this.setState({
            SearchText: "",
            HOSearch: true,
            mulFamilySearch: false,
            SearchDropDown: 11
        })
        this.props.globalSearch.data = []
    }
    /**
    * handle seach option click for HO
    */
    handleSearchClickMultiFamily = () => {
        this.setState({
            SearchText: "",
            HOSearch: false,
            mulFamilySearch: true,
            SearchDropDown: 1
        })
        this.props.globalSearch.data = []
    }
    /**
     * 
     */
    handleSubmit = () => { }
    /**
     * Render Search
     * 
     * [1]  -   Monitoring Funder
     * [2]  -   Home program
     * [3]  -   NHTF program
     * [4]  -   Monitoring Status
     * [5]  -   Others
     */
    renderSearch() {
        const { SearchText, SearchDropDown, selectedOption } = this.state;
        // [1]
        if (SearchDropDown === 2) {
            return (
                <Grid item xs={6} className="autocompleteList">
                    <AutoComplete
                        className="autocompleteTag"
                        suggestions={searchMoniteringFunder}
                        name="SearchText"
                        handleAutoCompolete={this.handleAutoComplete}
                        selectedValue={selectedOption}
                    />
                </Grid>
            )
        } else if (SearchDropDown === 13) {
            return (
                <Grid item xs={6} className="autocompleteList">
                    <AutoComplete
                        className="autocompleteTag autocompleteTags"
                        suggestions={searchMoniteringFunderHO}
                        name="SearchText"
                        handleAutoCompolete={this.handleAutoComplete}
                        selectedValue={selectedOption}
                    />
                </Grid>
            )
        }
        else if (SearchDropDown === 7) {
            return (
                <Grid item xs={6} className="autocompleteList">
                    <AutoComplete
                        className="autocompleteTag"
                        suggestions={searchHomeProgram}
                        name="SearchText"
                        handleAutoCompolete={this.handleAutoComplete}
                        selectedValue={selectedOption}
                    />
                </Grid>
            )
        } else if (SearchDropDown === 8) {
            // [3]
            return (
                <Grid item xs={6} className="autocompleteList">
                    <AutoComplete
                        className="autocompleteTag"
                        suggestions={searchNHTFProgram}
                        name="SearchText"
                        handleAutoCompolete={this.handleAutoComplete}
                        selectedValue={selectedOption}
                    />
                </Grid>
            )
        } else if (SearchDropDown === 9) {
            // [4]
            return (
                <Grid item xs={6} className="autocompleteList">
                    <AutoComplete
                        className="autocompleteTag"
                        suggestions={global._.sortBy(searchMoniteringStatus, 'label')}
                        name="SearchText"
                        handleAutoCompolete={this.handleAutoComplete}
                        selectedValue={selectedOption}
                    />
                </Grid>
            )
        } else if (SearchDropDown === 14) {
            return (
                <Grid item xs={6} className="autocompleteList">
                    <AutoComplete
                        className="autocompleteTag"
                        suggestions={global._.sortBy(searchMoniteringStatusHO, 'label')}
                        name="SearchText"
                        handleAutoCompolete={this.handleAutoComplete}
                        selectedValue={selectedOption}
                    />
                </Grid>
            )
        }
        else {
            return (
                <Grid item xs={6}>
                    <TextBox
                        id="input-with-icon-textfield"
                        name="SearchText"
                        className="globalSearch"
                        margin="normal"
                        field="SearchText"
                        value={SearchText}
                        clear={SearchText ? true : false}
                        handleClear={this.handleClear}
                        handleChange={this.handleChangeTextBox}
                        maxLength={20}
                    />
                </Grid>
            )
        }
    }

    /**
     * render
     */
    render() {
        const { anchor, variant, open, globalSearch, access } = this.props;
        const { SearchDropDown, HOSearch, mulFamilySearch } = this.state;
        var opsearchConstants = []
        // condition for drop down suggestions
        if (access === 3) {
            if (this.state.HOSearch) {
                opsearchConstants = [...searchConstantsHO]
            } else if (this.state.mulFamilySearch) {
                opsearchConstants = [...searchConstants]
            }
        } else if (access === 1) {
            opsearchConstants = [...searchConstants];
        } else {
            opsearchConstants = [...searchConstantsHO]
        }
        // show monitoring status for funder user
        if ((this.orgType === orgTypesConstants.FUNDER && access === 1) || (access === 3 && this.state.mulFamilySearch)) {
            opsearchConstants = [...opsearchConstants,
            {
                key: 9,
                name: 'Monitoring Status'
            }
            ]
        } else if (this.orgType === orgTypesConstants.FUNDER && (access === 2 || this.orgType !== orgTypesConstants.CONTRACTOR) &&
            this.orgType !== orgTypesConstants.PREPARER && this.orgType !== orgTypesConstants.OWNER && this.orgType !== orgTypesConstants.PROP_MGR && access === 3 && this.state.HOSearch) {
            opsearchConstants = [...opsearchConstants,
            {
                key: 14,
                name: 'Monitoring Status'
            }
            ]
        } else {
            opsearchConstants = [...opsearchConstants]
        }
        return (
            <Drawer
                anchor={anchor}
                variant={variant}
                open={open}
                className={'global-box'}
            >
                <div className='globalSearchList'>
                    <div className='drawerSearch'>
                        <div className="left-search">
                            <Typography className={'globalSearchTitle'}>Search</Typography>
                        </div>
                        <div className="right-search">
                            <IconButton size="small" onClick={() => {
                                this.props.toggleDrawer(false)
                                this.setState({ SearchText: '' })
                                this.props.getGlobalSearch('', '');
                            }
                            }>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                    {access === 3 ? (
                        <div className="globalSearchCategory">
                            <div>
                                <Button
                                    color={mulFamilySearch === true ? "primary" : "default"}
                                    title="multi family"
                                    onClick={this.handleSearchClickMultiFamily}>
                                </Button>
                            </div>
                            <div className="home-Ownership">
                                <Button
                                    color={HOSearch === true ? "primary" : "default"}
                                    title="home ownership"
                                    onClick={this.handleSearchClick}
                                    className="buttonsearchsss">
                                </Button>
                            </div>
                        </div>) : null
                    }

                    <ValidatorForm
                        name="logInForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }}
                    >
                        <Grid container spacing={3} className="sidebar_search">
                            {this.renderSearch()}

                            <Grid item xs={6}>
                                <DropDown
                                    id="SearchDropDown"
                                    margin="normal"
                                    name="SearchDropDown"
                                    className='width100per globalSearch'
                                    value={SearchDropDown}
                                    handleChange={this.handleChangeDropDown}
                                >
                                    {
                                        opsearchConstants.map((item) => {
                                            return <MenuItem key={item.key} value={item.key}>{item.name}</MenuItem>
                                        })
                                    }
                                </DropDown>
                            </Grid>

                            {globalSearch && globalSearch.data.length > 0 &&
                                <React.Fragment>
                                    <Grid item xs={6} >
                                        <Typography className='resultTitle'>
                                            <b>Project Name</b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Typography className='resultFound'>
                                            {globalSearch.data.length} Projects Found
                                        </Typography>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>

                        <div style={{ height: window.innerHeight - 200 }} className={'searchListContent'}>

                            {globalSearch && globalSearch.isLoading === false && globalSearch.data && globalSearch.data.length > 0 &&
                                <List component="nav" dense={true}>
                                    {
                                        globalSearch.data.map((item, index) => {
                                            return (
                                                <ListItem button
                                                    key={index}
                                                    value={item._id}
                                                    onClick={(evt) => { this.onClickList(item) }}>
                                                    <ListItemText className={'searchValue'} inset primary={truncateString(item.name, 35)} />
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>
                            }
                            {globalSearch && globalSearch.isLoading === true &&
                                <div className='searchLoader'>
                                    <SvgLoadIcon> </SvgLoadIcon>
                                </div>
                            }

                            {globalSearch && globalSearch.isLoading === false && globalSearch.data.length <= 0 &&
                                <div className='searchLoader'>
                                    <span>No Projects Found</span>
                                </div>
                            }
                        </div>
                    </ValidatorForm>
                </div>
            </Drawer >
        );
    }
}
/** Default props */
GlobalSearch.defaultProps = {
    anchor: 'right',
    variant: 'persistent',
    open: false,
    toggleDrawer: () => { },
}
/** Props types */
GlobalSearch.propTypes = {
    anchor: propTypes.string,
    variant: propTypes.string,
    open: propTypes.bool,
    classes: propTypes.object,
    toggleDrawer: propTypes.func,
    onKeyDown: propTypes.func
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownprops 
 * @returns 
 */
const mapStateToProps = ({ globalSearch }, ownprops) => {
    return {
        globalSearch: globalSearch
    }
}
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getGlobalSearch: (searchValue, searchKey) => dispatch(globalSearchActions.getGlobalSearch(searchValue, searchKey))
    }
}
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles({ withTheme: true })(GlobalSearch));