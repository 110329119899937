import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

// Helpers
import { history } from '../../../helpers';

// Components
import { SvgLoadIcon } from '../../../components/SvgIcons/SvgIcons';
import DonutChart from '../../../components/Charts/Donut Chart/DonutChart';

// Styles
import dashboardStyle from '../../../assets/jss/containers/dashboard';

/**
 *OccupancyRate
 *
 * @class OccupancyRate
 * @extends {Component}
 */
class OccupancyRate extends Component {
    /**
     *Creates an instance of OccupancyRate.
     * @param {*} props
     * @memberof OccupancyRate
     */
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    /**
     * Handle Click Action
     * @param {*} data 
     */
    handleClickAction = (data) => {
        history.push({
            pathname: `/occupants`,
            state: {
                page: 'occupantsList',
                pageData: {
                    data: data.data
                }
            }
        });
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof OccupancyRate
     */
    render() {
        const { classes, reportYear, occupancyRate } = this.props
        let currYear = new Date().getFullYear();
        return (
            <Grid item xs={12} sm={12}>
                <Card className={classes.occupancyRateContainer}>
                    <div className={classes.cardHeader}>
                        <div className="header">Occupancy Rate {reportYear}</div>
                    </div>
                    <div className='occupancyRateCard'>
                        <Grid item xs={12} sm={12} className="cardContainer">
                            {
                                occupancyRate.isLoading ?
                                    <div className='occupanceRateLoader'>
                                        <SvgLoadIcon className= "greyLoader"/>
                                    </div>
                                    :
                                    <div>
                                        <DonutChart
                                            isCurrentYear={currYear === +reportYear}
                                            data={occupancyRate.data.occupancyRate}
                                            occupiedUnits={occupancyRate.data.occupiedUnits}
                                            totalUnits={occupancyRate.data.totalUnits}
                                            handleClickAction={(data) => { this.handleClickAction(data) }} />
                                        <div className="cardText">Total Occupancy Rate</div>
                                        <div className="occupantCount"><span className="occupiedCount">{occupancyRate.data.occupiedUnits}</span>/<span className="totalCount">{occupancyRate.data.totalUnits}</span></div>
                                    </div>
                            }
                        </Grid>
                    </div>
                </Card>
            </Grid>
        )
    }
}
// prop types
OccupancyRate.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
OccupancyRate.defaultProps = {
    classes: {}
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};
// export OccupancyRate
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(OccupancyRate));