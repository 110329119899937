import { sitesConstants } from '../constants/sites';

const initialState = {
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['name'],
            searchCols: []
        }
    },
    getOne: {
        data: null,
        isLoading: false,
        projects: [],
        siteSortTypeOptions: [],
        onsiteUsers: [],
        users: [],
        orgs: [],
        orgsOnsite: []
    },
    isLoading: false,
    siteNameList: [],
    siteTable1List: false
}

export function sites(state = initialState, action) {
    switch (action.type) {
        case sitesConstants.ADD_SITE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sitesConstants.ADD_SITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case sitesConstants.ADD_SITE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case sitesConstants.GETALL_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne
                },
                isLoading: true,
            };
        case sitesConstants.GETALL_SUCCESS:
            return {
                ...state,
                ...action.sites,
                isLoading: false,
            };
        case sitesConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case sitesConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    ...initialState.getOne,
                    data: null,
                    isLoading: true
                }
            };
        case sitesConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: action.site.data,
                    isLoading: true
                },
                isLoading: false
            };
        case sitesConstants.GETONE_FAILURE:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    error: action.error,
                    isLoading: false
                }
            };
        case sitesConstants.CLEAR_FILTER_SET:
            return {
                ...state,
                reset: true,
            };
        case sitesConstants.CLEAR_FILTER_RESET:
            return {
                ...state,
                reset: false,
            };
        case sitesConstants.GETALL_ORGANIZATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sitesConstants.GETALL_ORGANIZATION_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    orgs: action.suggestions
                },
                isLoading: false,
            };
        case sitesConstants.GETALL_ORGANIZATION_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case sitesConstants.GETALL_ORGANIZATION_ONSITE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sitesConstants.GETALL_ORGANIZATION_ONSITE_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    orgsOnsite: action.suggestions
                },
                isLoading: false,
            };
        case sitesConstants.GETALL_ORGANIZATION_ONSITE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case sitesConstants.GET_ORG_USERS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case sitesConstants.GET_ORG_USERS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    users: action.users
                },
                isLoading: false
            }
        case sitesConstants.GET_ORG_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case sitesConstants.GET_ORG_USERS_ROLE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case sitesConstants.GET_ORG_USERS_ROLE_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    onsiteUsers: action.users
                },
                isLoading: false
            }
        case sitesConstants.GET_ORG_USERS_ROLE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case sitesConstants.DELETE_REQUEST:
            // add 'deleting:true' property to site being deleted
            return {
                ...state,
                data: state.data.map(site =>
                    site.id === action.id
                        ? { ...site, deleting: true }
                        : site
                )
            };
        case sitesConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                data: state.data.filter(site => site._id !== action.id)
            };
        case sitesConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                data: state.data.map(site => {
                    if (site.id === action.id) {
                        // make copy of user without 'deleting:true' property
                        const { deleting, ...siteCopy } = site;
                        // return copy of user with 'deleteError:[error]' property
                        return { ...siteCopy, deleteError: action.error };
                    }

                    return site;
                })
            };
        case sitesConstants.GETALL_PROJECT_LISTS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case sitesConstants.GETALL_PROJECT_LISTS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    projects: action.suggestions
                },
                isLoading: false,
            }
        case sitesConstants.GETALL_PROJECT_LISTS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case sitesConstants.UPDATE_SITE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case sitesConstants.UPDATE_SITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case sitesConstants.UPDATE_SITE_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case sitesConstants.UPDATE_SITE_CALIM_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case sitesConstants.UPDATE_SITE_CALIM_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case sitesConstants.UPDATE_SITE_CALIM_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case sitesConstants.GET_SITENAME_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case sitesConstants.GET_SITENAME_SUCCESS:
            return {
                ...state,
                siteNameList: action.data.data,
                isLoading: false
            }
        case sitesConstants.GET_SITENAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
            case sitesConstants.GET_SITE_TABLE1_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                }
            case sitesConstants.GET_SITE_TABLE1_SUCCESS:
                return {
                    ...state,
                    siteTable1List: action.data.data,
                    isLoading: false
                }
            case sitesConstants.GET_SITE_TABLE1_FAILURE:
                return {
                    ...state,
                    error: action.error,
                    isLoading: false,
                }

        default:
            return state
    }
}