import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import dashboardStyle from '../../assets/jss/containers/dashboard';
import { Grid } from '@material-ui/core';

// import components
import PSBUCounts from './DashboardSummary/PSBUCounts';
import ReportingStatus from './DashboardSummary/ReportingStatus';
import UsersSummary from './DashboardSummary/UsersSummary';

// import helper
import { getUserType } from '../../helpers';

/**
 *DashboardSummary
 *
 * @class DashboardSummary
 * @extends {Component}
 */
class DashboardSummary extends Component {
    /**
     *Creates an instance of DashboardSummary.
     * @param {*} props
     * @memberof DashboardSummary
     */
    constructor(props) {
        super(props);
        this.state = {

        }
        this.userRole = getUserType();
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof DashboardSummary
     */
    render() {
        const { classes, reportYear, PCLCounts, reportStatus } = this.props;
        return (
            <div >
                <Grid container spacing={2} className={classes.DashboardContainer}>
                    <Grid item xs={12} sm={12}>
                        <PSBUCounts
                            PCLCounts={PCLCounts.data}
                            isLoading={PCLCounts.isLoading}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <ComplianceIssues
                            reportYear={reportYear}
                        />
                    </Grid> */}
                </Grid>
                <Grid container spacing={2} className={classes.DashboardContainer}>
                    {/* <Grid item xs={12} sm={4}>
                        <LoanRate
                            reportYear={reportYear}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <ReportingStatus
                            reportYear={reportYear}
                            reportStatus={reportStatus} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.DashboardContainer}>
                    <Grid item xs={12} >
                        <UsersSummary
                            reportYear={reportYear} />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

// prop types
DashboardSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};

/**
 * Default Props
 */
DashboardSummary.defaultProps = {
    classes: {}
};

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};


// export DashboardSummary
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardSummary));