import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Fab';
import CoreButton from '@material-ui/core/Button';
import { Menu, MenuItem } from '@material-ui/core';

// Imports Styles
import pageActionsStyle from '../../assets/jss/components/pageActions'

// Imports Components
import ToolTip from '../../components/ToolTip/ToolTip';

// SVG Icons
import { BuildingsIcon, CommentsIcon, DownloadIcon, EyeIcon, FilterIcon, HelpIcon, MergeIcon, UnlinkIcon, PencilsquareIcon, PlusIcon, PrintIcon, RocketIcon, SelectedIcon, UnclaimIcon, UnitsIcon, UploadIcon, VerifyData, XmlIcon, AssignFunder, EditTableHeaders, SaveIcon, ExploreDataIcon } from '../SvgIcons/SvgIcons';

// Helpers
import { history } from '../../helpers';

const currYear = new Date().getFullYear();
const reportYears = [currYear, currYear - 1, currYear - 2, currYear - 3, currYear - 4, currYear - 5, currYear - 6, currYear - 7, currYear - 8, currYear - 9, currYear - 10, currYear - 11]

/**
 *Page actions component
 *
 * @class PageActions
 * @extends {Component}
 */
class PageActions extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }


    /**Handle Add Event on clicking */
    handleAddActionClick = () => {
        this.props.handleAddActionClick();
    };
    /**Handle Claim Event on Clicking */
    handleClaimActionClick = () => {
        this.props.handleClaimActionClick();
    }
    /**Handle UnClaim Event on Clicking */
    handleUnClaimActionClick = () => {
        this.props.handleUnClaimActionClick();
    }
    /**Handle Move In Event on clicking */
    handleMoveInActionClick = () => {
        this.props.handleMoveInActionClick();
    }
    /**Handle Save Event on cliking */
    handleSaveActionClick = () => {
        this.props.handleSaveActionClick();
    };
    /**Handle Print Event on clicking */
    handlePrintActionClick = () => {
        this.props.handlePrintActionClick();
    };
    /**Handle Download Event on clicking */
    handleDownloadActionClick = (e) => {
        this.props.handleDownloadActionClick(e);
    };
    /**Handle Upload Event on clicking */
    handleUploadActionClick = (event) => {
        this.props.handleUploadActionClick(event);
    };
    /**Handle Upload Action on Clicking */
    handleUploadButtonActionClick = () => {
        this.props.handleUploadButtonActionClick();
    }
    /**Handle Clear Filter Event on clicking */
    handleClearFilterActionClick = () => {
        this.props.handleClearFilterActionClick();
    };
    /**Handle submit on clicking */
    handleSubmitActionClick = () => {
        this.props.handleSubmitActionClick();
    };
    /**Handle unsubmit on clicking */
    handleUnSubmitActionClick = () => {
        this.props.handleUnSubmitActionClick();
    };

    /**
     * A method to call back the Field Filter action props passed to the Page Actions
     *
     * @memberof PageActions
     */
    handleFieldFilterActionClick = () => {
        this.props.handleFieldFilterActionClick();
    };

    /**
     * Goto Building List
     */
    handleGotoBuildingListActionClick = () => {
        this.props.handleGotoBuildingListActionClick();
    }

    /**
     * Goto Building List
     */
    handleGotoUnitsListActionClick = () => {
        this.props.handleGotoUnitsListActionClick();
    }

    /**
         * Goto Table1 List
     */
    handleGotoTable1ListActionClick = () => {
        this.props.handleGotoTable1ListActionClick();
    }

    handleGotoSettingsActionClick = () => {
        this.props.handleGotoSettingsActionClick();
    }

    handleTable1ImportActionClick = () => {
        this.props.handleTable1ImportActionClick();
    }
    
    handleXMLImportActionClick = () => {
        this.props.handleXMLImportActionClick();
    }

    handleMergeActionClick = () => {
        this.props.handleMergeActionClick();
    }

    handleRefreshValidationsClick = () => {
        this.props.handleRefreshValidationsClick();
    }

    /**
     * Prop Method to call delete occupant method
     *
     */
    handleDeleteOccupantActionClick = () => {
        this.props.handleDeleteOccupantActionClick();
    }

    handleCommentActionClick = () => {
        this.props.handleCommentActionClick();
    }

    handleHelpActionClick = () => {
        this.props.handleHelpActionClick();
    }

    handleFilterActionClick = () => {
        this.props.handleFilterActionClick();
    }

    handleEditTableHeadersActionClick = () => {
        this.props.handleEditTableHeadersActionClick();
    }

    handleSendActionClick = () => {
        this.props.handleSendActionClick();
    }

    handlePreviewActionClick = () => {
        this.props.handlePreviewActionClick();
    }

    handleReportYearActionClick = (year) => {
        this.props.handleReportYearActionClick(year);
    }

    /**Handle Close Event */
    handleAssignFunderActionClick = () => {
        this.props.handleAssignFunderActionClick();
    };

    handleGotoExploreDataClick = () => {
        this.props.handleGotoExploreDataClick();
    }

    /**
     * Handle Menu Event
     */
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    /**Handle Close Event */
    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    /**
     * Handle Re-upload Event
     */
    handleReUploadButtonActionClick = () => {
        this.props.handleReUploadButtonActionClick()
    };

    /**
    * Handle OFF Maintenance Event
    */
    handleOffMaintenanceActionClick = () => {
        this.props.handleOffMaintenanceActionClick()
    };

    /**
     * Handle Edit Event
     */
    handleEditButtonActionClick = (e) => {
        this.props.handleEditButtonActionClick(e)
    }

    /**
     * Handle Verify Event
     */
    handleVerifyButtonActionClick = () => {
        this.props.handleVerifyButtonActionClick()
    }

    /**
     * Handle Logs Event
     */
    handleLogsActionClick = () => {
        this.props.handleLogsActionClick()
    }

    /**
     *render html
     *
     * @returns
     * @memberof PageActions
     */
    render() {
        var y = history.location.pathname.split('/')
        var doesLoan = y.includes('loanProjects')
        // let dashboardHo = y.includes('dashboardho')
        const { classes, actions, isSubPage, reportYear, downloadColor, uploadTooltip, xmlTitle, homePurchase, toolTipTitle } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            // <React.Fragment>
            //     {actions && actions.length > 0 &&
            <div className={isSubPage === true ? classes.subPage : classes.root} >

                {actions.indexOf('OFFMAINTENANCE') >= 0 &&
                    <div className={'anchorIconLink icon-height-21'} style={{ top: 'unset' }}>
                        {/* <ToolTip title="Off Maintenance" placement="bottom"> */}
                        <CoreButton
                            variant="contained"
                            color="primary"
                            size="small"
                            component="span"
                            style={{ "textTransform": "none", top: 'unset' }}
                            onClick={this.handleOffMaintenanceActionClick}
                        >
                            OFF Maintanence Mode
                            </CoreButton>
                        {/* </ToolTip> */}
                    </div>
                }
                {actions.indexOf('SAVEBUTTON') >= 0 &&
                    <div className={'anchorIconLink icon-height-21'} style={{ top: 'unset' }}>
                        {/* <ToolTip title="Off Maintenance" placement="bottom"> */}
                        <CoreButton
                            variant="contained"
                            color="primary"
                            size="large"
                            component="span"
                            style={{ "textTransform": "none", top: 'unset' }}
                            onClick={this.handleSaveActionClick}
                        >
                            Save
                            </CoreButton>
                        {/* </ToolTip> */}
                    </div>
                }
                {actions.indexOf('VERIFYDATA') >= 0 &&
                    <div className={'anchorIconLink'}>
                        <ToolTip title="Verify Data" placement="bottom">
                            {
                                // eslint-disable-next-line
                                <a className="add-rows" onClick={this.handleVerifyButtonActionClick}>
                                    <VerifyData />
                                Verify Data
                            </a>
                            }
                        </ToolTip>
                    </div>
                }
                {actions.indexOf('EDIT') >= 0 &&
                    <div className={'anchorIconLink icon-height-21'}>
                        <ToolTip title="Download & Edit Data" placement="bottom">
                            {
                                // eslint-disable-next-line
                                <a className="add-rows" onClick={this.handleEditButtonActionClick}>
                                    <PencilsquareIcon />
                                Download & Edit Data
                            </a>
                            }
                        </ToolTip>
                    </div>
                }
                {actions.indexOf('RE-UPLOAD') >= 0 &&
                    <div className={'anchorIconLink icon-height-21'}>
                        <ToolTip title="Re-upload Data" placement="bottom">
                            {
                                // eslint-disable-next-line
                                <a className="add-rows" onClick={this.handleReUploadButtonActionClick}>
                                    <UploadIcon />
                                Re-upload Data
                            </a>
                            }
                        </ToolTip>
                    </div>
                }
                {actions.indexOf('ASSIGNFUNDER') >= 0 &&
                    <ToolTip title="Assign Funder" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Send" className={classes.button} onClick={this.handleAssignFunderActionClick}>
                            <AssignFunder />
                        </Button>
                    </ToolTip>

                }
                {actions.indexOf('ADDLINK') >= 0  &&
                    <div className={'anchorIconLink addRow'}>                       
                        <ToolTip title="Add Row" placement="bottom">
                            {
                                // eslint-disable-next-line
                                <a  className={doesLoan && homePurchase ? "disableAddRow add-rows": doesLoan ? "add-rows  colorAddrow":"add-rows  "} onClick={homePurchase? null:this.handleAddActionClick}>
                                    <div className={doesLoan && homePurchase?'loanAddDisabled roundCircledisabled':doesLoan ?'loanAddIcons ':'roundCircle1'}>
                                    <PlusIcon  height='14px' width='12px' fill={doesLoan && homePurchase ?'grey':null} />
                                    </div>
                                    Add Rows
                                </a>
                            }
                        </ToolTip>
                    </div>
                //     :
                //     <div className={'anchorIconLink addRow'}>
                //     <ToolTip title="Add Row" placement="bottom">
                //         {
                //             // eslint-disable-next-line
                //             <a className={doesLoan?"add-rows  colorAddrow":"add-rows  "} onClick={this.handleAddActionClick}>
                //             <div className={doesLoan?'loanAddIcons':'roundCircle1'}>
                //                 <PlusIcon height='14px' width='12px' />
                //             </div>
                //             Add Rows
                //         </a>
                // }
                //     </ToolTip>
                // </div>
                }

                {actions.indexOf('SCHEDULE') >= 0 &&
                    <ToolTip title="Schedule" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Move In" className={classes.button} onClick={this.handleMoveInActionClick}>
                            <i className="fa fa-clock-o"></i>
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('MOVEIN') >= 0 &&
                    <ToolTip title="Move In" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Move In" className={classes.button} onClick={this.handleMoveInActionClick}>
                            <PlusIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('ADD') >= 0 &&
                    <ToolTip title="Add" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Add" className={doesLoan ? "loanColorUpdate" : classes.button} onClick={this.handleAddActionClick}>
                            <PlusIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('CLAIM') >= 0 &&
                    <ToolTip title="Claim" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Claim" className={classes.button} onClick={this.handleClaimActionClick}>
                            <i className="icon-claim"></i>
                        </Button>
                    </ToolTip>
                }


                {actions.indexOf('UNCLAIM') >= 0 &&
                    <ToolTip title="Unclaim" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Unclaim" className={classes.button} onClick={this.handleUnClaimActionClick}>
                            <UnclaimIcon />
                        </Button>
                    </ToolTip>
                }

                {/* {actions.indexOf('REFRESH_VALIDATIONS') >= 0 &&
                    <ToolTip title="Refresh Validations" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Refresh Validations" className={classes.button} onClick={this.handleRefreshValidationsClick}>
                            <i className="fa fa-refresh"></i>
                        </Button>
                    </ToolTip>
                } */}

                {actions.indexOf('SUBMIT') >= 0 &&
                    <ToolTip title="Submit" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Clear" className={classes.button} onClick={this.handleSubmitActionClick}>
                            <RocketIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('UNSUBMIT') >= 0 &&
                    <ToolTip title="Unsubmit" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Clear" className={classes.button} onClick={this.handleUnSubmitActionClick}>
                            <UnlinkIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('MERGE') >= 0 &&
                    <ToolTip title={toolTipTitle ? toolTipTitle : 'Merge'} placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} onClick={this.handleMergeActionClick}>
                            <MergeIcon />
                        </Button>
                    </ToolTip>
                }

                {
                    actions.indexOf('LOANSAVE') >= 0 &&
                    <ToolTip title="Save" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} onClick={this.handleSaveActionClick}>
                            <SaveIcon />
                        </Button>
                    </ToolTip>
                }
                {actions.indexOf('MERGEORG') >= 0 &&
                    <ToolTip title="Merge Organizations" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Merge" className={classes.button} onClick={this.handleMergeActionClick}>
                            <MergeIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('PRINT') >= 0 &&
                    <ToolTip title="Print" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Print" className={classes.button} onClick={this.handlePrintActionClick}>
                            <PrintIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('REPORTYEAR') >= 0 &&
                    <span className="reportYear">
                        <span
                            aria-owns={open ? 'menu-appbar' : null}
                            aria-haspopup="true"
                            onClick={this.handleMenu}>
                            Report Year {reportYear}
                            <ToolTip
                                title="Report Year"
                                placement="bottom">
                                <i className="fa fa-angle-down"></i>
                            </ToolTip>
                        </span>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={this.handleClose}
                        >
                            {
                                reportYears.map((year, i) => (
                                    <MenuItem
                                        key={i}
                                        onClick={() => {
                                            this.handleClose();
                                            this.handleReportYearActionClick(year)
                                        }}
                                        button >{year}</MenuItem>
                                ))
                            }

                        </Menu>
                    </span>
                }

                {actions.indexOf('LOGS') >= 0 &&
                    <ToolTip title="Logs" placement="bottom">
                        <Button variant="circular" size="small" color='default' aria-label="Logs" className={classes.button} onClick={this.handleLogsActionClick}>
                            <i className="fa fa-server"></i>
                        </Button>
                    </ToolTip>
                }
                {actions.indexOf('DOWNLOAD') >= 0 &&
                    <ToolTip title="Download" placement="bottom">
                        <Button variant="circular" size="small" color={downloadColor} aria-label="Download" className={classes.button} onClick={this.handleDownloadActionClick}>
                            {downloadColor === 'secondary' ? <DownloadIcon fill='#fff' /> : <DownloadIcon />}

                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('UPLOAD_BULK_UTILITYALLOWANCE') >= 0 &&
                    <ToolTip title={uploadTooltip} placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Upload" className={classes.button} onClick={this.handleUploadActionClick}>
                            <UploadIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('UPLOAD') >= 0 &&
                    <ToolTip title="Upload" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Upload" className={classes.button} onClick={this.handleUploadButtonActionClick}>
                            <UploadIcon />
                        </Button>
                    </ToolTip>
                }

              
                {actions.indexOf('TABLE1_IMPORT') >= 0 &&
                    <ToolTip title="Import Table 1" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Import Table 1" className={classes.button} onClick={this.handleTable1ImportActionClick}>
                            <XmlIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('XML_IMPORT') >= 0 &&
                    <ToolTip title={xmlTitle} placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Import xml" className={classes.button} onClick={this.handleXMLImportActionClick}>
                            <XmlIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('FILTER') >= 0 &&
                    <ToolTip title="Select the Column" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Field Filter" className={classes.button} onClick={this.handleFieldFilterActionClick}>
                            <SelectedIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('LINK_TO_EXPLORE') >= 0 && 
                    <ToolTip title="Explore Data" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Clear" className={classes.button} onClick={this.handleGotoExploreDataClick}>
                            <PlusIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('CLEAR') >= 0 &&
                    <ToolTip title="Clear Filter" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Clear" className={classes.button} onClick={this.handleClearFilterActionClick}>
                            <FilterIcon />
                        </Button>
                    </ToolTip>
                }

                
                {actions.indexOf('BUILDINGLIST') >= 0 &&
                    <ToolTip title="Goto Building List" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Clear" className={classes.button} onClick={this.handleGotoBuildingListActionClick}>
                            <BuildingsIcon fill='#FFFFFF' />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('GOTOUNITSLIST') >= 0 &&
                    <ToolTip title="Goto Units List" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Clear" className={classes.button} onClick={this.handleGotoUnitsListActionClick}>
                            <UnitsIcon fill='#FFFFFF' />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('GOTOTABLE1LIST') >= 0 &&
                    <ToolTip title="Goto Table1 List" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Clear" className={classes.button} onClick={this.handleGotoTable1ListActionClick}>
                            <i className="icon-cityscape-2"></i>
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('SETTINGS') >= 0 &&
                    <ToolTip title="Goto Compliance Settings" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Clear" className={classes.button} onClick={this.handleGotoSettingsActionClick}>
                            <i className="lnr lnr-cog"></i>
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('DELETE_OCCUPANT') >= 0 &&
                    <ToolTip title="Delete Household" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Delete Household" className={classes.button} onClick={this.handleDeleteOccupantActionClick}>
                            <i className="fa fa-trash-o"></i>
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('COMMENT') >= 0 &&
                    <ToolTip title="Comment" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Comment" className={classes.button} onClick={this.handleCommentActionClick}>
                            <CommentsIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('HELP') >= 0 &&
                    <ToolTip title="Help" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Help" className={classes.button} onClick={this.handleHelpActionClick}>
                            <HelpIcon fill="#777777" height='14px' width='14px' />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('EDITTABLEHEADERS') >= 0 &&
                    <ToolTip title="Edit Columns" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Filter" className={classes.button} onClick={this.handleEditTableHeadersActionClick}>
                            <EditTableHeaders />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('ATTRIBUTEFILTER') >= 0 &&
                    <ToolTip title="Filter" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Filter" className={classes.button} onClick={this.handleFilterActionClick}>
                            <FilterIcon/>
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('PREVIEW') >= 0 &&
                    <ToolTip title="Preview" placement="bottom">
                        <Button variant="circular" size="small" color="default" aria-label="Preview" className={classes.button} onClick={this.handlePreviewActionClick}>
                            <EyeIcon />
                        </Button>
                    </ToolTip>
                }

                {actions.indexOf('PREVIEWLINK') >= 0 &&
                    <div className={'anchorIconLinkRight'}>
                        <ToolTip title="Preview" placement="bottom">
                            {
                                // eslint-disable-next-line
                                <a className="add-rows" onClick={this.handlePreviewActionClick}>
                                    <EyeIcon fill='#36aebe' />
                                    Preview
                                </a>
                            }
                        </ToolTip>
                    </div>
                }

                {actions.indexOf('VIEW') >= 0 &&
                    <div className={'anchorIconLinkRight'}>
                        <ToolTip title="View" placement="bottom">
                            {
                                // eslint-disable-next-line
                                <a className="add-rows" onClick={this.handlePreviewActionClick}>
                                    <EyeIcon/>
                                    View
                                </a>
                            }
                        </ToolTip>
                    </div>
                }

                {actions.indexOf('SEND') >= 0 &&
                    <ToolTip title="Send" placement="bottom">
                        <Button variant="circular" size="small" color="secondary" aria-label="Send" className={classes.button} onClick={this.handleSendActionClick}>
                            <i className="fa fa-send-o"></i>
                        </Button>
                    </ToolTip>
                }

                
             
            </div>
            //     } 
            // </React.Fragment>
        );
    }
}

//default props
PageActions.defaultProps = {
    actions: [],
    classes: {},
    xmlTitle: "Import xml",
    toolTipTitle: "",
    isSubPage: false,
    downloadColor: 'default',
    iconsColor: 'default',
    uploadTooltip: 'Upload',
    handleAddActionClick: () => { },
    handleClaimActionClick: () => { },
    handleUnClaimActionClick: () => { },
    handlePrintActionClick: () => { },
    handleDownloadActionClick: () => { },
    handleUploadActionClick: () => { },
    handleUploadButtonActionClick: () => { },
    handleClearFilterActionClick: () => { },
    handleGotoBuildingListActionClick: () => { },
    handleGotoUnitsListActionClick: () => { },
    handleGotoTable1ListActionClick: () => { },
    handleSubmitActionClick: () => { },
    handleUnSubmitActionClick: () => { },
    handleGotoSettingsActionClick: () => { },
    handleMoveInActionClick: () => { },
    handleTable1ImportActionClick: () => { },
    handleXMLImportActionClick: () => { },
    handleMergeActionClick: () => { },
    handleSaveActionClick: () => { },
    handleRefreshValidationsClick: () => { },
    handleDeleteOccupantActionClick: () => { },
    handleCommentActionClick: () => { },
    handleHelpActionClick: () => { },
    handleFilterActionClick: () => { },
    handlePreviewActionClick: () => { },
    handleReportYearActionClick: () => { },
    handleSendActionClick: () => { },
    handleReUploadButtonActionClick: () => { },
    handleEditButtonActionClick: () => { },
    handleVerifyButtonActionClick: () => { },
    handleLogsActionClick: () => { },
    handleAssignFunderActionClick: () => { },
    handleOffMaintenanceActionClick: () => { },
    handleEditTableHeadersActionClick: () => { },
    handleGotoExploreDataClick: () => { },
    homePurchase:false
}

//prop types
PageActions.propTypes = {
    actions: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    isSubPage: PropTypes.bool,
    handleAddActionClick: PropTypes.func,
    handleClaimActionClick: PropTypes.func,
    handleUnClaimActionClick: PropTypes.func,
    handleSaveActionClick: PropTypes.func,
    handlePrintActionClick: PropTypes.func,
    handleDownloadActionClick: PropTypes.func,
    handleUploadActionClick: PropTypes.func,
    handleUploadButtonActionClick: PropTypes.func,
    handleClearFilterActionClick: PropTypes.func,
    handleGotoBuildingListActionClick: PropTypes.func,
    handleGotoUnitsListActionClick: PropTypes.func,
    handleGotoTable1ListActionClick: PropTypes.func,
    handleSubmitActionClick: PropTypes.func,
    handleUnSubmitActionClick: PropTypes.func,
    handleXMLImportActionClick: PropTypes.func,
    handleGotoSettingsActionClick: PropTypes.func,
    handleMoveInActionClick: PropTypes.func,
    handleMergeActionClick: PropTypes.func,
    handleSendActionClick: PropTypes.func,
    handleReUploadButtonActionClick: PropTypes.func,
    handleEditButtonActionClick: PropTypes.func,
    handleVerifyButtonActionClick: PropTypes.func,
    handleLogsActionClick: PropTypes.func,
    handleAssignFunderActionClick: PropTypes.func,
    handleEditTableHeadersActionClick: PropTypes.func,
    handleGotoExploreDataClick: PropTypes.func,
    xmlTitle: PropTypes.string,
    toolTipTitle: PropTypes.string,
    homePurchase: PropTypes.bool
};

/**
 * Export Component
 */
export default withStyles(pageActionsStyle)(PageActions);
