import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Import Components
import TextArea from '../../../../components/TextArea/TextArea';
import NumberField from '../../../../components/NumberField/NumberField';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import PageActions from '../../../../components/PageActions/PageActions';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';

// Helpers
import { getDebtServices } from '../../../../helpers/Table4Calc';

const fieldClass = {
    monthyLoanPayment: 'txt-right',
    rate: 'txt-right',
    fundsPaid: 'txt-right',
    amortizationYears: 'txt-right',
    termYears: 'txt-right'
};
/**
 * EditableTextAmt Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * EditableTextFloat Component
 * @class EditableTextFloat
 * @extends {Component}
 */
class EditableTextFloat extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required', 'minNumber: 0']}
                errorMessages={['this field is required', 'Should not be Negative']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly}
                prefix={''}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * EditableTextInteger Component
 * @class EditableTextInteger
 * @extends {Component}
 */
class EditableTextInteger extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required', 'minNumber: 0']}
                errorMessages={['this field is required', 'Should not be Negative']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly}
                prefix={''}
                thousandSeparator={true}
                decimalScale={0}
            />
        )
    }
};
/**
 * EditableTextComment Component
 * @class EditableTextComment
 * @extends {Component}
 */
class EditableTextComment extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        const isRequired = data.lender_id === 19 || data.lender_id === 26
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextArea
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                validators={isRequired ? ['required'] : ''}
                errorMessages={isRequired ? ['This field is required'] : ''}
                disabled={isReadOly}
                multiline={true}
            />
        )
    }
};
/**
 * LenderTypes Component
 * @class LenderTypes
 * @extends {Component}
 */
class LenderTypes extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { lenders, isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled ${fieldClass[fieldName]}` : `${fieldClass[fieldName]}`
        return (
            <div className="autocomplete-border autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={lenders}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    className={className}
                    selectedValue={{ id: data[fieldName] || '' }}
                    disabled={isReadOly}
                    validators={['required']}
                    errorMessages={['this field is required']}

                />
            </div>
        )
    }
};


const componentMap = {
    lender_id: LenderTypes,
    otherFunderComment: EditableTextComment,
    monthyLoanPayment: EditableTextAmt,
    rate: EditableTextFloat,
    amortizationYears: EditableTextInteger,
    termYears: EditableTextInteger,
    fundsPaid: EditableTextAmt
};
/**
 * DebtServices Component
 * @class DebtServices
 * @extends {Component}
 */
class DebtServices extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {}
    }
    /**
     * Cell Component Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        value = value === undefined ? null : value;
        this.props.onChange('debtServices', fieldName, rowIndex, value);
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                value={value}
                prefix={'$'}
                thousandSeparator={true}
                displayType={'text'}
            />
        )
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { data, config, classes, isDisabled } = this.props;
        let { totalDebtService, cashFlow } = getDebtServices(data);
        let isNegativeCashFLow = cashFlow < 0;
        totalDebtService = this.displayAmount(totalDebtService);
        cashFlow = this.displayAmount(cashFlow);
        return (
            <Grid container spacing={3}>
                < Grid item xs={12} sm={12}>
                    <div className={'tableContainerScroll'} >
                        <EditableTable
                            headerCols={['Lender', 'Other Funder / Comment', 'Monthly Loan Payment',
                                'Rate %', 'Amortization (Years)', 'Term (Years)', 'Funds Paid']}
                            fieldCols={Object.keys(componentMap)}
                            rowDatas={data.debtServices}
                            needTitle={false}
                            actions={isDisabled ? [] : ['DELETE']}
                            isReadOnly={isDisabled}
                            componentMap={componentMap}
                            cellComponentOnChange={this.cellComponentOnChange}
                            config={{ ...config, classes, isReadOly: isDisabled }}
                            tableClass={'fieldWidth12'}
                            footerDatas={[
                                {
                                    'termYears_footer': 'Total Deb Service:',
                                    'fundsPaid_footer': totalDebtService
                                },
                                {
                                    'termYears_footer': 'Cash Flow (NOI-Debt Service):',
                                    'fundsPaid_footer': cashFlow
                                }
                            ]}
                            onDelete={(item) => {
                                this.props.onDelete('debtServices', item)
                            }}
                        >
                            <Grid item xs={12} sm={12}>
                                <PageActions
                                    isSubPage={true}
                                    actions={isDisabled ? [] : ['ADDLINK']}
                                    handleAddActionClick={() => { this.props.onAdd('debtServices') }}
                                />
                            </Grid>
                        </EditableTable>
                    </div>
                </ Grid>
                {
                    isNegativeCashFLow && (
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <h4 className="section_title">{'Please explain why Cash Flow is Negative.'}</h4>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextArea
                                    name="negativeCashFlowNotes"
                                    value={data.negativeCashFlowNotes}
                                    label="Cash Flow is negative failed for the following reasons"
                                    className={classes.textField}
                                    margin="dense"
                                    handleChange={this.props.handleChange}
                                    disabled={isDisabled}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    multiline={true}
                                />
                            </Grid>
                        </React.Fragment>
                    )
                }
            </Grid>
        )
    }
}
/** Props Types */
DebtServices.propTypes = {
    classes: PropTypes.object.isRequired
}
/** Default Props */
DebtServices.defaultProps = {
    data: {},
    config: {},
    classes: {},
    pageAccess: [],
    expandOnError: false,
}
/** Export Component */
export default DebtServices;