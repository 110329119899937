import React, { Component } from "react";
import "./style.css";

/**
 * Upload Container
 * @class Upload
 * @extends {Component}
 */
class ProcessBar extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      circle: 3,
      active: 0,
      width: 30,
      stageList: ["DOWNLOAD TEMPLATE", "UPLOAD & VALIDATE", "SUBMIT"],
    };
  }
  /**
   * Component will receive props
   * @param {*} newProps
   */
  componentWillReceiveProps = async (newProps) => {
    await this.setState({ active: parseInt(newProps.step) });
    this.state.active == 0 ?
      this.setState({width: 30})
    : this.state.active < 2 && this.state.active > 0
      ? this.setState({
          width: (100 / (this.state.circle - 1.05)) * this.state.active,
        })
      : this.state.active === 2 &&
        this.setState({
          width: (100 / (this.state.circle - 1)) * this.state.active,
        });
  };

  render() {
    const stage = [];

    for (let i = 1; i <= this.state.circle; i++) {
      stage.push(
        <span
          className={i <= this.state.active + 1 ? "circle active" : "circle"}
          style={
            i - 1 > this.state.active
              ? {
                  width: "8px",
                  height: "8px",
                  background: "grey",
                  border: "none",
                  opacity: "0.6",
                }
              : {}
          }
          key={i}
        >
          {i - 2 < this.state.active && i}
        </span>
      );
    }
    return (
      <div className="body">
        <div className="container">
          <div className="steps" style={{maxWidth:'96%',marginLeft:'10px'}}>
            {stage}
            <div className="progress-bar">
              <div
                className="indicator"
                style={{ width: this.state.width + "%" }}
              ></div>
            </div>
          </div>
          <div className="steps" style={{paddingTop:'3px'}}>
            {this.state.stageList.map((menu, i) => {
              return (
                <span
                  className={this.state.active === i ? "active-menu" : ""}
                  style={i === 0 ? { fontWeight: "bold", marginLeft: '-16px' } : (i === 1 ? { fontWeight: "bold", marginLeft: '-95px' } : { fontWeight: "bold" })}
                  key={i}
                >
                  {menu}
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
export default ProcessBar;
