import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// import components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TabContainer from '../../components/TabContainer/TabContainer';
import BuildingEdit from './BuildingEdit';
import UAView from "../UtilityAllowance/UAView";
import AlertDialog from '../../components/AlertDialog/AlertDialog';

// Actions
import { buildingActions } from '../../actions/building';

// Helper
import { getTitle } from '../../helpers/setTitle';
import { getUserAccessForPage, history } from '../../helpers';

/**
 * Building view comtainer
 * @class BuildingView
 * @extends {Component}
 */
class BuildingView extends Component {
    /**
     *Creates an instance of BuildingView.
     * @param {*} props
     * @memberof BuildingView
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            claimType: null
        }
        this.buildingRef = React.createRef();
        this.accessAction = getUserAccessForPage('buildingview');
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value
     * 
     * [1]  -   Navigate to UA view
     * [2]  -   Navigate to buildting view
     */
    handleTabChange = (event, value) => {
        const { match, location } = this.props;
        this.setState({tab:value})
        switch (value) {
            // [1]
            case 1:
                history.push({
                    pathname: `${match.url}/ua`,
                    state: {
                        page: 'uaEdit',
                        pageData: {
                            //handled for nav from archive
                            site_id: parseInt(history.location.pathname.split("/")[4]),
                            building_id: !match.params.buildingId?parseInt(history.location.pathname.split("/")[6]):parseInt(match.params.buildingId, 10),
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [2]
            default:
                history.push({
                    pathname: `${match.url}`,
                    state: {
                        page: 'buildingEdit',
                        pageData: {
                            //handled for nav from archive
                            site_id: parseInt(history.location.pathname.split("/")[4]),
                            building_id: !match.params.buildingId?parseInt(history.location.pathname.split("/")[6]):parseInt(match.params.buildingId, 10),
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
        }
    };

    componentDidMount(){
        if(history.location.pathname.includes("ua")){
            this.setState({tab:1})
        }
    }
    /**
     * Handle form save action click
     * @param {*} formData
     * @memberof BuildingEdit
     * 
     * [1]  -   Update building 
     */
    handleFormSaveActionClick = (formData) => {
        // [1]
        if (formData) {
            formData.siteId = this.props.siteId;
            this.props.updateBuilding(formData, formData._id);
        }
    };
    /**
     * Handle save action click
     */
    handleSaveActionClick = () => {
        this.buildingRef.refs.form.submit();
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
     * Goto Units List
     */
    handleGotoUnitsListActionClick = () => {
        const { match, location: { state }, getOne } = this.props;
        history.push({
            pathname: `${match.url}/units`,
            state: {
                page: 'unitsList',
                pageData: {
                    site_id: state.pageData.site_id,
                    building_id: state.pageData.building_id,
                    isArchived: match.path.includes('archives')
                },
                title: getOne.data ? getOne.data.name : ''
            }
        })
    }
    /**
     * Handle Claim Action Click
     */
    handleClaimActionClick = () => {
        this.setState({
            claimType: 'claim'
        })
    }
    /**
     * Handle UnClaim Action Click
     */
    handleUnClaimActionClick = () => {
        this.setState({
            claimType: 'unclaim'
        })
    }
    /**
     *render html
     *
     * @returns
     * @memberof BuildingView
     */
    render() {
        let { pageAccess, siteId } = this.props;
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        const { match: { params }, location: { pathname, state },
            classes, match, theme, isSubPage, pageTitle } = this.props;
        const currentTab = pathname.includes('/ua') ? 1 : 0;
        pageAccess = isSubPage ? pageAccess : [...pageAccess, 'BACK'];
        //handled for nav from archive
        let buildingId = !match.params.buildingId?parseInt(history.location.pathname.split("/")[6]):parseInt(match.params.buildingId, 10);
        return (
            <ContentWrapper
                classes={classes}
                isSubPage={isSubPage}
                pageAccess={(match.url === pathname || `${match.url}/ua` === pathname) ? pageAccess : []}
                title={isSubPage ? '' : pageTitle.title}
                subTitle={isSubPage ? '' : pageTitle.subTitle}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
                handleBackActionClick={this.handleBackActionClick}
                handleGotoUnitsListActionClick={this.handleGotoUnitsListActionClick}
                handleClaimActionClick={this.handleClaimActionClick}
                handleUnClaimActionClick={this.handleUnClaimActionClick}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="General" value={0} />
                        <Tab label="Utility Allowance" value={1} />
                    </Tabs>
                </AppBar>
                <TabContainer dir={theme.direction}>
                    <Switch>
                        <Route
                            exact
                            path={`${match.url}`}
                            render={(props) => {
                                return (
                                    <BuildingEdit
                                        isSubPage={isSubPage}
                                        pageAccess={pageAccess}
                                        classes={classes}
                                        buildingId={buildingId}
                                        innerRef={(node) => this.buildingRef = node}
                                        isReadOly={isReadOly}
                                        handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                                        isArchived={pathname.includes('archives')}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={`${match.url}/ua`}
                            render={(props) => {
                                return (
                                    <UAView
                                        siteId={siteId}
                                        buildingId={buildingId} {...props} page={'ua'}
                                        isArchived={pathname.includes('archives')}
                                        isSubPage={true}
                                    />
                                )
                            }}
                        />
                    </Switch>

                </TabContainer>
                <AlertDialog
                    open={this.state.claimType ? true : false}
                    saveText={this.state.claimType === 'claim' ? 'Claim' : 'Unclaim'}
                    onSave={() => {
                        this.setState({ claimType: null });
                        const urlParams = params || {};
                        const building_id = urlParams.buildingId ? parseInt(urlParams.buildingId, 10) : null;
                        const { site_id } = state.pageData || {};
                        if (building_id && site_id) {
                            this.props.updateClaimStatus(building_id, site_id);
                        } else {
                            alert("Site ID / Building ID not found.")
                        }
                    }}
                    onClose={() => {
                        this.setState({ claimType: null });
                    }}
                    title={this.state.claimType === 'claim' ? 'Building Claim Confirmation' : 'Building Unclaim Confirmation'}
                >
                    <React.Fragment>
                        {this.state.claimType === 'claim' ?
                            'Are you sure you want to Claim this Building. If you do not want to do this, click Cancel' :
                            "WARNING! Unclaiming this Building will imply that this Building was never monitored by this Funder."
                        }
                    </React.Fragment>
                </AlertDialog>
            </ContentWrapper>
        );
    }
}

// prop types
BuildingView.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
}

// default types
BuildingView.defaultProps = {
    title: "",
    isSubPage: false,
    pageAccess: []
}
/**
 * Map state to props
 * @param {*} state 
 * @returns 
 */
const mapStateToProps = (state) => {
    const { buildings, pageAccess } = state;
    const pageTitle = getTitle(history.location.pathname, state);
    return {
        getOne: buildings.getOne,
        pageAccess: pageAccess['buildingEdit'] || [],
        pageTitle
    }
};
/**
 * Map dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateBuilding: (formData, id) => dispatch(buildingActions.updateBuilding(formData, id)),
        updateClaimStatus: (building_id, site_id) => dispatch(buildingActions.updateClaimStatus(building_id, site_id))
    }
};
// export BuildingView
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(BuildingView));