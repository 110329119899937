export const buildingConstants = {
    // get building listing
    GETALL_REQUEST: "BUILDING_GETALL_REQUEST",
    GETALL_SUCCESS: "BUILDING_GETALL_SUCCESS",
    GETALL_FAILURE: "BUILDING_GETALL_FAILURE",

    // set and unset filter
    CLEAR_FILTER_SET: 'SITES_SET_FILTER',
    CLEAR_FILTER_RESET: 'SITES_RESET_FILTER',

    //create building
    ADD_BUILDING_REQUEST: "ADD_BUILDING_REQUEST",
    ADD_BUILDING_SUCCESS: "ADD_BUILDING_SUCCESS",
    ADD_BUILDING_FAILURE: "ADD_BUILDING_FAILURE",

    // building detail page
    GETONE_REQUEST: "GETONE_REQUEST",
    GETONE_SUCCESS: "GETONE_SUCCESS",
    GETONE_FAILURE: "GETONE_FAILURE",

    // update building
    UPDATE_BUILDING_REQUEST: "UPDATE_BUILDING_REQUEST",
    UPDATE_BUILDING_SUCCESS: "UPDATE_BUILDING_SUCCESS",
    UPDATE_BUILDING_FAILURE: "UPDATE_BUILDING_FAILURE",

    //delete building
    DELETE_REQUEST: "DELETE_REQUEST",
    DELETE_SUCCESS: "DELETE_SUCCESS",
    DELETE_FAILURE: "DELETE_FAILURE",

    // claim and un claim building
    UPDATE_BUILDING_CALIM_STATUS_REQUEST: 'UPDATE_SITE_BUILDING_STATUS_REQUEST',
    UPDATE_BUILDING_CALIM_STATUS_SUCCESS: 'UPDATE_SITE_BUILDING_STATUS_SUCCESS',
    UPDATE_BUILDING_CALIM_STATUS_FAILURE: 'UPDATE_SITE_BUILDING_STATUS_FAILURE',

    // get building name list
    GET_BUILDINGNAME_REQUEST: 'GET_BUILDINGNAME_REQUEST',
    GET_BUILDINGNAME_SUCCESS: 'GET_BUILDINGNAME_SUCCESS',
    GET_BUILDINGNAME_FAILURE: 'GET_BUILDINGNAME_FAILURE',

    // Buildings bulk update
    BULKUPDATE_REQUEST: 'BULKUPDATE_REQUEST',
    BULKUPDATE_SUCCESS: 'BULKUPDATE_SUCCESS',
    BULKUPDATE_FAILURE: 'BULKUPDATE_FAILURE',
}