import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Fab';

// Imports Styles
import pageTitleStyle from '../../assets/jss/components/pageTitle'

// Imports Components
import ToolTip from '../../components/ToolTip/ToolTip';
import { PageleftIcon } from '../SvgIcons/SvgIcons';

// Import Helpers
import { truncateString } from '../../helpers'

/**
 *Title of the page
 *
 * @class PageTitle
 * @extends {Component}
 */
class PageTitle extends Component {
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        this.props.handleBackActionClick();
    };
    /**
     * Bind Validation
     * @returns 
     * 
     * [1]  -   Find Warning Error
     * [2]  -   Find HardToStop Error
     * [3]  -   find field errors
     */
    bindValidationMsg() {
        const { validations } = this.props;
        if (validations) {
            // [1]
            const validationWarning = validations.validationWarning;
            const warnings = Object.keys(validationWarning).map(key => validationWarning[key])
                .reduce((a, b) => {
                    return [...a, b.error];
                }, []);

            // [2]
            const validationHardStop = validations.validationHardStop;
            let hardstop = Object.keys(validationHardStop).map(key => validationHardStop[key])
                .reduce((a, b) => {
                    return [...a, b.error];
                }, []);
            // [3]
            let fielderrors = validations.fielderrors || [];
            return (
                <ul>
                    {
                        hardstop.map((item, i) => (
                            <li className={'validation_red'}>{item}</li>
                        ))
                    }
                    {
                        warnings.map((item, i) => (
                            <li className={'validation_yellow'}>{item}</li>
                        ))
                    }
                    {
                        fielderrors.map((item, i) => (
                            <li className={'validation_yellow'}>{item.msg}</li>
                        ))
                    }
                </ul>
            )

        } else {
            return null;
        }
    }
    /**
     *render html
     *
     * @returns
     * @memberof PageTitle
     */
    render() {
        const { classes, actions, subTitle, isSubPage, titleCss } = this.props;
        let { title } = this.props;
        const trunText = truncateString(title, 75);
        return (
            // <React.Fragment>
            //     {actions && actions.length > 0 && title && title.length > 0 &&
            <div className={isSubPage === true ? classes.subPage : classes.root}>
                <Toolbar variant="dense" className={isSubPage === true ? classes.subPage : classes.noPadding}>
                    {actions.indexOf('BACK') >= 0 &&
                        <ToolTip title="Back" placement="bottom">
                            <Button variant="circular" size="small" color="default" aria-label="Back" className="backButton" onClick={this.handleBackActionClick}>
                                <PageleftIcon width='13px' height='20px' />
                            </Button>
                        </ToolTip>
                    }

                    {actions.indexOf('VALIDATION_WARNING') >= 0 &&
                        <Button variant="circular" size="small" color="default" aria-label="Validation" className={classes.button + ' validation_yellow_bg'}>
                            <i className="fa fa-exclamation"></i>
                        </Button>
                    }

                    {actions.indexOf('VALIDATION_HARDSTOP') >= 0 &&
                        <Button variant="circular" size="small" color="default" aria-label="Validation" className={classes.button + ' validation_red_bg'}>
                            <i className="fa fa-exclamation"></i>
                        </Button>
                    }

                    {actions.indexOf('NO_VALIDATION_ISSUES') >= 0 &&
                        <Button variant="circular" size="small" color="default" aria-label="Validation" className={classes.button + ' validation_green_bg'}>
                            <i className="fa fa-exclamation"></i>
                        </Button>
                    }
                    {titleCss === '' &&
                        <Typography className={isSubPage === true ? classes.subPageTypography : classes.typography} variant="h6" component="h2" title={title}>
                            {trunText}
                            {subTitle ?
                                <span className="title_subtext" dangerouslySetInnerHTML={{ __html: subTitle }}></span>
                                : null}
                        </Typography>
                    }

                    {titleCss !== "" &&
                        <h4 className="report_level_title">
                            {title}
                        </h4>
                    }
                </Toolbar>
            </div>
            //     }
            // </React.Fragment>
        );
    }
}
//default props
PageTitle.defaultProps = {
    title: '',
    actions: [],
    classes: {},
    isSubPage: false,
    titleCss: "",
    subTitle: null,
    validations: null,
    handleBackActionClick: () => { }
}
//prop types
PageTitle.propTypes = {
    title: PropTypes.string,
    titleCss: PropTypes.string,
    subTitle: PropTypes.any,
    isSubPage: PropTypes.bool,
    validations: PropTypes.object,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    handleBackActionClick: PropTypes.func
};
/**Export Component */
export default withStyles(pageTitleStyle)(PageTitle);
