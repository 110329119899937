import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import ExploreList from "./ExploreList";
import Explore from './Explore';
import ExploreView from './ExploreView'
/**
 * ExploreRouter Container
 * @class ExploreRouter
 * @extends {Component}
 */
class ExploreRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <ExploreList match={props.match} page={'explore'} {...props} />
                }} />
                <Route exact path={`${match.url}/new`} component={Explore} />
                <Route path={`${match.url}/:_id`} render={(props) => {
                    return <ExploreView _id={props.match.params._id} {...props} />
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default ExploreRouter;
