// import { themeSpacingSize } from '../common';

const dashboardStyle = theme => ({
    root: {
        padding: 14
    },
    image: {
        width: 50,
        height: 50
    },
    buttons: {
        padding: "5px",
        margin: "0px",
        minHeight: "30px",

    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
    formControl: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%",
    },
    tab: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 3px rgba(102, 102, 102, 0.16)"
    },
    cardHeader: {
        display: 'flex',
        justifyContent: "space-between",
        padding: 16,
        '& .header': {
            fontSize: 16,
            fontWeight: 600
        },
        '& .actions': {
            color: '#36aebe',
            cursor: 'pointer'
        }
    },
    DashboardContainer: {
        margin: '0 -8px',
    },
    PSBUCountCard: {
        display: 'flex',
        justifyContent: "space-between",
        padding: '14px 16px',
        "& .text": {
            color: '#333333',
            "& .count": {
                fontSize: 24,
                fontWeight: 600,
                // padding: 4,
                '& .PSBULoader > svg': {
                    height: 24,
                    width: 24
                }
            },
            "& .name": {
                fontSize: 16,
                textTransform: 'capitalize'
                // padding: 4,
            }
        },
        "& .icon": {
            display: 'flex',
            alignSelf: 'center',
            "& .projects-icon": {
                // padding: 14,
                borderRadius: '50%',
                backgroundColor: '#fff7e2',
                color: '#ffca62',
                height: 48,
                width: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& i': {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    display: 'flex',
                }
            },
            "& .sites-icon": {
                // padding: 14,
                borderRadius: '50%',
                backgroundColor: '#edf1ff',
                color: '#668bff',
                height: 48,
                width: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& i': {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    display: 'flex',
                }
            },
            "& .contracts-icon": {
                // padding: 14,
                borderRadius: '50%',
                backgroundColor: '#edf1ff',
                color: '#668bff',
                height: 48,
                width: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& i': {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    display: 'flex',
                }
            },

            "& .buildings-icon": {
                // padding: 14,
                borderRadius: '50%',
                backgroundColor: '#e5fbfa',
                color: '#49e3d7',
                height: 48,
                width: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& i': {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    display: 'flex',
                }
            },
            "& .units-icon": {
                // padding: 14,
                borderRadius: '50%',
                backgroundColor: '#fff5fa',
                color: '#fea1b8',
                height: 48,
                width: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& i': {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    display: 'flex',
                }
            },
            "& .records-icon": {
                // padding: 14,
                borderRadius: '50%',
                backgroundColor: '#fff5fa',
                color: '#fea1b8',
                height: 48,
                width: 48,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& i': {
                    fontSize: 20,
                    height: 20,
                    width: 20,
                    display: 'flex',
                }
            },

        }
    },
    complianceIssueContainer: {
        '& .complianceIssueCard': { 
            display: 'flex',
            justifyContent: "space-between",
            padding: '0 16px 19px 16px',
            height: '8.1rem',
            '& .cardContainer': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .complianceIssueLoader > svg': {
                    height: 64,
                    width: 64
                },
                '& .cardText': {
                    fontSize: '0.815rem',
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: "8px"
                }
            }
        },
        '& .greyLoader': {
            fill: '#ddd !important'
        }
    },
    occupancyRateContainer: {
        '& .occupancyRateCard': {
            display: 'flex',
            justifyContent: "space-between",
            padding: 16,
            minHeight: '21.45rem',
            '& .cardContainer': {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& .cardText, .occupantCount': {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: "8px"
                },
                '& .cardText': {
                    fontSize: '1.3rem',
                },
                '& .occupantCount': {
                    fontSize: '1.5rem',
                    alignItems: 'baseline',
                    '& .occupiedCount': {
                        color: '#6ee4d8',
                        fontSize: '2rem'
                    },
                    '& .totalCount': {
                        fontSize: '1.5rem'
                    }
                },
                '& .occupanceRateLoader > svg': {
                    height: 64,
                    width: 64
                }
            }
        }
    },
    reportingStatusContainer: {
        '& .reportingStatusCard': {
            minHeight: '21.45rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            '& .cardContainer': {
                height: '100%',
                '& .innerCard': {
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    height: '100%',
                    '& .reportingStatusLoader > svg': {
                        height: 72,
                        width: 72
                    }
                }

            }
        }
    },
    usersSummaryContainer: {
        '& .usersSummaryCard': {
            display: 'flex',
            padding: '0 16px 16px 16px',
            // justifyContent: 'space-around',
            '& .cardContainer': {
                // display: 'flex',
                '& .innerCardContainer': {
                    // display: 'flex'
                },
                '& .userCard, .orgCard, .orgAdminCard, .propCard': {
                    display: 'flex',
                    padding: '12px 12px 12px 24px',
                    '& .icon': {
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: '50%',
                        height: '48px',
                        // padding: 12,
                        '& .MuiSvgIcon-root': {
                            fontSize: '2rem'
                        }
                    },
                    '& .details': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        '& .count': {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            ' & .userSummaryLoader > svg': {
                                height: 24,
                                width: 24
                            }
                        },
                        '& .title': {
                            fontSize: '.875rem'
                        }
                    }
                },
                '& .orgAdminCard, .propCard': {
                    minWidth: '42%',
                    height: 72,
                    padding: '12px 12px 12px 36px',
                },
                '& .userCard, .orgCard': {
                    '& .details': {
                        paddingLeft: 12,
                    }
                },
                '& .chartCard': {
                    padding: '12px 12px 12px 36px'
                },
                '& .userCard': {
                    '& .icon': {
                        backgroundColor: '#FFF7E2',
                        '& .MuiSvgIcon-root': {
                            color: '#FAC962'
                        }
                    }
                },
                '& .orgCard': {
                    '& .icon': {
                        backgroundColor: '#FDF5FA',
                        '& .MuiSvgIcon-root': {
                            color: '#EF9FB7'
                        }
                    }
                },
            },
            '& .borderRight': {
                borderRight: '1px solid #c7c8ce'
            }
        }
    },
    utilityAllowanceContainer: {
        // backgroundColor: '#dee6fe',
        height: "100%",
        '& .utilityAllowanceCard': {
            textAlign: 'center',
            // display: 'flex',
            // justifyContent: "space-between",
            padding: '0 16px 16px 16px',
            '& .count': {
                fontSize: '5.2rem',
                // fontWeight: 'bold',
                color: "#678BFE",
                '& .utilityAllowanceLoader > svg': {
                    height: 48,
                    width: 48
                }
            },
            '& .text': {
                fontSize: '1.2rem',
                // fontWeight: 'bold',
                // color: "#678BFE"
            }
        }
    },
    leftSliderIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: -7,
        margin: 'auto',
        height: 48,
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 0 2px grey',
        borderRadius: 8,
        backgroundColor: '#fbab92',
        cursor: 'pointer',
        width: 12,
        '&:hover': {
            backgroundColor: '#fbab92'
        },
    },
    rightSliderIconContainer: {
        width: "100%",
        height: '100%',
        paddingLeft: 2,
        "& i": {
            height: '100%',
            width: '100%'
        }
    },
    leftSliderIconContainer: {
        width: "100%",
        height: '100%',
        paddingRight: 2,
        "& i": {
            height: '100%',
            width: '100%'
        }
    },
    sliderposition: {
        position: "relative"
    }
});

export default dashboardStyle;