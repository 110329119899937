import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import OutlinedInput from '@material-ui/core/OutlinedInput';

// Import Styles
import textBoxStyle from '../../assets/jss/components/textBox';

/**
 * Text Area Outline Box component
 */
class OutlineTextArea extends Component {


    /**
     * Handle Events 
     */
    handleChange = value => event => {
        this.props.handleChange(this.props.name, event.target.value)
    };

    /**
     * Bind
     * HTML to DOM
     */
    render() {
        const { margin, value, name, disabled, fullWidth} = this.props;
        return (
            <OutlinedInput
                multiline
                name={name}
                value={value}
                margin={margin}
                fullWidth={fullWidth}
                disabled={disabled}
                rowsMax={4}
                rows={4}
                onChange={this.handleChange()}
            />
        );
    }
}

//default props
OutlineTextArea.defaultProps = {
    name: '',
    value: '',
    disabled: false,
    fullWidth: false,
    classes: {},
    margin: 'dense',
    handleChange: () => {},
    onInputChangeRequest: () => { }
}

//prop types
OutlineTextArea.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    margin: PropTypes.string,
    onInputChangeRequest: PropTypes.func.isRequired
};

//export component
export default withStyles(textBoxStyle)(OutlineTextArea);
