import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import _ from 'underscore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { loanProjectActions } from '../../actions/loanProjects';
import { userActions } from '../../actions/user';
import { confActions } from '../../actions/configuration';
import { getUserOrgId, getUserRole, globalVariable, history, formatDate, filterSearchFields } from '../../helpers';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';

// Import Components
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import TableComponent from '../../components/Table/TableComponent';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from "../../components/HelpFloater/HelpFloater";
import { Grid, Typography } from '@material-ui/core';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ToolTip from '../../components/ToolTip/ToolTip';


// for table county field
class CountyField extends Component {
    render() {
        const { data } = this.props;
        const { sites_id } = data;
        const county = sites_id.length > 0 ? sites_id[0].county : '';
        return county ? county : '';
    }
}

CountyField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

// for funder Field
class FundersField extends Component {

    render() {
        const { data } = this.props;
        let { funders, funderIds, fundersName } = data;
        let fundersData = [];
        funders.forEach(item => {
            if (fundersName.indexOf(item.name) > -1)
                fundersData.push(item)
        });
        const links = fundersData.map((fn, index) => {
            return (
                <Button
                    key={index}
                    size="small"
                    className={'linkCell validation_color text-button'}
                    onClick={() => {
                        const funderId = funderIds.filter(fkey => { return fkey.org_id === fn._id });
                        if (funderId[0]) {
                            this.props.handleChange("funders", { ...data, funderKey: { ...funderId[0], name: fn.name } })
                        }
                    }}
                >
                    {fn.name}
                </Button>
            )
        })
        return links;
    }
}

FundersField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: -13,
        top: 10,
        // border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        backgroundColor: '#dee8ed'
    },
}))(Badge);
// for contract number
class ContractNoField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listOpen: false,
            anchorEl: null
        }
    }

    render() {
        const { data } = this.props;
        let { contractNumber,isLastEle } = data;
        let fundersData = contractNumber.split(", ");
        return (
            <div>
            <ToolTip title={fundersData[0]} placement='bottom-start'>
            <Typography >
            <ClickAwayListener onClickAway={()=>{this.setState({listOpen:false})}}><StyledBadge className='StyledBadgeClass' badgeContent={fundersData.length > 1 ? `+${fundersData.length - 1}` : 0} onClick={(e) => { this.setState({ listOpen: !this.state.listOpen, anchorEl: e.currentTarget }) }}></StyledBadge></ClickAwayListener>{fundersData[0].length>15?fundersData[0].substr(0, 15) + '...':fundersData[0]}
            </Typography>
            </ToolTip>
            
            <div class="containers" >
                <span class={this.state.listOpen ?isLastEle? "lastList tableClickTextBox" :"tableClickTextBox" : "tooltiptextNone"}>
                <List>
                    {fundersData.slice(1, fundersData.length).map((data, index) => {
                        return (
                            <ToolTip title={data} placement="right">
                            <ListItem onMouseEnter={()=>{console.log("sfsfsdfsdf")}} key={index}>
                                <ListItemText className='loanContractItem'
                                    primary={data.length>12?data.substr(0, 12) + '...':data}
                                />
                            </ListItem>
                            </ToolTip>
                        )
                    })
                    }
                </List>
                </span>
            </div>
            </div>

        )
    }
}

ContractNoField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}
const componentMap = {
    "sites_id.county": CountyField,
    "fundersName": FundersField,
    "contractNumber": ContractNoField
}


/**
 *  Loan Project List
 */
class LoanProjectsList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
            anchorEl: null,
            deleteProj: false,
            deleteItemId: null,
            openHelp: false,
            deleteProjName:null
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }

    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { projects: { page }, userId,fromPage } = this.props;
        this.props.getHelp();
        if (page && page.skip) {
            this.props.getLoanProjects(page.skip,fromPage==="users"?globalVariable.subPagetableRowSize: globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, false, userId);
        } else {
            this.props.getLoanProjects(1,fromPage==="users"?globalVariable.subPagetableRowSize : globalVariable.tableRowSize, 'name', 'asc', '', '', false, userId);
        }
        setTimeout(() => {
            userId ? this.props.getFieldFilter('associatedLoanProjects') : this.props.getFieldFilter('loanProjects');
        }, 100);
    }

    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }

    /**
     * Handle Table Pagination On Change Event
     */
    onChangePageTable = (skip, limit) => {
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        this.props.getLoanProjects(skip, limit, page.orderBy, page.order, sFields, sValues, false, userId);
    };

    /**
     * Handle Table Sort Event
     */
    onSortRequest = (orderBy, order) => {
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        this.props.getLoanProjects(page.skip, page.limit, orderBy, order, sFields, sValues, false, userId);
    };

    /**
     * Handle Table Search Input Box Event
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { projects, userId, searchData,fromPage } = this.props;
        const { page } = projects;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getLoanProjects(1,fromPage==="users"?globalVariable.subPagetableRowSize : globalVariable.tableRowSize, page.orderBy, page.order, sFields, sValues, false, userId);
    };

    /**
     * Handle Table Navigation Event
     */
    handleNavigateAction = (id, field, item) => {
        switch (field) {
            case "name":
                history.push({
                    pathname: `/loanProjects/${id}`,
                    state: {
                        page: 'loanProjectEdit',
                        pageData: {
                            project_id: id
                        }
                    }
                });
                break;
            case "monitoring":
                if (item.monitoring === 'Claim') {
                    this.props.checkFunderAvailability(item._id)
                } else if (item.monitoring === 'Expired') {
                    const funder_id = item.isExpired[0]._id;
                    history.push({
                        pathname: `/loanProjects/${item._id}/funders/${funder_id}`,
                        state: {
                            page: 'loanFunderEdit',
                            pageData: {
                                funder_id: funder_id
                            }
                        }
                    });
                } else if (item.monitoring === 'Pending' && item.isPending.length > 0) {
                    history.push(`/loanProjects/${item._id}/funders/new`);
                } else if (item.isMonitoring.length > 0 && item.monitoring === 'Monitoring') {
                    const funder_id = item.isMonitoring[0]._id;
                    history.push({
                        pathname: `/loanProjects/${item._id}/funders/${funder_id}`,
                        state: {
                            page: 'loanFunderEdit',
                            pageData: {
                                funder_id: funder_id
                            }
                        }
                    });
                }
                break;
            case "ownerName":
                const { ownerDetails } = item;
                const ownerId = ownerDetails || { _id: 0 };
                if (ownerId._id) {
                    history.push({
                        pathname: `/users/${ownerId._id}`,
                        state: {
                            page: 'userEdit',
                            pageData: {
                                user_id: parseInt(ownerId._id, 10)
                            }
                        }
                    });
                }
                break;
            case "owners.org_id.name":
                const { owners } = item;
                const ownerOrgId = owners ? owners.org_id ? owners.org_id._id : 0 : 0;
                if (ownerOrgId) {
                    history.push({
                        pathname: '/organizations/' + ownerOrgId,
                        state: {
                            page: 'organizationEdit',
                            pageData: {
                                org_id: parseInt(ownerOrgId, 10)
                            }
                        }
                    });
                }
                break;
            default: break;
        }
    }

    /**
     * Handle Add Action
     */
    handleAddActionClick = () => {
        history.push({
            pathname: '/loanProjects/new',
            state: {
                page: 'loanProjectsNew',
                pageData: {}
            }
        });
    };

    /**
     * Handle Clear Filter Action
     */
    handleClearFilterActionClick = () => {
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getLoanProjects(page.skip, page.limit, page.orderBy, page.order, [], [], false, userId);
    };

    /** handle field filter Action
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "loanProjects", page.fieldFilters.visibleColumns);
    };

    /** handle field filter close
    * @memberof Users
    */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "loanProjects", page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns, key is a column name and
     * values contains boolean of checked status
     *
     * @memberof Users
     */
    handleFieldFilterOnChange = (key, value) => {
        const { userId } = this.props;
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "loanProjects", updatedColumns);
        } else {
            this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "loanProjects", [...this.props.visibleColumns, key]);
        }
    }

    /** handle field filter Close
     * @memberof Users
     */

    handleFieldFilterOnSave = () => {
        const { userId } = this.props;
        this.props.updateFieldFilter(userId ? "associatedLoanProjects" : "loanProjects", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { projects, userId, searchData: { sFields, sValues } } = this.props;
            const { page } = projects;
            this.props.getLoanProjects(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
        });
    }

    /**
    * Handle Table Delete Action
    */
    handleTableDeleteAction = (id) => {
        let {projects} = this.props;
        let search = projects
        let delProject = search.data.filter(item=>item._id===id)
        if(delProject[0].monitoring==='Claim'){
            this.props.deleteProject(id)
        }else{
        //show pop up
        this.setState({ deleteProj: true, deleteItemId: id,deleteProjName:delProject[0].name });
        }
        
    };

    /**
     * A method to call when copy icon clicked, copy the project details to new project
     *
     * @memberof Projects
     */
    handleTableCopyAction = (id) => {
        history.push({
            pathname: `/loanProjects/new/${id}`,
            state: {
                page: 'loanProjectsNew',
                pageData: {}
            }
        });
    }

    /**
     * Handle Field Component On Change Event
     */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "funders":
                const { _id, name: funderName, org_id, modifiedBy, updatedAt } = data.funderKey;
                if (_id) {
                    history.push({
                        pathname: `/loanProjects/${data._id}/funders/${_id}`,
                        state: {
                            page: 'loanFunderEdit',
                            pageData: {
                                funder_id: _id,
                                orgId: org_id
                            },
                            pageTitle: {
                                title: `${funderName} | ${data.name}`,
                                subTitle: modifiedBy ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>` : ''
                            }
                        }
                    });
                }
                break;
            default:
        }
    }

    /**
     * Handle Download Click Action
     */
    handleDownloadActionClick = () => {

    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
     * Handle Upload Click Action
     */
    handleTable1ImportActionClick = () => {
        history.push({
            pathname: `/table1-import`,
            state: {
                page: 'table1Import',
                pageData: {}
            }
        });
    }

    /**
    * For merging projects
    */
    handleMergeActionClick = () => {

        history.push({
            pathname: '/mergeho',
            state: {
                page: 'mergeHOProjects',
                pageData: {}
            }
        })
    }

    componentWillUnmount() {
        // clear search of assoc tab
        if (this.props.userId) {
            this.props.searchActions({ sFields: [], sValues: [] });
        }
    }

    //upload xml
    handleXMLImportActionClick = () => {
        history.push({
            pathname: `/reportActivityImport`,
            state: {
                page: 'reportActivityImport',
                pageData: {}
            }
        });
    }

    /**
     * Bind Html to DOM
     */
    render() {
        let {
            projects, columnsMap, visibleColumns, tableActionRequired,
            removeActions, location: { pathname }, title, pageAccess,
            isSubPage, classes, needPadding, helpData,
            searchData: { sFields, sValues },userAccess
        } = this.props;
        const { userRole, userOrgId } = this;
        const { fieldFilter, openHelp,deleteProjName } = this.state;
        const search = projects;
        const fieldFilters = search.page.fieldFilters;
        pageAccess = _.difference(pageAccess, removeActions);

        // remove download icon
        const downloadIndex = pageAccess.indexOf('DOWNLOAD');
        if (this.props.userId && downloadIndex > -1) {
            pageAccess.splice(downloadIndex, 1);
        }
        pageAccess = [...pageAccess, 'HELP']
        
        //remove xml import for associated projectd
        if(pathname.split("/")[1]==='users'&& pageAccess.indexOf('XML_IMPORT')>-1){
            let index = pageAccess.indexOf('XML_IMPORT');
            pageAccess.splice(index,1)
        }
        //remove xml import for none users
        let isNonFundersWithOA = getUserRole() === 'nonFunderWithOA' ||getUserRole() === 'nonFunderWithOAandHO'|| getUserRole() ==='nonFunderWithOAandBoth';

        if(!isNonFundersWithOA && pageAccess.indexOf('XML_IMPORT')>-1){
            let index = pageAccess.indexOf('XML_IMPORT');
            pageAccess.splice(index,1)
        }

        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                xmlTitle={"Import Activity"}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                handleMergeActionClick={this.handleMergeActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                handleXMLImportActionClick={this.handleXMLImportActionClick}
                needPadding={needPadding}
                handleDownloadActionClick={() => (
                    <Menu>
                        <MenuItem>New</MenuItem>
                        <MenuItem>Update</MenuItem>
                    </Menu>
                )}
                handleTable1ImportActionClick={this.handleTable1ImportActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleUploadActionClick={() => {
                    history.push({
                        pathname: '/ua/bulk-upload',
                        state: {
                            page: 'uaBulkUpload',
                            pageData: {}
                        }
                    })
                }}
            >
                <TableComponent
                    page={search.page.skip}
                    rowsPerPage={search.page.limit}
                    count={search.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    actions={tableActionRequired === true ? pageAccess : []}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    currentPage={'contractList'}
                    links={['name', "monitoring", "ownerName", "owners.org_id.name"]}
                    order={search.page.order}
                    orderBy={search.page.orderBy}
                    data={search.data}
                    highlightField={['name']}
                    isLoading={false}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    handleTableCopyAction={this.handleTableCopyAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                    moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                    prevNext={false}
                    paginationVisible={true}
                    disablePagination={search.page.lastPage}
                    showLoading={isSubPage}
                    userAccess={ userAccess&&userAccess.orgAccess?userAccess.orgAccess :""}
                    getDeleteTitle={(item) =>{
                        return (
                            <div>
                               Are you sure you want to delete the project <b>{item.name}</b>?
                            </div>
                        )
                    }}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />

                <DeleteDialog
                    open={this.state.deleteProj !== false}
                    content={<div dangerouslySetInnerHTML={{ __html: `This project <b>${deleteProjName}</b> is associated with at least one contract. Deleting this project will delete all associated contracts?`}}></div>}
                    handleDeleteAction={() => {
                        this.setState({ deleteProj: false,deleteProjName:null });
                        this.props.deleteProject(this.state.deleteItemId)
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ deleteProj: false })
                    }}
                />

                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Homeownership Projects"
                />}
            </ContentWrapper>

        );
    }
}

/**
 * Bind Component Property Types
 */
LoanProjectsList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    title: PropTypes.string,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};

/**
 * Default Props Types
 */
LoanProjectsList.defaultProps = {
    needPadding: false,
    tableActionRequired: true,
    title: "Homeownership",
    removeActions: [],
    isSubPage: false
};

/**  
 * A method to map the actions from different pages for same containers
 */

const getPageState = function (page, state) {
    switch (page) {
        case 'loanProjects': return state.loanProjects;
        case 'users': return state.users.loanProjects;
        default: break;
    }
}

const getPageAction = function (page) {
    switch (page) {
        case 'loanProjects': return loanProjectActions.getLoanProjects;
        case 'users': return userActions.getAssociatedLoanProjects;
        default: break;
    }
}

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = ownProps.userId ? state.configuration.fieldFilter['associatedLoanProjects'] || {} : state.configuration.fieldFilter['loanProjects'] || {};
    const pageAccess = state.pageAccess['loanProjects'] || ['ADD', 'FILTER', 'COPY', 'DELETE', 'CLEAR'];
    const projects = getPageState(ownProps.page, state);
    const searchIndexFor = ownProps.userId ? 'userProjects' : 'loanProjects';
    const searchData = state.search[searchIndexFor] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0];
    const userAccess =state.users.getOne.data
    return {
        projects,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData,
        helpData,
        userAccess
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetFilter: () => dispatch(loanProjectActions.resetFilter()),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getLoanProjects: (skip, limit, orderBy, order, sField, sValue, exactMatch, userId) => {
            const actionToCall = getPageAction(ownProps.page);
            dispatch(actionToCall(skip, limit, orderBy, order, sField, sValue, exactMatch, userId))
        },
        deleteProject: (id) => dispatch(loanProjectActions.delete(id)),
        searchActions: (data) => {
            if (ownProps.userId) {
                return dispatch(searchActions.userProjects(data));
            } else {
                return dispatch(searchActions.loanProjects(data));
            }
        },
        getHelp: () => dispatch(helpActions.getAll({ _id: 18 })),
        checkFunderAvailability: (id) => dispatch(loanProjectActions.checkLoanProjectFunder(id))
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(LoanProjectsList));
