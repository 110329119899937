import { authHeader, globalVariable, handleDownloadResponse, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const maintenanceService = {
    getMaintenanceStatus,
    saveMaintenanceStatus,
    importLimitFiles,
    startCron,
    goLive,
    verifyData,
    downloadTemplate,
    listLogs,
    reUpload,
    getCronStatus,
    restoreAndGoLive
};

function getMaintenanceStatus() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.ms_apiURL}/maintenance/logs`, requestOptions).then(handleResponse);
}

function reUpload() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.ms_apiURL}/re-upload-files`, requestOptions).then(handleResponse);
}

function saveMaintenanceStatus(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.ms_apiURL}/start-maintanence`, requestOptions).then(handleResponse);
}

/**
 * upload file
 */
function importLimitFiles(formData) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData
    };

    return fetch(`${globalVariable.ms_apiURL}/import-limit-files`, requestOptions).then(handleResponse);
}


function startCron() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.ms_apiURL}/start-cron`, requestOptions).then(handleResponse);
}


function goLive() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.ms_apiURL}/go-live`, requestOptions).then(handleResponse);
}

function restoreAndGoLive() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.ms_apiURL}/restore-maintanence`, requestOptions).then(handleResponse);
}

/**
 * Verify data
 */
function verifyData(formData, limitType = "", skip, limit, orderBy, order) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    };

    return fetch(`${globalVariable.ms_apiURL}/verify-data/${limitType}?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 * Download template table
 * 
 * @param {*} data - table data
 */
function downloadTemplate(id, limitType) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    return fetch(`${globalVariable.ms_apiURL}/download-limit-files/${id}/${limitType}`, requestOptions).then(handleDownloadResponse);
}

/**
 * Logs list
 */
function listLogs(formData, skip, limit, orderBy, order) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    };

    return fetch(`${globalVariable.ms_apiURL}/maintenance/list/logs?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 * Cron Status list
 */
function getCronStatus(type) {
    const requestOptions = {
        method: 'GET',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        }
    };

    return fetch(`${globalVariable.ms_apiURL}/cron-status/${type}`, requestOptions).then(type === 'view' ? handleResponse : handleDownloadResponse);
}
