import { buildingConstants } from "../constants";

const initialState = {
    isLoading: false,
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['name'],
            searchCols: []
        }
    },
    reset: false,
    getOne: {
        data: null,
        isLoading: false,
    },
    buildingNameList: []
}

export function buildings(state = initialState, action) {

    switch (action.type) {
        case buildingConstants.ADD_BUILDING_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case buildingConstants.ADD_BUILDING_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case buildingConstants.ADD_BUILDING_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case buildingConstants.GETALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case buildingConstants.GETALL_SUCCESS:
            return {
                ...state,
                ...action.building,
                isLoading: false
            };
        case buildingConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case buildingConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    data: null,
                    isLoading: true,
                }
            };

        case buildingConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.building.data,
                    isLoading: false,
                }
            };

        case buildingConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case buildingConstants.DELETE_REQUEST:
            return {
                ...state,
                getOne: {
                    data: null,
                    isLoading: true,
                }
            };

        case buildingConstants.DELETE_SUCCESS:
            return {
                ...state,
                data: state.data.filter((building) => {
                    return parseInt(building._id, 10) !== parseInt(action.id, 10);
                }),
                isLoading: false,
            };

        case buildingConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case buildingConstants.UPDATE_BUILDING_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case buildingConstants.UPDATE_BUILDING_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case buildingConstants.UPDATE_BUILDING_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case buildingConstants.UPDATE_BUILDING_CALIM_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case buildingConstants.UPDATE_BUILDING_CALIM_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case buildingConstants.UPDATE_BUILDING_CALIM_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case buildingConstants.GET_BUILDINGNAME_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case buildingConstants.GET_BUILDINGNAME_SUCCESS:
            return {
                ...state,
                buildingNameList: action.data.data,
                isLoading: false
            }

        case buildingConstants.GET_BUILDINGNAME_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case buildingConstants.BULKUPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case buildingConstants.BULKUPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case buildingConstants.BULKUPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        default: {
            return state;
        }
    }
}