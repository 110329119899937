import React, { Component } from 'react';
import { connect } from "react-redux";

// Actions
import { pageAccessActions } from '../../actions/pageAccess';

/**
 * PageAccess Container
 * @class PageAccess
 * @extends {Component}
 */
class PageAccess extends Component {
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { pageName, pageData } = this.props;
        if (pageName) {
            this.props.getAccess(pageName, pageData);
        }
    }
    /**
     * Component Will Recive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { pageName, pageData } = nextProps;
        if (pageName && pageName !== this.props.pageName) {
            this.props.getAccess(pageName, pageData);
        }
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        return <div />
    }
}

/**
 * Set Props Types
 */
PageAccess.propTypes = {
};

/**
 * Default Props
 */
PageAccess.defaultProps = {
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    const { location: { state } } = ownProps;
    const pageName = state ? state.page : null;
    return {
        pageName,
        pageAccess: pageName ? pageAccess[pageName] : [],
        pageData: state ? state.pageData : {}
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getAccess: (pageName, pageData) => dispatch(pageAccessActions.getAccess(pageName, pageData))
    }
};
/** Export Component */
export default connect(mapStateToProps, mapDispatchToProps)(PageAccess);