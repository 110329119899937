import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

/**
 * Menu Component
 * @class MenuComponent
 * @extends {MenuComponent}
 */
class MenuComponent extends Component {
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { menuDatas } = this.props
        return(
            <List component="nav" className={"noPadding navBar"}>
                { menuDatas.map( help => {
                    return(
                        <ListItem 
                            button
                            key={help._id}
                            onClick={() => this.props.handleItemClick(help)}
                            component={NavLink}
                            to={{
                                pathname: `/help/${help.name.toLowerCase()}`,
                                state: { page: 'help', type: help.key},
                            }}
                            activeClassName='helpcontainer'
                        >
                            {help.name.replace(/^\w/, (c) => c.toUpperCase())}
                        </ListItem>
                    )
                })
                }
            </List>
        )
    }    
}
/** Props Types */
MenuComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {

    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {

    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(MenuComponent));