import React from 'react';

// Import SVG Icon
import { SvgLoadIcon } from '../../components/SvgIcons/SvgIcons';

const loaderWrapperStyle = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    bottom: '0px',
    width: '100%',
    height: '100%',
    zIndex: 2000,
    backgroundColor: "rgba(255, 255, 255, 0.65)"
};

const loaderTextStyle = {
    color: "rgb(255, 255, 255)",
    height: "100px",
    position: "absolute",
    width: "100px",
    margin: "auto",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
}
/**
 * Page Loader
 * @param {*} props 
 * @returns 
 */
const PageLoader = (props) => {
    if (!props.show)
        return null;
    return (
        <div style={loaderWrapperStyle}>
            <div style={loaderTextStyle}>
                <div className={'svgloader'}>
                    <SvgLoadIcon />
                </div>
            </div>
            {props.showText?<h1 style={{position:'absolute',top:'60%',right:'25%',maxWidth:'50%',overflow:'wrap',textAlign:'center'}}>The Submission is in progress.Till the submission has been successfully updated, please avoid refreshing or pressing the back button!</h1>:<></>}
        </div>
    )
}

export default PageLoader;