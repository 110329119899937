import { authHeader, globalVariable, handleResponse } from '../helpers';
export const funderService ={
    getAll,
    delete:deleteFunder
};

/**
 * Get all funder listing page
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} projectId - project_id
 * @param {*} exactMatch 
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, projectId, exactMatch = false) {
    let data = {
        fields: sfields,
        values: sValues,
        exactMatch,
        projectId: projectId
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/projects/funder?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 * Delete funder
 * 
 * @param {*} id 
 */
function deleteFunder(id){
    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify({id: id})
    };

    return fetch(`${globalVariable.apiURL}/projects/delete-funder`, requestOptions).then(handleResponse);
}

