import { Component } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-faux-dom';

// D3
import * as d3 from "d3";

// Import Style
import './BarChart.css';

// Import Components
import ResponsiveWrapper from '../ResponsiveWrapper';

/**
 *Bar chart component
 *
 * @class BarChart
 * @extends {Component}
 */
class BarChart extends Component {
    /**
     * chart plot marking
     * @param {*} chart 
     * @param {*} width 
     * @param {*} height 
     * @param {*} margin 
     */
    plot(chart, width, height, margin) {

        let data = this.props.data;
        let tooltip = d3.select(".toolTip");

        let xScale = d3.scaleBand().rangeRound([0, width]).padding(0.3),
            yScale = d3.scaleLinear().rangeRound([height, 0]);

        let colours = d3.scaleOrdinal().range(['#FBD789', '#8CA8FE', '#75E9E0', '#F2B7C9', '#FF0000']);

        xScale.domain(data.map(function (d) { return d.title; }));
        yScale.domain([0, d3.max(data, function (d) { 
            let value = d.value
            if(!d.value)
                value = 0
            return value; 
        }) + 2]);

        data.forEach(function (d) {
            d.xValue = d.title;
            let value = d.value
            if(!d.value)
                value = 0
            d.yValue = +value;
        });

        chart.append('g')
            .attr('class', 'gridline')
            .call(d3.axisLeft()
                .scale(yScale)
                .tickSize(-width, 0, 0)
                .ticks(5)
                .tickPadding(5)
                .tickFormat(d => d + ' %'));

        chart.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(xScale))
            .selectAll("text")
            .attr("y", 0)
            .attr("x", 9)
            .attr("dy", ".35em")
            .style("transform", "rotate(0deg) translate(-32px,23px)")
            .style("text-anchor", "start")
            .style('font-size', '12px');

        chart.append("text")                         // outer y-axis label
            .attr("class", "y label")
            .attr("text-anchor", "middle")
            .attr("x", -height / 2)
            .attr("y", -10 - margin.left / 3)
            .attr("dy", "-.75em")
            .attr("transform", "rotate(-90)")
        // .text("Impact Return");

        chart.append("text")
            .attr("x", width / 2)
            .attr("y", 275)
            .style("text-anchor", "middle")
            .text("Report Tables");

        chart.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 10 - margin.left)
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text("% of issues");

        chart.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function (d) { return xScale(d.xValue); })
            .attr("y", function (d) { return yScale(d.yValue); })
            .attr("width", xScale.bandwidth() - 4)
            .attr("height", function (d) { return height - yScale(d.yValue) })
            .on("mousemove", function (d) {
                tooltip
                    .style("z-index", '99999')
                    .style("left", d3.event.pageX - 35 + "px")
                    .style("top", d3.event.pageY - 70 + "px")
                    .style("display", "inline-block")
                    .html(d.xValue + "<br><span>" + d.yValue + " % </span>");
            })
            .on("mouseout", function (d) { tooltip.style("display", "none"); });

        chart.selectAll(".bar")
            .attr("fill", function (d) { return colours(d.xValue); });

        // Controls the text labels at the top of each bar. Partially repeated in the resize() function below for responsiveness.
        chart.selectAll(".text")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("x", (function (d) { return xScale(d.title) + xScale.bandwidth() / 2 - 15; }))
            .attr("y", function (d) { return yScale(d.value) - 15; })
            .attr("dy", ".75em")
            .text(function (d) { 
                let value = d.value
                if(!d.value)
                    value = 0
                return value + " %"; });

    }
    /**
     * Draw Chart
     * @returns 
     */
    drawChart() {
        const el = new Element('div');
        const svgDimensions = {
            //selects the maximum width from the two parameters passed
            width: Math.max(this.props.parentWidth, 300),
            height: 300
        };
        d3.select('.barChart').selectAll("*").remove();
        let svg = d3.select(el)
            .append('svg')
            .attr("width", svgDimensions.width)
            .attr("height", svgDimensions.height);

        let margin = { top: 20, right: 100, bottom: 20, left: 100 },
            width = +svgDimensions.width - margin.left - margin.right,
            height = svgDimensions.height - margin.top - margin.bottom - 50;

        let chart = svg.append("g")
            .attr("transform", "translate(" + (margin.left + 40) + "," + margin.top + ")");

        this.plot(chart, width, height, margin);

        return el.toReact();
    }

    /**
     *render html
     *
     * @returns
     * @memberof BarChart
     */
    render() {
        return this.drawChart();
    }
}

//prop types
BarChart.propTypes = {
    data: PropTypes.array.isRequired
};

//default props
BarChart.defaultProps = {
    data: []
}

/*Export Component*/
export default ResponsiveWrapper(BarChart);
