import React, { Component } from 'react';
import { connect } from 'react-redux';

// Materia UI
import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

//import images
import Maintenance_Mode from '../../../assets/img/Maintenance_Mode.png'
import DB_Backup from '../../../assets/img/db_backup.svg'
import Caution from '../../../assets/img/alertCaution.svg'

//import components
import ToolTip from '../../../components/ToolTip/ToolTip';
import AlertDialog from '../../../components/AlertDialog/AlertDialog'
import SaveCancel from '../../../components/SaveCancel/SaveCancel';

// Actions
import { maintenanceActions } from '../../../actions/maintenance';
import { userActions } from '../../../actions';

// Styles
import pageStyle from '../../../assets/jss/containers/common';

//Helper
import { history } from '../../../helpers';
import { InfoIcon } from '../../../components/SvgIcons/SvgIcons';
/**
 * Maintenence Container
 * @class Maintenence
 * @extends {Component}
 */
class Maintenence extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            alertPopup: {
                open: false,
            },
            bannerText: '',
            maintenanceDate: new Date(),
        }
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        this.props.maintanance();
    }
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        const { bannerText, maintenanceDate, alertPopup } = this.state
        let data = {
            bannerText,
            date: new Date(maintenanceDate)
        }
        this.props.onMaintenance(data);
        this.setState({ alertPopup: { ...alertPopup, open: false } });
        history.push('/incomerent');
    }
    /**
     * render HTML
     * @returns 
     * 
     * [1]  -   If processIndex is not equal to 0
     * [2]  -   If processIndex is 0 and status is pending
     * [3]  -   Default return - Start Maintenance
     */
    render() {
        const { maintenance } = this.props;
        const { alertPopup } = this.state;
        // [1]
        if (maintenance && maintenance.maintenance && maintenance.processIndex !== 0) {
            return null
        }
        // [2]
        if (maintenance && maintenance.processIndex === 0 && maintenance.pending) {
            return (
                <>
                    <Grid container spacing={4} align="center" className="height-100-per alignContentCenter">
                        <Grid item xs={12}>
                            <img className="max-h-110 max-w-200" src={DB_Backup} alt="DB_Backup" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="fw-600">
                                Backup is in Progress... Please Wait!
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
        // [3]
        return (
            <React.Fragment>
                <Grid container spacing={4} align="center" className="height-100-per alignContentCenter">
                    <Grid item xs={12}>
                        <img className="max-h-110 max-w-200" src={Maintenance_Mode} alt="sleep" />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" className="fw-600">
                            Please Turn On Maintenance Mode To Backup Data
                        </Typography>
                        <Typography variant="body1" className={'pt-20 lineHeit'}>
                            Turning on maintenance mode will shut down the server and initiate data backup.
                            Only the user turning on the maintenance mode will be given read and write access.
                            Once the files are uploaded successfully the user can verify the data and run the CRON.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="displayFlex alignItemsCenter jcCenter">
                        <Button variant="contained" color="primary" style={{ "textTransform": "none" }} onClick={() => { this.setState({ alertPopup: { ...this.state.alertPopup, open: true } }) }}>ON Maintanence Mode</Button>
                        <span className="hw-20 pl-12">
                            <ToolTip title={"Please Turn On Maintenance Mode To Backup Data"} placement="right">
                                <span><InfoIcon fill='#36aebe' width='20px' height='20px' /></span>
                            </ToolTip>
                        </span>
                    </Grid>
                </Grid>
                <AlertDialog
                    open={alertPopup.open}
                    title=""
                    saveText={'Confirm'}
                    saveVisible={false}
                    cancelVisible={false}
                >
                    <div className="p-t-36 p-l-r-180">
                        <ValidatorForm
                            name="maintenanceForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => { }}
                        >
                            <Grid container spacing={4} align="center" className="displayFlex jcCenter">
                                <div className="w-100-per">
                                    <img className="max-h-110 max-w-200" src={Caution} alt="caution" />
                                </div>
                                <div>
                                    <Typography variant="body1" className={'pt-10 lineHeit'}>
                                        Do you want to turn ON the Maintanence Mode and
                                        Backup the Data. <br />
                                    </Typography>
                                </div>
                            </Grid>
                            <br />
                            <SaveCancel
                                saveText="Confirm"
                                justifyContent="center"
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { this.setState({ alertPopup: { ...alertPopup, open: false } }) }}
                            />
                        </ValidatorForm>
                    </div>
                </AlertDialog>
            </React.Fragment>
        );
    }
}
// prop types
Maintenence.propTypes = {
};
/**
 * Default Props
 */
Maintenence.defaultProps = {
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        maintenance: state.maintenance.data
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        onMaintenance: (data) => dispatch(maintenanceActions.saveMaintenanceStatus(data)),
        logout: () => dispatch(userActions.logout()),
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Maintenence));