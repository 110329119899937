import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import ContractsRouter from '../../Contracts/Contracts.Router';

class Contracts extends Component {

    /**
 * Bind Html to DOM 
 */
    render() {
        const { match, projectId } = this.props;
        return (
            <Switch >
                
                <Route path={match.url} render={props => (
                    <ContractsRouter {...props}
                        match={match}
                        loanProjectId={projectId}
                        page={'projects'} 
                        isSubPage={true}
                        />
                )} />
            </Switch>
        );
    }
}

/**
 * Export Component
 */
export default Contracts;