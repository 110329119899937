import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

/**
 *CustomDialog Component
 *
 * @class CustomDialog
 * @extends {React.Component}
 */
class CustomDialog extends React.Component {
    /**
     * Handle Dialog close Action
     */
    handleClose = () => {
        this.props.handleCloseAction()
    };
    /**Handle Dialog Delete Action */
    handleOkay = () => {
        this.props.handleOkayAction(this.props.id)
    }
    /**
     *render html
     *
     * @returns
     * @memberof CustomDialog
     */
    render() {
        const { open, title, saveVisible, cancelVisible, className, confirmationButtonLabel, closeButtonLabel } = this.props;
        return (
            <Dialog
                maxWidth='md'
                fullWidth
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={className}
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <div style={{ borderTop: '1px solid #EEEEEE' }}></div>
                <DialogContent className="overFlowAuto">
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    {
                        saveVisible && (
                            <Button
                                onClick={this.handleOkay}
                                color="primary"
                                variant="contained"
                                size="large"
                                component="span"
                            >
                                {confirmationButtonLabel}
                            </Button>
                        )
                    }
                    {
                        cancelVisible && (
                            <Button
                                onClick={this.handleClose}
                                color="default"
                                variant="contained"
                                size="large"
                                component="span"
                            >
                                {closeButtonLabel}
                            </Button>
                        )
                    }
                </DialogActions>
            </Dialog >
        );
    }
}

//default props
CustomDialog.defaultProps = {
    open: true,
    handleOkayAction: () => { },
    handleClose: () => { },
    maxWidth: 'sm'
}

//prop types
CustomDialog.propTypes = {
    handleOkayAction: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    className: PropTypes.string
};

/**Export Component */
export default CustomDialog;