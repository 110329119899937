import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// Import Components
import BuildingView from "./BuildingView";
import BuildingNew from './BuildingNew';
import BuildingsList from './BuildingsList';
import UnitsRouter from '../Units/Units.Router';

/**
 * Buildings Container Router
 * @class BuildingsRouter
 * @extends Component
 */
class BuildingsRouter extends Component {

    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, siteId, title, isSubPage, projectId } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <BuildingsList
                        {...props}
                        page={'buildings'}
                        siteId={siteId}
                        title={title}
                        projectId={projectId}
                        isSubPage={isSubPage} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <BuildingNew
                        {...props}
                        siteId={siteId}
                        title={'New Building'}
                        projectId={projectId}
                        isSubPage={isSubPage} />
                }} />
                <Route
                    path={`${match.url}/:buildingId/units`}
                    render={(props) => {
                        const title = props.location.state ? props.location.state.title : '';
                        return (
                            <UnitsRouter
                                buildingId={parseInt(props.match.params.buildingId, 10)}
                                title={title}
                                isSubPage={isSubPage}
                                {...props}
                                siteId={siteId}
                                projectId={projectId}
                            />
                        )
                    }}
                />
                <Route path={`${match.url}/:buildingId`} render={(props) => {
                    return <BuildingView buildingId={props.match.params.buildingId}
                        isSubPage={isSubPage}
                        title={title}
                        {...props} siteId={siteId}
                        projectId={projectId} />
                }} />
            </Switch>
        );
    }
}

// default props
BuildingsRouter.defaultProps = {
    isSubPage: false,
    title: 'All Buildings'
}

/**
 * Export BuildingsRouter
 */
export default BuildingsRouter;
