import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

// Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {  convertFromHTML } from 'draft-convert';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import FileUpload from '../../components/FileUpload/FileUpload';
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import { DeleteIcon } from '../../components/SvgIcons/SvgIcons';

// Import Redux Actions and Helper Variable
import { history } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";
import { configurationConstants } from "../../helpers/appConstants";

// Import Actions
import { alertActions, helpActions } from "../../actions";
import { confActions } from '../../actions/configuration';

/**
 * HelpNew container
 * @class HelpNew
 * @extends {Component}
 */
class HelpNew extends Component {
    /**
     * constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.userRef = React.createRef();
        this.state = {
            _id: 0,
            header: '',
            category: null,
            attachment: '',
            url: '',
            editorNotes: EditorState.createEmpty(),
            // editorNotes:[],
            uploadFileData: null,
            open: false,
            isPropsReceived: false
        };
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        this.props.getConfiguration(configurationConstants.HelpCategory);
    }
    /** Component Will Mount */
    UNSAFE_componentWillMount(){
        ValidatorForm.addValidationRule('isValidUrl', (value) => {
            let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            const isValidUrl = pattern.test(value)
            return isValidUrl
        })
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getHelp } = nextProps;
        if (getHelp._id !== null && getHelp._id !== undefined && getHelp._id !== this.state._id) {
            this.setState({
                _id: getHelp._id,
                header: getHelp.title,
                category: getHelp.category,
                attachment: getHelp.attachment ? getHelp.attachment : '',
                url: getHelp.url ? getHelp.url : '',
                editorNotes:getHelp.editorNotes==='<p><br></p>'?EditorState.createEmpty(): this.convertFromHTMLString(getHelp.editorNotes),
            });
        }
    }
    /**
     * Convert from HTML string
     * @param {*} sampleMarkup 
     * @returns 
     */
    convertFromHTMLString (sampleMarkup) {
        sampleMarkup = sampleMarkup || '';
        const blocksFromHTML = convertFromHTML(sampleMarkup);
        return EditorState.createWithContent(blocksFromHTML);
    }
    /**
     * Handle File Upload
     * @param {*} acceptedFiles 
     * @param {*} rejectedFiles 
     */
    handleFileUpload = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length === 0 && acceptedFiles.length === 1) {
            const data = new FormData();
            const file = acceptedFiles[0];
            data.append('file', file);
            data.append('name', file.name);
            this.setState({ uploadFileData: data, attachment: file.name });
        }
        else {
            this.props.showErrorAlert('The Attached file format is not supported.Please upload a file with an extension of csv, docx, xlxs, xls, xml, xsd, pdf, txt, jpg, jpeg, png, mp4, mp3');
        }
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });

    };
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        if (this.state.url !== '' || this.state.attachment !== '') {
            this.props.clearErrorAlert();
            this._saveForm();
        }
        else {
            this.props.showErrorAlert('Select a file or video URL is required');
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Editor State Change
     * @param {*} editorNotes 
     */
    onEditorStateChange(editorNotes) {
        this.setState({ editorNotes});
    }
    /**
     * Saving the data 
     */
    _saveForm() {
        let editorNotes = stateToHTML(this.state.editorNotes.getCurrentContent());
        // set up api form submission data
        const formData = {
            // _id: this.state._id ,
            _id: this.props._id ? this.props._id : null,
            title: this.state.header,
            category: this.state.category,
            attachment: this.state.attachment.toLocaleLowerCase(),
            url: this.state.url,
        };
        this.props.createHelp(formData, editorNotes, this.state.uploadFileData)
    }
    /*on clicking trash icon for upload file delete*/
    handleDeleteClick = () => {
        this.setState({ open: true })
    }
    /*on close after upload file delete*/
    handleDeleteOnCloseAction = () => {
        this.setState({ open: false })
    }
    /**
     * handling delete action for uploaded file
     */
    handleDeleteAction = () => {
        this.setState({ open: false, uploadFileData: null, attachment: '' });
    };
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, isReadOly, pageAccess, isSubPage, needPadding, title, HelpCategory } = this.props;
        const { header, category, attachment, url, editorNotes } = this.state;
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                handleBackActionClick={this.handleBackActionClick}
                isSubPage={isSubPage}
                needPadding={needPadding}
            >
                <Grid item xs={12} sm={12}>
                    <ValidatorForm
                        name="HelpNewListForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={(e) => this.handleSubmit(e)}
                        onError={errors => { }} >

                        <Grid container spacing={3} className={'helpPadding'}>
                            <Grid item xs={12} sm={6}>
                                <TextBox
                                    name="header"
                                    value={header}
                                    label="Title"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    disabled={isReadOly}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} className={'helpAutocomplete'}>
                                <AutoComplete
                                    name="category"
                                    className="autocomplete-fixed"
                                    placeholder='Category'
                                    suggestions={HelpCategory}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            category: selectedOption.value
                                        })
                                    }}
                                    selectedValue={{ id: category }}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                >
                                    <div class={'css-11unzgr'}>
                                        {
                                            HelpCategory.length > 0 &&
                                            HelpCategory.map(items => {
                                                return <MenuItem key={items.key} value={items.value}>{items.name}</MenuItem>
                                            })
                                        }
                                    </div>
                                </AutoComplete>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                                {attachment ?
                                    <div> {attachment}
                                        <IconButton className="delete_icon" color="primary" aria-label="Delete" onClick={() => this.handleDeleteClick()}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                    :
                                    <div className='helpUploadfile'>
                                        <FileUpload
                                            value={'Upload File'}
                                            accept={[".csv", ".jpg", ".jpeg", ".png", ".pdf", ".mp4", ".docx", ".doc", ".xlxs", ".xls", ".xlsx", ".mp3", ".txt",".xml",".xsd"]}
                                            content="Select The Files"
                                            handleFileUpload={this.handleFileUpload}
                                            disabled={url ? true : false}
                                        />
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={2} className={'orOption'}>
                                <h4>OR</h4>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <TextBox
                                    name="url"
                                    value={url}
                                    label="Video Url Streaming"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={url ? ['isValidUrl'] : []}
                                    errorMessages={url ? ['Please enter a valid URL'] : []}
                                    disabled={attachment ? true : false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Editor
                                    editorState={editorNotes}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={this.onEditorStateChange}
                                    toolbar={{
                                        options: ['inline', 'list', 'history'],
                                        inline: {
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline', 'monospace', 'strikethrough']
                                        },
                                        list: {
                                            inDropdown: false,
                                            options: ['unordered', 'ordered']
                                        },
                                        history: { inDropdown: false },
                                    }}
                                    placeholder={'Type Something'}
                                />

                            </Grid>

                        </Grid>
                        <br/>
                        <SaveCancel 
                            handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.goBack() }} />
                    </ValidatorForm>
                </Grid>

                <DeleteDialog
                    open={this.state.open}
                    content={"Are you sure you want to remove this attachment?"}
                    handleDeleteAction={this.handleDeleteAction}
                    handleDeleteOnCloseAction={this.handleDeleteOnCloseAction}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
HelpNew.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    getHelp: PropTypes.object,
    pageAccess: PropTypes.array,
}

/**
 * Default Component Property Types
 */
HelpNew.defaultProps = {
    classes: '',
    pageAccess: [],
    isReadOly: false,
    isSubPage: false,
    needPadding: true,
    title: 'Create new Help',
    getHelp: {},
    _id: null
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess, configuration: { configurations }, help }, ownProps) => {
    let HelpCategory = global._.sortBy(configurations[configurationConstants.HelpCategory], 'name');
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['helpnew'] || [];
    return {
        pageAccess,
        HelpCategory
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        createHelp: (formData, editorNotes, uploadFile) => dispatch(helpActions.createHelp(formData, editorNotes, uploadFile)),
        getConfiguration: (config_names) => dispatch(confActions.getConfiguration(config_names)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearErrorAlert: () => dispatch(alertActions.clear()),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(HelpNew));