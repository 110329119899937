//import constants
import { archivesConstants } from '../constants';

//import services
import { projectsService, userService, orgService, sitesService, funderService } from '../services';

//import actions
import { alertActions, validationActions, pageAccessActions } from './';

//import helper
import { history, getUser } from '../helpers';

export const archivesActions = {
    create,
    update,
    delete: _delete,
    getById,
    getProjects,
    getProjectSites,
    getAllOrg,
    getUsersInOrg,
    isProjectNameExists,
    resetFilter,
    getAllFunders,
    deleteFunder,
    getProjectReport,
    getFunderSettingsConfigDatas,
    getBasicFunderSettings,
    updateFunderSettings,
    getReportSettings,
    createFunderSettings,
    getTable2,
    getTable3,
    updateReportCoverPage,
    getReadOnlyOrg,
    getFunderReportTable,
    findFunderHistory
};

/**
 * A method call API to create the project details
 *
 * @param {*} formData contains the project details data to create
 * @returns
 */
function create(formData) {
    return dispatch => {
        dispatch(request());

        projectsService.create(formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Project added successfully"));
                    // since we clear message on history push
                    // move page after 800 ms
                    setTimeout(() => {
                        history.push("/projects");
                    }, 800);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.CREATE_ARCHIVE_REQUEST } }
    function success(data) { return { type: archivesConstants.CREATE_ARCHIVE_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.CREATE_ARCHIVE_FAILURE, error } }
}

/**
 * A method call API to update the project details
 *
 * @param {*} id contains the id of project to update
 * @param {*} formData contains the project details data to update
 * @returns
 */
function update(id, formData) {
    return dispatch => {
        dispatch(request());

        projectsService.update(id, formData)
            .then(
                data => {
                    dispatch(success(data));
                    // history.push("/projects");
                    dispatch(alertActions.success("Updated successfully"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.UPDATE_ARCHIVE_REQUEST } }
    function success(data) { return { type: archivesConstants.UPDATE_ARCHIVE_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.UPDATE_ARCHIVE_FAILURE, error } }
}

/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - project_id
 * @returns
 */
function _delete(id, page = 'projects') {
    return dispatch => {
        dispatch(request(id));

        projectsService.delete(id, page)
            .then(
                data => {
                    dispatch(success(id));
                    dispatch(alertActions.success(page === 'projects' ? 'Deleted successfully' : 'Retrieved successfully'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 1000);
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };


    function request() { return { type: archivesConstants.DELETE_ARCHIVE_REQUEST } }
    function success(id) { return { type: archivesConstants.DELETE_ARCHIVE_SUCCESS, id } }
    function failure(error) { return { type: archivesConstants.DELETE_ARCHIVE_FAILURE, error } }
}

/**
 *Get single project view
 *
 * @param {*} id - project_id
 * @param {*} [callBack=() => { }]
 * @returns
 */
function getById(id, page = 'archives', callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        projectsService.getById(id, page)
            .then(
                project => {
                    dispatch(success(project.data))
                    let orgId = project.data.owners.org_id ? project.data.owners.org_id._id : getUser().org.id;
                    dispatch(getUsersInOrg(orgId));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_ONE_PJT_REQUEST } }
    function success(project) { return { type: archivesConstants.GET_ONE_PJT_SUCCESS, project } }
    function failure(error) { return { type: archivesConstants.GET_ONE_PJT_FAILURE, error } }
}

/**
 *Get project listing page details
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [exactMatch=false]
 * @param {*} [userId=null] - default value null
 * @returns
 */
function getProjects(skip, limit, orderBy, order, sFields, sValues, exactMatch = false, userId = null, query = null) {
    return dispatch => {
        dispatch(request());

        projectsService.getAll(skip, limit, orderBy, order, sFields, sValues, exactMatch, userId, query)
            .then(
                projects => {
                    dispatch(success(projects));
                    const { page } = projects;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.PAGE_GETALL_ARCHIVES_REQUEST } }
    function success(projects) { return { type: archivesConstants.PAGE_GETALL_ARCHIVES_SUCCESS, projects } }
    function failure(error) { return { type: archivesConstants.PAGE_GETALL_ARCHIVES_FAILURE, error } }
}

/**
 * A service call to check whether the project name is exists or not
 * 
 * @param {*} data 
 * @param {*} callBack 
 */
function isProjectNameExists(data, callBack) {
    return dispatch => {
        dispatch(request());

        projectsService.isProjectNameExists(data)
            .then(
                data => {
                    dispatch(success(data.data));
                    callBack(data.data);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.IS_ARCHIVE_NAME_EXISTS_REQUEST } }
    function success(projects) { return { type: archivesConstants.IS_ARCHIVE_NAME_EXISTS_SUCCESS, projects } }
    function failure(error) { return { type: archivesConstants.IS_ARCHIVE_NAME_EXISTS_FAILURE, error } }
}

/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
function getAllOrg(text, page) {
    return dispatch => {
        dispatch(request());

        orgService.getOrganizationsBySearchKey(text, page)
            .then(
                org => {
                    let orgs = org.data.map((data) => {
                        data.label = data.name;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(orgs));
                    // callBack();
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_ALL_ORGS_REQUEST } }
    function success(orgs) { return { type: archivesConstants.GET_ALL_ORGS_SUCCESS, orgs } }
    function failure(error) { return { type: archivesConstants.GET_ALL_ORGS_FAILURE, error } }
}

/**
 * Get Read Only orgs
 */
function getReadOnlyOrg() {
    return dispatch => {
        dispatch(request());

        orgService.getReadOnlyOrg()
            .then(
                org => {

                    let orgs = org.data.map((data) => {
                        data.label = data.name;
                        data.value = data._id;
                        return data;
                    });

                    dispatch(success(orgs));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_READ_ONLY_ORGS_REQUEST } }
    function success(orgs) { return { type: archivesConstants.GET_READ_ONLY_ORGS_SUCCESS, orgs } }
    function failure(error) { return { type: archivesConstants.GET_READ_ONLY_ORGS_FAILURE, error } }
}

/**
 * A meethod to get users in organizations id except role onsite manager
 *
 * @param {*} orgId contains organizations id
 * @returns
 */
function getUsersInOrg(orgId) {
    return dispatch => {
        dispatch(request());

        userService.getByOrgId(orgId)
            .then(
                user => {
                    let users = user.data.map((data) => {
                        data.label = `${data.firstName} ${data.lastName}`;
                        // add star before  inactive
                        data.label = data.isActive ? data.label : `*${data.label}`;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_ORG_USERS_REQUEST } }
    function success(users) { return { type: archivesConstants.GET_ORG_USERS_SUCCESS, users } }
    function failure(error) { return { type: archivesConstants.GET_ORG_USERS_FAILURE, error } }
}

/**
 * Get all funder listing page
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} projectId - project_id
 * @param {*} exactMatch 
 */
function getAllFunders(skip, limit, orderBy, order, sFields, sValues, projectId) {
    return dispatch => {
        dispatch(request());

        funderService.getAll(skip, limit, orderBy, order, sFields, sValues, projectId)
            .then(
                funders => dispatch(success(funders)),
                error => dispatch(failure(error.toString()))
            );

    };
    function request() { return { type: archivesConstants.GET_ALL_FUNDERS_REQUEST } }
    function success(funders) { return { type: archivesConstants.GET_ALL_FUNDERS_SUCCESS, funders } }
    function failure(error) { return { type: archivesConstants.GET_ALL_FUNDERS_FAILURE, error } }
}

function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: archivesConstants.ARCHIVES_CLEAR_FILTER } }
    function reset() { return { type: archivesConstants.ARCHIVES_CLEAR_FILTER_RESET } }
}

/**
 *  An action method to get the sites based on the project id 
 * @param {*} skip page number from to fetch record
 * @param {*} limit record limit to fetch
 * @param {*} orderBy sorting order column
 * @param {*} order sort asc or desc
 * @param {*} sFields search field column names
 * @param {*} sValues search field values
 * @param {*} projectId id of project to get the sites below it
 */
function getProjectSites(skip, limit, orderBy, order, sFields, sValues, projectId = null, query = null) {
    return dispatch => {
        dispatch(request());

        sitesService.getAll(skip, limit, orderBy, order, sFields, sValues, projectId, false, query)
            .then(
                sites => dispatch(success(sites)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_ARCHIVE_SITES_REQUEST } }
    function success(sites) { return { type: archivesConstants.GET_ARCHIVE_SITES_SUCCESS, sites } }
    function failure(error) { return { type: archivesConstants.GET_ARCHIVE_SITES_FAILURE, error } }
}

/**
 * Delete funder
 * 
 * @param {*} id 
 */
function deleteFunder(id, projectId, callBack = () => { }) {
    return dispatch => {
        dispatch(request(id));

        funderService.delete(id)
            .then(
                funders => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                    dispatch(pageAccessActions.getAccess('fundersList', { project_id: projectId }));
                    callBack();
                },
                error => dispatch(failure(id, error.toString()))
            );
    };


    function request() { return { type: archivesConstants.DELETE_FUNDER_REQUEST } }
    function success(id) { return { type: archivesConstants.DELETE_FUNDER_SUCCESS, id } }
    function failure(error) { return { type: archivesConstants.DELETE_FUNDER_FAILURE, error } }
}

function getProjectReport(skip, limit, orderBy, order, sFields, sValues, projectId) {
    return dispatch => {
        dispatch(request());
        projectsService.getProjectReport(skip, limit, orderBy, order, sFields, sValues, projectId)
            .then(
                reports => dispatch(success(reports)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_ARCHIVE_REPORT_REQUEST } }
    function success(reports) { return { type: archivesConstants.GET_ARCHIVE_REPORT_SUCCESS, reports } }
    function failure(error) { return { type: archivesConstants.GET_ARCHIVE_REPORT_FAILURE, error } }
}


/**
 * An Action method to get funder settings basic config datas
 *
 */
function getFunderSettingsConfigDatas(id, orgId) {
    return dispatch => {
        dispatch(request());
        projectsService.getFunderSettingsConfigDatas(id, orgId)
            .then(
                settings => dispatch(success(settings.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_FUNDER_CONFIG_SETTINGS_REQUEST } }
    function success(settings) { return { type: archivesConstants.GET_FUNDER_CONFIG_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: archivesConstants.GET_FUNDER_CONFIG_SETTINGS_FAILURE, error } }
}

/**
 * An Action method to get the funder basic settings for the project
 *
 * @param {*} funderId contains the  project id to get the settings
 */
function getBasicFunderSettings(funderId, orgId) {
    return dispatch => {
        dispatch(request());
        projectsService.getBasicFunderSettings(funderId, orgId)
            .then(
                settings => dispatch(success(settings.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_FUNDER_BASIC_SETTINGS_REQUEST } }
    function success(data) { return { type: archivesConstants.GET_FUNDER_BASIC_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.GET_FUNDER_BASIC_SETTINGS_FAILURE, error } }
}

/**
 * Get funder report table data
 * 
 * @param {*} funderId 
 */
function getFunderReportTable(funderId) {
    return dispatch => {
        dispatch(request());
        projectsService.getFunderReportTable(funderId)
            .then(
                settings => dispatch(success(settings.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.GET_FUNDER_REPORT_TABLE_REQUEST } }
    function success(data) { return { type: archivesConstants.GET_FUNDER_REPORT_TABLE_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.GET_FUNDER_REPORT_TABLE_FAILURE, error } }
}

/**
 * An Action method to get the funder basic settings for the project
 *
 * @param {*} formData contains the data to get the settings 
 * @param {*} funderId contains the funder id to get the settings
 */
function updateFunderSettings(formData, funderId) {
    return dispatch => {
        dispatch(request());
        projectsService.updateFunderSettings(formData, funderId)
            .then(
                data => {
                    if (formData.reports.length === 0) {
                        dispatch(success(data));
                        // update over due in projects
                        dispatch(validationActions.updateOverDueSites(0, formData.project_id));
                        dispatch(alertActions.success("Updated successfully"));
                        setTimeout(() => {
                            history.push({
                                pathname: `/projects/${formData.project_id}/funders/${funderId}`,
                                state: {
                                    page: 'funderEdit',
                                    pageData: {
                                        funder_id: funderId
                                    },
                                }
                            });
                        }, 100);
                        history.push(`/projects/${formData.project_id}/funders`);
                    }
                    else {
                        dispatch(success(data));
                        // update over due in projects
                        dispatch(validationActions.updateOverDueSites(0, formData.project_id));
                        dispatch(alertActions.success("Updated successfully"));
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.UPDATE_FUNDER_SETTINGS_REQUEST } }
    function success(data) { return { type: archivesConstants.UPDATE_FUNDER_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.UPDATE_FUNDER_SETTINGS_FAILURE, error } }
}

/**
 * Get report settings
 * 
 * @param {*} id 
 */
function getReportSettings(id) {
    return dispatch => {
        dispatch(request());

        projectsService.getReportOne(id)
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.GET_ARCHIVE_ONE_REPORT_REQUEST } }
    function success(data) { return { type: archivesConstants.GET_ARCHIVE_ONE_REPORT_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.GET_ARCHIVE_ONE_REPORT_FAILURE, error } }
}

/**
 * An Action method to Create the funder basic settings for the project
 *
 * @param {*} formData contains the data to create the settings
 */
function createFunderSettings(formData) {
    return dispatch => {
        dispatch(request());
        projectsService.createFunderSettings(formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Created successfully"));
                    // history.push(`/projects/${formData.project_id}/funders`);
                    setTimeout(() => {
                        history.push({
                            pathname: `/projects/${formData.project_id}/funders/${data.data}`,
                            state: {
                                page: 'funderEdit',
                                pageData: {
                                    funder_id: data.data
                                },
                            }
                        });
                    }, 100);
                    history.push(`/projects/${formData.project_id}/funders`);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: archivesConstants.CREATE_FUNDER_SETTINGS_REQUEST } }
    function success(data) { return { type: archivesConstants.CREATE_FUNDER_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.CREATE_FUNDER_SETTINGS_FAILURE, error } }
}

/**
 * An Action method for Table2
 */
function getTable2(id) {
    return dispatch => {
        dispatch(request());

        projectsService.getTable2(id)
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.GET_TABLE2_REQUEST } }
    function success(data) { return { type: archivesConstants.GET_TABLE2_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.GET_TABLE2_FAILURE, error } }
}


/**
 * An Action method for Table3
 */
function getTable3(id) {
    return dispatch => {
        dispatch(request());

        projectsService.getTable3(id)
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.GET_TABLE3_REQUEST } }
    function success(data) { return { type: archivesConstants.GET_TABLE3_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.GET_TABLE3_FAILURE, error } }
}

/**
* A method to update the report cover page details
*
* @param {*} id contains the reportyear _id to update 
* @param {*} data contains the form datas to update 
*/
function updateReportCoverPage(id, data) {
    return dispatch => {
        dispatch(request());
        projectsService.updateReportCoverPage(id, data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Created successfully"));
                    history.goBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.UPDATE_REPORTCOVERPAGE_REQUEST } }
    function success(data) { return { type: archivesConstants.UPDATE_REPORTCOVERPAGE_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.UPDATE_REPORTCOVERPAGE_FAILURE, error } }
}

/**
 *A method to find whether funder has unclaimed already
 *
 * @param {*} projectId
 */
function findFunderHistory(projectId, org_id, callBack) {
    return dispatch => {
        dispatch(request());
        projectsService.findFunderHistory(projectId, org_id)
            .then(
                data => {
                    callBack(data);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: archivesConstants.GET_FUNDER_HISTORY_REQUEST } }
    // function success(data) { return { type: archivesConstants.GET_FUNDER_HISTORY_SUCCESS, data } }
    function failure(error) { return { type: archivesConstants.GET_FUNDER_HISTORY_FAILURE, error } }
}