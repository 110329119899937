import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TextValidator } from 'react-material-ui-form-validator';

// Table validations warning helper
import { hasWarnings } from '../../helpers/TableValidations/warnings';

/**
 *DatePicker Component
 *
 * @class DatePicker
 * @extends {Component}
 */
class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            setFocus:false
        }
    }
    /**
     * Handle TextField Events
     * @param {*} date 
     */
    handleChange = (date) => {
        this.props.handleChange(this.props.name, date);
    };
    handleonFocus = (event) => {
        this.props.onFocus(event.target, event.target.name)
    };
    /**
     * Set Hover state
     */
    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }
    /**
     *render html
     *
     * @returns
     * @memberof DatePicker
     */
    render() {
        const { value, name, label, disabled, margin, className, validators, errorMessages, validations, toolTipTitle, format, fielderrors, warningfieldname, style, notRequired, tooltipOnHover } = this.props;
        const customClass = validators && validators.length > 0 && !notRequired ? className + ' required' : className;
        const warningClass = hasWarnings(fielderrors, warningfieldname);
        return (
            <div class="containers datepicker" >
                <span class={!tooltipOnHover &&toolTipTitle?"tooltiptextHover":toolTipTitle&&this.state.setFocus ?"tooltiptext":"tooltiptextNone"}>{toolTipTitle}</span>

                <KeyboardDatePicker
                    inputVariant='standard'
                    label={label && `${label} (MM/DD/YYYY)`}
                    variant="inline"
                    name={name}
                    value={value ? value : null}
                    onChange={this.handleChange}
                    format={format}
                    // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                    className={`datePicker ${customClass} ${warningClass}`}
                    disabled={disabled}
                    margin={margin}
                    TextFieldComponent={TextValidator}
                    invalidDateMessage={errorMessages}
                    validators={validators}
                    errorMessages={errorMessages}
                    style={style}
                    {...validations}
                    autoOk={true}
                    inputProps={{
                        onFocus: (e) => { this.setState({setFocus:true}) },
                        onBlur:(e)=>{this.setState({setFocus:false})}
                    }}
                />
            {/* //  </Tooltip> */}
            </div>
        );
    }
}

//default props
DatePicker.defaultProps = {
    name: "",
    value: "",
    label: "",
    disabled: false,
    margin: "dense",
    className: "",
    errorMessages: [],
    validators: [],
    handleChange: () => { },
    validations: {},
    onBlur: () => { },
    format: "MM/DD/YYYY",
    fielderrors: [],
    warningfieldname: '',
    style: {},
    helperText: "mm/dd/yyyy (e.g 01/01/1991)",
    notRequired: false
}

//prop types
DatePicker.propTypes = {
    name: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    helperText: PropTypes.string
};
//Export Component
export default DatePicker;
