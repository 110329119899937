export const reportActivityConstants = {

    // get certification form 
    GET_CERTIFICATION_FORM_REQUEST: 'GET_CERTIFICATION_FORM_REQUEST',
    GET_CERTIFICATION_FORM_SUCCESS: 'GET_CERTIFICATION_FORM_SUCCESS',
    GET_CERTIFICATION_FORM_FAILURE: 'GET_CERTIFICATION_FORM_FAILURE',

    // create certification form
    CREATE_CERTIFICATION_FORM_REQUEST: 'CREATE_CERTIFICATION_FORM_REQUEST',
    CREATE_CERTIFICATION_FORM_SUCCESS: 'CREATE_CERTIFICATION_FORM_SUCCESS',
    CREATE_CERTIFICATION_FORM_FAILURE: 'CREATE_CERTIFICATION_FORM_FAILURE',

    // update certification form
    UPDATE_CERTIFICATION_FORM_REQUEST: 'UPDATE_CERTIFICATION_FORM_REQUEST',
    UPDATE_CERTIFICATION_FORM_SUCCESS: 'UPDATE_CERTIFICATION_FORM_SUCCESS',
    UPDATE_CERTIFICATION_FORM_FAILURE: 'UPDATE_CERTIFICATION_FORM_FAILURE',

    // get financial activity 
    GET_FINANCIAL_ACTIVITY_REQUEST: 'GET_FINANCIAL_ACTIVITY_REQUEST',
    GET_FINANCIAL_ACTIVITY_SUCCESS: 'GET_FINANCIAL_ACTIVITY_SUCCESS',
    GET_FINANCIAL_ACTIVITY_FAILURE: 'GET_FINANCIAL_ACTIVITY_FAILURE',

    // create financial activity
    CREATE_FINANCIAL_ACTIVITY_REQUEST: 'CREATE_FINANCIAL_ACTIVITY_REQUEST',
    CREATE_FINANCIAL_ACTIVITY_SUCCESS: 'CREATE_FINANCIAL_ACTIVITY_SUCCESS',
    CREATE_FINANCIAL_ACTIVITY_FAILURE: 'CREATE_FINANCIAL_ACTIVITY_FAILURE',

    // update financial activity
    UPDATE_FINANCIAL_ACTIVITY_REQUEST: 'UPDATE_FINANCIAL_ACTIVITY_REQUEST',
    UPDATE_FINANCIAL_ACTIVITY_SUCCESS: 'UPDATE_FINANCIAL_ACTIVITY_SUCCESS',
    UPDATE_FINANCIAL_ACTIVITY_FAILURE: 'UPDATE_FINANCIAL_ACTIVITY_FAILURE',

    // get program activity 
    GET_PROGRAM_ACTIVITY_REQUEST: 'GET_PROGRAM_ACTIVITY_REQUEST',
    GET_PROGRAM_ACTIVITY_SUCCESS: 'GET_PROGRAM_ACTIVITY_SUCCESS',
    GET_PROGRAM_ACTIVITY_FAILURE: 'GET_PROGRAM_ACTIVITY_FAILURE',

    // get activity access
    GET_ACTIVITY_ACCESS_REQUEST: 'GET_ACTIVITY_ACCESS_REQUEST',
    GET_ACTIVITY_ACCESS_SUCCESS: 'GET_ACTIVITY_ACCESS_SUCCESS',
    GET_ACTIVITY_ACCESS_FAILURE: 'GET_ACTIVITY_ACCESS_FAILURE',

    // create program activity
    CREATE_PROGRAM_ACTIVITY_REQUEST: 'CREATE_PROGRAM_ACTIVITY_REQUEST',
    CREATE_PROGRAM_ACTIVITY_SUCCESS: 'CREATE_PROGRAM_ACTIVITY_SUCCESS',
    CREATE_PROGRAM_ACTIVITY_FAILURE: 'CREATE_PROGRAM_ACTIVITY_FAILURE',

    // update program activity
    UPDATE_PROGRAM_ACTIVITY_REQUEST: 'UPDATE_PROGRAM_ACTIVITY_REQUEST',
    UPDATE_PROGRAM_ACTIVITY_SUCCESS: 'UPDATE_PROGRAM_ACTIVITY_SUCCESS',
    UPDATE_PROGRAM_ACTIVITY_FAILURE: 'UPDATE_PROGRAM_ACTIVITY_FAILURE',

    // get all contract activity 
    GETALL_LOAN_ACTIVITY_REQUEST: 'GETALL_LOAN_ACTIVITY_REQUEST',
    GETALL_LOAN_ACTIVITY_SUCCESS: 'GETALL_LOAN_ACTIVITY_SUCCESS',
    GETALL_LOAN_ACTIVITY_FAILURE: 'GETALL_LOAN_ACTIVITY_FAILURE',

    // get contract activity 
    GET_LOAN_ACTIVITY_REQUEST: 'GET_LOAN_ACTIVITY_REQUEST',
    GET_LOAN_ACTIVITY_SUCCESS: 'GET_LOAN_ACTIVITY_SUCCESS',
    GET_LOAN_ACTIVITY_FAILURE: 'GET_LOAN_ACTIVITY_FAILURE',

    // create contract activity
    CREATE_LOAN_ACTIVITY_REQUEST: 'CREATE_LOAN_ACTIVITY_REQUEST',
    CREATE_LOAN_ACTIVITY_SUCCESS: 'CREATE_LOAN_ACTIVITY_SUCCESS',
    CREATE_LOAN_ACTIVITY_FAILURE: 'CREATE_LOAN_ACTIVITY_FAILURE',

    // update contract activity
    UPDATE_LOAN_ACTIVITY_REQUEST: 'UPDATE_LOAN_ACTIVITY_REQUEST',
    UPDATE_LOAN_ACTIVITY_SUCCESS: 'UPDATE_LOAN_ACTIVITY_SUCCESS',
    UPDATE_LOAN_ACTIVITY_FAILURE: 'UPDATE_LOAN_ACTIVITY_FAILURE',

    CLEAR_LOAN_ACTIVITY: 'CLEAR_LOAN_ACTIVITY',

    SUBMIT_LOAN_REQUEST: 'SUBMIT_LOAN_REQUEST',
    SUBMIT_LOAN_SUCCESS: 'SUBMIT_LOAN_SUCCESS',
    SUBMIT_LOAN_FAILURE: 'SUBMIT_LOAN_FAILURE',

    SUBMIT_LOAN_EDIT_REQUEST: 'SUBMIT_LOAN_EDIT_REQUEST',
    SUBMIT_LOAN_EDIT_SUCCESS: 'SUBMIT_LOAN_EDIT_SUCCESS',
    SUBMIT_LOAN_EDIT_FAILURE: 'SUBMIT_LOAN_EDIT_FAILURE',

    UNSUBMIT_LOAN_REQUEST: 'UNSUBMIT_LOAN_REQUEST',
    UNSUBMIT_LOAN_SUCCESS: 'UNSUBMIT_LOAN_SUCCESS',
    UNSUBMIT_LOAN_FAILURE: 'UNSUBMIT_LOAN_FAILURE',

    //download loan project report constant
    DOWNLOAD_REPORT_REQUEST: 'DOWNLOAD_REPORT_REQUEST',
    DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
    DOWNLOAD_REPORT_FAILURE: 'DOWNLOAD_REPORT_FAILURE',
    
    SAVE_LOAN_REQUEST: 'SAVE_LOAN_REQUEST',
    SAVE_LOAN_SUCCESS: 'SAVE_LOAN_SUCCESS',
    SAVE_LOAN_FAILURE: 'SAVE_LOAN_FAILURE',

    GET_INCOMELIMIT_REQUEST: 'GET_INCOMELIMIT_REQUEST',
    GET_INCOMELIMIT_SUCCESS: 'GET_INCOMELIMIT_SUCCESS',
    GET_INCOMELIMIT_FAILURE: 'GET_INCOMELIMIT_FAILURE',

    // get report year
    GET_REPORTYEAR_REQUEST:'GET_REPORTYEAR_REQUEST',
    GET_REPORTYEAR_SUCCESS: 'GET_REPORTYEAR_SUCCESS',
    GET_REPORTYEAR_FAILURE:'GET_REPORTYEAR_FAILURE',

    GETALL_BYREPORT_LOAN_ACTIVITY_REQUEST: 'GETALL_BYREPORT_LOAN_ACTIVITY_REQUEST',
    GETALL_BYREPORT_LOAN_ACTIVITY_SUCCESS: 'GETALL_BYREPORT_LOAN_ACTIVITY_SUCCESS',
    GETALL_BYREPORT_LOAN_ACTIVITY_FAILURE: 'GETALL_BYREPORT_LOAN_ACTIVITY_FAILURE',

    // validation issue
    VALIDATION_ISSUE_REQUEST: 'GET_VALIDATION_ISSUE_REQUEST',
    VALIDATION_ISSUE_SUCCESS: 'GET_VALIDATION_ISSUE_SUCCESS',
    VALIDATION_ISSUE_FAILURE: 'GET_VALIDATION_ISSUE_FAILURE',

      // delete contract
      DELETE_REQUEST: 'CONTRACTACTIVITY_DELETE_REQUEST',
      DELETE_SUCCESS: 'CONTRACTACTIVITY_DELETE_SUCCESS',
      DELETE_FAILURE: 'CONTRACTACTIVITY_DELETE_FAILURE',
}