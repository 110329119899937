import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import MenuComponent from '../../components/Menu/Menu';
import HelpContent from './HelpContent';
import PageTitle from '../../components/PageTitle/PageTitle';

// Import SVG Icon
import { SvgLoadIcon } from '../../components/SvgIcons/SvgIcons';

//Import Helpers
import { history } from '../../helpers';

// Import Redux Actions and Helper Variable
import { helpActions } from '../../actions/help';
import { confActions } from '../../actions/configuration';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Helpes
import { configurationConstants } from '../../helpers/appConstants';
import { getHelpPageMenuHeight, getHelpPageContentHeight } from '../../helpers/global';

/**
 * Help Container
 * @class Help
 * @extends {Component}
 */
class Help extends Component {
    /**
     * constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            fieldFilters: false,
            reset: false,
            downloadFile: false,
            fileName: "",
            open: false,
            deleteItemId: null,
            selectedItem: null,
            isMenuOpen: true,
            selectedMenuName: 'general'
        }
    }
    //** component will mount */
    UNSAFE_componentWillMount() {
        this.props.location.state && this.props.getHelp(this.props.location.state.type);
        this.props.getMenuByName();
    }
    /**
     * Handle Add Action Click Event
     * @memberof ContentWrapper
     */
    handleAddActionClick = () => {
        const { match } = this.props;
        history.push({
            pathname: `${match.url}/new`,
            state: {
                page: 'helpnew',
                pageData: {}
            }
        });
    };
    /**
     * handleItemClick
     * @param {*} help 
     */
    handleItemClick(help) {
        this.setState({ selectedItem: help.key, selectedMenuName: help.name });
        this.props.getHelp(help.key);
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, pageAccess, isSubPage, title, needPadding, location: { pathname }, help, menuItems, match } = this.props;
        const { isMenuOpen } = this.state
        let helpContentWidth = isMenuOpen ? 9 : 12
        let selectedMenuName = match.path.split('/')[2]
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                needPadding={needPadding}
                isSubPage={isSubPage}
                title={title}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
            >
                <div className={isMenuOpen ? 'helpContainer' : "helpContainer p-l-12"}>
                    <Grid container className="helpContainerShadow">
                        {isMenuOpen && <Grid item xs={12} sm={3} className={"helpMenu"}>
                            <div style={{ height: getHelpPageMenuHeight() }}>
                                <MenuComponent
                                    menuDatas={menuItems}
                                    handleItemClick={(help) => this.handleItemClick(help)}
                                    match={match}
                                />
                            </div>
                        </Grid>}
                        <Grid item xs={12} sm={helpContentWidth} className={isMenuOpen ? "helpContent" : ''} style={{ position: 'relative', border: '1px solid #eee', paddingLeft: '12px' }}>
                            <div className="helpPageContent" style={{ height: getHelpPageContentHeight() }}>
                                <div className={classes.leftSliderIcon}>
                                    {
                                        !isMenuOpen ? <div className={classes.rightSliderIconContainer} onClick={() => this.setState({ isMenuOpen: true })}><i class="fa fa-chevron-right flexIcon" aria-hidden="true"></i></div>
                                            : <div className={classes.leftSliderIconContainer} onClick={() => this.setState({ isMenuOpen: false })}><i class="fa fa-chevron-left flexIcon" aria-hidden="true" ></i></div>
                                    }
                                </div>
                                {/* {!isMenuOpen &&  */}
                                <PageTitle
                                    title={selectedMenuName}
                                    subTitle={''}
                                    actions={[]}
                                />
                                {/* // } */}
                                <HelpContent
                                    helpData={help}
                                    match={match}
                                    handleGetHelps={() => this.props.getHelp(this.props.location.state.type)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {help && help.isLoading &&
                    <div className={'svgloader'}>
                        <SvgLoadIcon></SvgLoadIcon>
                    </div>
                }
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
Help.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    title: PropTypes.string,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
};

/**
 * Default Props Types
 */
Help.defaultProps = {
    needPadding: false,
    tableActionRequired: true,
    title: "Help",
    isSubPage: false,
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess, help, configuration }, ownProps) => {
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['help'] || [];
    help = help.data[0] || {};
    const menuItems = configuration.configurations[configurationConstants.HelpCategory] || [];
    return {
        help,
        pageAccess,
        menuItems
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAll: () => dispatch(helpActions.getAll({})),
        getHelp: (key) => dispatch(helpActions.getAll({ _id: key })),
        deleteHelp: (id) => dispatch(helpActions.delete(id)),
        viewFile: (id, fileName, ext) => dispatch(helpActions.viewFile(id, fileName, ext)),
        getMenuByName: () => dispatch(confActions.getByName('HelpCategory')),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Help));
