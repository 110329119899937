import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'underscore';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import SitesEdit from './SitesEdit';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Actions
import { sitesActions } from '../../actions/sites';
import { projectsActions } from '../../actions/projects';
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * SitesNew Component
 * @class SitesNew
 * @extends {Component}
 */
class SitesNew extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.userRef = React.createRef();
        this.state={
            submitted:false
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount () {
        const { projectId } = this.props;
        if (projectId) {
            this.props.getById(projectId);
        }
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.siteRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            if (this.props.projectId) {
                formData.project_id = parseInt(this.props.projectId, 10);
            }
            if(!this.state.submitted){
                this.setState({submitted:true})
            this.props.addNewSite(formData,(site)=>{
                    this.setState({submitted:false})
            },(error)=>{
                this.setState({submitted:false})
            });
        }
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Bind Html to DOM
     */
    render() {
        let { projectId, match, location: { pathname }, title,
            removeActions, pageAccess, isSubPage, classes } = this.props;

        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        pageAccess = _.difference(pageAccess, removeActions);

        return (
            <ContentWrapper
                classes={classes}
                pageAccess={match.url === pathname ? pageAccess : ['BACK']}
                title={title}
                isSubPage={isSubPage}
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
            >
                <SitesEdit
                    location={this.props.location}
                    isSubPage={isSubPage}
                    projectId={projectId}
                    pageAccess={pageAccess}
                    innerRef={(node) => { this.siteRef = node; }}
                    isReadOly={isReadOly}
                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                    isArchived={pathname.includes('archives')}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
SitesNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};
/** Default Props */
SitesNew.defaultProps = {
    projectId: null,
    title: "New Site",
    removeActions: [],
    isSubPage: false
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['siteNew'] || []
    }
};
/**
 * Maps Dispa to propstch
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        addNewSite: (formData,successCallback,errorCallback) => dispatch(sitesActions.addNewSite(formData,successCallback,errorCallback)),
        getById: (id) => dispatch(projectsActions.getById(id)),
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(SitesNew));





