import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import LoanProjectsList from "./LoanProjectsList";
import LoanProjectsView from "./LoanProjectsView";
import LoanProjectsNew from './LoanProjectsNew'

class LoanProjectsRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }

    /**
     * Bind Html to DOM
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <LoanProjectsList match={props.match} page='loanProjects' {...props} />
                }} />
                <Route exact path={`${match.url}/new/:copyId`} render={(props) => {
                    return <LoanProjectsNew {...props} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <LoanProjectsNew {...props} />
                }} />
                <Route path={`${match.url}/:project_id`} render={(props) => {
                    return <LoanProjectsView {...this.props} loanProjectId={props.match.params.project_id} />
                }} />
            </Switch>
        );
    }
}

/**
 * Bind Component Property Types
 */
LoanProjectsRouter.propTypes = {
    classes: PropTypes.object,
};


/**
 * Export Component
 */
export default LoanProjectsRouter;
