import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Components
import AutoCompleteMultiMUI from '../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import TextBox from '../../components/TextBox/TextBox';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';

// Helpers
import { getUserRole, getUserId, getUserOrgId, configurationConstants } from '../../helpers';
import { history } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Actions
import { buildingActions, projectsActions, sitesActions } from '../../actions';
import { confActions } from '../../actions/configuration';

/**
 * Buildings Bulk Upload Container
 * @class BuildingsBulkUpload
 * @extends {React.Component}
 */
class BuildingsBulkUpload extends Component {
    /**
     * Constructor
     * @param {*} props 
     * @memberof BuildingsBulkUpload
     */
    constructor(props) {
        super(props);

        this.state = {
            selectedProjects: {},
            selectedSites: [],
            selectedBuildings: [],
            selectedCity: {},
            confirmPopup: false,
            address: '',
            zipcode: ''
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.pageAccess = ['UPDATE', 'SAVE', 'DELETE'];
    }
    /**
     * A method that is after render
     * @memberof BuildingsBulkUpload
     */
    componentDidMount() {
        this.props.getProjectNameList(null);
        this.props.getSiteNameList(null);
        this.props.getBuildingNameList(null);
        this.props.getConfiguration(configurationConstants.cities);
    }
    /**
     * Handle Form Submit Event
     * @memberof ValidatorForm
     */
    handleSubmit = () => {
        this.setState({ confirmPopup: true })
    }
    /**
     * Handle save after confirmation
     * @param {*} e
     * @memberof DeleteDialog
     * 
     * [1]  -   Fetch building ids form selectedBuildings
     * [2]  -   Fetch city id form selectedCity
     * [3]  -   Update the buildings and clear the state
     */
    _saveForm = (e) => {
        const { buildingNames } = this.props;
        const { selectedBuildings, address, selectedCity, zipcode } = this.state;
        let buildingIds = [];
        let cityId = null;
        // [1]
        if (selectedBuildings.length > 0) {
            if (selectedBuildings[0].value === 0) {
                buildingIds = buildingNames.map((elem) => elem.value);
            } else {
                buildingIds = selectedBuildings.map((elem) => elem.value);
            }
        }
        // [2]
        if (selectedCity && selectedCity.value) {
            cityId = selectedCity.value
        }
        let updateData = {
            buildingIds: buildingIds,
            address: address,
            cityId: cityId,
            zipcode: zipcode
        }
        // [3]
        this.props.bulkUpdate(updateData)
        this.setState({
            selectedProjects: {},
            selectedSites: [],
            selectedBuildings: [],
            selectedCity: {},
            address: '',
            zipcode: ''
        })
        // window.location.reload();
        };
    /**
     * A method that is used to get values of the auto complete
     * @param {*} data 
     * @param {*} name 
     * 
     * [1]  -   Feteh project id from data
     * [2]  -   Fetch site ids
     *          if - select all is selected fetch from siteNames
     *          else fetch from data
     */
    getValues(data, name) {
        const { siteNames } = this.props;
        const { selectedProjects } = this.state;
        switch (name) {
            // [1]
            case 'project':
                if (Object.keys(data).length > 0) {
                    return {
                        projectIds: [data.value],
                    };
                }
                break;
            // [2]
            case 'site':
                if (data[0]) {
                    if (data[0].value === 0) {
                        return {
                            siteIds: siteNames.map((elem) => elem.value),
                            projectIds: Object.keys(selectedProjects).length > 0 ? [selectedProjects.value] : []
                        };
                    } else {
                        return {
                            siteIds: data.map((elem) => elem.value),
                            projectIds: Object.keys(selectedProjects).length > 0 ? [selectedProjects.value] : []
                        };
                    }
                }
                break;
            default:
                break;
        }
    }
    /**
     * A method that is used to handle AutoComplete
     * @param {*} data 
     * @param {*} name 
     * @memberof AutoComplete
     */
    handleAutoCompolete = (data, name) => {
        switch (name) {
            case 'project':
                this.setState({ selectedProjects: data, selectedSites: [], selectedBuildings: [] })
                this.props.getSiteNameList(this.getValues(data, name))
                this.props.getBuildingNameList(this.getValues(data, name))
                break;
            case 'site':
                this.setState({ selectedSites: data, selectedBuildings: [] })
                this.props.getBuildingNameList(this.getValues(data, name))
                break;
            default:
                break;
        }
    }
    /**
     * Handle Back Action Click
     * @memberof ContentWrapper
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Bind HTML to DOM
     * @memberof BuildingsBulkUpload
     */
    render() {
        let { classes, pageAccess, title, projectNames, siteNames, buildingNames, cities } = this.props;
        const { selectedProjects, selectedSites, selectedBuildings, address, selectedCity, zipcode, confirmPopup } = this.state
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                handleBackActionClick={this.handleBackActionClick}
                handleDownloadActionClick={this.handleDownloadActionClick}
                isSubPage={false}
                needPadding={true}
            >
                <div className="p-12">
                    <ValidatorForm
                        name="bulkForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }}
                        style={{ marginBottom: "16px", marginTop: "16px" }}
                    >
                        {
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={4}>
                                    <AutoComplete
                                        name="project"
                                        suggestions={projectNames}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.handleAutoCompolete(data, 'project');
                                        }}
                                        selectedValue={selectedProjects}
                                        disabled={false}
                                        placeholder="Select Project"
                                        needSelectAll
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AutoCompleteMultiMUI
                                        name="site"
                                        suggestions={siteNames}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.handleAutoCompolete(data, 'site');
                                        }}
                                        selectedValue={selectedSites}
                                        disabled={selectedProjects.value ? false : true}
                                        placeholder="Select Site"
                                        needSelectAll
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AutoCompleteMultiMUI
                                        name="building"
                                        suggestions={buildingNames}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.setState({ selectedBuildings: data })
                                        }}
                                        selectedValue={selectedBuildings}
                                        disabled={selectedProjects.value ? false : true}
                                        placeholder="Select Building"
                                        needSelectAll
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="address"
                                        value={address}
                                        label="New Address"
                                        className={classes.textField}
                                        handleChange={(name, value) => {
                                            this.setState({ address: value })
                                        }}
                                        margin="dense"
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <AutoComplete
                                        name="city"
                                        suggestions={cities}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.setState({ selectedCity: data })
                                        }}
                                        selectedValue={selectedCity}
                                        disabled={false}
                                        placeholder="Select New City"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="zipcode"
                                        value={zipcode}
                                        label="New Zipcode"
                                        className={classes.textField}
                                        handleChange={(name, value) => {
                                            this.setState({ zipcode: value })
                                        }}
                                        margin="dense"
                                        disabled={false}
                                    />
                                </Grid>
                            </Grid>
                        }
                        < SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.goBack() }}
                        />
                    </ValidatorForm>
                </div>
                <DeleteDialog
                    open={confirmPopup}
                    content= {<div dangerouslySetInnerHTML={{ __html: `Do you want to update the address for <b>${selectedProjects.label}</b>?`}}></div>}
                    handleDeleteAction={() => {
                        this._saveForm();
                        this.setState({ confirmPopup: false })
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ confirmPopup: false })
                    }}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
BuildingsBulkUpload.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}
/**
 * Default props
 */
BuildingsBulkUpload.defaultProps = {
    isReadOly: false,
    title: 'Bulk Upload'
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ dashboard, projects: { projectNameList }, sites: { siteNameList }, buildings: { buildingNameList }, configuration: { configurations }, pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['bulkUpload'] || [],
        projectNames: projectNameList,
        siteNames: siteNameList,
        buildingNames: buildingNameList,
        cities: configurations[configurationConstants.cities] || []
    }
};
/**
 * Map dispatch to props 
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getProjectNameList: (data) => dispatch(projectsActions.getProjectNameList(data)),
        getSiteNameList: (data) => dispatch(sitesActions.getSiteNameList(data)),
        getBuildingNameList: (data) => dispatch(buildingActions.getBuildingNameList(data)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        bulkUpdate: (data) => dispatch(buildingActions.bulkUpdate(data)),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(BuildingsBulkUpload));

