import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions and Helper Files
import { getUserRole } from '../../helpers';


const EMPTY_CONTENT = "<p><br></p>";
/**
 * TemplatePreview Container
 * @class TemplatePreview
 * @extends {Component}
 */
class TemplatePreview extends Component {
    /**
     * Init Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            orgId: '',
            name: '',
            organizationType: [],
            phone: '',
            address: '',
            city: null,
            state: null,
            postalCode: '',
            website: '',
            isActive: 1,
            inActiveOrgId: null,
            nonEditable: false
        };

        this.userRole = getUserRole();
    }
    /**
     * Component Will Receive Props Event
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { mailTemplateDetails } = nextProps;
        const { name, templateCategory, roleAccess, subject, body, signature, footer } = mailTemplateDetails;
        if ( name !== null && templateCategory !== null && roleAccess !== null && subject !== null && body !== null && signature !== null && footer !== null ) {
            this.setState({
                name,
                templateCategory,
                roleAccess,
                subject,
                bodyContent: body, 
                signatureContent: signature, 
                footerContent: footer
            }); 
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { bodyContent, signatureContent, footerContent } = this.state;
        let shouldDividerShow = false;
        if ( bodyContent && signatureContent && footerContent ) {
            if ( ( ( bodyContent.length !== EMPTY_CONTENT ) || ( signatureContent.length !== EMPTY_CONTENT ) ) && ( footerContent.length !== EMPTY_CONTENT ) ) {
                shouldDividerShow = true;
            }
        }
        return (
            <div className="preview-layout">
                <div className="preview-title">Preview</div>
                <div className="preview-container">
                    <div className="preview-content">
                        <div dangerouslySetInnerHTML={{__html: bodyContent}}></div>
                        <div dangerouslySetInnerHTML={{__html: signatureContent}}></div>
                        { shouldDividerShow && <div className="preview-footer-divider"></div> }
                        <div className="email-preview-footer" dangerouslySetInnerHTML={{__html: footerContent}}></div>
                    </div>
                </div>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
TemplatePreview.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    orgId: PropTypes.string,
    isReadOly: PropTypes.bool.isRequired,
    pageAccess: PropTypes.array.isRequired
};
/**
 * Default Props 
 */
TemplatePreview.defaultProps = {
    pageAccess: [],
    isReadOly: true
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(TemplatePreview));
