import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Materi UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Helpers
import ValidatorForm from "../../helpers/fieldValidations";
import { history } from '../../helpers';

// Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML } from 'draft-convert';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import TemplatePreview from "./TemplatePreview";
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import MailTemplateSend from "./MailTemplateSend";

// Import Actions and Helper Files=
import { getUserRole} from '../../helpers';

//import Action
import { mailTemplateActions } from '../../actions/mailTemplate';

/**
 * MailTemplateEdit Container
 * @class MailTemplateEdit
 * @extends {Component}
 */
class MailTemplateEdit extends Component {
    /**
     * Init Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            subject: "",
            openSend: false,
            bodyContent: EditorState.createEmpty(),
            signatureContent: EditorState.createEmpty(),
            footerContent: EditorState.createEmpty()
        };

        this.userRole = getUserRole();
        this.onBodyContentChange = this.onBodyContentChange.bind(this);
        this.onSignatureContentChange = this.onSignatureContentChange.bind(this);
        this.onFooterContentChange = this.onFooterContentChange.bind(this);
    }
    /** 
     * Component Did Mount
    */
    componentDidMount() {
        const { _id } = this.props;
        if (_id) {
            this.props.getById(_id);
        }

        if (this.props.innerRef) {
            this.props.innerRef(this);
        }
    }
    /**
     * Convert HTML to String
     * @param {*} sampleMarkup 
     * @returns 
     */
    convertFromHTMLString(sampleMarkup) {
        sampleMarkup = sampleMarkup || '';
        const blocksFromHTML = convertFromHTML(sampleMarkup);
        return EditorState.createWithContent(blocksFromHTML);
    }
    /**
     * Component Will Receive Props Event
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { mailTemplates } = nextProps;
        if (mailTemplates.data) {
            this.setState({
                name: mailTemplates.data.name,
                subject: mailTemplates.data.subject,
                bodyContent: this.convertFromHTMLString(mailTemplates.data.body),
                signatureContent: this.convertFromHTMLString(mailTemplates.data.signature),
                footerContent: this.convertFromHTMLString(mailTemplates.data.footer),
            });
        }
    }
    /**
     * Body Content Change
     * @param {*} bodyContent 
     */
    onBodyContentChange(bodyContent) {
        this.setState({ bodyContent });
    }
    /**
     * Signature Content Change
     * @param {*} signatureContent 
     */
    onSignatureContentChange(signatureContent) {
        this.setState({ signatureContent });
    }
    /**
     * Footer Content Change
     * @param {*} footerContent 
     */
    onFooterContentChange(footerContent) {
        this.setState({ footerContent });
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save form
     */
    _saveForm() {
        const { _id } = this.props;
        const { name, bodyContent, footerContent, signatureContent, subject } = this.state;
        let bodyHtml = stateToHTML(bodyContent.getCurrentContent());
        let signatureHtml = stateToHTML(signatureContent.getCurrentContent());
        let footerHtml = stateToHTML(footerContent.getCurrentContent());

        if (_id) {
            const templateInfo = {
                name,
                subject,
                bodyContent: bodyHtml,
                signatureContent: signatureHtml,
                footerContent: footerHtml,
                _id: _id
            };
            this.props.saveMailTemplate(templateInfo, this.props._id);
            history.push('/mailTemplate');
        } else {
            const templateInfo = {
                name,
                subject,
                bodyContent: bodyHtml,
                signatureContent: signatureHtml,
                footerContent: footerHtml,
            };
            this.props.handleFormSaveActionClick(templateInfo);
        }
    }
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.push('/mailTemplate');
    }
    /**
     * Handle Send Action Click
     */
    handleSendActionClick = () => {
        this.setState({ openSend: true });
    }
    /**
     * Handle Send Close
     */
    handleSendClose = () => {
        this.setState({ openSend: false });
    }
    /**
     * Handle Send Confirm
     * @param {*} id 
     */
    handleSendConfirm = (id) => {
        this.setState({ openSend: false });
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { name, subject, bodyContent, footerContent, signatureContent, openSend } = this.state;
        let { classes, isSubPage, mailTemplates, pageAccess } = this.props;
        let mailTemplateDetails = {
            name,
            subject,
            body: stateToHTML(bodyContent.getCurrentContent()),
            signature: stateToHTML(signatureContent.getCurrentContent()),
            footer: stateToHTML(footerContent.getCurrentContent())
        }
        let isPredefined = mailTemplates.data ? mailTemplates.data.isPredefined : false;
        let isActive = mailTemplates.data ? mailTemplates.data.isActive : true;
        pageAccess = pageAccess.indexOf("BACK") > -1 ? pageAccess : [...pageAccess, "BACK"]
        if(!isActive && ( pageAccess.indexOf("SEND") > -1 )) {
            pageAccess = pageAccess.filter(ops => ops !== 'SEND');
        }
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={name ? name : "CREATE NEW MAIL TEMPLATE"}
                isSubPage={isSubPage}
                handleBackActionClick={this.handleBackActionClick}
                handleSendActionClick={this.handleSendActionClick}
            >
                <div className={classes.rootEdit} >
                    <ValidatorForm
                        name="newMailForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="name"
                                            value={name}
                                            label="Template Name"
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            disabled={isPredefined || !isActive}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="subject"
                                            value={subject}
                                            label="Subject"
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            disabled={isPredefined || !isActive}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className="custom-label">Body</div>
                                        <Editor
                                            editorState={bodyContent}
                                            editorClassName="mailTemplateEditor"
                                            onEditorStateChange={this.onBodyContentChange}
                                            toolbarHidden
                                            placeholder={'Type Something...'}
                                            readOnly={isPredefined || !isActive}
                                        />
                                        <div className="cutom-editor-gap"></div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className="custom-label">Signature</div>
                                        <Editor
                                            editorState={signatureContent}
                                            editorClassName="mailTemplateEditor"
                                            onEditorStateChange={this.onSignatureContentChange}
                                            toolbarHidden
                                            placeholder={'Type Something...'}
                                            readOnly={isPredefined || !isActive}
                                        />
                                        <div className="cutom-editor-gap"></div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <div className="custom-label">Footer</div>
                                        <Editor
                                            editorState={footerContent}
                                            editorClassName="mailTemplateEditor"
                                            onEditorStateChange={this.onFooterContentChange}
                                            toolbarHidden
                                            placeholder={'Type Something...'}
                                            readOnly={isPredefined || !isActive}
                                        />
                                        <div className="cutom-editor-gap"></div>
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        {(isActive && !isPredefined) && <SaveCancel
                                            handleSaveButtonClick={() => { this.refs.form.submit() }}
                                            handleCancelButtonClick={() => { history.goBack() }}
                                        />}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TemplatePreview
                                    title="Preview"
                                    mailTemplateDetails={mailTemplateDetails}
                                />
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
                
                {
                    openSend && <MailTemplateSend
                        itemData={mailTemplateDetails}
                        handleOkayAction={this.handleSendConfirm}
                        handleCloseAction={this.handleSendClose}
                    />
                }
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
MailTemplateEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    orgId: PropTypes.string,
    isReadOly: PropTypes.bool.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool
};
/**
 * Default Props 
 */
MailTemplateEdit.defaultProps = {
    pageAccess: [],
    isReadOly: true,
    isSubPage: false
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const mailTemplates = state.mailTemplates.getOne;
    return {
        mailTemplates,
        pageAccess: state.pageAccess['mailTemplateEdit'] || []    
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // Map actions here
        getById: (_id) => dispatch(mailTemplateActions.getById(_id)),
        saveMailTemplate: (template, id) => dispatch(mailTemplateActions.updateTemplate(template, id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(MailTemplateEdit));
