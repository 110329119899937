//import helper
import { authHeader, globalVariable, handleResponse } from "../helpers";

export const buildingService = {
    getAll,
    addNewBuilding,
    getById,
    updateBuilding,
    delete: _delete,
    updateClaimStatus,
    getBuildingNameList,
    bulkUpdate
}

/**
 *Get data for building list
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {*} siteId - site_id
 * @returns
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, siteId, query) {
    let data = {
        fields: sFields,
        values: sValues,
        siteId: siteId,
        query: query
    };

    const requestOptions = {
        method: 'post',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/building/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 *Adding new building
 *
 * @param {*} building - new building data 
 * @returns
 */
function addNewBuilding(building) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(building)
    };

    return fetch(`${globalVariable.apiURL}/building`, requestOptions).then(handleResponse);
}

/**
 *Get single building information
 *
 * @param {*} id - building_id
 * @returns
 */
function getById(id, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.apiURL}/building/${id}/${type}`, requestOptions).then(handleResponse);
}

/**
 *Update one particular building
 *
 * @param {*} formData - update data
 * @param {*} id - building_id
 * @returns
 */
function updateBuilding(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };
    return fetch(`${globalVariable.apiURL}/building/${id}`, requestOptions).then(handleResponse);
}

/**
 *Delete a building
 *
 * @param {*} id - building_id
 * @returns
 */
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/building/${id}`, requestOptions).then(handleResponse);
}

/** An api call method to update claim status of a site
 *
 *
 * @param {*} building_id
 * @param {*} site_id
 * @returns
 */
function updateClaimStatus(building_id, site_id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ building_id: building_id })
    };

    return fetch(`${globalVariable.apiURL}/building/claim-status/${site_id}/${building_id}`, requestOptions).then(handleResponse);
}

/**
 * A method to get building name list
 */
function getBuildingNameList(data = null) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/buildings/name-list`, requestOptions).then(handleResponse);
}

/**
 * A method to bulk update
 */
function bulkUpdate(data = null) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/buildings/bulk-update`, requestOptions).then(handleResponse);
}