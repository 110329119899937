import { authHeader, globalVariable, handleResponse } from '../helpers';

export const userService = {
    login,
    logout,
    resetPassword,
    changePassword,
    getAll,
    getById,
    getByOrgId,
    getByOrgIdHos,
    update,
    addNewUser,
    delete: _delete,
    uploadPicture,
    getPicture,
    acceptTOS,
    hasAssociatedProjects,
    activeStatus,
    retreiveUser
};

/**
 * Check the logged in user as valid
 * 
 * @param {*} username - username
 * @param {*} password - password
 */
function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`${globalVariable.apiURL}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // login successful if there's a jwt token in the response
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user.data));
            return user.data;
        });
}

/**
 * Logout
 */
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}
/**
 * A method to accept tos on success change tos to true
 *
 * @returns
 */
function acceptTOS() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/accept-tos`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // tos accepted successfully
            // update true status in local storage's user's data
            const localUser = JSON.parse(localStorage.getItem('user'));
            localUser.tos = true; // update tos status
            localStorage.setItem('user', JSON.stringify(localUser));
            return localUser;
        });
}

/**
 * A service method to create hash for the particular email user
 *
 * @param {*} email E mail string to reset password
 * @returns
 */
function resetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    return fetch(`${globalVariable.apiURL}/reset-password`, requestOptions).then(handleResponse);
}

/**
 * A service method to change the password user
 *
 * @param {*} data contains newPassword, confirmPassword and hash
 * @returns
 */
function changePassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/change-password`, requestOptions).then(handleResponse);
}

/**
 * Get data for the listing page of users
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, page='users', isAutoComplete = false, orgId = null) {

    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete,
        orgId: orgId,
        page:page
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/users/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 * Get details page of a user
 * 
 * @param {*} id - user_id
 */
function getById(id,page) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/users/${id}/${page}`, requestOptions).then(handleResponse);
}

/**
 * A meethod to get users in organizations id except role onsite manager
 *
 * @param {*} orgId contains organizations id
 * @returns
 */
function getByOrgId(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/users/search/${orgId}`, requestOptions).then(handleResponse);
}

/**
 * A meethod to get users in organizations id except role onsite manager
 *
 * @param {*} orgId contains organizations id
 * @returns
 */
function getByOrgIdHos(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/users/search/ho/${orgId}`, requestOptions).then(handleResponse);
}

/**
 * update user by using user_id
 * 
 * @param {*} userData - update data
 * @param {*} userId - user_id
 */
function update(userData, userId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    };

    return fetch(`${globalVariable.apiURL}/users/${userId}`, requestOptions).then(handleResponse);
}

/**
 * Create a new user
 * 
 * @param {*} user - user details
 */
function addNewUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(`${globalVariable.apiURL}/users`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id - user_id
 */
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/users/${id}`, requestOptions).then(handleResponse);
}

/**
 * Retreieve user - Archive user
 * 
 * @param {*} id - user_id
 */
 function retreiveUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/users/retrieve/${id}`, requestOptions).then(handleResponse);
}

/**
 * Upload image
 * 
 * @param {*} id - user_id
 * @param {*} formData 
 */
function uploadPicture(id, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData
    };

    return fetch(`${globalVariable.apiURL}/users/picture/${id}`, requestOptions)
        .then(response => response.blob());
}

/**
 * Get picture
 * @param {*} id - user_id
 */
function getPicture(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${globalVariable.apiURL}/users/picture/${id}`, requestOptions)
        .then(response => response.blob());
}

/**
 * If users has assoc projects
 *
 * @param {*} userId
 */
function hasAssociatedProjects(userId) {
    if (userId) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        return fetch(`${globalVariable.apiURL}/users/is-associated/${userId}`, requestOptions).then(handleResponse);
    }
}

/**
 * User active status
 * @param {*} id - user_id
 * @param {*} isActive 
 */
function activeStatus(id, isActive) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ isActive })
    };

    return fetch(`${globalVariable.apiURL}/users/active-status/${id}`, requestOptions).then(handleResponse);
}