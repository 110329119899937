import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Component
import AutoCompleteMulti from '../../components/AutoCompleteMulti/AutoCompleteMulti';
import PageTitle from '../../components/PageTitle/PageTitle';
import UAView from "./UAView";

// Helpers
import { getUserRole, getUserId, getUserOrgId } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";
import { history } from '../../helpers';

// Actions
import { dashboardActions } from '../../actions/dashboard';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * UABulkUpload Component
 * @class UABulkUpload
 * @extends {Component}
 */
class UABulkUpload extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            projects: null,
            project: null,
            site: {},
            disableUploadBtn: false
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.pageAccess = ['UPDATE', 'SAVE', 'DELETE'];
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.props.getProjectSummary(null, '');
    }
    /**
     * A method for autocomplete on change contains selected option value
     * @param {*} selectedOption 
     */
    handleAutoCompleteProject = (selectedOption) => {
        this.setState({
            downloadFile: false,
            project: selectedOption,
            site: {}
        });
    }
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        const { project, site } = this.state;
        if (project.value) {
            this.props.handleDownload({ project_id: project.value, site_id: site.value });
        }
    };
    /**
     * Get Project Ids
     * @returns 
     */
    getPjtIds() {
        const { projects } = this.props.projectSummaryData;
        const pjt = this.state.project || [];
        if (pjt[0]) {
            if (this.state.project[0].value === 0) {
                return projects.map((pjt) => pjt.value);
            } else {
                return this.state.project.map((pjt) => pjt.value);
            }
        }
    }
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    //for disabling upload button if table value is there
    UploadBtn = (value) => {
        this.setState({
            disableUploadBtn: value
        })
    }
    /**
     * Bind Html to DOM 
     */
    render() {
        let { classes, projectSummaryData, match, pageAccess } = this.props;
        let { projects } = projectSummaryData;
        const { project, site } = this.state;
        return (
            // <div className={classes.root} style={{ padding: '0px', width: '100%' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <PageTitle title="UA Bulk Upload" actions={['BACK']} handleBackActionClick={this.handleBackActionClick} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Card>
                            <CardContent>
                                <ValidatorForm
                                    name="uaBulkForm"
                                    ref="form"
                                    autoComplete="off"
                                    onSubmit={this.handleSubmit}
                                    onError={errors => { }}
                                    style={{ marginBottom: "16px" }}
                                >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={10} className="zIndex3">
                                            <AutoCompleteMulti
                                                placeholder="Select Project(s)"
                                                selectedValue={project}
                                                handleAutoCompolete={(selectedOption) => {
                                                    this.setState({
                                                        project: selectedOption
                                                    });
                                                }}
                                                suggestions={[{ value: 0, label: "All Projects" }, ...projects]}
                                            />
                                        </Grid>
                                        <Grid item xs={3} sm={2} className="textLeft">
                                            <Button
                                                disabled={project === null || project.length === 0 || this.state.disableUploadBtn === true}
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                style={{ height: "36px", minHeight: "36px" }}
                                                className={classes.button}
                                                onClick={() => {
                                                    this.uaForm.refs.uaForm.submit();
                                                }}
                                            >
                                                Upload
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ValidatorForm>
                                <UAView
                                    match={match}
                                    innerRef={(node) => { this.uaForm = node; }}
                                    isBulkUpload={true}
                                    projectId={this.getPjtIds()}
                                    pageAccess={pageAccess}
                                    siteId={site.value}
                                    isSubPage={false}
                                    disableUploadBtn={(value) => { this.UploadBtn(value) }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            // </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
UABulkUpload.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}
/** Defaut Props */
UABulkUpload.defaultProps = {
    isReadOly: false
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ dashboard, pageAccess }, ownProps) => {
    return {
        projectSummaryData: dashboard.projectSummary,
        pageAccess: pageAccess['uaBulkUpload'] || []
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getProjectSummary: (limit, search) => dispatch(dashboardActions.getProjectSummary(limit, search))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(UABulkUpload));

