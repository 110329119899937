import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import ReportsRouter from '../../Reports/Reports.Router';
/**
 * Reports Container
 * @class Reports
 * @extends {Component}
 */
class Reports extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, projectId } = this.props;
        return (
            <Switch >
                <Route
                    path={match.url} render={props => (
                        <ReportsRouter {...props}
                            isSubPage={true}
                            match={match}
                            projectId={projectId}
                            page={'projects'} />
                    )} />

            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default Reports;