import React, { Component } from 'react';
import CodeMirror from 'react-codemirror';

//Components
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ToolTip from '../../components/ToolTip/ToolTip';
import { HelpIcon } from '../../components/SvgIcons/SvgIcons';

// import cmaddon15 from "codemirror/addon/selection/active-line"

/**
 * XMLEditorError component
 * @class XMLEditorError
 * @extends {Component}
 */
class XMLEditorError extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = { }
       
    }

    renderErrors = (error, index,fullScreenStylePopup) => {
        let message = error.error || parseInt(index+1) +'.' +error.message;
        let helpIconStyle = (fullScreenStylePopup) ? '150px' : '48px' ;
        return (
            <Grid container 
            className="error-list"
            >
               <Grid   item xs={10} sm={10} 
                    key={index}
                    onClick={() => this.props.scrollToErrorLine(error, index)}>
                    {message}
                </Grid>
                <Grid item xs={2} sm={2}  >
                    <Grid style={{ marginLeft:helpIconStyle,marginRight:'0px' }}>
                    <ToolTip title="Help" placement="bottom"  >
                        <Button    className="xml-help-button"   style={{ width: '27px', height: '27px', boxShadow: 'none', background: ' #e0e0e0', borderRadius: '50%', minHeight: '27px', minWidth: '27px', padding: '2px'}} variant="circular" size="small" color="default" aria-label="Merge"  onClick={() => { this.props.helpIconMessagePopup(error, index) }}  >
                            <HelpIcon />
                        </Button>
                    </ToolTip>
                    </Grid>
                </Grid>
            </Grid>
            
        )
    }
  
    render() {
        const {  errors,fullScreenStylePopup } = this.props;
        return (
            <React.Fragment>
                {/* <div className="xml-preview"  > */}
               
                            {
                                // errors.length > 0 &&
                                <div className="error-container xml-editor-error" xs={12} sm={12} >
                                    <div className="error-content">
                                        {
                                            errors.map((error, index) => {
                                                return this.renderErrors(error, index,fullScreenStylePopup)
                                            })
                                        }
                                    </div>
                                </div>
                          }
                       
                {/* </div> */}
            </React.Fragment>
        )
    }
}
/** Default props */
XMLEditorError.defaultProps = {
    errors: [],
}
/** Export Component */
export default XMLEditorError;