import { notificationActions } from '../actions';
import { notificationConstants } from '../constants/notification';

const initialState = {
    data: [],
    notificationList: [],
    notificationAlertList: [],
    isLoading: false,
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: [],
            searchCols: []
        }
    },
    error: ''
}

export function notification(state = initialState, action) {
    switch (action.type) {
        case notificationConstants.GETBYUSERID_NOTIFICATION_REQUEST:
        case notificationConstants.GETBYUSERID_NOTIFICATIONALERT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case notificationConstants.GETBYUSERID_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationList: action.notification.data,
                isLoading: false
            };
        case notificationConstants.GETBYUSERID_NOTIFICATION_FAILURE:
            return {
                ...state,
                error: action.error,
                notificationAlertList:[],
                isLoading: false,
            };
        case notificationConstants.UPDATEDATA_REQUEST:
        case notificationConstants.UPDATEALERTDATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case notificationConstants.UPDATEDATA_SUCCESS:
            return {
                ...state,
                notificationList:action.notification.data,
                isLoading: false
            };
        case notificationConstants.UPDATEALERTDATA_SUCCESS:
            return {
                ...state,
                notificationAlertList:[],
                isLoading: false
            };
        case notificationConstants.UPDATEDATA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case notificationConstants.UPDATEALERTDATA_FAILURE:
            return{
                ...state,
                notificationAlertList:[],
                error:action.error,
                isLoading:false
            }
        case notificationConstants.DELETEDATA_REQUEST:
        case notificationConstants.DELETEALERTDATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case notificationConstants.DELETEDATA_SUCCESS:
        case notificationConstants.DELETEALERTDATA_SUCCESS:
            return {
                ...state,
                notificationList: action.notification.data,
                isLoading: false
            };
        case notificationConstants.DELETEDATA_FAILURE:
        case notificationConstants.DELETEALERTDATA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case notificationConstants.UPDATEDATA_SOCKET_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case notificationConstants.UPDATEDATA_SOCKET_SUCCESS:
            return {
                ...state,
                notificationList: state.notificationList.length > 0 ? !state.notificationList.some(elem => elem._id === action.notification[0]._id) ? [...action.notification, ...state.notificationList] : state.notificationList : action.notification,
            };
        case notificationConstants.UPDATEDATA_SOCKET_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case notificationConstants.GETBYUSERID_NOTIFICATIONALERT_SUCCESS:
            return {
                ...state,
                notificationAlertList: action.notification.data,
                isLoading: false
            };
        case notificationConstants.ALERT_UPDATEDATA_SOCKET_SUCCESS:
            return {
                ...state,
                notificationAlertList: action.notification,
                isLoading: false
            };
        default:
            return state
    }
}