import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Helpers
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextBox from '../../../components/TextBox/TextBox';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { alertActions } from '../../../actions';

// neccessary db columns for a single cities row
const citiesData = {
    name: ''
};
/**
 * EditableText component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    name: EditableText
}
/**
 * Cities Container
 * @class Cities
 * @extends {Component}
 */
class Cities extends Component {
    /**
     * Construnctor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            city: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.cities);
    }
    /**
     * Component will recive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { cities } = nextProps;
        this.setState({
            saved: true,
            city: cities
        })
    }
    /**
     * Cell componet on change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { city } = this.state;
        city[rowIndex][fieldName] = value;
        this.setState({ city });
    }
    /**
     * handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate city names
        let city = this.state.city;
        let duplicateName=''
        let cityNames = city.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicateCity = cityNames.some(function(item, idx){ 
                    if(cityNames.indexOf(item) !== idx){
                        duplicateName=city[cityNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicateCity){
            this.props.erroralert(`${duplicateName.toUpperCase()} city already exists`);
        }else{
        this.props.updatedata(configurationConstants.cities, this.state.city);
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { city } = this.state;
        const { height, isLoading } = this.props;
        // [1]
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="cities"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'CITIES'}
                        headerCols={['NAME']}
                        requiredFields={['NAME']}
                        fieldCols={['name']}
                        rowDatas={city}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        actions={['DELETE']}
                        style={style}
                        staticHeader={true}
                        height= {height}
                        onAdd={() => {
                            if (city.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    city: [
                                        {
                                            ...citiesData,
                                            key: Math.max(...city.map(elt => elt.key)) + 1,
                                            type: configurationConstants.cities
                                        },
                                        ...city
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            this.props.deleteConfig("City", item._id);
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.cities) }}
                />
            </ValidatorForm>
        );
    }
}
/**
 * Props types
 */
Cities.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        cities: configuration.configurations[configurationConstants.cities] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByName: (cities) => dispatch(confActions.getByName(cities)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert:(msg)=>dispatch(alertActions.error(msg))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Cities));
