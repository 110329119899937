export const globalSearchConstants = {
    GETALL_REQUEST: "GLOBALSEARCH_GETALL_REQUEST",
    GETALL_SUCCESS: "GLOBALSEARCH_GETALL_SUCCESS",
    GETALL_FAILURE: "GLOBALSEARCH_GETALL_FAILURE"
}

// dropdown
export const searchConstants = [
    {
        key: 1,
        name: 'Project / Site Name'
    },
    {
        key: 3,
        name: 'Contract ID /OID'
    },
    {
        key: 4,
        name: 'Users'
    },
    {
        key: 5,
        name: 'Building Name'
    },
    {
        key: 6,
        name: 'City/County/Street Address'
    },
    {
        key: 7,
        name: 'HOME Program'
    },
    {
        key: 8,
        name: 'NHTF Program'
    },
    {
        key: 2,
        name: 'Monitoring Funder'
    },
]
// drop down for HO
export const searchConstantsHO = [
    {
        key: 11,
        name: 'Project / Contract Number'
    },
    {
        key: 15,
        name: 'Contract Key'
    },
    {
        key: 10,
        name: 'Users'
    },
    {
        key: 12,
        name: 'County'
    }
]
// home program categories
export const searchHomeProgram = [
    {
        label: 'HOME Program - City',
        value: 'HOME Program - City'
    },
    {
        label: 'HOME Program - County',
        value: 'HOME Program - County'
    },
    {
        label: 'HOME Program - State',
        value: 'HOME Program - State'
    }
]

// nhtf program categories
export const searchNHTFProgram = [
    {
        label: 'Yes',
        value: true
    },
    {
        label: 'No',
        value: false
    },
    // {
    //     label: 'NHTF Program - State',
    //     value: 'NHTF Program - State'
    // }
]

// funder data
export const searchMoniteringFunder = [
    {
        label: 'A Regional Coalition for Housing',
        value: 'A Regional Coalition for Housing'
    },
    {
        label: 'City of Bellingham',
        value: 'City of Bellingham'
    },
    {
        label: 'City of Spokane, Community Development Dept.',
        value: 'City of Spokane, Community Development Dept.'
    },
    {
        label: 'Clark County Dept of Community Services',
        value: 'Clark County Dept of Community Services'
    },
    {
        label: 'Department of Commerce, Housing Trust Fund',
        value: 'Department of Commerce, Housing Trust Fund'
    },
    {
        label: 'King County Housing Finance Program',
        value: 'King County Housing Finance Program'
    },
    {
        label: 'Pierce County Housing & CD Division',
        value: 'Pierce County Housing & CD Division'
    },
    {
        label: 'Seattle Office of Housing',
        value: 'Seattle Office of Housing'
    },
    {
        label: 'Snohomish County Office of Housing',
        value: 'Snohomish County Office of Housing'
    },
    {
        label: 'Spokane County, Housing and Community Development',
        value: 'Spokane County, Housing and Community Development'
    },
    {
        label: 'Tacoma Community & Economic Development Department',
        value: 'Tacoma Community & Economic Development Department'
    },
    {
        label: 'Washington State Housing Finance Commission',
        value: 'Washington State Housing Finance Commission'
    },
]
export const searchMoniteringFunderHO = [
    {
        label: 'Department of Commerce, Housing Trust Fund',
        value: 'Department of Commerce, Housing Trust Fund'
    }
]
// monitering status
export const searchMoniteringStatus = [
    {
        label: 'Claim',
        value: 'Claim'
    },
    {
        label: 'Pending',
        value: 'Pending'
    },
    {
        label: 'Monitoring',
        value: 'Monitoring'
    },
    {
        label: 'Expired',
        value: 'Expired'
    },
]
// monitering status suggestion for HO
export const searchMoniteringStatusHO = [
    {
        label: 'Claim',
        value: 'Claim'
    },
    // {
    //     label: 'Pending',
    //     value: 'Pending'
    // },
    {
        label: 'Monitoring',
        value: 'Monitoring'
    },
    {
        label: 'Expired',
        value: 'Expired'
    },
]