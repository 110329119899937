export const exploreConstants = {

    // explore data listing
    GETALL_REQUEST: 'EXPLOREDATA_GETALL_REQUEST',
    GETALL_SUCCESS: 'EXPLOREDATA_GETALL_SUCCESS',
    GETALL_FAILURE: 'EXPLOREDATA_GETALL_FAILURE',

    // explore data update
    UPDATEDATA_REQUEST: 'EXPLOREDATA_UPDATE_REQUEST',
    UPDATEDATA_SUCCESS: 'EXPLOREDATA_UPDATE_SUCCESS',
    UPDATEDATA_FAILURE: 'EXPLOREDATA_UPDATE_FAILURE',

    // funder reports explore listing
    GETALL_REPORTS_REQUEST: 'EXPLOREDATA_GETALL_REPORT_REQUEST',
    GETALL_REPORTS_SUCCESS: 'EXPLOREDATA_GETALL_REPORT_SUCCESS',
    GETALL_REPORTS_FAILURE: 'EXPLOREDATA_GETALL_REPORT_FAILURE',

    // funder reports dashboard listing
    GETALL_FUNDERDATA_REPORTS_REQUEST: 'FUNDERDATA_GETALL_REPORT_REQUEST',
    GETALL_FUNDERDATA_REPORTS_SUCCESS: 'FUNDERDATA_GETALL_REPORT_SUCCESS',
    GETALL_FUNDERDATA_REPORTS_FAILURE: 'FUNDERDATA_GETALL_REPORT_FAILURE',

    // explore delete
    DELETE_REQUEST: 'EXPLORE_DELETE_REQUEST',
    DELETE_SUCCESS: 'EXPLORE_DELETE_SUCCESS',
    DELETE_FAILURE: 'EXPXLORE_DELETE_FAILURE',

    // explore details 
    GETONE_REQUEST: 'EXPLORE_GETONE_REQUEST',
    GETONE_SUCCESS: 'EXPLORE_GETONE_SUCCESS',
    GETONE_FAILURE: 'EXPLORE_GETONE_FAILURE',

    // download explore reports
    DOWNLOAD_REQUEST: 'EXPLORE_DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'EXPLORE_DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'EXPLORE_DOWNLOAD_FAILURE',

    // preview explore reports
    PREVIEW_REQUEST: 'EXPLORE_PREVIEW_REQUEST',
    PREVIEW_SUCCESS: 'EXPLORE_PREVIEW_SUCCESS',
    PREVIEW_FAILURE: 'EXPLORE_PREVIEW_FAILURE',

    // Save Template explore reports
    SAVETEMPLATE_REQUEST: 'EXPLORE_SAVETEMPLATE_REQUEST',
    SAVETEMPLATE_SUCCESS: 'EXPLORE_SAVETEMPLATE_SUCCESS',
    SAVETEMPLATE_FAILURE: 'EXPLORE_SAVETEMPLATE_FAILURE'
};