import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemText } from '@material-ui/core';
import CustomToolTip from '../../components/ToolTip/CustomToolTip';

// Helpers
import { hasWarnings } from '../../helpers/TableValidations/warnings';

// SVG Icons
import { SvgLoadIcon } from '../SvgIcons/SvgIcons';

const styles = theme => ({
    input: {
        display: 'flex',
        height: 16,
    },
    valueContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        letterSpacing: 0,
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        display: 'none'
    }
});
/** Modules to avoid sorting suggessions */
const avoidSorting = ['autocompleteuser', 'sitepropertymanager', 'onsiteManagerUser', 'dashboardReportYear', "primaryContact", "isHTFLoanStatisfied"]
/**
 * No Option Message
 * @param {*} props 
 * @returns 
 */
function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}
/**
 * Input Component
 * @param {*} param0 
 * @returns 
 */
function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}
/**
 * Control for Input
 * @param {*} props 
 * @returns 
 */
function Control(props) {
    let className = 'autocomplete-fixed'
    const customClass = props.selectProps.validators && props.selectProps.validators.length > 0 ? `required ${className}` : `${className}`;
    let value = props.selectProps.value && props.selectProps.value.label ? props.selectProps.value.label : '';

    return (
        <TextValidator
            variant="standard"
            fullWidth
            name={props.selectProps.name}
            label={props.selectProps.placeholder}
            validators={props.selectProps.validators}
            margin={props.selectProps.margin}
            value={value}
            errorMessages={props.selectProps.errorMessages}
            className={customClass}
            disabled={props.isDisabled}
            InputLabelProps={{
                shrink: (value !== null && value !== '') || props.isFocused,
                filled: (value !== null && value !== '') || props.isFocused
            }}
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    ref: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
        />
    );
}
/**
 * Indicator Seperator
 * @param {*} param0 
 * @returns 
 */
const IndicatorSeparator = ({ innerProps }) => {
    return (
        null
    );
};
/**
 * Dropdown Indicator
 * @param {*} param0 
 * @returns 
 */
const DropdownIndicator = ({ innerProps, ...props }) => {
    if (props.isDisabled === true) {
        return null
    } else {
        return (
            <i className="fa fa-caret-down autocomple_dropdwn" ></i>
        );
    }

};
/**
 * Suggessions for Option
 * @param {*} props 
 * @returns 
 */
function Option(props) {
    return (
        <React.Fragment>
            <MenuItem
                buttonRef={props.innerRef}
                selected={props.isFocused}
                component="div"
                title={props.data.label ? props.data.label : props.data.value}
                style={{
                    fontWeight: props.isSelected ? 500 : 400
                }}
                {...props.innerProps}
            >
                <ListItemText className="autocomplete-list" primary={props.data.label} />
            </MenuItem>
            {
                props.options.length < props.selectProps.dataCount && props.options.findIndex(elem => elem.value === props.value) === (props.options.length - 1) &&
                <div className='autoCompleteLoader'>
                    <SvgLoadIcon />
                </div>
            }
        </React.Fragment>
    );
}
/**
 * Placeholder
 * @param {*} props 
 * @returns 
 */
function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}
/**
 * Single value
 * @param {*} props 
 * @returns 
 */
function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}
/**
 * Value Container
 * @param {*} props 
 * @returns 
 */
function ValueContainer(props) {
    const diabledCSS = props.isDisabled && props.isDisabled === true ? props.selectProps.classes.valueContainer + ' disabled' : props.selectProps.classes.valueContainer;
    return <div className={diabledCSS}>{props.children}</div>;
}

const components = {
    Option,
    Control,
    NoOptionsMessage,
    Placeholder,
    SingleValue,
    ValueContainer,
    IndicatorSeparator,
    DropdownIndicator,
    ClearIndicator: () => { return null }
};

const MAX_CONTENT = 30;

/**
 * Autocomplete Component
 * @class AutoComplete
 * @extends {React.Component}
 */
class AutoCompleteToolTip extends React.Component {
    state = {
        autocomplete: null,
        focus: false,
        search: '',
        suggestions: [],
        suggestionCount: 0,
        setOpen: false
    };
    /**
     * Component Did Mount
     */
    componentDidMount() {
        let { autocomplete, suggestions, suggestionCount } = this.state;
        const { selectedValue, needSelectAll } = this.props;
        if (selectedValue && autocomplete) {
            if (selectedValue.value !== autocomplete.value) {
                if (needSelectAll) {
                    autocomplete = [{ value: 0, label: "Select All" }, ...selectedValue]
                } else {
                    autocomplete = selectedValue
                }
            }
        }
        suggestionCount = this.props.suggestions.length
        suggestions = this.props.suggestions.slice(0, MAX_CONTENT);
        this.setState({
            autocomplete,
            suggestions,
            suggestionCount
        })
    }
    /**
     * Component Will recieve props
     * @param {*} newProps 
     * 
     * [1]  - Check if selectedValue && autocomplete has value.
     * [2]  - Assign to state autocomplete if autocomplete & selectedValue are different.
     * [3]  - Assign if the selected value is 0.
     * [4]  - Set Suggessions bases on MAX_CONTENT, if state suggession and new suggession arrays are same.
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        let { autocomplete, suggestions, suggestionCount } = this.state;
        const { selectedValue } = newProps;
        // [1]
        if (selectedValue && autocomplete) {
            // [2]
            if (selectedValue.value !== autocomplete.value) {
                autocomplete = selectedValue;
            }
        } else if (selectedValue && (selectedValue.value || selectedValue.value === 0) && selectedValue.label) {
            // [3]
            autocomplete = selectedValue;
        }
        // [4]
        if (suggestions && (suggestions.filter(this.arrayComparer(newProps.suggestions.slice(0, suggestions.length))).length !== 0 || suggestions.length === 0)) {
            suggestionCount = newProps.suggestions.length
            suggestions = newProps.suggestions.slice(0, MAX_CONTENT);
        }
        this.setState({
            autocomplete,
            suggestions,
            suggestionCount
        })
    }
    /**
     * Array comparer - To check if array datas are same
     * @param {*} otherArray 
     * @returns 
     */
    arrayComparer = (otherArray) => {
        return function (current) {
            return otherArray.filter(function (other) {
                return other.label === current.label && other.display === current.display
            }).length === 0;
        }
    }
    /**
     * Handle change Event for Textfields
     * @param {*} value 
     * 
     * [1]  - Update the parent component
     */
    handleChange = value => {
        this.setState({
            autocomplete: value,
        });
        // [1]
        this.props.handleAutoCompolete(value || [])
    };
    /**
     * A method to get the name from suggestion if only id available
     *
     * @param {*} suggestions contains suggestions / options
     * @param {*} id contains id to filter out the name / label
     * @returns name / label for the id passed, else emtpy string
     * @memberof AutoComplete
     */
    getValueIfNoName(suggestions, id) {
        const val = suggestions.filter((opt) => {
            const crOptId = opt.id || opt.value;
            return crOptId === id;
        });
        return val.length > 0 ? val[0].label : '';
    }
    /**
     * Handle Lazy Loading
     * 
     * [1]  - Check if suggestionCount & suggestions.length different
     * [2]  - Check if any search keyword has value
     * [3]  - Update the suggessions by MAX_CONTENT
     */
    handleLazyLoading = () => {
        let { suggestions, search, suggestionCount } = this.state;
        // [1]
        if (suggestionCount !== suggestions.length) {
            // [2]
            if (search) {
                let regexText = new RegExp(search, 'i');
                let filterSuggestions = this.props.suggestions.filter(elem => regexText.test(elem.label));
                suggestionCount = filterSuggestions.length;
                suggestions = filterSuggestions.slice(0, suggestions.length + MAX_CONTENT);
            } else {
                // [3]
                suggestions = this.props.suggestions.slice(0, suggestions.length + MAX_CONTENT);
                suggestionCount = this.props.suggestions.length;
            }
            this.setState({ suggestions, suggestionCount });
        }
    }
    /**
     * Handle Search
     * @param {*} search 
     */
    handleSearch = (search) => {
        let { suggestions, suggestionCount } = this.state;
        let regexText = new RegExp(search, 'i');
        suggestions = this.props.suggestions.filter(elem => regexText.test(elem.label));
        suggestionCount = suggestions.length;
        suggestions = suggestions.slice(0, MAX_CONTENT);
        this.setState({ suggestions, suggestionCount });
    }

    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }

    handleonFocus = (event) => {
        this.props.onFocus(event.target)
    };

    /**
     * Bind HTML to reactDOM
     * @returns
     * 
     * [1]  - Sorting
     * [2]  - set selected value and label for selectedValue.
     * [3]  - Get Warning Class
     * [4]  - Inorder to avoid null in val
     */
    render() {
        const { classes, selectedValue, disabled, name, fielderrors, warningfieldname, className } = this.props;
        const { focus, search, suggestions, suggestionCount } = this.state;
        let val = this.state.autocomplete;
        //[1]
        if (!avoidSorting.some(elem => elem === name)) {
            suggestions.sort((a, b) => {
                const ai = parseInt(a.label, 10), bi = parseInt(b.label, 10);
                return (b == null) - (a == null)
                    || (ai - bi)
                    || (a.label > b.label) - (b.label > a.label);
            }).map(x => x);
        }
        // [2]
        if (selectedValue && selectedValue.id !== undefined) {
            const label = selectedValue.name || this.getValueIfNoName(this.props.suggestions, selectedValue.id);
            val = {
                value: selectedValue.id && selectedValue.id._id ? selectedValue.id._id : selectedValue.id,
                label: label
            }
        }
        // [3]
        const warningClass = hasWarnings(fielderrors, warningfieldname);
        // [4]
        if (val !== null) {
            val.value = val.value === null ? '' : val.value
        } else val = ''

        return (
            <CustomToolTip
                {...this.props}
                focus={focus}
            >

                <div className={`${warningClass} autocompleteFix ${className}`}>
                    <Select
                        classes={classes}
                        className={className}
                        options={focus ? suggestions : []}
                        components={components}
                        value={val}
                        onChange={this.handleChange}
                        placeholder={this.props.placeholder}
                        validators={this.props.validators}
                        errorMessages={this.props.errorMessages}
                        name={name}
                        isDisabled={disabled}
                        onMenuOpen={() => {
                            this.setState({
                                focus: true
                            });
                        }}
                        // onMenuClose={() => {
                        //     this.setState({
                        //         focus: false
                        //     });
                        // }}
                        isClearable={true}
                        dataCount={suggestionCount}
                        captureMenuScroll={this.props.suggestions.length > 30}
                        inputValue={search}
                        onMenuScrollToBottom={() => {
                            this.handleLazyLoading(search)
                        }}
                        onFocus={() => {
                            this.setState({
                                focus: true
                            });
                        }}
                        onBlur={() => {
                            this.setState({
                                focus: false
                            });
                        }}
                        onInputChange={(search) => {
                            this.setState({ search })
                            if (this.state.search !== search) {
                                setTimeout(() => {
                                    this.handleSearch(search);
                                }, 1000)
                            }
                        }}
                        onMenuClose={() => {
                            if (this.props.suggestions.length > 30) {
                                this.setState({ suggestions: this.props.suggestions.slice(0, MAX_CONTENT) })
                            }
                        }}
                    />
                </div>
            </CustomToolTip>
        );
    }
}
//default props
AutoCompleteToolTip.defaultProps = {
    suggestions: [],
    selectedValue: "",
    disabled: false,
    name: 'autocomplete',
    placeholder: "",
    validators: [],
    errorMessages: [],
    /**Handle autocomplete event */
    handleAutoCompolete: () => { },
    fielderrors: [],
    warningfieldname: '',
    margin: 'dense',
    className: '',
    dataCount: 0,
    handleSearch: () => { },
    handleLazyLoading: () => { },
    onFocus: () => { },
    toolTipTitle: "",
    tooltipOnHover: true,
    onBlur: () => { }

}
//prop types
AutoCompleteToolTip.propTypes = {
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.any,
    disabled: PropTypes.bool,
    handleAutoCompolete: PropTypes.func.isRequired,
    selectedValue: PropTypes.any,
    toolTipTitle: PropTypes.string,
    onFocus: PropTypes.func,
    tooltipOnHover: PropTypes.bool,
    onBlur: PropTypes.func
};
/**Export Component */
export default withStyles(styles)(AutoCompleteToolTip);