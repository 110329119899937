import React, { Component } from "react";

// Component
import Page from "./Page";

// Material UI
import Grid from '@material-ui/core/Grid';
/**
 * Export PrevNext Component
 * @class PrevNext
 * @extends {Component}
 */
export default class PrevNext extends Component {
    /**
     * Handle Click Event
     * @param {*} isNext 
     * @returns 
     */
    handleClick = (isNext) => (e) => {
        this.props.onClick(isNext);
    }
    /**
     * Build Pages
     * @returns 
     */
    buildPages = () => {
        const { isLoading } = this.props;
        const pages = [];
        pages.push(
            <Page
                key={"prev"}
                onClick={this.handleClick(false)}
                pageText={'⟨'}
                isDisabled={isLoading}
            />
        );
        pages.push(
            <Page
                key={"next"}
                onClick={this.handleClick(true)}
                pageText={'⟩'}
                isDisabled={isLoading}
            />
        );

        return pages;
    }

    /**
     * Bind
     * HTML to DOM
     */
    render() {
        const pages = this.buildPages();
        return (
            <Grid container justifyContent="flex-end" className="pagination-block-Prev-Next">
                <Grid item sm={6} className="text-right" >
                    <ul className="pagination_ul">{pages}</ul>
                </Grid>
            </Grid>
        );
    }
}
