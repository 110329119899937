import React, { memo, Component } from "react";
import PropTypes from "prop-types";

// Material UI
import { withStyles } from "@material-ui/core/styles";
import { TextValidator } from "react-material-ui-form-validator";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Clear } from "@material-ui/icons";

// Table validations warning helper
import { hasWarnings } from "../../helpers/TableValidations/warnings";
import _ from 'underscore';

// Import Styles
import textBoxStyle from "../../assets/jss/components/textBox";

/**
 *Text Box Component
 *
 * @class TextBox
 * @extends {Component}
 */
class TextBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
    };
    // this.myRef = React.createRef();
  }
  /**
   * Handle TextField Events
   * @param {*} event
   */
  handleChange = (event) => {
    this.props.handleChange(this.props.name, event.target.value);
  };
  /**
   * Handle Clear
   * @param {*} event
   */
  handleClear = (event) => {
    this.props.handleClear();
  };
  /**
   * Handle focus
   * @param {*} event
   */
  handleonFocus = (event) => {
    this.props.onFocus(event.target, event.target.name);
    if (event.target.name === "comments") {
      this.props.gotFocus(event.target.name);
    }
  };

  handleonBlur = (event) => {
    this.props.onBlur();
    if (event.target.name === "comments") {
      this.props.outFocus(event.target.name);
    }
  };

  /**
   * Set Hover state
   */
  setHoverState = () => {
    const { tooltipOnHover, focusVisible } = this.props;
    if (!tooltipOnHover && !focusVisible) {
      this.setState({ setOpen: true });
    }
  };

  /**
   * Bind HTML to DOM
   * @returns
   */
  render() {
    let {
      name,
      value,
      label,
      type,
      className,
      margin,
      validators,
      errorMessages,
      disabled,
      maxLength,
      SelectProps,
      variant,
      fielderrors,
      warningfieldname,
      clear,
      title,
      onBlur,
      style,
    } = this.props;
    validators = validators || [];
    let customClass =
      validators && validators.length > 0 && validators.indexOf("required") >= 0
        ? className + " required"
        : className;
    if (validators.indexOf("isEmail") >= 0 && validators.length === 1) {
      customClass = className;
    }
    const warningClass = hasWarnings(fielderrors, warningfieldname);

    switch (type) {
      case "multiselect":
        return (
          <TextValidator
            name={name}
            value={value}
            label={label}
            type={type}
            select
            className={`${customClass} ${warningClass}`}
            margin={margin}
            validators={validators}
            errorMessages={errorMessages}
            disabled={disabled}
            variant={variant}
            SelectProps={{ ...SelectProps }}
          >
            {this.props.children}
          </TextValidator>
        );
      default:
        return clear === true ? (
          <TextValidator
            name={name}
            value={value}
            label={label}
            type={type}
            className={`${customClass} ${warningClass} `}
            onChange={this.handleChange}
            onBlur={onBlur}
            onFocus={(e) => this.handleonFocus(e)}
            margin={margin}
            validators={validators}
            variant={variant}
            errorMessages={errorMessages}
            disabled={disabled}
            style={{ width: "100%" }}
            InputProps={{
              maxLength,
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ marginLeft: -25, cursor: "pointer" }}
                >
                  <Clear onClick={this.handleClear} />
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <TextValidator
            name={name}
            title={title}
            value={value || ""}
            label={label}
            type={type}
            className={`${customClass} ${warningClass}`}
            onChange={this.handleChange}
            onBlur={(e) => this.handleonBlur(e)}
            onFocus={(e) => this.handleonFocus(e)}
            margin={margin}
            validators={validators}
            variant={variant}
            errorMessages={errorMessages}
            disabled={disabled}
            inputProps={{
              maxLength,
            }}
            style={style}
          />
        );
    }
  }
}
// set default props
TextBox.defaultProps = {
  classes: {},
  name: "",
  value: "",
  label: "",
  type: "text",
  className: "",
  errorMessages: [],
  validators: [],
  disabled: false,
  variant: "standard",
  margin: "dense",
  maxLength: 50,
  SelectProps: {},
  handleChange: () => {},
  handleClear: () => {},
  fielderrors: [],
  warningfieldname: "",
  clear: false,
  title: null,
  onBlur: () => {},
  onFocus: () => {},
  toolTipTitle: "",
  tooltipOnHover: true,
  style: {},
  otherPublicAssistance: "",
  gotFocus: () => {},
  outFocus: () => {},
};
//prop types
TextBox.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  clear: PropTypes.bool,
  toolTipTitle: PropTypes.string,
  onFocus: PropTypes.func,
  tooltipOnHover: PropTypes.bool,
  style: PropTypes.object,
  otherPublicAssistance: PropTypes.string,
  gotFocus: PropTypes.func,
  outFocus: PropTypes.func,
};

// prevProps.value !== nextProps.value

function compareprevValue(prevProps, nextProps) {
  if (prevProps.name === nextProps.name) {
    if (!_.isEqual(prevProps,nextProps)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

//export component
TextBox = React.memo(TextBox, compareprevValue);
export default withStyles(textBoxStyle)(TextBox);
