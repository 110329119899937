import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helper Constants
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextBox from '../../../components/TextBox/TextBox';
import { alertActions } from '../../../actions';
/**
 * Default datas
 */
const HUOData = {
    name: '',
    value: ''
}
/**
 * Editable text validation component
 * @class EditableTextValidation
 * @extends { Component}
 * @memberof HomeUnitOccupancy
 */
class EditableTextValidation extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    name: EditableTextValidation,
    value: EditableTextValidation
}
/**
 * HomeUnitOccupancy Containers
 * @class HomeUnitOccupancy
 * @extends { Component}
 */
class HomeUnitOccupancy extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            homeunitoccupancy: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.homeUnitOccupancy);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { homeUnitOccupancy } = nextProps;
        this.setState({
            saved: true,
            homeunitoccupancy: homeUnitOccupancy
        });
    }
    /**
     * Handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate city names
        let homeunitoccupancy = this.state.homeunitoccupancy;
        let duplicateName=''
        let homeunitoccupancyNames = homeunitoccupancy.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicatehomeunitoccupancy = homeunitoccupancyNames.some(function(item, idx){ 
                    if(homeunitoccupancyNames.indexOf(item) !== idx){
                        duplicateName=homeunitoccupancy[homeunitoccupancyNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicatehomeunitoccupancy){
            this.props.erroralert(`${duplicateName.toUpperCase()} home unit occupancy already exists`);
        }else{
        this.props.updatedata(configurationConstants.homeUnitOccupancy, this.state.homeunitoccupancy);
        }
    }
    /**
     * cell Component OnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { homeunitoccupancy } = this.state;
        homeunitoccupancy[rowIndex][fieldName] = value;
        this.setState({ homeunitoccupancy });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { homeunitoccupancy } = this.state;
        // [1]
        //display 0 value
        homeunitoccupancy.map(item=>{
            item.value = item.value===0?'0':item.value;
        })
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="homeunitoccupancy"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'HOME UNIT OCCUPANCY'}
                        headerCols={['NAME', 'VALUE']}
                        requiredFields={['NAME', 'VALUE']}
                        fieldCols={['name', 'value']}
                        rowDatas={homeunitoccupancy}
                        style={style}
                        staticHeader={true}
                        height={height}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (homeunitoccupancy.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    homeunitoccupancy: [
                                        {
                                            ...HUOData,
                                            key: Math.max(...homeunitoccupancy.map(elt => elt.key)) + 1,
                                            type: configurationConstants.homeUnitOccupancy
                                        },
                                        ...homeunitoccupancy
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            this.props.deleteConfig(item.type, item._id);
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.homeUnitOccupancy) }}
                />
            </ValidatorForm>
        );
    }
}
/**
 * Props types
 */
HomeUnitOccupancy.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        homeUnitOccupancy: configuration.configurations[configurationConstants.homeUnitOccupancy] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        getByName: (homeUnitOccupancy) => dispatch(confActions.getByName(homeUnitOccupancy)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert:(msg)=>dispatch(alertActions.error(msg))
    }
};
/**
 * Export HomeUnitOccupancy
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(HomeUnitOccupancy));
