import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

// Style
import dashboardStyle from '../../../assets/jss/containers/dashboard';

// Components
import HorizontalBarChart from '../../../components/Charts/HorizontalBarChart/HorizontalBarChart'

// Actions
import { dashboardActions } from '../../../actions';

//import helper
import { getUserType } from '../../../helpers'

//SVG icons
import { UsersIcon, OrganizationIcon, SvgLoadIcon } from "../../../components/SvgIcons/SvgIcons";

/**
 *UsersSummary
 *
 * @class UsersSummary
 * @extends {Component}
 */
class UsersSummary extends Component {
    /**
     *Creates an instance of UsersSummary.
     * @param {*} props
     * @memberof UsersSummary
     */
    constructor(props) {
        super(props);
        this.state = {

        }
        this.userRole = getUserType();
    }

    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { userRole } = this;
        this.props.getUsersSummary(userRole);
    }

    /**
     *render HTML
     *
     * @returns
     * @memberof UsersSummary
     */
    render() {
        const { classes, usersSummary, isLoading } = this.props
        return (
            <Grid item xs={12} sm={12}>
                <Card className={classes.usersSummaryContainer}>
                    <div className={classes.cardHeader}>
                        <div className="header">Users</div>
                    </div>
                    <div className='usersSummaryCard'>
                        <Grid item xs={12} sm={3} className="cardContainer">
                            <div className="userCard">
                                <div className="icon">
                                    <div className="userIcon">
                                        <UsersIcon fill='#ffca62' width='10px' height='10px' />
                                    </div></div>
                                <div className="details">
                                    <div className="count">
                                        {
                                            isLoading
                                                ?
                                                <div className='userSummaryLoader'>
                                                    <SvgLoadIcon className="greyLoader"/>
                                                </div>
                                                :
                                                usersSummary.totalUsers.count
                                        }
                                    </div>
                                    <div className="title">{usersSummary.totalUsers.title}</div>
                                </div>
                            </div>
                            <div className="orgCard">
                                <div className="icon">
                                    <div className='userIcon'>
                                        <OrganizationIcon fill='#fea1b8' width='19px !important' height='44px !important' />
                                    </div>

                                </div>
                                <div className="details">
                                    <div className="count">
                                        {
                                            isLoading
                                                ?
                                                <div className='userSummaryLoader'>
                                                    <SvgLoadIcon className="greyLoader" />
                                                </div>
                                                : usersSummary.totalOrganizations.count
                                        }
                                    </div>
                                    <div className="title">{usersSummary.totalOrganizations && usersSummary.totalOrganizations.title}</div>
                                </div>
                            </div>
                        </Grid>
                        <div className="borderRight"></div>
                        <Grid item xs={12} sm={3} className="cardContainer">
                            <Grid ref="innerCardRef" item xs={12} sm={12} className="innerCardContainer">
                                <div className="orgAdminCard">
                                    <div className="details">
                                        <div className="count">
                                            {
                                                isLoading
                                                    ?
                                                    <div className='userSummaryLoader'>
                                                        <SvgLoadIcon className="greyLoader"/>
                                                    </div>
                                                    :
                                                    usersSummary.organizationAdmin.count
                                            }
                                        </div>
                                        <div className="title">{usersSummary.organizationAdmin && usersSummary.organizationAdmin.title}</div>
                                    </div>
                                </div>
                                <div className="chartCard">
                                    <HorizontalBarChart data={usersSummary.organizationAdmin && usersSummary.organizationAdmin.status} parentWidth={this.refs.innerCardRef} />
                                </div>
                            </Grid>
                        </Grid>
                        <div className="borderRight"></div>
                        <Grid item xs={12} sm={3} className="cardContainer">
                            <Grid item xs={12} sm={12} className="innerCardContainer">
                                <div className="propCard">
                                    <div className="details">
                                        <div className="count">
                                            {
                                                isLoading
                                                    ?
                                                    <div className='userSummaryLoader'>
                                                        <SvgLoadIcon className="greyLoader" />
                                                    </div>
                                                    :
                                                    usersSummary.onsiteManager.count
                                            }
                                        </div>
                                        <div className="title">{usersSummary.onsiteManager && usersSummary.onsiteManager.title}</div>
                                    </div>
                                </div>
                                <div className="chartCard">
                                    <HorizontalBarChart data={usersSummary.onsiteManager && usersSummary.onsiteManager.status} parentWidth={this.refs.innerCardRef} />
                                </div>
                            </Grid>
                        </Grid>
                        <div className="borderRight"></div>
                        <Grid item xs={12} sm={3} className="cardContainer">
                            <Grid item xs={12} sm={12} className="innerCardContainer">
                                <div className="propCard">
                                    <div className="details">
                                        <div className="count">
                                            {
                                                isLoading
                                                    ?
                                                    <div className='userSummaryLoader'>
                                                        <SvgLoadIcon className="greyLoader" />
                                                    </div>
                                                    :
                                                    usersSummary.none.count
                                            }
                                        </div>
                                        <div className="title">{usersSummary.none && usersSummary.none.title}</div>
                                    </div>
                                </div>
                                <div className="chartCard">
                                    <HorizontalBarChart data={usersSummary.none && usersSummary.none.status} parentWidth={this.refs.innerCardRef} />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        )
    }
}
// prop types
UsersSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
UsersSummary.defaultProps = {
    classes: {}
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        usersSummary: (state.dashboard.usersSummary && state.dashboard.usersSummary.data && state.dashboard.usersSummary.data) || [],
        isLoading: (state.dashboard.usersSummary && state.dashboard.usersSummary.isLoading) || false

    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getUsersSummary: (userRole) => dispatch(dashboardActions.getUsersSummary(userRole)),

    }
};
// export UsersSummary
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(UsersSummary));