import React from 'react';

// Material UI
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

//Import Components
import AlertDialog from "../AlertDialog/AlertDialog";

class FieldFilter extends React.Component {
  /**Handle Save Button when clicked  */
  handleOnSave = () => {
    this.props.handleOnSave();
  };
  /**Handle Close Event */
  handleOnClose = () => {
    this.props.handleOnClose();
  };
  /**Handle Cancel Event when invoked*/
  handleOnCancel = () => {
    this.props.handleOnCancel();
  };
  /** Get Columns List */
  getColumnsList() {
    const { columnsMap, visibleColumns, handleOnChange, disabledCols } = this.props;
    const cbxGroup = Object.keys(columnsMap).map((key, value)=>{
      return (
        <Grid item xs={3}  key={`ff_grid_${key}`}>
          <FormControlLabel
            key={`ffc_cbx_${key}`}
            style={{ height: "35px"}}
            control={
                <Checkbox
                  // disabled={value === 0}
                  checked={visibleColumns.indexOf(key) > -1}
                  onChange={(evt) => {handleOnChange(key, evt.target.checked)}}
                  value="checkedB"
                  color="primary"
                  disabled={disabledCols.indexOf(key) >= 0}
                />
              
            }
            label={columnsMap[key]}
          />
        </Grid>
      )
    });
    return <Grid container><FormGroup row>{cbxGroup}</FormGroup></Grid>
  }
  /**
   *render html
   *
   * @returns
   * @memberof FieldFilter
   */
  render() {
    const {open, title, handleOnSave, handleOnClose} = this.props;
    return (
      <AlertDialog
        open={open}
        title={title}
        onSave={handleOnSave}
        onClose={handleOnClose}>
          {this.getColumnsList()}
      </AlertDialog>
    );
  }
}
//default props
FieldFilter.defaultProps = {
  columnsMap: {},
  visibleColumns: [],
  title: "Show/Hide Columns",
  handleOnChange: () => {},
  disabledCols: []
}
//Export Component
export default FieldFilter;