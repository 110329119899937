import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { TextValidator } from 'react-material-ui-form-validator';

// Table validations warning helper
import { hasWarnings } from '../../helpers/TableValidations/warnings';


/**
 *DateTimePicker Component
 *
 * @class DateTimePicker
 * @extends {Component}
 */
class DateTimePicker extends Component {
    /**
     * Handle TextField Events
     * @param {*} date 
     */
    handleChange = (date) => {
        this.props.handleChange(this.props.name, date);
    };
    /**
     *render html
     *
     * @returns
     * @memberof DateTimePicker
     */
    render() {
        const { value, name, label, disabled, margin, className, validators, errorMessages, validations, onBlur, format, fielderrors, warningfieldname, style, notRequired, minDate } = this.props;
        const customClass = validators && validators.length > 0 && !notRequired ? className + ' required' : className;
        const warningClass = hasWarnings(fielderrors, warningfieldname);
        return (
            <KeyboardDateTimePicker
                label={label && `${label} (MM/DD/YYYY)`}
                variant="inline"
                name={name}
                value={value ? value : null}
                onChange={this.handleChange}
                format={format}
                // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                className={`${customClass} ${warningClass}`}
                disabled={disabled}
                margin={margin}
                TextFieldComponent={TextValidator}
                invalidDateMessage={errorMessages}
                validators={validators}
                errorMessages={errorMessages}
                onBlur={onBlur}
                style={style}
                // helperText={helperText}
                {...validations}
                autoOk={true}
                minDate={minDate && minDate}
            />
        );
    }
}

//default props
DateTimePicker.defaultProps = {
    name: "",
    value: "",
    label: "",
    disabled: false,
    margin: "dense",
    className: "",
    errorMessages: [],
    validators: [],
    handleChange: () => { },
    validations: {},
    onBlur: () => { },
    format: "MM/DD/YYYY",
    fielderrors: [],
    warningfieldname: '',
    style: {},
    helperText: "mm/dd/yyyy (e.g 01/01/1991)",
    notRequired: false,
    minDate: ''
}

//prop types
DateTimePicker.propTypes = {
    name: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    helperText: PropTypes.string
};
//Export Component
export default DateTimePicker;
