// service
import { contractService } from '../services/contract'
// constant
import { contractConstant } from '../constants/contract'
import { alertActions } from './alert';
import { history } from '../helpers';
import { userService } from '../services';

const insertContractDetails = (data, loanProjectId) => {

    return dispatch => {
        dispatch(request());
        contractService.insertContract(data)

            .then(
                contract => {
                    dispatch(alertActions.success('Contract Created Successfully'));
                    setTimeout(function () {
                        history.push({
                            pathname: `/loanProjects/${data.project_id}/contracts`,
                            state: {
                                page: 'contractsList',
                                pageData: {
                                    loanProjectId: loanProjectId
                                },
                            }
                        });
                    }, 2000);
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: contractConstant.GET_CONTRACT_DETAILS_REQUEST } }
    function failure(error) { return { type: contractConstant.GET_CONTRACT_DETAILS_FAILURE, error } }
}
// get all contract 
const getAllContract = (id, skip, limit, orderBy, order, sField, sValue, exactMatch = false, userId = null, query = { isDelete: false }) => {

    return dispatch => {
        dispatch(request());
        contractService.getAllContract(id, skip, limit, orderBy, order, sField, sValue, exactMatch, userId, query)
            .then(
                allContract => {
                    dispatch(success(allContract))
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: contractConstant.GET_CONTRACT_DETAILS_REQUEST } }
    function success(allContract) { return { type: contractConstant.GET_CONTRACT_DETAILS_SUCCESS, allContract } }
    function failure(error) { return { type: contractConstant.GET_CONTRACT_DETAILS_FAILURE, error } }
}
/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
const getOrganizations = (text, page) => {
    return dispatch => {
        dispatch(request());

        contractService.getOrganizations(text, page)
            .then(
                suggestions =>
                    dispatch(success(suggestions)),

                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contractConstant.GETALL_ORGANIZATION_REQUEST } }
    function success(suggestions) { return { type: contractConstant.GETALL_ORGANIZATION_SUCCESS, suggestions } }
    function failure(error) { return { type: contractConstant.GETALL_ORGANIZATION_FAILURE, error } }
}
/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
const getAllPreparerInOrg = (value) => {
    return dispatch => {
        dispatch(request());
        userService.getByOrgIdHos(value)
            .then(
                user => {
                    let users = user.data.map((data) => {
                        data.label = `${data.firstName} ${data.lastName}`;
                        // add star before  inactive
                        data.label = data.isActive ? data.label : `*${data.label}`;
                        data.value = data._id;
                        data.id = +data.id;
                        return data;
                    });
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contractConstant.GETALL_PREPARER_ORGANIZATION_REQUEST } }
    function success(suggestions) { return { type: contractConstant.GETALL_PREPARER_ORGANIZATION_SUCCESS, suggestions } }
    function failure(error) { return { type: contractConstant.GETALL_PREPARER_ORGANIZATION_FAILURE, error } }
}
/**
 * get projects details
 * 
 * @param {*} id
 */
const getProjectDetails = (id) => {
    return dispatch => {
        dispatch(request());

        contractService.getProjectDetails(id)
            .then(projectData =>
                dispatch(success(projectData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contractConstant.GET_PROJECTS_DETAILS_REQUEST } }
    function success(suggestions) { return { type: contractConstant.GET_PROJECTS_DETAILS_SUCCESS, suggestions } }
    function failure(error) { return { type: contractConstant.GET_PROJECTS_DETAILS_FAILURE, error } }
}


/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - explore id in the listing page
 * @returns
 */
 function _delete(id,successCallback) {
    return dispatch => {
        dispatch(request(id));

        contractService.deleteContract(id)
            .then(
                data => {
                    successCallback(data);
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: contractConstant.DELETE_REQUEST, id } }
    function success(id) { return { type: contractConstant.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: contractConstant.DELETE_FAILURE, id, error } }
}




/**
 * get projects details
 * 
 * @param {*} id
 */
const getOnePreparerDetails = (id) => {
    return dispatch => {
        dispatch(request());

        contractService.getOnePreparerDetails(id)
            .then(projectData =>
                dispatch(success(projectData)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contractConstant.GET_ONE_PREPARER_DETAILS_REQUEST } }
    function success(suggestions) { return { type: contractConstant.GET_ONE_PREPARER_DETAILS_SUCCESS, suggestions } }
    function failure(error) { return { type: contractConstant.GET_ONE_PREPARER_DETAILS_FAILURE, error } }
}
/**
 * 
 * 
 * @param {*} formData
 */
const updateContract = (formData, loanProjectId) => {
    return dispatch => {
        contractService.updateContract(formData).then(
            data => {
                // dispatch(success())
                dispatch(alertActions.success("Contract Updated Successfully"));
                setTimeout(() => {
                    history.push({
                        pathname: `/loanProjects/${formData.project_id}/contracts`,
                        // state: {
                        //     page: 'contractsList',
                        //     pageData: {
                        //         loanProjectId: loanProjectId
                        //     },
                        // }
                    });
                }, 800);
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        )
    };


    // function request() { return { type: contractConstant.GET_UPDATE_CONTRACT_REQUEST } }
    // function success() { return { type: contractConstant.GET_UPDATE_CONTRACT_SUCCESS } }
    function failure(error) { return { type: contractConstant.GET_UPDATE_CONTRACT_FAILURE, error } }
}
/**
 * 
 * 
 * @param {*} formData
 */
const getByContractId = (id) => {
    return dispatch => {
        dispatch(request());

        contractService.getByContractId(id)
            .then(contractDetails => {
                dispatch(success(contractDetails))
            },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: contractConstant.GET_CONTRACT_DEATILS_REQUEST } }
    function success(contractDetails) { return { type: contractConstant.GET_CONTRACT_DEATILS_SUCCESS, contractDetails } }
    function failure(error) { return { type: contractConstant.GET_CONTRACT_DEATILS_FAILURE, error } }
}

const clearContractDetails = () => {
    return dispatch => {
        dispatch(success())
    };

    function success() { return { type: contractConstant.CLEAR_CONTRACT_DETAILS } }
}
export const contractAction = {
    insertContractDetails,
    getAllContract,
    delete: _delete,
    getOrganizations,
    getAllPreparerInOrg,
    getProjectDetails,
    getOnePreparerDetails,
    updateContract,
    getByContractId,
    clearContractDetails
}