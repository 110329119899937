import React, { Component } from "react";
import PropTypes from "prop-types";

//Material UI
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";

// Import Styles
import accordionStyle from "../../assets/jss/containers/accordion";

//Import Components
import ToolTip from "../ToolTip/ToolTip";
import PageActions from "../PageActions/PageActions";
import SearchInput from "../SearchInput/SearchInput";
import { SvgLoadIcon } from "../SvgIcons/SvgIcons";

// Helpers
import { getChildCardHeight } from "../../helpers/global";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 42,
    "&$expanded": {
      minHeight: 42,
    },
  },
  content: {
    margin: 0,
    width: "90%",
    display: "unset",
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    padding: theme.spacing(1),
  },
}))(MuiAccordionDetails);

let MAX_CONTENT = 50;
/**
 * AccordionMenu Component
 * @class AccordionMenu
 * @extends {Component}
 */
class AccordionMenu extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedAccordionKeys: [],
      selectedInnerAccordionKeys: [],
      search: "",
      accordionKeys: [],
      accordionKeysCount: 0,
    };
  }
  /**
   * Component Will Receive Props
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    let { accordionKeys, search } = this.state;
    let regexText = new RegExp(search, "i");
    accordionKeys = nextProps.accordionKeys.filter((elem) => {
      if (regexText.test(elem.accordionSummary.label)) {
        return true;
      } else {
        let siteData = elem.accordionDetails.filter((data) =>
          regexText.test(data.label)
        );
        return siteData.length > 0 ? true : false;
      }
    });
    accordionKeys = accordionKeys.slice(0, MAX_CONTENT);
    this.setState({
      accordionKeys: accordionKeys,
      accordionKeysCount: accordionKeys.length,
    });
  }
  /**
   * On Search
   * @param {*} field
   * @param {*} value
   */
  onSearch = (field, value) => {
    let regexText = new RegExp(value, "i");
    let accordionKeys = this.props.accordionKeys.filter((elem) => {
      if (regexText.test(elem.accordionSummary.label)) {
        return true;
      } else {
        let siteData = elem.accordionDetails.filter((data) =>
          regexText.test(data.label)
        );
        return siteData.length > 0 ? true : false;
      }
    });
    this.setState({
      search: value,
      accordionKeys: accordionKeys.slice(0, MAX_CONTENT),
      accordionKeysCount: accordionKeys.length,
    });
  };

  getParentChecked = (innerData, outerData, item) => {
    const { isHoMenu } = this.props;
    let mofNewData = outerData.filter((data) => data._id === item._id);
    if (isHoMenu) {
        if (mofNewData && mofNewData[0] && mofNewData[0].contract) {
            mofNewData = mofNewData[0].contract.length;
          }
          if (innerData.filter((data) => data.project_id === item._id).length === mofNewData) {
            return true;
          } else {
            return false;
          }
    } else {
      if (mofNewData && mofNewData[0] && mofNewData[0].site) {
        mofNewData = mofNewData[0].site.length;
      }
      if (innerData.filter((data) => data.projectid === item._id).length === mofNewData) {
        return true;
      } else {
        return false;
      }
    }
  };

  getChildChecked = (selectedInnerData, itemData) => {
    const { isHoMenu } = this.props;
    if (isHoMenu) {
      return  selectedInnerData.some((elem) => elem._id === itemData._id);
      
    } else {
       return selectedInnerData.some((elem) => elem.siteid === itemData.siteid);
    }
  };
  /**
   * On Scroll Event
   */
  onScrollEvent = () => {
    let { accordionKeys, search } = this.state;
    let scrollEl = document.getElementById("scrollEl");
    if (scrollEl.scrollTop + scrollEl.clientHeight >= scrollEl.scrollHeight) {
      accordionKeys = this.props.accordionKeys.slice(
        0,
        accordionKeys.length + MAX_CONTENT
      );
      if (search) {
        let regexText = new RegExp(search, "i");
        accordionKeys = this.props.accordionKeys.filter((elem) => {
          if (regexText.test(elem.accordionSummary.label)) {
            return true;
          } else {
            let siteData = elem.accordionDetails.filter((data) =>
              regexText.test(data.label)
            );
            return siteData.length > 0 ? true : false;
          }
        });
      }
    }
    this.setState({ accordionKeys: accordionKeys });
  };
  /**
   * Bind HTML to reactDOM
   * @returns
   */
  render() {
    let {
      classes,
      needHeader,
      selectedAccordionKeys,
      selectedInnerAccordionKeys,
      selectAll,
    } = this.props;
    let { search, accordionKeys, accordionKeysCount } = this.state;
    return (
      <div style={{ height: getChildCardHeight() }}>
        <div className={classes.menuHeaderContainer}>
          {needHeader && (
            <div className={classes.menuHeaderWrapper}>
              <Grid container>
                <Grid item xs={12} sm={8}>
                  <div className={classes.menuHeader}>Select Projects</div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.previewButton}>
                  <PageActions
                    actions={["VIEW"]}
                    handlePreviewActionClick={this.props.handlePreview}
                  />
                </Grid>
              </Grid>
            </div>
          )}
          <div className={classes.search}>
            <SearchInput
              placeholder={"Search Here"}
              value={search}
              field="accordionSearch"
              onInputChangeRequest={this.onSearch}
              onEnter={this.onSearch}
            />
          </div>
        </div>
        {accordionKeys.length > 0 && (
          <div
            className={classes.menuCheckBoxContainerWrapper}
            style={{ height: getChildCardHeight() - 99 }}
          >
            <div className={classes.selectAll}>
              <FormControlLabel
                className={classes.summaryLabel}
                aria-label={"Select All"}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    color="primary"
                    checked={selectAll}
                    onChange={() => {
                      this.props.handleSeletedAll(selectAll);
                    }}
                  />
                }
                label={"Select All"}
              />
            </div>
            <div
              className={classes.menuCheckBoxContainer}
              style={{ height: getChildCardHeight() - 162 }}
              id="scrollEl"
              onScroll={(e) => this.onScrollEvent()}
            >
              {accordionKeys.map((item, index) => {
                return (
                  <React.Fragment>
                    <Accordion>
                      <AccordionSummary
                        key={index}
                        expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id="additional-actions1-header"
                      >
                        <ToolTip
                          title={`${item.accordionSummary.label}`}
                          placement="right"
                        >
                          <FormControlLabel
                            className={classes.summaryLabel}
                            aria-label={`${item.accordionSummary.label}`}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                              <Checkbox
                                color="primary"
                                checked={this.getParentChecked(
                                  selectedInnerAccordionKeys,
                                  selectedAccordionKeys,
                                  item
                                )}
                                onChange={() => {
                                  this.props.handleSeletedAccordion(item);
                                }}
                              />
                            }
                            label={`${item.accordionSummary.label}`}
                          />
                        </ToolTip>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionDetails}>
                        {item.accordionDetails &&
                          item.accordionDetails.length > 0 &&
                          item.accordionDetails.map((itemData, index) => {
                            return (
                              <div key={index}>
                                <ToolTip title={item.label} placement="right">
                                  <FormControlLabel
                                    className={classes.detailsLabel}
                                    aria-label={itemData.label}
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    control={
                                      <Checkbox
                                        color="primary"
                                        // checked={selectedInnerAccordionKeys.some(elem => elem.siteid === itemData.siteid)}
                                        // checked={selectedInnerAccordionKeys.some(elem =>elem._id===itemData._id)}
                                        checked={this.getChildChecked(
                                          selectedInnerAccordionKeys,
                                          itemData
                                        )}
                                        onChange={(e) => {
                                          this.props.handleSeletedInnerAccordion(
                                            itemData,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    }
                                    label={itemData.label}
                                  />
                                </ToolTip>
                              </div>
                            );
                          })}
                      </AccordionDetails>
                    </Accordion>
                    {index === accordionKeys.length - 1 &&
                      accordionKeysCount > accordionKeys.length && (
                        <div
                          className="autoCompleteLoader"
                          style={{ padding: 12 }}
                        >
                          <SvgLoadIcon />
                        </div>
                      )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
        {accordionKeys.length === 0 && (
          <div
            className="accordin-no-records"
            style={{ height: getChildCardHeight() - 97 }}
          >
            No Records Found
          </div>
        )}
      </div>
    );
  }
}
/**
 * Bind Component Property Types
 */
AccordionMenu.propTypes = {
  accordion: PropTypes.array.isRequired,
  noDataErrorMessage: PropTypes.string.isRequired,
  isHoMenu:PropTypes.bool
};
/*Default Props */
AccordionMenu.defaultProps = {
  accordion: [],
  accordionData: [],
  onChange: () => {},
  selectedColumns: [],
  noDataErrorMessage: "",
  accordionMenuGroups: {},
  selectedMenuGroupKey: [],
  accordionKeys: [],
  selectedAccordionKeys: [],
  searchAccordionData: {},
  selectedInnerAccordionKeys: [],
  isHoMenu:false
};
//Export Component
export default withStyles(accordionStyle)(AccordionMenu);
