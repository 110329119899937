import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

//Import Component
import MaterialTable from '../../../components/Table/MaterialTable';

// import helper
import { globalVariable, history, filterSearchFields, getUserOrgId, getUserType } from '../../../helpers';

//import actions
import { dashboardActions } from '../../../actions';
import { searchActions } from '../../../actions/search';

/**
 * PMNeedsAction Container
 * @class PMNeedsAction
 * @extends PMNeedsAction
 */
class PMNeedsAction extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            paginationVisible: true,
            searchVisible: true
        }
        this.org_id = getUserOrgId();
        this.userRole = getUserType();
    }
    /**
     * On Change Page Table 
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { userRole, org_id } = this;
        const { dashboard, searchData: { sFields, sValues }, dashboardCompliance } = this.props;
        this.props.getPMProjectList(skip, limit, dashboard.page.orderBy, dashboard.page.order, sFields, sValues, dashboardCompliance.selectedSites, dashboardCompliance.year, userRole, org_id);
    };
    /**
     * On Sort Request
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { userRole, org_id } = this;
        const { dashboard, searchData: { sFields, sValues }, dashboardCompliance } = this.props;
        this.props.getPMProjectList(dashboard.page.skip, dashboard.page.limit, orderBy, order, sFields, sValues, dashboardCompliance.selectedSites, dashboardCompliance.year, userRole, org_id);
    };
    /**
     * On Input Change Request
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { dashboard, searchData, dashboardCompliance } = this.props;
        let { sFields, sValues } = searchData;
        const { userRole, org_id } = this;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.searchActions({ sFields, sValues });
        this.props.getPMProjectList(1, globalVariable.tabTableRowSize, dashboard.page.orderBy, dashboard.page.order, sFields, sValues, dashboardCompliance.selectedSites, dashboardCompliance.year, userRole, org_id);
    };
    /**
     * Handle View Report Action
     * @param {*} item 
     */
    handleViewReportAction = (item) => {
        const { dashboardCompliance } = this.props;
        if (item.reportYear_id) {
            history.push({
                pathname: `/projects/${item.project_id}/reports/${item.reportYear_id}/${dashboardCompliance.year}`,
                state: {
                    page: 'reportView',
                    site_id: item.site_id,
                    pageData: {
                        project_id: parseInt(item.project_id, 10),
                        reportYear: dashboardCompliance.year,
                        reportYear_id: item.reportYear_id,
                        site_id: item.site_id,
                    },
                    pageTitle: {
                        title: `Report - ${item.siteName} - ${dashboardCompliance.year} | ${item.projectName}`,
                        subTitle: ''
                    }
                }
            });
        } else {
            history.push(`/projects/${item.project_id}/reports`);
        }
    }
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        const { dashboardCompliance } = this.props;
        switch (field) {
            case 'table1Issues':
                if (item.site_id !== null) {
                    history.push({
                        pathname: `/projects/${item.project_id}/reports/${item.reportYear_id}/${dashboardCompliance.year}/table1`,
                        state: {
                            page: 'table1',
                            site_id: item.site_id,
                            pageData: {
                                project_id: parseInt(item.project_id, 10),
                                reportYear: dashboardCompliance.year,
                                reportYear_id: item.reportYear_id,
                                site_id: item.site_id,
                            },
                            pageTitle: {
                                title: `Report - ${item.siteName} - ${dashboardCompliance.year} | ${item.projectName}`,
                                subTitle: ''
                            }
                        }
                    });
                }
                break;
            case 'table2Issues':
                if (item.site_id !== null) {
                    history.push({
                        pathname: `/projects/${item.project_id}/reports/${item.reportYear_id}/${dashboardCompliance.year}/table2`,
                        state: {
                            page: 'table2',
                            site_id: item.site_id,
                            pageData: {
                                project_id: parseInt(item.project_id, 10),
                                reportYear: dashboardCompliance.year,
                                reportYear_id: item.reportYear_id,
                                site_id: item.site_id,
                            },
                            pageTitle: {
                                title: `Report - ${item.siteName} - ${dashboardCompliance.year} | ${item.projectName}`,
                                subTitle: ''
                            }
                        }
                    });
                }
                break;
            case 'table3Issues':
                if (item.site_id !== null) {
                    history.push({
                        pathname: `/projects/${item.project_id}/reports/${item.reportYear_id}/${dashboardCompliance.year}/table3`,
                        state: {
                            page: 'table3',
                            site_id: item.site_id,
                            pageData: {
                                project_id: parseInt(item.project_id, 10),
                                reportYear: dashboardCompliance.year,
                                reportYear_id: item.reportYear_id,
                                site_id: item.site_id,
                            },
                            pageTitle: {
                                title: `Report - ${item.siteName} - ${dashboardCompliance.year} | ${item.projectName}`,
                                subTitle: ''
                            }
                        }
                    });
                }
                break;
            case 'table4Issues':
                if (item.site_id !== null) {
                    history.push({
                        pathname: `/projects/${item.project_id}/reports/${item.reportYear_id}/${dashboardCompliance.year}/table4`,
                        state: {
                            page: 'table4',
                            site_id: item.site_id,
                            pageData: {
                                project_id: parseInt(item.project_id, 10),
                                reportYear: dashboardCompliance.year,
                                reportYear_id: item.reportYear_id,
                                site_id: item.site_id,
                            },
                            pageTitle: {
                                title: `Report - ${item.siteName} - ${dashboardCompliance.year} | ${item.projectName}`,
                                subTitle: ''
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * render html
     */
    render() {
        let { dashboard, searchData: { sFields, sValues } } = this.props;
        const { paginationVisible, searchVisible } = this.state;
        const fieldFilters = dashboard.page ? dashboard.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        return (
            <Grid item xs={12} className="needsAction">
                <MaterialTable
                    page={dashboard.page.skip}
                    primaryHeader={['Table 1', 'Table 2', 'Table 3', 'Table 4']}
                    stickyHeaders={["Project Name", "Site Name", "Total Issues"]}
                    rowsPerPage={dashboard.page.limit}
                    count={dashboard.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    multiHeader={true}
                    // componentMap={componentMap}
                    links={["table1Issues", "table2Issues", "table3Issues", "table4Issues"]}
                    fieldComponentOnChange={() => { }}
                    highlightField={['projectName', 'siteName']}
                    actions={["VIEW_REPORT"]}
                    order={dashboard.page.order}
                    orderBy={dashboard.page.orderBy}
                    data={dashboard.data.data}
                    isLoading={dashboard.isLoading}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    resetSearchContent={false}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    paginationVisible={paginationVisible}
                    searchVisible={searchVisible}
                    handleViewReportAction={this.handleViewReportAction}
                    handleNavigateAction={this.handleNavigateAction}
                    showLoading={true}
                    showMsgInside={true}
                    noDataErrorMessage={"No Records Found"}
                    setLabel={(tableItem, defaultValue) => {
                        return tableItem.table === "1" ? "View Household" : "View Report"
                    }}
                />
            </Grid>
        );
    }
}
/**
 * Set Props Types
 */
PMNeedsAction.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array
};
/**
 * Default Props
 */
PMNeedsAction.defaultProps = {
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['dashboardPropertyManager'] || {};
    const searchData = state.search['pmdashboard'] || { sFields: [], sValues: [] }
    const pageAccess = state.pageAccess['dashboard'] || [];
    return {
        dashboard: state.dashboard.dashboard,
        dashboardCompliance: state.dashboard.complianceNeedsAction,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData
    };
}
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getPMProjectList: (skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id) => dispatch(dashboardActions.getPMProjectList(skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id)),
        searchActions: (data) => dispatch(searchActions.PMDashboard(data))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PMNeedsAction);