import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TableComponent from "../../components/Table/TableComponent";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

//Actions
import { complianceActions } from "../../actions/compliance";
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';

//Helpers
import { globalVariable, history, filterSearchFields } from "../../helpers";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

/**
 *Compliance Component
 *
 * @class Compliance
 * @extends {Component}
 */
class Compliance extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            openHelp: false,
        }
    }
    /**
     *Executes just before the render function
     * @memberof Compliance
     * 
     * [1]  -   Get help datas of building list
     * [2]  -   To check if the reducer has page value
     * [3]  -   Get data based on reducer page values
     * [4]  -   Get data based on default page values
     * [5]  -   Search if pagedata has values
     */
    UNSAFE_componentWillMount() {
        const { page } = this.props.compliance;
        const { location: { state } } = this.props;
        // [1]
        this.props.getHelp();
        // [2]
        if (page.skip) {
            // [3]
            this.props.getCompliance(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues);
        } else {
            // [4]
            this.props.getCompliance(1, globalVariable.tableRowSize, 'projects.name', 'asc', '', '');
        }
        // [5]
        if (state && state.pageData) {
            if (state.pageData.data) {
                this.onInputChangeRequest("reportTable", state.pageData.data)
            }
        }
    }
    /**
     * Component Did Update
     * @memberof Compliance
     * 
     * [1]  -   clear the table filter
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            // [1]
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Go to Compliance Settings 
     */
    handleGotoSettingsActionClick = () => {
        history.push({
            pathname: `/compliance/setting`,
            state: {
                page: 'compliancesetting',
                pageData: {
                }
            }
        });
    }
    /**
     * Handle sort`
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { compliance, searchData: { sFields, sValues } } = this.props;
        this.props.getCompliance(compliance.page.skip, compliance.page.limit, orderBy, order, sFields, sValues);
    };
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     */
    onChangePageTable = (skip) => {
        const { compliance, searchData: { sFields, sValues } } = this.props;
        this.props.getCompliance(skip, globalVariable.tableRowSize, compliance.page.orderBy, compliance.page.order, sFields, sValues);
    };
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { compliance } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getCompliance(compliance.page.skip, compliance.page.limit, compliance.page.orderBy, compliance.page.order, [], []);
    };

    /**
    *
    * @param {*} searchField
    * @param {*} searchKey
    * @memberof TableComponent
    *
    * [1]  -   To check wheather searchField is already in the array sFields
    *          if - Update the searchField
    *          else - Push the searchField into the array sFields
    * [2]  -   To check if the searchKey has a vale
    *          if - searchKey is empty remove the searchField from the array sFields
    *          else - Update the searchKey
    */
    onInputChangeRequest = (searchField, searchKey) => {
        const { compliance, searchData } = this.props;
        let { sFields, sValues } = searchData;
        // [1]
        if (sFields.indexOf(searchField) >= 0) {
            // [2]
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getCompliance(1, globalVariable.tableRowSize, compliance.page.orderBy, compliance.page.order, sFields, sValues);
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     * 
     * [1]  -   Navigate to occupants general page
     */
    handleNavigateAction = (id, field, item) => {
        switch (field) {
            // [1]
            case 'table1_id.headOfHouseholdName':
                if (item.table1_id !== null) {
                    history.push({
                        pathname: `/occupants/${item.table1_id._id}`,
                        state: {
                            page: 'occgeneral',
                            pageData: {
                                table_id: item.table1_id._id
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * Go to Report Page
     * @param {*} item 
     * @memberof TableComponent
     * 
     * [1]  -   Navigate to occupants general page
     * [2]  -   Navigate to table2
     * [3]  -   Navigate to table3
     * [4]  -   Navigate to table4
     */
    handleViewReportAction = (item) => {
        const { reportYearData } = item;
        const reportYear_id = reportYearData ? reportYearData : null;
        switch (item.reportTable) {
            // [1]
            case "1":
                history.push({
                    pathname: `/occupants/${item.table1_id._id}`,
                    state: {
                        page: 'occgeneral',
                        pageData: {
                            table_id: item.table1_id._id
                        }
                    }
                });
                break;
            // [2]
            case "2":
                history.push({
                    pathname: `/projects/${item.project_id._id}/reports/${reportYear_id.reportYear_id}/${reportYear_id.year}/table2`,
                    state: {
                        page: 'table2',
                        pageData: {
                            project_id: item.project_id._id,
                            site_id: item.site_id._id,
                            reportYear_id: reportYear_id.reportYear_id
                        }
                    }
                });
                break;
            // [3]
            case "3":
                history.push({
                    pathname: `/projects/${item.project_id._id}/reports/${reportYear_id.reportYear_id}/${reportYear_id.year}/table3`,
                    state: {
                        page: 'table3',
                        pageData: {
                            project_id: item.project_id._id,
                            site_id: item.site_id._id,
                            reportYear_id: reportYear_id.reportYear_id
                        }
                    }
                });
                break;
            // [4]
            case "4":
                history.push({
                    pathname: `/projects/${item.project_id._id}/reports/${reportYear_id.reportYear_id}/${reportYear_id.year}/table4`,
                    state: {
                        page: 'table4',
                        pageData: {
                            project_id: item.project_id._id,
                            site_id: item.site_id._id,
                            reportYear_id: reportYear_id.reportYear_id
                        }
                    }
                });
                break;
            default:
                break;
        }
    }
    /**
     * Handle Help Click Action
     * @memberof ContentWrapper
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Render Html
     */
    render() {
        let { pageAccess, classes, isSubPage, location: { pathname }, title, compliance, searchData: { sFields, sValues }, helpData } = this.props;
        const fieldFilters = compliance.page ? compliance.page.fieldFilters : {};
        const { openHelp } = this.state;
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        pageAccess = [...pageAccess, 'HELP'];
        return (
            <div className='complianceContainer'>
                <ContentWrapper
                    classes={classes}
                    pageAccess={pageAccess}
                    title={title}
                    needPadding={!isSubPage}
                    isSubPage={isSubPage}
                    handleClearFilterActionClick={() => {
                        history.replace(`${pathname}#clear`);
                    }}
                    handleGotoSettingsActionClick={this.handleGotoSettingsActionClick}
                    handleHelpActionClick={this.handleHelpActionClick}
                >

                    <TableComponent
                        page={compliance.page.skip}
                        rowsPerPage={compliance.page.limit}
                        count={compliance.page.count}
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        actions={pageAccess}
                        order={compliance.page.order}
                        orderBy={compliance.page.orderBy}
                        isLoading={compliance.isLoading}
                        data={compliance.data}
                        links={["table1_id.headOfHouseholdName"]}
                        highlightField={["project_id.name"]}
                        resetSearchContent={this.props.location.hash === "#clear"}
                        onChangePageTable={this.onChangePageTable}
                        onSortRequest={this.onSortRequest}
                        onInputChangeRequest={this.onInputChangeRequest}
                        sFields={filterSearchFields(sFields)}
                        sValues={sValues}
                        handleNavigateAction={this.handleNavigateAction}
                        handleViewReportAction={this.handleViewReportAction}
                        noDataErrorMessage={"No Records Found"}
                        prevNext={false}
                        paginationVisible={!compliance.page.lastPage ? true : false}
                        showLoading={false}
                        setLabel={(tableItem, defaultValue) => {
                            return tableItem.reportTable === "1" ? "View Household" : "View Report"
                        }}
                    />
                    { openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="Compliances"
                    />}
                </ContentWrapper>
            </div>
        );
    }
}

/**
 * Set Props Types
 */
Compliance.propTypes = {
    classes: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
};

/**
 * Default Props
 */
Compliance.defaultProps = {
    title: "ALL COMPLIANCE ISSUES",
    isSubPage: false,
    pageAccess: []
};
/**
 *  Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ compliance, pageAccess, search, help }, ownProps) => {
    const helpData = help.data[0]
    return {
        pageAccess: ownProps.pageAccess ? ownProps.pageAccess : pageAccess['compliance'] || [],
        compliance: compliance,
        searchData: search['compliance'] || { sFields: [], sValues: [] },
        helpData
    }
};
/**
 *  Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getCompliance: (skip, limit, orderBy, order, sField, sValue) => dispatch(complianceActions.getAll(skip, limit, orderBy, order, sField, sValue)),
        searchActions: (data) => dispatch(searchActions.compliance(data)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 13 }))
    }
};
// export Compliance
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Compliance));
