//import constants
import { occupantsConstants } from '../constants';

//import services
import { occupantServices,incomeRentService } from '../services';

//import actions
import { alertActions, validationActions } from './';

export const occupantsActions = {
    getAll,
    getOccupantsDetails,
    updateOccupantsHHIDetails,
    updateOccupantsHHINCOMEDetails,
    updateOccupantsMRD,
    updateOccupantsGeneral,
    getAssocPjtConfig,
    getEmptyUnits,
    moveInOccupant,
    getVacantUnit,
    transferUnit,
    saveOccupantToLocal,
    clearOccupantInLocal,
    getMoveInConfig,
    getOneValidationError,
    getOneClearValidationError,
    clearEmptyUnits,
    deleteOccupant,
    getFunders,
    updateIncomeRent,
    showLoading,
    hideLoading
};

/**
 *Get occupants list data
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [isAutoComplete=false]
 * @param {*} projectData
 * @returns
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, isAutoComplete = false, projectData) {
    return dispatch => {
        dispatch(request());

        occupantServices.getAll(skip, limit, orderBy, order, sFields, sValues, isAutoComplete, projectData)
            .then(
                occupants => {
                    occupants.data && occupants.data.forEach(elem => {

                        let updatedAt = new Date(elem.updatedAt);

                        let modifiedDay = updatedAt.getDate() < 10 ? ('0' + updatedAt.getDate()) : updatedAt.getDate()

                        let modifiedMonth = updatedAt.getMonth() + 1 < 10 ? ('0' + (updatedAt.getMonth() + 1)) : updatedAt.getMonth() + 1

                        let modifiedHours = updatedAt.getHours() < 10 ? ('0' + (updatedAt.getHours())) : updatedAt.getHours()

                        let modifiedTime = updatedAt.getMinutes() < 10 ? ('0' + updatedAt.getMinutes()) : updatedAt.getMinutes();

                        let modifiedSeconds = updatedAt.getSeconds() < 10 ? ('0' + updatedAt.getSeconds()) : updatedAt.getSeconds();

                        let formatModifiedDate = `${modifiedMonth}/${modifiedDay}/${updatedAt.getFullYear()}, ${modifiedHours}:${modifiedTime}:${modifiedSeconds}`;
                        elem.updatedAt = formatModifiedDate;
                    })
                    dispatch(success(occupants));
                    const { page } = occupants;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: occupantsConstants.GETALL_REQUEST } }
    function success(data) { return { type: occupantsConstants.GETALL_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.GETALL_FAILURE, error } }
}


/**
 *Get single occupants detail by using occupant id
 *
 * @param {*} id - occupant_id
 * @param {*} reportYear_id - reportYear_id
 * @returns
 */
function getOccupantsDetails(id, reportYear_id) {
    return dispatch => {
        dispatch(request());

        occupantServices.getOccupantsDetails(id, reportYear_id)
            .then(
                data => dispatch(success(data.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: occupantsConstants.GET_OCCUPANTS_DETAILS_REQUEST } }
    function success(data) { return { type: occupantsConstants.GET_OCCUPANTS_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.GET_OCCUPANTS_DETAILS_FAILURE, error } }
}

/**
 * A method to get occupant's House hold info details
 *
 * @param {*} id contains occupants id
 * @returns
 */
function updateOccupantsHHIDetails(id, formData, callBack = () => { }) {
    return dispatch => {
        dispatch(request());
        //Captalize firstName and Lastname
        let updatedMemDetails = [];
        if(formData && formData.table1HouseholdMemberDC){
        const mem = formData.table1HouseholdMemberDC[Symbol.iterator]();
        for(const m of mem){
            console.log(m,m.firstName)
            let fn = (typeof m.firstName) === 'string' ? m.firstName.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()):m.firstName;
            let ln = (typeof m.lastName) === 'string' && m.lastName!=='HHM' ? m.lastName.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()):m.lastName;
            let mi = (typeof m.middleInitial) === 'string' ? m.middleInitial.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()):m.middleInitial;
            
            updatedMemDetails.push({...m,firstName: fn,lastName : ln,middleInitial: mi})
        }
        }
        formData.table1HouseholdMemberDC = updatedMemDetails;
        occupantServices.updateOccupantsHHIDetails(id, formData)
            .then(
                data => {
                    dispatch(success(data.data));
                    dispatch(alertActions.success('Updated Successfully'));
                    callBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.UPDATE_OCCUPANTS_HHI_DETAILS_REQUEST } }
    function success(data) { return { type: occupantsConstants.UPDATE_OCCUPANTS_HHI_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.UPDATE_OCCUPANTS_HHI_DETAILS_FAILURE, error } }
}

/**
 * A method to get occupant's House hold income details
 *
 * @param {*} id contains occupants id
 * @returns
 */
function updateOccupantsHHINCOMEDetails(id, formData, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        occupantServices.updateOccupantsHHINCOMEDetails(id, formData)
            .then(
                data => {
                    console.log('&&&',data)
                    dispatch(success(data.data));
                    dispatch(alertActions.success('Updated Successfully'));
                    callBack();
                },
                error => {
                    console.log('@@@',error)
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.UPDATE_OCCUPANTS_HHINCOME_DETAILS_REQUEST } }
    function success(data) { return { type: occupantsConstants.UPDATE_OCCUPANTS_HHINCOME_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.UPDATE_OCCUPANTS_HHINCOME_DETAILS_FAILURE, error } }
}

/**
 * A method to get occupant's House hold income details
 *
 * @param {*} id contains occupants id
 * @returns
 */
function updateOccupantsMRD(id, formData,callBack = () => { }) {
    return dispatch => {
        dispatch(request());
        occupantServices.updateOccupantsMRD(id, formData)
            .then(
                data => {
                    // update table 1 validations
                    const { project_id, site_id, reportYear_id } = formData;
                    dispatch(validationActions.updateValidations(project_id, site_id, reportYear_id, 0));
                    setTimeout(() => {
                        dispatch(success(data.data));
                        dispatch(alertActions.success('Updated Successfully'));
                        callBack(data);
                        // refresh validation
                        dispatch(getOneValidationError(id, reportYear_id));
                    }, 1000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.UPDATE_OCCUPANTS_MRD_DETAILS_REQUEST } }
    function success(data) { return { type: occupantsConstants.UPDATE_OCCUPANTS_MRD_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.UPDATE_OCCUPANTS_MRD_DETAILS_FAILURE, error } }
}

/**
 * A memthod to update occupants details
 *
 * @param {*} id _id of table 1
 * @param {*} formData update fields objects
 */
function updateOccupantsGeneral(id, formData, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        occupantServices.updateOccupantsGeneral(id, formData)
            .then(
                data => {
                    dispatch(success(data.data));
                    dispatch(alertActions.success('Updated Successfully'));
                    callBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.UPDATE_OCCUPANTS_DETAILS_REQUEST } }
    function success(data) { return { type: occupantsConstants.UPDATE_OCCUPANTS_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.UPDATE_OCCUPANTS_DETAILS_FAILURE, error } }
}

/**
 * A memthod to get assoc pjt, sites and buildings
 *
 */
function getAssocPjtConfig(callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        occupantServices.getAssocPjtConfig()
            .then(
                data => {
                    dispatch(success(data.data));
                    callBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.GET_ASSOC_PROJECTS_CONFIG_REQUEST } }
    function success(config) { return { type: occupantsConstants.GET_ASSOC_PROJECTS_CONFIG_SUCCESS, config } }
    function failure(error) { return { type: occupantsConstants.GET_ASSOC_PROJECTS_CONFIG_FAILURE, error } }
}

/**
 * A action call method to get vacan / empty units
 *
 * @param {*} data object that contains { project_id, site_id, building_id - * }
 */
function getEmptyUnits(data) {
    return dispatch => {
        dispatch(request());

        occupantServices.getEmptyUnits(data)
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.GET_EMPTY_UNITS_REQUEST } }
    function success(units) { return { type: occupantsConstants.GET_EMPTY_UNITS_SUCCESS, units } }
    function failure(error) { return { type: occupantsConstants.GET_EMPTY_UNITS_FAILURE, error } }
}

/**
 * An action method to create / update (empty unit) occupatn general details
 *
 * @param {*} [unit_id=null]
 * @param {*} data
 */
function moveInOccupant(unit_id = null, formData, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        occupantServices.moveInOccupant(unit_id, formData)
            .then(
                data => {
                    dispatch(success(data.data));
                    dispatch(alertActions.success('Occupant Moved-in successfully'));
                    callBack(data);

                    // update table 1 validations
                    const { project_id, site_id } = formData;
                    dispatch(validationActions.updateValidations(project_id, site_id, 0, 0));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.MOVE_IN_OCCUPANT_REQUEST } }
    function success(data) { return { type: occupantsConstants.MOVE_IN_OCCUPANT_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.GET_EMPTY_UNITS_FAILURE, error } }
}

/**
 * A method to get vacant units in the site
 *
 * @param {*} site_id
 * @returns
 */
function getVacantUnit(site_id) {
    return dispatch => {
        dispatch(request());

        occupantServices.getVacantUnit(site_id)
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: occupantsConstants.GET_VACANT_UNITS_REQUEST } }
    function success(units) { return { type: occupantsConstants.GET_VACANT_UNITS_SUCCESS, units } }
    function failure(error) { return { type: occupantsConstants.GET_VACANT_UNITS_FAILURE, error } }
}

/**
 * An action method to create / update (empty unit) occupatn general details
 *
 * @param {*} [table_id =null]
 * @param {*} data
 */
function transferUnit(table_id = null, formData, bedroomSizeChanged, callBack = () => { }, errorCallback = () => { }) {

    console.log("------------ Table 1 Id ----------",table_id,bedroomSizeChanged)

    return dispatch => {
        dispatch(request());

        occupantServices.tranferUnit(table_id, formData)
            .then(
                data => {
                    dispatch(success(data.data));
                    dispatch(alertActions.success('Occupant Transfered successfully'));
                   
                    if (bedroomSizeChanged) {
                        dispatch(alertActions.success("As you have updated the Unit details, your Income and rent limit values are processing. You will be notified once Table 1 is updated successfully."))
                        incomeRentService.triggerIncomeRent(0,0,table_id,0,0,0).then(
                            data => {
                                dispatch(alertActions.success("Income and rent limit values are updated successfully in Table 1."));
                            }
                        )
                    }
                    
                    // update table 1 validations
                    const { site_id } = formData;
                    if (site_id) {
                        dispatch(validationActions.updateValidations(0, site_id, 0, 0));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear());
                        callBack(data);
                    }, 1000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                        errorCallback();
                    }, 1000);
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.TRANSFER_OCCUPANT_REQUEST } }
    function success(data) { return { type: occupantsConstants.TRANSFER_OCCUPANT_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.TRANSFER_OCCUPANT_FAILURE, error } }
}

/**
 * An action method to save new movein occupant details in reducer
 *
 * @param {*} [unit_id=null]
 * @param {*} data
 */
function clearOccupantInLocal(data) {
    return dispatch => {
        dispatch(save());
    };

    function save() { return { type: occupantsConstants.OCCUPANTS_CLEAR_TEMP_SAVE_MOVEIN } }
}

/**
 * An action method to save new movein occupant details in reducer
 *
 * @param {*} [unit_id=null]
 * @param {*} data
 */
function saveOccupantToLocal(data) {
    //Captalize firstName and Lastname
    try{
    let updatedMemDetails = [];
    if(data && data.table1HouseholdMemberDC){
    const mem = data.table1HouseholdMemberDC[Symbol.iterator]();
    for(const m of mem){
        let fn = (typeof m.firstName) === 'string' ? m.firstName.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toString().toUpperCase() + p2.toString().toLowerCase()):m.firstName;
        let ln = (typeof m.lastName) === 'string' && m.lastName!=='HHM' ? m.lastName.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toString().toUpperCase() + p2.toString().toLowerCase()):m.lastName;
        let mi = (typeof m.middleInitial) === 'string' ? m.middleInitial.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toString().toUpperCase() + p2.toString().toLowerCase()):m.middleInitial;
        
        updatedMemDetails.push({...m,firstName: fn,lastName : ln,middleInitial: mi})
    }
    data.table1HouseholdMemberDC = updatedMemDetails;
    }
    if(data.firstName){
        data.firstName = (typeof data.firstName) === 'string' ? data.firstName.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toString().toUpperCase() + p2.toString().toLowerCase()):data.firstName;
    }

    if(data.lastName){
        data.lastName = (typeof data.lastName) === 'string' &&  data.lastName!=='HHM' ? data.lastName.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toString().toUpperCase() + p2.toString().toLowerCase()):data.lastName;
    }

    if(data.middleInitial){
        data.middleInitial = (typeof data.middleInitial) === 'string' ? data.middleInitial.replace(/^(\w)(.+)/, (match, p1, p2) => p1.toString().toUpperCase() + p2.toString().toLowerCase()):data.middleInitial;
    }
}catch(err){
    console.log(err)
}

    return dispatch => {
        dispatch(save(data));
    };

    function save(data) { return { type: occupantsConstants.OCCUPANTS_TEMP_SAVE_MOVEIN, data } }
}

/**
 * An action method to get move in MRD data config
 *
 * @param {*} data
 */
function getMoveInConfig(site_id = 0, building_id = 0, unit_id = 0, data) {
    return dispatch => {
        dispatch(request());

        occupantServices.getMoveInConfig(site_id, building_id, unit_id, data)
            .then(
                rData => {
                    dispatch(success(rData.data));
                },
                error => {
                    alert(error.toString());
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.OCCUPANTS_GET_MOVEIN_CONFIG_REQUEST } }
    function success(rData) { return { type: occupantsConstants.OCCUPANTS_GET_MOVEIN_CONFIG_SUCCESS, data: rData } }
    function failure(error) { return { type: occupantsConstants.OCCUPANTS_GET_MOVEIN_CONFIG_FAILURE, error } }
}

/**
 * An action method to get occupant validation error
 *
 * @param {*} data
 */
function getOneValidationError(table1_id = null, reportYear_id = null) {

    return dispatch => {
        dispatch(request());

        occupantServices.getOneValidationError(table1_id, reportYear_id)
            .then(
                resData => {
                    dispatch(success(resData.data));
                },
                error => {
                    alert(error.toString());
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_REQUEST } }
    function success(data) { return { type: occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_FAILURE, error } }
}

function getOneClearValidationError() {
    return { type: occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_REQUEST }
}

function clearEmptyUnits() {
    return { type: occupantsConstants.CLEAR_EMPTY_UNITS }
}

/**
 * An action method to delete occupant
 *
 * @param {*} id table1 _id
 */
function deleteOccupant(id = 0, item, cb = () => { }) {

    return dispatch => {
        dispatch(request());

        occupantServices.deleteOccupant(id)
            .then(
                resData => {
                    dispatch(success(resData));
                    dispatch(alertActions.success("Occupant Delete successfully!"));

                    // update table 1 validations
                    if (resData.data) {
                        const { project_id, site_id } = resData.data;
                        dispatch(validationActions.updateValidations(project_id, site_id, 0, 0));
                    }

                    setTimeout(() => {
                        dispatch(alertActions.clear());
                        cb();
                    }, 100);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.OCCUPANTS_DELETE_ONE_REQUEST } }
    function success(data) { return { type: occupantsConstants.OCCUPANTS_DELETE_ONE_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.OCCUPANTS_DELETE_ONE_FAILURE, error } }
}

/**
 * An action method to funder details
 *
 * @param {*} project_id project_id
 */
function getFunders(project_id) {

    return dispatch => {
        dispatch(request());

        occupantServices.getFunderDetails(project_id)
            .then(
                resData => {
                    dispatch(success(resData));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: occupantsConstants.GET_FUNDER_DETAILS_REQUEST } }
    function success(data) { return { type: occupantsConstants.GET_FUNDER_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: occupantsConstants.GET_FUNDER_DETAILS_FAILURE, error } }
}

/**
 * An action method to update IR
 * @param {*} project_id 
 * @param {*} site_id 
 * @param {*} building_id 
 * @param {*} table1_id 
 * @param {*} reportYear_id 
 * @returns 
 */
function updateIncomeRent(data) {
    return dispatch => {
        occupantServices.updateIncomeRent(data)
            .then(
                resData => { },
                error => { }
            );
    };
}

function showLoading(){
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: occupantsConstants.OCCUPANTS_SHOW_LOADING } }
}

function hideLoading(){
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: occupantsConstants.OCCUPANTS_HIDE_LOADING } }
}
