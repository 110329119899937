export const UAConstants = {

    // UA config data
    GET_UA_CONFIG_DATAS_REQUEST: 'UA_CONFIG_DATAS_GET_REQUEST',
    GET_UA_CONFIG_DATAS_SUCCESS: 'UA_CONFIG_DATAS_GET_SUCCESS',
    GET_UA_CONFIG_DATAS_FAILURE: 'UA_CONFIG_DATAS_GET_FAILURE',

    // UA listing
    GET_UA_DETAILS_REQUEST: 'UA_DETAILS_GET_REQUEST',
    GET_UA_DETAILS_SUCCESS: 'UA_DETAILS_GET_SUCCESS',
    GET_UA_DETAILS_FAILURE: 'UA_DETAILS_GET_FAILURE',

    // UA update
    UPDATE_UA_DETAILS_REQUEST: 'UA_DETAILS_UPDATE_REQUEST',
    UPDATE_UA_DETAILS_SUCCESS: 'UA_DETAILS_UPDATE_SUCCESS',
    UPDATE_UA_DETAILS_FAILURE: 'UA_DETAILS_UPDATE_FAILURE',

    // UA bulk data
    UA_BULK_UPDATE_REQUEST: 'UA_BULK_UPDATE_REQUEST',
    UA_BULK_UPDATE_SUCCESS: 'UA_BULK_UPDATE_SUCCESS',
    UA_BULK_UPDATE_FAILURE: 'UA_BULK_UPDATE_FAILURE',

    // monitering projects
    GET_MONITORING_PJTS_UA_REQUEST: 'GET_MONITORING_PJTS_UA_REQUEST',
    GET_MONITORING_PJTS_UA_SUCCESS: 'GET_MONITORING_PJTS_UA_SUCCESS',
    GET_MONITORING_PJTS_UA_FAILURE: 'GET_MONITORING_PJTS_UA_FAILURE',

}