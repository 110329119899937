export const UnitsConstants =
{
    // units listing
    GETALL_REQUEST: 'UNITS_GETALL_REQUEST',
    GETALL_SUCCESS: 'UNITS_GETALL_SUCCESS',
    GETALL_FAILURE: 'UNITS_GETALL_FAILURE',

    // set or reset filter
    CLEAR_FILTER_SET: 'UNITS_CLEAR_FILTER',
    CLEAR_FILTER_RESET: 'UNITS_CLEAR_FILTER_RESET',

    // create new unit
    ADD_UNIT_REQUEST: 'UNITS_ADD_REQUEST',
    ADD_UNIT_SUCCESS: ' UNITS_ADD_SUCCESS',
    ADD_UNIT_FAILURE: ' UNITS_ADD_FAILURE',

    // update unit
    UPDATE_UNIT_REQUEST: 'UNITS_UPDATE_REQUEST',
    UPDATE_UNIT_SUCCESS: ' UNITS_UPDATE_SUCCESS',
    UPDATE_UNIT_FAILURE: ' UNITS_UPDATE_FAILURE',

    // unit details
    GETONE_REQUEST: 'UNITS_GETONE_REQUEST',
    GETONE_SUCCESS: 'UNITS_GETONE_SUCCESS',
    GETONE_FAILURE: 'UNITS_GETONE_FAILURE',

    // delete units
    DELETE_REQUEST: 'UNITS_DELETE_REQUEST',
    DELETE_SUCCESS: 'UNITS_DELETE_SUCCESS',
    DELETE_FAILURE: 'UNITS_DELETE_FAILURE',
    
    // delete units msg
    DELETE_MSG_REQUEST: 'UNITS_DELETE_REQUEST_MSG',
    DELETE_MSG_SUCCESS: 'UNITS_DELETE_SUCCESS_MSG',
    DELETE_MSG_FAILURE: 'UNITS_DELETE_FAILURE_MSG',

    //To get Unit History
    GETONE_UH_REQUEST: 'UNITSHISTORY_GETONE_REQUEST',
    GETONE_UH_SUCCESS: 'UNITSHISTORY_GETONE_SUCCESS',
    GETONE_UH_FAILURE: 'UNITSHISTORY_GETONE_FAILURE',

    // to get buildings list for add popup 
    GET_BUILDINGS_FOR_SITE_REQUEST: 'GET_BUILDINGS_FOR_SITE_REQUEST',
    GET_BUILDINGS_FOR_SITE_SUCCESS: 'GET_BUILDINGS_FOR_SITE_SUCCESS',
    GET_BUILDINGS_FOR_SITE_FAILURE: 'GET_BUILDINGS_FOR_SITE_FAILURE',

    
    // get building name list
    GET_UNITSBULKLIST_REQUEST: 'GET_UNITSBULKLIST_REQUEST',
    GET_UNITSBULKLIST_SUCCESS: 'GET_UNITSBULKLIST_SUCCESS',
    GET_UNITSBULKLIST_FAILURE: 'GET_UNITSBULKLIST_FAILURE',

    // Buildings bulk update
    UNITS_BULKUPDATE_REQUEST: 'UNITS_BULKUPDATE_REQUEST',
    UNITS_BULKUPDATE_SUCCESS: 'UNITS_BULKUPDATE_SUCCESS',
    UNITS_BULKUPDATE_FAILURE: 'UNITS_BULKUPDATE_FAILURE',


    // Get unit table1 list
    GET_UNIT_TABLE1_REQUEST: 'GET_UNIT_TABLE1_REQUEST',
    GET_UNIT_TABLE1_SUCCESS: 'GET_UNIT_TABLE1_SUCCESS',
    GET_UNIT_TABLE1_FAILURE: 'GET_UNIT_TABLE1_FAILURE',
};