import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Constants
import { configurationConstants } from '../../../helpers';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextBox from '../../../components/TextBox/TextBox';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import { alertActions } from '../../../actions';
/**
 * Default datas
 */
const setasideData = {
    category_id: '',
    name: '',
    desc: '',
    pctValue: ''
};
/**
 * EditableTextValidation component
 * @class EditableTextValidation
 * @extends { Component}
 * @memberof SetAsideTypes
 */
class EditableTextValidation extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-value-' + rowIndex}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * EditableText component
 * @class EditableText
 * @extends { Component}
 * @memberof SetAsideTypes
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-value-' + rowIndex}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * EditableAutocomplete component
 * @class EditableAutocomplete
 * @extends { Component}
 * @memberof SetAsideTypes
 */
class EditableAutocomplete extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config } = this.props;
        return (
            <div className="autocompleteList">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={config}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value || null);
                    }}
                    // className={'textBox--bordered'}
                    selectedValue={{ id: data[fieldName] }}
                    validators={['required']}
                    errorMessages={['this field is required']}
                > 
                </AutoComplete>
            </div>

        )
    }
};

const componentMap = {
    name: EditableTextValidation,
    desc: EditableText,
    category_id: EditableAutocomplete,
    pctValue: EditableText
}
/**
 * SetAsideTypes Containers
 * @class SetAsideTypes
 * @extends { Component}
 */
class SetAsideTypes extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            setasidetypes: [],
            saved: false
        }
    }
    /**
     * Component will Mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.setAsideType);
        this.props.getConfiguration(configurationConstants.setAsideCategory);
    }
    /**
     * Componet will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { setAsideType } = nextProps;

        this.setState({
            saved: true,
            setasidetypes: setAsideType.filter(s => s.category_id !== 6)
        })
    }
    /**
     * Handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate setAsidetype names
        let setasidetypes = this.state.setasidetypes;
        let duplicateName1=''
        let duplicateName2=''
        let duplicateCatergory1=''
        let duplucateCategory2=''
        let setasidetypesNames = setasidetypes.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicatesetasidetypesNames = setasidetypesNames.some(function(item, idx){ 
                    if(setasidetypesNames.indexOf(item) !== idx){
                        duplicateName1=setasidetypes[setasidetypesNames.indexOf(item)].name;
                        duplicateCatergory1=setasidetypes[setasidetypesNames.indexOf(item)].category_id;
                        duplicateName2=setasidetypes[idx].name;
                        duplucateCategory2=setasidetypes[idx].category_id;
                        return item
                    }
            });
        if((duplicateName1.trim()===duplicateName2.trim())&&(duplicateCatergory1===duplucateCategory2)){
            this.props.erroralert(`${duplicateName1.toUpperCase()} with same set aside type category already exists`);
        }else{
        this.props.updatedata(configurationConstants.setAsideType, this.state.setasidetypes);
        }
    }
    /**
     * Cell component on change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { setasidetypes } = this.state;
        setasidetypes[rowIndex][fieldName] = value;
        this.setState({ setasidetypes });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { setasidetypes } = this.state;
        // [1]
        const { height, isLoading, setAsideCategory } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="Setasidetypes"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'SET ASIDE TYPES'}
                        headerCols={['NAME', 'DESCRIPTION', 'CATEGORY', '%']}
                        requiredFields={['NAME', 'CATEGORY']}
                        fieldCols={['name', 'desc', 'category_id', 'pctValue']}
                        rowDatas={setasidetypes}
                        style={style}
                        staticHeader={true}
                        height={height}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        config={[...setAsideCategory]}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (setasidetypes.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    setasidetypes: [
                                        {
                                            ...setasideData,
                                            key: Math.max(...setasidetypes.map(elt => elt.key)) + 1,
                                            type: configurationConstants.setAsideType
                                        },
                                        ...setasidetypes
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            if (item && item._id !== undefined)
                                this.props.deleteConfig(item.type, item._id);
                            else {
                                let changedArray = setasidetypes.filter(i => i._id);
                                this.setState({ setasidetypes: changedArray });
                            }
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.setAsideType) }}
                />
            </ValidatorForm>
        );
    }
}
/**
 * Props types
 */
SetAsideTypes.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    let setAsideCategory = configuration.configurations[configurationConstants.setAsideCategory] || [];
    setAsideCategory = setAsideCategory.filter(s => s.key !== 6);
    return {
        setAsideType: configuration.configurations[configurationConstants.setAsideType] || [],
        setAsideCategory,
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        getConfiguration: (setAsideCategory) => dispatch(confActions.getConfiguration(setAsideCategory)),
        getByName: (setAsideType) => dispatch(confActions.getByName(setAsideType)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) => dispatch(alertActions.error(msg))
    }
};
/**
 * Export SetAsideTypes
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(SetAsideTypes));
