import { alertConstants } from '../constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'success',
                message: action.message
            };
        case alertConstants.ERROR:
            let error_msg = action.message;
            let redirect = false;
            if (error_msg.includes('SyntaxError') || error_msg.includes('TypeError') || error_msg.includes('Failed to fetch') || error_msg.includes('Fatal')) {
                error_msg = 'Please check internet connection.'
            }
            //handle direction based on inactivity
            if (error_msg.includes('inactivity')) {
                redirect = true;
            }
            return {
                type: 'error',
                message: error_msg,
                redirect: redirect
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
}