import React, { Component} from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Item from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

//import Component
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import FileUpload from '../../components/FileUpload/FileUpload';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import PageLoader from '../../components/PageLoader/PageLoader';
import XMLEditor from "./XMLEditor";
import XMLEditorError from "./XMLEditorError";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions
import { uploadActions } from '../../actions/uploads';

// helpers
import { formatDate, getUser, history, globalVariable, xmlFormatter, authHeader, handleBulkResponse } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";
import { Save } from '@material-ui/icons';

// Imports Components
import ToolTip from '../../components/ToolTip/ToolTip';

// SVG Icons
import { XmlCopyIcon, XmlFolderIcon, DownloadIcon, XmlSettingsIcon, FilterIcon, HelpIcon, XmlWarningIcon, XmlZoomInIcon, XmlZoomOutIcon, PlusIcon, XmlIcon, SaveIcon } from '../../components/SvgIcons/SvgIcons';
import { helpActions } from '../../actions';

/**
 * Table1Import container
 * @class Table1Import
 * @extends {Component}
 */
class Table1ImportSuccess extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        const { firstName, lastName, org, id } = getUser();
        this.state = {
            user_name: `${firstName} ${lastName}`,
            user_org: org ? org.name : '',
            user_id: id,
            importDate: formatDate(new Date().toISOString()),
            importYear: new Date().getFullYear(),
            importYears: [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2],
            data: null,
            file: null,
            startImported: false,
            label: 'Select XML file to upload',
            isLoading: false,
            xmlFormattedString: '',
            editorOpened: false,
            saveEditXml :false,
            fullScreenXmlEdittor :false,
            fullScreenXmlErrorEdittor:false,
            fullScreenFlag:false,
            editXmlFlag:true,
            openHelp:false
            // skipWarnings: false
        };
        
    }
   
    UNSAFE_componentWillMount() {
        this.props.getHelp();
    }
  
     listingpage = (e) => {
        history.push({
            pathname: `/table1-import-log`,
            state: {
                page: 'table1LogList',
                pageData: {}
            }
        });
     }
        
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }    
  
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { location: { state } } = this.props;
        
        let skipErrorFlag = state?.pageData?.skipErrorFlag;
        let { classes, importProcess: { isLoading, data }, pageAccess,helpData  } = this.props;
        let { user_name, user_org, importDate, importYear, importYears, file, startImported, label, xmlFormattedString, editorOpened,saveEditXml ,fullScreenXmlEdittor ,fullScreenXmlErrorEdittor,fullScreenFlag,editXmlFlag,openHelp} = this.state;
        data = data || {};
        // if (pageAccess.indexOf("SAVE") === -1 && pageAccess.length > 0) {
        //     return "NO ACCESS";
        // }
      
        if(data && data.errors){
            if(data.errors.length == 0 && editorOpened == true ){
               this.setsuccessfullFlag();
            }
        }  
      editorOpened = data && data.errors ? editorOpened : false;
      let pageTitle = !editorOpened ? "XML Editor" : file.name
       // let pageTitle = "XML Data Import";
       pageAccess.push("BACK","HELP")
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={pageTitle}
                needPadding={false}
                isSubPage={false}
                handleBackActionClick={()=>{history.goBack()}}
                isXMLSuccessPage={true}
                handleHelpActionClick={this.handleHelpActionClick}
            >
                    <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '80vh' }}
                    >

                    <Grid item xs={6}>
                        <XmlSettingsIcon/>
                       
                    </Grid>  
                    {/* <Grid item xs={6} style={{ fontWeight: 700,fontSize:"18px"}}>
                      { !skipErrorFlag && <div>Your Table 1 data uploaded successfully.</div> }
                     </Grid> */}

                    <Grid item xs={9} style={{ fontWeight: 700,fontSize:"18px",marginBottom:"1%"}}>
                      
                       { !skipErrorFlag ?
                         (
                            <>
                              <div>Your table 1 data uploaded successfully, and an email will be sent to you once the table 1</div>
                              <div style={{textAlign:'center'}}>is uploaded successfully</div>
                            </>
                         
                        ) :
                         (<>
                            <div>Your table 1 partial data upload is in progress, and an email will be sent to you once the table 1 is</div>
                            <div style={{textAlign:'center'}}>uploaded successfully.Please check the records after 40 mins.</div>
                         </>)
                       }

                    </Grid> 
                    <Grid item xs={8} style={{fontSize:"13px",padding:"2px",marginBotton:"3%"}}>
                     { !skipErrorFlag ? 
                        (<div> Note: Please do not upload again until you receive an email response to avoid conflict. (Estimated time – 40 mins) </div>) :
                        (<div> Note: Please do not upload again until you receive an email response to avoid conflict. (Estimated time – 40 mins) </div>) }
                    </Grid> 

                    <Grid item xs={3} style={{marginTop:"2%"}}>
                    <Button variant="contained" style={{ background: '#36aebe',color:'#fff',margin:'5px'}} onClick={() => { this.listingpage() }} >{"Back to list view"}</Button>    
                    </Grid>  

                    </Grid> 

                
                    {
                    openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="XML Import"
                    />
                }
            
           
            </ContentWrapper>
        );
    }
}
/** Porps types */
Table1ImportSuccess.propTypes = {
    classes: PropTypes.object.isRequired,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
}
/** Default props */
Table1ImportSuccess.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: [],
}
/**
 * Match state to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ bulkUploads, pageAccess,help }, ownProps) => {
    const helpData = help.data[0]
    return {
        importProcess: bulkUploads.table1,
        pageAccess: pageAccess['Table1ImportSuccess'] || [],
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return{
    getHelp : () => dispatch(helpActions.getAll({_id:25}))
    }
};
/** Export Components */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table1ImportSuccess));