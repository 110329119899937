import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import _ from "underscore";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
import ProfilePhoto from '../../assets/img/default_user.jpg';

// Import Actions
import { userActions, alertActions } from "../../actions";
import { confActions } from "../../actions/configuration";
import { orgActions } from "../../actions/organizations"

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import FileUpload from '../../components/FileUpload/FileUpload';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import AlertDialog from '../../components/AlertDialog/AlertDialog';

// import global validation requirements
import ValidatorForm, { FieldValidations } from "../../helpers/fieldValidations";

// Import Helpers
import { getUserRole, constants, getUserOrgId } from '../../helpers';
import { history, configurationConstants, userconstants, statusConstants } from '../../helpers';
/**
 * UsersEdit Component
 * @class UsersEdit
 * @extends {Component}
 */
class UsersEdit extends Component {
    /**
     * Init Constructor
     */
    constructor(props) {
        super(props);
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.state = {
            userId: '',
            firstName: '',
            lastName: '',
            org_id: this.props.orgId ? parseInt(this.props.orgId, 10) : this.userOrgId,
            orgAccess: '',
            isActive: 1,
            email: '',
            isReadOnly: 0,
            isReadOnlyHO: 0,
            userName: '',
            password: '',
            cPassword: '',
            title: '',
            role: '',
            roleHO: '',
            access: true,
            accessHO: true,
            phone: '',
            address: '',
            city: '',
            state: '',
            postalCode: '',
            imageData: null,
            inActiveUserId: null,
            imagePreview: null,
            totalProjects: 0,
            // isFunder: true,
            autoFillAddress: false,
            homeFlag: true,
            multiFlag: true,
            isReadOnlyOrgSelected: false,
            isDelete: false,
            accessChangeFlag:false,
            multiFamilyProjectNames:[],
            homeOwnershipProjectNames:[],
            accessChangeText:'',
            accessChangeTextTrimmed:'',
            isReadMoreClicked:false
        };
    }
    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        // Validation rule for 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });

        //Password validation rule
        ValidatorForm.addValidationRule('pwdRequired', (value) => {
            if (!this.props.users.getOne.data && value === '') {
                return false;
            }
            return true;
        });
    }

    /**
     * Get Selectd Organization
     */
    getSelectedOrg() {
        const { org_id } = this.state;
        const { userOrgId } = this;
        return org_id ? { id: org_id } : { id: userOrgId }
    }

    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        let page = 'organizationOrg';
        if (this.props.userId) {
            this.props.getById(this.props.userId, this.props.navFrom === "" ? 'users' : this.props.navFrom);
            this.props.getUserPicture(this.props.userId);
            page = 'usersEdit';
        } else if (this.props.orgId) {
            this.props.getByOrgId(this.props.orgId);
        }

        if (history.location.pathname.split("/")[2] === 'new' || history.location.pathname.split("/")[4] === 'new') {
            this.setState({ role: "" })
        }

        this.props.getAllOrg(page);
        // Get State and City
        this.props.getConfiguration(configurationConstants.state);
        this.props.getConfiguration(configurationConstants.cities);
    }

    /**
     * Component Will Receive Props Event
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { users } = nextProps;
        let { isReadOnlyOrgSelected } = this.state;
        if (users && users.getOne.data && nextProps.userId) {
            // update user image on navigate to my profile
            if (this.props.userId) {
                if (this.props.userId !== nextProps.userId) {
                    this.props.getById(nextProps.userId);
                    this.props.getUserPicture(nextProps.userId);
                }
            }
            // do not update again
            if (users.getOne.data._id !== this.state.userId && users.orgs && users.orgs.length > 0) {
                const getOne = users.getOne.data;
                const projectList = users.getOne.projectNames
                this.oldState = null;
                isReadOnlyOrgSelected = users.orgs.filter(elem => elem._id === getOne.org_id)[0].isReadOnlyOrg;
                this.setState({
                    userId: getOne._id,
                    firstName: getOne.firstName,
                    lastName: getOne.lastName,
                    org_id: getOne.org_id,
                    orgAccess: getOne.orgAccess,
                    title: getOne.title,
                    isActive: getOne.isActive === true ? 1 : 0,
                    email: getOne.email,
                    isReadOnly: getOne.isReadOnly === true ? 1 : 0,
                    isReadOnlyHO: getOne.homeOwnershipAccess && getOne.homeOwnershipAccess.isReadOnly === true ? 1 : 0,
                    userName: getOne.userName,
                    role: getOne.role === '' ? '' : getOne.role,
                    roleHO: _.isEmpty(getOne.homeOwnershipAccess) ? '' : getOne.homeOwnershipAccess.role,
                    access: getOne.access === '' ? '' : getOne.access,
                    accessHO: _.isEmpty(getOne.homeOwnershipAccess) ? '' : getOne.homeOwnershipAccess.access,
                    phone: getOne.phone,
                    address: getOne.address.line || '',
                    city: getOne.address.city || '',
                    state: getOne.address.state || '',
                    postalCode: getOne.address.postalCode || '',
                    password: '*******',
                    cPassword: '*******',
                    totalProjects: getOne.totalProjects,
                    isDelete: getOne.isDelete,
                    isReadOnlyOrgSelected: isReadOnlyOrgSelected,
                    multiFamilyProjectNames:projectList.multiProjectNames,
                    homeOwnershipProjectNames:projectList.hoProjectNames
                });
                // preserve the old state
                this.oldState = JSON.stringify(this.state);
            }
        }
        // if user created with in an org, then auto fill address fields
        let { orgId, userId } = this.props;
        if (!orgId) {
            orgId = this.userOrgId;
        }
        if (orgId && users.orgs.length > 0 && !this.state.autoFillAddress && userId === undefined) {
            let org = users.orgs.filter(uo => uo.value === parseInt(orgId, 10))[0] || null;
            isReadOnlyOrgSelected = org.isReadOnlyOrg;
            if (org) {
                const { line, city, state, postalCode } = org.address || { line: '', city: '', state: '', postalCode: '' };
                this.setState({
                    address: line,
                    city: city,
                    state: state,
                    postalCode: postalCode,
                    autoFillAddress: true,
                    orgAccess: org.organizationAccess,
                    isReadOnlyOrgSelected: isReadOnlyOrgSelected,
                    isReadOnly: isReadOnlyOrgSelected ? 1 : 0,
                    isReadOnlyHO: isReadOnlyOrgSelected ? 1 : 0
                });
            }
        }
    }

    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
    console.log("🚀 ~ file: UsersEdit.js:225 ~ UsersEdit ~ value:", value)
    console.log("🚀 ~ file: UsersEdit.js:225 ~ UsersEdit ~ name:", name)

        let { role, roleHO, isReadOnly, isReadOnlyHO, isReadOnlyOrgSelected, access, accessHO, multiFamilyProjectNames, homeOwnershipProjectNames} = this.state;
        //handled for no access scenario
        if(!accessHO){
            isReadOnlyHO=2
        }

        if(!access){
            isReadOnly=2
        }
        console.log("🚀 ~ file: UsersEdit.js:228 ~ UsersEdit ~ this.state:", this.state)
        let wbarsProjects = [];
        let homeProjects = [];
        let accessChangeText='';
        if(multiFamilyProjectNames!==null || multiFamilyProjectNames.length!==0){
            if(multiFamilyProjectNames[0]!==null){
                multiFamilyProjectNames.map(item=>
                    wbarsProjects.push(item)
                )
            }
        }

        if(homeOwnershipProjectNames!==null || homeOwnershipProjectNames.length!==0){
            if(homeOwnershipProjectNames[0]!==null)
            homeOwnershipProjectNames.map(item=>
                homeProjects.push(item)
            )
        }

        console.log("🚀 ~ file: UsersEdit.js:252 ~ UsersEdit ~ homeProjects:", homeProjects)
        console.log("🚀 ~ file: UsersEdit.js:254 ~ UsersEdit ~ wbarsProjects:", wbarsProjects)

        if(name==='access' && wbarsProjects.length>0){
        accessChangeText = `<p>User Access cannot be removed because they are associated with following projects, Please remove the user from associated project prior to making this change.</p>`;
        accessChangeText = accessChangeText + `<table style="border: 1px solid black;
        border-collapse: collapse;padding:20px;margin:auto"><thead><tr style="border: 1px solid black;
        border-collapse: collapse;padding:20px;margin:auto"><th style="border: 1px solid black;
        border-collapse: collapse;padding:20px;margin:auto">Associated Projects</th><td style="padding:10px">${wbarsProjects.join(", ")}</td></tr></thead></table>`;
        }

        if(name==='accessHO' && homeProjects.length>0){
            accessChangeText = `<p>User Access cannot be removed because they are associated with following projects, Please remove the user from associated project prior to making this change.</p>`;
            accessChangeText = accessChangeText + `<table style="border: 1px solid black;
            border-collapse: collapse;padding:20px;margin:auto"><thead><tr style="border: 1px solid black;
            border-collapse: collapse;padding:20px;margin:auto"><th style="border: 1px solid black;
            border-collapse: collapse;padding:20px;margin:auto">Associated Projects</th><td style="padding:10px">${homeProjects.join(", ")}</td></tr></thead></table>`;
        }

        if (isReadOnlyOrgSelected) {
            this.setState({ [name]: value });
        } else {
            //Multifamily access change
            if (name === "isReadOnly" && value === 1) {
                if (role === "Organization Admin" || role === "Onsite Manager") {
                    this.props.showErrorAlert(`A Read-Only user cannot be an ${role}!`);
                } else {
                    this.setState({ [name]: value })
                }
            } else if (name === "role" && (value === "Organization Admin" || value === "Onsite Manager")) {
                if (isReadOnly === 1) {
                    this.props.showErrorAlert(`A Read-Only user cannot be an ${value}!`);
                } else {
                    this.setState({ [name]: value })
                }
                //HomeOwnership role access change
            } else if (name === "isReadOnlyHO" && value === 1) {
                if (roleHO === "Organization Admin") {
                    this.props.showErrorAlert(`A Read-Only user cannot be an ${roleHO}!`);
                } else {
                    this.setState({ [name]: value })
                }
            } else if (name === "roleHO" && value === "Organization Admin") {
                if (isReadOnlyHO === 1) {
                    this.props.showErrorAlert(`A Read-Only user cannot be an ${value}!`);
                } else {
                    this.setState({ [name]: value })
                }
            } 
            else if(name === 'access' && wbarsProjects.length>0){
                if(access!==value){
                    this.setState({accessChangeFlag:true,accessChangeText,accessChangeTextTrimmed:accessChangeText})
                }
            }else if(name === 'accessHO' && homeProjects.length>0){
                if(accessHO!==value){
                    this.setState({accessChangeFlag:true,accessChangeText,accessChangeTextTrimmed:accessChangeText})
                }
            }
            else if(name === 'access' && value === false)
            {
                if(accessHO === false){
                    this.props.showErrorAlert(`User should have at least one of the accesses as Yes for at least one of the family.`);
                }else{
                    this.setState({ [name]: value,isReadOnly:2 })
                }
            }else if(name === 'accessHO' && value === false)
            {
                if(access === false){
                    this.props.showErrorAlert(`User should have at least one of the accesses as Yes for at least one of the family.`);
                }else{
                    this.setState({ [name]: value,isReadOnlyHO:0 })
                }
            } else {
                this.setState({ [name]: value,isReadOnly:0 })
            }
            // if (orgAccess === 1) {
            //     if (name === 'isReadOnly' && value === 1) {
            //         if (role) {
            //             this.props.showErrorAlert(`A Read-Only user cannot be an ${role}!`);
            //         } else this.setState({ [name]: value });
            //     } else if (name === 'role' && value !== "") {
            //         if (isReadOnly)
            //             this.props.showErrorAlert(`A Read-Only user cannot be an ${value}!`);
            //         else this.setState({ [name]: value });
            //     } else this.setState({ [name]: value });
            // } else if (orgAccess === 2) {
            //     if (name === 'isReadOnlyHO' && value === 1) {
            //         if (roleHO !== '') {
            //             this.props.showErrorAlert(`A Read-Only user cannot be an ${roleHO}!`);
            //         } else this.setState({ [name]: value });
            //     } else if (name === 'roleHO' && value !== "") {
            //         if (isReadOnlyHO)
            //             this.props.showErrorAlert(`A Read-Only user cannot be an ${roleHO === '' ? 'Organization Admin' : roleHO}!`);
            //         else this.setState({ [name]: value });
            //     } else this.setState({ [name]: value });
            // } else if (orgAccess === 3) {
            //     if ((name === 'isReadOnly' || name === 'isReadOnlyHO') && value === 1) {
            //         if (role !== '' || roleHO !== '') {
            //             //if readonly is selected and role is none or onsite show roleho
            //             this.props.showErrorAlert(`A Read-Only user cannot be an ${name === 'isReadOnlyHO' ? roleHO === '' ? role : roleHO : role === '' || (role === 'Onsite Manager' && roleHO === 'Organization Admin') || role.length === 1 ? roleHO : role}`);
            //         } else this.setState({ [name]: value });
            //     } else if ((name === 'role' || name === 'roleHO') && value !== "") {
            //         if (isReadOnly || isReadOnlyHO) {

            //             this.props.showErrorAlert(`A Read-Only user cannot be an ${value}!`);
            //         }
            //         else this.setState({ [name]: value });
            //     } else this.setState({ [name]: value });
            // }
        }
    };

    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        if (!this.state.isActive && this.state.totalProjects > 0) {
            this.setState({
                inActiveUserId: this.state.userId
            });
        } else {
            this._saveForm();
        }
    };

    /**
     * Save Form
     */
    _saveForm() {
        const { userRole } = this;
        const readOnlyUser = (userRole === constants.readOnlyFunderWithoutOA || userRole === constants.readOnlyFunderWithOAandHO || userRole === constants.readOnlyFunderWithOAandBoth
            || userRole === constants.readOnlyFunderWithOA || userRole === constants.readOnlyFunderWithOAandHO || userRole === constants.readOnlyFunderWithOAandBoth)
        if (readOnlyUser) {
            if ((this.state.role === "Organization Admin" || this.state.role === "Onsite Manager") && this.state.isReadOnly) {
                this.props.showErrorAlert(`A Read-Only user cannot be an ${this.state.role}!`);
                return false;
            }
            this.setState({
                isReadOnly: 1
            });
        }
        const generalData = { ...this.state, imageData: null, imagePreview: null };
        this.props.handleFormSaveActionClick(generalData, this.state.imageData);
        this.props.getById(this.state.userId);
        this.props.getUserPicture(this.state.userId);
    }

    /**
     * Handle AutoComplete Event
     * @param {*} selectedOption 
     */
    handleAutoCompolete = (selectedOption) => {
        if (selectedOption.address) {
            this.setState({
                org_id: selectedOption.value,
                address: selectedOption.address.line,
                city: selectedOption.address.city,
                state: selectedOption.address.state,
                postalCode: selectedOption.address.postalCode,
                homeFlag: selectedOption.organizationAccess === 1 ? false : true,
                multiFlag: selectedOption.organizationAccess === 2 ? false : true,
                isReadOnly: (selectedOption.organizationAccess === 2 || selectedOption.organizationAccess === 3) && selectedOption.isReadOnlyOrg ? 1 : 0,
                isReadOnlyHO: (selectedOption.organizationAccess === 1 || selectedOption.organizationAccess === 3) && selectedOption.isReadOnlyOrg ? 1 : 0,
                isReadOnlyOrgSelected: selectedOption.isReadOnlyOrg
            });
        } else {
            this.setState({
                org_id: '',
                address: '',
                city: '',
                state: '',
                postalCode: '',
                homeFlag: true,
                multiFlag: true,
                isReadOnly: 0,
                isReadOnlyHO: 0,
                isReadOnlyOrgSelected: false
            });
        }
    }

    /**
     * Handle AutoComplete Event
     */
    _handleAutoComplete = (selectedOption) => {
        this.setState({ city: selectedOption.value });
    }

    /**
     * Handle File Upload
     */
    handleFileUpload = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length === 0 && acceptedFiles.length === 1) {
            const data = new FormData();
            let file = acceptedFiles[0];
            file = Object.assign(file, { preview: URL.createObjectURL(file) });
            data.append('file', file);
            data.append('name', file.name);
            this.setState({ imageData: data, imagePreview: file.preview });
        }
    }

    /**
     * Handle AutoComplete State
     */
    handleAutoCompleteState = (selectedOption) => {
        this.setState({ state: selectedOption.value });
    }

    /**
     * Get Phone Validation
     * @param {*} phone 
     */
    getPhoneValidation(phone) {
        let str = phone || '';
        if (str.trim() !== '') {
            return {
                validators: [...FieldValidations.phone.validation],
                errorMessages: [...FieldValidations.phone.errorMsg]
            }
        }
        return {};
    }

    /**
     * Bind Html to DOM
     */
    render() {
        let { firstName, lastName, isActive, email, isReadOnly, isReadOnlyHO, userName, password, cPassword, title, role, roleHO, phone, address, city, state, postalCode, imagePreview, org_id, orgAccess, homeFlag, multiFlag, isReadOnlyOrgSelected, isDelete, access, accessHO } = this.state;
        let { classes, users, isReadOly, userImage, pageAccess, cities, states } = this.props;
        let userImageURL = imagePreview ? imagePreview : userImage;
        const { userRole, userOrgId } = this;
        const funder_Ids = [1, 2, 3, 4, 5, 6, 7, 8, 11].filter(item => item !== userOrgId);

        console.log('THIS>STATE',this.state)
        let superFunderReadOnly = false;

        // funder user
        const funderUser = (userRole === constants.superFunder || userRole === constants.superFunderWithHO ||
            userRole === constants.funderWithOA || userRole === constants.funderWithOAandBoth ||
            userRole === constants.funderWithOAandHO || userRole === constants.funderWithoutOA ||
            userRole === constants.funderWithoutOAandHO || userRole === constants.funderWithoutOAandBoth);

        // none user
        const noneUser = (userRole === constants.nonFunderWithoutOA || userRole === constants.nonFunderWithoutOAandHO || userRole === constants.nonFunderWithoutOAandBoth || userRole === constants.readOnlyFunderWithoutOA || userRole === constants.readOnlyFunderWithOAandHO || userRole === constants.readOnlyFunderWithOAandBoth);

        // read only 
        const readOnlyUser = (userRole === constants.readOnlyFunderWithoutOA || userRole === constants.readOnlyFunderWithOAandHO || userRole === constants.readOnlyFunderWithOAandBoth
            || userRole === constants.readOnlyFunderWithOA || userRole === constants.readOnlyFunderWithOAandHO || userRole === constants.readOnlyFunderWithOAandBoth)
        // read only OA user
        const readOnlyOA = (userRole === constants.readOnlyFunderWithOA || userRole === constants.readOnlyFunderWithOAandHO || userRole === constants.readOnlyFunderWithOAandBoth);

        // Access change
        const superaccess = (userRole === constants.superFunder || userRole === constants.superFunderWithHO)
            && (orgAccess === 3 && homeFlag === true && multiFlag === true)

        // OA user
        const OAUser = (userRole === constants.nonFunderWithOA || userRole === constants.nonFunderWithOAandHO || userRole === constants.nonFunderWithOAandBoth);
        // non-editable
        const otherFunder = pageAccess.indexOf('SAVE') === -1;

        // user edit orgName
        const sameOrg = users.getOne.data ? users.getOne.org_id === userOrgId ? false : true : false;
        const superFunderUserId = users.getOne.data ? users.getOne.data._id : null;

        if (userRole === constants.superFunder) {
            if (superFunderUserId === 17040) superFunderReadOnly = true;
        } else if (userRole === constants.superFunderWithHO) {
            if (superFunderUserId === 17039) superFunderReadOnly = true;
        }

        let activeStatus = true;
        if (funderUser) {
            activeStatus = false;
        } else if (OAUser) {
            activeStatus = false;
        } else if (readOnlyOA) {
            activeStatus = true;
        } else {
            activeStatus = true;
        }
        let userOrgs = [];
        switch (userRole) {
            case 'superFunder':
            case 'superFunderWithHO':
                userOrgs = users.orgs;
                break;
            case 'funderWithOA':
            case 'funderWithOAandHO':
            case 'funderWithOAandBoth':
            case 'funderWithoutOA':
            case 'funderWithoutOAandHO':
            case 'funderWithoutOAandBoth':
            case 'readOnlyFunderWithOA':
            case 'readOnlyFunderWithOAandHO':
            case 'readOnlyFunderWithOAandBoth':
            case 'readOnlyFunderWithoutOA':
            case 'readOnlyFunderWithoutOAandHO':
            case 'readOnlyFunderWithoutOAandBoth':
                if (!otherFunder)
                    userOrgs = users.orgs.filter(item => !funder_Ids.includes(item._id))
                else userOrgs = users.orgs;
                break;
            default: userOrgs = users.orgs;
                break;
        }
        if (orgAccess === 1) {
            userOrgs = userOrgs.filter(item => item.organizationAccess !== 2);
        } else if (orgAccess === 2) {
            userOrgs = userOrgs.filter(item => item.organizationAccess !== 1);
        }
        const isFromOrgContacts = history.location.pathname.includes('organizations')
        //handled for archive users
        isReadOly = isDelete ? true : isReadOly;
        //disble status for None funders
        let isFunderWithNone = userRole === 'funderWithoutOA' || userRole === 'funderWithoutOAandHO' || userRole === 'funderWithoutOAandBoth';
        //show only active orgs
        //org Active status change - CR change
        userOrgs = userOrgs.filter(item => item.isActive === true);
        
        let readOnlySuggestions = [
            {
                label: "Read/Write",
                value: 0
    
            },
            {
                label: "Read Only",
                value: 1
            }
        ];

        let readOnlySuggestionsHO = [
            {
                label: "Read/Write",
                value: 0
    
            },
            {
                label: "Read Only",
                value: 1
            }
        ]
        
        if(access===false ){
            readOnlySuggestions.push({
                label: "No",
                value: 2
            })

            isReadOnly=2
        }

        if(accessHO===false){
            readOnlySuggestionsHO.push({
                label: "No",
                value: 2
            })

            isReadOnlyHO=2
            
        }


        return (
            <div className={`${classes.rootEdit} usersEditRoot`}>
                <ValidatorForm
                    name="projectForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h6" className="section_title">General</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="firstName"
                                value={firstName.trimStart()}
                                label="First Name"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['This field is required']}
                                disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="lastName"
                                value={lastName.trimStart()}
                                label="Last Name"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['This field is required']}
                                disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <AutoComplete
                                name="organizationName"
                                placeholder='Organization Name'
                                className="autocomplete-fixeds"
                                suggestions={userOrgs}
                                handleAutoCompolete={this.handleAutoCompolete}
                                selectedValue={{ value: org_id, id: org_id }}
                                disabled={isFromOrgContacts || isReadOly || !funderUser || sameOrg || superFunderReadOnly}
                                validators={['required']}
                                errorMessages={['This field is required']}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                            <AutoComplete
                                name="isActive"
                                placeholder='Status'
                                suggestions={statusConstants}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        isActive: selectedOption.value
                                    });
                                }}
                                className="autocomplete-fixedsStatus"
                                selectedValue={{ id: isActive }}
                                margin="dense"
                                disabled={isFunderWithNone ? true : (isReadOly || activeStatus || superFunderReadOnly)}
                                defaultValue='Active'
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="email"
                                value={email}
                                label="Email Id"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required', 'isEmail']}
                                errorMessages={['this field is required', 'email is not valid']}
                                disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                            />

                        </Grid>

                        {((isReadOly === false && !superFunderReadOnly) || (isDelete)) &&
                            <React.Fragment>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="userName"
                                        value={userName.trimStart()}
                                        disabled={isReadOly}
                                        label="User Id"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="password"
                                        value={password}
                                        type="password"
                                        label="Password"
                                        disabled={isReadOly}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        validators={["required", 'pwdRequired', ...FieldValidations.password.validation]}
                                        errorMessages={['this field is required', 'this field is required', ...FieldValidations.password.errorMsg]}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="cPassword"
                                        value={cPassword}
                                        type="password"
                                        label="Confirm Password"
                                        disabled={isReadOly}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        validators={["required", 'pwdRequired', 'isPasswordMatch']}
                                        errorMessages={['this field is required', 'this field is required', 'password mismatch']}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextBox
                                        name='title'
                                        label="Title"
                                        value={title}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextBox
                                        name='phone'
                                        label="Phone Number"
                                        value={phone}
                                        minLength={10}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                                        {...this.getPhoneValidation(phone)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBox
                                        name='address'
                                        label="Address"
                                        value={address}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                    <AutoComplete
                                        name={'city'}
                                        placeholder='City'
                                        suggestions={cities}
                                        className="autocomplete-fixedsCity"
                                        handleAutoCompolete={this._handleAutoComplete}
                                        selectedValue={{ value: city, id: city }}
                                        disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                    <AutoComplete
                                        suggestions={states}
                                        handleAutoCompolete={this.handleAutoCompleteState}
                                        selectedValue={{ value: state, id: state }}
                                        disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                                        placeholder="State"
                                        validators={[]}
                                        errorMessages={[]}
                                        className="userStateEdit"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextBox
                                        name={'postalCode'}
                                        label="Zip Code"
                                        value={postalCode}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={noneUser ? false : (isReadOly || superFunderReadOnly)}
                                    />
                                </Grid>

                                {orgAccess !== 2 && multiFlag &&
                                    <React.Fragment>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" className="section_title">Multi-Family Access</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                            <AutoComplete
                                                name="role"
                                                placeholder='Type'
                                                className="autocomplete-fixed"
                                                suggestions={pageAccess.indexOf('ORGANIZATIONTYPE_OA') ? userconstants.RoleOA : userconstants.Role}
                                                handleAutoCompolete={(selectedOption) => {
                                                    this.setState({
                                                        // role: selectedOption.value
                                                    }, () => {
                                                        // trigger validate option for RO user
                                                        this.handleChange('role', selectedOption.value);
                                                    });
                                                }}
                                                selectedValue={{ id: role }}
                                                margin="dense"
                                                disabled={isReadOly || noneUser || superFunderReadOnly}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                        <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                            <AutoComplete
                                                name="access"
                                                placeholder='Access'
                                                suggestions={userconstants.roleAccess}
                                                handleAutoCompolete={(selectedOption) => {
                                                    // check user role and access
                                                    this.handleChange('access', selectedOption.value);
                                                }}
                                                selectedValue={{ id: access }}
                                                defaultValue='No'
                                                margin="dense"
                                                className="autocomplete-fixed dotted"
                                                disabled={!superaccess}
                                            />

                                        </Grid>
                                        <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19}`}>
                                            <AutoComplete
                                                name="isReadOnly"
                                                placeholder='Read/Write Access'
                                                suggestions={readOnlySuggestions}
                                                className="multiAccessmarginFix"
                                                handleAutoCompolete={(selectedOption) => {
                                                    // check user role and access
                                                    this.handleChange('isReadOnly', selectedOption.value);
                                                }}
                                                selectedValue={readOnlyUser || isReadOnlyOrgSelected ? { id: 1 } : { id: isReadOnly }}
                                                defaultValue='Read/Write'
                                                margin="dense"
                                                disabled={isReadOly || noneUser || OAUser || readOnlyUser || isReadOnlyOrgSelected || superFunderReadOnly || isReadOnly===2}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                            />
                                        </Grid>
                                    </React.Fragment>

                                }

                                {orgAccess !== 1 && homeFlag &&
                                    <React.Fragment>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="h6" className="section_title">Homeownership Access</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                            <AutoComplete
                                                name="roleHO"
                                                placeholder='Type'
                                                className="autocomplete-fixed"
                                                suggestions={userconstants.RoleOAHO}
                                                handleAutoCompolete={(selectedOption) => {
                                                    this.setState({
                                                        isReadOly
                                                        // roleHO: selectedOption.value
                                                    }, () => {
                                                        // trigger validate option for RO user
                                                        this.handleChange('roleHO', selectedOption.value);
                                                    });
                                                }}
                                                selectedValue={{ id: roleHO }}
                                                margin="dense"
                                                disabled={isReadOly || noneUser || superFunderReadOnly}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                        <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                            <AutoComplete
                                                name="roleAccess"
                                                placeholder='Access'
                                                className={classes.textField}
                                                suggestions={userconstants.roleAccess}
                                                handleAutoCompolete={(selectedOption) => {
                                                    // check user role and access
                                                    this.handleChange('accessHO', selectedOption.value);
                                                }}
                                                selectedValue={{ id: accessHO }}
                                                defaultValue='No'
                                                margin="dense"
                                                disabled={!superaccess}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} className={`${classes.autoCompleteTop19} ${"autoCompleteTop"}`}>
                                            <AutoComplete
                                                name="isReadOnly"
                                                placeholder='Read/Write Access'
                                                suggestions={readOnlySuggestionsHO}
                                                handleAutoCompolete={(selectedOption) => {
                                                    // check user role and access
                                                    this.handleChange('isReadOnlyHO', selectedOption.value);
                                                }}
                                                selectedValue={readOnlyUser || isReadOnlyOrgSelected ? { id: 1 } : { id: isReadOnlyHO }}
                                                defaultValue='Read/Write'
                                                margin="dense"
                                                disabled={isReadOly || noneUser || OAUser || readOnlyUser || isReadOnlyOrgSelected || superFunderReadOnly || isReadOnlyHO===2}
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                            />

                                        </Grid>
                                    </React.Fragment>
                                }

                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} className='uploadfilegrid'>
                            <div className="profilephoto">
                                <FileUpload
                                    value={'Upload File'}
                                    disabled={isDelete ? true : (isReadOly || superFunderReadOnly)}
                                    handleFileUpload={this.handleFileUpload}
                                />
                                <img src={userImageURL} width="100%" alt={'Profile'} />
                            </div>
                        </Grid>


                    </Grid>
                    <Grid container spacing={3}>

                    </Grid>



                </ValidatorForm>
                {
                    !isReadOly &&
                    <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                        handleCancelButtonClick={() => { history.goBack() }} />
                }

                <DeleteDialog
                    open={this.state.inActiveUserId !== null}
                    content={<div dangerouslySetInnerHTML={{ __html: `A <b>User: ${this.state.firstName} ${this.state.lastName}</b> is associated with one or more active Projects. Please choose different contact in the associated Project record(s). ` }}></div>}
                    handleDeleteAction={() => {
                        this._saveForm();
                        this.setState({ inActiveUserId: null })
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ inActiveUserId: null })
                    }}
                />
                    <AlertDialog
                        open={this.state.accessChangeFlag}
                        title={'Warning:'}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({accessChangeFlag:false })
                            }


                            // history.goBack();
                        }}
                        onReadMore={()=>{
                            this.setState({isReadMoreClicked:!this.state.isReadMoreClicked})
                        }}
                        closeIconVisible={true}
                        saveVisible={false}
                        cancelVisible={false}
                        readMoreVisible={false}
                        readMoreText={this.state.accessChangeText.length<=350?false:this.state.isReadMoreClicked===false?"Read More":"Show Less"}
                        page='useralert'
                    >
                    <div dangerouslySetInnerHTML={{ __html: this.state.accessChangeTextTrimmed}}></div>
                    </AlertDialog>
                
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
UsersEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    userId: PropTypes.string,
    isReadOly: PropTypes.bool.isRequired,
    isFunderNonOA: PropTypes.bool,
    isLoggedInUser: PropTypes.bool,
    isSubPage: PropTypes.bool
};
/**
 * Default Props 
 */
UsersEdit.defaultProps = {
    pageAccess: [],
    isReadOly: true,
    isFunderNonOA: false,
    isLoggedInUser: false,
    isSubPage: false
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const stateUserImage = ownProps.userId ? state.users.image || ProfilePhoto : ProfilePhoto;
    return {
        users: state.users,
        organization: state.organizations,
        userImage: stateUserImage,
        states: state.configuration.configurations[configurationConstants.state] || [],
        cities: state.configuration.configurations[configurationConstants.cities] || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getById: (id, page) => dispatch(userActions.getById(id, page)),
        getAllOrg: (page) => dispatch(userActions.getOrganizations('', page)),
        getUserPicture: (id) => dispatch(userActions.getPicture(id)),
        uploadUserPicture: (id, formData) => dispatch(userActions.uploadPicture(id, formData)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getByOrgId: (id) => dispatch(orgActions.getById(id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(UsersEdit));