import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { TableHead, TableRow, TableCell, TableBody, Table } from '@material-ui/core';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

//Import Components
import LoanProjectMerge from "../../../components/MergeTool/LoanProjectMerge";

//Import Actions
import { mergeActions } from '../../../actions/mergeActions';
import { alertActions } from '../../../actions';

// DND
import { DragDropContext } from 'react-beautiful-dnd';

/**
 * a little function to help us with reordering the result
 * @param {*} list 
 * @param {*} startIndex 
 * @param {*} endIndex 
 * @returns 
 */
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
/**
 * Moves an item from one list to another list.
 * @param {*} source 
 * @param {*} destination 
 * @param {*} droppableSource 
 * @param {*} droppableDestination 
 * @returns 
 */
const move = (source, destination, droppableSource, droppableDestination, draggableId) => {
    const destClone = [...destination];
    const sourceFiltered = source.filter(s => s._id !== draggableId);
    const removed = source.filter(s => s._id === draggableId)[0];
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceFiltered;
    result[droppableDestination.droppableId] = destClone;
    return result;
};
/**
 * HTML Tooltip
 * @param {*} props 
 * @returns 
 */
function HTMLTooltip(props) {
    let header = ["Contract Number", "Contract Key"];
    let contractKey = props.contract_id ? props.contract_id : "No Contract key";
    let contractName = props.contracts ? props.contracts[0].contractNumber : "No Contract Name";
    return (
        <div className={"tooltipDnD"}>
            <Table>
                <TableHead className={'tooltiphead'}>
                    <TableRow>
                        {header.map((header, index) => {
                            return <TableCell >{header} </TableCell>
                        })}
                    </TableRow>
                </TableHead>

                <TableBody className={'tooltipbody'}>
                    <TableRow >
                        <TableCell > {contractName}</TableCell>
                        <TableCell > {contractKey}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}
/**
 * Buildings Component
 * @class Buildings
 * @extends {Component}
 */
class RecordNumber extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            project1: 0,
            project2: 0,
            site1: 0,
            site2: 0,
            site1Name: null,
            site2Name: null,
            building1: [],
            building2: [],
            building1Static: [],
            building2Static: [],
            draggedBuildings: []
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.props.clearMergeSelectionDate('buildingsTab')
        setTimeout(() => {
            this.props.getProjectsByName('');
        }, 100);
    }

    /**
     * Component Will Receive Props
     * @param {*} props 
     */
    UNSAFE_componentWillReceiveProps(props) {
        if (props.recordNumbers) {
            const { fromRecordNumber, toRecordNumber } = props.recordNumbers;

            let building1 = [...fromRecordNumber.recordNumber];
            let building2 = [...toRecordNumber.recordNumber];
            if (this.state.draggedBuildings.length) {
                this.setState({
                    building1: this.state.building1 ? [...this.state.building1] : [],
                    building2: this.state.building2 ? [...this.state.building2] : [],
                    building1Static: this.state.building1 ? [...this.state.building1] : [],
                    building2Static: this.state.building2 ? [...this.state.building2] : []
                });
            } else {
                this.setState({
                    building1: [...building1],
                    building2: [...building2],
                    building1Static: [...building1],
                    building2Static: [...building2]
                });
            }
        }
        //clear data once merge successful
        if(props.mergeSuccess===true){
            this.setState({
            tab: 0,
            project1: 0,
            project2: 0,
            site1: 0,
            site2: 0,
            site1Name: null,
            site2Name: null,
            building1: [],
            building2: [],
            building1Static: [],
            building2Static: [],
            draggedBuildings: []
        })
    }
    }

    id2List = {
        droppable: 'building1',
        droppable2: 'building2'
        // droppable2: 'filtered'
    };
    /**
     * Get list
     * @param {*} _id 
     * @returns 
     */
    getList = _id => this.state[`${this.id2List[_id]}Static`];
    /**
     * Handle Merge
     * @param {*} formData 
     */
    handleMerge = (formData) => {
        let buildingids = formData ? formData.filter(i => i.project_id === this.state.project1) : [];
        let buildingDetails = {
            project1: this.state.project1 ? this.state.project1 : null,
            project2: this.state.project2 ? this.state.project2 : null,
            site1: this.state.site1 ? this.state.site1 : null,
            site2: this.state.site2 ? this.state.site2 : null,
            site1Name: this.state.site1Name ? this.state.site1Name : null,
            site2Name: this.state.site2Name ? this.state.site2Name : null,
            buildingIds: buildingids.map(i => { return i._id }),
            contentMsg: `Do you want to migrate these Record Number(s) from contract <b>${this.state.site1Name}</b> to contract <b>${this.state.site2Name}</b>?`
        }
        setTimeout(() => {
            this.props.handleMergeClick(formData, buildingDetails);
        }, 500);
    }
    /**
     * Drag Search Handler
     * @param {*} event 
     * @param {*} droppableId 
     */
    DragsearchHandler(event, droppableId) {
        const updateOn = droppableId === 'droppable2' ? 'building2' : 'building1';
        let items = [...this.state[`${updateOn}Static`]];
        let search = event.target.value.toLowerCase();
        items = items.filter((el) => {
            let searchValue = el.name.toLowerCase();
            return searchValue.includes(search);
        });
        this.setState({
            [updateOn]: items,
        });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, projects, contracts: { fromContracts, toContracts }, currentTab, pageAccess } = this.props;
        const { project1, project2, site1, site2, building1, building2 } = this.state;
        let selectedColumns = [];
        return (
            <div className={classes.rootEdit}>
                <DragDropContext
                    onDragEnd={(result) => {
                        const { source, destination, draggableId } = result;
                        this.setState({ draggedBuildings: [...this.state.draggedBuildings, { source, destination, draggableId }] });

                        // dropped outside the list
                        if (!destination) {
                            return;
                        }

                        if (source.droppableId === destination.droppableId) {
                            const items = reorder(
                                this.getList(source.droppableId),
                                source.index,
                                destination.index
                            );
                            let state = { items };
                            if (source.droppableId === 'droppable2') {
                                state = { selected: items };
                            }
                            this.setState(state);
                        } else {
                            const res = move(
                                this.getList(source.droppableId),
                                this.getList(destination.droppableId),
                                source,
                                destination,
                                +draggableId
                            );

                            let sourceList = this.id2List[source.droppableId];
                            let destinationList = this.id2List[destination.droppableId];

                            this.setState({
                                items: res.droppable2,
                                selected: res.droppable2,
                                filtered: res.droppable2,
                                [sourceList]: res[source.droppableId],
                                [destinationList]: res[destination.droppableId]
                            });
                            if (!this.state.project2) {
                                this.props.showErrorAlert("Please select To project")
                            }
                            else {
                                this.handleMerge(res.droppable2);
                            }
                        }
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} >
                            <LoanProjectMerge
                                suggestions={projects}
                                onChange={(value) => {
                                    this.setState({
                                        project1: value,
                                        site1:null
                                    }, () => {
                                        this.props.getSitesByProjectId('fromContracts', value);
                                    });
                                }}
                                value={project1}
                                name={'projectName1'}
                                sitename={'SiteName1'}
                                onSiteChange={(sitevalue, selectedSite) => {
                                    if (sitevalue === this.state.site2) {
                                        this.props.showErrorAlert("Record Number(s) from same Contract Number are not allowed to merge")
                                    } else {
                                        this.setState({
                                            site1: sitevalue,
                                            site1Name: selectedSite.label,
                                            draggedBuildings: []
                                        }, () => {
                                            if (sitevalue != undefined) {
                                                setTimeout(() => {
                                                    this.props.getBuildings('fromRecordNumber', sitevalue);
                                                }, 100)
                                            }
                                        });
                                    }
                                }}
                                sitevalue={site1}
                                suggestions_sites={project1 ? fromContracts.contracts : []}
                                itemsBuildings={site1 ? building1 : []}
                                seleted={selectedColumns}
                                droppableId={'droppable'}
                                currentTab={currentTab}
                                pageAccess={pageAccess}
                                toolTipHtmlContent={(item) => {
                                    return <HTMLTooltip {...item} />
                                }}
                                DragsearchHandler={(event, name) => {
                                    this.DragsearchHandler(event, name)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <LoanProjectMerge
                                isDisableFirst={true}
                                disableProject={site2}
                                suggestions={projects}
                                value={project2}
                                name={'projectName2'}
                                onChange={(value) => {
                                    this.setState({
                                        project2: value,
                                        site2:null
                                    }, () => {
                                        this.props.getSitesByProjectId('toContracts', value);
                                    });

                                }}
                                sitevalue={site2}
                                sitename={'SiteName2'}
                                onSiteChange={(sitevalue, selectedSite) => {
                                    if (sitevalue === this.state.site1) {
                                        this.props.showErrorAlert("Record Number(s) from same Contract Number are not allowed to merge")
                                    }
                                    else {
                                        this.setState({
                                            site2: sitevalue,
                                            site2Name: selectedSite.label,
                                            draggedBuildings: []
                                        }, () => {
                                            if (sitevalue != undefined) {
                                                setTimeout(() => {
                                                    this.props.getBuildings('toRecordNumber', sitevalue);
                                                }, 100);
                                            }
                                        });
                                    }

                                }}
                                suggestions_sites={project2 ? toContracts.contracts : []}
                                itemsBuildings={site2 ? building2 : []}
                                selected={selectedColumns}
                                droppableId={'droppable2'}
                                currentTab={currentTab}
                                pageAccess={pageAccess}
                                toolTipHtmlContent={(item) => {
                                    return <HTMLTooltip {...item} />
                                }}
                                DragsearchHandler={(event, name) => {
                                    this.DragsearchHandler(event, name)
                                }}
                            />
                        </Grid>
                    </Grid>
                </DragDropContext>

            </div>
        )
    }
}
/**
 * Bind Proptypes 
 */
RecordNumber.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    handleMergeClick: PropTypes.func

};
/**
 * Default PropTypes
 */
RecordNumber.defaultProps = {
    pageAccess: [],
    currentTab: null,
    handleMergeClick: () => { }
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        projects: state.merge.loanProjects,
        contracts: state.merge.contractsTab,
        recordNumbers: state.merge.recordNumberTab
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        getProjectsByName: () => dispatch(mergeActions.getLoanProjectsByName()),
        getSitesByProjectId: (name, value) => dispatch(mergeActions.getContractsWithProjectID(name, value)),
        getBuildings: (name, value) => dispatch(mergeActions.getRecordNumbersWithContractID(name, value)),
        clearMergeSelectionDate: (tabName) => dispatch(mergeActions.clearMergeSelectionDate(tabName)),

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(RecordNumber));