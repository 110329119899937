import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Import Components
import OtherIncome from "./components/OtherIncome";
import Counties from "./components/Counties";
import ExpenseCategories from "./components/ExpenseCategories";
import HomeUnitOccupancy from "./components/HomeUnitOccupancy";
import Lenders from "./components/Lenders";
import Races from "./components/Races";
import MemberRaces from "./components/MemberRaces";
import Cities from "./components/Cities";
import SetAsideTypes from "./components/SetAsideTypes";
import SpecialNeedsOptions from "./components/SpecialNeedsOptions";
import States from "./components/States";
import UtilityAllowanceSources from "./components/UtilityAllowanceSources";
import UtilityAllowanceTypes from "./components/UtilityAllowanceTypes";
import Bedrooms from "./components/Bedrooms";
import SubsidyTypes from "./components/SubsidyTypes";
import HelpCategory from "./components/HelpCategory"
import UploadNew from "./components/Upload/Upload";
import UploadRentIncomeLimit from "./components/UploadRentIncomeLimit";
import FunderReportTable from "./components/FunderReportTable";
import BannerText from './components/BannerText/BannerText';
import MoveOutReason from './components/moveOutReason';

// Helpers
import { getConfigurationPageTableHeight } from '../../helpers/global'
import BulkExport from './components/BulkExport/BulkExport';

/**
 * ConfigurationsContent conainers
 * @class ConfigurationsContent\
 * @extends ConfigurationsContent
 */
class ConfigurationsContent extends Component {
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { match, location } = this.props;
        const height = getConfigurationPageTableHeight();
        return (
            <Switch>
                <Route exact path={`${match.url}`} render={(props) => {
                    return <Redirect to={`${match.url}/funderreporttable`} />
                }} />
                <Route exact path={`${match.url}/bedrooms`} render={(props) => {
                    return <Bedrooms {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/cities`} render={(props) => {
                    return <Cities {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/counties`} render={(props) => {
                    return <Counties {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/expense-categories`} render={(props) => {
                    return <ExpenseCategories {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/help-category-types`} render={(props) => {
                    return <HelpCategory {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/home-unit-occupancy`} render={(props) => {
                    return <HomeUnitOccupancy {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/lenders`} render={(props) => {
                    return <Lenders {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/other-income`} render={(props) => {
                    return <OtherIncome {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/races`} render={(props) => {
                    return <Races {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/member-races`} render={(props) => {
                    return <MemberRaces {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/subsidy`} render={(props) => {
                    return <SubsidyTypes {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/set-aside-types`} render={(props) => {
                    return <SetAsideTypes {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/special-needs-options`} render={(props) => {
                    return <SpecialNeedsOptions {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/states`} render={(props) => {
                    return <States {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/utility-allowance-sources`} render={(props) => {
                    return <UtilityAllowanceSources {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/utility-allowance-types`} render={(props) => {
                    return <UtilityAllowanceTypes {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/upload/:sub_page`} render={(props) => {
                    return <UploadNew {...props} height={height} page={props.match.params.sub_page} />
                }} />
                <Route exact path={`${match.url}/incomerent`} render={(props) => {
                    return <UploadRentIncomeLimit {...props} location={location} height={height} />
                }} />
                <Route exact path={`${match.url}/funderreporttable`} render={(props) => {
                    return <FunderReportTable {...props} height={height} />
                }} />
                <Route path={`${match.url}/banner-text`} render={(props) => {
                    return <BannerText {...props} height={height} />
                }} />
                 <Route path={`${match.url}/bulk-export`} render={(props) => {
                    return <BulkExport {...props} height={height} />
                }} />
                <Route path={`${match.url}/moveOutReason`} render={(props) => {
                    return <MoveOutReason {...props} height={height} />
                }} />
            </Switch>
        );
    }
}

export default ConfigurationsContent;
