import { mailTemplateConstants } from '../constants';

const initialState = {
    getOne: {
        data: null
    },
    isLoading: false,
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['name'],
            searchCols: []
        }
    },
    usersList: []
};

export function mailTemplates(state = initialState, action) {
    switch (action.type) {
        case mailTemplateConstants.GET_ALL_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                },
                isLoading: true,
            };
        case mailTemplateConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                ...action.templates,
                isLoading: false,
            };
        case mailTemplateConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case mailTemplateConstants.GET_ONE_REQUEST:
            return {
                ...state,
                getOne: {
                    data: null
                },
                isLoading: true
            };
        case mailTemplateConstants.GET_ONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.template.data
                },
                isLoading: false
            };
        case mailTemplateConstants.GET_ONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case mailTemplateConstants.UPDATE_MAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case mailTemplateConstants.UPDATE_MAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.template.data
                },
                isLoading: false
            };
        case mailTemplateConstants.UPDATE_MAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case mailTemplateConstants.GET_USERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case mailTemplateConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                usersList: {
                    data: action.template.data.users,
                    usersCount: action.template.data.usersCount
                },
                isLoading: false
            };
        case mailTemplateConstants.GET_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case mailTemplateConstants.ADD_MAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case mailTemplateConstants.ADD_MAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case mailTemplateConstants.ADD_MAIL_TEMPLATE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case mailTemplateConstants.DELETE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case mailTemplateConstants.DELETE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(template => template._id !== action.id),
                page: state.page,
                isLoading: false
            };
        case mailTemplateConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        default:
            return state
    }
}