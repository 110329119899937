import { formatDate } from './';
import moment from 'moment';
import { history } from '../helpers';

/**
 * Get Modified Sub-Title
 * @param {*} data 
 * @returns 
 */
const getModifiedSubTitle = (data) => {
    const { modifiedBy, updatedAt } = data || { modifiedBy: null, updatedAt: null };
    if (modifiedBy && updatedAt) {
        return `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>`
    } else {
        return '';
    }
}
/**
 * Get Last Updated date in the list
 * @param {*} arrayOfDetails 
 * @returns 
 */
const getLastUpdatedinList = (arrayOfDetails) => {
    let latstupdatedAt = arrayOfDetails.sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt));
    return getModifiedSubTitle(latstupdatedAt[0]) || ''
}

/**
 * Get Page Title
 * @param {*} pathname 
 * @param {*} state 
 * @param {*} pageName 
 * @returns 
 * 
 * [1]  -   replace integer to empty string
 * [2]  -   get needed modules title here
 */
export const getTitle = (pathname, state, pageName = null) => {
    // [1]
    const cPathname = pathname.replace(new RegExp("[0-9]", "g"), "");
    // get needed modules title here
    const {
        projects: { getOne: { data: projectData, funders: fundersList, sites: siteList },
            funderSettings: { basic },
            reportSettings: { data: reportData } },
        loanProjects: { getOne: { data: aloanProjectData } }
        ,
        archives: { getOne: { data: aProjectData },
            funderSettings: { aBasic },
             },
        sites: { getOne: { data: siteData } },
        buildings: { getOne: { data: buildingData } },
        units: { getOne: { data: unitData } },
        reportTables: { updatedModified: updatedModifiedDet },
        contract: { getOne: { projectName } },
        contract: { fullUpdateDetails },
        loanProjects: { getOne: { data: funderData } },
        contract: { data: contractListData },
        reportActivity: { certificationForm: { data: certificationData } },
        reportActivity: { contractActivityData: { data: contractActivityDatas} }
    } = state;
    let title = '';
    var url = history.location.pathname.split('/')
    var year = url[5]

    switch (cPathname) {
        case '/projects/':
        case '/projects//sites':
            title = projectData ? projectData.name : '';
            return {
                title: title,
                subTitle: siteList.data ? getLastUpdatedinList(siteList.data) : ''
            };
        case '/projects//reports':
            title = projectData ? projectData.name : '';
            return {
                title: title,
                subTitle: projectData ? getModifiedSubTitle(projectData) : ''
            };
        case '/loanProjects//contracts/new':
            title = projectName ? `${projectName} | NEW CONTRACT` : "";
            return {
                title: title,
                subTitle: projectData ? getModifiedSubTitle(projectData) : ''
            };
        case '/loanProjects//funders/':
            title = funderData ? `${funderData.name}` : "";
            return {
                title: title,
                subTitle: aloanProjectData ? getModifiedSubTitle(aloanProjectData) : ''
            };
        case '/loanProjects//reports':
            title = funderData ? `${funderData.name}` : "";
            return {
                title: title,
                subTitle: aloanProjectData ? getModifiedSubTitle(aloanProjectData) : ''
            };

        case '/loanProjects/':
            title = funderData ? `${funderData.name}` : "";
            return {
                title: title,
                subTitle: funderData ? getModifiedSubTitle(funderData) : ""
            };
        case '/loanProjects//contracts/':
            title = fullUpdateDetails && fullUpdateDetails[0] ? `  ${fullUpdateDetails[0].contractNumber} | ${fullUpdateDetails[0].projectName}` : '';
            return {
                title: title,
                subTitle: funderData ? getModifiedSubTitle(funderData) : ''
            };
        case '/loanProjects//contracts':
            title = contractListData && contractListData[0] ? contractListData[0].projectName : "";
            return {
                title: title,
                subTitle: funderData ? getModifiedSubTitle(funderData) : ''
            };



        case '/projects//funders':
            // title = projectData && basic ? `${basic.funder.abbrevation} | ${projectData.name}` : '';
            title = projectData ? `${projectData.name}` : '';
            return {
                title: title,
                // subTitle: lastUpdatedFunder ? getModifiedSubTitle(lastUpdatedFunder) : ''
                subTitle: fundersList.data ? getLastUpdatedinList(fundersList.data) : ''
            };
        case '/projects//funders/':
            title = projectData && basic ? `${basic.funder.abbrevation} | ${projectData.name}` : '';
            return {
                title: title,
                subTitle: basic ? getModifiedSubTitle(basic) : ''
            };
        case '/projects//funders/new':
            title = projectData ? `${projectData.name}` : '';
            return {
                title: title,
                // subTitle: basic ? getModifiedSubTitle(basic) : ''
            };
        case '/projects//sites/':
        case '/projects//sites//buildings':
            title = projectData && siteData ? `${siteData.name} | ${projectData.name}` : '';
            return {
                title: title,
                subTitle: siteData ? getModifiedSubTitle(siteData) : ''
            };

        case '/projects//sites//buildings/':
        case '/projects//sites//buildings//ua':
        case '/projects//sites//buildings//units':
        // case '/projects//sites//buildings//units/new':
            title = projectData && siteData && buildingData ? `${buildingData.name} | ${siteData.name} | ${projectData.name}` : '';
            return {
                title: title,
                subTitle: buildingData ? getModifiedSubTitle(buildingData) : ''
            };
        case '/projects//sites//buildings//units/':
        case '/projects//sites//buildings//units//unit-history':
            title = projectData && siteData && buildingData && unitData ?
                `${unitData.common_id} | ${buildingData.name} | ${siteData.name} | ${projectData.name}` : '';
            return {
                title: title,
                subTitle: unitData ? getModifiedSubTitle(unitData) : ''
            };
        case '/projects//reports/':
        case '/projects//reports//':
            title = projectData && reportData ? `Report - ${reportData.site.name} - ${reportData.year} | ${projectData.name}` : '';
            return {
                title: title,
                subTitle: reportData ? getModifiedSubTitle(reportData) : ''
            };
        // case '/loanprojects//reports/':
        case '/loanProjects//reports//':
            title = certificationData && certificationData[0] ? `Report - ${certificationData[0].contractNumber} - ${certificationData[0].year} | ${certificationData[0].projectName}` : '';
            return {
                title: title,
                subTitle: funderData ? getModifiedSubTitle(funderData) : ''
            };
        case '/loanProjects//reports///contractActivity':
        case '/loanProjects//reports///financialActivity':
        case '/loanProjects//reports///programActivity':
            title = funderData ? `Report - ${certificationData && certificationData[0]?certificationData[0].contractNumber:""} - ${year} | ${funderData.name}` : '';
            return {
                title: title,
                subTitle: funderData ? getModifiedSubTitle(funderData) : ''
            };
        case '/loanProjects//reports///contractActivity/':
            title = contractActivityDatas ? `${contractActivityDatas.loanNumber}- Record Details` : '';
            return {
                title: title,
                // subTitle: funderData ? getModifiedSubTitle(funderData) : ''
            };
            case '/loanProjects//reports///contractActivity/new':
            title = `Add Record Details` ;
            return {
                title: title,
                // subTitle: funderData ? getModifiedSubTitle(funderData) : ''
            };
        case '/projects//reports///table':
            title = projectData && siteData && reportData ? `Report - ${siteData.name} - ${reportData.year} | ${projectData.name}` : '';
            return {
                title: title,
                subTitle: updatedModifiedDet.table === "table1" ? getModifiedSubTitle(reportData) : getModifiedSubTitle(updatedModifiedDet)
            };
        case '/archives/':
            title = aProjectData ? aProjectData.name : '';
            return {
                title: title,
                subTitle: aProjectData ? getModifiedSubTitle(aProjectData) + ' [Archived]' : '[Archived]'
            };
        case '/archives//sites':
            title = aProjectData ? aProjectData.name : '';
            return {
                title: title,
                subTitle: siteList.data ? getLastUpdatedinList(siteList.data) + ' [Archived]' : '[Archived]'
            };
        case '/archives//reports':
            title = aProjectData ? aProjectData.name : '';
            return {
                title: title,
                subTitle: aProjectData ? getModifiedSubTitle(aProjectData) + ' [Archived]' : '[Archived]'
            };
        case '/archives//funders':
            // title = aProjectData && aBasic ? `${aBasic.funder.abbrevation} | ${aProjectData.name}` : '';
            title = aProjectData ? `${aProjectData.name}` : '';

            return {
                title: title,
                // subTitle: lastUpdatedFunder ? getModifiedSubTitle(lastUpdatedFunder) : ''
                subTitle: fundersList.data ? getLastUpdatedinList(fundersList.data) + ' [Archived]' : '[Archived]'
            };
        case '/archives//funders/':
            title = basic ? `${basic.funder.abbrevation}`: '';
            return {
                title: title,
                subTitle: aBasic ? getModifiedSubTitle(aBasic) + ' [Archived]' : '[Archived]'
            };
        case '/archives//funders/new':
            title = aProjectData ? `${aProjectData.name}` : '';
            return {
                title: title,
                // subTitle: aBasic ? getModifiedSubTitle(aBasic)  + ' [Archived]' : '[Archived]'
            };
        case '/archives//sites/':
        case '/archives//sites//buildings':
            title = aProjectData && siteData ? `${siteData.name} | ${aProjectData.name}` : '';
            return {
                title: title,
                subTitle: siteData ? getModifiedSubTitle(siteData) + ' [Archived]' : '[Archived]'
            };

        case '/archives//sites//buildings/':
        case '/archives//sites//buildings//ua':
        case '/archives//sites//buildings//units':
        case '/archives//sites//buildings//units/new':
            title = aProjectData && siteData && buildingData ? `${buildingData.name} | ${siteData.name} | ${aProjectData.name}` : '';
            return {
                title: title,
                subTitle: buildingData ? getModifiedSubTitle(buildingData) + ' [Archived]' : '[Archived]'
            };
        case '/archives//sites//buildings//units/':
        case '/archives//sites//buildings//units//unit-history':
            title = aProjectData && siteData && buildingData && unitData ?
                `${unitData.common_id} | ${buildingData.name} | ${siteData.name} | ${aProjectData.name}` : '';
            return {
                title: title,
                subTitle: unitData ? getModifiedSubTitle(unitData) + ' [Archived]' : '[Archived]'
            };
        case '/archives//reports/':
        case '/archives//reports//':
            title = aProjectData && reportData ? `Report - ${reportData.site.name} - ${reportData.year} | ${aProjectData.name}` : '';
            return {
                title: title,
                subTitle: reportData ? getModifiedSubTitle(reportData) + ' [Archived]' : '[Archived]'
            };
        case '/archives//reports///table':
            title = aProjectData && siteData && reportData ? `Report - ${siteData.name} - ${reportData.year} | ${aProjectData.name}` : '';
            return {
                title: title,
                subTitle: updatedModifiedDet.table === "table1" ? getModifiedSubTitle(reportData) + ' [Archived]' : getModifiedSubTitle(updatedModifiedDet) + ' [Archived]'
            };
        case '/sites/':
        case '/sites//buildings':
            const subTitle = siteData ? getModifiedSubTitle(siteData) : '';
            title = siteData ?
                `${siteData.name}` : '';
            return {
                title: title,
                subTitle
            };
        case '/sites//buildings/':
        case '/sites//buildings//ua':
        case '/sites//buildings//units':
            title = siteData && buildingData ? `${buildingData.name} | ${siteData.name}` : '';
            return {
                title: title,
                subTitle: buildingData ? getModifiedSubTitle(buildingData) : ''
            };
        case '/sites//buildings//units/':
        case '/sites//buildings//units//unit-history':
            title = siteData && buildingData && unitData ?
                `${unitData.common_id} | ${buildingData.name} | ${siteData.name}` : '';
            return {
                title: title,
                subTitle: unitData ? getModifiedSubTitle(unitData) : ''
            };
        case '/buildings/':
            title = buildingData ? `${buildingData.name}` : '';
            return {

                title: title,
                subTitle: buildingData ? getModifiedSubTitle(buildingData) : ''
            };
        // last changes
        case '/buildings//ua':
        case '/buildings//units':
            title = buildingData ? `${buildingData.name}` : '';
            return {
                title: title,
                subTitle: buildingData ? getModifiedSubTitle(buildingData) : ''
            };
        case '/buildings//units/':
        case '/buildings//units//unit-history':
            title = buildingData && unitData ?
                `${unitData.common_id} | ${buildingData.name}` : '';
            return {
                title: title,
                // subTitle: ''
                subTitle: unitData ? getModifiedSubTitle(unitData) : ''
            };
        case '/units/':
        case '/units//unit-history':
            title = unitData ?
                `${unitData.common_id}` : '';
            return {
                title: title,
                // subTitle: ''
                subTitle: unitData ? getModifiedSubTitle(unitData) : ''
            };
        default: return '';
    }
}