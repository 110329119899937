export const mailTemplateConstants = {

    // organization listing page
    GET_ALL_REQUEST: 'MAIL_TEMPLATE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'MAIL_TEMPLATE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'MAIL_TEMPLATE_GET_ALL_FAILURE',

    GET_USERS_REQUEST: 'MAIL_TEMPLATE_GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'MAIL_TEMPLATE_GET_USERS_SUCCESS',
    GET_USERS_FAILURE: 'MAIL_TEMPLATE_GET_USERS_FAILURE',

    // organization details page
    GET_ONE_REQUEST: 'MAIL_TEMPLATE_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'MAIL_TEMPLATE_GET_ONE_SUCCESS',
    GET_ONE_FAILURE: 'MAIL_TEMPLATE_GET_ONE_FAILURE',

    // organization contact
    GET_ORG_CONTACT_REQUEST: 'MAIL_TEMPLATE_GET_ORG_CONTACT_REQUEST',
    GET_ORG_CONTACT_SUCCESS: 'MAIL_TEMPLATE_GET_ORG_CONTACT_SUCCESS',
    GET_ORG_CONTACT_FAILURE: 'MAIL_TEMPLATE_GET_ORG_CONTACT_FAILURE',

    // create organization
    ADD_MAIL_TEMPLATE_REQUEST: 'MAIL_TEMPLATE_NEW_REQUEST',
    ADD_MAIL_TEMPLATE_SUCCESS: 'MAIL_TEMPLATE_NEW_SUCCESS',
    ADD_MAIL_TEMPLATE_FAILURE: 'MAIL_TEMPLATE_NEW_FAILURE',

    // updateorganization
    UPDATE_MAIL_TEMPLATE_REQUEST: 'MAIL_TEMPLATE_UPDATE_REQUEST',
    UPDATE_MAIL_TEMPLATE_SUCCESS: 'MAIL_TEMPLATE_UPDATE_SUCCESS',
    UPDATE_MAIL_TEMPLATE_FAILURE: 'MAIL_TEMPLATE_UPDATE_FAILURE',

    SEND_MAIL_TEMPLATE_REQUEST: 'MAIL_TEMPLATE_SEND_REQUEST',
    SEND_MAIL_TEMPLATE_SUCCESS: 'MAIL_TEMPLATE_SEND_SUCCESS',
    SEND_MAIL_TEMPLATE_FAILURE: 'MAIL_TEMPLATE_SEND_FAILURE',

    // delete organization
    DELETE_REQUEST: 'MAIL_TEMPLATE_DELETE_REQUEST',
    DELETE_SUCCESS: 'MAIL_TEMPLATE_DELETE_SUCCESS',
    DELETE_FAILURE: 'MAIL_TEMPLATE_DELETE_FAILURE',

    // active or deactive organization
    MAIL_TEMPLATE_UPDATE_REQUEST: 'MAIL_TEMPLATE_UPDATE_REQUEST',
    MAIL_TEMPLATE_UPDATE_SUCCESS: 'MAIL_TEMPLATE_UPDATE_SUCCESS',
    MAIL_TEMPLATE_UPDATE_FAILURE: 'MAIL_TEMPLATE_UPDATE_FAILURE',

    // status icon
    MAIL_TEMPLATE_STATUS_REQUEST: 'MAIL_TEMPLATE_STATUS_REQUEST',
    MAIL_TEMPLATE_STATUS_SUCCESS: 'MAIL_TEMPLATE_STATUS_SUCCESS',
    MAIL_TEMPLATE_STATUS_FAILURE: 'MAIL_TEMPLATE_STATUS_FAILURE',

    // set or reset filter
    CLEAR_FILTER_SET: 'MAIL_TEMPLATE_CLEAR_FILTER',
    CLEAR_FILTER_RESET: 'MAIL_TEMPLATE_CLEAR_FILTER_RESET'
};
