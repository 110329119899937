import React, { Component } from 'react';
import { connect } from "react-redux";
import propTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { Badge, Button } from '@material-ui/core';

// Import Custom Images
import Logo from '../../assets/img/WBARS-Logo.png';
import AvatarImage from '../../assets/img/default_user.jpg';

//Import Styles
import headerStyle from '../../assets/jss/components/header';

// Import Helper
import { getUserId, history, getUser, getUserRole, constants,globalVariable } from '../../helpers';
import { isPRODEnviroment, getDevEnviromentLabel } from '../../helpers/global';

// Import Components
import ToolTip from '../../components/ToolTip/ToolTip';
import { NotificationIcon, SearchIcon } from '../../components/SvgIcons/SvgIcons';

// Import Container
import GlobalSearch from '../../containers/GlobalSearch/GlobalSearch';

// Import Redux Actions 
import { notificationActions, userActions, helpActions } from '../../actions';

const dev_style = {
    position: 'absolute',
    left: '172px',
    top: '0px',
    fontWeight: 'bold',
    color: '#FFF',
    padding: '8px',
    zIndex: 100,
    fontSize: '26px'
}
/**
 * Header Container
 * @class HeaderContainer
 * @extends {Component}
 */
class HeaderContainer extends Component {
    state = {
        anchorEl: null,
        userId: getUserId()
    };
    /**
     * Component did mount
     */
    componentDidMount() {
        this.props.getMyPicture(getUserId());
        this.props.getHelp();
    }
    /**
     * Handle Menu Event
     * @param {*} event 
     */
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
        this.props.toggleDrawer(false);
    };
    /**Handle Close Event */
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    /**
     * Navigate to Profile Page
     */
    navigatePage = () => {
        this.setState({ anchorEl: null });
        history.push({
            pathname: `/users/${this.state.userId}`,
            state: {
                page: 'userEdit',
                pageData: {
                    user_id: parseInt(this.state.userId, 10)
                }
            }
        });
    }
    /**
     * toggleNotificationDrawer
     * @param {*} open 
     */
    toggleNotificationDrawer = (open) => {
        // !open && this.props.updateNotification()
        this.props.toggleNotificationDrawer(open)
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, avatar, drawer, userName, notificationDrawer, notReadNotifications } = this.props;
        const { anchorEl } = this.state;
        let isOtherFunder = getUserRole()==='nonFunderWithOA' ||  getUserRole()==='nonFunderWithOAandBoth' || getUserRole()==='nonFunderWithoutOAandBoth' || getUserRole()==='nonFunderWithoutOA' || getUserRole()==='onsite';
        this.userRole = getUserRole();
        const notifcationAvail = this.userRole === constants.funderWithOAandHO || this.userRole === constants.nonFunderWithOAandHO ||
            this.userRole === constants.funderWithoutOAandHO || this.userRole === constants.nonFunderWithoutOAandHO ||
            this.userRole === constants.readOnlyFunderWithOAandHO || this.userRole === constants.readOnlyFunderWithoutOAandHO
        const open = Boolean(anchorEl);
        return (
            <AppBar position="absolute" color="primary" className={classes.appBar}>
                <Toolbar variant="dense">
                    <img
                        src={Logo}
                        className="logo_img"
                        onClick={() => {
                            history.push({
                                pathname: `/dashboard`,
                                state: {
                                    page: "dashboard"
                                }
                            })
                        }}
                        alt="Logo" />

                    <Typography
                        className={classes.flex}
                        variant="subtitle1"
                        color="inherit">

                    </Typography>

                    <div className="toolbarRight">
                        <Button size="small" style={{ color: '#fff' }} startIcon={<Avatar alt="Profile Image" src={avatar} className={classes.avatar} />} onClick={this.handleMenu}>
                            <span style={{ fontSize: '1rem', textTransform: 'capitalize' }}>{userName}</span>
                        </Button>
                        {!notifcationAvail &&
                            <ToolTip title="Notifications" placement="bottom">
                                <IconButton className={`${classes.button} notification`} aria-label="Notifications"
                                    color='inherit'
                                    onClick={() => {
                                        this.toggleNotificationDrawer(notificationDrawer === true ? false : true)
                                    }}>
                                    <Badge color="secondary" badgeContent={notReadNotifications.length} invisible={notReadNotifications.length === 0}>
                                        <NotificationIcon />
                                    </Badge>
                                </IconButton>
                            </ToolTip>
                        }
                        <ToolTip title="Global Search" placement="bottom">
                            <IconButton className={classes.button} aria-label="Search"
                                color="inherit"
                                onClick={() => {
                                    this.props.toggleDrawer(drawer === true ? false : true)
                                }}>
                                <SearchIcon />
                            </IconButton>
                        </ToolTip>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={this.handleClose}
                        >
                            <MenuItem onClick={this.navigatePage}
                                button >My Profile</MenuItem>

                            <MenuItem onClick={() => {
                                this.handleClose();
                                this.props.logout();
                            }} button>Logout</MenuItem>
                        </Menu>
                    </div>

                </Toolbar>
                {/* //Add the Xml schema in the dashboard in Span tag */}
                {
                    !isPRODEnviroment() && (
                        <div style={dev_style}>{getDevEnviromentLabel()}</div>
                    )
                }
                {!history.location.pathname.includes('dashboardho') && isOtherFunder && <div 
                className="dashBoardSchema"
                >
                    Here is the latest 2023 XML schema to import Table 1. Click here to view &nbsp;
                <a
                    href={`${globalVariable.apiURL}/table1-documentation/wbar_table1_import__2023.xsd`}
                    target="_blank"
                    style={{ color: '#ffffff' }}
                    title={"WBARS - Schema - Revised on Nov 03 2023"}
                    rel="noopener noreferrer"
                >
                    Sample Schema
                </a>
                </div>}
            </AppBar> 
        )
    }
}
/** Default props */
GlobalSearch.defaultProps = {
    toggleDrawer: () => { },
    drawer: false
}
/** Props types */
GlobalSearch.propTypes = {
    drawer: propTypes.bool,
    toggleDrawer: propTypes.func
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ users, notification }, ownProps) => {
    const { firstName, lastName } = getUser() ? getUser() : {};
    return {
        avatar: users.myimage || AvatarImage,
        userName: firstName ? `Welcome ${firstName} ${lastName}` : "",
        notReadNotifications: notification.notificationList && notification.notificationList.filter(elem => elem.isRead === false)
    }
};
/**
 * Maps actions to props
* @param {*} dispatch
        */
const mapDispatchToProps = (dispatch) => {
    return {
        getMyPicture: (id) => dispatch(userActions.getMyPicture(id)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 25 })),
        logout: () => dispatch(userActions.logout()),
        getNotification: (userId) => dispatch(notificationActions.getByUserId(userId)),
        updateNotification: () => dispatch(notificationActions.updateNotification())
    }
};
/**
 * Export Container
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(headerStyle)(HeaderContainer));