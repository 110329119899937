import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Cpmponents
import TextBox from '../../../components/TextBox/TextBox';
import SaveCancel from "../../../components/SaveCancel/SaveCancel";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import NumberField from '../../../components/NumberField/NumberField';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import TableErrors from '../../../components/TableErrors/TableErrors';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextArea from '../../../components/TextArea/TextArea';

// Import Actions
import { reportTablesActions } from '../../../actions/reportTables';
import { confActions } from '../../../actions/configuration';
import { alertActions } from "../../../actions";

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helpers 
import { configurationConstants } from '../../../helpers/appConstants';
import ValidatorForm from "../../../helpers/fieldValidations";
import Table3Validations from '../../../helpers/TableValidations/Table3';
import { history, getSubmissionLevel, ReportLevelsConstants } from '../../../helpers';

// Table validations warning helper
import { hasWarnings, hasWarningsSection } from '../../../helpers/TableValidations/warnings';

const DEFAULT_CONFIG = {
    setAsideType: []
};

const fieldClass = {
    count: 'txt-right'
};
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextBox
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                disabled={isReadOly}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * EditableNumber Component
 * @class EditableNumber
 * @extends {Component}
 */
class EditableNumber extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={isReadOly === false ? ['gtEqZero'] : []}
                errorMessages={isReadOly === false ? ['Please enter a positive number'] : []}
                disabled={isReadOly}
                allowNegative={false}
                prefix={''}
                thousandSeparator={true}
                decimalScale={0}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * LabelText Component
 * @class LabelText
 * @extends {Component}
 */
class LabelText extends Component {
    render() {
        const { data, fieldName, config: { fielderrors } } = this.props;
        const labelText = data[fieldName];
        return (
            <span className={`${hasWarnings(fielderrors, `${data._id}`)}`}><label>{labelText}</label></span>
        )
    }
};

const componentMap = {
    desc: LabelText,
    count: EditableNumber,
    comment: EditableText
}
/**
 * Table3 Component
 * @class Table3
 * @extends {Component}
 */
class Table3 extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.state = {
            _id: null,
            setAsideType: [],
            fields: [],
            comments: '',
            config: {
                ...DEFAULT_CONFIG
            },
            homelessFamilyHeadofHousehold: '',
            homelessIndividualHeadofHousehold: '',
            reportYear_id: null,
            type: null,
            unsubmitComment: '',
            notes: '',
            table2: null,
            error: null,
            elderlyCount: 0,
            disabledCount: 0,
            sIdUnitCounts: {},
            created:false,
            desc: null
        }
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { reportId } = this.props;
        if (reportId) {
            // get the table 3 data
            this.props.getTable3Data(reportId);
        }
        // get setAsideType Config Values
        this.props.getConfiguration(configurationConstants.setAsideType);
        // get validation issues
        if (reportId) {
            this.props.validationIssues(0, 0, reportId, "3");
        }
         //positive number validaton
              ValidatorForm.addValidationRule('positive', (value) => {
                if (value < 0 ) {
                    return false;
                }
                return true;
            });
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, setAsideType } = nextProps;
        const id = getOne.data ? getOne.data._id : null;
        if (getOne.data && getOne.data.reportYearRes) {
            const desc =  getOne.data.reportYearRes.site_type;
            this.setState({
                desc
            })
        }

        if (getOne.data && setAsideType.length > 0 && (this.state._id === null || this.state._id !== id)) {
            // filter cat 3
            let filterCategory = setAsideType.filter(r => r.category_id === 3);
            // remove homeless
            filterCategory = filterCategory.filter(s => s.setAsideType_id !== 17 && s.setAsideType_id !== 18);
            let homelessIndividualHeadofHousehold = getOne.data.setAsideType.filter(s => s.setAsideType_id === 18)[0] || { count: 0 };
            let homelessFamilyHeadofHousehold = getOne.data.setAsideType.filter(s => s.setAsideType_id === 17)[0] || { count: 0 };
            this.setState({
                setAsideType: filterCategory.map((st) => {
                    const curData = getOne.data.setAsideType.filter(gs => gs.setAsideType_id === st.key)[0] || { count: 0, comment: '' };
                    return {
                        _id: st.key,
                        desc: st.name,
                        setAsideType_id: st.key,
                        count: curData.count === '' || curData.count === null ? 0 : curData.count,
                        comment: curData.comment,
                    }
                }),
                _id: getOne.data._id,
                reportYear_id: getOne.data.reportYear_id,
                totalHomelessUnits: getOne.data.totalHomelessUnits,
                totalSpecialNeedsUnits: getOne.data.totalSpecialNeedsUnits,
                comments: '',
                table1setAside: getOne.data.setAsideType,
                homelessIndividualHeadofHousehold: homelessIndividualHeadofHousehold.count,
                homelessFamilyHeadofHousehold: homelessFamilyHeadofHousehold.count,
                notes: getOne.data.notes,
                table2: getOne.data.table2,
                elderlyCount: getOne.data.elderlyCount,
                disabledCount: getOne.data.disabledCount,
                sIdUnitCounts: getOne.data.sIdUnitCounts
            })
        }
        this.setState({created:false})
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save Form
     * 
     * [1]  -   set up api form submission datas
     */
    _saveForm() {
        // [1]
        const { reportId } = this.props;
        const generalData = {
            setAsideType: [
                ...this.state.setAsideType,
                // 18
                {
                    setAsideType_id: 18,
                    count: this.state.homelessIndividualHeadofHousehold,
                    comment: '',
                    _id: 18
                },
                // 17
                {
                    setAsideType_id: 17,
                    count: this.state.homelessFamilyHeadofHousehold,
                    comment: '',
                    _id: 17
                }
            ],
            comment: this.state.comments,
            totalHomelessUnits: this.getTotalHomelessUnits(),
            totalSpecialNeedsUnits: this.getTotal(),
            reportYear_id: this.state.reportYear_id,
            notes: this.state.notes
        };
        if (this.state._id && reportId && !this.state.created) {
            // update table 3
            this.setState({created:true,totalHomelessUnits: this.getTotalHomelessUnits(),totalSpecialNeedsUnits: this.getTotal()})
            this.props.getTable3DataUpdate(this.state._id, generalData);
        } else {
            if(!this.state.created){
            // create table 3
            this.setState({created:true,totalHomelessUnits: this.getTotalHomelessUnits(),totalSpecialNeedsUnits: this.getTotal()})
            this.props.createTable3(generalData);
            }
        }
        this.props.getTable3Data(reportId)
    }
    /**
     * Cell Component On Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { setAsideType } = this.state;
        setAsideType[rowIndex][fieldName] = value;
        this.setState({ setAsideType });
    }

    getTotal = () => {
        return this.state.setAsideType.reduce((a, b) => a + parseFloat(b.count === '' || b.count === null ? 0 : b.count), 0);
    }

    getTotalHomelessUnits = () => {
        const {
            homelessFamilyHeadofHousehold,
            homelessIndividualHeadofHousehold
        } = this.state;
        return parseFloat(homelessFamilyHeadofHousehold ? homelessFamilyHeadofHousehold : 0) + parseFloat(homelessIndividualHeadofHousehold ? homelessIndividualHeadofHousehold : 0);
    }
    /**
     * Table on submit / unsubmit click
     * @param {*} type 
     * @returns 
     */
    handleSubmitActionClick = (type) => {
        // if not saved atleast once
        if (this.state._id === undefined) {
            return this.setState({
                error: {
                    message: 'Table 3 must be saved at least once before Submission'
                }
            });
        }
        // disable submit when has hardstop
        const { validations } = this.props;
        if (validations && type === 'SUBMIT') {
            if (validations.data.table3 === 3) {
                this.props.showErrorAlert('Need to fix Hard stop errors before submit!');
                return;
            } else if (!validations.data.isPrevSubmitted) {
                this.props.showErrorAlert('Previous Year Report Not Submitted!');
                return;
            } else { }
        }
        // if saved atleast once
        this.setState({
            type
        });
    }
    /**
     * Table 3 download action
     */
    handleDownloadActionClick = () => {
        const { getOne } = this.props;
        const reportData = {
            reportYear_id: getOne.data.reportYearRes._id,
            project_id: getOne.data.reportYearRes.project_id,
            site_id: getOne.data.reportYearRes.site_id,
            year: getOne.data.reportYearRes.year
        }
        this.props.downloadTable3Report(reportData.project_id, reportData.site_id, reportData.year)
    }

    /**
     * Pop Alert
     * @param {*} type 
     * @returns 
     */
    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess } = this.props;
        const isReadOly = pageAccess.indexOf('UNSUBMIT') === -1;
        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                disabled={isReadOly}
                                multiline={true}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }
    /**
     * Handle Submit Report
     */
    handleSubmitReport = () => {
        if (this.state._id) {
            this._submitReport();
        }
    }
    /**
     * Save Report
     */
    _submitReport = () => {
        const { type } = this.state;
        const { reportId } = this.props;
        if (reportId) {
            if (type === 'SUBMIT') {
                this.props.submitReport(reportId, { comment: this.state.comments });
                this.setState({
                    type: null
                });
            } else {
                if (this.state.unsubmitComment !== '') {
                    this.props.unSubmitReport(reportId, { comment: this.state.unsubmitComment });
                    this.setState({
                        type: null
                    });
                }
            }
        }
        this.setState({
            type: null,
            popupContent: null
        });
        this._saveForm();
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                className={`textBox--bordered txt-right input--disabled`}
                value={value}
                disabled={true}
                prefix={''}
                thousandSeparator={true}
                decimalScale={0}
            />
        )
    }
    /**
     * Scroll To Content
     * @param {*} content 
     */
    scrollToContent(content) {
        let sectionIndex = content.sectionIndex || 1;
        if (content.title) {
            switch (content.title) {
                case 'SpecialNeedsCountGreaterThanTable2TotalHouseholds':
                    sectionIndex = 1
                    break;
                case 'SpecialNeedNotMatch':
                    sectionIndex = 1
                    break;
                case 'HomelessCountGreaterThanTable2TotalHouseholds':
                    sectionIndex = 2
                    break;
                default:
                    sectionIndex = 1
                    break;
            }
        }
        switch (sectionIndex) {
            case 1:
                this.section1.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 2:
                this.section2.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                this.section1.current.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   get field level validations for table 3
     */
    render() {
        const { classes, getOne, validations } = this.props;
        let { pageAccess } = this.props;
        const { homelessFamilyHeadofHousehold, homelessIndividualHeadofHousehold, setAsideType, notes } = this.state;
        let total = this.getTotal().toFixed(0);
        let totalHomelessUnits = this.getTotalHomelessUnits().toFixed(0);
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        if (pageAccess.length === 0) return null; // wait for response
        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any Funder for this report year.'}</h4>;
        }
        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';
        // [1]
        const fielderrors = Table3Validations({ ...this.state, totalHomelessUnits, total });
        return (
            <React.Fragment>
                <ContentWrapper
                    classes={classes}
                    pageAccess={['REFRESH_VALIDATIONS', 'DOWNLOAD', ...pageAccess]}
                    title={levelInfo}
                    titleCss={'bolderText'}
                    pageName="reports"
                    validationContent={getOne.validations}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={true}
                    handleSubmitActionClick={() => this.handleSubmitActionClick('SUBMIT')}
                    handleUnSubmitActionClick={() => this.handleSubmitActionClick('UNSUBMIT')}
                    handleDownloadActionClick={() => this.handleDownloadActionClick()}
                    handleRefreshValidationsClick={() => {
                        const { reportId } = this.props;
                        this.props.refreshValidations(0, 0, reportId, "3");
                    }}
                    titleBg={'white'}
                >
                    <TableErrors
                        fielderrors={fielderrors || []}
                        data={getOne.data}
                        classes={{
                            action: classes.action,
                            message: classes.message,
                            root: classes.root
                        }}
                        validations={validations.data.table3Issues || []}
                        handleClickAction={(err) => {
                            this.scrollToContent(err)
                        }}
                    />
                    <ValidatorForm
                        name="Table3"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >
                        <div className={classes.rootEditSubPage}>
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={12}>
                                    <div ref={this.section1}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 1)}`}>1</span>
                                            Restricted Unit Special-Needs Population
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <EditableTable
                                        headerCols={['Type', 'Head of Household count ', 'Comments']}
                                        fieldCols={['desc', 'count', 'comment']}
                                        rowDatas={setAsideType}
                                        componentMap={componentMap}
                                        cellComponentOnChange={this.cellComponentOnChange}
                                        needTitle={false}
                                        config={{ isReadOly, fielderrors }}
                                        tableClass={'fieldWidth12'}
                                        footerDatas={[
                                            {
                                                'desc_footer': <span className={`${hasWarnings(fielderrors, 'sec1total')}`}><label>Total</label></span>,
                                                'count_footer': this.displayAmount(total)

                                            }
                                        ]}
                                    />
                                </Grid>

                                {/*Total Restricted Unit Households Served During the Year*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section2}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 2)}`}>2</span>
                                            Total Number of Homeless Households in Restricted Units
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="homelessFamilyHeadofHousehold"
                                        label="Homeless Family Head of Household served:"
                                        value={homelessFamilyHeadofHousehold}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="homelessIndividualHeadofHousehold"
                                        label="Homeless Individual Head of Household served:"
                                        value={homelessIndividualHeadofHousehold}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalHomelessUnits"
                                        label="Total:"
                                        value={totalHomelessUnits}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'totalHomelessUnits'}
                                    />
                                </Grid>

                                < Grid item xs={12} sm={12}>
                                    <Card className='projectsummary_card commentMargin'>
                                        <CardContent className="d_down_reports">
                                            <TextArea
                                                name="notes"
                                                label="Comments"
                                                value={notes || ''}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                multiline={true}
                                            />
                                        </CardContent>
                                    </Card>
                                </ Grid>
                            </Grid>
                            {
                                pageAccess.indexOf('SAVE') > -1 ? (
                                    <SaveCancel handleSaveButtonClick={() => {
                                        this.refs.form.submit();
                                    }}
                                        handleCancelButtonClick={() => { history.goBack() }} />)
                                    : null
                            }
                        </div>
                    </ValidatorForm>
                    <ValidatorForm ref="submitReport" onSubmit={this.handleSubmitReport}>
                        <AlertDialog
                            open={this.state.type !== null}
                            title={'Warning:'}
                            onSave={() => this.refs.submitReport.submit()}
                            onClose={() => {
                                this.setState({ type: null })
                            }}
                            saveText={'Submit'}
                        >
                            {this.popAlert(this.state.type)}
                        </AlertDialog>
                    </ValidatorForm>

                    {
                        this.state.error !== null && (
                            <AlertDialog
                                open={true}
                                title={'Error:'}
                                saveVisible={false}
                                onClose={() => {
                                    this.setState({ error: null })
                                }}
                                cancelText={'OK'}
                            >
                                {this.state.error.message}
                            </AlertDialog>
                        )
                    }

                </ContentWrapper>
            </React.Fragment>
        );
    }
}
/**
 * Bind Component Property Types
 */
Table3.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
/** Default Props */
Table3.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess, reportTables, configuration }, ownProps) => {
    return {
        pageAccess: pageAccess['table3'] || [],
        getOne: reportTables.table3,
        setAsideType: configuration.configurations[configurationConstants.setAsideType] || [],
        validations: { data: reportTables.validationIssues }
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable3Data: (id) => dispatch(reportTablesActions.getTable3Data(id)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getTable3DataUpdate: (id, data) => dispatch(reportTablesActions.getTable3DataUpdate(id, data)),
        createTable3: (data) => dispatch(reportTablesActions.createTable3(data)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 3)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 3)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        refreshValidations: (project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) => dispatch(reportTablesActions.refreshValidations(project_id, site_id, reportYear_id, reportTable)),
        downloadTable3Report: (project_id, site_id, year) => dispatch(reportTablesActions.downloadTable3Report(project_id, site_id, year))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table3));
