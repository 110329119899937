export const dashboardHOConstants = {

    // PSBU Counts
    PCL_COUNT_REQUEST: 'PCL_COUNT_REQUEST',
    PCL_COUNT_SUCCESS: 'PCL_COUNT_SUCCESS',
    PCL_COUNT_FAILURE: 'PCL_COUNT_FAILURE',

    // Users Summary
    USERSSUMMARY_HO_REQUEST: 'USERSSUMMARY_HO_REQUEST',
    USERSSUMMARY_HO_SUCCESS: 'USERSSUMMARY_HO_SUCCESS',
    USERSSUMMARY_HO_FAILURE: 'USERSSUMMARY_HO_FAILURE',

    // Dashboard Dropdown data
    GETALL_HO_PROJECTSUMMARY_REQUEST: 'DASHBOARD_GETALL_HO_PROJECTSUMMARY_REQUEST',
    GETALL_HO_PROJECTSUMMARY_SUCCESS: 'DASHBOARD_GETALL_HO_PROJECTSUMMARY_SUCCESS',
    GETALL_HO_PROJECTSUMMARY_FAILURE: 'DASHBOARD_GETALL_HO_PROJECTSUMMARY_FAILURE',
    GET_HO_STATUSREPORT_REQUEST:'DASHBOARD_GET_HO_STATUSREPORT_REQUEST',
    GET_HO_STATUSREPORT_SUCCESS:'DASHBOARD_GET_HO_STATUSREPORT_SUCCESS',
    GET_HO_STATUSREPORT_FAILURE:'DASHBOARD_GET_HO_STATUSREPORT_FAILURE',

    //download loan project report constant
    DOWNLOAD_HO_REPORT_REQUEST: 'DOWNLOAD_HO_REPORT_REQUEST',
    DOWNLOAD_HO_REPORT_SUCCESS: 'DOWNLOAD_HO_REPORT_SUCCESS',
    DOWNLOAD_HO_REPORT_FAILURE: 'DOWNLOAD_HO_REPORT_FAILURE',
    // Loan Rate
    GETALL_HO_OCCUPANCY_RATE_REQUEST: 'GETALL_HO_OCCUPANCY_RATE_REQUEST',
    GETALL_HO_OCCUPANCY_RATE_SUCCESS: 'GETALL_HO_OCCUPANCY_RATE_SUCCESS',
    GETALL_HO_OCCUPANCY_RATE_FAILURE: 'GETALL_HO_OCCUPANCY_RATE_FAILURE',

    // Dashboard Report status chart
    GETALL_HO_REPORT_STATUS_REQUEST: 'DASHBOARD_HO_REPORT_STATUS_REQUEST',
    GETALL_HO_REPORT_STATUS_SUCCESS: 'DASHBOARD_HO_REPORT_STATUS_SUCCESS',
    GETALL_HO_REPORT_STATUS_FAILURE: 'DASHBOARD_HO_REPORT_STATUS_FAILURE',

    // Dashboard Compliance Issue
    GETALL_HO_COMPLIANCE_ISSUE_REQUEST: 'GETALL_HO_COMPLIANCE_ISSUE_REQUEST',
    GETALL_HO_COMPLIANCE_ISSUE_SUCCESS: 'GETALL_HO_COMPLIANCE_ISSUE_SUCCESS',
    GETALL_HO_COMPLIANCE_ISSUE_FAILURE: 'GETALL_HO_COMPLIANCE_ISSUE_FAILURE',

    GET_PROJECT_LIST_HO_REQUEST: 'DASHBOARD_GET_PROJECT_LIST_HO_REQUEST',
    GET_PROJECT_LIST_HO_SUCCESS: 'DASHBOARD_GET_PROJECT_LIST_HO_SUCCESS',
    GET_PROJECT_LIST_HO_FAILURE: 'DASHBOARD_GET_PROJECT_LIST_HO_FAILURE',

    // Dashboard compliance issue chart
    GETALL_HO_COMPLIANCE_NEEDSACTION_REQUEST: 'DASHBOARD_HO_COMPLIANCE_NEEDSACTION_REQUEST',
    GETALL_HO_COMPLIANCE_NEEDSACTION_SUCCESS: 'DASHBOARD_HO_COMPLIANCE_NEEDSACTION_SUCCESS',
    GETALL_HO_COMPLIANCE_NEEDSACTION_FAILURE: 'DASHBOARD_HO_COMPLIANCE_NEEDSACTION_FAILURE',

    // Dashboard PM needs action list
    GET_HO_PROJECTSLIST_REQUEST: 'DASHBOARD_HO_PROJECTSLIST_REQUEST',
    GET_HO_PROJECTSLIST_SUCCESS: 'DASHBOARD_HO_PROJECTSLIST_SUCCESS',
    GET_HO_PROJECTSLIST_FAILURE: 'DASHBOARD_HO_PROJECTSLIST_FAILURE',
}