import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid} from '@material-ui/core';

// Styles
import dashboardStyle from '../../../assets/jss/containers/dashboard';

// Components
import {SitesIcon,CityIcon,BuildingsIcon, SvgLoadIcon, UnitsIcon } from '../../../components/SvgIcons/SvgIcons';

// Helpers
import { getUserType } from '../../../helpers';

// Icons
const icons = {
    'Total Projects': <CityIcon fill='#ffca62' width='20px' height='24px' />,
    'Total Sites': <SitesIcon fill='#668bff' width='20px' height='24px'/>,
    'Total Buildings': <BuildingsIcon fill=' #49e3d7' width='20px' height='24px' />,
    'Total Units': <UnitsIcon fill='#fea1b8' width='20px' height='24px' />
}
/**
 *PSBUCounts
 *
 * @class PSBUCounts
 * @extends {Component}
 */
class PSBUCounts extends Component {
    /**
     *Creates an instance of PSBUCounts.
     * @param {*} props
     * @memberof PSBUCounts
     */
    constructor(props) {
        super(props);
        this.state = {

        }
        this.userRole = getUserType();
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof PSBUCounts
     */
    render() {
        let { classes, PSBUCounts, isLoading, isPropertyManager } = this.props;
        let PSBUKeys = PSBUCounts ? Object.keys(PSBUCounts) : [];
        
        return (
            <Grid container spacing={2}>
                {
                    PSBUKeys.map((item, index) => {
                        return (
                            <Grid key={index} item xs={12} sm={isPropertyManager ? 6 : 3}>
                                <Card className={classes.PSBUCountCard}>
                                    <div className="text">
                                        <div className="count">
                                            {
                                                !isLoading ?
                                                    PSBUCounts[item] :
                                                    <div className='PSBULoader'>
                                                        <SvgLoadIcon className="greyLoader" />
                                                    </div>
                                            }
                                        </div>
                                        <div className="name">{item}</div>
                                    </div>
                                    <div className="icon">
                                        <div className={`${item.toLowerCase()}-icon`}>
                                            {icons[item]}
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        )
                    })
                }
            </Grid>

        )
    }
}
// prop types
PSBUCounts.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
PSBUCounts.defaultProps = {
    classes: {},
    PSBUCounts: {},
    isLoading: false
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        // PSBUCounts: state.dashboard.PSBUCounts && state.dashboard.PSBUCounts.data && state.dashboard.PSBUCounts.data
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook

    }
};
// export PSBUCounts
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(PSBUCounts));