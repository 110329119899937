import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserRole, roleAccess, getUserId, getUserOrgId } from '../../helpers';
import { history } from '../../helpers'
import { configurationConstants } from "../../helpers/appConstants";

// Import Actions
import { unitsActions } from "../../actions";
import { confActions } from "../../actions/configuration";

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import NumberField from '../../components/NumberField/NumberField';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import SaveCancel from '../../components/SaveCancel/SaveCancel';

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";
/**
 * UnitsEdit Component
 * @class UnitsEdit
 * @extends {Component}
 */
class UnitsEdit extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            unitid: '',
            common_id: '',
            otherid: '',
            sqft: '',
            bdrms: '',
            sensory: '',
            mobility: '',
            building_id: null,
            bedroom_id: '',
            prevBedroom_id: '',
            curtable1_id: '',

        };
        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.pageAccess = roleAccess[this.userRole]['unitview'].slice();
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        const { isArchived } = this.props;
        //handled for nav from archive
        if (!isNaN(this.props.unitid)) {
            this.props.getById(this.props.unitid, isArchived ? 'archives' : null);
        }else{
            this.props.getById(parseInt(history.location.pathname.split("/")[8]), isArchived ? 'archives' : null);
        }

        
        this.props.getConfiguration(configurationConstants.bedrooms);
    }
    /**
     * Component Will receive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { units, getOne } = nextProps;
        if (units && getOne.data && (this.props.unitid || parseInt(history.location.pathname.split("/")[8]) )) {
            if (getOne.data._id !== this.state.unitid) {
                // const getOne = units.getOne.data;

                // console.log("-------------- GET ONE DATA --------------",getOne.data)
                this.oldState = null;
                this.setState({
                    unitid: getOne.data._id,
                    common_id: getOne.data.common_id,
                    otherid: getOne.data.other_id,
                    sqft: getOne.data.squareFootage,
                    bedroom_id: getOne.data.bedroom_id,
                    prevBedroom_id: getOne.data.bedroom_id,
                    curtable1_id: getOne.data.curTable1_id
                });
                this.oldState = JSON.stringify(this.state);
            }
        }
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save Form
     */
    _saveForm() {
        // set up api form submission data

        this.props.getUnitByTable1List(this.state.curtable1_id);

        console.log("This get unit ny table1 list", this.props.units.unitTable1List)

        const generalData = {
            _id: this.state.unitid,
            common_id: this.state.common_id,
            other_id: this.state.otherid,
            squareFootage: this.state.sqft,
            building_id: this.state.building_id,
            bedroom_id: this.state.bedroom_id
        };

        const bedroomSizeChanged = ((this.state.bedroom_id != this.state.prevBedroom_id) && this.props.units.unitTable1List) ? true : false;

        this.props.handleFormSaveActionClick(generalData,this.state.curtable1_id,bedroomSizeChanged);
    }
    /**
     * Bind Html to DOM 
     */
    render() {
        let { classes, isReadOly, pageAccess, bedrooms } = this.props;
        const { otherid, sqft, common_id, bedroom_id } = this.state;
        let propOnly = pageAccess.indexOf('PROP_ONLY_FIELDS') !== -1;
        return (
            <div className={classes.rootEdit} >
                <ValidatorForm
                    name="unitForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >

                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="common_id"
                                value={common_id}
                                type={"text"}
                                label="Unit Id"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="otherid"
                                value={otherid}
                                label="Other Id"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={!propOnly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="sqft"
                                value={sqft}
                                label="Area Sq.ft"
                                className={`${classes.textField} buildeffectiveyearbuilt`}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                                prefix={''}
                                thousandSeparator={true}
                                decimalScale={0}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                name="bedroom_id"
                                placeholder='# BDRMS'
                                suggestions={bedrooms}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        bedroom_id: selectedOption.value
                                    })
                                }}
                                selectedValue={{ id: bedroom_id }}
                                disabled={isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                    </Grid>
                    {
                        pageAccess.indexOf('SAVE') > -1 || propOnly ? (
                            <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }} />
                        ) : null
                    }
                </ValidatorForm>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
UnitsEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired,
    isSubPage: PropTypes.bool
}
/** Default props */
UnitsEdit.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: false
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ units, configuration: { configurations }, utilityAllowance }, ownProps) => {
    let bedrooms = global._.sortBy(configurations[configurationConstants.bedrooms], 'desc');
    return {
        units: units,
        getOne: units.getOne,
        unitTable1List: units.unitTable1List,
        bedrooms: bedrooms.map(bd => { return { ...bd, value: bd.key, label: bd.desc } })
    }
};
/**
 * Maps dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getById: (id, type) => dispatch(unitsActions.getById(id, type)),
        getConfiguration: (config_names) => dispatch(confActions.getConfiguration(config_names)),
        getUnitByTable1List: (table1_id) => dispatch(unitsActions.getUnitByTable1List(table1_id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(UnitsEdit));

