import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import MailTemplateEdit from './MailTemplateEdit';

// Import Actions
import { mailTemplateActions } from '../../actions/mailTemplate';

// Import Helpers
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * MailTemplateNew Container
 * @class MailTemplateNew
 * @extends {Component}
 */
class MailTemplateNew extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.templateRef = React.createRef();
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.templateRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            this.props.addNewTemplate(formData);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.push('/mailTemplate');
    }
    /**
   * Bind Html to DOM
   */
    render() {
        const { pageAccess, isSubPage } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        return (
            <MailTemplateEdit
                isSubPage={isSubPage}
                pageAccess={pageAccess}
                innerRef={(node) => { this.templateRef = node; }}
                isReadOly={isReadOly}
                handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
            />
        );
    }
}
/**
 * Bind Component Property Types
 */
MailTemplateNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool
};
/** Default props */
MailTemplateNew.defaultProps = {
    isSubPage: false,
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        pageAccess: state.pageAccess['mailTemplateNew'] || []
    }
};
/**
 * Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        addNewTemplate: (formData) => dispatch(mailTemplateActions.addNewTemplate(formData))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(MailTemplateNew));
