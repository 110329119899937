import React, { Component } from 'react';

class XMLEditorSuccess extends Component {

    constructor (props) {
        super(props);
        this.state = { }
    }

    render() {
        const {  success } = this.props;
        return (
            <React.Fragment>
              <div className='success-container'>
                 <table className='success-table-container'>
                   <tr className='success-table-row heading'>
                        <td className='success-table-cell'>Building</td>
                        <td className='success-table-cell'>Unit</td>
                        <td className='success-table-cell'>Move-In Date</td>
                        <td className='success-table-cell'>Household Name</td>
                    </tr>
                    {
                        success.map((record,index) => {
                            return (
                                <tr className='success-table-row'>  
                                    <td className='success-table-cell'>{record.buildingName}</td>
                                    <td className='success-table-cell'>{record.unitName}</td>
                                    <td className='success-table-cell'>{record.moveInDate}</td>
                                    <td className='success-table-cell'>{record.houseHoldName}</td>
                                </tr>
                            )
                        })
                    }
                 </table>
             </div>
              
            </React.Fragment>
        )
    }
}

/** Default props */
XMLEditorSuccess.defaultProps = {
    success: [],
}
/** Export Component */
export default XMLEditorSuccess;