import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import ProjectsList from "./ProjectsList";
import ProjectsView from "./ProjectsView";
import ProjectsNew from "./ProjectsNew";
import UABulkUpload from '../UtilityAllowance/UABulkUpload';

/**
 * ProjectsRouter Container
 * @class ProjectsRouter
 * @extends {Component}
 */
class ProjectsRouter extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <ProjectsList match={props.match} page='projects' {...props} />
                }} />
                <Route exact path={`${match.url}/ua/bulk-upload`} component={UABulkUpload} />
                <Route exact path={`${match.url}/new/:copyId`} render={(props) => {
                    return <ProjectsNew {...props} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <ProjectsNew {...props} />
                }} />
                <Route path={`${match.url}/:project_id`} render={(props) => {
                    return <ProjectsView {...this.props} projectId={props.match.params.project_id} />
                }} />

            </Switch>

        );
    }
}
/**
 * Bind Component Property Types
 */
ProjectsRouter.propTypes = {
    classes: PropTypes.object,
};


/**
 * Export Component
 */
export default ProjectsRouter;
