import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Component
import PageTitle from '../PageTitle/PageTitle';

// Actions
import PageActions from '../PageActions/PageActions';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Helpers
import { getParentCardHeight, getChildCardHeight, getSubChildCardHeight, getConfigurationContentHeight } from '../../helpers/global'

/**
 *Content Wrapper component
 *
 * @class ContentWrapper
 * @extends {Component}
 */
class ContentWrapper extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            parentCardHeight: getParentCardHeight(),
            childCardHeight: getChildCardHeight(),
            subChildCardHeight: getSubChildCardHeight(),
            configurationCardHeight: getConfigurationContentHeight()
        }
        //    this.divHeight=props.onScrollChange()
    }
    /**
     * Handle Windo Resize
     */
    handleWindowResize = () => {
        this.setState({
            parentCardHeight: getParentCardHeight(),
            childCardHeight: getChildCardHeight(),
            subChildCardHeight: getSubChildCardHeight()
        });
    }
    /**
     * component did mount
     */
    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }
    /**
     * component will unmount
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
    /**
     * Bind Html to DOM
     * @returns
     * 
     * [1]  -   Card height for UA bulk upload page
     * [2]  -   Card height for configuration page
     * [3]  -   If card doesn't need title
     * [4]  -   If Configuration page has tab
     * [5]  -   Card height for Merge page
     * [6]  -   For Other Pages
     * [7]  -   If Card is childPage
     * [8]  -   If Cart is not Sub-Page
     */
    render() {
        const { classes, title, subTitle, xmlTitle, pageAccess, handleAddActionClick, handleClaimActionClick, handleUnClaimActionClick, handleMoveInActionClick, needTitleBar,
            handleClearFilterActionClick, handleFieldFilterActionClick, handleSaveActionClick, handleBackActionClick,
            isSubPage, handleUploadActionClick, handleUploadButtonActionClick, needPadding, handleGotoBuildingListActionClick, handleGotoUnitsListActionClick,
            handleGotoTable1ListActionClick, handleSubmitActionClick, handleUnSubmitActionClick, handleDownloadActionClick, handleCommentActionClick, handleGotoSettingsActionClick, handleGotoViewActionClick, ischildPage,
            validationContent, handleTable1ImportActionClick, handleXMLImportActionClick, titleCss, handleMergeActionClick, handleRefreshValidationsClick, handlePrintActionClick, handleDeleteOccupantActionClick,
            handleHelpActionClick, titleBg, pageName, handleSendActionClick, downloadColor, handleAssignFunderActionClick, isConfigurationPage, isConfigurationPageTab, parentCardBoxShadowClass, isMergePage, uploadTooltip, isUABulkUploadPage,isXMLSuccessPage,
            isArchiveFunderPage, toolTipTitle,handleGotoExploreDataClick
        } = this.props;
        const { parentCardHeight, childCardHeight, subChildCardHeight, configurationCardHeight } = this.state;
        let style, customClass;
        // [1]
        if (isUABulkUploadPage) {
            // [3]
            if (needTitleBar === false) {
                style = { height: childCardHeight + 75 };
            } else {
                if(isSubPage){
                    style = { height: subChildCardHeight + 75};
                }else{
                    style = { height: subChildCardHeight + 50 };
                }
            }
            customClass = needPadding === true ? 'p-t-0 p-b-0' : classes.nopadding + ' p-b-0';
            customClass = customClass + ' parentCard';
        }
        else if(isXMLSuccessPage){
            style={height: subChildCardHeight + 200}
        }else if(isArchiveFunderPage){
            style={height: subChildCardHeight + 200}
            customClass = ' p-t-0';
        } else if (isConfigurationPage) {
            //[2]
            // [3]
            if (needTitleBar === false) {
                // [4]
                if (isConfigurationPageTab) {
                    style = { height: configurationCardHeight - 72 };
                } else {
                    style = { height: configurationCardHeight - 36 };
                }
            } else {
                style = { height: configurationCardHeight };
            }

            customClass = needPadding === true ? 'p-t-0 p-b-0' : classes.nopadding + ' p-b-0';
            customClass = customClass + ' parentCard';
        } else if (isMergePage) {
            // [5]
            // [3]
            if (needTitleBar === false) {
                style = { height: childCardHeight };
            } else {
                style = { height: subChildCardHeight + 38 };
            }

            customClass = needPadding === true ? 'p-t-0 p-b-0' : classes.nopadding + ' p-b-0';
            customClass = customClass + ' parentCard ' + parentCardBoxShadowClass;
        } else {
            // [6]
            // [7]
            if (ischildPage === true) {
                // [3]
                if (needTitleBar === false) {
                    style = pageName === 'reports' ? { height: childCardHeight } : { height: subChildCardHeight + 38 };
                } else {
                    style = pageName === 'reports' ? { height: subChildCardHeight + 38 } : { height: subChildCardHeight };
                }

                customClass = needPadding === true ? 'p-t-0 p-b-0' : classes.nopadding + ' p-b-0';
                customClass = customClass + ' parentCard ' + parentCardBoxShadowClass;
            } else {
                // [8]
                if (!isSubPage) {
                    style = { height: parentCardHeight };
                    customClass = needPadding === true ? 'p-t-0 p-b-0' : classes.nopadding + ' p-b-0';
                    customClass = customClass + ' parentCard';
                }
                else {
                    // [3]
                    if (needTitleBar === false) {
                        style = { height: childCardHeight + 38 };
                    } else {
                        style = { height: childCardHeight };
                    }
                    customClass = needPadding === true ? 'p-t-0 p-l-r-8 p-b-0' : classes.nopadding + ' p-b-0';
                    customClass = customClass + ' childCard';
                }
            }
        }
        return (
            <Grid container xs={12} item={true} >
                {needTitleBar === true &&
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={isSubPage ? `subpage ${title === '' || ischildPage ? titleBg : ''}` : 'page-header'}
                    >
                        <Grid item xs={12} sm={8}>
                            <PageTitle
                                isSubPage={isSubPage}
                                title={title}
                                validations={validationContent}
                                subTitle={subTitle}
                                actions={pageAccess}
                                titleCss={titleCss}
                                handleBackActionClick={handleBackActionClick}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <PageActions
                                isSubPage={isSubPage}
                                actions={pageAccess}
                                xmlTitle={xmlTitle}
                                toolTipTitle={toolTipTitle}
                                downloadColor={downloadColor}
                                uploadTooltip={uploadTooltip}
                                handleAddActionClick={handleAddActionClick}
                                handleClaimActionClick={handleClaimActionClick}
                                handleUnClaimActionClick={handleUnClaimActionClick}
                                handleMoveInActionClick={handleMoveInActionClick}
                                handleClearFilterActionClick={handleClearFilterActionClick}
                                handleFieldFilterActionClick={handleFieldFilterActionClick}
                                handleSaveActionClick={handleSaveActionClick}
                                handleUploadActionClick={handleUploadActionClick}
                                handleUploadButtonActionClick={handleUploadButtonActionClick}
                                handleGotoBuildingListActionClick={handleGotoBuildingListActionClick}
                                handleGotoUnitsListActionClick={handleGotoUnitsListActionClick}
                                handleGotoTable1ListActionClick={handleGotoTable1ListActionClick}
                                handleSubmitActionClick={handleSubmitActionClick}
                                handleUnSubmitActionClick={handleUnSubmitActionClick}
                                handleGotoSettingsActionClick={handleGotoSettingsActionClick}
                                handleGotoViewActionClick={handleGotoViewActionClick}
                                handleDownloadActionClick={handleDownloadActionClick}
                                handleCommentActionClick={handleCommentActionClick}
                                handleTable1ImportActionClick={handleTable1ImportActionClick}
                                handleXMLImportActionClick={handleXMLImportActionClick}
                                handleMergeActionClick={handleMergeActionClick}
                                handleRefreshValidationsClick={handleRefreshValidationsClick}
                                handlePrintActionClick={handlePrintActionClick}
                                handleDeleteOccupantActionClick={handleDeleteOccupantActionClick}
                                handleHelpActionClick={handleHelpActionClick}
                                handleSendActionClick={handleSendActionClick}
                                handleAssignFunderActionClick={handleAssignFunderActionClick}
                                handleGotoExploreDataClick={handleGotoExploreDataClick}
                            />
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={0}>
                    <Grid item xs={12} className={isSubPage === true ? 'p-b-0' : ''}>
                        {
                            isSubPage === false && ischildPage === false ? (
                                <Card>
                                    <CardContent
                                        className={customClass}
                                        style={style}
                                        ref={(divElement) => { this.divElement = divElement }}
                                    // onScroll={() =>onScrollChange( this.divElement ) }

                                    >
                                        {this.props.children}
                                    </CardContent>
                                </Card>
                            ) :
                                <div
                                    className={customClass}
                                    style={style}
                                    ref={(divElement) => { this.divElement = divElement }}
                                // onScroll={() =>onScrollChange(this.divElement ) }
                                >
                                    {
                                        this.props.children
                                    }
                                </div>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

/**
 * Bind Component Property Types
 */
ContentWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    validationContent: PropTypes.object,
    title: PropTypes.string,
    toolTipTitle: PropTypes.string,
    titleCss: PropTypes.string,
    subTitle: PropTypes.string,
    pageAccess: PropTypes.array,
    isSubPage: PropTypes.bool,
    needTitleBar: PropTypes.bool,
    ischildPage: PropTypes.bool,
    xmlTitle: PropTypes.string,
    onScrollChange: PropTypes.func
};

//default props
ContentWrapper.defaultProps = {
    title: "",
    xmlTitle: "Import xml",
    toolTipTitle:"",
    subTitle: "",
    pageAccess: [],
    isSubPage: false,
    needTitleBar: true,
    needPadding: false,
    ischildPage: false,
    isConfigurationPage: false,
    isConfigurationPageTab: false,
    isUABulkUploadPage: false,
    isXMLSuccessPage:false,
    isArchiveFunderPage:false,
    validationContent: null,
    titleCss: '',
    pageName: '',
    downloadColor: 'default',
    uploadTooltip: 'Upload',
    handleAddActionClick: () => { },
    handleClaimActionClick: () => { },
    handleUnClaimActionClick: () => { },
    handleMoveInActionClick: () => { },
    handleClearFilterActionClick: () => { },
    handleFieldFilterActionClick: () => { },
    handleSaveActionClick: () => { },
    handleBackActionClick: () => { },
    handleUploadActionClick: () => { },
    handleUploadButtonActionClick: () => { },
    handleGotoBuildingListActionClick: () => { },
    handleSubmitActionClick: () => { },
    handleUnSubmitActionClick: () => { },
    handleGotoSettingsActionClick: () => { },
    handleGotoViewActionClick: () => { },
    handleDownloadActionClick: () => { },
    handleCommentActionClick: () => { },
    handleTable1ImportActionClick: () => { },
    handleXMLImportActionClick: () => { },
    handleMergeActionClick: () => { },
    handleRefreshValidationsClick: () => { },
    handlePrintActionClick: () => { },
    handleDeleteOccupantActionClick: () => { },
    handleHelpActionClick: () => { },
    handleSendActionClick: () => { },
    handleAssignFunderActionClick: () => { },
    handleGotoExploreDataClick: () => {},
    titleBg: "grey",
    parentCardBoxShadowClass: '',
    isMergePage: false,
    onScrollChange: () => { }
}

/**
 * Export Component
 */
export default (withStyles(pageStyle)(ContentWrapper));
