import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

//Import Component
import MaterialTable from '../../../components/Table/MaterialTable';

// import helper
import { globalVariable, history, filterSearchFields, getUserOrgId, getUserType } from '../../../helpers';

//import actions
import { dashboardHOActions } from '../../../actions';
import { searchActions } from '../../../actions/search';

class PMNeedsAction extends Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            paginationVisible: true,
            searchVisible: true
        }
        this.org_id = getUserOrgId();
        this.userRole = getUserType();
    }

    /**
     * On Change Page Table 
     */
    onChangePageTable = (skip, limit) => {
        const { userRole, org_id } = this;
        const { dashboard, searchData: { sFields, sValues }, dashboardCompliance } = this.props;
        this.props.getPMProjectList(skip, limit, dashboard.page.orderBy, dashboard.page.order, sFields, sValues, dashboardCompliance.selectedSites, dashboardCompliance.year, userRole, org_id);
    };

    /**
     * On Sort Request
     */
    onSortRequest = (orderBy, order) => {
        const { userRole, org_id } = this;
        const { dashboard, searchData: { sFields, sValues }, dashboardCompliance } = this.props;
        this.props.getPMProjectList(dashboard.page.skip, dashboard.page.limit, orderBy, order, sFields, sValues, dashboardCompliance.selectedSites, dashboardCompliance.year, userRole, org_id);
    };

    /**
     * On Input Change Request
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { dashboard, searchData, dashboardCompliance } = this.props;
        let { sFields, sValues } = searchData;
        const { userRole, org_id } = this;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.searchActions({ sFields, sValues });
        this.props.getPMProjectList(1, globalVariable.tabTableRowSize, dashboard.page.orderBy, dashboard.page.order, sFields, sValues, dashboardCompliance.selectedSites, dashboardCompliance.year, userRole, org_id);
    };

    /**
     * Handle View Report Action
     */
    handleViewReportAction = (item) => {
        let {dashboardCompliance:{year}} = this.props
        if (item.reportYear_id) {
            history.push({
                pathname: `loanProjects/${item.project_id}/reports/${item.reportYear_id}/${year}`,
                state: {
                    page: 'certificationForm',
                    reportYear_id: item.reportYear_id,
                    pageData: {
                        reportYear: item.year,
                        reportYear_id: item.reportYear_id,
                    }
                }
            });
        } else {
            history.push(`/projects/${item.project_id}/reports`);
        }
    }

    /**
     * Handle Table Navigation Event
     */
    handleNavigateAction = (id, field, item) => {
        let {dashboardCompliance:{year}} = this.props
        switch (field) {
            case 'programCount':
                if (item.contract_id !== null) {
                    history.push({
                        pathname: `loanProjects/${item.project_id}/reports/${item.reportYear_id}/${year}/programActivity`,
                        state: {
                            page: 'programActivity',
                            contract_id: item.contract_id,
                            reportYear_id: item.reportYear_id,
                            pageData: {
                                reportYear: item.year,
                                reportYear_id: item.reportYear_id,                            
                            },
                        }
                    });
                }
                break;
            case 'financeCount':
                if (item.contract_id !== null) {
                    history.push({
                        pathname: `loanProjects/${item.project_id}/reports/${item.reportYear_id}/${year}/financialActivity`,
                        state: {
                            page: 'financialActivity',
                            contract_id: item.contract_id,
                            reportYear_id: item.reportYear_id,
                            pageData: {
                                reportYear: item.year,
                                reportYear_id: item.reportYear_id,
                            },
                        }
                    });
                }
                break;
            case 'loanCount':
                if (item.contract_id !== null) {
                    history.push({
                        pathname: `loanProjects/${item.project_id}/reports/${item.reportYear_id}/${year}/contractActivity`,
                        state: {
                            page: 'contractActivityList',
                            contract_id: item.contract_id,
                            reportYear_id: item.reportYear_id,
                            pageData: {
                                reportYear: item.year,
                                reportYear_id: item.reportYear_id,
                            },
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * render html
     */
    render() {
        let { dashboard, searchData: { sFields, sValues } } = this.props;
        const { paginationVisible, searchVisible } = this.state;
        const fieldFilters = dashboard.page ? dashboard.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        return (
            <Grid item xs={12} className="needsAction">
                <MaterialTable
                    page={dashboard.page.skip}
                    primaryHeader={['Program Activity','Financial Activity','Contract Activity']}
                    stickyHeaders={["Loan Project", "Contract Number", "Total Issues"]}
                    rowsPerPage={dashboard.page.limit}
                    count={dashboard.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    multiHeader={true}
                    // componentMap={componentMap}
                    links={["programCount", "financeCount", "loanCount"]}
                    fieldComponentOnChange={() => { }}
                    highlightField={['projectName', 'contractNumber']}
                    actions={["VIEW_REPORT"]}
                    order={dashboard.page.order}
                    orderBy={dashboard.page.orderBy}
                    data={dashboard.data.data}
                    isLoading={dashboard.isLoading}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    resetSearchContent={false}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    paginationVisible={paginationVisible}
                    searchVisible={searchVisible}
                    handleViewReportAction={this.handleViewReportAction}
                    handleNavigateAction={this.handleNavigateAction}
                    showLoading={true}
                    showMsgInside={true}
                    staticSpan={2}
                    noDataErrorMessage={"No Records Found"}
                    setLabel={(tableItem, defaultValue) => {
                        return tableItem.table === "1" ? "View Household" : "View Report"
                    }}
                />
            </Grid>
        );
    }
}

/**
 * Set Props Types
 */
PMNeedsAction.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array
};

/**
 * Default Props
 */
PMNeedsAction.defaultProps = {
    pageAccess: []
};

/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['dashboardHOReports'] || {};
    const searchData = state.search['pmdashboardHO'] || { sFields: [], sValues: [] }
    const pageAccess = state.pageAccess['dashboardHO'] || [];
    return {
        dashboard: state.dashboardHO.dashboard,
        dashboardCompliance: state.dashboardHO.complianceNeedsAction,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData
    };
}
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getPMProjectList: (skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id) => dispatch(dashboardHOActions.getPMProjectList(skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id)),
        searchActions: (data) => dispatch(searchActions.PMDashboardHO(data))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PMNeedsAction);