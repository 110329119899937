import { authHeader,globalVariable,handleResponse } from "../helpers";
import momentTZ from 'moment-timezone';

// inserting data
const insertContract=(data)=>{
   const requestOption={
        method : 'post',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body : JSON.stringify(data)
    }
    return fetch(`${globalVariable.apiURL}/contract/add`, requestOption).then(handleResponse);

}
// get contract list
const getAllContract=(id,skip,limit,orderBy,order,sField,sValue, exactMatch = false, userId = null, query = { isDelete: false})=>{
    let data = {
        fields: sField,
        values: sValue,
        exactMatch,
        userId: userId, // to get user's associated projects
        query: query // query to fetct project

    };
    let timeZone= momentTZ.tz.guess(true)
    let timeZoneOffSet = new Date().getTimezoneOffset()
   const requestOption={
        method : 'post',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body:JSON.stringify(data)
    }
    return fetch(`${globalVariable.apiURL}/contract/list?id=${id}&skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timezone=${timeZone}&timezoneoffset=${timeZoneOffSet}`, requestOption).then(handleResponse);

}
// get projects details
const getProjectDetails=(id)=>{
   const requestOption={
        method : 'post',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body:JSON.stringify(id)
    }
    return fetch(`${globalVariable.apiURL}/contract/projects`, requestOption).then(handleResponse);

}
// get single preparer details
const getOnePreparerDetails=(id)=>{
    const requestOption={
         method : 'post',
         headers: {...authHeader(), 'Content-Type': 'application/json'},
         body:JSON.stringify(id)
     }
     return fetch(`${globalVariable.apiURL}/contract/selectedpreparer`, requestOption).then(handleResponse);
 
 }

 // get organization
const getOrganizations=()=>{
    const requestOption={
         method : 'post',
         headers: {...authHeader(), 'Content-Type': 'application/json'}
     }
     return fetch(`${globalVariable.apiURL}/contract/orglist`, requestOption).then(handleResponse);
 
 }

  // update contract
const updateContract=(data)=>{
    const requestOption={
         method : 'post',
         headers: {...authHeader(), 'Content-Type': 'application/json'},
         body:JSON.stringify(data)
     }
     return fetch(`${globalVariable.apiURL}/contract/update`, requestOption).then(handleResponse);
 
 }
   // get contract by id for update
const getByContractId=(id)=>{
    const requestOption={
         method : 'GET',
         headers: {...authHeader(), 'Content-Type': 'application/json'}
     }
     return fetch(`${globalVariable.apiURL}/contract/details/${id}`, requestOption).then(handleResponse);
 
 }


 
/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function deleteContract(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/contract/${id}`, requestOptions).then(handleResponse);
}

 export const contractService = {
    insertContract,
    getAllContract,
    getProjectDetails,
    getOnePreparerDetails,
    getOrganizations,
    updateContract,
    getByContractId,
    deleteContract
}