import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import Components
import ContractActivityList from '../ContractActivity/ContractActivityList';
import ContractActivityNew from '../ContractActivity/ContractActivityNew';
import ContractActivityView from '../ContractActivity/ContractActivityView';


class ContractActivityRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }

    /**
     * Bind Html to DOM
     */
    render() {
        const { match, innerRef } = this.props;
        return (

            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <ContractActivityList match={props.match} page='contractActivity' {...props} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <ContractActivityNew {...props} projectId="1" innerRef={innerRef}/>
                }} />
                <Route path={`${match.url}/:id`} render={(props) => {
                    return <ContractActivityView {...props} projectId="1" innerRef={innerRef}/>
                }} />
            </Switch>

        );
    }
}

/**
 * Bind Component Property Types
 */
ContractActivityRouter.propTypes = {
    classes: PropTypes.object,
};

/**
 * Export Component
 */
export default ContractActivityRouter;
