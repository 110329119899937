const alertDialogStyle = theme => ({
    appBar: {
        position: 'relative',
      },
      flex: {
        flex: 1,
      },
});

export default alertDialogStyle;
