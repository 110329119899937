import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

// Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TableComponent from "../../components/Table/TableComponent";
import MailTemplateSend from "./MailTemplateSend";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// import Action
import { searchActions } from '../../actions/search';
import { helpActions, mailTemplateActions } from '../../actions';

//Helpers
import { globalVariable, history, filterSearchFields } from "../../helpers";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

/**
 * Status Component
 * @class StatusComponent
 * @extends {Component}
 */
class StatusComponent extends Component {
    /**
     * Handle Change
     * @param {*} event 
     */
    handleChange = event => {
        const { data } = this.props;
        this.props.handleChange("isActive", { id: data._id, status: event.target.checked });
    };
    /**
     * Render Status component
     * @returns 
     */
    render() {
        const { data } = this.props;
        return (<Switch
            disabled={data.isPredefined}
            color="primary"
            size="small"
            checked={data.isActive}
            onChange={this.handleChange}
        />);
    }
}
/** Default props */
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

const componentMap = {
    isActive: StatusComponent
}

/**
 *MailTemplateList Component
 * @class MailTemplateList
 * @extends {Component}
 */
class MailTemplateList extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            openHelp: false,
            openSend: false,
            id: {},
            isActive: false
        }
    }
    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { page } = this.props.mailTemplates;
        this.props.getHelp();
        if (page.skip) {
            this.props.getMailTemplates(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues);
        } else {
            this.props.getMailTemplates(1, globalVariable.tableRowSize, 'projects.name', 'asc', '', '');
        }
    }
    /**
     * Component Ded Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Go to Compliance Settings 
     */
    handleGotoSettingsActionClick = () => {
        history.push({
            pathname: `/compliance/setting`,
            state: {
                page: 'compliancesetting',
                pageData: {
                }
            }
        });
    }
    /**
     * Handle sort
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { mailTemplates, searchData: { sFields, sValues } } = this.props;
        this.props.getMailTemplates(mailTemplates.page.skip, mailTemplates.page.limit, orderBy, order, sFields, sValues);
    };
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     */
    onChangePageTable = (skip) => {
        const { mailTemplates, searchData: { sFields, sValues } } = this.props;
        this.props.getMailTemplates(skip, globalVariable.tableRowSize, mailTemplates.page.orderBy, mailTemplates.page.order, sFields, sValues);
    };
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { mailTemplates } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });

        this.props.getMailTemplates(mailTemplates.page.skip, mailTemplates.page.limit, mailTemplates.page.orderBy, mailTemplates.page.order, [], []);
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        const { match } = this.props;
        switch (field) {
            case 'name':
                // Use this case to handle navigation for existing templates
                history.push({
                    pathname: `${match.url}/${item._id}`,
                    state: {
                        page: 'mailTemplateEdit',
                        pageData: {
                            _id: item._id
                        }
                    }
                });
                break;
            default:
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Help Click Action
     * @param {*} id 
     * @param {*} item 
     */
    handleSendAction = (id, item) => {
        this.setState({
            openSend: true,
            id: item
        });
    }
    /**
     * on search request 
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { mailTemplates, searchData } = this.props;
        let { sFields, sValues } = searchData;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.searchActions({ sFields, sValues });
        this.props.getMailTemplates(1, globalVariable.tableRowSize, mailTemplates.page.orderBy, mailTemplates.page.order, sFields, sValues);
    };
    /**
     * Handle Add Action Click
     */
    handleAddActionClick = () => {
        history.push({
            pathname: '/mailTemplate/new',
            state: {
                page: 'mailTemplateNew',
                pageData: {}
            }
        });
    }
    /**
     * Handle Table Delete Action
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {
        this.props.deleteTemplate(id)
    };
    /**
     * Handle Secd Close
     */
    handleSendClose = () => {
        this.setState({ openSend: false });
    }
    /**
     * Handle Send Confirm
     * @param {*} id 
     */
    handleSendConfirm = (id) => {
        this.setState({ openSend: false });
    }
    /**
     * Field Component On Change
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "isActive":
                this.setTemplateActiveStatus(data.id, data.status);
                break;
            default:
        }
    }
    /**
     * Set Template Active Status
     * @param {*} id 
     * @param {*} status 
     */
    setTemplateActiveStatus = (id, status) => {
        this.props.activeStatus(id, status, () => {
            const { mailTemplates, searchData: { sFields, sValues } } = this.props;
            this.props.getMailTemplates(mailTemplates.page.skip, mailTemplates.page.limit, mailTemplates.page.orderBy, mailTemplates.page.order, sFields, sValues);
        });
    }
    /**
     * Render Html
     */
    render() {
        let { pageAccess, classes, isSubPage, location: { pathname }, title, helpData, mailTemplates, searchData: { sFields, sValues } } = this.props;
        const fieldFilters = mailTemplates.page ? mailTemplates.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        const { openHelp, openSend, id } = this.state;
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                needPadding={!isSubPage}
                isSubPage={isSubPage}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleGotoSettingsActionClick={this.handleGotoSettingsActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                handleAddActionClick={this.handleAddActionClick}
            >
                <TableComponent
                    page={mailTemplates.page.skip}
                    rowsPerPage={mailTemplates.page.limit}
                    count={mailTemplates.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    componentMap={componentMap}
                    search={fieldFilters.searchCols}
                    actions={['SEND', 'DELETE']}
                    order={mailTemplates.page.order}
                    orderBy={mailTemplates.page.orderBy}
                    isLoading={mailTemplates.isLoading}
                    data={mailTemplates.data}
                    links={["name"]}
                    highlightField={["name"]}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    handleNavigateAction={this.handleNavigateAction}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleViewReportAction={this.handleViewReportAction}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    handleSendAction={this.handleSendAction}
                    noDataErrorMessage={"No Records Found"}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    prevNext={false}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    paginationVisible={true}
                    disablePagination={mailTemplates.page.lastPage}
                    showLoading={false}
                    setLabel={(tableItem, defaultValue) => {
                        return tableItem.reportTable === "1" ? "View Household" : "View Report"
                    }}
                >
                </TableComponent>
                { openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Mail Templates"
                />}
                {
                    openSend && <MailTemplateSend
                        itemData={id}
                        handleOkayAction={this.handleSendConfirm}
                        handleCloseAction={this.handleSendClose}
                    />
                }
            </ContentWrapper>
        );
    }
}
/**
 * Set Props Types
 */
MailTemplateList.propTypes = {
    classes: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
};
/**
 * Default Props
 */
MailTemplateList.defaultProps = {
    title: "Mail Templates",
    isSubPage: false,
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const searchData = state.search.mailTemplate || { sFields: [], sValues: [] };
    const pageAccess = state.pageAccess['mailTemplateList'] || [];
    const mailTemplates = state.mailTemplates;
    const helpData = state.help.data[0]
    return {
        pageAccess,
        searchData,
        mailTemplates,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        searchActions: (data) => dispatch(searchActions.mailTemplate(data)),
        getMailTemplates: (skip, limit, orderBy, order, sField, sValue) => dispatch(mailTemplateActions.getAll(skip, limit, orderBy, order, sField, sValue)),
        deleteTemplate: (id) => dispatch(mailTemplateActions.deleteTemplate(id)),
        activeStatus: (id, isActive, callBack) => dispatch(mailTemplateActions.activeStatus(id, isActive, callBack)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 17 }))
    }
};
// export Compliance
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(MailTemplateList));
