import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Import Components
import TextArea from '../../../../components/TextArea/TextArea';
import NumberField from '../../../../components/NumberField/NumberField';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import PageActions from '../../../../components/PageActions/PageActions';

// helpers
import {
    totalGrossRentalIncome,
    totalOtherIncome,
    totalSubsidyOperating,
    totalSubsidyServices,
    totalSubsidyEGI,
} from '../../../../helpers/Table4Calc';
import { getFloat } from '../../../../helpers/global';

const fieldClass = {
    amount: 'txt-right',
    operatingAmt: 'txt-right',
    servicesAmt: 'txt-right',
};
/**
 * EditableTextAmt Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isDisabled } = this.props;
        const isRequired = data.otherIncome_id !== '';
        let className = isReadOly || isDisabled ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={`${className} `}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={isRequired ? ['required'] : []}
                errorMessages={isRequired ? ['this field is required'] : []}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly || isDisabled}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * EditableText1 Component
 * @class EditableText1
 * @extends {Component}
 */
class EditableText1 extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isDisabled } = this.props;
        const isRequired = data.subsidyType_id === 8 || data.subsidyType_id === 65;
        let className = isReadOly || isDisabled ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextArea
                className={className}
                name={`${fieldName}_${rowIndex}_subsidytype`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly || isDisabled}
                validators={isRequired ? ['required'] : []}
                errorMessages={isRequired ? ['this field is required'] : []}
                multiline={true}
                inputProps={{ maxLength: 200 }}
            />
        )
    }
};
/**
 * IncomeTypes Component
 * @class IncomeTypes
 * @extends {Component}
 */
class IncomeTypes extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { othersIncome, isReadOly }, isDisabled } = this.props;
        let className = isReadOly || isDisabled ? `input--disabled ${fieldClass[fieldName]}` : `${fieldClass[fieldName]}`
        return (
            <div className="autocomplete-border autocompleteList">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    className={className}
                    suggestions={othersIncome}
                    placeholder={''}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    selectedValue={{ id: data[fieldName] || '' }}
                    disabled={isReadOly || isDisabled}
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
            </div>
        )
    }
};
/**
 * SubsidyTypes Component
 * @class SubsidyTypes
 * @extends {Component}
 */
class SubsidyTypes extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { subsidyType, isReadOly }, isDisabled } = this.props;
        let className = isReadOly || isDisabled ? `input--disabled ${fieldClass[fieldName]}` : `${fieldClass[fieldName]}`
        return (
            <div className="autocomplete-border autocompleteList">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    className={className}
                    placeholder=""
                    suggestions={subsidyType}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    // className={'textBox--bordered'}
                    selectedValue={{ id: data[fieldName] || '' }}
                    disabled={isReadOly || isDisabled}
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
            </div>
        )
    }
};
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isDisabled } = this.props;
        const isRequired = data.otherIncome_id === 33;
        let className = isReadOly || isDisabled ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextArea
                className={className}
                name={`${fieldName}_${rowIndex}_otherIncome`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly || isDisabled}
                validators={isRequired ? ['required'] : []}
                errorMessages={isRequired ? ['this field is required'] : []}
                multiline={true}
                inputProps={{ maxLength: 200 }}
            />
        )
    }
};
/**
 * EditableTextAmtOp Component
 * @class EditableTextAmtOp
 * @extends {Component}
 */
class EditableTextAmtOp extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isDisabled } = this.props;
        const subsidyValue = data.subsidyType_id !== '';
        const isRequired = (data.operatingAmt === null && data.servicesAmt === null) || (data.servicesAmt === '' && data.operatingAmt === '');
        let className = isReadOly || isDisabled ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}_op`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly || isDisabled}
                prefix={'$'}
                thousandSeparator={true}
                validators={subsidyValue && isRequired ? ['required'] : []}
                errorMessages={subsidyValue && isRequired ? ['Enter Either Operating or Service Amount'] : []}
            />
        )
    }
};
/**
 * EditableTextAmtSer Component
 * @class EditableTextAmtSer
 * @extends {Component}
 */
class EditableTextAmtSer extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isDisabled } = this.props;
        const subsidyValue = data.subsidyType_id !== '';
        const isRequired = (data.servicesAmt === null && data.operatingAmt === null) || (data.servicesAmt === '' && data.operatingAmt === '') ||
            (data.servicesAmt === null && data.operatingAmt === '') || (data.servicesAmt === '' && data.operatingAmt === null);
        let className = isReadOly || isDisabled ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}_ser`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly || isDisabled}
                prefix={'$'}
                thousandSeparator={true}
                validators={subsidyValue && isRequired ? ['required'] : []}
                errorMessages={subsidyValue && isRequired ? ['Enter Either Operating or Service Amount'] : []}
            />
        )
    }
};

const componentMap = {
    otherIncome_id: IncomeTypes,
    subsidyType_id: SubsidyTypes,
    operatingAmt: EditableTextAmtOp,
    servicesAmt: EditableTextAmtSer,
    amount: EditableTextAmt,
    comment: EditableText
};

const componentMap2 = {
    ...componentMap,
    comment: EditableText1
};
/**
 * Income Component
 * @class Income
 * @extends {Component}
 */
class Income extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {}
    }
    /**
     * Cell Component On change Other Income
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChangeOtherIncome = (fieldName, rowIndex, value) => {
        value = value === undefined ? null : value;
        this.props.onChange('otherIncome', fieldName, rowIndex, value);
    }
    /**
     * Cell Component On change Subsidy type
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChangeSubsidyType = (fieldName, rowIndex, value) => {
        value = value === undefined ? null : value;
        this.props.onChange('subsidyTypes', fieldName, rowIndex, value);
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                value={value}
                prefix={'$'}
                thousandSeparator={true}
                displayType={'text'}
            />
        )
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { data, config, classes, isDisabled } = this.props;
        const otherIncome = data.otherIncome.reduce((total, income) => {
            return total + getFloat(income.amount);
        }, 0)
        const subsidy = data.subsidyTypes.reduce((total, subsidy) => {
            return total + getFloat(subsidy.operatingAmt) + getFloat(subsidy.servicesAmt)
        }, 0);
        let validation = getFloat(otherIncome) + getFloat(data.commercialNetIncome) + getFloat(data.actualRentIncome) + getFloat(subsidy);
        const commercialNIFied = (
            <NumberField
                name="commercialNetIncome"
                value={data.commercialNetIncome || 0}
                className={`${classes.textField} txt-right font-bold`}
                // className={classes.textField}
                validators={validation <= 0 ? ['required'] : []}
                errorMessages={validation <= 0 ? ['this field is required'] : []}
                margin="dense"
                disabled={isDisabled}
                thousandSeparator={true}
                handleChange={this.props.handleChange}
            />
        )

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <NumberField
                        name="actualRentIncome"
                        label="Actual Rental Income"
                        value={`${data.actualRentIncome || 0}`}
                        validators={validation <= 0 && isDisabled === false ? ['required', 'gtZero'] : ['required']}
                        errorMessages={validation <= 0 && isDisabled === false ? ['this field is required', 'Actual Rental Income or Other Income Source or Commercial Net Income or Subsidy Income must have a value greater than zero.'] : ['this field is required']}
                        className={classes.textField}
                        margin="dense"
                        disabled={isDisabled}
                        thousandSeparator={true}
                        handleChange={this.props.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <EditableTable
                        headerCols={['Other Income Source', 'Amount', 'Comments']}
                        fieldCols={['otherIncome_id', 'amount', 'comment']}
                        rowDatas={data.otherIncome}
                        needTitle={false}
                        actions={isDisabled ? [] : ['DELETE']}
                        componentMap={componentMap}
                        isDisabled={isDisabled}
                        cellComponentOnChange={this.cellComponentOnChangeOtherIncome}
                        config={{ ...config, classes, validation }}
                        tableClass={'fieldWidth12'}
                        footerDatas={[
                            {
                                'otherIncome_id_footer': 'Subtotal:',
                                'amount_footer': this.displayAmount(totalOtherIncome(data)),
                                'comment_footer': ''
                            },
                            {
                                'otherIncome_id_footer': 'Gross Rental Income:',
                                'amount_footer': this.displayAmount(totalGrossRentalIncome(data)),
                                'comment_footer': ''
                            },
                            {
                                'otherIncome_id_footer': 'Commercial Net Income:',
                                'amount_footer': commercialNIFied,
                                'comment_footer': ''
                            }
                        ]}
                        onDelete={(item) => {
                            this.props.onDelete('otherIncome', item)
                        }}
                    >
                        <Grid item xs={12} sm={12}>
                            <PageActions
                                isSubPage={true}
                                actions={isDisabled ? [] : ['ADDLINK']}
                                handleAddActionClick={() => { this.props.onAdd('otherIncome') }}
                            />
                        </Grid>
                    </EditableTable>
                    <Grid item xs={12} sm={12}>
                        <Grid item xs={3} sm={3}>
                            {'Subsidy Income (see instructions)'}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <EditableTable
                        headerCols={['Subsidy Type', 'Operating', 'Services', 'Comments']}
                        fieldCols={["subsidyType_id", 'operatingAmt', 'servicesAmt', 'comment']}
                        rowDatas={data.subsidyTypes}
                        needTitle={false}
                        actions={isDisabled ? [] : ['DELETE']}
                        isDisabled={isDisabled}
                        componentMap={componentMap2}
                        cellComponentOnChange={this.cellComponentOnChangeSubsidyType}
                        config={{ ...config, classes, validation }}
                        tableClass={'fieldWidth12'}
                        footerDatas={[
                            {
                                'subsidyType_id_footer': 'Subtotal:',
                                'operatingAmt_footer': this.displayAmount(totalSubsidyOperating(data)),
                                'servicesAmt_footer': this.displayAmount(totalSubsidyServices(data)),
                                'comment_footer': ''
                            },
                            {
                                'subsidyType_id_footer': 'EGI: Effective Gross Income: (operating + service dollars)',
                                'operatingAmt_footer': this.displayAmount(totalSubsidyEGI(data)),
                                'servicesAmt_footer': '',
                                'comment_footer': ''
                            }
                        ]}
                        onDelete={(item) => {
                            this.props.onDelete('subsidyTypes', item)
                        }}
                    >
                        <Grid item xs={12} sm={12}>
                            <PageActions
                                isSubPage={true}
                                actions={isDisabled ? [] : ['ADDLINK']}
                                handleAddActionClick={() => { this.props.onAdd('subsidyTypes') }}
                            />
                        </Grid>
                    </EditableTable>
                </Grid>
            </Grid>
        )
    }
}
/** Props Type */
Income.propTypes = {
    classes: PropTypes.object.isRequired
}
/** Default Props */
Income.defaultProps = {
    data: {},
    config: {},
    classes: {},
    pageAccess: [],
    isReadOly: false,
    handleChange: () => { }
}
/** Export Component */
export default Income;