import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { TextValidator } from 'react-material-ui-form-validator';
import { InputAdornment } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

// Table validations warning helper
import { hasWarnings } from '../../helpers/TableValidations/warnings';

/**
 *Dropdown Component
 *
 * @class DropDown
 * @extends {Component}
 */
class DropDown extends Component {
    /**
     * Handle TextField Events
     * @param {*} event 
     */
    handleChange = event => {
        this.props.handleChange(this.props.name, event.target.value)
    };
    /**
     * handle Clear
     * @param {*} event 
     */
    handleClear = (event) => {
        this.props.handleClear()
    };
    /**
     * render html
     */
    render() {
        const { name, value, label, className, margin, validators, errorMessages, disabled, fielderrors, warningfieldname, clear } = this.props;
        const customClass = validators && validators.length > 0 ? className + ' required' : className;
        let iconClass = disabled === true ? 'hidden' : value ? 'hidden' : '';
        const warningClass = hasWarnings(fielderrors, warningfieldname);
       //Include the dropdown for reportyear and template(exploredata) and Dashboard for reportName
        if (name == "reportFilter" || name == "reportYear" || name == "reportName" || name == "template" || name == "fileFormat" ) {
            iconClass = '';
        }

        return (
            clear && value ? <TextValidator
                select
                name={name}
                value={value}
                label={label}
                variant="standard"
                className={`${customClass} ${warningClass}`}
                onChange={this.handleChange}
                margin={margin}
                validators={validators}
                errorMessages={errorMessages}
                disabled={disabled}
                SelectProps={{ classes: { icon: iconClass } }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" style={{ marginLeft: -25, cursor: "pointer" }}>
                            <Clear style={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={this.handleClear} />
                        </InputAdornment>
                    ),
                }}
            >
                {this.props.children}
            </TextValidator>
                : <TextValidator
                    select
                    name={name}
                    value={value}
                    label={label}
                    variant="standard"
                    className={`${customClass} ${warningClass}`}
                    onChange={this.handleChange}
                    margin={margin}
                    validators={validators}
                    errorMessages={errorMessages}
                    disabled={disabled}
                    SelectProps={{ classes: { icon: iconClass } }}
                >
                    {this.props.children}
                </TextValidator>

        );
    }
}
//Default props
DropDown.defaultProps = {
    name: '',
    value: '',
    label: "",
    className: '',
    errorMessages: [],
    validators: [],
    disabled: false,
    margin: "dense",
    items: [],
    /**handleOnChange -a method to handle changes as a user adds input to the text box */
    handleChange: () => { },
    fielderrors: [],
    warningfieldname: '',
    clear: false
}
//prop types
DropDown.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    items: PropTypes.any.isRequired,
    handleChange: PropTypes.func.isRequired
};
//Export Component
export default DropDown;
