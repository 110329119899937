import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import SitesList from '../../Sites/SitesList';
import SitesView from "../../Sites/SitesView";
import SitesNew from "../../Sites/SitesNew";
import BuildingRouter from "../../Buildings/Building.Router";

/**
 * Sites Container
 * @class Sites
 * @extends {Component}
 */
class Sites extends Component {

    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, projectId, isSubPage } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={props => (
                    <SitesList {...props}
                        match={match}
                        projectId={projectId}
                        page={'projects'} 
                        isSubPage={isSubPage}
                        />
                )} />
                <Route exact path={`${match.url}/new`} render={props => (
                    <SitesNew {...props} 
                    projectId={projectId} 
                    removeActions={['BACK']} 
                    isSubPage={isSubPage} />
                )} />
                <Route exact path={`${match.url}/:siteId`} render={props => {
                    return (
                        <SitesView
                            {...props}
                            projectId={projectId}
                            siteId={props.match.params.siteId}
                            isSubPage={isSubPage}
                            removeActions={['BACK']}
                        />
                    )
                }} />
                <Route path={`${match.url}/:siteId/buildings`} render={props => {
                    return (
                        <BuildingRouter                       
                            projectId={projectId}
                            siteId={parseInt(props.match.params.siteId, 10)}
                            {...props}
                            isSubPage={isSubPage}
                        />
                    )
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default Sites;