import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Components
// import TextBox from '../../../components/TextBox/TextBox';
import SectionTable from './SectionTable';
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import DropDown from '../../../components/DropDown/DropDown';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';

import { getUserRole } from '../../../helpers';

class SetAside extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claimedSite: 0,
            setAsideBy: 0,
            open: false,
            delBox: null
        }
        this.userRole = getUserRole();
    }

    /**
    * Handle TextBox and DropDown Change Event
    */
    handleSetAsideChange = (colName, index) => (name, value) => {
        this.props.onChange(colName, index, value);
    };

    /**
    * Handle Multi Select Change Event
    */
    handleSetAsideChangeMultiSelect = (colName, index, value) => {
        this.props.onChange(colName, index, value);
    };

    /**
    * Handle Input Change Event
    */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };

    /**
    * Handle SetAside DropDown Change Event
    */
    handleSetAsideBy = (name, value) => {
        this.setState({ open: true, setAsideBy: value });
    }

    /**
    * Handle Delete Dialog
    */
    renderConfirmDialog(openState, title, yesCallback, cancelCallback) {
        this.setState({
            delBox: <DeleteDialog
                open={openState}
                content={title}
                handleDeleteAction={yesCallback}
                handleDeleteOnCloseAction={cancelCallback}
            />
        });
    }

    /**
    * Bind HTML to DOM
    */
    render() {
        const { classes, isReadOly, sites, setAsideBy, claimedSite, org_id } = this.props;
        const { userRole } = this;
        return (
            <div>
                <h4 className="section_title sectionTitleMargin">
                    Unit Set-Aside Requirements
                </h4>

                <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                        <DropDown
                            name={`setAsideBy`}
                            value={setAsideBy}
                            label="Set Aside by"
                            className={classes.textField}
                            handleChange={this.handleSetAsideBy}
                            margin="dense"
                            validators={['required']}
                            errorMessages={['This field is required']}
                            disabled={isReadOly}
                        >
                            <MenuItem value={0}>{"Set-Aside Requirement are for the entire Project"}</MenuItem>
                            <MenuItem value={1}>{"Set-Aside Requirement are set for each Contract"}</MenuItem>
                        </DropDown>
                    </Grid>
                    {
                        claimedSite !== 0 && (
                            <Grid item xs={4} sm={4}>
                                <AutoComplete
                                className="autoCompleteTop"
                                    name={`claimedSite`}
                                    placeholder={"Select Claimed Contract"}
                                    suggestions={sites}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.props.onSiteChange(selectedOption.value);
                                    }}
                                    selectedValue={{ id: claimedSite }}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    disabled={userRole === "ReadOnlyOrg" || userRole === "Funder" || claimedSite === 0}
                                />
                            </Grid>
                        )
                    }
                    <DeleteDialog
                        open={this.state.open !== false}
                        content={
                            this.state.setAsideBy === 0 ? 'This will merge all Set-Aside Requirements for each contract and will move to entire project' : 'This will move project level Set-Aside Requirements to first contract'
                        }
                        handleDeleteAction={() => {
                            this.setState({ open: false });
                            this.props.onSetAsideBy(this.state.setAsideBy);
                        }}
                        handleDeleteOnCloseAction={() => {
                            this.setState({ open: false })
                        }}
                    />
                </Grid>
                <SectionTable
                    {...this.props}
                    title={'LIH Percentages'}
                    headers={['LIH Percentage', 'Contracts', 'First Year', 'Last Year', 'Actions']}
                    categoryId={1}
                    handleSetAsideChange={this.handleSetAsideChange}
                    handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                    onDelete={(index) => {
                        this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                            this.props.onDelete(index);
                            this.setState({ delBox: null });
                        }, () => {
                            this.setState({ delBox: null });
                        })
                    }}
                />
                {org_id !== 1 &&
                    <React.Fragment>
                        <SectionTable
                            {...this.props}
                            title={'Special Needs Populations entered on Contract Activity'}
                            headers={['Special Needs Populations', 'Contracts', 'First Year', 'Last Year', 'Actions']}
                            categoryId={3}
                            handleSetAsideChange={this.handleSetAsideChange}
                            handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                            onDelete={(index) => {
                                this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                    this.props.onDelete(index);
                                    this.setState({ delBox: null });
                                }, () => {
                                    this.setState({ delBox: null });
                                })
                            }}
                        />
                    </React.Fragment>
                }
                {this.state.delBox}
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
SetAside.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

SetAside.defaultProps = {
    isReadOly: false
}
/**
 * Maps state from store to props
 */
const mapStateToProps = ({ projects }, ownProps) => {
    return {

    }
};

/**
 * map props to state
* @param {*} dispatch
*/
const mapDispatchToProps = (dispatch) => {
    return {

    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(SetAside));

