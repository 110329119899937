// Change Material UI Theme
import { createTheme } from "@material-ui/core/styles/index";

const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: "#36aebe"
        },
        secondary: {
            main: '#fbab92'
        }
    },
    overrides: {
        MuiToolbar: {
            dense: {
                minHeight: 48
            }
        },
        MuiButton: {
            root: {
                borderRadius: "0",
                boxShadow: "none",
                minHeight: "45px",
            },
            containedPrimary: {
                color: 'white',
                borderRadius: "0",
                boxShadow: "none",
            },
            containedSecondary: {
                color: "white",
                borderRadius: "0",
                boxShadow: "none",
            },
            // mini: {
            //     boxShadow: "none",
            //     width: "35px",
            //     height: "35px",
            //     minHeight: "35px",
            //     marginTop: "0px",
            //     marginBottom: "0px",
            //     lineHeight:"40px"
            // },
            contained: {
                color: "#666",
                boxShadow: "none",
            },
            label: {
                letterSpacing: 0
            }

        },
        MuiIconButton: {
            colorInherit: {
                color: "white"
            },
            root: {
                height: 28,
                width: 28,
                marginLeft: 5,
                marginRight: 5,
                padding: 0
            },
            label: {
                width: "20px !important"
            }
        },
        MuiAvatar: {
            root: {
                width: 30,
                height: 30
            }
        },
        PageTitle: {
            root: {
                paddingTop: 0,
            }
        },
        MuiCard: {
            root: {
                borderRadius: "0px",
                boxShadow: "0px 0px 3px rgba(102, 102, 102, 0.25)",
            },
        },
        MuiCardHeader: {
            title: {
                fontSize: "16px",
                color: "#222222",
                fontWeight: 500
            },
            root: {
                minHeight: "45px",
                padding: 10
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: "#eee"
            },
            light: {
                backgroundColor: "#000"
            }
        },
        MuiListItemText: {
            primary: {
                fontSize: "15px",
                letterSpacing: 0
            },
        },
        MuiListItemIcon: {
            root: {
                color: "#909090",
                width: "16px",
                fontSize: "16px"
            },
        },
        MuiListItem: {
            root: {
                paddingTop: '12px',
                paddingBottom: '12px',
            },
            gutters: {
                paddingLeft: '24px',
                paddingRight: '24px',
            }
        },
        MuiTab: {
            root: {
                maxWidth: "auto",
                borderRight: "1px solid #e4e4e4",
                "&$selected": {
                    backgroundColor: "#ECF7F9",
                },
                letterSpacing: 0,
                minHeight: 36
            }
        },
        MuiTabs: {
            root: {
                minHeight: 36
            },
            flexContainer: {
                '& button:last-child': {
                    borderRight: 'unset'
                }
            }
        },
        MuiAppBar: {
            colorDefault: {
                backgroundColor: "#fff",
                borderBottom: "1px solid #eee",
                boxShadow: "none",
            },
            colorPrimary: {
                color: '#fff',
                boxShadow: 'none',
                fontSize: '1rem'
            },
            colorSecondary: {
                backgroundColor: "#f4f4f4",
                boxShadow: 'none'
            }
        },
        components: {
            MuiInput: {
                styleOverrides: {
                    root: {
                        fontSize: "0.875rem !important",
                        letterSpacing: 0,
                        color: 'rgba(0, 0, 0, 0.75)',
                        // padding: "0 4px",
                        '&$disabled': {
                            color: "rgba(0, 0, 0, 0.38) !important",
                            // backgroundColor: '#bebebe21'
                        }
                    },
                    underline: {
                        '&:before': {
                            borderBottom: "1px solid #ddd",
                        },
                        '&.Mui-focused .MuiInput-underline:after': {
                            borderBottom: "1px solid red !important",
                        }
                    },
                    input: {
                        padding: "7px 0 7px 4px",
                        letterSpacing: 0,
                        // height: 20
                    },
                }
            }
        },
        MuiInputBase: {
            root: {
                '&$disabled': {
                    color: "rgba(0, 0, 0, 0.75) !important"
                }
            },
            input: {
                '&$disabled': {
                    color: "rgba(0, 0, 0, 0.75) !important"
                }
            }
        },
        MuiTypography: {
            root: {
                // overflow: 'hidden'
                letterSpacing: 0
            },
            h3: {
                fontSize: '2.8125rem'
            },
            h6: {
                lineHeight: '1em',
                fontSize: '1.2em'
            },
            colorTextPrimary: {
                color: '#FFFFFF'
            },
            body1: {
                fontSize: '0.875rem',
                letterSpacing: 0
            }
        },
        MuiTableCell: {
            root: {
                borderBottomStyle: 'dotted !important',
                padding: "0px 6px!important",
                letterSpacing: "0px !important",
                // borderBottom: '1px solid !important'
            },
            head: {
                fontWeight: "500",
                fontSize: "14px",
                color: "#222",
                lineHeight: '2.3rem !important',
                borderBottomStyle: 'solid !important',
                whiteSpace: "nowrap !important"
            },
            body: {
                fontWeight: "400",
                fontSize: "13px !important",
                borderBottomStyle: 'solid',
                whiteSpace: "nowrap"
            },
            stickyHeader: {
                whiteSpace: "nowrap",
                minWidth: 115,
                textAlign: 'center',
                backgroundColor: 'white',
                // borderRight: '1px solid rgba(224, 224, 224, 1)'
            }
        },
        MuiTableRow: {
            root: {
                height: 30
            },
            head: {
                height: 46
            }
        },
        MuiTable: {
            root: {
                position: "relative"
            }
        },
        MuiFormLabel: {
            root: {
                fontSize: "0.875rem",
                letterSpacing: 0
            }
        },
        MuiSelect: {
            root: {
                fontSize: "0.875rem",
                letterSpacing: 0
            }
        },
        //Previously used inherit cause that overflowing while increasing the screen size so its changed to auto.
        MuiDialogContent: {
            root: {
                padding: 24,
                overflowY: "auto"
            }
        },
        MuiDialog: {
            paperScrollPaper: {
                overflow: 'visible'
            },
            paper: {
                overflowY: "visible"
            },
            paperWidthSm: {
                maxWidth: "800px"
            }
        },
        MuiLinearProgress: {
            root: {
                height: 2
            }
        },
        MuiFab: {
            root: {
                lineHeight: 0.5
            },
            sizeSmall: {
                width: "36px",
                height: "36px",
                boxShadow: "none",
                minWidth: 36,
                minHeight: 36
            },
            secondary: {
                color: "#FFFFFF"
            }
        },
        MuiSnackbar: {
            anchorOriginTopCenter: {
                top: '0px !important'
            }
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: '#FFF'
            },
            toolbarBtnSelected: {
                color: '#FFF'
            }
        },
        MuiPickersDay: {
            daySelected: {
                color: "#FFF !important"
            }
        },
        MuiMenuItem: {
            root: {
                letterSpacing: 0
            }
        },
        MuiFormHelperText: {
            root: {
                letterSpacing: 0
            }
        },
        MuiDrawer: {
            root: {
                height: "100%"
            }
        },
        MuiFormControl: {
            root: {
                width: "100%"
            }
        },
        MuiToolTip: {
            popper: {
                border: '2px solid green !important',
                boxShadow: 'unset !important'
            },
            tooltip: {
                fontSize: "2em !important",
                color: "yellow !important",
                backgroundColor: "red !important"
            }
        },
        MuiCheckbox: {
            root: {
                checked: {
                    "& svg": {
                        color: "green !important"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                '&.Mui-focused': {
                    borderColor: 'red !important'
                },

                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red !important'
                }
            }
        },
    }
});

export default theme;