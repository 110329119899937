//import constants
import { mailTemplateConstants } from '../constants';

//import servives
import { mailTemplateService } from '../services';

//import actions
import { alertActions } from './';

//import helper
import { history } from '../helpers';

export const mailTemplateActions = {
    getAll,
    getById,
    resetFilter,
    addNewTemplate,
    updateTemplate,
    deleteTemplate,
    setActiveStatus,
    sendTemplate,
    getUserList,
    activeStatus
};

function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: mailTemplateConstants.CLEAR_FILTER_SET } }
    function reset() { return { type: mailTemplateConstants.CLEAR_FILTER_RESET } }
}

/**
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 */
function getAll(skip, limit, orderBy, order, sFields, sValues) {
    return dispatch => {
        dispatch(request());

        mailTemplateService.getAll(skip, limit, orderBy, order, sFields, sValues)
            .then(
                templates => {
                    dispatch(success(templates));
                    const { page } = templates;
                    if (page) {
                        if (templates.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: mailTemplateConstants.GET_ALL_REQUEST } }
    function success(templates) { return { type: mailTemplateConstants.GET_ALL_SUCCESS, templates } }
    function failure(error) { return { type: mailTemplateConstants.GET_ALL_FAILURE, error } }
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function getById(id) {
    return dispatch => {
        dispatch(request());
        mailTemplateService.getById(id)
            .then(
                template => dispatch(success(template)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: mailTemplateConstants.GET_ONE_REQUEST } }
    function success(template) { return { type: mailTemplateConstants.GET_ONE_SUCCESS, template } }
    function failure(error) { return { type: mailTemplateConstants.GET_ONE_FAILURE, error } }
}

function getUserList(projects, userType, limit, search) {
    return dispatch => {
        dispatch(request());
        mailTemplateService.getUsersList(projects, userType, limit, search)
            .then(
                template => dispatch(success(template)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: mailTemplateConstants.GET_USERS_REQUEST } }
    function success(template) { return { type: mailTemplateConstants.GET_USERS_SUCCESS, template } }
    function failure(error) { return { type: mailTemplateConstants.GET_USERS_FAILURE, error } }
}

/**
 * Add new organization
 * 
 * @param {*} mailTemplate 
 */
function addNewTemplate(template) {
    return dispatch => {
        dispatch(request(template));

        mailTemplateService.addNewTemplate(template)
            .then(
                mailTemplate => {
                    dispatch(success(mailTemplate));
                    dispatch(alertActions.success('Mail Template created successfully'));
                    setTimeout(() => {
                        history.push('/mailTemplate');
                    }, 800);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(template) { return { type: mailTemplateConstants.ADD_MAIL_TEMPLATE_REQUEST, template } }
    function success(template) { return { type: mailTemplateConstants.ADD_MAIL_TEMPLATE_SUCCESS, template } }
    function failure(error) { return { type: mailTemplateConstants.ADD_MAIL_TEMPLATE_FAILURE, error } }
}

/**
 * Update organization data 
 * 
 * @param {*} templatesDate - organization Data
 */
function updateTemplate(template, id) {
    return dispatch => {
        dispatch(request());

        mailTemplateService.update(template, id)
            .then(
                template => {
                    dispatch(success(template));
                    dispatch(alertActions.success('Updated successfully'));

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(template) { return { type: mailTemplateConstants.UPDATE_MAIL_TEMPLATE_REQUEST, template } }
    function success(template) { return { type: mailTemplateConstants.UPDATE_MAIL_TEMPLATE_SUCCESS, template } }
    function failure(error) { return { type: mailTemplateConstants.UPDATE_MAIL_TEMPLATE_FAILURE, error } }
}

function sendTemplate(template) {
    return dispatch => {
        dispatch(request());

        mailTemplateService.sendTemplate(template)
            .then(
                template => {
                    dispatch(success(template));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(template) { return { type: mailTemplateConstants.SEND_MAIL_TEMPLATE_REQUEST, template } }
    function success(template) { return { type: mailTemplateConstants.SEND_MAIL_TEMPLATE_SUCCESS, template } }
    function failure(error) { return { type: mailTemplateConstants.SEND_MAIL_TEMPLATE_FAILURE, error } }
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function deleteTemplate(id) {
    return dispatch => {
        dispatch(request(id));

        mailTemplateService.deleteTemplate(id)
            .then(
                org => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 800);
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: mailTemplateConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: mailTemplateConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: mailTemplateConstants.DELETE_FAILURE, id, error } }
}

/**
 * A method to activate / deactivate the org
 *
 * @param {*} id contains org id
 * @param {*} status contains boolean value of org to update. e-g true - active, false - deactive
 * @param {*} callBack contains a method to call back of successfull response
 * @returns
 */
function setActiveStatus(id, status, callBack) {
    return dispatch => {
        dispatch(request(id));

        mailTemplateService.setActivateStatus(id, status)
            .then(
                org => {
                    dispatch(success(id, org));
                    dispatch(alertActions.success('Updated successfully'));
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: mailTemplateConstants.ACTIVE_UPDATE_REQUEST, id } }
    function success(id, org) { return { type: mailTemplateConstants.ACTIVE_UPDATE_SUCCESS, id, org } }
    function failure(id, error) { return { type: mailTemplateConstants.ACTIVE_UPDATE_FAILURE, id, error } }
}

/**
 * 
 * @param {row index} id 
 * @param {*} isActive 
 * @param {*} callBack 
 */
function activeStatus(id, isActive, callBack = () => { }) {
    return dispatch => {
        dispatch(request(id));

        mailTemplateService.activeStatus(id, isActive)
            .then(
                user => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Updated successfully'));
                    callBack();
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: mailTemplateConstants.MAIL_TEMPLATE_STATUS_REQUEST, id } }
    function success(id) { return { type: mailTemplateConstants.MAIL_TEMPLATE_STATUS_SUCCESS, id } }
    function failure(id, error) { return { type: mailTemplateConstants.MAIL_TEMPLATE_STATUS_FAILURE, id, error } }
}