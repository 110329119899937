//import constants
import { loanProjectsConstants } from '../constants';

//import services
import { loanProjectsService, userService, orgService } from '../services';

//import actions
import { alertActions } from './';

//import helper
import { history, getUser } from '../helpers';

export const loanProjectActions = {
    getLoanProjects,
    create,
    delete: _delete,
    getReportActivity,
    updateFunderSettings,
    createFunderSettings,
    isProjectNameExists,
    getById,
    getUsersInOrg,
    update,
    getAllOrg,
    checkLoanProjectFunder,
    getFunderSettingsConfigDatas,
    getBasicFunderSettings,
    getAllFunders
}

/**
 * check loan project funder
 * @param {*} id 
 */
function checkLoanProjectFunder(id) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.checkLoanProjectFunder({ project_id: id })
            .then(
                projects => {
                    dispatch(success(projects));
                    if (projects.message === "Success") 
                    {
                        history.push({
                            pathname: `/loanProjects/${id}/funders`,
                            state: {
                                page: 'loanFundersList',
                                pageData: {
                                    project_id: id
                                }
                            }
                        });
                    }else{
                        history.push({
                                    pathname: `/loanProjects/${id}/funders/new`,
                                    state: {
                                        page: 'loanFunderEdit',
                                        pageData: {
                                            project_id: id,
                                            funder_id: projects.data
                                        }
                                    }
                                });
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_REQUEST } }
    function success(projects) { return { type: loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_FAILURE, error } }

}

/**
 *Get project listing page details
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [exactMatch=false]
 * @param {*} [userId=null] - default value null
 * @returns
 */
function getLoanProjects(skip, limit, orderBy, order, sFields, sValues, exactMatch = false, userId = null, query = { isDelete: false }) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.getAll(skip, limit, orderBy, order, sFields, sValues, exactMatch, userId, query)
            .then(
                projects => {
                    dispatch(success(projects));
                    const { page } = projects;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_REQUEST } }
    function success(projects) { return { type: loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_FAILURE, error } }
}


/**
 * Get all funder listing page
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} projectId - Loanproject_id
 * @param {*} exactMatch 
 */
 function getAllFunders(skip, limit, orderBy, order, sFields, sValues, projectId) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.getAllFunders(skip, limit, orderBy, order, sFields, sValues, projectId)
            .then(
                funders => dispatch(success(funders)),
                error => dispatch(failure(error.toString()))
            );

    };
    function request() { return { type: loanProjectsConstants.GET_ALL_LOAN_FUNDERS_REQUEST } }
    function success(funders) { return { type: loanProjectsConstants.GET_ALL_LOAN_FUNDERS_SUCCESS, funders } }
    function failure(error) { return { type: loanProjectsConstants.GET_ALL_LOAN_FUNDERS_FAILURE, error } }
}

/**
 * A method call API to create the loan project details
 *
 * @param {*} formData contains the loan project details data to create
 * @returns
 */
function create(formData) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.create(formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Project added successfully"))
                    // dispatch(alertActions.success("Project Added Successfully"));
                    // since we clear message on history push
                    // move page after 800 ms
                    setTimeout(() => {
                        history.push(`/loanProjects/${data.id}/funders/new`);
                    }, 800);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: loanProjectsConstants.CREATE_LOAN_PROJECT_REQUEST } }
    function success(data) { return { type: loanProjectsConstants.CREATE_LOAN_PROJECT_SUCCESS, data } }
    function failure(error) { return { type: loanProjectsConstants.CREATE_LOAN_PROJECT_FAILURE, error } }
}

/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - project_id
 * @returns
 */
function _delete(id, page = 'loanProjects') {
    return dispatch => {
        dispatch(request(id));

        loanProjectsService.delete(id, page)
            .then(
                data => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 1000);
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };


    function request() { return { type: loanProjectsConstants.DELETE_LOAN_PROJECT_REQUEST } }
    function success(id) { return { type: loanProjectsConstants.DELETE_LOAN_PROJECT_SUCCESS, id } }
    function failure(error) { return { type: loanProjectsConstants.DELETE_LOAN_PROJECT_FAILURE, error } }
}

/**
 * Get Report Activity Listing 
 */
function getReportActivity(skip, limit, orderBy, order, sFields, sValues, projectId) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.getReportActivity(skip, limit, orderBy, order, sFields, sValues, projectId)
            .then(
                reportsList => {
                    dispatch(success(reportsList));
                    const { page } = reportsList;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.GETALL_REPORT_ACTIVITY_REQUEST } }
    function success(reportsList) { return { type: loanProjectsConstants.GETALL_REPORT_ACTIVITY_SUCCESS, reportsList } }
    function failure(error) { return { type: loanProjectsConstants.GETALL_REPORT_ACTIVITY_FAILURE, error } }
}

/**
 * An Action method to get the funder basic settings for the project
 *
 * @param {*} formData contains the data to get the settings 
 * @param {*} funderId contains the funder id to get the settings
 */
function updateFunderSettings(formData, funderId) {
    return dispatch => {
        dispatch(request());
        loanProjectsService.updateFunderSettings(formData, funderId)
            .then(
                data => {
                    dispatch(success(data));
                    // update over due in projects
                    // dispatch(validationActions.updateOverDueContracts(0, formData.project_id));
                    dispatch(alertActions.success("Updated successfully"));
                    setTimeout(() => {
                        history.push({
                            pathname: `/loanProjects/${formData.project_id}/funders/${funderId}/reset`,
                            state: {
                                page: 'loanFunderEdit',
                                pageData: {
                                    funder_id: funderId
                                },
                            }
                        });
                    }, 100);
                    history.push(`/loanProjects/${formData.project_id}/funders/${funderId}/reset`);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.UPDATE_LOAN_FUNDER_SETTINGS_REQUEST } }
    function success(data) { return { type: loanProjectsConstants.UPDATE_LOAN_FUNDER_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: loanProjectsConstants.UPDATE_LOAN_FUNDER_SETTINGS_FAILURE, error } }
}

/**
 * An Action method to Create the funder basic settings for the project
 * @param {*} formData contains the data to create the settings
 */
function createFunderSettings(formData) {
    return dispatch => {
        dispatch(request());
        loanProjectsService.createFunderSettings(formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Created successfully"));
                    // history.push(`/projects/${formData.project_id}/funders`);
                    setTimeout(() => {
                        history.push({
                            pathname: `/loanProjects/${formData.project_id}/funders/${data.data}`,
                            state: {
                                page: 'loanFunderEdit',
                                pageData: {
                                    funder_id: data.data
                                },
                            }
                        });
                    }, 100);
                    history.push(`/loanProjects/${formData.project_id}/funders/`);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.CREATE_LOAN_FUNDER_SETTINGS_REQUEST } }
    function success(data) { return { type: loanProjectsConstants.CREATE_LOAN_FUNDER_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: loanProjectsConstants.CREATE_LOAN_FUNDER_SETTINGS_FAILURE, error } }
}

/**
 * A service call to check whether the project name is exists or not
 * 
 * @param {*} data 
 * @param {*} callBack 
 */
function isProjectNameExists(data, callBack) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.isProjectNameExists(data)
            .then(
                data => {
                    dispatch(success(data.data));
                    callBack(data.data);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.IS_LOAN_PROJECT_NAME_EXISTS_REQUEST } }
    function success(projects) { return { type: loanProjectsConstants.IS_LOAN_PROJECT_NAME_EXISTS_SUCCESS, projects } }
    function failure(error) { return { type: loanProjectsConstants.IS_LOAN_PROJECT_NAME_EXISTS_FAILURE, error } }
}

/**
 *Get single project view
 *
 * @param {*} id - project_id
 * @param {*} [callBack=() => { }]
 * @returns
 */
function getById(id, page = 'loanProjects', callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.getById(id, page)
            .then(
                project => {
                    dispatch(success(project.data))
                    let orgId = project.data.owners.org_id ? project.data.owners.org_id._id : getUser().org.id;
                    dispatch(getUsersInOrg(orgId));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: loanProjectsConstants.GET_ONE_LPJT_REQUEST } }
    function success(project) { return { type: loanProjectsConstants.GET_ONE_LPJT_SUCCESS, project } }
    function failure(error) { return { type: loanProjectsConstants.GET_ONE_LPJT_FAILURE, error } }
}

/**
 * A method call API to update the project details
 *
 * @param {*} id contains the id of project to update
 * @param {*} formData contains the project details data to update
 * @returns
 */
function update(id, formData) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.update(id, formData)
            .then(
                data => {
                    dispatch(success(data));
                    // history.push("/projects");
                    dispatch(alertActions.success("Updated Successfully"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: loanProjectsConstants.UPDATE_LOAN_PROJECT_REQUEST } }
    function success(data) { return { type: loanProjectsConstants.UPDATE_LOAN_PROJECT_SUCCESS, data } }
    function failure(error) { return { type: loanProjectsConstants.UPDATE_LOAN_PROJECT_FAILURE, error } }
}

/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
function getAllOrg(text, page) {
    return dispatch => {
        dispatch(request());

        orgService.getOrganizationsBySearchKey(text, page)
            .then(
                org => {
                    let orgs = org.data.map((data) => {
                        data.label = data.name;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(orgs));
                    // callBack();
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.GET_ALL_ORGS_REQUEST } }
    function success(orgs) { return { type: loanProjectsConstants.GET_ALL_ORGS_SUCCESS, orgs } }
    function failure(error) { return { type: loanProjectsConstants.GET_ALL_ORGS_FAILURE, error } }
}

/**
 * A meethod to get users in organizations id except role onsite manager
 *
 * @param {*} orgId contains organizations id
 * @returns
 */
function getUsersInOrg(orgId) {
    return dispatch => {
        dispatch(request());
        userService.getByOrgIdHos(orgId)
            .then(
                user => {
                    let users = user.data.map((data) => {
                        data.label = `${data.firstName} ${data.lastName}`;
                        // add star before  inactive
                        data.label = data.isActive ? data.label : `*${data.label}`;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.GET_ORG_USERS_REQUEST } }
    function success(users) { return { type: loanProjectsConstants.GET_ORG_USERS_SUCCESS, users } }
    function failure(error) { return { type: loanProjectsConstants.GET_ORG_USERS_FAILURE, error } }
}



/**
 * An Action method to get funder settings basic config datas
 *
 */
function getFunderSettingsConfigDatas(id, orgId) {
    return dispatch => {
        dispatch(request());
        loanProjectsService.getFunderSettingsConfigDatas(id, orgId)
            .then(
                settings => dispatch(success(settings.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.GET_LOAN_FUNDER_CONFIG_SETTINGS_REQUEST } }
    function success(settings) { return { type: loanProjectsConstants.GET_LOAN_FUNDER_CONFIG_SETTINGS_SUCCESS, settings } }
    function failure(error) { return { type: loanProjectsConstants.GET_LOAN_FUNDER_CONFIG_SETTINGS_FAILURE, error } }
}

/**
 * An Action method to get the funder basic settings for the project
 *
 * @param {*} funderId contains the  project id to get the settings
 */
function getBasicFunderSettings(funderId, orgId) {
    return dispatch => {
        dispatch(request());
        loanProjectsService.getBasicFunderSettings(funderId, orgId)
            .then(
                settings => dispatch(success(settings.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: loanProjectsConstants.GET_LOAN_FUNDER_BASIC_SETTINGS_REQUEST } }
    function success(data) { return { type: loanProjectsConstants.GET_LOAN_FUNDER_BASIC_SETTINGS_SUCCESS, data } }
    function failure(error) { return { type: loanProjectsConstants.GET_LOAN_FUNDER_BASIC_SETTINGS_FAILURE, error } }
}