import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

// Components
import { SvgLoadIcon } from '../SvgIcons/SvgIcons';

const styles = theme => ({
  input: {
    display: 'flex !important',
    height: 16
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    display: 'none'
  },
  chip: {
    margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    borderRadius: 0
    // backgroundColor: '#fff'
  },
  chipFocused: {
    backgroundColor: '#36aebe',
    color: '#fff'
  },
});
/**
 * No Option Message
 * @param {*} props 
 * @returns 
 */
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
/**
 * Input Component
 * @param {*} param0 
 * @returns 
 */
function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}
/**
 * Input Component
 * @param {*} props 
 * @returns 
 */
function Control(props) {
  const customClass = props.selectProps.validators && props.selectProps.validators.length > 0 ? 'required autocomplete-fixed' : 'autocomplete-fixed';
  const sample = useRef(props.selectProps.name);
  // if(props.selectProps.validators.length ===0 && (props.selectProps.name==="sweatEquityModel" ||props.selectProps.name==="nonSweatEquityModel")){
  //   sample.current.validate()
  // }
  return (
    <TextValidator
      ref={sample}
      fullWidth
      name={props.selectProps.name}
      label={props.selectProps.placeholder}
      variant="standard"
      validators={props.selectProps.validators}
      margin={props.selectProps.margin}
      value={props.selectProps.value && props.selectProps.value.length > 0 ? ' ' : ''}
      errorMessages={props.selectProps.errorMessages}
      className={customClass}
      disabled={props.isDisabled}
      focused={props.isFocused ? true : props.selectProps.needManualFocus ? props.selectProps.value && props.selectProps.value.length > 0 : false}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          ref: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
    />

  );
}
/**
 * Indicator Separator
 * @param {*} param0 
 * @returns 
 */
const IndicatorSeparator = ({ innerProps }) => {
  return (
    null
  );
};
/**
 * Dropdown Indicator
 * @param {*} param0
 * @returns 
 */
const DropdownIndicator = ({ innerProps, ...props }) => {
  if (props.isDisabled === true) {
    return null
  }
    else if(props.selectProps.module==='SF'){
      return (
        <i className="fa fa-caret-down" style={{backgroundColor:'#36aebe1c !important'}}></i>
      );
    } 
  else{
    return (
      <i className="fa fa-caret-down autocomple_dropdwn" ></i>
    );
  }

};
/**
 * Option
 * @param {*} props 
 * @returns 
 */
function Option(props) {
  return (
    <React.Fragment>
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        title={props.data.title ? props.data.title : props.data.value}
        style={{
          fontWeight: props.isSelected ? 500 : 400
        }}
        {...props.innerProps}
      >
        <Checkbox checked={props.isSelected} />
        <ListItemText className="autocomplete-list" primary={props.data.label} />

      </MenuItem>
      {
        props.options.length < props.selectProps.dataCount && props.options.findIndex(elem => elem.value === props.value) === (props.options.length - 1) &&
        <div className='autoCompleteLoader'>
          <SvgLoadIcon />
        </div>
      }
    </React.Fragment>

  );
}
/**
 * Placeholder
 * @param {*} props 
 * @returns 
 */
function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
/**
 * Singile value component
 * @param {*} props 
 * @returns 
 */
function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}
/**
 * Multiple value component
 * @param {*} props 
 * @returns 
 */
function MultiValue(props) {
  if (props.selectProps.value.some(elem => elem.value === 0) && props.data.value === 0) {
    return (
      <Chip
        tabIndex={-1}
        label={`${props.children} (${props.selectProps.dataCount ? props.selectProps.dataCount : props.selectProps.value.length - 1})`}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
      />
    )
  } else if (!props.selectProps.value.some(elem => elem.value === 0) && (props.selectProps.value.findIndex(elem => elem.value === props.data.value) === props.selectProps.value.length - 1)) {
    return (
      <Chip
        tabIndex={-1}
        label={`${props.selectProps.value.length} Selected`}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
      />
    );
  } else {
    return null
  }
}
/**
 * Value Container
 * @param {*} props 
 * @returns 
 */
function ValueContainer(props) {
  const diabledCSS = props.isDisabled && props.isDisabled === true ? props.selectProps.classes.valueContainer + ' disabled' : props.selectProps.classes.valueContainer;
  return <div className={diabledCSS}>{props.children}</div>;
}

const components = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  SingleValue,
  MultiValue,
  ValueContainer,
  IndicatorSeparator,
  DropdownIndicator
};

const MAX_CONTENT = 30;

/**
 * Autocomplete Multi select Component
 * @class AutoCompleteMultiMUI
 * @extends {React.Component}
 */
class AutoCompleteMultiMUI extends React.Component {
  state = {
    autocomplete: [],
    suggestions: [],
    search: '',
    suggestionCount: 0
  };
  /**
   * Component Did Mount
   * 
   * [1]  - Check if selectedValue && autocomplete has value.
   * [2]  - Check if autocomplete & selectedValue are different.
   * [3]  - Check if needSelectAll is true, then unshift select all object, else default selectedValue
   * [4]  - Slice the suggession by MAX_CONTENT
   */
  componentDidMount() {
    let { autocomplete, suggestions, suggestionCount } = this.state;
    const { selectedValue, needSelectAll } = this.props;
    // [1]
    if (selectedValue && autocomplete) {
      // [2]
      if (selectedValue.value !== autocomplete.value) {
        // [3]
        if (needSelectAll) {
          autocomplete = [{ value: 0, label: "Select All" }, ...selectedValue]
        } else {
          autocomplete = selectedValue
        }
      }
    }
    suggestionCount = this.props.suggestions.length
    // [4]
    suggestions = this.props.suggestions.slice(0, MAX_CONTENT);
    this.setState({
      autocomplete,
      suggestions,
      suggestionCount
    })
  }
  /**
   * Component Will Recieve Props
   * @param {*} newProps 
   * 
   * [1]  - Check if selectedValue && autocomplete has value.
   * [2]  - Check if autocomplete & selectedValue are different.
   * [3]  - Check if needSelectAll is true, then unshift select all object, else default selectedValue
   * [4]  - Slice the suggession by MAX_CONTENT
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    // focusedField
    let { autocomplete, suggestions, suggestionCount } = this.state;
    const { selectedValue, needSelectAll } = newProps;
    let newState = false
    // [1]
    if (selectedValue && autocomplete) {
      // [2]
      if (selectedValue.value !== autocomplete.value) {
        newState = true;
        // [3]
        if (needSelectAll) {
          autocomplete = [{ value: 0, label: "Select All" }, ...selectedValue]
        } else {
          autocomplete = selectedValue
        }
      }
    }
    // [4]
    if (suggestions && (suggestions.filter(this.arrayComparer(newProps.suggestions.slice(0, suggestions.length))).length !== 0 || suggestions.length === 0)) {
      newState = true;
      suggestionCount = newProps.suggestions.length
      suggestions = newProps.suggestions.slice(0, MAX_CONTENT);
    }
    if (newState) {
      this.setState({
        autocomplete,
        suggestions,
        suggestionCount
      })
    }
  }
  /**
   * Array comparer - To check if array datas are same
   * @param {*} otherArray 
   * @returns 
   */
  arrayComparer = (otherArray) => {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.label === current.label && other.display === current.display
      }).length === 0;
    }
  }
  /**
   * Handle Change
   * @param {*} value 
   * @param {*} param1 
   * 
   * [1]  - If select-option event is triggered and select all is selected, then set All Suggessiions
   * [2]  - If deselect-option event is triggered and select all is selected, then Clear the value, else filter the de-selected value
   * [3]  - If pop-value event is triggered (back-space in Input), then slice one by one from last
   */
  handleChange = (value, { action, option }) => {
    switch (action) {
      case "select-option":
        // [1]
        if (option.value === 0) {
          value = this.props.suggestions;
        }
        break;
      case "deselect-option":
        // [2]
        if (option.value === 0) {
          value = null;
        } else {
          value = value.filter((val) => val.value !== 0)
        }
        break;
      case "pop-value":
        // [3]
        if (value) {
          if (value.length > 0 && value[0].value === 0) {
            value.splice(0, 1)
          }
          value.splice(0, -1)
        }
        break;
      default: break;
    }
    this.setState({
      autocomplete: value,
    });
    if (option && option.value === 0) {
      action === "select-option" ? this.props.handleAutoCompolete(value, true) : this.props.handleAutoCompolete([], false);
    } else {
      this.props.handleAutoCompolete(value || [], false);
    }
  };

  /**
   * A method to get the name from suggestion if only id available
   *
   * @param {*} suggestions contains suggestions / options
   * @param {*} id contains id to filter out the name / label
   * @returns name / label for the id passed, else emtpy string
   * @memberof AutoComplete
   */
  getValueIfNoName(suggestions, id) {
    const val = suggestions.filter((opt) => {
      const crOptId = opt.id || opt.value;
      return crOptId === id;
    });

    return val.length > 0 ? val[0].label : '';
  }
  /**
   * Handle Lazy loading
   * 
   * [1]  - Check if suggestionCount & suggestions.length different
   * [2]  - Check if any search keyword has value, then filter the sugession
   * [3]  - Update the suggessions by MAX_CONTENT
   */
  handleLazyLoading = () => {
    let { suggestions, search, suggestionCount } = this.state;
    // [1]
    if (suggestionCount !== suggestions.length) {
      // [2]
      if (search) {
        let regexText = new RegExp(search, 'i');
        let filterSuggestions = this.props.suggestions.filter(elem => regexText.test(elem.label));
        suggestionCount = filterSuggestions.length;
        // [3]
        suggestions = filterSuggestions.slice(0, suggestions.length + MAX_CONTENT);
      } else {
        // [3]
        suggestions = this.props.suggestions.slice(0, suggestions.length + MAX_CONTENT);
        suggestionCount = this.props.suggestions.length;
      }
      this.setState({ suggestions, suggestionCount });
    }
  }
  /**
   * Handle Search
   * @param {*} search 
   */
  handleSearch = (search) => {
    let { suggestions, suggestionCount } = this.state;
    let regexText = new RegExp(search, 'i');
    suggestions = this.props.suggestions.filter(elem => regexText.test(elem.label));
    suggestionCount = suggestions.length;
    suggestions = suggestions.slice(0, MAX_CONTENT);
    this.setState({ suggestions, suggestionCount });
  }
  /**
   * render html
   * @returns
   * 
   * [1]  - Sorting
   * [2]  - Check for selectall and add
   */
  render() {
    let { classes, className, selectedValue, disabled, name, needSelectAll, needManualFocus, module } = this.props;
    let { search, suggestions, suggestionCount } = this.state;

    // [1]
    if (suggestions.length > 0) {
      if (selectedValue && selectedValue.length > 0 && selectedValue[0].value !== 0 && !search) {
        selectedValue.forEach(data => {
          let index = suggestions.findIndex(elem => elem.value === data.value);
          suggestions = suggestions.filter((elem, i) => index !== i)
        })
        suggestions = [...selectedValue, ...suggestions]
      }
      // [2]
      if (needSelectAll) {
        suggestions = [{ value: 0, label: "Select All" }, ...suggestions]
        if (selectedValue.length === suggestionCount) {
          selectedValue = [{ value: 0, label: "Select All" }, ...selectedValue]
        }
      }
    }
    let val = this.state.autocomplete;
    if (selectedValue && selectedValue.length >= 0) {
      val = selectedValue
    }
    return (
      <Select
        classes={classes}
        className={className}
        options={suggestions}
        components={components}
        value={val}
        onChange={this.handleChange}
        placeholder={this.props.placeholder}
        validators={this.props.validators}
        errorMessages={this.props.errorMessages}
        name={name}
        isDisabled={disabled}
        module={module}
        isMulti
        isClearable={false}
        hideSelectedOptions={false}
        closeMenuOnSelectboolean={false}
        closeMenuOnSelect={false}
        dataCount={suggestionCount}
        captureMenuScroll={this.props.suggestions.length > 30}
        onMenuScrollToBottom={() => {
          this.handleLazyLoading(search)
        }}
        inputValue={search}
        onInputChange={(search) => {
          this.setState({ search })
          if (this.state.search !== search) {
            setTimeout(() => {
              this.handleSearch(search);
            }, 1000)
          }
        }}
        // onFocus={(e)=>this.handleFocus(e)}
        // onBlur={onBlur}
        onMenuClose={() => {
          if (this.props.suggestions.length > 30) {
            this.setState({ suggestions: this.props.suggestions.slice(0, MAX_CONTENT) })
          }
        }}
        needManualFocus={needManualFocus}
      />

    );
  }
}
//default props
AutoCompleteMultiMUI.defaultProps = {
  suggestions: [],
  selectedValue: [],
  disabled: false,
  needSelectAll: false,
  name: 'autocomplete',
  placeholder: "",
  validators: [],
  errorMessages: [],
  /**Handle autocomplete event */
  handleAutoCompolete: () => { },
  margin: 'dense',
  needManualFocus: false,
  tooltipOnHover: false,
  toolTipTitle: "",
  module:""
}
//prop types
AutoCompleteMultiMUI.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.any,
  disabled: PropTypes.bool,
  handleAutoCompolete: PropTypes.func.isRequired,
  selectedValue: PropTypes.array,
  toolTipTitle: PropTypes.string,
  tooltipOnHover: PropTypes.bool,
  module: PropTypes.string
};
/**Export Component*/
export default withStyles(styles)(AutoCompleteMultiMUI);