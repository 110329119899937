import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Import Helpers
import { history, globalVariable, filterSearchFields, convertDateWithTimeZone } from '../../helpers';


//Import Components
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import TableComponent from '../../components/Table/TableComponent';
import HelpFloater from "../../components/HelpFloater/HelpFloater";

// import Action
import { contractAction } from '../../actions/contractAction'
import { searchActions } from '../../actions';
import { confActions } from '../../actions/configuration';
import { helpActions } from '../../actions';
import FieldFilter from '../../components/FieldFilter/FieldFilter';


class ContractsList extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            sFields: [],
            sValues: [],
            fieldFilter: false,
            openHelp: false,
            firstrender: false

        }
    }

    /**
     * component did mount
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * component will mount
     */
    UNSAFE_componentWillMount() {
        const { loanProjectId, userId, contract: { page: { fieldFilters } } } = this.props
        this.props.getHelp();
        this.setState({ firstrender: true })
        if (fieldFilters && fieldFilters.skip) {
            this.props.getAll(loanProjectId, fieldFilters.skip, globalVariable.subPageTabtableRowSize, fieldFilters.orderBy, fieldFilters.order, '', '', false, userId)
        } else {
            this.props.getAll(loanProjectId, 1, globalVariable.subPageTabtableRowSize, 'contractNumber', 'asc', '', '', false, userId)
        }
        setTimeout(() => {
            this.props.getFieldFilter('contracts');
        }, 100);

        this.props.clearContractDetails()
    }

    // handle addition click
    handleAddActionClick() {
        const { match, loanProjectId } = this.props;
        history.push({
            pathname: `${match.url}/new`,
            state: {
                page: 'contractNew',
                pageData: {
                    project_id: loanProjectId
                },
            }
        });
    }
    /**
     * Handle Table Pagination On Change Event
    */
    onChangePageTable = (skip, limit) => {
        const { contract, userId, searchData: { sFields, sValues }, loanProjectId } = this.props;
        const { page } = contract;
        this.props.getAll(loanProjectId, skip, limit, page.fieldFilters.orderBy, page.fieldFilters.order, sFields, sValues, false, userId);
    };
    /**
    * Handle Table Sort Event
    */
    onSortRequest = (orderBy, order) => {
        const { contract, userId, loanProjectId, searchData: { sFields, sValues } } = this.props;
        const { page } = contract;
        this.props.getAll(loanProjectId, page.fieldFilters.skip, page.fieldFilters.limit, orderBy, order, sFields, sValues, false, userId);
    };
    /**
    * Handle Table Navigation Event
    */
    handleNavigateAction = (id, field, item) => {
        const { match } = this.props;
        switch (field) {
            case "contractNumber":
                history.push({
                    pathname: `${match.url}/${id}`,
                    state: {
                        page: 'contractEdit',
                        pageData: {
                            contract_id: id
                        }
                    }
                });
                break;
            case "orgName":
                //history.push(`/organizations/${item.preparer.org_id}`);
                history.push({
                    pathname: `/organizations/${item.preparer.org_id}`,
                    state: {
                        page: 'organizationEdit',
                        pageData: {
                            org_id: item.preparer.org_id
                        }
                    }
                });
                break;
            case "preparerName":
                let orgId = item.preparer.org_id
                let userid = item.preparer.user_id
                history.push({
                    pathname: `/users/${userid}`,
                    state: {
                        page: 'userEdit',
                        orgId,
                        pageData: {
                            user_id: parseInt(userid, 10)
                        }
                    }
                });
                //history.push(`/users/${item.preparer.user_id}`);
                break;


            default: break;

        }
    }
    /**
       * Handle Clear Fileter Action
       */
    handleClearFilterActionClick = () => {
        const { contract, loanProjectId, userId } = this.props;
        const { page } = contract;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getAll(loanProjectId, page.fieldFilters.skip, page.fieldFilters.limit, page.fieldFilters.orderBy, page.fieldFilters.order, [], [], false, userId);
    };

    /**
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        const { contract } = this.props;
        const { page } = contract;
        this.props.changeFieldFilter("contracts", page.fieldFilters.visibleColumns);
    };

    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    handleFieldFilterOnClose = () => {
        const { contract } = this.props;
        const { page } = contract;
        this.props.changeFieldFilter("contracts", page.fieldFilters.visibleColumns);
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns, key is a column name and
     * values contains boolean of checked status
     *
     * @memberof Users
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("contracts", updatedColumns);
        } else {
            this.props.changeFieldFilter("contracts", [...this.props.visibleColumns, key]);
        }
    }

    handleFieldFilterOnSave = () => {
        const { loanProjectId } = this.props;
        this.props.updateFieldFilter("contracts", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { contract, userId, searchData: { sFields, sValues } } = this.props;
            const { page } = contract;
            this.props.getAll(loanProjectId, page.fieldFilters.skip, page.fieldFilters.limit, page.fieldFilters.orderBy, page.fieldFilters.order, sFields, sValues, false, userId);
        });
    }
    

    /**
    * Handle Table Search Input Box Event
    */
    onInputChangeRequest = (searchField, searchKey) => {
        const { contract, userId, loanProjectId, searchData: { sFields, sValues } } = this.props;
        const { page } = contract;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.searchActions({ sFields, sValues });
        this.props.getAll(loanProjectId, 1, globalVariable.subPageTabtableRowSize, page.fieldFilters.orderBy, page.fieldFilters.order, sFields, sValues, false, userId);
    };
    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, needPadding, isSubPage,
            contract, loanProjectId, searchData: { sFields, sValues }, columnsMap, visibleColumns, location: { pathname }, helpData } = this.props;
        let { pageAccess } = this.props;
        // pageAccess = ["DELETE"]
        if (contract.page.fieldFilters && contract.page.fieldFilters.headerCols) {
            var header = contract.page.fieldFilters.headerCols
            var field = contract.page.fieldFilters.fieldCols
        }
        contract.data.map(item=>{
            if(item.commitmentStartDate != null && item.commitmentStartDate != ''){
                return item.commitmentStartDate = convertDateWithTimeZone(item.commitmentStartDate)
            }
        })

        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={"ALL CONTRACT NUMBERS"}
                isSubPage={isSubPage}
                needPadding={needPadding}
                handleAddActionClick={() => this.handleAddActionClick(loanProjectId)}
                handleHelpActionClick={this.handleHelpActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                    //this.handleClearFilterActionClick()
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
            >
                <TableComponent
                    page={contract.page.fieldFilters.skip}
                    rowsPerPage={contract.page.fieldFilters.limit}
                    data={contract.data}
                    header={header}
                    field={field}
                    search={field}
                    count={contract.count}
                    paginationVisible={contract.page.fieldFilters && !contract.page.fieldFilters.lastPage ? true : false}
                    onSortRequest={this.onSortRequest}
                    highlightField={['contractNumber']}
                    actions={pageAccess}
                    prevNext={false}
                    sFields={filterSearchFields(sFields)}
                    onChangePageTable={this.onChangePageTable}
                    sValues={sValues}
                    order={contract.page.fieldFilters.order}
                    orderBy={contract.page.fieldFilters.orderBy}
                    onInputChangeRequest={this.onInputChangeRequest}
                    links={["contractNumber", "orgName", "preparerName"]}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    noDataErrorMessage={"No Records Found"}
                    handleNavigateAction={this.handleNavigateAction}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                >

                </TableComponent>
                <FieldFilter
                    open={this.state.fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                {this.state.openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Contract numbers"
                />}

            </ContentWrapper>
        );
    }
}

/**
 *
 */
ContractsList.defaultProps = {
    classes: {},
    theme: {},
    isReadOly: false,
    projectId: null,
    copyId: null,
    pageAccess: [],
    handleFormSaveActionClick: () => { },
    isSubPage: true
}


/**
 * Bind Component Property Types
 */
ContractsList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps, projects) => {
    const columnsData = state.configuration.fieldFilter['contracts'] || {}
    var contract = state.contract
    const pageAccess = state.pageAccess['contractsList'] || [];
    const searchIndexFor = 'contract';
    const searchData = state.search[searchIndexFor] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0];

    return {
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        contract,
        searchData,
        helpData
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        insertContract: () => dispatch(contractAction.insertContractDetails()),
        getAll: (loanProjectId, skip, limit, orderBy, order, sField, sValue, exactMatch, userId) => dispatch(contractAction.getAllContract(loanProjectId, skip, limit, orderBy, order, sField, sValue, exactMatch, userId)),
        searchActions: (data) => {
            return dispatch(searchActions.contract(data));
        },
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 19 })),
        clearContractDetails: () => dispatch(contractAction.clearContractDetails()),
       // deleteContractListItem: (id,successCallback) => dispatch(contractAction.delete(id,successCallback)),

    }
};

/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(ContractsList);
