import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

// TabContainer function
function TabContainer({ children, dir, className }) {
    return (
        <Typography component="div" dir={dir} className={className}>
            {children}
        </Typography>
    );
}

//default props
TabContainer.defaultProps = {
    children: '',
    dir: '',
    className: ''
}

//prop types
TabContainer.propTypes = {
    children: PropTypes.node,
    dir: PropTypes.string.isRequired,
    className: PropTypes.string
};

//export component
export default TabContainer;