//import constants
import { reportTablesConstants } from '../constants/reportTables';

//import actions
import { alertActions, pageAccessActions, validationActions } from './';

//import services
import { reportTablesServices, validationService } from '../services';

import { saveAs } from 'file-saver';

export const reportTablesActions = {
    getTable4Data,
    submitTable,
    unSubmitTable,
    getTable1Data,
    getTable2Data,
    getTable3Data,
    getTable5Data,
    getTable2DataUpdate,
    getTable3DataUpdate,
    updateTable4,
    createTable4,
    createTable3,
    createTable2,
    validationStatus,
    getTable1Errors,
    clearTables,
    getIssues,
    refreshValidations,
    downloadReportTable,
    downloadTable3Report,
    downloadTable4Report,
    checkIfTable4Exists,
    createTable5,
    updateTable5
}

/**
 * Method to get the table 4 datas
 *
 * @param {*} id report year table _id value
 */
function getTable4Data(id, type) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getTable4Data(id, type)
            .then(
                table4 => {
                    dispatch(success(table4.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_TABLE4_DATA_REQUEST } }
    function success(table4) { return { type: reportTablesConstants.GET_TABLE4_DATA_SUCCESS, table4 } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE4_DATA_FAILURE, error } }

}

/**
 * Method to get the table 2 datas
 *
 * @param {*} id report year table _id value
 */
function getTable2Data(id) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getTable2Data(id)
            .then(
                table2 => {
                    dispatch(success(table2.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_TABLE2_DATA_REQUEST } }
    function success(table2) { return { type: reportTablesConstants.GET_TABLE2_DATA_SUCCESS, table2 } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE2_DATA_FAILURE, error } }

}

/**
 * Method to update table 2 data
 *
 * @param {*} id contains table 2 _id
 * @param {*} formData contains reportYear_id - must
 */
function getTable2DataUpdate(id, data) {
    let newEntry = false
    if(data.new){
        newEntry = true
    }
    //handled for new table2Alternate creation validation issue
    delete data['new']
    return dispatch => {
        dispatch(request());
        reportTablesServices.getTable2DataUpdate(id, data)
            .then(
                table2 => {
                    dispatch(success());
                    if(!newEntry) {dispatch(alertActions.success("Updated Successfully"));}
                    setTimeout(() => dispatch(alertActions.clear()), 5000)

                    // update table 2 validations
                    dispatch(validationActions.updateValidations(0, 0, data.reportId, 2));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, data.reportId, 2));
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: reportTablesConstants.GET_TABLE2_UPDATE_REQUEST } }
    function success() { return { type: reportTablesConstants.GET_TABLE2_UPDATE_SUCCESS } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE2_UPDATE_FAILURE, error } }

}

/**
 * Method to get the table 3 datas
 *
 * @param {*} id report year table _id value
 */
function getTable3Data(id) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getTable3Data(id)
            .then(
                table3 => {
                    dispatch(success(table3));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: reportTablesConstants.GET_TABLE3_DATA_REQUEST } }
    function success(table3) { return { type: reportTablesConstants.GET_TABLE3_DATA_SUCCESS, table3 } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE3_DATA_FAILURE, error } }

}

/**
 * Method to update table 3 data
 *
 * @param {*} id contains table 3 _id
 * @param {*} formData contains reportYear_id - must
 */
function getTable3DataUpdate(id, data) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.updateTable3(id, data)
            .then(
                table3 => {
                    dispatch(success());
                    dispatch(alertActions.success("Updated Successfully"));
                    setTimeout(() => dispatch(alertActions.clear()), 5000)

                    // update table 3 validations
                    dispatch(validationActions.updateValidations(0, 0, data.reportYear_id, 3));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, data.reportYear_id, 3));
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_TABLE3_UPDATE_REQUEST } }
    function success() { return { type: reportTablesConstants.GET_TABLE3_UPDATE_SUCCESS } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE3_UPDATE_FAILURE, error } }

}


/**
 * Get Table 1 Data
 * @param {} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 * @param {*} projectData 
 */
function getTable1Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete = false, projectData, query) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getTable1Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete, projectData, query)
            .then(
                table1 => {
                    let {project_id, reportId} = projectData;
                    // let dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
                    table1.data && table1.data.length > 0 && table1.data.forEach(elem => {
                        let updatedAt = new Date(elem.updatedAt);

                        let modifiedDay = updatedAt.getDate() < 10 ? ('0' + updatedAt.getDate()) : updatedAt.getDate()

                        let modifiedMonth = updatedAt.getMonth() + 1 < 10 ? ('0' + (updatedAt.getMonth() + 1)) : updatedAt.getMonth() + 1

                        let modifiedHours = updatedAt.getHours() < 10 ? ('0' + (updatedAt.getHours())) : updatedAt.getHours()

                        let modifiedTime = updatedAt.getMinutes() < 10 ? ('0' + updatedAt.getMinutes()) : updatedAt.getMinutes();

                        let modifiedSeconds = updatedAt.getSeconds() < 10 ? ('0' + updatedAt.getSeconds()) : updatedAt.getSeconds();

                        let formatModifiedDate = `${modifiedMonth}/${modifiedDay}/${updatedAt.getFullYear()}, ${modifiedHours}:${modifiedTime}:${modifiedSeconds}`;
                        elem.updatedAt = formatModifiedDate;
                    })

                    if (table1?.isValidationCronTriggered != undefined && table1?.isValidationCronTriggered !=null && table1.isValidationCronTriggered == false) {
                        if (project_id && reportId) {
                            dispatch(validationActions.updateValidations(project_id,0, reportId, 0));
                        }   
                    }

                    dispatch(success(table1))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: reportTablesConstants.GET_TABLE1_DATA_REQUEST } }
    function success(table1) { return { type: reportTablesConstants.GET_TABLE1_DATA_SUCCESS, table1 } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE1_DATA_FAILURE, error } }
}


/**
 * Get Table 5 Data
 * @param {} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 * @param {*} projectData 
 */
function getTable5Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete = false, projectData) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getTable5Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete, projectData)
            .then(
                table5 => {
                    dispatch(success(table5))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: reportTablesConstants.GET_TABLE5_DATA_REQUEST } }
    function success(table5) { return { type: reportTablesConstants.GET_TABLE5_DATA_SUCCESS, table5 } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE5_DATA_FAILURE, error } }
}
/**
 * Clear Tables Data
 */
function clearTables(pageDefaults = {}) {
    return dispatch => {
        dispatch(clear());
    }

    function clear() { return { type: reportTablesConstants.CLEAR_TABLES_REQUEST, pageDefaults } }
}


/**
 * Method to submit the table 1, 2, 3, 4 data
 *
 * @param {*} id report year table _id value
 * @param {*} formData form datas to update
 */
function submitTable(id, formData, tableIndex = null) {
    console.log("dfefsdfsfsfds")
    return dispatch => {
        dispatch(request());

        reportTablesServices.submitTable(id, formData, tableIndex)
            .then(
                data => {
                    let valId = (data?.currRptId != null && data?.currRptId != undefined) ? data.currRptId : id;
                    dispatch(success(data));
                    dispatch(alertActions.success('The report has been submitted.'));
                    dispatch(pageAccessActions.getAccess(`table${tableIndex}`, { reportYear_id: id }));

                    // update over due in projects
                    const { overDueValidate: { site_id } } = data;
                    dispatch(validationActions.updateOverDueSites(site_id, 0));

                    // update table validations after un-submission
                    dispatch(validationActions.updateValidations(0, site_id, valId, 0));

                    if (id != valId) {
                        dispatch(validationActions.updateValidations(0, site_id, id, 0));
                    }
                },
                error => {
                    console.log("two",error.toString())
                    setTimeout(() => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    },4000)
                }
            );
    };


    function request() { return { type: reportTablesConstants.SUBMIT_TABLE_ONE_REQUEST } }
    function success(data) { return { type: reportTablesConstants.SUBMIT_TABLE_ONE_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.SUBMIT_TABLE_ONE_FAILURE, error } }
}

/**
 * Method to submit the table 1, 2, 3, 4 data
 *
 * @param {*} id report year table _id value
 * @param {*} formData form datas to update
 */
function unSubmitTable(id, formData, tableIndex = null) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.unSubmitTable(id, formData, tableIndex)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('The report has been un-submitted.'));
                    dispatch(pageAccessActions.getAccess(`table${tableIndex}`, { reportYear_id: id }));

                    // update over due in projects
                    const { overDueValidate: { site_id } } = data;
                    dispatch(validationActions.updateOverDueSites(site_id, 0));

                    // update table validations after un-submission
                    dispatch(validationActions.updateValidations(0, site_id, id, 0));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.UNSUBMIT_TABLE_ONE_REQUEST } }
    function success(data) { return { type: reportTablesConstants.UNSUBMIT_TABLE_ONE_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.UNSUBMIT_TABLE_ONE_FAILURE, error } }
}


/**
 * Method to update table 4 data
 *
 * @param {*} id contains table 4 _id
 * @param {*} formData contains reportYear_id - must
 */
function updateTable4(id, formData) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.updateTable4(id, formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('Updated Successfully'));
                    //setTimeout(()=>alertActions.clear(),5000)
                    // update table 4 validations
                    dispatch(validationActions.updateValidations(0, 0, formData.reportYear_id, 4));

                    //get data table data
                    dispatch(getTable4Data(formData.reportYear_id));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, formData.reportYear_id, 4));
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.UPDATE_TABLE4_REQUEST } }
    function success(data) { return { type: reportTablesConstants.UPDATE_TABLE4_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.UPDATE_TABLE4_FAILURE, error } }
}

/**
 * Method to update table 4 data
 *
 * @param {*} id contains table 4 _id
 * @param {*} formData contains reportYear_id - must
 */
function updateTable5(id, formData, clbk) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.updateTable5(id, formData)
        .then(
            data => {
                dispatch(success(data));
                dispatch(alertActions.success('Updated Successfully'));
                clbk();
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    }

    function request() { return { type: reportTablesConstants.UPDATE_TABLE5_REQUEST } }
    function success(data) { return { type: reportTablesConstants.UPDATE_TABLE5_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.UPDATE_TABLE5_FAILURE, error } }
}

/**
 * Method to create table 5 data
 *
 * @param {*} formData contains reportYear_id - must
 */

function createTable5(formData, clbk = () => {}) {
    return dispatch => {
        dispatch(request());
        reportTablesServices.createTable5(formData, clbk)
        .then(
            data => {
                dispatch(success(data));
                dispatch(alertActions.success('Table 5 Saved Successfully'));
                clbk();
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );   
    }

    function request() { return { type: reportTablesConstants.CREATE_TABLE5_REQUEST } }
    function success(data) { return { type: reportTablesConstants.CREATE_TABLE5_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.CREATE_TABLE5_FAILURE, error } }
}

/**
 * Method to create table 4 data
 *
 * @param {*} formData contains reportYear_id - must
 */
function createTable4(formData) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.createTable4(formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('Table 4 Saved Successfully'));

                    //get data table data
                    dispatch(getTable4Data(formData.reportYear_id));

                    // update table 4 validations
                    dispatch(validationActions.updateValidations(0, 0, formData.reportYear_id, 4));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, formData.reportYear_id, 4));
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.CREATE_TABLE4_REQUEST } }
    function success(data) { return { type: reportTablesConstants.CREATE_TABLE4_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.CREATE_TABLE4_FAILURE, error } }
}

/**
 * Method to create table 3 data
 *
 * @param {*} formData contains reportYear_id - must
 */
function createTable3(formData) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.createTable3(formData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('Table 3 Saved Successfully'));

                    //get data table data
                    dispatch(getTable3Data(formData.reportYear_id));

                    // update table 3 validations
                    dispatch(validationActions.updateValidations(0, 0, formData.reportYear_id, 3));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, formData.reportYear_id, 3));
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.CREATE_TABLE3_REQUEST } }
    function success(data) { return { type: reportTablesConstants.CREATE_TABLE3_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.CREATE_TABLE3_FAILURE, error } }
}
/**
 * Method to create table 2 data
 *
 * @param {*} formData contains reportYear_id - must
 */
function createTable2(formData,callback) {
    return dispatch => {
        dispatch(request());
        reportTablesServices.createTable2(formData)
            .then(
                data => {
                    dispatch(success(data));
                    callback(data);
                    dispatch(alertActions.success('Table 2 Saved Successfully'));

                    //get data table data
                    dispatch(getTable2Data(formData.reportYear_id));

                    // update table 2 validations
                    dispatch(validationActions.updateValidations(0, 0, formData.reportId, 2));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, formData.reportId, 2));
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.CREATE_TABLE2_REQUEST } }
    function success(data) { return { type: reportTablesConstants.CREATE_TABLE2_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.CREATE_TABLE2_FAILURE, error } }
}

/**
 * 
 * @param {*} id 
 */
function validationStatus(projectId, reportYear) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.validationStatus(projectId, reportYear)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_REPORT_TABLES_VALIDATION_REQUEST } }
    function success(validations) { return { type: reportTablesConstants.GET_REPORT_TABLES_VALIDATION_SUCCESS, validations } }
    function failure(error) { return { type: reportTablesConstants.GET_REPORT_TABLES_VALIDATION_FAILURE, error } }
}

/**
 * Get table 1 errors
 * @param {*} reportYear_id 
 */
function getTable1Errors(reportYear_id) {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getTable1Errors(reportYear_id)
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_TABLE_1_ERROR_REQUEST } }
    function success(validationData) { return { type: reportTablesConstants.GET_TABLE_1_ERROR_SUCCESS, validationData } }
    function failure(error) { return { type: reportTablesConstants.GET_TABLE_1_ERROR_FAILURE, error } }
}

/**
 * Get validation issues
 * @param {*} reportYear_id 
 */
function getIssues(project_id = null, site_id = null, reportYear_id = null, reportTable = '') {
    return dispatch => {
        dispatch(request());

        reportTablesServices.getIssues(project_id, site_id, reportYear_id, '')
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_VALIDATION_ISSUES_REQUEST } }
    function success(data) { return { type: reportTablesConstants.GET_VALIDATION_ISSUES_SUCCESS, data } }
    function failure(error) { return { type: reportTablesConstants.GET_VALIDATION_ISSUES_FAILURE, error } }
}

/**
 * refresh validations
 * 
 * @param {*} project_id 
 * @param {*} site_id 
 * @param {*} reportYear_id 
 * @param {*} reportTable 
 */
function refreshValidations(project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) {
    return dispatch => {
        dispatch(request());

        validationService.updateValidationPost(project_id, site_id, reportYear_id, reportTable)
            .then(
                data => {
                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, reportYear_id, reportTable));
                        dispatch(success(reportTable));
                    }, 2000);
                },
                error => {
                    dispatch(failure(reportTable));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportTablesConstants.GET_REFRESH_VALIDATIONS_REQUEST, reportTable } }
    function success(reportTable) { return { type: reportTablesConstants.GET_REFRESH_VALIDATIONS_SUCCESS, reportTable } }
    function failure(reportTable) { return { type: reportTablesConstants.GET_REFRESH_VALIDATIONS_FAILURE, reportTable } }
}

/**
 * Download Report table
 * 
 * @param {*} reportTable - table number 
 * @param {*} data - table data
 */
function downloadReportTable(reportTable = null, data = {}) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        reportTablesServices.downloadReportTable(data)
            .then(res => res.blob())
            .then(
                blob => {
                    let name = reportTable === "4" ? `_4_4A_4B_${data.title}_${data.year}` : reportTable;
                    saveAs(blob, `Table${name}.pdf`);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request(reportTable) { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_REQUEST, reportTable } }
    function success(reportTable, data) { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_SUCCESS, data, reportTable } }
    function failure(reportTable, error) { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_FAILURE, error, reportTable } }
}

/**
 * Table 3 Report
 * @param {*} reportYear_id 
 * @param {*} year 
 * @param {*} fileName 
 */
function downloadTable3Report(project_id,site_id,year, fileName = 'WBAR_Table3Export.xlsx') {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        reportTablesServices.downloadTable3Report(project_id,site_id,year)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, `${year}_${fileName}`);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_REQUEST } }
    function success() { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_SUCCESS } }
    function failure(error) { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_FAILURE, error } }
}

/**
 * Table 4 Report
 * @param {*} reportYear_id 
 * @param {*} year 
 * @param {*} fileName 
 */
function downloadTable4Report(project_id, site_id, year, fileName = 'WBAR_Table4Export.xlsx') {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        reportTablesServices.downloadTable4Report(project_id, site_id, year)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, `${year}_${fileName}`);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_REQUEST } }
    function success() { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_SUCCESS } }
    function failure(error) { return { type: reportTablesConstants.DOWNLOAD_REPORT_TABLE_FAILURE, error } }
}


/**
 * Save table4 on tab click for new project if no table4 is created
 * Implemented on 18-04-2024 - WBAR-3283
 * @param {*} reportYear_id 
 * @param {*} generalData 
 */

function checkIfTable4Exists(reportYear_id,generalData){
    return dispatch =>{
        dispatch(request)
        reportTablesServices.checkIfTable4Exists(reportYear_id)
        .then(result=>{
            if(result.data.length>0){
                dispatch(success(true))
            }else{
        generalData['reportYear_id']=parseInt(reportYear_id)
        //create table 4 record and trigger validation if no id exists for reportyear
        reportTablesServices.createTable4(generalData)
            .then(data => {
                    //get data table data
                    dispatch(getTable4Data(reportYear_id));

                    // update table 4 validations
                    dispatch(validationActions.updateValidations(0, 0, reportYear_id, 4));

                    // refresh validations
                    setTimeout(() => {
                        dispatch(getIssues(0, 0, reportYear_id, 4));
                    }, 2000);
                    dispatch(success(false))
                },
                error => {
                    dispatch(alertActions.error(error.toString()));
                }
            );
            }
        }).catch(err=>{
            dispatch(failure(err))
        })
    };
    function request() { return { type: reportTablesConstants.CHECK_IF_TABLE4_EXISTS_REQUEST } }
    function success(result) { return { type: reportTablesConstants.CHECK_IF_TABLE4_EXISTS_SUCCESS,result } }
    function failure(error) { return { type: reportTablesConstants.CHECK_IF_TABLE4_EXISTS_FAILURE, error } }
}