//import constants
import { notificationConstants } from '../constants/notification';
import { getTimeStampForNotification } from '../helpers/global';

//import services
import { notificationService } from '../services/notification';


export const notificationActions = {
    getByUserId,
    updateNotification,
    updateNotificationFromSocket,
    delteteNotification,
    getNotificaionAlertByUserId,
    updateAlertNotification,
    delteteAlertNotification,
    updateNotificationAlertFromSocket
};

/**
 *Get notification data listing values
 *
 * @param {*} userId
 * @returns
 */
function getByUserId() {
    return dispatch => {
        dispatch(request());
        notificationService.getByUserId()
            .then(
                notification => {
                    if (notification.data && notification.data.length > 0) {
                        notification.data.map(noti => {
                            noti.createdAt = getTimeStampForNotification(noti.createdAt)
                            return noti
                        })
                    }
                    dispatch(success(notification))
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: notificationConstants.GETBYUSERID_NOTIFICATION_REQUEST } }
    function success(notification) { return { type: notificationConstants.GETBYUSERID_NOTIFICATION_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.GETBYUSERID_NOTIFICATION_FAILURE, error } }
}

/**
 *Get notification data listing values
 *
 * @param {*} userId
 * @returns
 */
function getNotificaionAlertByUserId(callBack = () => { }) {
    return dispatch => {
        dispatch(request());
        notificationService.getAlertByUserId()
            .then(notification => {
                dispatch(success(notification))
                callBack(notification.data)
            },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: notificationConstants.GETBYUSERID_NOTIFICATIONALERT_REQUEST } }
    function success(notification) { return { type: notificationConstants.GETBYUSERID_NOTIFICATIONALERT_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.GETBYUSERID_NOTIFICATION_FAILURE, error } }
}


/**
 *update notification data listing values
 *
 * @param {*} userId
 * @returns
 */
function updateNotification(data = null) {
    return dispatch => {
        dispatch(request());
        notificationService.updateNotification(data)
            .then(
                notification => {
                    if (notification.data && notification.data.length > 0) {
                        notification.data.map(noti => {
                            noti.createdAt = getTimeStampForNotification(noti.createdAt)
                            return noti
                        })
                    }
                    dispatch(success(notification))
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: notificationConstants.UPDATEDATA_REQUEST } }
    function success(notification) { return { type: notificationConstants.UPDATEDATA_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.UPDATEDATA_FAILURE, error } }
}

/**
 *update alert notification data listing values
 *
 * @param {*} userId
 * @returns
 */
function updateAlertNotification(data = null, callBack) {
    return dispatch => {
        dispatch(request());
        notificationService.updateAlertNotification(data)
            .then(
                alert => {
                    dispatch(success(alert));
                    callBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    callBack();
                }
            );
    };
    function request() { return { type: notificationConstants.UPDATEALERTDATA_REQUEST } }
    function success(alert) { return { type: notificationConstants.UPDATEALERTDATA_SUCCESS, alert } }
    function failure(error) { return { type: notificationConstants.UPDATEALERTDATA_FAILURE, error } }
}

/**
 *delete notification data listing values
 *
 * @param {*} 
 * @returns
 */
function delteteNotification() {
    return dispatch => {
        dispatch(request());
        notificationService.deleteNotification()
            .then(
                notification => dispatch(success(notification)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: notificationConstants.DELETEDATA_REQUEST } }
    function success(notification) { return { type: notificationConstants.DELETEDATA_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.DELETEDATA_FAILURE, error } }
}


/**
 *delete alert notification 
 *
 * @param {*} 
 * @returns
 */
function delteteAlertNotification() {
    return dispatch => {
        dispatch(request());
        notificationService.delteteAlertNotification()
            .then(
                notification => dispatch(success(notification)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: notificationConstants.DELETEALERTDATA_REQUEST } }
    function success(notification) { return { type: notificationConstants.DELETEALERTDATA_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.DELETEALERTDATA_FAILURE, error } }
}


/**
 *Get notification data listing values
 *
 * @param {*} userId
 * @returns
 */
function updateNotificationFromSocket(notification) {
    return (dispatch, state) => {
        if (notification && notification.length > 0) {
            notification.map(noti => {
                noti.createdAt = getTimeStampForNotification(noti.createdAt)
                return noti
            })
        }
        dispatch(success(notification, state))
    };
    function success(notification) { return { type: notificationConstants.UPDATEDATA_SOCKET_SUCCESS, notification } }
}

/**
 *Get notification data listing values
 *
 * @param {*} userId
 * @returns
 */
function updateNotificationAlertFromSocket(notification) {
    return (dispatch, state) => {
        dispatch(success(notification, state))
    };
    function success(notification) { return { type: notificationConstants.ALERT_UPDATEDATA_SOCKET_SUCCESS, notification } }
}