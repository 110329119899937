import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Components
import PrivateLayout from '../../layouts/private';

// Containers
import PageAccess from '../../containers/PageAccess/PageAccess';

// Helpers
import { getUserTOSStatus } from '../../helpers/global';
/**
 * Private Routes Component
 * @param {*} param0 
 * @returns 
 * 
 * Routes the page visible to user, when user authentication is sucessfull
 */
export const PrivateRoute = ({ component: Component, ...rest }) => (
    
    <Route {...rest} render={props => (
        getUserTOSStatus().loggedIn && getUserTOSStatus().tos
            ? (
                <PrivateLayout {...props}>
                    <Component {...props} routes={rest.routes} />
                    <PageAccess {...props} />
                </PrivateLayout>
            )
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )} />
)

