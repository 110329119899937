import { bulkDataConstants } from "../constants/bulkData";

const initialState = {
    data: [],
    logList: [],
    isLoading: false,
    error: '',
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['report_name'],
            searchCols: []
        }
    },
    downloadStatus: {
        isLoading: false,
        error: ''
    }
};

export function bulkData (state = initialState, action) {
   switch (action.type) {
        case bulkDataConstants.SAVEBULKDATA_REQUEST : 
            return {
                ...state,
                isLoading: true
            };
        case bulkDataConstants.SAVEBULKDATA_SUCCESS : 
            return {
                ...state,
                isLoading: false,
                
            };
        case bulkDataConstants.SAVEBULKDATA_FAILURE : 
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        
        case bulkDataConstants.GETBULKDATALOGS_REQUEST : 
            return {
                ...state,
                isLoading: true
            }
        case bulkDataConstants.GETBULKDATALOGS_SUCCESS : 
            console.log("===== GETBULKDATALOGS_SUCCESS ==== ",action)
            return {
                ...state,
                data: action.bulkData,
                page: action.bulkData.page,
                logList: action.bulkData.data,
                isLoading: false
            }
        case bulkDataConstants.GETBULKDATALOGS_FAILURE :
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case bulkDataConstants.DELETE_REQUEST :
            return {
                ...state,
                isLoading: true
            }
        case bulkDataConstants.DELETE_SUCCESS : 
            return {
                ...state,
                logList: state.logList.filter((bulkDataLog) => {
                    return bulkDataLog._id !== action.id
                }),
                isLoading: false
            }
        case bulkDataConstants.DELETE_FAILURE : 
            return {
                ...state.page,
                error: action.error,
                isLoading: false
            }
        
        case bulkDataConstants.DOWNLOAD_REQUEST : 
            return {
                ...state,
                isLoading: true,
                downloadStatus: {
                    isLoading: true
                }
            };
        
        case bulkDataConstants.DOWNLOAD_SUCCESS : 
            return {
                ...state,
                isLoading: false,
                downloadStatus: {
                    isLoading: false
                }
            };
        case bulkDataConstants.DOWNLOAD_FAILURE : 
            return {
                ...state,
                error: action.error,
                isLoading: false,
                downloadStatus: {
                    isLoading: false
                }
            };
        
        default:
            return state
   }
}