import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ValidatorForm from "../../../helpers/fieldValidations";

//import Component
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import TextBox from '../../../components/TextBox/TextBox';
import DropDown from '../../../components/DropDown/DropDown';
import FileUpload from '../../../components/FileUpload/FileUpload';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import PageLoader from '../../../components/PageLoader/PageLoader';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Actions
import { uploadActions } from '../../../actions/uploads';

// helpers
import { formatDate, getUser, history, xmlFormatter } from '../../../helpers';

import XMLEditor from "./XMLEditor";
import { alertActions } from '../../../actions';

class ReportActivityImport extends Component {

    constructor(props) {
        super(props);
        const { firstName, lastName, org, id } = getUser();
        this.state = {
            user_name: `${firstName} ${lastName}`,
            user_org: org ? org.name : '',
            user_id: id,
            importDate: formatDate(new Date().toISOString()),
            importYear: new Date().getFullYear(),
            importYears: [new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2],
            data: null,
            file: null,
            startImported: false,
            label: 'Select XML file to upload',
            isLoading: false,
            xmlFormattedString: '',
            editorOpened: false
        };
    }

    /**
     * Handle File Select Action
     */
    handleFileChange = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length === 0 && acceptedFiles.length === 1) {
            const data = new FormData();
            const file = acceptedFiles[0];
            data.append('file', file);
            data.append('name', file.name);
            // year

            // type_userid_timestamp
            data.append('actionStatus', `reportActivityImport_${this.state.user_id}_${new Date().getTime()}`);
            this.setState({ data: data, file: file, startImported: false, xmlFormattedString: null });
            // this.filePreview();
        } else {
            this.setState({ data: null, file: null, startImported: false, label: 'Please select a valid XML file.' });
        }
    }

    /**
     * Handle Validate & Import Click Action
     */
    handleFileUpload = () => {
        let { importProcess } = this.props;
        const { data, xmlFormattedString } = this.state;
        if (data !== null) {
            // // update latest imprt year
            // data.append('importDate', this.state.importDate);
            data.has('importDate') ? data.set('importDate', this.state.importDate) : data.append('importDate', this.state.importDate);
            data.has('reportYear') ? data.set('reportYear', this.state.importYear) : data.append('reportYear', this.state.importYear);
            // // type_userid_timestamp
            if (!importProcess.data || (importProcess.data && !importProcess.data.dummyResponse)) {
                data.has('actionStatus') ? data.set('actionStatus', `reportActivityImport_${this.state.user_id}_${new Date().getTime()}`) : data.append('actionStatus', `reportActivityImport_${this.state.user_id}_${new Date().getTime()}`);
            }

            if (xmlFormattedString) {
                // create a new blob with formated file content
                let blob = new Blob([xmlFormattedString], { type: 'text/xml' });

                // remove old file and append with formatted file
                data.delete('file');
                data.append('file', blob);

                // // Preview
                this.setState({ data: data, xmlFormattedString: xmlFormattedString, startImported: true, editorOpened: true });

                //Import
                this.props.reportActivityImport(data);
            } else {
                // read xml file and format it
                const reader = new FileReader()
                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = () => {
                    // format the file contents
                    const binaryStr = reader.result;
                    const xmlFormattedString = xmlFormatter(binaryStr);

                    // create a new blob with formated file content
                    let blob = new Blob([xmlFormattedString], { type: 'text/xml' });

                    // remove old file and append with formatted file
                    data.delete('file');
                    data.append('file', blob);

                    // Preview
                    this.setState({ data: data, xmlFormattedString, startImported: true, editorOpened: true });

                    //Import
                    this.props.reportActivityImport(data);
                }
                reader.readAsText(this.state.file);
            }
        } else {
            this.props.showErrorAlert('Please select XML file to upload')
        }
    }

    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        const { editorOpened } = this.state
        editorOpened ? this.setState({ editorOpened: false, data: null, file: null, startImported: false, label: 'Please select a valid XML file.', xmlFormattedString: null }) : history.goBack();
    }

    /**
     * Handle Save Click Action
     */
    handleSave = (newCode) => {
        let { data, xmlFormattedString } = this.state;

        xmlFormattedString = newCode ? newCode : xmlFormattedString;

        // type_userid_timestamp
        data.has('actionStatus') ? data.set('actionStatus', `reportActivityImport_${this.state.user_id}_${new Date().getTime()}`) : data.append('actionStatus', `reportActivityImport_${this.state.user_id}_${new Date().getTime()}`);

        // create a new blob with formated file content
        let blob = new Blob([xmlFormattedString], { type: 'text/xml' });

        // remove old file and append with formatted file
        data.delete('file');
        data.append('file', blob);

        // Preview
        this.setState({ data: data, xmlFormattedString: xmlFormattedString, editorOpened: false });
    }

    formatReponse(responseData) {
        if (responseData) {
            return <div dangerouslySetInnerHTML={{ __html: responseData }}></div>
        }
        return null;
    }

    render() {

        let { classes, importProcess: { isLoading, data }, pageAccess } = this.props;
        let { user_name, user_org, importDate, importYear, importYears, file, startImported, label, xmlFormattedString, editorOpened } = this.state;
        data = data || {};

        if (pageAccess.indexOf("SAVE") === -1 && pageAccess.length > 0) {
            return "YOU DON'T HAVE ACCESS TO THIS PAGE";
        }

        editorOpened = data && data.errors ? editorOpened : false;

        let pageTitle = !editorOpened ? "XML Editor" : file.name
        // data && data.errors && this.handleTable1ImportActionClick();

        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={pageTitle}
                needPadding={true}
                isSubPage={false}
                handleBackActionClick={this.handleBackActionClick}
            >
                {!editorOpened && <ValidatorForm
                    className="marginTopImport"
                    name="ReportActivityImport"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleFileUpload}
                    onError={errors => console.log(errors)} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextBox
                                name="user_name"
                                value={user_name}
                                label="User"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextBox
                                name="user_org"
                                value={user_org}
                                label="Organization"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextBox
                                name="importDate"
                                value={importDate}
                                label="Import Date"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DropDown
                                name={"importYear"}
                                label={"Import Year"}
                                value={importYear}
                                className={classes.textField}
                                handleChange={(name, value) => {
                                    this.setState({
                                        importYear: value
                                    });
                                }}
                                disabled={false}
                            >
                                {
                                    importYears.map((item) => {
                                        return <MenuItem value={item}>{item}</MenuItem>
                                    })
                                }
                            </DropDown>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} className='uploadfilegrid'>
                            <div>
                                <Paper className={classes.root} elevation={1} style={{ height: '100px', boxShadow: 'none' }}>
                                    <Typography variant="h5" component="h5">
                                        {"Documentation"}
                                    </Typography>
                                    <Typography component="p">
                                        <a
                                            href={`${globalVariable.apiURL}/table1-documentation/for_end_users.pdf`}
                                            target="_blank"
                                            title={"WBARS - XML Import Documentation - For End Users"}
                                            rel="noopener noreferrer"
                                        >
                                            {"WBARS - XML Import Documentation - For End Users"}
                                        </a>
                                        <br />
                                        <a
                                            href={`${globalVariable.apiURL}/table1-documentation/for_developers.pdf`}
                                            target="_blank"
                                            title={"WBARS - XML Import Documentation - For Software Developers"}
                                            rel="noopener noreferrer"
                                        >
                                            {"WBARS - XML Import Documentation - For Software Developers"}
                                        </a>
                                    </Typography>
                                </Paper>
                            </div>
                        </Grid> */}
                        <Grid item xs={12} sm={6} className='uploadfilegrid'>
                            <div className="uploadfile">
                                <FileUpload
                                    content={file ? file.name : label}
                                    disabled={false}
                                    accept={["text/xml"]}
                                    handleFileUpload={this.handleFileChange}
                                    name={'xmlfileupload'}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <SaveCancel
                        handleSaveButtonClick={() => { this.refs.form.submit() }}
                        handleCancelButtonClick={() => { history.goBack() }}
                        saveText={"Validate & Import"}
                    />
                </ValidatorForm>}
                <PageLoader show={isLoading || this.state.isLoading} />
                {
                    startImported && data.dummyResponse && this.formatReponse(data.data)
                }
                {editorOpened && <XMLEditor
                    xmlFormattedString={xmlFormattedString}
                    title={file ? file.name : 'XML Preview'}
                    onChange={(newCode) => {
                        this.handleSave(newCode)
                    }}
                    onSave={(newCode) => this.handleSave(newCode)}
                    onCancel={(newCode) => this.handleSave(newCode)}
                    errors={data.errors}
                />}
            </ContentWrapper>
        );
    }
}

ReportActivityImport.propTypes = {
    classes: PropTypes.object.isRequired,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
}

ReportActivityImport.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: []
}

const mapStateToProps = ({ bulkUploads, pageAccess }, ownProps) => {
    return {
        importProcess: bulkUploads.reportActivity,
        pageAccess: pageAccess['reportActivityImport'] || []
    }
};

//Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        reportActivityImport: (formData) => dispatch(uploadActions.reportActivityImport(formData)),
        showErrorAlert: (msg) => dispatch(alertActions.error(msg))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ReportActivityImport));