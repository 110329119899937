import React, { Component } from "react";
import PropTypes from 'prop-types';

class CustomToolTip extends Component {

    render() {
        const { focus, toolTipTitle, tooltipOnHover, toolTipClassName } = this.props;
        var hoverClassName = "tooltiptextHover"
        if (toolTipClassName === "topTextBox") {
            hoverClassName = "topTextBoxHover"
        }
        else if(toolTipClassName ==="topOutlineTextBox"){
            hoverClassName="topOutlineTextBoxHover"
        }else if(toolTipClassName ==="financialTopTextBox"){
            hoverClassName="financialTopTextBoxHover"
        }else if(toolTipClassName ==="textAreaTooltip"){
            hoverClassName="textAreaTooltipHover"
        }else if(toolTipClassName ==="fintextAreaTooltip"){
            hoverClassName="fintextAreaTooltipHover"
        }else if(toolTipClassName ==="topTextBoxLeftAlign"){
            hoverClassName="topTextBoxLeftAlignHover"
        }else if(toolTipClassName ==="certificationtopTextBox"){
            hoverClassName="certificationtopTextBoxHover"
        }else if(toolTipClassName ==="autocomplteTextBox"){
            hoverClassName="autocomplteTextBoxHover"
        }else if(toolTipClassName ==="rightOutlined"){
            hoverClassName="rightOutlinedHover"
        }else if(toolTipClassName ==="righSecondtOutlined"){
            hoverClassName="righSecondtOutlinedHover"
        }else if(toolTipClassName ==="righThirdtOutlined"){
            hoverClassName="righThirdtOutlinedHover"
        }else if(toolTipClassName ==="moveTopOutlineTextBox"){
            hoverClassName="moveTopOutlineTextBoxHover"
        }else if(toolTipClassName ==="righSecondtOutlinedNum"){
            hoverClassName="righSecondtOutlinedNumHover"
        }else if(toolTipClassName ==="sixthrighSecondtOutlined"){
            hoverClassName="sixthrighSecondtOutlinedHover"
        }else if(toolTipClassName ==="topTextBoxFinancial"){
            hoverClassName="topTextBoxFinancialHover"
        }else if(toolTipClassName ==="fintextAreaTooltipLast"){
            hoverClassName="fintextAreaTooltipLastHover"
        }else if(toolTipClassName ==="progTopTextBox"){
            hoverClassName="progTopTextBoxHover"
        }else if(toolTipClassName ==="righProgSecondtOutlinedNum"){
            hoverClassName="righProgSecondtOutlinedNumHover"
        }
        return (
            <div class="containers" >
                <span class={!tooltipOnHover && toolTipTitle ? hoverClassName : toolTipTitle && focus ? toolTipClassName : "tooltiptextNone"}>{toolTipTitle}</span>
                {this.props.children}
            </div>
        );
    }
}

CustomToolTip.defaultProps = {
    toolTipTitle: '',
    toolTipClassName: 'tooltiptext'
}
//prop types
CustomToolTip.propTypes = {
    toolTipTitle: PropTypes.string.isRequired,
    toolTipClassName: PropTypes.string
};

export default CustomToolTip;