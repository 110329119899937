
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'underscore';

// Import Components
import ContractsEdit from './ContractsEdit';

// Import Actions
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
import { contractAction } from '../../actions/contractAction';

class ContractsNew extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.userRef = React.createRef();
    }

    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.siteRef.refs.form.submit();
    };

    /**
     * Handle Form Save Action Click
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            this.props.updateContract(formData);
        }
    };

    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
   * Bind Html to DOM
   */
    render() {
        let { match:{params},
            removeActions, pageAccess, isSubPage } = this.props;

        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        pageAccess = _.difference(pageAccess, removeActions);
        return (
                <ContractsEdit
                    {...this.props}
                    contractsId = {params.contractsId}
                    isReadOly = {isReadOly}
                    location={this.props.location}
                    isSubPage={isSubPage}
                    pageAccess={pageAccess}
                    handleFormSaveActionClicks={(formData) => this.handleFormSaveActionClick(formData)}
                    loanProjectsId={this.props.loanProjectId}
                />
        );
    }
}

/**
 * Bind Component Property Types
 */
 ContractsNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};

ContractsNew.defaultProps = {
    contractsId: null,
    title: "Edit Contract",
    removeActions: [],
    isSubPage: false
};

/**
 * Maps state from store to props
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['contractEdit'] || []
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateContract:(formData) => dispatch(contractAction.updateContract(formData))
    }
};


/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(ContractsNew));





