import { maintenanceConstants } from '../constants';

const initialState = {
    getOne: {
        data: {}
    },
    isLoading: false,
    data: {},
    error: {},
    verifyData: {
        data: [],
        page: {
            count: 0,
            skip: 1,
            limit: 12,
            orderBy: "county",
            fieldFilters: {
                headerCols: [],
                fieldCols: [],
                selectedCols: [],
                searchCols: [],
                visibleColumns: []
            },
            order: "asc"
        }
    },
    listLogs: {
        data: [],
        page: {
            count: 0,
            skip: 1,
            limit: 12,
            orderBy: "startDate",
            fieldFilters: {
                headerCols: [],
                fieldCols: [],
                selectedCols: [],
                searchCols: [],
                visibleColumns: []
            },
            order: "desc"
        }
    },
    cronStatus: {}
};

export function maintenance(state = initialState, action) {
    switch (action.type) {
        case maintenanceConstants.GET_ALL_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case maintenanceConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                ...action.data,
                isLoading: false,
            };
        case maintenanceConstants.GET_ALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case maintenanceConstants.GET_ONE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case maintenanceConstants.GET_ONE_SUCCESS:
            return {
                ...state,
                data: action.data.data || {},
                // error: action.data.error || {},
                isLoading: false
            };
        case maintenanceConstants.GET_ONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case maintenanceConstants.UPDATE_MAINTENANCE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case maintenanceConstants.UPDATE_MAINTENANCE_SUCCESS:
            return {
                ...state,
                data: action.data.data || {},
                isLoading: false
            };
        case maintenanceConstants.UPDATE_MAINTENANCE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case maintenanceConstants.ADD_MAINTENANCE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case maintenanceConstants.ADD_MAINTENANCE_SUCCESS:
            return {
                ...state,
                data: action.data.data || {},
                isLoading: false
            };
        case maintenanceConstants.ADD_MAINTENANCE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        // verify data
        case maintenanceConstants.VERIFY_DATA_MAINTENANCE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case maintenanceConstants.VERIFY_DATA_MAINTENANCE_SUCCESS:
            return {
                ...state,
                verifyData: {
                    ...action.data
                },
                isLoading: false
            };
        case maintenanceConstants.VERIFY_DATA_MAINTENANCE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case maintenanceConstants.DOWNLOAD_TEMPLATE_REQUEST:
            return {
                ...state,
                // isLoading: true
            }
        case maintenanceConstants.DOWNLOAD_TEMPLATE_SUCCESS:
        case maintenanceConstants.DOWNLOAD_TEMPLATE_FAILURE:
            return {
                ...state,
                // isLoading: false
            }
        // list logs
        case maintenanceConstants.GET_LIST_LOGS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case maintenanceConstants.GET_LIST_LOGS_SUCCESS:
            return {
                ...state,
                listLogs: {
                    ...action.data
                },
                isLoading: false
            };
        case maintenanceConstants.GET_LIST_LOGS_FAILURE:
        case maintenanceConstants.IMPORT_FILES_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true
            }
        case maintenanceConstants.IMPORT_FILES_SUCCESS:
            return {
                ...state,
                error: action.data.error || {},
                isLoading: false
            }
        case maintenanceConstants.IMPORT_FILES_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case maintenanceConstants.REUPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case maintenanceConstants.REUPLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case maintenanceConstants.REUPLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case maintenanceConstants.GET_CRON_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case maintenanceConstants.GET_CRON_STATUS_SUCCESS:
            return {
                ...state,
                cronStatus: action.data.data || {},
                isLoading: false
            }
        case maintenanceConstants.GET_CRON_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        default:
            return state
    }
}