
import { authHeader, globalVariable, handleResponse } from '../helpers';

export const restoreTime = {
    getRestoreDate
};
/**
*Get Current Database Restored in Uat
*
* @param {*} env - Environment detail
* @returns
*/
function getRestoreDate(env) {
    const requestDate = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    return fetch(`${globalVariable.apiURL}/db-restore/${env}`, requestDate).then(handleResponse)
}