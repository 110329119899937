import { helpConstants } from "../constants/help";
import { helpService } from "../services/help";
import { alertActions } from "./";
import { history } from "../helpers";
import { saveAs } from 'file-saver';

const blobType = {
    jpg: "image/png",
    pdf: "application/pdf",
    txt: "text/plain",
    png: "image/png",
    jpeg: "image/jpeg",
    gif: "image/gif"

}

export const helpActions = {
    getAll,
    createHelp,
    getById,
    getHelpById,
    delete: _delete,
    viewFile,
    downloadFile,
    clearHelp
};

/**
 * A method to get the units list
 *
 * @param {*} skip  page to display. if 1 first page
 * @param {*} limit no of recorrd to fetch on the given page
 * @param {*} orderBy sort order column name string
 * @param {*} order sorttypr asc or desc
 * @param {*} sFields search fields column name
 * @param {*} sValues search field column values
 * @param {*} building_id building id to fecth the units under it
 * @returns
 */
function getAll(data) {
    return dispatch => {
        dispatch(request())

        helpService.getAll(data)
            .then(
                help => {
                    dispatch(success(help))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: helpConstants.GETALL_REQUEST } }
    function success(help) { return { type: helpConstants.GETALL_SUCCESS, help } }
    function failure(error) { return { type: helpConstants.GETALL_FAILURE, error } }
}

/**
 *Create help
 *
 * @param {*} formData - title and category
 * @param {*} editorNotes - notes
 * @param {*} uploadFile - file upload
 * @returns
 */
function createHelp(formData, editorNotes, uploadFile) {
    return dispatch => {
        dispatch(request());
        helpService.createHelp(formData, editorNotes)
            .then(
                help => {
                    const promise = uploadFile ? helpService.uploadFile(formData._id, uploadFile) : Promise.resolve(help);
                    promise.then(() => {
                        dispatch(success(help));
                        dispatch(alertActions.success("Help topic added successfully"));
                        dispatch(clearHelp())
                        history.goBack();
                    })
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: helpConstants.ADD_HELP_REQUEST } }
    function success(help) { return { type: helpConstants.ADD_HELP_SUCCESS, help } }
    function failure(error) { return { type: helpConstants.ADD_HELP_FAILURE, error } }

}

/**
 * 
 * clears help data
 */
function clearHelp(){
    return dispatch =>{
        dispatch(success())
    };

    function success (){return {type:helpConstants.CLEAR_HELP_DATA}}
}

/**
 *View thw help data
 * 
 * @param {*} _id - help id
 * @returns
 */
function getById(_id) {
    return dispatch => {
        dispatch(request());
        helpService.getById(_id)
            .then(
                help => {  dispatch(success(help)) },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: helpConstants.GETONE_REQUEST } }
    function success(help) { return { type: helpConstants.GETONE_SUCCESS, help } }
    function failure(error) { return { type: helpConstants.GETONE_FAILURE, error } }
}

/**
 *Edit the help data
 *
 * @param {*} _id
 * @returns
 */
function getHelpById(_id) {
    return dispatch => {
        dispatch(request());

        helpService.getHelpById(_id)
            .then(
                help => dispatch(success(help)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: helpConstants.GETONE_HELP_REQUEST } }
    function success(help) { return { type: helpConstants.GETONE_HELP_SUCCESS, help } }
    function failure(error) { return { type: helpConstants.GETONE_HELP_FAILURE, error } }
}


/**
 *Delete help record
 *
 * @param {*} id - help id
 * @returns
 */
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        helpService.delete(id)
            .then(
                help => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                    dispatch(helpActions.getAll())
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()))
                }
            );
    };

    function request(id) { return { type: helpConstants.DELETE_HELP_REQUEST,id } }
    function success(id) { return { type: helpConstants.DELETE_HELP_SUCCESS, id } }
    function failure(id,error) { return { type: helpConstants.DELETE_HELP_FAILURE, id,error } }
}

/**
 *View help data
 *
 * @param {*} _id - help id
 * @param {*} fileName - help file name
 * @param {*} ext
 * @returns
 */
function viewFile(_id, fileName, ext) {
    return dispatch => {
        dispatch(request(_id));

        helpService.viewFile(_id, fileName)
            .then(res => res.arrayBuffer())
            .then(
                buffer => {
                    var file = new Blob([buffer], { type: blobType[ext] });
                    var fileURL = URL.createObjectURL(file);
                    window.open(fileURL, "name=hello");
                    // saveAs(blob, `${reportName}.xlsx`);
                    dispatch(success(_id));
                },
                error => {
                    dispatch(failure(_id, error.toString()));
                }
            );
    };

    function request(id) { return { type: helpConstants.VIEW_HELPFILE_REQUEST,id } }
    function success(id) { return { type: helpConstants.VIEW_HELPFILE_SUCCESS, id } }
    function failure(id,error) { return { type: helpConstants.VIEW_HELPFILE_FAILURE, id,error } }
}

/**
 *Download help data
 *
 * @param {*} fileName - help file name
 * @param {*} ext
 * @returns
 */
function downloadFile(fileName, ext) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));

        helpService.downloadFile(fileName)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, fileName);
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: helpConstants.DOWNLOAD_HELPFILE_REQUEST } }
    function success() { return { type: helpConstants.DOWNLOAD_HELPFILE_SUCCESS } }
    function failure(error) { return { type: helpConstants.DOWNLOAD_HELPFILE_FAILURE,error } }
}
