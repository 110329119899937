
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { history, convertDateWithTimeZone } from '../../helpers';
import FieldValidationConstants from "../../helpers/fieldValidationConstants"

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import TextBox from '../../components/TextBox/TextBox';
import TextArea from '../../components/TextArea/TextArea';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import DatePicker from '../../components/DatePicker/DatePicker';
import { contractAction } from '../../actions/contractAction';
import { alertActions } from '../../actions';


class ContractsEdit extends Component {

    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            _id: '',
            project_id: '',
            projectName: '',
            contractNumber: '',
            contractKey: '',
            desc: "",
            commitmentStartDate: '',
            preparerOrg: {
                id: "",
                name: ""
            },
            preparerUser: {
                id: "",
                name: "",
                phone: "",
                email: ""
            },
            expirationDate: null,
            isApplied: false,
            isClaimed: false,
            validationMsg: ''

        }
        this.userRef = React.createRef();
    }

    componentDidMount() {
        const { contractsId, loanProjectsId } = this.props
        this.props.getProjectDetails(loanProjectsId)
        this.props.getAllOrg('', 'contracts');
        if (contractsId) {
            this.props.getByContractId(contractsId);
            this.setState({ isApplied: false })
        }
        this.props.getAllPreparerInOrg(this.state.preparerOrg.id)

        let url = history.location.pathname.includes('new') && this.props.contractsCount < 1;
        if (url) {
            this.setState({ contractNumber: '<empty>' })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { contractsId, loanProjectsId, getOne } = this.props;
        const { contractUpdateData } = nextProps;
        if (loanProjectsId && history.location.pathname.includes('new')) {
            this.setState({
                project_id: loanProjectsId,
                projectName: getOne.projectName,
                contractKey: getOne.lastId,
            })
        }
        if (contractsId && contractUpdateData && contractUpdateData.length > 0) {
            if (this.state._id !== contractUpdateData[0]._id) {
                this.props.getAllPreparerInOrg(contractUpdateData[0].preparerByOrg.id)
                this.setState({
                    _id: contractUpdateData[0]._id,
                    isClaimed: contractUpdateData[0].expirationDate === null ? true : false,
                    validationMsg: contractUpdateData[0].expirationDate === null ? "Please fill the expiration date and Claim the Contract to edit the contract" : "",
                    project_id: contractUpdateData[0].project_id,
                    contractNumber: contractUpdateData[0].contractNumber,
                    contractKey: contractUpdateData[0]._id,
                    projectName: contractUpdateData[0].projectName,
                    desc: contractUpdateData[0].desc,
                    commitmentStartDate: convertDateWithTimeZone(contractUpdateData[0].commitmentStartDate),
                    preparerOrg: {
                        id: contractUpdateData[0].preparerByOrg ? contractUpdateData[0].preparerByOrg.id : '',
                        name: contractUpdateData[0].preparerByOrg ? contractUpdateData[0].preparerByOrg.name : ''
                    },
                    preparerUser: {
                        id: contractUpdateData[0].preparerByUser ? contractUpdateData[0].preparerByUser.id : '',
                        name: contractUpdateData[0].preparerByUser ? contractUpdateData[0].preparerByUser.name : '',
                        phone: contractUpdateData[0].preparerByUser ? contractUpdateData[0].preparerByUser.phone : '',
                        email: contractUpdateData[0].preparerByUser ? contractUpdateData[0].preparerByUser.email : ''
                    },
                    expirationDate: contractUpdateData[0].expirationDate,
                    isApplied: true
                })
            };
        }

    }
    /**
    * Handle date change
    */

    handleChangeDatess = (name, value) => {
        this.setState({ [name]: value });
    }

    // handle auto complete

    handleAutoCompolete = (selectedOption) => {
        this.setState({
            preparerOrg: {
                id: selectedOption.value,
                name: selectedOption.label
            },
            preparerUser: {
                id: "",
                name: "",
                phone: "",
                email: ""
            }
        });
        if (selectedOption.value) {
            this.props.getAllPreparerInOrg(selectedOption.value);
        }
    }


    // handleAutoComplete for Preparer
    handleAutoCompletePreparer = (selectedOption) => {
        this.setState({
            preparerUser: {
                id: selectedOption.value,
                name: selectedOption.label,
                phone: selectedOption.phone,
                email: selectedOption.email
            }
        });
    }

    /**
    * Handle TextBox and DropDown Change Event
    */
    handleChange = (name, value) => {
        if (name === "contractNumber") {
            var reg = new RegExp("^([<>a-zA-z0-9]*)(-[a-zA-z0-9]+)*(-)?$");
            var contNumReg = reg.test(value)
            if (contNumReg) {
                this.setState({ [name]: value });
            }
        } else if (name === 'commitmentStartDate') {
            var datereg = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
            var comitdate = datereg.test(value)
            if (comitdate) {
                this.setState({ [name]: value });
            }
        } else {
            this.setState({ [name]: value });
        }
    }

    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.siteRef.refs.form.submit();
    };

    /**
     * Handle Form Save Action Click
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            if (this.props.projectId) {
                formData.project_id = parseInt(this.props.projectId, 10);
            }
            this.props.addNewSite(formData);
        }
    };
    /**
    * Handle Form Submit Event
    */
    handleSubmit = (e) => {
        const { contractsId, loanProjectsId } = this.props;
        let formData = {
            ...this.state
        }
        if (formData.contractNumber === "<empty>") {
            this.props.showWarningAlert('Please enter a valid Contract Number')
            return
        }
        if (this.state.commitmentStartDate._isValid === false) {
            return
        }
        this.props.clearAlert()
        if (contractsId) {
            this.props.updateContract({ ...formData, _id: contractsId }, loanProjectsId)
            this.setState({ commitmentStartDate: formData.commitmentStartDate })

        } else {
            this.props.insertContract(formData, loanProjectsId);
        }
        this.props.getByContractId(contractsId);
    };

    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
   * Bind Html to DOM
   */
    render() {
        let { classes, allOrg, getOne, loanProjectsId, pageAccess } = this.props;
        const { contractNumber, commitmentStartDate, desc, preparerOrg, preparerUser, projectName, contractKey, isClaimed, validationMsg } = this.state;
        // let isFunder =  ['funderWithOA', 'funderWithOAandHO', 'funderWithOAandBoth', 'funderWithoutOA', 'funderWithoutOAandHO', 'funderWithoutOAandBoth', 'superFunderWithHO', 'superFunder'].includes(getUserRole());
        //let isNonFundersWithOA = ['nonFunderWithOA', 'nonFunderWithOAandHO', 'nonFunderWithOAandBoth'].includes(getUserType());
        //let isContractor = getUserType()==='Contractor';
        //let isPreperar = getUserType()==='Preparer';
        //let isnewContract =  location.pathname.split("/")[4]==="new"
        const isReadOnly = pageAccess && pageAccess.indexOf('SAVE') <= -1;
        const isFunderAccess = pageAccess && pageAccess.indexOf('FNDR_ACCESS') <= -1;
        // let isEditableUser = isFunder || ((isContractor || isPreperar) && isNonFundersWithOA);
        // let validationMsg = "";
        // if (isFunder&&isReadOnly) {
        //     validationMsg = "Please fill the expiration date and Claim the Contract to edit the contract";
        // }
        // let isEnableOA = (isEditableUser && expirationDate !== null) ? false : (isEditableUser && isnewContract) ? false : true;
        // let isEnableFunder = (isFunder && expirationDate !== null) ? false : (isFunder && isnewContract) ? false : true;
        return (
            <div className={`${classes.rootEdit} contractRootEdit`}>
                {isClaimed &&
                    <div className="cliamedChipDiv">
                        <Chip
                            label={validationMsg}
                            className={`${classes.chip} cliamedChip`}
                            color="secondary"
                        />
                    </div>
                }
                <ValidatorForm
                    name="userForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)} >

                    <Grid container spacing={4} >
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="projectName"
                                    value={loanProjectsId ? getOne.projectName : projectName}
                                    label="Project Name"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="center">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    type="text"
                                    name="contractNumber"
                                    value={contractNumber}
                                    label="Contracts Number"
                                    className={classes.textField}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={isFunderAccess}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    type="number"
                                    name="contractKey"
                                    value={contractKey}
                                    label="Contract Key"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextArea
                                name={"desc"}
                                value={desc}
                                label="Description"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                //old condition (isFunder && expirationDate !== null) ? false : (isFunder && isnewContract) ? false : true
                                disabled={isFunderAccess}
                                validators={['isMaxLen1000']}
                                errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.STR_LEN1000]}
                                multiline={true}
                                needCustomToolTip={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} className={classes.autoCompleteTop} align="left">
                            <Grid item xs={12} sm={10} className="autoCompleteTop10">
                                <AutoComplete
                                    className="contPreparerOrg"
                                    placeholder='Preparer Organization'
                                    suggestions={allOrg || ""}
                                    handleAutoCompolete={this.handleAutoCompolete}
                                    selectedValue={preparerOrg}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    //disabled={isNotOAUser?true:isFunder || isOAUser?false:true}
                                    disabled={isReadOnly}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} className={classes.autoCompleteTop} align="center">
                            <Grid item xs={12} sm={10} className="autoCompleteTop">
                                <AutoComplete
                                    placeholder='Preparer'
                                    suggestions={getOne.praparerByOrg}
                                    handleAutoCompolete={this.handleAutoCompletePreparer}
                                    selectedValue={preparerUser}
                                    validators={['required']}
                                    // disabled={isNotOAUser?true:isFunder||isOAUser?false:true}
                                    disabled={isReadOnly}
                                    errorMessages={['This field is required']}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="phone"
                                    value={preparerUser.phone}
                                    label="Phone"
                                    className={classes.textField}
                                    margin="dense"
                                    // validators={['required']}
                                    // errorMessages={['This field is required']}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="email"
                                    value={preparerUser.email}
                                    label="Email"
                                    className={classes.textField}
                                    margin="dense"
                                    // validators={['required']}
                                    // errorMessages={['This field is required']}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="center">
                            <Grid item xs={12} sm={10}>
                                <DatePicker
                                    name={"commitmentStartDate"}
                                    label="Commitment Start Date"
                                    value={commitmentStartDate}
                                    handleChange={this.handleChangeDatess}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    //old condition !(isFunder && expirationDate !== null)
                                    disabled={isFunderAccess}
                                    className={classes.textField}
                                />
                            </Grid>
                        </Grid>
                        {!isReadOnly ?
                            <Grid item xs={12}>
                                <SaveCancel
                                    saveText="Save"
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }}
                                />
                            </Grid> : null
                        }
                    </Grid>
                </ValidatorForm>
            </div>
        );
    }
}

/**
 * Bind Component Property Types
 */
ContractsEdit.propTypes = {

};

ContractsEdit.defaultProps = {

};

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    const allOrg = state.contract.getOne.orgs
    const getOne = state.contract.getOne
    const contractUpdateData = state.contract.fullUpdateDetails
    const contractsCount = state.contract.count
    return {
        allOrg,
        getOne,
        contractUpdateData,
        contractsCount,
        pageAccess: ownProps.history.location.pathname.split("/")[4] === "new" ? state.pageAccess['contractNew'] : state.pageAccess['contractEdit'] || [],
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllOrg: (text, page) => dispatch(contractAction.getOrganizations(text, page)),
        getAllPreparerInOrg: (value) => dispatch(contractAction.getAllPreparerInOrg(value)),
        getOnePreparerDetails: (value) => dispatch(contractAction.getOnePreparerDetails(value)),
        insertContract: (data, loanProjectsId) => dispatch(contractAction.insertContractDetails(data, loanProjectsId)),
        updateContract: (data, loanProjectsId) => dispatch(contractAction.updateContract(data, loanProjectsId)),
        getByContractId: (id) => dispatch(contractAction.getByContractId(id)),
        getProjectDetails: (id) => dispatch(contractAction.getProjectDetails(id)),
        showWarningAlert: (msg) => dispatch(alertActions.error(msg)),
        clearAlert: () => dispatch(alertActions.clear()),

    }
};


/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(ContractsEdit));