import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'underscore';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import UsersEdit from './UsersEdit';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Actions
import { userActions } from '../../actions/user';
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * UsersNew Component
 * @class UsersNew
 * @extends {Component}
 */
class UsersNew extends Component {
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     * @param {*} imageData 
     */
    handleFormSaveActionClick = (formData, imageData) => {
        if (formData) {
            this.props.addNewUser(formData, imageData);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
   * Bind Html to DOM
   */
    render() {
        let { classes, removeActions, title, pageAccess, isSubPage, orgId } = this.props;
        pageAccess = _.difference(pageAccess, removeActions);
        // let isReadOly = pageAccess.indexOf('SAVE') === -1;
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleBackActionClick={this.handleBackActionClick}
            >
                <UsersEdit
                    isSubPage={isSubPage}
                    pageAccess={pageAccess}
                    orgId={orgId}
                    isLoggedInUser={true}
                    innerRef={(node) => { this.userRef = node; }}
                    isReadOly={false}
                    handleFormSaveActionClick={(formData, imageData = null) => this.handleFormSaveActionClick(formData, imageData)}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
UsersNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    title: PropTypes.string,
    pageAccess: PropTypes.array,
    isSubPage: PropTypes.bool
};
/**
 * Default Props
 */
UsersNew.defaultProps = {
    removeActions: [],
    title: 'Create New User',
    isSubPage: false,
    pageAccess: []
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        pageAccess: state.pageAccess['userNew'] || []
    }
};
/**
 * Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        addNewUser: (formData, imageData) => dispatch(userActions.addNewUser(formData, imageData))
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(UsersNew));