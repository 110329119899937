import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Actions
import { occupantsActions } from '../../../actions/occupants';
import { alertActions } from '../../../actions/alert';
import { confActions } from '../../../actions/configuration';

// Import Components
import TextBox from '../../../components/TextBox/TextBox';
import EditableTable from '../../../components/EditableTable/EditableTable';
import DatePicker from '../../../components/DatePicker/DatePicker';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import AutoCompleteMultiMUI from '../../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import ErrorDisplay from '../../../components/ErrorDisplay/ErrorDisplay';
import TableErrors from '../../../components/TableErrors/TableErrors';
import PageActions from '../../../components/PageActions/PageActions';

// import helpers
import { relationshipHOH, discloseType, ethinicity, configurationConstants, householdStudentException } from '../../../helpers/appConstants';
import { Table1MRDValidations } from '../../../helpers/TableValidations/Table1';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
import { occupantRequiredFields, DEFAULT_MEMBERINFO } from "./Validations";
import { sortByOrderUnderScoreJS_numberString } from '../../../helpers';
import { history } from '../../../helpers';


/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled HHI-textBox` : `HHI-textBox`
        return (
            <TextBox
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={fieldName === ('ssnLast4') ? ['lessThan9999'] : []}
                errorMessages={['Value should be 4 digits']}
                maxLength={fieldName === ('ssnLast4') ? 4 : 50}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly}
            />
        )
    }
};
/**
 * MiddleInitialTF Component
 * @class MiddleInitialTF
 * @extends {Component}
 */
class MiddleInitialTF extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled HHI-textBox` : `HHI-textBox`
        return (
            <TextBox
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly}
            />
        )
    }
};
/**
 * DateOfBirth Component
 * @class DateOfBirth
 * @extends {Component}
 */
class DateOfBirth extends React.Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `HHI-date HHI` : `HHI-date HHI`
        return (
            <DatePicker
                className={className}
                format={"MM/DD/YYYY"}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, date) => {
                    onChange(fieldName, rowIndex, date);
                }}
                margin="dense"
                disabled={isReadOly}
                validations={{
                    maxDate: new Date()
                }}
                helperText={''}
            />
        )
    }
}
/**
 * RelationshipHOHType Component
 * @class RelationshipHOHType
 * @extends {Component}
 */
class RelationshipHOHType extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { relationshipHOH, isReadOly } } = this.props;
        //    console.log(123123, data, relationshipHOH);
        const rt = relationshipHOH;
        // if (rowIndex === 0) {
        //     rt = relationshipHOH.filter(u => u)
        // }
        // else {
        //     rt = relationshipHOH.filter(u => u);
        // }
        let className = isReadOly ? `input--disabled HHI-autocomplete HHI` : `HHI-autocomplete HHI`
        return (
            <div className=" autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    className={className}
                    suggestions={rt}
                    placeholder={''}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                        console.log("hi it is ")
                    }}
                    selectedValue={{ id: data[fieldName] }}
                    disabled={isReadOly}
                    margin='normal'
                />
            </div>
        )
    }
};
/**
 * DiscloseType Component
 * @class DiscloseType
 * @extends {Component}
 */
class DiscloseType extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { discloseType, isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled HHI-autocomplete HHI` : `HHI-autocomplete HHI`
        return (
            <div className="autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    className={className}
                    suggestions={discloseType}
                    placeholder={''}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    selectedValue={{ id: data[fieldName] }}
                    disabled={isReadOly}
                    margin='normal'
                />
            </div>
        )
    }
};
/**
 * EthnicityType Component
 * @class EthnicityType
 * @extends {Component}
 */
class EthnicityType extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { ethinicity, isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled HHI-autocomplete HHI` : `HHI-autocomplete HHI`
        return (
            <div className="autocompleteList no-margin">
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={ethinicity}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value);
                    }}
                    className={className}
                    selectedValue={{ id: data[fieldName] }}
                    margin='normal'
                    disabled={isReadOly}
                />
            </div>
        )
    }
};
/**
 * RaceTypeAutoMulti Component
 * @class RaceTypeAutoMulti
 * @extends {Component}
 */
class RaceTypeAutoMulti extends Component {
    render() {
        let { data, rowIndex, onChange, fieldName, config: { race, isReadOly, isCurrentReportYear } } = this.props;
        race.map(elem => {
            elem.label = (elem.key === 14 ? 8 : elem.key) + " - " + elem.name
            return elem
        })
        race = sortByOrderUnderScoreJS_numberString(race, 1, 'label')
        fieldName = fieldName === 'householdMemberRaceType' ? 'householdMemberRaceType_id' : fieldName
        const raceIds = `${data[fieldName]}` || '0';
        let strToArr = raceIds.split(',').filter(s => s !== "0").map(s => {
            let key = parseInt(s, 10);
            key = key || 14;
            const label = race.filter(r => r.key === key)[0] || { desc: null };
            return {
                label: (key === 14 ? 8 : key) + " - " + (label.desc || label.name),
                value: key,
                key
            }
        });
        strToArr = strToArr.filter(f => f.label !== null);
        let className = isReadOly ? `input--disabled HHI-autocomplete HHI` : `HHI-autocomplete HHI`
        if (isCurrentReportYear) {
            return (
                <div className="autocompleteList no-margin">
                    <AutoComplete
                        name={`${fieldName}_${rowIndex}`}
                        placeholder=""
                        suggestions={race}
                        handleAutoCompolete={(selectedOption) => {
                            onChange(fieldName, rowIndex, selectedOption.value);
                        }}
                        className={className}
                        selectedValue={{ id: +data[fieldName] }}
                        margin='normal'
                        disabled={isReadOly}
                    />
                </div>
            )
        }
        return (
            <div className=' autocompleteList no-margin race-sel'>
                <AutoCompleteMultiMUI
                    placeholder=''
                    className={className}
                    suggestions={race}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.map(s => s.key).join(","));
                    }}
                    selectedValue={strToArr}
                    disabled={isReadOly}
                    validators={[]}
                    errorMessages={[]}
                    margin='normal'
                />
            </div>
        )
    }
};

const componentMap = {
    firstName: EditableText,
    lastName: EditableText,
    middleInitial: MiddleInitialTF,
    familyRelationshipType_id: RelationshipHOHType,
    fullTimeStudentType_id: DiscloseType,
    dateOfBirth: DateOfBirth,
    ssnLast4: EditableText,
    householdMemberRaceType: RaceTypeAutoMulti,
    householdMemberEthnicityType_id: EthnicityType,
    disabilityType_id: DiscloseType
}
/**
 * HouseHoldInfo Container
 * @class HouseHoldInfo
 * @extends {Component}
 */
class HouseHoldInfo extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            config: {
                race: []
            },
            table1HouseholdMemberDC: [],
            table1DeletedMM: [],
            householdStudentExceptionType_id: '',
            _id: null,
            householdSize: 0,
            hohExistOccupant: false,
            existHeadOfHouseHold: '',
            errors: {
                hoherror: null,
            },
            deleteFlag: false,
            changeFlag: false,
            visibleDialog: false,
            hasTable1DC: false,
            AssetData: [],
            orginalMemDC: [],
            updatedAsset: [],
            isMemEmpty: false,
            canChangeMoveInDate: null,
            householdSize_MoveIn: '',
            isCurrentYear: null,
            isWSHFC: false,
            isImplementFromCurrentYear: false,
            isNewOccupant: false,
            isWSHFCFT:false
        };
    }
    /**
     * Componet Did Mount
     * 
     * [1]  -   get validation error for occupants
     * [2]  -   config
     * [3]  -   validation for SSN
     */
    componentDidMount() {
        const { tableId, reportYear_id, getOne } = this.props;
        let id = tableId;
        if (id) {
            console.log("ID", id)
            this.props.getOccupantsDetails(id, reportYear_id);
            // [1]
            this.props.getOneValidationError(id, reportYear_id);
        }
        // [2]
        this.props.getConfiguration(configurationConstants.householdMemberInfoRace);
        //[3]
        ValidatorForm.addValidationRule('lessThan9999', (value) => {
            let intValue = parseInt(value)
            if (intValue === "NaN" || intValue > 9999 || intValue === null || value.length < 4) {
                return false;
            }
            return true;
        });
        getOne.data != null && this.setState({ orginalMemDC: getOne.data.table1HouseholdMemberDC })

        //GET funder details for new occupant
        if(history.location.pathname.includes('new')){
            const {getOne:{data}} = this.props
            this.props.getFunderDetails(data.project_id.id)
        }
        // const {getOne} = this.props;

        // const { householdSize, table1HouseholdMemberDC} = getOne.data;

        // console.log("HOUSE HOLD SIZE", householdSize);

        // console.log("TABLE!HOUSEHOLDMEMBERDC", table1HouseholdMemberDC);

        //  if (householdSize < table1HouseholdMemberDC.length) {
        //     console.log("INSIDE COMPONENT DID MOUNT")
        // }

        // const { householdSize, table1HouseholdMemberDC } = this.state;



        // if (householdSize < table1HouseholdMemberDC) {
        //     console.log("INSIDE COMPONENT DID MOUNT")
        // }

    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     * 
     * [1]  -   auto fill head of house hold if the length is 0
     * [2]  -   auto fill other memeber details which is not filled
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, race, monitoringFunders } = nextProps;

        // if (getOne.data && this.state._id === null) {
        //     const { householdSize, table1HouseholdMemberDC} = getOne.data;
        //     console.log("HOUSE HOLD SIZE", householdSize);

        //    console.log("TABLE!HOUSEHOLDMEMBERDC", table1HouseholdMemberDC);

        //     if (householdSize < table1HouseholdMemberDC.length) {
        //         this.setState({
        //             visibleDialog: true
        //         })
        //     }

        // }

        if (getOne.data && this.state._id === null) {

            //     const { householdSize} = getOne.data;
            //     console.log("HOUSE HOLD SIZE", householdSize);

            //    console.log("TABLE!HOUSEHOLDMEMBERDC", table1HouseholdMemberDC);

            //     if (householdSize < getOne.data.table1HouseholdMemberDC.length) {
            //         this.setState({
            //             visibleDialog: true
            //         })
            //     }

            var MemData = getOne.data.table1HouseholdMemberDC;
            var AssetData = getOne.data.table1HouseholdMemberDCIncomeFromAssets;

            if (getOne.data && getOne.data.limits && getOne.data.limits.data) {
                this.setState({ isWSHFC: getOne.data.limits.data.isWSHFC, isImplementFromCurrentYear: getOne.data.isImplementFromCurrentYear })
            }
            //handle fulltime student type based on funders
            if (history.location.pathname.includes('new')) {
                if(getOne && getOne.fpAllFunders){
                console.log('GETONE---DATAIIIIIIIIIIIIIIIIIIIIIIIIIIIIII-------------',getOne)
                let hasWSHFC = getOne.fpAllFunders.filter(item => item.org_id === 1)
                console.log('GETONE---DATA-------------',hasWSHFC)
                if (hasWSHFC.length > 0) {
                    let expiryYear = new Date(hasWSHFC[0].expirationDate).getFullYear()
                    let isWSHFCValid = parseInt(expiryYear) >= parseInt(getOne.data.moveInYear)
                    console.log("🚀 ~ file: HouseHoldInfo.js:431 ~ UNSAFE_componentWillReceiveProps ~ parseInt(getOne.data.moveInYear):", parseInt(getOne.data.moveInYear))
                    console.log("🚀 ~ file: HouseHoldInfo.js:431 ~ UNSAFE_componentWillReceiveProps ~ parseInt(expiryYear):", parseInt(expiryYear))

                    if (isWSHFCValid) {
                        this.setState({ isWSHFCFT: true, isImplementFromCurrentYear: true })
                    } else {
                        this.setState({ isWSHFCFT: false, isImplementFromCurrentYear: true })
                    }
                } else {
                    this.setState({ isWSHFCFT: false, isImplementFromCurrentYear: true })
                }
            }
            }else{
                let hasWSHFC = getOne.data.fpAllFunders.filter(item => item.org_id === 1)
                if (hasWSHFC.length > 0) {
                    let expiryYear = new Date(hasWSHFC[0].expirationDate).getFullYear()
                    let isWSHFCValid = parseInt(expiryYear) >= parseInt(getOne.data.currentYear)

                    if (isWSHFCValid) {
                        this.setState({ isWSHFCFT: true, isImplementFromCurrentYear: true })
                    } else {
                        this.setState({ isWSHFCFT: false, isImplementFromCurrentYear: true })
                    }
                } else {
                    this.setState({ isWSHFCFT: false, isImplementFromCurrentYear: true })
                }
            }


            // AssetData&&AssetData.map((data,index)=>{
            //     if(index==0){
            //         return data.table1HouseholdMemberDC=[MemData[0]];
            //     }else{
            //         return data.table1HouseholdMemberDC=[MemData[data.table1HouseholdMemberDC_id]]
            //     }
            // })
            this.setState({ isCurrentYear: getOne.data.isCurrentYear })
            console.log(getOne.data.isCurrentYear)
            console.log('======', MemData)
            console.log('======', AssetData)
            if (AssetData && AssetData.length > 0 && AssetData[0].table1HouseholdMemberDC) {
                if (AssetData[0].table1HouseholdMemberDC.length > 0) {
                    this.setState({ hasTable1DC: true })
                }
            }

            this.setState({
                existHeadOfHouseHold: `${getOne.data.firstName} ${getOne.data.middleInitial} ${getOne.data.lastName}`,
                AssetData: getOne.data.table1HouseholdMemberDCIncomeFromAssets,
                canChangeMoveInDate: getOne.data.canChangeMoveInDate,
                householdSize_MoveIn: getOne.data.householdSize_MoveIn
            });

            let table1HouseholdMemberDC = getOne.data.table1HouseholdMemberDC ? getOne.data.table1HouseholdMemberDC.map((hhm) => {
                hhm.householdMemberRaceType = hhm.householdMemberRaceType_id;
                if (hhm.householdMemberRaceType_id) {
                    if (typeof (hhm.householdMemberRaceType_id) === 'string') {
                        let raceArray = hhm.householdMemberRaceType_id.split(',');
                        raceArray.forEach((raceValue, index) => {
                            race.forEach(raceType => {
                                if (raceType.key === +raceValue) {
                                    hhm.householdMemberRaceType = (index !== 0 && hhm.householdMemberRaceType) ? `${hhm.householdMemberRaceType}, ${(raceType.key === 14 ? 8 : raceType.key) + ' - ' + raceType.name}` : (raceType.key === 14 ? 8 : raceType.key) + ' - ' + raceType.name
                                }
                            })
                        })
                    } else if (typeof (hhm.householdMemberRaceType_id) === 'number') {
                        race.forEach(raceType => {
                            if (raceType.key === hhm.householdMemberRaceType_id) {
                                hhm.householdMemberRaceType = raceType.key === 14 ? 8 + ' - ' + raceType.name : raceType.key + ' - ' + raceType.name
                            }
                        })
                    } else {
                        if (hhm && hhm.householdMemberRaceType_id && hhm.householdMemberRaceType_id.length > 0)
                            hhm.householdMemberRaceType_id.forEach((raceTypeId, index) => {
                                race.forEach(raceType => {
                                    if (raceType.key === +raceTypeId) {
                                        hhm.householdMemberRaceType = (index !== 0 && hhm.householdMemberRaceType) ? `${hhm.householdMemberRaceType}, ${(raceType.key === 14 ? 8 : raceType.key) + ' - ' + raceType.name}` : (raceType.key === 14 ? 8 : raceType.key) + ' - ' + raceType.name
                                    }
                                })
                            })
                    }
                }
                return hhm
            }) : [];
            // [1]
            if (table1HouseholdMemberDC.length === 0) {
                table1HouseholdMemberDC.push({
                    ...DEFAULT_MEMBERINFO,
                    // _id: Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,
                    _id: table1HouseholdMemberDC[getOne.data.householdSize - 1] ? table1HouseholdMemberDC[getOne.data.householdSize - 1]._id + 1 : Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,

                    firstName: "HOH",
                    lastName: getOne.data.headOfHouseholdName,
                    familyRelationshipType_id: 1,
                    householdMemberRaceType_id: 9,
                    householdMemberRaceType: race && race.length > 0 && race.filter(raceType => raceType.key === 9)[0].key + ' - ' + race.filter(raceType => raceType.key === 9)[0].name,
                    householdMemberEthnicityType_id: 9,
                    disabilityType_id: 3
                });
            }
            // [2]
            if (table1HouseholdMemberDC.length < getOne.data.householdSize) {
                console.log('2')
                for (let i = table1HouseholdMemberDC.length; i < getOne.data.householdSize; i++) {
                    table1HouseholdMemberDC.push({
                        ...DEFAULT_MEMBERINFO,
                        firstName: `${i + 1}`,
                        lastName: "HHM",
                        // _id: Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,
                        _id: table1HouseholdMemberDC[getOne.data.householdSize - 1] ? table1HouseholdMemberDC[getOne.data.householdSize - 1]._id + 1 : Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,

                        familyRelationshipType_id: 7,
                        householdMemberRaceType_id: 9,
                        householdMemberRaceType: race && race.length > 0 && race.filter(raceType => raceType.key === 9)[0].key + ' - ' + race.filter(raceType => raceType.key === 9)[0].name,
                        householdMemberEthnicityType_id: 9,
                        disabilityType_id: 3
                    });
                }
            }


            table1HouseholdMemberDC.sort((a, b) => {
                // Sort by firstName in ascending order
                if (a.firstName < b.firstName) {
                    return -1;
                } else if (a.firstName > b.firstName) {
                    return 1;
                } else {
                    return 0;
                }
            }).map((elem, index) => {
                // Check if firstName is numeric
                if (!isNaN(elem.firstName)) {
                    // Convert to number and add a large value
                    elem.firstName = table1HouseholdMemberDC.filter(member => isNaN(member.firstName)).length + index + 1;
                }
                return elem;
            });

            table1HouseholdMemberDC.sort((a, b) => {
                // Sort by firstName
                const firstNameA = isNaN(a.firstName) ? -Infinity : Number(a.firstName);
                const firstNameB = isNaN(b.firstName) ? -Infinity : Number(b.firstName);
                if (firstNameA < firstNameB) {
                    return -1;
                } else if (firstNameA > firstNameB) {
                    return 1;
                } else {
                    return 0;
                }
            });



            let len = table1HouseholdMemberDC.length;
            let i = 0;
            let existHo;
            while (i < len) {
                if (table1HouseholdMemberDC[i].familyRelationshipType_id === 1) {
                    this.setState({
                        hohExistOccupant: table1HouseholdMemberDC[i].firstName + table1HouseholdMemberDC[i].lastName
                    });
                    if (table1HouseholdMemberDC[i].lastName.toLowerCase() === 'hhm') {
                        existHo = table1HouseholdMemberDC[i].firstName
                    } else {
                        existHo = table1HouseholdMemberDC[i].firstName + table1HouseholdMemberDC[i].middleInitial + table1HouseholdMemberDC[i].lastName;
                    }
                    const isNewOccupant = history.location.pathname.includes('new')
                    //current year
                    if ((getOne && getOne.data && getOne.data.isCurrentYear) || isNewOccupant) {
                        table1HouseholdMemberDC[i].firstName = (getOne.data.firstName === '' || getOne.data.firstName === null) ? 'HOH' : getOne.data.firstName;
                        table1HouseholdMemberDC[i].lastName = getOne.data.lastName;
                        // table1HouseholdMemberDC[i].middleInitial = getOne.data.middleInitial;
                        table1HouseholdMemberDC[i].middleInitial == '' && getOne.data.headOfHouseholdName.split(' ').length !== 3 && getOne.data.middleInitial !== '' ?  
                            table1HouseholdMemberDC[i].middleInitial = getOne.data.middleInitial:
                            table1HouseholdMemberDC[i].middleInitial == '' && getOne.data.headOfHouseholdName.split(' ').length === 3 && getOne.data.middleInitial !== ''?
                            table1HouseholdMemberDC[i].middleInitial =getOne.data.headOfHouseholdName.split(' ')[1]:
                            table1HouseholdMemberDC[i].middleInitial=table1HouseholdMemberDC[i].middleInitial
                    } else {
                        //snapshot years
                        const hoName = getOne.data.headOfHouseholdName;
                        const hoNameArray = hoName.split(/\ +/);
                        var newHo = hoNameArray[0];
                        var mTestFlag = 0;
                        table1HouseholdMemberDC[i].firstName = hoNameArray[0];
                        table1HouseholdMemberDC[i].lastName = "HHM";


                        if (hoNameArray.length === 2) {
                            table1HouseholdMemberDC[i].lastName = hoNameArray[1];
                            newHo = hoNameArray[0] + ' ' + hoNameArray[1];
                        } else if (hoNameArray.length >= 3) {
                            table1HouseholdMemberDC[i].middleInitial = hoNameArray[1];
                            table1HouseholdMemberDC[i].lastName = hoNameArray[2];
                            newHo = hoNameArray[0] + hoNameArray[1] + hoNameArray[2];
                            mTestFlag = 1;
                        }

                        if (hoNameArray.length > 3) {
                            table1HouseholdMemberDC[i].firstName = hoNameArray[0];
                            table1HouseholdMemberDC[i].middleInitial = hoNameArray[1];
                            table1HouseholdMemberDC[i].lastName = hoNameArray[2];
                            newHo = hoNameArray[0] + hoNameArray[1] + hoNameArray[2];
                            mTestFlag = 1;
                        }

                    }




                    // existHo = existHo.replace(/ +/g, "");
                    //newHo = newHo.replace(/ +/g, "");
                    if (isNaN(existHo) && existHo.trim().toLowerCase().includes('hoh')) {
                        existHo = existHo.substring(3);
                    }
                    //if(existHo.trim().toLowerCase() !== newHo.trim().toLowerCase() ){
                    // table1HouseholdMemberDC[i].dateOfBirth = '01/01/1999';
                    // table1HouseholdMemberDC[i].disabilityType_id = 3;
                    // if(!mTestFlag){
                    //        table1HouseholdMemberDC[i].middleInitial = '';
                    // }
                    // table1HouseholdMemberDC[i].fullTimeStudentType_id =3;
                    // table1HouseholdMemberDC[i].ssnLast4 = 9999;
                    // table1HouseholdMemberDC[i].householdMemberRaceType_id = 9;
                    // table1HouseholdMemberDC[i].householdMemberEthnicityType_id = 9; 

                    //}



                    const el = table1HouseholdMemberDC.splice(i, 1)[0];
                    table1HouseholdMemberDC.unshift(el);
                    len--;
                } else {
                    i++;
                }
            }

            //update middle initial
            let middleInitialFromGeneral = (getOne.data&&getOne.data.middleInitial&&getOne.data.middleInitial) || '';

            table1HouseholdMemberDC.map(item=>{
                if(item.familyRelationshipType_id===1){
                    item.middleInitial=middleInitialFromGeneral
                }
            })

            this.setState({
                ...getOne.data,
                table1HouseholdMemberDC,
                orginalMemDC:table1HouseholdMemberDC
            });

            if (getOne.data.householdSize < table1HouseholdMemberDC.length) {
                this.setState({
                    visibleDialog: true
                })
            }
        }
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm(2);
    };

    /**
    * Handle SPrev Button
    * @param {*} e
    */
    handlePrevButton = (e) => {

        const error = { ...this.getHouseHoldSizeError() }

        if (!error.required) {
            this._saveForm(0);
        }

        return;
    };
    onHandleDelete = (data) => {
        console.log('---onDelete')
        const { AssetData, deleteFlag, originalMemeDC } = this.state;
        console.log(data)
        if (deleteFlag && AssetData.length > 0) {
            // Asset Delete
            // const elementIndex= AssetData.findIndex(
            //     (item)=>  item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC[0]&&(data.firstName+" "+data.lastName === item.table1HouseholdMemberDC[0].firstName+" "+item.table1HouseholdMemberDC[0].lastName)
            //     )
            const deleteAsset = AssetData.filter(
                (item) => item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && (data.firstName + " " + data.lastName === item.table1HouseholdMemberDC[0].firstName + " " + item.table1HouseholdMemberDC[0].lastName)
            )
            const NonDeleteAsset = AssetData.filter(
                (item) => item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && (data.firstName + " " + data.lastName != item.table1HouseholdMemberDC[0].firstName + " " + item.table1HouseholdMemberDC[0].lastName)
            )
            console.log(deleteAsset, NonDeleteAsset)
            // const memberEle=originalMemeDC.findIndex(
            //     (item)=> data.firstName+" "+data.lastName === item.firstName+" "+item.lastName
            //     )
            // if(elementIndex>=0){
            // AssetData.splice(elementIndex,1);
            // }
            // const removedMember=originalMemeDC.splice(memberEle,1);
            this.setState({ updatedAsset: NonDeleteAsset, AssetData: NonDeleteAsset })
            // console.log(elementIndex,AssetData)
        }

    }

    /**
     * Save form
     * 
     * [1]  -   set up api form submission datas
     * [2]  -   save to local move to next tab
     */
    _saveForm(value) {
        // [1]
        const { table1HouseholdMemberDC, householdStudentExceptionType_id, existHeadOfHouseHold, updatedAsset, isWSHFC,isWSHFCFT, isImplementFromCurrentYear, isNewOccupant } = this.state;
        const {getOne} = this.props

        if (table1HouseholdMemberDC) {
            const filteredHOHName = table1HouseholdMemberDC.filter((data) => {
                var checkFullName = data.firstName + data.lastName;
                // if(data.familyRelationshipType_id===1 && checkFullName.trim().toLowerCase() !== hohExistOccupant.trim().toLowerCase() ){

                //         return data.firstName;
                // }
                if (data.familyRelationshipType_id === 1) {

                    return data.firstName;
                }
                return false;
            })

            let householdFirstName = (filteredHOHName.length > 0) ? (filteredHOHName[0].firstName.toString().trim().toLowerCase() != 'hoh') ? filteredHOHName[0].firstName + ' ' : '' : '';
            //let householdMiddleName =(filteredHOHName.length > 0) ?(filteredHOHName[0].middleInitial != '') ? filteredHOHName[0].middleInitial+' ': '': '';
            let householdLastName = (filteredHOHName.length > 0) ? (filteredHOHName[0].lastName.trim().toLowerCase() != 'hhm') ? filteredHOHName[0].lastName : '' : '';
            let householdMiddleInitial = (filteredHOHName.length > 0) ? (filteredHOHName[0].middleInitial !== '' || filteredHOHName[0].middleInitial !== null) ? filteredHOHName[0].middleInitial : '' : '';
            let householdFullName = `${householdFirstName} ${(householdMiddleInitial === 0 || householdMiddleInitial === '') ? '' : householdMiddleInitial} ${householdLastName}`;

            // var convertedAsset=[];
            // if(this.state.deleteFlag && this.state.AssetData.length>0){
            //     // Asset Delete
            // const element= this.state.AssetData.filter(
            //     (item)=> table1HouseholdMemberDC.find(a=> item.table1HouseholdMemberDC&&item.table1HouseholdMemberDC[0]&&(a.firstName+" "+a.lastName == item.table1HouseholdMemberDC[0].firstName+" "+item.table1HouseholdMemberDC[0].lastName)
            //     )
            // )
            //  convertedAsset=element.map(ele => ({...ele, memberInfo: ele.table1HouseholdMemberDC[0]}));
            // }

            let currentYear = history.location.pathname.includes('new')?getOne&&getOne.data&&getOne.data.moveInYear:getOne&&getOne.data&&getOne.data.currentYear;

            //empty fulltimestudent value if the project is non WSHFC monitoring
            if (((!isWSHFCFT && isImplementFromCurrentYear === true) && isNewOccupant !== true) && currentYear>=2023) {
                table1HouseholdMemberDC.map(item => {
                    return item['fullTimeStudentType_id'] = null
                })
            }
            // Convert ISO object date to ISO date with time reseted for time zone issue 
            table1HouseholdMemberDC.map((data)=>{
                 data['dateOfBirth'] = typeof data.dateOfBirth != 'string'&& data.dateOfBirth != null ? data.dateOfBirth.toISOString().slice(0,10).concat('T00:00:00.000Z'):data.dateOfBirth
            })

            //check if both firstName and last name are empty for Head
            let checkHOHNames = table1HouseholdMemberDC.filter(item=>item.familyRelationshipType_id===1)[0];
            if((checkHOHNames.firstName===''||checkHOHNames.firstName===null || checkHOHNames.firstName==='HHM') && (checkHOHNames.lastName===''||checkHOHNames.lastName===null || checkHOHNames.lastName==='HHM')){
                this.props.getAlertOnFailure('Please enter a valid First name and Last name')
                return
            }
            
            const generalData = {
                // headOfHouseholdNameChanged:(filteredHOHName.length > 0) ?(filteredHOHName[0].lastName != '') ? filteredHOHName[0].firstName+' '+filteredHOHName[0].lastName  : filteredHOHName[0].firstName  : existHeadOfHouseHold,
                headOfHouseholdNameChanged: (filteredHOHName.length > 0) ? householdFullName : existHeadOfHouseHold,
                table1HouseholdMemberDC: table1HouseholdMemberDC,
                householdStudentExceptionType_id: (householdStudentExceptionType_id === '' || householdStudentExceptionType_id === undefined) ? null : householdStudentExceptionType_id,
                table1HouseholdMemberDCIncomeFromAssets: this.state.deleteFlag && this.state.hasTable1DC ? updatedAsset : this.state.AssetData,
                householdSize: table1HouseholdMemberDC.length,
                householdSize_MoveIn: (this.state.canChangeMoveInDate && this.state.canChangeMoveInDate == true) ? table1HouseholdMemberDC.length : this.state.householdSize_MoveIn,
                firstName: filteredHOHName[0].firstName,
                lastName: filteredHOHName[0].lastName,
                middleInitial: filteredHOHName[0].middleInitial,
                isImplementFromCurrentYear:true
            };

            if (this.props.tableId) {
                console.log(this.state.changeFlag, generalData)
                //this.state.deleteFlag || this.state.changeFlag ? this.props.updateOccupantsHHINCOMEDetails(this.props.tableId, generalData) : console.log('no change income');
                this.props.updateOccupantsHHI(this.props.tableId, generalData, () => {
                    // this.props.handleNav(null, 2);
                    this.props.handleNav(null, value);
                    this.props.getAlertOnSuccess("Updated Successfully")
                });
            } else {
                // [2]
                this.props.saveOccupantToLocal(generalData);
                this.props.handleNav(null, value);
            }
        }

    }
    /**
     * Cell Component Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = async (fieldName, rowIndex, value, fieldCols) => {
        const { race } = this.props;
        let newArray = [];
        var ChangeIndex;
        const { table1HouseholdMemberDC, updatedAsset, AssetData, table1HouseholdMemberDCIncomeFromAssets } = this.state;

        var originalMemeDC = this.state.orginalMemDC;
        var Assets = updatedAsset.length > 0 ? updatedAsset : table1HouseholdMemberDCIncomeFromAssets;
        var ChangeIndex = Assets && Assets.length > 0 && Assets.findIndex(item => item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && item.table1HouseholdMemberDC[0].firstName == table1HouseholdMemberDC[rowIndex]['firstName'] && item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && item.table1HouseholdMemberDC[0].lastName == table1HouseholdMemberDC[rowIndex]['lastName']);
        var ChangeAsset = Assets && Assets.length > 0 && Assets.filter(item => item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && item.table1HouseholdMemberDC[0].firstName == table1HouseholdMemberDC[rowIndex]['firstName'] && item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && item.table1HouseholdMemberDC[0].lastName == table1HouseholdMemberDC[rowIndex]['lastName']);
        var NonChangeAsset = Assets && Assets.length > 0 && Assets.filter(item => item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && item.table1HouseholdMemberDC[0].firstName != table1HouseholdMemberDC[rowIndex]['firstName'] && item.table1HouseholdMemberDC && item.table1HouseholdMemberDC[0] && item.table1HouseholdMemberDC[0].lastName != table1HouseholdMemberDC[rowIndex]['lastName']);

        console.log(Assets, ChangeAsset, NonChangeAsset)
        value = value === undefined ? null : value;

        await this.setState({ changeFlag: true })
        switch (fieldName) {
            case 'firstName':
                // console.log('***',Assets)
                // console.log('***',table1HouseholdMemberDC[rowIndex])
                // var ChangeIndex=Assets.findIndex(item=>item.memberInfo.firstName == table1HouseholdMemberDC[rowIndex]['firstName'] && item.memberInfo.lastName == table1HouseholdMemberDC[rowIndex]['lastName']);
                // console.log(Assets.findIndex(item=>item.memberInfo.firstName == table1HouseholdMemberDC[rowIndex]['firstName']))
                // console.log(Assets.map(item=>item.memberInfo.firstName+" "+item.memberInfo.lastName === table1HouseholdMemberDC[rowIndex]['firstName']+" "+table1HouseholdMemberDC[rowIndex]['lastName']))
                if(value.trim()===""){
                    table1HouseholdMemberDC[rowIndex][fieldName] = "";
                }else{
                table1HouseholdMemberDC[rowIndex][fieldName] = value;
                }
                if (Assets && Assets.length > 0 && ChangeIndex >= 0) {
                    // Assets[ChangeIndex]['table1HouseholdMemberDC']=[table1HouseholdMemberDC[rowIndex]]
                    // Assets[ChangeIndex]['memberInfo']=table1HouseholdMemberDC[rowIndex]
                    ChangeAsset.map((ele) => {
                        ele['table1HouseholdMemberDC'] = [table1HouseholdMemberDC[rowIndex]]
                        ele['memberInfo'] = table1HouseholdMemberDC[rowIndex]
                    })
                    this.setState({ table1HouseholdMemberDC, updatedAsset: [...ChangeAsset, ...NonChangeAsset] })
                } else {
                    this.setState({ table1HouseholdMemberDC })
                }
                console.log(table1HouseholdMemberDC, updatedAsset)

                break;
            case 'householdMemberRaceType_id':
                table1HouseholdMemberDC[rowIndex][fieldName] = value;
                table1HouseholdMemberDC[rowIndex]['householdMemberRaceType'] = race && race.length > 0 ? (value === 14 ? 8 : race.filter(raceType => raceType.key === value)[0].key) + ' - ' + race.filter(raceType => raceType.key === value)[0].name : value;

                if (Assets && Assets.length > 0 && ChangeIndex >= 0) {
                    ChangeAsset.map((ele) => {
                        ele['table1HouseholdMemberDC'] = [table1HouseholdMemberDC[rowIndex]]
                        ele['memberInfo'] = table1HouseholdMemberDC[rowIndex]
                    })
                    this.setState({ table1HouseholdMemberDC, updatedAsset: [...ChangeAsset, ...NonChangeAsset] })
                } else {
                    await this.setState({ table1HouseholdMemberDC });
                }
                break;
            default:
                table1HouseholdMemberDC[rowIndex][fieldName] = value;

                if (Assets && Assets.length > 0 && ChangeIndex >= 0) {
                    ChangeAsset.map((ele) => {
                        ele['table1HouseholdMemberDC'] = [table1HouseholdMemberDC[rowIndex]]
                        ele['memberInfo'] = table1HouseholdMemberDC[rowIndex]
                    })
                    this.setState({ table1HouseholdMemberDC, updatedAsset: [...ChangeAsset, ...NonChangeAsset] })
                } else {
                    await this.setState({ table1HouseholdMemberDC });
                }

                //-----------------------------------------
                const filteredHOH = table1HouseholdMemberDC.filter((data) => {
                    if (data.familyRelationshipType_id === 1) {
                        return true
                    }
                    return false;
                })
                if (value === 1 && fieldName == 'familyRelationshipType_id') {
                    if (Number(table1HouseholdMemberDC[rowIndex]['firstName'])) {
                        table1HouseholdMemberDC[rowIndex]['firstName'] = 'HOH';
                    }
                }
                // else   if(isNaN(table1HouseholdMemberDC[rowIndex]['firstName'])){
                //         if(table1HouseholdMemberDC[rowIndex]['firstName'].trim().toLowerCase() === 'hoh'){
                //         let indexOfHOH = table1HouseholdMemberDC[table1HouseholdMemberDC.length-1].familyRelationshipType_id===1 && table1HouseholdMemberDC[table1HouseholdMemberDC.length-1].firstName==='HOH' && fieldName==='lastName';
                //         if(!indexOfHOH){
                //     table1HouseholdMemberDC[rowIndex]['firstName'] = table1HouseholdMemberDC.length+1;
                //         }
                //     }
                // }


                if (filteredHOH.length > 1) {
                    this.setState({
                        ...this.state, errors: {
                            // hoherror : 'Household member info” section should have only one Head of Household.'
                            hoherror: null
                        }
                    })
                } else {
                    this.setState({
                        ...this.state, errors: {
                            hoherror: null
                        }
                    })
                }
            //  table1HouseholdMemberDC.map((data)=>{
            //     if ( (rowIndex === 0 && data.familyRelationshipType_id=== 1 )|| (rowIndex !==0 && data.familyRelationshipType_id=== 1) ) {
            //        this.setState({...this.state , errors : {
            //         hoherror : 'Household member info” section should have only one Head of Household.'
            //        }})
            //     }
            //     else{

            //         this.setState({...this.state, errors :{
            //             hoherror :null
            //            }})
            //     }
            //  })
        }
    }


    /**
     * Get HouseHold Size errors
     * @returns 
     */
    getHouseHoldSizeError() {
        const { householdSize, table1HouseholdMemberDC } = this.state;

        // const duplicateIds = table1HouseholdMemberDC
        // .map(e => e)
        // .map((e, i, final) => final.indexOf(e) !== i && i)
        // .filter(obj=> table1HouseholdMemberDC[obj])
        // .map(e => table1HouseholdMemberDC[e])
        const lookup = table1HouseholdMemberDC.reduce((a, e) => {
            delete a._id;
            // a[e] = ++a[e] || 0;
            console.log(a)
            return a;
        }, {});

        console.log(table1HouseholdMemberDC.filter(e => lookup[e]));
        //    console.log(table1HouseholdMemberDC,duplicateIds)

        const lowerCaseNames = {};
        const duplicateNames = {};
        if (table1HouseholdMemberDC) {
            for (let i = 0; i < table1HouseholdMemberDC.length; i++) {
                const member = table1HouseholdMemberDC[i];
                const { firstName, middleInitial, lastName } = member;
                if (firstName && !isNaN(firstName)) {
                    // Skip if firstName is numeric
                    continue;
                }
                const fullName = `${firstName || ''}${middleInitial ? ' ' + middleInitial : ''}${lastName || ''}`;
                const lowerCaseFullName = fullName.toLowerCase();
                if (lowerCaseNames[lowerCaseFullName] !== undefined) {
                    duplicateNames[i] = { required: true, message: 'duplicated' };
                    duplicateNames[lowerCaseNames[lowerCaseFullName]] = { required: true, message: 'duplicated' };
                } else {
                    lowerCaseNames[lowerCaseFullName] = i;
                }
            }
        }

        //    if(Object.keys(duplicateNames).length > 0 ){
        //         return {
        //             required: true,
        //             message: '“Details are duplicated, please enter the unique details.”'
        //         };
        //    }

        //---------------------------------------------------------
        var hasDupsOccupent = function (array) {
            return array.map(function (value) {
                var dob = typeof value.dateOfBirth != 'string' ? value.dateOfBirth != null && value.dateOfBirth && value.dateOfBirth.format('MM/DD/YYYY') : value.dateOfBirth;
                return value.firstName + value.lastName + value.middleInitial + value.familyRelationshipType_id + dob + value.fullTimeStudentType_id + value.ssnLast4 + value.householdMemberRaceType + value.householdMemberEthnicityType_id + value.disabilityType_id;
            }).some(function (value, index, array) {
                return array.indexOf(value) !== array.lastIndexOf(value);
            })
        }
        //   console.log('isduplicate...',hasDupsOccupent(table1HouseholdMemberDC),table1HouseholdMemberDC);
        if (hasDupsOccupent(table1HouseholdMemberDC)) {
            return {
                required: true,
                message: 'Details are duplicated, please enter the unique details.'
            };
        }
        //--------------------------------------------

        const filteredHOH = table1HouseholdMemberDC.filter((data) => {
            if (data.familyRelationshipType_id === 1) {
                return true
            }
            return false;
        })
        console.log('filteredHOH', filteredHOH);
        if (table1HouseholdMemberDC.length > 0) {
            if (filteredHOH.length < 1) {
                return {
                    required: true,
                    message: '"Household Member Info” section must have one Head of Household.'
                };
            }
            if (filteredHOH.length > 1) {
                return {
                    required: true,
                    message: ' "Household Member Info” section must have only one Head of Household.'
                };
            }
        }

        if (this.state.isMemEmpty) {
            if (filteredHOH.length < 1) {
                return {
                    required: true,
                    message: '"Household Member Info” section must have one Head of Household.'
                };
            }
        }

        // if (householdSize > table1HouseholdMemberDC.length) {
        //     return {
        //         required: true,
        //         message: 'The number of people listed in the "Household Member Info" section is lesser than the "Household Size". Please add members or descrease Household Size.'
        //     };
        // }

        if (filteredHOH.length === 1 || filteredHOH.length > 1) {

            if ((!filteredHOH[0].firstName && filteredHOH[0].firstName == "") && (!filteredHOH[0].lastName && filteredHOH[0].lastName == "")) {
                return {
                    required: true,
                    message: 'Please enter a valid Head of Household name'
                };
            }

        }


        if (householdSize < table1HouseholdMemberDC.length) {
            return {
                required: false,
                message: "The number of people listed below is greater than the number of people listed in the Household Size field on the General tab."
            };
        }

        if (filteredHOH.length === 1) {
            let table1HouseholdMemberDCWith = table1HouseholdMemberDC.filter(item => ((item.firstName === 'HOH' || item.firstName === 'hoh') && (item.firstName === 'hhm' || item.firstName === 'HHM')) && item.familyRelationshipType_id === 1).length > 0;

            // if(filteredHOH[0].firstName === '1' || filteredHOH[0].firstName === '2' || filteredHOH[0].firstName === '3' || filteredHOH[0].firstName === '4' || filteredHOH[0].firstName === '5' || filteredHOH[0].firstName === '6' || filteredHOH[0].firstName === '7' || filteredHOH[0].firstName === '8' || filteredHOH[0].firstName === '9' || filteredHOH[0].firstName === '10' || filteredHOH[0].firstName === '11'){
            if (table1HouseholdMemberDCWith || (filteredHOH[0].firstName && filteredHOH[0].firstName.toString().trim().toLowerCase() === 'hoh' && filteredHOH[0].lastName && filteredHOH[0].lastName.trim().toLowerCase() === 'hhm')) {
                return {
                    required: true,
                    message: 'Please enter a valid Head of Household name'
                };
            }
        }

        // if (filteredHOH.length === 1 || filteredHOH.length > 1) {

        //     if ((!filteredHOH[0].firstName && filteredHOH[0].firstName == "") && (!filteredHOH[0].lastName && filteredHOH[0].lastName == "")) {
        //         return {
        //             required: true,
        //             message: 'Please enter a valid Head of Household name'
        //         };
        //     }

        // }




        // Filter the array to include only objects with a numeric value for the firstName property
        //const numericFirstNameMembers = table1HouseholdMemberDC.filter(member => !isNaN(member.firstName));
        // Check if any duplicates exist in the numericFirstNameMembers array
        // const hasDuplicateNumericFirstNames = numericFirstNameMembers.some((member, index) => numericFirstNameMembers.findIndex(m => m.firstName === member.firstName) !== index);

        // if(hasDuplicateNumericFirstNames){
        //     return {
        //         required: true,
        //         message: 'Please enter the unique First name'
        //     };
        // }

        return { required: false, message: null };
    }
    /**
     * Bind HTML to reactDOM
     * 
     * [1] - To change the HH race selection from multi-autocomplete to autocomplete which is effective from particular date of table 1 submission and current year datas.
     * @returns 
     */
    render() {
        let { classes, race, pageAccess, validationErrors, getOne } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        const { table1HouseholdMemberDC, householdStudentExceptionType_id, canChangeMoveInDate, householdSize, householdSize_MoveIn, specialNeedOption_id, unitDesignation_id, isWSHFC, isNewOccupant,isWSHFCFT } = this.state;
        console.log("🚀 ~ file: HouseHoldInfo.js:1118 ~ render ~ householdStudentExceptionType_id:", householdStudentExceptionType_id)
        console.log("🚀 ~ file: HouseHoldInfo.js:1115 ~ render ~ this.state:", this.state)
        console.log('currentYear=============================',getOne.data&&getOne.data.moveInYear)
        let displayFT = true;
        //update details based on funders
        let memDetailsHeaders = ['First Name', 'Last Name', "Middle Initial", 'Relationship to HOH', 'Date of Birth', 'FT Student?',
            'SSN-Last 4', 'Race', 'Ethnicity', 'Disabled'];
        let memDetailsFields = [
            'firstName', 'lastName', 'middleInitial', 'familyRelationshipType_id', 'dateOfBirth', 'fullTimeStudentType_id',
            'ssnLast4', 'householdMemberRaceType', 'householdMemberEthnicityType_id', 'disabilityType_id'
        ]


        let currentYear = history.location.pathname.includes('new')?getOne&&getOne.data&&getOne.data.moveInYear:getOne&&getOne.data&&getOne.data.currentYear;
        console.log("🚀 ~ file: HouseHoldInfo.js:1124 ~ render ~ currentYear:", isWSHFC,isNewOccupant,currentYear)

        if ((!isWSHFCFT || isNewOccupant === true) && currentYear>=2023) {
            memDetailsHeaders = memDetailsHeaders.filter(item => item !== 'FT Student?')
            memDetailsFields = memDetailsFields.filter(item => item !== 'fullTimeStudentType_id')
            displayFT = false;
        }

        const table1HHM = table1HouseholdMemberDC;
        const fielderrors = Table1MRDValidations({
            ...this.state,
            validationErrors
        });
        // [1]
        let isCurrentReportYear = true;
        if (getOne.data && getOne.data.currentReportYearData && getOne.data.currentReportYearData.table1Activity && getOne.data.currentReportYearData.table1Activity.length > 0) {
            let currentActivity = getOne.data.currentReportYearData.table1Activity.filter(elem => elem.activeFlag === 1)[0];
            if (new Date(currentActivity.date).getTime() <= new Date('2021-09-01').getTime()) {
                isCurrentReportYear = false
            }
        }
        //handle error for largehousehold
        let valErrors = validationErrors;
        if (unitDesignation_id && unitDesignation_id === 1) {
            if (!canChangeMoveInDate) {
                if (specialNeedOption_id === 2 && householdSize_MoveIn < 4 && householdSize < 4) {
                    let index = valErrors.findIndex(x => x.message === "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                    if (index === -1) {
                        valErrors.push({
                            "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                            "errorType": 2, "tabIndex": 0
                        })
                    }
                }
                if (specialNeedOption_id === 2 && householdSize >= 4) {
                    if (valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")) > -1) {
                        valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")), 1)
                    }
                }
            } else {
                if (specialNeedOption_id === 2 && householdSize_MoveIn < 4 && householdSize < 4) {
                    let index = valErrors.findIndex(x => x.message === "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                    if (index === -1) {
                        valErrors.push({
                            "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                            "errorType": 2, "tabIndex": 0
                        })
                    }
                }
                if (specialNeedOption_id === 2 && (householdSize_MoveIn >= 4 || householdSize >= 4)) {
                    if (valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")) > -1) {
                        valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")), 1)
                    }
                }
            }
        }
        return (
            <div className={classes.rootEdit}>
                <TableErrors
                    fielderrors={fielderrors}
                    validations={validationErrors}
                    data={getOne.data || {}}
                    classes={classes}
                />
                <ValidatorForm
                    name="occupantsDetailsform"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >
                    <Grid item xs={12} sm={12}>
                        <ErrorDisplay
                            name="householdSize-error-display"
                            {...this.getHouseHoldSizeError()}
                        />
                    </Grid>
                    <div>
                        {<span style={{ color: "red" }}>{this.state.errors.hoherror}</span>}
                        <div className={'household-info-table'} >
                            <EditableTable
                                headerCols={memDetailsHeaders}
                                fieldCols={memDetailsFields}
                                actions={!isReadOly ? ['DELETE'] : []}
                                rowDatas={table1HouseholdMemberDC}
                                componentMap={componentMap}
                                cellComponentOnChange={this.cellComponentOnChange}
                                config={{ relationshipHOH, discloseType, ethinicity, race, isReadOly, isCurrentReportYear }}
                                needTitle={false}
                                deleteTooltip={"Delete Occupant"}
                                onDelete={async (item) => {
                                    const updatedHHI = this.state.table1HouseholdMemberDC.filter(tt => tt._id !== item._id)
                                    const deletedHHI = table1HHM.filter(tt => tt._id == item._id)
                                    await this.setState({
                                        table1HouseholdMemberDC: updatedHHI,
                                        table1DeletedMM: [...this.state.table1DeletedMM, deletedHHI[0]._id],
                                        deleteFlag: true
                                    });
                                    if (updatedHHI.length < 1) {
                                        await this.setState({ isMemEmpty: true })
                                    }
                                    this.onHandleDelete(item);
                                }}
                            />
                        </div>
                        <div className={'household-info-table-pageactions'}>
                            <Grid item xs={12} sm={12}>
                                <PageActions
                                    isSubPage={true}
                                    actions={!isReadOly ? ['ADDLINK'] : []}
                                    handleAddActionClick={() => {
                                        const rowData = table1HouseholdMemberDC;
                                        rowData.push({
                                            ...DEFAULT_MEMBERINFO,
                                            firstName: `${rowData.length + 1}`,
                                            lastName: "HHM",
                                            _id: Math.max(...table1HouseholdMemberDC.map(elt => elt._id), 0) + 1,
                                            familyRelationshipType_id: 7,
                                            householdMemberRaceType_id: 9,
                                            householdMemberRaceType: race && race.length > 0 && race.filter(raceType => raceType.key === 9)[0].key + ' - ' + race.filter(raceType => raceType.key === 9)[0].name,
                                            householdMemberEthnicityType_id: 9,
                                            disabilityType_id: 3
                                        });
                                        this.setState({
                                            data: {
                                                table1HouseholdMemberDC: rowData
                                            }
                                        });
                                    }}
                                />
                            </Grid>
                        </div>
                    </div>
                    {
                        displayFT && <>
                            <Grid item xs={12} sm={6}>
                                <AutoComplete
                                    name="householdStudentExceptionType_id"
                                    placeholder='Household Student Exception'
                                    suggestions={householdStudentException}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            householdStudentExceptionType_id: selectedOption.value,
                                        })
                                    }}
                                    selectedValue={{ id: householdStudentExceptionType_id }}
                                    margin="dense"
                                    {...occupantRequiredFields.householdStudentExceptionType(this.state)}
                                    disabled={isReadOly}
                                />
                            </Grid>
                        </>
                    }
                    {
                        pageAccess.indexOf('SAVE') > -1 ? (
                            <SaveCancel
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { this.handlePrevButton() }}
                                saveText={'Next'}
                                cancelVisible={true}
                                swap={true}
                                cancelText={'Prev'}
                            />
                        ) : null
                    }
                </ValidatorForm>
                {/* <AlertDialog
                    open={this.state.visibleDialog}
                    title={'Message:'}
                    closeIconVisible={true}
                    onClose={() => {
                        this.setState({visibleDialog:false})
                    }}
                    onSave={
                        () => {
                            this.setState({visibleDialog:false})
                        }}
                    saveText={"Ok"}
                    cancelVisible={false}
                    saveVisible={true}
                >
                {"The number of people listed in the Household Member Info section is greater than the Household Size"}
                </AlertDialog> */}
            </div >
        );
    }
}
/**
 * Bind Component Property Types
 */
HouseHoldInfo.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}
/** Default Props */
HouseHoldInfo.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 * 
 * [1]  -   update for label and value
 */
const mapStateToProps = ({ occupants, projects, configuration: { configurations }, pageAccess }) => {
    // [1]
    let race = global._.sortBy(configurations[configurationConstants.householdMemberInfoRace], 'seq').map(s => {
        return {
            ...s,
            label: s.desc || s.name,
            value: s.key
        }
    });
    return {
        getOne: occupants.getOne,
        monitoringFunders: projects.getOne.funders.data,
        race,
        pageAccess: pageAccess['occhouseholdinfo'] || [],
        validationErrors: occupants.validationErrors
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOccupantsDetails: (id, reportYear_id) => dispatch(occupantsActions.getOccupantsDetails(id, reportYear_id)),
        getConfiguration: (name) => dispatch(confActions.getConfiguration(name)),
        updateOccupantsGeneral: (id, formData, callBack) => dispatch(occupantsActions.updateOccupantsGeneral(id, formData, callBack)),
        updateOccupantsHHI: (id, formData, callBack) => dispatch(occupantsActions.updateOccupantsHHIDetails(id, formData, callBack)),
        updateOccupantsHHINCOMEDetails: (id, formData, callBack) => dispatch(occupantsActions.updateOccupantsHHINCOMEDetails(id, formData, callBack)),
        saveOccupantToLocal: (data) => dispatch(occupantsActions.saveOccupantToLocal(data)),
        getOneValidationError: (id, reportYear_id) => dispatch(occupantsActions.getOneValidationError(id, reportYear_id)),
        getAlertOnSuccess: (message) => dispatch(alertActions.success(message)),
        getAlertOnFailure: (message) => dispatch(alertActions.error(message)),
        getFunderDetails: (project_id) => dispatch(occupantsActions.getFunders(project_id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(HouseHoldInfo));
