
import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Components
import ConfigurationsContent from './ConfigurationsContent';
import ConfigurationsMenu from './ConfigurationsMenu';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

//Actions
import { helpActions, pageAccessActions } from '../../actions';

// Helpers
import { getHelpPageMenuHeight, getHelpPageContentHeight } from '../../helpers/global'

/**
 * Configurations container
 * @class Configurations
 * @extends Configurations
 */
class Configurations extends Component {
    /**
     * Cp=onstructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            openHelp: false,
            isMenuOpen: true,
        }
    }

    /*
    component will mount
    */
    UNSAFE_componentWillMount() {
        this.props.getHelp()
        this.props.getPageAccess('configurations', {})
    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, match, isSubPage, title, pageAccess, helpData, location } = this.props;
        const { openHelp, isMenuOpen } = this.state;
        pageAccess = [...pageAccess, 'HELP']
        let helpContentWidth = isMenuOpen ? 9 : 12
        return (

            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleHelpActionClick={this.handleHelpActionClick}
            >
                <div className={ isMenuOpen ? "helpContainer" : "helpContainer p-l-12"}>
                    <Grid container style={{ boxShadow: '0px 0px 2px grey' }}>
                        {isMenuOpen && <Grid item xs={12} sm={3} className={"helpMenu"}>
                            <div style={{ height: getHelpPageMenuHeight() }}>
                                <ConfigurationsMenu
                                    match={match}
                                    pageAccess={pageAccess} />
                            </div>
                        </Grid>}
                        <Grid item xs={12} sm={helpContentWidth} className={isMenuOpen ? "helpContent" : ''} style={{ position: 'relative', border: '1px solid #eee'}}>
                            <div style={{ height: getHelpPageContentHeight(), backgroundColor: "#fff", padding: "16px", overflow: 'auto' }}>
                                <div className={classes.leftSliderIcon}>
                                    {
                                        !isMenuOpen ? <div className={classes.rightSliderIconContainer} onClick={() => this.setState({ isMenuOpen: true })}><i class="fa fa-chevron-right flexIcon" aria-hidden="true"></i></div>
                                            : <div className={classes.leftSliderIconContainer} onClick={() => this.setState({ isMenuOpen: false })}><i class="fa fa-chevron-left flexIcon" aria-hidden="true" ></i></div>
                                    }
                                </div>
                                <ConfigurationsContent
                                    {...this.props}
                                    match={match}
                                    pageAccess={pageAccess} />
                            </div>
                        </Grid>
                    </Grid>
                </div>
                { openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Configurations"
                />}
            </ContentWrapper>
        );
    }
}
/** Props types */
Configurations.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};
/** Default props */
Configurations.defaultProps = {
    title: "Configurations",
    isSubPage: false,
    pageAccess: []
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const pageAccess = state.pageAccess['configurations'] || [];
    const helpData = state.help.data[0]
    return {
        pageAccess,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getHelp: () => dispatch(helpActions.getAll({ _id: 14 })),
        getPageAccess: (name, pageData) => dispatch(pageAccessActions.getAccess(name, pageData))
    }
};
/** Export Configurations */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Configurations));
