import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
        '&:last-child': {
            marginRight: 'unset'
        }
    },
    root: {
        textAlign: 'right',
        marginTop: 12,
        marginBottom: 12,
    }
});

/**
 *Save Cancel Button
 *
 * @class SaveCancel
 * @extends {Component}
 */
class SaveCancel extends Component {
    /**Handle Add Event on clicking */
    handleSave = () => {
        this.props.handleSaveButtonClick();
    };
    /**Handle Save Event on cliking */
    handleCancel = () => {
        this.props.handleCancelButtonClick();
    };

    //save button
    saveButton() {
        const { classes, disableSave, saveText, saveVisible, saveKey } = this.props;
        return (
            saveVisible ? (
                <Button
                    key={saveKey}
                    variant="contained"
                    color="primary"
                    size="large"
                    component="span"
                    className={classes.button}
                    onClick={this.handleSave}
                    disabled={disableSave}
                >
                    {saveText}
                </Button>
            ) : null
        )

    }

    //cancel button
    cancelButton() {
        const { classes, cancelText, cancelVisible, cancelKey } = this.props;
        return (
            cancelVisible ? (
                <Button
                    key={cancelKey}
                    variant="contained"
                    color="default"
                    size="large"
                    component="span"
                    className={classes.button}
                    onClick={this.handleCancel}>
                    {cancelText}
                </Button>
            ) : null
        )
    }

    /**
     *render html
     *
     * @returns
     * @memberof SaveCancel
     */
    render() {
        const { classes, swap, justifyContent } = this.props;
        const buttons = swap ? [this.cancelButton(), this.saveButton()] : [this.saveButton(), this.cancelButton()];
        return (
            <Grid
                container
                direction="row"
                justifyContent={justifyContent}
                alignItems="center"
            >
                <div className={classes.root}>
                    {buttons}
                </div>
            </Grid>
        );
    }
}

//default props
SaveCancel.defaultProps = {
    handleSaveButtonClick: () => { },
    handleCancelButtonClick: () => { },
    disableSave: false,
    saveText: 'Save',
    cancelText: 'Cancel',
    saveVisible: true,
    cancelVisible: true,
    swap: false,
    saveKey: 0,
    cancelKey: 1,
    justifyContent:"flex-end"
}

//prop types
SaveCancel.propTypes = {
    disableSave: PropTypes.bool,
    saveText: PropTypes.string,
    cancelText: PropTypes.string,
    saveVisible: PropTypes.bool,
    cancelVisible: PropTypes.bool,
    handleSaveButtonClick: PropTypes.func.isRequired,
    handleCancelButtonClick: PropTypes.func.isRequired,
};

//export component
export default withStyles(styles)(SaveCancel);