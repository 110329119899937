import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

// Import Custom Component
import SnackbarContentWrapper from '../SnackBarContentWrapper/SnackBarContentWrapper'

/**
 *Snack bar component
 *
 * @class SnackBarComponent
 * @extends {React.Component}
 */
class SnackBarComponent extends React.Component {
    state = {
        open: true,
        vertical: 'top',
        horizontal: 'right',
    };

    /**
     * Handle Component Will Receive Props
     */
    UNSAFE_componentWillReceiveProps() {
        this.setState({ open: true });
    }

    /**
     * Handle Close Event
    */
    handleClose = () => {
        this.setState({ open: false });
        this.props.onClose();
    };

    getMessageText = (message) => {
        if (message && message.props && message.props.children) {
            return {
                message: <span>{message}</span>
            }
        } else {
            return {
                message: <span dangerouslySetInnerHTML={{ __html: message }}></span>
            }
        }
    }

    /**
     * Bind
     * HTML to DOM
     */
    render() {
        const { vertical, horizontal, open } = this.state;
        const { type, message, style } = this.props;
        //timeout modified for inactivity
        let timeOut = message.includes('inactivity') ? 2000 : 5000;

        return (
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={timeOut}
                onClose={this.handleClose}
                style={style}
            >
                <SnackbarContentWrapper
                    onClose={this.handleClose}
                    variant={type}
                    {...this.getMessageText(message)}
                // message={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
                />
            </Snackbar>

        );
    }
}

//default props
SnackBarComponent.defaultProps = {
    open: true,
    type: 'success',
    message: '',
    onClose: () => { },
    style: {},
    redirect: false
}

//prop types
SnackBarComponent.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string
};

//export component
export default SnackBarComponent;