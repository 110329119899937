import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";

// import child router components
import ContractsList from "./ContractsList";
import ContractsView from "./ContractsView";
import ContractsNew from './ContractsNew'

class ContractsRouter extends Component {
    /**
     * Bind Html to DOM
     */
    render() {
        const { match, loanProjectId } = this.props;
        
        return (
            <div className="contractEdit">
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <ContractsList match={props.match} loanProjectId={loanProjectId} {...props} />
                }} />
                 <Route path={`${match.url}/new`} render={(props) => {
                    return <ContractsNew contractsId={null} {...props} loanProjectId={loanProjectId}/>
                }} />
                <Route exact path={`${match.url}/:contractsId`} render={(props) => {
                    return <ContractsView {...props} loanProjectId={loanProjectId}/>
                }} />
            </Switch>
            </div>
        );
    }
}

/**
 * Export Component
 */
export default ContractsRouter;
