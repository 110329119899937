import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { history, formatDate } from '../../helpers'

// Import Components
import TextBox from '../../components/TextBox/TextBoxToolTip';
import AutoComplete from '../../components/AutoComplete/AutoCompleteToolTip';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import DatePicker from '../../components/DatePicker/DatePickerTooltip';
import NumberField from '../../components/NumberField/NumberFieldToolTip';
import CheckboxValidator from "../../components/CheckBox/CheckBox";

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";
import { configurationConstants, contractActivityContants } from "../../helpers/appConstants";
import FieldValidationConstants from "../../helpers/fieldValidationConstants"
// import ContractActivityValidations from '../../helpers/TableValidations/contractActivity';


// import actions
import { reportActivityActions } from '../../actions/reportActivity';
import { validationActions } from '../../actions/validation';
import { confActions } from "../../actions/configuration";
import { alertActions } from '../../actions/alert';
import { focusHelper } from '../../helpers/global';

class ContractActivityNew extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loanNumber: '',
            isFundsFromMutipleHTFContracts: "",
            isLoan: '',
            homeType: '',
            numberOfBedrooms: '',
            address: '',
            city: '',
            county: '',
            ethincity: "",
            race: '',
            sweatEquity: "",
            landTrust: "",
            populationCategory: '',
            typeHTFActivity: '',
            dateOfPurchase: '',
            householdSize: '',
            maxAllowedIncome: '',
            annualGrossIncome: '',
            purchasePrice: '',
            interestRateHTFLoan: 0,
            assistanceHTF: '',
            otherPublicAssistance: "",
            otherFundersAndAmt: '',
            homeOwnerCashInvestment: 0,
            totalMarketValue: '',
            totalRestrictiveValue: 0,
            homeOwnerPurchasePrice: '',
            firstMortageAmount: '',
            firstMortageMonthlyPayment: '',
            secondMortageAmount: 0,
            secondMortageMonthlyPayment: 0,
            totalMortageMonthlyPayment: '',
            totalPITIU: '',
            affordabilityRatio: 0,
            homeOwnerPriorResidenceStatus: '',
            homeOwnerPriorHousingCost: '',
            isPropertyForclosure: "",
            isHTFLoanStatisfied: "",
            ami: "",
            update: true,
            updateColor: "",
            isFormNotSaved: false,
            incomeLimitData: {}
        };

        this.onwerName = "";

    }


    /**
     * Component Did mount Event
     */
    componentDidMount() {
        const { match } = this.props;
        let urlData = match.path.split('/');
        const reportYear_id = urlData.length === 8 ? urlData[4] : null;
        this.props.getContractActivity(1, 22, 'year', 'asc', [], [], reportYear_id, () => { });
        this.props.getConfiguration(configurationConstants.counties);
        this.props.getConfiguration(configurationConstants.cities);
        this.props.getConfiguration(configurationConstants.homeType);
        this.props.getConfiguration(configurationConstants.bedrooms_ho);
        this.props.getConfiguration(configurationConstants.race);
        this.props.getConfiguration(configurationConstants.populationCategoryHO);
        this.props.getConfiguration(configurationConstants.typeHTFActivityHO);
        this.props.getConfiguration(configurationConstants.HOPriorResidenceStatus);
        this.props.getConfiguration(configurationConstants.setAsideType_ho);
        this.setState({ update: false })
    }

    /**
 * Handle Change
 * @param {*} name
 * @param {*} value
 */
    handleChange = (name, value, ele) => {
        let { isFundsFromMutipleHTFContracts, ethincity, sweatEquity, landTrust, otherPublicAssistance, isPropertyForclosure, isHTFLoanStatisfied, isLoan } = this.state;
        if (value !== '')
            switch (name) {
                case "isFundsFromMutipleHTFContracts":
                    this.setState({
                        isFundsFromMutipleHTFContracts: isFundsFromMutipleHTFContracts === "" ? value : !isFundsFromMutipleHTFContracts,
                        focusField: ele,
                        focusFieldName: name,
                        focusVisible: true,
                        isFormNotSaved: true
                    });
                    break;
                case "isLoan":
                    this.setState({
                        isLoan: isLoan === "" ? value : !isLoan,
                        isFormNotSaved: true
                    });
                    break;
                case "ethincity":
                    this.setState({
                        ethincity: ethincity === "" ? value : !ethincity,
                        isFormNotSaved: true
                    });
                    break;
                case "sweatEquity":
                    this.setState({
                        sweatEquity: sweatEquity === "" ? value : !sweatEquity,
                        isFormNotSaved: true
                    });
                    break;
                case "landTrust":
                    this.setState({
                        landTrust: landTrust === "" ? value : !landTrust,
                        isFormNotSaved: true
                    });
                    break;
                case "otherPublicAssistance":
                    this.setState({
                        otherPublicAssistance: otherPublicAssistance === "" ? value : !otherPublicAssistance,
                        isFormNotSaved: true
                    });

                    break;
                case "isPropertyForclosure":
                    this.setState({
                        isPropertyForclosure: isPropertyForclosure === "" ? value : !isPropertyForclosure,
                        isFormNotSaved: true
                    });
                    break;
                case "isHTFLoanStatisfied":
                    this.setState({
                        isHTFLoanStatisfied: isHTFLoanStatisfied === "" ? value : !isHTFLoanStatisfied,
                        isFormNotSaved: true
                    });
                    break;
                default:
                    break;
            };
        if (name !== "isHTFLoanStatisfied" && name !== "isPropertyForclosure" && name !== "otherPublicAssistance" && name !== "landTrust" && name !== "sweatEquity" && name !== "ethincity" && name !== "isFundsFromMutipleHTFContracts" && name !== "isLoan") {
            this.setState({
                [name]: value,
                isFormNotSaved: true
            });
        }
    }

    handleFocus = (name) => {
        this.setState({ updateColor: true, isFormNotSaved: true })
    }
    handleOutFocus = (name) => {
        this.setState({ updateColor: false, isFormNotSaved: true })
    }

    /**** 
     *  Method when org got changed
     */
    handleAutoComplete_homeType = (selectedOption) => {
        this.setState({ homeType: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete Bedroom
    */
    handleAutoComplete_bedrooms = (selectedOption) => {
        this.setState({ numberOfBedrooms: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete city
    */
    handleAutoComplete_city = (selectedOption) => {
        this.setState({ city: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete race
    */
    handleAutoComplete_race = (selectedOption) => {
        this.setState({ race: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete population category
    */
    handleAutoComplete_populationCategory = (selectedOption) => {
        this.setState({ populationCategory: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete HTF
    */

    handleAutoComplete_typeHTFActivity = (selectedOption) => {
        this.setState({ typeHTFActivity: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete prior residence status
    */
    handleAutoComplete_HOPriorResidenceStatus = (selectedOption) => {
        this.setState({ homeOwnerPriorResidenceStatus: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle AutoComplete county
    */
    handleAutoComplete_county = (selectedOption) => {
        const { location: { pathname } } = this.props;
        let tabUrl = pathname.split('/');
        const { ami, householdSize, dateOfPurchase } = this.state;
        if (ami !== "" && householdSize !== "" && dateOfPurchase !== "") {
            let generalData = {
                county: selectedOption.value,
                householdSize: householdSize,
                setAside: ami,
                year: tabUrl.length > 0 ? tabUrl[5] : 0,
                dateOfPurchase: dateOfPurchase
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount,
                    incomeLimitData: incomeData.data
                });
            });
        }
        this.setState({
            county: selectedOption.value,
            isFormNotSaved: true
        });
    }

    /**
    * Handle HouseHold Change
    */
    handleChangeHouseholdSize = (name, value) => {
        const { location: { pathname } } = this.props;
        let tabUrl = pathname.split('/');
        const { county, ami, dateOfPurchase } = this.state;
        if (county !== "" && ami !== "" && value !== "" && dateOfPurchase !== "") {
            let generalData = {
                county: county,
                householdSize: value,
                setAside: ami,
                year: tabUrl.length > 0 ? tabUrl[5] : 0,
                dateOfPurchase: dateOfPurchase
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount,
                    incomeLimitData: incomeData.data
                });
            });
        }

        this.setState({
            [name]: value,
            isFormNotSaved: true
        });
    }

    /**
    * Handle AMI
    */

    handleAutoComplete_ami = (selectedOption) => {
        const { location: { pathname } } = this.props;
        let tabUrl = pathname.split('/');
        const { county, householdSize, dateOfPurchase } = this.state;
        if (county !== "" && householdSize !== "" && dateOfPurchase !== "") {
            let generalData = {
                county: county,
                householdSize: householdSize,
                setAside: selectedOption.value,
                year: tabUrl.length > 0 ? tabUrl[5] : 0,
                dateOfPurchase: dateOfPurchase
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount,
                    incomeLimitData: incomeData.data
                });
            });
        }
        this.setState({
            ami: selectedOption.value,
            isFormNotSaved: true
        });
    }

    /**
    * Handle Date of purchase
    */

    handleAutoComplete_dop = (name, value) => {
        const { location: { pathname } } = this.props;
        let tabUrl = pathname.split('/');
        const { county, householdSize, ami } = this.state;
        if (county !== "" && householdSize !== "" && ami !== "" && value !== null) {
            let generalData = {
                county: county,
                householdSize: householdSize,
                dateOfPurchase: value.format('MM/DD/YYYY'),
                year: tabUrl.length > 0 ? tabUrl[5] : 0,
                setAside: ami
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount,
                    incomeLimitData: incomeData.data
                });
            });
        }
        this.setState({
            [name]: value,
            isFormNotSaved: true
        });
    }

    handleAutoComplete_ethnicity = (selectedOption) => {
        this.setState({ ethincity: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_HFT = (selectedOption) => {
        this.setState({ isHTFLoanStatisfied: selectedOption.value, isFormNotSaved: true });
    }

    /**
    * Handle Submit
    */
    handleSubmit = (e) => {
        this.setState({ isFormNotSaved: false })
        this._saveForm();
    }

    /**
    * Handle Max Income
    */
    getMaxIncomeTitle() {
        const { ami, dateOfPurchase, incomeLimitData } = this.state;
        const { setAsideType_ho } = this.props;
        let setAsideTypeloh = setAsideType_ho.length > 0 ? setAsideType_ho.filter(item => item.category_id === 1) : [];
        let year = incomeLimitData && incomeLimitData.year ? incomeLimitData.year : 'N/A'

        //set date to previous if month less than 4
        let getDate = new Date(dateOfPurchase);
        if (getDate.getMonth() <= 3 && getDate.getDate()) {
            getDate.setFullYear(getDate.getFullYear() - 1);
            getDate.setDate(1);
            getDate.setMonth(0);
        }

        let formattedPurchaseDate = ((getDate.getMonth() > 8) ? (getDate.getMonth() + 1) : ('0' + (getDate.getMonth() + 1))) + '/' + ((getDate.getDate() > 9) ? getDate.getDate() : ('0' + getDate.getDate())) + '/' + getDate.getFullYear()

        let aminew = setAsideTypeloh.length > 0 ? setAsideTypeloh.filter(item => item.key === ami).map(item => item.name) : ""
        return `Effective Date: ${formatDate(formattedPurchaseDate)}, year: ${year}, Effective RUP: ${aminew}, Limit Pool: Multi Family`;
    }

    /**
    * ToolTip title
    * @param {*} fieldName
    */
    getTooltipProps = (fieldName) => {
        return {
            focusVisible: this.state.focusVisible && this.state.focusFieldName === fieldName,
            onFocus: (ele) => {
                if (fieldName === "maxAllowedIncome" || fieldName === "annualGrossIncome" || fieldName === "purchasePrice" || fieldName === "interestRateHTFLoan" || fieldName === "assistanceHTF" || fieldName === "homeOwnerCashInvestment"
                    || fieldName === "totalMarketValue" || fieldName === "totalRestrictiveValue" || fieldName === "homeOwnerPurchasePrice" || fieldName === "firstMortageAmount" || fieldName === "firstMortageMonthlyPayment" || fieldName === "secondMortageAmount" || fieldName === "secondMortageMonthlyPayment" || fieldName === "totalMortageMonthlyPayment" || fieldName === "totalPITIU" || fieldName === "affordabilityRatio" || fieldName === "homeOwnerPriorHousingCost") {
                    this.setState({ focusField: ele.target, focusFieldName: fieldName, focusVisible: true })

                } else {
                    this.setState({ focusField: ele, focusFieldName: fieldName, focusVisible: true })
                }
            },
            onBlur: () => this.setState({ focusField: null, focusFieldName: "", focusVisible: false })                                        // validators={['required', 'isStringLength250', 'validAddress']}
        }
    }

    getFloat(value) {
        return value === '' || value === null || value === 'undefined' ? 0 : parseFloat(value, 10);
    }
    /**
     * Handle Tab change
     */
    handleTabChange() {
        const { isFormNotSaved } = this.state;
        return isFormNotSaved
    }
    /**
    * Get window height for tooltip
    * @param {*} element
    */
    chechInView = (ele) => {
        var { focusField } = this.state
        if (focusField) {
            const { top: parentTop, bottom: parentBottom } = ele.getBoundingClientRect()
            const { top: childTop } = focusField.getBoundingClientRect()
            this.setState({ focusVisible: childTop + 25 > parentTop && childTop < parentBottom })
        }
    }

    /**
    * Handle Save Form
    */
    _saveForm() {
        const { contractActivity, match, AllContractActivity } = this.props;
        const { loanNumber, isFundsFromMutipleHTFContracts, sweatEquity, landTrust, otherPublicAssistance, isPropertyForclosure, isHTFLoanStatisfied, totalPITIU, homeOwnerPriorHousingCost } = this.state;
        const isLoanExist = AllContractActivity && AllContractActivity.filter(item => (item.loanNumber === loanNumber));

        if (isLoanExist.length > 0) {
            this.props.showAlert("Record Number Already Exist");
        } else {
            if (totalPITIU === "") {
                this.setState({ totalPITIU: null })
            }
            if (homeOwnerPriorHousingCost === "") {
                this.setState({ homeOwnerPriorHousingCost: null })
            }
            let urlData = match.path.split('/');
            const reportYear_id = urlData.length === 8 ? urlData[4] : null;

            // let generalDataLoanSave = {}
            // let validationData = {}

            //For grid save if only one loan is there for contract
            // if (this.props.reportData.data && this.props.reportData.data.length === 0) {
            // let fielderrors = [];
            // fielderrors = ContractActivityValidations(this.props);

            // ContractActivityValidations(this.props);

            // generalDataLoanSave = {
            //     reportYear_id: reportYear_id,
            //     fielderrors: fielderrors ? fielderrors.length : 0
            // }

            // if (fielderrors.length > 0) {
            // validationData = {
            //     reportYear_id: reportYear_id,
            //     reportActivity: 3,
            //     errors: fielderrors
            // }
            // }
            // }
            this.setState({
                affordabilityRatio: (Math.round((this.state.totalPITIU / (this.state.annualGrossIncome / 12)) * 100))
            })
            let generalData = {
                ...this.state,
                reportYear_id: reportYear_id,
                contractActivity: contractActivity ? contractActivity._id : null
            }
            //check if date is valid
            if (this.state.dateOfPurchase._isValid === false) {
                return
            }
            urlData.pop()
            let urlPath = urlData.join('/')

            if (isFundsFromMutipleHTFContracts === "" || sweatEquity === "" || landTrust === "" ||
                otherPublicAssistance === "" || isPropertyForclosure === "" || isHTFLoanStatisfied === "") {
                this.props.showErrorAlert("Select any option in the check box");
            } else {
                if (contractActivity) {
                    this.props.clearAlert()
                    this.props.updateContractActivity(generalData, () => {

                        if (this.props.reportData.data && this.props.reportData.data.length === 0) {
                            // this.props.saveContractActivity(generalDataLoanSave);
                            // this.props.validationErrors(validationData);
                            // this.props.validationIssue(reportYear_id);
                        }

                        history.push({
                            pathname: `${urlPath}`,
                            reportYear_id: reportYear_id,
                            state: {
                                pageData: {
                                    reportYear_id: reportYear_id
                                }
                            }
                        });
                    });
                } else {
                    this.props.clearAlert()
                    this.props.createContractActivity(generalData, () => {

                        if (this.props.reportData.data && this.props.reportData.data.length === 0) {
                            // this.props.validationErrors(validationData);
                            // this.props.validationIssue(reportYear_id);
                            // this.props.saveContractActivity(generalDataLoanSave);
                        }

                        history.push({
                            pathname: `${urlPath}`,
                            reportYear_id: reportYear_id,
                            state: {
                                pageData: {
                                    reportYear_id: reportYear_id
                                }
                            }
                        });
                    });
                }
                // setTimeout(() => {
                //     this.props.history.goBack()
                // }, 800);

            }
        }
    }

    /**
    * Bind HTML to DOM
    */
    render() {
        const { classes, counties, cities, homeTypes, bedrooms_ho, races, populationCategories, typeHTFActivities, HOPriorResidenceStatus, setAsideType_ho, location } = this.props;
        let setAsideType_hoLIH = setAsideType_ho.length > 0 ? setAsideType_ho.filter(item => item.category_id === 1) : [];
        let {
            loanNumber,
            isFundsFromMutipleHTFContracts,
            isLoan,
            homeType,
            numberOfBedrooms,
            address,
            city,
            county,
            ethincity,
            race,
            sweatEquity,
            landTrust,
            populationCategory,
            typeHTFActivity,
            dateOfPurchase,
            householdSize,
            maxAllowedIncome,
            annualGrossIncome,
            purchasePrice,
            interestRateHTFLoan,
            assistanceHTF,
            otherPublicAssistance,
            otherFundersAndAmt,
            homeOwnerCashInvestment,
            totalMarketValue,
            totalRestrictiveValue,
            homeOwnerPurchasePrice,
            firstMortageAmount,
            firstMortageMonthlyPayment,
            secondMortageAmount,
            secondMortageMonthlyPayment,
            totalPITIU,
            homeOwnerPriorResidenceStatus,
            homeOwnerPriorHousingCost,
            isPropertyForclosure,
            isHTFLoanStatisfied,
            ami
        } = this.state;
        const ethincities = contractActivityContants.ethincities;
        const htfLoans = contractActivityContants.htfLoansSatisfied;

        //update validation for HomeownerPSR and HomeownerPHC for future 2022
        let homeownerPRSValidation = [];
        let homeownerPRSErrmsg = [];
        let homeownerPHCValidation = [];
        let homeownerPHCErrmsg = [];
        if (new Date(dateOfPurchase).getFullYear() >= 2022) {
            homeownerPRSValidation = ['required'];
            homeownerPRSErrmsg = [FieldValidationConstants.FINANCIAL_ACTIVITY.required];
            homeownerPHCValidation = ['required', 'dollarisValueTwoDecimal', 'greatthan0'];
            homeownerPHCErrmsg = [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0];
        } else {
            homeownerPHCValidation = ['dollarisValueTwoDecimal'];
            homeownerPHCErrmsg = [FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal];
        }
        // let selectedEthnicity = ethincities.filter(obj => obj.value === ethincity)
        // if(selectedEthnicity[0])
        //     ethincity = selectedEthnicity[0]
        // else
        //     ethincity = { key: "Not Disclosed", value: 3, label: "Not Disclosed" }
        return (
            <Grid className={`${classes.activityContainer} loanContent`}>
                <ContentWrapper
                    classes={classes}
                    title={''}
                    needTitleBar={false}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    titleBg={'white'}
                    pageName="reports"
                    onScrollChange={(ele) => { this.chechInView(ele) }}
                >
                    <div className={classes.rootEdit} >
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log(errors)} >

                            {/* Grid Content */}
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={12} align="left">
                                    <Grid item xs={3} sm={3}>
                                        <TextBox
                                            toolTipClassName="topTextBox"
                                            name="loanNumber"
                                            label="Record Number"
                                            value={loanNumber}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("loanNumber")}
                                            toolTipTitle={focusHelper().loanNumber}
                                            validators={['required', 'isStringLength30']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN30, FieldValidationConstants.LOAN_ACTIVITY.validAddress1]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className="required-header" variant="body1">Was this activity funded with funds from multiple HTF contracts ?</Typography>
                                    <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                        <CheckboxValidator
                                            name={"isFundsFromMutipleHTFContracts"}
                                            checked={isFundsFromMutipleHTFContracts === true}
                                            toolTipTitle={focusHelper().wasThisLoanFunded}
                                            value={isFundsFromMutipleHTFContracts}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                            validators={['required']}
                                            inboxRequired={true}
                                            label={"Yes"}
                                            color={"primary"}
                                        />
                                        <CheckboxValidator
                                            name={"isFundsFromMutipleHTFContracts"}
                                            checked={isFundsFromMutipleHTFContracts === false}
                                            value={isFundsFromMutipleHTFContracts}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                            toolTipTitle={focusHelper().wasThisLoanFunded}
                                            validators={['required']}
                                            inboxRequired={true}
                                            label={"No"}
                                            color={"primary"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className="required-header" variant="body1">Is this a Loan or Grant?</Typography>
                                    <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                        <CheckboxValidator
                                            name={"isLoan"}
                                            checked={isLoan === true}
                                            toolTipTitle={focusHelper().loanOrgrant}
                                            value={isLoan}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                            validators={['required']}
                                            label={"Loan"}
                                            color={"primary"}
                                        />
                                        <CheckboxValidator
                                            name={"isLoan"}
                                            checked={isLoan === false}
                                            value={isLoan}
                                            toolTipTitle={focusHelper().loanOrgrant}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                            validators={['required']}
                                            label={"Grant"}
                                            color={"primary"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Type of Home"
                                            name="homeType"
                                            suggestions={homeTypes}
                                            handleAutoCompolete={this.handleAutoComplete_homeType}
                                            selectedValue={{ value: homeType, id: homeType }}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("homeType")}
                                            toolTipTitle={focusHelper().typeOfHome}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="topTextBoxLeftAlign"
                                            placeholder="Numbers of Bedrooms"
                                            name="numberOfBedrooms"
                                            suggestions={bedrooms_ho}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("numberOfBedrooms")}
                                            toolTipTitle={focusHelper().noOfBedroom}
                                            handleAutoCompolete={this.handleAutoComplete_bedrooms}
                                            selectedValue={{ value: numberOfBedrooms, id: numberOfBedrooms }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="address"
                                            label="Street Address"
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("address")}
                                            toolTipTitle={focusHelper().homeAddress}
                                            value={address}
                                            validators={['required', 'isStringLength500']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN500]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="City"
                                            name="city"
                                            suggestions={cities}
                                            handleAutoCompolete={this.handleAutoComplete_city}
                                            selectedValue={{ value: city, id: city }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="County"
                                            name="county"
                                            suggestions={counties}
                                            toolTipTitle={focusHelper().county}
                                            tooltipOnHover={true}
                                            toolTipClassName="topTextBoxLeftAlign"
                                            {...this.getTooltipProps("county")}
                                            handleAutoCompolete={this.handleAutoComplete_county}
                                            selectedValue={{ value: county, id: county }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Race"
                                            name="race"
                                            suggestions={races}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("race")}
                                            toolTipTitle={focusHelper().race}
                                            handleAutoCompolete={this.handleAutoComplete_race}
                                            selectedValue={{ value: race, id: race }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} align="left" className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"

                                            placeholder="Ethnicity"
                                            name="ethincity"
                                            suggestions={ethincities}
                                            {...this.getTooltipProps("ethincity")}
                                            toolTipTitle={focusHelper().Ethnicity}
                                            handleAutoCompolete={this.handleAutoComplete_ethnicity}
                                            selectedValue={{ value: ethincity, id: ethincity }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} align="left" className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Population Category"
                                            name="populationCategory"
                                            suggestions={populationCategories}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("populationCategory")}
                                            toolTipTitle={focusHelper().populationCate}
                                            handleAutoCompolete={this.handleAutoComplete_populationCategory}
                                            selectedValue={{ value: populationCategory, id: populationCategory }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Type of HTF activity"
                                            name="typeHTFActivity"
                                            suggestions={typeHTFActivities}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("typeHTFActivity")}
                                            toolTipTitle={focusHelper().HTFFunding}
                                            handleAutoCompolete={this.handleAutoComplete_typeHTFActivity}
                                            selectedValue={{ value: typeHTFActivity, id: typeHTFActivity }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className="required-header" variant="body1">Sweat Equity ?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"sweatEquity"}
                                                checked={sweatEquity === true}
                                                toolTipTitle={focusHelper().sweatEquity}
                                                value={sweatEquity}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                validators={['required']}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"sweatEquity"}
                                                checked={sweatEquity === false}
                                                value={sweatEquity}
                                                toolTipTitle={focusHelper().sweatEquity}
                                                handleChange={(name, checked, ele) => { this.handleChange(name, checked ? false : true, ele) }}
                                                validators={['required']}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className="required-header" variant="body1">Land trust ?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"landTrust"}
                                                checked={landTrust === true}
                                                toolTipTitle={focusHelper().landTrust}
                                                value={landTrust}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                validators={['required']}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"landTrust"}
                                                checked={landTrust === false}
                                                value={landTrust}
                                                toolTipTitle={focusHelper().landTrust}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                validators={['required']}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/* Project contractor */}
                                <Grid item xs={12}>
                                    <h4 className="section_title contractActivitySecTitle">{'Date of purchase household size max allowed income and Annual gross income'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <DatePicker
                                            name="dateOfPurchase"
                                            value={dateOfPurchase}
                                            label="Date of Purchase"
                                            className={classes.textField}
                                            tooltipOnHover={true}
                                            handleChange={this.handleAutoComplete_dop}
                                            toolTipTitle={focusHelper().dateOfPurchase}
                                            {...this.getTooltipProps("dateOfPurchase")}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.invalidDate]}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="AMI"
                                            name="ami"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            toolTipTitle={focusHelper().AMI}
                                            {...this.getTooltipProps("AMI")}
                                            tooltipOnHover={true}
                                            suggestions={setAsideType_hoLIH}
                                            handleAutoCompolete={this.handleAutoComplete_ami}
                                            selectedValue={{ value: ami, id: ami }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            toolTipClassName="topTextBoxLeftAlign"
                                            type="number"
                                            name="householdSize"
                                            label="Household Size"
                                            value={householdSize}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("householdSize")}
                                            toolTipTitle={focusHelper().houseHoldSize}
                                            validators={['required', 'isValue0To30NotDecimal']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To30NotDecimal]}
                                            className={classes.textField}
                                            handleChange={this.handleChangeHouseholdSize}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container direction="row" justifyContent="start" spacing={5}>

                                        <Grid item xs={12} sm={6} align="right" title={this.getMaxIncomeTitle()}>
                                            {/* <Grid item xs={12} sm={10} > */}
                                            <NumberField
                                                // type="number"
                                                name="maxAllowedIncome"
                                                label="Max allowed income of household at time of purchase"
                                                tooltipOnHover={false}
                                                {...this.getTooltipProps("maxAllowedIncome")}
                                                toolTipTitle={focusHelper().maxAlloIncome}
                                                value={maxAllowedIncome || maxAllowedIncome === 0 ? maxAllowedIncome : 'N/A'}
                                                validators={['required']}
                                                errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={true}
                                                style={{ width: "100%" }}
                                                prefix={maxAllowedIncome || maxAllowedIncome === 0 ? '$' : ''}
                                            />
                                            {/* </Grid> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} align="right">
                                            {/* <Grid item xs={12} sm={10}> */}
                                            <NumberField
                                                // type="number"
                                                toolTipClassName="topTextBoxLeftAlign"
                                                name="annualGrossIncome"
                                                label={"Actual Annual Gross income at time of purchase"}
                                                value={annualGrossIncome}
                                                tooltipOnHover={true}
                                                {...this.getTooltipProps("annualGrossIncome")}
                                                toolTipTitle={focusHelper().annualgross}
                                                validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                                errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                style={{ width: "100%" }}
                                            />
                                            {/* </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="purchasePrice"
                                            label="Purchase Price"
                                            value={purchasePrice}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("purchasePrice")}
                                            toolTipTitle={focusHelper().purchasePrice}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="interestRateHTFLoan"
                                            label="Interest Rate of HTF Activity %"
                                            value={interestRateHTFLoan}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("interestRateHTFLoan")}
                                            toolTipTitle={focusHelper().HTFLoanInterest}
                                            validators={['required', 'dollarisValueTwoDecimal']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            zeroFlag={true}
                                            suffix={"%"}
                                            prefix={""}
                                            thousandSeparator={''}
                                            toolTipClassName="topTextBoxLeftAlign"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="assistanceHTF"
                                            label="HTF Assistance"
                                            value={assistanceHTF}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("assistanceHTF")}
                                            toolTipTitle={focusHelper().HTFAssistance}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={12} align="left">
                                        <Typography className="required-header" variant="body1">Other Public Assistance</Typography>
                                        <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                            <CheckboxValidator
                                                name={"otherPublicAssistance"}
                                                checked={otherPublicAssistance === true}
                                                toolTipTitle={focusHelper().otherPublicAssist}
                                                value={otherPublicAssistance}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                validators={['required']}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"otherPublicAssistance"}
                                                toolTipTitle={focusHelper().otherPublicAssist}
                                                checked={otherPublicAssistance === false}
                                                value={otherPublicAssistance}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                validators={['required']}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8} align="right">
                                    <Grid item xs={12} sm={12} className={classes.mw96}>
                                        <TextBox
                                            otherPublicAssistance={otherPublicAssistance}
                                            // ref="sampl"
                                            name="otherFundersAndAmt"
                                            label="Please identify other funder(s) and amount(s)"
                                            value={otherFundersAndAmt}
                                            gotFocus={this.handleFocus}
                                            outFocus={this.handleOutFocus}
                                            // validators={false ? ['required_publicassistance'] : []}
                                            errorMessages={otherPublicAssistance ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required_publicassistance] : []}
                                            className={this.state.otherPublicAssistance === false && this.state.updateColor === "" ? `${classes.textField} loanactNEW` : this.state.otherPublicAssistance === false && this.state.updateColor === true ? `${classes.textField} loanactNEWFocus` : this.state.otherPublicAssistance === true ? classes.textField : "norPUB"}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            validators={otherPublicAssistance ? ['required'] : []}
                                            maxLength={1000}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="homeOwnerCashInvestment"
                                            label="Homeowner Cash Investment"
                                            value={homeOwnerCashInvestment}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("homeOwnerCashInvestment")}
                                            toolTipTitle={focusHelper().homeBuyerCashAssist}
                                            validators={['required', 'dollarisValueTwoDecimal']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required_nocash, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalMarketValue"
                                            label="Total Market Value"
                                            value={totalMarketValue}
                                            toolTipClassName="topTextBoxLeftAlign"
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("totalMarketValue")}
                                            toolTipTitle={focusHelper().totalMarketValue}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />



                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalRestrictiveValue"
                                            label="Total Restrictive Value"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            value={totalRestrictiveValue}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("totalRestrictiveValue")}
                                            toolTipTitle={focusHelper().totalRestrictiveValue}
                                            validators={['required', 'dollarisValueTwoDecimal']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="homeOwnerPurchasePrice"
                                            label="Homeowner Purchase Price"
                                            value={homeOwnerPurchasePrice}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("homeOwnerPurchasePrice")}
                                            toolTipTitle={focusHelper().ownerPurchasePrice}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="firstMortageAmount"
                                            label="1st Mortage Amount"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            value={firstMortageAmount}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("firstMortageAmount")}
                                            toolTipTitle={focusHelper().firstMortageAmount}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="firstMortageMonthlyPayment"
                                            label="1st Mortage Monthly Payment"
                                            value={firstMortageMonthlyPayment}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("firstMortageMonthlyPayment")}
                                            toolTipTitle={focusHelper().monthlyFirstMortage}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="secondMortageAmount"
                                            label="2nd Mortage Amount"
                                            value={secondMortageAmount}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("secondMortageAmount")}
                                            toolTipTitle={focusHelper().secondMortage}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            validators={['dollarisValueTwoDecimal']}
                                            errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="secondMortageMonthlyPayment"
                                            label="2nd Mortage Monthly Payment"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            value={secondMortageMonthlyPayment}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("secondMortageMonthlyPayment")}
                                            toolTipTitle={focusHelper().monthlySecondMortage}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            validators={['dollarisValueTwoDecimal']}
                                            errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalMortageMonthlyPayment"
                                            label="Total Monthly Mortage  Payment"
                                            value={parseFloat(firstMortageMonthlyPayment || 0) + parseFloat(secondMortageMonthlyPayment || 0) || 0}
                                            tooltipOnHover={false}
                                            {...this.getTooltipProps("totalMortageMonthlyPayment")}
                                            toolTipTitle={focusHelper().totalMonthlyMortage}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            validators={['required', 'dollarisValueTwoDecimal']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            disabled={true}
                                            prefix="$"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalPITIU"
                                            label="Total Principle,Interest,Taxes&Insurance+Utilities"
                                            value={totalPITIU}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("totalPITIU")}
                                            toolTipTitle={focusHelper().totalPrincipleInterestTax}
                                            validators={['required', 'dollarisValueTwoDecimal', 'greatthan0']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={this.state.focusFieldName === "annualGrossIncome" ? (`${classes.textField} focusAlign`) : classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="affordabilityRatio"
                                            label="Affordability Ratio %"
                                            value={annualGrossIncome && totalPITIU ? `${(Math.round((totalPITIU / (annualGrossIncome / 12)) * 100))}%` : "0%"}
                                            tooltipOnHover={false}
                                            {...this.getTooltipProps("affordabilityRatio")}
                                            toolTipTitle={focusHelper().affordabilityRatio}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        // fixedDecimalScale={false}
                                        // thousandSeparator=""
                                        // decimalScale={0}
                                        // prefix=""
                                        // suffix="%"
                                        // zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="topTextBoxLeftAlign"
                                            placeholder="Homeowner Prior Residence Status"
                                            name="homeOwnerPriorResidenceStatus"
                                            suggestions={HOPriorResidenceStatus}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("homeOwnerPriorResidenceStatus")}
                                            toolTipTitle={focusHelper().homeOwnerResidenceStatus}
                                            handleAutoCompolete={this.handleAutoComplete_HOPriorResidenceStatus}
                                            selectedValue={{ value: homeOwnerPriorResidenceStatus, id: homeOwnerPriorResidenceStatus }}
                                            validators={homeownerPRSValidation}
                                            errorMessages={homeownerPRSErrmsg}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="homeOwnerPriorHousingCost"
                                            label="Homeowner Prior Housing Cost"
                                            value={homeOwnerPriorHousingCost}
                                            tooltipOnHover={true}
                                            {...this.getTooltipProps("homeOwnerPriorHousingCost")}
                                            toolTipTitle={focusHelper().homeOwnerResidencePrior}
                                            validators={homeownerPHCValidation}
                                            errorMessages={homeownerPHCErrmsg}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                        />
                                    </Grid>
                                </Grid>

                                {/* </Grid> */}
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className="required-header" variant="body1">Was Property Foreclosed?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"isPropertyForclosure"}
                                                checked={isPropertyForclosure === true}
                                                value={isPropertyForclosure}
                                                toolTipTitle={focusHelper().isPropertyForclosure}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                validators={['required']}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"isPropertyForclosure"}
                                                checked={isPropertyForclosure === false}
                                                toolTipTitle={focusHelper().isPropertyForclosure}
                                                value={isPropertyForclosure}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                validators={['required']}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className="required-header" variant="body1">HTF Loan Satisfied?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"isHTFLoanStatisfied"}
                                                checked={isHTFLoanStatisfied === true}
                                                value={isHTFLoanStatisfied}
                                                toolTipTitle={focusHelper().isHTFLoanStatisfied}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                validators={['required']}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"isHTFLoanStatisfied"}
                                                checked={isHTFLoanStatisfied === false}
                                                value={isHTFLoanStatisfied}
                                                toolTipTitle={focusHelper().isHTFLoanStatisfied}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                validators={['required']}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} sm={4} align="right" className={"HTFContractActivity"}>
                                    <Grid item xs={12} sm={10} align="left" className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Is the HTF Loan Satisfied?"
                                            name="isHTFLoanStatisfied"
                                            suggestions={htfLoans}
                                            {...this.getTooltipProps("isHTFLoanStatisfied")}
                                            toolTipTitle={focusHelper().isHTFLoanStatisfied}
                                            handleAutoCompolete={this.handleAutoComplete_HFT}
                                            selectedValue={{ value: isHTFLoanStatisfied, id: isHTFLoanStatisfied }}
                                            validators={['required']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }} />

                        </ValidatorForm>
                    </div>
                </ContentWrapper >
            </Grid>
        );
    }
}
/**
 * Bind Component Property Types
 */
ContractActivityNew.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

ContractActivityNew.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        cities: state.configuration.configurations[configurationConstants.cities] || [],
        counties: state.configuration.configurations[configurationConstants.counties] || [],
        homeTypes: state.configuration.configurations[configurationConstants.homeType] || [],
        bedrooms_ho: state.configuration.configurations[configurationConstants.bedrooms_ho] || [],
        races: state.configuration.configurations[configurationConstants.race] || [],
        populationCategories: state.configuration.configurations[configurationConstants.populationCategoryHO] || [],
        typeHTFActivities: state.configuration.configurations[configurationConstants.typeHTFActivityHO] || [],
        HOPriorResidenceStatus: state.configuration.configurations[configurationConstants.HOPriorResidenceStatus] || [],
        setAsideType_ho: state.configuration.configurations[configurationConstants.setAsideType_ho] || [],
        incomeData: state.reportActivity.incomeData ? state.reportActivity.incomeData.data : 0,
        pageAccess: state.pageAccess['contractActivityNew'] || [],
        searchData: state.search["contractActivity"] || { sFields: [], sValues: [] },
        reportData: state.reportActivity.contractActivity,
        programActivity: state.reportActivity.programActivity.data,
        financialActivity: state.reportActivity.financialActivity.data,
        AllContractActivity: state.reportActivity.contractActivity.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getByIdContractActivity: (reportYear_id) => dispatch(reportActivityActions.getByIdContractActivity(reportYear_id)),
        createContractActivity: (formData, callback) => dispatch(reportActivityActions.createContractActivity(formData, callback)),
        updateContractActivity: (formData, callback) => dispatch(reportActivityActions.updateContractActivity(formData, callback)),
        getConfiguration: (type) => dispatch(confActions.getConfigurationContractActivity(type)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearAlert: () => dispatch(alertActions.clear()),
        getIncomeLimit: (generalData, successCallBack) => dispatch(reportActivityActions.getIncomeLimit(generalData, successCallBack)),
        getContractActivity: (skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack) => dispatch(reportActivityActions.getContractActivity(skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack)),
        getByIdProgramActivity: (reportYear_id) => dispatch(reportActivityActions.getByIdProgramActivity(reportYear_id)),
        getByIdFinancialActivity: (reportYear_id) => dispatch(reportActivityActions.getByIdFinancialActivity(reportYear_id)),
        saveContractActivity: (generalData, successCallBack) => dispatch(reportActivityActions.saveContractActivity(generalData, successCallBack)),
        validationErrors: (validationErrors) => dispatch(validationActions.activityValidations(validationErrors)),
        validationIssue: (reportYear_id) => dispatch(reportActivityActions.validationIssue(reportYear_id)),
        showAlert: (message) => dispatch(alertActions.error(message)),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ContractActivityNew));
