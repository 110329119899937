import { saveAs } from 'file-saver';

//import constants
import { reportActivityConstants } from '../constants/reportActivity';

//import actions
import { alertActions } from './';

//import services
import { reportActivityServices } from '../services';

export const reportActivityActions = {
    getReportActivityAccess,
    getByIdCertificationForm,
    createCertificationForm,
    updateCertificationForm,
    getByIdFinancialActivity,
    createFinancialActivity,
    updateFinancialActivity,
    getByIdProgramActivity,
    createProgramActivity,
    updateProgramActivity,
    getContractActivity,
    getByIdContractActivity,
    createContractActivity,
    updateContractActivity,
    submitLoan,
    unsubmitLoan,
    downloadReport,
    saveContractActivity,
    getIncomeLimit,
    getReportYear,
    getByReportIdContractActivity,
    submitLoanEdit,
    validationIssue,
    clearContractActivity,
    delete: _delete,
}

/**
 * get one certification form details by id
 */
function getReportActivityAccess(reportYear_id, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.getReportActivityAccess(reportYear_id)
            .then(
                data => {
                    dispatch(success(data))
                    successCallBack()
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.GET_ACTIVITY_ACCESS_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GET_ACTIVITY_ACCESS_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_ACTIVITY_ACCESS_FAILURE, error } }
}


/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - explore id in the listing page
 * @returns
 */
 function _delete(id,successCallback) {
    return dispatch => {
        dispatch(request(id));

        reportActivityServices.deleteContractActivity(id)
            .then(
                data => {
                    successCallback(data);
                    dispatch(success(id));
                    dispatch(alertActions.success('Record number deleted successfully'));
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: reportActivityConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: reportActivityConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: reportActivityConstants.DELETE_FAILURE, id, error } }
}




/**
 * get one certification form details by id
 */
function getByIdCertificationForm(reportYear_id) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.getByIdCertificationForm(reportYear_id)
            .then(
                data => {
                    dispatch(success(data))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.GET_CERTIFICATION_FORM_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GET_CERTIFICATION_FORM_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_CERTIFICATION_FORM_FAILURE, error } }
}

/**
 * Create certification form
 */
function createCertificationForm(generalData) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.createCertificationForm(generalData)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success("Certification form created successfully"));
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.CREATE_CERTIFICATION_FORM_REQUEST } }
    function success(data) { return { type: reportActivityConstants.CREATE_CERTIFICATION_FORM_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.CREATE_CERTIFICATION_FORM_FAILURE, error } }
}

/**
 * Update certification form
 */
function updateCertificationForm(generalData) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.updateCertificationForm(generalData)
            .then(
                data => {
                    dispatch(success(data));
                    if ('isAddressDelete' in generalData) {
                        dispatch(alertActions.success("Address deleted successfully"));
                    } else {
                        dispatch(alertActions.success("Certification form updated successfully"));
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.UPDATE_CERTIFICATION_FORM_REQUEST } }
    function success(data) { return { type: reportActivityConstants.UPDATE_CERTIFICATION_FORM_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.UPDATE_CERTIFICATION_FORM_FAILURE, error } }
}

/**
 * get one financial activity details by id
 */
function getByIdFinancialActivity(reportYear_id, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.getByIdFinancialActivity(reportYear_id)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.GET_FINANCIAL_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GET_FINANCIAL_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_FINANCIAL_ACTIVITY_FAILURE, error } }
}

/**
 * Create certification form
 */
function createFinancialActivity(formData, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.createFinancialActivity(formData)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data));
                    dispatch(alertActions.success("Financial activity created successfully"))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.CREATE_FINANCIAL_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.CREATE_FINANCIAL_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.CREATE_FINANCIAL_ACTIVITY_FAILURE, error } }
}

/**
 * Update certification form
 */
function updateFinancialActivity(formData, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.updateFinancialActivity(formData)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data));
                    dispatch(alertActions.success("Financial activity updated successfully"))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.UPDATE_FINANCIAL_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.UPDATE_FINANCIAL_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.UPDATE_FINANCIAL_ACTIVITY_FAILURE, error } }
}

/**
 * get one program activity details by id
 */
function getByIdProgramActivity(reportYear_id, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.getByIdProgramActivity(reportYear_id)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.GET_PROGRAM_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GET_PROGRAM_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_PROGRAM_ACTIVITY_FAILURE, error } }
}

/**
 * Create program activity
 */
function createProgramActivity(formData, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.createProgramActivity(formData)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data));
                    dispatch(alertActions.success("Program activity created successfully"));
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.CREATE_PROGRAM_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.CREATE_PROGRAM_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.CREATE_PROGRAM_ACTIVITY_FAILURE, error } }
}

/**
 * Update program activity
 */
function updateProgramActivity(formData, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.updateProgramActivity(formData)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data));
                    dispatch(alertActions.success("Program activity updated successfully"));
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.UPDATE_PROGRAM_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.UPDATE_PROGRAM_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.UPDATE_PROGRAM_ACTIVITY_FAILURE, error } }
}

/**
 * get all contract activity details 
 */
function getContractActivity(skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.getContractActivity(skip, limit, orderBy, order, sField, sValue, reportYear_id)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function request() { return { type: reportActivityConstants.GETALL_LOAN_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GETALL_LOAN_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GETALL_LOAN_ACTIVITY_FAILURE, error } }
}

/**
 * get one program activity details by id
 */
function getByIdContractActivity(loan_id) {
    return dispatch => {
        dispatch(clear());
        dispatch(request());
        reportActivityServices.getByIdContractActivity(loan_id)
            .then(
                data => {
                    dispatch(success(data))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function clear() { return { type: reportActivityConstants.CLEAR_LOAN_ACTIVITY } }
    function request() { return { type: reportActivityConstants.GET_LOAN_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GET_LOAN_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_LOAN_ACTIVITY_FAILURE, error } }
}

/**
 * Create program activity
 */
function createContractActivity(formData, callback) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.createContractActivity(formData)
            .then(
                data => {
                    dispatch(success(data));
                    callback();
                    dispatch(alertActions.success("Contract activity created successfully"));
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.CREATE_LOAN_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.CREATE_LOAN_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.CREATE_LOAN_ACTIVITY_FAILURE, error } }
}

/**
 * Update program activity
 */
function updateContractActivity(formData, callback) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.updateContractActivity(formData)
            .then(
                data => {
                    dispatch(success(data));
                    callback();
                    dispatch(alertActions.success("Contract activity updated successfully"));
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.UPDATE_LOAN_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.UPDATE_LOAN_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.UPDATE_LOAN_ACTIVITY_FAILURE, error } }
}

function submitLoan(reportYear_id, successCallBack) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.submissionCheck(reportYear_id)
            .then(
                data => {

                    dispatch(success(data));
                    if (!data.data) {
                        if (data.message === "Cannot submit to the funder level until first day of next calendar year.") {
                            dispatch(alertActions.error('Cannot submit to the funder level until first day of next calendar year.'))
                        }
                        if (data.message === "Program activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Program activity before submit!'))
                        }
                        else if (data.message === "Finance activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Financial activity before submit!'))
                        } else if (data.message === "Contract activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Contract activity before submit!'))
                        }
                        else if (data.message === "Certificate is not Saved") {
                            dispatch(alertActions.error('Please enter the mandatory fields in Certification form'))
                        }
                        else if (data.message === "Program and Finance activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Program and Finance Activity before submit!'))
                        }
                        else if (data.message === "Loan and Program activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Program and Contract Activity before submit!'))
                        }
                        else if (data.message === "Loan and Finance activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Financial and Contract Activity before submit!'))
                        }
                        else if (data.message === "Loan,Finance and Program activity validation") {
                            dispatch(alertActions.error('Need to fix Hard stop errors in Program,Financial and Contract Activity before submit!'))
                        }
                        // else if (data.message === "Validation is not Fixed") {
                        //     dispatch(alertActions.error('Need to fix Hard stop errors before submit!'));
                        // } 
                        else if (data.message === "Certificate is not Saved Funder") {
                            dispatch(alertActions.error('Certification must be signed by the Contractor on the Certification Form before Report Activity is submitted to the Funder level.'));
                        }
                        else if (data.message === "Program Activity and Financial Activity not Saved") {
                            dispatch(alertActions.error('Program activity and Financial activity must be saved at least once before the submission'));
                        }
                        else if (data.message === "Program Activity and Contract Activity not Saved") {
                            dispatch(alertActions.error('Program activity and Contract activity must be saved at least once before the submission'));
                        }
                        else if (data.message === "Financial Activity and Contract Activity not Saved") {
                            dispatch(alertActions.error('Financial activity and Contract activity must be saved at least once before the submission'));
                        }
                        else if (data.message === "Program,Financial and Contract Activity not Saved") {
                            dispatch(alertActions.error('Report activity must be saved at least once before the submission'));
                        }
                        else if (data.message === "Program Activity not Saved") {
                            dispatch(alertActions.error('Program activity must be saved atleast once before the submission'));
                        }
                        else if (data.message === "Finance Activity not Saved") {
                            dispatch(alertActions.error('Financial activity must be saved atleast once before the submission'));
                        }
                        else if (data.message === "Contract Activity not Saved") {
                            dispatch(alertActions.error('Contract activity must be saved atleast once before the submission'));
                        }
                        else if (data.message === "Contract Activity not Saved all validation") {
                            dispatch(alertActions.error('Need to fix hard stop errors in Program, Financial and Contract activity before submit!'));
                        }
                        else if (data.message === "Contract Activity not Saved loan and finance validation") {
                            dispatch(alertActions.error('Need to fix hard stop errors in Financial and Contract activity before submit!'));
                        }
                        else if (data.message === "Contract Activity not Saved loan and program validation") {
                            dispatch(alertActions.error('Need to fix hard stop errors in Program and Contract activity before submit!'));
                        }
                        else if (data.message === "Contract Activity not Saved loan validation") {
                            dispatch(alertActions.error('Report activity must be saved at least once before the submission'));
                        }

                    } else {
                        dispatch(alertActions.clear())
                        successCallBack(data)
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request() { return { type: reportActivityConstants.SUBMIT_LOAN_REQUEST } }
    function success(data) { return { type: reportActivityConstants.SUBMIT_LOAN_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.SUBMIT_LOAN_FAILURE, error } }
}

/**
 * Method to submit the table 1, 2, 3, 4 data
 *
 * @param {*} id report year table _id value
 * @param {*} formData form datas to update
 */
function unsubmitLoan(reportYear_id, comment, callback) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.unsubmitLoan(reportYear_id, comment)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('The report has been un-submitted.'));
                    callback(data)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: reportActivityConstants.UNSUBMIT_LOAN_REQUEST } }
    function success(data) { return { type: reportActivityConstants.UNSUBMIT_LOAN_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.UNSUBMIT_LOAN_FAILURE, error } }
}

/**
 * Method to submit the table 1, 2, 3, 4 data
 *
 * @param {*} id report year table _id value
 * @param {*} formData form datas to update
 */
function downloadReport(reportYear_id, reportYear) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.downloadReport(reportYear_id, reportYear)
            .then(res => res.blob())
            .then(
                data => {
                    saveAs(data, `${reportYear}_Homeownership Annual Report.xlsx`);
                    dispatch(success({}))
                    dispatch(alertActions.success('Successfully downloaded'));
                    dispatch(alertActions.clear());
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: reportActivityConstants.DOWNLOAD_REPORT_REQUEST } }
    function success(data) { return { type: reportActivityConstants.DOWNLOAD_REPORT_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.UNSUBMIT_LOAN_FAILURE, error } }

}
/*
 * Save Contract Activity
 */
function saveContractActivity(generalData, successCallBack) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.saveContractActivity(generalData)
            .then(
                data => {
                    successCallBack(data)
                    dispatch(success(data));
                    dispatch(alertActions.success('Contract activity has been saved successfully.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: reportActivityConstants.SAVE_LOAN_REQUEST } }
    function success(data) { return { type: reportActivityConstants.SAVE_LOAN_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.SAVE_LOAN_FAILURE, error } }
}

/**
 * 
 * clear contract activity 
 */

function clearContractActivity() {
    return dispatch => {
        dispatch(clear())
    }

    function clear() { return { type: reportActivityConstants.CLEAR_LOAN_ACTIVITY } }
}
/*
 * Get Income Limit
 */
function getIncomeLimit(generalData, successCallBack) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.getIncomeLimit(generalData)
            .then(
                data => {
                    successCallBack(data);
                    dispatch(success(data));
                    //dispatch(alertActions.success('Maximum Allowed Income has been changed Successfully.'));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request() { return { type: reportActivityConstants.GET_INCOMELIMIT_REQUEST, generalData } }
    function success(data) { return { type: reportActivityConstants.GET_INCOMELIMIT_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_INCOMELIMIT_FAILURE, error } }
}
/*
 * get Report Year
 */
function getReportYear(reportYear_id) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.getReportYear(reportYear_id)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request() { return { type: reportActivityConstants.GET_REPORTYEAR_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GET_REPORTYEAR_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GET_REPORTYEAR_FAILURE, error } }
}

function getByReportIdContractActivity(reportYear_id, successCallBack) {
    return dispatch => {
        dispatch(request());
        reportActivityServices.getByReportIdContractActivity(reportYear_id)
            .then(
                data => {
                    successCallBack(data)
                    dispatch(success(data))
                },
                error => {
                    dispatch(failure(error))
                }
            )
    }
    function request() { return { type: reportActivityConstants.GETALL_BYREPORT_LOAN_ACTIVITY_REQUEST } }
    function success(data) { return { type: reportActivityConstants.GETALL_BYREPORT_LOAN_ACTIVITY_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.GETALL_BYREPORT_LOAN_ACTIVITY_FAILURE, error } }
}

function submitLoanEdit(reportYear_id, comment, callback) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.submitLoan(reportYear_id, comment)
            .then(
                data => {

                    dispatch(success(data));
                    dispatch(alertActions.success('The report activity has been submitted.'));
                    callback(data)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request() { return { type: reportActivityConstants.SUBMIT_LOAN_EDIT_REQUEST } }
    function success(data) { return { type: reportActivityConstants.SUBMIT_LOAN_EDIT_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.SUBMIT_LOAN_EDIT_FAILURE, error } }
}

function validationIssue(reportYear_id) {
    return dispatch => {
        dispatch(request());

        reportActivityServices.validationIssue(reportYear_id)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request() { return { type: reportActivityConstants.VALIDATION_ISSUE_REQUEST } }
    function success(data) { return { type: reportActivityConstants.VALIDATION_ISSUE_SUCCESS, data } }
    function failure(error) { return { type: reportActivityConstants.VALIDATION_ISSUE_FAILURE, error } }
}