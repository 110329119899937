import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

// Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import LoanProjectFundersEdit from './LoanFundersEdit';

// Import Actions
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

class LoanProjectFundersView extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.funderRef = React.createRef();
    }

    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.funderRef.refs.form.submit();
    };

    /**
     * Handle Form Save Action Click
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {

        }
    };

    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
    * Handle Table Navigation Event - Projects View Page
    */
    handleNavigateAction = (id) => {
        history.push('/funders/' + id);
    }

    /**
    * Bind Html to DOM
    */
    render() {
        const { match: { params }, needPadding, classes, isSubPage, projectId, orgId, pageTitle } = this.props;
        let { pageAccess } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') <= -1;
        return (
            <ContentWrapper
                classes={classes}
                isSubPage={isSubPage}
                pageAccess={pageAccess}
                title={isSubPage ? '' : pageTitle.title}
                subTitle={isSubPage ? '' : pageTitle.subTitle}
                needTitleBar={false}
                needPadding={needPadding}
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
            >
                <LoanProjectFundersEdit
                    isSubPage={isSubPage}
                    pageAccess={pageAccess}
                    id={parseInt(params.funderId, 10)}
                    orgId={parseInt(orgId, 10)}
                    innerRef={(node) => { this.funderRef = node; }}
                    isReadOly={isReadOly}
                    projectId={projectId}
                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                />
            </ContentWrapper>
        );
    }
}

/**
 * Bind Component Property Types
 */
LoanProjectFundersView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};

LoanProjectFundersView.defaultProps = {
    needPadding: true,
    isSubPage: false,
    title: "Funder Settings",
    pageAccess: []
}

/**
 * Maps state from store to props
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['loanFunderEdit'] || []
    }
};

/**
 * Maps state from store to props
 */
const mapDispatchToProps = (dispatch) => {
    return {

    }
};


/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(LoanProjectFundersView));





