import { dashboardHOConstants } from '../constants';

const initialState = {
    PCLCounts: {
        data: {
            'Total Projects': 0,
            'Total Contracts': 0,
            'Total Records': 0
        },
        isLoading: false
    },
    projectSummary: {
        projects: [],
        projectsCount: 0,
        years: [],
        summaryDetails: {},
        isLoading: false
    },
    complianceIssue: {
        data: {
            donutChartData: [
                [
                    {
                        title: "Program Activity",
                        value: 0,
                    },
                    {
                        title: "Program Activity",
                        value: 100,
                    }
                ],
                [
                    {
                        title: "Financial Activity",
                        value: 0,
                    },
                    {
                        title: "Financial Activity",
                        value: 100,
                    }
                ],
                [
                    {
                        title: "Contract Activity",
                        value: 0,
                    },
                    {
                        title: "Contract Activity",
                        value: 100,
                    }
                ]
            ]
        },
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    reportStatus: {
        data: [
            {
                label: "Preparer",
                value: 0,
                actualValue: 0
            },
            {
                label: "Owner",
                value: 0,
                actualValue: 0
            },
            {
                label: "Funder",
                value: 0,
                actualValue: 0
            }
        ],
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    usersSummary: {
        data: {
            totalUsers: {
                title: 'Total Users',
                count: 0
            },
            totalOrganizations: {
                title: 'Total Organizations',
                count: 0
            },
            organizationAdmin: {
                title: 'Organization Admin',
                count: 0,
                status: [
                    {
                        title: 'Active',
                        value: 0
                    },
                    {
                        title: 'Inactive',
                        value: 0
                    }
                ]
            },
            onsiteManager: {
                title: 'Onsite Manager',
                count: 0,
                status: [
                    {
                        title: 'Active',
                        value: 0
                    },
                    {
                        title: 'Inactive',
                        value: 0
                    }
                ]
            },
            none: {
                title: 'Others',
                count: 0,
                status: [
                    {
                        title: 'Active',
                        value: 0
                    },
                    {
                        title: 'Inactive',
                        value: 0
                    }
                ]
            }
        },
        isLoading: false
    },
    loanRate: {
        data: {
            loanRate: [
                {
                    name: "Closed Loans",
                    value: "0",
                },
                {
                    name: "Opened Loans",
                    value: "100",
                }
            ],
            closedLoans: 0,
            totalLoans: 0
        },
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    projectList: {
        data: {},
        isLoading: false
    },
    complianceNeedsAction: {
        data: {},
        selectedSites: [],
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    dashboard: {
        isLoading: false,
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: ['project.name'],
                searchCols: []
            }
        }
    },
}

export function dashboardHO(state = { ...initialState }, action) {
    switch (action.type) {

        case dashboardHOConstants.GET_HO_PROJECTSLIST_REQUEST:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isLoading: true
                }
            };
        case dashboardHOConstants.GET_HO_PROJECTSLIST_SUCCESS:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...action.data,
                    hadData: state.dashboard.hadData !== undefined ? state.dashboard.hadData : action.data.data.length > 0,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GET_HO_PROJECTSLIST_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    error: action.error,
                    isLoading: false
                }
            };

        case dashboardHOConstants.GETALL_HO_PROJECTSUMMARY_REQUEST:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    summaryDetails: {},
                    isLoading: true,
                }
            };
        case dashboardHOConstants.GETALL_HO_PROJECTSUMMARY_SUCCESS:
            // let projects = state.projectSummary.projects;
            // action.data.projects.map( project => {
            //     if(!state.projectSummary.projects.some(elem => elem.value === project.value)){
            //         projects.push(project);
            //     }
            // });
            return {
                ...state,
                projectSummary: {
                    // projects: state.projectSummary.projects.some() [...action.data.projects],
                    projects: action.data.projects,
                    projectsCount: action.data.projectsCount,
                    years: action.data.years,
                    summaryDetails: action.data.summaryDetails || state.projectSummary.summaryDetails,
                    isLoading: false,
                }
            };
        case dashboardHOConstants.GETALL_HO_PROJECTSUMMARY_FAILURE:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    error: action.error,
                    isLoading: false
                }
            };

        //get report success
        case dashboardHOConstants.GETALL_HO_REPORT_STATUS_REQUEST:
            return {
                ...state,
                reportStatus: {
                    ...state.reportStatus,
                    isLoading: true,
                }
            };
        case dashboardHOConstants.GETALL_HO_REPORT_STATUS_SUCCESS:
            return {
                ...state,
                reportStatus: {
                    data: action.data,
                    year: action.year,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GETALL_HO_REPORT_STATUS_FAILURE:
            return {
                ...state,
                reportStatus: {
                    ...state.reportStatus,
                    error: action.error,
                    isLoading: false
                }
            };

        case dashboardHOConstants.PCL_COUNT_REQUEST:
            return {
                ...state,
                PCLCounts: {
                    ...state.PCLCounts,
                    isLoading: true,
                }
            };

        case dashboardHOConstants.PCL_COUNT_SUCCESS:
            return {
                ...state,
                PCLCounts: {
                    data: action.data,
                    isLoading: false
                }
            };
        case dashboardHOConstants.PCL_COUNT_FAILURE:
            return {
                ...state,
                PCLCounts: {
                    ...state.PCLCounts,
                    error: action.error,
                    isLoading: false,
                }
            };
        case dashboardHOConstants.USERSSUMMARY_HO_REQUEST:
            return {
                ...state,
                usersSummary: {
                    ...state.usersSummary,
                    isLoading: true,
                }
            };

        case dashboardHOConstants.USERSSUMMARY_HO_SUCCESS:
            return {
                ...state,
                usersSummary: {
                    data: action.data,
                    isLoading: false
                }
            };
        case dashboardHOConstants.USERSSUMMARY_HO_FAILURE:
            return {
                ...state,
                usersSummary: {
                    ...state.usersSummary,
                    error: action.error,
                    isLoading: false,
                }
            };
        case dashboardHOConstants.GETALL_HO_OCCUPANCY_RATE_REQUEST:
            return {
                ...state,
                loanRate: {
                    ...state.loanRate,
                    isLoading: true,
                }
            };
        case dashboardHOConstants.GETALL_HO_OCCUPANCY_RATE_SUCCESS:
            return {
                ...state,
                loanRate: {
                    data: action.data,
                    year: action.year,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GETALL_HO_OCCUPANCY_RATE_FAILURE:
            return {
                ...state,
                loanRate: {
                    ...state.loanRate,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GETALL_HO_COMPLIANCE_ISSUE_REQUEST:
            return {
                ...state,
                complianceIssue: {
                    ...state.complianceIssue,
                    isLoading: true,
                }
            };
        case dashboardHOConstants.GETALL_HO_COMPLIANCE_ISSUE_SUCCESS:
            return {
                ...state,
                complianceIssue: {
                    data: action.data,
                    year: action.year,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GETALL_HO_COMPLIANCE_ISSUE_FAILURE:
            return {
                ...state,
                complianceIssue: {
                    ...state.complianceIssue,
                    error: action.error,
                    isLoading: false
                }
            };

        case dashboardHOConstants.GET_PROJECT_LIST_HO_REQUEST:
            return {
                ...state,
                projectList: {
                    data: {},
                    isLoading: true,
                }
            };

        case dashboardHOConstants.GET_PROJECT_LIST_HO_SUCCESS:
            return {
                ...state,
                projectList: {
                    data: action.data,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GET_PROJECT_LIST_HO_FAILURE:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    error: action.error,
                    isLoading: false,
                }
            };

        case dashboardHOConstants.GETALL_HO_COMPLIANCE_NEEDSACTION_REQUEST:
            return {
                ...state,
                complianceNeedsAction: {
                    ...state.complianceNeedsAction,
                    isLoading: true,
                }
            };
        case dashboardHOConstants.GETALL_HO_COMPLIANCE_NEEDSACTION_SUCCESS:
            return {
                ...state,
                complianceNeedsAction: {
                    data: action.data,
                    year: action.year,
                    selectedSites: action.selectedSites,
                    isLoading: false
                }
            };
        case dashboardHOConstants.GETALL_HO_COMPLIANCE_NEEDSACTION_FAILURE:
            return {
                ...state,
                complianceNeedsAction: {
                    ...state.complianceNeedsAction,
                    error: action.error,
                    isLoading: false
                }
            };
        default: return state;
    }
}