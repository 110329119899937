import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Components
import SectionTable from './SectionTable';
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import DropDown from '../../../components/DropDown/DropDown';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';

// Helpers
import { getUserRole } from '../../../helpers';

/**
 * SetAside Components
 * @class SetAside
 * @extends {COmponent}
 */
class SetAside extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            claimedSite: 0,
            setAsideBy: 0,
            open: false,
            delBox: null
        }
        this.userRole = getUserRole();
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} colName 
     * @param {*} index 
     * @returns 
     */
    handleSetAsideChange = (colName, index) => (name, value) => {
        this.props.onChange(colName, index, value);
    };
    /**
     * handleSetAsideChangeMultiSelect
     * @param {*} colName 
     * @param {*} index 
     * @param {*} value 
     */
    handleSetAsideChangeMultiSelect = (colName, index, value) => {
        this.props.onChange(colName, index, value);
        console.log("-------------- HandleSetAsideChange MultiSelect LiH Percentage --------",colName,index,value)
    };
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * handleSetAsideBy
     * @param {*} name 
     * @param {*} value 
     */
    handleSetAsideBy = (name, value) => {
        this.setState({ open: true, setAsideBy: value });
    }
    /**
     * render Confirm Dialog
     * @param {*} openState 
     * @param {*} title 
     * @param {*} yesCallback 
     * @param {*} cancelCallback 
     */
    renderConfirmDialog(openState, title, yesCallback, cancelCallback) {
        this.setState({
            delBox: <DeleteDialog
                open={openState}
                content={title}
                handleDeleteAction={yesCallback}
                handleDeleteOnCloseAction={cancelCallback}
            />
        });
    }
    /**
     * Bind HTML to renderDOM
     * @returns 
     */
    render() {
        const { classes, isReadOly, sites, setAsideBy, claimedSite, isConfigByBedRoom, org_id, elderlyType,
            setAsideTypes, isConfigByBedRoomNHTF, isConfigByBedRoomPSH, isAfterPHA, elderlyConstants } = this.props;
        const homeProgramheader = !isConfigByBedRoom ? ['HOME Program', '#Units', 'First Year', 'Last Year', 'Actions'] :
            ['HOME Program', 'Bedroom Type', '#Units', 'First Year', 'Last Year', 'Actions'];
        const NHTFProgramHeader = !isConfigByBedRoomNHTF ? ['#Units', 'First Year', 'Last Year', 'Actions'] :
            ['Bedroom Type', '#Units', 'First Year', 'Last Year', 'Actions'];
        const PSHProgramHeader = !isConfigByBedRoomPSH ? ['#Units', 'First Year', 'Last Year', 'Actions'] :
            ['Bedroom Type', '#Units', 'First Year', 'Last Year', 'Actions'];
        const isElderly = setAsideTypes.filter(st => st.setAsideType_id === 57 && st.category_id === 2
            && (st.site_id === claimedSite || (setAsideBy === 0 && st.site_id === null))).length >= 1;
        const { userRole } = this;
        return (
            <div>
                <h4 className="section_title sectionTitleMargin">
                    Unit Set-Aside Requirements
                </h4>

                <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                        <DropDown
                            name={`setAsideBy`}
                            value={setAsideBy}
                            label="Set Aside by"
                            className={classes.textField}
                            handleChange={this.handleSetAsideBy}
                            margin="dense"
                            validators={['required']}
                            errorMessages={['this field is required']}
                            disabled={isReadOly}
                        >
                            <MenuItem value={0}>{"Set-Aside Requirement are for the entire Project"}</MenuItem>
                            <MenuItem value={1}>{"Set-Aside Requirement are set for each Site"}</MenuItem>
                        </DropDown>
                    </Grid>
                    {
                        claimedSite !== 0 && (
                            <Grid item xs={4} sm={4}>
                                <AutoComplete
                                    name={`claimedSite`}
                                    placeholder={"Select Claimed Site"}
                                    suggestions={sites}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.props.onSiteChange(selectedOption.value);
                                    }}
                                    selectedValue={{ id: claimedSite }}
                                    margin="dense"
                                    className="selectClaimedSite"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    disabled={userRole === "ReadOnlyOrg" || userRole === "Funder" || claimedSite === 0}
                                />
                            </Grid>
                        )
                    }
                    <DeleteDialog
                        open={this.state.open !== false}
                        content={
                            this.state.setAsideBy === 0 ? 'This will merge all Set-Aside Requirements for each site and will move to entire project' : 'This will move project level Set-Aside Requirements to first site'
                        }
                        handleDeleteAction={() => {
                            this.setState({ open: false });
                            this.props.onSetAsideBy(this.state.setAsideBy);
                        }}
                        handleDeleteOnCloseAction={() => {
                            this.setState({ open: false })
                        }}
                    />
                </Grid>
                <SectionTable
                    {...this.props}
                    title={'LIH Percentages'}
                    headers={['LIH Percentage', '#Units', 'First Year', 'Last Year', 'Actions']}
                    categoryId={1}
                    handleSetAsideChange={this.handleSetAsideChange}
                    handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                    onDelete={(index) => {
                        this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                            this.props.onDelete(index);
                            this.setState({ delBox: null });
                        }, () => {
                            this.setState({ delBox: null });
                        })
                    }}
                />
                {org_id === 1 &&
                    <SectionTable
                        className="funderSettingTax"
                        {...this.props}
                        title={'Tax Credits Special Needs'}
                        headers={['Tax Credits Special Need', '#Units', 'First Year', 'Last Year', 'Actions']}
                        categoryId={2}
                        handleSetAsideChange={this.handleSetAsideChange}
                        handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                        childrenAlign={'bottom'}
                        onDelete={(index) => {
                            this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                this.props.onDelete(index);
                                this.setState({ delBox: null });
                            }, () => {
                                this.setState({ delBox: null });
                            })
                        }}
                    >
                        {isElderly && <Grid item xs={12} sm={3}>
                            <AutoComplete
                                name={`elderlyType_id`}
                                placeholder={"Elderly Type"}
                                suggestions={elderlyConstants}
                                handleAutoCompolete={(selectedOption) => {
                                    this.props.elderlyTypeOnChange(selectedOption.value);
                                }}
                                selectedValue={{ id: elderlyType }}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isReadOly}
                            />
                        </Grid>}
                    </SectionTable>
                }
                {org_id !== 1 &&
                    <React.Fragment>
                        <SectionTable
                            {...this.props}
                            title={'HOME Program'}
                            headers={homeProgramheader}
                            categoryId={5}
                            handleSetAsideChange={this.handleSetAsideChange}
                            handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                            isConfigByBedRoom={isConfigByBedRoom}
                            childrenAlign={'top'}
                            onDelete={(index) => {
                                this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                    this.props.onDelete(index);
                                    this.setState({ delBox: null });
                                }, () => {
                                    this.setState({ delBox: null });
                                })
                            }}
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isConfigByBedRoom}
                                            onChange={(evt) => {
                                                this.props.isConfigByBedRoomChange(!isConfigByBedRoom);
                                            }}
                                            disabled={isReadOly}
                                            value={`${isConfigByBedRoom}`}
                                        />
                                    }
                                    label="Configure by Bedroom"
                                />
                            </FormGroup>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isAfterPHA}
                                            onChange={(evt) => {
                                                this.props.isAfterPHAChange(evt.target.checked);
                                            }}
                                            disabled={isReadOly}
                                            value={'true'}
                                        />
                                    }
                                    label="Committed after 8/23/2013"
                                />
                            </FormGroup>
                        </SectionTable>
                        {org_id === 2 &&
                            <SectionTable
                                {...this.props}
                                title={'NHTF Program'}
                                headers={NHTFProgramHeader}
                                categoryId={6}
                                handleSetAsideChange={this.handleSetAsideChange}
                                handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                                isConfigByBedRoom={isConfigByBedRoomNHTF}
                                childrenAlign={'top'}
                                onDelete={(index) => {
                                    this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                        this.props.onDelete(index);
                                        this.setState({ delBox: null });
                                    }, () => {
                                        this.setState({ delBox: null });
                                    })
                                }}
                            >
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isConfigByBedRoomNHTF}
                                                onChange={(evt) => {
                                                    this.props.isConfigByBedRoomNHTFChange(!isConfigByBedRoomNHTF);
                                                }}
                                                disabled={isReadOly}
                                                value={`${isConfigByBedRoomNHTF}`}
                                            />
                                        }
                                        label="Configure by Bedroom"
                                    />
                                </FormGroup>
                            </SectionTable>
                        }
                        {org_id === 2 &&
                            <SectionTable
                                {...this.props}
                                title={'Permanent Supportive Housing'}
                                headers={PSHProgramHeader}
                                categoryId={7}
                                handleSetAsideChange={this.handleSetAsideChange}
                                handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                                isConfigByBedRoom={isConfigByBedRoomPSH}
                                childrenAlign={'top'}
                                onDelete={(index) => {
                                    this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                        this.props.onDelete(index);
                                        this.setState({ delBox: null });
                                    }, () => {
                                        this.setState({ delBox: null });
                                    })
                                }}
                            >
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isConfigByBedRoomPSH}
                                                onChange={(evt) => {
                                                    this.props.isConfigByBedRoomPSHChange(!isConfigByBedRoomPSH);
                                                }}
                                                disabled={isReadOly}
                                                value={`${isConfigByBedRoomPSH}`}
                                            />
                                        }
                                        label="Configure by Bedroom"
                                    />
                                </FormGroup>
                            </SectionTable>
                        }
                        <SectionTable
                            {...this.props}
                            title={'Special Needs Populations entered on Table 3.1'}
                            headers={['Special Needs Populations', '#Units', 'First Year', 'Last Year', 'Actions']}
                            categoryId={3}
                            handleSetAsideChange={this.handleSetAsideChange}
                            handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                            onDelete={(index) => {
                                this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                    this.props.onDelete(index);
                                    this.setState({ delBox: null });
                                }, () => {
                                    this.setState({ delBox: null });
                                })
                            }}
                        />
                        <SectionTable
                            {...this.props}
                            title={'Special Needs Populations - Homeless entered on Table 3'}
                            headers={['Special Needs Populations - Homeless', '#Units', 'First Year', 'Last Year', 'Actions']}
                            categoryId={4}
                            handleSetAsideChange={this.handleSetAsideChange}
                            handleSetAsideChangeMultiSelect={this.handleSetAsideChangeMultiSelect}
                            onDelete={(index) => {
                                this.renderConfirmDialog(true, 'Are you sure you want to delete this record?', () => {
                                    this.props.onDelete(index);
                                    this.setState({ delBox: null });
                                }, () => {
                                    this.setState({ delBox: null });
                                })
                            }}
                        />
                    </React.Fragment>
                }
                {this.state.delBox}
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
SetAside.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

SetAside.defaultProps = {
    isReadOly: false
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ projects }, ownProps) => {
    return {

    }
};
/**
 *
* @param {*} dispatch
*/
const mapDispatchToProps = (dispatch) => {
    return {

    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(SetAside));

