import React, { Component } from 'react';

// Import Component
import ContractActivityRouter from '../../ContractActivity/ContractActivity.Router';

class ContractActivity extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, projectId } = this.props;
        return (
            <ContractActivityRouter
                {...this.props}
                isSubPage={true}
                match={match}
                projectId={projectId}
                page={'projects'} />
        )
    }
}

/**
 * Export Component
 */
export default ContractActivity;