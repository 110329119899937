import React from 'react';

//import components
import TextBox from '../TextBox/TextBox';
/**
 * Error to Display
 * @param {*} param0 
 * @returns 
 */
const ErrorDisplay = ({ name, required, message,color}) => {
  
    if (color === undefined) {
        color = 'red'
    }

    if (message === "The number of people listed below is greater than the number of people listed in the Household Size field on the General tab.") {
        color = "#FFC107"
    }

    return (
        <React.Fragment>
            <div style={{display: 'none'}}>
                <TextBox
                    className={`hidden-form-error-field`}
                    name={name}
                    value={null}
                    validators={required ? ['required'] : []}
                    errorMessages={required ? ['Some error in form fields.'] : []}
                    disabled={true}
                />
            </div>
            {
                message && (
                    <div style={{color: color}}>
                        {message}
                    </div>
                )
            }
        </React.Fragment>
    )
}
//default props
ErrorDisplay.defaultProps = {
    name: 'default-error-form-field',
    required: false,
    message: null
};
/**export component */
export default ErrorDisplay;