import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

// Styles
import dashboardStyle from '../../../assets/jss/containers/dashboard';

// Components
import { SvgLoadIcon } from '../../../components/SvgIcons/SvgIcons';

/**
 *UtilityAllowance
 *
 * @class UtilityAllowance
 * @extends {Component}
 */
class UtilityAllowance extends Component {
    /**
     *Creates an instance of UtilityAllowance.
     * @param {*} props
     * @memberof UtilityAllowance
     */
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof UtilityAllowance
     */
    render() {
        const { classes, utilituAllowance } = this.props
        return (
            <Grid item xs={12} sm={12} className="h-100">
                <Card className={classes.utilityAllowanceContainer} style={{background:'#DEE6FF '}}>
                    <div className={classes.cardHeader}>
                        <div className="header">Utility Allowance Report</div>
                        {/* <div className="actions">Explore More</div> */}
                    </div>
                    <div className='utilityAllowanceCard'>
                        <div className="count">
                            {
                                utilituAllowance.isLoading ?
                                    <div className='utilityAllowanceLoader'>
                                        <SvgLoadIcon className="greyLoader" />
                                    </div>
                                    :
                                    utilituAllowance.page.count || 0
                            }
                        </div>
                        <div className="text">Utility Allowance not filled</div>
                    </div>
                </Card>
            </Grid>
        )
    }
}
// prop types
UtilityAllowance.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
UtilityAllowance.defaultProps = {
    classes: {},
    data: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};
// export UtilityAllowance
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(UtilityAllowance));