import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

//Material Ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Components
import TableComponent from '../../components/Table/TableComponent';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// actions
import { buildingActions } from '../../actions/building';
import { dashboardActions } from '../../actions/dashboard';
import { sitesActions } from '../../actions/sites';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';
import { confActions } from '../../actions/configuration';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Helpers
import { getUserRole, globalVariable, history, filterSearchFields } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";

let DEFAULT_DEL_TITLE ="This Building is associated with at least one Unit.Deleting this building will delete all associated unit Details ?"


/**
 * Monitoring field for Table component
 * @class MonitoringField
 * @extends {Component}
 * @memberof BuildingsList
 */
class MonitoringField extends Component {
    /**
     *render HTML
     *
     * @returns
     * @memberof MonitoringField
     */
    render() {
        const { data } = this.props;
        const { monitoring } = data;
        const clsName = monitoring === 'Claim' || monitoring === 'Monitoring' ? 'linkCell' : '';
        return (
            <span
                className={clsName}
                onClick={() => {
                    this.props.handleChange("monitoring", {
                        id: data._id, monitoring: data.monitoring,
                        site_id: data.site_id
                    });
                }}
            >
                {monitoring}
            </span>
        )
    }
}
// default props
MonitoringField.defaultProps = {
    data: {},
    path: ""
}
/**
 *Building listing page component
 *
 * @class BuildingsList
 * @extends {Component}
 */
class BuildingsList extends Component {
    /**
     * Constructors
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
            deleteContent:"",
            addUnitPopup: {
                open: false,
                project: '',
                site: '',
                deleteBldg: false,
                deleteItemId: null,
                deleteItemSiteId:null,
                openHelp: false
            }
        }
        this.userRole = getUserRole();
    }
    /**
     *Executes just before the render function
     * @memberof BuildingsList
     * 
     * [1]  -   Get help datas
     * [2]  -   To check if the reducer has page value
     * [3]  -   Get data based on reducer page values
     * [4]  -   Get data based on default page values
     * [5]  -    Gel field filters
     */
    UNSAFE_componentWillMount() {
        const { location: { pathname } } = this.props;
        const { page } = this.props.building;
        //handled for routes from archive
        const siteId = isNaN(this.props.siteId)?pathname.split("/")[4]:this.props.siteId;
        // [1]
        this.props.getHelp();
        const tblConfigName = this.props.isSubPage ? 'building' : 'buildingsList';
        let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
        // [2]
        if (page.skip) {
            // [3]
            this.props.getBuilding(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, siteId, query);
        } else {
            // [4]
            this.props.getBuilding(1, globalVariable.tableRowSize, 'name', 'asc', '', '', siteId, query);
        }

        setTimeout(() => {
            // [5]
            this.props.getFieldFilter(tblConfigName);
        }, 100);

        // to get associated projects and sites list
        this.props.getProjectSummary(null, '');
    }
    /**
     *Executes just after the render function
     * @memberof BuildingsList
     * 
     * [1]  -   Get buildings by site id, if it is sub-page
     */
    componentDidMount() {
        const { siteId, location: { pathname } } = this.props;
        // [1]
        if (siteId) {
            this.props.getById(siteId, pathname.includes('archives') ? 'archives' : null);
        }
    }
    /**
     * Component will unmount
     * @memberof BuildingsList
     * 
     * [1]  -   clear search of assoc tab
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            // [1]
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Sort Event
     * @param {string} orderBy 
     * @param {number} order 
     * @memberof TableComponent
     * 
     * [1]  -   Get Buildings
     */
    onSortRequest = (orderBy, order) => {
        const siteId = this.props.siteId;
        const { building, searchData: { sFields, sValues }, location: { pathname } } = this.props;
        let query = pathname.includes('archives') ? { isDelete: true } : null;
        // [1]
        this.props.getBuilding(building.page.skip, building.page.limit, orderBy, order, sFields, sValues, siteId, query);
    };
    /**
     * Handle Table Pagination On Change Event
     * @param {number} skip 
     * @param {number} limit 
     * @memberof TableComponent
     * 
     * [1]  -   Get Buildings
     */
    onChangePageTable = (skip, limit) => {
        const siteId = this.props.siteId;
        const { building, searchData: { sFields, sValues }, location: { pathname } } = this.props;
        let query = pathname.includes('archives') ? { isDelete: true } : null;
        // [1]
        this.props.getBuilding(skip, limit, building.page.orderBy, building.page.order, sFields, sValues, siteId, query);
    };
    /**
     * 
     * @param {*} searchField 
     * @param {*} searchKey 
     * @memberof TableComponent
     * 
     * [1]  -   To check wheather searchField is already in the array sFields
     *          if - Update the searchField
     *          else - Push the searchField into the array sFields
     * [2]  -   To check if the searchKey has a vale
     *          if - searchKey is empty remove the searchField from the array sFields
     *          else - Update the searchKey
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const siteId = this.props.siteId;
        const { building, searchData, isSubPage, location: { pathname } } = this.props;
        let { sFields, sValues } = searchData;
        let query = pathname.includes('archives') ? { isDelete: true } : null;
        // [1]
        if (sFields.indexOf(searchField) >= 0) {
            // [2]
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues }, isSubPage);
        this.props.getBuilding(1, globalVariable.tableRowSize, building.page.orderBy, building.page.order, sFields, sValues, siteId, query);
    };
    /**
     * Handle Table Delete Action
     * @param {*} id 
     * @memberof TableComponent
     * 
     * [1]  -   show pop up
     */
    handleTableDeleteAction = (id,item) => {
        let deleteTitle = DEFAULT_DEL_TITLE
        if(item && item.name && item.totalunit){
            deleteTitle=  <p>This <b>Building: {item.name}</b> is associated with <b>{item.totalunit.length} Units.</b> Deleting this building will delete all associated unit details?</p>
        }
        // [1]
        //this.setState({ deleteBldg: true, deleteItemId: id, deleteContent:deleteTitle});
        this.setState({ deleteBldg: true, deleteItemId: id,deleteItemSiteId:item.site_id, deleteContent:deleteTitle});
    };
    /**
     * A method get invoked when add icon get clicked
     * @memberof ContentWrapper
     * 
     * [1]  -   If the page is main builfing list, add new building open popup
     * [2]  -   If the page is sub-page, navigate to add new building page
     */
    handleAddActionClick = () => {
        const { location: { state }, match, siteId } = this.props;
        // [1]
        if (state.main === true) {
            this.setState({
                addUnitPopup: {
                    ...this.state.addUnitPopup,
                    open: true
                }
            })
        } else {
            // [2]
            history.push({
                pathname: `${match.url}/new`,
                state: {
                    page: 'buildingNew',
                    pageData: {
                        site_id: siteId
                    }
                }
            });
        }
    };

    /**
     * A method to navigate to the project->sites->building->add building page when user selects from add building popup
     * @memberof ValidatorForm
     */
    gotoAddBuildingPage = () => {
        const { addUnitPopup: { site, project } } = this.state;
        history.push({
            pathname: `/projects/${project.value}/sites/${site.value}/buildings/new`,
            state: {
                page: 'buildingNew',
                pageData: {
                    site_id: site.value
                },
                pageTitle: {
                    title: `${site.label} | ${project.label}`,
                    subTitle: ''
                }
            }
        });
    }
    /**
     * Handle Table Navigation Event
     * @param {number} id 
     * @param {string} field 
     * @param {object} item 
     * 
     * [1]  -   Navigation for project edit view
     * [2]  -   Navigation for site view
     * [3]  -   Navigation for building view
     * [4]  -   Navigation for building view
     */
    handleNavigateAction = (id, field, item) => {
        const { match, siteId } = this.props;
        let projectID = item.funder&&item.funder.length>0?item.funder[0].project_id:null;
        
        switch (field) {
            // [1]
            case 'project.name':
                history.push({
                    pathname: `/projects/${projectID}`,
                    state: {
                        page: 'projectEdit',
                        pageData: {
                            project_id: item.project_id,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [2]
            case 'site.name':
                history.push({
                    pathname: `/sites/${item.site_id}`,
                    state: {
                        page: 'siteEdit',
                        pageData: {
                            site_id: item.site_id,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [3]
            case 'name':
                history.push({
                    pathname: `${match.url}/${id}`,
                    state: {
                        page: 'buildingEdit',
                        pageData: {
                            site_id: siteId ? siteId : item.site_id,
                            building_id: id,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [4]
            case "monitoring":
                if (item.monitoring === 'Monitoring' || item.monitoring === 'Claim' || item.monitoring === 'Pending' || item.monitoring === 'Expired') {
                    history.push({
                        pathname: `/projects/${projectID}/funders`,
                    });
                }
                break;
            default:
        }
    }
    /**
     * Handle Back Action Click
     * @memberof ContentWrapper
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle clear filter action
     * @memberof ContentWrapper
     * 
     * [1]  -   Update the feldFilters
     */
    handleClearFilterActionClick = () => {
        this.setState({ reset: true });
        const { building, siteId, isSubPage, location: { pathname } } = this.props;
        // [1]
        let query = pathname.includes('archives') ? { isDelete: true } : null;
        this.props.searchActions({ sFields: [], sValues: [] }, isSubPage);
        this.props.getBuilding(building.page.skip, building.page.limit, building.page.orderBy, building.page.order, [], [], siteId, query);
    };
    /**
     * Handle Field Filter Action Event
     * @memberof ContentWrapper
     * 
     * [1]  -   Update visible columns
     */
    handleFieldFilterActionClick = () => {
        const tblConfigName = this.props.isSubPage ? 'building' : 'buildingsList';
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        this.props.changeFieldFilter(tblConfigName, this.props.building.page.fieldFilters.visibleColumns);
    };
    /**
    * Handle field filter close action
    * @memberof FieldFilter
    * 
    * [1]  -   reset to current table column values if user not save the changes4
    * [2]  -   close the popup
    */
    handleFieldFilterOnClose = () => {
        const tblConfigName = this.props.isSubPage ? 'building' : 'buildingsList';
        // [1]
        this.props.changeFieldFilter(tblConfigName, this.props.building.page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {string} key 
     * @param {boolean} value 
     * @memberof FieldFilter
     * 
     * [1]  -   If value is false, remove that key from the array visibleColumns
     * [2]  -   Else push the key into the array visibleColumns
     */
    handleFieldFilterOnChange = (key, value) => {
        const tblConfigName = this.props.isSubPage ? 'building' : 'buildingsList';
        // [1]
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(tblConfigName, updatedColumns);
        } else {
            // [2]
            this.props.changeFieldFilter(tblConfigName, [...this.props.visibleColumns, key]);
        }
    }

    getDeleteTitle = (item) => {
        let fundersName =item.fundersName.substring(0,item.fundersName.length-2) ;
        return <p>This <b>Building: {item.name}</b> is monitored by the funder(s) <b>{fundersName}.</b> Are you sure you want to delete this record?</p>
    }
    /**
     * Handle Field Filter On Save Event
     * @memberof FieldFilter
     * 
     * [1]  -   Update the field filters
     * [2]  -   On api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        const tblConfigName = this.props.isSubPage ? 'building' : 'buildingsList';
        // [1]
        this.props.updateFieldFilter(tblConfigName, this.props.visibleColumns, () => {
            // [2]
            this.setState({ fieldFilter: false });
            const { building, siteId, searchData: { sFields, sValues }, location: { pathname } } = this.props;
            let query = pathname.includes('archives') ? { isDelete: true } : null;
            this.props.getBuilding(building.page.skip, building.page.limit, building.page.orderBy, building.page.order, sFields, sValues, siteId, query);
        });
    }
    /**
     * Handle Help Click Action
     * @memberof ContentWrapper
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     * 
     * [1]  -   Navigation to building using monitoring status
     */
    fieldComponentOnChange = (name, data) => {
        const { match, siteId } = this.props;
        switch (name) {
            // [1]
            case "monitoring":
                if (data.monitoring === 'Monitoring' || data.monitoring === 'Claim' || data.monitoring === 'Pending' || data.monitoring === 'Expired') {
                    history.push({
                        pathname: `${match.url}/${data.id}`,
                        state: {
                            page: 'buildingEdit',
                            pageData: {
                                site_id: siteId ? siteId : data.site_id,
                                building_id: data.id
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * Handle projects search
     * @param {*} search 
     * @memberof BuildingsList
     * 
     */
    handleProjectsSearch = (search) => {
        this.props.getProjectSummary(null, search);
    }
    /**
     *Bind HTML to reactDOM
     * @returns
     * @memberof BuildingsList
     * 
     * [1]  -   Remove bulk upload icon from pageAccess - projects/buildinglist
     */
    render() {
        const { userRole } = this;
        let { building, location: { pathname }, columnsMap, visibleColumns, pageAccess,
            isSubPage, classes, title, projectSummaryData, searchData: { sFields, sValues }, helpData } = this.props;
        const fieldFilters = building.page ? building.page.fieldFilters : {};
        const defaultColumns = fieldFilters.defaultCols
        const { addUnitPopup, openHelp, addUnitPopup: { project, site } ,deleteContent} = this.state;
        const { projects, projectsCount } = projectSummaryData;
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        // [1]
        if (pathname.includes('projects') || pathname.includes('sites')) {
            const index = pageAccess.indexOf('UPLOAD_BULK_UTILITYALLOWANCE');
            if (index > -1) {
                pageAccess.splice(index, 1);
            }
            pageAccess = [...pageAccess, 'HELP']
        } else {
            pageAccess = [...pageAccess, 'HELP']
        }
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                needPadding={false}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleBackActionClick={this.handleBackActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                handleUploadActionClick={(event) => {
                    history.push({
                        pathname: `/bulk-upload/buildings`,
                        state: {
                            page: 'bulkUpload',
                            pageData: {}
                        }
                    })
                }}
                uploadTooltip="Buildings Bulk Update"
            >
                <TableComponent
                    page={building.page.skip}
                    rowsPerPage={building.page.limit}
                    count={building.page.count}
                    header={fieldFilters.headerCols}
                    links={["name", "monitoring"]}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    actions={pageAccess}
                    userRole={userRole}
                    // isLoading={building.isLoading}
                    order={building.page.order}
                    orderBy={building.page.orderBy}
                    highlightField={['name']}
                    getDeleteTitle={this.getDeleteTitle}
                    data={building.data}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={building.isLoading ? "" : "No Records Found"}
                    moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                    prevNext={false}
                    paginationVisible={true}
                    disablePagination={building.page.lastPage}
                    showLoading={isSubPage}
                />
                <FieldFilter
                    open={this.state.fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                    // disabledCols={defaultColumns}
                />

                <AlertDialog
                    open={addUnitPopup.open}
                    title="Add Building"
                    onClose={() => {
                        this.setState({
                            addUnitPopup: {
                                ...addUnitPopup,
                                open: false
                            }
                        })
                    }}
                    onSave={() => {
                        this.refs.form.submit();
                    }}
                    saveText={'Add Building'}
                    className="p-0-24"
                >
                    <Grid item xs={12} sm={12} style={{ minWidth: '400px' }} >
                        <ValidatorForm
                            name="addBuildingToForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.gotoAddBuildingPage}
                            onError={errors => { }} >
                            <Grid item xs={12} sm={12} >
                                <AutoComplete
                                    name="project"
                                    suggestions={projects}
                                    className="autocomplete-fixed"
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            addUnitPopup: {
                                                ...addUnitPopup,
                                                project: selectedOption,
                                                site: ''
                                            }
                                        })
                                    }}
                                    selectedValue={project}
                                    disabled={false}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    placeholder="Select Project"
                                    dataCount={projectsCount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AutoComplete
                                    name="site"
                                    suggestions={project.sites}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            addUnitPopup: {
                                                ...addUnitPopup,
                                                site: selectedOption,
                                                building: ''
                                            }
                                        });
                                    }}
                                    selectedValue={site || {}}
                                    disabled={false}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    placeholder="Select Site"
                                />
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </AlertDialog>
                <DeleteDialog
                    open={this.state.deleteBldg === true}
                    content={deleteContent}
                    handleDeleteAction={() => {
                        this.setState({ deleteBldg: false });
                        // this.props.deleteBuilding(this.state.deleteItemId)
                        this.props.deleteBuilding(this.state.deleteItemId,this.state.deleteItemSiteId)
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ deleteBldg: false })
                    }}
                />
                { openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Buildings"
                />}
            </ContentWrapper>
        );
    }
}
// prop types
BuildingsList.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};
// default props
BuildingsList.defaultProps = {
    title: "",
    isSubPage: false,
    pageAccess: []
}
/**
 * Mao state to props
 * @param {*} state 
 * @param {*} param1 
 * @returns 
 */
const mapStateToProps = (state, { isSubPage }) => {
    const tblConfigName = isSubPage ? 'building' : 'buildingsList';
    const columnsData = state.configuration.fieldFilter[tblConfigName] || {};
    const searchData = isSubPage ? state.search['buildings'] || { sFields: [], sValues: [] } : state.search['buildingsList'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    return {
        building: state.buildings,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess: state.pageAccess['buildingsList'] || [],
        projectSummaryData: state.dashboard.projectSummary,
        searchData,
        helpData
    }
};
/**
 * Map dispatch  to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getBuilding: (skip, limit, orderBy, order, sFields, sValues, siteId, query) => dispatch(buildingActions.getAll(skip, limit, orderBy, order, sFields, sValues, siteId, query)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        //deleteBuilding: (id) => dispatch(buildingActions.delete(id)),
        deleteBuilding: (id,site_id) => dispatch(buildingActions.delete(id,site_id)),
        getProjectSummary: (limit, search) => dispatch(dashboardActions.getProjectSummary(limit, search)),
        getById: (id, type) => dispatch(sitesActions.getById(id, type)),
        searchActions: (data, isSubPage) => dispatch(searchActions.buildings(data, isSubPage)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 9 })),
    }
};
// export BuildingsList
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(pageStyle)(BuildingsList));