export const bulkDataConstants = {

    // SAVE DATA REQUEST
    SAVEBULKDATA_REQUEST: "BULKDATA_SAVE_REQUEST",
    SAVEBULKDATA_SUCCESS: "BULKDATA_SAVE_SUCCESS",
    SAVEBULKDATA_FAILURE: "BULKDATA_SAVE_FAILURE",

    // GET BULK DATA LOGS

    GETBULKDATALOGS_REQUEST: "BULKDATA_GET_LOG_REQUEST",
    GETBULKDATALOGS_SUCCESS: "BULKDATA_GET_LOG_SUCCESS",
    GETBULKDATALOGS_FAILURE: "BULKDATA_GET_LOG_FAILURE",

    // DELETE BULK DATA LOGS
    DELETE_REQUEST: "BULKDATA_DELETE_REQUEST",
    DELETE_SUCCESS: "BULKDATA_SUCCESS_REQUEST",
    DELETE_FAILURE: "BULKDATA_FAILURE_REQUEST",

    // DOWNLOAD BULK DATA 
    DOWNLOAD_REQUEST: "BULKDATA_DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "BULKDATA_DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "BULKDATA_DOWNLOAD_FAILURE"
}