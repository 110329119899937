import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TableComponent from '../../components/Table/TableComponent';
import HelpFloater from '../../components/HelpFloater/HelpFloater';

//Actions
import { confActions } from '../../actions/configuration';
import { exploreActions } from '../../actions/explore';
import { helpActions, alertActions } from '../../actions';

//helpers
import { getUserRole, getUserAccessForPage } from '../../helpers';
import { history, globalVariable, filterSearchFields } from '../../helpers'


// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Custom Variables
let sFields = [];
let sValues = [];

/**
 * ExploreList Component
 * @class ExploreList
 * @extends {Component}
 */
class ExploreList extends Component {
    /* Constructor */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilters: false,
            reset: false,
            openHelp: false,
            pageAccessUpdated:[],
            // download: false,
            // reportId: null
        };
        this.userRole = getUserRole();
        this.pageAccess = getUserAccessForPage('explore');
    }
    /**
     * Component did Update for clearing the search
     */
    componentDidUpdate() {
        const { location } = this.props;
        switch (location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                // history.replace({
                //     pathname: location.pathname,
                //     state: {
                //         ...location.state
                //     }
                // });
                history.replace(location.pathname)
                break;
            default:
                break;
        }
    }
    /**
     * Component Will Mount
     * 
     * [1]  -   Get help and pageaccess datas
     * [2]  -   To check if the reducer has page value
     * [3]  -   Get data based on reducer page values
     * [4]  -   Get data based on default page values
     * [5]  -   Fetch list after an interval 120 seconds
     */
    UNSAFE_componentWillMount() {
        const { explore } = this.props;
        const { page } = explore;
        // [1]
        this.props.getHelp();
        let pgaccess = getUserAccessForPage('explore');
        this.setState({pageAccessUpdated:pgaccess})
        // [2]
        if (page.skip) {
            // [3]
            sFields = page.sFields;
            sValues = page.sValues;
            this.props.getAll(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues);
        } else {
            // [4]
            sFields = [];
            sValues = [];
            this.props.getAll(1, globalVariable.tableRowSize, 'createdAt', 'desc', '', '');
        }
        // [5]
        this.intervalId = setTimeout(() => {
            const { explore } = this.props;
            const { page } = explore;
            if (page.skip) {
                sFields = page.sFields;
                sValues = page.sValues;
                this.props.getAll(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues);
            } else {
                sFields = [];
                sValues = [];
                this.props.getAll(1, globalVariable.tableRowSize, 'report_name', 'asc', '', '');
            }
        }, 3000);
    }

    /**
     * Component will unmount
     */
    componentWillUnmount() {
        clearTimeout(this.intervalId);
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @memberof TableComponent
     * 
     * [1]  -   Get Explore list
     */
    onChangePageTable = (skip) => {
        const { explore } = this.props;
        // [1]
        this.props.getAll(skip, globalVariable.tableRowSize, explore.page.orderBy, explore.page.order, sFields, sValues);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     * @memberof TableComponent
     * 
     * [1]  -   To check wheather searchField is already in the array sFields
     *          if - Update the searchField
     *          else - Push the searchField into the array sFields
     * [2]  -   To check if the searchKey has a vale
     *          if - searchKey is empty remove the searchField from the array sFields
     *          else - Update the searchKey
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { explore } = this.props;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.getAll(1, globalVariable.tableRowSize, explore.page.orderBy, explore.page.order, sFields, sValues);
    };
    /**
     * Handle Table Sort Event
     * @param {string} orderBy 
     * @param {number} order 
     * @memberof TableComponent
     * 
     * [1]  -   Get Explore list
     */
    onSortRequest = (orderBy, order) => {
        const { explore } = this.props;
        // [1]
        this.props.getAll(explore.page.skip, explore.page.limit, orderBy, order, sFields, sValues);
    };

    /**
    * Handle Clear Fileter Action
    * @memberof TableComponent
    * 
    * [1]  -   Get Explore list by clearing the filters
    */
    handleClearFilterActionClick = () => {
        this.setState({ reset: true });
        // [1]
        sFields = [];
        sValues = [];
        const { explore } = this.props;
        this.props.getAll(explore.page.skip, explore.page.limit, explore.page.orderBy, explore.page.order, [], []);
        // this.props.resetFilter();
    };
    /**
     * Handle Table Delete Action
     * @param {*} id 
     * @memberof TableComponent
     * 
     * [1]  -   delete the explore data
     */
    handleTableDeleteAction = (id) => {
        // [1]
        this.props.deleteExploreData(id)
    };
    /**
     * Handle Table Navigation Event
     * @param {*} _id 
     * @param {*} field 
     * @param {*} item 
     * 
     * [1]  -   Navigate to edit exploredata
     */
    handleNavigateAction = (_id, field, item) => {
        const { match } = this.props;
        // [1]
        if (item.exploreType !== "DashboardDownload") {
            history.push({
                pathname: `${match.url}/${item._id}`,
                state: {
                    page: 'exploreEdit',
                    pageData: {
                        _id: item._id
                    }
                }
            });
        }
    }
    /**
    * Handle Add Action Click Event
    * 
    * [1]   -   Navigate in add explore data page
    */
    handleAddActionClick = () => {
        const { match } = this.props;
        // [1]
        history.push({
            pathname: `${match.url}/new`,
            state: {
                page: 'exploreNew',
                pageData: {}
            }
        });
    };
    /**
     * Handle Download Action
     * @param {*} item 
     * 
     * [1]  -   Explore file can be downloaded only if download status is 'Completed'
     */
    handleDownloadAction = (item) => {
        if (item.downloadStatus === "InProgress") {
            this.props.showErrorAlert("Please wait... Download is in Progress...");
        } else {
            this.props.downloadExploreReport(item._id, null, item.report_name);
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
    * Bind Html to DOM 
    */
    render() {
        let { explore, title, isSubPage, pageAccess, classes, location: { pathname }, helpData } = this.props;
        const { openHelp,pageAccessUpdated } = this.state
        const fieldFilters = explore.page ? explore.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        if(pageAccess.length===0){
            pageAccess = pageAccessUpdated
        }
        pageAccess = [...pageAccess, 'HELP']
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                needPadding={false}
                isSubPage={isSubPage}
                title={title}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
            >

                {explore.data &&
                    <TableComponent
                        page={explore.page.skip}
                        rowsPerPage={explore.page.limit}
                        count={explore.page.count}
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        actions={pageAccess}
                        links={["report_name"]}
                        isLoading={explore.isLoading}
                        order={explore.page.order}
                        orderBy={explore.page.orderBy}
                        data={explore.exploreList}
                        onChangePageTable={this.onChangePageTable}
                        onSortRequest={this.onSortRequest}
                        onInputChangeRequest={this.onInputChangeRequest}
                        handleTableDeleteAction={this.handleTableDeleteAction}
                        handleNavigateAction={this.handleNavigateAction}
                        handleDownloadAction={this.handleDownloadAction}
                        resetSearchContent={this.props.location.hash === "#clear"}
                        paginationVisible={true}
                        disablePagination={explore.page.lastPage}
                        sFields={filterSearchFields(sFields)}
                        sValues={sValues}
                        noDataErrorMessage={"No Records Found"}
                        showLoading={false}
                    />
                }
                { openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Explore Data"
                />}
            </ContentWrapper>


        );
    }
}

/**
 * Bind Component Property Types
 */
ExploreList.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.any.isRequired,
    pageAccess: PropTypes.array,
    isSubPage: PropTypes.bool,
};

/*  default props  */
ExploreList.defaultProps = {
    title: "All Reports",
    pageAccess: [],
    isSubPage: false,
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ explore, pageAccess, help }, ownProps) => {
    // const columnsData = configuration.fieldFilter['exploreData'] || {};
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['explore'] || [];
    // explore = explore;
    const helpData = help.data[0]

    return {
        pageAccess,
        explore,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (skip, limit, orderBy, order, sFields, sValues) => dispatch(exploreActions.getAll(skip, limit, orderBy, order, sFields, sValues)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        deleteExploreData: (id) => dispatch(exploreActions.delete(id)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        downloadExploreReport: (id, reportData, reportName) => dispatch(exploreActions.downloadExploreReport(id, reportData, reportName)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 7 })),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearErrorAlert: () => dispatch(alertActions.clear()),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(ExploreList));
