import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Material UI
import { TextValidator } from 'react-material-ui-form-validator';
import { MuiThemeProvider } from "@material-ui/core/styles";
import CustomToolTip from '../../components/ToolTip/CustomToolTip';


// Table validations warning helper
import { hasWarnings } from '../../helpers/TableValidations/warnings';

/**
 *Number field component
 *
 * @class NumberField
 * @extends {Component}
 */
class NumberFieldToolTip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            focus: false
        }
    }

    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }

    /**
     *render html
     *
     * @returns
     * @memberof NumberField
     */
    render() {
        let { name, value, className, validators, fielderrors, warningfieldname, allowNegative, displayType, variant, zeroFlag, suffix } = this.props;
        value = value === '' || value === null || value === 'undefined' ? '' : value;
        const hasRequired = validators.indexOf('required') >= 0;
        let customClass = validators && validators.length > 0 && hasRequired ? className + ' required' : className;
        const warningClass = hasWarnings(fielderrors, warningfieldname);

        return (
            <MuiThemeProvider>
                <CustomToolTip
                    {...this.props}
                    focus={this.state.focus}
                >
                    <NumberFormat
                        thousandSeparator=","
                        decimalSeparator="."
                        variant={variant}
                        fixedDecimalScale={value === 'N/A' ? false : true}
                        decimalScale={value === 'N/A' ? null : 2}
                        customInput={TextValidator}
                        isNumericString={true}
                        onChange={(event) => {
                            let newValue = event.target.value
                            if (typeof (event.target.value) === 'string') {
                                newValue = event.target.value.includes('%')?event.target.value.replace('%', ''):event.target.value.replace('$', '');
                                newValue = newValue.replaceAll(',', '');
                                newValue = +newValue;
                            }
                            this.props.handleChange(name, newValue);
                        }}
                        inputProps={{
                            onFocus: (values) => {
                                this.setState({
                                    focus: true
                                })
                                if (zeroFlag) {
                                    values.target.select()
                                }
                            },
                            onBlur: () => {
                                this.setState({
                                    focus: false
                                })
                            }
                        }}
                        {...this.props}
                        value={value}
                        className={`${customClass} ${warningClass}`}
                        allowNegative={displayType === "text" ? true : allowNegative}
                        suffix={suffix}
                    />
                </CustomToolTip>
            </MuiThemeProvider>
        );
    }
}

//default props
NumberFieldToolTip.defaultProps = {
    classes: {},
    name: 'no-name',
    value: '',
    label: "",
    type: "text",
    className: '',
    errorMessages: [],
    validators: [],
    disabled: false,
    margin: 'dense',
    maxLength: 50,
    SelectProps: {},
    handleChange: () => { },
    handleBlur: () => { },
    prefix: '$',
    fielderrors: [],
    warningfieldname: '',
    allowNegative: false,
    format: null,
    variant: 'standard',
    zeroFlag: false,
    suffix: ''
}

//prop types
NumberFieldToolTip.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    variant: PropTypes.string,
    zeroFlag: PropTypes.bool,
    suffix: PropTypes.string
};

//export component
export default NumberFieldToolTip;