import React, { Component } from 'react';
import { connect } from 'react-redux';

// Material UI
import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

//import images
import Success from '../../../assets/img/success.png'
import Error from '../../../assets/img/error.png'
import Caution from '../../../assets/img/alertCaution.svg'
import Cron_Running from '../../../assets/img/stopwatch.svg'

//import components
import AlertDialog from '../../../components/AlertDialog/AlertDialog'
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import { DownloadIcon } from '../../../components/SvgIcons/SvgIcons';

// Styles
import pageStyle from '../../../assets/jss/containers/common';

// Actions
import { maintenanceActions } from '../../../actions/maintenance';
import { pageAccessActions, userActions } from '../../../actions';

/**
 * RunCron Componenet
 * @class RunCron
 * @extends {Component}
 */
class RunCron extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false
        }
    }
    /**
     * UNSAFE_componentWillMount
     */
    UNSAFE_componentWillMount() {
        this.props.getAccess('incomerent', {})
    }
    /**
     * Handle View Details
     */
    handleViewDetails = () => {
        this.props.downloadCronStatus('view')
        this.setState({ showDetails: true })
    }
    /**
     * render HTML
     * @returns 
     * 
     * [1]  -   If ProcessIndex is not equal to 3
     * [2]  -   If Process Index is equal to 3 and status is pending
     * [3]  -   Default return in Cron completion status
     */
    render() {
        const { maintenance, cronStatus, pageAccess } = this.props;
        const { showDetails } = this.state;
        // [1]
        if (maintenance && maintenance.maintenance && maintenance.processIndex !== 3) {
            return null
        }
        // [2]
        if (maintenance && maintenance.processIndex === 3 && maintenance.pending) {
            const { logs } = maintenance
            const lastLog = logs.length > 0 ? logs[logs.length - 1] || "Please Wait!" : "Please Wait!"
            return (
                <>
                    <Grid container spacing={4} align="center" className="height-100-per alignContentCenter">
                        <Grid item xs={12}>
                            <img src={Cron_Running} alt="Cron Running" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="fw-600">
                                {lastLog.message}
                            </Typography>
                        </Grid>
                        {!lastLog.isDbRestoring ?
                            (!maintenance.error && !showDetails) ? (
                                <Grid item xs={12} className="displayFlex alignItemsCenter jcCenter">
                                    <Button
                                        color="primary"
                                        style={{ "textTransform": "none" }}
                                        onClick={() => this.handleViewDetails()}
                                    >
                                        Check Details Here
                                    </Button>
                                </Grid>
                            )
                                :
                                (
                                    <div className="w-100-per">
                                        <Grid item xs={12} className="displayFlex alignItemsCenter jcCenter">
                                            <div className="pA-24">
                                                <Typography color="primary" variant="h5" className={'lineHeit fw-600'}>
                                                    {cronStatus.totalProjects || 0}
                                                </Typography>
                                                <Typography variant="h6" className={'lineHeit'}>
                                                    Total Projects Count
                                                </Typography>
                                            </div>
                                            <div className="pA-24">
                                                <Typography color="primary" variant="h5" className={'lineHeit fw-600'}>
                                                    {cronStatus.totalProjectsUpdated || 0}
                                                </Typography>
                                                <Typography variant="h6" className={'lineHeit'}>
                                                    Updated Count
                                                </Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} className="displayFlex alignItemsCenter jcCenter">
                                            <Button
                                                color="primary"
                                                style={{ "textTransform": "none" }}
                                                onClick={() => this.setState({ showDetails: false })}
                                            >
                                                Hide Details Here
                                            </Button>
                                        </Grid>
                                    </div>
                                ) :
                            null
                        }
                    </Grid>
                </>
            )
        }
        // [3]
        return (
            <React.Fragment>
                {
                    maintenance && !maintenance.error && <Grid container spacing={2} align="center" className="height-100-per alignContentCenter">
                        <Grid item xs={12}>
                            <img src={Success} alt="Success" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="fw-600">
                                Cron is completed successfully.
                            </Typography>
                            {
                                pageAccess.indexOf("MAINTENANCE") > -1 &&
                                <Typography variant="body1" className={'pt-20 lineHeit'}>
                                    You can now turn OFF the maintenance mode to ON the system. Please find the below summary.
                                </Typography>
                            }
                        </Grid>
                        {
                            pageAccess.indexOf("MAINTENANCE") > -1 &&
                            <Grid item xs={12} className="displayFlex alignItemsCenter jcCenter">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ "textTransform": "none" }}
                                    onClick={() => { this.props.goLive() }}
                                >
                                    OFF Maintanence Mode
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }
                {
                    maintenance && maintenance.error && <Grid container spacing={2} align="center" className="height-100-per alignContentCenter">
                        <Grid item xs={12}>
                            <img src={Error} alt="Error" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="fw-600">
                                Oops! Something went wrong.
                            </Typography>
                            <Typography variant="h6" className={' lineHeit'}>
                                There was an error while running the cron. Please find the error below.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className="displayFlex alignItemsCenter jcCenter pt-20">
                            <span className="displayFlex alignItemsCenter jcSpaceBetween pA-10 bg-red color-red minWidth-30-per br-24 p-l-r-24">
                                <div>Total Projects Failed</div>
                                <div>{maintenance.error.totalNotUpdated || ""}</div>
                                <div className="cursorPointer" onClick={() => this.props.downloadCronStatus()}><DownloadIcon /></div>
                            </span>
                        </Grid>
                        {
                            pageAccess.indexOf("MAINTENANCE") > -1 &&
                            <Grid item sm="12" className="pt-40">
                                <SaveCancel
                                    saveText="Re-run the Cron again"
                                    cancelText="Restore the Database"
                                    justifyContent="center"
                                    handleSaveButtonClick={() => { this.props.startCron() }}
                                    handleCancelButtonClick={() => { this.setState({ confirmRestore: true }) }}
                                />
                            </Grid>
                        }
                    </Grid>
                }
                <AlertDialog
                    open={this.state.confirmRestore}
                    title=""
                    onClose={() => { }}
                    saveVisible={false}
                    cancelVisible={false}
                    maxWidth="sm"
                >
                    <div className="p-t-b-32 p-l-r-180">
                        <ValidatorForm
                            name="maintenanceForm"
                            ref="form"
                            autoComplete="off"
                            // onSubmit={ }
                            onError={errors => { }}
                        >
                            <Grid container spacing={4} align="center" className="jcCenter">
                                <div className="w-100-per">
                                    <img src={Caution} alt="caution" />
                                </div>
                                <div>
                                    <br />
                                    <Typography variant="h6" className={'lineHeit'}>
                                        Are you sure to Restore the Database? <br />
                                        Clicking confirm will restore the database and set the the site to live!.
                                    </Typography>
                                </div>
                            </Grid>
                            <br />
                            <SaveCancel
                                saveText="Confirm"
                                justifyContent="center"
                                handleSaveButtonClick={() => { this.props.restoreDatabase(); this.setState({ confirmRestore: false }) }}
                                handleCancelButtonClick={() => { this.setState({ confirmRestore: false }) }}
                                cancelVisible={true}
                            />
                        </ValidatorForm>
                    </div>
                </AlertDialog>
            </React.Fragment>
        );
    }
}
// prop types
RunCron.propTypes = {
};
/**
 * Default Props
 */
RunCron.defaultProps = {
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ maintenance: { data, cronStatus }, pageAccess: { incomerent } }, ownProps) => {
    return {
        maintenance: { ...data },
        cronStatus: { ...cronStatus },
        pageAccess: incomerent || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        startCron: () => dispatch(maintenanceActions.startCron()),
        goLive: () => dispatch(maintenanceActions.goLive()),
        downloadCronStatus: (type) => dispatch(maintenanceActions.getCronStatus(type)),
        logout: () => dispatch(userActions.logout()),
        restoreDatabase: () => dispatch(maintenanceActions.restoreAndGoLive()),
        getAccess: (pageName, pageData) => dispatch(pageAccessActions.getAccess(pageName, pageData))
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(RunCron));