import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const mailTemplateService = {
    getAll,
    getById,
    update,
    addNewTemplate,
    deleteTemplate,
    setActivateStatus,
    sendTemplate,
    getUsersList,
    activeStatus
};

/**
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false) {

    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete
    };

    const requestOptions = {
        method: 'Post',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates/${id}`, requestOptions).then(handleResponse);
}

function getUsersList(projects, userType, limit, search) {
    let data = {
        projects: projects,
        userType: userType
    }
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates/usersList?limit=${limit}&search=${search}`, requestOptions).then(handleResponse);
}

/**
 * Update Organization data
 * 
 * @param {*} template 
 */
function update(template, id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(template)
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates/${id}`, requestOptions).then(handleResponse);
}

function sendTemplate(template) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(template)
    };

    return fetch(`${globalVariable.ms_apiURL}/sendMail`, requestOptions).then(handleResponse);
}

/**
 * Add new organization
 * 
 * @param {*} template 
 */
function addNewTemplate(template) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(template)
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function deleteTemplate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates/${id}`, requestOptions).then(handleResponse);
}

/**
 * A method to activate / deactivate the org
 *
 * @param {*} id contains org id
 * @param {*} status contains boolean value of org to update. e-g true - active, false - deactive
 * @param {*} callBack contains a method to call back of successfull response
 * @returns
 */
function setActivateStatus(id, status) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ status })
    };

    return fetch(`${globalVariable.apiURL}/organizations/active-status/${id}`, requestOptions).then(handleResponse);
}

/**
 * Mail Template active status
 * @param {*} id - user_id
 * @param {*} isActive 
 */
function activeStatus(id, isActive) {
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify({isActive})
    };

    return fetch(`${globalVariable.apiURL}/mailTemplates/active-status/${id}`, requestOptions).then(handleResponse);
}