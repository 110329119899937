import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Styles
import dashboardStyle from '../../assets/jss/containers/dashboard';

// Components
import PSBUCounts from './DashboardSummary/PSBUCounts';
import ComplianceIssues from './DashboardSummary/ComplianceIssues';
import OccupancyRate from './DashboardSummary/OccupancyRate';
import ReportingStatus from './DashboardSummary/ReportingStatus';
import UsersSummary from './DashboardSummary/UsersSummary';
import UtilityAllowance from './DashboardSummary/UtilityAllowance';

// Helpers
import { getUserType, orgTypesConstants } from '../../helpers';

/**
 *DashboardSummary
 *
 * @class DashboardSummary
 * @extends {Component}
 */
class DashboardSummary extends Component {
    /**
     *Creates an instance of DashboardSummary.
     * @param {*} props
     * @memberof DashboardSummary
     */
    constructor(props) {
        super(props);
        this.state = {

        }
        this.userRole = getUserType();
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof DashboardSummary
     */
    render() {
        let { classes, reportYear, PSBUCount, complianceIssues, occupancyRate, reportStatus, utilituAllowance, match } = this.props
        const { userRole } = this;
        let isPropertyManager = userRole !== orgTypesConstants.FUNDER && userRole !== orgTypesConstants.RO_ORGANIZATION ;
        return (
            <div >
                <Grid container spacing={2} className={classes.DashboardContainer}>
                    <Grid item xs={12} sm={isPropertyManager ? 5 : 12}>
                        <PSBUCounts PSBUCounts={PSBUCount.data} isLoading={PSBUCount.isLoading} isPropertyManager ={isPropertyManager} />
                    </Grid>
                    {isPropertyManager &&
                        <Grid item xs={12} sm={7}>
                            <ComplianceIssues
                                reportYear={reportYear}
                                complianceIssues={complianceIssues}
                                handleActions={(type) => this.props.handleActions(type)} />
                        </Grid>
                    }
                </Grid>
                <Grid container spacing={2} className={classes.DashboardContainer}>
                    {isPropertyManager &&
                        <Grid item xs={12} sm={4}>
                            <OccupancyRate
                                reportYear={reportYear}
                                occupancyRate={occupancyRate}
                                match={match} />
                        </Grid>
                    }
                    <Grid item xs={12} sm={isPropertyManager ? 8 : 12}>
                        <ReportingStatus
                            reportYear={reportYear}
                            reportStatus={reportStatus} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.DashboardContainer}>
                    <Grid item xs={12} sm={isPropertyManager ? 9 : 12}>
                        <UsersSummary
                            reportYear={reportYear} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {isPropertyManager &&
                            <UtilityAllowance
                                reportYear={reportYear}
                                utilituAllowance={utilituAllowance} />
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}

// prop types
DashboardSummary.propTypes = {
    classes: PropTypes.object.isRequired,
};

/**
 * Default Props
 */
DashboardSummary.defaultProps = {
    classes: {}
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};
// export DashboardSummary
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardSummary));