import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import { SubmitIcon, UnSubmitIcon } from '../../components/SvgIcons/SvgIcons';
import ToolTip from '../../components/ToolTip/ToolTip';

// Import Redux Actions and Helper Variable
import { loanProjectActions } from '../../actions/loanProjects';
import { confActions } from '../../actions/configuration';
import { reportActivityActions } from '../../actions/reportActivity';
import { alertActions, searchActions } from '../../actions';


// Import Helpers
import { getUserRole, history, getUserId, getUserOrgId, getUserType, globalVariable, filterSearchFields } from '../../helpers';
import { reportActivityConstants } from '../../helpers/appConstants';
import TextArea from '../../components/TextArea/TextArea';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Typography } from '@material-ui/core';

// Custom Variables
let sFields = [];
let sValues = [];
// let submitData = [];


// for table county field
class TableField extends Component {

    render() {
        const { path, data } = this.props;
        const tableKey = path.split(".")[0];
        let reportLevelIndex = data[tableKey] ? data[tableKey].levelTo_id : 1;
        const tableData = data[tableKey] || {};

        // let orgsForthisYear = data.orgFRY.filter(orgD => {
        //     if (orgD.fry <= data.year &&
        //         new Date(orgD.expirationDate).getTime() > new Date(`${data.year}-06-30`).getTime()) {
        //         return true;
        //     }
        //     return false;
        // });

        // orgsForthisYear = orgsForthisYear.map(orgD => orgD.org_id);

        //if contract details are not entered
        if (data.contracts.contractNumber === "<empty>") {
            reportLevelIndex = 1
        } else {

            // if no preparer present
            if ((data.contracts.preparer.user_id === 0 || data.contracts.preparer.user_id === null) && reportLevelIndex === 1) {
                reportLevelIndex = 2;
            }
        }

        // append date of submission, if exists
        const date = tableData.date ? tableData.date : '';

        return <span>{`${date} ${reportActivityConstants[reportLevelIndex]}`}</span>;
    }
}

TableField.defaultProps = {
    data: {},
    path: ""
}

class SubmitComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSubmitComment: false,
            submitComment: ""
        };
    }

    /**
     *  Handle submit
     */
    handleSubmitAction = () => {
        const { data } = this.props;
        this.props.handleChange("submit", data._id);
    }

    /**
     *  Handle Unsubmit action
     */
    handleUnSubmitAction = () => {
        const { data } = this.props;
        this.props.handleChange("unsubmit", data._id);
    }
    /**
     *  Bind HTML to DOM
     */

    render() {
        const { data } = this.props;
        const access = data.submissionAccess;
        if (access && access.length > 0)
            return (
                <Grid container spacing={2}>
                    {access.indexOf('SUBMIT') > -1 &&
                        <div style={access.length > 1 ? { marginLeft: "-35px" } : { marginLeft: "0px" }}>
                            <Grid xs={12} sm={6}>
                                <ToolTip title="Submit" placement="bottom">
                                    <IconButton className={"reportSubmit"} color="primary" aria-label="Download" onClick={this.handleSubmitAction}>
                                        <SubmitIcon />
                                    </IconButton>
                                </ToolTip>
                            </Grid>
                        </div>
                    }
                    {access.indexOf('UNSUBMIT') > -1 &&
                        <div style={access.length > 1 ? { marginLeft: "-20px" } : { marginLeft: "0px" }}>
                            <Grid xs={12} sm={6} className="unsubmitGrid">
                                <ToolTip title="Unsubmit" placement="bottom">
                                    <IconButton className={"reportSubmit"} color="primary" aria-label="Download" onClick={this.handleUnSubmitAction}>
                                        <UnSubmitIcon />
                                    </IconButton>
                                </ToolTip>
                            </Grid>
                        </div>
                    }
                </Grid>
            );
        // })
        else return <Grid></Grid>;
    }
}


/**
 *  Default Props
 */

SubmitComponent.defaultProps = {
    handleChange: () => { },
    handleSubmitAction: () => { },
    handleUnSubmitAction: () => { },
    data: []
}

const componentMap = {
    "submissionStatus.levelTo_id": TableField,
    "submissionAccess": SubmitComponent
}

class ReportsActivityList extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            fieldFilter: false,
            openSubmitComment: false,
            submit: false,
            unSubmit: false,
            reportYearId: 0,
            data: [],
            openHelp: false,
            firstrender: false,
            commentValidator: [],
            commentError: []
        };

        this.userRef = React.createRef();

        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.userType = getUserType();
    }


    /**
 * Component Did Mount Event
 */
    UNSAFE_componentWillMount() {
        const { page } = this.props.reports;
        const { location } = this.props;
        // submitData = this.props.submitDatas
        // this.setState({ firstrender: true })
        if (page.skip) {
            sFields = page.sFields;
            sValues = page.sValues;
            this.props.getReportActivity(page.skip, globalVariable.subPagetableRowSize, page.orderBy, page.order, '', '', location.pathname.split("/")[2]);
            //this.props.getByIdCertificationForm(this.state.reportYearId)
        } else {
            sFields = [];
            sValues = [];
            this.props.getReportActivity(1, globalVariable.subPagetableRowSize, 'year', 'dsc', '', '', location.pathname.split("/")[2]);
            //this.props.getByIdCertificationForm(this.state.reportYearId)
        }
        setTimeout(() => {
            this.props.getFieldFilter('reportActivity');
        }, 100);

    }

    /**
    * Component Did Update
    */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }

    /**
     * 
     */
    componentWillUnmount() {
        // sFields = [];
        // sValues = [];
    }

    /**
     *  Handle Change
     */
    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    /**
    * Handle Table Tagination Event
    */
    onChangePageTable = (skip, limit) => {
        this.props.clearAlert()
        const { reports, location } = this.props;
        this.props.getReportActivity(skip, limit, reports.page.orderBy, reports.page.order, sFields, sValues, location.pathname.split("/")[2]);
    };

    /**
     * Handle Sort Action
     * @param {*} orderBy 
     * @param {*} order
     */
    onSortRequest = (orderBy, order) => {
        this.props.clearAlert()
        const { reports, searchData: { sFields, sValues }, location } = this.props;
        this.props.getReportActivity(reports.page.skip, reports.page.limit, orderBy, order, sFields, sValues, location.pathname.split("/")[2]);
    };

    /**
      * Handle Table Search Input Change Event
      */
    onInputChangeRequest = (searchField, searchKey) => {
        const { reports, searchData: { sFields, sValues }, location } = this.props;
        this.props.clearAlert()

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.searchActions({ sFields, sValues });
        this.props.getReportActivity(1, globalVariable.subPagetableRowSize, reports.page.orderBy, reports.page.order, sFields, sValues, location.pathname.split("/")[2]);
    };

    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        sFields = [];
        sValues = [];
        const { reports, location } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getReportActivity(reports.page.skip, reports.page.limit, reports.page.orderBy, reports.page.order, sFields, sValues, location.pathname.split("/")[2]);
    };

    /**
   * Handle Add Action
   */
    handleAddActionClick = () => {
        // history.push('/users/new');
    };

    /**
    * Handle Field Filter Action Event
    * @memberof Users
    */
    handleFieldFilterActionClick = () => {
        this.props.clearAlert()
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter("reportActivity", this.props.reports.page.fieldFilters.visibleColumns);
    };

    /**
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("reportActivity", this.props.reports.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns, key is a column name and
     * values contains boolean of checked status
     *
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("reportActivity", updatedColumns);
        } else {
            this.props.changeFieldFilter("reportActivity", [...this.props.visibleColumns, key]);
        }
    }

    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("reportActivity", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { reports, location } = this.props;
            this.props.getReportActivity(reports.page.skip, reports.page.limit, reports.page.orderBy, reports.page.order, sFields, sValues, location.pathname.split("/")[2]);
        });
    }


    /**
     * Handle Table Navigation Event
     */
    handleNavigateAction = (id, field, item) => {
        switch (field) {
            case 'year':
                const { match } = this.props;
                history.push({
                    pathname: `${match.url}/${item._id}/${item.year}`,
                    state: {
                        page: 'certificationForm',
                        reportYear_id: item._id,
                        pageData: {
                            reportYear: item.year,
                            page: this.props.page,
                            reportYear_id: item._id,
                        }
                    }
                });
                break;
            default:
                break;
        }
    }

    /**
     *  Handle Submit
     */
    handleSubmit = () => {
        const { submit, submitComment, reportYearId } = this.state
        const { reports,location } = this.props;
        
            if (submit) {
                this.props.submitLoanEdit(reportYearId, submitComment,(result)=>{
                    if(result.data){
                        this.setState({ openSubmitComment: false,submitComment:"" })
                        this.props.getReportActivity(reports.page.skip, globalVariable.subPagetableRowSize, reports.page.orderBy, reports.page.order, reports.page.sFields, reports.page.sValues, location.pathname.split("/")[2]);
                    }
                })
                
                
            } else {
                if (submitComment.length > 0) {
                this.props.unSubmitLoan(reportYearId, submitComment,(result)=>{
                    if(result.data){
                        this.setState({ openSubmitComment: false,commentValidator:[],commentError:[],submitComment:"" })
                this.props.getReportActivity(reports.page.skip, globalVariable.subPagetableRowSize, reports.page.orderBy, reports.page.order, reports.page.sFields, reports.page.sValues, location.pathname.split("/")[2]);
                    }
                })
            } else {
                return null
            }
        }

    }

    /**
    *  Handle field Change
    */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "submit":
                this.props.submitLoan(data, (alertDia) => {
                    if (alertDia.data) {
                        this.setState({ openSubmitComment: true, submitHelperText: "Submitting a Report Activity will send it forward to the level after yours. Once submitted you will no longer be able to edit this specific Report Activity page for this year; you will be able to edit any other Report Activity still under your control. Do you want to continue?", submit: true, reportYearId: data })
                    }
                });

                break;
            case "unsubmit":
                this.setState({ openSubmitComment: true, submitHelperText: "Unsubmitting a Report Activity will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Activity page for this year; you will be able to edit any other Report Activity still under your control. Do you want to continue?", submit: false, reportYearId: data, commentValidator: ['required'], commentError: ['This field is required'] })
                break;
            default:
        }
    }

    /**
     * Bind Html to DOM
     */

    render() {
        const { classes, needPadding, isSubPage, location: { pathname }, reports, searchData: { sFields, sValues }, columnsMap, visibleColumns } = this.props;
        let { pageAccess } = this.props;
        const { submitComment } = this.state
        const fieldFilters = reports.page.fieldFilters;
        //sort data by id
        // let reportsData = reports.data.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={"ALL REPORTS"}
                isSubPage={isSubPage}
                needPadding={needPadding}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
            >
                <TableComponent
                    page={reports.page.skip}
                    rowsPerPage={reports.page.limit}
                    count={reports.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    componentMap={componentMap}
                    actions={['REPORT_DOWNLOAD']}
                    links={[fieldFilters.fieldCols[1]]}
                    removedSortingRow={['Submission Status']}
                    order={reports.page.order}
                    orderBy={reports.page.orderBy}
                    data={reports.data}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    paginationVisible={true}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    // sFields={this.state.firstrender ? [] : filterSearchFields(sFields)}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    // sValues={this.state.firstrender ? [] : sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    handleDownloadAction={(item) => {
                        this.props.downloadReport(item._id, item.year);
                    }}
                    noDataErrorMessage={reports.isLoanProjectLoading ? "" : "No Records Found"}
                />
                <FieldFilter
                    open={this.state.fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                <ValidatorForm ref="form" onSubmit={this.handleSubmit}>
                    <AlertDialog
                        open={this.state.openSubmitComment}
                        title="Warning:"
                        onSave={() => {
                            this.refs.form.submit();
                        }}
                        onClose={() => { this.setState({ openSubmitComment: false, commentValidator: [], commentError: [], submitComment: '', submit: false }) }}
                    >
                        <Typography>{this.state.submitHelperText}</Typography>
                        <TextArea
                            name="submitComment"
                            label="Comments"
                            value={submitComment || ""}
                            className={`width400`}
                            handleChange={this.handleChange}
                            margin="dense"
                            disabled={false}
                            multiline={true}
                            validators={this.state.commentValidator}
                            errorMessages={this.state.commentError}
                            needCustomToolTip={true}
                            inputProps={{ maxLength: 1000 }}
                        />
                    </AlertDialog>
                </ValidatorForm>
            </ContentWrapper>
        );
    }
}

/**
 * Bind Component Property Types
 */
ReportsActivityList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};

ReportsActivityList.defaultProps = {
    projectId: null,
    copyId: null,
    needPadding: false,
    isSubPage: false,
    page: 'reports',
    title: 'All Reports'
}

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['reportActivity'] || {};
    const searchData = state.search['reportActivity'] || { sFields: [], sValues: [] };
    const pageAccess = state.pageAccess['reportActivity'] || [];
    const submitDatas = state.reportActivity.submissionData || {};
    const certification = state.reportActivity.certificationForm.data;
    const programActivity = state.reportActivity.programActivity.data;
    const financialActivity = state.reportActivity.financialActivity.data;
    const loanSave = state.reportActivity.contractActivitySave;
    const reports = state.loanProjects.reportActivity;

    return {
        reports,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        searchData,
        pageAccess,
        submitDatas,
        certification,
        programActivity,
        financialActivity,
        loanSave
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByIdCertificationForm: (reportYear_id) => dispatch(reportActivityActions.getByIdCertificationForm(reportYear_id)),
        getReportActivity: (skip, limit, orderBy, order, sField, sValue, projectId) => dispatch(loanProjectActions.getReportActivity(skip, limit, orderBy, order, sField, sValue, projectId)),
        searchActions: (data) => {
            return dispatch(searchActions.reportActivity(data));
        },
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        submitLoan: (reportYear_id, successCallBack) => dispatch(reportActivityActions.submitLoan(reportYear_id, successCallBack)),
        unSubmitLoan: (reportYear_id, comment, callback) => dispatch(reportActivityActions.unsubmitLoan(reportYear_id, comment, callback)),
        downloadReport: (reportYear_id, reportYear) => dispatch(reportActivityActions.downloadReport(reportYear_id, reportYear)),
        submitLoanEdit: (reportYear_id, comment, callback) => dispatch(reportActivityActions.submitLoanEdit(reportYear_id, comment, callback)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearAlert: () => dispatch(alertActions.clear()),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps

)(withStyles(pageStyle, { withTheme: true })(ReportsActivityList));