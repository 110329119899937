import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ValidatorForm from "../../helpers/fieldValidations";
import Button from '@material-ui/core/Button';
// Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML } from 'draft-convert';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import { Table1LogActions } from '../../actions/Table1Log';
// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import AutoCompleteMultiMUI from '../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import CustomDialog from "../../components/CustomDialog/CustomDialog";
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import HelpFloater from '../../components/HelpFloater/HelpFloater';
// Import Actions 
import { mailTemplateActions } from '../../actions/mailTemplate';
import { dashboardActions } from '../../actions/dashboard';
import { alertActions } from '../../actions/alert';

// Helpers
import { getUserRole, history } from '../../helpers';
import { templateCategoryConstants, templateUserCategoryConstants } from '../../helpers/appConstants';
import { helpActions } from '../../actions';
/**
 * XmlLogsEmail Container
 * @class XmlLogsEmail
 * @extends {Component}
 */
class XmlLogsEmail extends Component {
    /**
     * Init Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            emailid: "",
            mailcc: "",
            templateName: "",
            subject: "Table1 Import logs",
            filename: "",
            filepath: "",
            templateType: "",
            isProject: true,
            selectedUserType: [],
            selectedProject: [],
            selectedUser: [],
            bodyContent: EditorState.createEmpty(),
            signatureContent: EditorState.createEmpty(),
            footerContent: EditorState.createEmpty(),
            openHelp:false
        };

        this.userRole = getUserRole();
        this.onBodyContentChange = this.onBodyContentChange.bind(this);
        this.onSignatureContentChange = this.onSignatureContentChange.bind(this);
        this.onFooterContentChange = this.onFooterContentChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.getHelp();
    }
    /**
     * omponet Did Mount
     */
    componentDidMount() {
        const { location: { state } } = this.props;
        let item = state.pageData.item;
        this.setState({
            filename: item.filename,
            filepath: item.filepath,
        });
    }
    /**
     * Convert from HTML to String
     * @param {*} sampleMarkup 
     * @returns 
     */
    convertFromHTMLString(sampleMarkup) {
        sampleMarkup = sampleMarkup || '';
        const blocksFromHTML = convertFromHTML(sampleMarkup);
        return EditorState.createWithContent(blocksFromHTML);
    }
    /**
     * Body Content Change
     * @param {*} bodyContent 
     */
    onBodyContentChange(bodyContent) {
        this.setState({ bodyContent });
    }
    /**
     * On Signature Content Change
     * @param {*} signatureContent 
     */
    onSignatureContentChange(signatureContent) {
        this.setState({ signatureContent });
    }
    /**
     * On Footer Content Change
     * @param {*} footerContent 
     */
    onFooterContentChange(footerContent) {
        this.setState({ footerContent });
    }
    /**
     * Handle Close Action
     */
    // handleCloseAction = () => {
    //     this.props.handleCloseAction()
    // }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Change template type
     * @param {*} name 
     * @param {*} value 
     */
    handleChangeTemplateType = (name, value) => {
        if (value === "project") {
            this.props.getProjectSummary(null, '');
            this.setState({
                [name]: value,
                selectedUserType: [],
                selectedProject: [],
                selectedUser: [],
                isProject: false
            });
        } else {
            this.props.getUserList([], [], null, '');
            this.setState({
                [name]: value,
                selectedUserType: [],
                selectedProject: [],
                selectedUser: [],
                isProject: true
            });
        }
    };
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Handle Help Click Action
     */
     handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Hanlde Okay Action
     */
    _saveForm = () => {
        const { emailid, mailcc, bodyContent, subject, filename, filepath } = this.state;
        //  this.refs.form.submit();
        let bodyHtml = stateToHTML(bodyContent.getCurrentContent());
        const templateInfo = {
            emailid,
            mailcc,
            subject,
            bodyContent: bodyHtml,
            filename: filename,
            filepath, filepath

        };

        this.props.handleFormSaveActionClick(templateInfo);
        //  this.props.handleOkayAction();
        // setTimeout(() => {
        //     this.props.showAlert("Mail Sent Successfully");
        //    // history.goBack();
        // }, 2000);
        // setTimeout(() => {
        //     this.props.clearAlert();
        // }, 5000);
    }
    /**
     * Handle Project Autocomplete
     * @param {*} selectedOption 
     */
    handleProjectAutoCompolete = (selectedOption) => {
        let { selectedUserType } = this.state;
        let userType = [];
        selectedUserType.length > 0 && selectedUserType.forEach(item => {
            userType.push(item.value)
        })
        this.setState({ selectedProject: selectedOption, selectedUserType: [], selectedUser: [] });
    }
    /**
     * Handle User Type Autocomplete
     * @param {*} selectedOption 
     */
    handleUserTypeAutoCompolete = (selectedOption) => {
        let { selectedProject } = this.state;
        this.setState({ selectedUserType: selectedOption, selectedUser: [] });
        let userType = [];
        selectedOption.length > 0 && selectedOption.forEach(item => {
            userType.push(item.value)
        })
        this.props.getUserList(selectedProject, userType, null, '');
    }
    /**
     * Handle User Autocomplete
     * @param {*} selectedOption 
     * @param {*} isAllSelected 
     */
    handleUserAutoCompolete = (selectedOption, isAllSelected) => {
        let { selectedUser } = this.state;
        const { userList } = this.props;
        if (isAllSelected) {
            selectedUser = userList.data;
        } else {
            selectedUser = selectedOption;
        }
        this.setState({ selectedUser: selectedUser })
    }
    /**
     * Bind Html to DOM
     */
    /**
     * Handle Help Click Action
     */
     handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    render() {

        const { emailid, mailcc, filename,openHelp } = this.state;
        const { classes, projectList, userList,helpData } = this.props;
        let pageAccess = ['BACK','HELP']
        return (
            <ContentWrapper
                title="Send Email Notification"
                saveVisible={true}
                cancelVisible={true}
                pageAccess={pageAccess}
                // handleOkayAction={this.handleOkayAction}
                // handleCloseAction={this.handleCloseAction}
                confirmationButtonLabel={"Send"}
                closeButtonLabel={"Cancel"}
                className={"sendMailNotify"}
                handleBackActionClick={()=>history.goBack()}
                handleHelpActionClick={this.handleHelpActionClick}
            >

                <div className={classes.rootEdit} >
                    <ValidatorForm
                        name="newSendMailForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>

                                        <TextBox
                                            name="emailid"
                                            label="Email Id"
                                            value={emailid}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={false}
                                            validators={['required']}
                                            errorMessages={['Email ID is required']}
                                            maxLength={1000}
                                        />

                                    </Grid>
                                    <Grid item xs={12}>

                                        <TextBox
                                            name="mailcc"
                                            label="Cc"
                                            value={mailcc}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={false}
                                            validators={[]}
                                            errorMessages={[]}
                                            maxLength={1000}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <div className="custom-label">Message</div>
                                        <Editor
                                            editorClassName="mailTemplateEditor sendMailBody"
                                            onEditorStateChange={this.onBodyContentChange}
                                            toolbarHidden
                                            placeholder={'Body'}
                                        />
                                        <div className="cutom-editor-gap"></div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className="custom-label">Attachment</div>
                                        <div className="custom-label" style={{ color: '#36aebe',fontSize:"12px"}}>{filename}</div>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={10}>
                                        <Button variant="contained" style={{ background: '#36aebe', color: '#fff', margin: '5px' }} onClick={() => { this.refs.form.submit() }} >{"Send"}</Button>
                                        <Button variant="contained" style={{ color: '#070707', margin: '5px' }} onClick={() => { history.goBack() }}>{"cancel"}</Button>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={24} >
                            <Grid item xs={12}>
                                <Grid  style={{textAlign:"right",marginTop:"10%" }}>
                                    
                                    <Button   variant="contained" style={{ background: '#36aebe', color: '#fff', margin: '5px' }} onClick={() => { this.refs.form.submit() }} >{"Send"}</Button>
                                    <Button variant="contained" style={{ color: '#070707', margin: '5px' }} onClick={() => { history.goBack() }}>{"cancel"}</Button>
                                </Grid>
                            </Grid>'
                        </Grid>

                    </ValidatorForm>
                </div>
                {
                    openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="XML Import"
                    />
                }
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
XmlLogsEmail.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    orgId: PropTypes.string,
    isReadOly: PropTypes.bool.isRequired,
    pageAccess: PropTypes.array.isRequired
};
/**
 * Default Props
 */
XmlLogsEmail.defaultProps = {
    pageAccess: [],
    isReadOly: true
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const projectList = state.dashboard.projectSummary;
    const userList = {
        data: state.mailTemplates.usersList.data || [],
        usersCount: state.mailTemplates.usersList.usersCount || 0,
    }
    return {
        projectList,
        userList,
        helpData : state.help.data[0]
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        handleFormSaveActionClick: (templateInfo) => dispatch(Table1LogActions.sendTemplate(templateInfo)),
        showAlert: (message) => dispatch(alertActions.success(message)),
        clearAlert: () => dispatch(alertActions.clear()),
        getHelp: () =>dispatch(helpActions.getAll({_id:25}))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(XmlLogsEmail));
