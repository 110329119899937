// import Configurations from "../containers/Configurations/Configurations";
export const roleAccess = {
    superFunder: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE", "UPLOAD", "DOWNLOAD"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["ADD", "FILTER", "CLEAR", "DELETE", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        projects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        projectview: ["SAVE", "BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        siteview: ["BACK", "SAVE"],
        sitenew: ["SAVE", "BACK"],
        building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        buildingnew: ["SAVE", "BACK"],
        buildingview: ["SAVE"],
        units: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE", "UPLOAD"],
        unitsnew: ["SAVE"],
        unitview: ["SAVE"],
        configuration:["ADD", "DELETE"],
        dashboard: ["VIEW_REPORT"],
        funders: ["ADD", "CLEAR", "DELETE"],
        fundersview: [],
        UAView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        explore:['ADD'],
        exploreNew:['SAVE','BACK'],
        exploreEdit:['SAVE','BACK'],
        OccupantsView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        Compliance: ['SETTINGS'],
        ComplianceSetting:['ADD']
    },
    superFunderWithHO: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE", "UPLOAD", "DOWNLOAD"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["ADD", "FILTER", "CLEAR", "DELETE", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        projects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        projectview: ["SAVE", "BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        siteview: ["BACK", "SAVE"],
        sitenew: ["SAVE", "BACK"],
        building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        buildingnew: ["SAVE", "BACK"],
        buildingview: ["SAVE"],
        units: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE", "UPLOAD"],
        unitsnew: ["SAVE"],
        unitview: ["SAVE"],
        configuration:["ADD", "DELETE"],
        dashboard: ["VIEW_REPORT"],
        funders: ["ADD", "CLEAR", "DELETE"],
        fundersview: [],
        UAView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        explore:['ADD'],
        exploreNew:['SAVE','BACK'],
        exploreEdit:['SAVE','BACK'],
        OccupantsView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        Compliance: ['SETTINGS'],
        ComplianceSetting:['ADD'],
        loanProjects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        loanProjectsView: ["SAVE", "BACK"]
    },
    funderWithOA: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["ADD", "FILTER", "CLEAR", "DELETE", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        projects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        projectview: ["SAVE", "BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        siteview: ["BACK", "SAVE"],
        sitenew: ["BACK", "SAVE"],
        building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        buildingnew: ["SAVE", "BACK"],
        buildingview: ["SAVE"],
        units: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        unitsnew: ["SAVE"],
        unitview: ["SAVE"],
        dashboard: ["VIEW_REPORT"],
        configuration:["ADD", "DELETE"],
        funders: ["ADD", "CLEAR", "DELETE"],
        fundersview: [],
        explore:['ADD'],
        exploreNew:['BACK'],
        exploreEdit:['SAVE','BACK'],
        UAView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        Compliance: ['SETTINGS'],
        ComplianceSetting:['ADD']
    },
    funderWithOAandHO: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["ADD", "FILTER", "CLEAR", "DELETE", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        // projects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        // projectview: ["SAVE", "BACK"],
        // projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        // sites: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        // siteview: ["BACK", "SAVE"],
        // sitenew: ["BACK", "SAVE"],
        // building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        // buildingnew: ["SAVE", "BACK"],
        // buildingview: ["SAVE"],
        // units: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        // unitsnew: ["SAVE"],
        // unitview: ["SAVE"],
        dashboard: ["VIEW_REPORT"],
        configuration:["ADD", "DELETE"],
        // funders: ["ADD", "CLEAR", "DELETE"],
        // fundersview: [],
        explore:['ADD'],
        exploreNew:['BACK'],
        exploreEdit:['SAVE','BACK'],
        // UAView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        // Compliance: ['SETTINGS'],
        // ComplianceSetting:['ADD'],
        loanProjects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        loanProjectsView: ["SAVE", "BACK"]
    },
    funderWithOAandBoth: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["ADD", "FILTER", "CLEAR", "DELETE", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        projects: ["ADD", "FILTER", "CLEAR", "DELETE", "COPY", "UPLOAD", "UPDATE"],
        projectview: ["SAVE", "BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        siteview: ["BACK", "SAVE"],
        sitenew: ["BACK", "SAVE"],
        building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        buildingnew: ["SAVE", "BACK"],
        buildingview: ["SAVE"],
        units: ["ADD", "UPLOAD", "FILTER", "CLEAR", "DELETE"],
        unitsnew: ["SAVE"],
        unitview: ["SAVE"],
        dashboard: ["VIEW_REPORT"],
        configuration:["ADD", "DELETE"],
        funders: ["ADD", "CLEAR", "DELETE"],
        fundersview: [],
        explore:['ADD'],
        exploreNew:['BACK'],
        exploreEdit:['SAVE','BACK'],
        UAView: ['UPDATE', 'SAVE', 'DELETE', 'ADD', 'UPLOAD'],
        Compliance: ['SETTINGS'],
        ComplianceSetting:['ADD']
    },
    funderWithoutOA: {
        users: ["ADD", "FILTER", "CLEAR"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["BACK"],
        organizations: ["ADD", "FILTER", "CLEAR"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["BACK"],
        projects: ["ADD", "FILTER", "CLEAR", "COPY"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["ADD", "FILTER", "CLEAR", "DELETE"],
        siteview: ["SAVE", "BACK"],
        sitenew: ["SAVE", "BACK"],
        building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        buildingnew: ["SAVE", "BACK"],
        buildingview: ["SAVE"],
        units: ["ADD", "FILTER", "CLEAR", "DELETE"],
        configuration:["ADD", "DELETE"],
        unitsnew: ["SAVE"],
        unitview: ["SAVE"],
        dashboard: ["VIEW_REPORT"],
        funders: ["ADD", "CLEAR", "DELETE"],
        explore:['ADD'],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        ComplianceSetting:['ADD'],
        exploreEdit:['SAVE','BACK'],
    },
    funderWithoutOAandBoth: {
        users: ["ADD", "FILTER", "CLEAR"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["BACK"],
        organizations: ["ADD", "FILTER", "CLEAR"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["BACK"],
        projects: ["ADD", "FILTER", "CLEAR", "COPY"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["ADD", "FILTER", "CLEAR", "DELETE"],
        siteview: ["SAVE", "BACK"],
        sitenew: ["SAVE", "BACK"],
        building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        buildingnew: ["SAVE", "BACK"],
        buildingview: ["SAVE"],
        units: ["ADD", "FILTER", "CLEAR", "DELETE"],
        configuration:["ADD", "DELETE"],
        unitsnew: ["SAVE"],
        unitview: ["SAVE"],
        dashboard: ["VIEW_REPORT"],
        funders: ["ADD", "CLEAR", "DELETE"],
        explore:['ADD'],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        ComplianceSetting:['ADD'],
        exploreEdit:['SAVE','BACK'],
    },
    funderWithoutOAandHO: {
        users: ["ADD", "FILTER", "CLEAR"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["BACK"],
        organizations: ["ADD", "FILTER", "CLEAR"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["BACK"],
        // projects: ["ADD", "FILTER", "CLEAR", "COPY"],
        // projectview: ["BACK"],
        // projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        // sites: ["ADD", "FILTER", "CLEAR", "DELETE"],
        // siteview: ["SAVE", "BACK"],
        // sitenew: ["SAVE", "BACK"],
        // building: ["ADD", "FILTER", "CLEAR", "DELETE"],
        // buildingnew: ["SAVE", "BACK"],
        // buildingview: ["SAVE"],
        // units: ["ADD", "FILTER", "CLEAR", "DELETE"],
        configuration:["ADD", "DELETE"],
        // unitsnew: ["SAVE"],
        // unitview: ["SAVE"],
        dashboard: ["VIEW_REPORT"],
        // funders: ["ADD", "CLEAR", "DELETE"],
        explore:['ADD'],
        // fundersview: [],
        // UAView: [],
        // Compliance: ['SETTINGS'],
        // ComplianceSetting:['ADD'],
        exploreEdit:['SAVE','BACK'],
    },
    funderWithoutOAandROU: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    readOnlyFunderWithOA: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["FILTER", "CLEAR","UPDATE"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    readOnlyFunderWithOAandHO: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["FILTER", "CLEAR","UPDATE"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        // projects: ["FILTER", "CLEAR"],
        // projectview: ["BACK"],
        // projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        // sites: ["FILTER", "CLEAR"],
        // siteview: ["BACK"],
        // sitenew: ["BACK"],
        // building: ["FILTER", "CLEAR"],
        // buildingnew: ["BACK"],
        // buildingview: [],
        // units: ["FILTER", "CLEAR"],
        // unitview: [],
        // unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        // funders: ["CLEAR"],
        // fundersview: [],
        // UAView: [],
        // Compliance: ['SETTINGS'],
        explore:['ADD'],
        // ComplianceSetting:['ADD']
    },
    readOnlyFunderWithOAandBoth: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["FILTER", "CLEAR","UPDATE"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    readOnlyFunderWithoutOA: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    readOnlyFunderWithoutOAandHO: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        // projects: ["FILTER", "CLEAR"],
        // projectview: ["BACK"],
        // projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        // sites: ["FILTER", "CLEAR"],
        // siteview: ["BACK"],
        // sitenew: ["BACK"],
        // building: ["FILTER", "CLEAR"],
        // buildingnew: ["BACK"],
        // buildingview: [],
        // units: ["FILTER", "CLEAR"],
        // unitview: [],
        // unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        // funders: ["CLEAR"],
        // fundersview: [],
        // UAView: [],
        // Compliance: ['SETTINGS'],
        explore:['ADD'],
        // ComplianceSetting:['ADD']
    },
    readOnlyFunderWithoutOAandBoth: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    nonFunderWithOA: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["FILTER", "CLEAR", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    nonFunderWithOAandHO: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["FILTER", "CLEAR", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        // projects: ["FILTER", "CLEAR"],
        // projectview: ["BACK"],
        // projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        // sites: ["FILTER", "CLEAR"],
        // siteview: ["BACK"],
        // sitenew: ["BACK"],
        // building: ["FILTER", "CLEAR"],
        // buildingnew: ["BACK"],
        // buildingview: [],
        // units: ["FILTER", "CLEAR"],
        // unitview: [],
        // unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        // funders: ["CLEAR"],
        // fundersview: [],
        // UAView: [],
        // Compliance: ['SETTINGS'],
        explore:['ADD'],
        // ComplianceSetting:['ADD']
    },
    nonFunderWithOAandBoth: {
        users: ["ADD", "FILTER", "CLEAR", "DELETE"],
        usersNew: ["SAVE", "BACK"],
        usersView: ["SAVE", "BACK"],
        organizations: ["FILTER", "CLEAR", "UPDATE"],
        orgsNew: ["SAVE", "BACK"],
        orgsView: ["SAVE", "BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    nonFunderWithoutOA: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    nonFunderWithoutOAandHO: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        // projects: ["FILTER", "CLEAR"],
        // projectview: ["BACK"],
        // projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        // sites: ["FILTER", "CLEAR"],
        // siteview: ["BACK"],
        // sitenew: ["BACK"],
        // building: ["FILTER", "CLEAR"],
        // buildingnew: ["BACK"],
        // buildingview: [],
        // units: ["FILTER", "CLEAR"],
        // unitview: [],
        // unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        // funders: ["CLEAR"],
        // fundersview: [],
        // UAView: [],
        // Compliance: ['SETTINGS'],
        explore:['ADD'],
        // ComplianceSetting:['ADD']
    },
    nonFunderWithoutOAandBoth: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    },
    onsite: {
        users: ["FILTER", "CLEAR"],
        usersNew: ["BACK"],
        usersView: ["BACK"],
        organizations: ["FILTER", "CLEAR"],
        orgsNew: ["BACK"],
        orgsView: ["BACK"],
        projects: ["FILTER", "CLEAR"],
        projectview: ["BACK"],
        projects_reportTab: ["CLEAR", "REPORT_DOWNLOAD"],
        sites: ["FILTER", "CLEAR"],
        siteview: ["BACK"],
        sitenew: ["BACK"],
        building: ["FILTER", "CLEAR"],
        buildingnew: ["BACK"],
        buildingview: [],
        units: ["FILTER", "CLEAR"],
        unitview: [],
        unitsnew: [],
        dashboard: ["VIEW_REPORT"],
        funders: ["CLEAR"],
        fundersview: [],
        UAView: [],
        Compliance: ['SETTINGS'],
        explore:['ADD'],
        ComplianceSetting:['ADD']
    }
}