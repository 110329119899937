import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Components
import BuildingsBulkUpload from './BuildingsBulkUpload';
import UnitsBulkUpload from './UnitsBulkUpload';

/**
 * Bulk upload Router Container
 * @class BulkUploadRouter
 * @extends {React.Component}
 */
class BulkUploadRouter extends Component {
    /**
    * Bind Html to DOM 
    * @memberof BulkUploadRouter
    */
    render() {
        const { match } = this.props;
        return (
            <Switch >
                <Route exact path={`${match.url}/buildings`} render={(props) => {
                    return <BuildingsBulkUpload {...props} type={match.params.type} title={`Buildings Bulk Update`} />
                }} />
                <Route exact path={`${match.url}/units`} render={(props) => {
                    return <UnitsBulkUpload {...props} type={match.params.type} title={`Units Bulk Update`} />
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default BulkUploadRouter;
