import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Route } from 'react-router-dom';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Tab components
import General from './tabs/General';
import Contracts from './tabs/Contracts'
import Reports from './tabs/Reports';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
import './index.css'

// Import Helpers
import { history, getUserRole, getUserId, getUserOrgId } from '../../helpers';
import { getTitle } from '../../helpers/setTitle';
import { loanProjectActions } from '../../actions/loanProjects'
import { helpActions } from '../../actions';

// import container
import LoanProjectFundersRouter from '../LoanProjectFunders/LoanFunders.Router'

class LoanProjectsView extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            openHelp: false,
            helpTitle: '',
            selectedTable: ''
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.reportActivityRef = React.createRef();
    }

    /**
     * Component Will Mount
     * @param {*} event 
     */
    UNSAFE_componentWillMount = () => {
        if (this.props.location.state) {
            this.handleReportTable(this.props.location.state.page)
        } else {
            let pathNames = this.props.location.pathname.split('/')
            let latestPathName = pathNames[pathNames.length - 1]
            this.handleReportTable(latestPathName)
        }
    }


    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
    };

    /**
     * Handle Form Save Action Click
     * @param {*} id
     * @param {*} formData
     */
    handleFormSaveActionClick = (id, formData) => {
        if (formData) {
            this.props.updateProject(id, formData);
        }
    };

    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
     * Component Did Mount
     * @param {C} Event
     */
    componentDidMount() {
        const { loanProjectId } = this.props;
        if (loanProjectId) {
            this.props.getById(loanProjectId);
        }
    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
     * Handle Report table select
     */
    handleReportTable = (table) => {
        switch (table) {
            case 'financialActivity':
                this.props.getHelp(23);
                this.setState({ helpTitle: 'Financial Activity', selectedTable: 'financialActivity' })
                break;
            case 'programActivity':
                this.props.getHelp(22);
                this.setState({ helpTitle: 'Program Activity', selectedTable: 'programActivity' })
                break;
            case 'contractActivityList':
                this.props.getHelp(21);
                this.setState({ helpTitle: 'Contract Activity', selectedTable: 'contractActivityList' })
                break;
            default:
                break;
        }
    }
    /**
    * Handle Tab Change Event
    */
    handleTabChange = (event, value) => {
        const { location: { state } } = this.props;
        let isFormNotSaved = false;
        if (state && state.page && (state.page === "programActivity" || state.page === "financialActivity" || state.page === "contractActivityView" || state.page === 'contractActivityNew' || state.page === "certificationForm")) {
            isFormNotSaved = this.reportActivityRef.current.handleTabChange();
        }
        if (!isFormNotSaved) {
            this.handleTabNavigation(value)
        }
    };
    /**
     * Handle Tab Change Event
     */
    handleTabNavigation = (value) => {
        const { match, loanProjectId } = this.props;
        switch (value) {
            case 0:
                history.push({
                    pathname: `${match.url}/${loanProjectId}`,
                    state: {
                        page: 'loanProjectEdit',
                        pageData: {
                            project_id: loanProjectId
                        }
                    }
                });
                break;
            case 1:
                this.props.checkFunderAvailability(loanProjectId)
                break;
            case 2:
                history.push({
                    pathname: `${match.url}/${loanProjectId}/contracts`,
                    state: {
                        page: 'contractsList',
                        pageData: {
                            project_id: loanProjectId
                        }
                    }
                });
                break;
            default:
                history.push({
                    pathname: `${match.url}/${loanProjectId}/reports`,
                    state: {
                        page: 'reportActivity',
                        pageData: {
                            project_id: loanProjectId
                        }
                    }
                });
                break;
        }
    };

    /**
     * Bind Html to DOM
     */
    render() {
        let { classes, match, loading, loanProjectId, location: { pathname },
            pageAccess, needPadding, pageTitle, helpData } = this.props;
        const { openHelp, selectedTable, helpTitle } = this.state
        match.url = match.url.replace(/\/$/, "");
        const currentTab = pathname.includes('/funders') ? 1 : (pathname.includes('/contracts') ? 2 : (pathname.includes('/reports') ? 3 : 0))

        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        if (this.props.location.state) {
            if (this.props.location.state.page !== selectedTable) {
                this.handleReportTable(this.props.location.state.page);
            }
        }
        let pathNames = this.props.location.pathname.split('/')
        let latestPathName = pathNames[pathNames.length - 1]
        if (latestPathName === 'financialActivity' || latestPathName === 'programActivity' || latestPathName === 'contractActivity') {
            pageAccess = ['BACK', 'HELP']
        } else {
            pageAccess = ['BACK']
        }
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                subTitle={pageTitle.subTitle}
                title={pageTitle.title === undefined ? "loan projects" : pageTitle.title}
                handleAddActionClick={this.handleAddActionClick}
                handleBackActionClick={this.handleBackActionClick}
                needPadding={needPadding}
                handleSaveActionClick={this.handleSaveActionClick}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
            >

                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="GENERAL" value={0} />
                        <Tab label="FUNDERS" value={1} />
                        <Tab label="CONTRACTS" value={2} />
                        <Tab label="REPORTS" value={3} />
                    </Tabs>

                </AppBar>
                {loading &&
                    <LinearProgress className='tabLoader' />
                }
                <TabContainer>
                    <Route
                        exact
                        path={`${match.url}/:project_id`}
                        render={(props) => {
                            return (
                                <General
                                    pageAccess={pageAccess}
                                    isReadOly={isReadOly}
                                    projectId={loanProjectId}
                                    innerRef={(node) => { this.projectRef = node; }}
                                    handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                />
                            )
                        }}
                    />
                    <Route
                        path={`${match.url}/${loanProjectId}/funders`}
                        render={(props) => {
                            return (
                                <LoanProjectFundersRouter
                                    {...props}
                                    isSubPage={true}
                                    projectId={loanProjectId}
                                    currentTab={"loanProjects"}
                                />
                            )
                        }}
                    />
                    <Route
                        path={`${match.url}/${loanProjectId}/contracts`}
                        render={(props) => {
                            return (
                                <Contracts
                                    {...props}
                                    isSubPage={true}
                                    projectId={loanProjectId}
                                />
                            )
                        }}
                    />
                    <Route
                        path={`${match.url}/${loanProjectId}/reports`}
                        render={(props) => {
                            return (
                                <Reports
                                    {...props}
                                    isSubPage={true}
                                    projectId={loanProjectId}
                                    innerRef={this.reportActivityRef}
                                />
                            )
                        }}
                    />
                </TabContainer>
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title={helpTitle}
                />}
            </ContentWrapper>
        );
    }
}

/**
 * Bind Component Property Types
 */
LoanProjectsView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    needPadding: PropTypes.bool,
    loading: PropTypes.bool
};

LoanProjectsView.defaultProps = {
    isSubPage: false,
    pageAccess: [],
    title: '',
    needPadding: false,
    loading: false
}

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    const { pageAccess, loanProjects } = state;
    const { location: { state: locState } } = ownProps;
    const statePageTitle = locState || {};
    const pageTitle = getTitle(history.location.pathname, state) === undefined ? statePageTitle.pageTitle || {} : getTitle(history.location.pathname, state);
    const helpData = state.help.data[0]
    return {
        details: loanProjects.getOne,
        pageAccess: pageAccess['loanProjectEdit'] || [],
        loading: state.projects.isLoading,
        pageTitle,
        helpData
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (id, formData) => dispatch(loanProjectActions.update(id, formData)),
        getById: (id) => dispatch(loanProjectActions.getById(id)),
        getHelp: (id) => dispatch(helpActions.getAll({ _id: id })),
        checkFunderAvailability: (id) => dispatch(loanProjectActions.checkLoanProjectFunder(id))
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(LoanProjectsView));
