import { history } from '../../../helpers';

const required = {
    validators: ['required'],
    errorMessages: ['This field is required']
}

export const occupantRequiredFields = {
    houseHoldName: (occupantData) => {
        return required;
    },
    firstName: (occupantData) =>{
        return required
    },
    lastName: (occupantData) =>{
        return required
    },
    moveInDate: (occupantData) => {
        const { moveInIsReadOnly } = occupantData;
        if (moveInIsReadOnly) {
            return {
                validators: ['required'],
                errorMessages: [
                    'This field is required'
                ]
            };
        } else {
            return {
                validators: ['required', 'isCurrentReportYear'],
                errorMessages: [
                    'This field is required',
                    'Date should be in current report year.'
                ]
            };
        }
    },
    moveOutDate: (occupantData) => {
        const { moveOutDate } = occupantData;
        if (moveOutDate === null)
            return {}
        else {
            return {
                validators: ['isValidMoveOutYear', 'isLessThanMoveIn'],
                errorMessages: [
                    'Date should be in current report year.',
                    'Date should be greater than Move-In Date.'
                ]
            };
        }

    },
    recentCertDate: (occupantData) => {
        const { unitDesignation_id, moveOutDate } = occupantData;
        if (unitDesignation_id === 1) {
            if (moveOutDate === "" || moveOutDate === null) {
                return {
                    validators: ['required', 'isCurrentYear'],
                    errorMessages: [
                        'This field is required',
                        'Certification Date must be in or before the year being reported on.',
                        // 'The current certification date is more than a year old. It should be updated before submission', 'Difference between Certification Dates must be less than 1 year.'
                    ]
                };
            }
        }
        return {};
    },
    moveInRestrictedUnitPercentage: (occupantData = {}) => {
        const { unitDesignation_id, curentReportYearData, moveInYear, canChangeMoveInDate } = occupantData;
        const currentYear = curentReportYearData ? curentReportYearData.year : null;
        if ((unitDesignation_id === 1 || moveInYear === currentYear) && canChangeMoveInDate) {
            return required;
        }
        return {};
    },
    moveInMaximumAllowableIncome: (occupantData = {}) => {
        const { unitDesignation_id, curentReportYearData, moveInYear } = occupantData;
        const currentYear = curentReportYearData ? curentReportYearData.year : null;
        if (unitDesignation_id === 1 && moveInYear === currentYear) {
            return required;
        }
        return {};
    },
    moveInHouseholdSize: (occupantData = {}) => {
        const { unitDesignation_id, householdSize_MoveIn } = occupantData;
        if (unitDesignation_id === 1) {
            return {
                validators: ['required', 'gtZero', 'lessThan15'],
                errorMessages: ['This field is required', 'Enter the value greater than 0', 'Value should be between 1 to 15']
            };
        } else if (householdSize_MoveIn !== null || householdSize_MoveIn !== "") {
            return {
                // validators: ['gtZero'],
                // errorMessages: ['Enter the value greater than 0']
                validators: ['lessThan15'],
                errorMessages: ['Value should be between 1 to 15']
            };
        } else {
            return {
                validators: ['lessThan15'],
                errorMessages: ['Value should be between 1 to 15']
            };
        }
    },
    houseHoldholdSize: (occupantData = {}) => {
        const { unitDesignation_id, householdSize, householdSize_MoveIn ,canChangeMoveInDate} = occupantData;
        if (unitDesignation_id === 1) {
            //'notEqualMoveInHouseHold'
            // 'The current household size and move in household size do not match'

            const validators = ['required', 'gtZero', 'lessThan15']
            const ErrorMessages = ['This field is required', 'Enter the value greater than 0', 'Value should be between 1 to 15']

            if (householdSize != householdSize_MoveIn && householdSize != "" && householdSize != null && ( history.location.pathname.includes('new') || canChangeMoveInDate)) {
                validators.push('notEqualMoveInHouseHold')
                ErrorMessages.push('The value of MoveInHouseholdSize and HouseholdSize must match.')
            }
            
            return {
                validators: validators,
                errorMessages: ErrorMessages
            };
        } else if (householdSize !== null && householdSize !== "") {

            const validators = ['lessThan15']
            const ErrorMessages = ['Value should be between 1 to 15']

            if (householdSize != householdSize_MoveIn && householdSize != "" && ( history.location.pathname.includes('new') || canChangeMoveInDate)) {
                validators.push('notEqualMoveInHouseHold')
                ErrorMessages.push('The value of MoveInHouseholdSize and HouseholdSize must match.')
            }

            return {
                // validators: ['gtZero'],
                // errorMessages: ['Enter the value greater than 0']
                validators: validators,
                errorMessages: ErrorMessages
            };
        } else {
            return {
                validators: ['lessThan15'], 
                errorMessages: ['Value should be between 1 to 15']
            };
        }
    },
    residentPayment: (occupantData = {}) => {
        const { unitDesignation_id } = occupantData;
        if (unitDesignation_id === 1) {
            return required;
        }
        return {};
    },
    utilityAllowanceSource: (occupantData = {}) => {
        const { unitDesignation_id, bedRoom, UASources, isDisabledUASelected } = occupantData;
        const errMsg = `No Utility Allowances found for this Building and this Unit which has a Bedroom Type of ${bedRoom}. Please configure Utility Allowances for this Building and Bedroom Type on this Site Page`;
        if (unitDesignation_id === 1) {
            const erm = {
                validators: ['required', 'isNO_UA'],
                errorMessages: [UASources.length > 0 ? 'This field is required' : errMsg, errMsg]
            }

            if (isDisabledUASelected) {
                // erm.validators.push('isDisabledUASelected');
                // erm.errorMessages.push('HOME/NHTF Projects cannot use Section 8 Utility Allowances. Please choose another UA Source')
            }

            return {
                ...erm
            };
        }
        return {};
    },

    householdStudentExceptionType: (occupantData = {}) => {
        //check funder project
        let checkFunderProject = occupantData.isWSHFC;
        let { table1HouseholdMemberDC,orginalMemDC } = occupantData;
        orginalMemDC = orginalMemDC || [];
        const isFTStudentYes = orginalMemDC.filter(memberInfo => memberInfo.fullTimeStudentType_id === 1);
        
        if(checkFunderProject){
            if (isFTStudentYes.length === orginalMemDC.length) {
                return {
                    validators: ['required'],
                    errorMessages: ['This field is required']
                };
            }
        }

        return {};
    },
    householdSizeError: (occupantData = {}) => {
        return {
            validators: ['isHouseholdInfoLess', 'isHouseholdInfoHigh'],
            errorMessages: ['The number of people listed in the "Household Member Info" section is lesser than the "Household Size". Please add members or descrease Household Size.', 'The number of people listed in the "Household Member Info" section is greater than the "Household Size". Please remove members or increase Household Size.']
        };
    },
    restrictedUnitPercentage: (occupantData = {}) => {
        const { unitDesignation_id } = occupantData;
        if (unitDesignation_id === 1) {
            return required;
        }
        return {};
    },
    moveInAnnualGrossIncome: (occupantData = {}) => {
        const { unitDesignation_id, currentYear, moveInYear, moveInAnnualGrossIncome } = occupantData;
        if (unitDesignation_id === 1 && moveInYear === currentYear) {
            return {
                validators: ['required', 'lessThan10Lak'],
                errorMessages: ['This field is required', 'Value should be between 0 to 1000,000']
            };
        } else if (moveInAnnualGrossIncome !== null || moveInAnnualGrossIncome !== "") {
            return {
                // validators: ['gtZero'],
                // errorMessages: ['Enter the value greater than 0']
                validators: ['lessThan10Lak'],
                errorMessages: ['Value should be between 0 to 1000,000']
            };
        }
        else {
            return {
                validators: ['lessThan10Lak'],
                errorMessages: ['Value should be between 0 to 1000,000']
            };
        }
    },
    currentAnnualGrossIncome: (occupantData = {}) => {
        const { unitDesignation_id, annualGrossIncome } = occupantData;
        if (unitDesignation_id === 1) {
            return {
                validators: ['required', 'lessThan10Lak'],
                errorMessages: ['This field is required', 'Value should be between 0 to 1000,000']
            };
        } else if (annualGrossIncome !== null || annualGrossIncome !== "") {
            return {
                // validators: ['gtZero'],
                // errorMessages: ['Enter the value greater than 0']
                validators: ['lessThan10Lak'],
                errorMessages: ['Value should be between 0 to 1000,000']
            };
        }
        else {
            return {
                validators: ['lessThan10Lak'],
                errorMessages: ['Value should be between 0 to 1000,000']
            };
        }
    },
    subsidyType: (occupantData = {}) => {
        return {};
    },
    subsidyAmount: (occupantData = {}) => {
        const { subsidyType_id } = occupantData;
        if (subsidyType_id === undefined || subsidyType_id === null) {
            return {};
        }
        return required;
    },
    homeField: (occupantData = {}) => {
        const { unitType } = occupantData;
        if (unitType === 1) {
            return required;
        }
        return {};
    },
    isSmallUnit: (occupantData = {}) => {
        const { unitType, isWSHFC, specialNeedOption_id } = occupantData;
        if (unitType === 1 && isWSHFC) {
            if (specialNeedOption_id === 2) {
                return {
                    validators: ['required'],
                    errorMessages: ['This field is required']
                }
            }
            // else {
            //     return {
            //         validators: ['required'],
            //         errorMessages: ['This field is required']
            //     };
            // }
        }
        return {};
    },
    isCommentRequired: (occupantData = {}) => {
        const { isHigherRUP, isHigherRUPMoveIn } = occupantData;
        if (isHigherRUP || isHigherRUPMoveIn) {
            return {
                validators: ['required'],
                errorMessages: ['Enter the reason for the higher set-aside in the Comment field']
            };
        }
        return {};
    },
}

// for tab color use in occupants page
export const titleErrorMaptoTabs = {
    "Currentcertificationdatemorethan12monthsold": 0,
    "AnnualIncomeExceedsHOME": 3,
    "IncomeBelowMaxIncome": 3,
    "HouseholdTooSmallForLargeHouseholdCommitment": 0,
    "UnitTooSmallForLargeHouseholdCommitment": 0,
    "HOMEUnitsLessThanRequired": 0,
    "ValidateHouseholdIncome": 3,
    "ReasonableRecertDate": 0,
    "IncomeBelowConfigPercentMax": 3,
    "Missingcurrentcertificationdate": 3,
    "ResidentPaymentBelowMaxRent": 3,
    "RecertsWithinTwelveMonths": 0,
    "HouseHoldSizeRequired": 0,
    "MoveInIncomeRequired": 3,
    "SubsidyTypeRequireAmount": 3,
    "RUPHigherThanFunderSetAside": 3
}

// error colors
export const tabErrorColors = {
    0: 'validationGreenBorder',
    1: 'validationYellowBorder',
    2: 'validationYellowBorder',
    3: 'validationRedBorder',
    4: 'validationRedBorder'
}

export const DEFAULT_MEMBERINFO = {
    "_id": 0,
    "lastName": "",
    "firstName": "",
    "middleInitial": "",
    "familyRelationshipType_id": null,
    "dateOfBirth": "1/1/1999",
    "fullTimeStudentType_id": 3,
    "ssnLast4": "9999",
    "householdMemberEthnicityType_id": null,
    "disabilityType_id": null,
    "employmentOrWages": "",
    "socSecPensions": "",
    "publicAssistance": "",
    "otherIncome": "",
    "race_id": null,
    "householdMemberRaceType_id": null
}
