import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import _ from 'underscore';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem'; 

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Redux Actions
import { projectsActions } from '../../actions/projects';
import { userActions } from '../../actions/user';
import { confActions } from '../../actions/configuration';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';



// Helpers
import { getUserOrgId, getUserRole, globalVariable, history, formatDate, filterSearchFields } from '../../helpers';

// Import Components
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import TableComponent from '../../components/Table/TableComponent';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from "../../components/HelpFloater/HelpFloater";

/**
 * CountyField Container
 * @class CountyField
 * @extends {Component}
 */
class CountyField extends Component {
    render() {
        const { data } = this.props;
        const { sites_id } = data;
        const county = sites_id.length > 0 ? sites_id[0].county : '';
        return county ? county : '';
    }
}
/** Default props */
CountyField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}
/**
 * FundersField Container
 * @class FundersField
 * @extends {Component}
 */
class FundersField extends Component {
    render() {
        const { data } = this.props;
        let { funders, funderIds, fundersName } = data;
        let fundersData = [];
        funders.forEach(item => {
            if (fundersName.indexOf(item.name) > -1)
                fundersData.push(item)
        });
        const links = fundersData.map((fn, index) => {
            return (
                <Button
                    key={index}
                    size="small"
                    className={'linkCell validation_color text-button'}
                    onClick={() => {
                        const funderId = funderIds.filter(fkey => { return fkey.org_id === fn._id });
                        if (funderId[0]) {
                            this.props.handleChange("funders", { ...data, funderKey: { ...funderId[0], name: fn.name } })
                        }
                    }}
                >
                    {fn.name}
                </Button>
            )
        })
        return links;
    }
}
/** Default props */
FundersField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

const componentMap = {
    "sites_id.county": CountyField,
    "fundersName": FundersField
}

/**
 * ProjectsList Container
 * @class ProjectsList
 * @extends {Component}
 */
class ProjectsList extends Component {
    /**
     * Cinstructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            anchorEl: null,
            deleteProj: false,
            deleteItemId: null,
            openHelp: false,
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }

    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.getHelp();
        if (page.skip) {
            this.props.getProjects(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, false, userId);
        } else {
            this.props.page === 'projects' ?
                this.props.getProjects(1, globalVariable.tableRowSize, 'name', 'asc', '', '', false, userId) :
                this.props.getProjects(1, globalVariable.subPageTabtableRowSize, 'name', 'asc', '', '', false, userId)
        }
        setTimeout(() => {
            userId ? this.props.getFieldFilter('associatedProjects') : this.props.getFieldFilter('projects');
        }, 100);
    }

    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Component Will Unmount
     * 
     * [1]  -   clear search of assoc tab
     */
    componentWillUnmount() {
        // [1]
        if (this.props.userId) {
            this.props.searchActions({ sFields: [], sValues: [] });
        }
    }
    /**
     * Handle Table Pagination On Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        this.props.getProjects(skip, limit, page.orderBy, page.order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Sort Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        this.props.getProjects(page.skip, page.limit, orderBy, order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Search Input Box Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { projects, userId, searchData } = this.props;
        const { page } = projects;
        let { sFields, sValues } = searchData;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getProjects(1, globalVariable.tableRowSize, page.orderBy, page.order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        console.log('****OnClick')
        // const { match } = this.props;
        const { owners } = item;
        switch (field) {
            case "name":
                history.push({
                    pathname: `/projects/${id}`,
                    state: {
                        page: 'projectEdit',
                        pageData: {
                            project_id: id
                        }
                    }
                });
                break;
            case "monitoring":
                if (item.monitoring === 'Claim') {
                    history.push(`/projects/${item._id}/funders/new`);
                } else if (item.monitoring === 'Expired') {
                    const funder_id = item.isExpired[0]._id;
                    history.push({
                        pathname: `/projects/${item._id}/funders/${funder_id}`,
                        state: {
                            page: 'funderEdit',
                            pageData: {
                                funder_id: funder_id
                            }
                        }
                    });
                } else if (item.monitoring === 'Pending' && item.isPending.length > 0) {
                    history.push(`/projects/${item._id}/funders/new`);
                    // const funder_id = item.isPending[0]._id;
                    // history.push({
                    //     pathname: `/projects/${item._id}/funders/${funder_id}`,
                    //     state: {
                    //         page: 'funderEdit',
                    //         pageData: {
                    //             funder_id: funder_id
                    //         }
                    //     }
                    // });
                } else if (item.isMonitoring.length > 0 && item.monitoring === 'Monitoring') {
                    const funder_id = item.isMonitoring[0]._id;
                    history.push({
                        pathname: `/projects/${item._id}/funders/${funder_id}`,
                        state: {
                            page: 'funderEdit',
                            pageData: {
                                funder_id: funder_id
                            }
                        }
                    });
                }
                break;
            case "ownerName":
                const ownerId = owners || { user_id: 0 };
                if (ownerId.user_id) {
                    history.push({
                        pathname: `/users/${ownerId.user_id}`,
                        state: {
                            page: 'userEdit',
                            pageData: {
                                user_id: parseInt(ownerId.user_id, 10)
                            }
                        }
                    });
                }
                break;
            case "owners.org_id.name":
                const ownerOrgId = owners ? owners.org_id ? owners.org_id : 0 : 0;
                if (ownerOrgId) {
                    history.push({
                        pathname: '/organizations/' + ownerOrgId,
                        state: {
                            page: 'organizationEdit',
                            pageData: {
                                org_id: parseInt(ownerOrgId, 10)
                            }
                        }
                    });
                }
                break;
            default: break;
        }
    }
    /**
     * Handle Add Action
     */
    handleAddActionClick = () => {
        history.push({
            pathname: '/projects/new',
            state: {
                page: 'projectNew',
                pageData: {}
            }
        });
    };
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getProjects(page.skip, page.limit, page.orderBy, page.order, [], [], false, userId);
    };

    /**
     * Handle Field Filter Action Click
     * 
     * [1]  -   update visible columns
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter(userId ? "associatedProjects" : "projects", page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Close Action Click
     * 
     * [1]  -   reset to current table column values if user not save the changes
     * [2]  -   close the popup
     */
    handleFieldFilterOnClose = () => {
        // [1]
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter(userId ? "associatedProjects" : "projects", page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        const { userId } = this.props;
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(userId ? "associatedProjects" : "projects", updatedColumns);
        } else {
            this.props.changeFieldFilter(userId ? "associatedProjects" : "projects", [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Fieldfilter om Save
     * 
     * [2]  -   on api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        const { userId } = this.props;
        this.props.updateFieldFilter(userId ? "associatedProjects" : "projects", this.props.visibleColumns, () => {
            // [1]
            this.setState({ fieldFilter: false });
            const { projects, userId, searchData: { sFields, sValues } } = this.props;
            const { page } = projects;
            this.props.getProjects(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
        });
    }
    /**
     *  Handle Table Delete Action
     * @param {*} id 
     * 
     * [1]  -   show pop up
     * [2]  -   for projects with zero sites error msg
     */
    handleTableDeleteAction = (id) => {
        // [1]
        const { projects, userId, searchData } = this.props;
        const { page } = projects;
        let { sFields, sValues } = searchData;
        this.setState({ deleteProj: true, deleteItemId: id });
        // [2]
        projects.data.forEach((item, index) => {
            if (id === item._id) {
                this.noOfSites = item.sites;
            }
        })
        if (this.noOfSites === '0') {
            this.props.deleteProject(id)
            this.setState({ deleteProj: false })
            this.props.getProjects(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, sFields, sValues, false, userId);

        }
    };
    /**
     * A method to call when copy icon clicked, copy the project details to new project
     * @param {*} id 
     */
    handleTableCopyAction = (id) => {
        history.push({
            pathname: `/projects/new/${id}`,
            state: {
                page: 'projectNew',
                pageData: {}
            }
        });
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "funders":
                const { _id, name: funderName, org_id, modifiedBy, updatedAt } = data.funderKey;
                if (_id) {
                    history.push({
                        pathname: `/projects/${data._id}/funders/${_id}`,
                        state: {
                            page: 'funderEdit',
                            pageData: {
                                funder_id: _id,
                                orgId: org_id
                            },
                            pageTitle: {
                                // title: '',
                                title: `${funderName} | ${data.name}`,
                                subTitle: modifiedBy ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>` : ''
                                // subTitle: ''
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Upload Click Action
     */
   

    handleTable1ImportActionClick = () => {
        history.push({
            pathname: `/table1-import-log`,
            state: {
                page: 'table1LogList',
                pageData: {}
            }
        });
    }

    /**
    * For merging projects
    */
    handleMergeActionClick = () => {

        history.push({
            pathname: '/merge',
            state: {
                page: 'mergeProjects',
                pageData: {}
            }
        })
    }
    /**
     * To handle bulk upload navigation
     * @param {*} page 
     */
    handleBulkUploadNavigation = (page) => {
        history.push({
            pathname: `/bulk-upload/${page}`,
            state: {
                page: 'bulkUpload',
                pageData: {}
            }
        })
    }
    deleteProject=()=>{
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        
        this.setState({ deleteProj: false });
        this.props.deleteProject(this.state.deleteItemId)
        this.props.getProjects(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
    }
    /**
     * Bind Html to DOM
     * 
     * [1]  -   remove download icon
     */
    render() {
        let {
            projects, columnsMap, visibleColumns, tableActionRequired,
            removeActions, location: { pathname }, title, pageAccess,
            isSubPage, classes, needPadding, helpData,
            searchData: { sFields, sValues }
        } = this.props;
        const { userRole, userOrgId } = this;
        const { fieldFilter, openHelp, anchorEl } = this.state;
        const search = projects;
        const fieldFilters = search.page.fieldFilters;
        const defaultColumns = fieldFilters.defaultCols
        pageAccess = _.difference(pageAccess, removeActions);
        // [1]
        const downloadIndex = pageAccess.indexOf('DOWNLOAD');
        if (this.props.userId && downloadIndex > -1) {
            pageAccess.splice(downloadIndex, 1);
        }
        pageAccess = [...pageAccess, 'HELP']
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                handleMergeActionClick={this.handleMergeActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                needPadding={needPadding}
                handleDownloadActionClick={() => (
                    <Menu>
                        <MenuItem>New</MenuItem>
                        <MenuItem>Update</MenuItem>
                    </Menu>
                )}
                handleTable1ImportActionClick={this.handleTable1ImportActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleUploadActionClick={(event) => {
                    if ((userRole === 'superFunder' || userRole === 'superFunderWithHO')) {
                        this.setState({ anchorEl: event.currentTarget })
                        // this.handleBulkUploadNavigation('building')
                    } else {
                        history.push({
                            pathname: '/projects/ua/bulk-upload',
                            state: {
                                page: 'uaBulkUpload',
                                pageData: {}
                            }
                        })
                    }
                }}
                uploadTooltip={(userRole === 'superFunder' || userRole === 'superFunderWithHO') ? "Bulk Update" : "Bulk Upload Utility Allowances"}
            >
                <TableComponent
                    page={search.page.skip}
                    rowsPerPage={search.page.limit}
                    count={search.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    actions={tableActionRequired === true ? pageAccess : []}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    currentPage={'projects'}
                    links={['name', "monitoring", "ownerName", "owners.org_id.name"]}
                    order={search.page.order}
                    orderBy={search.page.orderBy}
                    data={search.data}
                    highlightField={['name']}
                    isLoading={false}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    handleTableCopyAction={this.handleTableCopyAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                    moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                    prevNext={false}
                    showLoading={isSubPage}
                    paginationVisible={true}
                    disablePagination={search.page.lastPage}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                    // disabledCols={defaultColumns}
                />
                <DeleteDialog
                    open={this.state.deleteProj !== false}
                    content={"This Project is associated with at least one Site/Building/Unit.Deleting this project will delete all associated Site/Building/Unit ?"}
                    handleDeleteAction={() => this.deleteProject()}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ deleteProj: false })
                    }}
                />
                {
                    openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="Projects"
                    />
                }
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    className='customMenu'
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    <MenuItem onClick={() => this.handleBulkUploadNavigation('buildings')}>Buildings Bulk Update</MenuItem>
                    <MenuItem onClick={() => this.handleBulkUploadNavigation('units')}>Units Bulk Update</MenuItem>
                </Menu>
            </ContentWrapper>

        );
    }
}
/**
 * Bind Component Property Types
 */
ProjectsList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    title: PropTypes.string,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};
/**
 * Default Props Types
 */
ProjectsList.defaultProps = {
    needPadding: false,
    tableActionRequired: true,
    title: "All Projects",
    removeActions: [],
    isSubPage: false
};
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 * @param {*} state 
 * @returns 
 */
const getPageState = function (page, state) {
    switch (page) {
        case 'projects': return state.projects;
        case 'users': return state.users.projects;
        default: break;
    }
}
/**
 * Map Page Action
 * @param {*} page 
 * @returns 
 */
const getPageAction = function (page) {
    switch (page) {
        case 'projects': return projectsActions.getProjects;
        case 'users': return userActions.getAssociatedProjects;
        default: break;
    }
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = ownProps.userId ? state.configuration.fieldFilter['associatedProjects'] || {} : state.configuration.fieldFilter['projects'] || {};
    const pageAccess = state.pageAccess['projectsList'] || [];
    const projects = getPageState(ownProps.page, state);
    const searchIndexFor = ownProps.userId ? 'userProjects' : 'projects';
    const searchData = state.search[searchIndexFor] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    return {
        projects,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @param {*} ownProps 
 * @returns 
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetFilter: () => dispatch(projectsActions.resetFilter()),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getProjects: (skip, limit, orderBy, order, sField, sValue, exactMatch, userId) => {
            const actionToCall = getPageAction(ownProps.page);
            dispatch(actionToCall(skip, limit, orderBy, order, sField, sValue, exactMatch, userId))
        },
        deleteProject: (id) => dispatch(projectsActions.delete(id)),
        searchActions: (data) => {
            if (ownProps.userId) {
                return dispatch(searchActions.userProjects(data));
            } else {
                return dispatch(searchActions.projects(data));
            }
        },
        getHelp: () => dispatch(helpActions.getAll({ _id: 6 })),
        // onNavigateProject:(id)=>dispatch(projectsActions.navigate(id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(ProjectsList));
