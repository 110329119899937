import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@mui/material/Tooltip';

/**
 *Tool tip component
 *
 * @class ToolTip
 * @extends {React.Component}
 */
class ToolTip extends React.Component {
  /**
   * Bind HTML to DOM
   * @returns 
   */
  render() {
    const { title, placement, classes, TooltipComponent, className } = this.props;
    return (
      <Tooltip
        title={TooltipComponent ? TooltipComponent : title}
        placement={placement}
        disableFocusListener disableTouchListener
        TransitionComponent={Zoom}
        arrow
        classes={{ popper: classes.arrowPopper, tooltipPlacementRight: className, tooltipPlacementLeft: className }}
      >
        <span>
        {this.props.children}
        </span>
      </Tooltip>
    );
  }
}
//default props
ToolTip.defaultProps = {
  title: '',
  placement: 'bottom',
  TooltipComponent: null,
  classes: {},
  className: ''
}
//prop types
ToolTip.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  TooltipComponent: PropTypes.func,
  classes: PropTypes.object,
};
//export component
export default ToolTip;


