import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import ReportsActivityRouter from '../../ReportActivity/ReportsActivity.Router';

class Reports extends Component {

    /**
 * Bind Html to DOM 
 */
    render() {

        const { match, projectId, innerRef } = this.props;

        return (
            <Switch >
                <Route
                    path={match.url} render={props => (
                        <ReportsActivityRouter {...props}
                            innerRef={innerRef}
                            isSubPage={true}
                            match={match}
                            projectId={projectId}
                            page={'reportActivity'} />
                    )} />

            </Switch>
        );
    }
}

/**
 * Export Component
 */
export default Reports;