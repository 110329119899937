import { authHeader, globalVariable, handleBulkResponse, handleDownloadResponse, handleResponse } from '../helpers';

export const uploadService = {
  funderBulkUpload,
  importIncRentLmt,
  table1Import,
  importStatus,
  triggerUpdate,
  reportActivityImport,
  funderErrorLogDownload,
  updateTemplateDownload,
  funderBulkUploadSubmit
};

/**
 * Bulk import
 * 
 * @param {*} uploadData 
 * @param {*} action 
 */
function funderBulkUpload(formData, action) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader()
    },
    body: formData
  };

  let routeType = action === 'new' ? '/bulkImport' : '/bulkImportUpdate';
  return fetch(`${globalVariable.apiURL + routeType}`, requestOptions)
    .then(handleBulkResponse);
}

/**
 * Bulk import
 * 
 * @param {*} uploadData 
 * @param {*} action 
 */
function funderBulkUploadSubmit(formData, action) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader()
    },
    body: formData != null && JSON.stringify({
      file:formData.filePath,
      projectName:formData.project,
      additionType:action==='Add'?formData.additionType:null
    })
  };

  let routeType = action === 'new' ? '/submitBulkImport' : action==='Add'?'/submitBulkImportAddition':'/submitBulkImportUpdate';
  return fetch(`${globalVariable.apiURL + routeType}`, requestOptions)
    .then(handleBulkResponse);
}

/**
 * Bulk import
 * 
 * @param {*} fileName 
 */
function funderErrorLogDownload(fileName) {
  console.log(fileName)
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({
            path:`funderImport_error_logs/${fileName}`
          })
    };

    return fetch(`${globalVariable.apiURL}/getErrorLogs`, requestOptions).then(handleDownloadResponse);
}
/**
 * Update template
 * 
 * @param {*} bodyData 
 */
function updateTemplateDownload(bodyData) {
  console.log(bodyData)
    const requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyData)
    };

    return fetch(`${globalVariable.apiURL}/bulkUpdateTemplate`, requestOptions).then(handleDownloadResponse);
}
/**
 * import Income and Rent limit
 * 
 * @param {*} generalData 
 * @param {*} uploadData 
 */
function importIncRentLmt(generalData, uploadData) {
  let {categoryType} = generalData;
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader()
    },
    body: uploadData
  };
  return fetch(`${globalVariable.ms_apiURL}/import/incomerentlmt/${categoryType}`, requestOptions)
    .then(handleBulkResponse);
}

/**
 * A method to import table 1 data
 *
 * @param {*} formData
 * @returns
 */
function table1Import(formData) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader()
    },
    body: formData
  };
  return fetch(`${globalVariable.ms_apiURL}/table1-import`, requestOptions).then(handleBulkResponse);
}

/**
 * Import status 
 */
function importStatus() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${globalVariable.apiURL}/import/status`, requestOptions).then(handleResponse);
}

/**
 * Trigger Update
 */
function triggerUpdate() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };
  return fetch(`${ globalVariable.ms_apiURL }/update-incomerentlimit`, requestOptions).then(handleResponse);
}

/**
 * A method to import reportActivity
 */
 function reportActivityImport(formData) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader()
    },
    body: formData
  };
  return fetch(`${globalVariable.ms_apiURL}/reportActivityImport`, requestOptions).then(handleBulkResponse);
}