export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        // header pragma added for no cache in IE11 fix
        return { 
            // 'Pragma': 'no-cache', 
            'Authorization': 'Bearer ' + user.token 
        };
    } else {
        return {};
    }
}