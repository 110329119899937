import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import _ from 'underscore';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { history } from '../../../helpers';

// Import Components
import OutlineTextBox from '../../../components/OutlineTextBox/OutlineTextBox';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TableErrors from '../../../components/TableErrors/TableErrors';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
import { focusHelper } from '../../../helpers/global';
import FieldValidationConstants from "../../../helpers/fieldValidationConstants";
import ProgramActivityValidations from '../../../helpers/TableValidations/programActivity';
import FinancialActivityValidations from '../../../helpers/TableValidations/financialActivity';
import ContractActivityValidations from '../../../helpers/TableValidations/contractActivity';

// import Actions
import { reportActivityActions } from '../../../actions/reportActivity';
import { validationActions } from '../../../actions/validation';


class ProgramActivity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            numberofLoansGeneratedReportingPeriod: '',
            array_numberofLoansGeneratedReportingPeriod: [],
            // numberofLoansMakingPaymentsReportingPeriod: '',
            // array_numberofLoansMakingPaymentsReportingPeriod: [],
            numberofGrantsReportingPeriod: '',
            array_numberofGrantsReportingPeriod: [],
            numberofDeferredLoansReportingPeriod: '',
            array_numberofDeferredLoansReportingPeriod: [],
            // numberofLoansSatisfiedReportingPeriod: '',
            // array_numberofLoansSatisfiedReportingPeriod: [],
            numberofLoansDefaultReportingPeriod: '',
            array_numberofLoansDefaultReportingPeriod: [],
            numberofClosuresReportingPeriod: '',
            array_numberofClosuresReportingPeriod: [],
            numberofResalesReportingPeriod: '',
            array_numberofResalesReportingPeriod: [],
            focusVisible: false,
            isFormNotSaved: false,
            created: false
        };
        this.onwerName = "";
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.section6 = React.createRef();
        this.section7 = React.createRef();
        this.section8 = React.createRef();
        this.section9 = React.createRef();
        this.section10 = React.createRef();
        this.section11 = React.createRef();
        this.section12 = React.createRef();
    }

    componentDidMount() {
        const { location: { state } } = this.props;
        let rp_id = history.location.pathname.split("/")[4]
        console.log("🚀 ~ file: ProgramActivity.jsx:77 ~ ProgramActivity ~ componentDidMount ~ rp_id:", rp_id)
        const reportYear_id = rp_id;
        this.props.getByIdCertificationForm(reportYear_id);
        this.props.getReportActivityAccess(reportYear_id, () => { })
        this.props.getByIdProgramActivity(reportYear_id, () => { });
        this.props.getByIdFinancialActivity(reportYear_id, () => { });
        this.props.getByReportIdContractActivity(reportYear_id, () => { });
    }

    UNSAFE_componentWillMount() {
        const { location: { state } } = this.props;
        let rp_id = history.location.pathname.split("/")[4]
        console.log("🚀 ~ file: ProgramActivity.jsx:89 ~ ProgramActivity ~ UNSAFE_componentWillMount ~ history:", history)
        console.log("🚀 ~ file: ProgramActivity.jsx:88 ~ ProgramActivity ~ UNSAFE_componentWillMount ~ rp_id:", rp_id)
        
        const reportYear_id = rp_id;
        if (reportYear_id) this.props.validationIssue(reportYear_id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { programActivity } = nextProps;
        const { isFormNotSaved } = this.state;
        if (programActivity && !isFormNotSaved) {
            this.setState({
                numberofLoansGeneratedReportingPeriod: programActivity.numberOfLoansGenerated,
                array_numberofLoansGeneratedReportingPeriod: programActivity.loansGenerated,
                // numberofLoansMakingPaymentsReportingPeriod: programActivity.numberOfLoansMakingPayments,
                // array_numberofLoansMakingPaymentsReportingPeriod: programActivity.loansMakingPayments,
                numberofGrantsReportingPeriod: programActivity.numberOfLoansGrants,
                array_numberofGrantsReportingPeriod: programActivity.loansGrants,
                numberofDeferredLoansReportingPeriod: programActivity.numberOfLoansDeferred,
                array_numberofDeferredLoansReportingPeriod: programActivity.loansDeferred,
                // numberofLoansSatisfiedReportingPeriod: programActivity.numberOfLoansRepaid,
                // array_numberofLoansSatisfiedReportingPeriod: programActivity.loansRepaid,
                numberofLoansDefaultReportingPeriod: programActivity.numberOfLoansInDefault,
                array_numberofLoansDefaultReportingPeriod: programActivity.loansInDefault,
                numberofClosuresReportingPeriod: programActivity.numberOfForeclosuresLoans,
                array_numberofClosuresReportingPeriod: programActivity.foreClosuresLoans,
                numberofResalesReportingPeriod: programActivity.numberOfResales,
                array_numberofResalesReportingPeriod: programActivity.resaleLoans || []
            })
        }
        //Handle section navigation
        //Redirected from contractActivity validations
        if(this.props.location.state.content){
            this.scrollToContent(this.props.location.state.content)
        }
    }

    handleChange = (name, value) => {
        //handle the empty string in textfields
        if (value === '')
        switch (name) {
            case "numberofLoansGeneratedReportingPeriod":
                    this.setState({
                        [name]: parseInt(value),
                        array_numberofLoansGeneratedReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofLoansGeneratedReportingPeriod'][m] || null),
                        isFormNotSaved: true
                    })
                break;
            case "numberofGrantsReportingPeriod":
                    this.setState({
                        array_numberofGrantsReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofGrantsReportingPeriod'][m] || null),
                        isFormNotSaved: true
                    })
                break;
            case "numberofDeferredLoansReportingPeriod":
                    this.setState({
                        array_numberofDeferredLoansReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofDeferredLoansReportingPeriod'][m] || null),
                        isFormNotSaved: true
                    })
                break;
            case "numberofLoansDefaultReportingPeriod":
                    this.setState({
                        array_numberofLoansDefaultReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofLoansDefaultReportingPeriod'][m] || null),
                        isFormNotSaved: true
                    })
                break;
            case "numberofClosuresReportingPeriod":
                    this.setState({
                        array_numberofClosuresReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofClosuresReportingPeriod'][m] || null),
                        isFormNotSaved: true
                    })
                break;
            case "numberofResalesReportingPeriod":
                    this.setState({
                        array_numberofResalesReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofResalesReportingPeriod'][m] || null)
                    })
                break;
            default:
                break;
        };
        if (value !== '')
            switch (name) {
                case "numberofLoansGeneratedReportingPeriod":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            [name]: parseInt(value),
                            array_numberofLoansGeneratedReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofLoansGeneratedReportingPeriod'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                // case "numberofLoansMakingPaymentsReportingPeriod":
                //     this.setState({
                //         array_numberofLoansMakingPaymentsReportingPeriod: _.range(value).map(m => this.state['array_numberofLoansMakingPaymentsReportingPeriod'][m] || null)
                //     });
                //     break;
                case "numberofGrantsReportingPeriod":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofGrantsReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofGrantsReportingPeriod'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                case "numberofDeferredLoansReportingPeriod":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofDeferredLoansReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofDeferredLoansReportingPeriod'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                // case "numberofLoansSatisfiedReportingPeriod":
                //     this.setState({
                //         array_numberofLoansSatisfiedReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofLoansSatisfiedReportingPeriod'][m] || null)
                //     });
                //     break;
                case "numberofLoansDefaultReportingPeriod":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofLoansDefaultReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofLoansDefaultReportingPeriod'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                case "numberofClosuresReportingPeriod":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofClosuresReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofClosuresReportingPeriod'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                case "numberofResalesReportingPeriod":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofResalesReportingPeriod: _.range(parseInt(value)).map(m => this.state['array_numberofResalesReportingPeriod'][m] || null)
                        })
                    }
                    break;
                default:
                    break;
            };
        this.setState({
            [name]: value,
            isFormNotSaved: true
        })
    }
    /**
    *  Handle Submit
    */

    handleSubmit = (e) => {
        this.setState({ isFormNotSaved: false })
        this._saveForm();
    }
    /**
     * Handle Tab change
     */
    handleTabChange() {
        const { isFormNotSaved } = this.state;
        return isFormNotSaved
    }
    /**
    *  Method Save Form
    */
    _saveForm() {
        const { location: { state }, programActivity, contractActivitySavedOnce } = this.props;
        const reportYear_id = state.reportYear_id ? state.reportYear_id : null;
        let programActivityfieldErrors = [];
        let financialActivityfieldErrors = [];
        let contractActivityfieldErrors = [];

        let generalData = {
            ...this.state,
            reportYear_id: reportYear_id,
            programActivity: programActivity ? programActivity._id : null
        }
        if (programActivity) {
            this.props.updateProgramActivity(generalData, () => {
                this.props.getByIdProgramActivity(reportYear_id, (data) => {
                    contractActivityfieldErrors = ContractActivityValidations({ programActivity: data.data, financialActivity: this.props.financialActivity, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                    programActivityfieldErrors = ProgramActivityValidations({ programActivity: data.data, financialActivity: this.props.financialActivity, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                    financialActivityfieldErrors = FinancialActivityValidations({ ...this.props, programActivity: data.data });
                    this.props.getByIdProgramActivity(reportYear_id, () => { 
                        let validationData = {
                            reportYear_id: reportYear_id,
                            errors: [...programActivityfieldErrors, ...financialActivityfieldErrors, ...contractActivityfieldErrors]
                        }
                        this.props.validationErrors(validationData, () => {
                            this.props.validationIssue(reportYear_id);
                        });
                    });  
                });
            });
        } else {
            if (!this.state.created) {
                this.props.createProgramActivity(generalData, (result) => {
                    if (result) {
                        this.setState({created:true})
                        this.props.getByIdProgramActivity(reportYear_id, (data) => {
                            contractActivityfieldErrors = ContractActivityValidations({ programActivity: data.data, financialActivity: this.props.financialActivity, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                            programActivityfieldErrors = ProgramActivityValidations({ programActivity: data.data, financialActivity: this.props.financialActivity, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                            financialActivityfieldErrors = FinancialActivityValidations({ ...this.props, programActivity: data.data });
                            this.props.getByIdProgramActivity(reportYear_id, () => { 
                                let validationData = {
                                    reportYear_id: reportYear_id,
                                    errors: [...programActivityfieldErrors, ...financialActivityfieldErrors, ...contractActivityfieldErrors]
                                }
                                this.props.validationErrors(validationData, () => {
                                    this.props.validationIssue(reportYear_id);
                                });
                            });
                        });
                    }
                });
            }
        }
    }

    /**
    *  Tooltip props
    * @param {C} fieldName
    */
    getTooltipProps = (fieldName) => {
        return {
            focusVisible: this.state.focusVisible && this.state.focusFieldName === fieldName,
            onFocus: (ele) => this.setState({ focusField: ele, focusFieldName: fieldName, focusVisible: true }),
            onBlur: () => this.setState({ focusField: null, focusFieldName: "", focusVisible: false })                                        // validators={['required', 'isStringLength250', 'validAddress']}
        }
    }

    /**
     *  Method Window length
     * @param {C} element
     */
    chechInView = (ele) => {
        var { focusField } = this.state
        if (focusField) {
            const { top: parentTop, bottom: parentBottom } = ele.getBoundingClientRect()
            const { top: childTop } = focusField.getBoundingClientRect()
            this.setState({ focusVisible: childTop > parentTop && childTop < parentBottom })
        }
    };

    /**
     *  Method To Update Text FIeld
     */
    updateTextField = (name, index, value) => {
        const {
            array_numberofLoansGeneratedReportingPeriod,
            // array_numberofLoansMakingPaymentsReportingPeriod,
            array_numberofGrantsReportingPeriod,
            array_numberofDeferredLoansReportingPeriod,
            // array_numberofLoansSatisfiedReportingPeriod,
            array_numberofLoansDefaultReportingPeriod,
            array_numberofClosuresReportingPeriod,
            array_numberofResalesReportingPeriod
        } = this.state;


        switch (name) {
            case `please_IdentifyLoansGeneratedReportingPeriod_${index}`:
                array_numberofLoansGeneratedReportingPeriod[index] = value;
                break;
            // case `please_IdentifyLoansMakingPaymentsReportingPeriod_${index}`:
            //     array_numberofLoansMakingPaymentsReportingPeriod[index] = value;
            //     break;
            case `please_IdentifyGrantsGeneratedReportingPeriod_${index}`:
                array_numberofGrantsReportingPeriod[index] = value;
                break;
            case `please_IdentifyDeferredLoansReportingPeriod_${index}`:
                array_numberofDeferredLoansReportingPeriod[index] = value;
                break;
            // case `please_IdentifyLoansSatisfiedReportingPeriod_${index}`:
            //     array_numberofLoansSatisfiedReportingPeriod[index] = value;
            //     break;
            case `please_IdentifyLoansDefaultReportingPeriod_${index}`:
                array_numberofLoansDefaultReportingPeriod[index] = value;
                break;
            case `please_IdentifyLoansForeclosedReportingPeriod_${index}`:
                array_numberofClosuresReportingPeriod[index] = value;
                break;
            case `please_IdentifyResalesReportingPeriod_${index}`:
                array_numberofResalesReportingPeriod[index] = value;
                break;
            default:
                break;
        };

        this.setState({
            array_numberofLoansGeneratedReportingPeriod: array_numberofLoansGeneratedReportingPeriod,
            // array_numberofLoansMakingPaymentsReportingPeriod: array_numberofLoansMakingPaymentsReportingPeriod,
            array_numberofGrantsReportingPeriod: array_numberofGrantsReportingPeriod,
            array_numberofDeferredLoansReportingPeriod: array_numberofDeferredLoansReportingPeriod,
            // array_numberofLoansSatisfiedReportingPeriod: array_numberofLoansSatisfiedReportingPeriod,
            array_numberofLoansDefaultReportingPeriod: array_numberofLoansDefaultReportingPeriod,
            array_numberofClosuresReportingPeriod: array_numberofClosuresReportingPeriod,
            array_numberofResalesReportingPeriod: array_numberofResalesReportingPeriod,
            isFormNotSaved: true
        })
    }
    /**
         * Scroll to content
         * @param {*} content 
         */
    scrollToContent(content) {
        if (content.sectionIndex) {
            switch (content.sectionIndex) {
                case 1:
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 2:
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 3:
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 4:
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 5:
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 6:
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 7:
                    this.section7.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 8:
                    this.section8.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 9:
                    this.section9.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 10:
                    this.section10.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 11:
                    this.section11.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 12:
                    this.section12.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }
    }
    /**
    * Handle Unit Select in table error
    * @param {*} data 
    */
    handleUnit = (data, content) => {
        if (content.sectionIndex) {
            switch (content.sectionIndex) {
                case 1:
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 2:
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 3:
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 4:
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 5:
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 6:
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 7:
                    this.section7.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 8:
                    this.section8.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 9:
                    this.section9.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 10:
                    this.section10.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 11:
                    this.section11.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 12:
                    this.section12.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }
    }
    /**
     *  Bind HTML to DOM
     */
    render() {
        const { classes, pageAccess, programActivity, reportTables, validationIssues } = this.props;
        validationIssues && validationIssues.programActivity && validationIssues.programActivity.forEach(item => {
            item.msg = item.message;
        })
        const isReadOly = pageAccess.indexOf('SAVE') <= -1;
        let fielderrors = validationIssues && validationIssues.programActivity && validationIssues.programActivity;
        //Error sorting hard stop and warning
        fielderrors = fielderrors && fielderrors.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))

        let hardstopSort = fielderrors && fielderrors.filter((item, index) => item.errorType === 1);
        let warningSort = fielderrors && fielderrors.filter((item, index) => item.errorType === 2);

        if ((hardstopSort && hardstopSort.length > 0) && (warningSort && warningSort.length > 0)) {
            fielderrors = hardstopSort.concat(warningSort)
        } else if ((hardstopSort && hardstopSort.length > 0) && (warningSort && warningSort.length === 0)) {
            fielderrors = hardstopSort;
        } else {
            fielderrors = warningSort;
        }

        const {
            numberofLoansGeneratedReportingPeriod,
            array_numberofLoansGeneratedReportingPeriod,
            numberofGrantsReportingPeriod,
            array_numberofGrantsReportingPeriod,
            numberofDeferredLoansReportingPeriod,
            array_numberofDeferredLoansReportingPeriod,
            numberofLoansDefaultReportingPeriod,
            array_numberofLoansDefaultReportingPeriod,
            numberofClosuresReportingPeriod,
            array_numberofClosuresReportingPeriod,
            numberofResalesReportingPeriod,
            array_numberofResalesReportingPeriod
        } = this.state;

        if (reportTables.programActivity === false) {
            return <h4 className="section_title textCenter">{'This Activity is not currently required by any Funder for this report year'}</h4>;
        }

        //get report level
        let level = ''
        if (!programActivity) {
            level = 'Report currently at Preparer level'
        } else {
            if (programActivity && programActivity.submissionStatus && programActivity.submissionStatus.length === 0) {
                level = 'Report currently at Preparer level'
            } else if (programActivity && programActivity.submissionStatus && programActivity.submissionStatus.length > 0) {
                var getlevel = programActivity.submissionStatus[(programActivity.submissionStatus.length) - 1]
                if (getlevel.levelTo_id === 1) {
                    level = 'Report currently at Preparer level'
                } else if (getlevel.levelTo_id === 2) {
                    level = 'Report currently at Contractor level'
                } else if (getlevel.levelTo_id === 3) {
                    level = 'Report currently at Funder level'
                }
            }
        }
        // let duplicateNumberOfLoansGenerated = array_numberofLoansGeneratedReportingPeriod.filter((item, index) => array_numberofLoansGeneratedReportingPeriod.indexOf(item) != index)
        // let indexOfduplicateNumberOfLoansGenerated = duplicateNumberOfLoansGenerated.map(item=>array_numberofLoansGeneratedReportingPeriod.indexOf(item))
        // this.props.showAlert(`Duplicate loan numbers in ${indexOfduplicateNumberOfLoansGenerated.join(",")}`)
        return (
            <Grid className={`${classes.activityContainer} programACT`}>
                <ContentWrapper
                    classes={classes}
                    title={''}
                    needTitleBar={false}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    //pageAccess={["HELP"]}
                    titleBg={'white'}
                    pageName="reports"
                    onScrollChange={(ele) => { this.chechInView(ele) }}
                >
                    <TableErrors
                        fielderrors={fielderrors || []}
                        data={[]}
                        errorPage={'reportActivity'}
                        classes={{
                            action: classes.action,
                            message: classes.message,
                            root: classes.root
                        }}
                        validations={[]}
                        handleClickAction={(err) => this.scrollToContent(err)}
                        handleUnitSelect={(data, content) => this.handleUnit(data, content)}
                    />
                    <div className={classes.rootEdit} >
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log(errors)} >

                            {/* Grid Content */}
                            <Grid container spacing={4}>
                                {/* section 1 */}
                                <Grid item xs={12} className={classes.pageClrText}>
                                    <Typography variant="h4" class="report_level_title_HO">{level}</Typography>
                                </Grid>
                                <Grid item xs={12} ref={this.section1}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6} >
                                            <Typography className="required-header" variant="body1">1. Number of loans generated for the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="progTopTextBox"
                                                key="numberofLoansGeneratedReportingPeriod"
                                                name="numberofLoansGeneratedReportingPeriod"
                                                tooltipOnHover={!isReadOly}
                                                {...this.getTooltipProps("numberofLoansGeneratedReportingPeriod")}
                                                toolTipTitle={focusHelper().noOfReportingYr}
                                                value={numberofLoansGeneratedReportingPeriod}
                                                validators={['required', 'isValue0To999NotDecimal',]}
                                                errorMessages={['This field is required. If no loans were generated, please enter "0"', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal,]}
                                                className={`${classes.textField} error`}
                                                fullWidth={false}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 1).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 1 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} smaction={12} className={classes.plr30} ref={this.section2}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of loans generated for reporting period</Typography>
                                    <Grid item xs={12} sm={12} >
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofLoansGeneratedReportingPeriod.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                key={index}
                                                                showAdornment={true}
                                                                toolTipTitle={focusHelper().pleasenoOfReportingYr}
                                                                {...this.getTooltipProps("")}
                                                                name={`please_IdentifyLoansGeneratedReportingPeriod_${index}`}
                                                                value={array_numberofLoansGeneratedReportingPeriod[index]}
                                                                index={index + 1}
                                                                validators={CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot']}
                                                                errorMessages={CheckDuplicateActivity ? ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot]}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 2 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 2 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* section 2 */}
                                <Grid item xs={12} ref={this.section3}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">2. Number of grants in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righProgSecondtOutlinedNum"
                                                name="numberofGrantsReportingPeriod"
                                                value={numberofGrantsReportingPeriod}
                                                tooltipOnHover={!isReadOly}
                                                {...this.getTooltipProps("numberofGrantsReportingPeriod")}
                                                toolTipTitle={focusHelper().noOfgrants}
                                                validators={['required', 'isValue0To999NotDecimal']}
                                                errorMessages={['This field is required. If no grants were generated, please enter "0"', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal]}
                                                className={classes.textField}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 3).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 3 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} className={classes.plr30} ref={this.section4}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the grants generated for the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofGrantsReportingPeriod.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                showAdornment={true}
                                                                toolTipTitle={focusHelper().pleasenoOfgrants}
                                                                {...this.getTooltipProps("")}
                                                                name={`please_IdentifyGrantsGeneratedReportingPeriod_${index}`}
                                                                value={array_numberofGrantsReportingPeriod[index]}
                                                                index={index + 1}
                                                                validators={CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot']}
                                                                errorMessages={CheckDuplicateActivity ? ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot]}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 4 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 4 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* section 3 */}
                                <Grid item xs={12} ref={this.section5}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">3. Number of resales in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righSecondtOutlinedNum"
                                                name="numberofResalesReportingPeriod"
                                                value={numberofResalesReportingPeriod}
                                                tooltipOnHover={!isReadOly}
                                                {...this.getTooltipProps("numberofResalesReportingPeriod")}
                                                toolTipTitle={focusHelper().resale}
                                                validators={['required', 'isValue0To999NotDecimal']}
                                                errorMessages={['This field is required. If no loans were generated, please enter "0"', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal]}
                                                className={classes.textField}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 5).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 5 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} className={classes.plr30} ref={this.section6}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of resales in the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofResalesReportingPeriod.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                showAdornment={true}
                                                                name={`please_IdentifyResalesReportingPeriod_${index}`}
                                                                value={array_numberofResalesReportingPeriod[index]}
                                                                index={index + 1}
                                                                validators={CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot']}
                                                                errorMessages={CheckDuplicateActivity ? ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot]}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                toolTipTitle={focusHelper().pleaseResale}
                                                                {...this.getTooltipProps("")}
                                                                margin="dense"
                                                                disabled={isReadOly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 6 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 6 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* section 4 */}
                                <Grid item xs={12} ref={this.section7}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">4. Number of deferred loans in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righProgSecondtOutlinedNum"
                                                name="numberofDeferredLoansReportingPeriod"
                                                value={numberofDeferredLoansReportingPeriod}
                                                tooltipOnHover={!isReadOly}
                                                {...this.getTooltipProps("numberofDeferredLoansReportingPeriod")}
                                                toolTipTitle={focusHelper().noOfDeff}
                                                validators={['required', 'isValue0To999NotDecimal']}
                                                errorMessages={['This field is required. If no loans were deferred, please enter "0"', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal]}
                                                className={classes.textField}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 7).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 7 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} className={classes.plr30} ref={this.section8}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the loans deferred for the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofDeferredLoansReportingPeriod.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                toolTipTitle={focusHelper().pleasenoOfDeff}
                                                                {...this.getTooltipProps("")}
                                                                showAdornment={true}
                                                                name={`please_IdentifyDeferredLoansReportingPeriod_${index}`}
                                                                value={array_numberofDeferredLoansReportingPeriod[index]}
                                                                index={index + 1}
                                                                validators={CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot']}
                                                                errorMessages={CheckDuplicateActivity ? ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot]}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 8 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 8 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* section 5 */}
                                <Grid item xs={12} ref={this.section9}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">5. Number of loans in default in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righSecondtOutlinedNum"
                                                name="numberofLoansDefaultReportingPeriod"
                                                value={numberofLoansDefaultReportingPeriod}
                                                tooltipOnHover={!isReadOly}
                                                {...this.getTooltipProps("numberofLoansDefaultReportingPeriod")}
                                                toolTipTitle={focusHelper().noOfDefaultLoan}
                                                validators={['required', 'isValue0To999NotDecimal']}
                                                errorMessages={['This field is required. If no loans were in default, please enter "0"', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal]}
                                                className={classes.textField}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 9).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 9 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}

                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} className={classes.plr30} ref={this.section10}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the loans in default for the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofLoansDefaultReportingPeriod.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                showAdornment={true}
                                                                name={`please_IdentifyLoansDefaultReportingPeriod_${index}`}
                                                                value={array_numberofLoansDefaultReportingPeriod[index]}
                                                                index={index + 1}
                                                                validators={CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot']}
                                                                errorMessages={CheckDuplicateActivity ? ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot]}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                toolTipTitle={focusHelper().pleasenoOfDefaultLoan}
                                                                {...this.getTooltipProps("")}
                                                                margin="dense"
                                                                disabled={isReadOly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 10 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 10 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* section 6 */}
                                <Grid item xs={12} ref={this.section11}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">6. Number of Foreclosures in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righSecondtOutlinedNum"
                                                name="numberofClosuresReportingPeriod"
                                                value={numberofClosuresReportingPeriod}
                                                tooltipOnHover={!isReadOly}
                                                {...this.getTooltipProps("numberofClosuresReportingPeriod")}
                                                toolTipTitle={focusHelper().noOfForeclosureLoan}
                                                validators={['required', 'isValue0To999NotDecimal']}
                                                errorMessages={['This field is required. If no loans were foreclosed, please enter "0"', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal]}
                                                className={classes.textField}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 11).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 11 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} className={classes.plr30} ref={this.section12}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the loans that foreclosed for the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofClosuresReportingPeriod.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                toolTipTitle={focusHelper().pleasenoOfForeclosureLoan}
                                                                {...this.getTooltipProps("")}
                                                                showAdornment={true}
                                                                name={`please_IdentifyLoansForeclosedReportingPeriod_${index}`}
                                                                value={array_numberofClosuresReportingPeriod[index]}
                                                                index={index + 1}
                                                                validators={CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot']}
                                                                errorMessages={CheckDuplicateActivity ? ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : ['This field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot]}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 12 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 12 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>

                            {!isReadOly &&
                                <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />
                            }

                        </ValidatorForm>

                    </div>
                </ContentWrapper>
            </Grid>
        );
    }
}
/**
 * Bind Component Property Types
 */
ProgramActivity.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

/**
*  Default props
*/
ProgramActivity.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    page: 'programActivity',
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        programActivity: state.reportActivity.programActivity.data,
        financialActivity: state.reportActivity.financialActivity.data,
        reportData: state.reportActivity.contractActivityReportData,
        pageAccess: state.pageAccess['programActivity'] || [],
        reportTables: state.reportActivity.reportTableAccess.reportTables,
        contractActivitySavedOnce: state.reportActivity.reportTableAccess.contractActivitySavedOnce,
        validationIssues: state.reportActivity.validations.data,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReportActivityAccess: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getReportActivityAccess(reportYear_id, successCallBack)),
        getByIdCertificationForm: (reportYear_id) => dispatch(reportActivityActions.getByIdCertificationForm(reportYear_id)),
        getByIdProgramActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByIdProgramActivity(reportYear_id, successCallBack)),
        getByIdFinancialActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByIdFinancialActivity(reportYear_id, successCallBack)),
        getByReportIdContractActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByReportIdContractActivity(reportYear_id, successCallBack)),
        createProgramActivity: (formData, successCallBack) => dispatch(reportActivityActions.createProgramActivity(formData, successCallBack)),
        updateProgramActivity: (formData, successCallBack) => dispatch(reportActivityActions.updateProgramActivity(formData, successCallBack)),
        validationErrors: (validationErrors, callBack) => dispatch(validationActions.activityValidations(validationErrors, callBack)),
        validationIssue: (reportYear_id) => dispatch(reportActivityActions.validationIssue(reportYear_id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ProgramActivity));