import { table1LogConstants } from "../constants/table1Log";

const initialState = {
  //isReportListLoading: false,
  isLoading: false,
  data: [],
  doubleClick: false,
  error : '',
  page: {
    fieldFilters: {
      headerCols: [],
      fieldCols: ["filename"],
      searchCols: [],
    },
  },
};

export function table1Log(state = { ...initialState }, action) {
  switch (action.type) {
    case table1LogConstants.PAGE_GETALL_TABLE1_REQUEST:
      return {
        ...state,
        getOne: {
          ...state.getOne,
          data: null,
        },
        isLoading: true,
      };
    case table1LogConstants.PAGE_GETALL_TABLE1_SUCCESS:
      return {
        ...state,
        ...action.xmlimportlogs,
        isLoading: false,
      };
    case table1LogConstants.PAGE_GETALL_TABLE1_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case table1LogConstants.SEND_MAIL_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case table1LogConstants.SEND_MAIL_TEMPLATE_SUCCESS:
    case table1LogConstants.SEND_MAIL_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false
      }
      case table1LogConstants.SEND_ERROR_FILE_REQUEST : 
            return {
                ...state,
                doubleClick: true
            };
        
        case table1LogConstants.SEND_ERROR_FILE_SUCCESS : 
            return {
                ...state,
                doubleClick: false
            };
        case table1LogConstants.SEND_ERROR_FILE_FAILURE : 
            return {
                ...state,
                error: action.error,
                doubleClick: false
            };
    default: return state;

  }
}
