import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Components
import MaterialTableComponent from '../../components/Table/MaterialTable';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";
import TextBox from '../../components/TextBox/TextBox';
import DatePicker from '../../components/DatePicker/DatePicker';

// Import Redux Actions and Helper Variable
import { occupantsActions } from '../../actions/occupants';
import { reportTablesActions } from '../../actions/reportTables';
import { confActions } from '../../actions/configuration';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserRole, getUserOrgId, calcDate, getDateDifference, history, filterSearchFields, globalVariable } from '../../helpers';

/** Default data */
const DEFAULT_TRANSFERUNIT = {
    fromEffectiveDate: null,
    toEffectiveDate: null,
    comments: '',
    item: null,
    site_id: null,
    building_id: null,
    unit_id: null,
    startDate: `${new Date().getFullYear()}-01-01`,
    enDate: `${new Date().getFullYear()}-12-31`
};
/**
 * HeadOfHouseholdName Component
 * @class HeadOfHouseholdName
 * @extends {Component}
 */
class HeadOfHouseholdName extends Component {
    render() {
        const { data: { headOfHouseholdName, vr }, handleChange } = this.props;
        const errorsInUnits = vr.map(v => v.message).join(", ");
        return <span
            onClick={() => handleChange(this.props.data, 'headOfHouseholdName')}
            title={errorsInUnits}
        >
            {headOfHouseholdName}
        </span>;
    }
}
/**
 * AMIField Component
 * @class AMIField
 * @extends {Component}
 */
class AMIField extends Component {
    render() {
        const { data: { percentageOfAreaMedianIncome_DENORM } } = this.props;
        return percentageOfAreaMedianIncome_DENORM === "" ? "" : `${percentageOfAreaMedianIncome_DENORM}%`;
    }
}
/**
 * VacantFlag Component
 * @class VacantFlag
 * @extends {Component}
 * 
 * if above 90 days need to display yellow background 
 */
class VacantFlag extends Component {
    render() {
        const { data: { isAbove90, vacantFlag } } = this.props;
        if (isAbove90) {
            return <span style={{ color: "#FFC107" }}>{vacantFlag}</span>
        }
        return vacantFlag;
    }
}

const componentMap = {
    "headOfHouseholdName": HeadOfHouseholdName,
    "percentageOfAreaMedianIncome_DENORM": AMIField,
    "vacantFlag": VacantFlag
}
/**
 * OccupantsList Component
 * @class OccupantsList
 * @extends {Component}
 */
class OccupantsList extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            comment: '',
            transferUnit: {
                ...DEFAULT_TRANSFERUNIT
            },
            disableTransferButton: false,
            openHelp: false
        }
        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
        this.pageAccess = [];
    }
    /**
     * Handle Component Will Mount 
     * 
     * [1]  -   validate from date less than to date
     * [2]  -   validate to effective date
     * [3]  -   validate from date less than to date
     * [4]  -   Validate current year
     */
    UNSAFE_componentWillMount() {
        const { occupants, projectData, location: { state } } = this.props;
        const { page } = occupants;
        this.props.getHelp();
        setTimeout(() => {
            this.props.getFieldFilter('occupants');
        }, 100);
        // [1]
        ValidatorForm.addValidationRule('isValidFromDate', (value) => {
            const { transferUnit: { fromEffectiveDate, toEffectiveDate } } = this.state;
            if (fromEffectiveDate && toEffectiveDate) {
                const days = getDateDifference(value, toEffectiveDate, 'days');
                return days > 1;
            }
            return false;
        });
        // [2]
        ValidatorForm.addValidationRule('isValidToDate', (value) => {
            const { transferUnit: { fromEffectiveDate, toEffectiveDate } } = this.state;
            if (fromEffectiveDate && toEffectiveDate) {
                const days = getDateDifference(fromEffectiveDate, toEffectiveDate, 'days');
                return days > 1;
            }
            return false;
        });
        // [3]
        ValidatorForm.addValidationRule('isFromDateGTMoveIn', (value) => {
            const { transferUnit: { item } } = this.state;
            if (item.moveInDate) {
                const moveInDate = moment(item.moveInDate, "MM/DD/YYYY");
                const days = getDateDifference(value, moveInDate, 'days');
                return days <= 0;
            }
            return false;
        });
        // [4]
        ValidatorForm.addValidationRule('isCurrentYear', (value) => {
            const { transferUnit: { item: { report_Year } } } = this.state;
            const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
            return report_Year === selYear;
        });
        if (state && state.pageData) {
            if (state.pageData.data.name === 'Occupancy Rate') {
                this.onInputChangeRequest('vacantFlag', 'No')
            } else if (state.pageData.data.name === 'Vacancy Rate') {
                this.onInputChangeRequest('vacantFlag', 'Yes')
            }
        } else {
            if (page.skip) {
                this.props.getOccupants(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, false, projectData);
            } else {
                this.props.getOccupants(1, globalVariable.tableRowSize, 'projectName', 'asc', '', '', false, projectData);
            }
        }
    }
    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Component On Page Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { occupants, projectData, searchData: { sFields, sValues } } = this.props;
        this.props.getOccupants(skip, limit, occupants.page.orderBy, occupants.page.order, sFields, sValues, false, projectData);
    };
    /**
     * Handle Table Component On Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { occupants, projectData, searchData: { sFields, sValues } } = this.props;
        this.props.getOccupants(occupants.page.skip, occupants.page.limit, orderBy, order, sFields, sValues, false, projectData);
    };
    /**
     * Handle Table Component Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { occupants, projectData, searchData } = this.props;
        let { sFields, sValues } = searchData;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        if (occupants.page.skip) {
            this.props.getOccupants(1, 15, occupants.page.orderBy, occupants.page.order, sFields, sValues, false, projectData);
        } else {
            this.props.getOccupants(1, 15, 'projectName', 'asc', sFields, sValues, false, projectData);
        }
    };
    /**
     * Handle Add Action Click Event
     * 
     * [1]  -   clear get one data occupants details
     */
    handleMoveInActionClick = () => {
        // [1]
        this.props.clearOccupantDetails();
        history.push({
            pathname: '/occupants/new',
            state: {
                page: 'occgeneral',
                pageData: {},
                vacantUnitData: null
            }
        });
    };
    /**
     * Handle Clear Filter Action Click Event
     */
    handleClearFilterActionClick = () => {
        const { occupants, projectData } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getOccupants(occupants.page.skip, occupants.page.limit, occupants.page.orderBy, occupants.page.order, [], [], false, projectData);
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     * 
     * [1]  -   clear get one data occupants details
     * [2]  -   occupant new with selected project, site, building, unit
     * [3]  -   clear get one data occupants details
     * [4]  -   occupant new with selected project, site, building, unit
     */
    handleNavigateAction = (id, field, item) => {
        const { pageAccess } = this.props;
        switch (field) {
            case 'vacantFlag':
                if (item.isVacant) {
                    if (pageAccess.indexOf('TRANSFER') > -1) {
                        // [1]
                        this.props.clearOccupantDetails();
                        // [2]
                        history.push({
                            pathname: '/occupants/new',
                            state: {
                                page: 'occgeneral',
                                pageData: {
                                    from: 'occupants'
                                },
                                vacantUnitData: {
                                    project_id: { id: item.project_id, value: item.project_id },
                                    site_id: { id: item.site._id, value: item.site._id },
                                    building_id: { id: item.building._id, value: item.building._id },
                                    unit_id: { id: item.unit._id, value: item.unit._id }
                                }
                            }
                        });
                    }
                }
                break;

            case 'headOfHouseholdName':
                if (item.isVacant && (item.headOfHouseholdName === "<empty>" || item.headOfHouseholdName === "<vacant>")) {
                    if (pageAccess.indexOf('TRANSFER') > -1) {
                        // [3]
                        this.props.clearOccupantDetails();
                        // [4]
                        history.push({
                            pathname: '/occupants/new',
                            state: {
                                page: 'occgeneral',
                                pageData: {
                                    from: 'occupants'
                                },
                                vacantUnitData: {
                                    project_id: { id: item.project_id, value: item.project_id },
                                    site_id: { id: item.site._id, value: item.site._id },
                                    building_id: { id: item.building._id, value: item.building._id },
                                    unit_id: { id: item.unit._id, value: item.unit._id }
                                }
                            }
                        });
                    }
                } else {
                    history.push({
                        pathname: '/occupants/' + id,
                        state: {
                            page: 'occgeneral',
                            pageData: {
                                table_id: id,
                                reportYear_id: item.reportYear_id,
                                from: 'occupants'
                            }
                        }
                    });
                }
                break;
            default:
                break;
        }
    }
    /**
    * Handle Select the Column Filter Button Action
    * 
    * [1]   -   update visible columns
    */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        this.props.changeFieldFilter("occupants", this.props.occupants.page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Popup On Close Event
     * 
     * [1]  -   reset to current table column values if user not save the changes
     * [2]  -   close the popup
     */
    handleFieldFilterOnClose = () => {
        // [1]
        this.props.changeFieldFilter("occupants", this.props.occupants.page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("occupants", updatedColumns);
        } else {
            this.props.changeFieldFilter("occupants", [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Field Filter On Save Event
     * 
     * [1]  -   on api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("occupants", this.props.visibleColumns, () => {
            // [1]
            this.setState({ fieldFilter: false });
            const { occupants, projectData, searchData: { sFields, sValues } } = this.props;
            this.props.getOccupants(occupants.page.skip, occupants.page.limit, occupants.page.orderBy, occupants.page.order, sFields, sValues, false, projectData);
        });
    }
    /** Table 1 on submit click */
    handleSubmitActionClick = () => {
        const { projectData } = this.props;
        if (projectData.reportId) {
            this.props.submitReport(projectData.reportId, { comment: this.state.comment });
        }
    }
    /**
     * Table 1 on Move-out click
     * @param {*} item 
     * 
     * [1]  -   get vacant units
     */
    handleTransferAction = (item) => {
        const moveInYear = moment(item.moveInDate, "MM/DD/YYYY").get('year');
        let startDate = `${item.report_Year || new Date().getFullYear()}-01-01`;
        if (moveInYear === item.report_Year) {
            startDate = moment(item.moveInDate, "MM/DD/YYYY").add('days', 1);
        }
        this.setState({
            transferUnit: {
                ...this.state.transferUnit,
                item: item,
                startDate: startDate,
                enDate: `${item.report_Year || new Date().getFullYear()}-12-30`
            }
        });
        // [1]
        if (item.site) {
            this.props.getVacantUnit(item.site._id);
        }
    }
    /** Table 1 on un-submit click */
    handleUnSubmitActionClick = () => {
        const { projectData } = this.props;
        if (projectData.reportId) {
            this.props.unSubmitReport(projectData.reportId, { comment: this.state.comment });
        }
    }
    /**
     * Handle change of input elements
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    /**
     * Submit Transfer Unit
     * 
     * [1]  -   disable button
     * [2]  -   call action
     * [3]  -   refresh occupant list after transfer
     */
    submitTransferUnit = () => {
        const { transferUnit } = this.state;      
        const generalData = {
            moveOutDate: transferUnit.fromEffectiveDate,
            moveInDate: transferUnit.toEffectiveDate,
            building_id: transferUnit.unit_id.building_id,
            site_id: transferUnit.unit_id.site_id,
            unit_id: transferUnit.unit_id.value,
            comments: transferUnit.comments,
            from_unit_id: transferUnit.item.unit._id
        }
        const bedroomSizeChanged = (transferUnit && transferUnit.item.bedroomsSize != transferUnit.unit_id.bedroom) ? true : false;
        // [1]
        this.setState({ disableTransferButton: true });
        // [2]
        this.props.transferUnit(transferUnit.item._id, generalData, bedroomSizeChanged, () => {
            this.setState({
                transferUnit: {
                    ...DEFAULT_TRANSFERUNIT
                },
                disableTransferButton: false
            });
            // [3]
            const { occupants, projectData } = this.props;
            const { page } = occupants;
            if (page.skip) {
                this.props.getOccupants(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, false, projectData);
            } else {
                this.props.getOccupants(1, 15, 'projectName', 'asc', '', '', false, projectData);
            }
        }, () => {
            this.setState({ disableTransferButton: false });
        });
    }
    /**
     * Check if Comment Required
     * @returns 
     */
    isCommentRequired() {
        const { transferUnit: { fromEffectiveDate, toEffectiveDate } } = this.state;
        if (fromEffectiveDate && toEffectiveDate) {
            const diff = getDateDifference(fromEffectiveDate, toEffectiveDate);
            if (diff > 2) {
                return {
                    validators: ['required'],
                    errorMessages: ['Please enter comments because the difference between Transfer FROM Effective Date and Transfer TO Effective Date is more than one day.']
                }
            }
        }
        return {};
    }

    handleFocus = (name) => {
        this.setState({ updateColor: true })
    }
    handleOutFocus = (name) => {
        this.setState({ updateColor: false })
    }

    /**
     * Handle Field Component On Change Event
     * @param {*} item 
     * @param {*} name 
     * 
     * [1]  -   clear get one data occupants details
     * [2]  -   occupant new with selected project, site, building, unit
     */
    fieldComponentOnChange = (item, name) => {
        const { pageAccess } = this.props;
        const id = item._id;
        switch (name) {
            case 'vacantFlag':
                if (item.vacant && item.moveInDisabled === false) {
                    if (pageAccess.indexOf('TRANSFER') > -1) {
                        // [1]
                        this.props.clearOccupantDetails();
                        // [2]
                        history.push({
                            pathname: '/occupants/new',
                            state: {
                                page: 'occgeneral',
                                pageData: {
                                    from: 'occupants'
                                },
                                vacantUnitData: {
                                    project_id: { id: item.project_id, value: item.project_id },
                                    site_id: { id: item.site._id, value: item.site._id },
                                    building_id: { id: item.building_id._id, value: item.building._id },
                                    unit_id: { id: item.unit._id, value: item.unit._id }
                                }
                            }
                        });
                    }
                }
                break;
            case 'headOfHouseholdName':
                if (item.headOfHouseholdName !== '<vacant>' && item.headOfHouseholdName !== '<empty>') {
                    history.push({
                        pathname: '/occupants/' + id,
                        state: {
                            page: 'occgeneral',
                            pageData: {
                                table_id: id,
                                reportYear_id: item.reportYear_id,
                                backButton: 'true',
                                from: 'occupants'
                            }
                        }
                    });
                } else {
                    if (pageAccess.indexOf('TRANSFER') > -1) {
                        // [1]
                        this.props.clearOccupantDetails();
                        // [2]
                        history.push({
                            pathname: '/occupants/new',
                            state: {
                                page: 'occgeneral',
                                pageData: {},
                                vacantUnitData: {
                                    project_id: { id: item.project_id, value: item.project_id },
                                    site_id: { id: item.site._id, value: item.site._id },
                                    building_id: { id: item.building._id, value: item.building._id },
                                    unit_id: { id: item.unit._id, value: item.unit._id },
                                    from: 'occupants'
                                }
                            }
                        });
                    }
                }
                break;
            case 'unit_number':
                history.push({
                    pathname: `/units/${item.unit_id._id}`,
                    state: {
                        page: 'unitEdit',
                        pageData: {
                            site_id: item.site_id._id,
                            project_id: item.project_id._id,
                            building_id: item.building_id._id
                        }
                    }
                });
                break;
            default:
                break;
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Render Html
     */
    render() {
        const { fieldFilter, comment, transferUnit, disableTransferButton, openHelp } = this.state;
        const { userRole, userOrgId } = this;
        var diffs
        let { occupants, columnsMap, visibleColumns, needPadding, searchData: { sFields, sValues },
            location: { pathname }, pageAccess, isSubPage, classes, title, vacantUnits, helpData } = this.props;
        const fieldFilters = occupants.page ? occupants.page.fieldFilters : {};
        let defaultCols = [
            "site.name",
            "building.name",
            "unit.common_id",
            "headOfHouseholdName",
            "moveInDate",
            "previousCertDate",
            "recentCertDate",
            "moveOutDate",
            "moveOutReasonId.name",
            "newHousingSituationId.name",
            "evictionReasonId.name",
            "vacantFlag",
            "householdStudentExceptionType_id",
            "specialNeed.name",
            "setAsideType_id_LIHPct",
            "householdSize",
            "unitSquareFootage",
            "noOfBedRooms",
            "maximumAllowableIncome",
            "maximumAllowableRent",
            "annualGrossIncome",
            "utilityAllowance",
            "residentPaymentAmount",
            "totalRent"
        ];
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        const stickyHeaders = fieldFilters.headerCols.slice(0, 5)
        pageAccess = [...pageAccess, 'HELP']
        if (transferUnit.fromEffectiveDate && transferUnit.toEffectiveDate) {
            diffs = getDateDifference(transferUnit.fromEffectiveDate, transferUnit.toEffectiveDate);
        }
        occupants.data.map(item=>{
            if(item['percentageOfAreaMedianIncome_DENORM']){
            item['percentageOfAreaMedianIncome_DENORM'] = item['percentageOfAreaMedianIncome_DENORM'].replace("%","")
            }
        })
        return (
            <div className='occupant-container'>
                <ContentWrapper
                    pageAccess={pageAccess}
                    isSubPage={isSubPage}
                    title={title}
                    handleMoveInActionClick={this.handleMoveInActionClick}
                    needPadding={needPadding}
                    handleClearFilterActionClick={() => {
                        history.replace(`${pathname}#clear`);
                    }}
                    handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                    handleSubmitActionClick={this.handleSubmitActionClick}
                    handleUnSubmitActionClick={this.handleUnSubmitActionClick}
                    handleHelpActionClick={this.handleHelpActionClick}
                >
                    <MaterialTableComponent
                        className="occupants_Table"
                        page={occupants.page.skip}
                        rowsPerPage={occupants.page.limit}
                        count={occupants.page.count}
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        actions={pageAccess}
                        stickyHeaders={stickyHeaders}
                        links={["unit_number", "headOfHouseholdName", "vacantFlag"]}
                        order={occupants.page.order}
                        orderBy={occupants.page.orderBy}
                        isLoading={occupants.isLoading}
                        data={occupants.data}
                        onChangePageTable={this.onChangePageTable}
                        onSortRequest={this.onSortRequest}
                        onInputChangeRequest={this.onInputChangeRequest}
                        handleTransferAction={this.handleTransferAction}
                        resetSearchContent={this.props.location.hash === "#clear"}
                        sFields={filterSearchFields(sFields)}
                        sValues={sValues}
                        handleNavigateAction={this.handleNavigateAction}
                        userOrgId={userOrgId}
                        userRole={userRole}
                        noDataErrorMessage={"No Records Found"}
                        currentPage={'occupants'}
                        prevNext={false}
                        paginationVisible={!occupants.page.lastPage ? true : false}
                        showLoading={false}
                        componentMap={componentMap}
                        fieldComponentOnChange={this.fieldComponentOnChange}
                        highlightField={['headOfHouseholdName']}
                    />

                    {
                        this.props.projectData !== undefined && (
                            <ValidatorForm>
                                <Grid item xs={12} sm={12}>
                                    <TextBox
                                        name="comment"
                                        label="Comment"
                                        value={comment}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={false}
                                    />
                                </Grid>
                            </ValidatorForm>
                        )
                    }

                    <FieldFilter
                        open={fieldFilter}
                        handleOnClose={this.handleFieldFilterOnClose}
                        handleOnChange={this.handleFieldFilterOnChange}
                        handleOnSave={this.handleFieldFilterOnSave}
                        columnsMap={columnsMap}
                        visibleColumns={visibleColumns}
                        disabledCols={defaultCols}
                    />
                    <AlertDialog
                        open={transferUnit.item !== null}
                        title="Unit Transfer:"
                        onClose={() => {
                            this.setState({
                                transferUnit: {
                                    ...DEFAULT_TRANSFERUNIT
                                }
                            })
                        }}
                        onSave={() => {
                            this.refs.transferForm.submit();
                        }}
                        saveText={'OK'}
                        buttonDisable={disableTransferButton}
                    >
                        <Grid item xs={12} sm={12}>
                            {
                                transferUnit.item && (
                                    <ValidatorForm
                                        name="moveOutTransfer"
                                        ref="transferForm"
                                        autoComplete="off"
                                        onSubmit={this.submitTransferUnit}
                                        onError={errors => { }} >
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={6}>
                                                <TextBox
                                                    name="unitFrom"
                                                    value={transferUnit.item.headOfHouseholdName}
                                                    label="Unit Moving From"
                                                    className={classes.textField}
                                                    handleChange={this.handleChange}
                                                    disabled={true}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <DatePicker
                                                    name="fromEffectiveDate"
                                                    value={transferUnit.fromEffectiveDate}
                                                    label="Transfer FROM Effective Date"
                                                    className={classes.textField}
                                                    handleChange={(name, value) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                fromEffectiveDate: value,
                                                                toEffectiveDate: moment(value).add(1, 'd')

                                                            }
                                                        });
                                                    }}
                                                    margin="dense"
                                                    validators={['required', 'isValidFromDate', 'isFromDateGTMoveIn', 'isCurrentYear']}
                                                    errorMessages={['Please enter valid Transfer FROM Effective Date', 'Date must be less than To Effective date', 'Date must be greater than Unit move in date', 'Date should be in current report year']}
                                                    validations={{
                                                        minDate: moment(transferUnit.startDate),
                                                        maxDate: moment().endOf('year')
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <AutoComplete
                                                    placeholder="Unit Number Moving TO"
                                                    suggestions={vacantUnits}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                unit_id: selectedOption
                                                            }
                                                        });
                                                    }}
                                                    selectedValue={transferUnit.unit_id}
                                                    validators={['required']}
                                                    errorMessages={['Please select Unit Number Moving TO']}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <DatePicker
                                                    name="toEffectiveDate"
                                                    value={transferUnit.toEffectiveDate}
                                                    label="Transfer TO Effective Date:"
                                                    className={classes.textField}
                                                    handleChange={(name, value) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                toEffectiveDate: value
                                                            }
                                                        });
                                                    }}
                                                    margin="dense"
                                                    validators={['required', 'isValidToDate', 'isCurrentYear']}
                                                    errorMessages={['Please enter valid Transfer TO Effective Date', 'Date must be greater than FROM Effective date', 'Date should be in current report year']}
                                                    disabled={transferUnit.fromEffectiveDate === null}
                                                    validations={{
                                                        minDate: transferUnit.fromEffectiveDate ? calcDate(transferUnit.fromEffectiveDate, 'add', { days: 1 }) : null,
                                                        maxDate: moment().endOf('year')
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <TextBox
                                                    name="comments"
                                                    value={transferUnit.comments}
                                                    label="Comments"
                                                    onBlur={() => console.log("blurrrr")}
                                                    // className={classes.textField}
                                                    gotFocus={this.handleFocus}
                                                    outFocus={this.handleOutFocus}
                                                    className={!(diffs > 2) && this.state.updateColor === "" ? `${classes.textField} loanactNEW` : !(diffs > 2) && this.state.updateColor === true ? `${classes.textField} loanactNEWFocus` : diffs > 2 ? classes.textField : "norPUB"}
                                                    handleChange={(name, value) => {
                                                        this.setState({
                                                            transferUnit: {
                                                                ...transferUnit,
                                                                comments: value
                                                            }
                                                        });
                                                    }}
                                                    {...this.isCommentRequired()}
                                                />
                                            </Grid>
                                        </Grid>
                                    </ValidatorForm>
                                )
                            }
                        </Grid>
                    </AlertDialog>
                    {openHelp && <HelpFloater
                        handleCloseFloater={() => this.setState({ openHelp: false })}
                        {...helpData}
                        title="Occupants"
                    />}
                </ContentWrapper>
            </div>
        );
    }
}
/**
 * Set Props Types
 */
OccupantsList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    pageAccess: PropTypes.array
};
/**
 * Default Props
 */
OccupantsList.defaultProps = {
    needPadding: false,
    isSubPage: false,
    title: 'Occupants',
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration, occupants, pageAccess, search, help }, ownProps) => {
    const columnsData = configuration.fieldFilter['occupants'] || {};
    const searchData = search['occupants'] || { sFields: [], sValues: [] };
    const helpData = help.data[0]
    return {
        pageAccess: pageAccess['occupantsList'] || [],
        occupants: occupants,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        projectData: ownProps.projectData,
        vacantUnits: occupants.transferUnit.vacantUnits,
        searchData,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getOccupants: (skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData) => dispatch(occupantsActions.getAll(skip, limit, orderBy, order, sField, sValue, isAutocomplete, projectData)),
        resetFilter: () => dispatch(occupantsActions.resetFilter()),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        setStatus: (id, status, callBack) => dispatch(occupantsActions.setActiveStatus(id, status, callBack)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable1(id, formData)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable1(id, formData)),
        getVacantUnit: (site_id) => dispatch(occupantsActions.getVacantUnit(site_id)),
        transferUnit: (table_id, data, bedroomSizeChanged, cb, ecb) => dispatch(occupantsActions.transferUnit(table_id, data, bedroomSizeChanged, cb, ecb)),
        clearOccupantDetails: () => dispatch(occupantsActions.clearOccupantInLocal()),
        searchActions: (data) => dispatch(searchActions.occupants(data)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 15 })),
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(OccupantsList));
