import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import OrganizationsEdit from './OrganizationsEdit';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';

// Import Actions
import { orgActions } from '../../actions/organizations';

// Import Helpers
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

/**
 * OrganizationsNew Container
 * @class OrganizationsNew
 * @extends {Component}
 */
class OrganizationsNew extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.orgRef = React.createRef();
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.orgRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            this.props.addNewOrg(formData);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.push('/organizations');
    }
    /**
   * Bind Html to DOM
   */
    render() {
        const { classes, pageAccess, isSubPage } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title="Create New Organization"
                isSubPage={isSubPage}
                handleBackActionClick={this.handleBackActionClick}
            >
                <OrganizationsEdit
                    isSubPage={isSubPage}
                    pageAccess={pageAccess}
                    innerRef={(node) => { this.orgRef = node; }}
                    isReadOly={isReadOly}
                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                    newPage={true}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
OrganizationsNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool
};
/** Default props */
OrganizationsNew.defaultProps = {
    isSubPage: false,
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        pageAccess: state.pageAccess['organizationNew'] || []
    }
};
/**
 * Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        addNewOrg: (formData) => dispatch(orgActions.addNewOrg(formData))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(OrganizationsNew));
