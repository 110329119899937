import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';
import { FormControl } from '@material-ui/core';
import classNames from 'classnames';
import { createTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";

// Import Styles
import textBoxStyle from '../../assets/jss/components/textBox';
import CustomToolTip from '../../components/ToolTip/CustomToolTip';

/**
 * Outline Input Box component
 */
class OutlineTextBox extends Component {


    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            focus: false
        }
    }
    /**
     * Handle Events
     */
    handleChange = (event) => {
        this.props.handleChange(this.props.name, event.target.value)
    };

    handleonFocus = (event) => {
        this.props.onFocus(event.target)
    };

    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }

    /**
     * Bind
     * HTML to DOM
     */
    render() {
        const { margin, maxLength, showAdornment, value, name, classes, index, disabled, type, validators, errorMessages, key, errorClass } = this.props;
        const defaultTheme = createTheme({
            overrides: {
                MuiOutlinedInput: {
                    root: {
                        bottom: "4px !important",
                        paddingLeft: showAdornment ? "45px !important" : "0px",
                        "&$focused $notchedOutline": {
                            borderColor: errorClass ? errorClass.includes('error') ? "#ea4633 !important" : "#F9C128 !important" : "#36aebe !important",
                        },
                    },
                    notchedOutline: {
                        borderColor: errorClass ? errorClass.includes('error') ? "#ea4633 !important" : "#F9C128 !important" : "#36aebe !important",
                    }
                },
                MuiFormHelperText: {
                    contained: {
                        marginLeft: 0,
                        marginRight: 0
                    }
                },
                MuiTooltip: {
                    tooltip: {
                        backgroundColor: "grey",
                        color: "white",
                        fontSize: 13
                    },
                    arrow: {
                        left: "5% !important",
                        marginLeft: "-5px"
                    }
                }
            }
        });
        return (
            <div className={classNames(classes.outlineParent, showAdornment && classes.outlineNumber)}>
                {showAdornment && <div className={`${classes.outlineLabel} ${errorClass}`}>{index}</div>}
                <MuiThemeProvider theme={defaultTheme}>
                    <FormControl>
                        <CustomToolTip
                            {...this.props}
                            focus={this.state.focus}
                        >
                            <TextValidator
                                key={key}
                                name={name}
                                className={`${classes.outlineTextBox} CustomOutlineText`}
                                margin={margin}
                                type={type}
                                fullWidth={true}
                                disabled={disabled}
                                maxLength={maxLength}
                                validators={validators}
                                errorMessages={errorMessages}
                                value={value}
                                onBlur={() => {
                                    this.setState({
                                        focus: false
                                    })
                                }}
                                onFocus={(e) => {
                                    this.setState({
                                        focus: true
                                    })
                                }}
                                onChange={this.handleChange}
                                variant={'outlined'}
                                // startAdornment={showAdornment ? "kjkjkl" : ''}
                            />
                        </CustomToolTip>
                    </FormControl>
                </MuiThemeProvider>
            </div>
        );
    }
}

//default props
OutlineTextBox.defaultProps = {
    name: '',
    value: '',
    field: '',
    type: "text",
    index: 1,
    disabled: false,
    fullWidth: false,
    classes: {},
    margin: 'dense',
    maxLength: 50,
    showAdornment: false,
    handleChange: () => { },
    onInputChangeRequest: () => { },
    onFocus: () => { },
    onBlur: () => { },
    toolTipTitle: "",
    tooltipOnHover: true,
    errorClass: ''
    // focusVisible:false
}

//prop types
OutlineTextBox.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    field: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    showAdornment: PropTypes.bool,
    maxLength: PropTypes.number,
    margin: PropTypes.string,
    onInputChangeRequest: PropTypes.func.isRequired,
    toolTipTitle: PropTypes.string,
    onFocus: PropTypes.func,
    tooltipOnHover: PropTypes.bool,
    onBlur: PropTypes.func
    // focusVisible:PropTypes.bool
};

//export component
export default withStyles(textBoxStyle)(OutlineTextBox);
