import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import dashboardStyle from '../../assets/jss/containers/dashboard';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

//Actions
import { helpActions, dashboardHOActions, exploreActions, alertActions } from '../../actions';

//import helpers
import ValidatorForm from "../../helpers/fieldValidations";
import { getUserId, getUserOrgId, getUserType, globalVariable } from '../../helpers';
import { dashboardHODownloadReportTypes, orgTypesConstants } from '../../helpers/appConstants'

// import component
import AutoComplete from '../../components/AutoComplete/AutoComplete';

// Import Styles
import { DownloadIcon } from '../../components/SvgIcons/SvgIcons';

// Custom Variables
let sFields = [];
let sValues = [];

/**
 *DashboardReports
 *
 * @class DashboardReports
 * @extends {Component}
 */
class DashboardReports extends Component {
    /**
     *Creates an instance of DashboardReports.
     * @param {*} props
     * @memberof DashboardReports
     */
    constructor(props) {
        super(props);
        this.state = {
            downloadFlag: true,
            viewFlag: false,
            project: {},
            site: {},
            year: {},
            reportType: {},
            summaryDetails: {},
            reportName: '',
            reportFilter: '',
            user_id: getUserId(),
            org_id: getUserOrgId(),
            allSites:[],
            allProjects:[]
        }
        this.userRole = getUserType();

    }

    /*component will mount
      */
    UNSAFE_componentWillMount() {
        let { reportType } = this.state;
        const { explore } = this.props;
        const { page } = explore;
        this.props.getHelp();
        if (this.userRole === orgTypesConstants.FUNDER) {
            if (page.skip) {
                sFields = page.sFields;
                sValues = page.sValues;
                this.props.getAll(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues);
            } else {
                sFields = [];
                sValues = [];
                this.props.getAll(1, globalVariable.subPagetableRowSize - 3, 'report_name', 'asc', '', '');
            }
        }


        reportType = {
            value: 0,
            label: "Homeownership Annual Report"
        }

        this.setState({
            reportType: reportType
        })

        this.intervalId = setTimeout(() => {
            const { explore } = this.props;
            const { page } = explore;
            if (this.userRole === orgTypesConstants.FUNDER) {
                if (page.skip) {
                    sFields = page.sFields;
                    sValues = page.sValues;
                    this.props.getAll(page.skip, globalVariable.subPagetableRowSize - 3, page.orderBy, page.order, page.sFields, page.sValues);
                } else {
                    sFields = [];
                    sValues = [];
                    this.props.getAll(1, globalVariable.subPagetableRowSize - 3, 'report_name', 'asc', '', '');
                }
            }
        }, 120000);

        this.props.getProjectSummary(null, '');
    }

    /**
      * Initialize values to the state
      *
      * @param {*} nextProps
      * @memberof FunderExport
      */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { dashboard } = nextProps;
        let { reportType, summaryDetails } = this.state
        if (JSON.stringify(dashboard.projectSummary.summaryDetails) !== JSON.stringify(this.state.summaryDetails) && reportType.value === 1) {
            summaryDetails = dashboard.projectSummary.summaryDetails;
        }

        this.setState({
            summaryDetails: summaryDetails,
            reportType: reportType
        })
    }

    /**
      * handle download
      */
    handleDownload = (e) => {
        const { project, year, site, reportType, allSites,allProjects } = this.state;
        if (reportType.value === 0) {
            this.props.downloadReport(project._id, site.id, year.id);
        } else if(reportType.value===1) {
            let reportData = {
                "ReportId": 29,
                "year": year.value,
                "selectedReportName": reportType.label,
                "reportFilter": "",
                "projectid":project._id,
                "contractid":site.id,
                "module":'HO',
                "contractids":allSites,
                "projectids":allProjects
            }
            let userid = getUserId()
            let orgid = getUserOrgId()

            this.props.downloadFunderReport(reportData, userid, reportType.label, orgid)

        }

    };

    /**
      * Handle Clear Fileter Action
      */
    handleClearFilterActionClick = () => {
        this.setState({ reset: true });
        sFields = [];
        sValues = [];
        const { explore } = this.props;
        this.props.getAll(explore.page.skip, explore.page.limit, explore.page.orderBy, explore.page.order, [], []);
        // this.props.resetFilter();
    };

    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { explore } = this.props;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.getAll(1, globalVariable.tableRowSize, explore.page.orderBy, explore.page.order, sFields, sValues);
    };

    /**
     * On change in table
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { explore } = this.props;
        this.props.getAll(skip, limit, explore.page.orderBy, explore.page.order, sFields, sValues);
    }

    /**
     * Sorting
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { explore } = this.props;
        this.props.getAll(explore.page.skip, explore.page.limit, orderBy, order, sFields, sValues);
    };

    /**
     * Handle Table Delete Action
     * @param {*} id
     */
    handleTableDeleteAction = (id) => {
        this.props.deleteExploreData(id)
        setTimeout(() => {
            this.props.clearErrorAlert()
        }, 3000)
    };

    /**
     * Handle Download Action
     * @param {*} item
     */
    handleDownloadAction = (item) => {
        if (item.downloadStatus === "InProgress") {
            this.props.showErrorAlert("Please wait... Download is in Progress...");
        } else {
            this.props.downloadExploreReport(item._id, null, item.report_name);
        }
        setTimeout(() => {
            this.props.clearErrorAlert()
        }, 3000)
    }

    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }

    handleProjectLazyLoading = (search) => {
        const { dashboard: { projectSummary: { projects } } } = this.props;
        this.props.getProjectSummary(projects.length + 30, search);
    }

    handleProjectsSearch = (search) => {
        this.props.getProjectSummary(30, search);
    }

    /**
     * Disable download with multiple conditions
     */
    disabledDownloadButton = () => {
        let { project, year, reportType } = this.state;
        switch (reportType.value) {
            case 0:
            case 1:
                if (project.value && year.value) {
                    return false
                } else {
                    return true
                }
            // case 1:
            //     if (year.value) {
            //         return false
            //     } else {
            //         return true
            //     }
            default:
                break;
        }
    }

    /**
     *render HTML
     *
     * @returns
     * @memberof DashboardReports
     */
    render() {
        let { classes, dashboard } = this.props;
        let { projects, years, projectsCount } = dashboard.projectSummary;
        const { project, site, year, reportType } = this.state;

        let reportTypes = dashboardHODownloadReportTypes

        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];

        let reportTypeGridSize = 0;
        let projectGridSize = 0;
        let siteGridSize = 0;
        let yearGridSize = 0;
        let buttonGridSize = 3;
        switch (reportType.value) {
            case 0:
            case 1:
                if (project.value && site.value) {
                    reportTypeGridSize = 2;
                    projectGridSize = 4;
                    siteGridSize = 2;
                    yearGridSize = 2;
                    buttonGridSize = 2;
                } else if (project.value) {
                    reportTypeGridSize = 3;
                    projectGridSize = 3;
                    siteGridSize = 3;
                    buttonGridSize = 3;
                } else {
                    reportTypeGridSize = 4;
                    projectGridSize = 5;
                }
                break;
            // case 1:
            //     reportTypeGridSize = 4;
            //     yearGridSize = 3;
            //     buttonGridSize = 3;
            //     break;

            default:
                reportTypeGridSize = 9
                break;
        }
        
        if(reportType.value===1 && projects.length>0){
        //accumulate all contracts and map to all projects
        let allContracts = [{
            "id": 1,
            "name": "(All Contracts)",
            "label": "(All Contracts)",
            "value": 1
        }];

        projects.map(item=>{
            allContracts = allContracts.concat(item.sites)
        })

        let allValue = [{
            "_id": 1,
            "name": "(All Projects)",
            "label": "(All Projects)",
            "id": 1,
            "owner": 0,
            "propertyManager": [
                0
            ],
            "value": 1,
            "sites": [
                ...allContracts
            ]
        }]

        projects = allValue.concat(projects)
        }
        return (
            <div className = 'DashboardReports'>
                <div className='ReportDownloadBox d_down_report'>
                    {/* <CardContent> */}
                    {/* <div className="d_down_report"> */}
                    <ValidatorForm
                        name="projectSummaryForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)} >
                        <Grid container spacing={4}>
                            <Grid item xs={reportTypeGridSize}>
                                <AutoComplete
                                    name="reportType"
                                    suggestions={reportTypes}
                                    className="autocomplete-fixed"
                                    handleAutoCompolete={(data) => {
                                        this.setState({ reportType: data, summaryDetails: {}, reportName: "", project: {}, site: {}, year: {}, downloadFlag: false })
                                    }}
                                    selectedValue={reportType}
                                    disabled={false}
                                    placeholder="Report Type"
                                />
                            </Grid>
                            {(reportType.value!==undefined && reportType.value !== 2) &&
                                <Grid item xs={projectGridSize}>
                                    <AutoComplete
                                        name="project"
                                        suggestions={projects}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                project: selectedOption,
                                                summaryDetails: {},
                                                site: (selectedOption.value===1 && reportType.value===1)?{
                                                    "id": 1,
                                                    "name": "(All Contracts)",
                                                    "label": "(All Contracts)",
                                                    "value": 1
                                                }:{},
                                                allSites:selectedOption.sites,
                                                allProjects:(selectedOption.value===1 && reportType.value===1)?projects:[],
                                                year: {}
                                            });
                                        }}
                                        selectedValue={project}
                                        disabled={false}
                                        placeholder="Select Project"
                                        dataCount={projectsCount}
                                        hasAllProjects={true}
                                    />
                                </Grid>}
                            {(project.value && reportType.value <= 1)&& <Grid item xs={siteGridSize}>
                                <AutoComplete
                                    name="site"
                                    className="p-t-4-HO"
                                    //add all contracts value if more than one contract
                                    suggestions={(project.sites.length>1 && project.value!==1 && reportType.value===1)?[{
                                        "id": 1,
                                        "name": "(All Contracts)",
                                        "label": "(All Contracts)",
                                        "value": 1
                                    },...project.sites]:project.sites}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            site: selectedOption,
                                            year: {},
                                            summaryDetails: {},
                                            allSites: selectedOption.id===1?project.sites:[]
                                        });
                                    }}
                                    selectedValue={site}
                                    disabled={false}
                                    placeholder="Select Contract"
                                    hasAllSites={true}
                                />
                            </Grid>}
                            {((project.value && site.value) || reportType.value === 2) && <Grid item xs={yearGridSize}>
                                <AutoComplete
                                    name="dashboardReportYear"
                                    className="p-t-4-HO"
                                    suggestions={global._.sortBy(years, 'label').reverse()}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            year: selectedOption,
                                            summaryDetails: {}
                                        })
                                    }}
                                    selectedValue={year}
                                    disabled={false}
                                    placeholder="Select Year"
                                />
                            </Grid>}
                            <Grid item xs={buttonGridSize} className="textRight">
                                <Button
                                    disabled={this.disabledDownloadButton()}
                                    variant="contained" color='secondary'
                                    className={`${classes.button} downloadReportButton`}
                                    style={{ marginTop: '5px' }}
                                    onClick={this.handleDownload}
                                >
                                    {year.value ? <DownloadIcon fill='#fff' /> : <DownloadIcon />}
                                    &nbsp;&nbsp;     Download Report
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                    {/* </div> */}
                </div>
                {/* {this.userRole === orgTypesConstants.FUNDER &&
                    // <div>
                    <ContentWrapper
                        classes={classes}
                        pageAccess={pageAccess}
                        needPadding={false}
                        ischildPage={true}
                        title={"Funder Data Export"}
                        handleAddActionClick={this.handleAddActionClick}
                        handleClearFilterActionClick={() => {
                            history.replace(`${pathname}#clear`);
                        }}
                        handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                        handleHelpActionClick={this.handleHelpActionClick}
                    >
                        {explore.data &&
                            <TableComponent
                                page={explore.page.skip}
                                rowsPerPage={explore.page.limit}
                                count={explore.page.count}
                                header={fieldFilters.headerCols}
                                field={fieldFilters.fieldCols}
                                search={fieldFilters.searchCols}
                                actions={pageAccess}
                                links={["report_name"]}
                                isLoading={explore.isLoading}
                                order={explore.page.order}
                                orderBy={explore.page.orderBy}
                                data={explore.funderDataExportList}
                                onChangePageTable={this.onChangePageTable}
                                onSortRequest={this.onSortRequest}
                                onInputChangeRequest={this.onInputChangeRequest}
                                handleTableDeleteAction={this.handleTableDeleteAction}
                                handleNavigateAction={this.handleNavigateAction}
                                handleDownloadAction={this.handleDownloadAction}
                                resetSearchContent={this.props.location.hash === "#clear"}
                                paginationVisible={!explore.page.lastPage ? true : false}
                                sFields={filterSearchFields(sFields)}
                                sValues={sValues}
                                noDataErrorMessage={"No Records Found"}
                            />
                        }
                    </ContentWrapper>
                    // </div>
                } */}
            </div>
        )

    }
}

/**
 * Default Props
 */
DashboardReports.defaultProps = {
    dashboard: {
        projects: [],
        years: [],
        site: [],
        title: "Funder Data Export",
        summaryDetails: {},
        isSubPage: false,
        classes: {}
    }
};

/**
 * Bind Component Property Types
 */
DashboardReports.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.any.isRequired,
    isSubPage: PropTypes.bool,
};

// Maps state from store to props
const mapStateToProps = ({ explore, dashboardHO, help, search, pageAccess }, ownProps) => {
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['explore'] || [];
    // explore = explore;
    const helpData = help.data[0]
    const searchData = ownProps.isSubPage ? search['explore'] || { sFields: [], sValues: [] } : search['exploreList'] || { sFields: [], sValues: [] };
    return {
        pageAccess,
        dashboard: dashboardHO,
        explore,
        helpData,
        searchData
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        getAll: (skip, limit, orderBy, order, sFields, sValues) => dispatch(exploreActions.getFunderDataExports(skip, limit, orderBy, order, sFields, sValues, 'DashboardDownload', 'HomeOwnership')),
        getHelp: () => dispatch(helpActions.getAll({ _id: 7 })),
        getProjectSummary: (limit, search) => dispatch(dashboardHOActions.getProjectSummary(limit, search)),
        downloadReport: (project, site, year) => dispatch(dashboardHOActions.downloadReport(project, site, year)),
        downloadFunderReport: (reportData, user_id, reportName, org_id) => dispatch(exploreActions.downloadFunderReport(reportData, user_id, reportName, org_id)),
        deleteExploreData: (id) => dispatch(exploreActions.delete(id)),
        downloadExploreReport: (id, reportData, reportName) => dispatch(exploreActions.downloadExploreReport(id, reportData, reportName)),
        clearErrorAlert: () => dispatch(alertActions.clear()),
    }
};

// export DashboardReports
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardReports));