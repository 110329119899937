//import constants
import { confConstants } from '../constants';

//import actions
import { alertActions, projectsActions } from './';
//import services
import { confService } from "../services"
//export confActions
export const confActions = {
    changeFieldFilter,
    getFieldFilter,
    updateFieldFilter,
    getByName,
    updatedata,
    delete: _delete,
    getConfiguration,
    updateReport,
    getFunderReportTable,
    getBanner,
    getConfigurationContractActivity,
    getMoveOutReasonEffectiveYear,
    updateMoveOutReasonEffectiveYear,
    showLoading,
    hideLoading
}

/**
 *Change field filter 
 *
 * @param {*} table
 * @param {*} visibleColumns
 * @returns
 */
function changeFieldFilter(table, visibleColumns) {
    return dispatch => {
        dispatch(change(table, visibleColumns));
    };

    function change(table, visibleColumns) {
        return { type: confConstants.fieldFilter.ON_CHANGE, table, visibleColumns }
    }
}

/**
 *Get Field Filter data
 *
 * @param {*} table
 * @returns
 */
function getFieldFilter(table,year) {
    return dispatch => {
        dispatch(request(table));

        confService.getFieldFilter(table,year)
            .then(
                fieldFilter => {
                    dispatch(success(table, fieldFilter));
                },
                error => {
                    dispatch(failure(table, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(table) {
        return { type: confConstants.fieldFilter.GET_FIELDS_REQUEST, table }
    }
    function success(table, fieldFilter) {
        return { type: confConstants.fieldFilter.GET_FIELDS_SUCCESS, table, fieldFilter }
    }
    function failure(table, error) {
        return { type: confConstants.fieldFilter.GET_FIELDS_FAILURE, table, error }
    }
}

/**
 *Update Field Filter
 *
 * @param {*} table
 * @param {*} visibleColumns
 * @param {*} clback
 * @returns
 */
function updateFieldFilter(table, visibleColumns, clback) {
    return dispatch => {
        dispatch(request(table));

        confService.putFieldFilter(table, visibleColumns)
            .then(
                fieldFilter => {
                    dispatch(success(table, fieldFilter));
                    clback();
                },
                error => {
                    dispatch(failure(table, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(table) {
        return { type: confConstants.fieldFilter.PUT_FIELDS_REQUEST, table }
    }
    function success(table, fieldFilter) {
        return { type: confConstants.fieldFilter.PUT_FIELDS_SUCCESS, table, fieldFilter }
    }
    function failure(table, error) {
        return { type: confConstants.fieldFilter.PUT_FIELDS_FAILURE, table, error }
    }
}

/**
 *Get Each Configuration data
 *
 * @param {*} type - configuration type
 * @returns
 */
function getByName(type) {
    return dispatch => {
        dispatch(request());

        confService.getDataByName(type)
            .then(
                configuration => dispatch(success(configuration.type, configuration.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: confConstants.GET_REQUEST } }
    function success(name, data) { return { type: confConstants.GET_SUCCESS, name, data } }
    function failure(error) { return { type: confConstants.GET_FAILURE, error } }
}

/**
 *Updating Configuration data
 *
 * @param {*} type - configuration type
 * @param {*} values - configuration data
 * @returns
 */
function updatedata(type, values) {
    return dispatch => {
        dispatch(request());

        confService.updatedata(type, values)
            .then(
                configuration => {
                    dispatch(success(configuration.type, configuration.data));
                    dispatch(alertActions.success("Updated successfully"));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: confConstants.UPDATE_REQUEST } }
    function success(name, data) { return { type: confConstants.UPDATE_SUCCESS, name, data } }
    function failure(error) { return { type: confConstants.UPDATE_FAILURE, error } }
}

/**
 *Get config data
 *
 * @param {*} type
 * @returns
 */
function getConfiguration(type) {
    return dispatch => {
        dispatch(request());

        confService.getDataByName(type)
            .then(
                configuration => {
                    let data = configuration.data.map((data) => {
                        data.label = data.abbrev ? data.abbrev : data.name;
                        if (type === 'Race') {
                            data.label = data.desc;
                        }

                        // for bedroom
                        if (type === 'Bedrooms') {
                            data.bedCount = data.value;
                        }

                        data.value = data.key;
                        return data;
                    });

                    dispatch(success(configuration.type, data));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: confConstants.GETONE_REQUEST } }
    function success(name, data) { return { type: confConstants.GETONE_SUCCESS, name, data } }
    function failure(error) { return { type: confConstants.GETONE_FAILURE, error } }
}

/**
 *Get config data
 *
 * @param {*} type
 * @returns
 */
 function getConfigurationContractActivity(type) {
    return dispatch => {
        dispatch(request(type));
        confService.getDataByName(type)
            .then(
                configuration => {
                    let data = configuration.data.map((data) => {
                        data.label = data.abbrev ? data.abbrev : data.name;
                        if (type === 'Race') {
                            data.label = data.desc;
                        }

                        // for bedroom
                        if (type === 'Bedrooms') {
                            data.bedCount = data.value;
                        }

                        data.value = data.key;
                        return data;
                    });
                    dispatch(success(configuration.type, data));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request(type) { return { type: confConstants.GETONE_REQUEST_CONTRACTACTIVITY } }
    function success(name, data) { return { type: confConstants.GETONE_SUCCESS_CONTRACTACTIVITY, name, data } }
    function failure(error) { return { type: confConstants.GETONE_FAILURE_CONTRACTACTIVITY, error } }
}

/**
 *Delete one value of selected configuration
 *
 * @param {*} type - config type
 * @param {*} id - row index
 * @returns
 */
function _delete(type, id) {
    return dispatch => {
        dispatch(request(id));

        confService.delete(type, id)
            .then(
                configuration => {
                    // dispatch(success(id));
                    dispatch(success(configuration.type, configuration.data));
                    dispatch(alertActions.success('Deleted successfully'));
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: confConstants.DELETE_REQUEST, id } }
    function success(name, data) { return { type: confConstants.GET_SUCCESS, name, data } }
    function failure(id, error) { return { type: confConstants.DELETE_FAILURE, id, error } }
}

/**
 *Update default reporting forms
 *
 * @param {*} report - report data
 * @param {*} org_id - organization id
 * @returns
 */
function updateReport(report, org_id) {
    return dispatch => {
        dispatch(request(report, org_id));

        confService.updateReport(report, org_id)
            .then(
                FunderReportTable => {
                    dispatch(success(report));
                    dispatch(alertActions.success('Updated successfully'));
                    dispatch(projectsActions.getFunderReportTable(org_id));
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request(report) { return { type: confConstants.FUNDER_REPORT_TABLE_REQUEST, report } }
    function success(report) { return { type: confConstants.FUNDER_REPORT_TABLE_SUCCESS, report } }
    function failure(error) { return { type: confConstants.FUNDER_REPORT_TABLE_FAILURE, error } }
}

/**
 *Get Default reporting forms data
 *
 * @param {*} org_id - organization id
 * @returns
 */
function getFunderReportTable(org_id) {
    return dispatch => {
        dispatch(request());
        confService.getFunderReportTable(org_id)
            .then(
                FunderReportTable => dispatch(success(FunderReportTable)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: confConstants.GET_FUNDER_REPORT_TABLE_REQUEST } }
    function success(data) { return { type: confConstants.GET_FUNDER_REPORT_TABLE_SUCCESS, data } }
    function failure(error) { return { type: confConstants.GET_FUNDER_REPORT_TABLE_FAILURE, error } }
}

/**
*Get current Banner
*
* @param {*} 
* @returns
*/
function getBanner() {
    return dispatch => {
        dispatch(request());

        confService.getBanner()
            .then(
                configuration => dispatch(success(configuration.type, configuration.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: confConstants.GET_LATEST_BANNER_REQUEST } }
    function success(name, data) { return { type: confConstants.GET_LATEST_BANNER_SUCCESS, name, data } }
    function failure(error) { return { type: confConstants.GET_LATEST_BANNER_FAILURE, error } }
}

/**
*Get move out reason effective year
*
* @param {*} 
* @returns
*/
function getMoveOutReasonEffectiveYear() {
    return dispatch => {
        dispatch(request());

        confService.getMoveOutReasonEffectiveYear()
            .then(
                configuration => dispatch(success(configuration.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: confConstants.GET_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST } }
    function success(data) { return { type: confConstants.GET_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS, data } }
    function failure(error) { return { type: confConstants.GET_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE, error } }
}

/**
*Get move out reason effective year
*
* @param {*} 
* @returns
*/
function updateMoveOutReasonEffectiveYear(year) {
    return dispatch => {
        dispatch(request());
            confService.updateMoveOutReasonEffectiveYear(year)
            .then(
                configuration => {
                    dispatch(alertActions.success('Updated successfully'))
                    dispatch(success(year))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );        
    };

    function request() { return { type: confConstants.UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST } }
    function success(year) { return { type: confConstants.UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS, year } }
    function failure(error) { return { type: confConstants.UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE, error } }
}
/**
*Show loader
*
* @param {*} 
* @returns
*/
function showLoading(){
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: confConstants.SHOW_LOADING } }
}
/**
*Hide loader
*
* @param {*} 
* @returns
*/
function hideLoading(){
    return dispatch => {
        dispatch(request());
    };

    function request() { return { type: confConstants.HIDE_LOADING } }
}