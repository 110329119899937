const FieldValidationConstants = {
    LOAN_ACTIVITY: {
        STR_LEN30: 'Charater length cannot be more than 30.',
        STR_LEN500: 'Charater length cannot be more than 500.',
        STR_LEN250: 'Charater length cannot be more than 250.',
        STR_LEN45: 'Charater length cannot be more than 45.',
        STR_LEN50: 'Charater length cannot be more than 50.',
        STR_LEN100: 'Charater length cannot be more than 100.',
        STR_LEN1000: 'Charater length cannot be more than 1000.',
        isValue0To99: 'Value should be between 0 and 99',
        isValueTwoDecimal: 'Not more than Two decimal value.',
        isValueAlphanumericandSpecialChars: 'Accepts only A-Z-a-z-0-9_*#&/-',
        isValueAlphanumericandSpecialCharsIncludeDot: 'Accepts only A-Z-a-z-0-9_*.#&/-',
        validAddress: 'Accepts only A-Z-a-z -',
        validAddress1: 'Accepts only A-Z-a-z0-9 -',
        greatthan0: 'Must be greater than zero.'
    },
    FINANCIAL_ACTIVITY: {
        currency: 'Accepts only "0-9 ," and not more than Two decimal value.',
        isValue0To999: 'Value should be between 0 and 999',
        isValue0To999NotDecimal: 'Value should not be decimal. Value should be from 0 to 99',
        isMaxLen1000: 'Character length cannot be more than 1000.',
        //required_greatthan0: 'This field is required. Must be greater than zero.',
        greatthan0: 'This field is required, Must be greater than zero.',
        required: 'This field is required.',
        invalidDate: 'Invalid date format.',
        required_loansPayment: 'This field is required. If no loans made payments in the reporting period, please enter "0."',
        required_baddebt: 'This field is required. If no loans incurred Bad Debt, please enter "0."',
        required_revolvingfund: 'This field is required. Detailed entry is needed, cannot read "N/A" or similar if Revolving Loan Fund has been checked as "Yes" above.',
        required_textarea: 'This field is required. If not applicable, enter "N/A"',
        required_yesno: 'Must select "Yes" or "No."',
        required_htf: 'This field is required, if the contract is still drawing funds. Enter any new details for the reporting year.',
        required_publicassistance: 'This field is required, if there is other public assistance. If there is no other public assistance, please check "No".',
        required_nocash: 'This field is required. If there was no cash investment please enter "0."',
        required_dropdown: 'This field is required. Please select from drop down.',
        isValue0To30NotDecimal: 'The value entered is invalid, Must be a number from 1 to 30.'
    },
    PROGRAM_ACTIVITY: {
        required_loan: 'This field is required. If no loans were generated, please enter "0."',
        required_grant: 'This field is required. If no grants were generated, please enter "0."',
        required_deferred: 'This field is required. If no loans were deferred, please enter "0."',
        required_default: 'This field is required. If no loans were in default, please enter "0."',
        required_foreclosed: 'This field is required. If no loans were foreclosed, please enter "0."'
    },
    CERTIFICATION: {
        isNumeric: 'Accepts only "0-9 -"'
    }
}

export default FieldValidationConstants;