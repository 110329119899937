import { globalSearchConstants } from '../constants/globalSearch';

const initialState = {
    isLoading: false,
    data: []
}

export function globalSearch(state = initialState, action) {
    switch (action.type) {
        case globalSearchConstants.GETALL_REQUEST:
            return {
                ...state,
                data: [],
                isLoading: true,
            };
        case globalSearchConstants.GETALL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.globalSearch.data
            };
        case globalSearchConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        default: {
            return state
        }
    }
}