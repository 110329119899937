export const maintenanceConstants = {

    // organization listing page
    GET_ALL_REQUEST: 'MAINTENANCE_GET_ALL_REQUEST',
    GET_ALL_SUCCESS: 'MAINTENANCE_GET_ALL_SUCCESS',
    GET_ALL_FAILURE: 'MAINTENANCE_GET_ALL_FAILURE',

    // organization details page
    GET_ONE_REQUEST: 'MAINTENANCE_GET_ONE_REQUEST',
    GET_ONE_SUCCESS: 'MAINTENANCE_GET_ONE_SUCCESS',
    GET_ONE_FAILURE: 'MAINTENANCE_GET_ONE_FAILURE',

    ADD_MAINTENANCE_REQUEST: 'MAINTENANCE_NEW_REQUEST',
    ADD_MAINTENANCE_SUCCESS: 'MAINTENANCE_NEW_SUCCESS',
    ADD_MAINTENANCE_FAILURE: 'MAINTENANCE_NEW_FAILURE',

    // updateorganization
    UPDATE_MAINTENANCE_REQUEST: 'MAINTENANCE_UPDATE_REQUEST',
    UPDATE_MAINTENANCE_SUCCESS: 'MAINTENANCE_UPDATE_SUCCESS',
    UPDATE_MAINTENANCE_FAILURE: 'MAINTENANCE_UPDATE_FAILURE',

    // verifydata
    VERIFY_DATA_MAINTENANCE_REQUEST: 'VERIFY_DATA_MAINTENANCE_REQUEST',
    VERIFY_DATA_MAINTENANCE_SUCCESS: 'VERIFY_DATA_MAINTENANCE_SUCCESS',
    VERIFY_DATA_MAINTENANCE_FAILURE: 'VERIFY_DATA_MAINTENANCE_FAILURE',

    //Download
    DOWNLOAD_TEMPLATE_REQUEST: 'DOWNLOAD_TEMPLATE_REQUEST',
    DOWNLOAD_TEMPLATE_SUCCESS: 'DOWNLOAD_TEMPLATE_SUCCESS',
    DOWNLOAD_TEMPLATE_FAILURE: 'DOWNLOAD_TEMPLATE_FAILURE',

    //Logs List
    GET_LIST_LOGS_REQUEST: 'GET_LIST_LOGS_REQUEST',
    GET_LIST_LOGS_SUCCESS: 'GET_LIST_LOGS_SUCCESS',
    GET_LIST_LOGS_FAILURE: 'GET_LIST_LOGS_FAILURE',
    //Import files

    IMPORT_FILES_REQUEST: 'IMPORT_FILES_REQUEST',
    IMPORT_FILES_SUCCESS: 'IMPORT_FILES_SUCCESS',
    IMPORT_FILES_FAILURE: 'IMPORT_FILES_FAILURE',

    //Re upload the files
    REUPLOAD_REQUEST: 'REUPLOAD_REQUEST',
    REUPLOAD_SUCCESS: 'REUPLOAD_SUCCESS',
    REUPLOAD_FAILURE: 'REUPLOAD_FAILURE',

    // Cron status
    GET_CRON_STATUS_REQUEST: 'GET_CRON_STATUS_REQUEST',
    GET_CRON_STATUS_SUCCESS: 'GET_CRON_STATUS_SUCCESS',
    GET_CRON_STATUS_FAILURE: 'GET_CRON_STATUS_FAILURE',

};
