import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ListItemText, MenuItem } from '@material-ui/core';

// Components
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import TextBox from '../../components/TextBox/TextBox';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageActions from '../../components/PageActions/PageActions';
import MaterialTable from '../../components/Table/MaterialTable';
import DropDown from '../../components/DropDown/DropDown';

// Helpers
import { configurationConstants, sortByOrderUnderScoreJS_numberString } from '../../helpers';
import { history } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";
import { getSubChildCardHeight, tablesSearch } from '../../helpers/global';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Actions
import { buildingActions, projectsActions, sitesActions, unitsActions } from '../../actions';
import { confActions } from '../../actions/configuration';

/**
 * Editable text component for table
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, path, rowIndex, handleChange } = this.props;
        return (
            <TextBox
                className={'textbox-bordered-material-table'}
                name={`${path}_${rowIndex}`}
                value={data[path]}
                handleChange={(name, value) => {
                    handleChange(path, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * Editable number component for table
 * @class EditableNumber
 * @extends {Component}
 */
class EditableNumber extends Component {
    render() {
        const { data, path, rowIndex, handleChange } = this.props;
        return (
            <TextBox
                className={'textbox-bordered-material-table'}
                name={`${path}_${rowIndex}`}
                value={data[path]}
                type="number"
                handleChange={(name, value) => {
                    handleChange(path, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * Editable dropdown component for table
 * @class EditableDropDown
 * @extends {Component}
 */
class EditableDropDown extends Component {
    render() {
        const { data, path, rowIndex, handleChange, config } = this.props;
        return (
            <DropDown
                className={`autocomplete-bordered-material-table`}
                name={`${path}_${rowIndex}`}
                value={data[path]}
                handleChange={(name, value) => {
                    handleChange(path, rowIndex, value);
                }}
                handleClear={() => handleChange(path, rowIndex, "")}
                clear
            >
                {
                    config && config.map((type) => {
                        return (
                            <MenuItem key={`mut_${type.value}`} value={type.value}>
                                <ListItemText primary={type.label} className="unset-margin-tb" />
                            </MenuItem>
                        )
                    })
                }
            </DropDown>
        )
    }
};

const componentMap = {
    newUnitId: EditableText,
    newOtherId: EditableText,
    newSquareFootage: EditableNumber,
    newBedroom: EditableDropDown
}

/**
 * Units Bulk Upload Container
 * @class UnitsBulkUpload
 * @extends {React.Component}
 */
class UnitsBulkUpload extends Component {
    /**
     * Constructor
     * @param {*} props 
     * @memberof UnitsBulkUpload
     */
    constructor(props) {
        super(props);

        this.state = {
            selectedProjects: {},
            selectedSites: [],
            selectedBuildings: [],
            bulkUnitsData: [],
            bulkUnitsDataCopy: [],
            bulkUnitsPage: {},
            confirmPopup: false,
        };

        this.tableHeight = getSubChildCardHeight() - 76
    }
    /**
     * A method that is called after render
     * @memberof UnitsBulkUpload
     */
    componentDidMount() {
        this.props.getProjectNameList(null);
        this.props.getSiteNameList(null);
        this.props.getBuildingNameList(null);
        this.props.getConfiguration(configurationConstants.bedrooms);
    }
    /**
     * A method that is called on every time props received
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { bulkUnits } = nextProps;
        this.setState({ bulkUnitsData: bulkUnits.data, bulkUnitsDataCopy: bulkUnits.data, bulkUnitsPage: bulkUnits.page })
    }
    /**
     * Handle Form Submit Event
     * @memberof UnitsBulkUpload
     */
    handleSubmit = () => {
        this.setState({ confirmPopup: true })
    }
    /**
     * Handle save after confirmation
     * @param {*} e
     * @memberof UnitsBulkUpload
     */
    _saveForm = (e) => {
        const { bulkUnitsDataCopy, selectedBuildings } = this.state;
        this.props.bulkUpdate(bulkUnitsDataCopy, selectedBuildings._id, () => {
            this.props.getUnitsBulkList(1, 0, 'common_id', 'asc', '', '', [])
            this.setState({
                selectedProjects: {},
                selectedSites: [],
                selectedBuildings: [],
                bulkUnitsData: [],
                bulkUnitsDataCopy: []
            })
        })
    };
    /**
     * A method that is used to get values of the auto complete
     * @param {*} data 
     * @param {*} name 
     * @memberof UnitsBulkUpload
     * 
     * [1]  -   fetch project id from data
     * [2]  -   fetch site id from data
     * [3]  -   fetch building id from data
     */
    getValues(data, name) {
        switch (name) {
            // [1]
            case 'project':
                if (Object.keys(data).length > 0) {
                    return {
                        projectIds: [data.value],
                    };
                }
                break;
            // [2]
            case 'site':
                if (Object.keys(data).length > 0) {
                    return {
                        siteIds: [data.value]
                    };
                }
                break;
            // [3]
            case 'building':
                if (Object.keys(data).length > 0) {
                    return {
                        buildingIds: [data.value]
                    };
                }
                break;
            default:
                break;
        }
    }
    /**
     * A method that is used to handle AutoComplete
     * @param {*} data 
     * @param {*} name 
     * @memberof AutoComplete
     * 
     * [1]  -   Get site and building list based on project selected
     * [2]  -   Get building list based on site selected
     * [3]  -   Get units list based on building selected
     * [4]  -   If reducer has page values, then use reducer values else use default values to fetch units list
     */
    handleAutoCompolete = (data, name) => {
        const { bulkUnits: { page } } = this.props;
        switch (name) {
            // [1]
            case 'project':
                this.setState({ selectedProjects: data, selectedSites: [], selectedBuildings: [] })
                this.props.getSiteNameList(this.getValues(data, name))
                this.props.getBuildingNameList(this.getValues(data, name))
                break;
            // [2]
            case 'site':
                this.setState({ selectedSites: data, selectedBuildings: [] })
                this.props.getBuildingNameList(this.getValues(data, name))
                break;
            // [3]
            case 'building':
                this.setState({ selectedBuildings: data });
                // [4]
                if (page.skip) {
                    this.props.getUnitsBulkList(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, this.getValues(data, name));
                } else {
                    this.props.getUnitsBulkList(1, 0, 'common_id', 'asc', [], [], this.getValues(data, name));
                }
                break;
            default:
                break;
        }
    }
    /**
     * Handle Back Action Click
     * @memberof ContentWrapper
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
     * A method on Field component change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     * @memberof MaterialTable
     */
    fieldComponentOnChange = (fieldName, rowIndex, value) => {
        let { bulkUnitsData } = this.state;
        bulkUnitsData[rowIndex][fieldName] = value
        this.setState({ bulkUnitsData: bulkUnitsData, bulkUnitsDataCopy: bulkUnitsData })
    }
    /**
     * Handle Sort
     * @param {*} orderBy 
     * @param {*} order 
     * @memberof MaterialTable
     * 
     * [1]  -   create a copy of orderBy, inorder to sort bedroom.
     * [2]  -   If tempOrderBy is newBedroom, then create newBedroomCopy for all units bu checking the index.
     * [3]  -   Sorting in front-end
     */
    onSortRequest = (orderBy, order) => {
        const { bedrooms } = this.props;
        let { bulkUnitsData, bulkUnitsPage } = this.state;
        // [1]
        let tempOrderBy = orderBy;
        let orderNum = order === 'desc' ? -1 : 1;
        // [2]
        if (tempOrderBy === 'newBedroom') {
            tempOrderBy = 'newBedroomCopy'
            bulkUnitsData = bulkUnitsData.map(unit => {
                if (unit.newBedroom) {
                    let index = bedrooms.findIndex(elem => elem.key === unit.newBedroom);
                    if (index > -1) {
                        unit.newBedroomCopy = bedrooms[index].desc
                    }
                } else {
                    unit.newBedroomCopy = ""
                }
                return unit
            })
        }
        // [3]
        bulkUnitsData = sortByOrderUnderScoreJS_numberString(bulkUnitsData, orderNum, tempOrderBy)
        this.setState({ bulkUnitsData: bulkUnitsData, bulkUnitsPage: { ...bulkUnitsPage, orderBy: orderBy, order: order } })
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     * @memberof MaterialTable
     * 
     * [1]  -   To check wheather searchField is already in the array sFields
     *          if - Update the searchField
     *          else - Push the searchField into the array sFields
     * [2]  -   To check if the searchKey has a vale
     *          if - searchKey is empty remove the searchField from the array sFields
     *          else - Update the searchKey
     * [3]  -   Create a copy of sFields for newBedroom search
     * [4]  -   Search in front-end
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { bedrooms } = this.props;
        let { bulkUnitsData, bulkUnitsDataCopy, bulkUnitsPage } = this.state;
        let { sFields, sValues } = bulkUnitsPage;
        // [1]
        if (sFields.indexOf(searchField) >= 0) {
            // [2]
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        // [3]
        let sFieldsTemp = [...sFields];
        if (sFieldsTemp.includes('newBedroom')) {
            sFieldsTemp[sFieldsTemp.indexOf('newBedroom')] = 'newBedroomCopy'
            bulkUnitsDataCopy = bulkUnitsDataCopy.map(unit => {
                if (unit.newBedroom) {
                    let index = bedrooms.findIndex(elem => elem.key === unit.newBedroom);
                    if (index > -1) {
                        unit.newBedroomCopy = bedrooms[index].desc
                    }
                } else {
                    unit.newBedroomCopy = ""
                }
                return unit
            })
        }
        // [4]
        bulkUnitsData = tablesSearch(bulkUnitsDataCopy, sFieldsTemp, sValues)
        this.setState({ bulkUnitsData: bulkUnitsData, bulkUnitsPage: { ...bulkUnitsPage, sFields: sFields, sValues: sValues } })
    };
    
    /**
     * Handle clear filter
     * @memberof PageActions
     */
    handleClearFilterActionClick = () => {
        let { bulkUnitsDataCopy, bulkUnitsPage } = this.state;
        this.setState({ bulkUnitsData: bulkUnitsDataCopy, bulkUnitsPage: { ...bulkUnitsPage, sFields: [], sValues: [] } })
    }
    /**
     * Bind HTML to DOM
     * @memberof UnitsBulkUpload
     */
    render() {
        let { classes, pageAccess, title, projectNames, siteNames, buildingNames, bedrooms } = this.props;
        const { selectedProjects, selectedSites, selectedBuildings, confirmPopup, bulkUnitsData, bulkUnitsPage } = this.state
        const fieldFilters = bulkUnitsPage && bulkUnitsPage.fieldFilters ? bulkUnitsPage.fieldFilters : {};
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                handleBackActionClick={this.handleBackActionClick}
                isSubPage={false}
                needPadding={true}
            >
                <div className="p-24 p-b-unset">
                    <ValidatorForm
                        name="bulkForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => console.log(errors)}
                        style={{ marginBottom: "15px", marginTop: "15px" }}
                    >
                        {
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={4} className="zIndex3 bg-light-grey">
                                    <AutoComplete
                                        name="project"
                                        suggestions={projectNames}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.handleAutoCompolete(data, 'project');
                                        }}
                                        selectedValue={selectedProjects}
                                        disabled={false}
                                        module='unitbulk'
                                        placeholder="Select Project"
                                        needSelectAll
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} className="zIndex3 bg-light-grey">
                                    <AutoComplete
                                        name="site"
                                        suggestions={siteNames}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.handleAutoCompolete(data, 'site');
                                        }}
                                        selectedValue={selectedSites}
                                        disabled={selectedProjects.value ? false : true}
                                        placeholder="Select Site"
                                        module='unitbulk'
                                        needSelectAll
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} className="zIndex3 bg-light-grey">
                                    <AutoComplete
                                        name="building"
                                        suggestions={buildingNames}
                                        className="autocomplete-fixed"
                                        handleAutoCompolete={(data) => {
                                            this.handleAutoCompolete(data, 'building');
                                        }}
                                        selectedValue={selectedBuildings}
                                        disabled={selectedProjects.value ? false : true}
                                        placeholder="Select Building"
                                        module='unitbulk'
                                        needSelectAll
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} style={{ paddingLeft: 'unset', paddingRight: 'unset' }}>

                                    {selectedBuildings.value &&
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            className={`subpage`}
                                        >
                                            <Grid item xs={12} sm={8}>
                                                <PageTitle
                                                    isSubPage={true}
                                                    title={`Units (${bulkUnitsData.length})`}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <PageActions
                                                    isSubPage={true}
                                                    actions={['CLEAR']}
                                                    handleClearFilterActionClick={this.handleClearFilterActionClick}
                                                />
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        selectedBuildings.value &&
                                        <div className='unitsBulkUpdate'>
                                            <MaterialTable
                                                page={bulkUnitsPage.skip}
                                                rowsPerPage={bulkUnitsPage.limit}
                                                count={bulkUnitsPage.count}
                                                primaryHeader={fieldFilters.primaryHeaderCols}
                                                header={fieldFilters.headerCols}
                                                data={bulkUnitsData}
                                                field={fieldFilters.fieldCols}
                                                search={fieldFilters.searchCols}
                                                sFields={bulkUnitsPage.sFields}
                                                sValues={bulkUnitsPage.sValues}
                                                order={bulkUnitsPage.order}
                                                orderBy={bulkUnitsPage.orderBy}
                                                multiHeader={true}
                                                componentMap={componentMap}
                                                fieldComponentOnChange={this.fieldComponentOnChange}
                                                isLoading={false}
                                                onSortRequest={this.onSortRequest}
                                                onInputChangeRequest={this.onInputChangeRequest}
                                                resetSearchContent={false}
                                                paginationVisible={false}
                                                searchVisible={true}
                                                handleViewReportAction={this.handleViewReportAction}
                                                handleNavigateAction={this.handleNavigateAction}
                                                showLoading={true}
                                                showMsgInside={false}
                                                noDataErrorMessage={"No Records Found"}
                                                config={bedrooms}
                                                tableHeight={this.tableHeight}
                                                tableHeaderColor='grey'
                                            />
                                        </div>

                                    }
                                </Grid>
                            </Grid>
                        }
                        <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.goBack() }}
                        />
                    </ValidatorForm>
                </div>
                <DeleteDialog
                    open={confirmPopup}
                    content={<div dangerouslySetInnerHTML={{ __html: `Do you want to update the selected units in <b>${selectedBuildings.label}</b>?` }}></div>}
                    handleDeleteAction={() => {
                        this._saveForm();
                        this.setState({ confirmPopup: false })
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ confirmPopup: false })
                    }}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
UnitsBulkUpload.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

UnitsBulkUpload.defaultProps = {
    isReadOly: false,
    title: 'Bulk Upload'
}

/**
 * 
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ dashboard, projects: { projectNameList }, sites: { siteNameList }, buildings: { buildingNameList }, units: { bulkUnits }, configuration: { configurations }, pageAccess }, ownProps) => {
    let bedrooms = global._.sortBy(configurations[configurationConstants.bedrooms], 'desc');
    return {
        pageAccess: pageAccess['bulkUpload'] || [],
        projectNames: projectNameList,
        siteNames: siteNameList,
        buildingNames: buildingNameList,
        bulkUnits,
        cities: configurations[configurationConstants.cities] || [],
        bedrooms: bedrooms.map(bd => { return { ...bd, id: bd.key, label: bd.desc } })
    }
};
/**
 * Map dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getProjectNameList: (data) => dispatch(projectsActions.getProjectNameList(data)),
        getSiteNameList: (data) => dispatch(sitesActions.getSiteNameList(data)),
        getBuildingNameList: (data) => dispatch(buildingActions.getBuildingNameList(data)),
        getUnitsBulkList: (skip, limit, orderBy, order, sFields, sValues, query) => dispatch(unitsActions.getUnitsBulkList(skip, limit, orderBy, order, sFields, sValues, query)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        bulkUpdate: (data, selectedBuilding, callback) => dispatch(unitsActions.bulkUpdate(data, selectedBuilding, callback)),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(UnitsBulkUpload));

