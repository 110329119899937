import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormHelperText from '@material-ui/core/FormHelperText';

// Import Styles
import textBoxStyle from '../../assets/jss/components/textBox';

const MenuProps = {
    variant: 'menu',
    PaperProps: {
        style: {
            maxHeight: 350,
            width: 250,
            padding: 20
        },
    },
};

/**
 * Group Check Box component
 */
class GroupCheckBox extends ValidatorComponent {

    getMenuItems = () => {
        const { value, classes, options } = this.props;
        const menuItems = []
        options.forEach((obj) => {
                menuItems.push(<ListSubheader  className="groupedCheckBox">{obj.name==="Non-523 Self-Help"?"":obj.name}</ListSubheader>)
                if(obj.name==="Non-523 Self-Help"){
                obj.values.forEach((item) => {
                    //move items to left
                    menuItems.push(
                        <MenuItem className={`${classes.menuItem} ${"GrpMenuItemcustom"}`} key={item} value={item}>
                        <Checkbox checked={value.indexOf(item) > -1} />
                        <ListItemText primary={item} />
                        </MenuItem>

                    )
                })
                    }else{
                    obj.values.forEach((item) => {
                        menuItems.push(
                            <MenuItem className={`${classes.menuItem} ${"GrpMenuItem"}`} key={item} value={item}>
                            <Checkbox checked={value.indexOf(item) > -1} />
                            <ListItemText primary={item} />
                            </MenuItem>

                        )
                    })
                }            
        })
        
        return menuItems
    }
/**
 * 
 * @param {*} value 
 * @returns length or value seperated by comma
 * based in setcountvalue props
 */
    handleSelected = (value) =>{
        const {setCountValue} = this.props;
        if(setCountValue){
            //remove item for sweatEquity
            if(value.indexOf("Non-523 Self-Help")>-1){
                return `${value.length-1} Selected`;
            }else{
            return `${value.length} Selected`;
            }
        }else{
            return value.join(",");
        }
    }

    /**
     * Bind
     * HTML to DOM
     */
     renderValidatorComponent() {
        const { label, margin, value, name, classes, disabled, fullWidth, handleChange, validators, errorMessages,className } = this.props;
        return (
            <FormControl
            className={`${className} groupCheckboxContainer`}>
                <InputLabel 
                    error={!this.isValid()}
                    id="group-label">{label}</InputLabel>
                <Select
                    id="demo-mutiple-checkbox"
                    multiple
                    name={name}
                    value={value}
                    style={{padding:"1px"}}
                    renderValue={(selected)=>this.handleSelected(selected)}
                    margin={margin}
                    disabled={disabled}
                    onChange={handleChange}
                    className={classes.outlineTextBox}
                    input={<Input />}
                    fullWidth={fullWidth}
                    error={!this.isValid()}
                    validators={validators} 
                    errorMessages={errorMessages}
                    MenuProps={{
                        ...MenuProps,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                >
                    {this.getMenuItems()}
                </Select>
                {!this.isValid() && <FormHelperText error={!this.isValid()}>{this.getErrorMessage()}</FormHelperText>}
            </FormControl>
        );
    }
}

//default props
GroupCheckBox.defaultProps = {
    name: '',
    label: '',
    value: ["a", "b"],
    disabled: false,
    classes: {},
    options: [],
    margin: 'dense',
    fullWidth: false,
    handleGroupCheckBox: () => { },
    validatorListener: () => { },
    validators: [],
    setCountValue:false
}

//prop types
GroupCheckBox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.array,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    options: PropTypes.array,
    fullWidth: PropTypes.bool,
    handleGroupCheckBox: PropTypes.func.isRequired,
    setCountValue: PropTypes.bool,
};

//export component
export default withStyles(textBoxStyle)(GroupCheckBox);