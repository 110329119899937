import { Component } from "react";
import PropTypes from 'prop-types';
import { Element } from 'react-faux-dom';
import * as d3 from "d3";

// Import Components
import ResponsiveWrapper from '../ResponsiveWrapper';

/**
 *Group Chart Component
 *
 * @class GroupBarChart
 * @extends {Component}
 */
class GroupBarChart extends Component {
    /**
     * Draw Chart
     * @returns 
     */
    drawChart() {

        const el = new Element('div');
        const svgDimensions = {
            //selects the maximum width from the two parameters passed
            width: Math.max(this.props.parentWidth, 300),
            height: 338
        };

        d3.select('.barChart').selectAll("*").remove();

        let svg = d3.select(el)
            .append('svg')
            .attr("width", svgDimensions.width)
            .attr("height", svgDimensions.height);

        let margin = { top: 20, right: 250, bottom: 40, left: 100 },
            width = +svgDimensions.width - margin.left - margin.right,
            height = svgDimensions.height - margin.top - margin.bottom;

        let chart = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        this.plot(chart, width, height, margin);

        return el.toReact();
    }

    /**
     * Plot Chart
     * @param {*} chart 
     * @param {*} width 
     * @param {*} height 
     * @param {*} margin 
     */
    plot(chart, width, height, margin) {

        let data = this.props.data;

        var x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1);

        var x1 = d3.scaleBand()
            .padding(0.25);

        var y = d3.scaleLinear()
            .rangeRound([height, 0]);

        var divTooltip = d3.select(".toolTip") //.append("div").attr("class", "toolTip");

        var z = d3.scaleOrdinal().range(['#FAC962', '#678BFE', '#6EE4D8', '#EF9FB7', "#C7C8CE"]);

        var keys = ['Not Required', 'Funder(s)', 'Contractor / Owner', 'Property Manager', 'Never Submitted'];

        x0.domain(data.map(function (d) { return d.TableName; }));
        x1.domain(keys).rangeRound([0, x0.bandwidth()]);

        y.domain([0, 100]); //d3.max(data, function (d) { return d3.max(keys, function (key) { return d[key]; }); })]).nice();

        let chargroup = chart.append("g")
            .attr("samp", function (d) { return "something" });

        chargroup.selectAll("g")
            .data(data)
            .enter().append("g")
            .attr("transform", function (d) { return "translate(" + x0(d.TableName) + ",0)"; })
            .selectAll("rect")
            .data(function (d) { return keys.map(function (key) { return { key: key, value: d[key] }; }); })
            .enter().append("rect")
            .attr("x", function (d) { return x1(d.key); })
            .attr("y", function (d) { return y(d.value); })
            .attr('value', function (d) { return d.key + " : " + d.value + "%" })
            .attr("width", x1.bandwidth())
            .attr("height", function (d) { return height - y(d.value); })
            .attr("fill", function (d) { return z(d.key); })

        chart.append("g")
            .attr("class", "axis")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x0));

        chart.append("g")
            .attr("class", "axis")
            .call(d3.axisLeft(y)
                .ticks(5)
                .tickFormat(d => d + ' %'))
            .attr('class', 'gridline')
            .append("text")
            .attr("x", 2)
            .attr("y", y(y.ticks().pop()) + 0.5)
            .attr("dy", "0.32em")
            .attr("fill", "#000")
            .attr("font-weight", "bold")
            .attr("text-anchor", "start");


        var legend = chart.append("g")
            .attr("font-family", "sans-serif")
            .attr("font-size", 10)
            .attr("text-anchor", "start")
            .selectAll("g")
            .data(keys.slice())
            .enter().append("g")
            .attr("transform", function (d, i) { return "translate(200," + i * 25 + ")"; })

        legend.append("rect")
            .attr("x", width - 150)
            .attr("y", "6rem")
            .attr("width", 25)
            .attr("height", 15)
            .attr("fill", z)

        legend.append("text")
            .attr("x", width - 110)
            .attr("y", 9.5)
            .attr("dy", "6.2rem")
            .text(function (d) { return d; });

        chargroup.on("mousemove", function (d) {
            divTooltip.style("z-index", '99999');
            divTooltip.style("left", d3.event.pageX - 35 + "px");
            divTooltip.style("top", d3.event.pageY - 50 + "px");
            divTooltip.style("display", "inline-block");
            let elements = document.querySelectorAll(':hover');
            let l = elements.length;
            l = l - 1;
            const value = elements[l].getAttribute("value");
            if (value)
                divTooltip.html(value);
        });

        chargroup.on("mouseout", function (d) {
            divTooltip.style("display", "none");
        });

    }
    /**
     *render html
     *
     * @returns
     * @memberof GroupBarChart
     */
    render() {
        return this.drawChart();
    }
}
//Prop Types
GroupBarChart.propTypes = {
    data: PropTypes.array.isRequired
};
//Default Props
GroupBarChart.defaultProps = {
    data: []
}
/**Export Component */
export default ResponsiveWrapper(GroupBarChart);