import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Tab components
import General from './tabs/General';
import FundersRouter from '../Funders/Funders.Router';
import Sites from './tabs/Sites';
import Reports from './tabs/Reports';
import ReportsRouter from '../Reports/Reports.Router';
import FundersEdit from '../Funders/FundersEdit';
import SitesNew from '../Sites/SitesNew';
import SitesView from '../Sites/SitesView';
import BuildingView from '../Buildings/BuildingView';
import BuildingsList from '../Buildings/BuildingsList';
import UnitsList from '../Units/UnitsList';
import UAView from '../UtilityAllowance/UAView';
import UnitsEdit from '../Units/UnitsEdit';
import UsersView from '../Users/UsersView';
// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { history, getUserRole, getUserId, getUserOrgId } from '../../helpers';
import { getTitle } from '../../helpers/setTitle';

// Actions
import { archivesActions } from '../../actions/archives';
import { helpActions } from '../../actions';

const helpMap = {
    table1: 2,
    table2: 3,
    table3: 3,
    table4: 4
}

/**
 * Archive View container
 * @class ArchiveView
 * @extends Component
 */
class ArchiveView extends Component {

    /**
     * Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            openHelp: false,
            helpTitle: '',
            selectedTable: ''
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
    }

    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount = () => {
        if (this.props.location.state) {
            this.handleReportTable(this.props.location.state.page)
        } else {
            let pathNames = this.props.location.pathname.split('/')
            let latestPathName = pathNames[pathNames.length - 1]
            this.handleReportTable(latestPathName)
        }
    }

    /**
     * Handle Tab Change Event
     * @param {object} event 
     * @param {number} value 
     * 
     * [1]  -   General tab
     * [2]  -   Funders List tab
     * [3]  -   Sites List tab
     * [4]  -   Reports List tab
     */
    handleTabChange = (event, value) => {
        const { match, projectId } = this.props;
        switch (value) {
            // [1]
            case 0:
                history.push({
                    pathname: `/archives/${projectId}`,
                    state: {
                        page: 'archiveEdit',
                        pageData: {
                            project_id: projectId,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [2]
            case 1:
                history.push({
                    pathname: `/archives/${projectId}/funders`,
                    state: {
                        page: 'fundersList',
                        pageData: {
                            project_id: projectId,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [3]
            case 2:
                history.push({
                    pathname: `/archives/${projectId}/sites`,
                    state: {
                        page: 'sitesList',
                        pageData: {
                            project_id: projectId,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            // [4]
            default:
                history.push({
                    pathname: `/archives/${projectId}/reports`,
                    state: {
                        page: 'reportList',
                        pageData: {
                            project_id: projectId,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
        }
    };

    /**
    * Handle Clear Fileter Action
    */
    handleClearFilterActionClick = () => {
        const { location: { pathname } } = this.props;
        history.replace(`${pathname}#clear`);
    };


    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.archiveRef.refs.form.submit();
    };

    /**
     * Handle Form Save Action Click
     * @param {number} id 
     * @param {object} formData 
     */
    handleFormSaveActionClick = (id, formData) => {
        if (formData) {
            this.props.updateProject(id, formData);
        }
    };

    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
     * Handle Report table select for help topics
     * @param {string} table 
     * 
     * [1]  -   Table 1
     * [2]  -   Table 2
     * [3]  -   Table 3
     * [4]  -   Table 4
     */
    handleReportTable = (table) => {
        switch (table) {
            // [1]
            case 'table1':
                this.props.getHelp(helpMap['table1']);
                this.setState({ helpTitle: 'Report Table 1', selectedTable: 'table1' })
                break;
            // [2]
            case 'table2':
                this.props.getHelp(helpMap['table2']);
                this.setState({ helpTitle: 'Report Tables 2 and 3', selectedTable: 'table2' })
                break;
            // [3]
            case 'table3':
                this.props.getHelp(helpMap['table3']);
                this.setState({ helpTitle: 'Report Tables 2 and 3', selectedTable: 'table3' })
                break;
            // [4]
            case 'table4':
                this.props.getHelp(helpMap['table4']);
                this.setState({ helpTitle: 'Report Table 4', selectedTable: 'table4' })
                break;
            default:
                break;
        }
    }

    /**
     * Bind Html to DOM
     * 
     * [1]  -   Condition to check if help topics are loaded based on table selected
     * [2]  -   To show help icon except report cover page
     */
    render() {
        let { classes, match, loading, projectId, location: { pathname },
            pageAccess, needPadding, pageTitle, helpData } = this.props;
        const { openHelp, helpTitle, selectedTable } = this.state
        match.url = match.url.replace(/\/$/, "");
        const currentTab = pathname.includes('/funders') ? 1 : (pathname.includes('/sites') ? 2 : (pathname.includes('/reports') ? 3 : 0))

        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        // [1]
        if (this.props.location.state) {
            if (this.props.location.state.page !== selectedTable) {
                this.handleReportTable(this.props.location.state.page);
            }
        }
        let pathNames = this.props.location.pathname.split('/')
        let latestPathName = pathNames[pathNames.length - 1]
        // [2]
        if (latestPathName === 'table1' || latestPathName === 'table2' || latestPathName === 'table4' || latestPathName === 'table3') {
            pageAccess = [...pageAccess, 'HELP']
        }
        let isArchiveFunderPage = history.location.pathname.split("/").length === 5 && history.location.pathname.split("/")[3] === 'funders'
        return (

            <ContentWrapper
                classes={classes}
                pageAccess={[...pageAccess, 'BACK']}
                subTitle={pageTitle.subTitle}
                title={pageTitle.title}
                handleAddActionClick={this.handleAddActionClick}
                handleBackActionClick={this.handleBackActionClick}
                needPadding={needPadding}
                handleSaveActionClick={this.handleSaveActionClick}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                isArchiveFunderPage={isArchiveFunderPage}
            >

                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="GENERAL" value={0} />
                        <Tab label="FUNDERS" value={1} />
                        <Tab label="SITES" value={2} />
                        <Tab label="REPORTS" value={3} />
                    </Tabs>

                </AppBar>
                {loading &&
                    <LinearProgress className='tabLoader' />
                }
                <TabContainer>
                    <Switch>
                        <Route
                            exact
                            path={`${match.url}/:project_id`}
                            render={(props) => {
                                return (
                                    <General
                                        pageAccess={pageAccess}
                                        isReadOly={isReadOly}
                                        projectId={projectId}
                                        innerRef={(node) => { this.archiveRef = node; }}
                                        handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={`${match.url}/${projectId}/funders`}
                            render={(props) => {
                                console.log('EnteredFunderRedirect')
                                return (
                                    <FundersRouter
                                        {...props}
                                        isSubPage={true}
                                        projectId={projectId}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={`${match.url}/${projectId}/sites`}
                            render={(props) => {
                                return (
                                    <Sites
                                        projectId={projectId}
                                        isSubPage={true}
                                        {...props}
                                    />
                                )
                            }}
                        />
                        <Route
                            path={`/archives/:project_id/reports`}
                            render={(props) => {
                                return (
                                    <Reports
                                        {...props}
                                        isSubPage={true}
                                        projectId={projectId}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={`${match.url}/${projectId}/reports/:report_id/:year`} render={props => (
                                <ReportsRouter {...props}
                                    isSubPage={true}
                                    match={match}
                                    projectId={projectId}
                                    page={'archives'} />
                            )} />
                        <Route exact path={`/archives/:project_id/funders/:funderId`} render={props => {
                            props.orgId = getUserOrgId();
                            props.id = parseInt(history.location.pathname.split("/")[4]);
                            props.classes = {
                                "root": "FundersEdit-root-6357",
                                "subPage": "FundersEdit-subPage-6358",
                                "image": "FundersEdit-image-6359",
                                "buttons": "FundersEdit-buttons-6360",
                                "textField": "FundersEdit-textField-6361",
                                "nopadding": "FundersEdit-nopadding-6362",
                                "rootEdit": "FundersEdit-rootEdit-6363",
                                "rootEditSubPage": "FundersEdit-rootEditSubPage-6364",
                                "textFieldNoBorder": "FundersEdit-textFieldNoBorder-6365",
                                "leftSliderIcon": "FundersEdit-leftSliderIcon-6366",
                                "rightSliderIcon": "FundersEdit-rightSliderIcon-6367",
                                "rightSliderIconContainer": "FundersEdit-rightSliderIconContainer-6368",
                                "leftSliderIconContainer": "FundersEdit-leftSliderIconContainer-6369",
                                "autoCompleteTop": "FundersEdit-autoCompleteTop-6370",
                                "autoCompleteTop19": "FundersEdit-autoCompleteTop19-6371",
                                "pb5": "FundersEdit-pb5-6372",
                                "pb10": "FundersEdit-pb10-6373",
                                "mr10": "FundersEdit-mr10-6374",
                                "orangeBtnLink": "FundersEdit-orangeBtnLink-6375",
                                "mw96": "FundersEdit-mw96-6376",
                                "LinkPadding": "FundersEdit-LinkPadding-6377",
                                "activityContainer": "FundersEdit-activityContainer-6378",
                                "ContractActivityContainer": "FundersEdit-ContractActivityContainer-6379",
                                "inlineCheckBoxes": "FundersEdit-inlineCheckBoxes-6380",
                                "textAreaBr0": "FundersEdit-textAreaBr0-6381",
                                "plr30": "FundersEdit-plr30-6382",
                                "pageClrText": "FundersEdit-pageClrText-6383",
                                "loanactvitylist": "FundersEdit-loanactvitylist-6384"
                            }
                            return <FundersEdit {...props}
                                match={match}
                                projectId={props.match.params.project_id}
                                page={'archives'} />
                        }}
                        />

                        <Route exact path={`${match.url}/new`} render={props => (
                            <SitesNew {...props}
                                projectId={projectId}
                                removeActions={['BACK']}
                                isSubPage={true} />
                        )} />

                        <Route exact path={`/archives/:project_id/sites/:site_id`} render={props => {
                            console.log('SITE_ID', history.location.pathname.split("/"))
                            return (
                                <SitesView
                                    {...props}
                                    projectId={parseInt(history.location.pathname.split("/")[2])}
                                    siteId={parseInt(history.location.pathname.split("/")[4])}
                                    isSubPage={true}
                                    removeActions={['BACK']}
                                    isArchived={true}
                                />
                            )
                        }} />

                        <Route exact path={'/archives/:project_id/sites/:site_id/buildings'} render={(props) => {
                            return <BuildingsList
                                {...props}
                                page={'buildings'}
                                siteId={parseInt(history.location.pathname.split("/")[4])}
                                title={''}
                                projectId={parseInt(history.location.pathname.split("/")[2])}
                                isSubPage={true} />
                        }} />

                        <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id`} render={props => {
                            return <BuildingView
                                buildingId={parseInt(history.location.pathname.split("/")[6])}
                                isSubPage={true}
                                title={''}
                                {...props}
                                siteId={parseInt(history.location.pathname.split("/")[4])}
                                projectId={parseInt(history.location.pathname.split("/")[2])}
                                isArchived={true}
                            />
                        }} />
                        <Route path={`/archives/:project_id/sites/:site_id/buildings/:building_id/ua`} render={props => {
                            return (
                                <UAView
                                    siteId={parseInt(history.location.pathname.split("/")[4])}
                                    buildingId={parseInt(history.location.pathname.split("/")[6])} {...props} page={'ua'}
                                    isArchived={true}
                                    isSubPage={true}
                                />
                            )
                        }} />

                        <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id`} render={props => {
                            return <BuildingView
                                buildingId={parseInt(history.location.pathname.split("/")[6])}
                                isSubPage={true}
                                title={''}
                                {...props}
                                siteId={parseInt(history.location.pathname.split("/")[4])}
                                projectId={parseInt(history.location.pathname.split("/")[2])}
                                isArchived={true}
                            />
                        }} />

                        <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id/units`} render={props => {
                            return <UnitsList
                                siteId={parseInt(history.location.pathname.split("/")[4])}
                                projectId={parseInt(history.location.pathname.split("/")[2])}
                                buildingId={parseInt(history.location.pathname.split("/")[6])}
                                {...props}
                                isSubPage={true}
                                isArchived={true}
                                title={''}
                                page={'Units'}
                            />
                        }} />

                        <Route exact path={`/archives/:project_id/sites/:site_id/buildings/:building_id/units/:unit_id`} render={props => {
                            return <UnitsEdit
                                match={match}
                                pageAccess={['BACK']}
                                unitid={parseInt(history.location.pathname.split("/")[7])}
                                isReadOly={true}
                                isArchived={true}
                            />
                        }} />
                        <Route exact path={`/users/:id/:page`} render={(props) => {
                            console.log('EnteredUsersView')
                            return <UsersView match={props.match} page='userEdit' {...props} />
                        }} />
                    </Switch>
                </TabContainer>
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title={helpTitle}
                />}
            </ContentWrapper>

        );
    }
}

/**
 * Bind Component Property Types
 */
ArchiveView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    needPadding: PropTypes.bool,
    loading: PropTypes.bool
};

ArchiveView.defaultProps = {
    isSubPage: false,
    pageAccess: [],
    title: '',
    needPadding: false,
    loading: false
}

/**
 * Maps state from store to props
 * @param {object} state 
 * @param {object} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const { archives, pageAccess } = state;
    const { history, location: { state: locState } } = ownProps;
    const statePageTitle = locState || {};
    // const pageTitle = statePageTitle.pageTitle === undefined ? getTitle(history.location.pathname, state) : statePageTitle.pageTitle || {};
    const pageTitle = getTitle(history.location.pathname, state) === undefined ? statePageTitle.pageTitle || {} : getTitle(history.location.pathname, state);
    const helpData = state.help.data[0]
    return {
        details: archives.getOne,
        pageAccess: pageAccess['archiveEdit'] || [],
        loading: state.archives.isLoading,
        pageTitle,
        helpData
    }
};

/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (id, formData) => dispatch(archivesActions.update(id, formData)),
        getById: (id) => dispatch(archivesActions.getById(id)),
        getHelp: (id) => dispatch(helpActions.getAll({ _id: id })),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ArchiveView));
