import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _ from 'underscore';
import { Typography } from '@material-ui/core';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { history, getOrgType } from '../../../helpers'

// Import Components
import OutlineTextBox from '../../../components/OutlineTextBox/OutlineTextBox';
import MultipleTextArea from '../../../components/MultipleTextAreaHO/MultipleTextArea'
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TableErrors from '../../../components/TableErrors/TableErrors';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import CheckboxValidator from "../../../components/CheckBox/CheckBox";

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
import FieldValidationConstants from "../../../helpers/fieldValidationConstants"

// import Actions
import { reportActivityActions } from '../../../actions/reportActivity';
import { alertActions } from '../../../actions/alert';
import { validationActions } from '../../../actions/validation';

// import helpers
import { focusHelper } from '../../../helpers/global';
import ProgramActivityValidations from '../../../helpers/TableValidations/programActivity';
import FinancialActivityValidations from '../../../helpers/TableValidations/financialActivity';
import ContractActivityValidations from '../../../helpers/TableValidations/contractActivity';
import NumberField from '../../../components/NumberField/NumberFieldToolTip';

class FinancialActivity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalFundedamount: 0,
            totalLoansReceivable: 0,
            totalHousingTrustFund: 0,
            valuePaymentsHomeOwners: 0,
            numberofloanswithpayment: '',
            array_numberofloanswithpayment: [],
            numberofLoansSatisfied: '',
            array_numberofLoansSatisfied: [],
            badDebtIncurred: 0,
            numberofLoansBadDebt: '',
            array_numberofLoansBadDebt: [],
            isRevolvingLoanFund: "",
            totalAnnualAdministrativeExpenses: 0,
            balanceFundsAvailableHomeWonership: 0,
            please_HowRevolvingLoanFundsAdministered: '',
            please_ExplainExtraordinaryCircumstances: '',
            isContractStillDrawingFunds: null,
            isContractStillDrawingFunds_ExplainHowHTFApplied: '',
            explainAnyExtradinaryCircumtanceArray: [],
            explainHowHTPAwardDollorsAppliedArray: [],
            explainHowRevolvingLoanFundsArray: [],
            RevolvingLoanFundsCommentData: {},
            ExtradinaryCircumtanceCommentData: {},
            HTPAwardDollorsAppliedCommentData: {},
            prevYear: false,
            isFormNotSaved: false
        };

        this.onwerName = "";
        this.userRole = getOrgType();
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.section6 = React.createRef();
    }

    /**
    * Component Did Mount event
    */
    componentDidMount() {
        const reportYear_id = history.location.pathname.split("/")[4]
        this.props.getByIdCertificationForm(reportYear_id);
        this.props.getReportActivityAccess(reportYear_id, () => { })
        this.props.getByIdProgramActivity(reportYear_id, () => { });
        this.props.getByIdFinancialActivity(reportYear_id, () => { });
        this.props.getByReportIdContractActivity(reportYear_id, () => { });
    }

    UNSAFE_componentWillMount() {
        let reportYear_id = history.location.pathname.split("/")[4]
        if (reportYear_id)
            this.props.validationIssue(reportYear_id);
    }

    /**
    * Component Will receive props event
    */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { financialActivity } = nextProps;
        const { isFormNotSaved } = this.state;
        if (financialActivity && financialActivity.prevYear && !isFormNotSaved) {
            this.setState({
                totalFundedamount: financialActivity.totalFundedAmtAllFunders,
                totalLoansReceivable: financialActivity.totalLoansReceivableAmt,
                totalHousingTrustFund: financialActivity.totalHousingTrustFundDollarsReceived,
                isRevolvingLoanFund: financialActivity.isRevolvingLoanFund,
                please_HowRevolvingLoanFundsAdministered: financialActivity.explainHowRevolvingLoanFunds,
                please_ExplainExtraordinaryCircumstances: financialActivity.explainAnyExtradinaryCircumtance,
                explainAnyExtradinaryCircumtanceArray: financialActivity.explainAnyExtradinaryCircumtanceArray,
                explainHowRevolvingLoanFundsArray: financialActivity.explainHowRevolvingLoanFundsArray,
                RevolvingLoanFundsCommentData: financialActivity.RevolvingLoanFundsCommentData,
                ExtradinaryCircumtanceCommentData: financialActivity.ExtradinaryCircumtanceCommentData,
                prevYear: financialActivity.prevYear
            });
        } else if (financialActivity && !financialActivity.prevYear && !isFormNotSaved) {
            this.setState({
                totalFundedamount: financialActivity.totalFundedAmtAllFunders,
                totalLoansReceivable: financialActivity.totalLoansReceivableAmt,
                totalHousingTrustFund: financialActivity.totalHousingTrustFundDollarsReceived,
                valuePaymentsHomeOwners: financialActivity.totalAmtPaymentReceivedIncludeSatisfiedLoans,
                numberofloanswithpayment: financialActivity.numberOfHomeOwnersMadePayment === 0 ? "0" : financialActivity.numberOfHomeOwnersMadePayment,
                array_numberofloanswithpayment: financialActivity.homeOwnersMadePayments,
                numberofLoansSatisfied: (financialActivity.numberOfLoansStatisfied === 0) ? "0" : financialActivity.numberOfLoansStatisfied,
                array_numberofLoansSatisfied: financialActivity.loansStatisfied === 0 ? "0" : financialActivity.loansStatisfied,
                badDebtIncurred: financialActivity.totalBadDebtIncurred,
                numberofLoansBadDebt: financialActivity.numberOfLoansBadDebtIncurred === 0 ? "0" : financialActivity.numberOfLoansBadDebtIncurred,
                array_numberofLoansBadDebt: financialActivity.loansBadDebtIncurred,
                isRevolvingLoanFund: financialActivity.isRevolvingLoanFund,
                totalAnnualAdministrativeExpenses: financialActivity.totalAnnualAdministrativeExpenses === null ? "0.00" : financialActivity.totalAnnualAdministrativeExpenses,
                balanceFundsAvailableHomeWonership: financialActivity.totalBalanceFundsAvailable === null ? "0.00" : financialActivity.totalBalanceFundsAvailable,
                please_HowRevolvingLoanFundsAdministered: financialActivity.explainHowRevolvingLoanFunds,
                please_ExplainExtraordinaryCircumstances: financialActivity.explainAnyExtradinaryCircumtance,
                isContractStillDrawingFunds: financialActivity.isContractStillDrawingFunds,
                isContractStillDrawingFunds_ExplainHowHTFApplied: financialActivity.explainHowHTPAwardDollorsApplied,
                explainAnyExtradinaryCircumtanceArray: financialActivity.explainAnyExtradinaryCircumtanceArray,
                explainHowHTPAwardDollorsAppliedArray: financialActivity.explainHowHTPAwardDollorsAppliedArray,
                explainHowRevolvingLoanFundsArray: financialActivity.explainHowRevolvingLoanFundsArray,
                RevolvingLoanFundsCommentData: financialActivity.RevolvingLoanFundsCommentData,
                ExtradinaryCircumtanceCommentData: financialActivity.ExtradinaryCircumtanceCommentData,
                HTPAwardDollorsAppliedCommentData: financialActivity.HTPAwardDollorsAppliedCommentData,
                prevYear: financialActivity.prevYear
            })
        }

        //Handle section navigation
        //Redirected from contractActivity validations
        if(this.props.location.state.content){
            this.scrollToContent(this.props.location.state.content)
        }
    }

    /**
    * Handle Input Change
    * @param {C} Name
    * @param {C} value
    */
    handleChange = (name, value) => {

        let { isRevolvingLoanFund, isContractStillDrawingFunds } = this.state;
        //handle the empty string in textfields
        if (value === "")
            switch (name) {
                case "numberofloanswithpayment":
                    this.setState({
                        array_numberofloanswithpayment: _.range(parseInt(value)).map(m => this.state['array_numberofloanswithpayment'][m] || null),
                        isFormNotSaved: true
                    })
                    break;
                case "numberofLoansSatisfied":
                    this.setState({
                        array_numberofLoansSatisfied: _.range(parseInt(value)).map(m => this.state['array_numberofLoansSatisfied'][m] || null),
                        isFormNotSaved: true
                    })
                    break;
                case "numberofLoansBadDebt":
                    this.setState({
                        array_numberofLoansBadDebt: _.range(parseInt(value)).map(m => this.state['array_numberofLoansBadDebt'][m] || null),
                        isFormNotSaved: true
                    })
                    break;
                default:
                    break;
            }
        if (value !== '')
            switch (name) {
                case "numberofloanswithpayment":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofloanswithpayment: _.range(parseInt(value)).map(m => this.state['array_numberofloanswithpayment'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                case "numberofLoansSatisfied":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofLoansSatisfied: _.range(parseInt(value)).map(m => this.state['array_numberofLoansSatisfied'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                case "numberofLoansBadDebt":
                    if (value >= 0 && value <= 99) {
                        this.setState({
                            array_numberofLoansBadDebt: _.range(parseInt(value)).map(m => this.state['array_numberofLoansBadDebt'][m] || null),
                            isFormNotSaved: true
                        })
                    };
                    break;
                case "isRevolvingLoanFund":
                    this.setState({
                        isRevolvingLoanFund: isRevolvingLoanFund === "" ? value : !isRevolvingLoanFund,
                        isFormNotSaved: true
                    });
                    break;
                case "isContractStillDrawingFunds":
                    this.setState({
                        isContractStillDrawingFunds: isContractStillDrawingFunds === "" || isContractStillDrawingFunds === null ? value : !isContractStillDrawingFunds,
                        isFormNotSaved: true
                    });
                    break;
                default:
                    break;
            }
        if (name !== "isRevolvingLoanFund" && name !== "isContractStillDrawingFunds") {
            this.setState({
                [name]: value,
                isFormNotSaved: true
            });
        }
    }

    /**
    * Handle Dollar value
    * @param {C} Name
    * @param {C} value
    */
    handleChangeDollarValues = (name, value, ele) => {
        value = value.slice(1);
        this.setState({
            [name]: value,
            isFormNotSaved: true
        });
    }

    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
    * Update Text field
    */
    updateTextField = (name, index, value) => {
        const {
            array_numberofloanswithpayment,
            array_numberofLoansSatisfied,
            array_numberofLoansBadDebt,
        } = this.state;

        switch (name) {
            case `please_IdentifyLoanswithPaymentsMade_${index}`:
                array_numberofloanswithpayment[index] = value;
                break;
            case `please_IdentifyLoansSatisfied_${index}`:
                array_numberofLoansSatisfied[index] = value;
                break;
            case `Please_IdentifyLoansBadDebt_${index}`:
                array_numberofLoansBadDebt[index] = value;
                break;
            default:
                break;
        };

        this.setState({
            array_numberofloanswithpayment: array_numberofloanswithpayment,
            array_numberofLoansSatisfied: array_numberofLoansSatisfied,
            array_numberofLoansBadDebt: array_numberofLoansBadDebt,
            isFormNotSaved: true
        })
    }

    /**
    * Handle Submit
    */
    handleSubmit = (e) => {
        let { isRevolvingLoanFund, isContractStillDrawingFunds, totalAnnualAdministrativeExpenses, balanceFundsAvailableHomeWonership, please_HowRevolvingLoanFundsAdministered, isContractStillDrawingFunds_ExplainHowHTFApplied } = this.state;
        //set 6a and 6b empty if no revolving
        if (isRevolvingLoanFund === false) {
            totalAnnualAdministrativeExpenses = 0;
            balanceFundsAvailableHomeWonership = 0;
            please_HowRevolvingLoanFundsAdministered = '';
        }
        if (isContractStillDrawingFunds === false) {
            isContractStillDrawingFunds_ExplainHowHTFApplied = ''
        }
        this.setState({
            totalAnnualAdministrativeExpenses: totalAnnualAdministrativeExpenses,
            balanceFundsAvailableHomeWonership: balanceFundsAvailableHomeWonership,
            please_HowRevolvingLoanFundsAdministered: please_HowRevolvingLoanFundsAdministered,
            isContractStillDrawingFunds_ExplainHowHTFApplied: isContractStillDrawingFunds_ExplainHowHTFApplied,
            isFormNotSaved: false
        })
        this._saveForm();
    }
    /**
    * Tooltip props
    * @param {C} fieldName
    */
    getTooltipProps = (fieldName) => {

        return {
            focusVisible: this.state.focusVisible && this.state.focusFieldName === fieldName,
            onFocus: (ele) => {
                if (fieldName === "totalFundedamount" || fieldName === "totalLoansReceivable" || fieldName === "totalHousingTrustFund" || fieldName === "badDebtIncurred" || fieldName === "valuePaymentsHomeOwners"
                    || fieldName === "balanceFundsAvailableHomeWonership" || fieldName === "totalAnnualAdministrativeExpenses") {
                    this.setState({ focusField: ele.target, focusFieldName: fieldName, focusVisible: true })
                } else {
                    this.setState({ focusField: ele, focusFieldName: fieldName, focusVisible: true })
                }

            },
            onBlur: () => this.setState({ focusField: null, focusFieldName: "", focusVisible: false })                                        // validators={['required', 'isStringLength250', 'validAddress']}
        }
    }

    /**
    * Maps props to state
    * @param {C} ele
    */
    chechInView = (ele) => {
        var { focusField } = this.state
        if (focusField) {
            const { top: parentTop, bottom: parentBottom } = ele.getBoundingClientRect()
            const { top: childTop } = focusField.getBoundingClientRect()
            this.setState({ focusVisible: childTop + 25 > parentTop && childTop < parentBottom })
        }
    }
    /**
     * Handle Tab change
     */
    handleTabChange() {
        const { isFormNotSaved } = this.state;
        return isFormNotSaved
    }
    /**
    * form save
    */
    _saveForm() {
        const { location: { state }, financialActivity, contractActivitySavedOnce } = this.props;
        const reportYear_id = state.pageData.reportYear_id ? state.pageData.reportYear_id : null;
        let programActivityfieldErrors = [];
        let financialActivityfieldErrors = [];
        let contractActivityfieldErrors = [];

        let generalData = {
            ...this.state,
            reportYear_id: reportYear_id,
            financialActivity: financialActivity ? financialActivity._id : null
        }

        if (financialActivity && !financialActivity.prevYear) {
            this.props.updateFinancialActivity(generalData, () => {
                this.props.getByIdFinancialActivity(reportYear_id, (data) => {
                    contractActivityfieldErrors = ContractActivityValidations({ programActivity: this.props.programActivity, financialActivity: data.data, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                    programActivityfieldErrors = ProgramActivityValidations({ ...this.props, financialActivity: data.data });
                    
                    financialActivityfieldErrors = FinancialActivityValidations({ programActivity: this.props.programActivity, financialActivity: data.data, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                    console.log("🚀 ~ file: FinancialActivity.jsx:366 ~ FinancialActivity ~ this.props.getByIdFinancialActivity ~ reportData:", this.propsreportData)
                    console.log("🚀 ~ file: FinancialActivity.jsx:366 ~ FinancialActivity ~ this.props.getByIdFinancialActivity ~ data.data:", data.data)
                    console.log("🚀 ~ file: FinancialActivity.jsx:366 ~ FinancialActivity ~ this.props.getByIdFinancialActivity ~ this.props.programActivity:", this.props.programActivity)
                    this.props.getByIdFinancialActivity(reportYear_id, () => { 
                    let validationData = {
                        reportYear_id: reportYear_id,
                        errors: [...programActivityfieldErrors, ...financialActivityfieldErrors, ...contractActivityfieldErrors]
                    }
                    this.props.validationErrors(validationData, () => {
                        this.props.validationIssue(reportYear_id);
                    });
                });
            });
        });
        } else {
            this.props.createFinancialActivity(generalData, () => {
                this.props.getByIdFinancialActivity(reportYear_id, (data) => {
                    contractActivityfieldErrors = ContractActivityValidations({ programActivity: this.props.programActivity, financialActivity: data.data, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });
                    programActivityfieldErrors = ProgramActivityValidations({ ...this.props, financialActivity: data.data });
                    financialActivityfieldErrors = FinancialActivityValidations({ programActivity: this.props.programActivity, financialActivity: data.data, reportData: this.props.reportData, contractActivitySavedOnce: contractActivitySavedOnce });

                    let validationData = {
                        reportYear_id: reportYear_id,
                        errors: [...programActivityfieldErrors, ...financialActivityfieldErrors, ...contractActivityfieldErrors]
                    }
                    this.props.validationErrors(validationData, () => {
                        this.props.getByIdFinancialActivity(reportYear_id, () => { });
                        this.props.validationIssue(reportYear_id);
                    });
                });
            });
        }
    }
    /**
     * Scroll to Validation Content
     */
    scrollToContent(content) {
        if (content.sectionIndex) {
            switch (content.sectionIndex) {
                case 1:
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 2:
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 3:
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 4:
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 5:
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 6:
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }
    }
    /**
    * Handle Unit Select in table error
    * @param {*} data 
    */
    handleUnit = (data, content) => {
        if (content.sectionIndex) {
            switch (content.sectionIndex) {
                case 1:
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 2:
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 3:
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 4:
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 5:
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 6:
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }
    }
    /**
     * Bind HTML to DOM
     */
    render() {
        const { classes, pageAccess, programActivity, reportTables, validationIssues } = this.props;
        validationIssues && validationIssues.financialActivity && validationIssues.financialActivity.forEach(item => {
            item.msg = item.message;
        })
        const isFirstReportingYear = pageAccess.indexOf('DISABLEFIELD') === -1;
        const isRevolvingFund = pageAccess.indexOf('REV_FUND') === -1;
        let isReadOnly = pageAccess.indexOf('SAVE') === -1;
        const { userRole } = this;
        const funderValidation = userRole === 'Funder' ? false : true;

        let {
            totalFundedamount,
            totalLoansReceivable,
            totalHousingTrustFund,
            valuePaymentsHomeOwners,
            numberofloanswithpayment,
            array_numberofloanswithpayment,
            numberofLoansSatisfied,
            array_numberofLoansSatisfied,
            badDebtIncurred,
            numberofLoansBadDebt,
            array_numberofLoansBadDebt,
            isRevolvingLoanFund,
            totalAnnualAdministrativeExpenses,
            balanceFundsAvailableHomeWonership,
            please_HowRevolvingLoanFundsAdministered,
            please_ExplainExtraordinaryCircumstances,
            isContractStillDrawingFunds_ExplainHowHTFApplied,
            isContractStillDrawingFunds,
            explainAnyExtradinaryCircumtanceArray,
            explainHowHTPAwardDollorsAppliedArray,
            explainHowRevolvingLoanFundsArray
        } = this.state;
        let fielderrors = validationIssues && validationIssues.financialActivity && validationIssues.financialActivity;
        //Error sorting hard stop and warning
        fielderrors = fielderrors && fielderrors.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))

        let hardstopSort = fielderrors && fielderrors.filter((item, index) => item.errorType === 1);
        let warningSort = fielderrors && fielderrors.filter((item, index) => item.errorType === 2);

        if ((hardstopSort && hardstopSort.length > 0) && (warningSort && warningSort.length > 0)) {
            fielderrors = hardstopSort.concat(warningSort)
        } else if ((hardstopSort && hardstopSort.length > 0) && (warningSort && warningSort.length === 0)) {
            fielderrors = hardstopSort;
        } else {
            fielderrors = warningSort;
        }
        if (reportTables.financialActivity === false) {
            return <h4 className="section_title textCenter">{'This Activity is not currently required by any Funder for this report year'}</h4>;
        }

        //get report level
        let level = ''
        // let levelid = 0

        if (!programActivity) {
            level = 'Report currently at Preparer level'
        } else if (programActivity && programActivity.submissionStatus && programActivity.submissionStatus.length === 0) {
            level = 'Report currently at Preparer level'
            // levelid = 1
        } else if (programActivity && programActivity.submissionStatus && programActivity.submissionStatus.length > 0) {
            let getlevel = programActivity.submissionStatus[(programActivity.submissionStatus.length) - 1]
            if (getlevel.levelTo_id === 1) {
                level = 'Report currently at Preparer level'
                // levelid = 1
            } else if (getlevel.levelTo_id === 2) {
                level = 'Report currently at Contractor level'
                // levelid = 2
            } else if (getlevel.levelTo_id === 3) {
                level = 'Report currently at Funder level'
                // levelid = 3
            }
        }
        return (
            <Grid className={`${classes.activityContainer} financialFull`}>
                <ContentWrapper
                    classes={classes}
                    //pageAccess={['HELP']}
                    title={''}
                    needTitleBar={false}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    titleBg={'white'}
                    pageName="reports"
                    onScrollChange={(ele) => { this.chechInView(ele) }}
                >
                    <TableErrors
                        fielderrors={fielderrors || []}
                        data={[]}
                        errorPage={'reportActivity'}
                        classes={{
                            action: classes.action,
                            message: classes.message,
                            root: classes.root
                        }}
                        validations={[]}
                        handleClickAction={(err) => this.scrollToContent(err)}
                        handleUnitSelect={(data, content) => this.handleUnit(data, content)}
                    />
                    <div className={classes.rootEdit} >
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log(errors)} >

                            {/* Grid Content */}
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classes.pageClrText}><Typography variant="h4" class="report_level_title_HO">{level}</Typography></Grid>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">1. Total funded amount from all funders for this project as of 12/31</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <NumberField
                                                toolTipClassName="financialTopTextBox"
                                                variant="outlined"
                                                toolTipTitle={focusHelper().totalAmtFund}
                                                name="totalFundedamount"
                                                value={isRevolvingFund || funderValidation || totalFundedamount ? totalFundedamount : 0.00}
                                                tooltipOnHover={!(isReadOnly)}
                                                {...this.getTooltipProps("totalFundedamount")}
                                                validators={funderValidation ? ['required', 'currency'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.currency] : []}
                                                className={`${classes.textField} modifiedNumberField`}
                                                fullWidth={false}
                                                //type={"number"}
                                                handleChange={this.handleChange}
                                                prefix="$"
                                                margin="dense"
                                                disabled={isReadOnly}
                                                zeroFlag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">2. Total loans receivable amount as of 12/31</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <NumberField
                                                toolTipClassName="righSecondtOutlined"
                                                name="totalLoansReceivable"
                                                variant="outlined"
                                                value={isRevolvingFund || funderValidation || totalLoansReceivable ? totalLoansReceivable : 0.00}
                                                toolTipTitle={focusHelper().totalLoanReceivable}
                                                tooltipOnHover={!(isReadOnly)}
                                                {...this.getTooltipProps("totalLoansReceivable")}
                                                validators={funderValidation ? ['required', 'currency'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.currency] : []}
                                                className={`${classes.textField} modifiedNumberField`}
                                                fullWidth={false}
                                                // type={"number"}
                                                prefix="$"
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                zeroFlag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">3. Total Housing Trust Fund dollars received as of 12/31</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <NumberField
                                                toolTipClassName="righSecondtOutlined"
                                                name="totalHousingTrustFund"
                                                variant="outlined"
                                                value={isRevolvingFund || funderValidation || totalHousingTrustFund ? totalHousingTrustFund : 0.00}
                                                toolTipTitle={focusHelper().housingTrust}
                                                tooltipOnHover={!(isReadOnly)}
                                                {...this.getTooltipProps("totalHousingTrustFund")}
                                                validators={funderValidation ? ['required', 'currency'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.currency] : []}
                                                className={`${classes.textField} modifiedNumberField`}
                                                fullWidth={false}
                                                // type={"number"}
                                                prefix="$"
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                zeroFlag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header finanfour" variant="body1">4. Total amount of payments including satisfied loans received during this period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <NumberField
                                                toolTipClassName="righSecondtOutlined"
                                                {...this.getTooltipProps("valuePaymentsHomeOwners")}
                                                toolTipTitle={focusHelper().valueOfPayment}
                                                tooltipOnHover={!isReadOnly}
                                                name="valuePaymentsHomeOwners"
                                                variant="outlined"
                                                value={isRevolvingFund || funderValidation || valuePaymentsHomeOwners ? valuePaymentsHomeOwners : 0.00}
                                                validators={funderValidation ? ['required'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required] : []}
                                                className={`${classes.textField} modifiedNumberField`} fullWidth={false}
                                                // type={"number"}
                                                prefix="$"
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                zeroFlag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} ref={this.section1}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">4a. Number of loans with payments in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righSecondtOutlinedNum"
                                                name="numberofloanswithpayment"
                                                value={isRevolvingFund || funderValidation || numberofloanswithpayment ? numberofloanswithpayment : null}
                                                toolTipTitle={focusHelper().makingPayment}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("numberofloanswithpayment")}
                                                validators={funderValidation ? ['required', 'isValue0To999NotDecimal'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal] : []}
                                                className={classes.textField}
                                                fullWidth={false}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 1).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 1 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} ref={this.section2}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the loans with payments in the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofloanswithpayment.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                toolTipTitle={focusHelper().pleasenoOfloansWithPayment}
                                                                {...this.getTooltipProps("")}
                                                                showAdornment={true}
                                                                name={`please_IdentifyLoanswithPaymentsMade_${index}`}
                                                                value={isRevolvingFund || funderValidation || array_numberofloanswithpayment[index] ? array_numberofloanswithpayment[index] : null}
                                                                index={index + 1}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOnly}
                                                                validators={funderValidation && CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : funderValidation ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot'] : []}
                                                                errorMessages={funderValidation && CheckDuplicateActivity ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot] : []}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 2 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 2 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} ref={this.section3}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">4b. Number of loans satisfied in the reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righSecondtOutlinedNum"
                                                name="numberofLoansSatisfied"
                                                value={isRevolvingFund || funderValidation || numberofLoansSatisfied ? numberofLoansSatisfied : null}
                                                toolTipTitle={focusHelper().loanSatisfied}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("numberofLoansSatisfied")}
                                                validators={funderValidation ? ['required', 'isValue0To999NotDecimal'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal] : []}
                                                className={classes.textField}
                                                type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 3).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 3 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} ref={this.section4}>
                                    <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the loans satisfied in the reporting period</Typography>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofLoansSatisfied.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                toolTipTitle={focusHelper().pleaseloanSatisfied}
                                                                {...this.getTooltipProps("")}
                                                                showAdornment={true}
                                                                name={`please_IdentifyLoansSatisfied_${index}`}
                                                                value={isRevolvingFund || funderValidation || array_numberofLoansSatisfied[index] ? array_numberofLoansSatisfied[index] : null}
                                                                index={index + 1}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOnly}
                                                                validators={funderValidation && CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : funderValidation ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot'] : []}
                                                                errorMessages={funderValidation && CheckDuplicateActivity ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot] : []}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 4 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 4 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">5. Bad debt incurred during this reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <NumberField
                                                toolTipClassName="righSecondtOutlined"
                                                variant="outlined"
                                                name="badDebtIncurred"
                                                value={isRevolvingFund || funderValidation || badDebtIncurred ? badDebtIncurred : 0.00}
                                                toolTipTitle={focusHelper().badDebt}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("badDebtIncurred")}
                                                validators={funderValidation ? ['required', 'currency'] : []}
                                                errorMessages={funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.currency] : []}
                                                className={`${classes.textField} modifiedNumberField`} fullWidth={false}
                                                // type={"number"}
                                                prefix={"$"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                zeroFlag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} ref={this.section5}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">5a. Number of loans that incurred bad debt</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <OutlineTextBox
                                                toolTipClassName="righSecondtOutlinedNum"
                                                name="numberofLoansBadDebt"
                                                value={isRevolvingFund || funderValidation || numberofLoansBadDebt ? numberofLoansBadDebt : null}
                                                validators={funderValidation ? ['required', 'isValue0To999NotDecimal'] : []}
                                                errorMessages={funderValidation ? ['This field is required. If no loans incurred Bad Debt, please enter "0."', FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To999NotDecimal] : []}
                                                className={classes.textField}
                                                type={"number"}
                                                toolTipTitle={focusHelper().noOfbadDebt}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("numberofLoansBadDebt")}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 5).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 5 && elem.errorType === 1).length > 0 ? "errorClassOT" : "warinigClassOT" : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} ref={this.section6}>
                                    <Grid sm={6}>
                                        <Typography variant="body1" className={classes.pb5}>Please identify the record number(s) of the loans that incurred bad debt in the reporting period</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={4}>
                                            {
                                                array_numberofLoansBadDebt.map((item, index, inputArray) => {
                                                    let CheckDuplicateActivity = inputArray.some((elem, j) => (elem === item && elem !== '' && index !== j))
                                                    return (
                                                        <Grid item xs={12} sm={3}>
                                                            <OutlineTextBox
                                                                toolTipTitle={focusHelper().pleasenoOfbadDebt}
                                                                {...this.getTooltipProps("")}
                                                                showAdornment={true}
                                                                name={`Please_IdentifyLoansBadDebt_${index}`}
                                                                value={isRevolvingFund || funderValidation || array_numberofLoansBadDebt[index] ? array_numberofLoansBadDebt[index] : null}
                                                                index={index + 1}
                                                                validators={funderValidation && CheckDuplicateActivity ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot', 'isDuplicateActivity'] : funderValidation ? ['required', 'isStringLength50', 'isValueAlphanumericandSpecialCharsIncludeDot'] : []}
                                                                errorMessages={funderValidation && CheckDuplicateActivity ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot, 'Record number already entered'] : funderValidation ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50, FieldValidationConstants.LOAN_ACTIVITY.isValueAlphanumericandSpecialCharsIncludeDot] : []}
                                                                className={classes.textField}
                                                                handleChange={(name, value) => {
                                                                    this.updateTextField(name, index, value)
                                                                }}
                                                                margin="dense"
                                                                disabled={isReadOnly}
                                                                errorClass={fielderrors && fielderrors.filter(elem => elem.sectionIndex === 6 && elem.units.some(unit => unit === item)).length > 0 ? fielderrors.filter(elem => elem.sectionIndex === 6 && elem.errorType === 1 && elem.units.some(unit => unit === item)).length > 0 ? "errorClassBG" : "warningClassBG" : ''}
                                                            />
                                                        </Grid>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        {/* <Grid item xs={12} sm={12} className={classes.pb10}> */}
                                        <Typography className="required-header" variant="body1">6. Is this revolving loan fund?</Typography>
                                        {/* </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                                <CheckboxValidator
                                                    toolTipTitle={focusHelper().isRevolving}
                                                    name={"isRevolvingLoanFund"}
                                                    checked={isRevolvingLoanFund === true}
                                                    value={isRevolvingLoanFund}
                                                    handleChange={(name, checked, ele) => { this.handleChange(name, checked ? true : false, ele) }}
                                                    disabled={isRevolvingFund}
                                                    validators={['required']}
                                                    label={"Yes"}
                                                    color={"primary"}
                                                />
                                                <CheckboxValidator
                                                    toolTipTitle={focusHelper().isRevolving}
                                                    name={"isRevolvingLoanFund"}
                                                    checked={isRevolvingLoanFund === false}
                                                    value={isRevolvingLoanFund}
                                                    handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                    disabled={isRevolvingFund}
                                                    validators={['required']}
                                                    label={"No"}
                                                    color={"primary"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {isRevolvingLoanFund && <><Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={6}>
                                            <Typography className="required-header" variant="body1">6a. Total annual administrative expenses incurred during this reporting period</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <NumberField
                                                toolTipClassName="sixthrighSecondtOutlined"
                                                variant="outlined"
                                                prefix={"$"}
                                                name="totalAnnualAdministrativeExpenses"
                                                value={isRevolvingFund || funderValidation || totalAnnualAdministrativeExpenses ? totalAnnualAdministrativeExpenses : 0.00}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("totalAnnualAdministrativeExpenses")}
                                                toolTipTitle={focusHelper().annualAdminExpenses}
                                                validators={userRole !== 'Funder' ? ['required', 'currency'] : []}
                                                errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.currency]}
                                                className={`${classes.textField} modifiedNumberField`}
                                                // type={"number"}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                                zeroFlag={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} sm={6}>
                                                <Typography className="required-header" variant="body1">6b. Balance of all funds available for home ownership activities as of 12/31</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <NumberField
                                                    toolTipClassName="sixthrighSecondtOutlined"
                                                    variant="outlined"
                                                    prefix={"$"}
                                                    name="balanceFundsAvailableHomeWonership"
                                                    value={isRevolvingFund || funderValidation || balanceFundsAvailableHomeWonership ? balanceFundsAvailableHomeWonership : 0.00}
                                                    tooltipOnHover={!isReadOnly}
                                                    {...this.getTooltipProps("balanceFundsAvailableHomeWonership")}
                                                    toolTipTitle={focusHelper().balanceFund}
                                                    validators={userRole !== 'Funder' ? ['required', 'currency'] : []}
                                                    errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.currency]}
                                                    className={`${classes.textField} modifiedNumberField`}
                                                    // type={"number"}
                                                    handleChange={this.handleChange}
                                                    margin="dense"
                                                    disabled={isReadOnly}
                                                    zeroFlag={true}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container alignItems="center">
                                            <Grid item xs={12} sm={12}>
                                                <Typography className="required-header" variant="body1">6c. Please explain how Revolving Loan Funds are currently being administered and loans to new Homeowners</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <MultipleTextArea
                                                    toolTipClassName="textAreaTooltip"
                                                    fullWidth={true}
                                                    name="please_HowRevolvingLoanFundsAdministered"
                                                    value={isRevolvingFund || funderValidation || please_HowRevolvingLoanFundsAdministered ? please_HowRevolvingLoanFundsAdministered || "" : ""}
                                                    tooltipOnHover={!isReadOnly}
                                                    {...this.getTooltipProps("please_HowRevolvingLoanFundsAdministered")}
                                                    toolTipTitle={focusHelper().explainRevolvingLoan}
                                                    dataArray={explainHowRevolvingLoanFundsArray}
                                                    validators={userRole !== 'Funder' ? ['required', 'isMaxLen1000'] : []}
                                                    errorMessages={['This field is required. Detailed entry is needed, cannot read "N/A" or similar if Revolving Loan Fund has been checked as "Yes" above.', FieldValidationConstants.FINANCIAL_ACTIVITY.isMaxLen1000]}
                                                    className={classes.textField}
                                                    handleChange={this.handleChange}
                                                    margin="dense"
                                                    disabled={isReadOnly}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid></>}

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1" className={`${classes.pb5} required-header`}>7. Please Explain any Extraordinary Circumstances or Activities that Occurred with your Program or Properties</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} className={classes.textAreaBr0}>
                                            <MultipleTextArea
                                                toolTipClassName="topTextBoxFinancial"
                                                fullWidth={true}
                                                name="please_ExplainExtraordinaryCircumstances"
                                                value={isRevolvingFund || funderValidation || please_ExplainExtraordinaryCircumstances ? please_ExplainExtraordinaryCircumstances || "" : ""}
                                                dataArray={explainAnyExtradinaryCircumtanceArray}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("please_ExplainExtraordinaryCircumstances")}
                                                toolTipTitle={focusHelper().extraorinaryCircum}

                                                validators={funderValidation ? ['required', 'isMaxLen1000'] : []}
                                                errorMessages={['This field is required. If not applicable, enter "N/A"', FieldValidationConstants.FINANCIAL_ACTIVITY.isMaxLen1000]}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        {/* <Grid item xs={12} sm={12} className={classes.pb10}> */}
                                        <Typography className="required-header" variant="body1">8. Is the contract still drawing funds ?</Typography>
                                        {/* </Grid> */}
                                        <Grid item xs={12} sm={12}>
                                            <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                                <CheckboxValidator
                                                    toolTipTitle={focusHelper().isContractDrawing}
                                                    name={"isContractStillDrawingFunds"}
                                                    checked={isContractStillDrawingFunds === true}
                                                    value={isRevolvingFund || funderValidation || isContractStillDrawingFunds ? isContractStillDrawingFunds : null}
                                                    handleChange={(name, checked, ele) => { this.handleChange(name, checked ? true : false, ele) }}
                                                    disabled={isReadOnly}
                                                    validators={funderValidation ? ['required'] : []}
                                                    label={"Yes"}
                                                    color={"primary"}
                                                    inboxRequired={true}

                                                />
                                                <CheckboxValidator
                                                    toolTipTitle={focusHelper().isContractDrawing}
                                                    name={"isContractStillDrawingFunds"}
                                                    checked={isContractStillDrawingFunds === false}
                                                    value={isRevolvingFund || funderValidation || isContractStillDrawingFunds ? isContractStillDrawingFunds : null}
                                                    handleChange={(name, checked, ele) => { this.handleChange(name, checked ? false : true, ele) }}
                                                    disabled={isReadOnly}
                                                    validators={funderValidation ? ['required'] : []}
                                                    label={"No"}
                                                    color={"primary"}
                                                    inboxRequired={true}

                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {isContractStillDrawingFunds && <Grid item xs={12}>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1" className={`${classes.pb5} required-header`}>8a. Please explain how HTF award dollars are currently being applied</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <MultipleTextArea
                                                toolTipClassName="fintextAreaTooltipLast"
                                                fullWidth={true}
                                                name="isContractStillDrawingFunds_ExplainHowHTFApplied"
                                                value={isRevolvingFund || funderValidation || isContractStillDrawingFunds_ExplainHowHTFApplied ? isContractStillDrawingFunds_ExplainHowHTFApplied || "" : ""}
                                                dataArray={explainHowHTPAwardDollorsAppliedArray}
                                                validators={isContractStillDrawingFunds ? ['required', 'isMaxLen1000'] : []}
                                                errorMessages={['This field is required, if the contract is still drawing funds. Enter any new details for the reporting year.', FieldValidationConstants.FINANCIAL_ACTIVITY.isMaxLen1000]}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                tooltipOnHover={!isReadOnly}
                                                {...this.getTooltipProps("isContractStillDrawingFunds_ExplainHowHTFApplied")}
                                                toolTipTitle={focusHelper().contractDrawingFund}
                                                margin="dense"
                                                disabled={isReadOnly}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>}

                            </Grid>

                            {(!isReadOnly || !isRevolvingFund) &&
                                <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />
                            }

                        </ValidatorForm>

                    </div>
                </ContentWrapper>
            </Grid>
        );
    }
}

/**
 * Bind Component Property Types
 */
FinancialActivity.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

/**
 * Default props
 */
FinancialActivity.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        programActivity: state.reportActivity.programActivity.data,
        financialActivity: state.reportActivity.financialActivity.data,
        reportData: state.reportActivity.contractActivityReportData,
        pageAccess: state.pageAccess['financialActivity'] || [],
        reportTables: state.reportActivity.reportTableAccess.reportTables,
        contractActivitySavedOnce: state.reportActivity.reportTableAccess.contractActivitySavedOnce,
        validationIssues: state.reportActivity.validations.data,
    }
};


/**
 * Maps state to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getReportActivityAccess: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getReportActivityAccess(reportYear_id, successCallBack)),
        getByIdCertificationForm: (reportYear_id) => dispatch(reportActivityActions.getByIdCertificationForm(reportYear_id)),
        getByIdProgramActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByIdProgramActivity(reportYear_id, successCallBack)),
        getByIdFinancialActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByIdFinancialActivity(reportYear_id, successCallBack)),
        getByReportIdContractActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByReportIdContractActivity(reportYear_id, successCallBack)),
        createFinancialActivity: (formData, successCallBack) => dispatch(reportActivityActions.createFinancialActivity(formData, successCallBack)),
        updateFinancialActivity: (formData, successCallBack) => dispatch(reportActivityActions.updateFinancialActivity(formData, successCallBack)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        validationErrors: (validationErrors, callBack) => dispatch(validationActions.activityValidations(validationErrors, callBack)),
        validationIssue: (reportYear_id) => dispatch(reportActivityActions.validationIssue(reportYear_id)),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(FinancialActivity));