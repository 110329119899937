import { UnitsConstants } from '../constants/units';


const initialState = {
    isLoading: false,
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['common_id'],
            searchCols: []
        }
    },
    reset: false,
    getOne: {
        data: null,
        isLoading: false,
        history: {
            data: [],
            page: {
                fieldFilters: {
                    headerCols: [],
                    fieldCols: ['common_id'],
                    searchCols: []
                }
            }
        }
    },
    addUnit: {
        buildings: []
    },
    bulkUnits: {
        data: [],
        page: {
            fieldFilters: {
                primaryHeaderCols: ['Unit Id', 'Other Id', 'Area Sq. Ft', '#BDRMS'],
                headerCols: ['Current', 'New', 'Current', 'New', 'Current', 'New', 'Current', 'New'],
                fieldCols: ['common_id', 'newUnitId', 'other_id', 'newOtherId', 'squareFootage', 'newSquareFootage', 'bedroom.desc', 'newBedroom'],
                searchCols: []
            }
        },
    },
    unitTable1List: false
}

export function units(state = initialState, action) {
    switch (action.type) {
        case UnitsConstants.GETALL_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                },
                isLoading: true
            };
        case UnitsConstants.GETALL_SUCCESS:
            // let units = action.units.data.map((item) => {
            //     item.squareFootage = parseInt(item.squareFootage, 10).toLocaleString('en-IN', { maximumSignificantDigits: 3 }); 
            //     return item;   
            // });
            return {
                ...state,
                ...action.units,
                isLoading: false
            };
        case UnitsConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.ADD_UNIT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UnitsConstants.ADD_UNIT_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case UnitsConstants.ADD_UNIT_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.UPDATE_UNIT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UnitsConstants.UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case UnitsConstants.UPDATE_UNIT_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                },
                isLoading: true
            };
        case UnitsConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: action.unit.data
                },
                isLoading: false
            };
        case UnitsConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.DELETE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UnitsConstants.DELETE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(unit => parseInt(unit._id, 10) !== parseInt(action.id, 10)),
                isLoading: false
            };

        case UnitsConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.DELETE_MSG_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UnitsConstants.DELETE_MSG_SUCCESS:
            return {
                ...state,
                isLoading: false
            };

        case UnitsConstants.DELETE_MSG_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        // To retrive Unit History data
        case UnitsConstants.GETONE_UH_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UnitsConstants.GETONE_UH_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    history: {
                        ...action.data
                    }
                },
                isLoading: false
            };
        case UnitsConstants.GETONE_UH_FAILURE:
            return {
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.GET_BUILDINGS_FOR_SITE_REQUEST:
            return {
                ...state,
                addUnit: {
                    ...state.addUnit,
                    buildings: []
                },
                isLoading: true
            };
        case UnitsConstants.GET_BUILDINGS_FOR_SITE_SUCCESS:
            return {
                ...state,
                addUnit: {
                    ...state.addUnit,
                    buildings: action.data.map((building) => {
                        building.label = building.name;
                        building.value = building._id;
                        return building;
                    })
                },
                isLoading: false
            };
        case UnitsConstants.GET_BUILDINGS_FOR_SITE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case UnitsConstants.GET_UNITSBULKLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case UnitsConstants.GET_UNITSBULKLIST_SUCCESS:
            return {
                ...state,
                bulkUnits: {
                    data: action.data.data,
                    page: action.data.page,
                },
                isLoading: false
            }

        case UnitsConstants.GET_UNITSBULKLIST_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case UnitsConstants.UNITS_BULKUPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case UnitsConstants.UNITS_BULKUPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case UnitsConstants.UNITS_BULKUPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        case UnitsConstants.GET_UNIT_TABLE1_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case UnitsConstants.GET_UNIT_TABLE1_SUCCESS:
            return {
                ...state,
                unitTable1List: action.data.data,
                isLoading: false
            }
        case UnitsConstants.GET_UNIT_TABLE1_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }
        default:
            return state
    }
}