import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

//import styles
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  input: {
    display: 'flex',
    height: 16
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    display: 'none'
  },
});
/**
 * Options
 * @param {*} props 
 * @param {*} some 
 * @returns 
 */
function Option(props, some) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: props.isSelected ? 'green' : '#000',
      }}
      {...props.innerProps}
    >
      <Checkbox checked={props.isSelected} />
      <ListItemText primary={props.data.label} />
    </MenuItem>
  );
}
const componentsMap = {
  Option
};

const MAX_CONTENT = 30;

/**
 * Multiple Autocomple Component
 * @class AutoCompleteMulti
 * @extends {React.Component}
 */
class AutoCompleteMulti extends React.Component {
  state = {
    autocomplete: null,
    focus: false,
    search: '',
    suggestions: [],
    suggestionCount: 0,
    setOpen: false
  };

  componentDidMount() {
    let { autocomplete, suggestions, suggestionCount } = this.state;
    const { selectedValue, needSelectAll } = this.props;
    if (selectedValue && autocomplete) {
      if (selectedValue.value !== autocomplete.value) {
        if (needSelectAll) {
          autocomplete = [{ value: 0, label: "Select All" }, ...selectedValue]
        } else {
          autocomplete = selectedValue
        }
      }
    }
    suggestionCount = this.props.suggestions.length
    suggestions = this.props.suggestions.slice(0, MAX_CONTENT);
    this.setState({
      autocomplete,
      suggestions,
      suggestionCount
    })
  }
  /**
 * Array comparer - To check if array datas are same
 * @param {*} otherArray 
 * @returns 
 */
  arrayComparer = (otherArray) => {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.label === current.label && other.display === current.display
      }).length === 0;
    }
  }
  
  UNSAFE_componentWillReceiveProps(newProps) {
    let { autocomplete, suggestions, suggestionCount } = this.state;
    const { selectedValue } = newProps;
    // [1]
    if (selectedValue && autocomplete) {
      // [2]
      if (selectedValue.value !== autocomplete.value) {
        autocomplete = selectedValue;
      }
    } else if (selectedValue && (selectedValue.value || selectedValue.value === 0) && selectedValue.label) {
      // [3]
      autocomplete = selectedValue;
    }
    // [4]
    if (suggestions && suggestions.length >0 && (suggestions.filter(this.arrayComparer(newProps.suggestions.slice(0, suggestions.length))).length !== 0 || suggestions.length === 0)) {
      suggestionCount = newProps.suggestions.length
      suggestions = newProps.suggestions.slice(0, MAX_CONTENT);
    }
    this.setState({
      autocomplete,
      suggestions,
      suggestionCount
    })
  }
  /**
   * Handle change Event for Textfields
   * @param {*} value 
   * @param {*} param1 
   */
  handleChange = (value, { action, option }) => {
    switch (action) {
      case "select-option":
        if (option.value === 0) {
          value = this.props.suggestions;
        }
        break;
      case "deselect-option":
        if (option.value === 0) {
          value = null;
        } else {
          value = value.filter((val) => val.value !== 0)
        }
        break;
      default: break;
    }
    this.props.handleAutoCompolete(value);
  };

  handleSearch = (search) => {
    let { suggestions, suggestionCount } = this.state;
    let regexText = new RegExp(search, 'i');
    suggestions = this.props.suggestions.filter(elem => regexText.test(elem.label));
    suggestionCount = suggestions.length;
    suggestions = suggestions.slice(0, MAX_CONTENT);
    this.setState({ suggestions, suggestionCount });
  }
  /**
   * A method to get the name from suggestion if only id available
   *
   * @param {*} suggestions contains suggestions / options
   * @param {*} id contains id to filter out the name / label
   * @returns name / label for the id passed, else emtpy string
   * @memberof AutoComplete
   */
  getValueIfNoName(suggestions, id) {
    const val = suggestions.filter((opt) => {
      const crOptId = opt.id || opt.value;
      return crOptId === id;
    });
    return val.length > 0 ? val[0].label : '';
  }

  handleLazyLoading = () => {
    let { suggestions, search, suggestionCount } = this.state;
    // [1]
    if (suggestionCount !== suggestions.length) {
      // [2]
      if (search) {
        let regexText = new RegExp(search, 'i');
        let filterSuggestions = this.props.suggestions.filter(elem => regexText.test(elem.label));
        suggestionCount = filterSuggestions.length;
        suggestions = filterSuggestions.slice(0, suggestions.length + MAX_CONTENT);
      } else {
        // [3]
        suggestions = this.props.suggestions.slice(0, suggestions.length + MAX_CONTENT);
        suggestionCount = this.props.suggestions.length;
      }
      this.setState({ suggestions, suggestionCount });
    }
  }
  /**
   * render html
   */
  render() {
    const { classes,suggestions, selectedValue, disabled, name } = this.props;
    const { search, suggestionCount } = this.state;
    return (
      <Select
        isMulti={true}
        classes={classes}
        options={suggestions}
        components={componentsMap}
        onMenuScrollToBottom={() => {
          this.handleLazyLoading(search)
        }}
        value={selectedValue}
        onInputChange={(search) => {
          this.setState({ search })
          if (this.state.search !== search) {
            setTimeout(() => {
              this.handleSearch(search);
            }, 1000)
          }
        }}

        inputValue={search}
        onChange={this.handleChange}
        dataCount={suggestionCount}
        placeholder={this.props.placeholder}
        validators={this.props.validators}
        errorMessages={this.props.errorMessages}
        name={name}
        isDisabled={disabled}
        closeMenuOnSelectboolean={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isAllSelected={true}
      />

    );
  }
}
//default props
AutoCompleteMulti.defaultProps = {
  suggestions: [],
  selectedValue: "",
  disabled: false,
  name: 'autocomplete',
  placeholder: "Organization Name",
  validators: ['required'],
  errorMessages: ['this field is required'],
  /**Handle autocomplete event */
  handleAutoCompolete: () => { }
}
//prop types
AutoCompleteMulti.propTypes = {
  classes: PropTypes.object.isRequired,
  suggestions: PropTypes.any,
  disabled: PropTypes.bool,
  handleAutoCompolete: PropTypes.func.isRequired,
  selectedValue: PropTypes.any
};
/**Export Component */
export default withStyles(styles)(AutoCompleteMulti);