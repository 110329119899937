import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

// Import Styles
import helpFloaterStyle from '../../assets/jss/components/HelpFloater';

//Import Components
import ToolTip from '../ToolTip/ToolTip';
import { AudioIcon, DownloadIcon, FileIcon, ImageIcon, PdfIcon, PlayIcon, TextIcon, XlIcon ,XmlHelpIcon,XsdHelpIcon} from '../SvgIcons/SvgIcons';

//Import actions
import { helpActions } from '../../actions/help'

// React Player
import ReactPlayer from 'react-player';

// Helpers
import { globalVariable } from '../../helpers';

/**
 * DownloadIconClass Component
 * @class DownloadIconClass
 * @extends {Component}
 */
class DownloadIconClass extends Component {
    render(){
        const { tooltip, data } = this.props
        return(
            <ToolTip title={tooltip} placement="bottom">
                <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleDownloadAction(data.attachment, data.fileType)}>
                    <DownloadIcon fill='#36aebe'/>
                </IconButton>
            </ToolTip>
        )
    }
}
/**
 * FloaterContent Component
 * @class FloaterContent
 * @extends {Component}
 */
class FloaterContent extends Component {
    /**
     * Handle Download
     * @param {*} fileName 
     * @param {*} ext 
     */
    handleDownload(fileName, ext){
        this.props.downloadHelpFile(fileName, ext);
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   File type patterns
     * [2]  -   Set file type
     */
    render(){
        let { classes, help, needTitle } = this.props
        // [1]
        const pdfPattern = /pdf$/i
        const videoPattern = /(mp4|webm|flv|mkv)$/i
        const audioPattern = /(mp3|wav|flac|aiff)$/i
        const xlsPattern = /(Xlsx|Xls|Ods|xlsx|xls|ods)$/i
        const textPattern = /.(Doc|Docx|Odt|Txt|doc|docx|odt|txt)$/i
        const imagePattern = /(jpg|jpeg|png)$/i
        const xmlPattern = /xml$/i
        const xsdPattern = /xsd$/i
        const filepath = `${globalVariable.apiURL}/help/video/${help._id}`;
        let isPdf = false;
        let isVideo = false;
        let isAudio = false;
        let isXlsx = false;
        let isText = false;
        let isImage = false;
        let isXml = false;
        let isXsd = false;
        let isUnidentified = false;
        // [2]
        if(help.attachment){
            isPdf = pdfPattern.test(help.attachment);
            isVideo = videoPattern.test(help.attachment);
            isAudio = audioPattern.test(help.attachment);
            isXlsx = xlsPattern.test(help.attachment);
            isText = textPattern.test(help.attachment);
            isXml = xmlPattern.test(help.attachment);
            isXsd = xsdPattern.test(help.attachment);
            isImage = imagePattern.test(help.attachment.split('.')[1]);
            isUnidentified = !isPdf && !isVideo && !isXlsx && !isText && !isImage && !isAudio && !isXml && !isXsd;
        }
        return(
            <React.Fragment>
                <div className={classes.helpContent}>
                    {needTitle && <div className={classes.subHeading}>{help.title}</div>}
                    {help.editorNotes.length !== 11 && <div className={classes.text} dangerouslySetInnerHTML={{__html: help.editorNotes}}></div>}
                    {help.attachment && 
                        <div className={classes.pdfContainer}>
                            <div className={classes.pdfContainerHeader}>
                                <div className={classes.flexBox}>
                                    {isPdf && <div className="help-pdfIcon"><PdfIcon/></div>}
                                    {isVideo && <div className="help-playIcon"><PlayIcon/></div>}
                                    {isAudio && <div className="help-playIcon"><AudioIcon/></div>}
                                    {isXlsx && <div className="help-playIcon"><XlIcon/></div>}
                                    {isText && <div className="help-playIcon"><TextIcon/></div>}
                                    {isImage && <div className="help-playIcon"><ImageIcon/></div>}
                                    {isXml && <div className="help-pdfIcon"><XmlHelpIcon/></div>}
                                    {isXsd && <div className="help-pdfIcon"><XsdHelpIcon/></div>}
                                    {isUnidentified && <div className="help-playIcon"><FileIcon/></div>}
                                    {(isPdf || isXml || isXsd) ?
                                        <div
                                            style={{cursor: 'pointer'}}
                                            className={classes.attachment}
                                            onClick={() => this.props.viewFile(help)}
                                        >{help.attachment}</div> :
                                        <div className={classes.attachment}>{help.attachment}</div>
                                    }
                                </div>
                                <DownloadIconClass 
                                    tooltip={`Download`}
                                    data={help}
                                    handleDownloadAction={(fileName, ext) => this.handleDownload(fileName, ext) }
                                />
                            </div>
                            { (isVideo || isAudio) &&
                            
                                <ReactPlayer
                                    url={filepath}
                                    className={isAudio ? 'react-player audio-style' : 'react-player'}
                                    height='fit-content'
                                    width='100%'
                                    playing={false}
                                    controls
                                />
                            }
                            {isImage &&
                                <img src={`${globalVariable.apiURL}/help/view/${help._id}/${help.attachment}`} alt={help.attachment} width="100%" style={{paddingTop: '12px'}}></img>
                            }
                        </div>
                    }
                    {help.url &&
                        <div className={classes.videoContainer}>
                            <div className={classes.videoContainerHeader}>
                                <div className={classes.flexBox}>
                                    <div className="help-playIcon"><PlayIcon/></div>
                                    <div className={classes.attachment}>{help.title}</div>
                                </div>
                            </div>
                            <div>
                                <ReactPlayer
                                    url={help.url}
                                    className='react-player'
                                    height='300px'
                                    width='100%'
                                    playing={false}
                                    controls
                                />
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
}
/**
 * Bind Component Property Types
 */
FloaterContent.propTypes = {
    title: PropTypes.string,
};
/**
 * Default Props Types
 */
FloaterContent.defaultProps = {
    classes: {},
    title: "Help",
    help: {},
    needTitle: true
};
/**
 * Maps state from store to props
 * @param {*} ownprops 
 * @returns 
 */
const mapStateToProps = (ownprops) => {
    return { }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        viewFile: (data) => dispatch(helpActions.viewFile(data._id, data.attachment, data.attachment.split('.')[1])),
        downloadHelpFile: (fileName, ext) => dispatch(helpActions.downloadFile(fileName, ext))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(helpFloaterStyle, { withTheme: true })(FloaterContent));
