import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helper Constants
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextBox from '../../../components/TextBox/TextBox';
import { alertActions } from '../../../actions';

/** Default data */
const UATData = {
    name: '',
    abbreviation: ''
};
/**
 * EditableText Container
 * @class EditableText
 * @extends {Component}
 * @memberof UtilityAllowanceTypes
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    name: EditableText
}
/**
 * UtilityAllowanceTypes Container
 * @class UtilityAllowanceTypes
 * @extends {Component}
 */
class UtilityAllowanceTypes extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            utilitytypes: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.utilityAllowanceType);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { utilityAllowanceType } = nextProps;

        this.setState({
            saved: true,
            utilitytypes: utilityAllowanceType
        })

    }
    /** Handle submit */
    handleSubmit = () => {
        this.saveForm();
    };
    /** save Form */
    saveForm() {
        //Check for duplicate utility type names
        let utilitytypes = this.state.utilitytypes;
        let duplicateName=''
        let utilitytypesNames = utilitytypes.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicateutilitytypesNames = utilitytypesNames.some(function(item, idx){ 
                    if(utilitytypesNames.indexOf(item) !== idx){
                        duplicateName=utilitytypes[utilitytypesNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicateutilitytypesNames){
            this.props.erroralert(`${duplicateName.toUpperCase()} utility allowance type already exists`);
        }else{
        this.props.updatedata(configurationConstants.utilityAllowanceType, this.state.utilitytypes);
        }
    }
    /**
     * cellComponentOnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { utilitytypes } = this.state;
        utilitytypes[rowIndex][fieldName] = value;
        this.setState({ utilitytypes });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { utilitytypes } = this.state;
        // [1]
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="utilitytypes"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'UTILITY ALLOWANCE TYPES'}
                        headerCols={['NAME']}
                        requiredFields={['NAME']}
                        fieldCols={['name']}
                        rowDatas={utilitytypes}
                        style={style}
                        staticHeader={true}
                        height= {height}
                        componentMap={componentMap}
                        actions={['DELETE']}
                        isLoading={isLoading}
                        onAdd={() => {
                            if (utilitytypes.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    utilitytypes: [
                                        {
                                            ...UATData,
                                            key: Math.max(...utilitytypes.map(elt => elt.key)) + 1,
                                            type: configurationConstants.utilityAllowanceType
                                        },
                                        ...utilitytypes
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            this.props.deleteConfig(item.type, item._id);
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => {  this.props.getByName(configurationConstants.utilityAllowanceType)}}
                />
            </ValidatorForm>
        );
    }
}
/** Props types */
UtilityAllowanceTypes.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        utilityAllowanceType: configuration.configurations[configurationConstants.utilityAllowanceType] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        getByName: (utilityAllowanceType) => dispatch(confActions.getByName(utilityAllowanceType)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) => dispatch(alertActions.error(msg))
    }
};
/** Export UtilityAllowanceTypes */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(UtilityAllowanceTypes));
