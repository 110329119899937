import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

// Import Components
import PageTitle from '../../components/PageTitle/PageTitle';
import PageActions from '../../components/PageActions/PageActions';
import HelpFloater from '../../components/HelpFloater/HelpFloater';
import DashboardSummary from './DashboardSummary';
import ExploreNeedsAction from './ExploreNeedsAction';
import DashboardReports from './DashboardReports';

// Import Styles
import dashboardStyle from '../../assets/jss/containers/dashboard';

// Import Redux Actions and Helper Variable
import { dashboardActions } from '../../actions/dashboard';
import { pageAccessActions } from '../../actions';

// Import Helpers
import { orgTypesConstants } from '../../helpers/appConstants';
import { getUserOrgId, getUserType, globalVariable, getUserRole,history } from '../../helpers';

// Actions
import { helpActions } from '../../actions';

/**
 *Dashboard Main Component
 *
 * @class Dashboard
 * @extends {Component}
 */
class Dashboard extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            openHelp: false,
            currentTab: '0',
            reportYear: new Date().getFullYear()-1
        }
        // get user type
        this.userRole = getUserType();
        this.org_id = getUserOrgId();
        this.user = getUserRole();
    }

    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { userRole, org_id } = this;
        this.props.getPSBUCounts(userRole, this.state.reportYear);
        this.props.getReportStatus(this.state.reportYear, userRole, org_id);
        this.props.getPageAccess('dashboard');
        this.props.getHelp();
        if (this.userRole !== orgTypesConstants.FUNDER && userRole !== orgTypesConstants.RO_ORGANIZATION) {
            this.props.getComplianceIssue(this.state.reportYear, userRole, org_id);
            this.props.getOccupancyRate(this.state.reportYear);
            this.props.getPMEffectiveDate(1, 3, 'projectName', 'asc', '', '');
        }
    }

    componentDidMount() {
        const { location } = this.props;

        if (location && location.state && location.state.pageData && location.state.pageData.naviagteToDashboardReports) {
            this.setState({ currentTab: '2' })
            history.replace(`${history.location.pathname}#exploreneedaction`);
        } else {
            this.setState({ currentTab: '0' })
            history.replace(`${history.location.pathname}#summary`);
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const {location} = nextProps;
        //handle navigation from dashboard tab1
        if (location && location.state && location.state.pageData && location.state.pageData.naviagteToDashboardReports) {
            this.setState({ currentTab: '2' })
            history.replace(`${history.location.pathname}#exploreneedaction`);
        }
    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Tab change
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });  
        let tabLocation = value==='0'?'summary':value==='1'?'exploreneedaction':value==='2'?'viewdownloadreports':'';
        history.replace(`${history.location.pathname}#${tabLocation}`);  
    }
    /**
     * Handle Download Action Click
     */
    handleDownloadActionClick = () => {
        const { dashboard: { PSBUCounts, complianceIssue, occupancyRate, reportStatus, effectiveDate } } = this.props;
        const { reportYear } = this.state;
        this.props.downloadDashboardReport({
            reportYear: reportYear,
            PSBUCounts: PSBUCounts.data,
            complianceIssue: { ...complianceIssue.data },
            occupancyRate: occupancyRate.data, reportStatus: reportStatus.data, utilityAllowance: effectiveDate.page.count, userRole: this.userRole
        })
    }
    /**
     * Handle report year action click
     * @param {*} year 
     */
    handleReportYearActionClick = (year) => {
        const { userRole, org_id } = this;
        // const { dashboardCompliance } = this.props;
        // const selectedSites = dashboardCompliance ? dashboardCompliance.selectedSites : [];
        const selectedSites = [];
        this.setState({ reportYear: year })
        // this.props.getPSBUCounts(userRole, year);
        this.props.getReportStatus(year, userRole, org_id);
        this.props.getProjectsList(userRole, org_id, year, (data) => {
            data.forEach(item => {
                item.site.forEach(siteItem => {
                    selectedSites.push(siteItem.siteid)
                });
            });
            this.props.getComplianceIssueSummary(year, userRole, org_id, selectedSites);
            this.props.getPMProjectList(1, globalVariable.tabTableRowSize, 'projectName', 'asc', '', '', selectedSites, year, userRole, org_id);
        })
        if (this.userRole !== orgTypesConstants.FUNDER && userRole !== orgTypesConstants.RO_ORGANIZATION) {
            this.props.getComplianceIssue(year, userRole, org_id);
            this.props.getOccupancyRate(year);
            this.props.getPMEffectiveDate(1, 3, 'projectName', 'asc', '', '');
        }
    }
    /**
     * Handle dashboard summary actions
     * @param {*} type 
     */
    handleDashboardSummaryActions = (type) => {
        if (type === 'Explore More') {
            this.setState({ currentTab: '1' })
        }
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof Dashboard
     */
    render() {
        const { classes, dashboard, pageAccess, helpData, match, location } = this.props;
        const { user } = this;
        const { openHelp, currentTab, reportYear } = this.state
        let actions = ['REPORTYEAR', 'HELP'];
        if (currentTab === '2') {
            actions = ['HELP'];
        }

        return (
            <Grid container spacing={2} className="dashBoardConts">
                <React.Fragment>
                    <Grid item xs={12} sm={6} className="dashBoardTopLtTitle">
                        <PageTitle title="Dashboard" />
                    </Grid>
                    <Grid item xs={12} sm={6} className="dashBoardTopRtTitle">
                        <PageActions
                            actions={actions}
                            reportYear={reportYear}
                            handleHelpActionClick={this.handleHelpActionClick}
                            handleDownloadActionClick={this.handleDownloadActionClick}
                            handleReportYearActionClick={(year) => this.handleReportYearActionClick(year)}
                            downloadColor='secondary'
                        />
                    </Grid>
                </React.Fragment>
                {
                    user !== "superFunder" && user !== "superFunderWithHO" &&
                    <div className={classes.tab}>
                        <TabContext value={currentTab}>
                            <AppBar position="static" color="default">
                                <TabList
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label="Summary" value={'0'} />
                                    <Tab label="Explore Needs Action" value={'1'} />
                                    <Tab label="View / Download Reports" value={'2'} />
                                </TabList>

                            </AppBar>
                            <TabPanel value={'0'} index={0} id="divIdToPrint">
                                <DashboardSummary
                                    reportYear={reportYear}
                                    PSBUCount={dashboard.PSBUCounts}
                                    complianceIssues={dashboard.complianceIssue}
                                    occupancyRate={dashboard.occupancyRate}
                                    reportStatus={dashboard.reportStatus}
                                    utilituAllowance={dashboard.effectiveDate}
                                    match={match}
                                    handleActions={(type) => this.handleDashboardSummaryActions(type)} />
                            </TabPanel>
                            <TabPanel value={'1'} index={1}>
                                <ExploreNeedsAction />
                            </TabPanel>
                            <TabPanel value={'2'} index={2} className={classes.DashboardTab}>
                                <DashboardReports
                                    pageAccess={pageAccess}
                                    location={location}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                }
                {
                    (user === "superFunder" || user === "superFunderWithHO") &&
                    <div className={classes.tab}>
                        <TabContext value={currentTab}>
                            <AppBar position="static" color="default">
                                <TabList
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label="Summary" value={'0'} />
                                    {/* <Tab label="Explore Needs Action" value={'1'} /> */}
                                    <Tab label="View / Download Reports" value={'2'} />
                                </TabList>

                            </AppBar>
                            <TabPanel value={'0'} index={0} id="divIdToPrint">
                                <DashboardSummary
                                    reportYear={reportYear}
                                    PSBUCount={dashboard.PSBUCounts}
                                    complianceIssues={dashboard.complianceIssue}
                                    occupancyRate={dashboard.occupancyRate}
                                    reportStatus={dashboard.reportStatus}
                                    utilituAllowance={dashboard.effectiveDate}
                                    match={match}
                                    handleActions={(type) => this.handleDashboardSummaryActions(type)} />
                            </TabPanel>
                            {/* <TabPanel value={'1'} index={1}>
                                <ExploreNeedsAction />
                            </TabPanel> */}
                            <TabPanel value={'2'} index={2} className={classes.DashboardTab}>
                                <DashboardReports
                                    pageAccess={pageAccess}
                                    location={location}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                }
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Dashboard"
                />}
            </Grid>
        );
    }
}
// prop types
Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired
};
/**
 * Default Props
 */
Dashboard.defaultProps = {
    pageAccess: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const pageAccess = state.pageAccess['dashboard'] || [];
    const helpData = state.help.data[0]
    return {
        dashboard: state.dashboard,
        search: state.dashboard.search,
        dashboardCompliance: state.dashboard.complianceNeedsAction,
        pageAccess,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getComplianceIssueSummary: (year, userRole, org_id, selectedSites) => dispatch(dashboardActions.getComplianceIssueSummary(year, userRole, org_id, selectedSites)),
        getComplianceIssue: (year, userRole, org_id) => dispatch(dashboardActions.getComplianceIssue(year, userRole, org_id)),
        getOccupancyRate: (year) => dispatch(dashboardActions.getOccupancyRate(year)),
        getReportStatus: (year, userRole, org_id) => dispatch(dashboardActions.getReportStatus(year, userRole, org_id)),
        getPMEffectiveDate: (skip, limit, orderBy, order, sFields, sValues) => dispatch(dashboardActions.getPMEffectiveDate(skip, limit, orderBy, order, sFields, sValues)),
        getProjectSummary: (limit, search) => dispatch(dashboardActions.getProjectSummary(limit, search)),
        viewProjectSummaryReport: (data) => dispatch(dashboardActions.viewProjectSummaryReport(data)),
        downloadProjectSummaryReport: (data) => dispatch(dashboardActions.downloadProjectSummaryReport(data)),
        downloadDashboardReport: (data) => dispatch(dashboardActions.downloadDashboardReport(data)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 12 })),
        getPSBUCounts: (userRole, reportYear) => dispatch(dashboardActions.getPSBUCounts(userRole, reportYear)),
        getProjectsList: (userRole, org_id, year, callBack) => dispatch(dashboardActions.getProjectsList(userRole, org_id, year, callBack)),
        getPMProjectList: (skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id) => dispatch(dashboardActions.getPMProjectList(skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id)),
        getPageAccess: (page) => dispatch(pageAccessActions.getAccess(page,'')) 
    }
};
// export Dashboard
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(Dashboard));