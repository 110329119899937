export const Table2Messages = {
    // db level
    section1: 'Section 1 - The total number of "Restricted Units" entered doesn\'t match the system calculated total. The system pulls the total from Table 1 household records.',

    // db level
    section6: 'Section 6 - Single Parent Head of Household total can\'t be greater than the total number of restricted households served during the year.',

    // db level
    section7: 'Section 7 - The total number of \'Households Served During the Year\' entered doesn\'t match the system calculated total. The system pulls the total from Table 1 household records.',

    // db level
    section4: 'Section 4 - Head of Household by Race and Ethnicity total doesn\'t match the total number of households served during the year.'
}

export const Table2AlternateMessages = {
    // field level
    singleBednights: 'Either Single Bed Units or Household Units must be greater than 0',

    // field level
    bednightsUsed: 'The Total Occupied Bed nights (used) can\'t be greater than the Total Bed Nights Available',

    // field level
    householdServed4: 'The Total Households Served in Section 4 should be the same as the Total Households Served in Section 3.',

    // field level
    householdServed5: 'Total household served in section 5 should be the same as the total household served in section 3',

    // field level
    totalSingleParent: 'Total Single Parent households in Section 6 should be lesser than the Total Household Served count in Section 3'
}

export const Table2FarmworkerMessages = (data) => {
    return {
        // field level
        noOfBeds: 'A valid value greater than 0 for the Temporary Farmworker Housing (# of Beds) is required',

        // field level
        beginningRY: `The beginning report year date of operation must be in the reporting year of ${data.year}`,

        // field level
        endingRY: `The ending report year date of operation must be in the reporting year of ${data.year}`,

        // db level
        bedNightAvailable: 'The Total Occupied Bednights (used) in Section 2 must not be greater than the Total Bednights Available',

        // field level
        perBed: 'You must enter one valid value for Rent per Individual (per bed)',

        // field level
        perUnit: 'You must enter one valid value for Rent per Household (per unit)',

        // field level
        totalHousehold: 'Total Households served in Section 7 should be same as the Total Household Served count in Section 5',

        // db level
        noOfMinors: 'Number of Minors is greater than the Total Households Served in Section 5',

        // field level
        totalHouseholdsServed5thPart: 'Total Household served is should be greater than 0',

        totalHouseholdsServedEqualTotal: 'The Total Households Served in Section 5 should be the same as the Total Households Served in Section 6.',
    }
}

export const Table3Messages = {
    totalHousehold: 'Total Number of Homeless Households is greater than Total Households Served on Table 2',

    totalSpecialNeeds: 'Total Number of Special Need Population is greater than Total Household Served on Table 2',

    elderly: 'Reported Frail elderly set aside is less than required.',

    disabled: 'Reported Developmentally Disabled Set Aside is less than required.'
}

export const Table1Messages = {
    // db level
    subsidyAmount: 'A Subsidy Type was selected; a Subsidy Amount must be entered.',

    // db level
    maxAllowRent: 'The Resident Payment + Utility is more than the maximum allowable Rent.',

    // db level
    smallUnit: 'Unit is too small for Large Household.',

     // db level
     currentCertificationDate: 'Current Certification Date must be entered between Move In date and Move Out Date',

     unitToSmallLargeousehold:'This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.',

     lessMembersLargehousehold:'This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.',
}

export const ProgramActivityMessages = {

    numberOfLoansGenerated: 'Section 1 - Number of loans must match the number listed in Program Activity.',
    numberofGrantsReportingPeriod: 'Section 2 - Number of grants must match the number listed in Program Activity.',
    numberofResales: 'Section 3 - Number of loans must match the number listed in Program Activity',
    numberofDeferredLoansReportingPeriod: 'Section 4 - Number of loans must match the number listed in Program Activity.',
    numberofLoansDefaultReportingPeriod: 'Section 5 - Number of loans must match the number listed in Program Activity.',
    numberofClosuresReportingPeriod: 'Section 6 - Number of loans must match the number listed in Program Activity.',

    loanNumber1: 'Section 1 - Record Number does not match a record in Contract Activity.',
    loanNumber2: 'Section 2 - Record Number does not match a record in Contract Activity.',
    loanNumber3: 'Section 3 - Record Number does not match a record in Contract Activity.',
    loanNumber4: 'Section 4 - Record Number does not match a record in Contract Activity.',
    loanNumber5: 'Section 5 - Record Number does not match a record in Contract Activity.',
    loanNumber6: 'Section 6 - Record Number does not match a record in Contract Activity.',

    foreClosure1: 'Section 1 - Record Number is one that is considered closed via foreclosure or has been satisfied.',
    foreClosure2: 'Section 2 - Record Number is one that is considered closed via foreclosure or has been satisfied.',
    foreClosure3: 'Section 3 - Record Number is one that is considered closed via foreclosure or has been satisfied.',
    foreClosure4: 'Section 4 - Record Number is one that is considered closed via foreclosure or has been satisfied.',
    foreClosure5: 'Section 5 - Record Number is one that is considered closed via foreclosure or has been satisfied.',
    foreClosure6: 'Section 6 - Record Number is one that does NOT match a record with the foreclosure box checked "Yes".',

    loanNumberMatch1: 'Section 1 - Number of Loans generated for the reporting period in Program activity not equal to Record number with Loan? checked "Yes"',
    loanNumberMatch2: 'Section 2 - Record number is not listed as a Grant in Contract Activity detail.',
    loanNumberMatch3: 'Section 3 - Number of resales in the reporting period are less than the amount of record numbers with Type of HTF Activity listed as “Resale Activity” in the Contract Activity tab '
}

export const FinancialActivityMessages = {

    numberOfHomeOwnersMadePayment: 'Section 4a - Number of loans must match the number listed in Financial Activity.',
    numberOfLoansStatisfied: 'Section 4b - Number of loans must match the number listed in Financial Activity.',
    numberOfLoansBadDebtIncurred: 'Section 5a - Number of loans must match the number listed in Financial Activity.',

    loanNumber4a: 'Section 4a - Record Number does not match a record in Contract Activity.',
    loanNumber4b: 'Section 4b - Record Number does not match a record in Contract Activity.',
    loanNumber5a: 'Section 5a - Record Number does not match a record in Contract Activity.',

    foreClosure1: 'Section 4a - Record Number is one that is considered closed via foreclosure or has been satisfied.',
    foreClosure2: 'Section 4b - Record number is one that is NOT marked as satisfied in record detail.',
    foreClosure3: 'Section 5a - Record Number is one that is considered closed via foreclosure or has been satisfied.',

    loanNumberMatch4a: 'Section 4a - Number of Loans with payment for the reporting period in Program activity not equal to Record number with Loan? checked "Yes"',
    loanNumberMatch4b: 'Section 4b - Number of Loans Satisfied for the reporting period in Program activity not equal to Record number with Loan? checked "Yes"',
    loanNumberMatch5a: 'Section 5a - Number of Loans that incurred for the reporting period in Program activity not equal to Record number with Loan? checked "Yes"',
}

export const ContractActivityMessages = {

    numberOfLoansGenerated: 'Section 1 - Number of loans must match the number listed in Program Activity.',
    numberofGrantsReportingPeriod: 'Section 2 - Number of grants must match the number listed in Program Activity.',
    numberofDeferredLoansReportingPeriod: 'Section 4 - Number of deferred loans must match the number listed in Program Activity.',
    numberofLoansDefaultReportingPeriod: 'Section 5 - Number of default loans must match the number listed in Program Activity.',
    numberofClosuresReportingPeriod: 'Section 6 - Number of Foreclosures loans must match the number listed in Program Activity.',
    numberOfHomeOwnersMadePayment: 'Section 4a - Number of loans with payment must match the number listed in Financial Activity.',
    numberOfLoansStatisfied: 'Section 4b - Number of Loans Satisfied must match the number listed in Financial Activity.',
    numberOfLoansBadDebtIncurred: 'Section 5a - Number of loans that incurred bad debt must match the number listed in Financial Activity.',

    loanNumber1: 'Record Number does not match a record in Contract Activity in section 1',
    loanNumber2: 'Record Number does not match a record in Contract Activity in section 2',
    loanNumber3: 'Record Number does not match a record in Contract Activity in section 3',
    loanNumber4: 'Record Number does not match a record in Contract Activity in section 4',
    loanNumber5: 'Record Number does not match a record in Contract Activity in section 5',
    loanNumber4a: 'Record Number does not match a record in Contract Activity in section 4a',
    loanNumber4b: 'Record Number does not match a record in Contract Activity in section 4b',
    loanNumber5a: 'Record Number does not match a record in Contract Activity in section 5a',

    foreClosure1: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 1.',
    foreClosure2: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 2.',
    foreClosure3: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 3.',
    foreClosure4: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 4.',
    foreClosure5: 'Record Number is one that does NOT match a record with the foreclosure box checked "Yes".',
    foreClosure6: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 4a.',
    foreClosure7: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 4b.',
    foreClosure8: 'Record Number is one that is considered closed via foreclosure or has been satisfied in section 5a.',
}