import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from '../helpers';

export const dashboardHOService = {
    getPCLCounts,
    getUsersSummary,
    getProjectSummary,
    downloadReport,
    getLoanRate,
    getReportStatus,
    getLoanComplianceIssue,
    getProjectsList,
    getComplianceIssueSummary,
    getPMProjectList,
    getStatusReportHO
};

/**
 *Report Status in both dashboard section
 *
 * @param {*} year - selected year
 * @param {*} userRole - logged in user
 * @param {*} org_id - organization id
 * @returns
 */
function getReportStatus(year, userRole) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/reportStatus/${year}/${userRole}`, requestOptions).then(handleResponse);
}

/**
 * download report
 * @param {*} id
 * @returns
 */
function downloadReport(project, site, year) {
    let data = {project, site, year};
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${globalVariable.apiURL}/dashboardHO/downloadReport`, requestOptions).then(handleDownloadResponse);
}

function getStatusReportHO(reportID,year){
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };
    return fetch(`${globalVariable.ms_apiURL}/funderexports/${reportID}/${year}`,requestOptions).then(handleDownloadResponse)
}

/**
 *Dropdown values in Dashboard
 *
 * @returns
 */
function getProjectSummary(limit, search) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/project-summary?limit=${limit}&search=${search}`, requestOptions).then(handleResponse);
}

/**
 * A dispatcher action method to call PSBU Counts
 *
 */
function getPCLCounts(userRole, reportYear) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/PCLCounts/${userRole}/${reportYear}`, requestOptions).then(handleResponse);
}

/**
 * A dispatcher action method to call USERS SUMMARY 
 *
 */
function getUsersSummary(userRole) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/UsersSummary/${userRole}`, requestOptions).then(handleResponse);
}

/**
 * Service for loan Rate
 */
function getLoanRate(userRole, year) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/loanRate/${userRole}/${year}`, requestOptions).then(handleResponse);
}

/**
 * Service for Compliance Issue
 */
 function getLoanComplianceIssue(userRole, year) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/complianceIssue/${userRole}/${year}`, requestOptions).then(handleResponse);
}

function getProjectsList(userRole, org_id, year) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" }
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/projectList/${userRole}/${org_id}/${year}`, requestOptions).then(handleResponse);
}

function getComplianceIssueSummary(year, userRole, org_id, selectedSites) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({contractIds:selectedSites})
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/needsAction/${year}/${userRole}/${org_id}`, requestOptions).then(handleResponse);
}

/**
 *PM Dashboard Needs Action Section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
 function getPMProjectList(skip, limit, orderBy, order, sfields, sValues, selectedSites, reportYear, userRole, org_id) {

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch: false,
        reportYear: reportYear,
        selectedSites: selectedSites,
        userRole: userRole,
        org_id: org_id
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/dashboardHO/PMprojectlist?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}