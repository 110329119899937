import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import ToolTip from '../../components/ToolTip/ToolTip';
import { CloseIcon } from '../../components/SvgIcons/SvgIcons';


/**
 *XmlHelpDialog Component
 *
 * @class XmlHelpDialog
 * @extends {React.Component}
 */
class XmlHelpDialog extends React.Component {
    /**
     * Handle Dialog close Action
     */
    handleClose = () => {
        this.props.handleCloseAction()
    };
    /**Handle Dialog Delete Action */
    handleOkay = () => {
        this.props.handleOkayAction(this.props.id)
    }
    /**
     *render html
     *
     * @returns
     * @memberof XmlHelpDialog
     */
    render() {
        const { open, title,content, saveVisible, cancelVisible, className, confirmationButtonLabel, closeButtonLabel } = this.props;
        const tittleContent = title+":";
        return (
            <Dialog
                maxWidth='md'
                fullWidth
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
           
                <Grid container style={{padding:'10px 10px 0px 13px' }}>  
                    <Grid  style={{fontSize:'24px' }} item xs={10} sm={10} >{tittleContent}</Grid>
                    <Grid item xs={2} sm={2} > 
                        <Grid style={{ marginLeft:'98px',marginRight:'0px' }}>
                            <ToolTip title="Close"  placement="bottom" >
                                <Button  variant="circular" size="small" color="default" aria-label="Merge"  onClick={this.handleClose}  >
                                    <CloseIcon />
                                </Button>
                            </ToolTip>
                        </Grid>
                    </Grid>

                </Grid>
               
                <Grid container style={{padding:'0px 10px 10px 13px',overflow:'auto' ,height:'500px'}} >  
                    {content}
                </Grid>
           
               
            </Dialog >
        );
    }
}

//default props
XmlHelpDialog.defaultProps = {
    open: true,
    handleOkayAction: () => { },
    handleClose: () => { },
    maxWidth: 'sm'
}

//prop types
XmlHelpDialog.propTypes = {
    handleOkayAction: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
};

/**Export Component */
export default XmlHelpDialog;