import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import _ from 'underscore';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Actions
import { archivesActions } from '../../actions/archives';
import { confActions } from '../../actions/configuration';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';

// Helpers
import { getUserOrgId, getUserRole, globalVariable, history, formatDate, filterSearchFields } from '../../helpers';

// Import Components
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import TableComponent from '../../components/Table/TableComponent';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from "../../components/HelpFloater/HelpFloater";

/**
 * For table county field
 * @class CountyField
 * @extends Component
 * @memberof ArchivesList
 */
class CountyField extends Component {
    render() {
        const { data } = this.props;
        const { sites_id } = data;
        const county = sites_id.length > 0 ? sites_id[0].county : '';
        return county ? county : '';
    }
}

CountyField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

/**
 * For table funders field
 * @class FundersField
 * @extends Component
 * @memberof ArchivesList
 */
class FundersField extends Component {
    render() {
        const { data } = this.props;
        const { funders, funderIds } = data;
        const links = funders.map((fn, index) => {
            return (
                <Button
                    key={index}
                    size="small"
                    className={'linkCell validation_color text-button'}
                    onClick={() => {
                        const funderId = funderIds.filter(fkey => { return fkey.org_id === fn._id });
                        if (funderId[0]) {
                            this.props.handleChange("funders", { ...data, funderKey: { ...funderId[0], name: fn.name } })
                        }
                    }}
                >
                    {fn.name}
                </Button>
            )
        })
        return links;
    }
}

FundersField.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

const componentMap = {
    "sites_id.county": CountyField,
    "fundersName": FundersField
}

/**
 * Archive list container
 * @class ArchivesList
 * @extends Component
 */
class ArchivesList extends Component {
    /**
     * Constructor
     * @param {object} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
            anchorEl: null,
            deleteProj: false,
            deleteItemId: null,
            openHelp: false
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }
    /**
     * Component Will Mount Event
     * 
     * [1]  -   To check if the reducer has page value
     * [2]  -   Get data based on reducer page values
     * [3]  -   Get data based on default page values
     * [4]  -    Gel field filters of archive list page
     */
    UNSAFE_componentWillMount() {
        const { projects, userId } = this.props;
        const { page } = projects;
        this.props.getHelp();
        // [1]
        if (page.skip) {
            // [2]
            this.props.getProjects(page.skip, globalVariable.tableRowSize-4, page.orderBy, page.order, page.sFields, page.sValues, false, userId);
        } else {
            // [3]
            this.props.getProjects(1, globalVariable.tableRowSize-4, 'name', 'asc', '', '', false, userId);
        }
        setTimeout(() => {
            // [4]
            this.props.getFieldFilter('archives');
        }, 100);
    }

    /**
     * Component Did Update
     * 
     * [1]  -   To clear filter entered in search box
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            // [1]
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Component will unmount
     * 
     * [1]  -   clear search of assoc tab
     */
    componentWillUnmount() {
        // [1]
        if (this.props.userId) {
            this.props.searchActions({ sFields: [], sValues: [] });
        }
    }
    /**
     * Handle Table Pagination On Change Event
     * @param {number} skip 
     * @param {number} limit 
     * 
     * [1]  -   Get Archived projects
     */
    onChangePageTable = (skip, limit) => {
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        // [1]
        this.props.getProjects(skip, limit, page.orderBy, page.order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Sort Event
     * @param {string} orderBy 
     * @param {number} order 
     * 
     * [1]  -   Get Archived projects
     */
    onSortRequest = (orderBy, order) => {
        const { projects, userId, searchData: { sFields, sValues } } = this.props;
        const { page } = projects;
        // [1]
        this.props.getProjects(page.skip, page.limit, orderBy, order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Search Input Box Event
     * @param {string} searchField 
     * @param {*} searchKey 
     * @memberof TableComponent
     * 
     * [1]  -   To check wheather searchField is already in the array sFields
     *          if - Update the searchField
     *          else - Push the searchField into the array sFields
     * [2]  -   To check if the searchKey has a vale
     *          if - searchKey is empty remove the searchField from the array sFields
     *          else - Update the searchKey
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { projects, userId, searchData } = this.props;
        const { page } = projects;
        let { sFields, sValues } = searchData;
        // [1]
        if (sFields.indexOf(searchField) >= 0) {
            // [2]
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getProjects(1, globalVariable.tableRowSize, page.orderBy, page.order, sFields, sValues, false, userId);
    };
    /**
     * Handle Table Navigation Event
     * @param {number} id 
     * @param {string} field 
     * @param {object} item 
     * 
     * [1]  -   Navigation for archive edit page
     * [2]  -   Navigation for User page only if owner id found
     * [3]  -   Navigation for Organization page only if owner's organization id found
     */
    handleNavigateAction = (id, field, item) => {
        const { modifiedBy, updatedAt, owners } = item;
        switch (field) {
            // [1]
            case "name":
                history.push({
                    pathname: `/archives/${id}`,
                    state: {
                        page: 'archiveEdit',
                        pageData: {
                            project_id: id
                        },
                        pageTitle: {
                            title: `${item.name}`,
                            subTitle: modifiedBy ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>` : '[Archived]'
                        }
                    }
                });
                break;
            // [2]
            case "ownerName":
                const ownerId = owners || { user_id: 0 };
                if (ownerId.user_id) {
                    history.push({
                        pathname: `/users/${ownerId.user_id}`,
                        state: {
                            page: 'userEdit',
                            pageData: {
                                user_id: parseInt(ownerId.user_id, 10)
                            }
                        }
                    });
                }
                break;
            // [3]
            case "owners.org_id.name":
                const ownerOrgId = owners ? owners.org_id ? owners.org_id._id : 0 : 0;
                if (ownerOrgId) {
                    history.push({
                        pathname: '/organizations/' + ownerOrgId,
                        state: {
                            page: 'organizationEdit',
                            pageData: {
                                org_id: parseInt(ownerOrgId, 10)
                            }
                        }
                    });
                }
                break;
            default: break;
        }
    }
    /**
     * Handle Clear Fileter Action
     * 
     * [1]  -   Set sFields and sValues to empty
     * [2]  -   Get Archived projects
     */
    handleClearFilterActionClick = () => {
        const { projects, userId } = this.props;
        const { page } = projects;
        // [1]
        this.props.searchActions({ sFields: [], sValues: [] });
        // [2]
        this.props.getProjects(page.skip, page.limit, page.orderBy, page.order, [], [], false, userId);
    };
    /**
     * Handle clear filter action
     * 
     * [1]  -   Update the feldFilters
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        const { projects } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter("archives", page.fieldFilters.visibleColumns);
    };
    /**
     * Handle field filter close action
     * 
     * [1]  -   reset to current table column values if user not save the changes4
     * [2]  -   close the popup
     */
    handleFieldFilterOnClose = () => {
        // [1]
        const { projects } = this.props;
        const { page } = projects;
        this.props.changeFieldFilter("archives", page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {string} key 
     * @param {boolean} value 
     * 
     * [1]  -   If value is false, remove that key from the array visibleColumns
     * [2]  -   Else push the key into the array visibleColumns
     */
    handleFieldFilterOnChange = (key, value) => {
        // [1]
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("archives", updatedColumns);
        } else {
            // [2]
            this.props.changeFieldFilter("archives", [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle field filter on save
     * 
     * [1]  -   on api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("archives", this.props.visibleColumns, () => {
            // [1]
            this.setState({ fieldFilter: false });
            const { projects, userId, searchData: { sFields, sValues } } = this.props;
            const { page } = projects;
            this.props.getProjects(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, false, userId);
        });
    }
    /**
     * Handle Table Delete Action
     * @param {number} id 
     * 
     * [1]  -   show pop up
     */
    handleTableDeleteAction = (id) => {
        // [1]
        this.setState({ deleteProj: true, deleteItemId: id });
    };
    /**
     * Handle Field Component On Change Event
     * @param {string} name 
     * @param {object} data 
     * 
     * [1]  -   Navigation to the clicked funder only if funder id found
     */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "funders":
                const { _id, name: funderName, org_id, modifiedBy, updatedAt } = data.funderKey;
                if (_id) {
                    history.push({
                        pathname: `/archives/${data._id}/funders/${_id}`,
                        state: {
                            page: 'funderEdit',
                            pageData: {
                                funder_id: _id,
                                orgId: org_id
                            },
                            pageTitle: {
                                // title: '',
                                title: `${funderName} | ${data.name}`,
                                subTitle: modifiedBy ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>` : ''
                                // subTitle: ''
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Bind Html to DOM
     */
    render() {
        let {
            projects, columnsMap, visibleColumns, tableActionRequired,
            removeActions, location: { pathname }, title, pageAccess,
            isSubPage, classes, needPadding, helpData,
            searchData: { sFields, sValues }
        } = this.props;

        const { userRole, userOrgId } = this;
        const { fieldFilter, openHelp } = this.state;
        const search = projects;
        const fieldFilters = search.page.fieldFilters;
        pageAccess = _.difference(pageAccess, removeActions);

        // remove download icon
        const downloadIndex = pageAccess.indexOf('DOWNLOAD');
        if (this.props.userId && downloadIndex > -1) {
            pageAccess.splice(downloadIndex, 1);
        }
        pageAccess = [...pageAccess, 'HELP']
        return (
            <ContentWrapper
                classes={classes}
                needTitleBar={true}
                pageAccess={pageAccess}
                isSubPage={true}
                
                handleHelpActionClick={this.handleHelpActionClick}
                needPadding={true}
                handleDownloadActionClick={() => (
                    <Menu>
                        <MenuItem>New</MenuItem>
                        <MenuItem>Update</MenuItem>
                    </Menu>
                )}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
            >
                <TableComponent
                    page={search.page.skip}
                    rowsPerPage={search.page.limit}
                    count={search.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    actions={tableActionRequired === true ? pageAccess : []}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    currentPage={'archives'}
                    links={[fieldFilters.fieldCols[0], "monitoring", "ownerName", "owners.org_id.name"]}
                    order={search.page.order}
                    orderBy={search.page.orderBy}
                    data={search.data}
                    highlightField={['name']}
                    isLoading={search.isLoading}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    handleTableCopyAction={this.handleTableCopyAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                    moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                    prevNext={false}
                    paginationVisible={true}
                    disablePagination={search.page.lastPage}
                    showLoading={isSubPage}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                <DeleteDialog
                    open={this.state.deleteProj !== false}
                    content={"This Project is associated with at least one Site/Building/Unit.Retieving this project will retieve all associated Site/Building/Unit ?"}
                    handleDeleteAction={() => {
                        this.setState({ deleteProj: false });
                        this.props.deleteProject(this.state.deleteItemId)
                        this.props.getProjects(search.page.skip, search.page.limit, search.page.orderBy, search.page.order, [], [], false, this.props.userId)
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ deleteProj: false })
                    }}
                />
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Archive Projects"
                />}
            </ContentWrapper>

        );
    }
}

/**
 * Bind Component Property Types
 */
ArchivesList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    title: PropTypes.string,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};

/**
 * Default Props Types
 */
ArchivesList.defaultProps = {
    needPadding: false,
    tableActionRequired: true,
    title: "All Archive Projects",
    removeActions: [],
    isSubPage: false
};

/**
 * Maps state from store to props
 * @param {object} state 
 * @param {object} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['archives'] || {};
    const pageAccess = state.pageAccess['archivesList'] || [];
    const projects = state.archives;
    const searchData = state.search['archives'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    return {
        projects,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData,
        helpData
    }
};

/**
 * Maps actions to props
 * @param {*} dispatch 
 * @param {object} ownProps 
 * @returns 
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        resetFilter: () => dispatch(archivesActions.resetFilter()),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getProjects: (skip, limit, orderBy, order, sField, sValue, exactMatch, userId) => dispatch(archivesActions.getProjects(skip, limit, orderBy, order, sField, sValue, exactMatch, userId, { isDelete: true })),
        deleteProject: (id) => dispatch(archivesActions.delete(id, 'archives')),
        searchActions: (data) => dispatch(searchActions.archives(data)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 16 })),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(ArchivesList));
