import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

//Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Menu, MenuItem } from '@material-ui/core';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// import actions
import { reportTablesActions } from '../../../actions/reportTables';
import { confActions } from '../../../actions/configuration';
import { alertActions } from "../../../actions";

// Components
import SaveCancel from "../../../components/SaveCancel/SaveCancel";
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import SnackBar from '../../../components/SnackBar/SnackBar';
import TextArea from '../../../components/TextArea/TextArea';
import TableErrors from '../../../components/TableErrors/TableErrors';
import { ExpansionPanelComponent } from '../../../components/ExpansionPanel/ExpansionPanel';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";

// table 4 section components
import Income from './Table4Sections/Income';
import Expenses from './Table4Sections/Expenses';
import DebtServices from './Table4Sections/DebtServices';
import PerformanceMeasures from './Table4Sections/PerformanceMeasures';
import Comments from './Table4Sections/Comments';
import ReplacementReserve from './Table4Sections/ReplacementReserve';
import OperatingReserve from './Table4Sections/OperatingReserve';
import moment from 'moment';


// import default state and config datas
import { DEFAULT_CONFIG, DefaultStateDatas, DefaultRowDatas, ErrorNames, WarningNames } from './Table4Sections/DefaultData';

// Helpers
import { configurationConstants } from '../../../helpers/appConstants';
import ValidatorForm from "../../../helpers/fieldValidations";
import { history, getSubmissionLevel, ReportLevelsConstants } from '../../../helpers';
import { getFloat } from '../../../helpers';

// calc
import {
    getOperatingReservebalance, getReplacementReservebalance,
    getReplacementReservePerUnit, getOperatingReservePerUnit, getDebtCoverage,
    getReplacementReserveDeposit, getOperatingReserveDeposit,
    totalGrossRentalIncome,
    totalOtherIncome,
    totalSubsidyOperating,
    totalSubsidyServices,
    totalSubsidyEGI,
    getExpensesCategory,
    getDebtServices
} from '../../../helpers/Table4Calc';

const dataDefault = {
    ...DefaultStateDatas,
    replacementReserveDeposit: 0,
    operatingReserveDeposit: 0
};

//Reset data Keys
const resetDataKeys = {
    income: ['actualRentIncome', 'commercialNetIncome', 'otherIncome', 'subsidyTypes', 'amount'],
    expense: ['expenseCategory'],
    debtService: ['debtServices'],
    comment: ['notes'],
    replacementReserve: ['replacementReserveBeginningBalance', 'aTransaction', 'transactionReplacementNotes'],
    operatingReserve: ['operatingReserveBeginningBalance', 'bTransaction', 'transactionOperatingNotes']
};
/**
 * Table4 Component
 * @class Table4
 * @extends {Component}
 */
class Table4 extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.expenseRef = React.createRef();
        this.incomeRef = React.createRef();
        this.debtServicesRef = React.createRef();
        this.performanceRef = React.createRef();
        this.commentRef = React.createRef();
        this.replacementRef = React.createRef();
        this.operatingRef = React.createRef();
        this.scrollToContent = this.scrollToContent.bind(this);
        this.state = {
            updateForm: true,
            data: dataDefault,
            cloneData: { ...dataDefault },
            config: {
                ...DEFAULT_CONFIG,
                allLoaded: false
            },
            _id: null,
            reportYear_id: null,
            type: null,
            unsubmitComment: '',
            comment: '',
            error: null,
            requiredFieldAlert: false,
            expansionSections: {
                income: false,
                expense: false,
                debtService: false,
                performance: false,
                comment: false,
                replacementReserve: false,
                operatingReserve: false
            },
            errorSections: {
                income: false,
                expense: false,
                debtService: false,
                performance: false,
                comment: false,
                replacementReserve: false,
                operatingReserve: false
            },
            warningSections: {
                expense: false,
                replacementReserve: false,
                operatingReserve: false
            },
            disabledSections: {
                income: true,
                expense: true,
                debtService: true,
                comment: true,
                replacementReserve: true,
                operatingReserve: true
            },
            errorLabels: [],
            anchorEl: null,
            created: false,
            isTable4Exists:null
        };
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { reportId, location: { pathname } } = this.props;
        if (reportId) {
            // get the table 4 data
            this.props.getTable4Data(reportId, pathname.includes('archives') ? 'archives' : 'projects');
        }
        // get config datas
        this.props.getConfiguration(configurationConstants.otherIncome);
        this.props.getConfiguration(configurationConstants.subsidyType);
        this.props.getConfiguration(configurationConstants.expenseCategory);
        this.props.getConfiguration(configurationConstants.lenders);
        this.props.refreshValidations(0, 0, reportId, "4");
        // get validation issues
        if (reportId) {
            this.props.validationIssues(0, 0, reportId, "4");
        }
        ValidatorForm.addValidationRule('isCurrentReportYear', (value) => {
            const { reportYearData } = this.state.data;
            const curYear = reportYearData ? reportYearData.year : new Date().getFullYear();
            const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
            return curYear === selYear;
        });
    }
    /**
     * Method get called  whe compoent props get changed
     *
     * @param {*} nextProps contains new / next props for this component
     * @memberof Table4
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, configuration } = nextProps;
        const { reportId, location: { pathname } } = this.props;
        if (getOne.data === null) {
            return;
        }
        const id = getOne.data ? getOne.data._id : null;
        if (configuration.allLoaded && getOne.data && (this.state._id === null || this.state._id !== id)) {
            // merge the expense category
            const preExpenses = getOne.data.previousExpenseCategory.expenseCategory || [];
            let expenseCategory = this.formatExpenseCategory(getOne.data.expenseCategory || [], configuration.expenseCategory, preExpenses);
            // add withdrawAmt and depositAmt fields to transaction data
            let transactions = getOne.data.abTransaction || [];
            const availBalance = {
                1: getOne.data.replacementReserveBeginningBalance || 0,
                0: getOne.data.operatingReserveBeginningBalance || 0
            }
            // upload static replacement transaction
            transactions = [
                // ...this.getReplacementTransction(getOne.data),
                // ...this.getOperatingTransction(getOne.data),
                ...transactions
            ];
            transactions = transactions.map((trans) => {
                // Replacing balance calculation
                const amt = availBalance[trans.replacementFlag];
                const tAmt = trans.amount || 0;
                let balance = trans.depositFlag === 0 ? amt - tAmt : amt + tAmt;
                availBalance[trans.replacementFlag] = balance;
                balance = this.getFloat(balance);
                return {
                    ...trans,
                    withdrawAmt: trans.depositFlag === 0 ? tAmt : '', // for withdraw field
                    depositAmt: trans.depositFlag === 1 ? tAmt : '', // for deposit field
                    balance: balance ? balance.toFixed(2) : 0
                }
            });
            // split the transactions for 4a and 4b
            let aTransaction = transactions.filter(aT => aT.replacementFlag === 1); // for 4a
            let bTransaction = transactions.filter(bT => bT.replacementFlag === 0); // for 4b
            // default fill
            if (aTransaction.length === 0) {
                aTransaction = this.getReplacementTransction(getOne.data);
            }

           

            if (id === undefined) {

                aTransaction.map((el) => {
                     el.depositAmt = "0.00"
                     el.withdrawAmt = "0.00"
                })
            }


            if (bTransaction.length === 0) {
                bTransaction = this.getOperatingTransction(getOne.data);
            }

            if (id == undefined) {
                bTransaction.map((el) => {
                     el.depositAmt = "0.00"
                    //  el.withdrawAmt = "0.00"
                })
            }

            const stData = {
                ...DefaultStateDatas,
                ...getOne.data,
                expenseCategory,
                aTransaction,
                bTransaction
            };
            this.setState({
                data: stData,
                cloneData: JSON.parse(JSON.stringify(stData)),
                reportYear_id: getOne.data.reportYearData._id,
                config: {
                    ...configuration
                },
                _id: getOne.data._id || 0
            })

        }
        this.setState({ created: false, isTable4Exists: getOne.isTable4Exists })
        console.log("======= ID ======",id)
    }

    componentDidMount(){
        let {isTable4Exists} = this.state;
        const generalData = this.getSaveData(this.state.data);
        let rpYear = history.location.pathname.split("/")[5]
        generalData['aTransaction'] = [
            {
                "_id" : 1,
                "replacementFlag" : 1,
                "date" : `12/31/${rpYear}`,
                "description" : "Annual Replacement Reserve Deposit from Table 4 (without bank interest)",
                "amount" : 0,
                "depositFlag" : 1,
                "updatedAt_4A" : new Date(),
                "updatedAt_4B" : new Date(),
                "createdAt" : null
            },
            {
                "_id" : 2,
                "replacementFlag" : 1,
                "date" : `12/31/${rpYear}`,
                "description" : "Annual Bank Interest Earned",
                "amount" : 0,
                "depositFlag" : 1,
                "updatedAt_4A" : new Date(),
                "updatedAt_4B" : new Date(),
                "createdAt" : null
            }
        ]

        generalData['bTransaction'] = [
            {
                "_id" : 3,
                "replacementFlag" : 0,
                "date" : `12/31/${rpYear}`,
                "description" : "Annual Operating Reserve Deposit from Table 4 (without bank interest)",
                "amount" : 0,
                "depositFlag" : 1,
                "updatedAt_4A" : new Date(),
                "updatedAt_4B" : new Date(),
                "createdAt" : null
            },
            {
                "_id" : 4,
                "replacementFlag" : 0,
                "date" : `12/31/${rpYear}`,
                "description" : "Annual Bank Interest Earned",
                "amount" : 0,
                "depositFlag" : 1,
                "updatedAt_4A" : new Date(),
                "updatedAt_4B" : new Date(),
                "createdAt" : null
            }
        ]
        //Check and save table4 if no record found for report year
        let rpID = history.location.pathname.split("/")[4]
        if(!isTable4Exists){
            try{
            this.props.checkTable4Data(rpID,generalData)
        }catch(err){
            console.log(err)
        }
    }
    }

    /**
     * Get Float
     * @param {*} value 
     * @returns 
     */
    getFloat(value) {
        return value === '' || value === null || value === 'undefined' || value === undefined ? 0 : parseFloat(value);
    }
    /**
     * static replacement transaction
     * @param {*} data 
     * @returns 
     */
    getReplacementTransction(data) {
        const replacementTrans = [];
        const { reportYearData: { year } } = data;
        // push reserve amount
        replacementTrans.push({
            ...DefaultRowDatas.aTransaction,
            editable: false,
            date: `12/31/${year}`,
            description: 'Annual Replacement Reserve Deposit from Table 4 (without bank interest)',
            amount: data.replacementReserveDeposit,
            depositFlag: 1,
            replacementFlag: 1
        });
        // push bank interest amount
        replacementTrans.push({
            ...DefaultRowDatas.aTransaction,
            editable: true,
            date: `12/31/${year}`,
            description: 'Annual Bank Interest Earned',
            amount: data.replacementReserveInterest,
            depositFlag: 1,
            replacementFlag: 1
        });
        // add if any withdrawal
        if (data.replacementReserveWithdrawal) {
            replacementTrans.push({
                ...DefaultRowDatas.aTransaction,
                editable: false,
                date: `12/31/${year}`,
                description: 'funds transfered to operating account',
                amount: data.replacementReserveWithdrawal,
                depositFlag: 0,
                replacementFlag: 1
            });
        }
        return replacementTrans;
    }
    /**
     *  static replacement transaction
     * @param {*} data 
     * @returns 
     */
    getOperatingTransction(data) {
        const operatingTrans = [];
        const { reportYearData: { year } } = data;
        // push reserve amount
        operatingTrans.push({
            ...DefaultRowDatas.bTransaction,
            editable: false,
            date: `12/31/${year}`,
            description: 'Annual Operating Reserve Deposit from Table 4 (without bank interest)',
            amount: data.operatingReserveDeposit,
            depositFlag: 1,
            replacementFlag: 0
        });
        // push bank interest amount
        operatingTrans.push({
            ...DefaultRowDatas.bTransaction,
            editable: true,
            date: `12/31/${year}`,
            description: 'Annual Bank Interest Earned',
            amount: data.operatingReserveInterest,
            depositFlag: 1,
            replacementFlag: 0
        });

        // add if any withdrawal
        if (data.operatingReserveWithdrawal) {
            operatingTrans.push({
                ...DefaultRowDatas.bTransaction,
                editable: false,
                date: `12/31/${year}`,
                description: 'funds transfered to operating account',
                amount: data.operatingReserveWithdrawal,
                depositFlag: 0,
                replacementFlag: 0
            });
        }

        return operatingTrans;
    }

    /**
     * A method to format expense category data to table view 
     *
     * @param {*} tableExpenses contains table4 expense data
     * @param {*} expenseCategory contains config expense data
     * @returns
     * @memberof Table4
     */
    formatExpenseCategory(tableExpenses, expenseCategory, previousExpenses) {
        // merge table4 data with expenseCategory for table view
        let expenseRowData = [];
        if (expenseCategory.length > 0) {
            expenseRowData = expenseCategory.map((ec) => {
                let curData = tableExpenses.filter(te => te.expenseCategory_id === ec.key)[0];
                let preData = previousExpenses.filter(te => te.expenseCategory_id === ec.key)[0];
                curData = curData || { ...DefaultRowDatas.expenseCategory };
                return {
                    _id: ec.key,
                    name: ec.name,
                    expenseCategory_id: ec.key,
                    operatingAmt: curData.operatingAmt,
                    servicesAmt: curData.servicesAmt,
                    preOperatingAmt: preData ? preData.operatingAmt : null,
                    preServicesAmt: preData ? preData.servicesAmt : null,
                    comment: curData.comment,
                    balance: ''
                }
            });
        }

        return expenseRowData;
    }
    /**
     * Method callback when the field components on change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        const { data, errorSections } = this.state;
        data[name] = value;
        let aTransactionUpdatedRowData;
        let bTransactionUpdatedRowData;
        let setData = {
            ...data
        }
        let formChilds = this.refs.form.childs;
        let currentChild = formChilds.filter(e => e.props.name === name)[0]
        let errorKey = ErrorNames.filter(e => name.match(e.name))[0].type;
        if (!currentChild.state.isValid) {
            errorSections[errorKey] = false;
        }

        // update transactions balance
        if (name === 'replacementReserveBeginningBalance') {
            const rowData = this.state.data.aTransaction;
            // update balances
            aTransactionUpdatedRowData = this.updateTransactionBalance(this.state.data.replacementReserveBeginningBalance, rowData);
            setData = {
                ...data,
                aTransaction: aTransactionUpdatedRowData
            }
        } else if (name === 'operatingReserveBeginningBalance') {
            const rowData = this.state.data.bTransaction;
            // update balances
            bTransactionUpdatedRowData = this.updateTransactionBalance(this.state.data.operatingReserveBeginningBalance, rowData);
            setData = {
                ...data,
                bTransaction: bTransactionUpdatedRowData
            }
        }
        this.setState({
            ...setData,
            errorSections: errorSections
        });
    }
    /**
     * Handle Change in Error Color
     * @param {*} dataName 
     * @param {*} fieldName 
     * @param {*} index 
     */
    handelChangeInErrorColor = (dataName, fieldName, index) => {
        const { data, errorSections } = this.state;
        let name = fieldName + '_' + index;
        if (dataName === 'aTransaction') {
            name = `replacement_reserve_${name}`;
            data.aTransaction[index].customError = false;
        }
        if (dataName === 'bTransaction') {
            name = `operating_reserve_${name}`;
            data.bTransaction[index].customError = false;
        }
        let formChilds = this.refs.form.childs;
        let currentChild = formChilds.filter(e => e.props.name.match(name))[0]
        if (currentChild) {
            if (dataName === 'expenseCategory') {
                if (name.match('operatingAmt')) {
                    name = `oprAmt`;
                }
                if (name.match('servicesAmt')) {
                    name = `serAmt`;
                }
                if (name.match('comment')) {
                    name = `expComment`;
                }
            }
            let errorKey = ErrorNames.filter(e => name.match(e.name))[0].type;
            // if(!currentChild.state.isValid){
            errorSections[errorKey] = false;
            this.setState({
                data: data,
                errorSections: errorSections
            })
            // }
        }
    }
    /**
     * Table 1 on submit / unsubmit click
     * @param {*} type 
     * @returns 
     */
    handleSubmitActionClick = (type) => {
        this.setState({ updateForm: false })
        // if not saved atleast once
        if (this.state._id === undefined || this.state._id === 0) {
            return this.setState({
                error: {
                    message: 'Table 4 must be saved at least once before Submission'
                }
            });
        }
        // disable submit when has hardstop
        const { validations } = this.props;
        if (validations && type === 'SUBMIT') {
            if (validations.data.table4 === 3) {
                this.props.showErrorAlert('Need to fix Hard stop errors before submit!');
                return;
            } else if (!validations.data.isPrevSubmitted) {
                this.props.showErrorAlert('Previous Year Report Not Submitted!');
                return;
            } else { }
        }

        if (type === "SUBMIT") {
            this.handleSaveButton();
            setTimeout(() => {
                if (!Object.values(this.state.errorSections).includes(true)) {
                    this.setState({
                        type,
                    });
                }
            }, 1000);
        } else {
            this.setState({
                type,
            });
        }
    }
    /**
     * Handle Download Action Click
     */
    // handleDownloadActionClick = () => {
    //     const { getOne } = this.props;
    //     const reportData = {
    //         reportYear_id: getOne.data.reportYearData._id,
    //         project_id: getOne.data.reportYearData.project_id,
    //         site_id: getOne.data.reportYearData.site_id,
    //         year: getOne.data.reportYearData.year
    //     }
    //     this.props.downloadTable4Report(reportData)
    // }
    /**
     * Get Save Data
     * @param {*} formData 
     * @returns 
     */
    getSaveData = (formData) => {
        // calc
        const { performanceMeasure, averageUnitTurnAround } = formData;
        let PM = {
            occupancyRate: performanceMeasure ? this.getFloat(performanceMeasure.occupancyRate) : 0.00,
            vacancyRate: performanceMeasure ? this.getFloat(performanceMeasure.vacancyRate) : 0.00,
            replacementReservePerUnit: getReplacementReservePerUnit(formData),
            operatingReservePerUnit: getOperatingReservePerUnit(formData),
            debtCoverageRatio: getDebtCoverage(formData),
            "vacancyRate_CALC": performanceMeasure ? this.getFloat(performanceMeasure.vacancyRate) : 0.00,
            "calculatedRateOccupancy_CALC": performanceMeasure ? this.getFloat(performanceMeasure.occupancyRate) : 0.00,
            averageUnitTurnAround: averageUnitTurnAround,
            "averageUnitTurnAround_CALC": averageUnitTurnAround,
            "actualPotentialRent_CALC": 0,
            "calculatedCollectionRate_CALC": 0,
            "replacementReservePerUnit_CALC": getReplacementReservePerUnit(formData),
            "operatingReservePerUnit_CALC": getOperatingReservePerUnit(formData),
        }

        const generalData = {
            ...formData,
            replacementReserveEndingBalance: getReplacementReservebalance(formData),
            operatingReserveEndingBalance: getOperatingReservebalance(formData),
            reportYear_id: this.state.reportYear_id,
            PM
        }

        return generalData;
    }
    /**
     * Method when the form is submit
     *
     * @memberof Table4
     */
    handleSubmit = () => {
        const { getOne } = this.props;
        const generalData = this.getSaveData(this.state.data);
        console.log("===== GENERAL DATA INSIDE SUBMIT ======",generalData)
        if (getOne.data) {
            if (getOne.data._id && !this.state.created) {
                // if _id  present update table
                if (this.state.updateForm) {
                    this.setState({ created: true })
                    this.props.updateTable4(getOne.data._id, generalData);
                }
            } else {
                if (!this.state.created) {
                    // if _id is undefined / not found create a new table 4 record
                    this.setState({ created: true })
                    this.props.createTable4({ ...generalData, reportYear_id: this.state.reportYear_id });
                }
            }
        }
    }
    /**
     * Pop Alert
     * @param {*} type 
     * @returns 
     */
    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess } = this.props;
        // const isReadOly = pageAccess.indexOf('SAVE') === -1;

        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={(name, value) => {
                                    this.setState({ [name]: value });
                                }}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                multiline={true}
                            // disabled={isReadOly}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }
    /**
     * Handle Submit Report
     */
    handleSubmitReport = () => {
        this._saveForm();
    }
    /**
     * sAVE fORM
     */
    _saveForm = () => {
        this.handleSubmit();

        setTimeout(() => {
            const { type } = this.state;
            let { reportId } = this.props;
            reportId = parseInt(reportId, 0);
            if (reportId) {
                if (type === 'SUBMIT') {
                    this.props.submitReport(reportId, { comment: this.state.comment });
                    this.setState({
                        type: null
                    });
                } else {
                    if (this.state.unsubmitComment !== '') {
                        this.props.unSubmitReport(reportId, { comment: this.state.unsubmitComment });
                        this.setState({
                            type: null
                        });
                    }
                }
            }
        }, 500);
    }
    /**
     * Update Transaction Balance
     * @param {*} beginingBalance 
     * @param {*} transactions 
     * @returns 
     */
    updateTransactionBalance = (beginingBalance, transactions) => {
        beginingBalance = beginingBalance === '' || beginingBalance === null || beginingBalance === undefined ? 0 : parseFloat(beginingBalance);
        return transactions.map((trans) => {
            const amt = trans.amount === '' || trans.amount === null || trans.amount === undefined ? 0 : parseFloat(trans.amount);
            const balance = trans.depositFlag === 0 ? beginingBalance - amt : beginingBalance + amt;
            beginingBalance = balance;
            trans.balance = balance;
            return {
                ...trans,
                balance: isNaN(balance) ? 0 : balance.toFixed(2)
            }
        });
    }
    /**
     * Get Income Total
     * @returns 
     */
    getIncomeTotal() {
        const { data } = this.state;

        const otherIncome = data.otherIncome.reduce((total, income) => {
            return total + getFloat(income.amount);
        }, 0)

        const subsidy = data.subsidyTypes.reduce((total, subsidy) => {
            return total + getFloat(subsidy.operatingAmt) + getFloat(subsidy.servicesAmt)
        }, 0);

        let validation = getFloat(otherIncome) + getFloat(data.commercialNetIncome) + getFloat(data.actualRentIncome) + getFloat(subsidy);

        if (validation <= 0) {
            return {
                required: true,
                message: 'Actual Rental Income or Other Income Source or Commercial Net Income or Subsidy Income must have a value greater than zero'
            };
        }
        return { required: false, message: null };
    }
    /**
     * Get Expense Total
     * @returns 
     */
    getExpenseTotal() {
        const { data } = this.state;

        const expense = data.expenseCategory.reduce((total, expense) => {
            return total + getFloat(expense.operatingAmt);
        }, 0)

        if (expense <= 0) {
            return {
                required: true,
                message: 'Expense should be Greater than 0'
            };
        }

        return { required: false, message: null };
    }
    /**
     * Handle Download Action Click
     */
    handleDownloadActionClick = () => {
        const data = this.state.cloneData;
        const { configuration: { othersIncome, subsidyType, lenders }, validations, reportTitle } = this.props;

        const reportYearData = data.reportYearData || {};
        const prevYearsData = data.previousExpenseCategory.reportYearData;
        const prevYrHeaders = prevYearsData.year ? [`${prevYearsData.year} Operating`, `${prevYearsData.year} Services`] : [];
        const prevFieldCols = prevYearsData.year ? ['preOperatingAmt', 'preServicesAmt'] : [];
        let { operatingAmt, servicesAmt, totalAmt, NOI, preOperatingAmt, preServicesAmt } = getExpensesCategory(data);
        // add footer
        const preExpenses = prevYearsData.year ? {
            'preOperatingAmt': preOperatingAmt,
            'preServicesAmt': preServicesAmt
        } : {};
        let { totalDebtService, cashFlow } = getDebtServices(data);

        const templateDatas = {
            title: reportTitle,
            year: reportYearData.year,
            responseType: "pdf",
            reportTable: "4",
            ...this.getSaveData(this.state.cloneData),
            totalOtherIncome: totalOtherIncome(data),
            totalGrossRentalIncome: totalGrossRentalIncome(data),
            totalSubsidyOperating: totalSubsidyOperating(data),
            totalSubsidyServices: totalSubsidyServices(data),
            totalSubsidyEGI: totalSubsidyEGI(data),
            otherIncome: data.otherIncome.map(m => {
                const data = othersIncome.filter(o => o.key === m.otherIncome_id)[0] || { label: 'none' };
                m.label = data.label;
                return m;
            }),
            subsidyTypes: data.subsidyTypes.map(m => {
                const data = subsidyType.filter(o => o.key === m.subsidyType_id)[0] || { label: 'none' };
                m.label = data.label;
                return m;
            }),
            debtServices: data.debtServices.map(m => {
                const data = lenders.filter(o => o.key === m.lender_id)[0] || { label: 'none' };
                m.label = data.label;
                return m;
            }),
            expenses_HeaderCols: ['', `${reportYearData.year} Operating`, `${reportYearData.year} Services`, ...prevYrHeaders, 'Comments'],
            expenses_FieldCols: ['name', 'operatingAmt', 'servicesAmt', ...prevFieldCols, 'comment'],
            expensesCategoryFooter: [
                {
                    name: 'Total:',
                    operatingAmt: operatingAmt,
                    servicesAmt: servicesAmt,
                    ...preExpenses
                },
                {
                    name: 'Total Expenses (Operating + Services):',
                    totalAmt: totalAmt
                },
                {
                    name: 'NOI: Net Operating Income (Effective Gross Income-Total Expenses):',
                    NOIAmt: NOI
                }
            ],
            totalDebtService,
            cashFlow,
            abText: `Deposits and Withdrawals During the Period ending December 31, ${reportYearData.year}`,
            isNegativeCashFLow: cashFlow < 0,
            validations: validations.data.table4Issues || []
        }
        this.props.downloadReportTable("4", templateDatas);
    }
    /**
     * Handle reset function
     * @param {*} childData 
     */
    handleResetData = (childData) => {
        const { data, cloneData, expansionSections } = this.state;
        let sections = expansionSections;
        if (childData.expanded) {
            sections[childData.resetKey] = true;
        } else {
            sections[childData.resetKey] = false;
        }
        if (childData.disabled) {
            var resetData = JSON.parse(JSON.stringify(data));
            resetDataKeys[childData.resetKey].forEach(key => {
                if (cloneData[key]) {
                    resetData[key] = JSON.parse(JSON.stringify(cloneData[key]))
                } else resetData[key] = null
            });
            this.setState({ data: resetData })
        }
    }
    /**
     * Handle Expansion Data
     * @param {*} data 
     * 
     * To handle retun statement from the child component.
     * To set state for expansion panel.
     * To reset enterd data on unedit state.
     */
    handleExpansionData(data) {
        let disabled = this.state.disabledSections;
        let errorSections = this.state.errorSections;
        if (data.disabled === false) {
            disabled[data.panel] = false
        }
        if (data.disabled === true) {
            disabled[data.resetKey] = true;
            errorSections[data.resetKey] = false;
        }
        if (data.resetData) {
            this.handleResetData(data);
        }
        let expansion = this.state.expansionSections;

        //Expand expansion panel if the panel is new (i.e., closed) else collapse the panel.
        //If edit is clicked & disabled state is false, expand else collapse.
        data.newExpanded ? expansion[data.panel] = true : data.edit && !data.disabled ? expansion[data.panel] = true : expansion[data.panel] = false;
        this.setState({
            expansionSections: expansion,
            disabledSections: disabled,
            errorSections: errorSections
        })
    }
    /**
     * Get Error Sections
     * @param {*} errors 
     * 
     * To open the expansion panel that contain errors
     */
    getErrorSections = (errors) => {
        let { data } = this.state;
        let errorSections = this.state.errorSections;
        let disabledSections = this.state.disabledSections;
        let errorLabels = [];
        let errorKeys = [];
        errorSections.income = false;
        errorSections.expense = false;
        errorSections.debtService = false;
        errorSections.replacementReserve = false;
        errorSections.operatingReserve = false;
        errors.forEach(error => {
            let errorData = ErrorNames.filter(e => error.props.name.match(e.name))[0]
            let errorKey = errorData.type;
            errorKeys.push(errorKey)
            let errorlabel = errorData.label;
            if (!errorSections[errorKey]) {
                errorSections[errorKey] = true;
            }
            if (disabledSections[errorKey]) {
                disabledSections[errorKey] = false;
            }
            if (!errorLabels.some(e => e === errorlabel)) {
                errorLabels.push(errorlabel);
            }
            if (error.props.name.match('date')) {
                let errorName = error.props.name.split('_')
                let rowIndex = errorName[3];
                if (error.props.name.match('replacement_reserve_date')) {
                    data.aTransaction[rowIndex].customError = true;
                }
                if (error.props.name.match('operating_reserve_date')) {
                    data.bTransaction[rowIndex].customError = true;
                }
            }
        })
        this.setState({
            data: data,
            errorLabels: errorLabels.join(', '),
            disabledSections: disabledSections,
            requiredFieldAlert: true,
        });
    }
    /**
     * Format Date
     * @param {*} data 
     * @returns 
     */
    formatDate(data) {
        let dateString = new Date(data.date);
        let date = dateString.getDate() < 10 ? '0' + dateString.getDate() : dateString.getDate();
        let month = dateString.getMonth() + 1 < 10 ? '0' + (dateString.getMonth() + 1) : (dateString.getMonth() + 1);
        let year = dateString.getFullYear();
        return `${month}/${date}/${year}`
    }
    /**
     * To handle Table 4 Save button
     */
    handleSaveButton() {
        const { data } = this.state;
        this.refs.form.submit();
        let stateObjects = {}
        let expansionSections = {
            income: false,
            expense: false,
            debtService: false,
            performance: false,
            comment: false,
            replacementReserve: false,
            operatingReserve: false
        }
        let warningSections = {
            expense: false,
            replacementReserve: false,
            operatingReserve: false
        }

        // check the form validation status and then change the sections
        this.refs.form.isFormValid().then(isValid => {
            if (isValid) {
                //To Reset Expansion Panel to collapse state and Input to be Disabled on succesful save
                if (this.refs.form.errors.length === 0) {
                    data.aTransaction.forEach(aTrans => {
                        aTrans.date = this.formatDate(aTrans);
                    })
                    data.bTransaction.forEach(bTrans => {
                        bTrans.date = this.formatDate(bTrans);
                    })
                    stateObjects = {
                        cloneData: JSON.parse(JSON.stringify(data)),
                        errorSections: {
                            income: false,
                            expense: false,
                            debtService: false,
                            performance: false,
                            comment: false,
                            replacementReserve: false,
                            operatingReserve: false
                        },
                        disabledSections: {
                            income: true,
                            expense: true,
                            debtService: true,
                            performance: true,
                            comment: true,
                            replacementReserve: true,
                            operatingReserve: true
                        }
                    }
                }
                this.setState({
                    expansionSections: expansionSections,
                    warningSections: warningSections,
                    ...stateObjects
                });
            } else {
                this.setState({
                    expansionSections: expansionSections,
                    warningSections: warningSections
                });
            }
        });
    }
    /**
     * Scroll To Content
     * @param {*} content 
     */
    scrollToContent(content) {
        let warningKey = WarningNames.filter(e => e.title.match(content.title))[0].type;
        switch (warningKey) {
            case 'expense':
                this.setState({
                    expansionSections: {
                        expense: true,
                        replacementReserve: this.state.expansionSections.replacementReserve,
                        operatingReserve: this.state.expansionSections.operatingReserve
                    },
                    disabledSections: {
                        expense: false,
                        replacementReserve: this.state.disabledSections.replacementReserve,
                        operatingReserve: this.state.disabledSections.operatingReserve
                    }
                })
                setTimeout(() => {
                    this.expenseRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 1000)
                break;
            case 'replacementReserve':
                this.setState({
                    expansionSections: {
                        expense: this.state.expansionSections.expense,
                        replacementReserve: true,
                        operatingReserve: this.state.expansionSections.operatingReserve
                    },
                    disabledSections: {
                        expense: this.state.disabledSections.expense,
                        replacementReserve: false,
                        operatingReserve: this.state.disabledSections.operatingReserve
                    }
                })
                setTimeout(() => {
                    this.replacementRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 1000)
                break;
            case 'operatingReserve':
                this.setState({
                    expansionSections: {
                        expense: this.state.expansionSections.expense,
                        replacementReserve: this.state.expansionSections.replacementReserve,
                        operatingReserve: true
                    },
                    disabledSections: {
                        expense: this.state.disabledSections.expense,
                        replacementReserve: this.state.disabledSections.replacementReserve,
                        operatingReserve: false
                    }
                })
                setTimeout(() => {
                    this.operatingRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 1000)
                break;
            default:
                break;
        }
    }
    /**
     * Handle Download Excel
     */
    handleExcelDownload = () => {
        const { getOne } = this.props;
        const reportData = {
            reportYear_id: getOne.data.reportYearData._id,
            project_id: getOne.data.reportYearData.project_id,
            site_id: getOne.data.reportYearData.site_id,
            year: getOne.data.reportYearData.year
        }
        this.props.downloadTable4Report(reportData.project_id, reportData.site_id, reportData.year)
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     */
    render() {
        const { classes, getOne, validations } = this.props;
        let { pageAccess } = this.props;
        let { data, config, expansionSections, disabledSections, errorSections, errorLabels, warningSections, anchorEl } = this.state;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        if (pageAccess.length === 0) return null; // wait for response
        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any Funder for this report year.'}</h4>;
        }
        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';
        let canDownload = this.state._id === null ? [] : ['DOWNLOAD'];
        if (validations.data.table4Issues) {
            warningSections = {
                expense: false,
                replacementReserve: false,
                operatingReserve: false
            }
            validations.data.table4Issues.forEach(warning => {
                let warningKey = WarningNames.filter(e => e.title.match(warning.title))[0].type;
                warningSections[warningKey] = true;
            })
        }
        return (
            <ContentWrapper
                ref={(c) => this.com = c}
                classes={classes}
                pageAccess={['REFRESH_VALIDATIONS', ...canDownload, ...pageAccess]}
                title={levelInfo}
                titleCss={'bolderText'}
                pageName="reports"
                validationContent={getOne.validations}
                needTitleBar={true}
                isSubPage={true}
                ischildPage={true}
                needPadding={true}
                handleSubmitActionClick={() => this.handleSubmitActionClick('SUBMIT')}
                handleUnSubmitActionClick={() => this.handleSubmitActionClick('UNSUBMIT')}
                handleRefreshValidationsClick={() => {
                    const { reportId } = this.props;
                    this.props.refreshValidations(0, 0, reportId, "4");
                }}
                handleDownloadActionClick={(event) => {
                    this.setState({ anchorEl: event.currentTarget })

                }}
                titleBg={'white'}
            >
                <TableErrors
                    fielderrors={[]}
                    data={getOne.data}
                    classes={{
                        action: classes.action,
                        message: classes.message,
                        root: classes.root
                    }}
                    validations={validations.data.table4Issues || []}
                    handleClickAction={(err) => this.scrollToContent(err)}
                />
                <div className={`${classes.rootEditSubPage}`} id="string-com">
                    <ValidatorForm
                        name="table4Form"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => {
                            this.getErrorSections(errors);
                        }
                        } >
                        <Grid item xs={12} sm={12}>
                        </Grid>
                        <div ref={this.incomeRef}>
                            <ExpansionPanelComponent
                                title={'Income'}
                                titleNo={'1'}
                                id={'income-header'}
                                controls={'income-content'}
                                expandedPanel={'income'}
                                expanded={expansionSections.income}
                                editAccess={!isReadOly}
                                errorSection={errorSections.income}
                                disableEdit={disabledSections.income}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <Income
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                    handleChange={this.handleChange}
                                    isDisabled={disabledSections.income}
                                    onChange={(dataName, fieldName, rowIndex, value) => {
                                        this.handelChangeInErrorColor(dataName, fieldName, rowIndex)
                                        const rowData = this.state.data[dataName];
                                        rowData[rowIndex][fieldName] = value;
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData
                                            }
                                        });
                                    }}
                                    onAdd={(dataName) => {
                                        const rowData = this.state.data[dataName];
                                        rowData.push({
                                            ...DefaultRowDatas[dataName],
                                            _id: Math.max(...rowData.map(elt => elt._id), 0) + 1
                                        });
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData
                                            }
                                        });
                                    }}
                                    onDelete={(dataName, item) => {
                                        const rowData = this.state.data[dataName];
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData.filter((el, index) => { return index !== item.rowIndex })
                                            }
                                        });
                                    }}
                                />
                            </ExpansionPanelComponent>
                        </div>
                        <div ref={this.expenseRef}>
                            <ExpansionPanelComponent
                                title={'Expense'}
                                titleNo={'2'}
                                id={'expense-header'}
                                controls={'expense-content'}
                                expandedPanel={'expense'}
                                editAccess={!isReadOly}
                                expanded={expansionSections.expense}
                                disableEdit={disabledSections.expense}
                                errorSection={errorSections.expense}
                                warningSection={warningSections.expense}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <Expenses
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                    isDisabled={disabledSections.expense}
                                    onChange={(dataName, fieldName, rowIndex, value) => {
                                        this.handelChangeInErrorColor(dataName, fieldName, rowIndex)
                                        const rowData = this.state.data[dataName];
                                        rowData[rowIndex][fieldName] = value;
                                        const upData = {
                                            ...this.state.data,
                                            [dataName]: rowData
                                        };

                                        // get and update deposit amount for a and b transactions
                                        const replacementReserveDeposit = getReplacementReserveDeposit(upData);
                                        const operatingReserveDeposit = getOperatingReserveDeposit(upData);

                                        const aTransaction = this.state.data.aTransaction.map((s, i) => {
                                            if (i === 0) {
                                                s.depositAmt = replacementReserveDeposit
                                            }
                                            return s;
                                        });

                                        const bTransaction = this.state.data.bTransaction.map((s, i) => {
                                            if (i === 0) {
                                                s.depositAmt = operatingReserveDeposit
                                            }
                                            return s;
                                        });

                                        this.setState({
                                            data: {
                                                ...upData,
                                                replacementReserveDeposit,
                                                operatingReserveDeposit,
                                                aTransaction,
                                                bTransaction
                                            },

                                        });
                                    }}
                                />
                            </ExpansionPanelComponent>
                        </div>
                        <div ref={this.debtServicesRef}>
                            <ExpansionPanelComponent
                                title={'Debt Services Payment'}
                                titleNo={'3'}
                                id={'debt-service-header'}
                                controls={'debt-service-content'}
                                expandedPanel={'debtService'}
                                editAccess={!isReadOly}
                                expanded={expansionSections.debtService}
                                disableEdit={disabledSections.debtService}
                                errorSection={errorSections.debtService}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <DebtServices
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                    isDisabled={disabledSections.debtService}
                                    onChange={(dataName, fieldName, rowIndex, value) => {
                                        this.handelChangeInErrorColor(dataName, fieldName, rowIndex)
                                        const rowData = this.state.data[dataName];
                                        rowData[rowIndex][fieldName] = value;
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData
                                            }
                                        });
                                    }}
                                    onAdd={(dataName) => {
                                        const rowData = this.state.data[dataName];
                                        rowData.push({
                                            ...DefaultRowDatas[dataName],
                                            _id: Math.max(...rowData.map(elt => elt._id), 0) + 1
                                        });
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData
                                            }
                                        });
                                    }}
                                    onDelete={(dataName, item) => {
                                        const rowData = this.state.data[dataName];
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData.filter((el, index) => { return index !== item.rowIndex })
                                            }
                                        });
                                    }}
                                    handleChange={this.handleChange}
                                />
                            </ExpansionPanelComponent>
                        </div>
                        <div ref={this.performanceRef}>
                            <ExpansionPanelComponent
                                title={'Performance Measures'}
                                titleNo={'4'}
                                id={'performance-header'}
                                controls={'performance-content'}
                                expandedPanel={'performance'}
                                expanded={expansionSections.performance}
                                errorSection={errorSections.performance}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <PerformanceMeasures
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                />
                            </ExpansionPanelComponent>
                        </div>
                        <div ref={this.commentRef}>
                            <ExpansionPanelComponent
                                title={'Table 4 Comments'}
                                titleNo={'5'}
                                id={'comments-header'}
                                controls={'comments-content'}
                                expandedPanel={'comment'}
                                editAccess={!isReadOly}
                                expanded={expansionSections.comment}
                                disableEdit={disabledSections.comment}
                                errorSection={errorSections.comment}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <Comments
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                    onChange={this.handleChange}
                                    isDisabled={disabledSections.comment}
                                    handleReset={(e) => this.handleResetData(e)}
                                />
                            </ExpansionPanelComponent>
                        </div>
                        <div ref={this.replacementRef}>
                            <ExpansionPanelComponent
                                title={'Table 4 (a) Replacement Reserve'}
                                titleNo={'6'}
                                id={'replacement-header'}
                                controls={'replacement-content'}
                                expandedPanel={'replacementReserve'}
                                editAccess={!isReadOly}
                                expanded={expansionSections.replacementReserve}
                                disableEdit={disabledSections.replacementReserve}
                                errorSection={errorSections.replacementReserve}
                                warningSection={warningSections.replacementReserve}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <ReplacementReserve
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                    handleChange={this.handleChange}
                                    isDisabled={disabledSections.replacementReserve}
                                    onChange={(dataName, fieldName, rowIndex, value) => {
                                        this.handelChangeInErrorColor(dataName, fieldName, rowIndex)
                                        const rowData = this.state.data[dataName];
                                       // rowData[rowIndex][fieldName] = value;
                                       if (moment.isMoment(value)) {
                                                 rowData[rowIndex][fieldName] = moment(value).format("MM/DD/YYYY");
                                       }else{
                                        rowData[rowIndex][fieldName] = value;

                                       }
                                        // update any one field withdrwal or deposit
                                        if (fieldName === 'withdrawAmt') {
                                            // Fix for WBAR-3287 - 20/04/2024
                                            // rowData[rowIndex].depositAmt = null;
                                            rowData[rowIndex].depositAmt = '';
                                            rowData[rowIndex].amount = value;
                                            rowData[rowIndex].depositFlag = 0;
                                        } else if (fieldName === 'depositAmt') {
                                             // Fix for WBAR-3287 - 20/04/2024
                                           // rowData[rowIndex].withdrawAmt = null;
                                            rowData[rowIndex].withdrawAmt = '';
                                            rowData[rowIndex].amount = value;
                                            rowData[rowIndex].depositFlag = 1;
                                        }

                                        // update balances
                                        const updatedRowData = this.updateTransactionBalance(this.state.data.replacementReserveBeginningBalance, rowData);
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: updatedRowData
                                            }
                                        });
                                    }}
                                    onAdd={(dataName) => {
                                        const rowData = this.state.data[dataName];
                                        rowData.push({
                                            ...DefaultRowDatas[dataName],
                                            _id: Math.max(...rowData.map(elt => elt._id), 0) + 1
                                        });
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData
                                            }
                                        });
                                    }}
                                    onDelete={(dataName, item) => {
                                        const rowData = this.state.data[dataName];
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData.filter((el, index) => { return index !== item.rowIndex })
                                            }
                                        });
                                    }}
                                />
                            </ExpansionPanelComponent>
                        </div>
                        <div ref={this.operatingRef}>
                            <ExpansionPanelComponent
                                title={'Table 4 (b) Operating Reserve'}
                                titleNo={'7'}
                                id={'operating-header'}
                                controls={'operating-content'}
                                expandedPanel={'operatingReserve'}
                                editAccess={!isReadOly}
                                expanded={expansionSections.operatingReserve}
                                disableEdit={disabledSections.operatingReserve}
                                errorSection={errorSections.operatingReserve}
                                warningSection={warningSections.operatingReserve}
                                handleData={(e) => this.handleExpansionData(e)}
                            >
                                <OperatingReserve
                                    pageAccess={pageAccess}
                                    data={data}
                                    config={{ ...config, isReadOly }}
                                    classes={classes}
                                    handleChange={this.handleChange}
                                    isDisabled={disabledSections.operatingReserve}
                                    onChange={(dataName, fieldName, rowIndex, value) => {
                                        this.handelChangeInErrorColor(dataName, fieldName, rowIndex)
                                        const rowData = this.state.data[dataName];
                                        //rowData[rowIndex][fieldName] = value;
                                        if (moment.isMoment(value)) {
                                            rowData[rowIndex][fieldName] = moment(value).format("MM/DD/YYYY");
                                        }else{
                                        rowData[rowIndex][fieldName] = value;

                                        }

                                        // update any one field withdrwal or deposit
                                        if (fieldName === 'withdrawAmt') {
                                            rowData[rowIndex].depositAmt = '';
                                            rowData[rowIndex].amount = value;
                                            rowData[rowIndex].depositFlag = 0;
                                        } else if (fieldName === 'depositAmt') {
                                            rowData[rowIndex].withdrawAmt = '';
                                            rowData[rowIndex].amount = value;
                                            rowData[rowIndex].depositFlag = 1;
                                        }

                                        // update balances
                                        const updatedRowData = this.updateTransactionBalance(this.state.data.operatingReserveBeginningBalance, rowData);

                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: updatedRowData
                                            }
                                        });
                                    }}
                                    onAdd={(dataName) => {
                                        const rowData = this.state.data[dataName];
                                        rowData.push({
                                            ...DefaultRowDatas[dataName],
                                            _id: Math.max(...rowData.map(elt => elt._id), 0) + 1
                                        });
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData
                                            }
                                        });
                                    }}
                                    onDelete={(dataName, item) => {
                                        const rowData = this.state.data[dataName];
                                        this.setState({
                                            data: {
                                                ...this.state.data,
                                                [dataName]: rowData.filter((el, index) => { return index !== item.rowIndex })
                                            }
                                        });
                                    }}
                                />
                            </ExpansionPanelComponent>
                        </div>

                        {
                            pageAccess.indexOf('SAVE') > -1 ? (
                                <SaveCancel
                                    handleSaveButtonClick={() => { this.handleSaveButton() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />)
                                : ''
                        }

                        <ValidatorForm ref="submitReport" onSubmit={this.handleSubmitReport}>
                            <AlertDialog
                                open={this.state.type !== null}
                                title={'Warning:'}
                                onSave={() => this.refs.submitReport.submit()}
                                onClose={() => {
                                    this.setState({ type: null ,updateForm:true})
                                }}
                                saveText={'Submit'}
                            >
                                {this.popAlert(this.state.type)}
                            </AlertDialog>
                        </ValidatorForm>

                        {
                            this.state.error !== null && (
                                <AlertDialog
                                    open={true}
                                    title={'Error:'}
                                    saveVisible={false}
                                    onClose={() => {
                                        this.setState({ error: null })
                                    }}
                                    cancelText={'OK'}
                                >
                                    {this.state.error.message}
                                </AlertDialog>
                            )
                        }
                        {
                            this.state.errorLabels.length > 0 && (
                                <SnackBar
                                    type={'error'}
                                    style={{ top: '50px',zIndex:'9999999'}}
                                    message={`Please resolve the errors in ${errorLabels}`}
                                    onClose={() => {
                                        this.setState({ errorLabels: [] });
                                    }}
                                />
                            )
                        }
                    </ValidatorForm>
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    <MenuItem onClick={() => {
                        if (this.state._id !== null) {
                            this.handleDownloadActionClick();
                        }
                    }}>Download PDF</MenuItem>
                    <MenuItem onClick={() => { this.handleExcelDownload() }}>Download Excel</MenuItem>
                </Menu>
            </ContentWrapper >
        );
    }
}
/**
 *  Component default Props
 */
Table4.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    reportId: null
}
/**
 * Bind Component Property Types
 */
Table4.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 */
const mapStateToProps = ({ projects, sites, pageAccess, reportTables, configuration: { configurations } }) => {
    let subsidyType = configurations[configurationConstants.subsidyType];
    return {
        pageAccess: pageAccess['table4'] || [],
        getOne: reportTables.table4,
        validations: { data: reportTables.validationIssues },
        configuration: {
            othersIncome: configurations[configurationConstants.otherIncome],
            subsidyType: subsidyType,
            expenseCategory: configurations[configurationConstants.expenseCategory],
            lenders: configurations[configurationConstants.lenders],
            allLoaded: configurations[configurationConstants.otherIncome].length > 0
                && configurations[configurationConstants.subsidyType].length > 0
                && configurations[configurationConstants.expenseCategory].length > 0
                && configurations[configurationConstants.lenders].length > 0
        },
        // for pdf title
        reportTitle: projects.getOne.data && sites.getOne.data ? `${projects.getOne.data.name} - ${sites.getOne.data.name}` : false
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable4Data: (id, type) => dispatch(reportTablesActions.getTable4Data(id, type)),
        getConfiguration: (name) => dispatch(confActions.getConfiguration(name)),
        createTable4: (formData) => dispatch(reportTablesActions.createTable4(formData)),
        updateTable4: (id, formData) => dispatch(reportTablesActions.updateTable4(id, formData)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 4)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 4)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        // alertRequiredField: () => dispatch(alertActions.error("Please Enter the Required Fields")),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        refreshValidations: (project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) => dispatch(reportTablesActions.refreshValidations(project_id, site_id, reportYear_id, reportTable)),
        downloadReportTable: (reportTable = null, data) => dispatch(reportTablesActions.downloadReportTable(reportTable, data)),
        downloadTable4Report: (project_id, site_id, year) => dispatch(reportTablesActions.downloadTable4Report(project_id, site_id, year)),
        checkTable4Data : (reportYear_id,data) => dispatch(reportTablesActions.checkIfTable4Exists(reportYear_id,data))

    }
};
/** Export Component */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(Table4));