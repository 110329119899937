import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Tab components
import General from './tabs/General';
import FundersRouter from '../Funders/Funders.Router';
import Sites from './tabs/Sites';
import Reports from './tabs/Reports';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { history, getUserRole, getUserId, getUserOrgId } from '../../helpers';
import { getTitle } from '../../helpers/setTitle';

// Actions
import { projectsActions } from '../../actions/projects';
import { helpActions } from '../../actions';

const helpMap = {
    table1: 2,
    table2: 3,
    table3: 3,
    table4: 4
}
/**
 * ProjectView Container
 * @class ProjectView
 * @extends {Component}
 */
class ProjectView extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            openHelp: false,
            helpTitle: '',
            selectedTable: ''
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount = () => {
        const { projectId, location: { state, pathname } } = this.props;
        if (projectId) {
            this.props.getById(projectId);
        }
        if(state){
            this.handleReportTable(state.page)
        } else {
            let pathNames = pathname.split('/')
            let latestPathName = pathNames[pathNames.length - 1]
            this.handleReportTable(latestPathName)
        }
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match, projectId } = this.props;
        switch (value) {
            case 0:
                history.push({
                    pathname: `${match.url}/${projectId}`,
                    state: {
                        page: 'projectEdit',
                        pageData: {
                            project_id: projectId
                        }
                    }
                });
                break;
            case 1:
                history.push({
                    pathname: `${match.url}/${projectId}/funders`,
                    state: {
                        page: 'fundersList',
                        pageData: {
                            project_id: projectId
                        }
                    }
                });
                break;
            case 2:
                history.push({
                    pathname: `${match.url}/${projectId}/sites`,
                    state: {
                        page: 'sitesList',
                        pageData: {
                            project_id: projectId
                        }
                    }
                });
                break;
            default:
                history.push({
                    pathname: `${match.url}/${projectId}/reports`,
                    state: {
                        page: 'reportList',
                        pageData: {
                            project_id: projectId
                        }
                    }
                });
                break;
        }
    };
    /**
    * Handle Clear Fileter Action
    */
    handleClearFilterActionClick = () => {
        const { location: { pathname } } = this.props;
        history.replace(`${pathname}#clear`);
    };
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.projectRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} id 
     * @param {*} formData 
     */
    handleFormSaveActionClick = (id, formData) => {
        if (formData) {
            this.props.updateProject(id, formData);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Report table select
     * @param {*} table 
     */
    handleReportTable = (table) => {
        switch (table) {
            case 'table1':
                this.props.getHelp(helpMap['table1']);
                this.setState({ helpTitle: 'Report Table 1', selectedTable: 'table1' })
                break;
            case 'table2':
                this.props.getHelp(helpMap['table2']);
                this.setState({ helpTitle: 'Report Tables 2 and 3', selectedTable: 'table2' })
                break;
            case 'table3':
                this.props.getHelp(helpMap['table3']);
                this.setState({ helpTitle: 'Report Tables 2 and 3', selectedTable: 'table3' })
                break;
            case 'table4':
                this.props.getHelp(helpMap['table4']);
                this.setState({ helpTitle: 'Report Table 4', selectedTable: 'table4' })
                break;
            default:
                break;
        }
    }

    /**
     * Bind Html to DOM
     */
    render() {
        let { classes, match, loading, projectId, location: { pathname },
            pageAccess, needPadding, pageTitle, helpData } = this.props;
        const { openHelp, helpTitle, selectedTable } = this.state
        match.url = match.url.replace(/\/$/, "");
        const currentTab = pathname.includes('/funders') ? 1 : (pathname.includes('/sites') ? 2 : (pathname.includes('/reports') ? 3 : 0))
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        if (this.props.location.state) {
            if (this.props.location.state.page !== selectedTable) {
                this.handleReportTable(this.props.location.state.page);
            }
        }
        let pathNames = this.props.location.pathname.split('/')
        let latestPathName = pathNames[pathNames.length - 1]
        if (latestPathName === 'table1' || latestPathName === 'table2' || latestPathName === 'table4' || latestPathName === 'table3') {
            pageAccess = [...pageAccess, 'HELP']
        }
        return (
            // <div className="occupantsView">
                <ContentWrapper
                    classes={classes}
                    pageAccess={[...pageAccess, 'BACK']}
                    subTitle={pageTitle.subTitle}
                    title={pageTitle.title}
                    handleAddActionClick={this.handleAddActionClick}
                    handleBackActionClick={this.handleBackActionClick}
                    needPadding={needPadding}
                    handleSaveActionClick={this.handleSaveActionClick}
                    handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                    handleHelpActionClick={this.handleHelpActionClick}
                >

                    <AppBar position="static" color="default">
                        <Tabs
                            name={'tab'}
                            value={currentTab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab label="GENERAL" value={0} />
                            <Tab label="FUNDERS" value={1} />
                            <Tab label="SITES" value={2} />
                            <Tab label="REPORTS" value={3} />
                        </Tabs>

                    </AppBar>
                    {loading &&
                        <LinearProgress className='tabLoader' />
                    }
                    {/* <div className="occupantsTab"> */}
                        <TabContainer>
                            <Route
                                exact
                                path={`${match.url}/:project_id`}
                                render={(props) => {
                                    return (
                                        
                                        <General
                                            pageAccess={pageAccess}
                                            isReadOly={isReadOly}
                                            projectId={projectId}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleFormSaveActionClick={(id, formData) => this.handleFormSaveActionClick(id, formData)}
                                        />
                                        
                                    )
                                }}
                            />
                            

                            <Route
                                path={`${match.url}/${projectId}/funders`}
                                render={(props) => {
                                    return (
                                        <FundersRouter
                                            {...props}
                                            isSubPage={true}
                                            projectId={projectId}
                                        />
                                    )
                                }}
                            />
                            <Route
                                path={`${match.url}/${projectId}/sites`}
                                render={(props) => {
                                    return (
                                        <Sites
                                            projectId={projectId}
                                            isSubPage={true}
                                            {...props}
                                        />
                                    )
                                }}
                            />
                            <Route
                                path={`${match.url}/${projectId}/reports`}
                                render={(props) => {
                                    return (
                                        <Reports
                                            {...props}
                                            isSubPage={true}
                                            projectId={projectId}
                                        />
                                    )
                                }}
                            />
                        </TabContainer>
                        {openHelp && <HelpFloater
                            handleCloseFloater={() => this.setState({ openHelp: false })}
                            {...helpData}
                            title={helpTitle}
                        />}
                    {/* </div> */}
                </ContentWrapper>
             /* </div>  */

        );
    }
}
/**
 * Bind Component Property Types
 */
ProjectView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    needPadding: PropTypes.bool,
    loading: PropTypes.bool
};

ProjectView.defaultProps = {
    isSubPage: false,
    pageAccess: [],
    title: '',
    needPadding: false,
    loading: false
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const { projects, pageAccess } = state;
    const { location: { state: locState } } = ownProps;
    const statePageTitle = locState || {};
    const pageTitle = getTitle(history.location.pathname, state) === undefined ? statePageTitle.pageTitle || {} : getTitle(history.location.pathname, state);
    const helpData = state.help.data[0]
    return {
        details: projects.getOne,
        pageAccess: pageAccess['projectEdit'] || [],
        loading: state.projects.isLoading,
        pageTitle,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        updateProject: (id, formData) => dispatch(projectsActions.update(id, formData)),
        getById: (id) => dispatch(projectsActions.getById(id)),
        getHelp: (id) => dispatch(helpActions.getAll({ _id: id })),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ProjectView));
