import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TableHead, TableRow, TableCell, TableBody, Table } from '@material-ui/core';

// DND
import { DragDropContext } from 'react-beautiful-dnd';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

//Import Components
import ProjectMerge from "../../../components/MergeTool/ProjectMerge";

//Import Actions
import { mergeActions } from '../../../actions/mergeActions';
import { alertActions } from '../../../actions';

/**
 * a little function to help us with reordering the result
 * @param {*} list 
 * @param {*} startIndex 
 * @param {*} endIndex 
 * @returns 
 */
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
/**
 * Moves an item from one list to another list.
 * @param {*} source 
 * @param {*} destination 
 * @param {*} droppableSource 
 * @param {*} droppableDestination 
 * @returns 
 */
const move = (source, destination, droppableSource, droppableDestination, draggableId) => {
    const destClone = [...destination];
    const sourceFiltered = source.filter(s => s._id !== draggableId);
    const removed = source.filter(s => s._id === draggableId)[0];
    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceFiltered;
    result[droppableDestination.droppableId] = destClone;
    return result;
};
/**
 * HTML Tooltip
 * @param {*} props 
 * @returns 
 */
function HTMLTooltip(props) {
    let header = ["Site Name", "Site Key", "No of Buildings", "No of Units"];
    const siteName = props.name ? props.name : "No Site Name";
    const sitekey = props.value ? props.value : "No Site Key"
    let buildingsCount = props.buildings ? props.buildings.length : "No Buildings";
    let unitsCount = props.units ? props.units.length : "No Units";
    return (
        <div>
            <Table>
                <TableHead className={'tooltiphead'}>
                    <TableRow>
                        {header.map((header, index) => {
                            return <TableCell >{header} </TableCell>
                        })}
                    </TableRow>
                </TableHead>

                <TableBody className={'tooltipbody'}>
                    <TableRow >
                        <TableCell > {siteName}</TableCell>
                        <TableCell > {sitekey}&nbsp;&nbsp;</TableCell>
                        <TableCell >{buildingsCount} </TableCell>
                        <TableCell >{unitsCount} </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
}
/**
 * Sites Component
 * @class Sites
 * @extends {Component}
 */
class Sites extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            project1: 0,
            project2: 0,
            project1Name: null,
            project2Name: null,
            items: [],
            selected: [],
            filtered: [],
            sites1: [],
            sites2: [],
            sites1Static: [],
            sites2Static: [],
            draggedSites: [],
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.props.clearMergeSelectionDate('sitesTab');
        setTimeout(() => {
            this.props.getProjectsByName('');
        }, 100);
    }
    /**
     * Component Will Receive Props
     * @param {*} props 
     */
    UNSAFE_componentWillReceiveProps(props) {
        if (props.sites) {
            const { fromSites, toSites } = props.sites;
            let sites1 = [...fromSites.sites];
            let sites2 = [...toSites.sites];
            if (this.state.draggedSites.length) {
                this.setState({
                    sites1: this.state.sites1 ? [...this.state.sites1] : [],
                    sites2: this.state.sites2 ? [...this.state.sites2] : [],
                    sites1Static: this.state.sites1 ? [...this.state.sites1] : [],
                    sites2Static: this.state.sites2 ? [...this.state.sites2] : []
                });
            } else {
                this.setState({
                    sites1: [...sites1],
                    sites2: [...sites2],
                    sites1Static: [...sites1],
                    sites2Static: [...sites2]
                });
            }
        }
        //clear data after merge
        if(props.mergeSuccess===true){
            this.setState({tab: 0,
                project1: 0,
                project2: 0,
                project1Name: null,
                project2Name: null,
                items: [],
                selected: [],
                filtered: [],
                sites1: [],
                sites2: [],
                sites1Static: [],
                sites2Static: [],
                draggedSites: [],})
        }
    }

    id2List = {
        droppable: 'sites1',
        droppable2: 'sites2'
        // droppable2: 'filtered'
    };
    /**
     * Get list
     * @param {*} _id 
     * @returns 
     */
    getList = _id => this.state[`${this.id2List[_id]}Static`];
    /**
     * Handle Merge
     * @param {*} formData 
     */
    handleMerge = (formData) => {
        let sitesids = formData ? formData.filter(i => i.project_id === this.state.project1) : [];

        let siteDetails = {
            project1: this.state.project1 ? this.state.project1 : null,
            project2: this.state.project2 ? this.state.project2 : null,
            sitesIds: sitesids.map(i => { return i._id }),
            project1Name: this.state.project1Name ? this.state.project1Name : null,
            project2Name: this.state.project2Name ? this.state.project2Name : null,
            contentMsg: `Do you want to migrate the sites(s) from project <b>${this.state.project1Name}</b> to project <b>${this.state.project2Name}</b>?`
        }
        setTimeout(() => {
            this.props.handleMergeClick(formData, siteDetails);
        }, 500);
    }
    /**
     * Drag Search Handler
     * @param {*} event 
     * @param {*} droppableId 
     */
    DragsearchHandler(event, droppableId) {
        const updateOn = droppableId === 'droppable2' ? 'sites2' : 'sites1';
        let items = [...this.state[`${updateOn}Static`]];
        let search = event.target.value.toLowerCase();
        items = items.filter((el) => {
            let searchValue = el.name.toLowerCase();
            return searchValue.includes(search);
        });
        this.setState({
            [updateOn]: items,
        });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, projects, currentTab, pageAccess } = this.props;
        let { project1, project2, sites1, sites2 } = this.state;
        let selectedColumns = [];
        return (
            <div className={classes.rootEdit}>
                <DragDropContext
                    onDragEnd={(result) => {
                        const { source, destination, draggableId } = result;
                        this.setState({ draggedSites: [...this.state.draggedSites, { source, destination, draggableId }] });

                        // dropped outside the list
                        if (!destination) {
                            return;
                        }

                        if (source.droppableId === destination.droppableId) {
                            const items = reorder(
                                this.getList(source.droppableId),
                                source.index,
                                destination.index
                            );
                            let state = { items };
                            if (source.droppableId === 'droppable2') {
                                state = { selected: items };
                            }
                            this.setState(state);
                        } else {
                            const res = move(
                                this.getList(source.droppableId),
                                this.getList(destination.droppableId),
                                source,
                                destination,
                                +draggableId
                            );
                            let sourceList = this.id2List[source.droppableId];
                            let destinationList = this.id2List[destination.droppableId];

                            this.setState({
                                items: res.droppable2,
                                selected: res.droppable2,
                                filtered: res.droppable2,
                                [sourceList]: res[source.droppableId],
                                [destinationList]: res[destination.droppableId]
                            });
                            if (!this.state.project2) {
                                this.props.showErrorAlert("Please select To project")
                            }
                            else {
                                this.handleMerge(res.droppable2);
                            }
                        }

                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} >
                            <ProjectMerge
                                suggestions={projects}
                                value={project1}
                                name={'projectName1'}
                                onChange={(value, selectedOption) => {
                                    if (value === this.state.project2) {
                                        this.props.showErrorAlert("Same projects are not allowed to merge");
                                    } else {
                                        this.setState({
                                            project1: value,
                                            project1Name: selectedOption.label,
                                            draggedSites: []
                                        }, () => {
                                            this.props.getSitesByProjectId('fromSites', value);
                                        });
                                    }

                                }}
                                itemsSites={project1 ? sites1 : []}
                                selected={selectedColumns}
                                droppableId={'droppable'}
                                projectId={this.state.project1}
                                currentTab={currentTab}
                                pageAccess={pageAccess}
                                toolTipHtmlContent={(item) => {
                                    return <HTMLTooltip {...item} />
                                }}
                                DragsearchHandler={(event, name) => {
                                    this.DragsearchHandler(event, name)
                                }}

                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <ProjectMerge
                                isDisableFirst={true}
                                disableProject={project2}
                                suggestions={projects}
                                value={project2}
                                name={'projectName2'}
                                onChange={(value, selectedOption) => {
                                    if (value === this.state.project1)
                                        this.props.showErrorAlert("Same projects are not allowed to merge");
                                    else {
                                        this.setState({
                                            project2: value,
                                            project2Name: selectedOption.label,
                                            draggedSites: []
                                        }, () => {
                                            let sites = this.props.getSitesByProjectId('toSites', value);
                                            if (sites === undefined) {
                                                this.props.clearMerge()
                                            }

                                        });
                                    }

                                }}
                                itemsSites={project2 ? sites2 : []}
                                selected={selectedColumns}
                                droppableId={'droppable2'}
                                projectId={this.state.project2}
                                currentTab={currentTab}
                                pageAccess={pageAccess}
                                toolTipHtmlContent={(item) => {
                                    return <HTMLTooltip {...item} />
                                }}
                                DragsearchHandler={(event, name) => {
                                    this.DragsearchHandler(event, name)
                                }}
                            />
                        </Grid>
                    </Grid>
                </DragDropContext>

            </div >
        )
    }
}

/**
 * Bind Proptypes 
 */
Sites.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    handleMergeClick: PropTypes.func,
    pageAccess: PropTypes.array
};

/**
 * Default PropTypes
 */
Sites.defaultProps = {
    pageAccess: [],
    currentTab: null,
    handleMergeClick: () => { },
    clearMerge: () => { }
};

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        projects: state.merge.projects,
        sites: state.merge.sitesTab,
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        getProjectsByName: (text) => dispatch(mergeActions.getProjectsByName(text)),
        getSitesByProjectId: (name, value) => dispatch(mergeActions.getSitesByProjectId(name, value)),
        clearMergeSelectionDate: (tabName) => dispatch(mergeActions.clearMergeSelectionDate(tabName)),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Sites));