import { Table2Messages } from "./messages";

const Table2Validations = (data) => {
    const errors = [];
    const { validationErrors } = data;
    
    // section - 1 
    // const totalRestricted = getFloat(restrictedOwnerUnits) + getFloat(restrictedRenderUnits);
    // if (totalNumberofRestrictedUnits !== totalRestricted) {
    //     errors.push({
    //         msg: Table2Messages.section1,
    //         fieldName: 'unitstotal',
    //         sectionIndex: 1
    //     });
    // }

    // TotalNumberofRestrictedUnitsNotMatchWithSystem (yellow validation onchange) )
    const unitTotalNotMatch = validationErrors.filter(vr => vr.title === "TotalNumberofRestrictedUnitsNotMatchWithSystem").length > 0;
    let units = parseInt(data.restrictedRenderUnits ? data.restrictedRenderUnits : 0, 10) + parseInt(data.restrictedOwnerUnits ? data.restrictedOwnerUnits : 0, 10)
    
    const unitMatchWithValues = !isNaN(parseInt(data.totalNumberofRestrictedUnits))? (data.totalNumberofRestrictedUnits !== units?true:false): (data.totalNumberofUnits  !== units?true:false);

    // const unitMatchWithValues = data.totalNumberofUnits && data.totalNumberofRestrictedUnits !== units?true:false;

    if (unitTotalNotMatch && unitMatchWithValues) {
        errors.push({
            msg: Table2Messages.section1,
            fieldName: 'unitstotal',
            sectionIndex: 1,
            type: 3
        });
    }

    // section - 4
    // const { raceTotal } = data;
    // if (raceTotal !== totalNumberofRestrictedUnits) {
    //     errors.push({
    //         msg: Table2Messages.section4,
    //         fieldName: 'sec4total',
    //         sectionIndex: 4
    //     });
    // }

    //sec-4 (yellow validation onchange)
    const sec4Err = validationErrors.filter(vr => vr.title === "RaceEthnicityNotMatchHouseholdsServed").length > 0;

    let sec4Cond1 = data.allDetails.reduce((a, b) => a + parseFloat(b.raceCount === '' || b.raceCount === null ? 0 : b.raceCount), 0);

     const sec4Data = data.totalNumberofRestrictedUnits !==sec4Cond1?true:false 

    if (sec4Err && sec4Data ) {

        errors.push({
            msg: Table2Messages.section4,
            fieldName: 'sec4total',
            sectionIndex: 4,
            type: 3
        });
    }

    // section - 7
    // const { totalOwnerHouseholdsinRestrictedUnits, totalRentHouseholdsinRestrictedUnits } = data;
    // const totalServed = getFloat(totalOwnerHouseholdsinRestrictedUnits) + getFloat(totalRentHouseholdsinRestrictedUnits);
    // if (totalServed !== totalNumberofRestrictedUnits) {
    //     errors.push({
    //         msg: Table2Messages.section7,
    //         fieldName: 'restrictedYeartotalHousehold',
    //         sectionIndex: 7
    //     });
    // }
    const sec6Err = validationErrors.filter(vr => vr.title === "SingleParentHOHGreaterThanHouseholdsServed").length > 0;
    if (sec6Err) {
        errors.push({
            msg: Table2Messages.section6,
            fieldName: 'TotalRestrictedUnitHouseholdsServed',
            sectionIndex: 6,
            type: 3
        });
    }

    //sec 7 (yellow validation onchange)
    const sec7Err = validationErrors.filter(vr => vr.title === "HouseholdsInYearNotEqualToTotalHouseholdsServed").length > 0;

    let sec7Condition =parseInt(data.totalRentHouseholdsinRestrictedUnits ? data.totalRentHouseholdsinRestrictedUnits : 0, 10) + parseInt(data.totalOwnerHouseholdsinRestrictedUnits ? data.totalOwnerHouseholdsinRestrictedUnits : 0, 10)

    const sec7data =data.totalRestrictedUnitHouseholdserved !== sec7Condition?true:false;

    if (sec7Err && sec7data) {
        errors.push({
            msg: Table2Messages.section7,
            fieldName: 'restrictedYeartotalHousehold',
            sectionIndex: 7,
            type: 3
        });
    }
    return errors;
}

export default Table2Validations;
