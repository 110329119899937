import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import dashboardStyle from '../../../assets/jss/containers/dashboard';
import { Card, Grid } from '@material-ui/core';
import HOReportStatusBarChart from '../../../components/Charts/HOReportStatusBarChart/HOReportStatusBarChart';
import { SvgLoadIcon } from '../../../components/SvgIcons/SvgIcons';

/**
 *ReportingStatus
 *
 * @class ReportingStatus
 * @extends {Component}
 */
class ReportingStatus extends Component {
    /**
     *Creates an instance of ReportingStatus.
     * @param {*} props
     * @memberof ReportingStatus
     */
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof ReportingStatus
     */
    render() {
        let { classes, reportYear, reportStatus } = this.props
        let checkNull = reportStatus.data.filter(item=>item.value!==null)
        return (
            <Grid item xs={12} sm={12}>
                <Card className={classes.reportingStatusContainer}>
                    <div className={classes.cardHeader}>
                        <div className="header">Reporting Status of the year {reportYear}</div>
                    </div>
                    <div className='reportingStatusCard'>
                        <Grid item xs={12} sm={12} className="cardContainer">
                            {
                                checkNull.length===0?'No Records Found':<div className="innerCard">
                                {
                                    reportStatus.isLoading ?
                                        <div className='reportingStatusLoader'>
                                            <SvgLoadIcon className= "greyLoader"/>
                                        </div> 
                                        :
                                        <HOReportStatusBarChart data={reportStatus.data} />
                                }
                              </div>
                            }
                            
                        </Grid>
                    </div>
                </Card>
            </Grid>
        )
    }
}

// prop types
ReportingStatus.propTypes = {
    classes: PropTypes.object.isRequired,
};

/**
 * Default Props
 */
ReportingStatus.defaultProps = {
    classes: {}
};

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    return {
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};


// export ReportingStatus
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(ReportingStatus));