import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import MailTemplateList from './MailTemplateList';
import MailTemplateNew from './MailTemplateNew';
import MailTemplateEdit from './MailTemplateEdit';

/**
 * MailRouter Container
 * @class MailRouter
 * @extends {Component}
 */
class MailRouter extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            templateList: []
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={`${match.url}`} render={(props) => {
                    return <MailTemplateList   {...props} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <MailTemplateNew {...props} match={props.match} />
                }} />
                <Route path={`${match.url}/:_id`} render={(props) => {
                    return <MailTemplateEdit _id={props.match.params._id} {...props} />
                }} />
            </Switch>
        );
    }
}
/**
 * Bind Component Property Types
 */
MailRouter.propTypes = {
    classes: PropTypes.object,
};
/**
 * Export Component
 */
export default MailRouter;
