import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import TextArea from '../../../../components/TextArea/TextArea';
import TableErrors from '../../../../components/TableErrors/TableErrors';
import ContentWrapper from "../../../../components/ContentWrapper/ContentWrapper";

// Import Form Elements
import ValidatorForm from "../../../../helpers/fieldValidations";
import TextBox from '../../../../components/TextBox/TextBox';
import SaveCancel from "../../../../components/SaveCancel/SaveCancel";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import NumberField from '../../../../components/NumberField/NumberField';

// Import Actions
import { reportTablesActions } from '../../../../actions/reportTables';
import { confActions } from '../../../../actions/configuration';
import { alertActions, exploreActions } from "../../../../actions";

// Import Styles
import pageStyle from '../../../../assets/jss/containers/common';
import { WarningNames } from "./DefaultDatas";

// Import Helpers
import { formatDate } from '../../../../helpers';
import { configurationConstants } from '../../../../helpers/appConstants';
import Table2Validations from '../../../../helpers/TableValidations/Table2';
import { history, getSubmissionLevel, ReportLevelsConstants } from '../../../../helpers';
import { hasWarnings, hasWarningsSection } from '../../../../helpers/TableValidations/warnings';


const DEFAULT_CONFIG = {
    race: []
};

const fieldClass = {
    raceCount: 'txt-right',
    hispanicCount: 'txt-right',
};
/**
 * EditableText Container
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered m-t-b-4 ${fieldClass[fieldName]}` : `textBox--bordered m-t-b-4 ${fieldClass[fieldName]}`
        return (
            <TextBox
                className={className}
                name={`${fieldName} _${rowIndex} `}
                value={data[fieldName]}
                disabled={isReadOly}
                //validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);

                }}
            />
        )
    }
};
/**
 * EditableNumber Container
 * @class EditableNumber
 * @extends {Component}
 */
class EditableNumber extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered m-t-b-4 ${fieldClass[fieldName]}`
        return (
            <TextBox
                className={className}
                name={`${fieldName} _${rowIndex} `}
                value={data[fieldName]}
                type="number"
                disabled={isReadOly}
                validators={['positive']}
                errorMessages={['Enter positive number']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    raceCount: EditableNumber,
    hispanicCount: EditableNumber,
    comment: EditableText
}
/**
 * Table2 Container
 * @class Table2
 * @extends {Component}
 */
class Table2 extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.NRUBRef = React.createRef();
        this.NRUHSRef = React.createRef();
        this.RERUHSRef = React.createRef();
        this.RUHIRef = React.createRef();
        this.TRUHSRef = React.createRef();
        this.TRUHSDYRef = React.createRef();
        this.occupancyRef = React.createRef();
        this.state = {
            //section 1
            restrictedRenderUnits: '',
            restrictedOwnerUnits: '',
            // unitstotal: '',
            totalNumberofUnits: '',
            totalNumberofRestrictedUnits: '',
            //section 2
            restrictedUnitsOccupied: '',
            restrictedUnitsVacant: '',
            //section 3
            totalNumberofFamilyHouseholdserved: '',
            totalNumberofIndividualHouseholdserved: '',
            restrictedunitstotal: '',
            //section 4
            label0to30: '',
            label31to50: '',
            label51to80: '',
            over80: '',
            totalRestrictedUnitHouseholdserved: '',
            //section 5
            race: [],
            fields: [],
            Total: '',
            //section 6
            singleMothersWithChildrens: '',
            singleFathersWithChildrens: '',
            restrictedTotal: '',
            totalHouseholdServed: '',
            //section 7
            totalRentHouseholdsinRestrictedUnit: '',
            totalOwnerHouseholdsinRestrictedUnits: '',
            restrictedYearTotal: '',
            restrictedYeartotalHouseholdServed: '',
            //section 8
            comments: '',
            allDetails: [],

            config: {
                ...DEFAULT_CONFIG
            },
            type: null,
            popupContent: null,

            reportYear_id: '',
            isloaded: false,
            notes: '',
            error: null,
            created:false
        }
    }
    /**
     * Component will mount
     * 
     * [1]  -   get the table 4 data
     * [2]  -   Get Race Config
     * [3]  -   get validation issues
     */
    UNSAFE_componentWillMount() {
        const { reportId } = this.props;

        if (reportId) {
            // [1]
            this.props.getTable2Data(reportId);
        }

        // [2]
        this.props.getConfiguration(configurationConstants.race);

        // [3]
        if (reportId) {
            this.props.validationIssues(0, 0, reportId, "2");
        }
        //positive number validaton
        ValidatorForm.addValidationRule('positive', (value) => {
            if (value < 0) {
                return false;
            }
            return true;
        });
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save form
     * 
     * [1]  -   set up api form submission datas
     * [2]  -   allDetails
     */
    _saveForm() {
        // [1]
        const id = this.props.reportId
        const { reportId } = this.props;

        /// [2]
        const table = this.state.allDetails.map((it) => {
            return {
                "_id": it._id,
                "race_id": it.race_id,
                "raceCount": parseInt(it.raceCount, 10),
                "hispanicCount": parseInt(it.hispanicCount, 10),
                "comment": it.comment
            }
        })
        const generalData = {
            //section 1
            table,
            restrictedRenderUnits: parseInt(this.state.restrictedRenderUnits, 10),
            restrictedOwnerUnits: parseInt(this.state.restrictedOwnerUnits, 10),
            totalNumberofUnits: parseInt(this.state.totalNumberofUnits, 10),
            totalNumberofRestrictedUnits: parseInt(this.state.totalNumberofRestrictedUnits, 10),
            //section 2
            restrictedUnitsOccupied: parseInt(this.state.restrictedUnitsOccupied, 10),
            restrictedUnitsVacant: parseInt(this.state.restrictedUnitsVacant, 10),
            //section 3
            totalNumberofFamilyHouseholdserved: parseInt(this.state.totalNumberofFamilyHouseholdserved, 10),
            totalNumberofIndividualHouseholdserved: parseInt(this.state.totalNumberofIndividualHouseholdserved, 10),
            restrictedunitstotal: parseInt(this.state.restrictedunitstotal, 10),
            //section 5
            label0to30: parseInt(this.state.label0to30, 10),
            label31to50: parseInt(this.state.label31to50, 10),
            label51to80: parseInt(this.state.label51to80, 10),
            over80: parseInt(this.state.over80, 10),
            totalRestrictedUnitHouseholdserved: parseInt(this.state.totalRestrictedUnitHouseholdserved, 10),
            //section 6
            singleMothersWithChildrens: parseInt(this.state.singleMothersWithChildrens, 10),
            singleFathersWithChildrens: parseInt(this.state.singleFathersWithChildrens, 10),
            restrictedTotal: parseInt(this.state.restrictedTotal, 10),
            totalHouseholdServed: parseInt(this.state.totalHouseholdServed, 10),
            //section 7
            totalRentHouseholdsinRestrictedUnits: parseInt(this.state.totalRentHouseholdsinRestrictedUnits, 10),
            totalOwnerHouseholdsinRestrictedUnits: parseInt(this.state.totalOwnerHouseholdsinRestrictedUnits, 10),
            restrictedYearTotal: parseInt(this.state.restrictedYearTotal, 10),
            restrictedYeartotalHouseholdServed: parseInt(this.state.restrictedYeartotalHouseholdServed, 10),
            //section 8
            comments: this.state.comments,
            customeType: 'Default',
            notes: this.state.notes,
            reportId: this.props.reportId,
            reportYear_id: this.props.reportId
        };
        if (this.state._id && reportId && !this.state.created) {
            // update table 2
            this.setState({created:true})
            this.props.getTable2DataUpdate(id, generalData);
        } else {
            // create table 2
            if(!this.state.created){
            this.props.createTable2(generalData,(result)=>{
                if(result){
                    let ryid = result.data.reportYear_id;
                    this.setState({created:true}) 
                    this.props.getTable2Data(ryid); 
                }
            });
        }
        }
    }
    /**
     * Cell Component On Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { allDetails } = this.state;
        allDetails[rowIndex][fieldName] = value;
        this.setState({ allDetails });
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     * @returns 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, races } = nextProps;
        if (getOne.data.data === null) {
            return;
        }
        const id = getOne.data.data ? getOne.data.data._id : null;
        if ((getOne.data.data && races.length > 0 && (!this.state.isloaded || this.state._id !== id))) {

            let race = races.map(r => {
                return {
                    desc: r.desc,
                    id: r.key
                }
            });
            this.setState({
                isloaded: true,
                //Section 1
                restrictedRenderUnits: getOne.data.data.renterBedCount ? getOne.data.data.renterBedCount : 0,
                restrictedOwnerUnits: getOne.data.data.ownerBedCount ? getOne.data.data.ownerBedCount : 0,
                unitstotal: parseInt(getOne.data.data.renterBedCount ? getOne.data.data.renterBedCount : 0, 10)
                    + parseInt(getOne.data.data.ownerBedCount ? getOne.data.data.ownerBedCount : 0, 10),
                totalNumberofUnits: getOne.data.data.totalUnits ? getOne.data.data.totalUnits : 0,
                totalNumberofRestrictedUnits: parseInt(getOne.data.data.totalRestrictedUnits ? getOne.data.data.totalRestrictedUnits : 0, 10),

                //Section 2
                restrictedUnitsOccupied: getOne.data.data.totalRestrictedOccupied ? getOne.data.data.totalRestrictedOccupied : 0,
                restrictedUnitsVacant: getOne.data.data.totalRestrictedVacant ? getOne.data.data.totalRestrictedVacant : 0,
                //Section 3
                totalNumberofFamilyHouseholdserved: getOne.data.data.totalRestrictedUnitsFamilyHouseholdsServed ? getOne.data.data.totalRestrictedUnitsFamilyHouseholdsServed : 0,
                totalNumberofIndividualHouseholdserved: getOne.data.data.TotalRestrictedUnitsIndividualHouseholdsServed ? getOne.data.data.TotalRestrictedUnitsIndividualHouseholdsServed : 0,
                restrictedunitstotal: parseInt(getOne.data.data.totalRestrictedUnitsFamilyHouseholdsServed ? getOne.data.data.totalRestrictedUnitsFamilyHouseholdsServed : 0, 10)
                    + parseInt(getOne.data.data.TotalRestrictedUnitsIndividualHouseholdsServed ? getOne.data.data.TotalRestrictedUnitsIndividualHouseholdsServed : 0, 10),
                //Section 4
                label0to30: getOne.data.data.totalRestrictedUnitsServedByPercent_0To30 ? getOne.data.data.totalRestrictedUnitsServedByPercent_0To30 : 0,
                label31to50: getOne.data.data.totalRestrictedUnitsServedByPercent_31To50 ? getOne.data.data.totalRestrictedUnitsServedByPercent_31To50 : 0,
                label51to80: getOne.data.data.totalRestrictedUnitsServedByPercent_51To80 ? getOne.data.data.totalRestrictedUnitsServedByPercent_51To80 : 0,
                over80: getOne.data.data.totalRestrictedUnitsServedByPercent_Over80 ? getOne.data.data.totalRestrictedUnitsServedByPercent_Over80 : 0,
                totalRestrictedUnitHouseholdserved: getOne.data.data.totalRestrictedUnitsServedByPercent_Total ? getOne.data.data.totalRestrictedUnitsServedByPercent_Total :
                    parseInt(getOne.data.data.totalRestrictedUnitsServedByPercent_0To30 ? getOne.data.data.totalRestrictedUnitsServedByPercent_0To30 : 0, 10) +
                    parseInt(getOne.data.data.totalRestrictedUnitsServedByPercent_31To50 ? getOne.data.data.totalRestrictedUnitsServedByPercent_31To50 : 0, 10) +
                    parseInt(getOne.data.data.totalRestrictedUnitsServedByPercent_51To8 ? getOne.data.data.totalRestrictedUnitsServedByPercent_51To80 : 0, 10) +
                    parseInt(getOne.data.data.totalRestrictedUnitsServedByPercent_Over80 ? getOne.data.data.totalRestrictedUnitsServedByPercent_Over80 : 0, 10),
                raceHeader: ['Race', 'Head of Household count by Race', 'Head of Household count of Hispanic Ethnicity', 'Comments'],

                table2race: getOne.data.data.table2RaceEthnicity ? getOne.data.data.table2RaceEthnicity : [],

                allDetails: race.map((s) => {
                    const Details = getOne.data.data.table2RaceEthnicity.filter(gs => gs.race_id === s.id)[0] || { raceCount: 0, hispanicCount: 0, comment: '' };
                    return {
                        ...Details,
                        desc: s.desc,
                        raceCount: Details.raceCount,
                        hispanicCount: Details.hispanicCount,
                        race_id: s.id
                    }
                }),
                Total: getOne.data.data.totalRestrictedUnitsServedByPercent_Total ? getOne.data.data.totalRestrictedUnitsServedByPercent_Total : 0,
                //Section 6
                singleMothersWithChildrens: getOne.data.data.femaleSingleParentServedCount ? getOne.data.data.femaleSingleParentServedCount : 0,
                singleFathersWithChildrens: getOne.data.data.maleSingleParentServedCount ? getOne.data.data.maleSingleParentServedCount : 0,
                totalSingleParentHouseholds: parseInt(getOne.data.data.femaleSingleParentServedCount ? getOne.data.data.femaleSingleParentServedCount : 0, 10)
                    + parseInt(getOne.data.data.maleSingleParentServedCount ? getOne.data.data.maleSingleParentServedCount : 0, 10),
                totalHouseholdServed: getOne.data.data.totalRestrictedUnitsServedByPercent_Total ? getOne.data.data.totalRestrictedUnitsServedByPercent_Total : 0,
                //Section 7
                totalRentHouseholdsinRestrictedUnits: getOne.data.data.renterTotalCount ? getOne.data.data.renterTotalCount : 0,
                totalOwnerHouseholdsinRestrictedUnits: getOne.data.data.ownerTotalCount ? getOne.data.data.ownerTotalCount : 0,
                restrictedYearTotal:
                    parseInt(getOne.data.data.renterTotalCount ? getOne.data.data.renterTotalCount : 0, 10) +
                    parseInt(getOne.data.data.ownerTotalCount ? getOne.data.data.ownerTotalCount : 0, 10),
                restrictedYeartotalHouseholdServed:
                    parseInt(getOne.data.data.renterTotalCount ? getOne.data.data.renterTotalCount : 0, 10) +
                    parseInt(getOne.data.data.ownerTotalCount ? getOne.data.data.ownerTotalCount : 0, 10),
                //Section 8
                comments: '',
                reportYear_id: getOne.data.data.reportYear_id,
                notes: getOne.data.data.notes ? getOne.data.data.notes : "",
                _id: getOne.data.data._id
            })

        }
        // update config
        if (this.state.config.race.length === 0 && races.length > 0) {
            this.setState({
                config: {
                    race: races
                }
            })
        }
        this.setState({created:false})
    }

    getTotal = () => {
        return this.state.allDetails.reduce((a, b) => a + parseFloat(b.raceCount === '' || b.raceCount === null ? 0 : b.raceCount), 0);
    }

    getTotalHispanic = () => {
        return this.state.allDetails.reduce((a, b) => a + parseFloat(b.hispanicCount === '' || b.hispanicCount === null ? 0 : b.hispanicCount), 0);
    }

    getTotalNumberofUnitsorBeds = () => {
        const {
            restrictedRenderUnits,
            restrictedOwnerUnits
        } = this.state;
        return parseInt(restrictedRenderUnits ? restrictedRenderUnits : 0, 10) + parseInt(restrictedOwnerUnits ? restrictedOwnerUnits : 0, 10)
    }

    getTotalofSingleParent = () => {
        const { singleFathersWithChildrens, singleMothersWithChildrens } = this.state;
        return parseInt(singleFathersWithChildrens ? singleFathersWithChildrens : 0, 10) + parseInt(singleMothersWithChildrens ? singleMothersWithChildrens : 0, 10)
    }

    getTotalRestrictedUnitsHouseholdServed = () => {
        const { totalRentHouseholdsinRestrictedUnits, totalOwnerHouseholdsinRestrictedUnits } = this.state;
        return parseInt(totalRentHouseholdsinRestrictedUnits ? totalRentHouseholdsinRestrictedUnits : 0, 10) + parseInt(totalOwnerHouseholdsinRestrictedUnits ? totalOwnerHouseholdsinRestrictedUnits : 0, 10)
    }

    dateLabel = () => {
        let now = new Date();
        let currYear = now.getFullYear()
        let dates = this.props.name
        if (dates < currYear) {
            return `12/31/${dates}`
        }
        else {
            return formatDate(now)
        }
    }
    /**
     * Table on submit / unsubmit click
     * @param {*} type 
     * @returns 
     * 
     * [1]  -   if not saved atleast once
     * [2]  -   disable submit when has hardstop
     * [3]  -   if saved atleast once
     */
    handleSubmitActionClick = (type) => {
        // [1]
        if (this.state._id === undefined) {
            return this.setState({
                error: {
                    message: 'Table 2 must be saved at least once before Submission'
                }
            });
        }
        // [2]
        const { validations } = this.props;
        if (validations && type === 'SUBMIT') {
            if (validations.data.table2 === 3) {
                this.props.showErrorAlert('Need to fix Hard stop errors before submit!');
                return;
            } else if (!validations.data.isPrevSubmitted) {
                this.props.showErrorAlert('Previous Year Report Not Submitted!');
                return;
            } else { }
        }
        // [3]
        this.setState({
            type: type
        });
    }
    /**Table 2 Download Report */

    handleDownloadActionClick = () => {
        const { getOne } = this.props;
        const reportData = {
            reportYear_id: getOne.data.desc._id,
            project_id: getOne.data.desc.project_id,
            site_id: getOne.data.desc.site_id,
            year: getOne.data.desc.year
        }
        this.props.downloadTable2Report(reportData.project_id, reportData.site_id, reportData.year);
    }
    /**
     * Pop Alert
     * @param {*} type 
     * @returns 
     */
    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess } = this.props;
        const isReadOly = pageAccess.indexOf('UNSUBMIT') === -1;
        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                disabled={isReadOly}
                                multiline={true}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }
    /**
     * Handle Submit
     */
    handleSubmitReport = () => {
        if (this.state._id) {
            this._submitReport();
        }
    }
    /**
     * Submit reports
     */
    _submitReport = () => {
        const { type } = this.state;
        const { reportId } = this.props;
        if (reportId) {
            if (type === 'SUBMIT') {
                this.props.submitReport(reportId, { comment: this.state.comments });
                this.setState({
                    type: null
                });
            } else {
                if (this.state.unsubmitComment !== '') {
                    this.props.unSubmitReport(reportId, { comment: this.state.unsubmitComment });
                    this.setState({
                        type: null
                    });
                }
            }
        }
        this._saveForm();
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                className={`textBox--bordered txt-right input--disabled`}
                value={value}
                disabled={true}
                prefix={''}
                thousandSeparator={true}
                decimalScale={0}
            />
        )
    }
    /**
     * Scroll to content on Validation errors Click
     * @param {*} content 
     */
    scrollToContent(content) {
        let warningKey = WarningNames.filter(e => e.title.match(content.title))[0].type;
        switch (warningKey) {
            case 'section1':
                this.NRUBRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'section2':
                this.occupancyRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'section3':
                this.NRUHSRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'section4':
                this.RERUHSRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'section5':
                this.RUHIRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'section6':
                this.TRUHSRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            case 'section7':
                this.TRUHSDYRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
            default:
                this.NRUBRef.current.scrollIntoView({ behavior: 'smooth' });
                break;
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, getOne, validations } = this.props;
        let { pageAccess } = this.props;
        const {
            //section 1
            restrictedRenderUnits,
            restrictedOwnerUnits,
            // unitstotal,
            totalNumberofUnits,
            totalNumberofRestrictedUnits,
            //section 2
            restrictedUnitsOccupied,
            restrictedUnitsVacant,
            //section 3
            totalNumberofFamilyHouseholdserved,
            totalNumberofIndividualHouseholdserved,
            restrictedunitstotal,
            //section 4
            label0to30,
            label31to50,
            label51to80,
            over80,
            totalRestrictedUnitHouseholdserved,
            //section 5
            raceHeader,
            //section 6
            singleMothersWithChildrens,
            singleFathersWithChildrens,
            // restrictedTotal,
            totalHouseholdServed,
            //section 7
            totalRentHouseholdsinRestrictedUnits,
            totalOwnerHouseholdsinRestrictedUnits,
            // restrictedYearTotal,
            // restrictedYeartotalHouseholdServed,
            //section 8
            // comments,
            // reportYear_id,
            notes

        } = this.state;
        let Total = this.getTotal();
        let hispanicTotal = this.getTotalHispanic();
        let unitstotal = this.getTotalNumberofUnitsorBeds();
        let totalSingleParentHouseholds = this.getTotalofSingleParent();
        let restrictedYeartotalHousehold = this.getTotalRestrictedUnitsHouseholdServed();
        let dateField = this.dateLabel();
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        if (pageAccess.length === 0) return null; // wait for response
        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any Funder for this report year.'}</h4>;
        }
        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';
        // get field level validations for table 2
        const fielderrors = Table2Validations({
            ...this.state,
            raceTotal: Total,
            validationErrors: validations.data.table2Issues || []
        });
        return (
            < React.Fragment >
                <ContentWrapper
                    classes={classes}
                    pageAccess={['REFRESH_VALIDATIONS', 'DOWNLOAD', ...pageAccess]}
                    title={levelInfo}
                    titleBg={'white'}
                    titleCss={'bolderText'}
                    pageName="reports"
                    validationContent={{ ...getOne.validations, fielderrors }}
                    needTitleBar={true}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    handleSubmitActionClick={() => this.handleSubmitActionClick('SUBMIT')}
                    handleUnSubmitActionClick={() => this.handleSubmitActionClick('UNSUBMIT')}
                    handleDownloadActionClick={this.handleDownloadActionClick}
                    handleRefreshValidationsClick={() => {
                        const { reportId } = this.props;
                        this.props.refreshValidations(0, 0, reportId, "2");
                    }}
                >
                    <TableErrors
                        fielderrors={fielderrors || []}
                        data={getOne.data.data}
                        classes={{
                            action: classes.action,
                            message: classes.message,
                            root: classes.root
                        }}
                        validations={validations.data.table2Issues || []}
                        handleClickAction={(err) => {
                            this.scrollToContent(err)
                        }}
                    />
                    <div className={classes.rootEditSubPage}>
                        <ValidatorForm
                            name="Table2"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => { }} >
                            <Grid container spacing={3}>
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.NRUBRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 1)}`}> 1 </span>
                                            Number of Restricted Units or Beds
                                        </h4>
                                    </div>
                                </ Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedRenderUnits"
                                        label="Restricted Renter Units:"
                                        value={restrictedRenderUnits}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedOwnerUnits"
                                        label="Restricted Owner Units:"
                                        value={restrictedOwnerUnits===0?'0':restrictedOwnerUnits}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="unitstotal"
                                        label="Total:"
                                        value={unitstotal}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'unitstotal'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofUnits"
                                        label="Total Number of Units:"
                                        value={totalNumberofUnits}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofRestrictedUnits"
                                        label="Total Number of Restricted Units as of 12/31:"
                                        value={totalNumberofRestrictedUnits}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>

                                </Grid>

                                {/*Occupancy*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.occupancyRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 2)}`}> 2 </span>
                                            Occupancy
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedUnitsOccupied"
                                        label={`Restricted Units Occupied on ${dateField}:`}
                                        value={restrictedUnitsOccupied}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedUnitsVacant"
                                        label={`Restricted Units Vacant on ${dateField}:`}
                                        value={restrictedUnitsVacant}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>

                                {/*Number of Restricted Units Households Served*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.NRUHSRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 3)}`}> 3 </span>
                                            Number of Restricted Units Households Served
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofFamilyHouseholdserved"
                                        label="Total Number of Family Household Served:"
                                        value={totalNumberofFamilyHouseholdserved}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofIndividualHouseholdserved"
                                        label="Total Number of Individual Household Served:"
                                        value={totalNumberofIndividualHouseholdserved}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedunitstotal"
                                        label="Total:"
                                        value={restrictedunitstotal}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>

                                {/*Race and Ethnicity of Restricted  Unit Household Served*/}
                                <Grid item xs={12} sm={12}>
                                    <div ref={this.RERUHSRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 4)}`}> 4 </span>
                                            Race and Ethnicity of Restricted  Unit Household Served
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} className="p-t-12">
                                    <EditableTable
                                        headerCols={raceHeader}
                                        fieldCols={['desc', 'raceCount', 'hispanicCount', 'comment']}
                                        rowDatas={this.state.allDetails}
                                        componentMap={componentMap}
                                        cellComponentOnChange={this.cellComponentOnChange}
                                        needTitle={false}
                                        tableClass={'fieldWidth12'}
                                        config={{ isReadOly }}
                                        footerDatas={[
                                            {
                                                'desc_footer': 'Total',
                                                'raceCount_footer': this.displayAmount(Total),
                                                'hispanicCount_footer': this.displayAmount(hispanicTotal)
                                            },
                                            {
                                                'desc_footer': <span className={`${hasWarnings(fielderrors, 'sec4total')}`}><label>Total Restricted Unit Households Served:</label></span>,
                                                'raceCount_footer': this.displayAmount(restrictedunitstotal),
                                            }
                                        ]}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <div ref={this.RUHIRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 5)}`}> 5 </span>
                                            Restricted Unit Households Income
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label0to30"
                                        label="0-30 %:"
                                        value={label0to30}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label31to50"
                                        label="31-50 %:"
                                        value={label31to50}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label51to80"
                                        label="51-80 %:"
                                        value={label51to80}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="over80"
                                        label="Over 80%:"
                                        value={over80}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalRestrictedUnitHouseholdserved"
                                        label="Total Restricted Unit Household Served:"
                                        value={totalRestrictedUnitHouseholdserved}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                {/*Total Restricted Unit Households Served*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.TRUHSRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 6)}`}> 6 </span>
                                            Total Restricted Unit Households Served
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="singleMothersWithChildrens"
                                        label="Single Mothers With Children:"
                                        value={singleMothersWithChildrens}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="singleFathersWithChildrens"
                                        label="Single Fathers With Children:"
                                        value={singleFathersWithChildrens}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalSingleParentHouseholds"
                                        label="Total:"
                                        value={totalSingleParentHouseholds}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalHouseholdServed"
                                        label="Total Household Served:"
                                        value={totalHouseholdServed}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>

                                {/*Total Restricted Unit Households Served During the Year*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.TRUHSDYRef}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 7)}`}> 7 </span>
                                            Total Restricted Unit Households Served During the Year
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalRentHouseholdsinRestrictedUnits"
                                        label="Total Renter Households in RestrictedUnits:"
                                        value={totalRentHouseholdsinRestrictedUnits}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalOwnerHouseholdsinRestrictedUnits"
                                        label="Total Owner Households in RestrictedUnits:"
                                        value={totalOwnerHouseholdsinRestrictedUnits===0?'0':totalOwnerHouseholdsinRestrictedUnits}
                                        type="number"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['positive']}
                                        errorMessages={['Enter positive number']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedYeartotalHousehold"
                                        label="Total:"
                                        value={restrictedYeartotalHousehold}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'restrictedYeartotalHousehold'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="restrictedYeartotalHouseholdServed"
                                        label="Total Household Served:"
                                        value={totalRestrictedUnitHouseholdserved}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>
                            </Grid>
                            < Grid item xs={12} sm={12}>
                                <Card className='projectsummary_card commentMargin'>
                                    <CardContent className="d_down_reports">
                                        <TextArea
                                            name="notes"
                                            label="Comments"
                                            value={notes}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={isReadOly}
                                            multiline={true}
                                        />
                                    </ CardContent>
                                </Card>
                            </ Grid>
                        </ValidatorForm>
                        {
                            pageAccess.indexOf('SAVE') > -1 ? (
                                <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />)
                                : null
                        }
                    </div>
                </ContentWrapper>

                <ValidatorForm ref="submitReport" onSubmit={this.handleSubmitReport}>
                    <AlertDialog
                        open={this.state.type !== null}
                        title={'Warning:'}
                        onSave={() => this.refs.submitReport.submit()}
                        onClose={() => {
                            this.setState({ type: null })
                        }}
                        saveText={'Submit'}
                    >
                        {this.popAlert(this.state.type)}
                    </AlertDialog>
                </ValidatorForm>

                {
                    this.state.error !== null && (
                        <AlertDialog
                            open={true}
                            title={'Error:'}
                            saveVisible={false}
                            onClose={() => {
                                this.setState({ error: null })
                            }}
                            cancelText={'OK'}
                        >
                            {this.state.error.message}
                        </AlertDialog>
                    )
                }

            </React.Fragment >
        )
    }
}
/**
 * Bind Component Property Types
 */
Table2.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
/** Default Props */
Table2.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess, reportTables, configuration }, ownProps) => {
    return {
        pageAccess: pageAccess['table2'] || [],
        getOne: reportTables.table2,
        races: configuration.configurations[configurationConstants.race] || [],
        validations: { data: reportTables.validationIssues }
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable2Data: (id) => dispatch(reportTablesActions.getTable2Data(id)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getTable2DataUpdate: (id, data) => dispatch(reportTablesActions.getTable2DataUpdate(id, data)),
        createTable2: (data,callback) => dispatch(reportTablesActions.createTable2(data,callback)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 2)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 2)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        refreshValidations: (project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) => dispatch(reportTablesActions.refreshValidations(project_id, site_id, reportYear_id, reportTable)),
        downloadTable2Report: (project_id, site_id, year) => dispatch(exploreActions.downloadTable2Report(project_id, site_id, year, 'WBARS_Table2Export.xlsx'))

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table2));
