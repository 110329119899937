import React from "react";
import PropTypes from "prop-types";
import paginator from "paginator";
import cx from "classnames";

// Component
import Page from "./Page";

// Material
import Grid from '@material-ui/core/Grid';
import { history } from '../../helpers';


let paginationInfo = {};
// let pageinationLoading = false;
/**
 * Export Pagination Component
 * @class Pagination
 * @extends {React.Component}
 */
export default class Pagination extends React.Component {
  //static propTypes need to be declared on the class itself, not on the instance of the class
  static propTypes = {
    totalItemsCount: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    activePage: PropTypes.number,
    itemsCountPerPage: PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
    prevPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    nextPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    lastPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    firstPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    disabledClass: PropTypes.string,
    hideDisabled: PropTypes.bool,
    hideNavigation: PropTypes.bool,
    innerClass: PropTypes.string,
    itemClass: PropTypes.string,
    itemClassFirst: PropTypes.string,
    itemClassPrev: PropTypes.string,
    itemClassNext: PropTypes.string,
    itemClassLast: PropTypes.string,
    linkClass: PropTypes.string,
    activeClass: PropTypes.string,
    activeLinkClass: PropTypes.string,
    linkClassFirst: PropTypes.string,
    linkClassPrev: PropTypes.string,
    linkClassNext: PropTypes.string,
    linkClassLast: PropTypes.string,
    hideFirstLastPages: PropTypes.bool,
    getPageUrl: PropTypes.func,
    isLoading: PropTypes.bool,
    userAccess:PropTypes.any,
    disablePagination:PropTypes.bool
  };

  //default props
  static defaultProps = {
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    activePage: 1,
    prevPageText: "⟨",
    firstPageText: "«",
    nextPageText: "⟩",
    lastPageText: "»",
    innerClass: "pagination",
    itemClass: undefined,
    linkClass: undefined,
    activeLinkClass: undefined,
    hideFirstLastPages: false,
    isLoading: false,
    getPageUrl: (i) => "#",
    userAccess:"",
    disablePagination:false
  };
  /**
   * First Page Visible Event
   * @param {*} has_previous_page 
   * @returns 
   */
  isFirstPageVisible(has_previous_page) {
    const { hideDisabled, hideFirstLastPages } = this.props;
    if (hideFirstLastPages || (hideDisabled && !has_previous_page)) return false;
    return true;
  }
  /**
   * Previous Page Visible Event
   * @param {*} has_previous_page 
   * @returns 
   */
  isPrevPageVisible(has_previous_page) {
    const { hideDisabled, hideNavigation } = this.props;
    if (hideNavigation || (hideDisabled && !has_previous_page)) return false;
    return true;
  }
  /**
   * Next Page Visible Event
   * @param {*} has_next_page 
   * @returns 
   */
  isNextPageVisible(has_next_page) {
    const { hideDisabled, hideNavigation } = this.props;
    if (hideNavigation || (hideDisabled && !has_next_page)) return false;
    return true;
  }
  /**
   * Last Page Visible Event
   * @param {*} has_next_page 
   * @returns 
   */
  isLastPageVisible(has_next_page) {
    const { hideDisabled, hideFirstLastPages } = this.props;
    if (hideFirstLastPages || (hideDisabled && !has_next_page)) return false;
    return true;
  }
  /**
   * building the pages
   * @returns 
   */
  buildPages() {
    const pages = [];
    const {
      itemsCountPerPage,
      pageRangeDisplayed,
      activePage,
      prevPageText,
      nextPageText,
      firstPageText,
      lastPageText,
      totalItemsCount,
      onChange,
      activeClass,
      itemClass,
      itemClassFirst,
      itemClassPrev,
      itemClassNext,
      itemClassLast,
      activeLinkClass,
      disabledClass,
      // hideDisabled,
      // hideNavigation,
      linkClass,
      linkClassFirst,
      linkClassPrev,
      linkClassNext,
      linkClassLast,
      // hideFirstLastPages,
      getPageUrl,
      // isLoading
    } = this.props;

    // pageinationLoading = isLoading;
    paginationInfo = new paginator(
      itemsCountPerPage,
      pageRangeDisplayed
    ).build(totalItemsCount, activePage);



    for (
      let i = paginationInfo.first_page;
      i <= paginationInfo.last_page;
      i++
    ) {
      pages.push(
        <Page
          isActive={i === activePage}
          key={i}
          href={getPageUrl(i)}
          pageNumber={i}
          pageText={i + ""}
          isDisabled={this.props.disablePagination}
          onClick={onChange}
          itemClass={itemClass}
          linkClass={linkClass}
          activeClass={activeClass}
          activeLinkClass={activeLinkClass}
        />
      );
    }
    this.isPrevPageVisible(paginationInfo.has_previous_page) &&
      pages.unshift(
        <Page
          key={"prev" + paginationInfo.previous_page}
          pageNumber={paginationInfo.previous_page}
          onClick={onChange}
          pageText={prevPageText}
          disablePagination={this.props.disablePagination}
          isDisabled={!paginationInfo.has_previous_page}
          itemClass={cx(itemClass, itemClassPrev)}
          linkClass={cx(linkClass, linkClassPrev)}
          disabledClass={disabledClass}
        />
      );
    this.isFirstPageVisible(paginationInfo.has_previous_page) &&
      pages.unshift(
        <Page
          key={"first"}
          pageNumber={1}
          onClick={onChange}
          pageText={firstPageText}
          disablePagination={this.props.disablePagination}
          isDisabled={!paginationInfo.has_previous_page}
          itemClass={cx(itemClass, itemClassFirst)}
          linkClass={cx(linkClass, linkClassFirst)}
          disabledClass={disabledClass}
        />
      );

    this.isNextPageVisible(paginationInfo.has_next_page) &&
      pages.push(
        <Page
          key={"next" + paginationInfo.next_page}
          pageNumber={paginationInfo.next_page}
          onClick={onChange}
          pageText={nextPageText}
          disablePagination={this.props.disablePagination}
          isDisabled={!paginationInfo.has_next_page}
          itemClass={cx(itemClass, itemClassNext)}
          linkClass={cx(linkClass, linkClassNext)}
          disabledClass={disabledClass}
        />
      );

    this.isLastPageVisible(paginationInfo.has_next_page) &&
      pages.push(
        <Page
          key={"last"}
          pageNumber={paginationInfo.total_pages}
          onClick={onChange}
          pageText={lastPageText}
          disablePagination={this.props.disablePagination}
          isDisabled={
            paginationInfo.current_page === paginationInfo.total_pages
          }
          itemClass={cx(itemClass, itemClassLast)}
          linkClass={cx(linkClass, linkClassLast)}
          disabledClass={disabledClass}
        />
      );

    return pages;
  }

  /**
   * Bind
   * HTML to DOM
   */
  render() {
    const {userAccess}=this.props
    var y=history.location.pathname.split('/')
    var doesLoan =y.includes('loanProjects')
    var getHO =(userAccess ===2 ) && y.includes("projects") && y.includes("users")
    var getBoth =userAccess ===3 && y.includes('projects') && y.includes("users") && y.includes("homeOwnership")

    const pages = this.buildPages();
    const { first_result } = paginationInfo;
    // const pagesTo = paginationInfo.current_page * paginationInfo.results;
    // const pagesFrom = (paginationInfo.current_page * paginationInfo.results) - (paginationInfo.results) + 1;
    const pagesFrom = (first_result + 1);
    const pagesTo = first_result + paginationInfo.results;
    return (
      <Grid container justifyContent="flex-end" className="pagination-block">
        <Grid item sm={6}>
          <p className="pagination-text">Displaying {pagesFrom} to  {pagesTo} of {paginationInfo.total_results} records</p>
        </Grid>
        {/* <Grid item sm={4} className="text-center">
          {pageinationLoading === true &&
            <div className={'svgloader'}>
              <SvgLoadIcon></SvgLoadIcon>
            </div>
          }
        </Grid> */}
        <Grid item sm={6} className="text-right" >
          <ul className={doesLoan || getHO || getBoth?"loanPagination":"pagination_ul"}>{pages}</ul>
        </Grid>
      </Grid>
    );
  }
}