// import { themeSpacingSize } from '../common';


const pageStyle = theme => ({
    root: {
        padding: 14
    },
    subPage: {
        padding: 12
    },
    image: {
        width: 50,
        height: 50
    },
    buttons: {
        padding: "5px",
        margin: "0px",
        minHeight: "30px",

    },
    textField: {
        width: "100%",
        // marginTop: '0px !important'
    },
    nopadding: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        overflow: 'hidden'
    },
    rootEdit: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3)
    },
    rootEditSubPage: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    textFieldNoBorder: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        border: 'none !important'
    },
    leftSliderIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-7px',
        margin: 'auto',
        height: 48,
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 0 2px grey',
        borderRadius: 8,
        backgroundColor: '#fbab92',
        cursor: 'pointer',
        width: 12,
        '&:hover': {
            backgroundColor: '#fbab92'
        },
    },
    rightSliderIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        height: 48,
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 0 2px grey',
        borderRadius: 8,
        backgroundColor: '#fbab92',
        cursor: 'pointer',
        width: 12,
        '&:hover': {
            backgroundColor: '#fbab92'
        },
    },
    rightSliderIconContainer: {
        width: "100%",
        height: '100%',
        paddingLeft: 2,
        "& i": {
            height: '100%',
            width: '100%'
        }
    },
    leftSliderIconContainer: {
        width: "100%",
        height: '100%',
        paddingRight: 2,
        "& i": {
            height: '100%',
            width: '100%'
        }
    },

    // 5/5Fixes
    autoCompleteTop: {
        '& .autocomplete-fixed': {
            marginTop: '6px !important'
        },
        '& .autocomplete-fixed .MuiInputBase-root': {
            marginTop: '16px !important'
        }
    },
    // autoCompleteTop: {
    //     '& .autocomplete-fixed .MuiInputBase-root': {
    //         marginTop: '16px !important'
    //     }
    // },
    autoCompleteTop19: {
        '& .autocomplete-fixed .MuiInputBase-root': {
            marginTop: '19px !important'
        }
    },
    pb5: {
        paddingBottom: 5
    },
    pb10: {
        paddingBottom: 10
    },
    mr10: {
        marginRight: 10
    },
    orangeBtnLink: {
        '& .roundCircle1': {
            backgroundColor: '#FBAA92 !important',
        },
        '& .anchorIconLink': {
            paddingLeft: '0 !important'
        },
        '& .anchorIconLink a': {
            color: '#101010',
            '&:hover': {
                color: '#36aebe'
            }
        },
        // '&:hover': {
        //     '& .anchorIconLink a': {
        //         color: '#36aebe'
        //     },
        // }
    },
    mw96: {
        maxWidth: '96% !important'
    },
    LinkPadding: {
        paddingTop: 10,
        paddingBottom: 10
    },
    activityContainer: {
        '& .parentCard': {
            height: 'calc(100vh - 227px) !important'
        }
    },
    ContractActivityContainer: {
        '& .parentCard': {
            height: 'calc(100vh - 275px) !important'
        },
        '& .page-header': {
            padding: '0 15px'
        }
    },
    inlineCheckBoxes: {
        '& .MuiFormControl-root': {
            width: 'auto !important'
        }
    },
    textAreaBr0: {
        '& div': {
            borderRadius: '0px !important'
        }
    },
    plr30: {
        paddingLeft: '30px !important',
        paddingRight: '30px !important'
    },
    pageClrText: {
        fontSize: '16px',
        color: '#36aebe'
    },
    loanactvitylist:{
        fontSize: '16px',
        color: '#36aebe',
        margin: '20px !important'
    }
});

export default pageStyle;
