import "babel-polyfill"
import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'

// import material UI
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// Import Styles
import './index.css';
import './assets/css/font-awesome.css';
import './assets/css/custom_font_styles.css';
import './assets/css/line-icons.css';
import './assets/css/developer_css.css';
// import 'react-datepicker/dist/react-datepicker.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/fold/foldgutter.css';

// History
import configureStore from './helpers/store';
import { history } from './helpers';

// Containers
import { App } from './containers/App';

const store = configureStore();
ReactDOM.render(
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
            {/* <React.Suspense fallback={<PageLoader show />}> */}
            <Router history={history}>
                <App />
            </Router>
            {/* </React.Suspense> */}
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root'));
// registerServiceWorker();
