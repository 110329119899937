import { occupantsConstants } from "../constants";

const tableDatas = (sortName) => {
    return {
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: [sortName],
                searchCols: []
            }
        },
        validationErrors: []
    };
}
const initialState = {
    ...tableDatas('project.name'),
    getOne: {
        data: null,
        config: null,
        vacantUnits: [],
        vacantUnitsErrMsg: ''
    },
    getOneHHI: {
        data: null
    },
    getOneHHIncome: {
        data: null
    },
    getOneMRD: {
        data: null
    },
    transferUnit: {
        vacantUnits: []
    },
    defaultOccupantDetails: {
        currentUASources: []
    }
};

export function occupants(state = initialState, action) {
    switch (action.type) {
        case occupantsConstants.GETALL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case occupantsConstants.GETALL_SUCCESS:
            return {
                ...state,
                ...action.data,
                isLoading: false
            };
        case occupantsConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        /**
         * For First Tab "General"
         */
        case occupantsConstants.GET_OCCUPANTS_DETAILS_REQUEST:
            return {
                ...state,
                getOne: {
                    data: null,
                    isLoading: true
                }
            };
        case occupantsConstants.GET_OCCUPANTS_DETAILS_SUCCESS:
            return {
                ...state,
                getOne: {
                    data: action.data,
                    isLoading: false
                }

            };
        case occupantsConstants.GET_OCCUPANTS_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case occupantsConstants.GET_ASSOC_PROJECTS_CONFIG_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case occupantsConstants.GET_ASSOC_PROJECTS_CONFIG_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    config: action.config
                },
                isLoading: false
            }
        case occupantsConstants.GET_ASSOC_PROJECTS_CONFIG_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case occupantsConstants.CLEAR_EMPTY_UNITS:
                return {
                    ...state,
                    getOne: {
                        ...state.getOne,
                        vacantUnitsErrMsg: ''
                    }
                }
        case occupantsConstants.GET_EMPTY_UNITS_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    vacantUnits: [],
                    vacantUnitsErrMsg: ''
                },
                isLoading: true
            }
        case occupantsConstants.GET_EMPTY_UNITS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    vacantUnits: action.units,
                    vacantUnitsErrMsg: action.units.length === 0 ? 'There are no empty units available for move-in.' : ''
                },
                isLoading: false
            }
        case occupantsConstants.GET_EMPTY_UNITS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case occupantsConstants.GET_VACANT_UNITS_REQUEST:
            return {
                ...state,
                transferUnit: {
                    vacantUnits: []
                },
                isLoading: true 
            }
        case occupantsConstants.GET_VACANT_UNITS_SUCCESS:
            return {
                ...state,
                transferUnit: {
                    vacantUnits: action.units
                },
                isLoading: false
            }
        case occupantsConstants.GET_VACANT_UNITS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case occupantsConstants.GET_FUNDER_DETAILS_REQUEST:
        case occupantsConstants.OCCUPANTS_SHOW_LOADING:
            return{
                ...state,
                isLoading:true
            }
        case occupantsConstants.OCCUPANTS_HIDE_LOADING:
            return{
                ...state,
                isLoading:false
            }
        case occupantsConstants.GET_FUNDER_DETAILS_SUCCESS:
            return{
                ...state,
                getOne:{
                    ...state.getOne,
                    fpAllFunders:action.data.data
                },
                isLoading:false
            }
        case occupantsConstants.GET_FUNDER_DETAILS_FAILURE:
            return{
                ...state,
                error:action.error,
                isLoading:false
            }
        
        // Save temp data
        case occupantsConstants.OCCUPANTS_TEMP_SAVE_MOVEIN:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: {
                        ...initialState.defaultOccupantDetails,
                        ...state.getOne.data,
                        ...action.data
                    } 
                }
            }

        // Save temp data
        case occupantsConstants.OCCUPANTS_CLEAR_TEMP_SAVE_MOVEIN:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                }
            }

        // get movein config
        case occupantsConstants.OCCUPANTS_GET_MOVEIN_CONFIG_REQUEST:
            return {
                ...state,
                isLoading: true 
            }

        case occupantsConstants.OCCUPANTS_GET_MOVEIN_CONFIG_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: {
                        ...state.getOne.data,
                        ...action.data
                    }
                },
                isLoading: false
            }
        case occupantsConstants.OCCUPANTS_GET_MOVEIN_CONFIG_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }

        // get validation err for occupant
        case occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_REQUEST:
            return {
                ...state,
                validationErrors: [],
                isLoading: true 
            }
            
        case occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_SUCCESS:
            return {
                ...state,
                validationErrors: action.data,
                isLoading: false
            }

        case occupantsConstants.OCCUPANTS_GET_ONE_VALIDATION_ERR_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }

        case occupantsConstants.UPDATE_OCCUPANTS_MRD_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true 
            }
            
        case occupantsConstants.UPDATE_OCCUPANTS_MRD_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case occupantsConstants.UPDATE_OCCUPANTS_MRD_DETAILS_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        case occupantsConstants.OCCUPANTS_GET_ONE_CLEAR_VALIDATION_ERR:
            return {
                ...state,
                validationErrors: [],
                isLoading: false
            }

        case occupantsConstants.TRANSFER_OCCUPANT_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case occupantsConstants.TRANSFER_OCCUPANT_SUCCESS:
            return {
                ...state,
                isLoading: false
            }

        case occupantsConstants.TRANSFER_OCCUPANT_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state
    }
}
