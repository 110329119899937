import { authHeader, globalVariable, handleResponse} from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)


export const validationService = {
    updateValidation,
    updateOverDueSites,
    updateValidationReports,
    updateValidationPost,
    updateOverDueContracts,
    activityValidations,
    removeValidation
};

/**
 * update validations
 * 
 * @param {*} project_id 
 * @param {*} site_id 
 * @param {*} reportYear_id 
 * @param {*} reportTable 
 */
function updateValidation(project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${globalVariable.ms_apiURL}/complainces/${project_id}/${site_id}/${reportYear_id}/${reportTable}?timeZone=${timeZone}`, requestOptions).then(handleResponse)
}

/**
 * remove validations
 * 
 * @param {*} site_id 
 */
function removeValidation(site_id,unit_id){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${globalVariable.apiURL}/remove-validations/${site_id}/${unit_id}`, requestOptions).then(handleResponse)
}

/**
 * Update overdue sites
 * 
 * @param {*} site_id 
 * @param {*} project_id 
 */
function updateOverDueSites(site_id = 0, project_id = 0) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.ms_apiURL}/site-overdues/${site_id}/${project_id}`, requestOptions).then(handleResponse)
}

/**
 * Update overdue sites
 * 
 * @param {*} site_id 
 * @param {*} project_id 
 */
function updateOverDueContracts(site_id = 0, project_id = 0) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.ms_apiURL}/contract-overdues/${site_id}/${project_id}`, requestOptions).then(handleResponse)
}

/**
 * Update Validation Reports
 */
function updateValidationReports(){
    const requestOptions = {
        method : 'GET',
        headers: authHeader(),
    };

    return fetch(`${globalVariable.ms_apiURL}/complainces/update-all-rec/0/100/10000/0`, requestOptions).then(handleResponse);
}

/**
 * refresh validations
 * 
 * @param {*} project_id 
 * @param {*} site_id 
 * @param {*} reportYear_id 
 * @param {*} reportTable 
 */
function updateValidationPost(project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    return fetch(`${globalVariable.ms_apiURL}/complainces/${project_id}/${site_id}/${reportYear_id}/${reportTable}`, requestOptions).then(handleResponse)
}

/**
 * activity validation update
 */
 function activityValidations(validationErrors) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body:JSON.stringify(validationErrors)
    };

    return fetch(`${globalVariable.apiURL}/activity-validations`, requestOptions).then(handleResponse)
}