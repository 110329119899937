import { searchConstants } from '../constants';

export const searchActions = {
    projects,
    sites,
    buildings,
    units,
    occupants,
    organizations,
    users,
    compliance,
    PMDashboard,
    PMDashboardHO,
    PMEffectiveDate,
    dashboardNeedsAction,
    userProjects,
    archives,
    mailTemplate,
    maintenanceSearch,
    loanProjects,
    contract,
    reportList,
    contractActivity,
    reportActivity,
    contacts,
    searchTable1ImportLogs
};
//search functions
function projects(data) {
    return { type: searchConstants.PROJECTS_SEARCH, data };
}

function loanProjects(data) {
    return { type: searchConstants.LOAN_PROJECT_SEARCH, data };
}

function contractActivity(data) {
    return { type: searchConstants.LOAN_ACTIVITY_SEARCH, data };
}

function reportActivity(data){
    return {type: searchConstants.REPORT_ACTIVITY_SEARCH,data}
}

function reportList(data) {
    return { type: searchConstants.REPORT_LIST_SEARCH, data };
}

function contract(data) {
    return { type: searchConstants.CONTRACT_SEARCH, data };
}

function archives(data) {
    return { type: searchConstants.ARCHIVES_SEARCH, data };
}

function userProjects(data) {
    return { type: searchConstants.USER_PROJECTS_SEARCH, data };
}

function sites(data, isSubPage) {
    if (isSubPage)
        return { type: searchConstants.SITES_SEARCH, data };
    else
        return { type: searchConstants.SITESLIST_SEARCH, data };
}

function buildings(data, isSubPage) {
    if (isSubPage)
        return { type: searchConstants.BUILDINGS_SEARCH, data };
    else
        return { type: searchConstants.BUILDINGSLIST_SEARCH, data };
}

function units(data, isSubPage) {
    if (isSubPage)
        return { type: searchConstants.UNITS_SEARCH, data };
    else
        return { type: searchConstants.UNITSLIST_SEARCH, data };
}

function occupants(data) {
    return { type: searchConstants.OCCUPANTS_SEARCH, data };
}

function organizations(data) {
    return { type: searchConstants.ORGANIZATIONS_SEARCH, data };
}

function users(data) {
    return { type: searchConstants.USERS_SEARCH, data };
}

function contacts(data){
    return {type: searchConstants.CONTACT_SEARCH, data};
}

function compliance(data) {
    return { type: searchConstants.COMPLIANCE_SEARCH, data };
}

function PMDashboard(data) {
    return { type: searchConstants.PMDASHBOARD_SEARCH, data };
}

function PMDashboardHO(data) {
    return { type: searchConstants.PMDASHBOARDHO_SEARCH, data };
}

function PMEffectiveDate(data) {
    return { type: searchConstants.PMEFFECTIVE_SEARCH, data };
}

function dashboardNeedsAction(data) {
    return { type: searchConstants.DASHBOARDNEEDSACTION_SEARCH, data };
}

function mailTemplate(data) {
    return { type: searchConstants.MAILTEMPLATE_SEARCH, data };
}

function maintenanceSearch(data) {
    return { type: searchConstants.VERIFY_DATA_MAINTENANCE, data };
}

function searchTable1ImportLogs(data) {
    return { type: searchConstants.TABLE1_IMPORT_LOG, data };
}