import React, { Component } from 'react';
import ReactDOM from'react-dom';

/**
 * FileDownload Component
 * @class FileDownload
 * @extends {Component}
 */
export default class FileDownload extends Component {
    /**
     *Component Did Mount
     *
     * @memberof FileDownload
     */
    componentDidMount() {
        ReactDOM.findDOMNode(this).submit();
        this.props.onDownloadComplete();
    }
    /**
     *render html
     *
     * @returns
     * @memberof FileDownload
     */
    render() {
        const {actionPath, method, name, value} = this.props;

        return (
            <form
                action={actionPath}
                className="hidden"
                method={method}
            >
                Form to download
                <input type="hidden" name={name} value={value} />
            </form>
        );
    }
}