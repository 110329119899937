//import constants
import { validationConstants } from '../constants';

//import services
import { validationService } from '../services/validation';

export const validationActions = {
    updateValidations,
    updateOverDueSites,
    updateValidationReports,
    updateOverDueContracts,
    activityValidations,
    removeValidationOnDelete
};

/**
 * update validations
 * 
 * @param {*} project_id 
 * @param {*} site_id 
 * @param {*} reportYear_id 
 * @param {*} reportTable 
 */
function updateValidations(project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) {
    return dispatch => {
        dispatch(request());

        validationService.updateValidation(project_id, site_id, reportYear_id, reportTable)
            .then(
                updated => {
                  success();
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_REQUEST } }
    function success() { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_SUCCESS } }
    function failure(error) { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_FAILURE, error } }
}

/**
 * remove validations
 * 
 * @param {*} site_id 
 */
function removeValidationOnDelete(site_id,unit_id=0){
    return dispatch => {
        dispatch(request());

        validationService.removeValidation(site_id,unit_id)
            .then(
                updated => {
                  success();
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_REQUEST } }
    function success() { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_SUCCESS } }
    function failure(error) { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_FAILURE, error } }
}

/**
 * Update overdue sites
 * 
 * @param {*} site_id 
 * @param {*} project_id 
 */
function updateOverDueSites(site_id = 0, project_id = 0) {
    return dispatch => {
        dispatch(request());

        validationService.updateOverDueSites(site_id, project_id)
            .then(
                updated => {
                  success();
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_REQUEST } }
    function success() { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_SUCCESS } }
    function failure(error) { return { type: validationConstants.UPDATE_SITE_VALIDATIONS_FAILURE, error } }
}

/**
 * Update overdue sites
 * 
 * @param {*} site_id 
 * @param {*} project_id 
 */
function updateOverDueContracts(site_id = 0, project_id = 0) {
    return dispatch => {
        dispatch(request());

        validationService.updateOverDueContracts(site_id, project_id)
            .then(
                updated => {
                  success();
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: validationConstants.UPDATE_CONTRACT_VALIDATIONS_REQUEST } }
    function success() { return { type: validationConstants.UPDATE_CONTRACT_VALIDATIONS_SUCCESS } }
    function failure(error) { return { type: validationConstants.UPDATE_CONTRACT_VALIDATIONS_FAILURE, error } }
}

/**
 * Update Validation Reports
 */
function updateValidationReports() {
    return dispatch => {
        dispatch(request());

        validationService.updateValidationReports()
            .then(
                updated => {
                  success();
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: validationConstants.UPDATE_ALL_SITE_VALIDATIONS_REQUEST } }
    function success() { return { type: validationConstants.UPDATE_ALL_SITE_VALIDATIONS_SUCCESS } }
    function failure(error) { return { type: validationConstants.UPDATE_ALL_SITE_VALIDATIONS_FAILURE, error } }
}

/**
 * Validation for activities
 */
 function activityValidations(validationErrors, callBack) {
    return dispatch => {
        dispatch(request());

        validationService.activityValidations(validationErrors)
            .then(
                data => {
                  callBack(data)
                  dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: validationConstants.UPDATE_ALL_ACTIVITY_VALIDATIONS_REQUEST } }
    function success() { return { type: validationConstants.UPDATE_ALL_ACTIVITY_VALIDATIONS_SUCCESS } }
    function failure(error) { return { type: validationConstants.UPDATE_ALL_ACTIVITY_VALIDATIONS_FAILURE, error } }
}