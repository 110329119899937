import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import IncomeRent from './IncomeRentProcess/IncomeRent'

/**
 * IncomeRentRouter Component
 * @class IncomeRentRouter
 * @extends {Component}
 */
class IncomeRentRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match } = this.props;
        return (
            <Switch >
                <Route exact path={`${match.url}`} render={(props) => {
                    return <IncomeRent {...props} />
                }} />
                {/* <Route exact path={`${match.url}/logs`} render={(props) => {
                    return <Logs {...props} />
                }} /> */}
            </Switch>
        );
    }
}
/** Export Component */
export default IncomeRentRouter;
