export const contractConstant = {
    GET_CONTRACT_DETAILS_REQUEST: "CONTRACT_DETAILS_REQUEST",
    GET_CONTRACT_DETAILS_SUCCESS: "CONTRACT_DETAILS_SUCCESS",
    GET_CONTRACT_DETAILS_FAILURE: "CONTRACT_DETAILS_FAILURE",

    PAGE_GETALL_CONTRACT_REQUEST: "PAGE_GETALL_CONTRACT_REQUEST",
    PAGE_GETALL_CONTRACT_SUCCESS: "PAGE_GETALL_CONTRACT_SUCCESS",
    PAGE_GETALL_CONTRACT_FAILURE: "PAGE_GETALL_CONTRACT_FAILURE",

    GETALL_ORGANIZATION_REQUEST: "GETALL_ORGANIZATION_REQUEST",
    GETALL_ORGANIZATION_SUCCESS: "GETALL_ORGANIZATION_SUCCESS",
    GETALL_ORGANIZATION_FAILURE: "GETALL_ORGANIZATION_FAILURE",

    GETALL_PREPARER_ORGANIZATION_REQUEST: "GETALL_PREPARER_ORGANIZATION_REQUEST",
    GETALL_PREPARER_ORGANIZATION_SUCCESS: "GETALL_PREPARER_ORGANIZATION_SUCCESS",
    GETALL_PREPARER_ORGANIZATION_FAILURE: "GETALL_PREPARER_ORGANIZATION_FAILURE",

    GET_PROJECTS_DETAILS_REQUEST: "GET_PROJECTS_DETAILS_REQUEST",
    GET_PROJECTS_DETAILS_SUCCESS: "GET_PROJECTS_DETAILS_SUCCESS",
    GET_PROJECTS_DETAILS_FAILURE: "GET_PROJECTS_DETAILS_FAILURE",

    GET_ONE_PREPARER_DETAILS_REQUEST: "GET_ONE_PREPARER_DETAILS_REQUEST",
    GET_ONE_PREPARER_DETAILS_SUCCESS: "GET_ONE_PREPARER_DETAILS_SUCCESS",
    GET_ONE_PREPARER_DETAILS_FAILURE: "GET_ONE_PREPARER_DETAILS_FAILURE",

    GET_UPDATE_CONTRACT_REQUEST: "GET_UPDATE_CONTRACT_REQUEST",
    GET_UPDATE_CONTRACT_SUCCESS: "GET_UPDATE_CONTRACT_SUCCESS",
    GET_UPDATE_CONTRACT_FAILURE: "GET_UPDATE_CONTRACT_FAILURE",

    GET_CONTRACT_DEATILS_REQUEST: "GET_CONTRACT_DEATILS_REQUEST",
    GET_CONTRACT_DEATILS_SUCCESS: "GET_CONTRACT_DEATILS_SUCCESS",
    GET_CONTRACT_DEATILS_FAILURE: "GET_CONTRACT_DEATILS_FAILURE",

    
    // delete contract
    DELETE_REQUEST: 'CONTRACT_DELETE_REQUEST',
    DELETE_SUCCESS: 'CONTRACT_DELETE_SUCCESS',
    DELETE_FAILURE: 'CONTRACT_DELETE_FAILURE',

    CLEAR_CONTRACT_DETAILS: "CLEAR_CONTRACT_DETAILS"
}