import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

//components
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import TableComponent from '../../components/Table/TableComponent';
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Import Redux Actions
import { unitsActions } from '../../actions/units';
import { confActions } from '../../actions/configuration';
import { sitesActions } from '../../actions/sites';
import { dashboardActions } from '../../actions/dashboard';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';

// Import Helpers
import { getUserAccessForPage, getUserRole, getUserOrgId, getUser } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";
import { globalVariable, history, filterSearchFields } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

let DEFAULT_DEL_TITLE = "Deleting this unit will delete all household names ?"

/**
 * Units Component
 * @class Units
 * @extends {Component}
 */
class Units extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            fieldFilter: false,
            reset: false,
            openHelp: false,
            deleteContent: "",
            addUnitPopup: {
                open: false,
                project: '',
                site: '',
                building: '',
                unitDelete: false,
                deleteItemId: null,
            }
        }
        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
        this.pageAccess = getUserAccessForPage('units');
    }
    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { units, fieldFilterTblName, location: { pathname } } = this.props;
        const { page } = units;
        const building_id = this.props.buildingId;
        this.props.getHelp();
        let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
        // retain old search values
        if (page.skip) {
            this.props.getUnits(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, building_id, query);
        } else {
            this.props.getUnits(1, globalVariable.tableRowSize, 'common_id', 'asc', '', '', building_id, query);
        }

        setTimeout(() => {
            this.props.getFieldFilter(fieldFilterTblName);
        }, 100);

        // to get associated projects and sites list
        this.props.getProjectSummary(null, '');
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        const { siteId, location: { pathname } } = this.props;
        if (siteId) {
            this.props.getById(siteId, pathname.includes('archives') ? 'archives' : null);
        }
    }
    /**
     * Component Did Update
     */
    componentDidUpdate() {
        const { location } = this.props;
        switch (location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace({
                    pathname: location.pathname,
                    state: {
                        ...location.state
                    }
                });
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const building_id = this.props.buildingId;
        const { units, searchData: { sFields, sValues }, location: { pathname } } = this.props;
        let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
        this.props.getUnits(skip, limit, units.page.orderBy, units.page.order, sFields, sValues, building_id, query);
    };
    /**
     * Handle Sort Request
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const building_id = this.props.buildingId;
        const { units, searchData: { sFields, sValues }, location: { pathname } } = this.props;
        let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
        this.props.getUnits(units.page.skip, units.page.limit, orderBy, order, sFields, sValues, building_id, query);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { units, searchData, isSubPage, location: { pathname } } = this.props;
        const building_id = this.props.buildingId;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
        this.props.searchActions({ sFields, sValues }, isSubPage);
        this.props.getUnits(1, globalVariable.tableRowSize, units.page.orderBy, units.page.order, sFields, sValues, building_id, query);
    };
    /**
     * Handle Table Delete Action
     * @param {*} unitid 
     */
    handleTableDeleteAction = (unitid, item) => {
        let userDetails = getUser()
        let mailContent;
        let deleteTitle = DEFAULT_DEL_TITLE;
        this.props.getDelMsg(unitid, (datas) => {
            if (datas && datas.data && datas.data[0]) {
                let data = datas.data[0]
                let membersName = data.headOfHouseName.toString();
                membersName = membersName.replaceAll(",", ", ")
                if (data && data.common_id) {
                    deleteTitle = membersName.length < 1 || membersName === "<empty>" ? <p>This <b>Unit: {data.common_id}</b> is not associated with any households.</p> : <p>This <b>Unit: {data.common_id}</b> is associated with Households: <b>{membersName}.</b> Deleting this unit will delete all associated household details from table 1?</p>
                    mailContent = membersName.length < 1 || membersName === "<empty>" ? `<p>Hello,<br></br><b>Unit: ${data.common_id}</b> is not associated with any households.</p>` : `<p>Hello,<br></br><b>Unit: ${data.common_id}</b> is associated with the following Households: <b>${membersName}</b> are deleted from the table1, Site: ${data.siteName} and Project: ${data.projectName} by the user: <b>${userDetails.firstName}.</b></p>`
                    item.funderStaffsMail = data.funderStaffsMail
                }
                item.module = "Unit"
                if (mailContent) {
                    item.mailContent = mailContent
                }
            }
            if (deleteTitle) {
                this.setState({ unitDelete: true, deleteItemId: unitid, deleteContent: deleteTitle, deleteItem: item });
            }
        })
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        const { match, location: { state } } = this.props;

        switch (field) {
            case 'project.name':
                history.push({
                    pathname: `/projects/${item.project_id._id}`,
                    state: {
                        page: 'projectEdit',
                        pageData: {
                            project_id: item.project_id._id
                        }
                    }
                });
                break;
            case 'site.name':
                history.push({
                    pathname: `/sites/${item.site_id._id}`,
                    state: {
                        page: 'siteEdit',
                        pageData: {
                            site_id: item.site_id._id
                        }
                    }
                });
                break;
            case 'building.name':
                history.push({
                    pathname: `/buildings/${item.building_id._id}`,
                    state: {
                        page: 'buildingEdit',
                        pageData: {
                            site_id: item.site_id._id,
                            building_id: item.building_id._id
                        }
                    }
                });
                break;
            case 'common_id':
                history.push({
                    pathname: `${match.url}/${id}`,
                    state: {
                        page: 'unitEdit',
                        pageData: {
                            site_id: state.pageData ? state.pageData.site_id : item.site_id._id,
                            building_id: state.pageData ? state.pageData.building_id : item.building_id._id,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            default:
        }
    }
    /**
     * Handle Add Action
     */
    handleAddActionClick = () => {
        const { location: { state }, match, siteId, buildingId } = this.props;
        if (state.main === true) {
            // open popup
            this.setState({
                addUnitPopup: {
                    ...this.state.addUnitPopup,
                    open: true
                }
            });
        } else {
            // if add unit clicked from the sub page
            history.push({
                pathname: `${match.url}/new`,
                state: {
                    page: 'unitNew',
                    pageData: {
                        site_id: siteId || null,
                        building_id: buildingId || null
                    }
                }
            });
        }
    };
    /**
     * A method to navigate to the project->sites->building->units->add unit page when user selects from add unit popup
     */
    gotoAddUnitPage = () => {
        const { addUnitPopup: { building, site, project } } = this.state;
        history.push({
            pathname: `/projects/${project.value}/sites/${site.value}/buildings/${building.value}/units/new`,
            state: {
                page: 'unitNew',
                pageData: {
                    site_id: site.value,
                    building_id: building.value
                },
                pageTitle: {
                    title: `${building.label} | ${site.label} | ${project.label}`,
                    subTitle: ''
                }
            }
        });
    }
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        this.setState({ reset: true });
        const { units, buildingId, isSubPage, location: { pathname } } = this.props;
        let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
        this.props.searchActions({ sFields: [], sValues: [] }, isSubPage);
        this.props.getUnits(units.page.skip, units.page.limit, units.page.orderBy, units.page.order, [], [], buildingId, query);
    };
    /**
     * Handle Field Filter Action Event
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter(this.props.fieldFilterTblName, this.props.units.page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter(this.props.fieldFilterTblName, this.props.units.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(this.props.fieldFilterTblName, updatedColumns);
        } else {
            this.props.changeFieldFilter(this.props.fieldFilterTblName, [...this.props.visibleColumns, key]);
        }
    }

    getDeleteTitle = (item) => {
        let fundersName = item.claimFunder.toString();
        fundersName = fundersName.replaceAll(",", ", ")
        return <p>This <b>Unit: {item.common_id}</b> is monitored by the funder(s) <b>{fundersName}.</b> Are you sure you want to delete this record?</p>
    }

    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter(this.props.fieldFilterTblName, this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { units, searchData: { sFields, sValues }, location: { pathname } } = this.props;
            const building_id = this.props.buildingId;
            let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
            this.props.getUnits(units.page.skip, units.page.limit, units.page.orderBy, units.page.order, sFields, sValues, building_id, query);
        });
    }
    /**
   * Handle Back Action Click
   */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Bind Html to DOM 
     * 
     * [1]  -   Remove bulk upload icon - projects/unitlist
     */
    render() {
        const { userRole } = this;
        const { addUnitPopup, addUnitPopup: { project, site, building }, openHelp, deleteContent, deleteItem } = this.state;
        let { units, title, columnsMap, visibleColumns,defaultCols, location: { pathname },
            needTitleBar, pageAccess, isSubPage, classes, projectSummaryData, searchData: { sFields, sValues }, helpData } = this.props;
        const fieldFilters = units.page ? units.page.fieldFilters : {};
        const defaultColumns = fieldFilters.defaultCols
        const { projects, projectsCount } = projectSummaryData;
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        // [1]
        if (pathname.includes('projects') || pathname.includes('buildings')) {
            const index = pageAccess.indexOf('UPLOAD_BULK_UTILITYALLOWANCE');
            if (index > -1) {
                pageAccess.splice(index, 1);
            }
            pageAccess = isSubPage || title === 'All Units' ? [...pageAccess, 'HELP'] : [...pageAccess, 'BACK'];
        } else {
            pageAccess = isSubPage || title === 'All Units' ? [...pageAccess, 'HELP'] : [...pageAccess, 'BACK'];
        }
        return (
            <ContentWrapper
                classes={classes}
                needTitleBar={needTitleBar}
                pageAccess={pageAccess}
                title={'All Units'}
                isSubPage={isSubPage}
                needPadding={false}
                handleAddActionClick={this.handleAddActionClick}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleBackActionClick={this.handleBackActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                handleUploadActionClick={(event) => {
                    history.push({
                        pathname: `/bulk-upload/units`,
                        state: {
                            page: 'bulkUpload',
                            pageData: {}
                        }
                    })
                }}
                uploadTooltip="Units Bulk Update"
            >
                <TableComponent
                    page={units.page.skip}
                    rowsPerPage={units.page.limit}
                    count={units.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    actions={pageAccess}
                    links={["project.name", "site.name", "building.name", "common_id"]}
                    userRole={userRole}
                    // isLoading={units.isLoading}
                    order={units.page.order}
                    orderBy={units.page.orderBy}
                    data={units.data}
                    currentPage={'units'}
                    onChangePageTable={this.onChangePageTable}
                    getDeleteTitle={this.getDeleteTitle}
                    highlightField={['project.name', 'site.name', 'building.name', "common_id"]}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={units.isLoading ? "" : "No Records Found"}
                    moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                    prevNext={false}
                    paginationVisible={true}
                    disablePagination={units.page.lastPage}
                    showLoading={isSubPage}
                />
                <FieldFilter
                    open={this.state.fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                    // disabledCols={defaultColumns}
                />
                <AlertDialog
                    open={addUnitPopup.open}
                    title="Add Unit"
                    onClose={() => {
                        this.setState({
                            addUnitPopup: {
                                ...addUnitPopup,
                                open: false
                            }
                        })
                    }}
                    onSave={() => {
                        this.refs.form.submit();
                    }}
                    saveText={'Add Unit'}
                >
                    <Grid item xs={12} sm={12} style={{ minWidth: '400px' }}>
                        <ValidatorForm
                            name="addUnitToForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.gotoAddUnitPage}
                            onError={errors => { }} >
                            <Grid item xs={12} sm={12}>
                                <AutoComplete
                                    name="project"
                                    suggestions={projects}
                                    className="autocomplete-fixed"
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            addUnitPopup: {
                                                ...addUnitPopup,
                                                project: selectedOption,
                                                site: ''
                                            }
                                        })
                                    }}
                                    selectedValue={project}
                                    disabled={false}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    placeholder="Select Project"
                                    dataCount={projectsCount}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AutoComplete
                                    name="site"
                                    suggestions={project.sites}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            addUnitPopup: {
                                                ...addUnitPopup,
                                                site: selectedOption,
                                                building: ''
                                            }
                                        });
                                        this.props.getBuildingsList(selectedOption.value);
                                    }}
                                    selectedValue={site || {}}
                                    disabled={false}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    placeholder="Select Site"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <AutoComplete
                                    name="building"
                                    suggestions={units.addUnit.buildings}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            addUnitPopup: {
                                                ...addUnitPopup,
                                                building: selectedOption
                                            }
                                        });
                                    }}
                                    selectedValue={building || {}}
                                    disabled={false}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    placeholder="Select Building"
                                />
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </AlertDialog>
                <DeleteDialog
                    open={this.state.unitDelete === true}
                    content={deleteContent}
                    handleDeleteAction={() => {
                        this.setState({ unitDelete: false });
                        const { units } = this.props;
                        const building_id = this.props.buildingId;
                        this.props.deleteUnit(this.state.deleteItemId, this.state.deleteItem, () => {
                            let query = pathname.includes('archives') ? { isDelete: true } : { isDelete: false };
                            this.props.getUnits(1, globalVariable.tableRowSize, units.page.orderBy, units.page.order, sFields, sValues, building_id, query);
                        });
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ unitDelete: false })
                    }}
                />
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Units"
                />}
            </ContentWrapper>
        );
    }
}
/** Props Types */
Units.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    needTitleBar: PropTypes.bool,
    title: PropTypes.string
};
/** Default Props */
Units.defaultProps = {
    title: "All Units",
    isSubPage: false,
    needTitleBar: true,
    pageAccess: []
};
/**
 * Map State to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration, units, pageAccess, dashboard, search, help }, ownProps) => {
    const fieldFilterTblName = ownProps.isSubPage === true ? 'units' : 'unitsList';
    const columnsData = configuration.fieldFilter[fieldFilterTblName] || {};
    const searchData = ownProps.isSubPage ? search['units'] || { sFields: [], sValues: [] } : search['unitsList'] || { sFields: [], sValues: [] };
    const helpData = help.data[0]

    return {
        units: units,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess: pageAccess['unitsList'] || [],
        projectSummaryData: dashboard.projectSummary,
        fieldFilterTblName,
        searchData,
        helpData
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getUnits: (skip, limit, orderBy, order, sFields, sValues, building_id, query) => dispatch(unitsActions.getAll(skip, limit, orderBy, order, sFields, sValues, building_id, query)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        resetFilter: () => dispatch(unitsActions.resetFilter()),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        deleteUnit: (unitid, item, cb) => dispatch(unitsActions.delete(unitid, item), cb),
        getProjectSummary: (limit, search) => dispatch(dashboardActions.getProjectSummary(limit, search)),
        getBuildingsList: (site_id) => dispatch(unitsActions.getBuildingslist(site_id)),
        getById: (id, type) => dispatch(sitesActions.getById(id, type)),
        searchActions: (data, isSubPage) => dispatch(searchActions.units(data, isSubPage)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 10 })),
        getDelMsg: (id, cb) => dispatch(unitsActions.getDelMsg(id, cb)),

    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Units));

