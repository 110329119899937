export const validationConstants = {
    // update single site validation
    UPDATE_SITE_VALIDATIONS_REQUEST: 'UPDATE_SITE_VALIDATIONS_REQUEST',
    UPDATE_SITE_VALIDATIONS_SUCCESS: 'UPDATE_SITE_VALIDATIONS_SUCCESS',
    UPDATE_SITE_VALIDATIONS_FAILURE: 'UPDATE_SITE_VALIDATIONS_FAILURE',

    // update all site validation
    UPDATE_ALL_SITE_VALIDATIONS_REQUEST: 'UPDATE_ALL_SITE_VALIDATIONS_REQUEST',
    UPDATE_ALL_SITE_VALIDATIONS_SUCCESS: 'UPDATE_ALL_SITE_VALIDATIONS_SUCCESS',
    UPDATE_ALL_SITE_VALIDATIONS_FAILURE: 'UPDATE_ALL_SITE_VALIDATIONS_FAILURE',

    // update single site validation
    UPDATE_CONTRACT_VALIDATIONS_REQUEST: 'UPDATE_CONTRACT_VALIDATIONS_REQUEST',
    UPDATE_CONTRACT_VALIDATIONS_SUCCESS: 'UPDATE_CONTRACT_VALIDATIONS_SUCCESS',
    UPDATE_CONTRACT_VALIDATIONS_FAILURE: 'UPDATE_CONTRACT_VALIDATIONS_FAILURE',

    // activity validations
    UPDATE_ALL_ACTIVITY_VALIDATIONS_REQUEST: 'UPDATE_ALL_ACTIVITY_VALIDATIONS_REQUEST',
    UPDATE_ALL_ACTIVITY_VALIDATIONS_SUCCESS: 'UPDATE_ALL_ACTIVITY_VALIDATIONS_SUCCESS',
    UPDATE_ALL_ACTIVITY_VALIDATIONS_FAILURE: 'UPDATE_ALL_ACTIVITY_VALIDATIONS_FAILURE',
}

