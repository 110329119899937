import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { List, Typography, ExpansionPanelDetails, ExpansionPanel, ExpansionPanelSummary, IconButton } from '@material-ui/core';

// Actions
import { helpActions } from '../../actions';

// Components
import FloaterContent from '../../components/HelpFloater/FloaterContent'
import ToolTip from '../../components/ToolTip/ToolTip';
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog';
import { DeleteIcon, PencilsquareIcon } from '../../components/SvgIcons/SvgIcons';

// Helpers
import { history, constants, getUserRole, getUser } from '../../helpers';

const styles = {
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 14,
        fontWeight: 600,
        alignSelf: 'center'
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    headerIcons: {
        display: 'flex',
        flexDirection: 'row-reverse',
        '& button': {
            color: '#36aebe !important'
        }
    }
};
/**
 * HelpContent Container
 * @class HelpContent
 * @extends {Component}
 */
class HelpContent extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.orgType = getUserRole();
        this.userDet = getUser();
        this.state = {
            open: false,
            deleteItemId: null,
        }

        this.handleDelete = this.handleDelete.bind(this);
        this.handleEdit = this.handleEdit.bind(this);

    }
    /**
     * Handle delete
     * @param {*} id 
     */
    handleDelete = (id) => {
        this.setState({
            open: true,
            deleteItemId: id
        });
    }
    /**
     * Handle edit
     * @param {*} _id 
     */
    handleEdit = (_id) => {
        const { match } = this.props;
        history.push({
            pathname: `${match.url}/helpview/${_id}`,
            state: {
                page: 'helpedit',
                pageData: {
                    _id: _id
                }
            }
        });
    }
    /**
     * Binr HTML to reactDOM
     * @returns 
     */
    render() {
        const { helpData, classes } = this.props;
        const { orgType, userDet } = this;
        const acc = (((orgType === constants.superFunder) || (orgType === constants.superFunderWithHO)) || ((orgType === constants.funderWithOA || orgType === constants.funderWithoutOA 
            || orgType === constants.funderWithOAandBoth || orgType === constants.funderWithoutOAandBoth || orgType === constants.funderWithOAandHO|| orgType === constants.funderWithoutOAandHO) 
            && ((userDet.org.id === 1 || userDet.org.id === 2 || userDet.org.organizationAccess===2) && (userDet.type === '' || userDet.type === "Organization Admin")))) ? true : false;
        return (
            <List>
                { helpData.helpTopics ? helpData.helpTopics.map(help => {
                    return (
                        <ExpansionPanel key={help._id}>
                            <ExpansionPanelSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <div className={classes.header}>
                                    <Typography className={classes.heading}>{help.title.replace(/^\w/, (c) => c.toUpperCase())}</Typography>
                                    {acc && <div className={classes.headerIcons}>
                                        < ToolTip title="Delete" placement="bottom" >
                                            <IconButton
                                                className="delete_icon_Help"
                                                color="primary"
                                                aria-label="View"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleDelete(help._id);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ToolTip>
                                        <ToolTip title="Edit" placement="bottom">
                                            <IconButton
                                                className="edit_icon_Help"
                                                color="primary"
                                                aria-label="View"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleEdit(help._id);
                                                }}
                                            >
                                                <PencilsquareIcon />
                                            </IconButton>
                                        </ToolTip>
                                    </div>}
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <FloaterContent
                                    help={help}
                                    needTitle={false}
                                />
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        // </ListItem>
                    )
                }) : <div className="nocontent">No Help Information Available</div>
                }
                <DeleteDialog
                    open={this.state.open !== false}
                    content={"Are you sure you want to remove this attachment?"}
                    handleDeleteAction={async () => {
                        await this.setState({ open: false });
                        await this.props.deleteHelp(this.state.deleteItemId);
                        await this.props.handleGetHelps();
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ open: false });

                    }}
                />
            </List>
        )
    }
}
/** Props types */
HelpContent.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        deleteHelp: (id) => dispatch(helpActions.delete(id)),
        getHelp: (key) => dispatch(helpActions.getAll({_id: key})),
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(HelpContent));