//import helper
import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from '../helpers';

export const helpService = {
    getAll,
    createHelp,
    getById,
    getHelpById,
    uploadFile,
    delete: _delete,
    viewFile,
    downloadFile
};

/**
 * Get All Help Lists
 */
function getAll(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/help/get-topics`, requestOptions).then(handleResponse);
}

/**
 *Create help
 *
 * @param {*} formData - title and category
 * @param {*} editorNotes - notes
 * @returns
 */
function createHelp(formData,editorNotes) {
    let data={
        help:formData,
        editorNotes:editorNotes,
    }
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/help`, requestOptions).then(handleResponse);
}

/**
 *View thw help data
 * 
 * @param {*} _id - help id
 * @returns
 */
function getById(_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/help/${_id}`, requestOptions).then(handleResponse);
}

/**
 *Edit the help data
 *
 * @param {*} _id
 * @returns
 */
function getHelpById(_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/help/helpview/${_id}`, requestOptions).then(handleResponse);
}

/**
 * upload file
 */
function uploadFile(id, formData) {
    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData
    };
 
    return fetch(`${globalVariable.apiURL}/help/file/${id}`, requestOptions)
        .then(response => response.blob());
 }
 
/**
 *Delete help record
 *
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id - help id
 * @returns
 */
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/help/${id}`, requestOptions).then(handleResponse);
}

/**
 *View help data
 *
 * @param {*} _id - help id
 * @param {*} fileName - help file name
 * @returns
 */
function viewFile(_id, fileName) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/help/view/${_id}/${fileName}`, requestOptions).then(handleDownloadResponse);
}

/**
 *Download help data
 *
 * @param {*} fileName - help file name
 * @returns
 */
function downloadFile(fileName) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        responseType: 'arraybuffer'
    };

    return fetch(`${globalVariable.apiURL}/help/download/${fileName}`, requestOptions).then(handleDownloadResponse);
}
