import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// Icons
import { PageDoubleLeftIcon, PageDoubleRightIcon, PageleftIcon, PageRightIcon } from "../SvgIcons/SvgIcons";
/**
 * Export Page Component
 * @class Page
 * @extends {Component}
 */
export default class Page extends Component {
    
    //static propTypes need to be declared on the class itself, not on the instance of the class
    
    static propTypes = {
        pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        pageNumber: PropTypes.number.isRequired,
        onClick: PropTypes.func.isRequired,
        isActive: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool,
        activeClass: PropTypes.string,
        activeLinkClass: PropTypes.string,
        itemClass: PropTypes.string,
        linkClass: PropTypes.string,
        disabledClass: PropTypes.string,
        href: PropTypes.string,
        disablePagination:PropTypes.bool
    };

    static defaultProps = {
        pageNumber:0,
        activeClass: "active",
        disabledClass: "disabled",
        itemClass: undefined,
        linkClass: undefined,
        activeLinkCLass: undefined,
        isActive: false,
        isDisabled: false,
        href: "#",
        disablePagination:false
    };
    /**
     * Handle Click Event
     * @param {*} e 
     * @returns 
     */
    handleClick = (e) => {
        const { isDisabled, pageNumber } = this.props;
        e.preventDefault();
        if (isDisabled) {
            return;
        }
        this.props.onClick(pageNumber);
    }
    /**
     * Bind
     * HTML to DOM
     */
    render() {
        let {
            pageText,
            //pageNumber,
            activeClass,
            itemClass,
            //linkClass,
            //activeLinkClass,
            disabledClass,
            isActive,
            isDisabled,
            disablePagination
           // href
        } = this.props;
        const css = cx(itemClass, {
            [activeClass]: isActive,
            [disabledClass]: isDisabled,
            isDisablePagination:disablePagination
        });

        // const linkCss = cx(linkClass, {
        //     [activeLinkClass]: isActive
        // });
        return (
            <li className={css} onClick={this.handleClick}>
                {// eslint-disable-next-line
                    <a>
                        {pageText === '⟨' &&
                        <PageleftIcon disablePagination={disablePagination}/>
                        }
                        {pageText === '«' &&
                        <PageDoubleLeftIcon disablePagination={disablePagination}/>
                        }
                        {pageText === '⟩' &&
                        <PageRightIcon disablePagination={disablePagination}/>
                        }
                        {pageText === '»' &&
                        <PageDoubleRightIcon disablePagination={disablePagination}/>
                        }
                        {pageText !== '⟨' && pageText !== '«' && pageText !== '⟩' && pageText !== '»' &&
                            pageText
                        }
                    </a>
                }
            </li>

            );
    }
}
