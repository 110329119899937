const pageTitleStyle = theme => ({
    root: {
        flexGrow: 1
        //paddingTop: theme.spacing.unit * 1,
        //paddingBottom: theme.spacing.unit * 1,
    },
    noPadding: {
        padding: 0
    },
    typography: {
        // marginTop: 3,
        textTransform: 'uppercase',
        fontSize: '1.20rem',
        letterSpacing: 0
    },
    subPageTypography: {
        textTransform: 'uppercase',
        fontSize: "13px"
    },
    button: {
        marginRight: 10,
    },
    // subPage: {
    //     minHeight: "40px",
    //     padding: "10px 0px",
    //     marginTop: 5
    // }

    subPage: {
        padding: "0px 0px"
    },
});

export default pageTitleStyle;
