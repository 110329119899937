import { restoreTimeConstants } from "../constants/restoreTime";

import { restoreTime } from "../services/restoreTime";

export const restoreTimeActions = {
    getRestoreTime
}
/**
*Get current Database Date and Time
*
* @param {*} data - Environment detail
* @returns
*/
function getRestoreTime(data) {
    return dispatch => {
        dispatch(request());
         
        restoreTime.getRestoreDate(data).then(item=>{
            dispatch(success(item))
        }).catch(err=>{
            dispatch(failure(err))
        })

    };

    function request() { return { type: restoreTimeConstants.GET_RESTORE_DATE_REQUEST } }
    function success(users) { return { type: restoreTimeConstants.GET_RESTORE_DATE_SUCCESS, users } }
    function failure(error) { return { type: restoreTimeConstants.GET_RESTORE_DATE_FAILURE, error } }
}