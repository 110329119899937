const helpFloaterStyle = theme => ({
  container: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 500001,
  },
  backDrop: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 500002,
    backgroundColor: 'rgb(181 181 181 / 37%)',
  },
  floater: {
    position: 'absolute',
    right: 0,
    width: '45%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 500003,
    overflow: 'scroll',
  },
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    fontSize: 20,
    padding: 12,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 'bold',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subHeading: {
    fontSize: 16,
    // paddingBottom: 12,
    fontWeight: 'bold'
  },
  body: {
    // height: "100%",
    // overflow: 'scroll',
    padding: 20,
    // paddingBottom: 52
  },
  helpContent: {
    paddingBottom: 6,
    paddingTop: 6,
    width: "100%"
  },
  text: {
    paddingBottom: 12,
    '& p': {
      margin: '2px 0'
    }
  },
  image: {
    width: '100%'
  },
  pdfContainer: {
    padding: 12,
    margin: '6px 0',
    boxShadow: '0px 0px 5px #a7a7a7de'
  },
  videoContainer: {
    padding: 12,
    boxShadow: '0px 0px 5px #a7a7a7de'
  },
  pdfContainerHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  videoContainerHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  flexBox: {
    display: 'flex'
  },
  attachment: {
    paddingLeft: 8,
    alignSelf: 'center'
  },
  noRecords: {
    alignItems: 'center',
    padding: '12px 0',
    textAlign: 'center',
    color: '#ccc',
    fontWeight: 'bold',
    fontSize: 18,
    letterSpacing: 0
  }
});

export default helpFloaterStyle;
