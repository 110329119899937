import React, { Component } from 'react';

// Material UI
import Grid from '@material-ui/core/Grid';

// import components
import PageActions from '../../../components/PageActions/PageActions';
import ToolTip from '../../../components/ToolTip/ToolTip';
import IconButton from '@material-ui/core/IconButton';
import TextBox from '../../../components/TextBox/TextBoxToolTip';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import { focusHelper, getUserType } from '../../../helpers/global';

class HomePurchse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arr: this.props.homePurchased,
            certficationAddress: false,
            focusVisible: false,
        }
        this.userRole = getUserType();
    }
    getTooltipProps = (fieldName) => {
        const { parentAlignTop, parentAlignBottom, childTop } = this.props
        return {
            focusVisible: (parentAlignTop && parentAlignBottom && childTop) ? childTop + 25 > parentAlignTop && childTop < parentAlignBottom && this.state.focusFieldName === fieldName : this.state.focusFieldName === fieldName,
            onFocus: (ele, name) => {
                this.setState({ focusField: ele, focusFieldName: fieldName, focusVisible: true })
                this.props.focusEle(ele)
            },
            onBlur: () => this.setState({ focusField: null, focusFieldName: "", focusVisible: false })                                        // validators={['required', 'isStringLength250', 'validAddress']}
        }
    }

    render() {
        let { homePurchased, childrenAlign, cities, counties, disabled, year } = this.props;
        return (
            <React.Fragment>
                {childrenAlign === 'top' && this.props.children}
                {homePurchased && homePurchased.map((item, index) => {
                    return (
                        <Grid container spacing={3} >
                            <Grid item xs={12} sm={5} align="left">
                                <TextBox
                                    toolTipTitle={focusHelper().certficationAddress}
                                    {...this.getTooltipProps(`${index}_homePurchasedAddress`)}
                                    tooltipOnHover={(item.year && year > item.year) || disabled ? false : true}
                                    name={`${index}_homePurchasedAddress`}
                                    label="Address"
                                    value={item.homePurchasedAddress}
                                    validators={!item.isMigrated?['required']:[]}
                                    errorMessages={['this field is required']}
                                    handleChange={this.props.handleChangeHome('homePurchasedAddress', index)}
                                    margin="dense"
                                    disabled={(item.year && year > item.year) || disabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} align="center">
                                <AutoComplete
                                    placeholder="City"
                                    name={`${index}_homePurchasedCity`}
                                    suggestions={cities}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.props.handleAutoCompleteHome('homePurchasedCity', index, selectedOption.value);
                                    }}
                                    selectedValue={{ value: item.homePurchasedCity, id: item.homePurchasedCity }}
                                    disabled={(item.year && year > item.year) || disabled}
                                    validators={!item.isMigrated?['required']:[]}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3} align="center">
                                <AutoComplete
                                    placeholder="County"
                                    name={`${index}_homePurchasedCounty`}
                                    suggestions={counties}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.props.handleAutoCompleteHome('homePurchasedCounty', index, selectedOption.value);
                                    }}
                                    selectedValue={{ value: item.homePurchasedCounty, id: item.homePurchasedCounty }}
                                    disabled={(item.year && year > item.year) || disabled}
                                    validators={!item.isMigrated?['required']:[]}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                            {item.year && year > item.year ?
                                null
                                :
                                <Grid item xs={12} sm={1} className="align-self-center">
                                    <ToolTip title="Delete" placement="bottom">
                                        <IconButton
                                            className="delete_icon"
                                            color="primary"
                                            aria-label="View"
                                            disabled={(item.year && year > item.year) || disabled}
                                            onClick={() => {
                                                this.props.onDelete(index);
                                            }}
                                        >
                                            <i className="lnr lnr-trash" ></i>
                                        </IconButton>
                                    </ToolTip>
                                </Grid>
                            }
                        </Grid>
                    );
                })}
                < Grid item xs={12} sm={12} className="certificationAddRow">
                    <PageActions
                        isSubPage={true}
                        actions={['ADDLINK']}
                        handleAddActionClick={() => {
                            homePurchased.length > 500 ?
                                this.props.showErrorAlt() :
                                this.props.onAdd();
                        }}
                        homePurchase={disabled}
                    />
                </Grid>

            </React.Fragment>
        );
    }
}

HomePurchse.defaultProps = {

};

export default HomePurchse;
