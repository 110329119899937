export const WarningNames = [
    {
        title: "TotalNumberofRestrictedUnitsNotMatchWithSystem",
        fieldName: "bedUnits",
        type: "section1"
    },
    {
        title: "bedUnits",
        type: "section1"
    },
    {
        title: "HomelessCountGreaterThanTable2TotalHouseholds",
        type: "section2"
    },
    {
        title: "RaceEthnicityNotMatchHouseholdsServed",
        type: "section6"
    },
    {
        title: "RaceandEthnicityTotalServedNotEqualTotalHouseholdsServed",
        type: "section6"
    },
    {
        title: "RaceandEthnicityTotalServedNotEqualTotalHouseholdsServed",
        type: "section6"
    },
    {
        title: "sec4total",
        type: "section5"
    },
    {
        title: "SingleParentHOHGreaterThanHouseholdsServed",
        type: "section6"
    },
    {
        title: "TotalHouseholdIncomeServedNotEqualTotalHouseholdServed",
        type: "section7"
    },
    {
        title: "TotalNumberofOccupantsGreaterThanTotalHouseholdsServed",
        type: "section8"
    },
    {
        title: "TotalSingleParentHouseholdServedNotEqualTotalHouseholdServed",
        type: "section6"
    },
    {
        title: "HouseholdsInYearNotEqualToTotalHouseholdsServed",
        type: "section7"
    },
    {
        title: "NumberOfMinorsGreaterThenTotalHouseholdsServedSestion5",
        type: "section8"
    },
    {
        title: "TotalOccupiedBedNightsUsedGreaterThenTotalBednightsAvailable",
        type: "section2"
    }
]