import { getFloat } from "../";
import { Table2FarmworkerMessages } from "./messages";
import moment from 'moment';

const Table2FarmworkerValidations = (data) => {
    const errors = [];
    const { reportYear, reportYearDatesofOperation, reportYearDatesofOperationEnd, validationErrors } = data;

    // error - 1 - section 1
    const { temporaryFarmworkerHousing } = data;
    if (getFloat(temporaryFarmworkerHousing) <= 0) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).noOfBeds,
            fieldName: 'temporaryFarmworkerHousing',
            sectionIndex: 1
        });
    }

    // error - 1
    if (reportYear !== moment(reportYearDatesofOperation).year()) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).beginningRY,
            fieldName: 'reportYearDatesofOperation',
            sectionIndex: 2
        });
    }

    // error - 1 a
    if (reportYear !== moment(reportYearDatesofOperationEnd).year()) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).endingRY,
            fieldName: 'reportYearDatesofOperationEnd',
            sectionIndex: 2
        });
    }

    // error - 2
    // const { totalBednightsAvailable, totalOccupiedBednights } = data;
    // if (getFloat(totalOccupiedBednights) > getFloat(totalBednightsAvailable)) {
    //     errors.push({
    //         msg: Table2FarmworkerMessages({ year: reportYear }).bedNightAvailable,
    //         fieldName: 'totalOccupiedBednights',
    //         sectionIndex: 2
    //     });
    // }
    // TotalOccupiedBedNightsUsedGreaterThenTotalBednightsAvailable
    // const bedCount = validationErrors.filter(vr => vr.title === "TotalOccupiedBedNightsUsedGreaterThenTotalBednightsAvailable").length > 0;
    // if (bedCount) {
    //     errors.push({
    //         msg: Table2FarmworkerMessages({ year: reportYear }).bedNightAvailable,
    //         fieldName: 'totalOccupiedBednights',
    //         sectionIndex: 2,
    //         type: 2
    //     });
    // }


    // error - 3a
    const { chargedtoTenantsbed, chargedtoEmployersbed, chargedtoTenantsUnit, chargedtoEmployersUnit } = data;
    if (getFloat(chargedtoTenantsbed) <= 0 || getFloat(chargedtoEmployersbed) <= 0) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).perBed,
            fieldName: 'perBed',
            sectionIndex: 4
        });
    }
    // error - 3b
    if (getFloat(chargedtoTenantsUnit) <= 0 || getFloat(chargedtoEmployersUnit) <= 0) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).perUnit,
            fieldName: 'perUnit',
            sectionIndex: 4
        });
    }

    // error- 4
    const { totalHouseholdsServed5thPart, householdIncome } = data;
    if (getFloat(totalHouseholdsServed5thPart) !== getFloat(householdIncome)) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).totalHousehold,
            fieldName: 'householdIncome',
            sectionIndex: 7
        });
    }

    // const { numberofMinors } = data;
    // if (getFloat(numberofMinors) > getFloat(totalHouseholdsServed5thPart)) {
    //     errors.push({
    //         msg: Table2FarmworkerMessages({ year: reportYear }).noOfMinors,
    //         fieldName: 'numberofMinors',
    //         sectionIndex: 8
    //     });
    // }
    const noOfMinors = validationErrors.filter(vr => vr.title === "NumberOfMinorsGreaterThenTotalHouseholdsServedSestion5").length > 0;
    if (noOfMinors) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).noOfMinors,
            fieldName: 'numberofMinors',
            sectionIndex: 8,
            type: 2
        });
    }


    // const { totalHouseholdsServed5thPart } = data;
    if (getFloat(totalHouseholdsServed5thPart) <= 0) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).totalHouseholdsServed5thPart,
            fieldName: 'totalHouseholdsServed5thPart',
            sectionIndex: 5
        });
    }

    const { totalHouseholdsServed, Total } = data;
    if (totalHouseholdsServed !== (Total)) {
        errors.push({
            msg: Table2FarmworkerMessages({ year: reportYear }).totalHouseholdsServedEqualTotal,
            fieldName: 'sec4total',
            sectionIndex: 5
        });
    }

    return errors;
}

export default Table2FarmworkerValidations;
