import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

//Import helpers
import { elderlyConstants } from '../../helpers/appConstants';
import { history, getUserId, getUserOrgId, formatDate } from '../../helpers';
import { FunderConstants } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import DatePicker from '../../components/DatePicker/DatePicker';
import SetAside from './components/SetAside';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import TextArea from '../../components/TextArea/TextArea';
import NumberField from '../../components/NumberField/NumberField';
import AlertDialog from '../../components/AlertDialog/AlertDialog';

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";

// Import Actions
import { projectsActions } from '../../actions/projects';

/** Default datas */
const setAsideRowData = {
    _id: '',
    site_id: 0,
    setAsideType_id: 0,
    unitCount: '',
    firstEffectiveYear: '',
    lastEffectiveYear: '',
    bedroom_id: 0,
    elderlyType_id: 0
};

const pjtOptions = [
    "None",
    "Elderly Project",
    "ARRA Project"
];
/**
 * FundersEdit comtainer
 * @class FundersEdit
 * @extends {Component}
 */
class FundersEdit extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            arraType_id: false,
            expirationDate: '',
            primary_contact: '',
            project_type: [],
            subsidy_OM_AwardsText: '',
            notes: '',
            config: {
                projectType: [],
                setAsideType: [],
                usersList: [],
                bedrooms: []
            },
            reports: [],
            headers: [],
            setAsideTypes: [],
            claimedSite: 0,
            user_id_FunderStaff: props.id ? 0 : getUserId(),
            org_id: props.id ? 0 : getUserOrgId(),
            isConfigByBedRoom: false,
            isElderly: false,
            elderlyType: 0,
            isClaimed: false,
            isConfigByBedRoomNHTF: false,
            isConfigByBedRoomPSH: false,
            isAfterPHA: false,
            claimTrue: false,
            isLIHPercentageChanged: false
        }

        this.userOrgId = getUserOrgId();
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        const isElderly = this.state.setAsideTypes.filter(st => st.setAsideType_id === 57 && st.category_id === 2).length >= 1;
        if (name === 'project_type' && value === 1 && !isElderly) {
            this.setState({
                [name]: value,
                setAsideTypes: [
                    ...this.state.setAsideTypes,
                    {
                        ...setAsideRowData,
                        firstEffectiveYear: new Date().getFullYear() - 1,
                        lastEffectiveYear: new Date().getFullYear() + 38,
                        setAsideType_id: 57,
                        site_id: this.state.claimedSite,
                        category_id: 2,
                        unitCount: this.state.totalUnits,
                        _id: Math.max(...this.state.setAsideTypes.map(elt => elt._id)) + 1
                    }
                ]
            });

        } else {
            this.setState({ [name]: value });
        }
    };

    /**
     * componentDidMount
     * 
     * [1]  -   Get Funder settings configuraton data
     * [2]  -   If The funder has claimed the project, then ge tbasci data, else show claim popup
     */
    componentDidMount() {
        const { id, orgId } = this.props;

        console.log("====== FUNDER EDIT PAGE =====", this.props)
        // [1]
        this.props.getFunderSettingsConfigDatas(id);
        // [2]
        if (id) {
            this.props.getBasicFunderSettings(id, orgId);
        } else {
            this.setState({ claimTrue: true })
        }

        if (this.props.projectId) {
            this.props.getProjectByTable1List(this.props.projectId);
        }
    }
    /**
     * onPopupOk
     * 
     * [1]  -   If Claim is clicked, then claim the project and navigate to fundersEdid
     */
    onPopupOk = () => {
        const { projectId } = this.props;
        this.setState({ claimTrue: false })
        // [1]
        this.props.findFunderHistory(projectId, this.userOrgId, (data) => {
            if (data.data) {
                history.replace({
                    pathname: `/projects/${projectId}/funders/${data.data}`,
                    state: {
                        page: 'funderEdit',
                        pageData: {
                            funder_id: data.data
                        },
                    }
                });
            }
        });
    }

    /**
     * Component will receive props
     * @param {*} nextprops 
     * 
     * [1]  -   Set basic data to state
     */
    UNSAFE_componentWillReceiveProps(nextprops) {
        const { funderSettings } = nextprops
        // [1];
        console.log("this.state", this.state, nextprops.funderSettings)
        if (funderSettings.basic) {
            if (funderSettings.basic._id !== this.state._id && this.props.id) {
                const { primary_contact } = funderSettings.basic;
                let pc = primary_contact || {};
                let name = `${pc.firstName}, ${pc.lastName}`;
                const setAsideTypes = global._.sortBy(funderSettings.basic.setAsideTypes, 'setAsideType_id');
                this.setState({
                    ...this.state,
                    ...funderSettings.basic,
                    reports: global._.sortBy(funderSettings.basic.reports, 'firstReportingYear').reverse(),
                    setAsideTypes,
                    isClaimed: funderSettings.basic.expirationDate !== null,
                    project_type: funderSettings.basic.project_type || funderSettings.basic.project_type === 0 ? this.converProjectTypeToArr(funderSettings.basic.project_type) : [],
                    primary_contact: !pc.isActive ? `*${name}` : name
                }, () => {
                    console.log("this.state", this.state)
                })
            }
        }
        if (funderSettings.config.usersList.length > 0) {
            this.setState({
                config: funderSettings.config
            });
        }
    }
    /**
     * A method to call whrn form got submit
     *
     * @memberof FundersEdit
     * 
     * [1]  -   convert project type array to int
     * [2]  -   update funder project duedate changes
     * [3]  -   If the projects is claimed already, the update the funderSettings
     * [4]  -   create new funder
     */
    handleSubmit = () => {
        let {
            user_id_FunderStaff, expirationDate, subsidy_OM_AwardsText,
            notes, project_type, arraType_id, setAsideTypes, reports
        } = this.state;
        let typeofProject = 0;
        // [1]
        if (project_type.indexOf(pjtOptions[1]) > -1 && project_type.indexOf(pjtOptions[2]) > -1) {
            typeofProject = 3;
        } else {
            typeofProject = pjtOptions.indexOf(project_type[0]);
        }
        // [2]
        const dueDates = [];
       
        reports.forEach(rp => {
            rp.cnvData.forEach(rcd => {
                rcd.forEach((rcdD) => {
                    if (rcdD.dbStatus !== rcdD.status) {
                        // rcdD.tableIndex = rcdD.tableIndex == 5 ? 6 : rcdD.tableIndex;
                        dueDates.push(rcdD);
                    }
                })
            });
        })
        const formData = {
            user_id_FunderStaff, expirationDate, subsidy_OM_AwardsText,
            notes, project_type: typeofProject, arraType_id, setAsideTypes, dueDates
        };
        // [3]
        if (this.props.id) {
            this.props.updateFunderSettings({
                ...formData,
                project_id: this.props.projectId,
                reports: reports.map((rp) => { return { site_id: rp._id, firstReportingYear: rp.firstReportingYear, currentReportingYear: rp.currentReportingYear } })
            }, this.state._id, this.state.isLIHPercentageChanged && this.props.projects.projectTable1List);
            history.goBack();
        } else {
            // [4]
            this.props.createFunderSettings({ ...formData, project_id: this.props.projectId });
            history.goBack();
        }

    }
    /**
     * Convert project type to array
     * @param {*} value 
     * @returns 
     */
    converProjectTypeToArr(value) {
        if (value === 3) {
            return [pjtOptions[1], pjtOptions[2]];
        } else {
            return [pjtOptions[value]];
        }
    }
    /**
     * Get options
     * @returns 
     */
    getOptions() {
        let options = [pjtOptions[0], pjtOptions[1]];
        // if wshfc
        if (this.state.org_id === 1) {
            options = [...options, pjtOptions[2]];
        }
        const menuEle = options.map((opt, index) => {
            return (
                <MenuItem key={`pjt_type_${index}`} value={opt}>
                    <Checkbox checked={this.state.project_type.indexOf(opt) > -1} />
                    <ListItemText primary={opt} />
                </MenuItem>
            );
        });
        return menuEle;
    }
    /**
     * Render due dates
     * @param {*} colData 
     * @param {*} yearIndex 
     * @param {*} tableIndex 
     * @param {*} siteIndex 
     * @returns 
     */
    renderDueDate = (colData, yearIndex, tableIndex, siteIndex) => {
        // no need to edit for previous years
        if (colData.date === null) return <TableCell key={yearIndex} style={{ textAlign: 'center' }}>{"N/A"}</TableCell>;
        return (
            <TableCell style={{ textAlign: 'center' }} key={yearIndex}>
                <Checkbox
                    checked={colData.status}
                    onChange={(value) => {
                        const updatedReports = [...this.state.reports];
                        const data = updatedReports[siteIndex].cnvData[tableIndex][yearIndex];
                        updatedReports[siteIndex].cnvData[tableIndex][yearIndex] = { ...data, status: !data.status };
                        this.setState({
                            reports: updatedReports
                        });
                    }}
                    value={`${colData.status}`}
                    color="primary"
                    disabled={colData.disabled || this.props.isReadOly}
                />
                {formatDate(colData.date)}
            </TableCell>
        );
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, pageAccess, isReadOly } = this.props;
        const { arraType_id, expirationDate, project_type, subsidy_OM_AwardsText, notes, config, reports, setAsideTypes, setAsideBy, claimedSite, user_id_FunderStaff, org_id, primary_contact, isConfigByBedRoom, isElderly, elderlyType, isClaimed, isConfigByBedRoomNHTF, isConfigByBedRoomPSH, isAfterPHA } = this.state;
        return (
            <div className={`${classes.rootEdit} funderPageEdit`} >
                <ValidatorForm
                    name="funderSettingsForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} className={classes.autoCompleteTop}>
                            {
                                getUserOrgId() === org_id || this.props.id === undefined ? (
                                    <AutoComplete
                                        suggestions={config.usersList}
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                user_id_FunderStaff: selectedOption.value
                                            })
                                        }}
                                        selectedValue={{ id: user_id_FunderStaff }}
                                        disabled={isReadOly}
                                        placeholder="Primary Contact on Project"
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                ) : (
                                    <TextBox
                                        name="disabled_primaryContact"
                                        value={primary_contact}
                                        label="Primary Contact on Project"
                                        className={classes.textField}
                                        margin="dense"
                                        disabled={true}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="subsidy_OM_AwardsText"
                                value={subsidy_OM_AwardsText}
                                label="Subsidy Commitment Text"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DatePicker
                                name={"expirationDate"}
                                label="Project Expiration Date"
                                value={expirationDate}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                className="funderEditDatePicker"
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name={'project_type'}
                                // value={project_type}
                                label="Project Type:"
                                className={classes.textField}
                                margin="dense"
                                type="multiselect"
                                value={project_type.join(",")}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                                SelectProps={{
                                    MenuProps: {
                                        variant: 'menu',
                                    },
                                    multiple: true,
                                    value: project_type,
                                    renderValue: selected => project_type.join(', '),
                                    onChange: (e) => {
                                        let values = e.target.value;
                                        // remove none option is choosen, deselect all other values
                                        if (values.indexOf(pjtOptions[0]) === values.length - 1) {
                                            values = [pjtOptions[0]];
                                        }

                                        // if choose other option other than none, remove none
                                        if (values.length > 1 && values.indexOf(pjtOptions[0]) !== values.length - 1) {
                                            values = values.filter(val => val !== pjtOptions[0]);
                                        }

                                        // if no options selected, set to none
                                        if (values.length === 0) {
                                            values.push(pjtOptions[0])
                                        }

                                        values = values.filter(v => v !== undefined);
                                        this.setState({
                                            project_type: values
                                        });
                                    }
                                }}
                            >
                                {this.getOptions()}
                            </TextBox>
                        </Grid>
                        {
                            (org_id === 1 && project_type.indexOf(pjtOptions[2]) > -1) && <Grid item xs={12} sm={4}>
                                <DropDown
                                    name={'arraType_id'}
                                    value={arraType_id}
                                    label="ARRA:"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    disabled={isReadOly}
                                >
                                    {
                                        FunderConstants.arratype.map((item) => {
                                            return <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>
                                        })
                                    }
                                </DropDown>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12}>
                            <TextArea
                                name={"notes"}
                                value={notes}
                                label="Note"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isReadOly}
                                multiline={true}
                            />
                        </Grid>
                        {isClaimed &&

                            < Grid item xs={12} sm={12}>
                                {reports !== undefined && reports.length > 0 &&
                                    <React.Fragment>
                                        <h4 className="section_title">
                                            Reporting Forms
                                        </h4>

                                        {reports.map((item, rIndex) => (
                                            <div key={'site' + item._id} className={"m-t-b-15"}>
                                                <Grid container spacing={3} >
                                                    <Grid item xs={3} sm={3}>
                                                        <p>Site : {item.site}</p>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3}>
                                                        <p>Site Key : {item._id}</p>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3}>
                                                        <p>Contract ID/OID : {item.contractId}</p>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3}>
                                                        <NumberField
                                                            className={`${classes.textField} funderEditFirstReport`}
                                                            name={`firstReportingYear_${item._id}`}
                                                            value={item.firstReportingYear}
                                                            label="First Reporting Year"
                                                            handleChange={(name, value) => {
                                                                reports[rIndex].firstReportingYear = value;
                                                                this.setState({
                                                                    reports: reports
                                                                });
                                                            }}
                                                            margin="dense"
                                                            validators={['required', 'minNumber:1970', 'maxNumber:5000']}
                                                            errorMessages={['This field is required', 'Not less than 1970', 'Not greater than 5000']}
                                                            disabled={isReadOly}
                                                            allowNegative={false}
                                                            prefix={''}
                                                            thousandSeparator={false}
                                                            decimalScale={0}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Divider light={true} />

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {item.headers.map((header, hi) => (
                                                                <TableCell key={hi} style={hi !== 0 ? { textAlign: 'center' } : {}}>{header}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {item.cnvData.map((colDatas, index) => {
                                                            console.log(" === TABLE DATA === ", 'Table ' + (index + 1))
                                                            return (
                                                                <TableRow key={'st' + index}>
                                                                    <TableCell component="th" scope="row">
                                                                        {'Table ' + (index + 1)}
                                                                    </TableCell>
                                                                    {
                                                                        colDatas.map((colData, yearIndex) => {
                                                                            return this.renderDueDate(colData, yearIndex, index, rIndex)
                                                                        })
                                                                    }
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                }
                                <SetAside
                                    org_id={org_id}
                                    config={config}
                                    isElderly={isElderly}
                                    sites={reports.map((report) => { return { value: report._id, label: report.site } })}
                                    setAsideTypes={setAsideTypes}
                                    setAsideBy={setAsideBy}
                                    isReadOly={isReadOly}
                                    isConfigByBedRoom={isConfigByBedRoom}
                                    isConfigByBedRoomNHTF={isConfigByBedRoomNHTF}
                                    isConfigByBedRoomPSH={isConfigByBedRoomPSH}
                                    isConfigByBedRoomChange={(status) => {
                                        this.setState({
                                            isConfigByBedRoom: status,
                                            setAsideTypes: setAsideTypes.map((st) => {
                                                if (st.category_id === 5) st.bedroom_id = null;
                                                return st;
                                            })
                                        });
                                    }}
                                    isConfigByBedRoomNHTFChange={(status) => {
                                        this.setState({
                                            isConfigByBedRoomNHTF: status,
                                            setAsideTypes: setAsideTypes.map((st) => {
                                                if (st.category_id === 6) st.bedroom_id = 0;
                                                return st;
                                            })
                                        });
                                    }}
                                    isConfigByBedRoomPSHChange={(status) => {
                                        this.setState({
                                            isConfigByBedRoomPSH: status,
                                            setAsideTypes: setAsideTypes.map((st) => {
                                                if (st.category_id === 7) st.bedroom_id = 0;
                                                return st;
                                            })
                                        });
                                    }}
                                    claimedSite={setAsideBy === 0 ? 0 : claimedSite}
                                    onSetAsideBy={(setAsideByValue) => {
                                        let updatedsetAsideTypes = setAsideTypes;
                                        if (setAsideByValue === 0) {
                                            // set to projects set site_id to null
                                            updatedsetAsideTypes = setAsideTypes.map((st) => {
                                                st.site_id = null;
                                                return st
                                            });
                                        } else {
                                            // set to first site of the project
                                            if (reports[0] !== undefined) {
                                                const _id = reports[0]._id;
                                                updatedsetAsideTypes = setAsideTypes.map((st) => {
                                                    st.site_id = _id;
                                                    return st
                                                });
                                            }
                                        }
                                        this.setState({ setAsideTypes: updatedsetAsideTypes, setAsideBy: setAsideByValue });
                                    }}
                                    onSiteChange={(claimedSite) => {
                                        this.setState({ claimedSite });
                                    }}
                                    elderlyType={elderlyType}
                                    elderlyConstants={elderlyConstants}
                                    elderlyTypeOnChange={(type) => {
                                        const updatedSetAsideTypes = setAsideTypes.map((st) => {
                                            if (st.category_id === 2 && st.setAsideType_id === 57) {
                                                st.elderlyType_id = type;
                                            }
                                            return st;
                                        });
                                        this.setState({ setAsideTypes: updatedSetAsideTypes, elderlyType: type });
                                    }}
                                    onChange={(name, index, value) => {
                                        // const st = setAsideTypes[index];
                                        setAsideTypes[index][name] = value;
                                        if (setAsideTypes[index].category_id && setAsideTypes[index].category_id == 1){
                                            this.setState({isLIHPercentageChanged: true})
                                        }
                                        // console.log("------ OnChange SetAside Types ------", this.state.isLIHPercentageChanged)
                                        this.setState({ setAsideTypes })
                                    }}
                                    onAdd={(siteId, category_id) => {
                                        // console.log("---------- Before Adding New Set Aside Type -------",this.state.setAsideTypes)

                                        // if (category_id == 6) {
                                        //     setAsideRowData.setAsideType_id = 101
                                        // }

                                        // if (category_id == 7) {
                                        //     setAsideRowData.setAsideType_id = 70
                                        // }

                                        if (category_id == 1 ) {
                                            this.setState({isLIHPercentageChanged: true})
                                        }

                                        const newSetAsideTypes = [
                                            ...setAsideTypes,
                                            {
                                                ...setAsideRowData,
                                                site_id: siteId,
                                                category_id,
                                                isAfterPHA: isAfterPHA,
                                                _id: Math.max(...this.state.setAsideTypes.map(elt => elt._id)) + 1
                                            }
                                        ];
                                        // console.log("----------------------- New Set Aside Type ---------- ",this.state.isLIHPercentageChanged)
                                        this.setState({ "setAsideTypes": newSetAsideTypes });
                                    }}
                                    onDelete={(index) => {

                                        if (setAsideTypes[index].category_id == 1) {
                                            this.setState({isLIHPercentageChanged: true})
                                        }

                                        setAsideTypes.splice(index, 1);


                                        this.setState({ setAsideTypes });

                                        // if (setAsideTypes[index].category_id == 1) {
                                        //     this.setState({isLIHPercentageChanged: true})
                                        // }

                                        // console.log("------- ONDELETE ----------",setAsideTypes[index-1],index)
            
                                    }}
                                    isAfterPHA={isAfterPHA}
                                    isAfterPHAChange={(value) => {
                                        const updatedSetAsideTypes = setAsideTypes.map((st) => {
                                            if (st.category_id === 5) {
                                                st.isAfterPHA = value;
                                            }
                                            return st;
                                        });
                                        this.setState({ setAsideTypes: updatedSetAsideTypes, isAfterPHA: value });
                                    }}
                                />
                            </Grid>
                        }
                        {
                            pageAccess.indexOf('SAVE') > -1 ?
                                (<SaveCancel
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }}
                                />)
                                : null
                        }
                        {
                            <AlertDialog
                                open={this.state.claimTrue}
                                title={'Warning:'}
                                onClose={() => {
                                    this.setState({ claimTrue: false })
                                    history.goBack();
                                }}
                                onSave={() => { this.onPopupOk() }}
                                saveText={'Claim'}
                                cancelText={'Cancel'}
                            >
                                Claiming this Project will also claim all Sites and Buildings under it.  Verify all appropriate Sites and Buildings are claimed, as this will affect required reporting for this Project.  If you do not want to claim this Project, click Cancel.
                            </AlertDialog>
                        }
                    </Grid>
                </ValidatorForm>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
FundersEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired,
    isSubPage: PropTypes.bool
}

FundersEdit.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: false
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ projects }, ownProps) => {
    return {
        funderSettings: projects.funderSettings,
        projects: projects
    }
};
/**
 * Map dispatch to props
* @param {*} dispatch
*/
const mapDispatchToProps = (dispatch) => {
    return {
        getFunderSettingsConfigDatas: (id) => dispatch(projectsActions.getFunderSettingsConfigDatas(id)),
        getBasicFunderSettings: (funderId, orgId) => dispatch(projectsActions.getBasicFunderSettings(funderId, orgId)),
        updateFunderSettings: (formData, funderId, isLIHPercentageChanged) => dispatch(projectsActions.updateFunderSettings(formData, funderId, isLIHPercentageChanged)),
        createFunderSettings: (formData) => dispatch(projectsActions.createFunderSettings(formData)),
        findFunderHistory: (projectId, org_id, callBack) => dispatch(projectsActions.findFunderHistory(projectId, org_id, callBack)),
        getProjectByTable1List: (projectId) => dispatch(projectsActions.getProjectByTable1List(projectId))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(FundersEdit));

