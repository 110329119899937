//import helper
import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from '../helpers';
import momentTZ from 'moment-timezone';

export const exploreService = {
    getCustomReportsMenu,
    updateData,
    getAll,
    delete: _delete,
    getById,
    downloadExploreReport,
    downloadFunderReport,
    downloadTenantReport,
    previewExploreReport,
    saveTemplateData,
    saveCustomTemplateData,
    downloadTable2Report,
    downloadTable5Report
};

/**
 * Get explore data dropdown fields while adding and updating 
 */
function getCustomReportsMenu(reportYear) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/explore/get-fields/${reportYear}`, requestOptions).then(handleResponse);
}

/**
 * Update a report in explore data
 * 
 * @param {*} reportData - updated data
 */
function updateData(reportData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(reportData)
    };
    return fetch(`${globalVariable.apiURL}/explore`, requestOptions).then(handleResponse);
}
/**
 * Save Template in explore data
 * 
 * @param {*} reportData - updated data
 */
function saveTemplateData(reportData) {
    let timeZoneOffSet = new Date().getTimezoneOffset()
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ userRole : reportData.userRole,userId :reportData.userId ,reportData: reportData, timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) })
    };
    return fetch(`${globalVariable.ms_apiURL}/explore/save-template`, requestOptions).then(handleDownloadResponse);
}
/**
 * Save Template in explore data
 * 
 * @param {*} reportData - updated data
 */
function saveCustomTemplateData(reportData) {
    let timeZoneOffSet = new Date().getTimezoneOffset()
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ reportData: reportData, timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) })
    };
    return fetch(`${globalVariable.ms_apiURL}/explore/save-custom-template?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleResponse);
}

/**
 * Get explore data listing values
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, exploreType) {
    let data = {
        fields: sFields,
        values: sValues,
        timeZone: momentTZ.tz.guess(true),
        reportType:"Multifamily"
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/explore/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&type=${exploreType}`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * @param {*} id 
 */
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/explore/${id}`, requestOptions).then(handleResponse);
}

/**
 * A method to get the explore details
 *
 * @param {*} id
 * @returns
 */
function getById(_id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/explore/${_id}`, requestOptions).then(handleResponse);
}

/**
 * Download explore reports
 * 
 * @param {*} id - explore id
 * @param {*} reportData - explore data
 */
function downloadExploreReport(id, reportData) {
    let timeZoneOffSet = new Date().getTimezoneOffset()
    if (!reportData) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) }),
            responseType: 'arraybuffer'
        };
        return fetch(`${globalVariable.ms_apiURL}/explore/export/${id}`, requestOptions).then(handleDownloadResponse);
    } else {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ reportData: reportData, timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) }),
            responseType: 'arraybuffer'
        };
        return fetch(`${globalVariable.ms_apiURL}/explore/export/`, requestOptions).then(handleDownloadResponse);
    }
}

/**
 * Preview explore reports
 * 
 * @param {*} data - data contains selectedColumns, filterAtributes
 */
function previewExploreReport(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };
    return fetch(`${globalVariable.ms_apiURL}/explore/preview?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleResponse);
}

/**
 * Download funder reports from dashboard
 * 
 * @param {*} reportData - funder excel data
 */
function downloadFunderReport(reportData, uniqId, selectedReportName, reportName, org_id) {
    console.log('reportData######################',reportData)
    if (!reportData) {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            responseType: 'arraybuffer',
            body: JSON.stringify({ uniqId: uniqId, selectedReportName: `${selectedReportName}_${new Date().getTime()}`, org_id, reportFilter: reportData.reportFilter })
        };
        return fetch(`${globalVariable.ms_apiURL}/funderexports/${reportData.ReportId}/${reportData.year}`, requestOptions).then(handleDownloadResponse);
    } else {
        if(reportData.ReportId===29){
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ uniqId: uniqId, selectedReportName: reportName, year: reportData.year, org_id, reportFilter: reportData.reportFilter, projectid_ho:reportData.projectid,contractid:reportData.contractid,contractsid:reportData.contractids,projectsid:reportData.projectids}),
            responseType: 'arraybuffer'
        };
        return fetch(`${globalVariable.ms_apiURL}/funderexports/${reportData.ReportId}/${reportData.year}`, requestOptions).then(handleDownloadResponse);
    }else{
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({ uniqId: uniqId, selectedReportName: reportName, year: reportData.year, org_id, reportFilter: reportData.reportFilter }),
            responseType: 'arraybuffer'
        };
        return fetch(`${globalVariable.ms_apiURL}/funderexports/${reportData.ReportId}/${reportData.year}`, requestOptions).then(handleDownloadResponse); 
    }
    }
}

/**
 *Tenant report from dashboard and Table 1 report 
 *
 * @param {*} project - project_id
 * @param {*} site - site_id
 * @param {*} year - selected year
 * @param {*} fieldFilters - fields to be added in the report
 * @returns
 */
function downloadTenantReport(project, site, year, fieldFilters) {
    //To get user timezone offset value
    let timeZoneOffSet = new Date().getTimezoneOffset()
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ fieldFilters: fieldFilters, timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) }),
        responseType: 'arraybuffer'
    };
    return fetch(`${globalVariable.ms_apiURL}/tenantexports/${project}/${site}/${year}?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleDownloadResponse);
}

/**
 * Table 2 Report
 * @param {*} reportYear_id 
 * @param {*} year 
 */
function downloadTable2Report(project, site, year) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${globalVariable.ms_apiURL}/table2Reports/${project}/${site}/${year}`, requestOptions).then(handleDownloadResponse);
}

function downloadTable5Report(project, site, year, reportId, fieldFilters) {
    let timeZoneOffSet = new Date().getTimezoneOffset()
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ fieldFilters: fieldFilters, timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) }),
        responseType: 'arraybuffer'
    };
    return fetch(`${globalVariable.ms_apiURL}/table5Reports/${project}/${site}/${year}/${reportId}?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleDownloadResponse);
}

