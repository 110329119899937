
import { bulkDataConstants } from '../constants/bulkData';
import {bulkDataService} from '../services/bulkData';
import { saveAs } from 'file-saver';
import { alertActions } from './';

export const bulkDataActions = {
   saveBulkData,
   getBulkDataLogs,
   delete: _delete,
   downloadBulkData
}

function saveBulkData (reportData, callBack = () => {}) {
    return dispatch => {
        dispatch(request());

        bulkDataService.saveBulkData(reportData)
        .then(
            bulkData => {
                dispatch(success(bulkData))
                dispatch(alertActions.success('The data export has been created'));
                callBack();
            },
            error => {
                dispatch(failure(error.toString()))
                dispatch(alertActions.error(error.toString()));
            }
        )
    };

    function request() { return {type: bulkDataConstants.SAVEBULKDATA_REQUEST} }
    function success(bulkData) { return {type: bulkDataConstants.SAVEBULKDATA_SUCCESS, bulkData} }
    function failure(error) { return {type: bulkDataConstants.SAVEBULKDATA_FAILURE , error} }
} 


function getBulkDataLogs (skip, limit, orderBy, order, sFields, sValues) {
        return dispatch => {
            dispatch(request());

            bulkDataService.getBulkDataLogs(skip, limit, orderBy, order, sFields, sValues)
            .then(
                bulkData => {
                    console.log("===== GET BULK DATA FROM SERVICE ===",bulkData)
                    dispatch(success(bulkData))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            )
        }
    function request() { return {type: bulkDataConstants.GETBULKDATALOGS_REQUEST}}
    function success(bulkData) { return {type: bulkDataConstants.GETBULKDATALOGS_SUCCESS,bulkData}}
    function failure(error) { return {type: bulkDataConstants.GETBULKDATALOGS_FAILURE,error}}
}


function _delete (id) {
    return dispatch => {
        dispatch(request(id));

        bulkDataService.delete(id)
            .then(
                explore => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted successfully'));
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: bulkDataConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: bulkDataConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: bulkDataConstants.DELETE_FAILURE, id, error } }
}


function downloadBulkData (id,reportName) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait... Download is Inprogress'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)

        bulkDataService.downloadBulkData(id)
            .then(res => res.blob())
            .then(
                blob => {
                    // reportName = (/.xlsx/g).test(reportName) ? reportName.replace('.xlsx', '') : reportName;
                    saveAs(blob, `${reportName}.zip`);
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
    function request() { return { type: bulkDataConstants.DOWNLOAD_REQUEST } }
    function success() { return { type: bulkDataConstants.DOWNLOAD_SUCCESS } }
    function failure(error) { return { type: bulkDataConstants.DOWNLOAD_FAILURE, error } }
}