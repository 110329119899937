import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Import Redux Actions and Helper Variable
import { sitesActions } from '../../actions/sites';
import { projectsActions } from '../../actions/projects';
import { archivesActions } from '../../actions/archives';
import { confActions } from '../../actions/configuration';
import { searchActions } from '../../actions/search';
import { helpActions } from '../../actions';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserOrgId, getUserRole, getUserType, filterSearchFields } from '../../helpers';
import { globalVariable, history } from '../../helpers';

/**
 * SiteRouter Component
 * @class SiteRouter
 * @extends {Component}
 * 
 * for table county field
 */
class MonitoringField extends Component {
    render() {
        const { data } = this.props;
        const { monitoring } = data;
        const clsName = monitoring === 'Claim' || monitoring === 'Monitoring' ? 'linkCell' : '';
        return (
            <span
                className={clsName}
                onClick={() => {
                    this.props.handleChange("monitoring", { id: data._id, monitoring: data.monitoring });
                }}
            >
                {monitoring}
            </span>
        )
    }
}
/** Default Props */
MonitoringField.defaultProps = {
    data: {},
    path: ""
}

const componentMap = {
    "monitoring": MonitoringField
}
/**
 * SitesList Component
 * @class SitesList
 * @extends {Component}
 */
class SitesList extends Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
            deleteSite: false,
            deleteItemId: null,
            openHelp: false
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
        this.userType = getUserType();
    }

    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { sites, projectId, location: { pathname} } = this.props;
        const { page } = sites;
        this.props.getHelp();
        let query = { }
        if(pathname.includes('archives')){
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        // retain old search values
        if (page.skip) {
            this.props.getSites(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, projectId, query);
        } else {
            this.props.getSites(1, globalVariable.tableRowSize, 'name', 'asc', '', '', projectId, query);
        }
        setTimeout(() => {
            this.props.getFieldFilter(this.props.page === 'projects' ? 'innerSites' : 'sites');
        }, 100);
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        const { projectId } = this.props;
        if (projectId) {
            this.props.getById(projectId);
        }
    }
    /**
     * Component Did Update Event
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { sites, projectId, searchData: { sFields, sValues }, location: { pathname}  } = this.props;
        let query = { }
        if(pathname.includes('archives')){
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        this.props.getSites(skip, limit, sites.page.orderBy, sites.page.order, sFields, sValues, projectId, query);
    };
    /**
     * Handle Sort
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { sites, projectId, searchData: { sFields, sValues }, location: { pathname}  } = this.props;
        let query = { }
        if(pathname.includes('archives')){
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        this.props.getSites(sites.page.skip, sites.page.limit, orderBy, order, sFields, sValues, projectId, query);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { sites, projectId, searchData, isSubPage, location: { pathname}  } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        let query = { }
        if(pathname.includes('archives')){
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        this.props.searchActions({ sFields, sValues }, isSubPage);
        this.props.getSites(1, globalVariable.tableRowSize, sites.page.orderBy, sites.page.order, sFields, sValues, projectId, query);
    };
    /**
     * Handle Field Filter Popup On Close Event
     * 
     * [1]  -   reset to current table column values if user not save the changes
     * [2]  -   close the popup
     */
    handleFieldFilterOnClose = () => {
        // [1]
        this.props.changeFieldFilter(this.props.page === 'projects' ? 'innerSites' :'sites', this.props.sites.page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(this.props.page === 'projects' ? 'innerSites' :'sites', updatedColumns);
        } else {
            this.props.changeFieldFilter(this.props.page === 'projects' ? 'innerSites' :'sites', [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Field Filter On Save Event
     * 
     * [1]  -   on api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter(this.props.page === 'projects' ? 'innerSites' :'sites', this.props.visibleColumns, () => {
            // [1]
            this.setState({ fieldFilter: false });
            const { sites, projectId, searchData: { sFields, sValues }, location: { pathname}  } = this.props;
            let query = { }
            if(pathname.includes('archives')){
                query = {
                    isDelete: true
                }
            } else {
                query = {
                    isDelete: false
                }
            }
            this.props.getSites(sites.page.skip, sites.page.limit, sites.page.orderBy, sites.page.order, sFields, sValues, projectId, query);
        });
    }
    /**
     * Handle Table Delete Action
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {
        const { sites, projectId, searchData, isSubPage, location: { pathname}  } = this.props;
        let { sFields, sValues } = searchData;
        this.setState({ deleteSite: true });
        let siteData = sites&&sites.data&&sites.data.filter(site=>site._id===id);
        this.props.deleteSite(id,siteData)

        setTimeout(()=>{
            this.props.searchActions({ sFields, sValues }, isSubPage);
            this.props.getSites(1, globalVariable.tableRowSize, sites.page.orderBy, sites.page.order, sFields, sValues, projectId, {isDelete:false});
        },1000)
        
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        const { match } = this.props;
        switch (field) {
            case "name":
                history.push({
                    pathname: `${match.url}/${id}`,
                    state: {
                        page: 'siteEdit',
                        pageData: {
                            site_id: id,
                            isArchived: match.path.includes('archives')
                        }
                    }
                });
                break;
            case "monitoring":
                if (item.monitoring === 'Claim' || item.monitoring === 'Monitoring' || item.monitoring === 'Pending' || item.monitoring === 'Expired') {
                    history.push({
                        pathname: `/projects/${item.project_id}/funders`,
                        // state: {
                        //     page: 'siteEdit',
                        //     pageData: {
                        //         site_id: id,
                        //         isArchived: match.path.includes('archives')
                        //     }
                        // }
                    });
                }
                break;
            case "propertyManager":
                const pmId = item.pmData || {};
                if (pmId.user_id) {
                    history.push({
                        pathname: `/users/${pmId.user_id}`,
                        state: {
                            page: 'userEdit',
                            pageData: {
                                user_id: parseInt(pmId.user_id, 10)
                            }
                        }
                    });
                }
                break;
            case "onsiteManager":
                const omId = item.omData || {};
                if (omId.user_id) {
                    history.push({
                        pathname: `/users/${omId.user_id}`,
                        state: {
                            page: 'userEdit',
                            pageData: {
                                user_id: parseInt(omId.user_id, 10)
                            }
                        }
                    });
                }
                break;
            case "projectManagerOrg":
                const pmOrgId = item.pmData || {};
                if (pmOrgId.org_id) {
                    history.push({
                        pathname: '/organizations/' + pmOrgId.org_id,
                        state: {
                            page: 'organizationEdit',
                            pageData: {
                                org_id: parseInt(pmOrgId.org_id, 10)
                            }
                        }
                    });
                }
                break;
            case "onsiteManagerCO":
                const omOrgId = item.omData || {};
                if (omOrgId.org_id) {
                    history.push({
                        pathname: '/organizations/' + omOrgId.org_id,
                        state: {
                            page: 'organizationEdit',
                            pageData: {
                                org_id: parseInt(omOrgId.org_id, 10)
                            }
                        }
                    });
                }
                break;
            default: break;
        }
    }
    /**
     * Handle Add Action
     */
    handleAddActionClick = () => {
        const { match } = this.props;
        history.push({
            pathname: `${match.url}/new`,
            state: {
                page: 'siteNew',
                pageData: {}
            }
        });
    };
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { sites, projectId, isSubPage, location: { pathname}  } = this.props;
        let query = { }
        if(pathname.includes('archives')){
            query = {
                isDelete: true
            }
        } else {
            query = {
                isDelete: false
            }
        }
        this.props.searchActions({ sFields: [], sValues: [] }, isSubPage);
        this.props.getSites(sites.page.skip, sites.page.limit, sites.page.orderBy, sites.page.order, [], [], projectId, query);
    };
    /**
     * Handle Field Filter Action Event
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter(this.props.page === 'projects' ? 'innerSites' :'sites', this.props.sites.page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({openHelp: true})
    }
    /**
     * A method call back when row report icon clicked 
     *
     * @param {*} item contains row data
     * @memberof SitesList
     */
    handleViewReportAction = (item) => {
        const { location: { pathname }  } = this.props;
        const { project_id, reportYear_id, lastSubmittedYearTable1, currentYear } = item;
        if (reportYear_id) {
            history.push({
                pathname: `${pathname.includes('archives') ? '/archives' : '/projects'}/${project_id}/reports/${reportYear_id}/${currentYear}`,
                state: {
                    page: 'reportView',
                    site_id: item._id,
                    pageData: {
                        project_id: parseInt(project_id, 10),
                        reportYear: isNaN(lastSubmittedYearTable1) ? currentYear : lastSubmittedYearTable1 ,
                        site_id: item._id,
                        isArchived: pathname.includes('archives')
                    },
                    pageTitle: {
                        title: `Report - ${item.name} - ${currentYear} | ${item.projectName}`,
                        subTitle: ''
                    }
                }
            });
        } else {
            history.push(`/projects/${project_id}/reports`);
        }
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        const { match } = this.props;
        switch (name) {
            case "monitoring":
                if (data.monitoring === 'Monitoring' || data.monitoring === 'Claim') {
                    history.push({
                        pathname: `${match.url}/${data.id}`,
                        state: {
                            page: 'siteEdit',
                            pageData: {
                                site_id: data.id
                            }
                        }
                    });
                }
                break;
            default:
        }
    }
    /**
     * Bind Html to DOM 
     */
    render() {
        const { fieldFilter, openHelp } = this.state;
        const { userRole, userOrgId } = this;
        let { sites, columnsMap, visibleColumns, title, needPadding,
            location: { pathname }, pageAccess, isSubPage, helpData,
            classes, searchData: { sFields, sValues } } = this.props;
        const fieldFilters = sites.page ? sites.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        pageAccess = [...pageAccess, 'HELP']
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title} 
                isSubPage={isSubPage}
                needPadding={needPadding}
                handleAddActionClick={this.handleAddActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
            >
                {sites.data &&
                    <TableComponent
                        page={sites.page.skip}
                        rowsPerPage={sites.page.limit}
                        count={sites.page.count}
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        actions={pageAccess}
                        userOrgId={userOrgId}
                        userRole={userRole}
                        links={['name', "monitoring", "propertyManager", "onsiteManager", "projectManagerOrg", "onsiteManagerCO"]}
                        // isLoading={sites.isLoading}
                        order={sites.page.order}
                        highlightField={['name']}
                        orderBy={sites.page.orderBy}
                        data={sites.data}
                        onChangePageTable={this.onChangePageTable}
                        onSortRequest={this.onSortRequest}
                        // removedSortingRow={['Last Year Submitted']}
                        onInputChangeRequest={this.onInputChangeRequest}
                        handleTableDeleteAction={this.handleTableDeleteAction}
                        resetSearchContent={this.props.location.hash === "#clear"}
                        paginationVisible={true}
                        disablePagination={sites.page.lastPage}
                        sFields={filterSearchFields(sFields)}
                        sValues={sValues}
                        handleNavigateAction={this.handleNavigateAction}
                        noDataErrorMessage={sites.isLoading ? "" : "No Records Found"}
                        handleViewReportAction={this.handleViewReportAction}
                        moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                        prevNext={false}
                        componentMap={componentMap}
                        fieldComponentOnChange={this.fieldComponentOnChange}
                        showLoading={isSubPage}
                    />

                }
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                { openHelp && <HelpFloater 
                    handleCloseFloater={() => this.setState({openHelp: false})}
                    {...helpData}
                    title="Sites"
                />}
            </ContentWrapper>

        );
    }
}
/**
 * Bind Component Property Types
 */
SitesList.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.any.isRequired,
    project_id: PropTypes.string,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
};
/** Default Props */
SitesList.defaultProps = {
    onRef: () => { },
    needPadding: false,
    title: 'All Sites',
    isSubPage: false,
    pageAccess: []
}
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 * @param {*} state 
 * @returns 
 */
const getPageState = function (page, state) {
    switch (page) {
        case 'sites': return state.sites;
        case 'projects': return state.projects.getOne.sites;
        case 'archives': return state.archives.getOne.sites;
        default: break;
    }
}
/** A method to map the actions from different pages for same containers */
const getPageAction = function (page) {
    switch (page) {
        case 'sites': return sitesActions.getAll;
        case 'projects': return projectsActions.getProjectSites;
        case 'archives': return archivesActions.getProjectSites;
        default: break;
    }
}
/** A method to map the actions from different pages for same containers */
const getByIdCall = function (page, id) {
    switch (page) {
        case 'archives': return archivesActions.getById(id);
        default: return projectsActions.getById(id);
    }
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = ownProps.page === 'projects' ? state.configuration.fieldFilter['innerSites'] || {} :  state.configuration.fieldFilter['sites'] || {};
    const sites = getPageState(ownProps.page, state);
    const searchData = ownProps.isSubPage ? state.search['sites'] || { sFields: [], sValues: [] } : state.search['sitesList'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]

    return {
        sites,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess: state.pageAccess['sitesList'] || [],
        searchData,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @param {*} ownProps 
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // You can now say this.props.createBook
        getSites: (skip, limit, orderBy, order, sField, sValue, project_id, query) => {
            const actionToCall = getPageAction(ownProps.page);
            dispatch(actionToCall(skip, limit, orderBy, order, sField, sValue, project_id, query))
        },
        // resetFilter: () => dispatch(orgActions.resetFilter()),
        // deleteSite: (id, cb) => dispatch(sitesActions.delete(id, cb)),
        deleteSite: (id,siteData, cb) => dispatch(sitesActions.delete(id,siteData,cb)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getById: (id) => dispatch(getByIdCall(ownProps.page, id)),
        searchActions: (data, isSubPage) => dispatch(searchActions.sites(data, isSubPage)),
        getHelp: () => dispatch(helpActions.getAll({_id: 8})),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(SitesList));
