import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Switch, Route } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';

//Actions
import { helpActions } from '../../actions';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

//Components
import HelpNew from './HelpNew';
/**
 * HelpView Containers
 * @class HelpView
 * @extends {Component}
 */
class HelpView extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.helpref = React.createRef();

    }
    /** Component Did mount */
    componentDidMount() {
        if (this.props._id) {
            this.props.getHelpById(this.props._id);
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { match: { params }, match, pageAccess, getHelp, title } = this.props;
        return (
            <Switch>
                <Route
                    exact
                    path={match.url}
                    render={(props) => {
                        return (
                            <HelpNew
                                pageAccess={pageAccess}
                                _id={params._id}
                                getHelp={getHelp}
                                {...props}
                                innerRef={(node) => { this.helpref = node; }}
                                title={getHelp ? getHelp.title : title}
                            />
                        )
                    }}
                />
            </Switch>
        );
    }
}
/**
 * Bind Component Property Types
 */
HelpView.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool

}
/*  default props  */
HelpView.defaultProps = {
    pageAccess: [],
    getHelp: {}
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ help, pageAccess }, ownProps) => {
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['helpedit'] || [];
    return {
        pageAccess,
        getHelp: help.getHelp.getHelpData
    }
};
/**
 * Map actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        createHelp: (formData, editorNotes, uploadFile) => dispatch(helpActions.createHelp(formData, editorNotes, uploadFile)),
        getHelpById: (_id) => dispatch(helpActions.getHelpById(_id)),
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(HelpView));

