import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const UAService = {
    getConfigDatas,
    getUADetails,
    updateUADetails,
    bulkUpload,
    updateUAInTable1,
    updateUABulkInTable1
};

/**
 * A service call to get UA config datas like bedrroms, allowance source, utility types.
 *
 * @returns
 */
function getConfigDatas() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/config/utility-allowance`, requestOptions).then(handleResponse)
}


/**
 * A service call to get the UA details for the building
 *
 * @param {*} buildingId contain building id to which the UA data need
 * @returns
 */
function getUADetails(buildingId, type = null) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/buildings/${buildingId}/ua/${type}?timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 * A service call to update UA details to the building
 *
 * @param {*} buildingId contains building id where UA to update
 * @param {*} formData contains UA datas to update
 * @returns
 */
function updateUADetails(buildingId, formData) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(`${globalVariable.apiURL}/buildings/${buildingId}/ua`, requestOptions).then(handleResponse);
}

/**
 * A service call to bulk upload UA details to the each buildings in the sites or projects
 *
 * @param {*} projectId contains proeject id of the builings
 * @param {*} siteId contains site id of the project to update for particular site, else update for all sites
 * @param {*} formData contains UA datas to update
 * @returns
 */
function bulkUpload(projectId, siteId = null, formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, projectId, siteId })
    };

    return fetch(`${globalVariable.apiURL}/ua/bulk-upload`, requestOptions).then(handleResponse);
}


/**
 * A service call to update UA details to the table 1 records
 *
 * @param {*} buildingId contains building id where UA to update in table 1
 * @returns
 */
function updateUAInTable1(buildingId, siteId = 0, projectId = 0) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.ms_apiURL}/update/ua-amounts/${projectId}/${siteId}/${buildingId}`, requestOptions).then(handleResponse);
}

/**
 * A service call to update UA details to the table 1 records for bulk UA
 *
 * @param {*} buildingId contains building id where UA to update in table 1
 * @returns
 */
function updateUABulkInTable1(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader() },
        body: JSON.stringify({ project_id: data.projectIds, unit_id: data.unitIds })
    };

    return fetch(`${globalVariable.ms_apiURL}/update/ua-amounts-bulk`, requestOptions).then(handleResponse);
}