import React from 'react';
import PropTypes from 'prop-types';

// Dropzone
import Dropzone from 'react-dropzone';

const inputStyle = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    opacity: 0,
    pointerEvents: 'none',
    width: "100%",
    height: "100%"
}
/**
 *To upload a file
 *
 * @class FileUpload
 * @extends {React.Component}
 */
class FileUpload extends React.Component {
    /**
     *render html
     *
     * @returns
     * @memberof FileUpload
     */
    render() {
        const { disabled, accept, content, name } = this.props;
        return (
            <Dropzone
                style={{ width: "100%", height: "100%" }}
                rejectStyle={{ border: "1px solid red" }}
                accept={accept}
                disabled={disabled}
                onDrop={(acceptedFiles, rejectedFiles) => this.props.handleFileUpload(acceptedFiles, rejectedFiles)}
                name={name}
                multiple={false}
            >
                {({getRootProps, getInputProps, isDragActive}) => (
                    <div className="uploadwrapper" {...getRootProps()}>
                        <input {...getInputProps()} style={inputStyle} />
                        <span 
                            className="uploadphoto" 
                            style={{
                                pointerEvents: disabled ? "none" : "auto"
                             }}
                        >
                            {content}
                        </span>
                    </div>
                )}
                
            </Dropzone>
        );
    }
}
//prop types
FileUpload.propTypes = {
    value: PropTypes.string
}
//default props
FileUpload.defaultProps = {
    accept: ["image/*"],
    multiple: false,
    disabled: false,
    content: "Upload Photo",
    style: '',
    name: 'fileupload'
}
//Export Component
export default FileUpload;