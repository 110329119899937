import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Helpers
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import DropDown from '../../../components/DropDown/DropDown';
import { MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';


/**
 * Cities Container
 * @class Cities
 * @extends {Component}
 */
class MoveOutReason extends Component {
    /**
     * Construnctor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            moveOutReasonEffectiveYear: [],
            year:null,
            yearsList:[]
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getMoveOutReason();
    }

    /**
     * Component will recive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { MoveOutReasonEffectiveYear } = nextProps;
        if(MoveOutReasonEffectiveYear && MoveOutReasonEffectiveYear.length>0){
        let currentYear = new Date().getFullYear()+1;
        this.setState({ 
            year: MoveOutReasonEffectiveYear[0].desc,
            yearsList:[`${currentYear}`,`${currentYear+1}`,`${currentYear+2}`]
        })
    }
    }

    handleChange = (name,value) =>{
        this.setState({ 
            year: value
        })
    }
    
    /**
     * handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };

    handleCancel = () =>{
        const { MoveOutReasonEffectiveYear } = this.props;
        if(MoveOutReasonEffectiveYear && MoveOutReasonEffectiveYear.length>0){
            this.setState({ 
                year: MoveOutReasonEffectiveYear[0].desc
            })
    }
}
    /**
     * Save form
     */
    saveForm() {
        this.props.updateMoveOutReason(this.state.year)
        //show loading
        this.props.showLoading()

        setTimeout( ()=>{
            this.props.getMoveOutReason();
        },200)
        //hide loading
        this.props.hideLoading()
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { year,yearsList } = this.state;
        return (
            <ValidatorForm
                name="cities"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
            <Grid item xs={6} style={{display:'inline-block'}}>
                <h3>Configure Move-out Reason Mandatory Year</h3>
                <DropDown
                label={'Select year to apply mandatory condition'}
                className={'moveOutReason'}
                value={year!==null&&year}
                handleChange={(name,value)=>this.handleChange(name,value)}
                >
                    {
                    yearsList.map((field) => {
                        return <MenuItem key={field} value={field}> 
                                    {field}
                                </MenuItem>
                                })
                     }
                </DropDown>
            </Grid>
            <Button
                        variant="contained" color='primary'
                        style={{ marginTop: '48px', marginLeft: '20px'}}
                        onClick={this.handleSubmit}
                    >
                    Save
                    </Button>
            <Button
            variant="contained"
            color='grey'
            style={{ marginTop: '48px', marginLeft: '20px'}}
            onClick={this.handleCancel}
            >
                Cancel
            </Button>
            <h4>If the report year is updated, it will get updated in occupants, table 1 and XML import and move out reason becomes mandatory with selected year</h4>
            </ValidatorForm>
        );
    }
}
/**
 * Props types
 */
MoveOutReason.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        MoveOutReasonEffectiveYear: configuration.configurations[configurationConstants.MoveOutReasonExpiryYear] || [],
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getMoveOutReason: (moveOutReason) => dispatch(confActions.getMoveOutReasonEffectiveYear('moveOutReason')),
        updateMoveOutReason: (year) => dispatch(confActions.updateMoveOutReasonEffectiveYear(year)),
        showLoading: () => dispatch(confActions.showLoading()),
        hideLoading: () => dispatch(confActions.hideLoading())
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(MoveOutReason));
