import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { CirclePicker, } from 'react-color';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import { MenuItem, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';

//Text Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import draftToHtml from 'draftjs-to-html';

//Actions
import { confActions } from '../../../../actions/configuration';

// Import Styles
import pageStyle from '../../../../assets/jss/containers/common';

// Helpers
import { activeStatusConstants, configurationConstants, history } from '../../../../helpers';
import { dateValidation, getSubChildCardHeight } from '../../../../helpers/global';

// Import Components
import SaveCancel from '../../../../components/SaveCancel/SaveCancel';
import TextBox from '../../../../components/TextBox/TextBox';
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import DateTimePicker from '../../../../components/DateTimePicker/DatePicker';
import DropDown from '../../../../components/DropDown/DropDown';
/**
 * Create new Banner text container
 * @class BannerTextList
 * @extends {Component}
 */
class BannerTextNew extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            _id: null,
            pageTitle: 'Add',
            title: '',
            editorNotes: this.convertFromHTMLString(''),
            bannerContentObj: '',
            editorHtml: '',
            setasidetypes: [],
            saved: false,
            from: null,
            to: null,
            status: true,
            validateFrom: false,
            validateTo: false,
            bannerColor: {
                hex: '#36aebe'
            },
            editorError: false,
            nextFromDate: ''
        }
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.bannerText);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { bannerText } = nextProps;

        this.setState({
            saved: true,
            setasidetypes: bannerText.filter(s => s.category_id !== 6)
        })
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        const { location: { state } } = this.props;
        if (state && state.pageData) {
            let bannerText = state.pageData.item;
            this.setState({
                pageTitle: 'Edit',
                _id: bannerText._id,
                title: bannerText.title,
                from: bannerText.from,
                to: bannerText.to,
                editorNotes: EditorState.createWithContent(convertFromRaw(JSON.parse(bannerText.bannerContentObj))),
                editorHtml: bannerText.bannerContent,
                status: bannerText.isActive,
                bannerColor: bannerText.bannerColor,
            })
        }
        ValidatorForm.addValidationRule('isDateVacant', (value) => {
            const { _id } = this.state;
            let { bannerText } = this.props;
            bannerText = bannerText.filter(elem => elem._id !== _id);
            return dateValidation(value, bannerText);
        });
        ValidatorForm.addValidationRule('inValidDate', (value) => {
            return new Date(value) instanceof Date && !isNaN(new Date(value).valueOf());
        });
        ValidatorForm.addValidationRule('minTime', (value) => {
            const { from } = this.state;
            return new Date(from).getTime() < new Date(value).getTime();
        });
    }
    /**
     * Convert form HTML string
     * @param {*} sampleMarkup 
     * @returns 
     */
    convertFromHTMLString(sampleMarkup) {
        sampleMarkup = sampleMarkup || '';
        const blocksFromHTML = convertFromHTML(sampleMarkup);
        return EditorState.createWithContent(blocksFromHTML);
    }
    /**
     * Editor State Change
     * @param {*} editorNotes 
     */
    onEditorStateChange(editorNotes) {
        if (editorNotes.getCurrentContent().getPlainText()) {
            this.setState({ editorNotes, editorError: false });
        } else {
            this.setState({ editorNotes, editorError: true })
        }
    }
    /**
     * Handle Form Submition
     */
    handleSubmit = () => {
        const { _id, title, editorNotes, from, to, status, bannerColor } = this.state;
        let contentHtml = draftToHtml(convertToRaw(editorNotes.getCurrentContent()));
        let bannerContentObj = JSON.stringify(convertToRaw(editorNotes.getCurrentContent()));
        let plainEditorText = editorNotes.getCurrentContent().getPlainText();
        if (plainEditorText) {
            let bannerData = _id ? {
                _id,
                title,
                from: new Date(from).toISOString(),
                to: new Date(to).toISOString(),
                desc: plainEditorText,
                bannerContent: contentHtml,
                bannerContentObj: bannerContentObj,
                bannerColor: bannerColor,
                isActive: status,
                type: 'BannerText'
            } : {
                    title,
                    from: new Date(from).toISOString(),
                    to: new Date(to).toISOString(),
                    desc: plainEditorText,
                    bannerContent: contentHtml,
                    bannerContentObj: bannerContentObj,
                    bannerColor: bannerColor,
                    isActive: status,
                    type: 'BannerText'
                }
            this.props.updatedata('BannerText', [bannerData])
            history.goBack();
        } else {
            this.setState({ editorError: true })
        }
    }
    /**
     * Handle validation error
     */
    handleValidationError = () => {
        const { editorNotes } = this.state;
        if (editorNotes.getCurrentContent().getPlainText()) {
            this.setState({ editorError: false });
        } else {
            this.setState({ editorError: true });
        }
    }
    /**
     * handle color change
     * @param {*} color 
     * @param {*} event 
     */
    handleColorChange = (color, event) => {
        this.setState({ bannerColor: color })
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { title, editorNotes, from, to, status, validateFrom, bannerColor, editorError, pageTitle } = this.state;
        const { classes } = this.props;
        const reportYear = new Date().getFullYear();
        let editorNotesHtml = draftToHtml(convertToRaw(editorNotes.getCurrentContent()));
        let editorClassName = editorError ? 'editorClassName errorClassName' : 'editorClassName'
        return (
            <ContentWrapper
                classes={classes}
                title={pageTitle + " Banner Text"}
                needPadding={false}
                downloadColor="secondary"
                isSubPage={true}
                handleBackActionClick={() => history.goBack()}
            >
                <Grid container spacing={4} className="width100per marginAuto">
                    <Grid item xs={6}>
                        <ValidatorForm
                            name="newMailForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => this.handleValidationError()}
                        >
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <TextBox
                                        name="name"
                                        value={title}
                                        label="Title"
                                        handleChange={(name, value) => { this.setState({ title: value }) }}
                                        margin="dense"
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        disabled={false}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={editorError ? "custom-label error-label" : 'custom-label'}>Banner*</div>
                                    <Editor
                                        editorState={editorNotes}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName={editorClassName}
                                        onEditorStateChange={this.onEditorStateChange}
                                        toolbar={{
                                            options: ['inline', 'blockType', 'history', 'list', 'textAlign', 'colorPicker'],
                                            // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
                                            inline: {
                                                inDropdown: false,
                                                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
                                                // options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                                            },
                                            // blockType: {
                                            //     inDropdown: true,
                                            //     options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code']
                                            // },
                                            // fontSize: {
                                            //     options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
                                            // },
                                            // fontFamily: {
                                            //     options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
                                            // },
                                            list: {
                                                inDropdown: false,
                                                options: ['unordered', 'ordered']
                                                // options: ['unordered', 'ordered', 'indent', 'outdent']
                                            },
                                            textAlign: {
                                                inDropdown: false,
                                                options: ['left', 'center', 'right', 'justify']
                                            },
                                            colorPicker: {
                                                colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                                    'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                                    'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                                    'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                                    'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                                    'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                                            },
                                            // link: {
                                            //     inDropdown: false,
                                            //     options: ['link', 'unlink']
                                            // },
                                            // emoji: {
                                            //     emojis: [
                                            //         '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                                            //         '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                                            //         '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                                            //         '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                                            //         '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                                            //         '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                                            //         '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                                            //         '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                                            //         '✅', '❎', '💯',
                                            //     ],
                                            // },
                                            // embedded: {
                                            //     defaultSize: {
                                            //         height: 'auto',
                                            //         width: 'auto',
                                            //     }
                                            // },
                                            history: {
                                                inDropdown: false,
                                                options: ['undo', 'redo'],
                                            },
                                        }}
                                        placeholder={'Type Something'}
                                    />
                                    {editorError && <div className="custom-label error-label">This field is required</div>}
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="custom-label">Banner Color</div>
                                    <CirclePicker
                                        label="test"
                                        className="width-100-per"
                                        color={bannerColor}
                                        styles={{ width: '100% !important' }}
                                        onChangeComplete={this.handleColorChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateTimePicker
                                        name="maintenance-date"
                                        value={from}
                                        format="MM/DD/YYYY, HH:mm"
                                        handleChange={(name, date) => {
                                            let currDate = new Date(date).getDate();
                                            let incDate = new Date(date).setDate(currDate + 1)
                                            this.setState({ from: date, nextFromDate: incDate, validateFrom: true })
                                        }}
                                        validators={validateFrom ? ['required', 'isDateVacant', 'inValidDate'] : ['required']}
                                        errorMessages={['This field is required', 'This Date is already been used', 'Date is invalid']}
                                        margin="dense"
                                        helperText="mm/dd/yyyy, hh:mm a (e.g 01/01/1991, 12:00 am)"
                                        label={'From'}
                                        minDate={new Date()}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateTimePicker
                                        name="maintenance-date"
                                        value={to}
                                        format="MM/DD/YYYY, HH:mm"
                                        handleChange={(name, date) => {
                                            this.setState({ to: date, validateTo: true })
                                        }}
                                        validators={validateFrom ? ['required', 'isDateVacant', 'inValidDate', 'minTime'] : ['required']}
                                        errorMessages={['This field is required', 'This Date is already been used', 'Date is invalid', 'To time Must greater that from time']}
                                        margin="dense"
                                        helperText="mm/dd/yyyy, hh:mm a (e.g 01/01/1991, 12:00 am)"
                                        label={'To'}
                                        minDate={from}
                                        disabled={!from}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <DropDown
                                        name="status"
                                        value={status}
                                        select
                                        label="Status"
                                        className={classes.textField}
                                        handleChange={(name, value) => {
                                            this.setState({ status: value })
                                        }}
                                        margin="dense"
                                        disabled={false}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    >
                                        {
                                            activeStatusConstants.map((item) => {
                                                return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                            })
                                        }
                                    </DropDown>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SaveCancel
                                        saveText="Save"
                                        justifyContent="flex-end"
                                        handleSaveButtonClick={() => { this.refs.form.submit() }}
                                        handleCancelButtonClick={() => { history.goBack() }}
                                    />
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="p">Preview</Typography>
                            </Grid>
                            <Grid item xs={12} className="banner_bg p-r" style={{ height: getSubChildCardHeight() }}>
                                <Grid item className="preview_banner_block" id="preview_banner_block">
                                    <Typography variant="h4" gutterBottom color="textPrimary">
                                        WBARS
                                    </Typography>
                                    <div
                                        className="banner_content_preview"
                                        style={{
                                            color: 'white',
                                            backgroundColor: bannerColor.hex,
                                            maxHeight: document.getElementById('preview_banner_block') && document.getElementById('preview_banner_block').offsetHeight - 155
                                        }}
                                        dangerouslySetInnerHTML={{ __html: editorNotesHtml }}></div>

                                </Grid>
                                <Grid item className="footer_links p-l-20">
                                    <p>
                                        {
                                            // eslint-disable-next-line
                                            <a target="_blank" rel="noopener noreferrer"> Standard Configuration </a>
                                        }
                                        {
                                            // eslint-disable-next-line
                                            <a target="_blank" rel="noopener noreferrer"> Terms of Use </a>
                                        }
                                    </p>
                                    <p> Washington State Housing Finance Commission. © {reportYear} All Rights Reserved. </p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentWrapper>
        );
    }
}

BannerTextNew.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Map State to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    let setAsideCategory = configuration.configurations[configurationConstants.setAsideCategory] || [];
    setAsideCategory = setAsideCategory.filter(s => s.key !== 6);
    return {
        bannerText: configuration.configurations[configurationConstants.bannerText] || [],
        setAsideCategory,
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getConfiguration: (setAsideCategory) => dispatch(confActions.getConfiguration(setAsideCategory)),
        getByName: (bannerText) => dispatch(confActions.getByName(bannerText)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id))
    }
};
/**
 * Export BannerTextNew
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(BannerTextNew));
