import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import moment from 'moment';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

//import Components
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import DatePicker from '../../../components/DatePicker/DatePicker';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";

//Actions
import { confActions } from '../../../actions/configuration';

//helpers
import { getUserOrgId } from '../../../helpers';

//constants
const currDate = new Date();
const currYear = new Date().getFullYear();
const nextYear = currYear + 1;
/**
 * FunderReportTable Container
 * @class FunderReportTable
 * @extends {Component}
 */
class FunderReportTable extends Component {
    /**
     * @param {Constructor} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            headers: [
                "Reports Year",
                "Table1",
                "Table2",
                "Table3",
                "Table4,4A&B",
                "Table5"
            ]
        }
        this.org_id = getUserOrgId()
    }

    /**
     * Component Will Mount 
     */
    UNSAFE_componentWillMount = () => {
        const { org_id } = this;
        this.props.getFunderReportTable(org_id);
    }
    /**
     * Component Will Recieve Props
     * @param {*} nextprops 
     */
    UNSAFE_componentWillReceiveProps(nextprops) {
        const { funderSettings } = nextprops;
        if (funderSettings) {
            this.setState({
                ...this.state,
                reports: global._.sortBy(funderSettings, 'firstReportingYear').reverse()
            })
        }
    }
    /**
     * date change handling
     * @param {*} index 
     * @returns 
     */
    handleChange = (index) => (name, value) => {
        const enteredYear = moment(value).get('year');
        if (enteredYear < nextYear) {
            window.confirm("Please enter valid date for Table 1. Year should be " + nextYear + " for " + currYear);
        }
        const upReport = this.state.reports;
        upReport[index].data[name] = value;
        this.setState({
            reports: upReport
        });
    }
    /**
     * date change handling
     * @param {*} index 
     * @returns 
     */
    handleChangeDate = (index) => (name, value) => {
        const upReport = this.state.reports;
        upReport[index].data[name] = value;
        this.setState({
            reports: upReport
        });
    }
    /**
     * Handle Change Check Box
     * @param {*} index 
     * @param {*} table 
     * @returns 
     */
    handleChangeCheckBox = (index, table) => (value) => {
        const updateReport = [...this.state.reports];
        updateReport[index].data[table] = updateReport[index].data[table] === null ? new Date() : null;
        this.setState({
            reports: updateReport,
        })
    };
    /**
     * Handle Change Current Year
     * @param {*} index 
     * @param {*} table 
     * @returns 
     */
    handleChangeCurrYear = (index, table) => (value) => {
        window.confirm('Projects for this year are already set up to submit this report. If needed, please change the due date')
    };
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        const reports = this.state;
        const { org_id } = this;

        this.props.updateReport(reports.reports, org_id);
    }
    /**
     * render Html
     */
    render() {
        const { isSubPage, title, pageAccess } = this.props;
        const { reports, headers } = this.state;
        const org_id = this;
        return (
            <div>
                <ContentWrapper
                    pageAccess={pageAccess}
                    title={title}
                    isSubPage={isSubPage}
                    needPadding={false}
                    ischildPage={true}>
                    <ValidatorForm
                        name="funderSettingsForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, hindex) => (
                                        <TableCell key={hindex} style={hindex !== 0 ? { textAlign: 'center' } : {}}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className="tableBody">
                                {
                                    reports.map(({ data: { table1, table2, table3, table4, table5 }, _id }, index) => {
                                        return <TableRow key={index}><TableCell>{_id}</TableCell>
                                            <TableCell  style={{ textAlign: 'center',width:"20%" }}>
                                                <div className='defaultReportingForms'>
                                                    <Checkbox
                                                        style={{ marginTop: '5px' }}
                                                        checked={table1 !== null}
                                                        onChange={_id > currYear ? this.handleChangeCheckBox(index, 'table1') : this.handleChangeCurrYear(index, 'table1')}
                                                        color="primary"
                                                        disabled={_id < currYear}
                                                    />
                                                    <DatePicker style={{ width: '100%' }}
                                                        name="table1"
                                                        value={table1}
                                                        handleChange={_id === currYear ? this.handleChange(index) : this.handleChangeDate(index)}
                                                        margin="dense"
                                                        disabled={_id < currYear}
                                                    />
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center',width:"20%"}}>
                                                {org_id.org_id !== 1 ? <div className='defaultReportingForms' >
                                                    <Checkbox
                                                        style={{ marginTop: '5px' }}
                                                        checked={table2 !== null}
                                                        onChange={_id > currYear ? this.handleChangeCheckBox(index, 'table2') : this.handleChangeCurrYear(index, 'table2')}
                                                        color="primary"
                                                        disabled={_id < currYear}
                                                    />
                                                    <DatePicker style={{ width: '100%' }}
                                                        name="table2"
                                                        value={table2}
                                                        minDate={_id < currYear ? null : currDate}
                                                        handleChange={_id === currYear ? this.handleChange(index) : this.handleChangeDate(index)}
                                                        margin="dense"
                                                        disabled={_id < currYear} />
                                                </div> : "N/A"}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center',width:"20%" }}>
                                                {org_id.org_id !== 1 ? <div className='defaultReportingForms' >
                                                    <Checkbox
                                                        style={{ marginTop: '5px' }}
                                                        checked={table3 !== null}
                                                        onChange={_id > currYear ? this.handleChangeCheckBox(index, 'table3') : this.handleChangeCurrYear(index, 'table3')}
                                                        color="primary"
                                                        disabled={_id < currYear}
                                                    />
                                                    <DatePicker style={{ width: '100%' }}
                                                        name="table3"
                                                        value={table3}
                                                        minDate={_id < currYear ? null : currDate}
                                                        handleChange={_id === currYear ? this.handleChange(index) : this.handleChangeDate(index)}
                                                        margin="dense"
                                                        disabled={_id < currYear}
                                                    />
                                                </div> : "N/A"}
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center',width:"20%" }}>
                                                <div className='defaultReportingForms' >
                                                    <Checkbox
                                                        style={{ marginTop: '5px' }}
                                                        checked={table4 !== null}
                                                        onChange={_id > currYear ? this.handleChangeCheckBox(index, 'table4') : this.handleChangeCurrYear(index, 'table4')}
                                                        color="primary"
                                                        disabled={_id < currYear}
                                                    />
                                                    <DatePicker style={{ width: '100%' }}
                                                        name="table4"
                                                        value={table4}
                                                        minDate={_id < currYear ? null : currDate}
                                                        handleChange={_id === currYear ? this.handleChange(index) : this.handleChangeDate(index)}
                                                        margin="dense"
                                                        disabled={_id < currYear} />
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ textAlign: 'center',width:"20%" }}>
                                                <div className='defaultReportingForms' >
                                                    <Checkbox
                                                        style={{ marginTop: '5px' }}
                                                        checked={table5 !== null}
                                                        onChange={_id > currYear ? this.handleChangeCheckBox(index, 'table5') : this.handleChangeCurrYear(index, 'table5')}
                                                        color="primary"
                                                        disabled={_id < currYear}
                                                    />
                                                    <DatePicker style={{ width: '100%' }}
                                                        name="table5"
                                                        value={table5}
                                                        minDate={_id < currYear ? null : currDate}
                                                        handleChange={_id === currYear ? this.handleChange(index) : this.handleChangeDate(index)}
                                                        margin="dense"
                                                        disabled={_id < currYear} />
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                            </TableBody>
                        </Table>
                    </ValidatorForm>

                </ContentWrapper>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { }}
                />
            </div>

        );
    }
}
/**
 * Bind Component Property Types
 */
FunderReportTable.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};
/**
 * default prop types
 */
FunderReportTable.defaultProps = {
    classes: {},
    theme: {},
    projectId: null,
    needPadding: true,
    isSubPage: false,
    title: 'Default Reporting Forms'
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        funderSettings: configuration.FunderReportTable.data
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getFunderReportTable: (org_id) => dispatch(confActions.getFunderReportTable(org_id)),
        updateReport: (report, org_id) => dispatch(confActions.updateReport(report, org_id)),
    }
}
/**
 * Export FunderReportTable
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FunderReportTable);