import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'underscore';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { confActions } from '../../actions/configuration';
import { getUserOrgId, getUserRole, globalVariable, history, formatDate, formatDateWithPrefixZero, filterSearchFields, capitalizeName } from '../../helpers';
import { searchActions } from '../../actions/search';
import { reportActivityActions } from '../../actions/reportActivity';
import { validationActions } from '../../actions/validation';

// Import Components
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TableErrors from '../../components/TableErrors/TableErrors';
import MaterialTableComponent from '../../components/Table/MaterialTable'
import ContractActivityValidations from '../../helpers/TableValidations/contractActivity';
import ProgramActivityValidations from '../../helpers/TableValidations/programActivity';
import FinancialActivityValidations from '../../helpers/TableValidations/financialActivity';
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";


class ContractActivityList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fieldFilter: false,
            reset: false,
            deleteProj: false,
            deleteItemId: null,
            loanNumber:null,
            contractNumber:null
        }

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }

    /**
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {
        const { reportData: { page } } = this.props;
        //const reportYear_id = state !== undefined && state.pageData.reportYear_id ? state.pageData.reportYear_id : null;
        let reportYear_id = parseInt(history.location.pathname.split("/")[4])
        if (reportYear_id) {
            this.props.validationIssue(reportYear_id);
        }

        this.props.getByIdCertificationForm(reportYear_id);
        this.props.getReportActivityAccess(reportYear_id, () => { })
        this.props.getByIdProgramActivity(reportYear_id, () => { });
        this.props.getByIdFinancialActivity(reportYear_id, () => { });
        this.props.getByReportIdContractActivity(reportYear_id, () => { });
        if (page && page.skip) {
            this.props.getContractActivity(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues, reportYear_id, () => { });
        } else {
            this.props.getContractActivity(1, globalVariable.subPagetableRowSizeXL, '_id', 'asc', '', '', reportYear_id, () => { });
        }
        setTimeout(() => {
            this.props.getFieldFilter('contractActivity');
        }, 100);

    }

    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }

    // UNSAFE_componentWillMount() {
    //     const { location: { state } } = this.props;
    //     let ryid = parseInt(history.location.pathname.split("/")[4])
    //     if (ryid)
    //         this.props.validationIssue(ryid);
    // }

    /**
     * Handle Table Pagination On Change Event
     */
    onChangePageTable = (skip, limit) => {
        const { searchData: { sFields, sValues }, location: { pathname }, reportData } = this.props;
        // const reportYear_id = state.pageData.reportYear_id ? state.pageData.reportYear_id : null
        const { page } = reportData;
        this.props.getContractActivity(skip, limit, page.orderBy, page.order, sFields, sValues, pathname.split("/")[4], () => { });
    };

    /**
     * Handle Table Sort Event
     */
    onSortRequest = (orderBy, order) => {
        const { searchData: { sFields, sValues }, location: { pathname }, reportData } = this.props;
        //const reportYear_id = state.pageData.reportYear_id ? state.pageData.reportYear_id : null
        const { page } = reportData;
        this.props.getContractActivity(page.skip, page.limit, orderBy, order, sFields, sValues, pathname.split("/")[4], () => { });
    };

    /**
     * Handle Table Search Input Box Event
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { searchData: { sFields, sValues }, location: { pathname }, reportData } = this.props;
        const { page } = reportData;
        // let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getContractActivity(1, globalVariable.subPagetableRowSizeXL, page.orderBy, page.order, sFields, sValues, pathname.split("/")[4], () => { });
    };

    /**
     * Handle Table Navigation Event
     */
    handleNavigateAction = (id, field) => {
        const { match } = this.props;
        let reportYear_id = parseInt(history.location.pathname.split("/")[4])
        switch (field) {
            case "loanNumber":
                history.push({
                    pathname: `${match.url}/${id}`,
                    reportYear_id: reportYear_id,
                    state: {
                        page: 'contractActivityView',
                        pageData: {
                            reportYear_id: reportYear_id,
                            // project_id: id
                        }
                    }
                });
                break;
            default: break;
        }
    }

    /**
     * Handle Add Action
     */
    handleAddActionClick = () => {
        const { match } = this.props;
        history.push({
            pathname: `${match.url}/new`,
            state: {
                page: 'contractActivityNew',
                pageData: {
                    //...state.pageData,
                }
            }
        });
    };

    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { location, reportData } = this.props;
        history.replace(`${location.pathname}#clear`);
        //const reportYear_id = location.state !== undefined && location.state.pageData.reportYear_id ? location.state.pageData.reportYear_id : null
        const { page } = reportData;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getContractActivity(page.skip, page.limit, page.orderBy, page.order, [], [], location.pathname.split("/")[4], () => { });
    };

    /**
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        const { userId, reportData } = this.props;
        const { page } = reportData;
        this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "contractActivity", page.fieldFilters.visibleColumns);
    };

    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        const { userId, reportData } = this.props;
        const { page } = reportData;
        this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "contractActivity", page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns, key is a column name and
     * values contains boolean of checked status
     *
     * @memberof Users
     */
    handleFieldFilterOnChange = (key, value) => {
        const { userId } = this.props;
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "contractActivity", updatedColumns);
        } else {
            this.props.changeFieldFilter(userId ? "associatedLoanProjects" : "contractActivity", [...this.props.visibleColumns, key]);
        }
    }

    handleFieldFilterOnSave = () => {
        const { userId, location: { pathname }, reportData } = this.props;
        //const reportYear_id = state.pageData.reportYear_id ? state.pageData.reportYear_id : null
        this.props.updateFieldFilter(userId ? "associatedLoanProjects" : "contractActivity", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { searchData: { sFields, sValues } } = this.props;
            const { page } = reportData;
            this.props.getContractActivity(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, pathname.split("/")[4], () => { });
        });
    }

    /**
    * Handle Table Delete Action
    */
    handleTableDeleteAction = (id,data) => {
        //show pop up
        let activityData = this.props.reportData.data.filter(item=>item._id===id.toString())[0];
        this.setState({ deleteProj: true, deleteItemId: id,loanNumber:activityData.loanNumber,contractNumber:activityData.contractNumber });
    };

    /**
     * A method to call when copy icon clicked, copy the project details to new project
     *
     * @memberof Projects
     */
    handleTableCopyAction = (id) => {
        history.push({
            pathname: `/loanProjects/new/${id}`,
            state: {
                page: 'loanProjectsNew',
                pageData: {
                    contractActivity_id: id
                }
            }
        });
    }

    /**
     * Handle Field Component On Change Event
     */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "funders":
                const { _id, name: funderName, org_id, modifiedBy, updatedAt } = data.funderKey;
                if (_id) {
                    history.push({
                        pathname: `/loanProjects/${data._id}/funders/${_id}`,
                        state: {
                            page: 'funderEdit',
                            pageData: {
                                funder_id: _id,
                                orgId: org_id
                            },
                            pageTitle: {
                                // title: '',
                                title: `${funderName} | ${data.name}`,
                                subTitle: modifiedBy ? `Last modified by: <b>${modifiedBy}</b> on <b>${formatDate(updatedAt)}</b>` : ''
                                // subTitle: ''
                            }
                        }
                    });
                }
                break;
            default:
        }
    }

    /**
     * Handle Download Click Action
     */
    handleDownloadActionClick = () => {

    }


    /**
     * Handle Upload Click Action
     */
    handleTable1ImportActionClick = () => {
        history.push({
            pathname: `/table1-import`,
            state: {
                page: 'table1Import',
                pageData: {}
            }
        });
    }

    /**
    * For merging projects
    */
    handleMergeActionClick = () => {

        history.push({
            pathname: '/merge',
            state: {
                page: 'mergeProjects',
                pageData: {}
            }
        })
    }

    componentWillUnmount() {
        // clear search of assoc tab
        if (this.props.userId) {
            this.props.searchActions({ sFields: [], sValues: [] });
        }
    }

    /**
     * 
     * @returns Handle Save Contract Activity
     */
    handleSaveActionClick = () => {
        const { location: { state }, allReportData } = this.props;
        const reportYear_id = state.pageData.reportYear_id ? state.pageData.reportYear_id : null;

        let fielderrors = [];
        let programActivityfieldErrors = [];
        let financialActivityfieldErrors = [];
        fielderrors = ContractActivityValidations({ ...this.props, reportData: allReportData });

        const hardStop = fielderrors ? fielderrors.filter(item => item.errorType === 1) : []

        let generalData = {
            reportYear_id: reportYear_id,
            fielderrors: fielderrors ? hardStop.length : 0
        }
        this.props.saveContractActivity(generalData, (data) => {
            this.props.getReportActivityAccess(reportYear_id, () => {
                this.props.getByReportIdContractActivity(reportYear_id, (data) => {
                    fielderrors = ContractActivityValidations({ ...this.props, reportData: data });
                    programActivityfieldErrors = ProgramActivityValidations({ ...this.props, reportData: data });
                    financialActivityfieldErrors = FinancialActivityValidations({ ...this.props, reportData: data });
                    let validationData = {
                        reportYear_id: reportYear_id,
                        errors: [...fielderrors, ...programActivityfieldErrors, ...financialActivityfieldErrors]
                    }
                    this.props.validationErrors(validationData, () => {
                        this.props.validationIssue(reportYear_id);
                    });
                });
            })
        });
    }

    scrollToContent = (content) =>{
        this.handleUnit(null,content)
    }
    /*  
    Handle unit navigation
    */
    handleUnit = (data,content) =>{
        let locationToNavigate = '';
        history.location.pathname.split("/").map((item,index)=>{
            if(index>0 && index<6){
                locationToNavigate = locationToNavigate + `/${item}`
            }
        })
        //Navigate to error tab and section
        switch(content.tab){
            case 'program':
                history.push({pathname:`${locationToNavigate}/programActivity`,state:{content:content}})
            break;
            case 'finance':
                history.push({pathname:`${locationToNavigate}/financialActivity`,state:{content:content}})
            break;
            default:

            break;
        }
    }

    /**
     * Bind Html to DOM
     */
    render() {
        let {
            reportData, columnsMap, visibleColumns, tableActionRequired,
            removeActions, title, pageAccess,
            isSubPage, classes, needPadding,
            searchData: { sFields, sValues }, programActivity, reportTables, validationIssues
        } = this.props;
        validationIssues && validationIssues.contractActivity && validationIssues.contractActivity.forEach(item => {
            item.msg = item.message;
        })
        const { userRole, userOrgId } = this;
        const { fieldFilter } = this.state;
        const search = reportData;
        let {page} = reportData;
        const fieldFilters = search.page.fieldFilters;
        const stickyHeaders = fieldFilters.fieldCols.slice(0, 4)
        let defaultCols = stickyHeaders;

        pageAccess = _.difference(pageAccess, removeActions);

        // remove download icon
        const downloadIndex = pageAccess.indexOf('DOWNLOAD');
        if (this.props.userId && downloadIndex > -1) {
            pageAccess.splice(downloadIndex, 1);
        }

        let fielderrors = validationIssues && validationIssues.contractActivity;
        //Error sorting hard stop and warning
        fielderrors = fielderrors && fielderrors.sort((a, b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))
        let hardstopSort = fielderrors && fielderrors.filter((item, index) => item.errorType === 1);
        let warningSort = fielderrors && fielderrors.filter((item, index) => item.errorType === 2);

        if ((hardstopSort && hardstopSort.length > 0) && (warningSort && warningSort.length > 0)) {
            fielderrors = hardstopSort.concat(warningSort)
        } else if ((hardstopSort && hardstopSort.length > 0) && (warningSort && warningSort.length === 0)) {
            fielderrors = hardstopSort;
        } else {
            fielderrors = warningSort;
        }
        search.data.forEach(item => {
            item.modifiedBy = capitalizeName(item.modifiedBy)
            item.homeOwnerCashInvestment = isNaN(item.homeOwnerCashInvestment)?null:item.homeOwnerCashInvestment;
            item.purchasePrice = isNaN(item.purchasePrice)?null:item.purchasePrice;
            item.interestRateHTFLoan = item.interestRateHTFLoan==='NaN%'?null:item.interestRateHTFLoan;
            item.homeOwnerCashInvestment = isNaN(item.homeOwnerCashInvestment)?null:item.homeOwnerCashInvestment;
            item.totalMarketValue = isNaN(item.totalMarketValue)?null:item.totalMarketValue;
            item.totalRestrictiveValue = isNaN(item.totalRestrictiveValue)?null:item.totalRestrictiveValue;
            item.homeOwnerPurchasePrice = isNaN(item.homeOwnerPurchasePrice)?null:item.homeOwnerPurchasePrice;
            item.firstMortageAmount = isNaN(item.firstMortageAmount)?null:item.firstMortageAmount;
            item.firstMortageMonthlyPayment = isNaN(item.firstMortageMonthlyPayment)?null:item.firstMortageMonthlyPayment;
            item.secondMortageAmount = isNaN(item.secondMortageAmount)?null:item.secondMortageAmount;
            item.secondMortageMonthlyPayment = isNaN(item.secondMortageMonthlyPayment)?null:item.secondMortageMonthlyPayment;
            item.totalPITIU = isNaN(item.totalPITIU)?null:item.totalPITIU;
            item.totalMortageMonthlyPayment = isNaN(item.totalMortageMonthlyPayment)?null:item.totalMortageMonthlyPayment;
            item.annualGrossIncome = isNaN(item.annualGrossIncome)?null:item.annualGrossIncome;
            item.updatedAt = formatDateWithPrefixZero(item.updatedAt);
            item.householdSize = item.householdSize==="0"?null:item.householdSize;
            item.isPropertyForclosure = item.isPropertyForclosure===null?'':item.isPropertyForclosure;
        })
        if (reportTables.contractActivity === false) {
            return <h4 className="section_title textCenter">{'This Activity is not currently required by any Funder for this report year'}</h4>;
        }
        //get report level
        let level = ''
        if (!programActivity) {
            level = 'Report currently at Preparer level'
        } else
            if (programActivity && programActivity.submissionStatus && programActivity.submissionStatus.length === 0) {
                level = 'Report currently at Preparer level'
            } else if (programActivity && programActivity.submissionStatus && programActivity.submissionStatus.length > 0) {
                var getlevel = programActivity.submissionStatus[(programActivity.submissionStatus.length) - 1]
                if (getlevel.levelTo_id === 1) {
                    level = 'Report currently at Preparer level'
                } else if (getlevel.levelTo_id === 2) {
                    level = 'Report currently at Contractor level'
                } else if (getlevel.levelTo_id === 3) {
                    level = 'Report currently at Funder level'
                }
            }

            //add flag to remove delete for snapshoted record
            search.data.map(item=>{
                if(item['snapshotData']){
                    item['allowDeleteContractActivity'] = false;
                }else{
                    item['allowDeleteContractActivity'] = true;
                }
            })

            // let deleteIndex = pageAccess.indexOf('DELETE');
            // if(deleteIndex>-1){
            //     pageAccess.splice(deleteIndex,1)
            // }
        //if no loan data remove loan save button
        // if (search.data.length <= 0) {
        //     let index = pageAccess.indexOf("LOANSAVE")
        //     if (index > -1) {
        //         pageAccess.splice(index, 1)
        //     }
        // }
        // //update value as empty if 0
        // search.data.map(item=>{
        //     item['otherFundersAndAmt']=item['otherFundersAndAmt']===0?null:item['otherFundersAndAmt']
        // })
        return (
            <div className={`${classes.ContractActivityContainer} contractactivityListtop`}>
                <ContentWrapper
                    ref={el => (this.container = el)}
                    classes={classes}
                    pageAccess={pageAccess}
                    title={title}
                    isSubPage={isSubPage}
                    handleAddActionClick={this.handleAddActionClick}
                    handleHelpActionClick={this.handleHelpActionClick}
                    handleSaveActionClick={() => this.handleSaveActionClick()}
                    needPadding={needPadding}
                    handleTable1ImportActionClick={this.handleTable1ImportActionClick}
                    handleClearFilterActionClick={() => {
                        this.handleClearFilterActionClick()
                    }}
                    handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                >
                    <TableErrors
                        fielderrors={fielderrors || []}
                        //fielderrors={[]}
                        data={[]}
                        classes={{
                            action: classes.action,
                            message: classes.message,
                            root: classes.root
                        }}
                        validations={[]}
                        errorPage ={'reportActivity'}
                        handleClickAction={(err) => {
                            this.scrollToContent(err)
                        }}
                        handleUnitSelect={(data, content) => this.handleUnit(data, content)}
                    />
                    <Grid item xs={12} className={classes.loanactvitylist}><Typography variant="h4" class="report_level_title_HO">{level}</Typography></Grid>
                    <MaterialTableComponent
                        page={search.page.skip}
                        rowsPerPage={search.page.limit}
                        count={search.page.count}
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        stickyHeaders={stickyHeaders}
                        // actionClassName={'actions-end'}
                        // componentMap={componentMap}
                        fieldComponentOnChange={this.fieldComponentOnChange}
                        actions={tableActionRequired === true ? pageAccess : []}
                        userOrgId={userOrgId}
                        userRole={userRole}
                        currentPage={'loanProjects'}
                        links={["loanNumber"]}
                        order={search.page.order}
                        orderBy={search.page.orderBy}
                        data={search.data}
                        // highlightField={['name']}
                        isLoading={search.isLoading}
                        onChangePageTable={this.onChangePageTable}
                        onSortRequest={this.onSortRequest}
                        onInputChangeRequest={this.onInputChangeRequest}
                        handleTableDeleteAction={this.handleTableDeleteAction}
                        handleTableCopyAction={this.handleTableCopyAction}
                        resetSearchContent={this.props.location.hash === "#clear"}
                        sFields={filterSearchFields(sFields)}
                        sValues={sValues}
                        handleNavigateAction={this.handleNavigateAction}
                        noDataErrorMessage={"No Records Found"}
                        // moreInfoIcons={['HOME', 'NHTF', 'PSH']}
                        prevNext={false}
                        paginationVisible={!search.page.lastPage ? true : false}
                        showLoading={isSubPage}
                        highlightField={['_id']}
                        isDeleteRequired={false}
                    />
                    <FieldFilter
                        open={fieldFilter}
                        handleOnClose={this.handleFieldFilterOnClose}
                        handleOnChange={this.handleFieldFilterOnChange}
                        handleOnSave={this.handleFieldFilterOnSave}
                        columnsMap={columnsMap}
                        visibleColumns={visibleColumns}
                        disabledCols={defaultCols}
                    />

                    <DeleteDialog
                    open={this.state.deleteProj !== false}
                    content= {<div dangerouslySetInnerHTML={{ __html: `Are you sure you want to delete the record number <b>${this.state.loanNumber}</b> in <b>${this.state.contractNumber}</b>?`}}></div>}
                    handleDeleteAction={() => {
                        this.setState({ deleteProj: false });
                       // this.props.deleteContractActivity(this.state.deleteItemId)
                       this.props.deleteContractActivity(this.state.deleteItemId, () => {
                        // on api call back close the popup and call the table view api again
                        // const { searchData: { sFields, sValues } } = this.props;
                        // const { page } = reportData;
                        this.props.getContractActivity(page.skip, page.limit, page.orderBy, page.order, sFields, sValues, history.location.pathname.split("/")[4], () => { });
                        });
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ deleteProj: false })
                    }}
                   />
                </ContentWrapper>
            </div>
        );
    }
}

/**
 * Bind Component Property Types
 */
ContractActivityList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    title: PropTypes.string,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};

/**
 * Default Props Types
 */
ContractActivityList.defaultProps = {
    needPadding: false,
    tableActionRequired: true,
    title: "Contract Activity",
    removeActions: [],
    isSubPage: false
};

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['contractActivity'] || {};
    const searchData = state.search["contractActivity"] || { sFields: [], sValues: [] };

    return {
        pageAccess: state.pageAccess['contractActivityList'] || [],
        // searchData: state.search.maintainence,
        reportData: state.reportActivity.contractActivity,
        allReportData: state.reportActivity.contractActivityReportData,
        searchData,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        programActivity: state.reportActivity.programActivity.data,
        financialActivity: state.reportActivity.financialActivity.data,
        reportTables: state.reportActivity.reportTableAccess.reportTables,
        contractActivitySavedOnce: state.reportActivity.reportTableAccess.contractActivitySavedOnce,
        validationIssues: state.reportActivity.validations.data,
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getReportActivityAccess: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getReportActivityAccess(reportYear_id, successCallBack)),
        getContractActivity: (skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack) => dispatch(reportActivityActions.getContractActivity(skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack)),
        searchActions: (data) => {
            return dispatch(searchActions.contractActivity(data));
        },
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        saveContractActivity: (generalData, successCallBack) => dispatch(reportActivityActions.saveContractActivity(generalData, successCallBack)),
        getByIdProgramActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByIdProgramActivity(reportYear_id, successCallBack)),
        getByIdFinancialActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByIdFinancialActivity(reportYear_id, successCallBack)),
        getByIdCertificationForm: (reportYear_id) => dispatch(reportActivityActions.getByIdCertificationForm(reportYear_id)),
        validationErrors: (validationErrors, callBack) => dispatch(validationActions.activityValidations(validationErrors, callBack)),
        validationIssue: (reportYear_id) => dispatch(reportActivityActions.validationIssue(reportYear_id)),
        getByReportIdContractActivity: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getByReportIdContractActivity(reportYear_id, successCallBack)),
        deleteContractActivity: (id,successCallback) => dispatch(reportActivityActions.delete(id,successCallback)),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(ContractActivityList));