import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// templates
import Table1 from './Templates/Table1';
import Table5 from './Templates/Table5';

const componentMap = {
    'table1': Table1,
    'table5': Table5
}
/**
 * Print Container
 * @class Print
 * @extends {Component}
 */
class Print extends Component {
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { printComponent } = this.props;
        const RenderTemplate = componentMap[printComponent];
        console.log("== printComponent ==", printComponent)
        if (RenderTemplate) {
            return (
                <div id="section-to-print">
                    <RenderTemplate data={{...this.props}} />
                </div>
            )
        } else {
            return null;
        }
    }
}


/**
 * Bind Component Property Types
 */
Print.propTypes = {
    isLoading: PropTypes.bool,
    printData: PropTypes.string
};

/**
 * Default Props Values
 */
Print.defaultProps = {
    isLoading: false,
    printData: null
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ print }, ownProps) => {
    return {
        ...print
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {}
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Print);