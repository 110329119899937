import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import moment from 'moment';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Actions
import { occupantsActions } from '../../../actions/occupants';
import { alertActions } from '../../../actions/alert';
import { confActions } from '../../../actions/configuration';

// Import Redux Actions and Helper Variable
import { history } from '../../../helpers';
import { getDateDifference } from '../../../helpers/global';
import { configurationConstants } from "../../../helpers/appConstants";

// Import Components
import TextBox from '../../../components/TextBox/TextBox';
import DatePicker from '../../../components/DatePicker/DatePicker';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import AlertDialog from "../../../components/AlertDialog/AlertDialog";
import NumberField from "../../../components/NumberField/NumberField";
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TableErrors from '../../../components/TableErrors/TableErrors';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
import { Table1MRDValidations } from '../../../helpers/TableValidations/Table1';
import MoveOutAlert from "../../../components/AlertDialog/MoveOutAlert";
// required fields
import { occupantRequiredFields } from "./Validations";

import _ from 'underscore';
import { Table4Service } from '../../../services';

/** Default Config */
const DEFAULT_CONFIG = {
    setAsideType: [],
    homeHouseHoldType: [],
    race: [],
    homeUnitOccupancy: [],
    unitDesignation: [],
    homeCategory: [],
    specialNeedOption: []
};

const mapName = {
    1: 'HOME',
    2: 'NHTF'
};

/**
 * General Container
 * @class General
 * @extends {Component}
 */
class General extends Component {
    /**
     * Constructor
     * @param {*} props 
     * 
     * [1]  -   for creating a new occupant
     * [2]  -   for view
     * [3]  -   disable for passed ids
     */
    constructor(props) {
        super(props);
        this.state = {
            // [1]
            project_id: {},
            site_id: {},
            building_id: {},
            unit_id: {},
            // [2]
            projectName: '',
            siteName: '',
            buildingName: '',
            unitNumber: '',
            bedRoom: '',
            squareFootage: '',
            unitDesignation_id: '',
            moveOutReason_id: 0,
            evictionReason_id: 0,
            newHousingSituation_id: 0,
            check_newHousingSituation_id: 0,
            changeNewHosueSituationFlag: 1,
            moveOutDateFlag: 0,
            unitType: 0,
            isPSH: false,
            householdInPreHOMEUnitFlag: false,
            unitOccupancy: '',
            categoryType: '',
            headOfHouseholdName: '',
            firstName: '',
            lastName: '',
            middleInitial: '',
            homeRace_id: '',
            moveInDate: null,
            recentCertDate: '',
            previousCertDate: '',
            moveOutDate: '',
            householdType_id: '',
            specialNeedOption_id: '',
            householdSize_MoveIn: '',
            householdSize: '',
            prevHouseHoldSize: '',
            _id: null,
            config: {
                ...DEFAULT_CONFIG
            },
            moveInIsReadOnly: props.tableId ? true : false,
            vacantUnitsErrMsg: '',
            currentYear: new Date().getFullYear(),
            currentReportYearData: { lsYear: { year: new Date().getFullYear() } },
            ethnicityFlag: false,
            bedroom_id: null,
            prRecentCert: null,
            // [3]
            projectDisable: false,
            siteDisable: false,
            buildingDisable: false,
            unitDisable: false,
            openValidationPopup: false,
            validationMessage: '',
            validationTitle: '',
            initialMoveOutDate: null,
            showAlert: false,
            yesClicked: false,
            houseHoldSizeChanged: false,
            initialhouseHoldChange: false,
            noClicked: false,
            canChangeMoveInDate: null,
            isCurrentYear: false,
            nameChange:false, 
            moveOutChange:false,
            moveInFlag:false,
            MoveOutReasonEffectiveYear:null
        };
    }
    /**
     * Check Recent Certificate Date
     * @param {*} value 
     * @returns 
     * 
     * [1]  -   if reportyear greater than last submitted year
     * [2]  -   no need to check for previous year data
     */
    isRecentCertDate(value) {
        const { currentReportYearData: { lsYear } } = this.state;
        const { currentYear } = this.getPSBUDatas();
        // [1]
        if ((currentYear > lsYear.year) || this.state._id === null || lsYear.year === undefined) {
            const mtDate = global.moment(value, 'MM/DD/YYYY');
            const months = getDateDifference(mtDate, global.moment(new Date(), 'MM/DD/YYYY'), 'months') - 1;
            return months < 13;
        } else {
            // [2]
            return true;
        }
    }
    /**
     * Component Will Mount
     * 
     * [1]  -   Validation rule for 'recentCertDate'
     * [2]  -   Validation rule for 'isCurrentYear'
     * [3]  -   Validation rule for 'isCurrentReportYear'
     * [4]  -   if already moved in check from current report year
     * [5]  -   difference b.w cert date must be less than 1 year
     * [6]  -   small bedroom for largehousehold
     * [7]  -   Validation rule for 'isEmptyUA'
     * [8]  -   no error
     * [9]  -   move-in field validation
     * [10]  -  previous certification field validation
     * [11]  -  Move-out field validation
     */
    UNSAFE_componentWillMount() {
        // [1]
        ValidatorForm.addValidationRule('isRecentCertDate', (value) => {
            return this.isRecentCertDate(value);
        });
        // [2]
        ValidatorForm.addValidationRule('isCurrentYear', (value) => {
            let { currentYear } = this.getPSBUDatas();
            const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
            return !(selYear > currentYear);
        });
        // [3]
        ValidatorForm.addValidationRule('isCurrentReportYear', (value) => {
            const date = global.moment(value, 'MM/DD/YYYY')
            if (value && date.isValid()) {
                const selYear = date.get('year');
                let { sites, currentYear, siteData } = this.getPSBUDatas();
                sites = sites || [];
                const { tableId } = this.props;
                // [4]
                if (tableId) {
                    return selYear === currentYear;
                }

                if (sites.length > 0 && siteData) {
                    return siteData.report_Year === selYear;
                }
                return false
            }
            return true;
        });
        // [5]
        ValidatorForm.addValidationRule('isValidDiff', (value) => {
            const { recentCertDate, previousCertDate } = this.state;
            if (recentCertDate && previousCertDate) {
                const mtDate = global.moment(value, 'MM/DD/YYYY');
                const months = getDateDifference(global.moment(new Date(previousCertDate), 'MM/DD/YYYY'), mtDate, 'months') - 1;
                return months < 13;
            }
        });
        // [6]
        ValidatorForm.addValidationRule('isSmallUnit', (value) => {
            return this.state.bedRoom < 3;
        });
        // [7]
        ValidatorForm.addValidationRule('isEmptyUA', (value) => {
            const { unit_id, building_id } = this.state;
            if (unit_id && building_id) {
                const { buildingData, unitData } = this.getPSBUDatas();

                if (buildingData.utilityAllowance && unitData.bedroom_id) {
                    return buildingData.utilityAllowance.filter(ua => ua.bedroom_id === unitData.bedroom_id).length > 0;
                } else {
                    return true;
                }
            }
            // [8]
            return true;
        });
        // [9]
        ValidatorForm.addValidationRule('isLessThanMoveIn', (value) => {
            if (value === '' || value === null) return true;
            const moveInDate = new Date(this.state.moveInDate).getTime();
            const moveOutDate = new Date(value).getTime();
            return moveOutDate > moveInDate;
        });
        // [10]
        ValidatorForm.addValidationRule('isValidMoveOutYear', (value) => {
            let { currentYear } = this.getPSBUDatas();
            if (value === '' || value === null) return true;
            const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
            return selYear === currentYear;
        });
        // [11]checkValidateDate
        ValidatorForm.addValidationRule('isValidPrevCertDate', (value) => {
            let { currentYear } = this.getPSBUDatas();
            if (value === '' || value === null) return true;
            const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
            return selYear <= currentYear;
        });
        // [12]Format
        ValidatorForm.addValidationRule('checkValidateDate', (value) => {
            let date = moment(value, 'MM/DD/YYYY');
            if (value) {
                return date.isValid()
            } else {
                return true
            }
        });

    }


    /**
     * Component Did Mount
     * 
     * [1]  -   get validation error for occupants
     * [2]  -   call service to find vacant / empty units in this building
     * [3]  -   config
     * [4]  -   if navigated by vacant unit
     * [5]  -   get config datas
     */
    componentDidMount() {
        const { tableId, vacantUnitData, reportYear_id, validationErrors } = this.props;
        const id = tableId
        if (id) {
            this.props.getOccupantsDetails(parseInt(id), reportYear_id);
        
            //loading issue handled
            this.props.showLoading()
            setTimeout(()=>{
                let idToSearch = history.location.pathname.split("/")[2];
                this.props.getOccupantsDetails(parseInt(idToSearch), reportYear_id);
                this.props.getOccupantsDetails(parseInt(id), reportYear_id);
            },2000)

            setTimeout(()=>{
                this.props.hideLoading()
            },3000)

            //[1]
            this.props.getOneValidationError(id, reportYear_id);
        } else {
            this.props.getOneClearValidationError();
        }
        if (vacantUnitData) {
            const { project_id, site_id, building_id } = vacantUnitData;
            // [2]
            if (building_id.value) {
                this.props.getEmptyUnits({
                    project_id: project_id.value,
                    site_id: site_id.value,
                    building_id: building_id.value
                });
            }
        }
        // [3]
        this.props.getAssocPjtConfig(() => {
            // [4]
            let gtData = {};
            if (this.props.getOne.data) {
                const { project_id, site_id, building_id, unit_id } = this.props.getOne.data;
                gtData = {
                    project_id, site_id, building_id, unit_id
                }
            }
            if (vacantUnitData) {
                const { project_id, site_id, building_id, unit_id } = vacantUnitData;
                this.setState({
                    project_id,
                    site_id,
                    building_id,
                    unit_id,
                    ...gtData,
                    projectDisable: project_id.id !== 0,
                    siteDisable: site_id.id !== 0,
                    unitDisable: unit_id.id !== 0,
                    buildingDisable: building_id.id !== 0,
                });
            }
        });
        // [5]
        this.props.getConfiguration(configurationConstants.setAsideType);
        this.props.getConfiguration(configurationConstants.homeHouseHoldType);
        this.props.getConfiguration(configurationConstants.homeUnitOccupancy);
        this.props.getConfiguration(configurationConstants.unitDesignation);
        this.props.getConfiguration(configurationConstants.specialNeedOption);
        this.props.getConfiguration(configurationConstants.race);
        this.props.getConfiguration(configurationConstants.MoveOutReason);
        this.props.getConfiguration(configurationConstants.EvictionReason);
        this.props.getConfiguration(configurationConstants.NewHousingSituation);
        this.props.getMoveOutReasonEffectiveYear()
        
        if(vacantUnitData){
        const { project_id} = vacantUnitData;
        this.props.getFunders(project_id.id)
        }

    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     * 
     * [1]  -   when getting occupants data
     * [2]  -   display local saved data if new move in
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, vacantUnitsErrMsg, validationErrors, MoveOutReasonEffectiveYear } = nextProps;
        // 
        if (getOne.data && this.state._id === null && this.props.tableId) {
            //get effective year for mandatory condition
            // if(MoveOutReasonEffectiveYear && MoveOutReasonEffectiveYear.length>0 && MoveOutReasonEffectiveYear[0].desc){
            // this.setState({MoveOutReasonEffectiveYear:parseInt(MoveOutReasonEffectiveYear[0].desc)-1})
            // }
        // switch (getOne.data.moveOutReason_id) {
        //   case 6:
        //     getOne.data.newHousingSituation_id = 6;
        //     break;
        //   case 7:
        //     getOne.data.newHousingSituation_id = 7;
        //     break;
        //   case 9:
        //     getOne.data.newHousingSituation_id = 2;
        //     break;
        //   case 10:
        //     getOne.data.newHousingSituation_id = 8;
        //     break;
        //   default:
        //     getOne.data.newHousingSituation_id = 0;
        //     break;
        // }
        // console.log(getOne.data)

            this.setState({
                ...getOne.data,
                prevHouseHoldSize: getOne.data.householdSize,
                // moveInDate:moment(getOne.data.moveInDate).local(),
                isCurrentYear: getOne.data.isCurrentYear,
                moveInIsReadOnly: getOne.data.moveInDate !== null,
                project_id: { isWSHFC: getOne.data.isWSHFC },
                MoveOutReasonEffectiveYear:getOne.data.moveOutMandatoryYear

            });
        }
        //set state if name mismatch between props and state
        if(getOne.data){
            if((this.state.firstName!==getOne.data.firstName) || (this.state.projectName!==getOne.data.projectName)){
                this.setState({
                    ...getOne.data,
                    prevHouseHoldSize: getOne.data.householdSize,
                    // moveInDate:moment(getOne.data.moveInDate).local(),
                    isCurrentYear: getOne.data.isCurrentYear,
                    moveInIsReadOnly: getOne.data.moveInDate !== null,
                    project_id: { isWSHFC: getOne.data.isWSHFC }
                });
            }
        }


        if (getOne.data && getOne.data.moveOutReason_id != null) {
            this.setState({ moveOutDateFlag: 1 })
            this.setState({
                moveOutReason_id: getOne.data.moveOutReason_id,
                newHousingSituation_id: getOne.data.newHousingSituation_id === null ? 0 : getOne.data.newHousingSituation_id,
                check_newHousingSituation_id: getOne.data.newHousingSituation_id === null ? 0 : getOne.data.newHousingSituation_id,
                evictionReason_id: getOne.data.evictionReason_id === null ? 0 : getOne.data.evictionReason_id,
            });
        }

        if (getOne.data && (getOne.data.moveOutDate === '' || getOne.data.moveOutDate === null)) {
            this.setState({ moveOutDateFlag: 0 })
        } else {
            if (getOne.data && (getOne.data.moveInDate !== null || getOne.data.moveInDate !== '')) {
                this.setState({ moveOutDateFlag: 1 })
            }
        }

        if (vacantUnitsErrMsg !== '' && this.state.building_id.value) {
            this.setState({
                vacantUnitsErrMsg
            });
        }
        // [2]
        if (getOne.data && this.state._id === null && this.props.tableId === null) {
            if (getOne.data._id === 1) {
                this.setState({
                    ...getOne.data
                });
            }
        }
        this.setState({ initialMoveOutDate: getOne.data && getOne.data.moveOutDate })

        if (getOne.data) {
            if ((getOne.data.currentYear<2023) || ((!getOne.data.firstName || getOne.data.firstName === '') && (!getOne.data.lastName || getOne.data.lastName === '') && getOne.data.headOfHouseholdName !== '')){
                let nameSplit = getOne.data.headOfHouseholdName.split(' ')
                if (nameSplit.length === 1) {
                    this.setState({firstName:null,lastName:nameSplit[0],middleInitial:null})
                }

                if (nameSplit.length === 2) {
                    if (nameSplit[0].includes(",")) {
                        this.setState({firstName:nameSplit[1],lastName:nameSplit[0].replace(",", ""),middleInitial:null})
                    } else {
                        this.setState({firstName:nameSplit[0],lastName:nameSplit[1],middleInitial:null})
                    }
                }

                if (nameSplit.length === 3) {
                    if (nameSplit[0].includes(",")) {
                        this.setState({firstName:nameSplit[2],lastName:nameSplit[0].replace(",", ""),middleInitial:nameSplit[1]})
                    } else {
                        this.setState({firstName:nameSplit[0],lastName:nameSplit[2],middleInitial:nameSplit[1]})
                    }
                }

                if (nameSplit.length > 3) {
                    let nameSplitSplice = nameSplit.splice(1)
                    this.setState({firstName:nameSplit[0],lastName:nameSplitSplice.join(" "),middleInitial:null})
                }
                //this.props.updateHOHName(getOne.data._id, { firstName: this.state.firstName, lastName: this.state.lastName, middleInitial: this.state.middleInitial })
                //this.setState({ firstName: getOne.data.headOfHouseholdName })
            }else if((getOne.data.firstName || getOne.data.firstName !== '') && (getOne.data.lastName || getOne.data.lastName !== '') && getOne.data.headOfHouseholdName !== '' && (!getOne.data.middleInitial || getOne.data.middleInitial === '')){
                let nameSplit = getOne.data.headOfHouseholdName.split(' ')
                if (nameSplit.length === 3) {
                    if (nameSplit[0].includes(",")) {
                        this.setState({middleInitial:nameSplit[1]})
                    } else {
                        this.setState({middleInitial:nameSplit[1]})
                    }
                }

            }
        }
        // if(getOne.data &&  getOne.data.bedRoom === 3 &&  getOne.data.specialNeedOption_id === 2){
        //     if(( getOne.data.householdSize <= 3) && ( getOne.data.householdSize_MoveIn <= 3)){
        //         this.setState({ specialNeedOption_id: "" })
        //     }
        //     if(( getOne.data.householdSize > 3) && ( getOne.data.householdSize_MoveIn > 3) && (getOne.data.bedRoom < 3)){
        //         this.setState({ specialNeedOption_id: "" })
        //     }
        // }else if(getOne.data &&  getOne.data.bedRoom > 3 &&  getOne.data.specialNeedOption_id === 2){
        //     if(( getOne.data.householdSize <= 3) && ( getOne.data.householdSize_MoveIn <= 3)){
        //         this.setState({ specialNeedOption_id: "" })
        //     }
        // }
        // if(getOne.data &&  getOne.data.bedRoom < 3 &&  getOne.data.specialNeedOption_id === 2){
        //     this.setState({ specialNeedOption_id: "" })
        // }

        // if ((getOne && getOne.data && getOne.data.specialNeedOption_id && getOne.data.currentYear && getOne.data.moveInYear && getOne.data.householdSize && getOne.data.householdSize_MoveIn)
        //     && ((getOne.data.currentYear > getOne.data.moveInYear ? getOne.data.specialNeedOption_id === 2
        //         && (getOne.data.householdSize > 15 || getOne.data.householdSize_MoveIn < 4) : (getOne.data.specialNeedOption_id === 2 && getOne.data.bedRoom >= 3 && getOne.data.householdSize_MoveIn < 4))
        //         || (getOne.data.unitDesignation_id === 1 && getOne.data.bedRoom < 3 && getOne.data.specialNeedOption_id === 2)
        //     )
        // ) {
        //     this.setState({ specialNeedOption_id: "" })
        // }
    }
    /**
     * Component Will Unmount
     */
    componentWillUnmount() {
        this.props.clearEmptyUnits();
    }

    isValidMoveOutYear = (value) => {
        let { currentYear } = this.getPSBUDatas();
        if (value === '' || value === null) return true;
        const selYear = global.moment(value, 'MM/DD/YYYY').get('year');
        return selYear === currentYear;
    }

    isLessThanMoveIn = (value) => {
        if (value === '' || value === null) return true;
        const moveInDate = new Date(this.state.moveInDate).getTime();
        const moveOutDate = new Date(value).getTime();
        return moveOutDate > moveInDate;
    }


    haveNoMoveOutValidation = (value) => {
        return  this.isValidMoveOutYear(value) && this.isLessThanMoveIn(value);
    }

    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        //handle HHM change
        if(name==='firstName'){
            this.setState({nameChange:true})
        }

        if (name === 'moveOutDate') {
            if (value != null && this.haveNoMoveOutValidation(value)) {
                this.setState({ moveOutDateFlag: 1 })
            } else {
                this.setState({ moveOutDateFlag: 0, moveOutReason_id: 0, evictionReason_id: 0, newHousingSituation_id: 0, moveOutChange:true })
            }
        } else {
            if (this.state.moveOutDateFlag != 1) {
                this.setState({ moveOutDateFlag: 0, moveOutReason_id: 0, evictionReason_id: 0, newHousingSituation_id: 0, moveOutChange:false })
            }
        }
        //handle blank space
        if((name==='firstName' || name==='lastName') && value.trim() === ''){
            this.setState({ [name]: null })
        }else{
        this.setState({ [name]: value });
        }
        if (name === 'unitDesignation_id') {
            this.props.changeUnitDesignation(value);
        }

        if (name === 'householdSize' && this.state.initialhouseHoldChange) {
            // console.log("Inside Handle Changes", name,value)
            // console.log("Handle Change Triggerd after state Changes")
            if (!this.state.noClicked) {
                this.setState({ houseHoldSizeChanged: true })
            }
            this.setState({ noClicked: false })
        }

        this.setState({ initialhouseHoldChange: true })
    }
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
        // this.state.moveOutDateFlag === 1 && Table4Service.getConvertedTable4Data(this.props.reportId, 'projects')
    };
    handleCloseAction = () => {
        this.setState({ openValidationPopup: false, moveOutDateFlag: 1 });
    }

    triggerOnSaveToDb = (id) => {
        if (this.state.moveOutReason_id === 5) {
            this.setState({ newHousingSituation_id: 0 });
        } else if (this.state.moveOutReason_id != 5 && this.state.moveOutReason_id != 0) {
            this.setState({ evictionReason_id: 0 });
        }
        console.log('***1',this.state.moveOutChange)
        if(this.state.moveInFlag || this.state.moveOutChange) { 
            Table4Service.getConvertedTable4Data(this.props.reportId || this.props.reportYear_id, 'projects');
        }
        this.props.updateOccupantsGeneral(id, { ...this.state }, () => {
            // Removed this.state.isWSHFC (for tab navigation)
            if (this.state.unitDesignation_id === 1) {
                this.props.handleNav(null, 1);
            } else {
                this.props.handleNav(null, 3);
            }
            this.props.getAlertOnSuccess("Updated Successfully")
        });
    }

    triggerOnSaveToLocal = () => {
        const { unit_id, project_id, site_id, building_id, moveInDate, moveOutDateFlag, moveOutReason_id, evictionReason_id, newHousingSituation_id, openValidationPopup, validationMessage, validationTitle } = this.state;

        if (unit_id.value) {
            if (this.state.moveOutReason_id === 5) {
                this.setState({ newHousingSituation_id: 0 });
            } else if (this.state.moveOutReason_id != 5 && this.state.moveOutReason_id != 0) {
                this.setState({ evictionReason_id: 0 });
            }
            const isWSHFC = this.isSelectedProjectWSHFC(project_id);
            this.props.saveOccupantToLocal({
                ...this.state,
                _id: 1,
                project_id: { ...project_id, id: project_id.value },
                site_id: { ...site_id, id: site_id.value },
                building_id: { ...building_id, id: building_id.value },
                unit_id: { ...unit_id, id: unit_id.value },
                isWSHFC,
                canChangeMoveInDate: true,
                moveInYear: new Date(moveInDate).getFullYear()
            });
            //Removed isWSHFC (tab navigation for all user)
            if (this.state.unitDesignation_id === 1) {
                this.props.handleNav(null, 1);
                this.props.getFunders(project_id.value)
            } else {
                this.props.handleNav(null, 3);
                this.props.getFunders(project_id.value)
            }
        }

    }


    haveMoveOutReasonValidation = () => {

        const { moveOutDateFlag, moveOutReason_id, evictionReason_id, newHousingSituation_id, openValidationPopup, validationMessage, validationTitle } = this.state;

        return (((moveOutDateFlag != 0 && (moveOutReason_id === 0 || moveOutReason_id === null)) || (moveOutReason_id === 5 && (evictionReason_id === 0 || evictionReason_id == null)) || (moveOutReason_id !== 5 && newHousingSituation_id === 0 && moveOutReason_id !== 0 && this.state.moveOutDate !== null)) && this.state.transferToMoveIn == null);
    }

    handleMoveOutReasonValidationPopup = (message) => {
        let statevalidationTitle = 'Warning';
        let statevalidationMessage = message;
        this.setState({ openValidationPopup: true ,validationMessage:statevalidationMessage,validationTitle:statevalidationTitle});
    }


    handleMoveOutReasonValidations = (id) => {
        const { moveOutDateFlag, moveOutReason_id, evictionReason_id, newHousingSituation_id, openValidationPopup, validationMessage, validationTitle,MoveOutReasonEffectiveYear } = this.state;

        let combinedValidationMsg = "You have entered a move-out date for this household – you cannot save the record until you choose an option from the drop-down lists in the <b> Move-out Reason </b> and either <b> Eviction Reason </b> or <b> New Housing Situation fields.</b>";

        let evictionValidationMsg = "You have indicated household is being evicted – you cannot save the record until you choose an option from the drop-down list under <b>Eviction Reason.</b>";

        let newHousingValidationMsg = "You cannot save your changes until you choose an option from the drop-down list in the <b>New Housing Situation field.</b>";

        if (this.state.currentYear > MoveOutReasonEffectiveYear) {

            if (this.haveMoveOutReasonValidation()) {

                if (moveOutDateFlag === 1 && (moveOutReason_id === 0 || (moveOutReason_id === 5 && evictionReason_id !=0) || moveOutReason_id === null)) {

                    this.handleMoveOutReasonValidationPopup(combinedValidationMsg);

                } else if (moveOutReason_id === 5 && evictionReason_id === 0) {
                    this.handleMoveOutReasonValidationPopup(evictionValidationMsg);
                } else if (moveOutReason_id != 5 && newHousingSituation_id === 0 && moveOutReason_id != 0) {
                    this.handleMoveOutReasonValidationPopup(newHousingValidationMsg);
                }

            } else {
                id ? this.triggerOnSaveToDb(id) : this.triggerOnSaveToLocal();
            }

        } else if(this.state.currentYear >= 2023  && this.state.currentYear <= MoveOutReasonEffectiveYear) {

            if (this.haveMoveOutReasonValidation()) {
                if (moveOutDateFlag === 1 && (moveOutReason_id === 0  || moveOutReason_id === 11 || (moveOutReason_id === 5 && evictionReason_id !=0) || moveOutReason_id === null)) {

                    this.setState({moveOutReason_id: 11})
                    id ? this.triggerOnSaveToDb(id) : this.triggerOnSaveToLocal();


                } else if (moveOutReason_id === 5 && evictionReason_id === 0) {

                    this.handleMoveOutReasonValidationPopup(evictionValidationMsg)

                } else if (moveOutReason_id != 5 && moveOutReason_id != 11 && newHousingSituation_id === 0 && moveOutReason_id != 0) {
                      
                    this.handleMoveOutReasonValidationPopup(newHousingValidationMsg)

                }
            } else {
                id ? this.triggerOnSaveToDb(id) : this.triggerOnSaveToLocal();
            }

        } else if(this.state.currentYear < 2023 ) {
            id ? this.triggerOnSaveToDb(id) : this.triggerOnSaveToLocal();
        }

    }

    
    handleAction = async() => {
        const id = this.props.tableId
        if (this.state.moveOutReason_id === undefined || this.state.moveOutReason_id === '') { this.setState({ moveOutReason_id: 0 }); }
        if (this.state.evictionReason_id === undefined || this.state.evictionReason_id === '') { this.setState({ evictionReason_id: 0 }); }
        if (this.state.newHousingSituation_id === undefined || this.state.newHousingSituation_id === '') { this.setState({ newHousingSituation_id: 0 }); }

        if (this.state.middleInitial === '0' || this.state.middleInitial === 0) {
            this.setState({ middleInitial: null })
        }
        // Convert ISO object date to ISO date with time reseted for time zone issue 
        typeof this.state.moveInDate != 'string' &&this.state.moveInDate !=null && await this.setState({moveInDate:this.state.moveInDate.toISOString().slice(0,10).concat('T00:00:00.000Z')})
        typeof this.state.recentCertDate != 'string' && this.state.recentCertDate !=null && await this.setState({recentCertDate:this.state.recentCertDate.toISOString().slice(0,10).concat('T00:00:00.000Z')})
        typeof this.state.previousCertDate != 'string' && this.state.previousCertDate !=null && await this.setState({previousCertDate:this.state.previousCertDate.toISOString().slice(0,10).concat('T00:00:00.000Z')})

        
        console.log('*****',this.state)

        if (id) {
            this.handleMoveOutReasonValidations(id)
        } else {
            this.handleMoveOutReasonValidations(id)
        }

        // if (id) {
        //     const { moveOutDateFlag, moveOutReason_id, evictionReason_id, newHousingSituation_id, openValidationPopup, validationMessage, validationTitle } = this.state;

        //     if (((moveOutDateFlag != 0 && (moveOutReason_id === 0 || moveOutReason_id === null)) || (moveOutReason_id === 5 && (evictionReason_id === 0 || evictionReason_id == null)) || (moveOutReason_id !== 5 && newHousingSituation_id === 0 && moveOutReason_id !== 0 && this.state.moveOutDate !== null) ) && this.state.transferToMoveIn == null) {

        //         var statevalidationMessage = '';
        //         var statevalidationTitle = 'Warning';
        //         if (moveOutDateFlag === 1 && (moveOutReason_id === 0 || (moveOutReason_id === 5 && evictionReason_id !=0) || moveOutReason_id === null)) {

        //             if (this.state.moveOutReason_id === 5) {
        //                 this.setState({ newHousingSituation_id: 0 });
        //             } else if (this.state.moveOutReason_id != 5 && this.state.moveOutReason_id != 0) {
        //                 this.setState({ evictionReason_id: 0 });
        //             }
                        
        //             this.props.updateOccupantsGeneral(id, { ...this.state }, () => {
        //                 // Removed this.state.isWSHFC (for tab navigation)
        //                 if (this.state.unitDesignation_id === 1) {
        //                     this.props.handleNav(null, 1);
        //                 } else {
        //                     this.props.handleNav(null, 3);
        //                 }
        //                 this.props.getAlertOnSuccess("Updated Successfully")
        //             });

        //             // statevalidationMessage = "You have entered a move-out date for this household – you cannot save the record until you choose an option from the drop-down lists in the <b> Move-out Reason </b> and either <b> Eviction Reason </b> or <b> New Housing Situation fields.</b> ";
        //             // this.setState({ openValidationPopup: true ,validationMessage:statevalidationMessage,validationTitle:statevalidationTitle});
        //         } else  if (moveOutReason_id === 5 && evictionReason_id === 0) {
        //             statevalidationMessage = "You have indicated household is being evicted – you cannot save the record until you choose an option from the drop-down list under <b>Eviction Reason.</b> ";
        //                this.setState({ openValidationPopup: true ,validationMessage:statevalidationMessage,validationTitle:statevalidationTitle});
        //         } else if (moveOutReason_id != 5 && newHousingSituation_id === 0 && moveOutReason_id != 0) {
        //             statevalidationMessage = "You cannot save your changes until you choose an option from the drop-down list in the <b>New Housing Situation field.</b> ";
        //                this.setState({ openValidationPopup: true ,validationMessage:statevalidationMessage,validationTitle:statevalidationTitle});
        //         }
        //         // this.setState({ openValidationPopup: true, validationMessage: statevalidationMessage, validationTitle: statevalidationTitle });

        //     }
        //     else {

        //         if (this.state.moveOutReason_id === 5) {
        //             this.setState({ newHousingSituation_id: 0 });
        //         } else if (this.state.moveOutReason_id != 5 && this.state.moveOutReason_id != 0) {
        //             this.setState({ evictionReason_id: 0 });
        //         }
                    
        //         this.props.updateOccupantsGeneral(id, { ...this.state }, () => {
        //             // Removed this.state.isWSHFC (for tab navigation)
        //             if (this.state.unitDesignation_id === 1) {
        //                 this.props.handleNav(null, 1);
        //             } else {
        //                 this.props.handleNav(null, 3);
        //             }
        //             this.props.getAlertOnSuccess("Updated Successfully")
        //         });
        //     }
        // } else {
        //     const { unit_id, project_id, site_id, building_id, moveInDate, moveOutDateFlag, moveOutReason_id, evictionReason_id, newHousingSituation_id, openValidationPopup, validationMessage, validationTitle } = this.state;
        //     if (((moveOutDateFlag !== 0 && moveOutReason_id === 0) || (moveOutReason_id === 5 && evictionReason_id === 0) || (moveOutReason_id !== 5 && newHousingSituation_id === 0 && moveOutReason_id !== 0)) && this.state.currentYear>=2023 && this.state.transferToMoveIn == null) {
        //         var statevalidationMessage = '';
        //         var statevalidationTitle = 'Warning';
        //         if (moveOutDateFlag === 1 && moveOutReason_id === 0) {
        //             statevalidationMessage = "You have entered a move-out date for this household – you cannot save the record until you choose an option from the drop-down lists in the <b> Move-out Reason </b> and either <b> Eviction Reason </b> or <b> New Housing Situation fields.</b> ";
        //         } else if (moveOutReason_id === 5 && evictionReason_id === 0) {
        //             statevalidationMessage = "You have indicated household is being evicted – you cannot save the record until you choose an option from the drop-down list under <b>Eviction Reason.</b> ";
        //         } else if (moveOutReason_id != 5 && newHousingSituation_id === 0 && moveOutReason_id != 0) {
        //             statevalidationMessage = "You cannot save your changes until you choose an option from the drop-down list in the <b>New Housing Situation field.</b> ";
        //         }
        //         this.setState({ openValidationPopup: true, validationMessage: statevalidationMessage, validationTitle: statevalidationTitle });

        //     }
        //     else {
        //         if (unit_id.value) {
        //             if (this.state.moveOutReason_id === 5) {
        //                 this.setState({ newHousingSituation_id: 0 });
        //             } else if (this.state.moveOutReason_id != 5 && this.state.moveOutReason_id != 0) {
        //                 this.setState({ evictionReason_id: 0 });
        //             }
        //             const isWSHFC = this.isSelectedProjectWSHFC(project_id);
        //             this.props.saveOccupantToLocal({
        //                 ...this.state,
        //                 _id: 1,
        //                 project_id: { ...project_id, id: project_id.value },
        //                 site_id: { ...site_id, id: site_id.value },
        //                 building_id: { ...building_id, id: building_id.value },
        //                 unit_id: { ...unit_id, id: unit_id.value },
        //                 isWSHFC,
        //                 canChangeMoveInDate: true,
        //                 moveInYear: new Date(moveInDate).getFullYear()
        //             });
        //             //Removed isWSHFC (tab navigation for all user)
        //             if (this.state.unitDesignation_id === 1) {
        //                 this.props.handleNav(null, 1);
        //                 this.props.getFunders(project_id.value)
        //             } else {
        //                 this.props.handleNav(null, 3);
        //                 this.props.getFunders(project_id.value)
        //             }
        //         }
        //     }
        // }
    }

    /**
     * Save Form
     */
    _saveForm() {


        if (history.location.pathname.includes('new') || this.state.canChangeMoveInDate) {
            // console.log("------------------------ New Occupant -------------------")
            if (this.state.householdSize_MoveIn != this.state.householdSize && (!this.state.yesClicked || this.state.houseHoldSizeChanged)) {
                if (this.state.houseHoldSizeChanged) {
                    this.setState({ showAlert: true, houseHoldSizeChanged: false })
                    return;
                }
            }
            this.handleAction();
        } else {
            this.handleAction();
        }
    }
    /**
     * A method to return view only fields in occupants edit
     *
     * @returns
     * @memberof General
     */
    getViewOnlyFields() {
        const { classes } = this.props;
        const {
            projectName,
            siteName,
            buildingName,
            unitNumber,
            bedRoom,
            squareFootage
        } = this.state;

        let bedroomValue = bedRoom === 0 ? "0" : bedRoom

        return (
            <React.Fragment>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="projectName"
                        label="Project Name"
                        value={projectName}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="siteName"
                        label="Site Name"
                        value={siteName}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="buildingName"
                        label="Building Name"
                        value={buildingName}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="unitNumber"
                        label="Unit Number"
                        value={unitNumber}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="bedRoom"
                        label="Number of Bedrooms"
                        value={bedroomValue}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="squareFootage"
                        label="Square Footage"
                        value={squareFootage}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
            </React.Fragment>
        )
    }
    /**
     * Get PSBU datas
     * @returns 
     */
    getPSBUDatas() {
        const { vacantUnits, assocProjects } = this.props;
        let {
            project_id,
            site_id,
            building_id,
            unit_id
        } = this.state;
        const selData = assocProjects.filter(ap => ap._id === project_id.value)[0];
        project_id = selData ? { ...selData, id: selData.value } : project_id;
        const sites = selData ? selData.sites : [];
        const siteData = sites.filter(st => st.value === site_id.value)[0];
        const buildings = siteData ? siteData.buildings : [];
        let vacUnit = vacantUnits.filter(unit => unit._id === unit_id.id)
        var unitData = {}
        if (vacUnit && vacUnit[0]) {
            unitData = vacUnit[0];
        }
        let bd = building_id || {};
        const buildingData = buildings.filter(st => st.value === bd.value)[0] || {};
        let currentYear = siteData ? siteData.report_Year : this.state.currentYear;

        return { assocProjects, sites, project_id, building_id, unit_id, site_id, buildings, unitData, bedroomSize: unitData.bedroom, buildingData, currentYear, siteData };
    }
    /** A method to add editable fields when in occupants new page
     * @memberof General
     * 
     * [1]  -   call service to find vacant / empty units in this building
     */
    getEditableFields() {
        const { classes, vacantUnits } = this.props;
        const { assocProjects, sites, project_id, building_id, unit_id, site_id, buildings, unitData } = this.getPSBUDatas();
        const { projectDisable, siteDisable, buildingDisable, unitDisable } = this.state;
        return (
            <React.Fragment>
                <Grid item xs={12} sm={4}>
                    <AutoComplete
                        name="project_id"
                        suggestions={assocProjects}
                        className="autocomplete-fixed"
                        handleAutoCompolete={(selectedOption) => {
                            this.setState({
                                project_id: selectedOption,
                                site_id: {},
                                building_id: {},
                                unit_id: {}
                            })
                        }}
                        selectedValue={project_id}
                        disabled={projectDisable}
                        placeholder="Select Project"
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutoComplete
                        name="site_id"
                        className="autocomplete-fixed"
                        suggestions={project_id !== '' ? sites : []}
                        handleAutoCompolete={(selectedOption) => {
                            this.setState({
                                site_id: selectedOption,
                                building_id: {},
                                unit_id: {}
                            })
                        }}
                        selectedValue={site_id}
                        disabled={siteDisable}
                        placeholder="Select Site"
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutoComplete
                        name="building_id"
                        className="autocomplete-fixed"
                        suggestions={site_id !== {} ? buildings : []}
                        handleAutoCompolete={(selectedOption) => {
                            this.setState({
                                building_id: selectedOption,
                                unit_id: {}
                            });
                            // [1]
                            this.props.getEmptyUnits({
                                project_id: project_id.value,
                                site_id: site_id.value,
                                building_id: selectedOption.value
                            });
                        }}
                        selectedValue={building_id}
                        disabled={buildingDisable}
                        placeholder="Select Building"
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutoComplete
                        name="unit_id"
                        className="autocomplete-fixed"
                        suggestions={unit_id !== {} ? vacantUnits : []}
                        handleAutoCompolete={(selectedOption) => {
                            this.setState({
                                unit_id: selectedOption
                            });
                        }}
                        selectedValue={unit_id}
                        disabled={unitDisable}
                        placeholder="Select Unit"
                        validators={['required', 'isEmptyUA']}
                        errorMessages={['this field is required', 'No Utility Allowances found for this Bedroom.']}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="bedRoom"
                        label="Number of Bedrooms"
                        value={unitData.bedroom !== undefined ? unitData.bedroom === 0 ? "0" : unitData.bedroom : ''}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextBox
                        name="squareFootage"
                        label="Square Footage"
                        value={unitData.squareFootage !== undefined ? unitData.squareFootage : ''}
                        errorMessages={['this field is required']}
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        disabled={true}
                    />
                </Grid>
            </React.Fragment>
        )
    }
    /**
     * Check for WSHFC Project
     * @returns 
     * 
     * [1]  -   if edit data
     */
    isWSHFCProject() {
        const { getOne: { data } } = this.props;
        // [1]
        if (data) {
            if (data._id) {
                return data.isWSHFC;
            }
        }
        const { project_id } = this.state;
        const { assocProjects } = this.props;
        const selData = assocProjects.filter(ap => ap._id === project_id.value)[0];
        return selData ? selData.isWSHFC : false;
    }
    /**
     * Check if selected project is WSHFC
     * @param {*} project_id 
     * @returns 
     */
    isSelectedProjectWSHFC(project_id) {
        const selData = this.props.assocProjects.filter(ap => ap._id === project_id.value)[0];
        return selData ? selData.isWSHFC : false;
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   get field level validations for table 1
     * [2]  -   if it is a year old certficate
     */
    render() {
        let { classes, pageAccess, tableId, validationErrors } = this.props;
        const { vacantUnitsErrMsg } = this.state;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        let { unitDesignation_id, moveOutReason_id, evictionReason_id, newHousingSituation_id, check_newHousingSituation_id, changeNewHosueSituationFlag, moveOutDateFlag, unitType, isPSH, householdInPreHOMEUnitFlag, unitOccupancy, categoryType, headOfHouseholdName, firstName, lastName, middleInitial, homeRace_id, moveInDate, recentCertDate, previousCertDate, moveOutDate, householdType_id, specialNeedOption_id, householdSize_MoveIn, householdSize, project_id, bedRoom, canChangeMoveInDate, prRecentCert,nameChange,MoveOutReasonEffectiveYear } = this.state;
        let { unitDesignation, MoveOutReason, EvictionReason, NewHousingSituation, homeUnitOccupancy, homeCategory, race, homeHouseHoldType, specialNeedOption, getOne } = this.props;
        const formElements = tableId ? this.getViewOnlyFields() : this.getEditableFields();
        const unitTypeName = unitType ? mapName[unitType] : "";
        let itemSpacing = {xs:3, sm:3};
        let inlineStyle = {};
        let containerSpacing = 0;

        if (moveOutDateFlag != 0 && this.state.currentYear>=2023) {
            itemSpacing = {xs:12, sm:3};
            inlineStyle = { marginLeft: '12px'};
            containerSpacing = 3;
        }

        const isNewOccupant = history.location.pathname.includes('new');
        const displayHOHSplit = isNewOccupant || this.state.isCurrentYear;
        if ((firstName === '' || firstName === null || firstName === 'HOH') && !isNewOccupant && !nameChange) {
            firstName = 'HHM'
        }

        if (middleInitial === "0" || middleInitial === 0) {
            middleInitial = null
        }

        if (this.state.currentYear > MoveOutReasonEffectiveYear) {
            MoveOutReason = MoveOutReason.filter((item) => item.key !== 11);
        }

        // console.log('MoveOutReason',MoveOutReason,'MoveOutId',moveOutReason_id)
        // [1]
        let isWSHFC = project_id.value ? this.isSelectedProjectWSHFC(project_id) : this.isWSHFCProject();
        const { bedroomSize } = project_id.value ? this.getPSBUDatas() : { bedroomSize: bedRoom };
        const { buildingData, currentYear } = this.getPSBUDatas();
        const fielderrors = Table1MRDValidations({
            ...this.state, isWSHFC: isWSHFC,
            bedroomSize: bedroomSize, buildingData,
            validationErrors
        });
        let moveInIsReadOnly = canChangeMoveInDate === false || isReadOly === true;

        moveOutReason_id = moveOutReason_id === null ? 0 : moveOutReason_id;
        //handle error for largehousehold
        let valErrors = validationErrors;
        if (this.state.initialMoveOutDate === null && (moveOutReason_id !== 0 || moveOutReason_id !== null)) {
            MoveOutReason = MoveOutReason.filter(item => item.key !== 0)
        }
        if (unitDesignation_id && unitDesignation_id === 1 && moveOutDate === null) {
            if (!canChangeMoveInDate) {
                if (specialNeedOption_id === 2 && householdSize_MoveIn < 4 && householdSize < 4) {
                    let index = valErrors.findIndex(x => x.message === "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                    if (index === -1) {
                        valErrors.push({
                            "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                            "errorType": 2, "tabIndex": 0
                        })
                    }
                }
                if (specialNeedOption_id === 2 && householdSize >= 4) {
                    if (valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")) > -1) {
                        valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")), 1)
                    }
                }
            } else {
                if (specialNeedOption_id === 2 && householdSize_MoveIn < 4 && householdSize < 4) {
                    let index = valErrors.findIndex(x => x.message === "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                    if (index === -1) {
                        valErrors.push({
                            "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                            "errorType": 2, "tabIndex": 0
                        })
                    }
                }
                if (specialNeedOption_id === 2 && (householdSize_MoveIn >= 4 || householdSize >= 4)) {
                    if (valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")) > -1) {
                        valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")), 1)
                    }
                }
            }
        }

        return (
            <div className={classes.rootEdit} >
                <TableErrors
                    fielderrors={fielderrors}
                    validations={valErrors}
                    data={getOne.data || {}}
                    classes={classes}
                />
                <ValidatorForm
                    name="occupantsDetailsform"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >
                    <Grid container spacing={3}>
                        {formElements}
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                name="unitDesignation_id"
                                placeholder='Unit Designation'
                                suggestions={unitDesignation}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        unitDesignation_id: selectedOption.value
                                    })
                                }}
                                selectedValue={{ id: unitDesignation_id }}
                                disabled={isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isReadOly}
                                        checked={unitType === 1}
                                        onChange={(evt) => { this.handleChange('unitType', evt.target.checked ? 1 : 0) }}
                                        value={1}
                                        color="primary"
                                    />
                                }
                                label="This is a HOME Program Unit"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isReadOly}
                                        checked={unitType === 2}
                                        onChange={(evt) => { this.handleChange('unitType', evt.target.checked ? 2 : 0) }}
                                        value={2}
                                        color="primary"
                                    />
                                }
                                label="This is a NHTF Program Unit"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isReadOly}
                                        checked={isPSH}
                                        onChange={(evt) => { this.handleChange('isPSH', evt.target.checked ? true : false) }}
                                        value={isPSH}
                                        color="primary"
                                    />
                                }
                                label="This is a Permanent Supportive Unit"
                            />
                        </Grid>
                        {
                            (unitType === 1 || unitType === 2) && (
                                <React.Fragment>
                                    <Grid item xs={12} sm={12} style={{ marginTop: '26px' }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={isReadOly}
                                                    checked={householdInPreHOMEUnitFlag}
                                                    onChange={(evt) => { this.handleChange('householdInPreHOMEUnitFlag', evt.target.checked) }}
                                                    value={true}
                                                    color="primary"
                                                />
                                            }
                                            label={`Was this Household living in the Unit before the Unit become a ${unitTypeName} Assisted Unit ?`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <AutoComplete
                                            name="unitOccupancy"
                                            placeholder={`${unitTypeName} Unit Occupancy`}
                                            suggestions={homeUnitOccupancy}
                                            handleAutoCompolete={(selectedOption) => {
                                                this.setState({
                                                    unitOccupancy: selectedOption.value
                                                })
                                            }}
                                            selectedValue={{ id: unitOccupancy }}
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                    {
                                        unitType === 1 && (
                                            <Grid item xs={12} sm={3}>
                                                <AutoComplete
                                                    name="categoryType"
                                                    placeholder={"HOME Category"}
                                                    suggestions={homeCategory}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            categoryType: selectedOption.value
                                                        })
                                                    }}
                                                    // validators={['required']}
                                                    selectedValue={{ id: categoryType }}
                                                    disabled={isReadOly}
                                                />
                                            </Grid>
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                        {(displayHOHSplit === true) &&
                            (<>
                                <Grid item xs={12} sm={3}>
                                    <TextBox
                                        name="firstName"
                                        label={`${unitTypeName} First Name`}
                                        value={firstName === '<vacant>' || firstName === '<empty>' || firstName === null || firstName === undefined? '' : firstName.trimStart()}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        isRequired={true}
                                        {...occupantRequiredFields.houseHoldName(this.state)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextBox
                                        name="lastName"
                                        label={`${unitTypeName} Last Name`}
                                        value={lastName === '<vacant>' || lastName === '<empty>' || lastName === null || lastName === undefined? '' : lastName.trimStart()}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        {...occupantRequiredFields.houseHoldName(this.state)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextBox
                                        name="middleInitial"
                                        label={`${unitTypeName} Middle Initial`}
                                        value={middleInitial}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    //{...occupantRequiredFields.houseHoldName(this.state)}
                                    />
                                </Grid>
                            </>)
                        }
                        {(displayHOHSplit === false) && (
                            <><Grid item xs={12} sm={3}>
                                <TextBox
                                    name="headOfHouseholdName"
                                    label={`${unitTypeName} Household Name`}
                                    value={headOfHouseholdName === '<vacant>' || headOfHouseholdName === '<empty>' ? '' : headOfHouseholdName}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={isReadOly}
                                    {...occupantRequiredFields.houseHoldName(this.state)}
                                />
                            </Grid>
                            </>
                        )}
                        {(unitType === 1 || unitType === 2) && (
                            <Grid item xs={12} sm={3}>
                                <AutoComplete
                                    name="homeRace_id"
                                    className="autocomplete-fixed"
                                    placeholder={`${unitTypeName} Head of household race`}
                                    suggestions={race}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            homeRace_id: selectedOption.value
                                        })
                                    }}
                                    selectedValue={{ id: homeRace_id }}
                                    disabled={isReadOly}
                                />
                            </Grid>
                        )}
                        {(unitType === 1 || unitType === 2) && (
                            <Grid item xs={12} sm={3} style={{ marginTop: '11px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={isReadOly}
                                            checked={this.state.ethnicityFlag}
                                            onChange={(evt) => { this.handleChange('ethnicityFlag', evt.target.checked ? true : false) }}
                                            value={true}
                                            color="primary"
                                        />
                                    }
                                    label={`${unitTypeName} Head of House Ethinicity - Hispanic or Latino`}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={3} style={{ marginLeft: '4px' }}>
                            <DatePicker
                                name="moveInDate"
                                value={moveInDate}
                                format={"MM/DD/YYYY"}
                                label="Move-In Date:"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                handleChange={(name, value) => {
                                    if (global.moment(value, 'MM/DD/YYYY').isValid()) {
                                        this.setState({
                                            moveInDate: value,
                                            recentCertDate: value,
                                            moveInFlag:true
                                        });
                                    }
                                    this.setState({
                                        moveInDate: value
                                    });
                                }}
                                className={classes.textField}
                                margin="dense"
                                disabled={moveInIsReadOnly}
                                {...occupantRequiredFields.moveInDate({ ...this.state, moveInIsReadOnly, tableId: this.props.tableId })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={displayHOHSplit === false ? 2 : 4}>
                            <DatePicker
                                name="recentCertDate"
                                value={recentCertDate}
                                format={"MM/DD/YYYY"}
                                label={`${currentYear} Certification Date:`}
                                handleChange={(name, value) => {
                                    // [2]
                                    let upPrevCert = {};
                                    if (this.isRecentCertDate(prRecentCert) === false) {
                                        upPrevCert = {
                                            previousCertDate: prRecentCert
                                        }
                                    }

                                    this.setState({
                                        recentCertDate: value,
                                        ...upPrevCert
                                    });
                                }}
                                className={classes.textField}
                                margin="dense"
                                disabled={isReadOly}
                                {...occupantRequiredFields.recentCertDate(this.state)}
                                fielderrors={fielderrors}
                                warningfieldname={'RecertsWithinTwelveMonths'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={displayHOHSplit === false ? 2 : 4} style={{ marginLeft: "11px" }}>
                            <DatePicker
                                name="previousCertDate"
                                value={previousCertDate}
                                format={"MM/DD/YYYY"}
                                label={`${currentYear - 1} Certification Date:`}
                                handleChange={this.handleChange}
                                className={`${classes.textField} previousCertDateCSS`}
                                margin="dense"
                                validators={['checkValidateDate', 'isValidPrevCertDate']}
                                errorMessages={['Invalid date format.', 'Previous Certification Date must not be greater than Report Year']}
                                disabled={isReadOly}
                                notRequired={true}
                            />
                        </Grid>
                        {
                            <React.Fragment>
                            {/* <Grid container spacing={containerSpacing}> */}
                                {
                                    (tableId) && (
                                        <Grid item xs={itemSpacing.xs} sm={itemSpacing.sm} style={inlineStyle}>
                                            <DatePicker
                                                name="moveOutDate"
                                                value={moveOutDate}
                                                format={"MM/DD/YYYY"}
                                                label="Move-Out Date:"
                                                handleChange={this.handleChange}
                                                className={classes.textField}
                                                margin="dense"
                                                disabled={isReadOly}
                                                validations={{
                                                    minDate: new Date(this.state.moveInDate),
                                                    maxDate: moment().endOf('year')
                                                }}
                                                {...occupantRequiredFields.moveOutDate(this.state)}
                                            />
                                        </Grid>
                                    )
                                }
                                {
                                        (moveOutDateFlag != 0 && this.state.currentYear>=2023) && (
                                            <Grid item xs={12} sm={4} >
                                                <AutoComplete
                                                    name="moveOutReason_id"
                                                    placeholder='Move Out Reason'
                                                    suggestions={MoveOutReason}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            moveOutReason_id: selectedOption.value,
                                                            newHousingSituation_id: (selectedOption.value == 7) ? 7 : (selectedOption.value == 9) ? 2 : (selectedOption.value == 6 ) ? 6 : (selectedOption.value == 10) ? 8: 0,
                                                            check_newHousingSituation_id: 0,
                                                            changeNewHosueSituationFlag: 0,
                                                            evictionReason_id: selectedOption.value == 5 ? 0 : null
                                                        })
                                                    }}
                                                    selectedValue={{ id: moveOutReason_id }}
                                                    disabled={isReadOly}
                                                    warningfieldname={'moveOutReason_id'}
                                                // validators={['required']}
                                                // errorMessages={['this field is required']}
                                                />
                                            </Grid>
                                        )}
                                    {
                                        ((moveOutReason_id === 5) && (moveOutDateFlag != 0 && this.state.currentYear>=2023)) && (
                                            <Grid item xs={4} sm={4} >
                                                <AutoComplete
                                                    name="evictionReason_id"
                                                    placeholder='Eviction Reason'
                                                    suggestions={EvictionReason}

                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            evictionReason_id: selectedOption.value
                                                        })
                                                    }}
                                                    // className={ isReadOly ? `input--disabled autocomplete-marginDense` : `autocomplete-marginDense`}
                                                    selectedValue={{ id: evictionReason_id }}
                                                    disabled={isReadOly}
                                                // validators={['required']}
                                                // errorMessages={['this field is required']}
                                                />
                                            </Grid>
                                        )}
                                    {
                                        (moveOutDateFlag != 0 && this.state.currentYear>=2023) && (moveOutReason_id > 5 || moveOutReason_id < 5) && (moveOutReason_id !== 0 && moveOutReason_id !== null) && (moveOutReason_id != 11) && (
                                            // <Grid item xs={12} sm={4}  style={{height:"230px" }}>
                                            <Grid item xs={4} sm={4} >
                                                <AutoComplete
                                                    name="newHousingSituation_id"
                                                    placeholder='New Housing Situation'
                                                    suggestions={NewHousingSituation}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            changeNewHosueSituationFlag: 1,
                                                            newHousingSituation_id: selectedOption.value,
                                                            check_newHousingSituation_id: 1
                                                        })
                                                    }}
                                                    selectedValue={{ id: (changeNewHosueSituationFlag == 0) ? (moveOutReason_id == 7) ? 7 : (moveOutReason_id == 9) ? 2 : (moveOutReason_id == 6 ) ? 6 :( moveOutReason_id == 10)? 8 : (check_newHousingSituation_id != 0) ? newHousingSituation_id : '' : newHousingSituation_id }}
                                                    disabled={isReadOly}

                                                // Abandoned unit = 6   nhs = 7
                                                // Buying a home  = 9   nhs = 2
                                                // Resident didn’t provide reason = 10   nhs = 7
                                                // Passed Away   = 7   nhs  = 6

                                                // validators={['required']}
                                                // errorMessages={['this field is required']}
                                                />
                                            </Grid>
                                    )}
                            {/* </Grid> */}
                            </React.Fragment>
                        }
                        {
                            (unitType === 1 || unitType === 2) && (
                                <Grid item xs={12} sm={3}>
                                    <AutoComplete
                                        name="householdType_id"
                                        placeholder={`${unitTypeName} Type of Household`}
                                        suggestions={homeHouseHoldType}
                                        className="autocomplete-fixed occuhouseholdType_id"
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                householdType_id: selectedOption.value
                                            })
                                        }}
                                        selectedValue={{ id: householdType_id }}
                                        disabled={isReadOly}
                                    />
                                </Grid>
                            )
                        }
                        {this.isWSHFCProject() && (
                            <Grid item xs={12} sm={3} style={{ marginTop: '4px', marginBottom: '4px' }}>
                                <AutoComplete
                                    name="specialNeedOption_id"
                                    placeholder='Tax Credits Special-Needs'
                                    suggestions={specialNeedOption}
                                    handleAutoCompolete={(selectedOption) => {
                                        if (tableId) {
                                            localStorage.setItem("specialNeedOption_id", selectedOption.value)
                                            this.setState({
                                                specialNeedOption_id: +localStorage.getItem("specialNeedOption_id")
                                            })
                                        } else {
                                            this.setState({
                                                specialNeedOption_id: selectedOption.value
                                            })
                                        }
                                    }}
                                    selectedValue={localStorage.getItem("specialNeedOption_id") ? { id: +localStorage.getItem("specialNeedOption_id") } : { id: specialNeedOption_id }}
                                    margin="dense"
                                    disabled={isReadOly}
                                    {...occupantRequiredFields.isSmallUnit({ ...this.state, isWSHFC: this.isWSHFCProject() })}
                                    fielderrors={fielderrors}
                                    warningfieldname={'specialNeedOption_id'}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} sm={3} style={{ marginTop: '6px', marginBottom: '4px' }}>
                            <NumberField
                                className={classes.textField}
                                label="Move-In Household Size:"
                                name="householdSize_MoveIn"
                                value={householdSize_MoveIn || ''}
                                handleChange={(name, value) => {
                                    this.setState({
                                        householdSize_MoveIn: value,
                                        householdSize: value
                                    });
                                }}
                                margin="dense"
                                disabled={moveInIsReadOnly}
                                {...occupantRequiredFields.moveInHouseholdSize(this.state)}
                                prefix={''}
                                decimalScale={0}
                            />

                        </Grid>
                        <Grid item xs={12} sm={3} style={{ marginTop: '6px', marginBottom: '4px' }}>
                            <NumberField
                                className={classes.textField}
                                name="householdSize"
                                label="Household Size:"
                                value={householdSize || ''}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                                {...occupantRequiredFields.houseHoldholdSize(this.state)}
                                prefix={''}
                                decimalScale={0}
                                fielderrors={fielderrors}
                                warningfieldname={'HouseholdTooSmallForLargeHouseholdCommitment'}
                            />
                        </Grid>
                    </Grid>
                    {
                        pageAccess.indexOf('SAVE') > -1 ? (
                            <SaveCancel
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }}
                                saveText={'Next'}
                                cancelVisible={false}
                            />
                        ) : null
                    }
                </ValidatorForm >
                <AlertDialog
                    open={vacantUnitsErrMsg !== ''}
                    title={'Message:'}
                    onClose={() => {
                        this.props.clearEmptyUnits();
                        this.setState({
                            vacantUnitsErrMsg: ''
                        });
                    }}
                    cancelText={"OK"}
                    saveVisible={false}
                >
                    {"There are no empty units available for move-in."}
                </AlertDialog>
                <AlertDialog
                    open={this.state.showAlert}
                    title={'Message:'}
                    onClose={() => {
                        // this.props.clearEmptyUnits();
                        // console.log("$$$$$$$$$$$$$ This HouseHoldSize $$$$$$$$$", this.state.prevHouseHoldSize)
                        // console.log("$$$$$$$$$$$$$ This HouseHoldSize--MoveIn $$$$$$$$$", this.state.householdSize_MoveIn)
                        this.setState({
                            showAlert: false,
                            householdSize: this.state.prevHouseHoldSize || this.state.householdSize_MoveIn,
                            yesClicked: true,
                            noClicked: true
                        });
                        // console.log("-----------AFTER ON CLOSE-----------",this.state.showAlert)
                        // this.setState({
                        //     householdSize: this.state.householdSize_MoveIn
                        // })
                    }}
                    onSave={() => {
                        this.setState({
                            showAlert: false,
                            yesClicked: true
                        });
                    }}
                    cancelText={"No"}
                    saveText={"Yes"}
                    saveVisible={true}
                >
                    {"The current household size and move in household size do not match.Do you really want to enter this value?"}
                </AlertDialog>
                <MoveOutAlert
                    open={this.state.openValidationPopup !== false}
                    content={<div dangerouslySetInnerHTML={{ __html: `${this.state.validationMessage}` }}></div>}
                    title={this.state.validationTitle}
                    handleCloseAction={this.handleCloseAction}
                />
            </div >
        );
    }
}
/**
 * Bind Component Property Types
 */
General.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

General.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { },
    tableId: null,
    assocProjects: []
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 */
const mapStateToProps = ({ occupants, configuration: { configurations }, pageAccess }) => {
    const homeCategory = configurations[configurationConstants.setAsideType].filter(st => st.category_id === 5);
    let unitDesignation = global._.sortBy(configurations[configurationConstants.unitDesignation], 'desc');
    let MoveOutReason = global._.sortBy(configurations[configurationConstants.MoveOutReason]);
    let EvictionReason = global._.sortBy(configurations[configurationConstants.EvictionReason]);
    let NewHousingSituation = global._.sortBy(configurations[configurationConstants.NewHousingSituation]);
    return {
        getOne: occupants.getOne,
        assocProjects: occupants.getOne.config || [],
        vacantUnits: occupants.getOne.vacantUnits || [],
        vacantUnitsErrMsg: occupants.getOne.vacantUnitsErrMsg,
        // confif datas
        homeCategory: global._.sortBy(homeCategory, 'seq'),
        specialNeedOption: global._.sortBy(configurations[configurationConstants.specialNeedOption], 'name'),
        homeHouseHoldType: global._.sortBy(configurations[configurationConstants.homeHouseHoldType], 'key'),
        race: global._.sortBy(configurations[configurationConstants.race], 'key'),
        unitDesignation: unitDesignation.map(ud => { return { ...ud, value: ud.key, label: ud.desc } }),
        MoveOutReason: MoveOutReason.map(ud => { return { ...ud, value: ud.key, label: ud.desc } }),
        EvictionReason: EvictionReason.map(ud => { return { ...ud, value: ud.key, label: ud.desc } }),
        NewHousingSituation: NewHousingSituation.map(ud => { return { ...ud, value: ud.key, label: ud.desc } }),
        homeUnitOccupancy: configurations[configurationConstants.homeUnitOccupancy],
        pageAccess: pageAccess['occgeneral'] || [],
        validationErrors: occupants.validationErrors,
        MoveOutReasonEffectiveYear: configurations[configurationConstants.MoveOutReasonExpiryYear] || [],
    }
};
/**
 * Map dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOccupantsDetails: (id, reportYear_id) => dispatch(occupantsActions.getOccupantsDetails(id, reportYear_id)),
        getConfiguration: (name) => dispatch(confActions.getConfiguration(name)),
        updateOccupantsGeneral: (id, formData, callBack) => dispatch(occupantsActions.updateOccupantsGeneral(id, formData, callBack)),
        getAssocPjtConfig: (cb) => dispatch(occupantsActions.getAssocPjtConfig(cb)),
        getEmptyUnits: (data) => dispatch(occupantsActions.getEmptyUnits(data)),
        moveInOccupant: (unit_id, data, callBack) => dispatch(occupantsActions.moveInOccupant(unit_id, data, callBack)),
        saveOccupantToLocal: (data) => dispatch(occupantsActions.saveOccupantToLocal(data)),
        getOneValidationError: (id, reportYear_id) => dispatch(occupantsActions.getOneValidationError(id, reportYear_id)),
        getOneClearValidationError: () => dispatch(occupantsActions.getOneClearValidationError()),
        clearEmptyUnits: () => dispatch(occupantsActions.clearEmptyUnits()),
        getAlertOnSuccess: (message) => dispatch(alertActions.success(message)),
        getFunders: (project_id) => dispatch(occupantsActions.getFunders(project_id)),
        showLoading: () => dispatch(occupantsActions.showLoading()),
        hideLoading: () => dispatch(occupantsActions.hideLoading()),
        getMoveOutReasonEffectiveYear: (moveOutReason) => dispatch(confActions.getMoveOutReasonEffectiveYear('moveOutReason'))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(General));
