/**
 * Handle response
 * @param {*} response 
 * @returns 
 * 
 * [1]  -   If status code is not 200, then [2], else return data
 * [2]  -   If status code is 400, [3]
 * [3]  -   remove user from local storage to log user out
 */
export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // [1]
        if (data.http_code !== 200) {
            // [2]
            if (response.status === 400) {
                // [2]
                localStorage.removeItem('user');
            }
            const error = (data && data.message && data.message.message) || (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
/**
 * Make Query String
 * @param {*} params 
 * @returns 
 */
export function makeQueryString(params) {
    return '&' + Object.keys(params).map(key => key + '=' + params[key]).join('&');
}
/**
 * Handle Download Response
 * @param {*} response 
 * @returns 
 * 
 * [1]  -   If content type is not application/json, then [2], else return response
 * [2]  -   If Status Code is not 200, then throw error
 */
export function handleDownloadResponse(response) {
    const contentType = response.headers.get("content-type");
    // [1]
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(data => {
            // [2]
            if (data.http_code !== 200) {
                const error = (data && data.message) || data.statusText;
                return Promise.reject(error);
            }
        });
    } else {
        return response;
    }
}
/**
 * Handle Bulk Response
 * @param {*} response 
 * @returns 
 * 
 * [1]  -   If status code is not 200, then [2], else return data
 * [2]  -   If status code is 400, [3]
 * [3]  -   remove user from local storage to log user out
 */
export function handleBulkResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // [1]
        if (data.http_code !== 200) {
            // [2]
            if (response.status === 400) {
                // [3]
                localStorage.removeItem('user');
            }

            const error = (data && data) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}