import React, { Component } from "react";
import { connect } from "react-redux";
// import xlsx from "json-as-xlsx";

// Material UI
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Checkbox from '@mui/material/Checkbox';

//import Component
import { DownloadIcon } from "../../../../components/SvgIcons/SvgIcons";
import FileDownload from "../../../../components/FileDownload/FileDownload";
import AutoCompleteMultiMUI from "../../../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI";
import AutoComplete from "../../../../components/AutoComplete/AutoComplete";

// Import Helpers
import { getUserOrgId, authHeader, handleResponse } from "../../../../helpers";
import { globalVariable } from "../../../../helpers/global";
import {
  alertActions,
  buildingActions,
  projectsActions,
  sitesActions,
} from "../../../../actions";
import { ValidatorForm } from "react-material-ui-form-validator";
import { uploadActions } from "../../../../actions/uploads";

/**
 * Option Container
 * @class Option
 * @extends {Component}
 */
class Option extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: "new",
      open: false,
      imageData: null,
      bulkAction: "new",
      downloadFile: false,
      uploadedFile: null,
      org_id: getUserOrgId(),
      selectedProjects: null,
      selectedSites: [],
      selectedBuildings: [],
      buildingNameList:[],
      Sites:false,
      Buildings:false,
      Units:false
    };
  }
  /**
   * A method that is after render
   * @memberof Option
   */
  componentDidMount() {
    let org = { org_id: this.state.org_id };
    this.props.getProjectNameList(org);
    this.props.onAction(this.state.bulkAction);
  }
  /**
   * handleChangeRadioButton
   * @param {*} event
   */
  handleChangeRadioButton = (event) => {
    // console.log(event.target.value);
    this.setState({
      showError: false,
      value: event.target.value,
      bulkAction: event.target.value,
      open: true,
      anchorEl: null,
      imageData: null,
      uploadedFile: null,
      selectedProjects:[],
      selectedSites:[],
      selectedBuildings:[]
    });
    this.props.onAction(event.target.value);
  };
  /**
   * handleMenu
   * @param {*} event
   */
  handleMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
    this.handleDownloadTemplate(this.state.value);
  };
  /**
   * handleDownloadTemplate
   * @param {*} templateType
   */
  handleDownloadTemplate = (templateType) => {
    let { org_id, selectedBuildings, selectedProjects, selectedSites } = this.state;
    let fileName = "";
    if (templateType) {
        if (templateType === "new" && org_id === 1) {
        this.props.showSuccessAlert(" Please wait… Download is Inprogress")
        fileName = "WBARS_Create Project_WSHFC.xlsx";
      } else if (templateType === "new" && org_id === 2) {
        this.props.showSuccessAlert(" Please wait… Download is Inprogress")
        fileName = "WBARS_Create Project_Commerce.xlsx";
      } else if (templateType === "update") {
        this.state.value === "update" &&
          selectedBuildings.length > 0 ?  
          this._saveForm():
          this.props.showErrorAlert("Please select the mandatory fields")
      }else if(this.state.value==="add"){
        if(!this.state.Sites && !this.state.Buildings && !this.state.Units){
          this.props.showErrorAlert("Please select the mandatory fields")
          return;
        }
         if((this.state.Sites && !this.state.Buildings && !this.state.Units) || (this.state.Sites && this.state.Buildings && this.state.Units) || (this.state.Sites && this.state.Buildings) || (this.state.Sites && this.state.Units)){
          if(typeof selectedProjects === 'object' && Object.keys(selectedProjects).length>0){
            this._saveForm("add")
          }else{
            this.props.showErrorAlert("Please select the mandatory fields")
          }
         }else if((this.state.Sites && this.state.Buildings && !this.state.Units) || (!this.state.Sites && this.state.Buildings && this.state.Units) || (!this.state.Sites && this.state.Buildings && !this.state.Units)){
          if(typeof selectedProjects === 'object' && Object.keys(selectedProjects).length>0 && typeof selectedSites === 'object' && Object.keys(selectedSites).length>0){
            this._saveForm("add")
          }else{
            this.props.showErrorAlert("Please select the mandatory fields")
          }
         }else if(this.state.Units){
          if(typeof selectedProjects === 'object' && Object.keys(selectedProjects).length>0 && typeof selectedSites === 'object' && Object.keys(selectedSites).length>0 && typeof selectedBuildings === 'object' && Object.keys(selectedBuildings).length>0){
            this._saveForm("add")
          }else{
            this.props.showErrorAlert("Please select the mandatory fields")
          }
         }

      }
      // else if (templateType === "update" && org_id === 1) {
      //   fileName = "HomebaseUpdate.xlsx";
      // } else {
      //   fileName = "HomebaseUpdate.xlsx";
      // }
     
      
      this.setState({
        anchorEl: null,
        downloadFile: fileName,
      });
    }
  };

  handleAddCheckBox = (e)=>{
    if(e.target.name==='Units'){
      if(this.state.Sites===true){
        this.setState({Buildings:true})
        this.setState({[e.target.name]:e.target.checked})
      }
    }
    this.setState({[e.target.name]:e.target.checked})
    if(e.target.name==='Units' && e.target.checked===false){
      this.setState({selectedBuildings:[]})
    }

    if(e.target.name==='Buildings' && e.target.checked===false){
      this.setState({selectedSites:[]})
    }

    // if(this.state.Sites===true && this.state.Units===true){
    //   this.setState({Buildings:true})
    // }


  }

  /**Handle Close Event */
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  /**
   * A method that is used to get values of the auto complete
   * @param {*} data
   * @param {*} name
   *
   * [1]  -   Feteh project id from data
   * [2]  -   Fetch site ids
   *          if - select all is selected fetch from siteNames
   *          else fetch from data
   */
  getValues(data, name) {
    const { siteNames } = this.props;
    const { selectedProjects } = this.state;
    switch (name) {
      // [1]
      case "project":
        if (Object.keys(data).length > 0) {
          return {
            projectIds: [data.value],
            menu:'SF'
          };
        }
        break;
      // [2]
      case "site":
        if (data[0]) {
          if (data[0].value === 0) {
            return {
              siteIds: siteNames.map((elem) => elem.value),
              projectIds:
                Object.keys(selectedProjects).length > 0
                  ? [selectedProjects.value]
                  : [],
            menu:'SF'
            };
          } else {
            return {
              siteIds: data.map((elem) => elem.value),
              projectIds:
                Object.keys(selectedProjects).length > 0
                  ? [selectedProjects.value]
                  : [],
            menu:'SF'
            };
          }
        }
        break;
      default:
        break;
    }
  }
   getBuildingNameList=(data = null)=> {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    fetch(`${globalVariable.apiURL}/buildings/name-list`, requestOptions).then(handleResponse).then((res)=>{
      this.setState({buildingNameList:res.data})
    })
}
  
  /**
   * A method that is used to handle AutoComplete
   * @param {*} data
   * @param {*} name
   * @memberof AutoComplete
   */
  handleAutoCompolete = (data, name, type="update") => {
    if(type==="add" && name==="site"){
      data = [data]
    }
    switch (name) {
      case "project":
        this.setState({
          selectedProjects: data,
          selectedSites: [],
          selectedBuildings: [],
        });
        this.props.getSiteNameList(this.getValues(data, name));
        // this.props.getBuildingNameList(this.getValues(data, name));
        break;
      case "site":
        this.setState({ selectedSites: type==="add"?data[0]:data, selectedBuildings: [],buildingNameList:[] });
        this.props.getBuildingNameList(this.getValues(data, name));
        data.length >0 && this.getBuildingNameList(this.getValues(data, name)); //WBAR-2836
        break;
      default:
        break;
    }
  };

  
  _saveForm = (type="update") => {
    const { selectedProjects, selectedSites, selectedBuildings,bulkAction} = this.state;
    var Sites = []
    var Buildings = []
    if(type==='add'){
      Sites.push(selectedSites.value);
      Buildings.push(selectedBuildings.value);
    }else{
    Sites = selectedSites.map((item) => item.value);
    Buildings = selectedBuildings.map((item) => item.value);
    }
    let data = {
      project_id: selectedProjects.value,
      site_id: Sites,
      building_id: Buildings,
      org_id: this.state.org_id,
      bulk_action:bulkAction,
      isSitesAdd:this.state.Sites,
      isBuildingsAdd:this.state.Buildings,
      isUnitsAdd:this.state.Units
    };
  
    this.props.downloadTemplate(data,bulkAction==="update"?"WBARS_Buildings & Units Update.xlsx":(bulkAction==="add" && this.state.org_id===1)?"WBARS_Addition_WSHFC":"WBARS_Addition_Commerce")

  };
  /**
   * Bind HTML to DOM
   * @memberof Option
   */
  render() {
    const {
      value,
      downloadFile,
      selectedProjects,
      selectedSites,
      selectedBuildings,
    } = this.state;
    let { projectNames, siteNames, buildingNames } = this.props;
    console.log(buildingNames);
    projectNames = [
      ...new Map(projectNames.map((item) => [item["value"], item])).values(),
    ];
    console.log(projectNames.length);
    console.log('THIS>STATE',this.state)

    let textForAdd = (!this.state.Sites && !this.state.Buildings && !this.state.Units)?'':(!this.state.Sites && (this.state.Buildings || this.state.Units))? 'Select the following to download the template capturing the details':'Select a project to download the template capturing the details';

    return (
      <div
        style={{
          marginTop: "15px",
          //backgroundColor: "#36aebe1c",
          padding: "17px",
          borderRadius: "7px",
          height: "400px",
        }}
      >
        <FormControl
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            {/* Choose an option whether to upload a new project or add new sites, buildings and units or update buildings and unit details. */}
            <b>Based on your preference, select one of the following options.</b>
          </span>
          <RadioGroup
            name="type"
            value={value}
            onChange={this.handleChangeRadioButton}
            row
            style={{ justifyContent:"center", marginTop : "2%" }}
          >
            <FormControlLabel
              value="new"
              control={<Radio color="primary" />}
              label="Create Project"
            />
            <FormControlLabel
              value="add"
              control={<Radio color="primary" />}
              label="Add New Sites, Buildings and Units"
            />
            <FormControlLabel
              value="update"
              control={<Radio color="primary" />}
              label="Update Buildings and Units"
            />
          </RadioGroup>
        </FormControl>
        <div
          className="download"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: "30px",
            gap: "20px",
            backgroundColor: "#36aebe1c",
            padding:"30px"
          }}
        >
          <span style={{fontSize:'0.875rem'}}>
            {this.state.value === "new"
              ? "Click Download button to download a new template"
              : this.state.value === "update" ? "Select the following to download a template capturing the details":""}
          </span>
          {
            this.state.value === "add" && (
              <Grid xs={12}>
              <div style={{display:"flex", justifyContent:"center"}}>
              <span style={{marginRight:"15px",paddingTop:"12px",fontSize:'0.875rem'}}>Select what would you like to add?</span>

              <span style={{marginRight:"15px"}}><Checkbox 
              sx={{'&.Mui-checked': {
                color: "#36aebe"
              }}}
              name="Sites"
              onChange={this.handleAddCheckBox}
              value={this.state.Sites}
              />
              Sites
              </span>
              <span style={{marginRight:"15px"}}>   
              <Checkbox
              sx={{'&.Mui-checked': {
                color: (this.state.Sites && this.state.Units) ? 'grey' : '#36aebe'
                }}}
                name="Buildings"
                value={(this.state.Sites && this.state.Units)?true:this.state.Buildings}
                onChange={this.handleAddCheckBox}
                disabled={this.state.Sites && this.state.Units}
                checked={(this.state.Sites && this.state.Units)?true:this.state.Buildings}     
              />
              Buildings
              </span>
              <span>
              <Checkbox
              sx={{'&.Mui-checked': {
                color: "#36aebe"
              }
              }}
              name="Units"
              value={this.state.Units}
              onChange={this.handleAddCheckBox}
              />
              Units
              </span>
              </div>
              </Grid>
            )
          }
          {this.state.value === "add" && (
            <div>
              <ValidatorForm
                name="bulkForm"
                ref="form"
                autoComplete="off"
                // onSubmit={this.handleSubmit}
                onError={(errors) => {}}
                style={{ marginBottom: "16px", marginTop: "16px" }}
              >
                <div style={{paddingBottom:'2px',fontSize:'0.875rem'}}>{textForAdd}</div>
                {
                  <Grid container spacing={2}>
                    {(this.state.Sites || this.state.Buildings || this.state.Units)&&
                    <Grid item xs={12} sm={4} style={this.state.Sites?{marginLeft:'35%'}:(!this.state.Sites && this.state.Buildings)?{marginLeft:'15%'}:{}}>
                    <AutoComplete
                        name="project"
                        suggestions={projectNames}
                        className="autocomplete-fixed"
                        handleAutoCompolete={(data) => {
                          this.handleAutoCompolete(data, "project");
                        }}
                        selectedValue={selectedProjects}
                        disabled={false}
                        placeholder="Select Project"
                        needSelectAll
                        validators={[(this.state.Sites || this.state.Buildings || this.state.Units)?"required":""]}
                        errorMessages={["This field is required"]}
                        module='SF'
                      />
                    </Grid>
                    }
                    {((this.state.Buildings || this.state.Units) && !(this.state.Sites && this.state.Buildings && this.state.Units) && !(this.state.Sites && this.state.Buildings) && (!this.state.Sites))&&
                    <Grid item xs={12} sm={4}>
                      <AutoComplete
                        name="site"
                        suggestions={siteNames}
                        className="autocomplete-fixed"
                        handleAutoCompolete={(data) => {
                          this.handleAutoCompolete(data, "site",'add');
                        }}
                        selectedValue={selectedSites}
                        disabled={selectedProjects.value ? false : true}
                        placeholder="Select Site"
                        needSelectAll
                        validators={[(this.state.Buildings || this.state.Units)?"required":""]}
                        errorMessages={["This field is required"]}
                        module='SF'
                      />
                    </Grid>
                    }
                    {(this.state.Units && !(this.state.Sites && this.state.Buildings && this.state.Units) && !(this.state.Buildings && this.state.Units)&& (!this.state.Sites))&&
                    <Grid item xs={12} sm={4}>
                      <AutoComplete
                        name="building"
                        suggestions={this.state.buildingNameList} //buildingNames
                        className="autocomplete-fixed"
                        handleAutoCompolete={(data) => {
                          this.setState({ selectedBuildings: data });
                        }}
                        selectedValue={selectedBuildings}
                        disabled={(this.state.Units && selectedSites.value) ? false : true}
                        placeholder="Select Building"
                        needSelectAll
                        validators={[(this.state.Units)?"required":""]}
                        errorMessages={["This field is required"]}
                        module='SF'
                      />
                    </Grid>
  }
                  </Grid>
                }
              </ValidatorForm>
            </div>
          )} 
          {this.state.value === "update" && (
            <div>
              <ValidatorForm
                name="bulkForm"
                ref="form"
                autoComplete="off"
                // onSubmit={this.handleSubmit}
                onError={(errors) => {}}
                style={{ marginBottom: "16px", marginTop: "16px" }}
              >
                {
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>

                    <AutoComplete
                        name="project"
                        suggestions={projectNames}
                        className="autocomplete-fixed"
                        handleAutoCompolete={(data) => {
                          this.handleAutoCompolete(data, "project");
                        }}
                        selectedValue={selectedProjects}
                        disabled={false}
                        placeholder="Select Project"
                        needSelectAll
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        module='SF'
                        // disableClearable
                        // disablePortal
                        // options={projectNames}
                        // sx={{ width: 200,height:30 }}
                        // renderInput={(params) => <TextField {...params} label="Select Project" className='autoComplete-table1'/>}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutoCompleteMultiMUI
                        name="site"
                        suggestions={siteNames}
                        className="autocomplete-fixed"
                        handleAutoCompolete={(data) => {
                          this.handleAutoCompolete(data, "site");
                        }}
                        selectedValue={selectedSites}
                        disabled={selectedProjects.value ? false : true}
                        placeholder="Select Site"
                        needSelectAll
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        module='SF'
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutoCompleteMultiMUI
                        name="building"
                        suggestions={this.state.buildingNameList} //buildingNames
                        className="autocomplete-fixed"
                        handleAutoCompolete={(data) => {
                          this.setState({ selectedBuildings: data });
                        }}
                        selectedValue={selectedBuildings}
                        disabled={selectedSites.length>0 ? false : true}
                        placeholder="Select Building"
                        needSelectAll
                        validators={["required"]}
                        errorMessages={["This field is required"]}
                        module='SF'
                      />
                    </Grid>
                  </Grid>
                }
              </ValidatorForm>
            </div>
          )} 
          <span style={(this.state.value === "add" && !this.state.Sites && !this.state.Buildings && !this.state.Units)?{ textAlign: "center" , marginTop : "-2%" }:{ textAlign: "center" , marginTop : "2%" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(e) => {
                this.handleMenu(e);
              }}
              // disabled={this.state.value !='new'&&selectedBuildings.length === 0 ?true:false}
              style={{ maxWidth: "400px",marginTop:'-2%'}}
            >
              <DownloadIcon fill="#ffffff" height="14px" width="14px" />{" "}
              &nbsp;&nbsp; Download{" "}
              {this.state.value === "new" ? "New" :this.state.value === "add"?"": "Update"} Template
            </Button>
          </span>
          {downloadFile && (
            <FileDownload
              actionPath={`${globalVariable.apiURL}/bulkTemplate/${downloadFile}`}
              method={"POST"}
              name="source"
              value=""
              onDownloadComplete={() => {
                this.setState({ downloadFile: false });
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
/**
 * Maps state from store to props
 * @param {*} param0
 * @param {*} ownProps
 * @returns
 */
const mapStateToProps = (
  {
    dashboard,
    projects: { projectNameList },
    sites: { siteNameList },
    buildings: { buildingNameList },
  },
  ownProps
) => {
  return {
    projectNames: projectNameList,
    siteNames: siteNameList,
    buildingNames: buildingNameList,
  };
};
/**
 * Maps actions to props
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getProjectNameList: (data) =>
    dispatch(projectsActions.getFunderProjectNameList(data)),
    getSiteNameList: (data) => dispatch(sitesActions.getSiteNameList(data)),
    getBuildingNameList: (data) =>
    dispatch(buildingActions.getBuildingNameList(data)),
    downloadTemplate: (payload,file) => dispatch(uploadActions.downloadUpdateTemplate(payload,file)),
    showErrorAlert: (error) => dispatch(alertActions.error(error)),
    showSuccessAlert: (msg) => dispatch(alertActions.success(msg))
  };
};
/** Export Upload */
export default connect(mapStateToProps, mapDispatchToProps)(Option);
