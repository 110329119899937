import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import LoanProjectFundersView from "./LoanFundersView";
import LoanProjectFundersNew from "./LoanFundersNew";
import LoanProjectFunderList from "./LoanFunderList";

// Import helper
import {history, getUserOrgId } from '../../helpers';

class LoanProjectFundersRouter extends Component {

    /**
 * Bind Html to DOM 
 */
    render() {
        const { match, projectId, isSubPage } = this.props;
        return (
            <div className="loanfund">
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <LoanProjectFunderList {...props} projectId={projectId} isSubPage={isSubPage} />
                }} />
                <Route exact path={`${match.url}/:funderId`} render={(props) => {
                    return <LoanProjectFundersView {...props} projectId={projectId} orgId={getUserOrgId()} isSubPage={isSubPage} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <LoanProjectFundersNew {...props} projectId={projectId} isSubPage={isSubPage} />
                }} />
                <Route exact path={`${match.url}/:funderId/reset`} render={(props) => {
                    history.push({
                        pathname: `${match.url}/${props.match.params.funderId}`,
                        state: {
                            page: 'loanFunderEdit',
                            pageData: {
                                funder_id: props.match.params.funderId
                            },
                        }
                    });
                }} />
            </Switch>
            </div>
        );
    }
}


/**
 * Export Component
 */
export default LoanProjectFundersRouter;
