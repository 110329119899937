import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import UnitsEdit from './UnitsEdit';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Actions
import { unitsActions } from '../../actions/units';
import { sitesActions } from '../../actions/sites';
import { history } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserAccessForPage } from '../../helpers';
/**
 * UnitsNew Component
 * @class UnitsNew
 * @extends {Component}
 */
class UnitsNew extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.userRef = React.createRef();
        this.accessAction = getUserAccessForPage('unitsnew');
        this.state = {
            disableSave : false
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { siteId } = this.props;
        if (siteId) {
            this.props.getById(siteId);
        }
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.unitRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
        if (formData && !this.state.disableSave) {
            this.props.addNewUnit({ ...formData, building_id: parseInt(this.props.buildingId, 10) }, () => { this.setState((prevState) => { return { disableSave : !prevState.disableSave}}) });
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Bind Html to DOM
     */
    render() {
        let { classes, isSubPage, pageAccess, title, location: { pathname }, match } = this.props;
        const { accessAction } = this;
        let isReadOly = accessAction.indexOf('SAVE') === -1;
        pageAccess = isSubPage ? pageAccess : [...pageAccess, 'BACK'];
        return (
            <ContentWrapper
                classes={classes}
                isSubPage={isSubPage}
                pageAccess={pageAccess}
                title={title}
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
            >
                <UnitsEdit
                    match={match}
                    isSubPage={isSubPage}
                    pageAccess={pageAccess}
                    innerRef={(node) => { this.unitRef = node; }}
                    isReadOly={isReadOly}
                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                    isArchived={pathname.includes('archives')}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
UnitsNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};
/** Default Props */
UnitsNew.defaultProps = {
    isSubPage: false,
    pageAccess: [],
    title: 'Create New Unit'
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess }, ownProps) => {
    return {
        pageAccess: pageAccess['unitNew'] || []
    }
};
/**
 * Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        addNewUnit: (formData, callBack) => dispatch(unitsActions.addNewUnit(formData, callBack)),
        getById: (id) => dispatch(sitesActions.getById(id))
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(UnitsNew));