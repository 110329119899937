import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TableHead, TableRow, TableCell, TableBody, Table } from '@material-ui/core';

// DND
import { DragDropContext } from 'react-beautiful-dnd';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

//Import Components
import LoanProjectMerge from "../../../components/MergeTool/LoanProjectMerge";

//Import Actions
import { mergeActions } from '../../../actions/mergeActions';
import { alertActions } from '../../../actions';

/**
 * a little function to help us with reordering the result
 * @param {*} list 
 * @param {*} startIndex 
 * @param {*} endIndex 
 * @returns 
 */
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
/**
 * Moves an item from one list to another list.
 * @param {*} source 
 * @param {*} destination 
 * @param {*} droppableSource 
 * @param {*} droppableDestination 
 * @returns 
 */
const move = (source, destination, droppableSource, droppableDestination, draggableId) => {
    const destClone = [...destination];
    const sourceFiltered = source.filter(s => s._id !== draggableId);
    const removed = source.filter(s => s._id === draggableId)[0];
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceFiltered;
    result[droppableDestination.droppableId] = destClone;
    return result;
};
/**
 * HTML Tooltip
 * @param {*} props 
 * @returns 
 */
function HTMLTooltip(props) {
    let header = ["Contract Number", "Contract Key"];
    let projectDetails = props ? props.contracts : [];
    return (
        <Table >
            <TableHead>
                <TableRow className={'tooltiphead'} >
                    {header.map((header, index) => {
                        return <TableCell>{header}</TableCell>
                    })}
                </TableRow>
            </TableHead>

            <TableBody className={'tooltipbody'} >
                {projectDetails && projectDetails.map((row, index) => {
                    return <TableRow key={index}>
                        <TableCell>{row.contractNumber}</TableCell>
                        <TableCell>{row._id}&nbsp;&nbsp;</TableCell>
                        {/* <TableCell >{row.contractOrg_id.map(i => i).join(", ")}</TableCell> */}
                    </TableRow>
                })}
            </TableBody>
        </Table>
    );
}
/**
 * Projects Component
 * @class Projects
 * @extends {Component}
 */
class Projects extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: props.currentTab,
            project1: null,
            project2: null,
            project1Name: [],
            project2Name: [],
            items: [],
            selected: [],
            filtered: [],
            draggedProject: [],
            fromProjectName: null,
            toProjectName: null
        
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.props.clearMergeSelectionDate('projectsTab');
        setTimeout(() => {
            this.props.getProjectsByName('');
        }, 100);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        //clear data after merge
        if(nextProps.mergeSuccess===true){
            this.setState({tab: nextProps.currentTab,
                project1: null,
                project2: null,
                project1Name: [],
                project2Name: [],
                items: [],
                selected: [],
                filtered: [],
                draggedProject: [],
                fromProjectName: null,
                toProjectName: null,
                mergeData:null})
        }
    }
    /**
     * Get list
     * @param {*} _id 
     * @returns 
     */
    getList = _id => this.state[`${this.id2List[_id]}`];

    id2List = {
        droppable: 'project1Name',
        droppable2: 'project2Name'
        // droppable2: 'filtered'
    };
    /**
     * Drag Search Handler
     * @param {*} event 
     * @param {*} droppableId 
     */
    DragsearchHandler(event, droppableId) {
        const updateOn = droppableId === 'droppable2' ? 'project2Name' : 'project1Name';
        let items = [...this.state[`${updateOn}Static`]];
        let search = event.target.value.toLowerCase();
        items = items.filter((el) => {
            let searchValue = el.name.toLowerCase();
            return searchValue.includes(search);
        });
        this.setState({
            [updateOn]: items,
        });
    }
    /**
     * Handle Merge
     * @param {*} formData 
     */
    handleMerge = (formData) => {
        const fromProjectName = this.state.project1Name ? this.state.project1Name.filter(i => i._id === this.state.project1)[0] : null;
        const toProjectName = this.state.project2Name ? this.state.project2Name.filter(i => i._id === this.state.project2)[0] : null;

        let fromProjectOrgId = formData.length === 2 ? formData.filter(i => i.project_id === this.state.project1)[0].funderIds : null;
        let toProjectOrgId = formData.length === 2 ? formData.filter(i => i.project_id === this.state.project2)[0].funderIds : null;

        let detinationOrgs = toProjectOrgId ? toProjectOrgId.map(s => s.org_id) : null;
        const orgsToUpdate = fromProjectOrgId ? fromProjectOrgId.filter(s => detinationOrgs.indexOf(s.org_id) === -1) : null;

        this.setState({
            fromProjectName: fromProjectName ? fromProjectName.name : null,
            toProjectName: toProjectName ? toProjectName.name : null
        });

        let projDetails = {
            project1: this.state.project1 ? this.state.project1 : null,
            project2: this.state.project2 ? this.state.project2 : null,
            fromProjectName: fromProjectName ? fromProjectName.name : null,
            toProjectName: toProjectName ? toProjectName.name : null,
            tab: this.state.tab,
            contentMsg: (orgsToUpdate && orgsToUpdate.length > 0) ? `Do you want to merge the project from <b>${fromProjectName ? fromProjectName.name : null}</b> to <b>${toProjectName ? toProjectName.name : null}</b> and Funders of both projects will be merged ?` : `Do you want to merge the project from <b>${ fromProjectName ? fromProjectName.name : null}</b> to <b>${toProjectName ? toProjectName.name : null}</b>?`
        };
            setTimeout(() => {
            this.props.handleMergeClick(formData, projDetails);
        }, 500);
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, projects, currentTab, pageAccess } = this.props;
        const { project1, project2, project1Name, project2Name } = this.state;
        let selectedColumns = [];

        return (
            <div className={classes.rootEdit}>
                <DragDropContext
                    onDragEnd={(result) => {
                        const { source, destination, draggableId } = result;
                        this.setState({ draggedProject: [...this.state.draggedProject, draggableId] });

                        // dropped outside the list
                        if (!destination) {
                            return;
                        }

                        if (source.droppableId === destination.droppableId) {
                            const items = reorder(
                                this.getList(source.droppableId),
                                source.index,
                                destination.index
                            );
                            let state = { items };
                            if (source.droppableId === 'droppable2') {
                                state = { selected: items };
                            }
                            this.setState(state);
                        } else {
                            const res = move(
                                this.getList(source.droppableId),
                                this.getList(destination.droppableId),
                                source,
                                destination,
                                +draggableId
                            );

                            this.setState({
                                items: res.droppable2,
                                selected: project2Name,
                                filtered: res.droppable2,
                                project2Name: res.droppable2,
                                project1Name: res.droppable
                            });
                            if (!this.state.project2) {
                                this.props.showErrorAlert("Please select To project")
                                this.setState({project1Name:projects.filter(item=>item._id===project1)})
                                this.setState({project2Name:[]})
                            }
                            else {
                                this.handleMerge(res.droppable2);
                            }
                        }

                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6}>
                            <LoanProjectMerge
                                suggestions={projects}
                                value={project1}
                                name={'projectName1'}
                                onChange={(value, selectedProject) => {
                                    if (value === undefined) {
                                        this.setState({ project1: null, project1Name: [] })
                                        this.props.clearMerge()
                                    } else if (value === this.state.project2 ) {
                                        this.props.showErrorAlert("Same projects are not allowed to merge");
                                    } else if (this.state.project2Name.length <= 1) {
                                        this.setState({
                                            project1: value,
                                            project1Name: selectedProject ? [{ ...selectedProject }] : [],
                                        });
                                    }
                                    else {
                                        this.props.showErrorAlert("Do not allow more than two projects to merge");
                                    }
                                }}
                                itemsProjects={project1Name}
                                seleted={selectedColumns}
                                droppableId={'droppable'}
                                projectId={this.state.project1}
                                currentTab={currentTab}
                                pageAccess={pageAccess}
                                toolTipHtmlContent={(item) => {
                                    return <HTMLTooltip {...item} />
                                }}
                                DragsearchHandler={(event, name) => {
                                    this.DragsearchHandler(event, name)
                                }}


                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <LoanProjectMerge
                                isDisableFirst={true}
                                disableProject={project2}
                                suggestions={projects}
                                value={project2}
                                name={'projectName2'}
                                onChange={(value, selectedProject) => {
                                    if (value === undefined) {
                                        this.setState({ project2: null, project2Name: [] })
                                        this.props.clearMerge()
                                    }
                                    else if (value === this.state.project1)
                                        this.props.showErrorAlert("Same projects are not allowed to merge");
                                    else {
                                        this.setState({
                                            project2: value,
                                            project2Name: selectedProject ? [{ ...selectedProject }] : []
                                        });
                                    }
                                }}
                                itemsProjects={project2Name}
                                selected={selectedColumns}
                                droppableId={'droppable2'}
                                projectId={this.state.project2}
                                currentTab={currentTab}
                                pageAccess={pageAccess}
                                toolTipHtmlContent={(item) => {
                                    return <HTMLTooltip {...item} />
                                }}
                                DragsearchHandler={(event, name) => {
                                    this.DragsearchHandler(event, name)
                                }}


                            />
                        </Grid>
                    </Grid>
                </DragDropContext>
            </div>
        )
    }
}

/**
 * Bind Proptypes
 */
Projects.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    handleMergeClick: PropTypes.func
};

/**
 * Default PropTypes
 */
Projects.defaultProps = {
    pageAccess: [],
    currentTab: null,
    handleMergeClick: () => { },
    clearMerge: () => { }
};

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        projects: state.merge.loanProjects,
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getProjectsByName: (text) => dispatch(mergeActions.getLoanProjectsByName()),
        getSitesByProjectId: (name, value) => dispatch(mergeActions.getSitesByProjectId(name, value)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearMergeSelectionDate: (tabName) => dispatch(mergeActions.clearMergeSelectionDate(tabName)),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Projects));