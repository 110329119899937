const exploreStyle = theme => ({

    //Explore
    container: {
        boxShadow: '0px 0px 2px grey',
        marginTop: 20
    },
    innerContainer: {
        border: '1px solid rgb(0,0,0, 0.1)',
        // overflow: 'auto',
        position: 'relative',
        padding: '0px !important'
    },

    //Menu
    menuContainer: {
        "& label": {
            width: '100%'
        },
    },
    menuHeaderContainer: {

    },
    menuHeaderWrapper: {
        // position: 'sticky',
        // top: 0
        display: 'flex',
        justifyContent: 'space-between',
        padding: 12,
    },
    flexBox: {
        display: 'flex'
    },
    heading: {
        alignSelf: 'center'
    },
    accordionDetails: {
        paddingLeft: 44,
        display: 'block'
    },
    menuHeader: {
        fontSize: 15,
        fontWeight: 'bold',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        // marginTop: 6
    },
    search: {
        // paddingBottom: 12
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, .03)',
        padding: '0 12px',
        "& searchip": {
            border: '1px solid #ddd',
            margin: '10px 16px',
            padding: '0px 10px',
            fontSize: '1rem',
            fontWeight: 500,
            borderBottom: 0,
            width: '100%'
        }
    },
    menuCheckBoxContainer: {
        overflow: 'auto',
        margin: 12
    },
    previewHeaderContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 0'
    },
    previewHeader: {
        fontSize: 15,
        fontWeight: 'bold',
        alignSelf: 'center',
        // marginTop: 6
    },
    previewContainer: {
        overflow: "auto",
        padding: '0 12px'
    },
    beforeContainer:{
        padding: '0 12px',
        overflow:'hidden'
    },
    button: {
        marginBottom: theme.spacing.unit * 1,
        marginLeft: theme.spacing(1),
    },
    leftSliderIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-7px',
        margin: 'auto',
        height: 48,
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 0 2px grey',
        borderRadius: 8,
        backgroundColor: '#fbab92',
        cursor: 'pointer',
        width: 12,
        zIndex: 1,
        '&:hover': {
            backgroundColor: '#fbab92'
        },
    },
    rightSliderIcon: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: '-7px',
        margin: 'auto',
        height: 48,
        alignItems: 'center',
        display: 'flex',
        boxShadow: '0 0 2px grey',
        borderRadius: 8,
        backgroundColor: '#fbab92',
        cursor: 'pointer',
        width: 12,
        zIndex: 1,
        '&:hover': {
            backgroundColor: '#fbab92'
        },
    },
    flexIcon: {
        display: 'flex',
        justifyContent: 'center'
    },
    rightSliderIconContainer: {
        width: "100%",
        height: '100%',
        paddingLeft: 1,
        "& i": {
            height: '100%',
            width: '100%'
        }
    },
    leftSliderIconContainer: {
        width: "100%",
        height: '100%',
        paddingRight: 1,
        "& i": {
            height: '100%',
            width: '100%'
        }
    }
})

export default exploreStyle