import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import FundersEdit from './FundersEdit';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * FundersNew container
 * @class FundersNew
 * @extends {Components}
 */
class FundersNew extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.funderRef = React.createRef();
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.funderRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {

        }
    };
    /**
   * Bind Html to DOM
   */
    render() {
        const { needPadding, pageAccess, projectId, isSubPage, title } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') <= -1;
        return (
            <ContentWrapper
                pageAccess={pageAccess}
                title={title}
                needPadding={needPadding}
                isSubPage={isSubPage}
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
            >
                <FundersEdit
                    isSubPage={isSubPage}
                    pageAccess={pageAccess}
                    projectId={projectId}
                    innerRef={(node) => { this.funderRef = node; }}
                    isReadOly={isReadOly}
                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                />
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
FundersNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool,
    pageAccess: PropTypes.array,
    title: PropTypes.string
};
/** Set the default props */
FundersNew.defaultProps = {
    pageAccess: ['SAVE'],
    isSubPage: false,
    needPadding: false,
    title: 'Claim Projects'
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {

    }
};
/**
 * Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {

    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(FundersNew));





