const textBoxStyle = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    input: {
        // fontSize:"0.875rem !important",
        height: 16
    },
    outlineTextBox: {
        borderRadius: "0px",
        paddingLeft: "0px",
        border: "1px #ddd",
        fontSize: "0.875rem",
        '& .MuiOutlinedInput-input': {
            padding: '9px 14px !important'
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '0px !important'
        }
    },
    subHeader: {
        backgroundColor: '#EFF9FA',
        color: '#36AEBE',
        fontSize: 14
    },
    menuItem: {
        borderBottom: '1px solid #ddd',
        '&:nth-child(odd)': {
            marginBottom: '12px !important',
            marginTop: '6px !important'
        },
        // '&:nth-child(odd)': {
        //     marginTop: '6px !important'
        // },
        '& .MuiIconButton-root': {
            marginLeft: '-9px'
        }
    },
    outlineNumber: {
        "& .MuiOutlinedInput-root": {
            paddingLeft: "45px",
            borderRadius: '0px !important'
        }
    },
    outlineParent: {
        position: "relative"
    },
    outlineLabel: {
        padding: "9.2px 17px",
        background: "#5EBECB",
        color: "#fff",
        borderTop: "1px solid #ddd",
        borderBottom: "1px solid #ddd",
        borderLeft: "1px solid #ddd",
        fontSize: "0.875rem",
        fontWeight: "600",
        borderRadius: "0px",
        width: "45px",
        position: "absolute",
        top: "4px",
       
    },
    root: {
        width: '100%',
        background: '#f3f3f3',
        padding: 20,
        borderRadius: 6,
        border: '1px solid #e0e0e0',
        position:'relative'
    },
    subText: {
        fontWeight: 'bold',
        textAlign: 'right',
        fontStyle: 'italic'
    },
    linkText: {
        fontWeight: 'bold',
        textAlign: 'right',
        fontStyle: 'italic',
        cursor: 'pointer'
    }
});

export default textBoxStyle;
