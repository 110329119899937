import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Import Components
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import MergeDragandDrop from "../../components/MergeDragandDrop/MergeDragandDrop";

//Import Helpers
import ValidatorForm from "../../helpers/fieldValidations";

// Import Styles
import textBoxStyle from '../../assets/jss/components/textBox';

/**
 *Merge two projects
 *
 * @class ProjectMerge
 * @extends {Component}
 */
class LoanProjectMerge extends Component {
    /**
     *Creates an instance of ProjectMerge.
     * @param {*} props
     * @memberof LoanProjectMerge
     */
    constructor(props) {
        super(props);
        //state declaration
        this.state = {

        };
    }
    /**
     * Bind
     * HTML to DOM
     */
    render() {
        let { classes, suggestions, needPadding, value, name, itemsSites, itemsProjects, itemsBuildings,
            suggestions_sites, selected, droppableId, currentTab, sitename, sitevalue, suggestions_buildings, itemsUnits, buildingname, buildingvalue, isDisableFirst, disableProject, DragsearchHandler, module, isMergeToSection } = this.props;
        let items = (currentTab === 0) ? itemsProjects : (currentTab === 1 && module==='HomeOwnership' && isMergeToSection) ? itemsSites:(currentTab === 1) ? itemsSites : (currentTab === 2) ? itemsBuildings : itemsUnits;
        console.log('disableProject_HO',disableProject)
        return (
            <ContentWrapper
                classes={classes}
                ischildPage={true}
                isSubPage={true}
                needPadding={needPadding}
                needTitleBar={false}
                isMergePage={true}
                parentCardBoxShadowClass='boxShadowWidthPadd-24'
            >
                <ValidatorForm
                    name="userForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmitMerge}
                    onError={errors => {}} >
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <AutoComplete
                                suggestions={suggestions}
                                name={name}
                                handleAutoCompolete={(selectedOption) => {
                                    this.props.onChange(selectedOption.value, selectedOption)
                                }}
                                selectedValue={{ id: value }}
                                placeholder={droppableId === 'droppable' ? "Select From Project" : "Select To Project"}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        {
                            ((currentTab === 1 && module==='HomeOwnership' && isMergeToSection) || currentTab === 2 || currentTab === 3) &&
                            <Grid item sm={12}>
                                <AutoComplete
                                    suggestions={suggestions_sites}
                                    name={sitename}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.props.onSiteChange(selectedOption.value, selectedOption)
                                    }}
                                    selectedValue={{ id: sitevalue }}
                                    placeholder="Select Contract"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                        }
                        {
                            currentTab === 3 &&
                            <Grid item sm={12}>
                                <AutoComplete
                                    suggestions={suggestions_buildings}
                                    name={buildingname}
                                    handleAutoCompolete={(selectedOption) => {
                                        this.props.onBuildingChange(selectedOption.value, selectedOption)
                                    }}
                                    selectedValue={{ id: buildingvalue }}
                                    placeholder="Select Record Number"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                        }
                        <Grid item sm={12}>
                            <MergeDragandDrop
                                isDisableFirst={isDisableFirst}
                                disableProject={disableProject}
                                droppableId={droppableId}
                                currentTab={currentTab}
                                selected={selected}
                                placement={droppableId === 'droppable' ? 'left' : 'right'}
                                items={items}
                                onChange={(selected) => {
                                    this.setState({
                                        selected
                                    });
                                }}
                                noDataErrorMessage={"No Records Found"}
                                toolTipHtmlContent={(item) => {
                                    return this.props.toolTipHtmlContent(item)
                                }}
                                DragsearchHandler={DragsearchHandler}
                            />
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </ContentWrapper >

        )
    }
}
// set default props
LoanProjectMerge.defaultProps = {
    suggestions: [],
    isSubPage: false,
    needPadding: true,
    value: null,
    name: '',
    suggestions_sites: [],
    itemsProjects: [],
    onChange: () => { },
    sitevalue: null,
    sitename: '',
    itemsSites: [],
    onSiteChange: () => { },
    itemsBuildings: [],
    buildingvalue: null,
    buildingname: '',
    suggestions_buildings: [],
    onBuildingChange: () => { },
    itemsUnits: [],
    selected: [],
    droppableId: '',
    currentTab: null,
    projectId: null,
    pageAccess: [],
    handleMerge: () => { },
    toolTipHtmlContent: () => { },
    isDisableFirst: false,
    disableProject: null,
    DragsearchHandler: () => { },
    module:'MultiFamily',
    isMergeToSection:false
}
//prop types
LoanProjectMerge.propTypes = {
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.array,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool,
    suggestions_sites: PropTypes.array,
    name: PropTypes.string,
    sitename: PropTypes.string,
    buildingname: PropTypes.string,
    suggestions_buildings: PropTypes.array,
    onChange: PropTypes.func,
    onSiteChange: PropTypes.func,
    onBuildingChange: PropTypes.func,
    droppableId: PropTypes.string,
    handleMerge: PropTypes.func,
    module: PropTypes.string,
    //handle config specific for to section
    isMergeToSection: PropTypes.bool

};
//export component
export default withStyles(textBoxStyle)(LoanProjectMerge);