
export const occupantsConstants = {

  // occupants listing data
  GETALL_REQUEST: 'OCCUPANTS_GETALL_REQUEST',
  GETALL_SUCCESS: 'OCCUPANTS_GETALL_SUCCESS',
  GETALL_FAILURE: 'OCCUPANTS_GETALL_FAILURE',

  // set and reset filter
  CLEAR_FILTER_SET: 'OCCUPANTS_CLEAR_FILTER',
  CLEAR_FILTER_RESET: 'OCCUPANTS_CLEAR_FILTER_RESET',

  // To Get Occupatn Detail
  GET_OCCUPANTS_DETAILS_REQUEST: 'GET_OCCUPANTS_DETAILS_REQUEST',
  GET_OCCUPANTS_DETAILS_SUCCESS: 'GET_OCCUPANTS_DETAILS_SUCCESS',
  GET_OCCUPANTS_DETAILS_FAILURE: 'GET_OCCUPANTS_DETAILS_FAILURE',

  // update house hold memebers info
  UPDATE_OCCUPANTS_HHI_DETAILS_REQUEST: 'UPDATE_OCCUPANTS_HHI_DETAILS_REQUEST',
  UPDATE_OCCUPANTS_HHI_DETAILS_SUCCESS: 'UPDATE_OCCUPANTS_HHI_DETAILS_SUCCESS',
  UPDATE_OCCUPANTS_HHI_DETAILS_FAILURE: 'UPDATE_OCCUPANTS_HHI_DETAILS_FAILURE',

  // update house hold income details
  UPDATE_OCCUPANTS_HHINCOME_DETAILS_REQUEST: 'UPDATE_OCCUPANTS_HHINCOME_DETAILS_REQUEST',
  UPDATE_OCCUPANTS_HHINCOME_DETAILS_SUCCESS: 'UPDATE_OCCUPANTS_HHINCOME_DETAILS_SUCCESS',
  UPDATE_OCCUPANTS_HHINCOME_DETAILS_FAILURE: 'UPDATE_OCCUPANTS_HHINCOME_DETAILS_FAILURE',

  // update Month Rent Data details
  UPDATE_OCCUPANTS_MRD_DETAILS_REQUEST: 'UPDATE_OCCUPANTS_MRD_DETAILS_REQUEST',
  UPDATE_OCCUPANTS_MRD_DETAILS_SUCCESS: 'UPDATE_OCCUPANTS_MRD_DETAILS_SUCCESS',
  UPDATE_OCCUPANTS_MRD_DETAILS_FAILURE: 'UPDATE_OCCUPANTS_MRD_DETAILS_FAILURE',

  // To Update first tab "General" Details
  UPDATE_OCCUPANTS_DETAILS_REQUEST: 'UPDATE_OCCUPANTS_DETAILS_REQUEST',
  UPDATE_OCCUPANTS_DETAILS_SUCCESS: 'UPDATE_OCCUPANTS_DETAILS_SUCCESS',
  UPDATE_OCCUPANTS_DETAILS_FAILURE: 'UPDATE_OCCUPANTS_DETAILS_FAILURE',

  // to add new occupants get associated project, sites and buildings details
  GET_ASSOC_PROJECTS_CONFIG_REQUEST: 'OCCUPANTS_GET_ASSOC_PROJECTS_CONFIG_REQUEST',
  GET_ASSOC_PROJECTS_CONFIG_SUCCESS: 'OCCUPANTS_GET_ASSOC_PROJECTS_CONFIG_SUCCESS',
  GET_ASSOC_PROJECTS_CONFIG_FAILURE: 'OCCUPANTS_GET_ASSOC_PROJECTS_CONFIG_FAILURE',

  // get vacant / empty units
  CLEAR_EMPTY_UNITS: 'OCCUPANTS_CLEAR_EMPTY_UNITS',
  GET_EMPTY_UNITS_REQUEST: 'OCCUPANTS_GET_EMPTY_UNITS_REQUEST',
  GET_EMPTY_UNITS_SUCCESS: 'OCCUPANTS_GET_EMPTY_UNITS_SUCCESS',
  GET_EMPTY_UNITS_FAILURE: 'OCCUPANTS_GET_EMPTY_UNITS_FAILURE',

  // get vacant / empty units
  GET_VACANT_UNITS_REQUEST: 'OCCUPANTS_GET_VACANT_UNITS_REQUEST',
  GET_VACANT_UNITS_SUCCESS: 'OCCUPANTS_GET_VACANT_UNITS_SUCCESS',
  GET_VACANT_UNITS_FAILURE: 'OCCUPANTS_GET_VACANT_UNITS_FAILURE',

  // get funder details
  GET_FUNDER_DETAILS_REQUEST: 'OCCUPANTS_FUNDER_DETAILS_REQUEST',
  GET_FUNDER_DETAILS_SUCCESS: 'OCCUPANTS_FUNDER_DETAILS_SUCCESS',
  GET_FUNDER_DETAILS_FAILURE: 'OCCUPANTS_FUNDER_DETAILS_FAILURE',

  // move in or add
  MOVE_IN_OCCUPANT_REQUEST: 'OCCUPANTS_MOVE_IN_OCCUPANT_REQUEST',
  MOVE_IN_OCCUPANT_SUCCESS: 'OCCUPANTS_MOVE_IN_OCCUPANT_SUCCESS',
  MOVE_IN_OCCUPANT_FAILURE: 'OCCUPANTS_MOVE_IN_OCCUPANT_FAILURE',

  // transfer occupant
  TRANSFER_OCCUPANT_REQUEST: 'OCCUPANTS_TRANSFER_OCCUPANT_REQUEST',
  TRANSFER_OCCUPANT_SUCCESS: 'OCCUPANTS_TRANSFER_OCCUPANT_SUCCESS',
  TRANSFER_OCCUPANT_FAILURE: 'OCCUPANTS_TRANSFER_OCCUPANT_FAILURE',

  // save temp move in data
  OCCUPANTS_TEMP_SAVE_MOVEIN: 'OCCUPANTS_TEMP_SAVE_MOVEIN',

  // clear local data
  OCCUPANTS_CLEAR_TEMP_SAVE_MOVEIN: 'OCCUPANTS_CLEAR_TEMP_SAVE_MOVEIN',

  // get move in config
  OCCUPANTS_GET_MOVEIN_CONFIG_REQUEST: 'OCCUPANTS_GET_MOVEIN_CONFIG_REQUEST',
  OCCUPANTS_GET_MOVEIN_CONFIG_SUCCESS: 'OCCUPANTS_GET_MOVEIN_CONFIG_SUCCESS',
  OCCUPANTS_GET_MOVEIN_CONFIG_FAILURE: 'OCCUPANTS_GET_MOVEIN_CONFIG_FAILURE',

  // get occupants validations
  OCCUPANTS_GET_ONE_VALIDATION_ERR_REQUEST: 'OCCUPANTS_GET_ONE_VALIDATION_ERR_REQUEST',
  OCCUPANTS_GET_ONE_VALIDATION_ERR_SUCCESS: 'OCCUPANTS_GET_ONE_VALIDATION_ERR_SUCCESS',
  OCCUPANTS_GET_ONE_VALIDATION_ERR_FAILURE: 'OCCUPANTS_GET_ONE_VALIDATION_ERR_FAILURE',

  OCCUPANTS_GET_ONE_CLEAR_VALIDATION_ERR: 'OCCUPANTS_GET_ONE_CLEAR_VALIDATION_ERR',

  // delete occupant
  OCCUPANTS_DELETE_ONE_REQUEST: 'OCCUPANTS_DELETE_ONE_REQUEST',
  OCCUPANTS_DELETE_ONE_SUCCESS: 'OCCUPANTS_DELETE_ONE_SUCCESS',
  OCCUPANTS_DELETE_ONE_FAILURE: 'OCCUPANTS_DELETE_ONE_FAILURE',

  OCCUPANTS_SHOW_LOADING:'OCCUPANTS_SHOW_LOADING',
  OCCUPANTS_HIDE_LOADING:'OCCUPNATS_HIDE_LOADING'
};