export const dashboardConstants = {
    // Dashboard PM needs action list
    GET_PROJECTSLIST_REQUEST: 'DASHBOARD_PROJECTSLIST_REQUEST',
    GET_PROJECTSLIST_SUCCESS: 'DASHBOARD_PROJECTSLIST_SUCCESS',
    GET_PROJECTSLIST_FAILURE: 'DASHBOARD_PROJECTSLIST_FAILURE',

    // Dashboard PM effective date
    GET_EFFECTIVELIST_REQUEST: 'DASHBOARD_EFFECTIVELIST_REQUEST',
    GET_EFFECTIVELIST_SUCCESS: 'DASHBOARD_EFFECTIVELIST_SUCCESS',
    GET_EFFECTIVELIST_FAILURE: 'DASHBOARD_EFFECTIVELIST_FAILURE',

    // Dashboard Needs Action List
    GETALL_PROJECTSLIST_REQUEST: 'DASHBOARD_GETALL_PROJECTSLIST_REQUEST',
    GETALL_PROJECTSLIST_SUCCESS: 'DASHBOARD_GETALL_PROJECTSLIST_SUCCESS',
    GETALL_PROJECTSLIST_FAILURE: 'DASHBOARD_GETALL_PROJECTSLIST_FAILURE',

    // Dashboard Dropdown data
    GETALL_PROJECTSUMMARY_REQUEST: 'DASHBOARD_GETALL_PROJECTSUMMARY_REQUEST',
    GETALL_PROJECTSUMMARY_SUCCESS: 'DASHBOARD_GETALL_PROJECTSUMMARY_SUCCESS',
    GETALL_PROJECTSUMMARY_FAILURE: 'DASHBOARD_GETALL_PROJECTSUMMARY_FAILURE',

    // Dashboard compliance issue chart
    GETALL_COMPLIANCE_ISSUE_REQUEST: 'DASHBOARD_COMPLIANCE_ISSUE_REQUEST',
    GETALL_COMPLIANCE_ISSUE_SUCCESS: 'DASHBOARD_COMPLIANCE_ISSUE_SUCCESS',
    GETALL_COMPLIANCE_ISSUE_FAILURE: 'DASHBOARD_COMPLIANCE_ISSUE_FAILURE',

    // Dashboard compliance issue chart
    GETALL_COMPLIANCE_NEEDSACTION_REQUEST: 'DASHBOARD_COMPLIANCE_NEEDSACTION_REQUEST',
    GETALL_COMPLIANCE_NEEDSACTION_SUCCESS: 'DASHBOARD_COMPLIANCE_NEEDSACTION_SUCCESS',
    GETALL_COMPLIANCE_NEEDSACTION_FAILURE: 'DASHBOARD_COMPLIANCE_NEEDSACTION_FAILURE',

    // Dashboard occupancy rate chart
    GETALL_OCCUPANCY_RATE_REQUEST: 'DASHBOARD_OCCUPANCY_RATE_REQUEST',
    GETALL_OCCUPANCY_RATE_SUCCESS: 'DASHBOARD_OCCUPANCY_RATE_SUCCESS',
    GETALL_OCCUPANCY_RATE_FAILURE: 'DASHBOARD_OCCUPANCY_RATE_FAILURE',

    // Dashboard Report status chart
    GETALL_REPORT_STATUS_REQUEST: 'DASHBOARD_REPORT_STATUS_REQUEST',
    GETALL_REPORT_STATUS_SUCCESS: 'DASHBOARD_REPORT_STATUS_SUCCESS',
    GETALL_REPORT_STATUS_FAILURE: 'DASHBOARD_REPORT_STATUS_FAILURE',

    // Dashboard Project Summary Report HTML View
    PROJECT_SUMMARY_REPORT_VIEW_REQUEST: 'DASHBOARD_PROJECT_SUMMARY_REPORT_VIEW_REQUEST',
    PROJECT_SUMMARY_REPORT_VIEW_SUCCESS: 'DASHBOARD_PROJECT_SUMMARY_REPORT_VIEW_SUCCESS',
    PROJECT_SUMMARY_REPORT_VIEW_FAILURE: 'DASHBOARD_PROJECT_SUMMARY_REPORT_VIEW_FAILURE',

    // Dashboard Project Summary Report Download more than once
    PROJECT_SUMMARY_REPORT_DOWNLOAD_REQUEST: 'DASHBOARD_PROJECT_SUMMARY_REPORT_DOWNLOAD_REQUEST',
    PROJECT_SUMMARY_REPORT_DOWNLOAD_SUCCESS: 'DASHBOARD_PROJECT_SUMMARY_REPORT_DOWNLOAD_SUCCESS',
    PROJECT_SUMMARY_REPORT_DOWNLOAD_FAILURE: 'DASHBOARD_PROJECT_SUMMARY_REPORT_DOWNLOAD_FAILURE',

    // Dashboard report name
    GET_REPORTNAME_REQUEST: 'FUNDERDATAEXPORT_REPORTNAME_REQUEST',
    GET_REPORTNAME_SUCCESS: 'FUNDERDATAEXPORT_REPORTNAME_SUCCESS',
    GET_REPORTNAME_FAILURE: 'FUNDERDATAEXPORT_REPORTNAME_FAILURE',

    // selected Year 
    GETALL_YEAR_REQUEST: 'FUNDERDATAEXPORT_GETALL_YEAR_REQUEST',
    GETALL_YEAR_SUCCESS: 'FUNDERDATAEXPORT_GETALL_YEAR_SUCCESS',
    GETALL_YEAR_FAILURE: 'FUNDERDATAEXPORT_GETALL_YEAR_FAILURE',

    // Dashboard Tenant and Funder download
    GETALL_DOWNLOAD_REQUEST: 'FUNDERDATAEXPORT_GETALL_DOWNLOAD_REQUEST',
    GETALL_DOWNLOAD_SUCCESS: 'FUNDERDATAEXPORT_GETALL_DOWNLOAD_SUCCESS',
    GETALL_DOWNLOAD_FAILURE: 'FUNDERDATAEXPORT_GETALL_DOWNLOAD_FAILURE',

    // Project Summary Report View and Download the report
    PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_REQUEST: 'DASHBOARD_PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_REQUEST',
    PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_SUCCESS: 'DASHBOARD_PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_SUCCESS',
    PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_FAILURE: 'DASHBOARD_PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_FAILURE',

    // PSBU Counts
    PSBU_COUNT_REQUEST: 'PSBU_COUNT_REQUEST',
    PSBU_COUNT_SUCCESS: 'PSBU_COUNT_SUCCESS',
    PSBU_COUNT_FAILURE: 'PSBU_COUNT_FAILURE',

    // Users Summary
    USERSSUMMARY_REQUEST: 'USERSSUMMARY_REQUEST',
    USERSSUMMARY_SUCCESS: 'USERSSUMMARY_SUCCESS',
    USERSSUMMARY_FAILURE: 'USERSSUMMARY_FAILURE',

    GET_PROJECT_LIST_REQUEST: 'DASHBOARD_GET_PROJECT_LIST_REQUEST',
    GET_PROJECT_LIST_SUCCESS: 'DASHBOARD_GET_PROJECT_LIST_SUCCESS',
    GET_PROJECT_LIST_FAILURE: 'DASHBOARD_GET_PROJECT_LIST_FAILURE',

};
