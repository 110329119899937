import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ValidatorForm } from 'react-material-ui-form-validator';


// Import Components
//import ValidatorForm from "../../../../helpers/fieldValidations";
import EditableTable from '../../../../components/EditableTable/EditableTable';
import TextBox from '../../../../components/TextBox/TextBox';
import AutoComplete from '../../../../components/AutoComplete/AutoComplete';
import SaveCancel from "../../../../components/SaveCancel/SaveCancel";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import NumberField from '../../../../components/NumberField/NumberField';
import TableErrors from '../../../../components/TableErrors/TableErrors';
import ContentWrapper from "../../../../components/ContentWrapper/ContentWrapper";
import DatePicker from '../../../../components/DatePicker/DatePicker';
import TextArea from '../../../../components/TextArea/TextArea';

// Import Actions
import { reportTablesActions } from '../../../../actions/reportTables';
import { confActions } from '../../../../actions/configuration';
import { configurationConstants } from '../../../../helpers/appConstants';
import { alertActions, exploreActions } from "../../../../actions";

// Import Styles
import pageStyle from '../../../../assets/jss/containers/common';

// Import Helpers 
import { rentPeriodDetails } from "../../../../helpers/appConstants";
import Table2FarmworkerValidations from '../../../../helpers/TableValidations/Table2Farmworker';
import { history, getSubmissionLevel, ReportLevelsConstants, getDateDifference,convertDateWithTimeZone } from '../../../../helpers';

// Table validations warning helper
import { hasWarningsSection } from '../../../../helpers/TableValidations/warnings';
import { WarningNames } from './FarmWorkerMessages';
import moment from 'moment';

const DEFAULT_CONFIG = {
    race: []
};

const fieldClass = {
    raceCount: 'txt-right',
    hispanicCount: 'txt-right',
};
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextBox
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                //validators={['required']}
                disabled={isReadOly}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    raceCount: EditableText,
    hispanicCount: EditableText,
    comment: EditableText
}
/**
 * Table2FarmWorker Component
 * @class Table2FarmWorker
 * @extends {Component}
 */
class Table2FarmWorker extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.section6 = React.createRef();
        this.section7 = React.createRef();
        this.section8 = React.createRef();
        this.state = {
            temporaryFarmworkerHousing: '',
            reportYearDatesofOperation: '',
            reportYearDatesofOperationEnd: '',
            totalOccupiedBednights: '',
            totalBednightsAvailable: '',
            averageLengthofStay: '',
            maximumLengthofStay: '',
            totalHouseholdsServed: '',
            label0to30: '',
            label31to50: '',
            label51to80: '',
            over80: '',
            householdIncome: '',
            race: [],
            fields: [],
            totalNumberofFamilyGroupHouseholdsServed: '',
            totalNumberofIndividualHouseholdsServed: '',
            numberofMinors: '',
            // totalSingleParentHouseholds: '',
            totalNumberofOccupants: '',
            totalRentHouseholdsinRestrictedUnit: '',
            totalOwnerHouseholdsinRestrictedUnits: '',
            restrictedYearTotal: '',
            restrictedYeartotalHouseholdServed: '',
            
            rentPeriod_id: '',
            chargedtoTenantsbed: '',
            chargedtoEmployersbed: '',
            chargedtoTenantsUnit: '',
            chargedtoEmployersUnit: '',

            comments: '',
            allDetails: [],
            config: {
                ...DEFAULT_CONFIG
            },

            type: null,
            popupContent: null,
            Total: '',
            rentPeriodLimits: [],
            isloaded: false,
            notes: '',
            reportYear: null,
            error: null,
            created:false
        }
    }
    /**
     * Component Will mount
     * 
     * [1]  -   get the table 2 data
     * [2]  -   Get Race Config
     * [3]  -   get validation issues
     */
    UNSAFE_componentWillMount() {
        const { reportId } = this.props;
        
        ValidatorForm.addValidationRule('isValidToDate', (value) => {
            const { reportYearDatesofOperation, reportYearDatesofOperationEnd } = this.state;
            if (reportYearDatesofOperation && reportYearDatesofOperationEnd) {
                let fromDate = moment(new Date(reportYearDatesofOperation))
                let endDate = moment(new Date(reportYearDatesofOperationEnd))
                const days = getDateDifference(fromDate, endDate, 'days');
                return days > 1;
            }
            return false;
        });

        if (reportId) {
            // [1]
            this.props.getTable2Data(reportId);
        }
        // [2]
        this.props.getConfiguration(configurationConstants.race);
        // [3]
        if (reportId) {
            this.props.validationIssues(0, 0, reportId, "2");
        }
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Rent
     * @param {*} name 
     * @param {*} value 
     */
    handleRent = (name, value) => {
        const re = /^\d*(?:[.,]\d*)?$/;
        if (re.test(value)) {
            this.setState({ [name]: value });
        }
    };
    /**
     * Hande Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save Form
     * 
     * [1]  -   set up api form submission datas  
     */
    _saveForm() {
        // [1]
        const id = this.props.reportId
        const { reportId } = this.props;
        const table = this.state.allDetails.map((it) => {
            return {
                "_id": it._id,
                "race_id": it.race_id,
                "raceCount": parseInt(it.raceCount, 10),
                "hispanicCount": parseInt(it.hispanicCount, 10),
                "comment": it.comment
            }
        })
        const generalData = {
            table,
            temporaryFarmworkerHousing: parseInt(this.state.temporaryFarmworkerHousing, 10),
            //Section 2
            reportYearDatesofOperation: (this.state.reportYearDatesofOperation),
            reportYearDatesofOperationEnd: (this.state.reportYearDatesofOperationEnd),
            totalOccupiedBednights: parseInt(this.state.totalOccupiedBednights, 10),
            totalBednightsAvailable: parseInt(this.state.totalBednightsAvailable, 10),
            //Section 3
            averageLengthofStay: parseInt(this.state.averageLengthofStay, 10),
            maximumLengthofStay: parseInt(this.state.maximumLengthofStay, 10),

            //Section 4
            rentPeriod_id: parseFloat(this.state.rentPeriod_id, 10),
            chargedtoTenantsbed: parseFloat(this.state.chargedtoTenantsbed, 10),
            chargedtoEmployersbed: parseFloat(this.state.chargedtoEmployersbed, 10),
            chargedtoTenantsUnit: parseFloat(this.state.chargedtoTenantsUnit, 10),
            chargedtoEmployersUnit: parseFloat(this.state.chargedtoEmployersUnit, 10),

            //Section 5
            totalNumberofFamilyGroupHouseholdsServed: parseInt(this.state.totalNumberofFamilyGroupHouseholdsServed, 10),
            totalNumberofIndividualHouseholdsServed: parseInt(this.state.totalNumberofIndividualHouseholdsServed, 10),
            totalHouseholdsServed: parseInt(this.state.totalHouseholdsServed, 10),

            //Section 7               
            label0to30: parseInt(this.state.label0to30, 10),
            label31to50: parseInt(this.state.label31to50, 10),
            label51to80: parseInt(this.state.label51to80, 10),
            over80: parseInt(this.state.over80, 10),
            householdIncome: parseInt(this.state.householdIncome, 10),

            //Section 8
            totalNumberofOccupants: parseInt(this.state.totalNumberofOccupants, 10),
            numberofMinors: parseInt(this.state.numberofMinors, 10),
            // totalSingleParentHouseholds: parseInt(this.state.totalSingleParentHouseholds, 10),

            //Section 9

            comments: this.state.comments,

            customeType: 'Farmworker',
            notes: this.state.notes,
            reportId: this.props.reportId,
            reportYear_id: this.props.reportId
        };

        // if (id && reportId) {
        //     // update table 2
        //     this.props.getTable2DataUpdate(id, generalData);
        // } else {
        //     // create table 2
        //     this.props.createTable2(generalData);
        // }
        if (this.state._id && reportId && !this.state.created) {
            // update table 3
            this.setState({created:true})
            this.props.getTable2DataUpdate(id, generalData);

        } else {
            if(!this.state.created){
            // create table 3
            this.props.createTable2(generalData,(data)=>{
                this.setState({created:true})
                //to handle validation clear issue on first save
                setTimeout(()=>{
                    this.props.getTable2DataUpdate(id, generalData);
                })
            },1000);
            }
        }
    }
    /**
     * Cell Component Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { allDetails } = this.state;
        allDetails[rowIndex][fieldName] = value;
        this.setState({ allDetails });
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const id = this.props.reportId
        if (id) {
            this.props.getTable2Data(id);
        }
    }
    /**
     * Component Will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, races } = nextProps;
        const id = getOne.data.data ? getOne.data.data._id : null;
        if (getOne.data.data && races.length > 0 && (!this.state.isloaded || this.state._id !== id)) {

            let race = races.map(r => {
                return {
                    desc: r.desc,
                    id: r.key
                }
            });
            this.setState({
                isloaded: true,
                //Section 1
                temporaryFarmworkerHousing: getOne.data.data.farmworkerBedCount ? getOne.data.data.farmworkerBedCount : "0",

                //Section 2
                reportYearDatesofOperation: (getOne.data.data.table2ReportYearDateOfOperationFirstDate ? convertDateWithTimeZone(getOne.data.data.table2ReportYearDateOfOperationFirstDate) : 0),
                reportYearDatesofOperationEnd: (getOne.data.data.table2ReportYearDateOfOperationLastDate ? convertDateWithTimeZone(getOne.data.data.table2ReportYearDateOfOperationLastDate) : 0),
                totalOccupiedBednights: getOne.data.data.totalOccupiedBedNights ? getOne.data.data.totalOccupiedBedNights : "0",
                totalBednightsAvailable: getOne.data.data.totalBedNightsAvailable ? getOne.data.data.totalBedNightsAvailable : "0",

                //Section 3
                averageLengthofStay: getOne.data.data.table2AverageLengthOfStay ? getOne.data.data.table2AverageLengthOfStay : 0,
                maximumLengthofStay: getOne.data.data.table2MaximumLengthOfStay ? getOne.data.data.table2MaximumLengthOfStay : 0,

                //Section 4                 
                rentPeriod_id: getOne.data.data.rentPeriod_id ? parseInt(getOne.data.data.rentPeriod_id, 10) : this.state.rentPeriod_id !== '' ? this.state.rentPeriod_id : "0",
                chargedtoTenantsbed: getOne.data.data.individualRentChargedToTennants ? parseFloat(getOne.data.data.individualRentChargedToTennants, 10).toFixed(2) : "0.00",
                chargedtoEmployersbed: getOne.data.data.individualRentChargedToEmployer ? parseFloat(getOne.data.data.individualRentChargedToEmployer, 10).toFixed(2) : "0.00",
                chargedtoTenantsUnit: getOne.data.data.householdRentChargedToTennants ? parseFloat(getOne.data.data.householdRentChargedToTennants, 10).toFixed(2) : "0.00",
                chargedtoEmployersUnit: getOne.data.data.householdRentChargedToEmployer ? parseFloat(getOne.data.data.householdRentChargedToEmployer, 10).toFixed(2) : "0.00",

                //Section 5
                totalNumberofFamilyGroupHouseholdsServed: getOne.data.data.totalFamilyHouseholdsServed ? getOne.data.data.totalFamilyHouseholdsServed : "0",
                totalNumberofIndividualHouseholdsServed: getOne.data.data.totalIndividualHouseholdsServed ? getOne.data.data.totalIndividualHouseholdsServed : "0",
                totalHouseholdsServed:
                    parseInt(getOne.data.data.totalFamilyHouseholdsServed ? getOne.data.data.totalFamilyHouseholdsServed : 0, 10) +
                    parseInt(getOne.data.data.totalIndividualHouseholdsServed ? getOne.data.data.totalIndividualHouseholdsServed : 0, 10),

                //Section 6
                race: races.map(r => {
                    return {
                        desc: r.desc,
                        id: r.key
                    }
                }),
                table2race: getOne.data.data.table2RaceEthnicity ? getOne.data.data.table2RaceEthnicity : [],
                allDetails: race.map((s) => {
                    const Details = getOne.data.data.table2RaceEthnicity.filter(gs => gs.race_id === s.id)[0] || { raceCount: 0, hispanicCount: 0, comment: '' };
                    return {
                        ...Details,
                        desc: s.desc,
                        raceCount: Details.raceCount,
                        hispanicCount: Details.hispanicCount,
                        race_id: s.id
                    }
                }),
                //Section 7               
                label0to30: getOne.data.data.householdIncome0To30Percent ? getOne.data.data.householdIncome0To30Percent : 0,
                label31to50: getOne.data.data.householdIncome31To50Percent ? getOne.data.data.householdIncome31To50Percent : 0,
                label51to80: getOne.data.data.householdIncome51To80Percent ? getOne.data.data.householdIncome51To80Percent : 0,
                over80: getOne.data.data.householdIncomeAbove80Percent ? getOne.data.data.householdIncomeAbove80Percent : 0,
                householdIncome: parseInt(getOne.data.data.householdIncome0To30Percent ? getOne.data.data.householdIncome0To30Percent : 0, 10)
                    + parseInt(getOne.data.data.householdIncome31To50Percent ? getOne.data.data.householdIncome31To50Percent : 0, 10)
                    + parseInt(getOne.data.data.householdIncome51To80Percent ? getOne.data.data.householdIncome51To80Percent : 0, 10)
                    + parseInt(getOne.data.data.householdIncomeAbove80Percent ? getOne.data.data.householdIncomeAbove80Percent : 0, 10),
                raceHeader: ['Race', 'Head of Household count by Race', 'Head of Household count of Hispanic Ethnicity', 'Comments'],
                //Section 8
                totalNumberofOccupants: getOne.data.data.table2TotalNumberOccupants ? getOne.data.data.table2TotalNumberOccupants : 0,
                numberofMinors: getOne.data.data.table2TotalNumberMinors ? getOne.data.data.table2TotalNumberMinors : 0,
                // totalSingleParentHouseholds: '',

                //Section 9
                comments: '',

                Total: getOne.data.data.totalRestrictedUnitsServedByPercent_Total ? getOne.data.data.totalRestrictedUnitsServedByPercent_Total : 0,

                notes: getOne.data.data.notes ? getOne.data.data.notes : "",
                _id: getOne.data.data._id,
                reportYear_id: getOne.data.reportYear_id,
                reportYear: getOne.data.desc ? getOne.data.desc.year : null,
            });
        }
        // update config
        if (this.state.config.race.length === 0 && races.length > 0) {
            this.setState({
                config: {
                    race: races
                }
            })
        }
        this.setState({created:false})
    }

    getTotal = () => {
        return this.state.allDetails.reduce((a, b) => a + parseFloat(b.raceCount === '' || b.raceCount === null ? 0 : b.raceCount), 0);
    }

    getTotalHispanic = () => {
        return this.state.allDetails.reduce((a, b) => a + parseFloat(b.hispanicCount === '' || b.hispanicCount === null ? 0 : b.hispanicCount), 0);
    }


    getTotalNumberofHouseholdServed = () => {
        const { totalNumberofFamilyGroupHouseholdsServed, totalNumberofIndividualHouseholdsServed } = this.state;
        const total = parseInt(totalNumberofFamilyGroupHouseholdsServed ? totalNumberofFamilyGroupHouseholdsServed : 0, 10) +
            parseInt(totalNumberofIndividualHouseholdsServed ? totalNumberofIndividualHouseholdsServed : 0, 10)
        return total;
    }

    getTotalHouseholdIncome = () => {
        const { label0to30, label31to50, label51to80, over80 } = this.state;
        return parseInt(label0to30 ? label0to30 : 0, 10) + parseInt(label31to50 ? label31to50 : 0, 10) + parseInt(label51to80 ? label51to80 : 0, 10) + parseInt(over80 ? over80 : 0, 10)
    }
    /**
     * Handle Submit
     * @param {*} type 
     * @returns 
     * 
     * [1]  -   if not saved atleast once
     * [2]  -   disable submit when has hardstop
     * [3]  -   if saved atleast once
     */
    handleSubmitActionClick = (type) => {
        // [1]
        if (this.state._id === undefined) {
            return this.setState({
                error: {
                    message: 'Table 2 must be saved at least once before Submission'
                }
            });
        }
        // [2]
        const { validations } = this.props;
        if (validations && type === 'SUBMIT') {
            if (validations.data.table2 === 3) {
                this.props.showErrorAlert('Need to fix Hard stop errors before submit!');
                return;
            } else if (!validations.data.isPrevSubmitted) {
                this.props.showErrorAlert('Previous Year Report Not Submitted!');
                return;
            } else { }
        }

        // [3]
        this.setState({
            type,
            popupContent: this.popAlert(type)
        });
    }
    /**Table 2 Download Report */
    handleDownloadActionClick = () => {
        const { getOne } = this.props;
        const reportData = {
            reportYear_id: getOne.data.desc._id,
            project_id: getOne.data.desc.project_id,
            site_id: getOne.data.desc.site_id,
            year: getOne.data.desc.year
        }

        this.props.downloadTable2Report(reportData.project_id, reportData.site_id, reportData.year);
    }
    /**
     * Pop Alert
     * @param {*} type 
     * @returns 
     */
    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess } = this.props;
        const isReadOly = pageAccess.indexOf('UNSUBMIT') === -1;

        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                disabled={isReadOly}
                                multiline={true}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }
    /**
     * Handle submit report
     */
    handleSubmitReport = () => {
        if (this.state._id) {
            this._submitReport();
        }
    }
    /**
     * Submit report 
     */
    _submitReport = () => {
        const { type } = this.state;
        const { reportId } = this.props;

        if (reportId) {
            if (type === 'SUBMIT') {
                this.props.submitReport(reportId, { comment: this.state.comments });
                this.setState({
                    type: null
                });
            } else {
                if (this.state.unsubmitComment !== '') {
                    this.props.unSubmitReport(reportId, { comment: this.state.unsubmitComment });
                    this.setState({
                        type: null
                    });
                }
            }
        }
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                className={`textBox--bordered txt-right input--disabled`}
                value={value}
                disabled={true}
                prefix={''}
                thousandSeparator={true}
                decimalScale={0}
            />
        )
    }
    /**
     * Scroll to content
     * @param {*} content 
     */
    scrollToContent(content) {
        if (content.sectionIndex) {
            switch (content.sectionIndex) {
                case 1:
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 2:
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 3:
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 4:
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 5:
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 6:
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 7:
                    this.section7.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 8:
                    this.section8.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        } else {
            let warningKey = ''
            if (content.fieldName === "sec4total") {
                warningKey = WarningNames.filter(e => e.title.match(content.title || content.fieldName))[1].type;
            } else {
                warningKey = WarningNames.filter(e => e.title.match(content.title || content.fieldName))[0].type;
            }
            switch (warningKey) {
                case 'section1':
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section2':
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section3':
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section4':
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section5':
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section6':
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section7':
                    this.section7.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section8':
                    this.section8.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }

    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, getOne, validations } = this.props;
        let { pageAccess } = this.props;

        const {
            temporaryFarmworkerHousing,

            reportYearDatesofOperation,
            reportYearDatesofOperationEnd,
            totalOccupiedBednights,
            totalBednightsAvailable,

            averageLengthofStay,
            maximumLengthofStay,

            label0to30,
            label31to50,
            label51to80,
            over80,

            raceHeader,
            totalNumberofOccupants,
            totalNumberofFamilyGroupHouseholdsServed,
            totalNumberofIndividualHouseholdsServed,
            numberofMinors,

            rentPeriod_id,
            chargedtoTenantsbed,
            chargedtoEmployersbed,
            chargedtoTenantsUnit,
            chargedtoEmployersUnit,
            notes
        } = this.state;
        let Total = this.getTotal();
        let hispanicTotal = this.getTotalHispanic();
        let householdIncome = this.getTotalHouseholdIncome();
        let totalHouseholdsServed5thPart = this.getTotalNumberofHouseholdServed();

        // if (totalHouseholdsServed5thPart <= 0) {
        //     return 'Errors';
        // }

        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any Funder for this report year.'}</h4>;
        }

        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';

        // if (validations) {
        //     pageAccess = validations.data.table2 === 2 ? [...pageAccess, 'VALIDATION_WARNING'] : (validations.data.table2 === 3 || validations.data.table2 === 4 ? [...pageAccess, 'VALIDATION_HARDSTOP'] : [...pageAccess, 'NO_VALIDATION_ISSUES']);
        // }

        // get field level validations for table 2
        const fielderrors = Table2FarmworkerValidations({
            ...this.state,
            totalHouseholdsServed5thPart,
            householdIncome,
            validationErrors: validations.data.table2Issues || [],
            Total: Total
        });
        return (
            <React.Fragment >
                <ContentWrapper
                    classes={classes}
                    pageAccess={['REFRESH_VALIDATIONS', 'DOWNLOAD', ...pageAccess]}
                    title={levelInfo}
                    titleCss={'bolderText'}
                    titleBg={'white'}
                    pageName="reports"
                    validationContent={getOne.validations}
                    needTitleBar={true}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    handleSubmitActionClick={() => this.handleSubmitActionClick('SUBMIT')}
                    handleUnSubmitActionClick={() => this.handleSubmitActionClick('UNSUBMIT')}
                    handleDownloadActionClick={() => this.handleDownloadActionClick()}
                    handleRefreshValidationsClick={() => {
                        const { reportId } = this.props;
                        this.props.refreshValidations(0, 0, reportId, "2");
                    }}
                >
                    {/* <Grid item xs={12} sm={12}>
                        <h4 className="report_level_title">
                            {levelInfo}
                        </h4>
                    </Grid> */}
                    <TableErrors
                        fielderrors={fielderrors}
                        data={getOne.data.data}
                        classes={classes}
                        validations={validations.data.table2Issues || []}
                        handleClickAction={(err) => {
                            this.scrollToContent(err)
                        }}
                    />
                    <div className={classes.rootEditSubPage}>
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => { }} >
                            <Grid container spacing={3}>

                                {/* Number of Beds*/}
                                <Grid item xs={12} sm={12}>
                                    <div ref={this.section1}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 1)}`}> 1 </span>
                                            Number of Beds
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="temporaryFarmworkerHousing"
                                        label="Temporary Farmworker Housing (# of Beds):"
                                        value={temporaryFarmworkerHousing}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'temporaryFarmworkerHousing'}
                                    />
                                </Grid>

                                {/*Dates of Operation and Bednights*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section2}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 2)}`}> 2 </span>
                                            Dates of Operation and Bednights
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    <h4 >
                                        Report Year Dates of Operation:
                                    </h4>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DatePicker
                                        name="reportYearDatesofOperation"
                                        value={reportYearDatesofOperation}
                                        label="Report Year Dates of Operation Begin:"
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['required']}
                                        errorMessages={['Please enter valid beginning report year date of operation']}
                                        fielderrors={fielderrors}
                                        warningfieldname={'reportYearDatesofOperation'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DatePicker
                                        name="reportYearDatesofOperationEnd"
                                        value={reportYearDatesofOperationEnd}
                                        label="Report Year Dates of Operation End:"
                                        // errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        validators={['required', 'isValidToDate']}
                                        errorMessages={['Please enter valid ending report year date of operation', 'The report year dates of operation end date must be greater than begin date']}
                                        fielderrors={fielderrors}
                                        warningfieldname={'reportYearDatesofOperationEnd'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    {/* Alignment Purpose */}
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    {/* Alignment Purpose */}
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberField
                                        name="totalOccupiedBednights"
                                        label="Total Occupied Bednights (used):"
                                        value={totalOccupiedBednights}
                                        className={classes.textField}
                                        margin="dense"
                                        disabled={isReadOly}
                                        thousandSeparator={true}
                                        handleChange={this.handleChange}
                                        fixedDecimalScale={false}
                                        prefix={''}
                                        fielderrors={fielderrors}
                                        warningfieldname={'totalOccupiedBednights'}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberField
                                        name="totalBednightsAvailable"
                                        label="Total Bednights Available:"
                                        value={totalBednightsAvailable}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        thousandSeparator={true}
                                        fixedDecimalScale={false}
                                        prefix={''}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    {/* Alignment Purpose */}
                                </Grid>

                                {/*Length of Stay*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section3}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 3)}`}> 3 </span>
                                            Length of Stay
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="averageLengthofStay"
                                        label="Average Length of Stay (days per household):"
                                        value={averageLengthofStay}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="maximumLengthofStay"
                                        label="Maximum Length of Stay (days per household):"
                                        value={maximumLengthofStay}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                {/* Rent */}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section4}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 4)}`}> 4 </span>
                                            Rent
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                </Grid>

                                < Grid item xs={12} sm={2}>
                                    <h4 >
                                        Rent Period:
                                    </h4>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <AutoComplete
                                        name="rentPeriod_id"
                                        placeholder='Rent Period'
                                        suggestions={rentPeriodDetails.rentPeriodLimitName}
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                rentPeriod_id: selectedOption.value
                                            })
                                        }}
                                        selectedValue={{ id: rentPeriod_id }}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                </Grid>


                                < Grid item xs={12} sm={4}>
                                    <h4 >
                                        <span className={'red'}>  * (one/row)</span> Rent Per Individual (per bed):
                                    </h4>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="chargedtoTenantsbed"
                                        label=" Charged To Tenants:"
                                        value={chargedtoTenantsbed}
                                        className={classes.textField}
                                        handleChange={this.handleRent}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'perBed'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="chargedtoEmployersbed"
                                        label="Charged To Ag Employers:"
                                        value={chargedtoEmployersbed}
                                        className={classes.textField}
                                        handleChange={this.handleRent}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'perBed'}
                                    />
                                </Grid>

                                < Grid item xs={12} sm={4} >
                                    <h4 >
                                        <span className={'red'}>  * (one/row)</span> Rent Per Household (per unit):
                                    </h4>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="chargedtoTenantsUnit"
                                        label=" Charged To Tenants:"
                                        value={chargedtoTenantsUnit}
                                        className={classes.textField}
                                        handleChange={this.handleRent}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'perUnit'}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="chargedtoEmployersUnit"
                                        label="Charged To Ag Employers :"
                                        value={chargedtoEmployersUnit}
                                        className={classes.textField}
                                        handleChange={this.handleRent}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'perUnit'}
                                    />
                                </Grid>

                                {/*Section 5 Number of Households Served */}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section5}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 5)}`}> 5 </span>
                                            Number of Households Served
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofFamilyGroupHouseholdsServed"
                                        label="Total Number of Family/Group Households Served:"
                                        value={totalNumberofFamilyGroupHouseholdsServed}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofIndividualHouseholdsServed"
                                        label="Total Number of Individual Households Served:"
                                        value={totalNumberofIndividualHouseholdsServed}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalHouseholdsServed5thPart"
                                        label="Total Households Served:"
                                        value={totalHouseholdsServed5thPart}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'totalHouseholdsServed5thPart'}
                                    />
                                </Grid>

                                {/*Section 6 Race and Ethnicity of Restricted  Unit Household Served*/}
                                <Grid item xs={12} sm={12}>
                                    <div ref={this.section6}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 6)}`}> 6 </span>
                                            Race and Ethnicity of Restricted  Unit Household Served
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} className="p-t-12">
                                    <EditableTable
                                        headerCols={raceHeader}
                                        fieldCols={['desc', 'raceCount', 'hispanicCount', 'comment']}
                                        rowDatas={this.state.allDetails}
                                        componentMap={componentMap}
                                        cellComponentOnChange={this.cellComponentOnChange}
                                        needTitle={false}
                                        tableClass={'fieldWidth12'}
                                        config={{ isReadOly }}
                                        footerDatas={[
                                            {
                                                'desc_footer': 'Total',
                                                'raceCount_footer': this.displayAmount(Total),
                                                'hispanicCount_footer': this.displayAmount(hispanicTotal)
                                            },
                                            {
                                                'desc_footer': <span className={`${hasWarningsSection(fielderrors, 5)}`}><label>
                                                    Total Restricted Unit Households Served:</label></span>,
                                                'raceCount_footer': this.displayAmount(Total),
                                            }
                                        ]}
                                    />
                                </ Grid>

                                {/*Section 7 Household Income*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section7}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 7)}`}> 7 </span>
                                            Household Income
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label0to30"
                                        label="0-30 %:"
                                        value={label0to30}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label31to50"
                                        label="31-50 %:"
                                        value={label31to50}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label51to80"
                                        label="51-80 %:"
                                        value={label51to80}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="over80"
                                        label="Over 80%:"
                                        value={over80}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="householdIncome"
                                        label="Total Households Served:"
                                        value={householdIncome}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'householdIncome'}
                                    />
                                </Grid>

                                {/*section 7 Total Number of Occupants*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section8}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 8)}`}> 8 </span>
                                            Total Number of Occupants
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofOccupants"
                                        label="Total Number of Occupants:"
                                        value={totalNumberofOccupants}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="numberofMinors"
                                        label="Number of Minors (if known):"
                                        value={numberofMinors}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'numberofMinors'}
                                    />
                                </Grid>

                                < Grid item xs={12} sm={12}>
                                    <Card className='projectsummary_card commentMargin'>
                                        <CardContent className="d_down_report">
                                            <TextBox
                                                name="notes"
                                                label="Comments"
                                                value={notes}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        {
                            pageAccess.indexOf('SAVE') > -1 ? (
                                <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />)
                                : null
                        }
                        {/* <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.goBack() }} /> */}
                    </div>
                </ContentWrapper>
                <ValidatorForm ref="submitReport" onSubmit={this.handleSubmitReport}>
                    <AlertDialog
                        open={this.state.type !== null}
                        title={'Warning:'}
                        onSave={() => this.refs.submitReport.submit()}
                        onClose={() => {
                            this.setState({ type: null })
                        }}
                        saveText={'Submit'}
                    >
                        {this.popAlert(this.state.type)}
                    </AlertDialog>
                </ValidatorForm>

                {
                    this.state.error !== null && (
                        <AlertDialog
                            open={true}
                            title={'Error:'}
                            saveVisible={false}
                            onClose={() => {
                                this.setState({ error: null })
                            }}
                            cancelText={'OK'}
                        >
                            {this.state.error.message}
                        </AlertDialog>
                    )
                }
            </React.Fragment >
        )
    }
}
/**
 * Bind Component Property Types
 */
Table2FarmWorker.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Table2FarmWorker.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess, reportTables, configuration }, ownProps) => {
    return {
        pageAccess: pageAccess['table2'] || [],
        getOne: reportTables.table2,
        races: configuration.configurations[configurationConstants.race] || [],
        validations: { data: reportTables.validationIssues }
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable2Data: (id) => dispatch(reportTablesActions.getTable2Data(id)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getTable2DataUpdate: (id, data) => dispatch(reportTablesActions.getTable2DataUpdate(id, data)),
        createTable2: (data,callBack) => dispatch(reportTablesActions.createTable2(data,callBack)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 2)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 2)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        refreshValidations: (project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) => dispatch(reportTablesActions.refreshValidations(project_id, site_id, reportYear_id, reportTable)),
        downloadTable2Report: (project_id, site_id, year) => dispatch(exploreActions.downloadTable2Report(project_id, site_id, year, 'WBARS_Table2Export.xlsx'))

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table2FarmWorker));
