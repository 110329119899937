import React, { Component } from 'react';
import { connect } from 'react-redux';

// Materia lUI
import { Typography, Grid, withStyles } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

// images
import File_Upload from '../../../assets/img/file_upload.svg'
import Restrict_Upload from '../../../assets/img/restrict_upload.svg'

//import components
import FileUpload from '../../../components/FileUpload/FileUpload'
import SaveCancel from '../../../components/SaveCancel/SaveCancel';

// Style
import pageStyle from '../../../assets/jss/containers/common';

// Actions
import { maintenanceActions } from '../../../actions/maintenance';
import { pageAccessActions } from '../../../actions';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        flexWrap: 'wrap',
        maxHeight: 200,
        overflow: 'auto'
    },
}))(MuiAccordionDetails);
/**
 * ImportFiles Component
 * @class ImportFiles
 * @extends {Component}
 */
class ImportFiles extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            income: null,
            rent: null,
            whatlimit: null,
            showError: false,
            fileTypeError: null,
            uploadedFile: new FormData(),
            expanded: '',
            isCanceled: false
        }
    }
    /**
     * UNSAFE_componentWillMount
     */
    UNSAFE_componentWillMount() {
        this.props.getAccess('incomerent', {})
    }
    /**
     * Handle File upload
     * @param {*} acceptedFiles 
     * @param {*} rejectedFiles 
     * @param {*} name 
     */
    handleFileUpload = (acceptedFiles, rejectedFiles, name) => {
        const { uploadedFile } = this.state;
        if (rejectedFiles.length === 0 && acceptedFiles.length === 1) {
            const file = acceptedFiles[0];
            if (uploadedFile.has(name)) {
                uploadedFile.set(name, file);
            } else {
                uploadedFile.append(name, file);
            }
            uploadedFile.append('name', file.name);
            this.setState({ showError: false, fileTypeError: null, [name]: file, uploadedFile: uploadedFile });
        } else {
            this.setState({ showError: true, fileTypeError: 'Invalid File. Please upload a File with extension csv' });
        }
    }
    /**
     * Handle Delete
     * @param {*} name 
     */
    handleDeleteClick = (name) => {
        const { uploadedFile } = this.state;
        uploadedFile.delete(name)
        this.setState({ [name]: null, uploadedFile: uploadedFile })
    }
    /**
     * Handle Save Button
     */
    handleSaveButtonClick = () => {
        const { uploadedFile } = this.state
        this.props.importFiles(uploadedFile);
        this.setState({ isCanceled: false })
    }
    /**
     * Handle Accordion Change
     * @param {*} panel 
     * @returns 
     */
    handleAccordionChange = (panel) => (event, newExpanded) => {
        this.setState({ expanded: newExpanded ? panel : '' })
    }
    /**
     * Handle re-upload
     */
    handleReUpload = () => {
        this.setState({ income: null, rent: null, whatlimit: null, isCanceled: true, uploadedFile: new FormData() })
        this.props.reUpload();
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Get errors
     * [2]  -   If process index is not equal to 1
     * [3]  -   If process index is 1 and file upload is pending
     * [4]  -   If the user has maintenance access, can upload file(i.e., Super funder who started maintenace)
     * [5]  -   Other Superfunder can only view the status
     */
    render() {
        const { maintenance, maintenanceError, pageAccess } = this.props;
        const { income, rent, whatlimit, expanded, isCanceled } = this.state;

        let errorHeaders = [];
        // [1]
        if (!isCanceled && maintenanceError && Object.keys(maintenanceError).length > 0) {
            errorHeaders = Object.keys(maintenanceError);
        }
        // [2]
        if (maintenance && maintenance.maintenance && maintenance.processIndex !== 1) {
            return null
        }
        // [3]
        if (maintenance && maintenance.processIndex === 1 && maintenance.pending) {
            return (
                <>
                    <Grid container spacing={4} align="center" className="height-100-per alignContentCenter">
                        <Grid item xs={12}>
                            <img className="max-h-110 max-w-200" src={File_Upload} alt="sleep" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="fw-600">
                                Limit Files Import is in Progress... Please Wait!
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
        // [4]
        if (pageAccess.indexOf("MAINTENANCE") > -1) {
            return (
                <Grid container spacing={4} className={`height-100-per alignContentSB`}>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={"pA-10 fw-600"}>
                            Income Limit
                        </Typography>
                        {income ?
                            <Typography variant="h6" className={"pA-10 height-255"}>
                                <div className="custom-label textItalic">Uploaded</div>
                                {income.name}
                            </Typography>
                            :
                            <div className='uploadArea'>
                                <FileUpload
                                    value={'Upload File'}
                                    accept={[".xlsx"]}
                                    content={"Drag & Drop the Excel file here"}
                                    handleFileUpload={(acceptedFiles, rejectedFiles) => this.handleFileUpload(acceptedFiles, rejectedFiles, 'income')}
                                    disabled={errorHeaders.length > 0}
                                />
                            </div>}

                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={"pA-10 fw-600"}>
                            Rent Limit
                        </Typography>
                        {rent ?
                            <Typography variant="h6" className={"pA-10 height-255"}>
                                <div className="custom-label textItalic">Uploaded</div>
                                {rent.name}
                            </Typography>
                            :
                            <div className='uploadArea'>
                                <FileUpload
                                    value={'Upload File'}
                                    accept={[".xlsx"]}
                                    content={"Drag & Drop the Excel file here"}
                                    handleFileUpload={(acceptedFiles, rejectedFiles) => this.handleFileUpload(acceptedFiles, rejectedFiles, 'rent')}
                                    disabled={errorHeaders.length > 0}
                                />
                            </div>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" className={"pA-10 fw-600"}>
                            What Limit
                        </Typography>
                        {whatlimit ?
                            <Typography variant="h6" className={"pA-10 height-255"}>
                                <div className="custom-label textItalic">Uploaded</div>
                                {whatlimit.name}
                            </Typography>
                            :
                            <div className='uploadArea'>
                                <FileUpload
                                    value={'Upload File'}
                                    accept={[".xlsx"]}
                                    content={"Drag & Drop the Excel file here"}
                                    handleFileUpload={(acceptedFiles, rejectedFiles) => this.handleFileUpload(acceptedFiles, rejectedFiles, 'whatlimit')}
                                    disabled={errorHeaders.length > 0}
                                />
                            </div>
                        }
                    </Grid>
                    {
                        errorHeaders.length > 0 &&
                        <div className="importFilesContainer">
                            {errorHeaders.map(header => (
                                <div className="w-100">
                                    <Accordion square expanded={expanded === header} onChange={this.handleAccordionChange(header)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography>{header}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                maintenanceError[header] && maintenanceError[header].length > 0 && maintenanceError[header].map(error => (
                                                    <Typography className="w-100-per">{error}</Typography>
                                                ))
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))}
                        </div>
                    }
                    {
                        (income || rent || whatlimit) &&
                        <Grid item xs={12}>
                            {errorHeaders.length > 0
                                ?
                                <Typography variant="h6" align="center" className={"pA-10"}>
                                    Files was not uploaded Successfully. Please reupload the file.
                                </Typography>
                                :
                                <Typography variant="h6" align="center" className={"pA-10"}>
                                    Files uploaded successfully. Click continue to import data.
                                </Typography>}
                            <br />
                            <div className="displayFlex jcCenter">
                                <SaveCancel
                                    saveText={errorHeaders.length === 0 ? "Continue" : "Re-Upload"}
                                    justifyContent="center"
                                    // saveVisible={errorHeaders.length === 0}
                                    cancelVisible={errorHeaders.length === 0}
                                    handleSaveButtonClick={() => errorHeaders.length === 0 ? this.handleSaveButtonClick() : this.handleReUpload()}
                                    handleCancelButtonClick={() => {
                                        this.setState({ income: null, rent: null, whatlimit: null, isCanceled: true })
                                    }}
                                />
                            </div>
                        </Grid>
                    }
                </Grid >
            );
        } else {
            // [5]
            return (
                <>
                    <Grid container spacing={4} align="center" className="height-100-per alignContentCenter">
                        <Grid item xs={12}>
                            <img className="max-h-110 max-w-200" src={Restrict_Upload} alt="Restrict_Upload" />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" className="fw-600">
                                Sorry! The user who turned on the maintenance only has the access to upload file.
                            </Typography>
                            <Typography variant="p">
                                (Waiting for the user to upload file)
                            </Typography>
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
}
// prop types
ImportFiles.propTypes = {
};
/**
 * Default Props
 */
ImportFiles.defaultProps = {
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 */
const mapStateToProps = ({ maintenance: { data, error }, pageAccess: { incomerent } }) => {
    return {
        maintenance: { ...data },
        maintenanceError: typeof (error) === 'object' ? { ...error } : {},
        pageAccess: incomerent || []
    }
};
/**
 *  Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        importFiles: (data) => dispatch(maintenanceActions.importLimitFiles(data)),
        getAccess: (pageName, pageData) => dispatch(pageAccessActions.getAccess(pageName, pageData)),
        goLive: () => dispatch(maintenanceActions.goLive()),
        reUpload: () => dispatch(maintenanceActions.reUpload()),
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ImportFiles));