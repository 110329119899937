import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MenuItem } from '@material-ui/core';

//Import Styles
import exploreStyle from '../../assets/jss/containers/explore';

//Helpers
import ValidatorForm from '../../helpers/fieldValidations';
import {prrFilters} from '../../helpers/appConstants';
import {rentBurderFilters} from '../../helpers/appConstants';
//Components
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
/**
 * ExploreForm Container
 * @class ExploreForm
 * @extends {Component}
 */
class ExploreForm extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            template: '',
            reportName: '',
            reportYear: new Date().getFullYear(),
            reportFilter: 'Ownership Organization'
        }
    }
    /*
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { reportName, template, reportYear } = nextProps;
        this.setState({ reportName: reportName, template: template, reportYear: reportYear })
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     * 
     * [1]  -   Update to parent Component
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value })
        if (name !== 'reportName' || name === null) {
            // [1]
            this.props.onChange(name, value);
        }
    }
    /**
     * Handle Submit    -   Submission using refs
     */
    handleSubmit = () => {
        this.refs.form.submit();
        if(this.state.reportName){
            setTimeout(() => {
                if (this.refs.form.errors.length === 0) {
                    this.props.handleSave()
                    return null
                }
            }, 1000)
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Sort report years from latest year to oldest year
     */
    render() {
        let { classes, templates, reportYears, reportFiltr } = this.props;
        let { reportName, template, reportYear, reportFilter } = this.state;

        let reportNameGridSize = 4, templateGridSize = 4, reportYearGridSize = 4, reportFilterGridSize = 4;
        
        if (template == 5 || template === 26){
            templateGridSize = 3;
            reportYearGridSize = 2;
            reportFilterGridSize = 2;
        }

        // [1]
        reportYears.sort((a, b) => {
            const ai = parseInt(a.Year, 10), bi = parseInt(b.Year, 10);
            return (b == null) - (a == null)
                || (bi - ai)
                || (a.Year < b.Year) - (b.Year < a.Year);
        }).map(x => x);
        return (<ValidatorForm
            name="ExploreForm"
            ref="form"
            className="exploreForm"
            autoComplete="off"
            onSubmit={() => { }}
            onError={errors => { }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={reportNameGridSize}>
                    <TextBox
                        name="reportName"
                        value={reportName}
                        label="Report Name"
                        className={`${classes.textField} reportNameWidth`}
                        handleChange={this.handleChange}
                        onBlur={(name, value) => this.props.onChange('reportName', reportName)}
                        margin="dense"
                        disabled={false}
                        validators={['required']}
                        errorMessages={['This field is required']}
                    />
                </Grid>
                <Grid item xs={12} sm={templateGridSize}>
                    <DropDown
                        name='template'
                        value={template}
                        label="Select a Template"
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        validators={['required']}
                        errorMessages={['This field is required']}
                    >
                        {templates && templates.map(rec => {
                            return <MenuItem value={rec.key} key={rec.key}>{rec.title}</MenuItem>
                        })}

                    </DropDown>
                </Grid>
                <Grid item xs={12} sm={reportYearGridSize}>
                    <DropDown
                        name='reportYear'
                        value={reportYear}
                        label="Select Year"
                        className={classes.textField}
                        handleChange={this.handleChange}
                        margin="dense"
                        validators={['required']}
                        errorMessages={['this field is required']}
                    >
                        {reportYears && reportYears.filter(y => y.Year <= (new Date().getFullYear())).map(rpt => {
                            return <MenuItem value={rpt.Year} key={rpt.Year}>{rpt.Year}</MenuItem>
                        })}
                    </DropDown>
                </Grid>
                {
                   (template != 0 && (template == 5 || template === 26)) && <Grid item xs={12} sm={reportFilterGridSize}>
                        <DropDown
                            name='reportFilter'
                            value={reportFiltr}
                            label="Select Filter"
                            className={classes.textField}
                            handleChange={this.handleChange}
                            margin="dense"
                            validators={['required']}
                            errorMessages={['this field is required']}
                        >

                            {template===26?rentBurderFilters &&rentBurderFilters.map(rec => {
                                return <MenuItem value={rec} key={rec}>{rec}</MenuItem>
                            }) : prrFilters && prrFilters.map(rec => {
                                return <MenuItem value={rec} key={rec}>{rec}</MenuItem>
                            })}

                      </DropDown>
                    </Grid>
                }
            </Grid>
        </ValidatorForm>)
    }
}

/**
 * Bind Component Property Types
 */
ExploreForm.propTypes = {
    // explore: PropTypes.array.isRequired,
    // noDataErrorMessage: PropTypes.string.isRequired,
};

/*Default Props */
ExploreForm.defaultProps = {
    classes: {},
    templates: [],
    reportYears: [],
    template: '',
    reportName: '',
    reportYear: '',
    onChange: () => { },
    reportFiltr: ''
}
//Export ExploreForm
export default (withStyles(exploreStyle)(ExploreForm));