import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route } from 'react-router-dom';

//compnents
import Explore from './Explore';

//Actions
import { exploreActions } from '../../actions';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';


// let sFields = [];
// let sValues = [];

class ExploreView extends Component {
    /* Constructor  */
    constructor(props) {
        super(props)
        this.exploreRef = React.createRef();

    }
    /*Constructor*/
    componentDidMount() {
        if (this.props._id) {
            this.props.getById(this.props._id);
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { match: { params }, match, pageAccess, getOne, title } = this.props;
        return (
            <Switch>
                <Route
                    exact
                    path={match.url}
                    render={(props) => {
                        return (
                            <Explore
                                pageAccess={pageAccess}
                                _id={params._id}
                                getOne={getOne}
                                {...props}
                                innerRef={(node) => { this.exploreRef = node; }}
                                reportFilter={getOne && getOne.reportFilter ? getOne.reportFilter : "Ownership Organization"}
                                title={getOne ? getOne.report_name : title}
                            />
                        )
                    }}
                />
            </Switch>
        );
    }
}
/**
 * Bind Component Property Types
 */
ExploreView.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    title: PropTypes.string,

}
/*  default props  */
ExploreView.defaultProps = {
    pageAccess: [],
    title: ''
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ explore, pageAccess }, ownProps) => {
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['exploreEdit'] || [];
    return {
        pageAccess,
        getOne: explore.getOne.data
    }
};
/**
 * Map actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getById: (_id) => dispatch(exploreActions.getById(_id)),
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(ExploreView));

