import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

/**
 * ConfigurationsMenu container
 * @class ConfigurationsMenu
 * @extends ConfigurationsMenu
 */
class ConfigurationsMenu extends Component {
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { match, pageAccess } = this.props;
        return (
            <List component="nav" className={"noPadding navBar"}>
                {pageAccess.indexOf("FUNDER_REPORT_TABLES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/funderreporttable`} activeClassName='MuiListItem-active'>
                        Default Reporting Forms
                            </ListItem> : null
                }
                {pageAccess.indexOf("SF_EXCEL_UPLOAD") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/upload/0`} activeClassName='MuiListItem-active'>
                        Super Funder Excel Import
                            </ListItem> : null
                }
                {pageAccess.indexOf("UPLOAD_RENTINCOMELIMIT") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/incomerent`} activeClassName='MuiListItem-active'>
                        Import Income & Rent Limit
                            </ListItem> : null
                }

                {pageAccess.indexOf("BEDROOMS") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/bedrooms`} activeClassName='MuiListItem-active' >
                        Bedrooms
                    </ListItem> : null
                }
                {pageAccess.indexOf("CITIES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/cities`} activeClassName='MuiListItem-active'>
                        Cities
              </ListItem> : null
                }
                {pageAccess.indexOf("MOVEOUT") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/moveOutReason`} activeClassName='MuiListItem-active'>
                        Move-out Reasons
              </ListItem> : null
                }
                {pageAccess.indexOf("COUNTIES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/counties`} activeClassName='MuiListItem-active'>
                        Counties
               </ListItem> : null
                }
                {pageAccess.indexOf("EXPENSE_CATEGORIES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/expense-categories`} activeClassName='MuiListItem-active'>
                        Expense Categories
                            </ListItem> : null
                }
                {pageAccess.indexOf("HELP_CATEGORY_TYPES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/help-category-types`} activeClassName='MuiListItem-active'>
                        Help Category
                            </ListItem> : null
                }
                {pageAccess.indexOf("HOME_UNIT_OCCUPANCY") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/home-unit-occupancy`} activeClassName='MuiListItem-active'>
                        Home Unit Occupancy
                            </ListItem> : null
                }
                {pageAccess.indexOf("LENDERS") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/lenders`} activeClassName='MuiListItem-active'>
                        Lenders
                            </ListItem> : null
                }
                {pageAccess.indexOf("OTHER_INCOME") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/other-income`} activeClassName='MuiListItem-active'>
                        Other Income
                            </ListItem> : null
                }
                {pageAccess.indexOf("RACES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/races`} activeClassName='MuiListItem-active'>
                        HOME Head of Household Race
                            </ListItem> : null
                }
                {pageAccess.indexOf("RACES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/member-races`} activeClassName='MuiListItem-active'>
                        Household Member Race
                            </ListItem> : null
                }
                {pageAccess.indexOf("SET_ASIDE_TYPES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/set-aside-types`} activeClassName='MuiListItem-active'>
                        Set Aside types
                            </ListItem> : null
                }
                {pageAccess.indexOf("SPECIAL_NEEDS_OPTIONS") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/special-needs-options`} activeClassName='MuiListItem-active'>
                        Special Needs Options
                            </ListItem> : null
                }
                {pageAccess.indexOf("STATES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/states`} activeClassName='MuiListItem-active'>
                        States
                            </ListItem> : null
                }
                {pageAccess.indexOf("SUBSIDY") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/subsidy`} activeClassName='MuiListItem-active'>
                        Subsidy Types
                            </ListItem>
                    : null
                }
                {pageAccess.indexOf("UTILITY_ALLOWANCE_SOURCES") > -1 ?
                    < ListItem button component={NavLink} to={`${match.url}/utility-allowance-sources`} activeClassName='MuiListItem-active'>
                        Utility Allowance Sources
                            </ListItem> : null
                }
                {pageAccess.indexOf("UTILITY_ALLOWANCE_TYPES") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/utility-allowance-types`} activeClassName='MuiListItem-active'>
                        Utility Allowance Types
                            </ListItem> : null
                }
                {pageAccess.indexOf("BANNER_TEXT") > -1 ?
                    <ListItem button component={NavLink} to={`${match.url}/banner-text`} activeClassName='MuiListItem-active'>
                        Banner Text
                            </ListItem> : null
                }
                {
                 pageAccess.indexOf("DATA_EXPORT") > -1 ? 
                 <ListItem button component={NavLink} to={`${match.url}/bulk-export`} activeClassName='MuiListItem-active'>
                    Data Export
                </ListItem> : null
                }
            </List >
        );
    }
}
/** Props types */
ConfigurationsMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {

    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {

    }
};
/** Export ConfigurationsMenu */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(ConfigurationsMenu));
