import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Container
import HeaderContainer from '../containers/Header/Header';
import GlobalSearch from '../containers/GlobalSearch/GlobalSearch';
import Notification from '../containers/Notification/Notification';

//Imports Components
import SideMenu from '../components/SideMenu/SideMenu';

// Imports Style
import privateLayoutStyle from '../assets/jss/privateLayouts';

// Actions
import { notificationActions } from '../actions';

// Helpers
import { getUser } from '../helpers/global'

//Snackbar
import { SnackbarProvider } from 'notistack';
import SnackMessage from '../components/NotiSnackbar/SnackMessage';

/**
 * PrivateLayout Component
 * @class PrivateLayout
 * @extends {React.Component}
 */
class PrivateLayout extends React.Component {
    state = {
        drawer: false,
        notificationDrawer: false
    };
    orgAccess = getUser().org.organizationAccess

    componentDidMount(){
        // this.props.getAlertNotification()
    }

    /**Drawer Navigation */
    /**
     * Drawer Navigation for Search
     * @param {*} open 
     */
    toggleDrawer = (open) => {
        this.setState({
            drawer: open,
            notificationDrawer: false
        });
    };
    /**
     * Drawer Navigation for Notification
     * @param {*} open 
     */
    toggleNotificationDrawer = (open) => {
        this.setState({
            notificationDrawer: open,
            drawer: false
        });
    };

    
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { notificationAlertState,classes, ...other } = this.props;
        const { drawer, notificationDrawer } = this.state;
        var { orgAccess } = this
            return(
                <div className={classes.root}>
                <SnackbarProvider
                maxSnack={1}
                variant={'success'}
                autoHideDuration={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                content={(key,message)=>
                    <SnackMessage key={key} message={message} />
                }
                >
                <HeaderContainer
                    toggleDrawer={this.toggleDrawer}
                    toggleNotificationDrawer={this.toggleNotificationDrawer}
                    drawer={drawer}
                    notificationDrawer={notificationDrawer}
                />
                <div onClick={() => { this.toggleDrawer(false) }}><SideMenu {...other} /></div>
                <div className={classes.content} onClick={() => { this.toggleDrawer(false) }}>
                    <div className={classes.toolbar} />
                    <Grid
                        container
                        style={{ padding: "0 14px" }}
                    >
                        {this.props.children}
                    </Grid>
                </div>
                {drawer || notificationDrawer ?
                    <div className="drawerBG" onClick={() => { this.toggleDrawer(false) }}></div> : null
                }
                <GlobalSearch
                    anchor="right"
                    variant="persistent"
                    open={drawer}
                    toggleDrawer={this.toggleDrawer}
                    access={orgAccess}
                />
                <Notification
                    anchor="right"
                    variant="persistent"
                    open={notificationDrawer}
                    toggleDrawer={this.toggleNotificationDrawer}
                />
                </SnackbarProvider>
            </div>
            )
            }
        
}
/** Props Types */
PrivateLayout.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const notificationAlertState = state.notification.notificationAlertList
    return {
        notificationAlertState
    }
};
/**
 * Maps actions to props
* @param {*} dispatch
        */
const mapDispatchToProps = (dispatch) => {
    return {
        updateNotification: () => dispatch(notificationActions.updateNotification()),
        // getAlertNotification: () => dispatch(notificationActions.getNotificaionAlertByUserId())
    }
};
/** Export Component */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(privateLayoutStyle)(PrivateLayout));