import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Import Components
import PageTitle from '../../components/PageTitle/PageTitle';
import PageActions from '../../components/PageActions/PageActions';

//Constant
import { orgTypesConstants } from '../../helpers/appConstants';

// Import Styles
import dashboardStyle from '../../assets/jss/containers/dashboard';

// Import Redux Actions and Helper Variable
import { dashboardActions } from '../../actions/dashboard';
import { dashboardHOActions } from '../../actions/dashboardHO';

// Import Helpers
import { getUserOrgId, getUserType, globalVariable, getUserRole } from '../../helpers';
import HelpFloater from '../../components/HelpFloater/HelpFloater';
import { helpActions } from '../../actions';
import { AppBar, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import DashboardSummary from './DashboardSummary';
import ExploreNeedsAction from './ExploreNeedsAction';
import DashboardReports from './DashboardReports';

/**
 *Dashboard Main Component
 *
 * @class Dashboard
 * @extends {Component}
 */
class DashboardHO extends Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            openHelp: false,
            currentTab: '0',
            reportYear: new Date().getFullYear()-1
        }
        // get user type
        this.userRole = getUserType();
        this.org_id = getUserOrgId();
        this.user = getUserRole();
    }

    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { userRole, org_id } = this;
        this.props.getPCLCounts(userRole, this.state.reportYear);
        this.props.getLoanRate(userRole, this.state.reportYear);
        this.props.getLoanComplianceIssue(userRole, this.state.reportYear);
        this.props.getReportStatus(this.state.reportYear, userRole);
        if (this.userRole === orgTypesConstants.PROP_MGR) {
            this.props.getComplianceIssue(this.state.reportYear, userRole, org_id);
            this.props.getPMEffectiveDate(1, 3, 'projectName', 'asc', '', '');
        }
        // this.props.getProjectSummary(30, '');
         this.props.getHelp();
    }

    /**
     * A method to call download project report in excel format
     * 
     * @param {*} data contains project id, site id (optional), year
     * @memberof Dashboard
     */
    downloadProjectSummary = (data) => {
        // this.props.viewProjectSummaryReport(data);
    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });
    }

    handleDownloadActionClick = () => {
        const { dashboard: { PSBUCounts, complianceIssue, reportStatus } } = this.props;
        const { reportYear } = this.state;
        // alert('InProgress...')
        this.props.downloadDashboardReport({
            reportYear: reportYear,
            PSBUCounts: PSBUCounts.data,
            complianceIssue: { ...complianceIssue.data },
            // occupancyRate: occupancyRate.data, 
            reportStatus: reportStatus.data,
            // utilityAllowance: effectiveDate.page.count, 
            userRole: this.userRole
        })
    }
    handleReportYearActionClick = (year) => {
        const { userRole, org_id } = this;
        const { dashboardCompliance } = this.props;
        const selectedSites = dashboardCompliance ? dashboardCompliance.selectedSites : [];
        this.setState({ reportYear: year })
        this.props.getComplianceIssueSummary(year, userRole, org_id, selectedSites);
        this.props.getPCLCounts(userRole, year);
        this.props.getLoanRate(userRole, year);
        this.props.getLoanComplianceIssue(userRole, year);
        this.props.getReportStatus(year, userRole);
        // this.props.getPMEffectiveDate(1, 3, 'projectName', 'asc', '', '');
        this.props.getPMProjectList(1, globalVariable.tabTableRowSize, 'projectName', 'asc', '', '', selectedSites, year, userRole, org_id);
        this.props.getComplianceIssueSummary(year, userRole, org_id, selectedSites)
        this.props.getProjectsList(userRole, org_id, year)
        if (this.userRole === orgTypesConstants.PROP_MGR) {
            this.props.getComplianceIssue(year, userRole, org_id);
            this.props.getPMEffectiveDate(1, 3, 'projectName', 'asc', '', '');
        }
    }

    handleDashboardSummaryActions = (type) => {
        if (type === 'Explore More') {
            this.setState({ currentTab: 1 })
        }
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof Dashboard
     */
    render() {
        const { classes, dashboard, pageAccess, helpData, match, location, dashboardHO } = this.props;
        const { user } = this;
        const { openHelp, currentTab, reportYear } = this.state
        let actions = ['REPORTYEAR', 'HELP'];
        if (currentTab === '1') {
            actions = ['REPORTYEAR', 'HELP'];
        } else if (currentTab === '2') {
            actions = ['HELP'];
        }

        return (
            <Grid container className="dashreport" spacing={2}>
                <React.Fragment>
                    <Grid item xs={12} sm={6} className="dashBoardTopLtTitle">
                        <PageTitle title="Dashboard Homeownership" />
                    </Grid>
                    <Grid item xs={12} sm={6} className="dashBoardTopRtTitle">
                        <PageActions
                            actions={actions}
                            reportYear={reportYear}
                            handleHelpActionClick={this.handleHelpActionClick}
                            handleDownloadActionClick={this.handleDownloadActionClick}
                            handleReportYearActionClick={(year) => this.handleReportYearActionClick(year)}
                            downloadColor='secondary'
                        />
                    </Grid>
                </React.Fragment>
                {
                    user !== "superFunder" && user !== "superFunderWithHO" &&
                    <div className={`${classes.tab} dashboardREP`}>
                        <TabContext value={currentTab}>
                            <AppBar position="static" color="default">
                                <TabList
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label="Summary" value={'0'} />
                                    <Tab label="Explore Needs Action" value={'1'} />
                                    <Tab label="View / Download Reports" value={'2'} />
                                </TabList>

                            </AppBar>
                            <TabPanel value={'0'} index={0} id="divIdToPrint">
                                <DashboardSummary
                                    reportYear={reportYear}
                                    PCLCounts={dashboardHO.PCLCounts}
                                    complianceIssues={dashboard.complianceIssue}
                                    // occupancyRate={dashboard.occupancyRate}
                                    loanRate={dashboardHO.loanRate}
                                    reportStatus={dashboardHO.reportStatus}
                                    // utilituAllowance={dashboard.effectiveDate}
                                    match={match}
                                    handleActions={(type) => this.handleDashboardSummaryActions(type)} />
                            </TabPanel>
                            <TabPanel value={'1'} index={1}>
                                <ExploreNeedsAction reportYear={reportYear}/>
                            </TabPanel>
                            <TabPanel value={'2'} index={2} className={`${classes.DashboardTab} dashboardHOreport`}>
                                <DashboardReports
                                    pageAccess={pageAccess}
                                    location={location}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                }
                {
                    (user === "superFunder" || user === "superFunderWithHO") &&
                    <div className={classes.tab}>
                        <TabContext value={currentTab}>
                            <AppBar position="static" color="default">
                                <TabList
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                >
                                    <Tab label="Summary" value={'0'} />
                                    <Tab label="Explore Needs Action" value={'1'} />
                                    <Tab label="View / Download Reports" value={'2'} />
                                </TabList>

                            </AppBar>
                            <TabPanel value={'0'} index={0} id="divIdToPrint">
                                <DashboardSummary
                                    reportYear={reportYear}
                                    PCLCounts={dashboardHO.PCLCounts}
                                    complianceIssues={dashboard.complianceIssue}
                                    // occupancyRate={dashboard.occupancyRate}
                                    loanRate={dashboardHO.loanRate}
                                    reportStatus={dashboardHO.reportStatus}
                                    // utilituAllowance={dashboard.effectiveDate}
                                    match={match}
                                    handleActions={(type) => this.handleDashboardSummaryActions(type)} />
                            </TabPanel>
                            <TabPanel value={'1'} index={1}>
                                <ExploreNeedsAction reportYear={reportYear}/>
                            </TabPanel>
                            <TabPanel value={'2'} index={2} className={`${classes.DashboardTab} dashboardHOreport`}>
                                <DashboardReports
                                    pageAccess={pageAccess}
                                    location={location}
                                />
                            </TabPanel>
                        </TabContext>
                    </div>
                }
                { openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Dashboard Homeownership"
                />}
            </Grid>
        );
    }
}

// prop types
DashboardHO.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired
};

/**
 * Default Props
 */
DashboardHO.defaultProps = {
    pageAccess: []
};

// Maps state from store to props
const mapStateToProps = (state, ownProps) => {
    const pageAccess = state.pageAccess['dashboardho'] || [];
    const helpData = state.help.data[0]
    return {
        dashboard: state.dashboard,
        dashboardHO: state.dashboardHO,
        search: state.dashboard.search,
        dashboardCompliance: state.dashboard.complianceNeedsAction,
        pageAccess,
        helpData
    }
};

// Maps actions to props
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getComplianceIssueSummary: (year, userRole, org_id, selectedSites) => dispatch(dashboardActions.getComplianceIssueSummary(year, userRole, org_id, selectedSites)),
        getComplianceIssue: (year, userRole, org_id) => dispatch(dashboardActions.getComplianceIssue(year, userRole, org_id)),
        // getOccupancyRate: (year) => dispatch(dashboardActions.getOccupancyRate(year)),
        getReportStatus: (year, userRole) => dispatch(dashboardHOActions.getReportStatus(year, userRole)),
        getPMEffectiveDate: (skip, limit, orderBy, order, sFields, sValues) => dispatch(dashboardActions.getPMEffectiveDate(skip, limit, orderBy, order, sFields, sValues)),
        getProjectSummary: (limit, search) => dispatch(dashboardHOActions.getProjectSummary(limit, search)),
        viewProjectSummaryReport: (data) => dispatch(dashboardActions.viewProjectSummaryReport(data)),
        downloadProjectSummaryReport: (data) => dispatch(dashboardActions.downloadProjectSummaryReport(data)),
        downloadDashboardReport: (data) => dispatch(dashboardActions.downloadDashboardReport(data)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 24 })),
        getPCLCounts: (userRole, reportYear) => dispatch(dashboardHOActions.getPCLCounts(userRole, reportYear)),
        getLoanRate: (userRole, year) => dispatch(dashboardHOActions.getLoanRate(userRole, year)),
        getLoanComplianceIssue: (year, userRole) => dispatch(dashboardHOActions.getLoanComplianceIssue(year, userRole)),
        getProjectsList: (userRole, org_id, year) => dispatch(dashboardHOActions.getProjectsList(userRole, org_id, year)),
        getPMProjectList: (skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id) => dispatch(dashboardHOActions.getPMProjectList(skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id)),
        // getComplianceIssueSummary: (year, userRole, org_id, selectedSites) => dispatch(dashboardHOActions.getComplianceIssueSummary(year, userRole, org_id, selectedSites)),
    }
};

// export Dashboard
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(DashboardHO));