import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import MergeContainer from './MergeContainer';

/**
 * MergeRouter Component
 * @class MergeRouter
 * @extends {Component}
 */
class MergeRouter extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route path={`${match.url}`} render={(props) => {
                    return <MergeContainer   {...this.props} />
                }} />
            </Switch>

        );
    }
}
/**
 * Bind Component Property Types
 */
MergeRouter.propTypes = {
    classes: PropTypes.object,
};
/**
 * Export Component
 */
export default MergeRouter;
