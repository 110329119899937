import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import Help from "./Help";
import HelpView from './HelpView';
import HelpNew from './HelpNew';
/**
 * HelpRouter container
 * @class HelpRouter
 * @extends {Component}
 */
class HelpRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match} = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <Help match={props.match} page={'help'} {...props} />
                }} />
                <Route exact path={`${match.url}/new`} component={HelpNew} />
                <Route path={`${match.url}/helpview/:_id`}  render={(props) => {
                    return <HelpView _id={props.match.params._id} {...props}  />
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default HelpRouter;
