import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Components
import ValidatorForm from "../../../../helpers/fieldValidations";
import EditableTable from '../../../../components/EditableTable/EditableTable';
import TextBox from '../../../../components/TextBox/TextBox';
import SaveCancel from "../../../../components/SaveCancel/SaveCancel";
import AlertDialog from "../../../../components/AlertDialog/AlertDialog";
import NumberField from '../../../../components/NumberField/NumberField';
import ContentWrapper from "../../../../components/ContentWrapper/ContentWrapper";
import TableErrors from '../../../../components/TableErrors/TableErrors';
import TextArea from '../../../../components/TextArea/TextArea';

// Import Actions
import { reportTablesActions } from '../../../../actions/reportTables';
import { confActions } from '../../../../actions/configuration';
import { alertActions, exploreActions } from "../../../../actions";

// Import Styles
import pageStyle from '../../../../assets/jss/containers/common';

// Import Helpers 
import { history, getSubmissionLevel, ReportLevelsConstants } from '../../../../helpers';
import { configurationConstants } from '../../../../helpers/appConstants';
import Table2AlternateValidations from '../../../../helpers/TableValidations/Table2Alternate';

// Table validations warning helper
import { hasWarnings, hasWarningsSection } from '../../../../helpers/TableValidations/warnings';
import { WarningNames } from './DefaultDatas';

const DEFAULT_CONFIG = {
    race: []
};

const fieldClass = {
    raceCount: 'txt-right',
    hispanicCount: 'txt-right',
};
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextBox
                className={className}
                name={`${fieldName} _${rowIndex} `}
                value={data[fieldName]}
                //validators={['required']}
                disabled={isReadOly}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    raceCount: EditableText,
    hispanicCount: EditableText,
    comment: EditableText
}
/**
 * Table2Alternate Component
 * @class Table2Alternate
 * @extends {Component}
 */
class Table2Alternate extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.section1 = React.createRef();
        this.section2 = React.createRef();
        this.section3 = React.createRef();
        this.section4 = React.createRef();
        this.section5 = React.createRef();
        this.section6 = React.createRef();
        this.state = {
            //section 1
            singleBedUnits: '',
            householdUnits: '',
            //section 2
            totalOccupiedBednights: '',
            totalBednightsAvailable: '',
            averageLengthofStay: '',
            maximumLengthofStay: '',
            //section 3
            totalNumberofFamilyHouseholdserved: '',
            totalNumberofIndividualHouseholdserved: '',
            totalHouseholdsServed: '',
            //section 4
            label0to30: '',
            label31to50: '',
            label51to80: '',
            over80: '',
            totalHouseholdServedIncome: '',
            //section 5
            race: [],
            fields: [],
            Total: '',
            //section 6
            singleMothersWithChildrens: '',
            singleFathersWithChildrens: '',
            totalSingleParentHouseholds: '',
            //section 7
            comments: '',
            allDetails: [],
            config: {
                ...DEFAULT_CONFIG
            },
            type: null,
            popupContent: null,
            isloaded: false,
            notes: '',
            error: null,
            //created:false
        }
    }
    /**
     * Component Will mount
     * 
     * [1]  -   get the table 2 data
     * [2]  -   Get Race Config
     * [3]  -   get validation issues
     */
    UNSAFE_componentWillMount() {
        const { reportId } = this.props;
        if (reportId) {
            // [1]
            this.props.getTable2Data(reportId);
        }
        // [2]
        this.props.getConfiguration(configurationConstants.race);
        // [3]
        if (reportId) {
            this.props.validationIssues(0, 0, reportId, "2");
        }
        ValidatorForm.addValidationRule('isValidBeds', (value) => {
            const { totalBednightsAvailable } = this.state;
            if (totalBednightsAvailable !== undefined && totalBednightsAvailable !== null && totalBednightsAvailable !== '' && value !== 0) {
                return value <= parseFloat(totalBednightsAvailable);
            }
            return true;
        });
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Hande Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save Form
     * 
     * [1]  -   set up api form submission datas  
     */
    _saveForm() {
        // [1]    
        const id = this.props.reportId
        const { reportId } = this.props;
        //allDetails
        const table = this.state.allDetails.map((it) => {
            return {
                "_id": it._id,
                "race_id": it.race_id,
                "raceCount": parseInt(it.raceCount, 10),
                "hispanicCount": parseInt(it.hispanicCount, 10),
                "comment": it.comment
            }
        });
        const generalData = {
            table,
            //section 1
            singleBedUnits: parseInt(this.state.singleBedUnits, 10),
            householdUnits: parseInt(this.state.householdUnits, 10),
            //Section 2
            totalOccupiedBednights: parseInt(this.state.totalOccupiedBednights, 10),
            totalBednightsAvailable: parseInt(this.state.totalBednightsAvailable, 10),
            averageLengthofStay: parseInt(this.state.averageLengthofStay, 10),
            maximumLengthofStay: parseInt(this.state.maximumLengthofStay, 10),
            //Section 3
            totalNumberofFamilyHouseholdserved: parseInt(this.state.totalNumberofFamilyHouseholdserved, 10),
            totalNumberofIndividualHouseholdserved: parseInt(this.state.totalNumberofIndividualHouseholdserved, 10),
            //Section 4
            label0to30: parseInt(this.state.label0to30, 10),
            label31to50: parseInt(this.state.label31to50, 10),
            label51to80: parseInt(this.state.label51to80, 10),
            over80: parseInt(this.state.over80, 10),
            totalHouseholdServedIncome: parseInt(this.state.totalHouseholdServedIncome, 10),
            //Section 6             
            allDetails: this.state.allDetails,
            //Section 7
            singleMothersWithChildrens: parseInt(this.state.singleMothersWithChildrens, 10),
            singleFathersWithChildrens: parseInt(this.state.singleFathersWithChildrens, 10),
            totalSingleParentHouseholds: parseInt(this.state.totalSingleParentHouseholds, 10),
            comments: '',
            totalRestrictedUnitsHouseholdsServed: this.state.Total ? parseInt(this.state.Total, 10) : 0,
            customeType: 'Alternate',
            notes: this.state.notes,
            reportId: this.props.reportId,
            reportYear_id: this.props.reportId
        };

        if (this.state._id && reportId) {
            // update table 2
            //this.setState({created:true})
            this.props.getTable2DataUpdate(id, generalData);
        } else {
            // if(!this.state.created){
            // create table 2
            //this.setState({created:true})
            this.props.createTable2(generalData,()=>{
                //Handled for new creation validation issue
                generalData['new'] = true;
                this.props.getTable2DataUpdate(id, generalData);
                //this.props.validationIssues(0, 0, reportId, "2");
            });
            //}
        }
    }
    /**
     * Cell Component Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { allDetails } = this.state;
        allDetails[rowIndex][fieldName] = value;
        this.setState({ allDetails });
    }
    /**
     * Component Will reeive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, races } = nextProps;
        const id = getOne.data.data ? getOne.data.data._id : null;
        if (getOne.data.data && races.length > 0 && (!this.state.isloaded || this.state._id !== id)) {
            let race = races.map(r => {
                return {
                    desc: r.desc,
                    id: r.key
                }
            });
            this.setState({
                isloaded: true,
                //Section 1
                singleBedUnits: getOne.data.data.singleBedUnits ? getOne.data.data.singleBedUnits : "0",
                householdUnits: getOne.data.data.householdUnits ? getOne.data.data.householdUnits : "0",
                //Section 2
                totalOccupiedBednights: getOne.data.data.totalOccupiedBedNights ? getOne.data.data.totalOccupiedBedNights : "0",
                totalBednightsAvailable: getOne.data.data.totalBedNightsAvailable ? getOne.data.data.totalBedNightsAvailable : '',
                averageLengthofStay: getOne.data.data.averageLengthOfStay ? getOne.data.data.averageLengthOfStay : 0,
                maximumLengthofStay: getOne.data.data.maximumLengthOfStay ? getOne.data.data.maximumLengthOfStay : 0,
                //Section 3
                totalNumberofFamilyHouseholdserved: getOne.data.data.totalFamilyHouseholdsServed ? getOne.data.data.totalFamilyHouseholdsServed : "0",
                totalNumberofIndividualHouseholdserved: getOne.data.data.totalIndividualHouseholdsServed ? getOne.data.data.totalIndividualHouseholdsServed : "0",
                totalHouseholdsServed:
                    parseInt(getOne.data.data.totalFamilyHouseholdsServed ? getOne.data.data.totalFamilyHouseholdsServed : 0, 10)
                    + parseInt(getOne.data.data.totalIndividualHouseholdsServed ? getOne.data.data.totalIndividualHouseholdsServed : 0, 10),
                //Section 4                
                label0to30: getOne.data.data.householdIncome0To30Percent ? getOne.data.data.householdIncome0To30Percent : 0,
                label31to50: getOne.data.data.householdIncome31To50Percent ? getOne.data.data.householdIncome31To50Percent : 0,
                label51to80: getOne.data.data.householdIncome51To80Percent ? getOne.data.data.householdIncome51To80Percent : 0,
                over80: getOne.data.data.householdIncomeAbove80Percent ? getOne.data.data.householdIncomeAbove80Percent : 0,
                // totalHouseholdServedIncome: parseInt(getOne.data.data.householdIncome0To30Percent ? getOne.data.data.householdIncome0To30Percent : 0)
                //     + parseInt(getOne.data.data.householdIncome31To50Percent ? getOne.data.data.householdIncome31To50Percent : 0)
                //     + parseInt(getOne.data.data.householdIncome51To80Percent ? getOne.data.data.householdIncome51To80Percent : 0)
                //     + parseInt(getOne.data.data.householdIncomeAbove80Percent ? getOne.data.data.householdIncomeAbove80Percent : 0),
                totalIndividualHouseholdsServed: parseInt(getOne.data.data.totalIndividualHouseholdsServed ? getOne.data.data.totalIndividualHouseholdsServed : 0, 10),
                //Section 6
                raceHeader: ['Race', 'Head of Household count by Race', 'Head of Household count of Hispanic Ethnicity', 'Comments'],
                race: races.map(r => {
                    return {
                        desc: r.desc,
                        id: r.key
                    }
                }),
                table2race: getOne.data.data.table2RaceEthnicity ? getOne.data.data.table2RaceEthnicity : 0,
                allDetails: race.map((s, index) => {
                    const Details = getOne.data.data.table2RaceEthnicity.filter(gs => gs.race_id === s.id)[0] || { raceCount: 0, hispanicCount: 0, comment: '' };
                    return {
                        ...Details,
                        desc: s.desc,
                        raceCount: Details.raceCount,
                        hispanicCount: Details.hispanicCount,
                        race_id: s.id
                    }
                }),
                //Section 7
                singleMothersWithChildrens: getOne.data.data.femaleSingleParentServedCount ? getOne.data.data.femaleSingleParentServedCount : 0,
                singleFathersWithChildrens: getOne.data.data.maleSingleParentServedCount ? getOne.data.data.maleSingleParentServedCount : 0,
                totalSingleParentHouseholds: parseInt(getOne.data.data.femaleSingleParentServedCount ? getOne.data.data.femaleSingleParentServedCount : 0, 10)
                    + parseInt(getOne.data.data.maleSingleParentServedCount ? getOne.data.data.maleSingleParentServedCount : 0, 10),
                comments: '',
                Total: getOne.data.data.totalRestrictedUnitsServedByPercent_Total ? getOne.data.data.totalRestrictedUnitsServedByPercent_Total : 0,
                notes: getOne.data.data.notes ? getOne.data.data.notes : "",
                _id: getOne.data.data._id,
                reportYear_id: getOne.data.reportYear_id,
            })
        }
        // update config
        if (this.state.config.race.length === 0 && races.length > 0) {
            this.setState({
                config: {
                    race: races
                }
            })
        }
        //this.setState({created:false})
    }
    getTotal = () => {
        return this.state.allDetails.reduce((a, b) => a + parseFloat(b.raceCount === '' || b.raceCount === null ? 0 : b.raceCount), 0, 10);
    }

    getTotalHispanic = () => {
        return this.state.allDetails.reduce((a, b) => a + parseFloat(b.hispanicCount === '' || b.hispanicCount === null ? 0 : b.hispanicCount), 0, 10);
    }

    getTotalNumberofHouseholdServed = () => {
        const { totalNumberofFamilyHouseholdserved, totalNumberofIndividualHouseholdserved } = this.state;
        return parseInt(totalNumberofFamilyHouseholdserved ? totalNumberofFamilyHouseholdserved : 0, 10) +
            parseInt(totalNumberofIndividualHouseholdserved ? totalNumberofIndividualHouseholdserved : 0, 10)
    }

    getTotalHouseholdIncome = () => {
        const { label0to30, label31to50, label51to80, over80 } = this.state;
        return parseInt(label0to30 ? label0to30 : 0, 10) + parseInt(label31to50 ? label31to50 : 0, 10) + parseInt(label51to80 ? label51to80 : 0, 10) + parseInt(over80 ? over80 : 0, 10)
    }
    getTotalofSingleParent = () => {
        const { singleFathersWithChildrens, singleMothersWithChildrens } = this.state;
        return parseInt(singleFathersWithChildrens ? singleFathersWithChildrens : 0, 10) + parseInt(singleMothersWithChildrens ? singleMothersWithChildrens : 0, 10)
    }
    /**
     * Handle Submit
     * @param {*} type 
     * @returns 
     * 
     * [1]  -   if not saved atleast oncedb.table1Snapshot.updateMany({_id:1090426},{$set:{moveInDate:ISODate("2010-10-29T00:00:00.000")}})

     * [2]  -   disable submit when has hardstop
     * [3]  -   if saved atleast once
     */
    handleSubmitActionClick = (type) => {
        // [1]
        if (this.state._id === undefined) {
            return this.setState({
                error: {
                    message: 'Table 2 must be saved at least once before Submission'
                }
            });
        }
        // [2]
        const { validations } = this.props;
        if (validations && type === 'SUBMIT') {
            if (validations.data.table2 === 3) {
                this.props.showErrorAlert('Need to fix Hard stop errors before submit!');
                return;
            } else if (validations.data.table2 === 4) {
                this.props.showErrorAlert('Previous Year Report Not Submitted!');
                return;
            } else { }
        }
        // [3]
        this.setState({
            type
        });
    }
    /**Table 2 Download Report */
    handleDownloadActionClick = () => {
        const { getOne } = this.props;
        const reportData = {
            reportYear_id: getOne.data.desc._id,
            project_id: getOne.data.desc.project_id,
            site_id: getOne.data.desc.site_id,
            year: getOne.data.desc.year
        }

        this.props.downloadTable2Report(reportData.project_id, reportData.site_id, reportData.year);
    }
    /**
     * Pop Alert
     * @param {*} type 
     * @returns 
     */
    popAlert = (type) => {
        const { unsubmitComment } = this.state;
        const { classes, pageAccess } = this.props;
        const isReadOly = pageAccess.indexOf('UNSUBMIT') === -1;
        const levelM = `${type}_${getSubmissionLevel(pageAccess)}`;
        switch (levelM) {
            case 'SUBMIT_1':
            case 'SUBMIT_2':
            case 'SUBMIT_3':
            case 'SUBMIT_4':
                return 'Submitting a Report Table will send it forward to the level after yours.  Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'
            case 'UNSUBMIT_1':
            case 'UNSUBMIT_2':
            case 'UNSUBMIT_3':
            case 'UNSUBMIT_4':
                return (
                    <Grid item xs={12} sm={12}>
                        <div>{'Unsubmitting a Report Table will send it backward to the level before yours. Once submitted you will no longer be able to edit this specific Report Table page for this year; you will be able to edit any other Report Table still under your control.  Do you want to continue?'}</div>
                        <div style={{ minWidth: '400px' }}>
                            <TextArea
                                name="unsubmitComment"
                                label="Comment"
                                value={unsubmitComment}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                margin="dense"
                                disabled={isReadOly}
                                multiline={true}
                            />
                        </div>
                    </Grid>
                )
            default: return null;
        }
    }
    /**
     * Handle submit report
     */
    handleSubmitReport = () => {
        if (this.state._id) {
            this._submitReport();
        }
    }
    /**
     * Submit report 
     */
    _submitReport = () => {
        const { type } = this.state;
        const { reportId } = this.props;
        if (reportId) {
            if (type === 'SUBMIT') {
                this.props.submitReport(reportId, { comment: this.state.comments });
                this.setState({
                    type: null
                });
            } else {
                if (this.state.unsubmitComment !== '') {
                    this.props.unSubmitReport(reportId, { comment: this.state.unsubmitComment });
                    this.setState({
                        type: null
                    });
                }
            }
        }
        this._saveForm();
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                className={`textBox--bordered txt-right input--disabled`}
                value={value}
                disabled={true}
                prefix={''}
                thousandSeparator={true}
                decimalScale={0}
            />
        )
    }
    /**
     * Scroll to content
     * @param {*} content 
     */
    scrollToContent(content) {
        if (content.sectionIndex) {
            switch (content.sectionIndex) {
                case 1:
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 2:
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 3:
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 4:
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 5:
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 6:
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 7:
                    this.section7.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 8:
                    this.section8.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        } else {
            let warningKey = WarningNames.filter(e => e.title.match(content.title || content.fieldName))[0].type;
            switch (warningKey) {
                case 'section1':
                    this.section1.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section2':
                    this.section2.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section3':
                    this.section3.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section4':
                    this.section4.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section5':
                    this.section5.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                case 'section6':
                    this.section6.current.scrollIntoView({ behavior: 'smooth' });
                    break;
                default:
                    break;
            }
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, getOne, validations } = this.props;
        let { pageAccess } = this.props;
        const {
            singleBedUnits,
            householdUnits,

            totalOccupiedBednights,
            averageLengthofStay,
            totalBednightsAvailable,
            maximumLengthofStay,

            totalNumberofFamilyHouseholdserved,
            totalNumberofIndividualHouseholdserved,

            label0to30,
            label31to50,
            label51to80,
            over80,

            raceHeader,

            singleMothersWithChildrens,
            singleFathersWithChildrens,

            // comments,
            notes

        } = this.state;
        let Total = this.getTotal();
        let hispanicTotal = this.getTotalHispanic();
        let totalHouseholdsServed = this.getTotalNumberofHouseholdServed();
        let totalHouseholdServedIncome = this.getTotalHouseholdIncome();
        let totalSingleParentHouseholds = this.getTotalofSingleParent();

        const isReadOly = pageAccess.indexOf('SAVE') === -1;

        if (pageAccess.indexOf('NO_ACCESS') > -1) {
            return <h4 className="section_title textCenter">{'This table is not currently required by any Funder for this report year.'}</h4>;
        }

        const levelInfo = pageAccess.length > 0 ? `Report currently at ${ReportLevelsConstants[pageAccess[pageAccess.length - 1]]} level.` : '';

        // if (validations) {
        //     pageAccess = validations.data.table2 === 2 ? [...pageAccess, 'VALIDATION_WARNING'] : (validations.data.table2 === 3 || validations.data.table2 === 4 ? [...pageAccess, 'VALIDATION_HARDSTOP'] : [...pageAccess, 'NO_VALIDATION_ISSUES']);
        // }

        // get field level validations for table 2
        const fielderrors = Table2AlternateValidations({
            ...this.state, Total, hispanicTotal,
            totalHouseholdsServed, totalHouseholdServedIncome,
            totalSingleParentHouseholds,
            validationErrors: validations.data.table2Issues || []
        });

        return (
            <React.Fragment >
                <ContentWrapper
                    classes={classes}
                    pageAccess={['REFRESH_VALIDATIONS', 'DOWNLOAD', ...pageAccess]}
                    title={levelInfo}
                    titleCss={'bolderText'}
                    titleBg={'white'}
                    pageName="reports"
                    validationContent={getOne.validations}
                    needTitleBar={true}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    handleSubmitActionClick={() => this.handleSubmitActionClick('SUBMIT')}
                    handleUnSubmitActionClick={() => this.handleSubmitActionClick('UNSUBMIT')}
                    handleDownloadActionClick={() => this.handleDownloadActionClick()}
                    handleRefreshValidationsClick={() => {
                        const { reportId } = this.props;
                        this.props.refreshValidations(0, 0, reportId, "2");
                    }}
                >

                    {/* <Grid item xs={12} sm={12}>
                        <h4 className="report_level_title">
                            {levelInfo}
                        </h4>
                    </Grid> */}
                    <TableErrors
                        fielderrors={fielderrors || []}
                        data={getOne.data.data}
                        classes={classes}
                        validations={validations.data.table2Issues || []}
                        handleClickAction={(err) => {
                            this.scrollToContent(err)
                        }}
                    />
                    <div className={classes.rootEditSubPage}>
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => { }} >

                            <Grid container spacing={3}>

                                {/* Number of Restricted Units or Beds*/}
                                <Grid item xs={12} sm={12} ref={this.unitsRef}>
                                    <div ref={this.section1}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 1)}`}> 1 </span>
                                        Number of Units or Beds
                                    </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="singleBedUnits"
                                        label="Single Bed Units:"
                                        value={singleBedUnits}
                                        validators={['required']}
                                        errorMessages={['This Field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'bedUnits'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="householdUnits"
                                        label="Household Units:"
                                        value={householdUnits}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'bedUnits'}
                                    />
                                </Grid>

                                {/*Occupancy*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section2}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 2)}`}> 2 </span>
                                            Occupancy
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalOccupiedBednights"
                                        label="Total Occupied Bednights (used):"
                                        value={totalOccupiedBednights}
                                        validators={['required', 'isValidBeds']}
                                        errorMessages={['This field is required', 'The Total Occupied Bednights (used) can\'t be greater than the Total Bednights Available']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                        fielderrors={fielderrors}
                                        warningfieldname={'totalOccupiedBednights'}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalBednightsAvailable"
                                        label="Total Bednights Available:"
                                        value={totalBednightsAvailable}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="averageLengthofStay"
                                        label="Average Length of Stay (days):"
                                        value={averageLengthofStay}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="maximumLengthofStay"
                                        label="Maximum Length of Stay (days):"
                                        value={maximumLengthofStay}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>

                                {/*Number of Households Served*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section3}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 3)}`}> 3 </span>
                                        Number of Households Served
                                    </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofFamilyHouseholdserved"
                                        label="Total Number of Family Households Served:"
                                        value={totalNumberofFamilyHouseholdserved}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalNumberofIndividualHouseholdserved"
                                        label="Total Number of Individual Household Served:"
                                        value={totalNumberofIndividualHouseholdserved}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                        // errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalHouseholdsServed"
                                        label="Total Households Served:"
                                        value={totalHouseholdsServed}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                    />
                                </Grid>

                                {/*Race and Ethnicity of Restricted  Unit Household Served*/}
                                <Grid item xs={12} sm={12}>
                                    <div ref={this.section4}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 4)}`}> 4 </span>
                                            Race and Ethnicity of Restricted  Unit Household Served
                                        </h4>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} className="p-t-12">
                                    <EditableTable
                                        headerCols={raceHeader}
                                        fieldCols={['desc', 'raceCount', 'hispanicCount', 'comment']}
                                        rowDatas={this.state.allDetails}
                                        componentMap={componentMap}
                                        cellComponentOnChange={this.cellComponentOnChange}
                                        needTitle={false}
                                        tableClass={'fieldWidth12'}
                                        config={{ isReadOly }}
                                        footerDatas={[
                                            {
                                                'desc_footer': 'Total',
                                                'raceCount_footer': this.displayAmount(Total),
                                                'hispanicCount_footer': this.displayAmount(hispanicTotal)
                                            },
                                            {
                                                'desc_footer': <span className={`${hasWarnings(fielderrors, 'sec4total')}`}><label>Total Restricted Unit Households Served:</label></span>,
                                                'raceCount_footer': this.displayAmount(Total),
                                            }
                                        ]}
                                    />
                                </Grid>

                                {/* Household Income*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section5}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 5)}`}> 5 </span>
                                        Household Income
                                    </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label0to30"
                                        label="0-30 %:"
                                        value={label0to30}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label31to50"
                                        label="31-50 %:"
                                        value={label31to50}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="label51to80"
                                        label="51-80 %:"
                                        value={label51to80}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="over80"
                                        label="Over 80%:"
                                        value={over80}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalHouseholdServedIncome"
                                        label="Total Household Served:"
                                        value={totalHouseholdServedIncome}
                                        errorMessages={['this field is required']}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'sec5total'}
                                    />
                                </Grid>

                                {/*Single Parent Head of Household*/}
                                < Grid item xs={12} sm={12}>
                                    <div ref={this.section6}>
                                        <h4 className="section_title">
                                            <span className={`title_issues ${hasWarningsSection(fielderrors, 6)}`}> 6 </span>
                                            Single Parent Head of Household
                                        </h4>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="singleMothersWithChildrens"
                                        label="Single Mothers With Childrens:"
                                        value={singleMothersWithChildrens}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="singleFathersWithChildrens"
                                        label="Single Fathers With Childrens:"
                                        value={singleFathersWithChildrens}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOly}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextBox
                                        name="totalSingleParentHouseholds"
                                        label="Total Single Parent Households:"
                                        value={totalSingleParentHouseholds}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        fielderrors={fielderrors}
                                        warningfieldname={'sec6total'}
                                    />
                                </Grid>

                                < Grid item xs={12} sm={12}>
                                    <Card className='projectsummary_card commentMargin'>
                                        <CardContent className="d_down_report">
                                            <TextBox
                                                name="notes"
                                                label="Comments"
                                                value={notes}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={isReadOly}
                                            />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </ValidatorForm>
                        {
                            pageAccess.indexOf('SAVE') > -1 ? (
                                <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />)
                                : null
                        }
                        {/* <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.goBack() }} /> */}
                    </div>
                </ContentWrapper>
                <ValidatorForm ref="submitReport" onSubmit={this.handleSubmitReport}>
                    <AlertDialog
                        open={this.state.type !== null}
                        title={'Warning:'}
                        onSave={() => this.refs.submitReport.submit()}
                        onClose={() => {
                            this.setState({ type: null })
                        }}
                        saveText={'Submit'}
                    >
                        {this.popAlert(this.state.type)}
                    </AlertDialog>
                </ValidatorForm>

                {
                    this.state.error !== null && (
                        <AlertDialog
                            open={true}
                            title={'Error:'}
                            saveVisible={false}
                            onClose={() => {
                                this.setState({ error: null })
                            }}
                            cancelText={'OK'}
                        >
                            {this.state.error.message}
                        </AlertDialog>
                    )
                }
            </React.Fragment >
        )
    }
}


/**
 * Bind Component Property Types
 */
Table2Alternate.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

Table2Alternate.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ pageAccess, reportTables, configuration }, ownProps) => {
    return {
        pageAccess: pageAccess['table2'] || [],
        getOne: reportTables.table2,
        races: configuration.configurations[configurationConstants.race] || [],
        validations: { data: reportTables.validationIssues }
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable2Data: (id) => dispatch(reportTablesActions.getTable2Data(id)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getTable2DataUpdate: (id, data) => dispatch(reportTablesActions.getTable2DataUpdate(id, data)),
        createTable2: (data,callback) => dispatch(reportTablesActions.createTable2(data,callback)),
        submitReport: (id, formData) => dispatch(reportTablesActions.submitTable(id, formData, 2)),
        unSubmitReport: (id, formData) => dispatch(reportTablesActions.unSubmitTable(id, formData, 2)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        validationIssues: (project_id, site_id, reportYear_id, reportTable) => dispatch(reportTablesActions.getIssues(project_id, site_id, reportYear_id, reportTable)),
        refreshValidations: (project_id = 0, site_id = 0, reportYear_id = 0, reportTable = 1) => dispatch(reportTablesActions.refreshValidations(project_id, site_id, reportYear_id, reportTable)),
        downloadTable2Report: (project_id, site_id, year) => dispatch(exploreActions.downloadTable2Report(project_id, site_id, year, 'WBARS_Table2Export.xlsx'))

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table2Alternate));
