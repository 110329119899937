//import constants
import { alertConstants } from '../constants';

export const alertActions = {
    success,
    info,
    error,
    clear
};

/**
 *success message for any alert
 *
 * @param {*} message
 * @returns
 */
function success(message) {
    return { type: alertConstants.SUCCESS, message };
}

/**
 *any alert information
 *
 * @param {*} message
 * @returns
 */
function info(message) {
    return { type: alertConstants.INFO, message };
}

/**
 *Error message for any alert
 *
 * @param {*} message
 * @returns
 */
function error(message) {
    return { type: alertConstants.ERROR, message };
}

/**
 *Clear alert
 *
 * @returns
 */
function clear() {
    return { type: alertConstants.CLEAR };
}