import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

// Helpers
import { history, getUserRole, constants, getUserOrgId, globalVariable, getHomeownershipAccess,getMultifamilyAccess } from '../../helpers';
import ValidatorForm, { FieldValidations } from "../../helpers/fieldValidations";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import GroupCheckBox from '../../components/GroupCheckBox/GroupCheckBox';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import SaveCancel from '../../components/SaveCancel/SaveCancel';

// Import Actions and Helper Files
import { orgActions } from "../../actions/organizations";
import { confActions } from "../../actions/configuration";

// Import Constants
import { orgTypesConstants, configurationConstants, statusConstants } from '../../helpers/appConstants';

/**
 * OrganizationsEdit Container
 * @class OrganizationsEdit
 * @extends {Component}
 */
class OrganizationsEdit extends Component {
    /**
     * Init Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            orgId: '',
            name: '',
            organizationType: [],
            prevorganizationType:[],
            prevOrgTypeData:[],
            organizationAccess: 0,
            prevOrgAccess: 0,
            organizationAccessType: 0,
            organizationAccessData: [],
            organizationTypeData: [],
            phone: '',
            address: '',
            city: null,
            state: null,
            postalCode: '',
            website: '',
            isActive: 1,
            inActiveOrgId: null,
            nonEditable: false,
            isCommerceUser: false,
            roleChange: false,
            orgAccess: {},
            multiFamilyProjects: [],
            homeOwnershipProjects: [],
            multiFamilyUserNames: [],
            homeOwnershipUserNames: [],
            hasProjects: false,
            hasProjectsText: '',
            hasProjectsTextTrimmed: '',
            isReadMoreClicked: false,
            OrgAccessChange: null,
            totalContacts:null,
            orgTypeAlert:false,
            orgTypeAlertText:'',
            newOrgAccess:false
        };
        this.userRole = getUserRole();
        this.orgId = getUserOrgId();
    }

    /**
     * Component Did Mount
     * 
     * [1]  -   get org details
     * [2]  -   Get State and City
     * [3]  -   Allowing inactive org to be created 
     */
    componentDidMount() {
        const orgId = this.props.orgId;
        if (orgId) {
            // [1]
            this.props.getById(orgId);
        }
        // [2]
        this.props.getConfiguration(configurationConstants.state);
        this.props.getConfiguration(configurationConstants.cities);
        this.props.getContacts(1, globalVariable.tableRowSize, 'name', 'asc', '', '', false, orgId)
        //[3]
        if (!this.state.isActive && history.location.pathname.split("/")[2] !== 'new') {
            this.setState({
                inActiveOrgId: this.state.orgId
            });
        }
    }

    /**
     * Component Will Receive Props Event
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {

        //set orgaccessdata
        const { orgs, pageAccess,contacts } = nextProps;
        const { userRole, orgId } = this;
        let organizationAccessData = [];
        if (orgId === 2 || (getHomeownershipAccess()===true && getMultifamilyAccess()===true)) {
            organizationAccessData = [
                { name: "Multi-Family", label: "Multi-Family", value: 1 },
                { name: "Homeownership", label: "Homeownership", value: 2 },
                { name: "Both", label: "Both", value: 3 }
            ];
            this.setState({
                organizationAccessData: organizationAccessData,
            })
        } else if(getMultifamilyAccess()===true && getHomeownershipAccess()===false) {
            organizationAccessData = [
                { name: "Multi-Family", label: "Multi-Family", value: 1 },
            ];
            this.setState({
                organizationAccessData: organizationAccessData,
            })
        }else{
            organizationAccessData = [
                { name: "Homeownership", label: "Homeownership", value: 2 },
            ];
            this.setState({
                organizationAccessData: organizationAccessData,
            })
        }
        
        if(contacts && contacts.data){
            this.setState({totalContacts:contacts.data.length})
        }

        if (orgs && orgs.getOne.data && (orgs.getOne.data._id !== this.state.orgId) && this.props.orgId) {
            const getOne = orgs.getOne.data;
            console.log('GETONE$$$$$$$$$$$$$$$',getOne)
            const projList = orgs.getOne.projectList;
            this.setState({
                orgId: getOne._id,
                name: getOne.name,
                organizationAccess: getOne.organizationAccess,
                prevOrgAccess: getOne.organizationAccess,
                organizationAccessType: getOne.organizationAccess,
                organizationType: getOne.organizationType,
                prevorganizationType:getOne.organizationType,
                phone: getOne.phone,
                website: getOne.website,
                address: getOne.address.line,
                city: getOne.address.city,
                state: getOne.address.state,
                postalCode: getOne.address.postalCode,
                isActive: getOne.isActive === true ? 1 : 0,
                inActiveOrgId: null,
                multiFamilyProjects: projList.projectList,
                homeOwnershipProjects: projList.projectListHO,
                // multiFamilyUserNames: projList.multiFamilyUserNames,
                // homeOwnershipUserNames: projList.hoUserNames

            });

            if (pageAccess.indexOf('IS_ORG_ACTIVE') === -1 && getOne.isActive === false) {
                this.setState({ nonEditable: true })
            }
        }

        let organizationTypeData = [];
        if (userRole === constants.superFunder || userRole === constants.superFunderWithHO) {
            organizationTypeData = [
                {
                    name: "Funder Level",
                    values: ["Funder", "Read Only Organization"]
                },
                {
                    name: "Multi-Family",
                    values: ["Owner", "Property Manager"]
                },
                {
                    name: "Homeownership",
                    values: ["Contractor", "Preparer"]
                }
            ];
        } else {
            organizationTypeData = [
                {
                    name: "Multi-Family",
                    values: ["Owner", "Property Manager"]
                },
                {
                    name: "Homeownership",
                    values: ["Contractor", "Preparer"]
                }
            ];
        }

        if (this.state.organizationAccessType === 1) {
            organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
        } else if (this.state.organizationAccessType === 2) {
            organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
        }

        if (this.props.orgId && parseInt(this.props.orgId) !== 2) {
            this.setState({
                isCommerceUser: true
            })
        }
        if (this.state.organizationAccess === 1) {
            this.setState({
                organizationAccess: { name: "Multi-Family", label: "Multi-Family", value: 1 }
            });
        } else if (this.state.organizationAccess === 2) {
            this.setState({
                organizationAccess: { name: "Homeownership", label: "Homeownership", value: 2 }
            });
        } else if (this.state.organizationAccess === 3) {
            this.setState({
                organizationAccess: { name: "Both", label: "Both", value: 3 }
            });
        }

        this.setState({
            organizationTypeData: organizationTypeData,
            prevOrgTypeData:organizationTypeData
        });
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        //inactive msg based on page 
        if (!this.state.isActive && history.location.pathname.split("/")[2] !== 'new') {
            this.setState({
                inActiveOrgId: this.state.orgId
            });
        } else {
            this._saveForm();
        }
    };
    /**
     * Auto Complete Event
     * @param {*} selectedOption 
     */
    _handleAutoComplete = (selectedOption) => {
        this.setState({ city: selectedOption.value });
    }
    /**
     * Auto Complete Event for State
     * @param {*} selectedOption 
     */
    handleAutoCompleteState = (selectedOption) => {
        this.setState({ state: selectedOption.value });
    }

    /**
     * Auto Complete Event
     */
    handleOnSave = () => {
        const { orgAccess, OrgAccessChange } = this.state

        //Handle org access change and update org type
        let updatedOrgType = []
                if((OrgAccessChange && OrgAccessChange.value &&  OrgAccessChange.value===1) || orgAccess.value===1){
                    if(this.state.prevorganizationType.includes('Funder')){
                        updatedOrgType.push('Funder')
                    }
                    if(this.state.prevorganizationType.includes('Owner')){
                        updatedOrgType.push('Owner')
                    }
                    if(this.state.prevorganizationType.includes('Property Manager')){
                        updatedOrgType.push('Property Manager')
                    }
                }

                if((OrgAccessChange && OrgAccessChange.value && OrgAccessChange.value===2) || orgAccess.value===2){
                    if(this.state.prevorganizationType.includes('Funder')){
                        updatedOrgType.push('Funder')
                    }
                    if(this.state.prevorganizationType.includes('Contractor')){
                        updatedOrgType.push('Contractor')
                    }
                    if(this.state.prevorganizationType.includes('Preparer')){
                        updatedOrgType.push('Preparer')
                    }
                }

                if((OrgAccessChange && OrgAccessChange.value &&OrgAccessChange.value===3) || orgAccess.value===3){
                    if(this.state.prevorganizationType.includes('Funder')){
                        updatedOrgType.push('Funder')
                    }
                    if(this.state.prevorganizationType.includes('Contractor')){
                        updatedOrgType.push('Contractor')
                    }
                    if(this.state.prevorganizationType.includes('Preparer')){
                        updatedOrgType.push('Preparer')
                    }
                    if(this.state.prevorganizationType.includes('Owner')){
                        updatedOrgType.push('Owner')
                    }
                    if(this.state.prevorganizationType.includes('Property Manager')){
                        updatedOrgType.push('Property Manager')
                    }
                }
                updatedOrgType = [...new Set(updatedOrgType)]
        
        //Handle org access change alert
        if ((this.state.homeOwnershipProjects!==undefined && this.state.homeOwnershipProjects.length > 0) || (this.state.multiFamilyProjects!==undefined && this.state.multiFamilyProjects.length > 0)) {
            console.log('Entered1')
            if (OrgAccessChange.name === 'Homeownership' && (this.state.multiFamilyProjects!==undefined && this.state.multiFamilyProjects.length > 0)) {
                let projText ='<p>Org access cannot be removed because they are associated with the following users. Please remove the user from associated projects prior to making this change.</p>';
                let tableText = `<table style="border: 1px solid black;
                    border-collapse: collapse;padding:20px;margin:auto"><tr style="border: 1px solid black;
                    border-collapse: collapse;padding:20px"><th style="border: 1px solid black;
                    border-collapse: collapse;padding:20px">User List</th><th>Associated Projects</th></tr>`;
                let rowText = this.state.multiFamilyProjects.map(item=>{
                    return`<tr style="border: 1px solid black;
                    border-collapse: collapse;padding:20px"><td style="border: 1px solid black;
                    border-collapse: collapse;padding:20px">${item.user}</td><td style="padding:10px">${item.projects}</td></tr>`
                })

                rowText = rowText.join(" ");

                projText = `${projText}${tableText}${rowText}</table>`
                
                this.setState({ hasProjects: true, hasProjectsTextTrimmed: projText, hasProjectsText: projText })
            } else if (OrgAccessChange.name === 'Multi-Family' && (this.state.homeOwnershipProjects!==undefined && this.state.homeOwnershipProjects.length > 0)) {
                let projText ='<p>Org access cannot be removed because they are associated with the following users. Please remove the user from associated projects prior to making this change.</p>';
                let tableText = `<table style="border: 1px solid black;
                    border-collapse: collapse;padding:20px;margin:auto"><tr style="border: 1px solid black;
                    border-collapse: collapse;padding:20px"><th style="border: 1px solid black;
                    border-collapse: collapse;padding:20px">User List</th><th>Associated Projects</th></tr>`;
                let rowText = this.state.homeOwnershipProjects.map(item=>{
                    return`<tr style="border: 1px solid black;
                    border-collapse: collapse;padding:20px"><td style="border: 1px solid black;
                    border-collapse: collapse;padding:20px">${item.user}</td><td style="padding:10px">${item.projects}</td></tr>`
                })

                rowText = rowText.join(" ");

                projText = `${projText}${tableText}${rowText}</table>`
                
                this.setState({ hasProjects: true, hasProjectsTextTrimmed: projText, hasProjectsText: projText })
            } else {
                console.log('Entered2')
                this.setState({ hasProjects: false })
                let { userRole } = this;
                let organizationTypeData = [];
                if (userRole === constants.superFunder || userRole === constants.superFunderWithHO) {
                    organizationTypeData = [
                        {
                            name: "Funder Level",
                            values: ["Funder", "Read Only Organization"]
                        },
                        {
                            name: "Multi-Family",
                            values: ["Owner", "Property Manager"]
                        },
                        {
                            name: "Homeownership",
                            values: ["Contractor", "Preparer"]
                        }
                    ];


                } else {
                    organizationTypeData = [
                        {
                            name: "Multi-Family",
                            values: ["Owner", "Property Manager"]
                        },
                        {
                            name: "Homeownership",
                            values: ["Contractor", "Preparer"]
                        }
                    ];
                }
                if (OrgAccessChange.value === 1) {
                    organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
                } else if (OrgAccessChange.value === 2) {
                    organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
                }
                console.log('orgAccess',orgAccess)
                console.log('prevorganizationType',this.state.prevorganizationType)
                console.log('OrgAccessChange',OrgAccessChange)
                console.log('Entered save 1',organizationTypeData)


                this.setState({
                    organizationAccess: OrgAccessChange,
                    orgAccess:OrgAccessChange,
                    organizationTypeData: organizationTypeData,
                    organizationType: updatedOrgType,
                    roleChange: false,
                    newOrgAccess:updatedOrgType.length===0
                });
                // if (this.state.multiFamilyProjects.length > 0) {
                //     wbarProjText = `<b>WBARS: ${this.state.multiFamilyProjects.join(",").replace(/,(?=[^\s])/g, ", ")}</b>`
                // }
                // if (this.state.homeOwnershipProjects.length > 0) {
                //     hoProjText = `and <b>HO: ${this.state.homeOwnershipProjects.join(",").replace(/,(?=[^\s])/g, ", ")}</b>`
                // }

            }
        }else{
            console.log('Entered3')
            this.setState({ hasProjects: false })
                let { userRole } = this;
                let organizationTypeData = [];
                if (userRole === constants.superFunder || userRole === constants.superFunderWithHO) {
                    organizationTypeData = [
                        {
                            name: "Funder Level",
                            values: ["Funder", "Read Only Organization"]
                        },
                        {
                            name: "Multi-Family",
                            values: ["Owner", "Property Manager"]
                        },
                        {
                            name: "Homeownership",
                            values: ["Contractor", "Preparer"]
                        }
                    ];


                } else {
                    organizationTypeData = [
                        {
                            name: "Multi-Family",
                            values: ["Owner", "Property Manager"]
                        },
                        {
                            name: "Homeownership",
                            values: ["Contractor", "Preparer"]
                        }
                    ];
                }
                if (orgAccess.value === 1) {
                    organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
                } else if (orgAccess.value === 2) {
                    organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
                }

                console.log('Entered save 2',organizationTypeData)
                this.setState({
                    organizationAccess: OrgAccessChange,
                    organizationTypeData: organizationTypeData,
                    organizationType: updatedOrgType,
                    roleChange: false,
                    newOrgAccess:updatedOrgType.length===0
                });
        }
        // let { userRole } = this;
        // let organizationTypeData = [];
        // if (userRole === constants.superFunder || userRole === constants.superFunderWithHO) {
        //     organizationTypeData = [
        //         {
        //             name: "Funder Level",
        //             values: ["Funder", "Read Only Organization"]
        //         },
        //         {
        //             name: "Multi-Family",
        //             values: ["Owner", "Property Manager"]
        //         },
        //         {
        //             name: "Homeownership",
        //             values: ["Contractor", "Preparer"]
        //         }
        //     ];
        // } else {
        //     organizationTypeData = [
        //         {
        //             name: "Multi-Family",
        //             values: ["Owner", "Property Manager"]
        //         },
        //         {
        //             name: "Homeownership",
        //             values: ["Contractor", "Preparer"]
        //         }
        //     ];
        // }
        // if (orgAccess.value === 1) {
        //     organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
        // } else if (orgAccess.value === 2) {
        //     organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
        // }
        //     this.setState({
        //         organizationAccess: orgAccess,
        //         organizationTypeData: organizationTypeData,
        //         organizationType: [],
        //         roleChange: false
        //     });

    }

    /**
     * Save form
     */
    _saveForm() {
        if (this.props.pageAccess.indexOf('IS_ORG_ACTIVE') === -1 && this.state.isActive === false) {
            this.setState({ nonEditable: true })
        }
        //set org access if 0
        if (this.state.organizationAccessData.length === 1 && this.state.organizationAccess === 0) {
            this.setState({ organizationAccess: this.state.organizationAccessData[0] })
        }
        if (this.state.organizationAccess === 0) {
            this.setState({
                organizationAccess: {
                    "name": "Both",
                    "label": "Both",
                    "value": 3
                }
            })
        }
        this.props.handleFormSaveActionClick(this.state);
    }
    /**
     * Bind Org Type
     * @param {*} isReadOly 
     */
    bindOrganizationType(isReadOly) {
        let orgType = [orgTypesConstants.OWNER, orgTypesConstants.PROP_MGR];

        const { pageAccess } = this.props;

        if (pageAccess.indexOf('ORGTYPE') >= 0 || isReadOly === true || this.state.nonEditable === true) {
            orgType = [orgTypesConstants.FUNDER, orgTypesConstants.RO_ORGANIZATION, ...orgType]
        }

        return orgType.map((item, key) =>
            <MenuItem key={key} value={item}>
                <Checkbox checked={this.state.organizationType.indexOf(item) > -1} />
                <ListItemText primary={item} />
            </MenuItem>
        )
    }
    /**
     * Set Org Typr
     * @param {*} value 
     */
    setOrgType(value) {
        let { organizationType,prevorganizationType,orgAccess, organizationAccess, multiFamilyProjects, homeOwnershipProjects } = this.state;


        //Handle org type change alerts
        if(organizationType.length!==0 && !this.state.newOrgAccess && ((multiFamilyProjects&&multiFamilyProjects.length>0) || (homeOwnershipProjects&&homeOwnershipProjects.length>0))){
        let showOrgTypeAlert = false
        if(prevorganizationType.includes('Funder') && value.includes('Funder') && value.length>1){
            this.setState({orgTypeAlert:true,orgTypeAlertText:` Org type cannot be removed because they are associated with ${this.state.totalContacts} users. Please remove the user from associated projects prior to making this change.`})
            return null
        }

        if(prevorganizationType.includes('Read Only Organization') && value.includes('Read Only Organization') && value.length>1){
            this.setState({orgTypeAlert:true,orgTypeAlertText:` Org type cannot be removed because they are associated with ${this.state.totalContacts} users. Please remove the user from associated projects prior to making this change.`})
            return null
        }

        if((value.includes('Funder') && prevorganizationType.indexOf('Funder')===-1) || (value.includes('Read Only Organization') && prevorganizationType.indexOf('Read Only Organization')===-1)){
            this.setState({orgTypeAlert:true,orgTypeAlertText:` Org type cannot be removed because they are associated with ${this.state.totalContacts} users. Please remove the user from associated projects prior to making this change.`})
            return null
        }

        prevorganizationType.map(item=>{
            if(value.indexOf(item)===-1){
                showOrgTypeAlert=true
                this.setState({orgTypeAlert:true,orgTypeAlertText:` Org type cannot be removed because they are associated with ${this.state.totalContacts} users. Please remove the user from associated projects prior to making this change.`})
                return null
            }
        })

        if(showOrgTypeAlert){
            return null
        }
    }
        const currentSelection = value.length > 0 ? [value[value.length - 1]] : [];
        const isContains = organizationType.indexOf(orgTypesConstants.FUNDER) > -1 || organizationType.indexOf(orgTypesConstants.RO_ORGANIZATION) > -1;
        // const multiSeletion = currentSelection[0] === orgTypesConstants.OWNER || currentSelection[0] === orgTypesConstants.PROP_MGR || 
        // currentSelection === orgTypesConstants.CONTRACTOR || currentSelection === orgTypesConstants.PREPARER;
        if (!isContains) {
            if (value === undefined) {
                this.setState({ organizationType: [] })
            } else if (value.length > 1 && (value[value.length - 1] === orgTypesConstants.FUNDER || value[value.length - 1] === orgTypesConstants.RO_ORGANIZATION)) {
                this.setState({ organizationType: currentSelection })
            }
            else {
                this.setState({ organizationType: value });
            }
        }
        else {
            this.setState({ organizationType: currentSelection });
        }
    }
    /**
     * Get Phone Validation
     * @param {*} phone 
     * @returns 
     */
    getPhoneValidation(phone) {
        let str = phone || '';
        if (str.trim() !== '') {
            return {
                validators: [...FieldValidations.phone.validation],
                errorMessages: [...FieldValidations.phone.errorMsg]
            }
        }
        return {};
    }
    /**
     * Handle Text box change
     * @param {*} name 
     * @param {*} value 
     */
    _handleChange = (name, value) => {
        this.setState({ [name]: value })
    }

    handleAutoCompleteOrg = (selectedOption) => {
        console.log('EnTzeredf')
        let { userRole } = this;
        let organizationTypeData = [];
        if (userRole === constants.superFunder || userRole === constants.superFunderWithHO) {
            organizationTypeData = [
                {
                    name: "Funder Level",
                    values: ["Funder", "Read Only Organization"]
                },
                {
                    name: "Multi-Family",
                    values: ["Owner", "Property Manager"]
                },
                {
                    name: "Homeownership",
                    values: ["Contractor", "Preparer"]
                }
            ];
        } else {
            organizationTypeData = [
                {
                    name: "Multi-Family",
                    values: ["Owner", "Property Manager"]
                },
                {
                    name: "Homeownership",
                    values: ["Contractor", "Preparer"]
                }
            ];
        }
        if (selectedOption.value === 1) {
            organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
        } else if (selectedOption.value === 2) {
            organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
        }
        console.log('stateUpdated', selectedOption, organizationTypeData)
        this.setState({
            organizationAccess: selectedOption,
            organizationTypeData: organizationTypeData,
            organizationType: [],
            roleChange: true
        });
    }



    /**
     * Bind Html to DOM
     */
    render() {
        let { name, organizationAccess, organizationTypeData, organizationType, phone, address, city, state, postalCode, website, isActive, nonEditable, roleChange, organizationAccessData, orgAccess } = this.state;
        const { classes, isReadOly, pageAccess, states, cities, newPage } = this.props;
        const { userRole, orgId } = this;
        // const isNonFunderOA = userRole === constants.nonFunderWithOA || userRole === constants.onsite;
        var nonFunderwithOA = false;
        if (userRole === "nonFunderWithOA" || userRole === "nonFunderWithOAandHO" || userRole === "nonFunderWithOAandBoth") {
            nonFunderwithOA = true;
        }
        if(JSON.parse(localStorage.user).org.role!="superFunderWithHO" && JSON.parse(localStorage.user).org.role!="superFunder" ){
            nonEditable=true
        }
        if (organizationAccess===1 || (organizationAccess && organizationAccess.value && organizationAccess.value === 1)) {
            organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
        } else if (organizationAccess===2 || (organizationAccess && organizationAccess.value && organizationAccess.value === 2)) {
            organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
        }

        if (orgId !== 2 && organizationAccess && organizationAccess.value === 3) {
            organizationAccessData = [
                { name: "Both", label: "Both", value: 3 },
                ...organizationAccessData
            ]
        }
        //set default if only one suggestion
        if (organizationAccessData.length === 1) {
            if (organizationAccess && organizationAccessData && organizationAccessData[0]) {
                if (organizationAccess.value === organizationAccessData[0].value) {
                    organizationAccess = organizationAccessData[0]
                }
            }
            if (history.location.pathname.includes('new')) {
                organizationAccess = organizationAccessData[0]
            }
            if (organizationAccess && organizationAccess.value === 1) {
                organizationTypeData = organizationTypeData.filter(item => item.name !== "Homeownership");
            } else if (organizationAccess && organizationAccess.value === 2) {
                organizationTypeData = organizationTypeData.filter(item => item.name !== "Multi-Family");
            }
        }
        //allow basic detail edit for own org users
        let isOwnOrg = getUserOrgId() === this.state.orgId && nonFunderwithOA;


        if (orgAccess && Object.keys(orgAccess).length === 0 && Object.getPrototypeOf(orgAccess) === Object.prototype) {
            if (Object.keys(organizationAccess).length !== 0 && Object.getPrototypeOf(organizationAccess) === Object.prototype) {
                orgAccess = this.state.organizationAccess
            } else {
                orgAccess = this.state.organizationAccess === 1 ? { name: "Multi-Family", label: "Multi-Family", value: 1 } : this.state.organizationAccess === 2 ? { name: "Homeownership", label: "Homeownership", value: 2 } : { name: "Both", label: "Both", value: 3 }
            }
        }
        
        return (
            <div className={classes.rootEdit} >
                <ValidatorForm
                    name="orgForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="name"
                                value={name}
                                label="Organization Name"
                                className={classes.textField}
                                handleChange={this._handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                //old condition isReadOly || isCommerceUser
                                disabled={isReadOly || nonFunderwithOA}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} className={"autoCompleteTop"}>
                            <AutoComplete
                                placeholder='Organization Access'
                                className="autocomplete-fixedOrgAcc"
                                suggestions={organizationAccessData}
                                handleAutoCompolete={((this.state.multiFamilyProjects!==undefined && this.state.multiFamilyProjects.length > 0) || (this.state.homeOwnershipProjects!==undefined && this.state.homeOwnershipProjects.length > 0)) ? ((selectedOption) => { this.setState({ OrgAccessChange: selectedOption, roleChange: (organizationAccessData.length === 1 || selectedOption.name === orgAccess.name) ? false : true }) }) : this.props.orgId ? ((selectedOption) => { this.setState({ orgAccess: selectedOption, roleChange: organizationAccessData.length === 1 ? false : true }) }) : this.handleAutoCompleteOrg}
                                selectedValue={orgAccess}
                                //old condition isReadOly || isCommerceUser
                                disabled={isReadOly || nonFunderwithOA}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        {/* <div className="menuUlsss"> */}
                        <Grid item xs={12} sm={4} className={nonFunderwithOA || isReadOly || nonEditable ? "groupCheklabel organization_type disabledGrey" : "organization_type"}>
                            <GroupCheckBox
                                label="Organization Type"
                                handleChange={(e) => this.setOrgType(e.target.value)}
                                className={"required autocomplete-fixedOrgType"}
                                value={organizationType}
                                options={organizationTypeData}
                                disabled={nonFunderwithOA || isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                            {/* <TextBox
                                name='organizationTypes'
                                label="Organization Type"
                                select
                                className={classes.textField}
                                disabled={isReadOly || isNonFunderOA || nonEditable}
                                margin="dense"
                                type="multiselect"
                                value={organizationType.join(",")}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                SelectProps={{
                                    MenuProps: {
                                        variant: 'menu',
                                    },
                                    multiple: true,
                                    value: organizationType,
                                    renderValue: selected => selected.join(', '),
                                    onChange: (e) => {
                                        this.setOrgType(e.target.value);
                                    }
                                }}
                            >
                                {
                                    this.bindOrganizationType(isReadOly)
                                }
                            </TextBox> */}
                        </Grid>
                        {/* </div> */}

                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name='phone'
                                label="Phone"
                                value={phone}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isOwnOrg ? false : isReadOly}
                                {...this.getPhoneValidation(phone)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name='address'
                                label="Address"
                                value={address}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isOwnOrg ? false : isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={"autoCompleteTop"}>
                            <AutoComplete
                                placeholder='City'
                                // className="autocomplete-fixed"
                                suggestions={cities}
                                handleAutoCompolete={this._handleAutoComplete}
                                selectedValue={{ value: city, id: city }}
                                disabled={isOwnOrg ? false : isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={"autoCompleteTopsy"}>
                            <AutoComplete
                                suggestions={states}
                                handleAutoCompolete={this.handleAutoCompleteState}
                                selectedValue={{ value: state, id: state }}
                                disabled={isOwnOrg ? false : isReadOly}
                                placeholder="State"
                                className="autocompleteStateFix"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name={'postalCode'}
                                label="Zip Code"
                                value={postalCode}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isOwnOrg ? false : isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="website"
                                value={website}
                                label="Website"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={[]}
                                errorMessages={[]}
                                disabled={isOwnOrg ? false : isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DropDown
                                name="isActive"
                                value={isActive}
                                select
                                label="Org Status"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                // disabled={isOwnOrg ? false : isReadOly || nonEditable}
                                disabled={isReadOly || nonEditable}
                            >
                                {
                                    statusConstants.map((item) => {
                                        return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                    })
                                }
                            </DropDown>
                        </Grid>

                    </Grid>
                </ValidatorForm>
                {
                    (pageAccess.indexOf('SAVE') > -1) || isOwnOrg ? (
                        <SaveCancel
                            handleSaveButtonClick={() => { this.refs.form.submit() }}
                            handleCancelButtonClick={() => { history.push("/organizations") }} />
                    ) : null
                }
                {
                    <AlertDialog
                        open={!newPage && roleChange}
                        title={'Warning:'}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {

                                this.setState({ roleChange: false,organizationTypeData:this.state.prevOrgTypeData, organizationType:this.state.prevorganizationType,orgAccess:this.state.prevOrgAccess===1?{ name: "Multi-Family", label: "Multi-Family", value: 1 }:this.state.prevOrgAccess===2?{ name: "Homeownership", label: "Homeownership", value: 2 }:{ name: "Both", label: "Both", value: 3 } })
                            }


                            // history.goBack();
                        }}
                        onSave={() => { this.handleOnSave() }}
                        saveText={'Change'}
                        cancelText={'Cancel'}
                    >
                        Changing the Organization Type will cause change in user role access. If you do not want to change the user role access, click Cancel.
                    </AlertDialog>
                }
                {
                    <AlertDialog
                        open={!newPage && this.state.orgTypeAlert}
                        title={'Warning:'}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {

                                this.setState({ orgTypeAlert: false})
                            }


                            // history.goBack();
                        }}
                        onSave={() => { this.setState({ orgTypeAlert: false}) }}
                        cancelVisible={false}
                        saveText={'Okay'}
                        closeIconVisible={true}
                    >
                        <div dangerouslySetInnerHTML={{ __html: this.state.orgTypeAlertText }}></div>
                    </AlertDialog>
                }
                {
                    <AlertDialog
                        open={this.state.hasProjects}
                        title={'Warning:'}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({ hasProjects: false, organizationAccess: this.state.prevOrgAccess, organizationAccessType: this.state.prevOrgAccess, roleChange: false})
                            }


                            // history.goBack();
                        }}
                        onReadMore={() => {
                            this.setState({ isReadMoreClicked: !this.state.isReadMoreClicked })
                        }}
                        closeIconVisible={true}
                        saveVisible={false}
                        cancelVisible={false}
                        readMoreVisible={false}
                        page='editalert'
                    >
                        <div dangerouslySetInnerHTML={{ __html: this.state.hasProjectsTextTrimmed }}></div>
                    </AlertDialog>
                }

                <DeleteDialog
                    open={this.state.inActiveOrgId !== null}
                    content={this.props.contacts.data.length === 0 ? "Are you sure you want to in activate the organization?" : "This org is associated with at least one project.Setting this org to not active will deactivate all contacts in this org ?"}
                    handleDeleteAction={() => {
                        this._saveForm();
                        this.setState({ inActiveOrgId: null })
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ inActiveOrgId: null })
                    }}
                />
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
OrganizationsEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    orgId: PropTypes.string,
    isReadOly: PropTypes.bool.isRequired,
    pageAccess: PropTypes.array.isRequired
};
/**
 * Default Props 
 */
OrganizationsEdit.defaultProps = {
    pageAccess: [],
    isReadOly: true
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        orgs: state.organizations,
        contacts: state.organizations.contacts,
        states: state.configuration.configurations[configurationConstants.state] || [],
        cities: state.configuration.configurations[configurationConstants.cities] || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getById: (id) => dispatch(orgActions.getById(id)),
        updateOrg: (formData, orgId) => dispatch(orgActions.updateOrg(formData, orgId)),
        getContacts: (skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, orgId = null) => dispatch(orgActions.getContacts(skip, limit, orderBy, order, sfields, sValues, isAutoComplete, orgId)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type))

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(OrganizationsEdit));