
import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helper Constants
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextBox from '../../../components/TextBox/TextBox';
import EditableTable from '../../../components/EditableTable/EditableTable';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import { alertActions } from '../../../actions';

/** Default datas */
const SubsidyData = {
    name: '',
    shortName: '',
    desc: '',
    subsidyTypetype_id: '',
    // isActive: true
};
/**
 * EditableTextValidation component
 * @class EditableTextValidation
 * @extends { Component}
 * @memberof SubsidyTypes
 */
class EditableTextValidation extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-value-' + rowIndex}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * SubsidyTypes component
 * @class SubsidyTypes
 * @extends { Component}
 * @memberof SubsidyTypes
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-desc-' + rowIndex}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

/**
 * EditableAutocomplete component
 * @class EditableAutocomplete
 * @extends { Component}
 * @memberof SubsidyTypes
 */
class EditableAutocomplete extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config } = this.props;
        return (
                <AutoComplete
                    name={`${fieldName}_${rowIndex}`}
                    placeholder=""
                    suggestions={config}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption.value || null);
                    }}
                    selectedValue={{ id: data[fieldName] }}
                    validators={['required']}
                    errorMessages={['this field is required']}
                />
        )
    }
};
/**
 * StatusComponent component
 * @class StatusComponent
 * @extends { Component}
 * @memberof SubsidyTypes
 */
class StatusComponent extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (<Switch
            color="primary"
            checked={data[fieldName]}
            onChange={event => {
                onChange(fieldName, rowIndex, event.target.checked);
            }}
        />);
    }
}
/** Default props */
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    onChange: () => { },
    data: {}
}

const componentMap = {
    name: EditableTextValidation,
    shortName: EditableTextValidation,
    desc: EditableText,
    isActive: StatusComponent,
    subsidyTypetype_id: EditableAutocomplete
}
/**
 * SubsidyTypes Container
 * @class SubsidyTypes
 * @extends { Component}
 * @memberof SubsidyTypes
 */
class SubsidyTypes extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            subsidytypes: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.subsidyType);
        this.props.getConfiguration(configurationConstants.subsidyTypeType);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { subsidyType } = nextProps;

        this.setState({
            saved: true,
            subsidytypes: subsidyType
        })

    }
    /**
     * Handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate subsidy type names
        let subsidytypes = this.state.subsidytypes;
        let duplicateName1=''
        let duplicateName2=''
        let duplicateSubsidyType1=''
        let duplicateSubsidyType2=''

        let subsidytypesNames = subsidytypes.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicatesubsidytypesNames = subsidytypesNames.some(function(item, idx){ 
                    if(subsidytypesNames.indexOf(item) !== idx){
                        duplicateName1=subsidytypes[subsidytypesNames.indexOf(item)].name;
                        duplicateSubsidyType1=subsidytypes[subsidytypesNames.indexOf(item)].subsidyTypetype_id;
                        duplicateName2=subsidytypes[idx].name;
                        duplicateSubsidyType2=subsidytypes[idx].subsidyTypetype_id;
                        return item
                    }
            });
        if((duplicateName1.trim()===duplicateName2.trim())&&(duplicateSubsidyType1===duplicateSubsidyType2)){
            this.props.erroralert(`${duplicateName1.toUpperCase()} with same subsidy type already exists`);
        }else{
        this.props.updatedata(configurationConstants.subsidyType, this.state.subsidytypes);
        }
    }
    /**
     * cellComponentOnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { subsidytypes } = this.state;
        switch (fieldName) {
            case "isActive":
                subsidytypes[rowIndex][fieldName] = value;
                this.setState({ subsidytypes });
                this.props.updatedata(configurationConstants.subsidyType, this.state.subsidytypes)
                break;
            default:
                subsidytypes[rowIndex][fieldName] = value;
                this.setState({ subsidytypes });
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { subsidytypes } = this.state;
        // [1]
        const { height, isLoading, subsidyTypeType } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="subsidytypes"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >

                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'SUBSIDY TYPES'}
                        headerCols={['NAME', 'SHORT NAME', 'DESCRIPTION', 'CATEGORY NAME', 'STATUS']}
                        requiredFields={['NAME', 'SHORT NAME', 'CATEGORY NAME']}
                        fieldCols={['name', 'shortName', 'desc', 'subsidyTypetype_id', 'isActive']}
                        rowDatas={subsidytypes}
                        style={style}
                        staticHeader={true}
                        height={height}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        config={[...subsidyTypeType]}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (subsidytypes.filter(i => !i._id).length <= 0) {
                                this.setState({

                                    subsidytypes: [
                                        {
                                            ...SubsidyData,
                                            key: Math.max(...subsidytypes.map(elt => elt.key)) + 1,
                                            type: configurationConstants.subsidyType

                                        },
                                        ...subsidytypes
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            if (item && item._id !== undefined)
                                this.props.deleteConfig(item.type, item._id);
                            else {
                                let changedArray = subsidytypes.filter(i => i._id);
                                this.setState({ subsidytypes: changedArray });
                            }
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.subsidyType) }}
                />
            </ValidatorForm>
        );
    }
}
/** Props types */
SubsidyTypes.propTypes = {
    classes: PropTypes.object.isRequired
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        subsidyType: configuration.configurations[configurationConstants.subsidyType] || [],
        subsidyTypeType: configuration.configurations[configurationConstants.subsidyTypeType] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByName:(subsidyType) => dispatch(confActions.getByName(subsidyType)),
        getConfiguration: (subsidyTypeType) => dispatch(confActions.getConfiguration(subsidyTypeType)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) => dispatch(alertActions.error(msg))
    }
};
/** Export SubsidyTypes */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(SubsidyTypes));
