import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

//Import Components
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import MergeDragandDrop from "../../components/MergeDragandDrop/MergeDragandDrop";

//Import Helpers
import ValidatorForm from "../../helpers/fieldValidations";

// Import Styles
import textBoxStyle from '../../assets/jss/components/textBox';

/**
 *Merge two projects
 *
 * @class OrganizationMerge
 * @extends {Component}
 */
class OrganizationMerge extends Component {
    /**
     *Creates an instance of OrganizationMerge.
     * @param {*} props
     * @memberof Organization
     */
    constructor(props) {
        super(props);
        //state declaration
        this.state = {

        };
    }
    /**
     * Bind
     * HTML to DOM
     */
    render() {
        let { classes, needPadding, selected, droppableId, isDisableFirst, disableProject, DragsearchHandler, itemsOrganization } = this.props;
        let items = itemsOrganization && itemsOrganization.length > 0 ? itemsOrganization : []
        return (
            <ContentWrapper
                classes={classes}
                ischildPage={true}
                isSubPage={true}
                needPadding={needPadding}
                needTitleBar={false}
                isMergePage={true}
                parentCardBoxShadowClass='boxShadowWidthPadd-24'
            >
                <ValidatorForm
                    name="userForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmitMerge}
                    onError={errors => { }} >
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <MergeDragandDrop
                                isDisableFirst={isDisableFirst}
                                disableProject={disableProject}
                                droppableId={droppableId}
                                // currentTab={currentTab}
                                selected={selected}
                                placement={droppableId === 'droppable' ? 'left' : 'right'}
                                items={items}
                                onChange={(selected) => {
                                    this.setState({
                                        selected
                                    });
                                }}
                                noDataErrorMessage={"No Records Found"}
                                toolTipHtmlContent={(item) => {
                                    return this.props.toolTipHtmlContent(item)
                                }}
                                DragsearchHandler={DragsearchHandler}
                            />
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </ContentWrapper >

        )
    }
}

// set default props
OrganizationMerge.defaultProps = {
    suggestions: [],
    isSubPage: false,
    needPadding: true,
    value: null,
    name: '',
    suggestions_sites: [],
    itemsProjects: [],
    onChange: () => { },
    itemsOrganization: [],
    selected: [],
    droppableId: '',
    currentTab: null,
    projectId: null,
    pageAccess: [],
    handleMerge: () => { },
    toolTipHtmlContent: () => { },
    isDisableFirst: false,
    disableProject: null,
    DragsearchHandler: () => { },
    searchMergeHandler: () => { }
}

//prop types
OrganizationMerge.propTypes = {
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.array,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool,
    suggestions_sites: PropTypes.array,
    name: PropTypes.string,
    suggestions_buildings: PropTypes.array,
    onChange: PropTypes.func,
    droppableId: PropTypes.string,
    handleMerge: PropTypes.func,
    searchMergeHandler: PropTypes.func
};

//export component
export default withStyles(textBoxStyle)(OrganizationMerge);