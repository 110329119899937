import { authHeader, globalVariable, handleResponse } from '../helpers';

export const orgService = {
    getAll,
    getOrganizationsBySearchKey,
    getById,
    update,
    addNewOrg,
    delete: _delete,
    setActivateStatus,
    getReadOnlyOrg,
    getAllOrg
    
};

/**
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false) {

    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/organizations/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}
 // get organization
 function getAllOrg(){
    const requestOptions={
         method : 'post',
         headers: {...authHeader(), 'Content-Type': 'application/json'}
     }
     return fetch(`${globalVariable.apiURL}/organizations/orglist`, requestOptions).then(handleResponse);
 }

/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
function getOrganizationsBySearchKey(text, page = 'projects') {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ page })
    };

    return fetch(`${globalVariable.apiURL}/organizations/search/${text}`, requestOptions).then(handleResponse);
}

/**
 * Get Read Only orgs
 */
function getReadOnlyOrg() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/organizations/readonlyorg`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/organizations/${id}`, requestOptions).then(handleResponse);
}

/**
 * Update Organization data
 * 
 * @param {*} org 
 * @param {*} orgId 
 */
function update(org, orgId) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(org)
    };

    return fetch(`${globalVariable.apiURL}/organizations/${orgId}`, requestOptions).then(handleResponse);
}

/**
 * Add new organization
 * 
 * @param {*} org 
 */
function addNewOrg(org) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(org)
    };

    return fetch(`${globalVariable.apiURL}/organizations`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/organizations/${id}`, requestOptions).then(handleResponse);
}

/**
 * A method to activate / deactivate the org
 *
 * @param {*} id contains org id
 * @param {*} status contains boolean value of org to update. e-g true - active, false - deactive
 * @param {*} callBack contains a method to call back of successfull response
 * @returns
 */
function setActivateStatus(id, status) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            ...authHeader(),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({status})
    };

    return fetch(`${globalVariable.apiURL}/organizations/active-status/${id}`, requestOptions).then(handleResponse);
}