import { UAConstants } from '../constants/UA';
import _ from "underscore";

const initialState = {
    getOne: {
        utiltiyAllowance: [],
        _id: null,
        access: []
    },
    configDatas: {
        bedrooms: [],
        utilityAllowanceSource: [],
        utilityAllowanceType: []
    },
    isLoading: false,
}

export function utilityAllowance(state = initialState, action) {
    switch (action.type) {
        case UAConstants.GET_UA_CONFIG_DATAS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case UAConstants.GET_UA_CONFIG_DATAS_SUCCESS:
            let uSource = _.sortBy(action.data.utilityAllowanceSource, "name");
            let uBedRooms = _.sortBy(action.data.bedrooms, "desc");
            let uUtype = _.sortBy(action.data.utilityAllowanceType, "name");
            return {
                ...state,
                configDatas: {
                    bedrooms: uBedRooms.map((bedroom, index) => {
                        bedroom.index = index;
                        bedroom.value = bedroom._id;
                        bedroom.label = bedroom.desc;
                        return bedroom;
                    }),
                    utilityAllowanceSource: uSource.map((source, index) => {
                        source.index = index;
                        source.value = source._id;
                        source.label = source.name;
                        return source;
                    }),
                    utilityAllowanceType: uUtype.map((type, index) => {
                        type.index = index;
                        type.value = type._id;
                        type.label = type.name;
                        return type;
                    })
                },
                isLoading: false,
            }
        case UAConstants.GET_UA_CONFIG_DATAS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }

        case UAConstants.GET_UA_DETAILS_REQUEST:
            return {
                ...state,
                getOne: {
                    utiltiyAllowance: [],
                    _id: null
                },
                isLoading: true,
            }
        case UAConstants.GET_UA_DETAILS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...action.data,
                    utilityAllowance: action.data.utilityAllowance.map((ua, index) => {
                        ua.index = index;
                        return ua;
                    })
                },
                isLoading: false,
            }
        case UAConstants.GET_UA_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }

        case UAConstants.UPDATE_UA_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case UAConstants.UPDATE_UA_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case UAConstants.UPDATE_UA_DETAILS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }

        case UAConstants.UA_BULK_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case UAConstants.UA_BULK_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case UAConstants.UA_BULK_UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            }

        default: return state;
    }
}