import { loanProjectsConstants } from '../constants';

const initialState = {
    isLoanProjectLoading: false,
    data: [],
    reportActivity: {
        isLoading: false,
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: ['name'],
                searchCols: []
            }
        }
    },
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['name'],
            searchCols: []
        }
    },
    getOne: {
        data: null,
        sites: {
            isLoading: false,
            data: [],
            page: {
                fieldFilters: {
                    headerCols: [],
                    fieldCols: ['name'],
                    searchCols: []
                }
            }
        },
        reports: {
            isLoading: false,
            data: [],
            page: {
                fieldFilters: {
                    headerCols: [],
                    fieldCols: ['site.name'],
                    searchCols: []
                }
            }
        },
        funders: {
            isLoading: false,
            data: [],
            page: {
                fieldFilters: {
                    headerCols: [],
                    fieldCols: ['name'],
                    searchCols: []
                }
            }
        },
        table2: {
            isLoading: false,
            data: []
        },
        users: [],
        orgs: [],
        readOnlyOrg: []
    },
    funderSettings: {
        basic: null,
        config: {
            projectType: [],
            usersList: []
        }
    },
}

export function loanProjects(state = { ...initialState }, action) {
    switch (action.type) {
        case loanProjectsConstants.GET_ONE_LPJT_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: null
                },
                isLoading: true
            }
        case loanProjectsConstants.GET_ONE_LPJT_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: action.project,
                },
                isLoading: false
            }
        case loanProjectsConstants.GET_ONE_LPJT_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        case loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_REQUEST:
            return {
                ...state,
                isLoanProjectLoading: true
            }
        case loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_SUCCESS:
            return {
                ...state,
                ...action.projects,
                isLoanProjectLoading: false
            }
        case loanProjectsConstants.PAGE_GETALL_LOAN_PROJECTS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoanProjectLoading: false
            }
        case loanProjectsConstants.GETALL_REPORT_ACTIVITY_REQUEST:
            return {
                ...state,
                isLoanProjectLoading: true
            }
        case loanProjectsConstants.DELETE_PROJECT_SUCCESS:
            return {
                ...state,
                data: state.data.filter((pjt) => {
                    return pjt._id !== action.id
                })
            }
        case loanProjectsConstants.DELETE_LOAN_PROJECT_SUCCESS:
            return {
                ...state,
                data: state.data.filter((pjt) => {
                    return pjt._id !== action.id
                })
            }

        case loanProjectsConstants.GETALL_REPORT_ACTIVITY_SUCCESS:
            return {
                ...state,
                reportActivity: action.reportsList,
                isLoanProjectLoading: false
            }
        case loanProjectsConstants.GETALL_REPORT_ACTIVITY_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoanProjectLoading: false
            }

        case loanProjectsConstants.IS_LOAN_PROJECT_NAME_EXISTS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case loanProjectsConstants.IS_LOAN_PROJECT_NAME_EXISTS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    isProjectNameExists: action.exists,
                },
                isLoading: false
            }
        case loanProjectsConstants.IS_LOAN_PROJECT_NAME_EXISTS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }

        //get all orhganization
        case loanProjectsConstants.GET_ALL_ORGS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case loanProjectsConstants.GET_ALL_ORGS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    orgs: action.orgs,
                    users: []
                },
                isLoading: false
            }
        case loanProjectsConstants.GET_ALL_ORGS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }

        case loanProjectsConstants.GET_ORG_USERS_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case loanProjectsConstants.GET_ORG_USERS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    users: action.users
                },
                isLoading: false
            }
        case loanProjectsConstants.GET_ORG_USERS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
            case loanProjectsConstants.GET_ALL_LOAN_FUNDERS_REQUEST:
                return {
                    ...state,
                    getOne: {
                        ...state.getOne,
                        funders: {
                            ...state.getOne.funders,
                            isLoading: true
                        }
                    }
    
                };
            case loanProjectsConstants.GET_ALL_LOAN_FUNDERS_SUCCESS:
                return {
                    ...state,
                    getOne: {
                        ...state.getOne,
                        funders: {
                            ...state.getOne.funders,
                            ...action.funders,
                            isLoading: false,
                        },
                    }
                };
            case loanProjectsConstants.GET_ALL_LOAN_FUNDERS_FAILURE:
                return {
                    ...state,
                    isLoading: false,
                    error: action.error
                };

        //get funder config settings
        case loanProjectsConstants.GET_LOAN_FUNDER_CONFIG_SETTINGS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case loanProjectsConstants.GET_LOAN_FUNDER_CONFIG_SETTINGS_SUCCESS:
            return {
                ...state,
                funderSettings: {
                    ...state.funderSettings,
                    config: {
                        ...action.settings,
                        projectType: action.settings.projectType.map((type) => {
                            type.value = type._id;
                            type.label = type.name;
                            return type;
                        })
                    }
                },
                isLoading: false,
            };
        case loanProjectsConstants.GET_LOAN_FUNDER_CONFIG_SETTINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        // get funder basic settings
        case loanProjectsConstants.GET_LOAN_FUNDER_BASIC_SETTINGS_REQUEST:
            return {
                ...state,
                funderSettings: {
                    ...state.funderSettings,
                    basic: null
                },
                isLoading: true
            };
        case loanProjectsConstants.GET_LOAN_FUNDER_BASIC_SETTINGS_SUCCESS:
            action.data.claimedSite = 0;
            action.data.setAsideBy = 0;
            if (action.data.reports !== undefined) {
                // if no due date found
                action.data.funderDueDates = action.data.funderDueDates || [];
                action.data.reports = action.data.reports.map((report) => {
                    const { headers } = report;
                    // changed to local
                    const yrs = [];
                    for (let i = 1; i < headers.length; i++) {
                        yrs.push({
                            year: headers[i]
                        });
                    }
                    const data = ['programActivity', 'financialActivity', 'contractActivity'].map((table, tableIndex) => {
                        const tableData = yrs.map((yrData) => {
                            // get due date from config type DueDates (or) from FunderProjectSitesReportTable
                            let fd = action.data.funderDueDates.filter(fd => fd._id._id === report._id && fd._id.year === yrData.year)[0];
                            const defaultDueDate = fd ? fd.data : {};
                            const enabledStatus = fd ? fd.enabledStatus : {};
                            const date = defaultDueDate[table] === false ? false : defaultDueDate[table];
                            let status = enabledStatus[table] === false ? false : true;
                            // disable / enable tables logic updated
                            let ryDatas = fd.ryDatas || { tableDatas: null };
                            let inFunderLevel = false;

                            if (ryDatas.tableDatas && ryDatas.tableDatas["submissionStatus"]) {
                                let submissionStatus = ryDatas.tableDatas["submissionStatus"];
                                if (submissionStatus.submitFlag && submissionStatus.levelTo_id === 3) {
                                    inFunderLevel = true;
                                }
                            }

                            // check for table 1 and site type
                            if (tableIndex === 0 && fd.siteType !== 'Default') {
                                inFunderLevel = true;
                                status = false;
                            }

                            return {
                                site_id: report._id,
                                fps_id: fd._id.fps_id,
                                date: date,
                                year: yrData.year,
                                status: status,
                                tableIndex: tableIndex + 1,
                                org_id: fd._id.org_id,
                                title: table,
                                // disabled: (yrData.year < report.currentReportingYear - 1) && inFunderLevel,
                                disabled: inFunderLevel,
                                dbStatus: status, // it will be used to get the updated data i.e user changed data,
                                fpsrt_id: fd._id.fpsrt_id // for update purpose else it will insert
                            };
                        });
                        return tableData;
                    });
                    report.cnvData = data;
                    return report;
                });

                const typeBy = action.data.setAsideTypes.filter(st => st.contract_id && st.contract_id !== null && st.contract_id !== 0);
                const isConfigByBedRoom = action.data.setAsideTypes.filter(st => st.bedroom_id !== 0 && st.bedroom_id !== null && st.category_id === 5);
                const isConfigByBedRoomNHTF = action.data.setAsideTypes.filter(st => st.bedroom_id !== 0 && st.bedroom_id !== null && st.category_id === 6);
                const isConfigByBedRoomPSH = action.data.setAsideTypes.filter(st => st.bedroom_id !== 0 && st.bedroom_id !== null && st.category_id === 7);
                const isElderly = action.data.setAsideTypes.filter(st => st.setAsideType_id === 57 && st.category_id === 2);
                action.data.setAsideBy = typeBy.length > 0 ? 1 : 0;
                action.data.isConfigByBedRoom = isConfigByBedRoom.length >= 1;
                action.data.isElderly = isElderly.length >= 1;
                action.data.elderlyType = isElderly.length >= 1 ? isElderly[0].elderlyType_id : 0;
                action.data.claimedSite = action.data.reports[0] ? action.data.reports[0]._id : 0;
                action.data.isConfigByBedRoomNHTF = isConfigByBedRoomNHTF.length >= 1;
                action.data.isConfigByBedRoomPSH = isConfigByBedRoomPSH.length >= 1;
                action.data.isAfterPHA = action.data.setAsideTypes.filter(st => st.category_id === 5 && st.isAfterPHA === true).length > 0;
                // action.data.modifiedBy = action.data.modifiedBy;
                // action.data.updatedAt = action.data.updatedAt;
            }

            // users list
            if (action.data.usersList !== undefined) {
                action.data.usersList = action.data.usersList.map((user) => {
                    user.label = `${user.firstName} ${user.lastName}`;
                    user.label = user.isActive ? user.label : `*${user.label}`;
                    user.value = user._id;
                    return user;
                });
            }

            return {
                ...state,
                funderSettings: {
                    ...state.funderSettings,
                    basic: action.data
                },
                isLoading: false,
            };
        case loanProjectsConstants.GET_LOAN_FUNDER_BASIC_SETTINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case loanProjectsConstants.CREATE_LOAN_FUNDER_SETTINGS_REQUEST:
        case loanProjectsConstants.UPDATE_LOAN_FUNDER_SETTINGS_REQUEST:
            return {
                ...state,
                isLoanProjectLoading: true
            }
        case loanProjectsConstants.CREATE_LOAN_FUNDER_SETTINGS_SUCCESS:
        case loanProjectsConstants.UPDATE_LOAN_FUNDER_SETTINGS_SUCCESS:
        case loanProjectsConstants.CREATE_LOAN_FUNDER_SETTINGS_FAILURE:
        case loanProjectsConstants.UPDATE_LOAN_FUNDER_SETTINGS_FAILURE:
            return {
                ...state,
                isLoanProjectLoading: false
            }
        default: return state;
    }
}