export const sitesConstants = {

    // sites listing page
    GETALL_REQUEST: 'SITES_GETALL_REQUEST',
    GETALL_SUCCESS: 'SITES_GETALL_SUCCESS',
    GETALL_FAILURE: 'SITES_GETALL_FAILURE',

    // sites details page
    GETONE_REQUEST: 'SITES_GETONE_REQUEST',
    GETONE_SUCCESS: 'SITES_GETONE_SUCCESS',
    GETONE_FAILURE: 'SITES_GETONE_FAILURE',

    // set or reset filter
    CLEAR_FILTER_SET: 'SITES_SET_FILTER',
    CLEAR_FILTER_RESET: 'SITES_RESET_FILTER',

    // create new site
    ADD_SITE_REQUEST: 'SITES_ADD_REQUEST',
    ADD_SITE_SUCCESS: ' SITES_ADD_SUCCESS',
    ADD_SITE_FAILURE: ' SITES_ADD_FAILURE',

    // organization user in site
    GET_ORG_USERS_REQUEST: 'SITES_GET_ORG_USERS_REQUEST',
    GET_ORG_USERS_SUCCESS: 'SITES_GET_ORG_USERS_SUCCESS',
    GET_ORG_USERS_FAILURE: 'SITES_GET_ORG_USERS_FAILURE',

    // organization user role in site
    GET_ORG_USERS_ROLE_REQUEST: 'SITES_GET_ORG_USERS_ROLE_REQUEST',
    GET_ORG_USERS_ROLE_SUCCESS: 'SITES_GET_ORG_USERS_ROLE_SUCCESS',
    GET_ORG_USERS_ROLE_FAILURE: 'SITES_GET_ORG_USERS_ROLE_FAILURE',

    // organization dropdown in sites
    GET_ALL_ORGS_REQUEST: 'SITES_GET_ALL_ORGS_REQUEST',
    GET_ALL_ORGS_SUCCESS: 'SITES_GET_ALL_ORGS_SUCCESS',
    GET_ALL_ORGS_FAILURE: 'SITES_GET_ALL_ORGS_FAILURE',

    // organization list in sites
    GETALL_ORGANIZATION_REQUEST: 'SITES_ORGANIZATION_REQUEST',
    GETALL_ORGANIZATION_SUCCESS: 'SITES_ORGANIZATION_SUCCESS',
    GETALL_ORGANIZATION_FAILURE: 'SITES_ORGANIZATION_FAILURE',

    // delete sites
    DELETE_REQUEST: 'SITES_DELETE_REQUEST',
    DELETE_SUCCESS: 'SITES_DELETE_SUCCESS',
    DELETE_FAILURE: 'SITES_DELETE_FAILURE',

    // project list in sites
    GETALL_PROJECT_LISTS_REQUEST: "SITES_GETALL_PROJECT_LISTS_REQUEST",
    GETALL_PROJECT_LISTS_SUCCESS: "SITES_GETALL_PROJECT_LISTS_SUCCESS",
    GETALL_PROJECT_LISTS_FAILURE: "SITES_GETALL_PROJECT_LISTS_FAILURE",

    // update sites
    UPDATE_SITE_REQUEST: 'SITES_UPDATE_REQUEST',
    UPDATE_SITE_SUCCESS: ' SITES_UPDATE_SUCCESS',
    UPDATE_SITE_FAILURE: ' SITES_UPDATE_FAILURE',

    // onsite organization in site
    GETALL_ORGANIZATION_ONSITE_REQUEST: 'SITES_ORGANIZATION_ONSITE_REQUEST',
    GETALL_ORGANIZATION_ONSITE_SUCCESS: 'SITES_ORGANIZATION_ONSITE_SUCCESS',
    GETALL_ORGANIZATION_ONSITE_FAILURE: 'SITES_ORGANIZATION_ONSITE_FAILURE',

    // claim and un claim site
    UPDATE_SITE_CALIM_STATUS_REQUEST: 'UPDATE_SITE_CALIM_STATUS_REQUEST',
    UPDATE_SITE_CALIM_STATUS_SUCCESS: 'UPDATE_SITE_CALIM_STATUS_SUCCESS',
    UPDATE_SITE_CALIM_STATUS_FAILURE: 'UPDATE_SITE_CALIM_STATUS_FAILURE',

    // Get sites name list
    GET_SITENAME_REQUEST: 'GET_SITENAME_REQUEST',
    GET_SITENAME_SUCCESS: 'GET_SITENAME_SUCCESS',
    GET_SITENAME_FAILURE: 'GET_SITENAME_FAILURE',

    // Get site table1 list
    GET_SITE_TABLE1_REQUEST: 'GET_SITE_TABLE1_REQUEST',
    GET_SITE_TABLE1_SUCCESS: 'GET_SITE_TABLE1_SUCCESS',
    GET_SITE_TABLE1_FAILURE: 'GET_SITE_TABLE1_FAILURE',
};