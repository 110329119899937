import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Import Helpers
import { getFloat,convertDateWithTimeZone } from '../../../../helpers/global';
import { getReplacementReservebalance } from '../../../../helpers/Table4Calc';

// Import Components
import TextBox from '../../../../components/TextBox/TextBox';
import NumberField from '../../../../components/NumberField/NumberField';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import PageActions from '../../../../components/PageActions/PageActions';
import TextArea from '../../../../components/TextArea/TextArea';

const fieldClass = {
    withdrawAmt: 'txt-right',
    depositAmt: 'txt-right',
    balance: 'txt-right',
};
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let className = isReadOly || data.editable === false ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextBox
                className={className}
                name={`replacement_reserve_${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={isReadOly ? [] : ['required']}
                errorMessages={isReadOly ? [] : ['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOly || data.editable === false}
                title={data[fieldName]}
            />
        )
    }
};
/**
 * EditableTextAmt Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let disStatus = isReadOly || data.editable === false;
        if (rowIndex === 0) {
            disStatus = true;
        }
        const rowStatus = rowIndex > 1;
        const validators = ((data.withdrawAmt === ''|| data.withdrawAmt === null) && (data.depositAmt === '' ||data.depositAmt === null) && !disStatus) ? ['required'] : [];
        const errorMessages = ((data.withdrawAmt === ''|| data.withdrawAmt === null) && (data.depositAmt === '' ||data.depositAmt === null) && !disStatus) ? ['this field is required'] : [];
        let className = disStatus ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`

        return (
            <NumberField
                className={className}
                name={`replacement_reserve_${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={validators}
                errorMessages={errorMessages}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={disStatus}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * EditableTextAmtWithdraw Component
 * @class EditableTextAmtWithdraw
 * @extends {Component}
 */
class EditableTextAmtWithdraw extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;

        let disStatus = isReadOly || data.editable === false;

        if (rowIndex === 0 || rowIndex === 1) {
            disStatus = true;
        }

        const validators = ((data.withdrawAmt === ''|| data.withdrawAmt === null) && (data.depositAmt === '' ||data.depositAmt === null) && !disStatus) ? ['required'] : [];
        const errorMessages = ((data.withdrawAmt === ''|| data.withdrawAmt === null) && (data.depositAmt === '' ||data.depositAmt === null) && !disStatus) ? ['this field is required'] : [];
        let className = disStatus ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`

        return (
            <NumberField
                className={className}
                name={`replacement_reserve_${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={validators}
                errorMessages={errorMessages}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={disStatus}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * NumberSpan Component
 * @class NumberSpan
 * @extends {Component}
 */
class NumberSpan extends Component {
    render() {
        const { data, fieldName } = this.props;
        return (
            <div className={fieldClass[fieldName]}>
                <NumberField
                    value={data.balance}
                    prefix={'$'}
                    thousandSeparator={true}
                    displayType={'text'}
                />
            </div>
        )
    }
};
/**
 * TransactionDate Component
 * @class TransactionDate
 * @extends {Component}
 */
class TransactionDate extends React.Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly } } = this.props;
        let disStatus = isReadOly || data.editable === false;
        let validationError = {};
        let helperText = 'mm/dd/yyyy (e.g 01/01/1991)';
        if (rowIndex <= 1) {
            disStatus = true;
        }
        if (data.customError) {
            validationError = {
                error: true
            }
            if (data[fieldName]) {
                helperText = "Please a enter valid Date that is in the Report Year"
            } else helperText = "This field is required"
        }
        let className = disStatus ? `input--disabled textBox--borderedDate ${fieldClass[fieldName]}` : `textBox--borderedDate ${fieldClass[fieldName]}`
        return (
            <DatePicker
                className={className}
                name={`replacement_reserve_${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, date) => {
                    onChange(fieldName, rowIndex, date);
                }}
                validators={isReadOly ? [] : ['required', 'isCurrentReportYear']}
                errorMessages={isReadOly ? [] : ['This field is required', 'Please a enter valid Date that is in the Report Year']}
                margin="dense"
                disabled={disStatus}
                helperText={helperText}
                validations={validationError}
            />
        )
    }
}

const componentMap = {
    date: TransactionDate,
    description: EditableText,
    withdrawAmt: EditableTextAmtWithdraw,
    depositAmt: EditableTextAmt,
    balance: NumberSpan
};
/**
 * ReplacementReserve Component
 * @class ReplacementReserve
 * @extends {Component}
 */
class ReplacementReserve extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    /**
    * Cell Component On Change
    * @param {*} fieldName 
    * @param {*} rowIndex 
    * @param {*} value 
    */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        this.props.onChange('aTransaction', fieldName, rowIndex, value);
    }
    /**
    * Display Amount
    * @param {*} value 
    * @returns 
    */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                value={value}
                prefix={'$'}
                thousandSeparator={true}
                displayType={'text'}
            />
        )
    }
    /**
     * Edit Amount
     * @param {*} fieldName 
     * @param {*} value 
     * @returns 
     */
    editAmount(fieldName, value) {
        const { isDisabled } = this.props;
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                style={{ width: "unset" }}
                name={fieldName}
                value={value}
                prefix={'$'}
                thousandSeparator={true}
                handleChange={(name, value) => {
                    this.props.handleChange(fieldName, value);
                }}
                disabled={isDisabled}
                allowNegative={true}
            />
        )
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { data, config, classes, isDisabled } = this.props;
        const reportYearData = data.reportYearData || {};

        let balance = getReplacementReservebalance(data);
        let replacementReserveBeginningBalance = getFloat(data.replacementReserveBeginningBalance);
        let replacementReserveLastYearEndingBalance = getFloat(data.replacementReserveLastYearEndingBalance);
        const isCommentRequired = replacementReserveBeginningBalance.toFixed(2) !== replacementReserveLastYearEndingBalance.toFixed(2);
        let errMsg = '';
        if (isCommentRequired) {
            errMsg = 'Please enter comments because Last Year Ending Balance and this Year Beginning Balance don\'t match.';
        } else if (balance <= 0) {
            errMsg = 'Please add a comment for why the Ending Balance is zero / Negative.'
        }
        //convert date with timezone
        data.aTransaction.map(item=>{
            item.date = item.date != "" ? convertDateWithTimeZone(item.date):"";
        })
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {`Deposits and Withdrawals During the Period ending December 31, ${reportYearData.year}`}
                    </Grid>
                    <Grid item xs={12} sm={12} className="textRight">
                        {`Last Year's Ending Balance: `}{this.displayAmount(data.replacementReserveLastYearEndingBalance)}
                        <br />
                        <div className="beginning-bal">
                            {`Beginning Balance: `}{this.editAmount('replacementReserveBeginningBalance', data.replacementReserveBeginningBalance)}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <EditableTable
                            headerCols={['Date (MM/DD/YYYY)', 'Explain All Withdrawals& Deposits', 'Withdrawal', 'Deposit', 'Reserve Balance']}
                            fieldCols={['date', 'description', 'withdrawAmt', 'depositAmt', 'balance']}
                            rowDatas={data.aTransaction}
                            needTitle={false}
                            actions={isDisabled ? [] : ['DELETE']}
                            componentMap={componentMap}
                            isReadOnly={isDisabled}
                            section='reserve'
                            cellComponentOnChange={this.cellComponentOnChange}
                            config={{ ...config, classes, isReadOly: isDisabled }}
                            tableClass={'fieldWidth12'}
                            footerDatas={[
                                {
                                    'depositAmt_footer': 'Ending Balance:',
                                    'balance_footer': this.displayAmount(balance)
                                }
                            ]}
                            onDelete={(item) => {
                                this.props.onDelete('aTransaction', item)
                            }}
                        >
                            <Grid item xs={12} sm={12}>
                                <PageActions
                                    isSubPage={true}
                                    actions={isDisabled ? [] : ['ADDLINK']}
                                    handleAddActionClick={() => { this.props.onAdd('aTransaction') }}
                                />
                            </Grid>
                        </EditableTable>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextArea
                            name="transactionReplacementNotes"
                            value={data.transactionReplacementNotes === null ? '' : data.transactionReplacementNotes}
                            label="Replacement Comments"
                            className={classes.textField}
                            margin="dense"
                            handleChange={this.props.handleChange}
                            disabled={isDisabled}
                            validators={errMsg !== '' && !isDisabled ? ['required'] : []}
                            errorMessages={errMsg !== '' && !isDisabled ? [errMsg] : []}
                            multiline={true}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}
/** Props Types */
ReplacementReserve.propTypes = {
    classes: PropTypes.object.isRequired
}
/** Default props */
ReplacementReserve.defaultProps = {
    data: {},
    config: {},
    classes: {},
    pageAccess: [],
    dateError: false
}
/** Export Component */
export default ReplacementReserve;