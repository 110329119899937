import { userConstants } from '../constants';
import { searchConstants } from '../constants';
import { userService, orgService, projectsService, loanProjectsService } from '../services';
import { alertActions } from './';
import { history, getUserOrgAccess } from '../helpers';
import React  from 'react';

export const userActions = {
    login,
    resetPassword,
    changePassword,
    logout,
    getById,
    getAll,
    getOrganizations,
    getAssociatedProjects,
    getAssociatedLoanProjects,
    resetFilter,
    addNewUser,
    updateUser,
    delete: _delete,
    getPicture,
    uploadPicture,
    getMyPicture,
    acceptTOS,
    hasAssociatedProjects,
    activeStatus,
    retreiveUser
};

/**
 * Check the logged in user as valid
 * 
 * @param {*} username - username
 * @param {*} password - password
 */
function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(getMyPicture(user.id));
                    if (user.maintenance) {
                        history.push({
                            pathname: '/incomerent',
                            state: {
                                page: 'incomerent'
                            }
                        });
                    } else {
                        if (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) {
                            history.push({
                                pathname: '/dashboard',
                                state: {
                                    page: 'dashboard'
                                }
                            });
                        } else {
                            history.push({
                                pathname: '/dashboardho',
                                state: {
                                    page: 'dashboardho'
                                }
                            });
                        }
                    }

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

/**
 * Logout
 */
function logout() {
    return dispatch => {
        userService.logout();
        dispatch(clearSearch());
        dispatch(userLogout());
        dispatch(clearUserSearch());
        history.push('/');
    }

    function clearSearch() { return { type: searchConstants.CLEAR_SEARCH } }
    function userLogout() { return { type: userConstants.LOGOUT } }
    function clearUserSearch() {return {type: searchConstants.CLEAR_USER_SEARCH}}
}
/**
 * A method to accept user's tos service
 *
 * @returns
 */
function acceptTOS() {
    return dispatch => {
        dispatch(request());

        userService.acceptTOS()
            .then(
                data => {
                    dispatch(success());
                    dispatch(alertActions.success('Terms and Conditions accepted'));
                    if (getUserOrgAccess() === 1 || getUserOrgAccess() === 3) {
                        history.push({
                            pathname: '/dashboard',
                            state: {
                                page: 'dashboard'
                            }
                        });
                    } else {
                        history.push({
                            pathname: '/dashboardho',
                            state: {
                                page: 'dashboardho'
                            }
                        });
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: userConstants.ACCEPT_TERMS_REQUEST } }
    function success() { return { type: userConstants.ACCEPT_TERMS_SUCCESS } }
    function failure(error) { return { type: userConstants.ACCEPT_TERMS_FAILURE, error } }
}

/**
 * A service method to create hash for the particular email user
 *
 * @param {*} email E mail string to reset password
 * @returns
 */
function resetPassword(email, callBack = () => { }) {
    return dispatch => {
        dispatch(request(email));

        userService.resetPassword(email)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('Mail has been sent successfully'));
                    callBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(email) { return { type: userConstants.RESET_PASSWORD_REQUEST, email } }
    function success(data) { return { type: userConstants.RESET_PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: userConstants.RESET_PASSWORD_FAILURE, error } }
}

/**
 * A service method to change the password user
 *
 * @param {*} data contains newPassword, confirmPassword and hash
 * @returns
 */
function changePassword(data, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        userService.changePassword(data)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(alertActions.success('Password changed successfully'));
                    callBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(email) { return { type: userConstants.CHANGE_PASSWORD_REQUEST, email } }
    function success(data) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, data } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }
}

/**
 * reset filter
 */
function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: userConstants.CLEAR_FILTER_SET } }
    function reset() { return { type: userConstants.CLEAR_FILTER_RESET } }
}

/**
 * Retreive User
 * @param {*} id - user_id
 */
function retreiveUser (id,callBack=()=>{}){
    return dispatch =>{
        dispatch(request())
        userService.retreiveUser(id).then(
            result=>{
                dispatch(success())
                dispatch(alertActions.success('User retrieved successfully'));
                callBack()
            },
            error=>{
                dispatch(failure(error))
            })
    };

    function request() { return { type: userConstants.RETRIEVE_USER_REQUEST} }
    function success() { return { type: userConstants.RETRIEVE_USER_SUCCESS} }
    function failure(err) { return { type: userConstants.RETRIEVE_USER_FAILURE,err} }
}

/**
 * Get details page of a user
 * 
 * @param {*} id - user_id
 */
function getById(id,page='users') {
    return dispatch => {
        dispatch(request());

        userService.getById(id,page)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETONE_REQUEST } }
    function success(user) { return { type: userConstants.GETONE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.GETONE_FAILURE, error } }
}

/**
 * Get data for the listing page of users
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 */
function getAll(skip, limit, orderBy, order, sFields, sValues,page) {
    return dispatch => {
        dispatch(request());

        userService.getAll(skip, limit, orderBy, order, sFields, sValues,page)
            .then(
                users => {
                    dispatch(success(users));
                    const { page } = users;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

/**
 * Create a new user
 * 
 * @param {*} user - user details
 * @param {*} imageData - user profile image
 */
function addNewUser(user, imageData = null) {
    return dispatch => {
        dispatch(request(user));

        userService.addNewUser(user)
            .then(
                user => {
                    const promise = imageData ? userService.uploadPicture(user.id, imageData) : Promise.resolve(user);
                    promise.then(() => {
                        dispatch(success());
                        dispatch(alertActions.success('User created successfully'));
                        setTimeout(() => {
                            history.goBack();
                        }, 1500);
                    });
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.ADD_USER_REQUEST, user } }
    function success(user) { return { type: userConstants.ADD_USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.ADD_USER_FAILURE, error } }
}

/**
 * update user by using user_id
 * 
 * @param {*} userData - update data
 * @param {*} userId - user_id
 * @param {*} imageData - profile image
 */
function updateUser(userData, userId, imageData) {
    return dispatch => {
        dispatch(request(userData));

        userService.update(userData, userId)
            .then(
                user => {
                    if (imageData) {
                        dispatch(uploadPicture(userId, imageData));
                    }
                    dispatch(success(user));
                    dispatch(alertActions.success('Updated successfully'));
                    setTimeout(() => {
                        history.goBack();
                    }, 1500);

                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.UPDATE_USER_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_USER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_USER_FAILURE, error } }
}

/**
 * Get all org list
 *
 * @param {*} text
 * @param {*} [typeOfView=null] to add / remove funders org from the list
 * @returns
 */
function getOrganizations(text, page) {
    return dispatch => {
        dispatch(request());

        orgService.getOrganizationsBySearchKey(text, page)
            .then(
                org => {
                    let suggestions = []
                    org.data.map(suggestion => (
                        suggestions.push({
                            value: suggestion._id,
                            label: suggestion.name,
                            ...suggestion
                        })
                    ));

                    dispatch(success(suggestions))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_ORGANIZATION_REQUEST } }
    function success(suggestions) { return { type: userConstants.GETALL_ORGANIZATION_SUCCESS, suggestions } }
    function failure(error) { return { type: userConstants.GETALL_ORGANIZATION_FAILURE, error } }
}

/** 
 * Get users Associated Projects
*/
function getAssociatedProjects(skip, limit, orderBy, order, sFields, sValues, isExactMatch, userId) {
    return dispatch => {
        dispatch(request());

        projectsService.getAll(skip, limit, orderBy, order, sFields, sValues, isExactMatch, userId)
            .then(
                projects => dispatch(success(projects)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_ASSTDPROJECTS_REQUEST } }
    function success(projects) { return { type: userConstants.GET_ASSTDPROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: userConstants.GET_ASSTDPROJECTS_FAILURE, error } }
}

/** 
 * Get users Associated Loan Projects
*/
function getAssociatedLoanProjects(skip, limit, orderBy, order, sFields, sValues, isExactMatch, userId) {
    return dispatch => {
        dispatch(request());

        loanProjectsService.getAll(skip, limit, orderBy, order, sFields, sValues, isExactMatch, userId)
            .then(
                projects => dispatch(success(projects)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_ASSTDLOANPROJECTS_REQUEST } }
    function success(projects) { return { type: userConstants.GET_ASSTDLOANPROJECTS_SUCCESS, projects } }
    function failure(error) { return { type: userConstants.GET_ASSTDLOANPROJECTS_FAILURE, error } }
}



/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id - user_id
 */
// function _delete(id, item, successCallback) {
    function _delete(id,userName,successCallback) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                    successCallback();
                    dispatch(alertActions.success(userName));
                    // dispatch(alertActions.success(<p>A <b>User: {item.firstName} {item.lastName}</b> has been deleted successfully.</p>));
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}

/**
 * Upload image
 * 
 * @param {*} id - user_id
 * @param {*} formData 
 */
function uploadPicture(id, formData) {
    return dispatch => {
        dispatch(request());

        userService.uploadPicture(id, formData)
            .then(
                image => dispatch(success(URL.createObjectURL(image))),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.UPDATE_USER_PICTURE_REQUEST } }
    function success(url) { return { type: userConstants.UPDATE_USER_PICTURE_SUCCESS, url } }
    function failure(error) { return { type: userConstants.UPDATE_USER_PICTURE_FAILURE, error } }
}

/**
 * Get picture
 * @param {*} id - user_id
 */
function getPicture(id) {
    return dispatch => {
        dispatch(request());

        userService.getPicture(id)
            .then(
                image => dispatch(success(URL.createObjectURL(image))),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_USER_PICTURE_REQUEST } }
    function success(url) { return { type: userConstants.GET_USER_PICTURE_SUCCESS, url } }
    function failure(error) { return { type: userConstants.GET_USER_PICTURE_FAILURE, error } }
}

/**
 * Get Picture
 * @param {*} id - user_id 
 */
function getMyPicture(id) {
    return dispatch => {
        dispatch(request());

        userService.getPicture(id)
            .then(
                image => dispatch(success(URL.createObjectURL(image))),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GET_MY_PROFILE_PICTURE_REQUEST } }
    function success(url) { return { type: userConstants.GET_MY_PROFILE_PICTURE_SUCCESS, url } }
    function failure(error) { return { type: userConstants.GET_MY_PROFILE_PICTURE_FAILURE, error } }
}


/**
 * If users has assoc projects
 *
 * @param {*} userId
 */
function hasAssociatedProjects(userId, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        userService.hasAssociatedProjects(userId)
            .then(
                data => {
                    dispatch(success(data.data));
                    callBack(data.data);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.HAS_ASSOC_PJT_REQUEST } }
    function success(status) { return { type: userConstants.HAS_ASSOC_PJT_SUCCESS, status } }
    function failure(error) { return { type: userConstants.HAS_ASSOC_PJT_FAILURE, error } }
}

/**
 * User active status
 * @param {*} id - user_id
 * @param {*} isActive 
 */
function activeStatus(id, isActive,userName, callBack = () => { }) {
    return dispatch => {
        dispatch(request(id));

        userService.activeStatus(id, isActive)
            .then(
                user => {
                    dispatch(success(id));
                    dispatch(alertActions.success(`A User: ${userName} has been ${!isActive?'inactivated':'activated'} successfully.`));
                    callBack();
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}