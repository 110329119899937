import { authHeader, globalVariable, handleResponse, handleDownloadResponse } from '../helpers';
import momentTZ from 'moment-timezone';

export const bulkDataService = {
    getBulkDataLogs,
    saveBulkData,
    delete: _delete,
    downloadBulkData
}


function getBulkDataLogs (skip, limit, orderBy, order, sFields, sValues) {
    let data = {
        fields: sFields,
        values: sValues,
        timeZone: momentTZ.tz.guess(true),
    };
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.ms_apiURL}/bulk-data-export/bulkDataLogs?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse)
}

function saveBulkData (reportData) {
    let timeZoneOffSet = new Date().getTimezoneOffset()
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...reportData, timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) })
    };
    return fetch(`${globalVariable.ms_apiURL}/bulk-data-export/saveBulkDataRequest`, requestOptions).then(handleResponse);
}

function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.ms_apiURL}/bulk-data-export/${id}`, requestOptions).then(handleResponse);
}

function downloadBulkData (id) {
    let timeZoneOffSet = new Date().getTimezoneOffset()
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ timeZoneOffSet: timeZoneOffSet, timeZone: momentTZ.tz.guess(true) }),
        responseType: 'arraybuffer'
    };
    return fetch(`${globalVariable.ms_apiURL}/bulk-data-export/download/${id}`, requestOptions).then(handleDownloadResponse);
}