import React, {Component} from "react";
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Components
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import TableComponent from '../../../../components/Table/TableComponent';
import ValidatorForm from '../../../../helpers/fieldValidations';
import Grid from '@material-ui/core/Grid';
import TextBox from '../../../../components/TextBox/TextBox';
import DropDown from '../../../../components/DropDown/DropDown';
import Button from '@material-ui/core/Button';
//import Button from "../../../../components/Button";
import ToolTip from "../../../../components/ToolTip/ToolTip";
import { Hidden, MenuItem } from "@material-ui/core";
import { InfoIcon } from '../../../../components/SvgIcons/SvgIcons';
import {fileFormatConstants} from '../../../../helpers/appConstants';
import pageStyle from "../../../../assets/jss/containers/common";
import {bulkDataActions} from '../../../../actions/bulkData';
import { alertActions } from "../../../../actions/alert";
import { history, globalVariable, filterSearchFields } from '../../../../helpers'
//import TableComponent from '../../../../components/Table/TableComponent';

// Custom Variables
let sFields = [];
let sValues = [];


class ToolTipComponent extends Component {


    render() {
        const { data } = this.props;
        const message = "The export failed due to an unexpected error, The WBARS team will be analyzing and resolving this.";
        return (
        <div style={{display: 'flex', gap: '10px'}}>
        <div>
            {data.status}
        </div>
        {data.status == "Failed" ? 
        <ToolTip title={message} placement="top">
            <span><InfoIcon /></span>
        </ToolTip> :
        <></>
        }
        </div>);
    }
}

ToolTipComponent.defaultProps = {
    data: {}
}

const componentMap = {
    status: ToolTipComponent
}

class BulkExport extends Component{

     /**
     * Constructor
     * @param {*} props 
     */
     constructor(props) {
        super(props);
        this.state = {
            reportName: '',
            reportYear: new Date().getFullYear(),
            fileFormat: 0,
            saveTriggered: false
        }
    }

    componentDidUpdate() {
        const { location } = this.props;
        switch (location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                // history.replace({
                //     pathname: location.pathname,
                //     state: {
                //         ...location.state
                //     }
                // });
                history.replace(location.pathname)
                break;
            default:
                break;
        }
    }

    UNSAFE_componentWillMount() {

        const {bulkData} = this.props;

        this.props.getAll(1, globalVariable.tableRowSize, 'report_name', 'asc', '', '');
    }

    handleChange = (name,value) => {
        this.setState({ [name]: value })
    }

    onSortRequest = (orderBy, order) => {
        const { bulkData } = this.props;
        // [1]
        this.props.getAll(bulkData.page.skip, bulkData.page.limit, orderBy, order, sFields, sValues);
    };

    onChangePageTable = (skip) => {
        const { bulkData } = this.props;
        // [1]
        this.props.getAll(skip, globalVariable.tableRowSize, bulkData.page.orderBy, bulkData.page.order, sFields, sValues);
    };

    onInputChangeRequest = (searchField,searchKey) => {
        const {bulkData} = this.props;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.getAll(1, globalVariable.tableRowSize, bulkData.page.orderBy, bulkData.page.order, sFields, sValues);
    }

    handleClearFilterActionClick = () => {
        sFields = [];
        sValues = [];
        const { bulkData } = this.props;
        // [1]
        this.props.getAll(bulkData.page.skip, bulkData.page.limit, bulkData.page.orderBy, bulkData.page.order, [], []);
    }

    handleTableDeleteAction = (id) => {
        this.props.deleteBulkExportLog(id)
    }

    handleDownloadAction = (item) => {
        const {bulkData} = this.props;
        const {isLoading} = bulkData;
        if (!isLoading) {
            this.props.downloadBulkData(item._id,item.report_name)
        } else {
            this.props.alertAction('Please wait...Download already in progress');
            setTimeout(() => {
                this.props.clearAlert();
            }, 3000)
        }
       
    }
    
    handleSubmit = () => {
        const {reportName, reportYear, fileFormat} = this.state;
        this.props.saveBulkData({reportName,reportYear,fileFormat}, () => {
           // this.props.getAll(1, globalVariable.tableRowSize, 'report_name', 'asc', '', '');
           this.handleClearFilterActionClick();
           this.setState({reportName:'',reportYear: new Date().getFullYear(), fileFormat: 0})
        })
        //this.setState({saveTriggered: true});
        // this.props.getAll(1, globalVariable.tableRowSize, 'report_name', 'asc', '', '');
        // this.setState({reportName: ''})
    }

    render() {
        const { classes, isLoading } = this.props;
        const {location: {pathname}} = this.props;
        const disableButton = this.state.reportName == '' ? true : false;
        const {bulkData} = this.props;
        let reportYear = new Date().getFullYear();
        const reportYears = [];
        while (reportYear >=  2018) {
            reportYears.push(reportYear);
            reportYear = reportYear - 1;
        }

//const reportYears = [reportYear, reportYear - 1,reportYear - 2,reportYear - 3,reportYear - 4,reportYear - 5]
        const fieldFilters = bulkData.page ? bulkData.page.fieldFilters : {};
        console.log("==== PROPS ====",this.props);
        console.log("==== FIELD FILTERS SEARCH =====",fieldFilters)
        return (
            <ContentWrapper
                classes={classes}
                title="DATA EXPORT"
                needPadding={true}
                downloadColor="secondary"
                isConfigurationPage={true}
                style={{overflow: 'hidden !important'}}
                // ischildPage={true}
                //  isSubPage={true}
                handleAddActionClick={() => {}}
             >
            <>
                <div className='ReportDownloadBox_edit'>
                    <ValidatorForm
                        name="ExploreForm"
                        ref="form"
                        className="exploreForm"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <TextBox
                                    name="reportName"
                                    value={this.state.reportName}
                                    label="Report Name"
                                    // className={`${classes.textField} reportNameWidth`}
                                    handleChange={this.handleChange}
                                    onBlur={(name, value) => {}}
                                    margin="dense"
                                    disabled={false}
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DropDown
                                    name='reportYear'
                                    value={this.state.reportYear}
                                    label="Report Year"
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    >
                                       {
                                        reportYears.map((field) => {
                                            return <MenuItem key={field} value={field}> 
                                            {field}
                                            </MenuItem>
                                        })
                                    }
                                </DropDown>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <DropDown
                                name='fileFormat'
                                value={this.state.fileFormat}
                                label="Select File Type"
                                //   className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['This field is required']}
                                >
                                    {
                                        fileFormatConstants.map((field) => {
                                            return <MenuItem key={field.value} value={field.value}> 
                                            {field.label}
                                            </MenuItem>
                                        })
                                    }
                                </DropDown>
                            </Grid>
                            <Grid item xs={12} sm={3} style={{display: 'flex', justifyContent:'center'}}>
                                <Button
                                variant="contained" 
                                color="secondary"
                                onClick={() => this.refs.form.submit()}
                                style={{ marginTop: '5px',width:'90px' }}
                                disabled={disableButton}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
                <ContentWrapper
                classes={classes}
                pageAccess={['CLEAR']}
                title="DATA EXPORT LOGS"
                needPadding={true}
                downloadColor="secondary"
                isConfigurationPage={true}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
             >
                <TableComponent
                  actions = {['REPORT_DOWNLOAD','DELETE']}
                  currentPage={'bulkexport'}
                  actionClassName={'actions-end'}
                  componentMap={componentMap}
                  page={bulkData.page.skip}
                  rowsPerPage={bulkData.page.limit}
                  count={bulkData.page.count}
                  prevNext={false}
                  data={bulkData.logList}
                  order={bulkData.page.order}
                  orderBy={bulkData.page.orderBy}
                  paginationVisible={true}
                  field={fieldFilters.fieldCols}
                  header={fieldFilters.headerCols}
                  search={fieldFilters.searchCols}
                  onChangePageTable={this.onChangePageTable}
                  onSortRequest={this.onSortRequest}
                  handleTableDeleteAction={this.handleTableDeleteAction}
                  handleDownloadAction={this.handleDownloadAction}
                  disablePagination={bulkData.page.lastPage}
                  noDataErrorMessage={"No Records Found"}
                  onInputChangeRequest={this.onInputChangeRequest}
                  sFields={filterSearchFields(sFields)}
                  resetSearchContent={this.props.location.hash === "#clear"}
                  sValues={sValues}
                />
            </ContentWrapper>
            </>
         </ContentWrapper>
        )
    }
}



/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ bulkData }, ownProps) => {
    // // const columnsData = configuration.fieldFilter['exploreData'] || {};
    // pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['explore'] || [];
    // // explore = explore;
    // const helpData = help.data[0]

    return {
        // pageAccess,
        bulkData,
        // helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
       getAll: (skip, limit, orderBy, order, sFields, sValues) => dispatch(bulkDataActions.getBulkDataLogs(skip, limit, orderBy, order, sFields, sValues)),
       deleteBulkExportLog: (id) => dispatch(bulkDataActions.delete(id)),
       downloadBulkData: (id,reportName) => dispatch(bulkDataActions.downloadBulkData(id,reportName)),
       saveBulkData: (reportData,callBack) => dispatch(bulkDataActions.saveBulkData(reportData,callBack)),
       alertAction:(message) => dispatch(alertActions.success(message)),
       clearAlert: () => dispatch(alertActions.clear())
    }
};

/**
 * BulKExport Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(BulkExport));

