import { combineReducers } from 'redux';
import { authentication } from './authentication';
import { alert } from './alert';
import { users } from './user';
import { organizations } from './organizations';
import { configuration } from './configuration'
import { projects } from './projects'
import { archives } from './archives'
import { sites } from './sites'
import { dashboard } from './dashboard'
import { buildings } from './building';
import { utilityAllowance } from './UA'
import { units } from './units';
import { pageAccess } from './pageAccess';
import { occupants } from './occupants';
import { compliance } from './compliance';
import { reportTables } from './reportTables';
import { explore } from './explore';
import { globalSearch } from './globalSearch';
import { help } from './help';
import { bulkUploads } from './uploads';
import { search } from './search';
import { merge } from './merge';
import { print } from './print';
import { contract } from './contract'
import { mailTemplates } from './mailTemplate';
import { notification } from './notification';
import { incomeRent } from './incomeRent';
import { maintenance } from './maintenance';
import { loanProjects } from './loanProjects';
import { reportActivity } from './reportActivity';
import { dashboardHO } from './dashboardHO'
import { table1Log } from './table1Log'
import { bulkData } from './bulkData';
export default combineReducers({
    contract,
    authentication,
    alert,
    users,
    organizations,
    configuration,
    projects,
    archives,
    dashboard,
    dashboardHO,
    units,
    sites,
    buildings,
    utilityAllowance,
    pageAccess,
    occupants,
    compliance,
    explore,
    reportTables,
    globalSearch,
    help,
    bulkUploads,
    search,
    merge,
    print,
    mailTemplates,
    notification,
    incomeRent,
    maintenance,
    loanProjects,
    reportActivity,
    table1Log,
    bulkData
})