import React, { Component } from 'react';
import PropTypes from 'prop-types';

//React DND
import { Droppable, Draggable } from 'react-beautiful-dnd';

//Material UI
import Grid from '@material-ui/core/Grid';
import { Input } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";

//COmponents
import Tooltip from '../ToolTip/ToolTip';

import { SearchIcon } from '../SvgIcons/SvgIcons';
//Helpers
import { getSubChildCardHeight } from '../../helpers/global';
/**
 * Get Item Style
 * @param {*} isDragging 
 * @param {*} draggableStyle 
 * @returns 
 * 
 * [1]  -   some basic styles to make the items look a bit nicer
 * [2]  -   change background colour if dragging
 * [3]  -   styles we need to apply on draggables
 */
const getItemStyle = (isDragging, draggableStyle) => ({
    // [1]
    userSelect: 'none',
    padding: 12,
    textAlign: 'center',
    border: '2px solid #ccc',
    fontSize: 13,
    // [2]
    background: isDragging ? '#eee' : 'white',
    // [3]
    ...draggableStyle
});
/**
 * Left list styles
 * @param {*} isDraggingOver 
 * @param {*} dragDropArea 
 * @returns 
 */
const getListStyle = (isDraggingOver, dragDropArea) => ({
    border: '1x solid #ccc',
    height: dragDropArea,
    padding: 24,
    overflowX: 'auto',
    overflowY: 'auto',
});
/**
 *Merge Drag and Drop
 *
 * @class MergeDragandDrop
 * @extends {Component}
 */
class MergeDragandDrop extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        //state declaration
        this.state = {
            items: props.items,
            selected: props.selected,
            filtered: props.selected,
            searchKeyItems: '',
            searchKeyFiltered: ''
        };
    }
    /**
     *component Will Receive Props
     *
     * @param {*} nexProps
     * @memberof MergeDragandDrop
     */
    UNSAFE_componentWillReceiveProps(nexProps) {
        const { selected, items } = nexProps;
        this.setState({
            items: items,
            selected: selected,
            filtered: selected
        });
    }
    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
        // droppable2: 'filtered'
    };
    /**
     * Get List
     * @param {*} _id 
     * @returns 
     */
    getList = _id => this.state[this.id2List[_id]];
    /**
     * RightSide DragandDrop Search Box
     * @param {*} event 
     */
    DropsearchHandler = (event) => {
        let { selected } = this.state;
        let search = event.target.value.toLowerCase();
        selected = selected.filter((el) => {
            let searchValue = el.name.toLowerCase();
            return searchValue.includes(search);
        });
        this.setState({
            filtered: selected,
            searchKeyFiltered: event.target.value
        })
    }
    /**
     * Leftside DragandDrop Search Box
     * @param {*} event 
     */
    DragsearchHandler = (event) => {
        this.props.DragsearchHandler(event, this.props.droppableId);
    }
    /**
     *render html
     *
     * @returns
     * @memberof MergeDragandDrop
     */
    render() {
        const { droppableId, isDisableFirst, disableProject, currentTab, placement, module } = this.props;
        let dragDropArea = getSubChildCardHeight();
        switch (currentTab) {
            case 0:
                if (this.state.items.length === 0) {
                    dragDropArea = getSubChildCardHeight() - 29;
                } else {
                    dragDropArea = getSubChildCardHeight() - 29;
                }
                break;
            case 1:
                if (this.state.items.length === 0) {
                    dragDropArea = getSubChildCardHeight() - 102;
                } else {
                    dragDropArea = getSubChildCardHeight() - 102;
                }
                break;
            case 2:
                if (this.state.items.length === 0) {
                    dragDropArea = getSubChildCardHeight() - 160;
                } else {
                    dragDropArea = getSubChildCardHeight() - 160;
                }
                break;
            case 3:
                if (this.state.items.length === 0) {
                    dragDropArea = getSubChildCardHeight() - 218;
                } else {
                    dragDropArea = getSubChildCardHeight() - 218;
                }
                break;
            default:
                if (this.state.items.length === 0) {
                    dragDropArea = getSubChildCardHeight() + 28;
                } else {
                    dragDropArea = getSubChildCardHeight() + 28;
                }
                break;
        }
        return (
            <Grid container className={'dndContMain'}>
                {(currentTab === 1 || currentTab === 2 || currentTab === 3) &&
                    <Grid item xs={12} sm={12} className={'searchipMain'}>
                        <Input
                            className={'searchipdnd'}
                            onChange={this.DragsearchHandler}
                            placeholder={'Search Here '}
                            endAdornment={        
                                    <InputAdornment style={{ marginRight: '-6px' }}>
                                        <Tooltip title="Search">
                                            <IconButton onClick={() => { this.handleOnClick() }} style={{ width: '40px', height: '24px', marginRight: 'unset' }}>
                                                <SearchIcon fill='#36aebe' width='16px' height='14px' />
                                            </IconButton>
                                        </Tooltip>
        
                                    </InputAdornment>
                                   
                            }
                        />

                    </Grid>
                }
                <React.Fragment>
                    {this.state.items.length <= 0 &&
                        <div className={'search-msg-merge-tool'}>
                            {this.props.noDataErrorMessage}
                        </div>
                    }
                </React.Fragment>
                <Grid item xs={12} sm={12}>
                    <Droppable droppableId={`${droppableId}`} >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver, dragDropArea)}>
                                {this.state.items.map((item, index) => {
                                    return (
                                        <Draggable
                                            key={`${droppableId}_${item._id}`}
                                            isDragDisabled={isDisableFirst && ((currentTab === 0 || currentTab === 1) ? disableProject === item.project_id : (currentTab === 2) ? disableProject === (item.site_id || item.contract_id): (currentTab === 4) ? item._id : disableProject === item.building_id)}
                                            draggableId={`${item._id}`}
                                            data-some={"some"}
                                            index={index}>
                                            {(provided, snapshot) => {
                                                const ToolTipCustom = this.props.toolTipHtmlContent(item);
                                                return (
                                                    <Tooltip TooltipComponent={ToolTipCustom} placement="right" className={`tooltipdesign ${placement}`}>
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}>
                                                            {item.name}
                                                        </div>
                                                    </Tooltip>
                                                )
                                            }}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Grid>
            </Grid>
        );
    }
}
/**
 * Bind Component Property Types
 */
MergeDragandDrop.defaultProps = {
    items: [],
    selected: [],
    classes: {},
    onChange: () => { },
    noDataErrorMessage: '',
    isSubPage: false,
    needPadding: true,
    droppableId: '',
    toolTipHtmlContent: {},
    isDisableFirst: false,
    disableProject: null,
    currentTab: null,
    placement: '',
    DragsearchHandler: () => { },
};
/*Default Props */
MergeDragandDrop.propTypes = {
    items: PropTypes.array,
    selected: PropTypes.array,
    classes: PropTypes.object.isRequired,
    noDataErrorMessage: PropTypes.string.isRequired,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool,
    droppableId: PropTypes.string,
};
//Export Component
export default MergeDragandDrop;


