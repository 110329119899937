import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Actions
import { projectsActions } from '../../../actions/projects';
import { sitesActions } from "../../../actions/sites";

// Import Components
import TextBox from '../../../components/TextBox/TextBox';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import ToolTip from '../../../components/ToolTip/ToolTip';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TableComponent from '../../../components/Table/TableComponent';
import TextArea from '../../../components/TextArea/TextArea';
import { InfoIcon } from '../../../components/SvgIcons/SvgIcons';

// Helpers
import ValidatorForm from "../../../helpers/fieldValidations";
import { history, ReportCoverConstants } from '../../../helpers'

const contactData = {
    id: null,
    name: '',
    phone: '',
    email: '',
    address: {}
};
/**
 * PerformanceMeasures Component
 * @class PerformanceMeasures
 * @extends {Component}
 */
class ReportsCoverPage extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            projectName: '',
            siteName: '',
            taxParcelId: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            projectUpdate: '',
            ownershipcertification: '',
            homesuitablityforoccupancycertificate: '',
            homecomcertificate: '',
            table1Activity: [],
            table2Activity: [],
            table3Activity: [],
            table4Activity: [],
            table5Activity: [],
            sitepropertymanager: {
                ...contactData
            },
            organization: {},
            onsiteManager: {
                ...contactData
            },
            onsitemanagerco: {},
            isHomeProject: false
        };

        this.onwerName = "";

    }
    /**
     * Component Did Mount
     * 
     * [1]  -   get site data similar as site edit page
     * [2]  -   get orgs list
     */
    componentDidMount() {
        const { reportId: id, location: { state }, match: { path } } = this.props;
        const { pageData: { site_id } } = state || { pageData: { site_id: null } };
        if (id) {
            this.props.getReportSettings(id);
        }
        // [1]
        if (site_id) {
            this.props.getById(site_id, path.includes('archives') ? 'archives' : null);
        }
        // [2]
        this.props.getAllOrg('', 'sites');
        this.props.getAllOrgOnsite('', 'projects');
    }
    /**
     * Componet Will ewcwivw props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, sites: { getOne: siteData }, location: { state } } = nextProps;
        if (getOne.data && this.state.projectName === '') {
            const { table1Activity, table2Activity, table3Activity, table4Activity, table5Activity } = getOne.data;
            this.setState({
                projectName: getOne.data.project.name,
                siteName: getOne.data.site.name,
                taxParcelId: getOne.data.site.taxParcel,
                address: getOne.data.project.address.line,
                city: getOne.data.project.address.city,
                state: getOne.data.project.address.state,
                zipcode: getOne.data.project.address.zipcode,
                projectUpdate: getOne.data.notes,
                // ownership certification
                ownershipcertification: getOne.data.homeWithCOMCertifiedSuitableFlag === true ? ReportCoverConstants.certification[1].value : ReportCoverConstants.certification[0].value,
                // HOME suitablity
                homesuitablityforoccupancycertificate: getOne.data.homeCertifiedSuitableFlag === true ? ReportCoverConstants.certification[1].value : ReportCoverConstants.certification[0].value,
                // HOME HUD
                homecomcertificate: getOne.data.homeCertifiedExemptFlag === true ? ReportCoverConstants.certification[1].value : ReportCoverConstants.certification[0].value,
                // table history
                table1Activity,
                table2Activity,
                table3Activity,
                table4Activity,
                table5Activity,
                isHomeProject: getOne.data.isHomeProject
            });
            this.onwerName = getOne.data.contractOwner ? getOne.data.contractOwner : '';
        }
        // update site datas
        if (siteData.data !== null && this.state.site_id === undefined) {
            let projectManagerOrg = { ...siteData.data.projectManager.org_id };
            let projectManager = { ...siteData.data.projectManager.user_id };
            let onsiteManagerOrg = { ...siteData.data.onsiteManager.org_id };
            let onsiteManager = { ...siteData.data.onsiteManager.user_id };

            if (projectManager._id) {
                projectManager.id = projectManager._id;
                projectManager.value = projectManager._id;
                projectManager.name = `${projectManager.isActive ? '' : '*'}${projectManager.firstName} ${projectManager.lastName}`;
                projectManagerOrg.id = projectManagerOrg._id;
                projectManagerOrg.value = projectManagerOrg._id;
                projectManagerOrg.label = projectManagerOrg.name;
            }

            if (onsiteManager._id) {
                onsiteManager.id = onsiteManager._id;
                onsiteManager.value = onsiteManager._id;
                onsiteManager.name = `${onsiteManager.isActive ? '' : '*'}${onsiteManager.firstName} ${onsiteManager.lastName}`;
                onsiteManagerOrg.id = onsiteManagerOrg._id;
                onsiteManagerOrg.value = onsiteManagerOrg._id;
                onsiteManagerOrg.label = onsiteManagerOrg.name;
            }

            const { pageData: { site_id } } = state || { pageData: { site_id: null } };

            this.setState({
                site_id: site_id,
                sitepropertymanager: projectManager._id ? { ...projectManager } : { ...contactData },
                onsiteManager: onsiteManager._id ? { ...onsiteManager } : { ...contactData },
                organization: { ...projectManagerOrg },
                onsitemanagerco: { ...onsiteManagerOrg },
            });
        }
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
     handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    /**
     * Method when org got changed
     * @param {*} selectedOption 
     */
    handleAutoCompolete = (selectedOption) => {
        let organization = {
            id: selectedOption.value,
            name: selectedOption.label
        };
        this.setState({ organization: organization, sitepropertymanager: { id: null, name: '', value: '', label: '', phone: '', address: {} } });
        if (selectedOption.value) {
            this.props.getAllUsersInOrg(selectedOption.value);
        }
    }
    /**
     * Method when user got changed
     * @param {*} selectedOption 
     */
    handleAutoCompleteUser = (selectedOption) => {
        if (selectedOption.length === 0) {
            this.setState({ sitepropertymanager: { id: null, name: '', value: '', label: '', phone: '', address: {} } });
        }
        else {
            this.setState({ sitepropertymanager: { ...selectedOption } });
        }
    }
    /**
     * Method when org got changed
     * @param {*} selectedOption 
     */
    handleAutoCompleteonsite = (selectedOption) => {
        let onsitemanagerco = {
            id: selectedOption.value,
            name: selectedOption.label
        };
        this.setState({ onsitemanagerco: onsitemanagerco, onsiteManager: { id: null, name: '', value: '', label: '', phone: '', address: {} } });
        if (selectedOption.value) {
            this.props.getAllUsersInOrgwithrole(selectedOption.value);
        }
    }
    /**
     * Method when user got changed
     * @param {*} selectedOption 
     */
    handleAutoCompleteonsitemanager = (selectedOption) => {
        if (selectedOption.length === 0) {
            this.setState({ onsiteManager: { id: null, name: '', value: '', label: '', phone: '', address: {} } });
        }
        else {
            this.setState({ onsiteManager: { ...selectedOption } });
        }
    }
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Save form
     */
    _saveForm() {
        const { location: { state } } = this.props;
        const { pageData: { site_id } } = state || { pageData: { site_id: null } };

        let { siteName, ownershipcertification, homesuitablityforoccupancycertificate, organization,
            sitepropertymanager, onsitemanagerco, onsiteManager, projectUpdate, homecomcertificate } = this.state;

        // set up api form submission datas
        const generalData = {
            siteData: {
                name: siteName,
                _id: site_id,
                projectManager: {
                    org_id: organization.id || 0,
                    user_id: sitepropertymanager.value || 0
                },
                onsiteManager: {
                    org_id: onsitemanagerco.id || 0,
                    user_id: onsiteManager.value || 0
                },
            }, 
            ownershipcertification: ownershipcertification === ReportCoverConstants.certification[1].value,
            homesuitablityforoccupancycertificate: homesuitablityforoccupancycertificate === ReportCoverConstants.certification[1].value,
            notes: projectUpdate,
            homecomcertificate: homecomcertificate === ReportCoverConstants.certification[1].value,
            // notes: projectUpdate
        };
        this.props.updateReportCoverPage(this.props.reportId, generalData);
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, pageAccess, projects, getOne: { tableHistory }, sites } = this.props;
        const { projectName,
            siteName,
            taxParcelId,
            address,
            city,
            state,
            zipcode,
            projectUpdate,
            ownershipcertification,
            homesuitablityforoccupancycertificate,
            sitepropertymanager, organization, onsiteManager, onsitemanagerco,
            isHomeProject,
            homecomcertificate
        } = this.state
        const { orgs, users, onsiteUsers, orgsOnsite } = sites.getOne;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        const ownershipCert = 'I, ' + this.onwerName + ' , hereby certify that the data contained in all WBARS Table reports for the applicable reporting year is accurate. I also certify that I am responsible for the accurate and timely submission of these reports to Funder level, even if I have authorized another staff person to submit these reports on my behalf.'
        const occupancyCert = '24 CFR 92.504(d)(ii)(C): "The property owner must annually certify to the participating jurisdiction that each building and all HOME-assisted units in the project are suitable for occupancy, taking into account State and local health, safety, and other applicable codes, ordinances, and requirements, and the ongoing property standards established by the participating jurisdiction to meet the requirements of §92.251." To be completed by authorized owner/contractor prior to submission to funder level: I, ' + this.onwerName + ' , certify that each building and all HOME-assisted units in the project are suitable for occupancy. hereby certify that the data contained in all WBARS Table reports for the applicable reporting year is accurate.'
        const comCert = ` I, ${this.onwerName} , have been given express consent from my HOME funder to waive this certification requirement because the units funded with HOME program funding are not considered rental units as per HUD guidelines.
        The user can select any one check box`;
        return (
            <ContentWrapper
                classes={classes}
                title={''}
                needTitleBar={false}
                isSubPage={true}
                ischildPage={true}
                needPadding={false}
                titleBg={'white'}
                pageName="reports"
            >
                <div className={classes.rootEdit} >
                    <ValidatorForm
                        name="reportCoverPageForm"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >

                        {/* Grid Content */}
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextBox
                                    name="projectName"
                                    label="Project Name:"
                                    value={projectName}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextBox
                                    name="siteName"
                                    label="Site Name:"
                                    value={siteName}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={isReadOly}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="taxParcelId"
                                    label="Tax Parcel Id:"
                                    value={taxParcelId}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextBox
                                    name="address"
                                    label="Address:"
                                    value={address}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="city"
                                    label="City :"
                                    value={city}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="state"
                                    label="State:"
                                    value={state}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="zipcode"
                                    label="Zipcode:"
                                    value={zipcode}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                            {
                                pageAccess.indexOf('NO_OWNERSHIP_CERT') === -1 && (
                                    <Grid item xs={12} sm={3}>
                                        <div className={'posRelative'}>
                                            <span className={'ddTooltip'}>
                                                <ToolTip title={ownershipCert} placement="top">
                                                    <span><InfoIcon /></span>
                                                </ToolTip>
                                            </span>
                                            <div className={'p-l-20'}>
                                                <AutoComplete
                                                    name="ownershipcertification"
                                                    placeholder='Ownership Certification'
                                                    suggestions={ReportCoverConstants.certification}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.setState({
                                                            ownershipcertification: selectedOption.value
                                                        })
                                                    }}
                                                    selectedValue={{ id: ownershipcertification }}
                                                    disabled={isReadOly || pageAccess.indexOf('DISABLE_CERT') !== -1}
                                                >
                                                    {
                                                        ReportCoverConstants.certification.map((item) => {
                                                            return <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                                                        })
                                                    }
                                                </AutoComplete>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            }
                            {isHomeProject && <Grid item xs={12} sm={4}>
                                <div className={'posRelative'}>
                                    <span className={'ddTooltip'}>
                                        <ToolTip title={occupancyCert} placement="top">
                                        <span><InfoIcon /></span>
                                        </ToolTip>
                                    </span>
                                    <div className={'p-l-20'}>
                                        <AutoComplete
                                            name="homesuitablityforoccupancycertificate"
                                            placeholder='HOME Suitability for Occupancy Certification'
                                            suggestions={ReportCoverConstants.certification}
                                            handleAutoCompolete={(selectedOption) => {
                                                if (selectedOption.value === ReportCoverConstants.certification[1].value) {
                                                    this.setState({
                                                        homesuitablityforoccupancycertificate: selectedOption.value,
                                                        homecomcertificate: 'None'
                                                    })
                                                } else {
                                                    this.setState({
                                                        homesuitablityforoccupancycertificate: selectedOption.value
                                                    })
                                                }
                                            }}
                                            selectedValue={{ id: homesuitablityforoccupancycertificate }}
                                            margin="dense"
                                            disabled={isReadOly || pageAccess.indexOf('DISABLE_CERT') !== -1}
                                            tooltip={true}
                                            tooltipContent={'test'}
                                        />
                                    </div>
                                </div>
                            </Grid>}
                            {isHomeProject && <Grid item xs={12} sm={4}>
                                <div className={'posRelative'}>
                                    <span className={'ddTooltip'}>
                                        <ToolTip title={comCert} placement="top">
                                        <span><InfoIcon /></span>
                                        </ToolTip>
                                    </span>
                                    <div className={'p-l-20'}>
                                        <AutoComplete
                                            name="homecomcertificate"
                                            placeholder='HOME COM Certification'
                                            suggestions={ReportCoverConstants.certification}
                                            handleAutoCompolete={(selectedOption) => {
                                                if (selectedOption.value === ReportCoverConstants.certification[1].value) {
                                                    this.setState({
                                                        homesuitablityforoccupancycertificate: 'None',
                                                        homecomcertificate: selectedOption.value
                                                    })
                                                } else {
                                                    this.setState({
                                                        homecomcertificate: selectedOption.value
                                                    })
                                                }
                                            }}
                                            selectedValue={{ id: homecomcertificate }}
                                            margin="dense"
                                            disabled={isReadOly || pageAccess.indexOf('DISABLE_CERT') !== -1}
                                            tooltip={true}
                                            tooltipContent={'test'}
                                        />
                                    </div>
                                </div>
                            </Grid>}
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextArea
                                    name="projectUpdate"
                                    label="Project Update:"
                                    value={projectUpdate ? projectUpdate : ""}
                                    errorMessages={['this field is required']}
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={isReadOly}
                                    multiline={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginTop: '4px', marginBottom: '4px' }}>
                                <AutoComplete
                                    placeholder="Organization:"
                                    name="organization"
                                    suggestions={orgs}
                                    handleAutoCompolete={this.handleAutoCompolete}
                                    selectedValue={organization}
                                    disabled={true}
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginTop: '4px', marginBottom: '4px' }}>
                                <AutoComplete
                                    suggestions={users}
                                    name="sitepropertymanager"
                                    handleAutoCompolete={this.handleAutoCompleteUser}
                                    selectedValue={sitepropertymanager}
                                    disabled={isReadOly}
                                    placeholder="Site Property Manager"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="sitepropertymanager.phone"
                                    value={sitepropertymanager.phone || ''}
                                    label="Phone Number"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={[]}
                                    errorMessages={[]}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="sitepropertymanager.email"
                                    value={sitepropertymanager.email || ''}
                                    label="Email Id"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={[]}
                                    errorMessages={[]}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="sitepropertymanager.address.postalCode"
                                    value={sitepropertymanager.address.postalCode || ''}
                                    label="Zipcode"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={[]}
                                    errorMessages={[]}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}style={{ marginTop: '4px', marginBottom: '4px' }}>
                                <AutoComplete
                                    name="onsitemanagerco"
                                    suggestions={orgsOnsite}
                                    handleAutoCompolete={this.handleAutoCompleteonsite}
                                    selectedValue={onsitemanagerco}
                                    disabled={true}
                                    placeholder="Organization:"
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3} style={{ marginTop: '4px', marginBottom: '4px' }}>
                                <AutoComplete
                                    name="onsiteManagerUser"
                                    suggestions={onsiteUsers}
                                    handleAutoCompolete={this.handleAutoCompleteonsitemanager}
                                    selectedValue={onsiteManager}
                                    disabled={isReadOly}
                                    placeholder="Onsite Manager"
                                    validators={[]}
                                    errorMessages={[]}
                                />

                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="phone"
                                    value={onsiteManager.phone}
                                    label="Phone"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={[]}
                                    errorMessages={[]}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="email"
                                    value={onsiteManager.email}
                                    label="Email Address"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={[]}
                                    errorMessages={[]}
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextBox
                                    name="onsiteManager.address.postalCode"
                                    value={onsiteManager.address.postalCode || ''}
                                    label="Zipcode"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={[]}
                                    errorMessages={[]}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        {
                            !isReadOly ? (
                                <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />
                            ) : null
                        }
                    </ValidatorForm>
                    <Grid item xs={12} sm={12}>
                        <h3 className="reportRecovSecTitle">{'REPORT HISTORY'}</h3>
                        {
                            [1, 2, 3, 4, 5].map((tableNo, index) => {
                                const seltable = this.state[`table${tableNo}Activity`] || [];
                                return (
                                    <React.Fragment key={index}>
                                        <h4 className="section_title reportRecovSecTitle">{`Table ${tableNo}`}</h4>
                                        <TableComponent
                                            rowsPerPage={seltable.length}
                                            count={seltable.length}
                                            header={tableHistory.headerCols}
                                            field={tableHistory.fieldCols}
                                            search={[]}
                                            isLoading={projects.isLoading}
                                            data={seltable}
                                            currentPage={'projects'}
                                            paginationVisible={false}
                                            searchVisible={false}
                                            noDataErrorMessage={"No Records Found"}
                                            style={{ position: 'relative' }}
                                        />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Grid>
                </div >
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
ReportsCoverPage.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}
/** Default Props */
ReportsCoverPage.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ projects, sites, pageAccess }, ownProps) => {
    return {
        getOne: projects.reportSettings,
        projects,
        sites,
        pageAccess: pageAccess['reportView'] || []
    }
};
/**
 * Map Dispatch to Props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getReportSettings: (id) => dispatch(projectsActions.getReportSettings(id)),
        updateReportCoverPage: (id, data) => dispatch(projectsActions.updateReportCoverPage(id, data)),
        getAllOrg: (text, page) => dispatch(sitesActions.getOrganizations(text, page)),
        getAllUsersInOrg: (orgId) => dispatch(sitesActions.getUsersInOrg(orgId)),
        getAllUsersInOrgwithrole: (orgId) => dispatch(sitesActions.getUsersInOrgwithRole(orgId)),
        getAllOrgOnsite: (text, page) => dispatch(sitesActions.getOrganizationsOnsite(text, page)),
        getById: (id, page) => dispatch(sitesActions.getById(id, page)),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ReportsCoverPage));

