import React from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

// import styles
import alertDialogStyle from '../../assets/jss/components/AlertDialog'

/**
 *Dialog box Component
 *
 * @class AlertDialog
 * @extends {React.Component}
 */
class AlertDialog extends React.Component {
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { open, onSave, onClose, saveText, title, fullScreen, classes, page } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen={fullScreen}
                className={'tosDialog'}
            >
                {page === 'login' ?
                    (
                        <AppBar className={classes.appBar}>
                            <Toolbar>
                                <Typography variant="h5" color="inherit" className={classes.flex} align={'center'}>
                                    {title}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    ) : null
                }
                <DialogContent style={{ overflow: 'auto' }}>
                    <DialogContentText>
                        {this.props.children}
                        {
                            page === 'login' && (
                                <AppBar color='default' className={classes.appBar} style={{ position: 'relative' }}>
                                    <Toolbar style={{ justifyContent: 'center' }}>
                                        <Button color="primary" onClick={onSave}>
                                            {saveText}
                                        </Button>
                                        <Button color="secondary" onClick={onClose}>
                                            {'I Disagree'}
                                        </Button>
                                    </Toolbar>
                                </AppBar>
                            )
                        }
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}
//default props
AlertDialog.defaultProps = {
    open: false,
    onClose: () => { },
    onSave: () => { },
    onCancel: () => { },
    title: "",
    saveText: "Save",
    cancelText: "Cancel",
    buttonSave: true,
    buttonCancel: true,
    fullScreen: false,
    page: ''
}
/**Export Component */
export default withStyles(alertDialogStyle)(AlertDialog);