import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Constants
import { configurationConstants } from '../../../helpers';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextBox from '../../../components/TextBox/TextBox';
import DropDown from '../../../components/DropDown/DropDown';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { alertActions } from '../../../actions';


const specialneedsData = {
    name: '',
    desc: ''
}
/**
 * EditableTextValidation component
 * @class EditableTextValidation
 * @extends { Component}
 * @memberof SpecialNeedsOptions
 */
class EditableTextVAlidation extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                validators={['required']}
                key={'tbox-value-' + rowIndex}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />

        )
    }
};
/**
 * EditableText component
 * @class EditableText
 * @extends { Component}
 * @memberof SpecialNeedsOptions
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;

        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-desc-' + rowIndex}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />

        )
    }
};
/**
 * StatusComponent component
 * @class StatusComponent
 * @extends { Component}
 * @memberof SpecialNeedsOptions
 */
class StatusComponent extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (<Switch
            color="primary"
            checked={data[fieldName]}
            onChange={event => {
                onChange(fieldName, rowIndex, event.target.checked);
            }}
        />);
    }
}
/** Props types */
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    onChange: () => { },
    data: {}
}
/**
 * Editabledropdown component
 * @class Editabledropdown
 * @extends { Component}
 * @memberof SpecialNeedsOptions
 */
class Editabledropdown extends Component {

    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <DropDown
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data.setAsideType.name}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            >
                {
                    data.setAsideType.map((type) => {
                        return (
                            <MenuItem key={`mut_${type._id}`} value={type._id}>
                                <Checkbox checked={data.setAsideType.indexOf(type._id) > -1} />
                                <ListItemText primary={type.name} />
                            </MenuItem>
                        )
                    })
                }
            </DropDown>
        )
    }

};

const componentMap = {
    name: EditableTextVAlidation,
    value: EditableText,
    desc: EditableText,
    setaside: Editabledropdown,
    isActive: StatusComponent
}
/**
 * SpecialNeedsOptions Container
 * @class SpecialNeedsOptions
 * @extends { Component}
 */
class SpecialNeedsOptions extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            specialneedoptions: [],
            saved: false
        }
    }
    /**
     * Componet will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.specialNeedOption);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { specialNeedOption } = nextProps;
        this.setState({
            saved: true,
            specialneedoptions: specialNeedOption
        });
    }
    /**
     * handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * save from
     */
    saveForm() {
        //Check for duplicate specialneedoptions names
        let specialneedoptions = this.state.specialneedoptions;
        let duplicateName=''
        let specialneedoptionsNames = specialneedoptions.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicatesetasidetypesNames = specialneedoptionsNames.some(function(item, idx){ 
                    if(specialneedoptionsNames.indexOf(item) !== idx){
                        duplicateName=specialneedoptions[specialneedoptionsNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicatesetasidetypesNames){
            this.props.erroralert(`${duplicateName.toUpperCase()} special needs option already exists`);
        }else{
        this.props.updatedata(configurationConstants.specialNeedOption, this.state.specialneedoptions);
        }
    }
    /**
     * Cell component onChnage
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { specialneedoptions } = this.state;
        switch (fieldName) {
            case "isActive":
                specialneedoptions[rowIndex][fieldName] = value;
                this.setState({ specialneedoptions });
                this.props.updatedata(configurationConstants.specialNeedOption, this.state.specialneedoptions)
                break;   
            default:
                specialneedoptions[rowIndex][fieldName] = value;
                this.setState({ specialneedoptions });
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { specialneedoptions } = this.state;
        // [1]
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="specialneedoptions"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'SPECIAL NEED OPTIONS'}
                        headerCols={['NAME', 'DESCRIPTION', 'STATUS']}
                        requiredFields={['NAME']}
                        fieldCols={['name', 'desc', 'isActive']}
                        rowDatas={specialneedoptions}
                        style={style}
                        staticHeader={true}
                        height= {height}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (specialneedoptions.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    specialneedoptions: [
                                        {
                                            ...specialneedsData,
                                            key: Math.max(...specialneedoptions.map(elt => elt.key)) + 1,
                                            type: configurationConstants.specialNeedOption
                                        },
                                        ...specialneedoptions
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            if (item && item._id !== undefined)
                                this.props.deleteConfig(item.type, item._id);
                            else {
                                let changedArray = specialneedoptions.filter(i => i._id);
                                this.setState({ specialneedoptions: changedArray });
                            }
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.specialNeedOption); }}
                />
            </ValidatorForm>

        );
    }
}
/** Props types */
SpecialNeedsOptions.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        specialNeedOption: configuration.configurations[configurationConstants.specialNeedOption] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        getByName: (specialNeedOption) => dispatch(confActions.getByName(specialNeedOption)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) => dispatch(alertActions.error(msg))
    }
};
/** Export SpecialNeedsOptions */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(SpecialNeedsOptions));
