import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';

// Import Actions
import { reportTablesActions } from '../../../actions/reportTables';
import { confActions } from '../../../actions/configuration';

// Components
import Table2Alternate from '../tabs/Table2Routes/Table2Alternate';
import Table2 from '../tabs/Table2Routes/Table2';
import Table2FarmWorker from '../tabs/Table2Routes/Table2FarmWorker';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

const CONFIG_NAME = 'table2Config';
const DEFAULT_CONFIG = {
    race: []
};
/**
 * Table2Selection Component
 * @class Table2Selection
 * @extends {Component}
 */
class Table2Selection extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            desc: null,
            year:null,
            _id: null
        }
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { reportId } = this.props;
        if (reportId) {
            // get the table 4 data
            this.props.getTable2Data(reportId);
        }
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne } = nextProps;
        if (getOne.data && getOne.data.desc && this.state._id == null) {
            const desc = getOne.data.desc.Sites_desc.type_id;
            const year = getOne.data.desc.year
            this.setState({
                desc,
                year,
                _id: Date.now()
            })
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {      
        const { desc,year } = this.state;       
        switch (desc) {
            case "Alternate": return (
                <Table2Alternate {...this.props} />
            );
            case "Farmworker": return (
                <Table2FarmWorker {...this.props} />
            );  
            case "Default": return (
                <Table2 {...this.props} name={year} />
            );
            default: return null
        }
    }
}
/**
 * Bind Component Property Types
 */
Table2Selection.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};
/** Default Props */
Table2Selection.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ reportTables, configuration }, ownProps) => {
    return {
        getOne: reportTables.table2,
        configuration: configuration.configurations[CONFIG_NAME] || { ...DEFAULT_CONFIG }
    }
};
/**
 * Map Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getTable2Data: (id) => dispatch(reportTablesActions.getTable2Data(id)),
        getConfiguration: (name, race) => dispatch(confActions.getConfiguration(name, race))

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Table2Selection));
