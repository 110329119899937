export const notificationConstants = {

    // notification data listing
    GETBYUSERID_NOTIFICATION_REQUEST: 'NOTIFICATIONDATA_GETBYUSERID_REQUEST',
    GETBYUSERID_NOTIFICATION_SUCCESS: 'NOTIFICATIONDATA_GETBYUSERID_SUCCESS',
    GETBYUSERID_NOTIFICATION_FAILURE: 'NOTIFICATIONDATA_GETBYUSERID_FAILURE',

    //notification Alert data listing
    GETBYUSERID_NOTIFICATIONALERT_REQUEST: 'NOTIFICATIONALERTDATA_GETBYUSERID_REQUEST',
    GETBYUSERID_NOTIFICATIONALERT_SUCCESS: 'NOTIFICATIONALERTDATA_GETBYUSERID_SUCCESS',
    GETBYUSERID_NOTIFICATIONALERT_FAILURE: 'NOTIFICATIONALERTDATA_GETBYUSERID_FAILURE',

    // notification data update 
    UPDATEDATA_REQUEST: 'NOTIFICATIONDATA_UPDATE_REQUEST',
    UPDATEDATA_SUCCESS: 'NOTIFICATIONDATA_UPDATE_SUCCESS',
    UPDATEDATA_FAILURE: 'NOTIFICATIONDATA_UPDATE_FAILURE',

    // notification data delete 
    DELETEDATA_REQUEST: 'NOTIFICATIONDATA_DELETE_REQUEST',
    DELETEDATA_SUCCESS: 'NOTIFICATIONDATA_DELETE_SUCCESS',
    DELETEDATA_FAILURE: 'NOTIFICATIONDATA_DELETE_FAILURE',

    // notification data update from socket
    UPDATEDATA_SOCKET_REQUEST: 'NOTIFICATIONDATA_SOCKET_UPDATE_REQUEST',
    UPDATEDATA_SOCKET_SUCCESS: 'NOTIFICATIONDATA_SOCKET_UPDATE_SUCCESS',
    UPDATEDATA_SOCKET_FAILURE: 'NOTIFICATIONDATA_SOCKET_UPDATE_FAILURE',

    // notification alert data update from socket
    ALERT_UPDATEDATA_SOCKET_REQUEST: 'NOTIFICATION_ALERT_DATA_SOCKET_UPDATE_REQUEST',
    ALERT_UPDATEDATA_SOCKET_SUCCESS: 'NOTIFICATION_ALERT_DATA_SOCKET_UPDATE_SUCCESS',
    ALERT_UPDATEDATA_SOCKET_FAILURE: 'NOTIFICATION_ALERT_DATA_SOCKET_UPDATE_FAILURE',


    // Alert data update 
    UPDATEALERTDATA_REQUEST: 'ALERTDATA_UPDATE_REQUEST',
    UPDATEALERTDATA_SUCCESS: 'ALERTDATA_UPDATE_SUCCESS',
    UPDATEALERTDATA_FAILURE: 'ALERTDATA_UPDATE_FAILURE',

    // Alert data delete 
    DELETEALERTDATA_REQUEST: 'ALERTDATA_DELETE_REQUEST',
    DELETEALERTDATA_SUCCESS: 'ALERTDATA_DELETE_SUCCESS',
    DELETEALERTDATA_FAILURE: 'ALERTDATA_DELETE_FAILURE',
};