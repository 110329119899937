export const complianceConstants = {
    // compliance listing 
    GETALL_REQUEST: "COMPLIANCE_GETALL_REQUEST",
    GETALL_SUCCESS: "COMPLIANCE_GETALL_SUCCESS",
    GETALL_FAILURE: "COMPLIANCE_GETALL_FAILURE",

    // compliance update
    UPDATEDATA_REQUEST: "COMPLIANCE_UPDATEDATA_REQUEST",
    UPDATEDATA_SUCCESS: "COMPLIANCE_UPDATEDATA_SUCCESS",
    UPDATEDATA_FAILURE: "COMPLIANCE_UPDATEDATA_FAILURE",

    // comliance get field
    GETFIELD_REQUEST: "COMPLIANCE_GETFIELD_REQUEST",
    GETFIELD_SUCCESS: "COMPLIANCE_GETFIELD_SUCCESS",
    GETFIELD_FAILURE: "COMPLIANCE_GETFIELD_FAILURE",

    // compliance get field data
    GETFIELDDATA_REQUEST: "COMPLIANCE_GETFIELDDATA_REQUEST",
    GETFIELDDATA_SUCCESS: "COMPLIANCE_GETFIELDDATA_SUCCESS",
    GETFIELDDATA_FAILURE: "COMPLIANCE_GETFIELDDATA_FAILURE",

    // download compliance error
    DOWNLOAD_REQUEST: 'COMPLIANCE_DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS: 'COMPLIANCE_DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE: 'COMPLIANCE_DOWNLOAD_FAILURE',
}