import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import UnitsList from './UnitsList';
import UnitsView from "./UnitsView";
import UnitsNew from './UnitsNew';
/**
 * UnitsRouter Component
 * @class UnitsRouter
 * @extends {Component}
 */
class UnitsRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, buildingId, title, isSubPage, siteId, projectId } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return (
                        <UnitsList
                            {...props}
                            isSubPage={isSubPage}
                            page={'units'}
                            buildingId={buildingId}
                            siteId={siteId}
                            projectId={projectId}
                            title={!isSubPage ? title : ''}
                        />
                    )
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <UnitsNew {...props} buildingId={buildingId} isSubPage={isSubPage} siteId={siteId} projectId={projectId} />
                }} />
                <Route path={`${match.url}/:unitid`} render={(props) => {
                    return <UnitsView buildingId={buildingId} {...props} isSubPage={isSubPage} siteId={siteId} projectId={projectId} />
                }} />
            </Switch>
        );
    }
}
/** Default Props */
UnitsRouter.defaultProps = {
    isSubPage: false
}
/**
 * Export Component
 */
export default UnitsRouter;
