import React from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Icons
import { CloseIcon } from '../SvgIcons/SvgIcons';

/**
 * Dialog box Component
 * @class AlertDialog
 * @extends {Component}
 */
class AlertDialog extends React.Component {
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { open, onSave, onClose,onReadMore, saveText, cancelText,readMoreText, title, saveVisible, cancelVisible, readMoreVisible, buttonDisable, maxWidth, closeIconVisible, className,page } = this.props;
        return (
            <Dialog
                open={open}
                className={title === 'Assign Funder' ? 'assignFunder_dropdown' :''}
                onClose={onClose}
                maxWidth={maxWidth}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {(title || closeIconVisible) &&
                    <DialogTitle id="alert-dialog-title" className={title ? "dialogBoxTitle" : "dialogBoxTitle closeAlert"}>
                        <div className='space-title'>
                        {title && <div>{title}</div>}
                        <div>{closeIconVisible && <CloseIcon onClick={onClose}/>}</div>
                        </div>
                    </DialogTitle>}
                <DialogContent className={className} style={page==='editalert'?{height:'350px',overflowY:'scroll'}:page==='useralert'?{height:'250px',overflowY:'scroll'}:{}}>
                    {this.props.children}
                </DialogContent>
                {
                    (saveVisible || cancelVisible || readMoreVisible) &&
                    <DialogActions style={readMoreVisible===true?{}:{ padding: "24px 24px" }}>
                        {
                            saveVisible && (
                                <Button variant="contained" disabled={buttonDisable} onClick={onSave} color="primary">
                                    {saveText}
                                </Button>
                            )
                        }
                        {
                            cancelVisible && (
                                <Button variant="contained" disabled={buttonDisable} onClick={onClose} color="default" autoFocus>
                                    {cancelText}
                                </Button>
                            )
                        }
                        {
                            readMoreVisible && (
                                <Button variant="text" onClick={onReadMore} style={{textDecoration:'underline',color:'#36aebe'}} autoFocus>
                                    {readMoreText}
                                </Button>
                            )
                        }
                    </DialogActions>
                }
            </Dialog>
        );
    }
}
//Default props
AlertDialog.defaultProps = {
    open: false,
    onClose: () => { },
    onSave: () => { },
    onCancel: () => { },
    onReadMore:() =>{ },
    title: "",
    saveText: "Save",
    cancelText: "Cancel",
    readMoreText:"Read More",
    saveVisible: true,
    cancelVisible: true,
    readMoreVisible:false,
    buttonDisable: false,
    closeIconVisible: false,
    maxWidth: 'md',
    className: "",
    page:''
}
/**Export Component */
export default AlertDialog;