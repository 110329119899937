import { FinancialActivityMessages } from "./messages";

const FinancialActivity = (data) => {

    let errors = [];
    let { programActivity, financialActivity, reportData, contractActivitySavedOnce } = data;
    let loanReportActivity = reportData.data;
    if (programActivity && financialActivity && loanReportActivity && ((loanReportActivity.length > 0 && loanReportActivity.filter(elem => elem.isContractActivitySaved === true).length > 0) || contractActivitySavedOnce)) {
        let loanNumbers = [];
        let closedLoanNumbers = [];
        let openLoanNumbers = [];
        //let openLoanNumbersHTF = [];
        let FAWarning1 = [];
        let FAWarning2 = [];
        let FA1 = true;
        let FA2 = true;
        // let FA3 = true;
        let errorLoan1 = [];
        let errorLoan2 = [];
        let errorLoan3 = [];

        //REMOVE migrated and DOP from contractactivitydata and financialactivitydata
        let migratedLoanNumbers = loanReportActivity.filter(item=>item.isMigrated || new Date(item.dateOfPurchase).getFullYear()<=2009).map(item=>item.loanNumber);
        loanReportActivity = loanReportActivity.filter(item=>!item.isMigrated || new Date(item.dateOfPurchase).getFullYear()>2009);

        //section 4a
        let migratedLoansInHomeOwnersMadePayment = financialActivity.homeOwnersMadePayments?financialActivity.homeOwnersMadePayments.filter(item=>migratedLoanNumbers.includes(item)):[];
        financialActivity.homeOwnersMadePayments = financialActivity.homeOwnersMadePayments?financialActivity.homeOwnersMadePayments.filter(item=>!migratedLoanNumbers.includes(item)):[];
        financialActivity.numberOfHomeOwnersMadePayment = financialActivity.numberOfHomeOwnersMadePayment - migratedLoansInHomeOwnersMadePayment.length;

        //section 4b
        let migratedLoansInloansStatisfied = financialActivity.loansStatisfied?financialActivity.loansStatisfied.filter(item=>migratedLoanNumbers.includes(item)):[];
        financialActivity.loansStatisfied = financialActivity.loansStatisfied?financialActivity.loansStatisfied.filter(item=>!migratedLoanNumbers.includes(item)):[];
        financialActivity.numberOfLoansStatisfied = financialActivity.numberOfLoansStatisfied - migratedLoansInloansStatisfied.length;

        //section 5a
        let migratedLoansInloansBadDebtIncurred = financialActivity.loansBadDebtIncurred?financialActivity.loansBadDebtIncurred.filter(item=>migratedLoanNumbers.includes(item)):[];
        financialActivity.loansBadDebtIncurred = financialActivity.loansBadDebtIncurred?financialActivity.loansBadDebtIncurred.filter(item=>!migratedLoanNumbers.includes(item)):[];
        financialActivity.numberOfLoansBadDebtIncurred = financialActivity.numberOfLoansBadDebtIncurred - migratedLoansInloansBadDebtIncurred.length;

        //***************************************
        let contractActivityData = loanReportActivity ? loanReportActivity : [];
        // let openLoan = loanReportActivity ? loanReportActivity.filter(item => item.closedLoan === true) : [];
        const contractActivityLength = contractActivityData ? contractActivityData.length : 0;
        const propertyForeclosureCount = contractActivityData ? contractActivityData.filter(item => (item.isPropertyForclosure === true || item.isPropertyForclosure === 'Yes')) : [];
        const htfSatisfied = contractActivityData ? contractActivityData.filter(item => (item.isHTFLoanStatisfied === true || item.isHTFLoanStatisfied === 'Yes' || item.isHTFLoanStatisfied === 1)) : [];
        //considering closed loans as open loans - 07/09/2023 - WBAR-2716
        const openLoan = contractActivityData ? contractActivityData : [];
        const HTFClosedLoans = contractActivityData ? contractActivityData.filter(item => ((item.isHTFLoanStatisfied === false || item.isHTFLoanStatisfied === 2))).map(loan => loan.loanNumber) : [];
        // const openHtfSatisfied = contractActivityData ? contractActivityData.filter(item => ) : [];

        let closedLoan = [...propertyForeclosureCount, ...htfSatisfied];
        //  let openLoan = [...openPropertyForeclosureCount,...openHtfSatisfied];
        closedLoan = closedLoan.filter((elem, index, inputArray) => {
            return inputArray.findIndex(inputElem => inputElem._id === elem._id) === index;
        })
        const loanData = contractActivityData ? contractActivityData : [];
        if (contractActivityLength > 0) {
            loanData.forEach(item => {
                loanNumbers.push(item.loanNumber)
            });
            loanNumbers = loanNumbers.length > 0 ? loanNumbers : [];
        }

        if (closedLoan.length > 0) {
            closedLoan.forEach(item => {
                //if (item.isLoan === true || item.isLoan === 'Yes')
                    closedLoanNumbers.push(item.loanNumber);
            });
        }

        if (openLoan.length > 0) {
            openLoan.forEach(item => {
                //if (item.isLoan === true || item.isLoan === 'Yes')
                    openLoanNumbers.push(item.loanNumber);
            });
        }

        // if (openLoanHTF.length > 0) {
        //     openLoan.forEach(item => {
        //         if (item.isLoan === true || item.isLoan === 'Yes')
        //             openLoanNumbersHTF.push(item.loanNumber);
        //     });
        // }

        // error - 1
        if (loanData.length < financialActivity.numberOfHomeOwnersMadePayment) {
            errors.push({
                sectionIndex: 1,
                msg: FinancialActivityMessages.numberOfHomeOwnersMadePayment,
                errorType: 2,
                reportActivity: 2,
                financialActivity: financialActivity._idcontractActivityLength
            });
        }

        const loanNumberFA1 = financialActivity.homeOwnersMadePayments ? financialActivity.homeOwnersMadePayments : [];
        loanNumberFA1.forEach(item => {
            if (!loanNumbers.includes(item))
                errorLoan1.push(item);
        });
        if (errorLoan1.length > 0)
            errors.push({
                units: errorLoan1,
                sectionIndex: 2,
                msg: FinancialActivityMessages.loanNumber4a,
                errorType: 1,
                reportActivity: 2,
                financialActivity: financialActivity._id
            });

        // error - 2
        if (loanData.length < financialActivity.numberOfLoansStatisfied) {
            errors.push({
                sectionIndex: 3,
                msg: FinancialActivityMessages.numberOfLoansStatisfied,
                errorType: 2,
                reportActivity: 2,
                financialActivity: financialActivity._id
            });
        }

        const loanNumberFA2 = financialActivity.loansStatisfied ? financialActivity.loansStatisfied : [];
        loanNumberFA2.forEach(item => {
            if (!loanNumbers.includes(item))
                errorLoan2.push(item);
        });
        if (errorLoan2.length > 0)
            errors.push({
                units: errorLoan2,
                sectionIndex: 4,
                msg: FinancialActivityMessages.loanNumber4b,
                errorType: 1,
                reportActivity: 2,
                financialActivity: financialActivity._id
            });

        // error - 3    
        // if (loanData.length < financialActivity.numberOfLoansBadDebtIncurred) {
        //     errors.push({
        //         sectionIndex: 5,
        //         msg: FinancialActivityMessages.numberOfLoansBadDebtIncurred,
        //         errorType: 2,
        //         reportActivity: 2,
        //         financialActivity: financialActivity._id
        //     });
        // }

        const loanNumberFA3 = financialActivity.loansBadDebtIncurred ? financialActivity.loansBadDebtIncurred : [];
        loanNumberFA3.forEach(item => {
            if (!loanNumbers.includes(item))
                errorLoan3.push(item);
        });
        if (errorLoan3.length > 0)
            errors.push({
                units: errorLoan3,
                sectionIndex: 6,
                msg: FinancialActivityMessages.loanNumber5a,
                errorType: 1,
                reportActivity: 2,
                financialActivity: financialActivity._id
            });

        // Warning - 1
        closedLoanNumbers.forEach(item => {
            // if (loanNumberFA1.indexOf(item) > -1 && FA1) {
            //     // FA1 = false;
            //     FAWarning1.push(item)
            // }

            if (FAWarning1.length > 0)
                errors.push({
                    units: FAWarning1,
                    sectionIndex: 2,
                    msg: FinancialActivityMessages.foreClosure1,
                    errorType: 2,
                    reportActivity: 2,
                    financialActivity: financialActivity._id
                });


            // if (loanNumberFA3.indexOf(item) > -1 && FA3) {
            //     FA3 = false;
            //     errors.push({
            //         msg: FinancialActivityMessages.foreClosure3,
            //         errorType: 2,
            //         reportActivity: 2,
            //         financialActivity: financialActivity._id
            //     });
            // }
        });

        //updated on 18/10 checked only HTF for 4b
        HTFClosedLoans.forEach(item => {
            if (loanNumberFA2.indexOf(item) > -1 && FA2) {
                // FA2 = false;
                FAWarning2.push(item)
            }
        })

        if (FAWarning2.length > 0)
            errors.push({
                units: FAWarning2,
                sectionIndex: 4,
                msg: FinancialActivityMessages.foreClosure2,
                errorType: 2,
                reportActivity: 2,
                financialActivity: financialActivity._id
            });

        // if (loanData.length !== financialActivity.numberOfHomeOwnersMadePayment) {
        //     errors.push({
        //         msg: FinancialActivityMessages.loanNumberMatch4a,
        //         errorType: 2,
        //         reportActivity: 2,
        //         financialActivity: financialActivity._id
        //     });
        // }

        // if (loanData.length !== financialActivity.numberOfLoansStatisfied) {
        //     errors.push({
        //         msg: FinancialActivityMessages.loanNumberMatch4b,
        //         errorType: 2,
        //         reportActivity: 2,
        //         financialActivity: financialActivity._id
        //     });
        // }

        // if (loanData.length !== financialActivity.numberOfLoansBadDebtIncurred) {
        //     errors.push({
        //         msg: FinancialActivityMessages.loanNumberMatch5a,
        //         errorType: 2,
        //         reportActivity: 2,
        //         financialActivity: financialActivity._id
        //     });
        // }
    }
    return errors;
}

export default FinancialActivity;