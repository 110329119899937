const getFloat = (value) => {
    let fVal = value === '' || value === null || value === 'undefined' || value === undefined ? 0 : parseFloat(value);
    fVal = parseFloat(parseFloat(fVal).toFixed(2));
    return fVal;
}

export const totalOtherIncome = (data) => {
    const totalOtherIncome = data.otherIncome.reduce((a, b) => a + (getFloat(b.amount)), 0);
    return getFloat(totalOtherIncome);
}

export const totalGrossRentalIncome = (data) => {
    const incomeAmt =  data.otherIncome.reduce((a, b) => a + getFloat(b.amount), 0);
    return getFloat(incomeAmt + getFloat(data.actualRentIncome));
}

export const commercialNetIncome = (data) => {
    const val = data.otherIncome.reduce((a, b) => a + getFloat(b.amount), 0)
    return getFloat(val);
}

export const totalSubsidyOperating = (data) => {
    const val = data.subsidyTypes.reduce((a, b) => a + getFloat(b.operatingAmt), 0);
    return getFloat(val);
}

export const totalSubsidyServices = (data) => {
    const val = data.subsidyTypes.reduce((a, b) => a + getFloat(b.servicesAmt), 0);
    return getFloat(val);
}

export const totalSubsidyEGI = (data) => {
    const totalSubsidy = data.subsidyTypes.reduce((a, b) => a + (getFloat(b.operatingAmt) + getFloat(b.servicesAmt)), 0);
    const val = totalGrossRentalIncome(data) + totalSubsidy + getFloat(data.commercialNetIncome);
    return getFloat(val);
}

export const getExpensesCategory = (data) => {
    let operatingAmt = data.expenseCategory ? data.expenseCategory.reduce((a, b) => a + getFloat(b.operatingAmt), 0) : 0;
    let servicesAmt = data.expenseCategory ? data.expenseCategory.reduce((a, b) => a + getFloat(b.servicesAmt), 0) : 0;
    let totalAmt = getFloat(operatingAmt + servicesAmt);
    let preOperatingAmt = data.expenseCategory ? data.expenseCategory.reduce((a, b) => a + getFloat(b.preOperatingAmt), 0) : 0;
    let preServicesAmt = data.expenseCategory ? data.expenseCategory.reduce((a, b) => a + getFloat(b.preServicesAmt), 0) : 0;

    const NOI = getFloat(totalSubsidyEGI(data)) - getFloat(totalAmt);
    return {
        operatingAmt: getFloat(operatingAmt),
        servicesAmt: getFloat(servicesAmt),
        totalAmt: getFloat(totalAmt),
        NOI: getFloat(NOI),
        preOperatingAmt: getFloat(preOperatingAmt),
        preServicesAmt: getFloat(preServicesAmt)
    }
}

export const getCashFlow = (data) => {
    const val = getExpensesCategory(data).NOI - getDebtServices(data).totalDebtService;
   return getFloat(val);
}

export const getDebtServices = (data) => {
    let totalDebtService = data.debtServices ? data.debtServices.reduce((a, b) => {
        const amt = b.fundsPaid === null || b.fundsPaid === '' ? 0 : getFloat(b.fundsPaid);
        return a + amt;
    }, 0) : 0;
    totalDebtService = getFloat(totalDebtService);
    return {
        totalDebtService,
        cashFlow: getExpensesCategory(data).NOI - totalDebtService
    }
}

export const updateTransactionBalance = (beginingBalance, transactions) => {
    beginingBalance = beginingBalance === '' || beginingBalance === null || beginingBalance === undefined ? 0 : parseFloat(beginingBalance);
    return transactions.map((trans) => {
        const amt = trans.amount === '' || trans.amount === null || trans.amount === undefined ? 0 : parseFloat(trans.amount);
        const balance = trans.depositFlag === 0 ? beginingBalance - amt : beginingBalance + amt;
        beginingBalance = balance;
        return {
            ...trans,
            balance: isNaN(balance) ? 0 : balance.toFixed(2)
        }
    });
}

export const getReplacementReservebalance = (data) => {
    const trans = updateTransactionBalance(data.replacementReserveBeginningBalance, data.aTransaction);
    return trans.length >  0 ? trans[trans.length - 1].balance : data.replacementReserveBeginningBalance;
}

export const getOperatingReservebalance = (data) => {
    const trans = updateTransactionBalance(data.operatingReserveBeginningBalance, data.bTransaction);
    return trans.length >  0 ? trans[trans.length - 1].balance : data.operatingReserveBeginningBalance;
}

export const getDebtCoverage = (data) => {
    if (getDebtServices(data).totalDebtService === 0 || getExpensesCategory(data).NOI === 0) {
        return 0;
    }
    const val = getExpensesCategory(data).NOI / getDebtServices(data).totalDebtService;
    return getFloat(val);
}

export const getReplacementReservePerUnit = (data) => {
    let val = getReplacementReservebalance(data) / data.performanceMeasure.totalUnits;
    return val ? parseFloat(val).toFixed(0) : 0
}

export const getOperatingReservePerUnit = (data) => {
    const val = getOperatingReservebalance(data) / data.performanceMeasure.totalUnits;
    return val ? parseFloat(val).toFixed(0) : 0
}

export const getReplacementReserveDeposit = (data) => {
    const value = data.expenseCategory.reduce((a, b) => {
        if (b._id === 36) {
            return a + (getFloat(b.operatingAmt) + getFloat(b.servicesAmt));
        } else {
            return a;
        }
    }, 0);
    return getFloat(value);
}

export const getOperatingReserveDeposit = (data) => {
    const value = data.expenseCategory.reduce((a, b) => {
        if (b._id === 37) {
            return a + (getFloat(b.operatingAmt) + getFloat(b.servicesAmt));
        } else {
            return a;
        }
    }, 0);
    return getFloat(value);
}

export function formatExpenseCategory(tableExpenses, expenseCategory, previousExpenses, DefaultRowDatas) {
    let expenseRowData = [];
    if (expenseCategory!= undefined && expenseCategory.length > 0) {
        expenseRowData = expenseCategory.map((ec) => {
            let curData = tableExpenses != undefined && tableExpenses.filter(te => te.expenseCategory_id === ec.key)[0];
            let preData = previousExpenses != undefined && previousExpenses.filter(te => te.expenseCategory_id === ec.key)[0];
            curData = curData || { ...DefaultRowDatas.expenseCategory }
            return {
                _id: ec.key,
                name: ec.name,
                expenseCategory_id: ec.key,
                operatingAmt: curData.operatingAmt,
                servicesAmt: curData.servicesAmt,
                preOperatingAmt: preData ? preData.operatingAmt : null,
                preServicesAmt: preData ? preData.servicesAmt : null,
                comment: curData.comment,
                balance: ''
            }
        });
    }
    return expenseRowData;
}