import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

// Import Styles
import helpFloaterStyle from '../../assets/jss/components/HelpFloater';

//Import Components
import FloaterContent from './FloaterContent'
import { CloseIcon } from '../SvgIcons/SvgIcons';

// Helpers
import { history } from '../../helpers';
/**
 * Help Floater Component
 * @class HelpFloater
 * @extends {Component}
 */
class HelpFloater extends Component {
    /** Handle View More */
    handleViewMore = () => {
        let { categoryName, _id } = this.props;
        history.push({
            pathname: `/help/${categoryName.toLowerCase()}`,
            state: { page: 'help', type: _id},
        })
    }
    /**
     * Render HTML to reactDOM
     * @returns 
     */
    render(){
        let { classes, handleCloseFloater, title, helpTopics } = this.props;
        let isHelpSliced = false
        if(helpTopics){
            isHelpSliced = helpTopics.length > 3 && true
            helpTopics = helpTopics.length <= 3 ? helpTopics : helpTopics.slice(Math.max(helpTopics.length-3, 0))
        }
        return(
            <div className={classes.container}>
                <div className={classes.backDrop} onClick = { () =>  handleCloseFloater()}></div>
                <div className={classes.floater}>
                    <div className={classes.header}>
                        About {title}
                        <CloseIcon onClick = { () =>  handleCloseFloater()}/>
                    </div>
                    <div className={classes.body}>
                        { helpTopics.length > 0 ? helpTopics.map( help => {
                                return(
                                    <FloaterContent 
                                        help={help}
                                        key={help._id}
                                    />
                                )
                            }) : <div className={classes.noRecords}>No Help Information Available</div>
                        }
                        { isHelpSliced &&
                            <div className="helpFloaterBtnContainer">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    component="span"
                                    className={classes.button}
                                    onClick={this.handleViewMore}
                                >
                                    View More
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
/**
 * Bind Component Property Types
 */
HelpFloater.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string,
};
/**
 * Default Props Types
 */
HelpFloater.defaultProps = {
    classes: {},
    title: "Help",
    helpTopics: [],
    handleCloseFloater: () => {}
};
/**
 * Maps state from store to props
 * @param {*} ownprops 
 * @returns 
 */
const mapStateToProps = (ownprops) => {
    return { }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return { }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(helpFloaterStyle, { withTheme: true })(HelpFloater));
