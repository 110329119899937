//Import socket
import socketIOClient from "socket.io-client";

// Import Access Role
import { roleAccess } from "./index";

//set global packs
import _ from "underscore";
import moment from "moment";

global._ = _;
global.moment = moment;

let API_POINTS = {
  clientApiURL: "http://localhost:3000/v1",
  apiURL: "http://localhost:3000/v1",
  ms_apiURL: "http://localhost:3030/v1/ms",
  api_socketURL: "ws://localhost:4051",
  ms_socketURL: "ws://localhost:4052",
};

let SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

switch (SERVER_POINT) {
  case "QA":
     API_POINTS = {
      clientApiURL: "https://wbars-qa.qaserverix.co/v1",
      apiURL: "https://wbars-qa.qaserverix.co:3457/v1",
      ms_apiURL: "https://wbars-qa.qaserverix.co:3458/v1/ms",
      api_socketURL: "wss://wbars-qa.qaserverix.co:3457",
      ms_socketURL: "wss://wbars-qa.qaserverix.co:3458",
    };
    break;
  case "QA_PERF":
    API_POINTS = {
      clientApiURL: "http://192.168.1.213:3457/v1",
      apiURL: "http://192.168.1.213:3457/v1",
      ms_apiURL: "http://192.168.1.213:3458/v1/ms",
      api_socketURL: "ws://192.168.1.213:3457",
      ms_socketURL: "ws://192.168.1.213:3458",
    };
    break;
  case "UAT":
    API_POINTS = {
      clientApiURL: "https://uat.wbars.com/v1",
      apiURL: "https://server-uat.wbars.com/v1",
      ms_apiURL: "https://ms-uat.wbars.com/v1/ms",
      api_socketURL: "wss://server-uat.wbars.com",
      ms_socketURL: "wss://ms-uat.wbars.com",
    };
    break;
  case "TEST":
    API_POINTS = {
      clientApiURL: "http://54.174.214.54:4051/v1",
      apiURL: "http://18.206.254.172:4051/v1",
      ms_apiURL: "http://44.203.156.144:4052/v1/ms",
      api_socketURL: "ws://18.206.254.172:4051",
      ms_socketURL: "ws://44.203.156.144:4052",
    };
    break;
  case "PERF":
    API_POINTS = {
      clientApiURL: "https://wbars-perf-server.qaserverix.co/v1",
      apiURL: "https://wbars-perf-server.qaserverix.co/v1",
      ms_apiURL: "https://wbars-perf-ms.qaserverix.co/v1/ms",
      api_socketURL: "wbars-perf-server.qaserverix.co",
      ms_socketURL: "wbars-perf-ms.qaserverix.co",
    };
    break;
  case "PROD":
    API_POINTS = {
      clientApiURL: "https://wbars.com/v1",
      apiURL: "https://server.wbars.com/v1",
      ms_apiURL: "https://ms.wbars.com/v1/ms",
      api_socketURL: "wss://server.wbars.com",
      ms_socketURL: "wss://ms.wbars.com",
    };
    break;
  case "MIRROR":
    API_POINTS = {
      clientApiURL: "https://mirror.wbars.com/v1",
      apiURL: "https://mirror.wbars.com/v1",
      ms_apiURL: "https://mirror.wbars.com/v1/ms",
      api_socketURL: "wss://mirror.wbars.com",
      ms_socketURL: "wss://mirror.wbars.com",
    };
    break;
  case "TRAIN":
    API_POINTS = {
      clientApiURL: "https://training.wbars.com/v1",
      apiURL: "https://training.wbars.com/v1",
      ms_apiURL: "https://training.wbars.com/v1/ms",
      api_socketURL: "wss://training.wbars.com:3000",
      ms_socketURL: "wss://training.wbars.com:3030",
    };
    break;

  case "DEMO":
    API_POINTS = {
      clientApiURL: "https://demo-site.qaserverix.co:4051/v1",
      apiURL: "https://demo-site.qaserverix.co:4051/v1",
      ms_apiURL: "https://demo-site.qaserverix.co:4052/v1/ms",
      api_socketURL: "wss://demo-site.qaserverix.co:4051",
      ms_socketURL: "wss://demo-site.qaserverix.co:4052",
    };
    break;
  case "DATAMART":
    API_POINTS = {
      clientApiURL: "https://datamart-uat.wbars.com ",
      apiURL: "https://datamart-uat.wbars.com:3000/v1",
      ms_apiURL: "https://datamart-uat.wbars.com:3030/v1/ms",
      api_socketURL: "wss://datamart-uat.wbars.com:3000",
      ms_socketURL: "wss://datamart-uat.wbars.com:3030",
    };
    break;
  default:
    API_POINTS = {
      clientApiURL: "http://localhost:4051/v1",
      apiURL: "http://localhost:4051/v1",
      ms_apiURL: "http://localhost:4052/v1/ms",
      api_socketURL: "ws://localhost:4051",
      ms_socketURL: "ws://localhost:4052",
    };
}

export const globalVariable = {
  tableRowSize: Math.ceil((getParentCardHeight() - 200) / 30),
  subPageTabtableRowSize: Math.ceil((getChildCardHeight() - 200) / 30),
  subPagetableRowSize: Math.ceil((getChildCardHeight() - 250) / 30),
  subPagetableRowSizeXL: Math.ceil((getChildCardHeight() - 275) / 30), //for loan activty list
  tabTableRowSize: Math.ceil((getChildCardHeight() - 300) / 30),
  ...API_POINTS,
};

export const socket = {
  api: socketIOClient(API_POINTS.api_socketURL, {
    secure: true,
    transports: ["websocket"],
  }),
  ms: socketIOClient(API_POINTS.ms_socketURL, {
    secure: true,
    transports: ["websocket"],
  }),
};

export function getParentCardHeight() {
  return window.innerHeight - 120;
}

export function getChildCardHeight() {
  return window.innerHeight - 219;
}

export function getSubChildCardHeight() {
  return window.innerHeight - 320;
}

export function getTableComponentHeight() {
  return window.innerHeight - 154;
}

export function getConfigurationPageMenuHeight() {
  return window.innerHeight - 122;
}

export function getConfigurationPageContentHeight() {
  return window.innerHeight - 122;
}

export function getConfigurationContentHeight() {
  return window.innerHeight - 211;
}

export function getHelpPageMenuHeight() {
  return window.innerHeight - 122;
}

export function getHelpPageContentHeight() {
  return window.innerHeight - 122;
}

export function getConfigurationPageTableHeight() {
  return window.innerHeight - 350;
}

export function getDragAndDropContainerHeight() {
  return window.innerHeight - 298;
}

export function getUser() {
  return JSON.parse(localStorage.getItem("user"));
}

export function getUserId() {
  return getUser().id;
}

export function getUserName() {
  return getUser().firstName;
}
export function getUserPicture() {
  return `${globalVariable}/v1/users/picture`;
}

export function getUserOrgId() {
  return getUser().org.id;
}

export function getOrgType() {
  return getUser().org.type;
}

export function getUserRole() {
  return getUser().org.role;
}

export function getUserType() {
  return getUser().org.type;
}

export function getUserOrgAccess() {
  return getUser().org.organizationAccess;
}

export function getMultifamilyAccess() {
  return getUser().multiFamilyAccess;
}

export function getHomeownershipAccess() {
  return getUser().hoAccess;
}

/**
 * Get User Access for page
 * @param {*} page
 * @returns
 */
export function getUserAccessForPage(page) {
  return roleAccess[getUserRole()][page].slice();
}
/**
 * Format Date
 * @param {*} isoFormat
 * @returns
 */
export function formatDate(isoFormat) {
  if (
    isoFormat === null ||
    isoFormat === "" ||
    isoFormat === "1970-01-01T00:00:00.000Z"
  )
    return "";
  const date = new Date(isoFormat);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function formatDateWithPrefixZero(isoFormat) {
  if (
    isoFormat === null ||
    isoFormat === "" ||
    isoFormat === "1970-01-01T00:00:00.000Z"
  )
    return "";
  const date = new Date(isoFormat);
  return `${
    date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }/${
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
  }/${date.getFullYear()}`;
}

export function formatDateWithTime(isoFormat) {
  if (
    isoFormat === null ||
    isoFormat === "" ||
    isoFormat === "1970-01-01T00:00:00.000Z"
  )
    return "";
  const date = new Date(isoFormat);
  return `${
    date.getMonth() + 1
  }/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
}

export function formatDateWithTimeWithPrefixZero(isoFormat) {
  if (
    isoFormat === null ||
    isoFormat === "" ||
    isoFormat === "1970-01-01T00:00:00.000Z"
  )
    return "";
  const date = new Date(isoFormat);
  return `${
    date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
  }/${
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
  }/${date.getFullYear()} ${
    date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
  }:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}:${
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()
  }`;
}
/**
 * A method to get the user tos state
 *
 * @export
 * @returns false if user is not loggeg in else  return user data
 */
export function getUserTOSStatus() {
  const users = JSON.parse(localStorage.getItem("user"));
  if (users) {
    return { loggedIn: true, tos: users.tos };
  } else {
    return { loggedIn: false, tos: false };
  }
}
/**
 * Truncate String
 * @param {*} text
 * @param {*} length
 * @returns
 */
export function truncateString(text, length) {
  return text && text.length > length
    ? `${text.substring(0, length)}...`
    : text;
}
// to rename
const pageRename = {
  buildings: "sites",
};
/**
 * Get Current Page
 * @param {*} pathname
 * @returns
 */
export function getCurrentPage(pathname) {
  const pathArrays = pathname.split("/");
  const paths = pathArrays.filter((path) => isNaN(path) && path !== "new");
  const page = paths.pop();
  return pageRename[page] ? pageRename[page] : page;
}
/**
 * Report submission level
 * @param {*} pageAccess
 * @returns
 */
export function getSubmissionLevel(pageAccess) {
  let levels = [
    pageAccess.indexOf("ONSITE_LEVEL") > 0,
    pageAccess.indexOf("PROP_LEVEL") > 0,
    pageAccess.indexOf("OWNER_LEVEL") > 0,
    pageAccess.indexOf("FUNDER_LEVEL") > 0,
  ];

  return levels.indexOf(true) + 1;
}
/**
 * Get Moment Date
 * @param {*} date
 * @param {*} format
 * @returns
 */
export function getMomentDate(date, format = "MM.DD.YYYY") {
  return new Date(moment(date, format));
}
/**
 * Get Calculated Date
 * @param {*} date
 * @param {*} method
 * @param {*} args
 * @returns
 */
export function calcDate(date, method, args = { days: 0 }) {
  return new Date(moment(date)[method](args));
}
/**
 * Get Date Difference
 * @param {*} date1
 * @param {*} date2
 * @param {*} diffBy
 * @returns
 */
export function getDateDifference(date1, date2, diffBy = "days") {
  return date2.diff(date1, diffBy) + 1;
}
/**
 * UnixTime to hour minute
 * @param {*} unixTime
 * @returns
 */
export function unixTimeToHourMinute(date) {
  let isoDate = moment(date).toISOString();
  let split1 = isoDate.split("T");
  let split2 = split1[1].split(":");

  return split2[0] + ":" + split2[1];
}
/**
 * Month Difference
 * @param {*} d1
 * @param {*} d2
 * @returns
 */
export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth() + 1;
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}
/**
 * Get Float Value
 * @param {*} value
 * @returns
 */
export function getFloat(value) {
  const num =
    value === "" || value === undefined || value === null
      ? 0
      : parseFloat(value);
  return num;
}
/**
 * Check for Production Environment
 * @returns
 */
export function isPRODEnviroment() {
  return (
    process.env.REACT_APP_BUILD_TO === "PROD" ||
    process.env.REACT_APP_BUILD_TO === "QA"
  );
}
/**
 * Get Dev Environment Label
 * @returns
 *
 * [1]  -   For local env
 * [2]  -   For Training
 * [3]  -   For PROD & QA remove the tag and for others add Env tag
 */
export function getDevEnviromentLabel() { 
  // [1]
  if (!process.env.REACT_APP_BUILD_TO) return "- LOCAL";
  // [2] 
  if (process.env.REACT_APP_BUILD_TO === "TRAIN") return "TRAINING";
  //UAT
  if (process.env.REACT_APP_BUILD_TO === "UAT") return "UAT";
  //QA-PERF
  if (process.env.REACT_APP_BUILD_TO === "QA_PERF") return "QA_PERF";
  // UAT - HO
  if (process.env.REACT_APP_BUILD_TO === "PERF") return "PERFORMANCE";
  // [3]
  //MIRROR
  if (process.env.REACT_APP_BUILD_TO === "MIRROR") return "MIRROR";
  if (process.env.REACT_APP_BUILD_TO === "QA") return "- QA";
  return process.env.REACT_APP_BUILD_TO !== "PROD"
    ? `- ${process.env.REACT_APP_BUILD_TO}`
    : "";
}
/**
 * Filter Search Fields
 * @param {*} sFields
 * @returns
 */
export function filterSearchFields(sFields) {
  return sFields.map((item) => item);
}
/**
 * Date Validation
 * @param {*} date
 * @param {*} datas
 * @returns
 */
export function dateValidation(date, datas = []) {
  return !datas.some(
    (elem) =>
      new Date(elem.fromDate).getTime() <= new Date(date).getTime() &&
      new Date(elem.toDate).getTime() >= new Date(date).getTime()
  );
}
/**
 * Capitalize First Letter of the String
 * @param {*} string
 * @returns
 */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
//format as Nona White
export function capitalizeName(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

/**
 * Sort for number and string fields
 * @param {*} result
 * @param {*} sort
 * @param {*} orderBy
 * @returns
 *
 * [1]  -   Sort ascending
 * [2]  -   Sorting nested object (with two fields)
 * [3]  -   Sorting one field
 * [1]  -   Sort decending
 */
export function sortByOrderUnderScoreJS_numberString(result, sort, orderBy) {
  let field = orderBy.split(".");
  let sorter = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });
  // [1]
  if (sort === 1) {
    // [2]
    if (field.length > 1)
      result.sort(function (a, b) {
        return sorter.compare(a[field[0]][field[1]], b[field[0]][field[1]]);
      });
    else {
      // [3]
      result.sort(function (a, b) {
        return sorter.compare(a[field[0]], b[field[0]]);
      });
    }
  } else {
    // [4]
    // [2]
    if (field.length > 1)
      result.sort(function (a, b) {
        return sorter.compare(a[field[0]][field[1]], b[field[0]][field[1]]);
      });
    else {
      // [3]
      result.sort(function (a, b) {
        return sorter.compare(a[field[0]], b[field[0]]);
      });
    }
    result = result.reverse();
  }
  return result;
}
/**
 * Convert dates to locaTimeZone
 * @param {*} date
 * @returns
 */
export function convertDateWithTimeZone(UTCdate) {
  const formatDate = moment(UTCdate).local()._i;
  return formatDate;
}
/**
 * Table Search
 * @param {*} result
 * @param {*} sFields
 * @param {*} sValues
 * @returns
 *
 * [1]  -   Search nested object (with two fields)
 * [2]  -   Search as string if the field is NaN or common_id
 * [3]  -   Convert the field to string if the field is Number and search
 * [4]  -   Search one field
 */
export function tablesSearch(result, sFields, sValues) {
  sFields.forEach((sField, index) => {
    result = result.filter((elem) => {
      let field = sField.split(".");
      // [1]
      if (field.length > 1) {
        // [2]
        if (isNaN(elem[field[0]][field[1]]) || sField === "common_id") {
          return elem[field[0]][field[1]]
            .toLowerCase()
            .includes(sValues[index].toLowerCase());
        } else {
          // [3]
          return elem[field[0]][field[1]].toString().includes(sValues[index]);
        }
      } else {
        // [4]
        // [2]
        if (isNaN(elem[sField]) || sField === "common_id") {
          return elem[sField]
            .toLowerCase()
            .includes(sValues[index].toLowerCase());
        } else {
          // [3]
          return elem[sField].toString().includes(sValues[index]);
        }
      }
    });
  });
  return result;
}
/**
 * Get Time stamp for Notification
 * @param {*} date
 * @returns
 */
export function getTimeStampForNotification(date) {
  let timeSplit = date ? date.split(", ")[1] : "00:00:00";
  let dateCheck = date.includes("Today")
    ? `${moment().format("YYYY-MM-DD")}, ${timeSplit}`
    : date.includes("Yesterday")
    ? `${moment().subtract(1, "d").format("YYYY-MM-DD")}, ${timeSplit}`
    : date;

  let currDate = new Date().getDate();
  let createdAtDate = new Date(dateCheck).getDate();
  let createdAtHour = new Date(dateCheck).getHours();
  let createdAtMinute = new Date(dateCheck).getMinutes();
  let createdAtMonth = new Date(dateCheck).getMonth() + 1;
  let createdAtYear = new Date(dateCheck).getFullYear();
  if (createdAtDate === currDate) {
    return `Today, ${createdAtHour > 9 ? createdAtHour : "0" + createdAtHour}:${
      createdAtMinute > 9 ? createdAtMinute : "0" + createdAtMinute
    }`;
  } else if (createdAtDate === currDate - 1) {
    //12 hrs condition
    //return `Yesterday, ${createdAtHour > 12 ? (createdAtHour - 12) > 9 ? createdAtHour - 12 : '0' + (createdAtHour - 12) : createdAtHour > 9 ? createdAtHour : '0' + createdAtHour}:${createdAtMinute > 9 ? createdAtMinute : '0' + createdAtMinute} ${createdAtHour >= 12 ? 'PM' : 'AM'}`
    return `Yesterday, ${
      createdAtHour > 9 ? createdAtHour : "0" + createdAtHour
    }:${createdAtMinute > 9 ? createdAtMinute : "0" + createdAtMinute}`;
  } else {
    return `${createdAtMonth}/${createdAtDate}/${createdAtYear},  ${
      createdAtHour > 9 ? createdAtHour : "0" + createdAtHour
    }:${createdAtMinute > 9 ? createdAtMinute : "0" + createdAtMinute}`;
  }
}

export function focusHelper() {
  return {
    projectName:
      "Enter the name of your project.  If the project name has changed since you were funded or since your last report, provide the new name in the Project Update Section below.",
    HTFcont:
      "Enter the contract number of your HTF contract for this project.  If you have more than one contract number associated with a program, please submit a separate report for each contract number you have with HTF funds. Each report should only include the funds and units associated with those funds per contract.",
    certficationAddress:
      "List the address of each home purchased during the reporting period.",
    certficationOrgName:
      "The name of the organization providing the homeownership funds.  Include both the street address and mailing address, if different, in the address lines.",
    certContactName:
      "Enter the name of the primary contact person that HTF staff should go to when discussing this project, along with that person's telephone number, fax number and e-mail address.",
    certPrepName:
      "Enter the name of the person who will prepare the report, along with the person's telephone number, fax number, and e-mail address.",
    projStatusUpdate:
      "Include here any pertinent details regarding changes to the project and/or the organizations involved. This can include changes to the name of the project and/or the organization, specific reporting arrangements made with HTF staff, or changes to the structure or administration of the project/program.",
    noOfReportingYr:
      "Please indicate the number of loans generated during the reporting period. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfReportingYr:
      "Enter the record number for the loans that were newly generated during the reporting period. The record number should be a unique identifier in the Record Detail.",
    makingPayment:
      "Please enter the number of loans that had any payment during the reporting period. If there are any, the details will be entered in the Contract Activity tab.",
    noOfgrants:
      "Please indicate the number of grants awarded by the reporting organization during the reporting period.  Grants are defined as awards without expectation of repayment. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfgrants:
      "Enter the record number for the grants that were newly generated during the reporting period. The record number should be a unique identifier in the Record Detail.",
    noOfDeff:
      "Please indicate the number of active loans with deferred payments for the reporting period. Deferred loans are those whose principal and/or interest installments are postponed for a specified period of time. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfDeff:
      "Enter the record number for the loans that were deferred during the reporting period. The record number should be a unique identifier in the Record Detail.",
    loanSatisfied:
      "Please enter the number of loans that were paid off during the reporting period. If there are any, the details will be entered in the Contract Activity tab.",
    pleaseloanSatisfied:
      "Enter the record numbers for the loans that were satisfied during the reporting period. The record number should be a unique identifier in the Record Detail.",
    noOfDefaultLoan:
      "Please indicate the number of loans in default during the reporting period. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfDefaultLoan:
      "Enter the record number for the loans that were in default during the reporting period. The record number should be a unique identifier in the Record Detail.",
    noOfForeclosureLoan:
      "Please indicate the number of loans and/or grants the program has foreclosed on or is in the process of foreclosing on. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfForeclosureLoan:
      "Enter the record number for the loans and/or grants that were in foreclosure during the reporting period. The record number should be a unique identifier in the Record Detail.",
    totalAmtFund:
      "This should be the total amount of funds associated with the reported homeownership program over its lifetime. This includes funds awarded by all funding sources, funds in loans/grants, any unspent funds and any funds being applied to administrative costs",
    totalLoanReceivable:
      "This category should reflect the total dollar amount of all outstanding loans (however old) the program expects to be repaid.",
    housingTrust:
      "This category allows us to determine what percentage of your program is funded by the Housing Trust Fund. Please include all HTF funds received over the entire lifetime of the program, as of 12/31 of the reporting year.",
    valueOfPayment:
      "For the reporting period, the total dollar amount the organization received in re-payments.",
    noOfloansWithPayment:
      "Please enter the number of loans that had any payment during the reporting period. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfloansWithPayment:
      "Enter the record numbers for the loans that had payments during the reporting period. The record number should be a unique identifier in the Record Detail.",
    badDebt:
      "For the reporting period, reflect any debt that is delinquent and has been written off as uncollectible.",
    noOfbadDebt:
      "Please enter the number of loans that incurred the above mentioned bad debt during the reporting period. If there are any, the details will be entered in the Contract Activity tab.",
    pleasenoOfbadDebt:
      "Enter the record numbers for the loans that incurred bad debt during the reporting period. The record number should be a unique identifier in the Record Detail.",
    isRevolving: `Please check "Yes" or "No".`,
    annualAdminExpenses:
      "Total amount of funds used for administrative expenses over the reporting period",
    balanceFund:
      "If any funds remain in the revolving loan fund as of 12/31 of the reporting period, please report them in this field. Include any funds that have not been given to homeowners. This may include funds that the program has promised to homeowners or expects to use for homeowners, but has not been formally dispersed yet.",
    isContractDrawing: `Please check "Yes" or "No." Only check "Yes" if this is an active contract still drawing dollars.`,
    explainRevolvingLoan:
      "Explain any fund balances and the plans to disburse the funds.",
    extraorinaryCircum:
      "Enter brief, but complete descriptive information here pertaining to unusual situations or circumstances during the report period that have had an impact on the operations of the project, especially if these circumstances resulted in large number of defaults/foreclosures or your project not being able to meet the contractual obligations.",
    contractDrawingFund:
      "Enter brief, but complete descriptive information here detailing the particular uses to which HTF funds associated with this contract are currently being applied. ",
    loanNumber:
      "Please enter a unique identifier for the loan or grant used to track activity. If a second record is added to a previous record, please report that information in the second mortgage area. If a client sells a home and purchases another using HTF funds, please reflect a new record number with information for the new property.",
    loanOrgrant:
      "Please select Loan if the moneys are to be repaid to the organization and Grant if there is no expectation of repayment.",
    wasThisLoanFunded:
      "Yes, if funds from more than one HTF contract were used.",
    typeOfHome:
      "Please indicate what type of property is being purchased or rehabilitated",
    noOfBedroom:
      "The number of bedrooms in the property for which the money was loaned or granted.",
    homeAddress: "List address of home.",
    county: "List the County of the property.",
    houseHoldSize:
      "Enter total number of persons in the household. This is the total number of people who will be living on the property. This does not have to be related individuals.",
    race: "Please indicate the race of the head of household from the drop down menu.",
    Ethnicity:
      "Please indicate the Ethnicity of the head of household from the drop down menu.",
    populationCate:
      "Select the category that identifies the special needs population served.  This is particularly important if you were contracted to serve a particular special needs population.",
    HTFFunding: `Identify the type of funding activity used for the loan or grant. If "other" is selected explain the activity used.`,
    sweatEquity: `Select "Yes" if homebuyers are investing sweat equity instead of or in addition to a cash down payments.`,
    landTrust: `Select "Yes" if the property purchase is part of a Community Land Trust.`,
    dateOfPurchase: "Identify the date the property was purchased.",
    AMI: "Select the Area Median Income percentage to which this property was restricted.",
    maxAlloIncome:
      "This field is automatically calculated based on the county, selected AMI, and date of purchase using income limits already in the system.",
    annualgross:
      "Enter the household’s annual gross income as it has been verified through an income verification process.",
    purchasePrice: "List the purchase price of the home.",
    HTFLoanInterest:
      "Reflect the current interest rate, if any for the HTF loan. If no interest rate, leave as zero.",
    HTFAssistance:
      "What was the total payment made on the behalf of the homebuyer for a down payment using HTF Funds?",
    otherPublicAssist:
      "What was the total payment made on behalf of the homebuyer for a down payment using other Public funds? Please identify the amount and which public funder provided the assistance.",
    homeBuyerCashAssist:
      "What was the amount of cash that the homebuyer provided towards their down payment?",
    totalMarketValue: "Total market value of the home.",
    ownerPurchasePrice: "Total purchase price to homebuyer",
    totalRestrictiveValue: "Total restricted value of the home.",
    firstMortageAmount:
      "Total dollar amount of first mortgage provided with homeownership funds.",
    monthlyFirstMortage:
      "If monthly payments are the same each month for the reporting period, please enter that amount. If monthly payments change, then please take total paid for the year and divide by twelve to create an average monthly payment. This amount should include total payment to organization from homeowner, including any payments of the principal or interest.",
    secondMortage:
      "Total dollar amount of second mortgage provided with homeownership funds. If no second mortgage, please leave this field blank.",
    monthlySecondMortage:
      "If monthly payments are the same each month for the reporting period, please enter that amount. If monthly payments change, then please take total paid for the year and divide by twelve to create an average monthly payment. This amount should include total payment to organization from homeowner, including any payments of the principal or interest.",
    totalMonthlyMortage:
      "This field automatically totals the 1st and 2nd mortgage payments provided above.",
    totalPrincipleInterestTax:
      "Enter the sum of the principal, interest, taxes, insurance and utiltities the homeowner pays monthly.",
    affordabilityRatio:
      "This field automatically calculates based on the Actual income at time of purchase and the PITI entered.",
    homeOwnerResidenceStatus:
      "Identify the homeowners prior residence using the drop down list.",
    homeOwnerResidencePrior:
      "Enter the monthly housing cost of the homeowner prior to purchasing the home.",
    isPropertyForclosure: `Select "Yes" if the loan or grant has been foreclosed on or have had foreclosure procedures start during the reporting period.`,
    isHTFLoanStatisfied: `Select "Yes" if the loan paid off or closed during the reporting period?  This could inlcude pay offs of the full loan amount or determinng the loan or some amount of the loan is being written off.`,
    uniqueRecord:
      "Enter the unique ID that matches the Record Number on the Contract Activity page associated with this activity",
    resale:
      "Please input the number of resales generated during the reporting period. If no resale activity, input zero.",
    pleaseResale:
      "Enter the record number for the resale activity that was newly generated during the reporting period. The record number should be a unique identifier in the Record Detail.",
  };
}
export const NegativePrefixHandler = (val) => {
  if (val.toString().includes("-")) {
    return "$ -";
  } else {
    return "$";
  }
};
