import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import Grid from '@material-ui/core/Grid';

// Import Components
import TextArea from '../../../../components/TextArea/TextArea';
import NumberField from '../../../../components/NumberField/NumberField';
import EditableTable from '../../../../components/EditableTable/EditableTable';
import ErrorDisplay from '../../../../components/ErrorDisplay/ErrorDisplay';

// Helpers
import { getExpensesCategory } from '../../../../helpers/Table4Calc';
import { getFloat } from '../../../../helpers/global';

const fieldClass = {
    operatingAmt: 'txt-right',
    servicesAmt: 'txt-right',
};
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isReadOnly } = this.props;
        const isRequired = (data._id === 35 && (data.operatingAmt + data.servicesAmt) > 0) || (data._id === 39 && (data.operatingAmt + data.servicesAmt) > 0);
        let className = isReadOly || isReadOnly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`
        return (
            <TextArea
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOnly || isReadOly}
                validators={isRequired ? ['required'] : []}
                errorMessages={isRequired ? ['this field is required'] : []}
                multiline={true}
                inputProps={{ maxLength: 200 }}
            />
        )
    }
};
/**
 * EditableTextAmt Component
 * @class EditableTextAmt
 * @extends {Component}
 */
class EditableTextAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName, config: { isReadOly }, isReadOnly } = this.props;
        let className = isReadOly || isReadOnly ? `input--disabled textBox--bordered ${fieldClass[fieldName]}` : `textBox--bordered ${fieldClass[fieldName]}`

        return (
            <NumberField
                className={className}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={[]}
                errorMessages={[]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={isReadOnly || isReadOly}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};
/**
 * DisplayTextAmt Component
 * @class DisplayTextAmt
 * @extends {Component}
 */
class DisplayTextAmt extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <NumberField
                className={`no-border txt-right`}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                disabled={true}
                prefix={'$'}
                thousandSeparator={true}
            />
        )
    }
};

const componentMap = {
    operatingAmt: EditableTextAmt,
    servicesAmt: EditableTextAmt,
    preOperatingAmt: DisplayTextAmt,
    preServicesAmt: DisplayTextAmt,
    comment: EditableText
};
/**
 * Expenses Component
 * @class Expenses
 * @extends {Component}
 */
class Expenses extends Component {
    /**
     * Consstructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {}
    }
    /**
     * Cell Component On Change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        this.props.onChange('expenseCategory', fieldName, rowIndex, value);
    }
    /**
     * Display Amount
     * @param {*} value 
     * @returns 
     */
    displayAmount(value) {
        value = value === '' || value === null || value === undefined ? '0' : `${value}`;
        return (
            <NumberField
                value={value}
                prefix={'$'}
                thousandSeparator={true}
                displayType={'text'}
            />
        )
    }
    /**
     * Get Expense Total
     * @returns 
     */
    getExpenseTotal() {
        const { data, pageAccess, isDisabled } = this.props;
        const expense = data.expenseCategory.reduce((total, expense) => {
            return total + getFloat(expense.operatingAmt) + getFloat(expense.servicesAmt);
        }, 0)

        if (!isDisabled && expense <= 0 && pageAccess.indexOf('SAVE') > -1) {
            return {
                required: true,
                message: 'Expense should be Greater than 0'
            };
        }

        return { required: false, message: null };
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Calculation
     * [2]  -   Add Footer
     */
    render() {
        const { data, config, classes, isDisabled } = this.props;
        const reportYearData = data.reportYearData || {};
        const prevYearsData = data.previousExpenseCategory.reportYearData;
        const prevYrHeaders = prevYearsData.year ? [`${prevYearsData.year} Operating`, `${prevYearsData.year} Services`] : [];
        const prevFieldCols = prevYearsData.year ? ['preOperatingAmt', 'preServicesAmt'] : [];
        // [1]
        let { operatingAmt, servicesAmt, totalAmt, NOI, preOperatingAmt, preServicesAmt } = getExpensesCategory(data);
        let totAmt1 = totalAmt;
        operatingAmt = this.displayAmount(operatingAmt);
        servicesAmt = this.displayAmount(servicesAmt);
        totalAmt = this.displayAmount(totalAmt);
        NOI = this.displayAmount(NOI);
        preOperatingAmt = this.displayAmount(preOperatingAmt);
        preServicesAmt = this.displayAmount(preServicesAmt);



        // [2]
        const preExpenses = prevYearsData.year ? {
            'preOperatingAmt_footer': preOperatingAmt,
            'preServicesAmt_footer': preServicesAmt
        } : {};

        return (
                <Grid container spacing={3}>
                <Grid>
                    <div>
                        <ErrorDisplay
                            name="householdSize-error-display"
                            {...this.getExpenseTotal()}
                        />
                    </div>
                </Grid>
                < Grid item xs={12} sm={12}>
                    <EditableTable
                        headerCols={['', `${reportYearData.year} Operating`, `${reportYearData.year} Services`, ...prevYrHeaders, 'Comments']}
                        fieldCols={['name', 'operatingAmt', 'servicesAmt', ...prevFieldCols, 'comment']}
                        rowDatas={data.expenseCategory}
                        needTitle={false}
                        actions={[]}
                        componentMap={componentMap}
                        isReadOnly={isDisabled}
                        cellComponentOnChange={this.cellComponentOnChange}
                        config={{ ...config, classes, totalAmt: totAmt1 }}
                        tableClass={'fieldWidth12'}
                        footerDatas={[
                            {
                                'name_footer': 'Total:',
                                'operatingAmt_footer': operatingAmt,
                                'servicesAmt_footer': servicesAmt,
                                ...preExpenses
                            },
                            {
                                'name_footer': 'Total Expenses (Operating + Services):',
                                'operatingAmt_footer': totalAmt
                            },
                            {
                                'name_footer': 'NOI: Net Operating Income (Effective Gross Income-Total Expenses):',
                                'operatingAmt_footer': NOI
                            }
                        ]}
                    />
                </Grid>
            </Grid >
        )
    }
}
/** Props Type */
Expenses.propTypes = {
    classes: PropTypes.object.isRequired
}
/** Default props */
Expenses.defaultProps = {
    data: {},
    config: {},
    classes: {},
    pageAccess: []
}
/** Export Component */
export default Expenses;