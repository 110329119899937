import React from 'react';

// Imports Components
import DatePicker from '../../components/DatePicker/DatePicker';
import TextBox from '../../components/TextBox/TextBox';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import AutoCompleteMultiMUI from '../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import TextArea from '../../components/TextArea/TextArea';

/**
 * EffectiveDate Component
 * @class EffectiveDate
 * @extends {Component}
 */
export class EffectiveDate extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, date) {
        const { data } = this.props;
        this.props.handleChange("effectiveDate", { id: data._id, value: date });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { data, actions } = this.props;
        return (
            <DatePicker
                name={`ed_dp_${data.index}`}
                value={data.effectiveDate}
                handleChange={this.handleChange}
                margin="dense"
                validators={!data.disabled ? ['required'] : []}
                errorMessages={!data.disabled ? ['this field is required'] : []}
                disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                helperText={''}
            />
        )
    }
}
/**
 * DateUA Component
 * @class DateUA
 * @extends {Component}
 */
export class DateUA extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, date) {
        const { data } = this.props;
        this.props.handleChange("dateUA", { id: data._id, value: date });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { data, actions } = this.props;

        return (
            <DatePicker
                name={`ua_dp_${data.index}`}
                value={data.dateUA}
                handleChange={this.handleChange}
                margin="dense"
                validators={!data.disabled ? ['required'] : []}
                errorMessages={!data.disabled ? ['this field is required'] : []}
                disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                helperText={''}
            />
        )
    }
}
/**
 * BedRooms Component
 * @class BedRooms
 * @extends {Component}
 */
export class BedRooms extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, value) {
        const { data } = this.props;
        this.props.handleChange("bedroom_id", { id: data._id, value });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { data, actions, config } = this.props;
        let options = config.bedrooms.map(bd => { return { ...bd, value: bd.key, label: bd.desc } });
        return (
            <div className="autocompleteList no-margin" style={{ minWidth: '200px' }}>
                <AutoComplete
                    name={`dpd_br_${data.index}`}
                    placeholder=''
                    suggestions={options}
                    handleAutoCompolete={(selectedOption) => {
                        this.handleChange('', selectedOption.value);
                    }}
                    selectedValue={{ id: data.bedroom_id }}
                    disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                    validators={!data.disabled ? ['required'] : []}
                    errorMessages={!data.disabled ? ['this field is required'] : []}
                />
            </div>
        )
    }
};
/**
 * AllowanceSource Component
 * @class AllowanceSource
 * @extends {Component}
 */
export class AllowanceSource extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, value) {
        const { data } = this.props;
        this.props.handleChange("source_id", { id: data._id, value });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { config, actions, data } = this.props;
        // const { configuration, isReadOly } = this.props;
        let options = config.utilityAllowanceSource.map(bd => { return { ...bd, value: bd.key, label: bd.name } });
        return (
            <div className="autocompleteList no-margin" style={{ minWidth: '200px' }}>
                <AutoComplete
                    name={`dpd_als_${data.index}`}
                    placeholder=''
                    className="autocomplete-no-width"
                    suggestions={options}
                    handleAutoCompolete={(selectedOption) => {
                        this.handleChange('', selectedOption.value);
                    }}
                    selectedValue={{ id: data.source_id }}
                    disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                    validators={!data.disabled ? ['required'] : []}
                    errorMessages={!data.disabled ? ['this field is required'] : []}
                />
            </div>
        )
    }
};
/**
 * UtilityType Component
 * @class UtilityType
 * @extends {Component}
 */
export class UtilityType extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, value) {
        const { data } = this.props;
        this.props.handleChange("utilityType_id", { id: data._id, value });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { data, actions, config } = this.props;
        const raceIds = `${data.utilityType_id}` || '0';
        let strToArr = raceIds.split(',').map(s => {
            const key = parseInt(s, 10);
            const label = config.utilityAllowanceType.filter(r => r.key === key)[0] || { name: null };
            return {
                label: label.name,
                value: key,
                key
            }
        });
        strToArr = strToArr.filter(f => f.label !== null);
        return (
            <div className='autocompleteList no-margin' style={{ minWidth: '200px' }}>
                <AutoCompleteMultiMUI
                    name={`dpd_uttype_${data.index}`}
                    placeholder=''
                    suggestions={config.utilityAllowanceType}
                    handleAutoCompolete={(selectedOption) => {
                        this.handleChange("utilityType_id", selectedOption.map(s => s.key).join(","));
                    }}
                    selectedValue={strToArr}
                    disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                    validators={!data.disabled ? ['required'] : []}
                    errorMessages={!data.disabled ? ['this field is required'] : []}
                    needSelectAll
                />
            </div>
        )
    }
};
/**
 * Amount Component
 * @class Amount
 * @extends {Component}
 */
export class Amount extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, value) {
        const { data } = this.props;
        this.props.handleChange("amount", { id: data._id, value });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { data, actions, config } = this.props;

        return (
            <TextBox
                disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                name={`txt_amt_${data.index}`}
                value={data.amount}
                type="number"
                className={`${config.classes.textField} textbox-no-margin`}
                handleChange={this.handleChange}
                margin="dense"
                validators={!data.disabled ? ['required'] : []}
                errorMessages={!data.disabled ? ['this field is required'] : []}
            />
        )
    }
};
/**
 * Comment Component
 * @class Comment
 * @extends {Component}
 */
export class Comment extends React.Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange(name, value) {
        const { data } = this.props;
        this.props.handleChange("comments", { id: data._id, value });
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        const { data, actions, config } = this.props;

        return (
            <TextArea 
                disabled={actions.indexOf('UPDATE') < 0 || data.disabled}
                name={`txt_cmt_${data.index}`}
                value={data.comments}
                className={`${config.classes.textField} adjust-height`  }
                handleChange={this.handleChange}
                margin="dense"
                multiline={true}               
            />
        )
    }
};