import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import FundersList from '../../Funders/FundersList';
import FundersEdit from '../../Funders/FundersEdit';

/**
 * Archive Funders container
 * @class Funders
 * @extends Component
 */
class Funders extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
    }
    /**
     * Bind Html to DOM 
     */
    render() {

        const { match, projectId } = this.props;

        return (
            <Switch >
                <Route exact path={match.url} render={props => (
                    <FundersList {...props}
                        match={match}
                        projectId={projectId}
                        page={'archives'} />
                )}
                />
                <Route exact path={`${match.url}/:funderId`} render={props => (
                    <FundersEdit {...props}
                        match={match}
                        projectId={projectId}
                        page={'archives'} />
                )}
                />
            </Switch>
        );
    }
}

/**
 * Export Component
 */
export default Funders;