import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { history, getUserType, getOrgType, convertDateWithTimeZone } from '../../../helpers'
import { focusHelper } from '../../../helpers/global';

// Import Components
import TextBox from '../../../components/TextBox/TextBoxToolTip';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import ContentWrapper from "../../../components/ContentWrapper/ContentWrapper";
import TableComponent from '../../../components/Table/TableComponent'
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextArea from '../../../components/TextArea/TextArea';
import DatePicker from '../../../components/DatePicker/DatePicker';
import GroupCheckBox from '../../../components/GroupCheckBox/GroupCheckBox';


// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
import FieldValidationConstants from "../../../helpers/fieldValidationConstants"

// Import Constants
import { configurationConstants } from '../../../helpers/appConstants';

// import Actions
import { reportActivityActions } from '../../../actions/reportActivity';
import { confActions } from '../../../actions/configuration';
import HomePurchse from '../../ContractActivity/component/homePurchase';
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog';
import { alertActions } from '../../../actions';
import MultipleTextArea from '../../../components/MultipleTextAreaHO/MultipleTextArea';

let homePurchasedData = {
    _id: '',
    year: '',
    homePurchasedAddress: '',
    homePurchasedCity: '',
    homePurchasedCounty: '',
};

class CertificationForm extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            projectName: '',
            htfContract: 0,
            homePurchased: [],
            organizationName: '',
            contactName: '',
            contactTitle: '',
            projectContractorPhone: 0,
            projectContractorFax: 0,
            projectContractorEmail: '',
            projectContractorAddress: '',
            projectContractorCity: '',
            projectContractorState: '',
            projectContractorZipcode: '',
            preparerName: '',
            preparerTitle: '',
            reportContactPhone: 0,
            reportContactFax: 0,
            reportContactEmail: '',
            reportContactAddress: '',
            reportContactCity: '',
            reportContactState: '',
            reportContactZipcode: '',
            othersDescribe: '',
            otherModelSelect: false,
            sweatEquityModel: [],
            nonSweatEquityModel: [],
            projectStatusUpdate: '',
            certAuthorizedAccepted: false,
            certAuthorizedSign: '',
            certAuthorizedDate: new Date(),
            equityTextArea: true,
            deleteAddress: false,
            projectNames: false,
            focusVisible: false,
            isDelete: false,
            certificationFormID: null,
            signed: null,
            submissionStatus: [],
            setfocus: false,
            setSweatEquityFocus: false,
            setNonSweatEquityFocus: false,
            sweatEquityTypeData: [],
            sweatEquityType: [],
            nonSweatEquityTypeData: [],
            nonSweatEquityType: [],
            isFormNotSaved: false
        };
        this.userRole = getUserType();
        this.onwerName = "";
        this.orgType = getOrgType();


    }
    /**
     * Component did mount event
     * @param {*} event 
     */

    componentDidMount() {
        const { location: { state } } = this.props;
        const reportYear_id = state.pageData && state.pageData.reportYear_id ? state.pageData.reportYear_id : state.reportYear_id
        this.props.getByIdCertificationForm(reportYear_id);
        this.props.getConfiguration(configurationConstants.state);
        this.props.getConfiguration(configurationConstants.counties);
        this.props.getConfiguration(configurationConstants.cities);
        this.props.getConfiguration(configurationConstants.sweatEquity);
        this.props.getConfiguration(configurationConstants.nonSweatEquity);
        this.props.getReportYear(reportYear_id)

        //set data for sweat and nosweat select
        const sweatTypeData = [
            {
                name: "",
                values: ["Owner occupied rehab", "Community Land trust", "RD 523 grandee new constructions", "RD 523 grantee rehab", "Non-523 Self-Help"]
            }
        ];

        const nonSweatTypeData = [
            {
                name: "",
                values: ["Owner occupied rehab", "Community Land trust", "New Construction", "Acquisition/Rehab", "Lease - to - Purchase", "Not a seller of home", "Down payment assistance"]
            }
        ]
        this.setState({ sweatEquityTypeData: sweatTypeData, nonSweatEquityTypeData: nonSweatTypeData })
    }

    /**
     * Component will receive props
     * @param {*} event 
     */

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { certificationForm } = nextProps;
        const { certificationFormID, isFormNotSaved } = this.state
        if (certificationForm && certificationFormID !== certificationForm[0]._id && !isFormNotSaved) {
            let certFormData = certificationForm[0];

            //Push sweatmodel label to sweattype
            let sweattype = [];
            if (certFormData.sweatEquityModel.length > 0) {
                certFormData.sweatEquityModel.forEach(item => {
                    sweattype.push(item.label)
                })
            }

            //push Non-523 Self-Help is any one of the category selected

            if (sweattype.indexOf("New Construction") > -1 || sweattype.indexOf("Acquisition/Rehab") > -1 || sweattype.indexOf("Mutual Self-Help") > -1 || sweattype.indexOf("Individual Self-Help") > -1) {
                sweattype.push("Non-523 Self-Help")
            }

            //Push nonSweatModel label to nonswettype
            let nonsweattype = [];
            if (certFormData.nonSweatEquityModel.length > 0) {
                certFormData.nonSweatEquityModel.forEach(item => {
                    nonsweattype.push(item.label)
                })
            }

            this.setState({
                certificationFormID: certFormData._id,
                projectName: certFormData.projectName,
                htfContract: certFormData.contractNumber,
                homePurchased: certFormData.address || [],
                // homePurchased: certFormData.address && certFormData.address.length > 0 ? certFormData.address : this.orgType === orgTypesConstants.CONTRACTOR || this.orgType === orgTypesConstants.FUNDER ? null : [homePurchasedData],
                organizationName: certFormData.orgName,
                contactName: certFormData.contractorAuthName,
                contactTitle: certFormData.contractorTitle,
                projectContractorPhone: certFormData.contractorPhone,
                projectContractorEmail: certFormData.contractorEmail,
                projectContractorAddress: certFormData.contractorAddress,
                projectContractorCity: certFormData.contractorCity,
                projectContractorState: certFormData.contractorState,
                projectContractorZipcode: certFormData.contractorZipcode,
                preparerName: certFormData.preparerName,
                preparerTitle: certFormData.preparerTitle,
                reportContactPhone: certFormData.preparerPhone,
                reportContactEmail: certFormData.preparerEmail,
                reportContactAddress: certFormData.preparerAddress,
                // reportContactAddress2: certFormData,
                reportContactCity: certFormData.preparerCity,
                reportContactState: certFormData.preparerState,
                reportContactZipcode: certFormData.preparerZipcode,
                projectStatusUpdate: certFormData.projectStatusComments,
                sweatEquityModel: certFormData.sweatEquityModel,
                nonSweatEquityModel: certFormData.nonSweatEquityModel,
                otherModelSelect: certFormData.otherModelSelect,
                equityTextArea: this.state.otherModelSelect,
                othersDescribe: certFormData.otherModelComments,
                certAuthorizedAccepted: certFormData.certAuthorizedAccepted,
                certAuthorizedSign: certFormData.contractorAuthName,
                certAuthorizedDate: certFormData.certAuthorizedDate === null ? new Date() : convertDateWithTimeZone(certFormData.certAuthorizedDate),
                submissionStatus: certFormData.submissionStatus,
                sweatEquityType: sweattype,
                nonSweatEquityType: nonsweattype
            });
        }
    }
    /**
     * Handle Change
     * @param {*} event 
     */

    handleChange = (name, value) => {
        const { otherModelSelect, certAuthorizedAccepted, equityTextArea } = this.state;
        if (name === 'otherModelSelect' && value === 0) {
            this.setState({ otherModelSelect: !otherModelSelect, equityTextArea: !equityTextArea, othersDescribe: '', isFormNotSaved: true });
        } else if (name === 'otherModelSelect' && value !== 0) {
            this.setState({ otherModelSelect: !otherModelSelect, equityTextArea: !equityTextArea, isFormNotSaved: true });
        } else if (name === 'certAuthorizedAccepted') {
            this.setState({ certAuthorizedAccepted: !certAuthorizedAccepted, signed: "signed", isFormNotSaved: true })
        }
        this.setState({
            [name]: value,
            isFormNotSaved: true
        })
    }
    /**** 
     *  Method when org got changed
     */
    handleAutoCompleteSweat = (selectedOption) => {
        this.setState({
            sweatEquityModel: selectedOption,
            isFormNotSaved: true
            // nonSweatEquityModel:[""]
        })
    }

    /**
     * Handle Non Sweat Equity Change
     * @param {*} event 
     */

    handleAutoCompleteNonSweat = (selectedOption) => {
        this.setState({
            nonSweatEquityModel: selectedOption,
            isFormNotSaved: true
        })
    }

    /**
     * Handle autocomplete home change
     * @param {*} event 
     */
    handleAutoCompleteHome = (colName, index, value) => {
        let { homePurchased } = this.state;
        homePurchased[index][colName] = value;
        this.setState({ homePurchased: homePurchased, isFormNotSaved: true });
    };

    /**
     * Handle home Change
     * @param {*} colName index
     */
    handleChangeHome = (colName, index) => (name, value) => {
        let { homePurchased } = this.state;
        homePurchased[index][colName] = value;
        this.setState({ homePurchased: homePurchased, isFormNotSaved: true });
    };

    /**
     * Handle Submit
     */
    handleSubmit = (e) => {
        this.setState({ isFormNotSaved: true })
        this._saveForm();
    };

    /**
     * Handle Change
     * @param {*} ele
     */
    chechInView = (ele) => {
        var { focusField } = this.state
        if (focusField) {
            const { top: parentTop, bottom: parentBottom } = ele.getBoundingClientRect()
            const { top: childTop } = focusField.getBoundingClientRect()
            this.setState({ focusVisible: childTop + 25 > parentTop && childTop < parentBottom, parentAlignTop: parentTop, parentAlignBottom: parentBottom, childTop })
        }
    };

    // equity validator
    equityValidator = () => {
        if (this.state.sweatEquityType === null && this.state.nonSweatEquityType === null && !this.state.otherModelSelect) {
            return (['required'])
        } else if (this.state.nonSweatEquityType !== null && !this.state.otherModelSelect) {
            return ([])
        } else if (this.state.otherModelSelect === 1) {
            return ([])
        }
    }
    // equity validator
    nonEquityValidator = () => {
        if (this.state.sweatEquityType === null && this.state.nonSweatEquityType === null && !this.state.otherModelSelect) {
            return (["required"])
        } else if (this.state.sweatEquityType !== null && !this.state.otherModelSelect) {
            return ([])
        } else if (this.state.otherModelSelect === 1) {
            return ([])
        }
    }

    /**
     * Handle Tab change
     */
    handleTabChange() {
        const { isFormNotSaved } = this.state;
        return isFormNotSaved
    }
    _saveForm() {
        const { certificationForm, pageAccess, sweatEquity, nonSweatEquity } = this.props;

        let sweatTypeFilter = this.state.sweatEquityType;

        //[1]
        //Remove Non-523 Self-Help from selected list
        if (sweatTypeFilter.indexOf("Non-523 Self-Help") > -1) {
            let index = sweatTypeFilter.indexOf("Non-523 Self-Help");
            sweatTypeFilter.splice(index, 1)
        }

        //[2]
        //set sweatEquityModel
        let selectedSweat = [];
        sweatTypeFilter.forEach(item => {
            selectedSweat.push(sweatEquity.filter(s => s.label === item)[0])
        })

        //set nonSweatEquityModel
        let selectedNonSweat = [];
        this.state.nonSweatEquityType.forEach(item => {
            selectedNonSweat.push(nonSweatEquity.filter(s => s.label === item)[0])
        })

        this.setState({ nonSweatEquityModel: selectedNonSweat, sweatEquityModel: selectedSweat, isFormNotSaved: false })


        const { sweatEquityModel, homePurchased, nonSweatEquityModel, certAuthorizedAccepted, projectStatusUpdate, otherModelSelect, othersDescribe, certAuthorizedSign, certAuthorizedDate
        } = this.state
        let certFormData = certificationForm[0];
        let isReadOnly = pageAccess.indexOf('PREP_DATA') === -1;
        let contractorSign = pageAccess.indexOf('CERT_SIGN') === -1;


        // set up api form submission datas
        let generalData = {
            homePurchased: homePurchased,
            sweatEquityModel: sweatEquityModel,
            nonSweatEquityModel: nonSweatEquityModel,
            projectStatusUpdate: projectStatusUpdate,
            otherModelSelect: otherModelSelect,
            othersDescribe: othersDescribe,
            certAuthorizedAccepted: certAuthorizedAccepted,
            certAuthorizedSign: certAuthorizedSign,
            certAuthorizedDate: certAuthorizedDate,
            _id: certFormData._id
        };

        if ((sweatEquityModel && sweatEquityModel.length === 0 && nonSweatEquityModel && nonSweatEquityModel.length === 0 && !otherModelSelect) && !isReadOnly) {
            this.props.showErrorAlert("Please enter atleast one field in program details")
            setTimeout(() => {
                this.props.clearAlert()
            }, 3000);
        }
        else if (!contractorSign && !certAuthorizedAccepted) {
            this.props.showErrorAlert("Please sign the certification form")
            setTimeout(() => {
                this.props.clearAlert()
            }, 3000);

        }
        else {
            if (certFormData) {
                this.props.updateCertificationForm(generalData);
            } else {
                this.props.createCertificationForm(generalData);
            }

            this.props.getByIdCertificationForm(certFormData._id);
        }
    }

    getTooltipProps = (fieldName) => {
        return {
            focusVisible: this.state.focusVisible && this.state.focusFieldName === fieldName,
            onFocus: (name) => this.setState({ focusFieldName: name, focusVisible: true }),
            onBlur: () => this.setState({ focusFieldName: "", focusVisible: false })                                        // validators={['required', 'isStringLength250', 'validAddress']}
        }
    }

    setSweatType = (value) => {

        //remove all selected in non 523 if unchecked
        if (value.indexOf("Non-523 Self-Help") === -1) {
            if (value.indexOf("New Construction") > -1) {
                let index = value.indexOf("New Construction");
                value.splice(index, 1)
            }
            if (value.indexOf("Acquisition/Rehab") > -1) {
                let index = value.indexOf("Acquisition/Rehab");
                value.splice(index, 1)
            }
            if (value.indexOf("Mutual Self-Help") > -1) {
                let index = value.indexOf("Mutual Self-Help");
                value.splice(index, 1)
            }
            if (value.indexOf("Individual Self-Help") > -1) {
                let index = value.indexOf("Individual Self-Help");
                value.splice(index, 1)
            }
        }
        this.setState({ sweatEquityType: value, isFormNotSaved: true })
    }

    setNonSweatType = (value) => {
        this.setState({ nonSweatEquityType: value, isFormNotSaved: true })
    }
    /**
    * Bind HTML to DOM
    */

    render() {
        const { classes, pageAccess, cities, states, counties, location: { pathname }, tableHistory } = this.props;
        let tabUrl = pathname.split('/');
        const year = tabUrl.length > 0 ? tabUrl[5] : 0;
        let isReadOnly = pageAccess.indexOf('PREP_DATA') === -1;
        let contractorSign = pageAccess.indexOf('CERT_SIGN') === -1;

        //let isCommerceFunder = (getUserRole()==='funderWithOA'|| getUserRole()==='funderWithOAandHO'|| getUserRole()=== 'funderWithOAandBoth')&&getUserOrgId()==2;
        let {
            projectName,
            htfContract,
            homePurchased,
            organizationName,
            contactName,
            contactTitle,
            projectContractorPhone,
            projectContractorEmail,
            projectContractorAddress,
            projectContractorCity,
            projectContractorState,
            projectContractorZipcode,
            preparerName,
            preparerTitle,
            reportContactPhone,
            reportContactEmail,
            reportContactAddress,
            reportContactCity,
            reportContactState,
            reportContactZipcode,
            projectStatusUpdate,
            othersDescribe,
            otherModelSelect,
            sweatEquityModel,
            nonSweatEquityModel,
            certAuthorizedDate,
            certAuthorizedSign,
            certAuthorizedAccepted,
            parentAlignTop,
            parentAlignBottom,
            childTop,
            focusVisible,
            submissionStatus,
            sweatEquityType,
            sweatEquityTypeData
        } = this.state;
        var currentYear = new Date().getFullYear();
        var sweatTitle = []
        var nonSweatTitle = []
        sweatEquityModel.forEach((item, index) => { sweatTitle.push(item.label) })
        nonSweatEquityModel.forEach((item, index) => { nonSweatTitle.push(item.label) })
        sweatTitle = sweatTitle.join(",")
        nonSweatTitle = nonSweatTitle.join(",")

        //push Non-523 Self-Help if any one of the category selected

        if ((sweatEquityType.indexOf("New Construction") > -1 || sweatEquityType.indexOf("Acquisition/Rehab") > -1 || sweatEquityType.indexOf("Mutual Self-Help") > -1
            || sweatEquityType.indexOf("Individual Self-Help") > -1) && sweatEquityType.indexOf("Non-523 Self-Help") === -1) {
            sweatEquityType.push("Non-523 Self-Help")
        }

        //push Non 523 items if checked
        if (sweatEquityType.indexOf("Non-523 Self-Help") > -1 && sweatEquityTypeData.length === 1) {
            sweatEquityTypeData.push({
                name: "Non-523 Self-Help",
                values: ["New Construction", "Acquisition/Rehab", "Mutual Self-Help", "Individual Self-Help"]
            })
        }
        //remove Non 523 items if not checked
        if (sweatEquityType.indexOf("Non-523 Self-Help") === -1 && sweatEquityTypeData.length === 2) {
            sweatEquityTypeData.pop()
        }

        //tooltip for sweatequity
        let toolTipContent = sweatTitle.split(',')
        let selectedNon523 = toolTipContent.filter(item => item === "New Construction" || item === "Acquisition/Rehab" || item === "Mutual Self-Help" || item === "Individual Self-Help");
        let selectedSweatitems = toolTipContent.filter(item => item === "Owner occupied rehab" || item === "Community Land trust" || item === "RD 523 grandee new constructions" || item === "RD 523 grantee rehab");
        if (selectedNon523.length > 0) {
            selectedNon523[0] = " Non-523 Self-Help: " + selectedNon523[0]
        }
        let combinedSelection = selectedNon523.length > 0 ? selectedSweatitems.concat(selectedNon523) : selectedSweatitems;
        let textForToolTip = combinedSelection.join(",")


        return (
            <div className={classes.activityContainer}>
                <ContentWrapper
                    ref={(divElement) => { this.container = divElement }}
                    classes={classes}
                    // pageAccess={['DOWNLOAD']}
                    title={''}
                    needTitleBar={false}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    titleBg={'white'}
                    pageName="reports"
                    onScrollChange={(ele) => { this.chechInView(ele) }}
                >
                    {/* Project Information */}
                    <div className={classes.rootEdit} >
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => console.log(errors)} >

                            <Grid container spacing={3}>

                                {/* Project Information */}
                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Project Information'}</h4>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container direction="row" justifyContent="start" spacing={5}>

                                        <Grid item xs={12} sm={6} align="left">
                                            {/* <Grid item xs={12} sm={10}> */}
                                            <TextBox
                                                name="projectName"
                                                label="Project Name"
                                                value={projectName}
                                                // validators={['required']}
                                                // errorMessages={['this field is required']}
                                                tooltipOnHover={false}
                                                toolTipTitle={focusHelper().projectName}
                                                {...this.getTooltipProps("projectName")}                                        // errorMessages={['this field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN250, FieldValidationConstants.LOAN_ACTIVITY.validAddress]}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={true}
                                            />
                                            {/* </Grid> */}
                                        </Grid>
                                        <Grid item xs={12} sm={6} align="right">
                                            {/* <Grid item xs={12} sm={10}> */}
                                            <TextBox
                                                toolTipClassName="certificationtopTextBox"
                                                name="htfContract"
                                                label="HTF Contract"
                                                value={htfContract}
                                                tooltipOnHover={false}
                                                // validators={['required']}
                                                // errorMessages={['this field is required']}
                                                toolTipTitle={focusHelper().HTFcont}
                                                {...this.getTooltipProps("htfContract")}                                        // errorMessages={['this field is required', FieldValidationConstants.CERTIFICATION.isNumeric]}
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={true}
                                            />
                                            {/* </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* Project Information */}


                                {/* Address of home purchased  */}
                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Address of home purchased(From contract execution through the end of reporting period)'}</h4>
                                </Grid>
                                <Grid item xs={12} className="certfAddressCity">
                                    <HomePurchse
                                        disabledProperty={year < currentYear ? true : false}
                                        homePurchased={homePurchased}
                                        cities={cities}
                                        counties={counties}
                                        classes={classes}
                                        handleAutoCompleteHome={this.handleAutoCompleteHome}
                                        handleChangeHome={this.handleChangeHome}
                                        showErrorAlt={() => {
                                            this.props.showErrorAlert("Address are allowed up-to 500 rows")
                                            setTimeout(() => {
                                                this.props.clearAlert()
                                            }, 3000);
                                        }}
                                        year={year}
                                        onAdd={() => {
                                            const newhomePurchased = [
                                                ...homePurchased,
                                                {
                                                    ...homePurchasedData,
                                                    _id: Math.max(...this.state.homePurchased.map(elt => elt._id)) + 1,
                                                    year: year
                                                }
                                            ];
                                            this.setState({ "homePurchased": newhomePurchased, isFormNotSaved: true });
                                        }}
                                        disabled={isReadOnly}
                                        onDelete={(index) => {
                                            if (!this.state.isDelete) {
                                                this.setState({ deleteAddress: true, addressId: index });
                                            }
                                        }}
                                        parentAlignTop={parentAlignTop}
                                        parentAlignBottom={parentAlignBottom}
                                        focusEle={(ele) => { this.setState({ focusField: ele }) }}
                                        childTop={childTop}
                                        focusVisible={focusVisible}
                                    />
                                </Grid>
                                <DeleteDialog
                                    open={this.state.deleteAddress !== false}
                                    content={"Are you sure you want to delete this record?"}
                                    handleDeleteOnCloseAction={() => { this.setState({ deleteAddress: false }) }}
                                    handleDeleteAction={(index) => {
                                        this.props.showAlert("Address Deleted Successfully")
                                        setTimeout(() => {
                                            this.props.clearAlert()
                                        }, 3000);
                                        homePurchased.splice(this.state.addressId, 1)
                                        this.setState({ deleteAddress: false, homePurchased, isFormNotSaved: true })
                                    }}
                                />
                                {/* Project contractor */}
                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Project contractor(HTF funds recipient)'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="organizationName"
                                            label="Organization Name"
                                            tooltipOnHover={false}
                                            toolTipTitle={focusHelper().certficationOrgName}
                                            value={organizationName}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="contactName"
                                            label="Contact Name"
                                            value={contactName}
                                            tooltipOnHover={false}
                                            toolTipTitle={focusHelper().certContactName}
                                            // validators={['required', 'isStringLength50']}
                                            // errorMessages={['this field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN50]}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="contactTitle"
                                            label="Contact Title"
                                            value={contactTitle}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="projectContractorPhone"
                                            label="Phone"
                                            value={projectContractorPhone}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="projectContractorEmail"
                                            label="Email"
                                            value={projectContractorEmail}
                                            // validators={['required']}
                                            // errorMessages={['this field is required']}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="projectContractorAddress"
                                            label="Address"
                                            tooltipOnHover={false}
                                            toolTipTitle={focusHelper().certficationOrgName}
                                            value={projectContractorAddress}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="City"
                                            name="projectContractorCity"
                                            suggestions={cities}
                                            handleAutoCompolete={this.handleChange}
                                            selectedValue={{ value: projectContractorCity, id: projectContractorCity }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="State"
                                            name="projectContractorState"
                                            suggestions={states}
                                            handleAutoCompolete={this.handleChange}
                                            selectedValue={{ value: projectContractorState, id: projectContractorState }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="projectContractorZipcode"
                                            label="Zipcode"
                                            value={projectContractorZipcode}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Report Contact'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="preparerName"
                                            label="Preparer Name"
                                            value={preparerName}
                                            tooltipOnHover={false}
                                            toolTipTitle={focusHelper().certPrepName}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="preparerTitle"
                                            label="Preparer Title"
                                            value={preparerTitle}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="reportContactPhone"
                                            label="Phone"
                                            value={reportContactPhone}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="reportContactEmail"
                                            label="Email"
                                            value={reportContactEmail}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="reportContactAddress"
                                            label="Address"
                                            value={reportContactAddress}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="City"
                                            name="reportContactCity"
                                            suggestions={cities}
                                            handleAutoCompolete={this.handleChange}
                                            selectedValue={{ value: reportContactCity, id: reportContactCity }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} className={"autoCompleteTop"}>
                                        <AutoComplete
                                            placeholder="State"
                                            name="reportContactState"
                                            suggestions={states}
                                            handleAutoCompolete={this.handleChange}
                                            selectedValue={{ value: reportContactState, id: reportContactState }}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="reportContactZipcode"
                                            label="Zipcode"
                                            value={reportContactZipcode}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Project Status Update'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Typography className="required-header" variant="body1">Use the space below to explain any pertinent details regarding changes to project and / or organization</Typography>
                                    <MultipleTextArea
                                        toolTipClassName="textAreaTooltip"
                                        fullWidth={true}
                                        name="projectStatusUpdate"
                                        value={projectStatusUpdate}
                                        tooltipOnHover={!isReadOnly}
                                        {...this.getTooltipProps("projectStatusUpdate")}                                        // errorMessages={['this field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN250, FieldValidationConstants.LOAN_ACTIVITY.validAddress]}
                                        toolTipTitle={focusHelper().projStatusUpdate}
                                        validators={['required', 'isMaxLen1000']}
                                        errorMessages={['this field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN1000]}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOnly}
                                        multiline={true}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Program Details'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1">What is your homeownership model? (check all that apply)</Typography>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <div class="containers" >
                                            <span class={isReadOnly === true && sweatTitle.length > 0 ? "certtooltiptextHover" : "tooltiptextNone"}>{textForToolTip}</span>

                                            {/* <AutoCompleteMultiMUI
                                                name="sweatEquityModel"
                                                placeholder='Sweat - Equity Model'
                                                suggestions={sweatEquity}
                                                handleAutoCompolete={(selectedOption) => { this.handleAutoCompleteSweat(selectedOption) }}
                                                selectedValue={sweatEquityModel}
                                                // onFocus={() => this.setState({ setSweatEquityFocus: true })}
                                                // onBlur={()=> this.setState({ setSweatEquityFocus: false })}
                                                validators={this.equityValidator()}
                                                errorMessages={['this field is required']}
                                                disabled={isReadOnly}
                                            /> */}
                                            <GroupCheckBox
                                                label="Sweat - Equity Model"
                                                handleChange={(e) => this.setSweatType(e.target.value)}
                                                //className={"required"}
                                                value={sweatEquityType}
                                                options={sweatEquityTypeData}
                                                disabled={isReadOnly}
                                                validators={this.equityValidator()}
                                                errorMessages={['this field is required']}
                                                setCountValue={true}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <div class="containers">
                                            <span class={isReadOnly === true && nonSweatTitle.length > 0 ? "certtooltiptextHover" : "tooltiptextNone"}>{nonSweatTitle}</span>

                                            {/* <AutoCompleteMultiMUI
                                                placeholder="Non Sweat - Equity Model"
                                                name="nonSweatEquityModel"
                                                suggestions={nonSweatEquity}
                                                {...this.getTooltipProps("nonSweatEquityModel")}
                                                handleAutoCompolete={(selectedOption) => { this.handleAutoCompleteNonSweat(selectedOption) }}
                                                selectedValue={nonSweatEquityModel}
                                                disabled={isReadOnly}
                                                validators={this.nonEquityValidator()}
                                                errorMessages={['this field is required']}
                                            // onFocus={() => this.setState({ setNonSweatEquityFocus: true })}
                                            // onBlur={()=> this.setState({ setNonSweatEquityFocus: false })}

                                            /> */}

                                            <GroupCheckBox
                                                label="Non Sweat - Equity Model"
                                                handleChange={(e) => this.setNonSweatType(e.target.value)}
                                                //className={"required"}
                                                value={this.state.nonSweatEquityType}
                                                options={this.state.nonSweatEquityTypeData}
                                                disabled={isReadOnly}
                                                validators={this.nonEquityValidator()}
                                                errorMessages={['this field is required']}
                                                setCountValue={true}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={otherModelSelect}
                                                onChange={(evt) => { this.handleChange('otherModelSelect', evt.target.checked ? 1 : 0) }}
                                                value={otherModelSelect}
                                                color="primary"
                                                disabled={isReadOnly}
                                            />
                                        }
                                        label="Others ( Please Describe )"
                                    />
                                    <TextArea
                                        name="othersDescribe"
                                        value={othersDescribe}
                                        validators={otherModelSelect ? ['required', 'isMaxLen1000'] : []}
                                        errorMessages={['this field is required', FieldValidationConstants.LOAN_ACTIVITY.STR_LEN1000]}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={isReadOnly ? isReadOnly : otherModelSelect ? false : true}
                                        multiline={true}
                                        needCustomToolTip={true}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h4 className="section_title certificationSecTitle">{'Certification'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={certAuthorizedAccepted}
                                                onChange={(evt) => { this.handleChange('certAuthorizedAccepted', evt.target.checked ? 1 : 0) }}
                                                value={certAuthorizedAccepted}
                                                color="primary"
                                                disabled={contractorSign}
                                            />
                                        }
                                        label="To the best of my knowledge, all information and data stated here in, as well as in any attachements, are true and accurate. The person certifying the report should be same person who is accountable to the HTF contract. Property management companies typically should not certify these reports."
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="certAuthorizedSign"
                                            label="Authorized Representative"
                                            value={!contractorSign || certAuthorizedAccepted ? certAuthorizedSign : ""}
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} className={"disabledGrey"}>
                                        <DatePicker
                                            name="certAuthorizedDate"
                                            value={certAuthorizedAccepted === 1 || certAuthorizedAccepted === true ? certAuthorizedDate : ""}
                                            label="Date"
                                            className={classes.textField}
                                            handleChange={this.handleChange}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Program Details */}

                            </Grid>
                            <Grid item xs={12} sm={12} style={{ marginTop: '35px' }}>
                                <React.Fragment>
                                    <h4 className="section_title certificationSecTitle">{'Report History'}</h4>
                                    <TableComponent
                                        rowsPerPage={submissionStatus.length}
                                        count={submissionStatus.length}
                                        header={tableHistory.headerCols}
                                        field={tableHistory.fieldCols}
                                        search={[]}
                                        isLoading={this.props.isLoading}
                                        data={submissionStatus}
                                        currentPage={'reportActivity'}
                                        paginationVisible={false}
                                        searchVisible={false}
                                        style={{ position: 'relative' }}
                                        noDataErrorMessage={"No Records Found"}
                                    />
                                </React.Fragment>
                            </Grid>

                            {(!isReadOnly || !contractorSign) &&
                                <SaveCancel
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }} />}

                        </ValidatorForm>
                    </div>
                </ContentWrapper>
            </div>
        );
    }

}
/**
 * Bind Component Property Types
 */
CertificationForm.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

/**
 * Default props
 */
CertificationForm.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    page: 'certificationForm',
    handleFormSaveActionClick: () => { }
}

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        certificationForm: state.reportActivity.certificationForm.data,
        tableHistory: state.reportActivity.tableHistory,
        states: state.configuration.configurations[configurationConstants.state] || [],
        counties: state.configuration.configurations[configurationConstants.counties] || [],
        cities: state.configuration.configurations[configurationConstants.cities] || [],
        sweatEquity: state.configuration.configurations[configurationConstants.sweatEquity] || [],
        nonSweatEquity: state.configuration.configurations[configurationConstants.nonSweatEquity] || [],
        pageAccess: state.pageAccess['certificationForm'] || [],
        isLoading: state.reportActivity.isLoading
    }
};

/**
 * Maps props to state
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByIdCertificationForm: (reportYear_id) => dispatch(reportActivityActions.getByIdCertificationForm(reportYear_id)),
        createCertificationForm: (generalData) => dispatch(reportActivityActions.createCertificationForm(generalData)),
        updateCertificationForm: (generalData) => dispatch(reportActivityActions.updateCertificationForm(generalData)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getReportYear: (reportYear_id) => dispatch(reportActivityActions.getReportYear(reportYear_id)),
        showAlert: (message) => dispatch(alertActions.success(message)),
        showErrorAlert: (message) => dispatch(alertActions.error(message)),
        clearAlert: (message) => dispatch(alertActions.clear())

    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(CertificationForm));