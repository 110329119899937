import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Fab';

// import components
import AccordinMenu from '../../components/AccordionMenu/AccordionMenu'
import ComplianceIssue from './ExploreNeedAction/ComplianceIssue';
import PMNeedsAction from './ExploreNeedAction/PMNeedsAction';
import ToolTip from '../../components/ToolTip/ToolTip';
import PageLoader from '../../components/PageLoader/PageLoader';

// import actions
import { dashboardActions } from '../../actions/dashboard';
import { alertActions } from '../../actions';

// import helper
import { getUserType, getUserOrgId, globalVariable } from '../../helpers';

// Styles
import dashboardStyle from '../../assets/jss/containers/dashboard';

/**
 *ExploreNeedsAction
 *
 * @class ExploreNeedsAction
 * @extends {Component}
 */
class ExploreNeedsAction extends Component {
    /**
     *Creates an instance of ExploreNeedsAction.
     * @param {*} props
     * @memberof ExploreNeedsAction
     */
    constructor(props) {
        super(props);
        this.state = {
            selectedAccordionKeys: [],
            selectedInnerAccordionKeys: [],
            view: true,
            isMenuOpen: true,
            triggerResize: false,
            initialList: false,
            selectAll: false
        }

        this.userRole = getUserType();
        this.org_id = getUserOrgId();
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        const { userRole, org_id } = this;
        const { dashboardCompliance } = this.props;
        let { selectedAccordionKeys, selectedInnerAccordionKeys, initialList, selectAll } = this.state;
        // const data = dashboard.data.data ? dashboard.data.data : [];
        // let selectedSites = dashboardCompliance ? dashboardCompliance.selectedSites : [];
        let selectedSites = [];
        this.props.getProjectsList(userRole, org_id, dashboardCompliance.year, (data) => {
            if (selectedSites === undefined) {
                selectedSites = [];
            }
            if (userRole !== 'Funder') {
                if (selectedSites.length > 0) {
                    selectAll = false
                    data.forEach(item => {
                        item.site.forEach(siteItem => {
                            if (selectedSites.indexOf(siteItem.siteid) !== -1) {
                                if (!selectedAccordionKeys.some(elem => elem._id === item._id)) {
                                    selectedAccordionKeys.push(item);
                                }
                                if (!selectedInnerAccordionKeys.some(elem => elem.siteid === siteItem.siteid)) {
                                    selectedInnerAccordionKeys.push(siteItem);
                                }
                            }
                        });
                    });
                } else {
                    data.forEach(item => {
                        item.site.forEach(siteItem => {
                            selectedSites.push(siteItem.siteid)
                        });
                    });
                }
                this.props.getComplianceIssueSummary(dashboardCompliance.year, userRole, org_id, selectedSites);
                this.props.getPMProjectList(1, globalVariable.tabTableRowSize, 'projectName', 'asc', '', '', selectedSites, dashboardCompliance.year, userRole, org_id);
            } else {
                selectAll = false;
                data.forEach(item => {
                    item.site.forEach(siteItem => {
                        if (selectedSites.indexOf(siteItem.siteid) !== -1) {
                            if (!selectedAccordionKeys.some(elem => elem._id === item._id)) {
                                selectedAccordionKeys.push(item);
                            }
                            if (!selectedInnerAccordionKeys.some(elem => elem.siteid === siteItem.siteid)) {
                                selectedInnerAccordionKeys.push(siteItem);
                            }
                        }
                    });
                });
            }
        });
        this.props.getComplianceIssueSummary(dashboardCompliance.year, userRole, org_id, selectedSites);
        this.props.getPMProjectList(1, globalVariable.tabTableRowSize, 'projectName', 'asc', '', '', selectedSites, dashboardCompliance.year, userRole, org_id);
        this.setState({ selectedAccordionKeys: selectedAccordionKeys, selectedInnerAccordionKeys: selectedInnerAccordionKeys, selectAll, initialList });
    }
    /**
     * Handle Selected group key from Menu
     * @param {string} key
     */
    handleSeletedAccordion = (key) => {
        const { dashboard } = this.props;
        const data = dashboard.data.data ? dashboard.data.data : [];
        let selectAll = false;
        let selectedInnerAccordionKeysData = [];
        let { selectedAccordionKeys, selectedInnerAccordionKeys, initialList } = this.state;
        selectedAccordionKeys = initialList ? data : selectedAccordionKeys;
        if (initialList) {
            for (var item of selectedAccordionKeys) {
                selectedInnerAccordionKeysData = [...selectedInnerAccordionKeysData, ...item.site]
            }
        }
        selectedInnerAccordionKeys = initialList ? selectedInnerAccordionKeysData : selectedInnerAccordionKeys;
        let index = selectedAccordionKeys.findIndex(elem => elem._id === key._id);
        if (index > -1) {
            selectedAccordionKeys = selectedAccordionKeys.filter(value => value._id !== key._id);
            selectedInnerAccordionKeys = selectedInnerAccordionKeys.filter(item => item.projectid !== key.project.projectid)
        } else {
            selectedAccordionKeys.push(key);
            data.forEach(item => {
                if (item === key)
                    selectedInnerAccordionKeys = selectedInnerAccordionKeys.concat(item.site)
            });
        }
        if (data.length === selectedAccordionKeys.length) {
            selectAll = true
        }
        this.setState({ selectedAccordionKeys: selectedAccordionKeys, selectedInnerAccordionKeys: selectedInnerAccordionKeys, initialList: false, selectAll });
    }
    /**
     * Handle selected Fields of group from Menu
     * @param {Object} item
     * @param {Boolean} status
     */
    handleSeletedInnerAccordion = (item, status) => {
        const { dashboard } = this.props;
        let selectAll = false;
        const data = dashboard.data.data ? dashboard.data.data : []
        let { selectedAccordionKeys, selectedInnerAccordionKeys, initialList } = this.state;
        let updatedSelectesSites = [];
        let totalSelectedInnerAccordionKeys = [];
        data.forEach(item => {
            totalSelectedInnerAccordionKeys = [...totalSelectedInnerAccordionKeys, ...item.site]
        });
        if (initialList) {
            selectedAccordionKeys = data;
            selectedInnerAccordionKeys = totalSelectedInnerAccordionKeys;
        }
        if (!status) {
            updatedSelectesSites = selectedInnerAccordionKeys.filter(e => e.siteid !== item.siteid);
        } else {
            updatedSelectesSites = [...selectedInnerAccordionKeys, item]
        }
        const isAllRemoved = updatedSelectesSites.filter(p => p.projectid === item.projectid).length === 0;
        if (status && selectedAccordionKeys.filter(f => f.projectid === item.projectid).length === 0) {
            const d = data.filter(i => i.project.projectid === item.projectid)[0];
            selectedAccordionKeys = [...selectedAccordionKeys, d]
        }
        selectedAccordionKeys = isAllRemoved ? selectedAccordionKeys.filter(f => f._id !== item.projectid) : selectedAccordionKeys
        if (totalSelectedInnerAccordionKeys.length === updatedSelectesSites.length) {
            selectAll = true;
        }
        this.setState({
            selectedAccordionKeys,
            selectedInnerAccordionKeys: updatedSelectesSites,
            selectAll,
            initialList: false
        });
    }
    /**
     * Handle Bar Chart View
     */
    handleChartView = () => {
        this.setState({
            view: true
        })
    }
    /**
     * Handle Table View
     */
    handleTableView = () => {
        this.setState({
            view: false
        })
    }
    /**
     * Handle Preview
     */
    handlePreview = () => {
        const { selectedInnerAccordionKeys, selectAll } = this.state;
        const { dashboardCompliance, dashboard } = this.props;
        let selectedSites = [];
        const { userRole, org_id } = this;
        if (selectAll) {
            dashboard.data.data.forEach(item => {
                item.site.forEach(elem => {
                    selectedSites.push(elem.siteid);
                })
            })
        } else {
            selectedInnerAccordionKeys.forEach(item => {
                selectedSites.push(item.siteid);
            });
        }
        if (selectedSites.length > 0) {
            this.props.getComplianceIssueSummary(dashboardCompliance.year, userRole, org_id, selectedSites);
            this.props.getPMProjectList(1, globalVariable.tabTableRowSize, 'projectName', 'asc', '', '', selectedSites, dashboardCompliance.year, userRole, org_id);
        } else {
            this.props.showErrorAlert('Select atleast one project / site')
            setTimeout(() => {
                this.props.clearErrorAlert()
            }, 3000)
        }
    }
    /**
     * Handle Click
     */
    handleClick = () => {
        const { isMenuOpen } = this.state;
        this.setState({
            isMenuOpen: !isMenuOpen
        }, () => {
            this.setState({ triggerResize: !this.state.triggerResize });
        })
    }
    /**
     * Hanlde select all
     */
    handleSeletedAll = () => {
        let { selectAll } = this.state;
        const { dashboard } = this.props;
        const data = dashboard.data.data ? dashboard.data.data : [];

        this.setState({
            selectAll: !selectAll
        });

        if (!selectAll) {
            let totalSelectedInnerAccordionKeys = [];
            data.forEach(item => {
                totalSelectedInnerAccordionKeys = [...totalSelectedInnerAccordionKeys, ...item.site]
            });
            this.setState({
                initialList: false,
                selectedAccordionKeys: data,
                selectedInnerAccordionKeys: totalSelectedInnerAccordionKeys
            });
        } else {
            this.setState({
                initialList: false,
                selectedAccordionKeys: [],
                selectedInnerAccordionKeys: []
            })
        }
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof ExploreNeedsAction
     */
    render() {

        let { selectedAccordionKeys, selectedInnerAccordionKeys, view, isMenuOpen, triggerResize, initialList, selectAll } = this.state;
        let chartContentWidth = isMenuOpen ? 9 : 12
        const { dashboard, classes, dashboardPMList, dashboardCompliance } = this.props;
        let data = dashboard.data && dashboard.data.data ? dashboard.data.data : [];
        data.map(elem => {
            elem.accordionSummary = elem.project;
            elem.accordionSummary.label = elem.project.projectName
            elem.accordionDetails = elem.site;
            elem.accordionDetails.map(detail => {
                detail.label = detail.siteName
                return detail
            })
            return elem
        })

        if (initialList || selectAll) {
            selectedAccordionKeys = data;
            let selectedInnerAccordionKeysData = [];
            selectedAccordionKeys.forEach(item => {
                selectedInnerAccordionKeysData = [...selectedInnerAccordionKeysData, ...item.site]
            });
            selectedInnerAccordionKeys = selectedInnerAccordionKeysData;
        }

        let totalIssues = dashboardPMList.data && dashboardPMList.data.totalIssues ? dashboardPMList.data.totalIssues : 0;
        let totalRecords = dashboardPMList.data && dashboardPMList.data.totalRecords ? dashboardPMList.data.totalRecords : 0; return (
            <div>
                <Grid container className="borderNeedsAction">
                    {isMenuOpen && <Grid item xs={12} sm={3} className="borderRightAccordion">
                        <AccordinMenu
                            needHeader={true}
                            accordionData={data}
                            accordionKeys={data}
                            selectedAccordionKeys={selectedAccordionKeys}
                            selectedInnerAccordionKeys={selectedInnerAccordionKeys}
                            selectAll={selectAll}
                            handleLazyLoading={(search) => { this.handleLazyLoading(search) }}
                            handleSeletedAccordion={(key) => this.handleSeletedAccordion(key)}
                            handleSeletedInnerAccordion={(item, status) => this.handleSeletedInnerAccordion(item, status)}
                            handlePreview={() => { this.handlePreview() }}
                            handleSeletedAll={() => { this.handleSeletedAll() }}
                        />
                    </Grid>}
                    <Grid item xs={12} sm={chartContentWidth} className={classes.sliderposition}>
                        <div className={classes.leftSliderIcon}>
                            {
                                !isMenuOpen ? <div className={classes.rightSliderIconContainer} onClick={this.handleClick}><i class="fa fa-chevron-right flexIcon" aria-hidden="true"></i></div>
                                    : <div className={classes.leftSliderIconContainer} onClick={this.handleClick}><i class="fa fa-chevron-left flexIcon" aria-hidden="true" ></i></div>
                            }
                        </div>
                        <div className="flexForIcon">
                            <div className="flexForTotalValue">
                                {!view &&
                                    <React.Fragment>
                                        <div className="paddingTotalValue">Total records <b>{totalRecords}</b></div>
                                        <div>Total issues <b>{totalIssues}</b></div>
                                    </React.Fragment>
                                }
                            </div>
                            <div className="iconNeedsAction">
                                {
                                    view ? <div><ToolTip title="Chart View" placement="bottom">
                                        <Button variant="extended" size="small" color="default" aria-label="Table View" className="iconBgColor" onClick={this.handleChartView}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.215" height="15.282" viewBox="0 0 18.215 15.282">
                                                <g id="bar-graph-white" transform="translate(0 -0.91)">
                                                    <path id="Path_29" data-name="Path 29" d="M1.067,15.293V.91H0V16.192H18.215v-.9Z" transform="translate(0 0)" fill="#fff" />
                                                    <path id="Path_30" data-name="Path 30" d="M68.857,136.8H72.1v9.149h-3.24Z" transform="translate(-66.601 -131.823)" fill="#fff" />
                                                    <path id="Path_31" data-name="Path 31" d="M206.571,67.947h3.24V79.158h-3.24Z" transform="translate(-199.083 -65.029)" fill="#fff" />
                                                    <path id="Path_32" data-name="Path 32" d="M344.286,206.571h3.24v7.059h-3.24Z" transform="translate(-331.567 -199.501)" fill="#fff" />
                                                </g>
                                            </svg>
                                        </Button>
                                    </ToolTip></div> : <div><ToolTip title="Chart View" placement="bottom">
                                        <Button variant="extended" size="small" color="default" aria-label="Table View" className="iconBgColorInactive" onClick={this.handleChartView}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18.215" height="15.282" viewBox="0 0 18.215 15.282">
                                                <g id="bar-graph-grey" transform="translate(0 -0.91)">
                                                    <path id="Path_29" data-name="Path 29" d="M1.067,15.293V.91H0V16.192H18.215v-.9Z" transform="translate(0 0)" fill="#ccc" />
                                                    <path id="Path_30" data-name="Path 30" d="M68.857,136.8H72.1v9.149h-3.24Z" transform="translate(-66.601 -131.823)" fill="#ccc" />
                                                    <path id="Path_31" data-name="Path 31" d="M206.571,67.947h3.24V79.158h-3.24Z" transform="translate(-199.083 -65.029)" fill="#ccc" />
                                                    <path id="Path_32" data-name="Path 32" d="M344.286,206.571h3.24v7.059h-3.24Z" transform="translate(-331.567 -199.501)" fill="#ccc" />
                                                </g>
                                            </svg>
                                        </Button>
                                    </ToolTip></div>
                                }
                                {
                                    !view ? <div><ToolTip title="Table View" placement="bottom">
                                        <Button variant="contained" size="small" color="default" aria-label="Table View" className="iconBgColor" onClick={this.handleTableView}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.17" height="17.067" viewBox="0 0 20.17 17.067">
                                                <g id="table-grid-white" transform="translate(0 -36.545)">
                                                    <path id="Path_28" data-name="Path 28" d="M19.6,37.115a1.867,1.867,0,0,0-1.37-.57H1.939a1.868,1.868,0,0,0-1.37.57A1.868,1.868,0,0,0,0,38.484V51.672a1.868,1.868,0,0,0,.57,1.37,1.867,1.867,0,0,0,1.37.57H18.23a1.945,1.945,0,0,0,1.939-1.939V38.484A1.868,1.868,0,0,0,19.6,37.115ZM6.206,51.672a.373.373,0,0,1-.388.388H1.939a.373.373,0,0,1-.388-.388V49.345a.374.374,0,0,1,.388-.388H5.818a.374.374,0,0,1,.388.388v2.327Zm0-4.654a.374.374,0,0,1-.388.388H1.939a.373.373,0,0,1-.388-.388V44.69a.373.373,0,0,1,.388-.388H5.818a.373.373,0,0,1,.388.388v2.327Zm0-4.655a.374.374,0,0,1-.388.388H1.939a.373.373,0,0,1-.388-.388V40.036a.373.373,0,0,1,.388-.388H5.818a.373.373,0,0,1,.388.388v2.327Zm6.206,9.309a.373.373,0,0,1-.388.388H8.145a.373.373,0,0,1-.388-.388V49.345a.374.374,0,0,1,.388-.388h3.879a.373.373,0,0,1,.388.388v2.327h0Zm0-4.654a.373.373,0,0,1-.388.388H8.145a.373.373,0,0,1-.388-.388V44.69a.373.373,0,0,1,.388-.388h3.879a.373.373,0,0,1,.388.388v2.327Zm0-4.655a.373.373,0,0,1-.388.388H8.145a.373.373,0,0,1-.388-.388V40.036a.373.373,0,0,1,.388-.388h3.879a.373.373,0,0,1,.388.388v2.327Zm6.206,9.309a.372.372,0,0,1-.388.388H14.352a.372.372,0,0,1-.388-.388V49.345a.373.373,0,0,1,.388-.388H18.23a.373.373,0,0,1,.388.388Zm0-4.654a.373.373,0,0,1-.388.388H14.352a.373.373,0,0,1-.388-.388V44.69a.373.373,0,0,1,.388-.388H18.23a.373.373,0,0,1,.388.388Zm0-4.655a.373.373,0,0,1-.388.388H14.352a.373.373,0,0,1-.388-.388V40.036a.373.373,0,0,1,.388-.388H18.23a.373.373,0,0,1,.388.388Z" transform="translate(0)" fill="#fff" />
                                                </g>
                                            </svg>
                                        </Button>
                                    </ToolTip></div> : <div><ToolTip title="Table View" placement="bottom">
                                        <Button variant="contained" size="small" color="default" aria-label="Table View" className="iconBgColorInactive" onClick={this.handleTableView}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.17" height="17.067" viewBox="0 0 20.17 17.067">
                                                <g id="table-grid-grey" transform="translate(0 -36.545)">
                                                    <path id="Path_28" data-name="Path 28" d="M19.6,37.115a1.867,1.867,0,0,0-1.37-.57H1.939a1.868,1.868,0,0,0-1.37.57A1.868,1.868,0,0,0,0,38.484V51.672a1.868,1.868,0,0,0,.57,1.37,1.867,1.867,0,0,0,1.37.57H18.23a1.945,1.945,0,0,0,1.939-1.939V38.484A1.868,1.868,0,0,0,19.6,37.115ZM6.206,51.672a.373.373,0,0,1-.388.388H1.939a.373.373,0,0,1-.388-.388V49.345a.374.374,0,0,1,.388-.388H5.818a.374.374,0,0,1,.388.388v2.327Zm0-4.654a.374.374,0,0,1-.388.388H1.939a.373.373,0,0,1-.388-.388V44.69a.373.373,0,0,1,.388-.388H5.818a.373.373,0,0,1,.388.388v2.327Zm0-4.655a.374.374,0,0,1-.388.388H1.939a.373.373,0,0,1-.388-.388V40.036a.373.373,0,0,1,.388-.388H5.818a.373.373,0,0,1,.388.388v2.327Zm6.206,9.309a.373.373,0,0,1-.388.388H8.145a.373.373,0,0,1-.388-.388V49.345a.374.374,0,0,1,.388-.388h3.879a.373.373,0,0,1,.388.388v2.327h0Zm0-4.654a.373.373,0,0,1-.388.388H8.145a.373.373,0,0,1-.388-.388V44.69a.373.373,0,0,1,.388-.388h3.879a.373.373,0,0,1,.388.388v2.327Zm0-4.655a.373.373,0,0,1-.388.388H8.145a.373.373,0,0,1-.388-.388V40.036a.373.373,0,0,1,.388-.388h3.879a.373.373,0,0,1,.388.388v2.327Zm6.206,9.309a.372.372,0,0,1-.388.388H14.352a.372.372,0,0,1-.388-.388V49.345a.373.373,0,0,1,.388-.388H18.23a.373.373,0,0,1,.388.388Zm0-4.654a.373.373,0,0,1-.388.388H14.352a.373.373,0,0,1-.388-.388V44.69a.373.373,0,0,1,.388-.388H18.23a.373.373,0,0,1,.388.388Zm0-4.655a.373.373,0,0,1-.388.388H14.352a.373.373,0,0,1-.388-.388V40.036a.373.373,0,0,1,.388-.388H18.23a.373.373,0,0,1,.388.388Z" transform="translate(0)" fill="#ccc" />
                                                </g>
                                            </svg>
                                        </Button>
                                    </ToolTip></div>
                                }
                            </div>
                        </div>
                        {
                            view ?
                                <Grid item xs={12} sm={12} className="needsActionPadding">
                                    {
                                        selectedInnerAccordionKeys.length > 0 && dashboardCompliance.data.barChartData && dashboardCompliance.data.barChartData.length > 0
                                            ?
                                            <ComplianceIssue
                                                triggerResize={triggerResize} />
                                            :
                                            <div className='nocontent-inside'>
                                                Please select atleast one project / site to view the chart
                                            </div>
                                    }

                                </Grid>
                                :
                                <Grid item xs={12} sm={12} className="needsActionPadding">
                                    {
                                        selectedInnerAccordionKeys.length > 0
                                            ?
                                            <PMNeedsAction />
                                            :
                                            <div className='nocontent-inside'>
                                                Please select atleast one project / site to view the table
                                            </div>
                                    }
                                </Grid>
                        }
                    </Grid>
                </Grid>
                <PageLoader show={dashboardCompliance.isLoading || dashboard.data.isLoading || dashboardPMList.data.isLoading} />
            </div>
        )
    }
}

// prop types
ExploreNeedsAction.propTypes = {
    classes: PropTypes.object.isRequired,
};

/**
 * Default Props
 */
ExploreNeedsAction.defaultProps = {
    classes: {}
};
/**
 *  Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        dashboard: state.dashboard.projectList,
        dashboardPMList: state.dashboard.dashboard,
        dashboardCompliance: state.dashboard.complianceNeedsAction
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getProjectsList: (userRole, org_id, year, callBack) => dispatch(dashboardActions.getProjectsList(userRole, org_id, year, callBack)),
        getComplianceIssueSummary: (year, userRole, org_id, selectedSites) => dispatch(dashboardActions.getComplianceIssueSummary(year, userRole, org_id, selectedSites)),
        getPMProjectList: (skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id) => dispatch(dashboardActions.getPMProjectList(skip, limit, orderBy, order, sFields, sValues, selectedSites, reportYear, userRole, org_id)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearErrorAlert: () => dispatch(alertActions.clear())
    }
};
// export ExploreNeedsAction
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(ExploreNeedsAction));
