import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import Table1Log from "./XmlLogsLists";
import Table1LogView from "./Table1View";
import Table1LogNew from "./XmlLogsEmail";
import UABulkUpload from '../UtilityAllowance/UABulkUpload';

/**
 * ProjectsRouter Container
 * @class ProjectsRouter
 * @extends {Component}
 */
class Table1LogRouter extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <Table1Log match={props.match} page='xmlimportlogs' {...props} />
                }} />
                <Route exact path={`${match.url}/email`} render={(props) => {
                    return <Table1LogNew match={props.match} page='email' {...props} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <Table1LogNew {...props} />
                }} />
                <Route path={`${match.url}/:table1-listId`} render={(props) => {
                    return <Table1LogView {...this.props} projectId={props.match.params.project_id} />
                }} />

            </Switch>

        );
    }
}
/**
 * Bind Component Property Types
 */
 Table1LogRouter.propTypes = {
    classes: PropTypes.object,
};


/**
 * Export Component
 */
export default Table1LogRouter;
