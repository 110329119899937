import React, { Component } from 'react';
import { connect } from 'react-redux';

// Material UI
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Typography, Grid, withStyles, Select, MenuItem, FormControl, FormControlLabel, Checkbox, Menu } from '@material-ui/core';

//import images
import Caution from '../../../assets/img/alertCaution.svg'

//import components
import AlertDialog from '../../../components/AlertDialog/AlertDialog'
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import PageActions from '../../../components/PageActions/PageActions';
import TableComponent from '../../../components/Table/TableComponent';

// helpers
import { globalVariable, history, filterSearchFields } from '../../../helpers';
import { getChildCardHeight } from '../../../helpers/global';

// Actions
import { searchActions } from '../../../actions/search';
import { maintenanceActions } from '../../../actions/maintenance';
import { alertActions, pageAccessActions } from '../../../actions';

// Style
import pageStyle from '../../../assets/jss/containers/common';

/**
 * VerifyData container
 * @class VerifyData
 * @extends {Component}
 */
class VerifyData extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            selectedImport: 'IncomeLimit',
            ischecked: false,
            alertPopup: {
                open: false,
            },
            openDownload: false,
            openReUpload: false,
            anchorEl: null
        }
    }
    /**
     * UNSAFE_componentWillMount
     */
    UNSAFE_componentWillMount() {
        // this.props.maintanance();
        this.props.getAccess('incomerent', {})
    }
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        const { alertPopup } = this.state;
        this.setState({ alertPopup: { ...alertPopup, open: false } })
        this.props.startCron();
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.getList({
            sFields: [],
            sValues: []
        }, this.state.selectedImport, 1, Math.ceil((getChildCardHeight() - 375) / 30), 'county', 'asc')
    }
    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Component Will Ummount
     */
    componentWillUnmount() {
        this.props.searchActions({ sFields: [], sValues: [] });
    }
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { verifyData } = this.props;
        const { page } = verifyData;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.getList({
            sFields: [],
            sValues: []
        }, this.state.selectedImport, page.skip, page.limit, page.orderBy, page.order);
    };
    /**
     * Handle Table Pagination On Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { verifyData, searchData } = this.props;
        const { page } = verifyData;
        this.getList(searchData, this.state.selectedImport, skip, limit, page.orderBy, page.order);
    };
    /**
     * Handle Table Sort Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { verifyData, searchData } = this.props;
        const { page } = verifyData;
        this.getList(searchData, this.state.selectedImport, page.skip, page.limit, orderBy, order);
    };
    /**
     * Handle Table Search Input Box Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { verifyData, searchData } = this.props;
        const { page } = verifyData;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.getList({ sFields, sValues }, this.state.selectedImport, page.skip, page.limit, page.orderBy, page.order);
    }
    /**
     * Get Limits List
     * @param {*} data 
     * @param {*} limitType 
     * @param {*} skip 
     * @param {*} limit 
     * @param {*} orderBy 
     * @param {*} order 
     */
    getList = (data, limitType, skip, limit, orderBy, order) => {
        this.props.getVerifyData({
            ...data,
            fields: data.sFields,
            values: data.sValues
        }, limitType, skip, limit, orderBy, order);
    }
    /**
     * Handle Template Download
     * @param {*} type 
     */
    handleTemplateDownload = (type) => {
        const { maintenance } = this.props;
        this.props.downloadTemplates(maintenance._id, type);
        setTimeout(() => {
            this.props.clearAlerts()
        }, 3000)
    }
    /**
     *render HTML
     */
    render() {
        const { classes, verifyData, searchData, pageAccess, maintenance, location: { pathname } } = this.props;
        const { selectedImport, ischecked, alertPopup, openReUpload, anchorEl } = this.state;
        const { fieldFilters } = verifyData.page;
        const { sFields, sValues } = searchData;
        return (
            <React.Fragment>
                <Grid container spacing={3} align="center">
                    <Grid item sm="2">
                        <FormControl variant="outlined" className={classes.formControl}>
                            {/* <InputLabel id="demo-simple-select-label">Type</InputLabel> */}
                            <Select
                                value={selectedImport}
                                // autoWidth
                                onChange={(event) => {
                                    const value = event.target.value
                                    this.setState({ selectedImport: value })
                                    this.getList({ sFields: [], sValues: [] }, value, 0, globalVariable.tabTableRowSize, 'county', 'asc')
                                }}
                            >
                                <MenuItem value={'IncomeLimit'}>Income Limit</MenuItem>
                                <MenuItem value={'RentLimit'}>Rent Limt</MenuItem>
                                <MenuItem value={'WhatLimit'}>What Limit</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm="10">
                        <PageActions
                            actions={pageAccess}
                            iconsColor='primary'
                            handleEditButtonActionClick={(event) => this.setState({ anchorEl: event.currentTarget })}
                            handleReUploadButtonActionClick={() => { this.setState({ openReUpload: true }) }}
                            handleVerifyButtonActionClick={() => { history.push('/projects') }}
                            handleClearFilterActionClick={() => {
                                history.replace(`${pathname}#clear`);
                            }}
                        />
                    </Grid>
                    <Grid item sm="12" style={{ padding: 'unset' }}>
                        <TableComponent
                            className="verifyData_Table"
                            page={verifyData.page.skip}
                            rowsPerPage={verifyData.page.limit}
                            count={verifyData.page.count}
                            header={fieldFilters.headerCols}
                            field={fieldFilters.fieldCols}
                            search={fieldFilters.searchCols}
                            order={verifyData.page.order}
                            orderBy={verifyData.page.orderBy}
                            isLoading={verifyData.isLoading}
                            data={verifyData.data}
                            onChangePageTable={this.onChangePageTable}
                            onSortRequest={this.onSortRequest}
                            onInputChangeRequest={this.onInputChangeRequest}
                            sFields={filterSearchFields(sFields)}
                            sValues={sValues}
                            noDataErrorMessage="No Records Found"
                            paginationVisible={true}
                        />
                    </Grid>
                    {
                        pageAccess.indexOf('MAINTENANCE') > -1 &&
                        <Grid item sm="12" className="alignSelfCenter textCenter">
                            <FormControlLabel
                                style={{ marginLeft: 0, marginRight: 0 }}
                                control={<Checkbox color="primary" checked={ischecked} onChange={() => { this.setState({ ischecked: !ischecked }) }} name="ischecked" />}
                                label="I have checked the data"
                            />
                            <SaveCancel
                                saveText="Proceed to Run"
                                justifyContent="center"
                                disableSave={!ischecked}
                                handleSaveButtonClick={() => { this.setState({ alertPopup: { ...this.state.alertPopup, open: true } }) }}
                                handleCancelButtonClick={() => {
                                    this.setState({ openReUpload: true });
                                }}
                            />
                        </Grid>
                    }
                </Grid>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    {
                        maintenance && maintenance.files && maintenance.files.length > 0 &&
                        <React.Fragment>
                            {maintenance.files.some(elem => elem === 'IncomeLimit') && <MenuItem onClick={() => this.handleTemplateDownload('IncomeLimit')}>Income Limit</MenuItem>}
                            {maintenance.files.some(elem => elem === 'RentLimit') && <MenuItem onClick={() => this.handleTemplateDownload('RentLimit')}>Rent Limit</MenuItem>}
                            {maintenance.files.some(elem => elem === 'WhatLimit') && <MenuItem onClick={() => this.handleTemplateDownload('WhatLimit')}>What Limit</MenuItem>}
                        </React.Fragment>
                    }
                </Menu>
                <AlertDialog
                    open={alertPopup.open}
                    title=""
                    onClose={() => { this.setState({ alertPopup: { ...alertPopup, open: false } }) }}
                    saveText={'Confirm'}
                    saveVisible={false}
                    cancelVisible={false}
                    maxWidth="sm"
                >
                    <div className="p-t-32 p-l-r-180">
                        <ValidatorForm
                            name="maintenanceForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => { }}
                        >
                            <Grid container spacing={4} align="center" className="jcCenter">
                                <div>
                                    <img src={Caution} alt="caution" />
                                </div>
                                <div>
                                    <br />
                                    <Typography variant="h6" className={'lineHeit'}>
                                        Do you want to proceed to Run. You wont be able to edit the data while running. To proceed further please click on the confirm button.
                                    </Typography>
                                </div>
                            </Grid>
                            <br />
                            <SaveCancel
                                saveText="Confirm"
                                justifyContent="center"
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { this.setState({ alertPopup: { ...alertPopup, open: false } }) }}
                            />
                        </ValidatorForm>
                    </div>
                </AlertDialog>
                <AlertDialog
                    open={openReUpload}
                    title=""
                    onClose={() => { this.setState({ openReUpload: false }) }}
                    saveText={'Confirm'}
                    saveVisible={false}
                    cancelVisible={false}
                    maxWidth="sm"
                >
                    <Grid container spacing={4} align="center" className="jcCenter pt-36">
                        <div>
                            <img src={Caution} alt="caution" />
                        </div>
                        <div>
                            <br />
                            <Typography variant="h6" className={'lineHeit'}>
                                Do you want to Re-Upload the file(s). You won't be able to revert back the file(s). To proceed further please click the below confirm button.
                            </Typography>
                        </div>
                    </Grid>
                    <br />
                    <SaveCancel
                        saveText="Confirm"
                        justifyContent="center"
                        handleSaveButtonClick={() => { this.props.reUpload() }}
                        handleCancelButtonClick={() => { this.setState({ openReUpload: false }) }}
                    />
                </AlertDialog>
            </React.Fragment>
        );
    }
}
// prop types
VerifyData.propTypes = {
};
/**
 * Default Props
 */
VerifyData.defaultProps = {
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ maintenance: { data, verifyData }, search: { maintenance }, pageAccess: { incomerent } }, ownProps) => {
    return {
        maintenance: { ...data },
        verifyData,
        searchData: { ...maintenance },
        pageAccess: incomerent || []
    }
};
/**
 *  Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        reUpload: () => dispatch(maintenanceActions.reUpload()),
        startCron: () => dispatch(maintenanceActions.startCron()),
        getVerifyData: (data, limitType, skip, limit, orderBy, order) => dispatch(maintenanceActions.verifyData(data, limitType, skip, limit, orderBy, order)),
        searchActions: (data) => {
            return dispatch(searchActions.maintenanceSearch(data));
        },
        downloadTemplates: (id, limitType) => dispatch(maintenanceActions.downloadTemplate(id, limitType)),
        getAccess: (pageName, pageData) => dispatch(pageAccessActions.getAccess(pageName, pageData)),
        clearAlerts: () => dispatch(alertActions.clear())
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(VerifyData));