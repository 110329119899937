export const helpConstants = {
    // help listing data
    GETALL_REQUEST: 'HELP_GETALL_REQUEST',
    GETALL_SUCCESS: 'HELP_GETALL_SUCCESS',
    GETALL_FAILURE: 'HELP_GETALL_FAILURE',

    // create help page
    ADD_HELP_REQUEST: 'HELP_ADD_REQUEST',
    ADD_HELP_SUCCESS: 'HELP_ADD_SUCCESS',
    ADD_HELP_FAILURE: 'HELP_ADD_FAILURE',

    //clear help
    CLEAR_HELP_DATA:'CLEAR_HELP_DATA',

    // help edit data
    GETONE_REQUEST: 'HELP_GETONE_REQUEST',
    GETONE_SUCCESS: 'HELP_GETONE_SUCCESS',
    GETONE_FAILURE: 'HELP_GETONE_FAILURE',

    // delete help
    DELETE_HELP_REQUEST: 'HELP_DELETE_REQUEST',
    DELETE_HELP_SUCCESS: 'HELP_DELETE_SUCCESS',
    DELETE_HELP_FAILURE: 'HELP_DELETE_FAILURE',

    // help view
    GETONE_HELP_REQUEST: 'HELPVIEW_GETONE_REQUEST',
    GETONE_HELP_SUCCESS: 'HELPVIEW_GETONE_SUCCESS',
    GETONE_HELP_FAILURE: 'HELPVIEW_GETONE_FAILURE',

    // help file upload
    VIEW_HELPFILE_REQUEST: 'VIEW_HELPFILE_REQUEST',
    VIEW_HELPFILE_SUCCESS: 'VIEW_HELPFILE_SUCCESS',
    VIEW_HELPFILE_FAILURE: 'VIEW_HELPFILE_FAILURE',

    // help file upload
    DOWNLOAD_HELPFILE_REQUEST: 'DOWNLOAD_HELPFILE_REQUEST',
    DOWNLOAD_HELPFILE_SUCCESS: 'DOWNLOAD_HELPFILE_SUCCESS',
    DOWNLOAD_HELPFILE_FAILURE: 'DOWNLOAD_HELPFILE_FAILURE'

};