import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

// Import Components
import BannerTextList from './BannerTextList';
import BannerTextNew from './BannerTextNew';

// Helpers
import { getConfigurationPageTableHeight } from '../../../../helpers/global'
/**
 * BannerText Container
 * @class BannerText
 * @extends {Component}
 */
class BannerText extends Component {
    render() {
        const { match } = this.props;
        const height = getConfigurationPageTableHeight();
        return (
            <Switch>
                <Route exact path={`${match.url}`} render={(props) => {
                    return <BannerTextList {...props} height={height} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <BannerTextNew {...props} height={height} />
                }} />
            </Switch>
        );
    }
}
/**
 * Export BannerText
 */
export default BannerText;
