import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';

// Import Component
import SearchInput from '../../components/SearchInput/SearchInput';
import Pagination from "../Pagination/Pagination";
import PrevNext from "../Pagination/PrevNext";
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import ToolTip from '../../components/ToolTip/ToolTip';

// SVG Icons
import { CopyIcon, DeleteIcon, DownloadIcon, SvgLoadIcon, TableIcon, TransferIcon } from '../../components/SvgIcons/SvgIcons';

// Import Style
import tableStyle from '../../assets/jss/components/table';

// Icons
import HomeIcon from '../../assets/img/h_icon.svg';
import NHFTIcon from '../../assets/img/n_icon.svg';

// Helpers
import { constantColors } from '../../helpers/appConstants';
import { history, userRoleConstants } from '../../helpers';

let DEFAULT_DEL_TITLE = "Are you sure you want to delete this record?";

/**
 *Material Table component
 *
 * @class MaterialTableComponent
 * @extends {Component}
 */

class MaterialTableComponent extends Component {

    state = {
        open: false,
        deleteItemId: '',
        deleteTitle: DEFAULT_DEL_TITLE
    };
    /**
     * Handle Delete On Click Open
     * @param {*} id 
     * @param {*} item 
     */
    handleDeleteClickOpen = (id, item) => {
        let deleteTitle = DEFAULT_DEL_TITLE
        if (this.props.getDeleteTitle) {
            deleteTitle = this.props.getDeleteTitle(item);
        }
        if(!this.props.isDeleteRequired){
            this.setState({deleteItemId: id, itemData: item, deleteTitle });
            this.props.handleTableDeleteAction(id, this.state.itemData);
        }else{
        this.setState({ open: true, deleteItemId: id, itemData: item, deleteTitle });
        }
    };
    /**
     * Page Navigation Event
     * @param {*} page 
     */
    handleChangePage = (page) => {
        this.props.onChangePageTable(page, this.props.rowsPerPage);
    };
    /**
     * Handle Change Rows Per Page
     * @param {*} event 
     */
    handleChangeRowsPerPage = event => {
        this.props.onChangePageTable(this.props.page, event.target.value);
    };
    /**
     * Handle Sorting
     * @param {*} property 
     * @returns 
     */
    handleSorting = property => event => {
        const orderBy = property;
        let order = 'desc';
        if (this.props.orderBy === orderBy && this.props.order === 'desc') {
            order = 'asc';
        }
        this.props.onSortRequest(orderBy, order);
    };
    /**
     * Request change on input function
     * @param {*} field 
     * @param {*} value 
     */
    onInputChangeRequest = (field, value) => {
        this.props.onInputChangeRequest(field, value);
    };
    /**
     * Handle Table Delete Event
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {
        this.props.handleTableDeleteAction(id, this.state.itemData);
        this.setState({ open: false, deleteItemId: '', itemData: null });
    };
    /** Table delete function on close action event */
    handleTableDeleteOnCloseAction = () => {
        this.setState({ open: false, deleteItemId: '', itemData: null })
    }
    /**
     * Handle Table Navigation Event with two links
     * @param {*} item 
     * @param {*} field 
     */
    handleNavigateAction = (item, field) => {
        this.props.handleNavigateAction(item._id, field, item);
    }
    /**
     * Sticky Table Header
     */
    stickyTableHeaderRow = () => {
        return (
            <React.Fragment>
                {
                    this.props.multiHeader &&
                    <TableRow>
                        <TableCell colspan="3"></TableCell>
                    </TableRow>
                }
                <TableRow>
                    {this.props.moreInfoIcons.length > 0 &&
                        <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                    }
                    {this.props.header.map((item, index) => {
                        return (
                            index < this.props.stickyHeaders.length && <React.Fragment key={'frow-' + index}>
                                {this.props.removedSortingRow.indexOf(item) < 0 &&
                                    <TableCell key={'hrow-' + index} sortDirection={this.props.orderBy === this.props.field[index] ? this.props.order : false}>
                                        <TableSortLabel
                                            active={this.props.orderBy === this.props.field[index]}
                                            direction={this.props.order}
                                            onClick={this.handleSorting(this.props.field[index])}>{item}</TableSortLabel>
                                    </TableCell>
                                }
                                {this.props.removedSortingRow.indexOf(item) >= 0 &&
                                    <TableCell > {item} </TableCell>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </TableRow >
                <TableRow className={'loaderRow'}>
                    <TableCell rowSpan={this.props.header.length + 3} colSpan={this.props.header.length + 3}>
                        {this.props.isLoading === true && this.props.data && this.props.data.length > 0 &&
                            <div className={'linearProgressContainer'}>
                                <LinearProgress color="primary" />
                            </div>
                        }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    };
    /**
     * Scroll Table Header
     */
    tableHeaderRow = () => {
        return (
            <React.Fragment>
                {
                    this.props.primaryHeader.length > 0 &&
                    <TableRow>
                        {
                            this.props.primaryHeader.map(header => (
                                <TableCell colspan={this.props.staticSpan ? this.props.staticSpan : this.props.header.length / this.props.primaryHeader.length}>{header}</TableCell>
                            ))
                        }
                        {
                            this.props.actions.length > 0 &&
                            <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                        }
                    </TableRow>
                }
                <TableRow>
                    {this.props.moreInfoIcons.length > 0 &&
                        <TableCell key={'hrow-moreInfo'} > {''} </TableCell>
                    }
                    {this.props.header.map((item, index) => {
                        return (
                            index > this.props.stickyHeaders.length - 1 && <React.Fragment key={'frow-' + index}>
                                {this.props.removedSortingRow.indexOf(item) < 0 &&
                                    <TableCell key={'hrow-' + index} sortDirection={this.props.orderBy === this.props.field[index] ? this.props.order : false}>
                                        <TableSortLabel
                                            active={this.props.orderBy === this.props.field[index]}
                                            direction={this.props.order}
                                            onClick={this.handleSorting(this.props.field[index])}>{item}</TableSortLabel>
                                    </TableCell>
                                }

                                {this.props.removedSortingRow.indexOf(item) >= 0 &&
                                    <TableCell > {item} </TableCell>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                    {this.props.header.length > 0 &&
                        this.props.actions && (this.props.actions.indexOf('COPY') >= 0 ||
                            this.props.actions.indexOf('DELETE') >= 0 ||
                            this.props.actions.indexOf('TRANSFER') >= 0 ||
                            this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                            this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                            this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                            this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                        <TableCell key={'hrow-action'} className={this.props.classes.lastCol} > {'Actions'} </TableCell>
                    }
                </TableRow >
                <TableRow className={'loaderRow'}>
                    <TableCell rowSpan={this.props.header.length + 3} colSpan={this.props.header.length + 3}>
                        {this.props.isLoading === true && this.props.data && this.props.data.length > 0 &&
                            <div className={'linearProgressContainer'}>
                                <LinearProgress color="primary" />
                            </div>
                        }
                    </TableCell>
                </TableRow>
            </React.Fragment>
        )
    };
    /**
     * Binding Select Field
     * @param {*} item 
     * @param {*} pathArr 
     * @param {*} rowIndex 
     * @returns 
     */
    bindSelectField = (item, pathArr, rowIndex) => {
        const { componentMap, fieldComponentOnChange } = this.props;
        let actions = this.props.actions.slice();
        if (componentMap[pathArr]) {
            const FieldComponent = componentMap[pathArr];
            return <FieldComponent
                key={item._id}
                rowIndex={rowIndex}
                config={this.props.config}
                path={pathArr}
                data={item}
                handleChange={fieldComponentOnChange}
                actions={actions}
            />
        } else {
            let arr = pathArr.split('.');
            return arr.reduce((obj, key) => (obj && obj[key] !== 'undefined')
                ? this.getStringLength(obj[key])
                : undefined,
                item
            );
        }
    };
    /**
     * Binding ToolTip Value
     * @param {*} item 
     * @param {*} pathArr 
     * @returns 
     */
    bindToolTipValue = (item, pathArr) => {
        let arr = pathArr.split('.');
        return arr.reduce((obj, key) => (obj && obj[key] !== 'undefined')
            ?
            (key === 'isActive' ? (obj[key] === false ? 'InActive' : 'Active') : obj[key] === false ? 'Read/Write' : (obj[key] === true ? 'Read' : obj[key]))
            : undefined,
            item
        );
    }
    /**
     * Getting String Length
     * @param {*} str 
     * @returns 
     * 
     * truncate if too long and elipsis...
     */
    getStringLength = (str) => {
        if (str && str.length > 22 && typeof (str) === 'string' && this.props.ellipsis) {
            return str.substr(0, 22) + '...';
        }
        return str;
    }
    /**
     * Sticky Table Filter
     */
    stickyTableFilterRow = () => {
        const tableRow = this.props.search.map((item, index) => {
            const colName = item ? item : "";
            const sValIndex = this.props.sFields.indexOf(colName);
            return (
                index < this.props.stickyHeaders.length && <TableCell key={index} className={this.props.classes.searchRow}>
                    {
                        item != null &&
                        <SearchInput
                            placeholder={'Search Here'}
                            header={this.props.header[index]}
                            value={sValIndex >= 0 ? this.props.sValues[sValIndex] : ''}
                            reset={this.props.resetSearchContent}
                            field={this.props.field[index]}
                            onInputChangeRequest={this.onInputChangeRequest}
                            onEnter={this.onEnter}
                            isLoading={this.props.isLoading}
                        />
                    }
                </TableCell>
            );
        });
        return (
            <TableRow>
                {this.props.moreInfoIcons.length > 0 &&
                    <TableCell className={this.props.classes.searchRow}> {''} </TableCell>
                }

                {tableRow}
            </TableRow>
        )
    };
    /**
     * scroll Table Filter
     */
    tableFilterRow = () => {
        const tableRow = this.props.search.map((item, index) => {
            const colName = item ? item : "";
            const sValIndex = this.props.sFields.indexOf(colName);
            return (
                index > this.props.stickyHeaders.length - 1 && <TableCell key={index} className={this.props.classes.searchRow}>
                    {
                        item != null &&
                        <SearchInput
                            placeholder={'Search Here'}
                            header={this.props.header[index]}
                            value={sValIndex >= 0 ? this.props.sValues[sValIndex] : ''}
                            reset={this.props.resetSearchContent}
                            field={this.props.field[index]}
                            onInputChangeRequest={this.onInputChangeRequest}
                            onEnter={this.onEnter}
                        />
                    }
                </TableCell>
            );
        });
        return (
            <TableRow>
                {this.props.moreInfoIcons.length > 0 &&
                    <TableCell className={this.props.classes.searchRow}> {''} </TableCell>
                }

                {tableRow}

                {this.props.actions &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    <TableCell className={this.props.classes.searchRow} />
                }
            </TableRow>
        )
    };
    /**
     * Sticky Table Row Entry
     */
    stickyTableDataRow = () => {
        return (
            <React.Fragment>
                {this.props.data.map((item, index) =>
                    <TableRow key={index}>
                        {
                            this.stickyTableDataRowValue(item, index)
                        }
                    </TableRow>
                )}
            </React.Fragment>
        )
    };
    /**
     * Value entry insticky table row
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     * 
     * [1]  -   check the validation color and applying the style
     */
    stickyTableDataRowValue = (item, rowIndex) => {
        var y = history.location.pathname.split('/')
        var doesLoan = y.includes('loanProjects')
        return (
            <React.Fragment>
                {this.props.moreInfoIcons.length > 0 &&
                    this.bindMoreInfoIcons(item)
                }
                {this.props.field.map((field, index) => {
                    // [1]
                    let validation_color = this.props.highlightField.indexOf(field) > -1 ? constantColors[item.finalStatus] : '';
                    const boldText = this.props.boldText.indexOf(field) > -1 && item['140Per'] === true ? 'bolderText' : '';
                    return this.props.links && this.props.links.indexOf(field) >= 0
                        ? index < this.props.stickyHeaders.length && <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={doesLoan ? `linkCell ${validation_color} loanLinkCell ` : `linkCell ${validation_color} `}
                            onClick={() => this.handleNavigateAction(item, field)}
                        >
                            {item.key}  <div className="m-r-8"> {this.bindSelectField(item, field, rowIndex)} </div>
                        </TableCell>
                        : index < this.props.stickyHeaders.length && <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={`${validation_color} ${boldText}`}
                        >
                            <div className="m-r-8"> {this.bindSelectField(item, field, rowIndex)} </div>
                        </TableCell>
                }
                )}
            </React.Fragment>
        )
    };
    /**
     * Scroll Table Row Entry
     */
    tableDataRow = () => {
        return (
            <React.Fragment>
                {this.props.data.map((item, index) =>
                    <TableRow key={index}>
                        {
                            this.tableDataRowValue(item, index)
                        }
                    </TableRow>
                )}
            </React.Fragment>
        )
    };
    /**
     * Value entry in Scroll table row
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     * 
     * [1]  -   check the validation color and applying the style
     */
    tableDataRowValue = (item, rowIndex) => {
        return (
            <React.Fragment>
                {this.props.moreInfoIcons.length > 0 &&
                    this.bindMoreInfoIcons(item)
                }
                {this.props.field.map((field, index) => {
                    // [1]
                    let validation_color = this.props.highlightField.indexOf(field) > -1 ? constantColors[item.finalStatus] : '';
                    const boldText = this.props.boldText.indexOf(field) > -1 && item['140Per'] === true ? 'bolderText' : '';
                    return this.props.links && this.props.links.indexOf(field) >= 0
                        ? index > this.props.stickyHeaders.length - 1 && <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={`linkCell ${validation_color}`}
                            onClick={() => this.handleNavigateAction(item, field)}
                        >
                            {item.key}  <div className="m-r-8"> {this.bindSelectField(item, field, rowIndex)} </div>
                        </TableCell>
                        : index > this.props.stickyHeaders.length - 1 && <TableCell
                            title={this.bindToolTipValue(item, field)}
                            key={'value-' + index}
                            className={`${validation_color} ${boldText}`}
                        >
                            <div className="m-r-8"> {this.bindSelectField(item, field, rowIndex)} </div>
                        </TableCell>
                }
                )}
                {this.props.actions &&
                    (this.props.actions.indexOf('COPY') >= 0 ||
                        this.props.actions.indexOf('DELETE') >= 0 ||
                        this.props.actions.indexOf('TRANSFER') >= 0 ||
                        this.props.actions.indexOf('VIEW_REPORT') >= 0 ||
                        this.props.actions.indexOf('VIEW_HOUSEHOLD') >= 0 ||
                        this.props.actions.indexOf('REPORT_DOWNLOAD') >= 0 ||
                        this.props.actions.indexOf('GRID_VIEW') >= 0) &&
                    this.addAction(item)
                }
            </React.Fragment>
        )
    };
    /**
     * Bind More Info Icons
     * @param {*} item 
     * @returns 
     */
    bindMoreInfoIcons = (item) => {
        return (
            <TableCell className={'noPadding'} >
                <div className={'tblMoreInfoIconCel'}>
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('HOME') >= 0 && item.isHomeProject !== undefined &&
                                item.isHomeProject === true ?
                                <span className='homeCls visible'>
                                    <img src={HomeIcon} title={item.setAsideType} alt='Home Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={HomeIcon} title='Home Program' alt='Home Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('ROLE') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('NHTF') >= 0 && item.isNHTFProject !== undefined &&
                                item.isNHTFProject === true ?
                                <span className='homeCls visible'>
                                    <img src={NHFTIcon} title={item.setAsideTypeNHTF} alt='NHTF Program' />
                                </span>
                                : <span className='homeCls hidden'>
                                    <img src={NHFTIcon} title='NHTF Program' alt='NHTF Program' />
                                </span>
                            }
                        </React.Fragment>
                    }
                    {this.props.moreInfoIcons.indexOf('HOME') < 0 &&
                        <React.Fragment>
                            {this.props.moreInfoIcons.indexOf('ACTIVE') >= 0 && item.isActive !== undefined
                                && item.isActive === false ?
                                <span className='activeCls visible'>*</span>
                                : <span className='activeCls hidden'>*</span>

                            }
                            {this.props.moreInfoIcons.indexOf('ROLE') >= 0 && item.role !== undefined &&
                                item.role === userRoleConstants.organizationAdmin ?
                                <span className='roleCls visible' title='Organization Admin'>
                                    <i className="fa fa-shield" aria-hidden="true"></i>
                                </span>
                                : <span className='roleCls hidden' title='Organization Admin'>
                                    <i className="fa fa-shield" aria-hidden="true"></i>
                                </span>
                            }
                        </React.Fragment>
                    }
                </div>
            </TableCell>
        )
    }
    /**
     * Adding action
     * @param {*} item 
     * @returns 
     * 
     * [1]  -   filter out DELETE & COPY & TRANSFER
     * [2]  -   for table 1
     * [3]  -   for contractActivity
     */
    addAction = (item) => {
        const { setLabel, deleteTooltip, actionClassName } = this.props;
        let actions = this.props.actions.slice();
        if (item.disabled) {
            // [1]
            actions = actions.filter(act => { return act !== 'DELETE' && act !== 'TRANSFER'; });
        }
        // [2]
        if (this.props.actions.indexOf('DELETE') >= 0 && item.allowDelete) {
            actions.push('DELETE');
        }
        // [3]
        if(!item.allowDeleteContractActivity&&history.location.pathname.includes('contractActivity')){
            actions = actions.filter(act=>{return act!=='DELETE'})
        }
        
        return (
            <TableCell key={'actions'} className={this.props.classes.lastCol}>
                <div className={actionClassName}>
                    {actions.indexOf('COPY') >= 0 &&
                        <ToolTip title="Copy" placement="bottom">
                            <IconButton className="copy_icon" color="primary" aria-label="Copy" onClick={() => this.props.handleTableCopyAction(item._id)}>
                                <CopyIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('TRANSFER') >= 0 &&
                        <ToolTip title="Transfer" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleTransferAction(item)}>
                                <TransferIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('VIEW_REPORT') >= 0 &&
                        <ToolTip title={setLabel ? setLabel(item, "View Report") : "View Report"} placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="View" onClick={() => this.props.handleViewReportAction(item)}>
                                <TableIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('REPORT_DOWNLOAD') >= 0 &&
                        <ToolTip title="Download Report" placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Download" onClick={() => this.props.handleDownloadAction(item)}>
                                <DownloadIcon />
                            </IconButton>
                        </ToolTip>
                    }
                    {actions.indexOf('DELETE') >= 0 &&
                        <ToolTip title={deleteTooltip} placement="bottom">
                            <IconButton className="delete_icon" color="primary" aria-label="Delete" onClick={() => this.handleDeleteClickOpen(item._id, item)}>
                                <DeleteIcon />
                            </IconButton>
                        </ToolTip>
                    }
                </div>
            </TableCell>
        )
    };
    /*
        Sticky Table
    */
    stickyTable = () => {
        const { data, searchVisible, stickyHeaders } = this.props;
        return (
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    {stickyHeaders.length > 0 &&
                        this.stickyTableHeaderRow()
                    }
                </TableHead>
                <TableBody>
                    {searchVisible && stickyHeaders.length > 0 &&
                        this.stickyTableFilterRow()
                    }

                    {data && data.length > 0 &&
                        this.stickyTableDataRow()
                    }
                </TableBody>
            </Table>
        )
    }

    /*
        Scroll Table
    */
    scrollTable = () => {
        const { data, searchVisible, header, tableHeaderColor } = this.props;
        let headerClass = tableHeaderColor === 'grey' ? 'th-light-grey' : '';
        return (
            <Table stickyHeader aria-label="sticky table">
                <TableHead className={headerClass}>
                    {header.length > 0 &&
                        this.tableHeaderRow()
                    }
                </TableHead>
                <TableBody>
                    {searchVisible && header.length > 0 &&
                        this.tableFilterRow()
                    }

                    {data && data.length > 0 &&
                        this.tableDataRow()
                    }
                </TableBody>
            </Table>
        )
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { classes, data, rowsPerPage, page, count, paginationVisible, noDataErrorMessage, isLoading, header, stickyHeaders, showLoading, prevNext, showMsgInside, tableHeight, primaryHeader } = this.props;
        const itemData = this.state.itemData || {};
        let style = primaryHeader.length > 0 ? { height: "100%" } : { padding: '0px 10px', height: "100%" }
        return (
            <div style={style}>
                {/* {data && data.length > 0 && */}
                <TableContainer className={classes.container}>
                    <div className="list-table">
                        {stickyHeaders.length > 0 && <div className="sticky-table">
                            {this.stickyTable()}
                        </div>}
                        {header.length > 0 && <div className={stickyHeaders.length > 0 ? "scroll-table" : "scroll-table-full-width"} style={{ height: tableHeight && data.length > 0 ? tableHeight : 'unset' }}>
                            {this.scrollTable()}
                        </div>}
                    </div>
                </TableContainer>
                {/* } */}
                {(header.length <= 0 || stickyHeaders.length <= 0) && data && data.length <= 0 && isLoading === true && showLoading === true &&
                    <div className={'loader'}>
                        <div className="svgloader">
                            <SvgLoadIcon> </SvgLoadIcon>
                        </div>
                    </div>
                }
                <div>
                    <React.Fragment>
                        {isLoading === false && data && data.length <= 0 &&
                            <div className={showMsgInside === true ? 'nocontent-inside' : 'nocontent'}>
                                {noDataErrorMessage}
                            </div>
                        }

                    </React.Fragment>
                    {data && data.length > 0 && paginationVisible &&
                        <Pagination
                            style={{ padding: '0px 6px' }}
                            activePage={page}
                            itemsCountPerPage={rowsPerPage}
                            totalItemsCount={count}
                            pageRangeDisplayed={5}
                            onChange={this.handleChangePage}
                            isLoading={false}
                        />}

                    {
                        data && data.length > 0 && prevNext && (
                            <PrevNext
                                onClick={(isNext) => {
                                    if (isNext) {
                                        this.props.onChangePageTable(page + 1, rowsPerPage);
                                    } else {
                                        if (page > 0) {
                                            this.props.onChangePageTable(page - 1, rowsPerPage);
                                        }
                                    }
                                }}
                                isLoading={isLoading}
                            />
                        )
                    }
                    <DeleteDialog
                        open={this.state.open}
                        content={itemData.isLastOA ? "This is the last Administrator contact for this organization! Are you sure you want to delete this contact?" : this.state.deleteTitle}
                        handleDeleteAction={this.handleTableDeleteAction}
                        id={this.state.deleteItemId}
                        handleDeleteOnCloseAction={this.handleTableDeleteOnCloseAction} />
                </div>
            </div>
        );
    }
}
//prop types
MaterialTableComponent.propTypes = {
    data: PropTypes.any.isRequired,
    header: PropTypes.any.isRequired,
    field: PropTypes.any.isRequired,
    search: PropTypes.any.isRequired,
    actions: PropTypes.any,
    links: PropTypes.any,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    orderBy: PropTypes.string.isRequired,
    order: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    onChangePageTable: PropTypes.func.isRequired,
    onSortRequest: PropTypes.func.isRequired,
    onInputChangeRequest: PropTypes.func,
    resetSearchContent: PropTypes.bool.isRequired,
    paginationVisible: PropTypes.bool.isRequired,
    handleTableDeleteAction: PropTypes.func,
    handleNavigateAction: PropTypes.func,
    userOrgId: PropTypes.any,
    userRole: PropTypes.any,
    highlighColor: PropTypes.any,
    highlightField: PropTypes.any,
    noDataErrorMessage: PropTypes.string.isRequired,
    currentPage: PropTypes.string,
    removedSortingRow: PropTypes.array,
    isLoading: PropTypes.bool,
    searchVisible: PropTypes.bool,
    showLoading: PropTypes.bool,
    moreInfoIcons: PropTypes.array,
    handleTransferAction: PropTypes.func,
    handleDownloadAction: PropTypes.func,
    boldText: PropTypes.array,
    showMsgInside: PropTypes.bool,
    multiHeader: PropTypes.bool,
    staticSpan: PropTypes.number
};
//default types
MaterialTableComponent.defaultProps = {
    data: [],
    primaryHeader: [],
    header: [],
    field: [],
    search: [],
    page: 0,
    showLoading: true,
    rowsPerPage: 0,
    count: 0,
    orderBy: '',
    order: 'asc',
    classes: {},
    sFields: [],
    sValues: [],
    onChangePageTable: () => { },
    onSortRequest: () => { },
    onInputChangeRequest: () => { },
    resetSearchContent: false,
    paginationVisible: false,
    handleTransferAction: () => { },
    handleTableDeleteAction: () => { },
    handleNavigateAction: () => { },
    componentMap: {},
    userOrgId: '',
    userRole: '',
    actions: [],
    handleTableCopyAction: () => { },
    highlightField: [],
    noDataErrorMessage: '',
    currentPage: '',
    removedSortingRow: [],
    isLoading: false,
    searchVisible: true,
    config: {},
    handleViewReportAction: () => { },
    handleDownloadAction: () => { },
    moreInfoIcons: [],
    style: {},
    innerStyle: {},
    boldText: [],
    prevNext: false,
    showMsgInside: false,
    ellipsis: true,
    getDeleteTitle: null,
    setLabel: null,
    deleteTooltip: 'Delete',
    actionClassName: 'actions-center',
    stickyHeaders: [],
    headers: [],
    multiHeader: false,
    tableHeight: "",
    tableHeaderColor: '',
    staticSpan: null,
    isDeleteRequired:true
}
//export component
export default withStyles(tableStyle)(MaterialTableComponent);