import { authHeader, globalVariable, handleResponse } from '../helpers';

export const incomeRentService = {
    triggerIncomeRent
}

function triggerIncomeRent(project_id = 0,site_id = 0,table1_id = 0,reportYear_id = 0,reportYear = 0,unitType = 0) {
    const requestOptions = {
        method: 'GET',
        headers: {...authHeader()}
    };

    return fetch(`${globalVariable.ms_apiURL}/update/income-rent-limit/${project_id}/${site_id}/${table1_id}/${reportYear_id}/${reportYear}/${unitType}?isWebRequest=true`, requestOptions);
}

