import { getFloat } from "../";
import { Table1Messages } from "./messages";
import moment from 'moment';

export const Table1MRDValidations = (data) => {
    
    const errors = [];

    // subsidy Amount
    const { subsidyType_id, subsidyAmount, validationErrors } = data;
    if (subsidyType_id !== null && subsidyType_id !== ' ' && subsidyType_id !== undefined && getFloat(subsidyAmount) <= 0 && (subsidyAmount !== undefined && subsidyAmount !== null)) {
        errors.push({
            msg: Table1Messages.subsidyAmount,
            fieldName: 'subsidyAmount',
            sectionIndex: 1
        });
    }

    //The Resident Payment + Utility is more than the maximum allowable Rent
    const { total, maximumAllowableRent } = data;
    if (total > maximumAllowableRent) {
        errors.push({
            msg: Table1Messages.maxAllowRent,
            fieldName: 'total',
            sectionIndex: 1
        });
    }

    // is small unit - UnitTooSmallForLargeHouseholdCommitment
    const hasSmallUnitError = validationErrors.filter(vr => vr.title === "UnitTooSmallForLargeHouseholdCommitment").length > 0;
    if (hasSmallUnitError) {
        errors.push({
            msg: null,
            fieldName: 'specialNeedOption_id',
            sectionIndex: 0,
            type: 2,
            tab: 0
        });
    }

    // RecertsWithinTwelveMonths
    const hasRecertError = validationErrors.filter(vr => vr.title === "RecertsWithinTwelveMonths").length > 0;
    if (hasRecertError) {
        errors.push({
            msg: null,
            fieldName: 'RecertsWithinTwelveMonths',
            sectionIndex: 0,
            type: 2,
            tab: 0
        });
    }

    //Currentcertificationdatemorethan12monthsold
    const hasCurExpError = validationErrors.filter(vr => vr.title === "Currentcertificationdatemorethan12monthsold").length > 0;
    if (hasCurExpError) {
        errors.push({
            msg: null,
            fieldName: 'RecertsWithinTwelveMonths',
            sectionIndex: 0,
            type: 2,
            tab: 0
        });
    }

    // HouseholdTooSmallForLargeHouseholdCommitment
    const hashousholdSmall = validationErrors.filter(vr => vr.title === "HouseholdTooSmallForLargeHouseholdCommitment").length > 0;
    if (hashousholdSmall) {
        errors.push({
            msg: null,
            fieldName: 'specialNeedOption_id',
            sectionIndex: 0,
            type: 2,
            tab: 0
        });
    }


    const { moveInDate, moveOutDate,recentCertDate } = data;
    if(moveOutDate !== null && moveOutDate !== ' ' && moveOutDate !== "" && moveOutDate !== undefined ){
        if(moveInDate !== null && moveInDate !== ' ' && moveInDate !== "" && moveInDate !== undefined ){
            if(recentCertDate !== null && recentCertDate !== ' ' && recentCertDate !== "" && recentCertDate !== undefined ){
                    var dateFrom = moment(moveInDate);
                    var dateTo = moment(moveOutDate);
                    var dateCheck = moment(recentCertDate);
                   
console.log('dateFrom',dateFrom,'dateTo',dateTo,'dateCheck',dateCheck);
                    if (dateCheck < dateFrom || dateCheck > dateTo) {
                        errors.push({
                            msg: Table1Messages.currentCertificationDate,
                            fieldName: 'recentCertDate',
                            sectionIndex:1,
                        });
                    }
        }
      }   
    }


    // Largeousehold
    const { specialNeedOption_id, bedRoom, householdSize,householdSize_MoveIn,unitDesignation_id} = data;
  if(moveOutDate === null){
   if(bedRoom === 3 && specialNeedOption_id === 2 && unitDesignation_id===1){
        if((householdSize <= 3) && (householdSize_MoveIn <= 3)){
            errors.push({
                msg: Table1Messages.lessMembersLargehousehold,
                fieldName: 'specialNeedOption_id',
                sectionIndex: 0,
                type: 3,
                tab: 0
            });
        }
        if((householdSize > 3) && (householdSize_MoveIn > 3) && (bedRoom < 3)&& unitDesignation_id===1){
            errors.push({
                msg: Table1Messages.lessMembersLargehousehold,
                fieldName: 'specialNeedOption_id',
                sectionIndex: 0,
                type: 3,
                tab: 0
            });
        }
    } else if(bedRoom > 3 && specialNeedOption_id === 2 && unitDesignation_id===1){
        if((householdSize <= 3) && (householdSize_MoveIn <= 3)){
            errors.push({
                msg: Table1Messages.lessMembersLargehousehold,
                fieldName: 'specialNeedOption_id',
                sectionIndex: 0,
                type: 3,
                tab: 0
            });
        }
    }
    if(bedRoom < 3 && specialNeedOption_id === 2 && unitDesignation_id===1){
        errors.push({
            msg: Table1Messages.unitToSmallLargeousehold,
            fieldName: 'specialNeedOption_id',
            sectionIndex: 0,
            type: 2,
            tab: 0
        });
    }
  }


    return errors;
}
