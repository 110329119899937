import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import _ from 'underscore';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

// Import Components
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from '../../components/HelpFloater/HelpFloater';
import AlertDialog from '../../components/AlertDialog/AlertDialog';

// Import Redux Actions
import { userActions } from '../../actions/user';
import { confActions } from '../../actions/configuration';
import { alertActions } from '../../actions/alert';
import { searchActions } from '../../actions/search';
import { pageAccessActions } from '../../actions/pageAccess';
import { orgActions, helpActions } from '../../actions';
import moment from 'moment';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { globalVariable, history, getUserOrgId, getUserRole, filterSearchFields } from '../../helpers';

/**
 * StatusComponent Component
 * @class StatusComponent
 * @extends {Component}
 */
class StatusComponent extends Component {
    /**
     * Handle change for status component
     * @param {*} event 
     */
    handleChange = event => {
        const { data } = this.props;
        this.props.handleChange("isActive", { id: data._id, status: event.target.checked, totalProjects: data.totalProjects });
    };

    /**
     * Bind HTML
     */
    render() {
        const { data } = this.props;
        return (<Switch
            disabled={data.disabled}
            color="primary"
            size="small"
            checked={data.isActive}
            onChange={this.handleChange}
        />);
    }
}
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

const componentMap = {
    isActive: StatusComponent
}
/**
 * UsersList Component
 * @class UsersList
 * @extends {Component}
 */
class UsersList extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            inActiveUserId: null,
            openHelp: false,
            openRetriveAlert:false,
            retriveUserId:null
        }
        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }
    /**
     * Component Did Mount Event
     */
    UNSAFE_componentWillMount() {
        const { page } = this.props.users;
        let ContactsTabRecordcount = history.location.pathname.includes('organizations');
        this.props.getHelp();
        this.props.getPageAccess();
        // retain old search values
        if (page.skip) {
            this.props.getUsers(page.skip, globalVariable.tableRowSize-4, page.orderBy, page.order, page.sFields, page.sValues, false, this.props.orgId);
        } else {
            this.props.page === 'users' ?
                this.props.getUsers(1, globalVariable.tableRowSize-4, 'name', 'asc', '', '', false, this.props.orgId) :
                this.props.getUsers(1, globalVariable.tableRowSize-4, 'name', 'asc', '', '', false, this.props.orgId)

        }
        setTimeout(() => {
            this.props.getFieldFilter("usersArchive");
        }, 100);
    }

    /**
     * Component Did update
     */
    componentDidUpdate() {
        //To clear the filters in the table
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { users, orgId, searchData: { sFields, sValues } } = this.props;
        this.props.getUsers(skip, limit, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
    };

    // set alert title for delete
    getDeleteTitle = (item) => {
        return <p>Are you sure you want to delete <b>User: {item.firstName} {item.lastName}</b>?</p>
    }
    /**
     * Handle Table Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { users, orgId, searchData: { sFields, sValues } } = this.props;
        this.props.getUsers(users.page.skip, users.page.limit, orderBy, order, sFields, sValues, false, orgId);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        this.setState({ reset: false });
        const { users, orgId, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getUsers(1, globalVariable.tableRowSize-4, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
    };
    /**
     * Handle Add Action Navigates to new user page
     */
    handleAddActionClick = () => {
        history.push({
            pathname: `${this.props.match.url}/new`,
            state: {
                page: 'userNew',
                pageData: {}
            }
        });
    };
    /**
     * Handle Clear Fileter Action
     * 
     */
    handleClearFilterActionClick = () => {
        const { users, orgId } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getUsers(users.page.skip, users.page.limit, users.page.orderBy, users.page.order, [], [], false, orgId);
    };

    /**
     * Handle Field Filter Action Event
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter("usersArchive", this.props.users.page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("usersArchive", this.props.users.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }

    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("usersArchive", updatedColumns);
        } else {
            this.props.changeFieldFilter("usersArchive", [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("usersArchive", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { users, orgId, searchData: { sFields, sValues } } = this.props;
            this.props.getUsers(users.page.skip, users.page.limit, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
        });
    }
    /**
     * Handle Table Delete Action
     * @param {*} id 
     * @param {*} item 
     */
    handleTableDeleteAction = (id, item) => {
        if (item.totalProjects === 0) {
            // this.props.deleteUser(id,item, () => {
            this.props.deleteUser(id, () => {
                const { page } = this.props.users;
                // retain old search values
                if (page.skip) {
                    this.props.getUsers(page.skip, globalVariable.tableRowSize-4, page.orderBy, page.order, page.sFields, page.sValues, false, this.props.orgId);
                } else {
                    this.props.getUsers(1, globalVariable.tableRowSize-4, 'name', 'asc', '', '', false, this.props.orgId);
                }
            })
        } else {
            this.props.alertError("A contact associated with a Project cannot be deleted – please inactive this contact record, then update the contact name in the associated Project record")
            // this.props.alertError(<p>A <b>User: {item.firstName} {item.lastName}</b> associated with a Project cannot be deleted – please inactive this contact record, then update the contact's name in the associated Project record.</p>)
        }
    };

    handleRetriveClickOpen = (id,item)=>{
        this.setState({openRetriveAlert:true,retriveUserId:id})
    }
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     */
    handleNavigateAction = (id) => {
        const { orgId } = this.props;
        const page = 'archive';
        if (orgId) {
            history.push({
                pathname: `/users/${id}`,
                state: {
                    page: 'userEdit',
                    orgId,
                    navFrom:'archive',
                    pageData: {
                        user_id: parseInt(id, 10)
                    }
                }
            });
        } else {
            history.push({
                pathname: `/users/${id}`,
                state: {
                    page: 'userEdit',
                    navFrom:'archive',
                    pageData: {
                        user_id: parseInt(id, 10)
                    }
                }
            });
        }
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        switch (name) {
            case "isActive":
                if (data.totalProjects > 0 && !data.status) {
                    this.setState({
                        inActiveUserId: data
                    });
                } else {
                    this._setUserActiveStatus(data.id, data.status);
                }
                break;
            default:
        }
    }
    /**
     * Handles users active status
     * @param {*} id 
     * @param {*} status 
     */
    _setUserActiveStatus = (id, status) => {
        this.props.activeStatus(id, status, () => {
            const { users, orgId, searchData: { sFields, sValues } } = this.props;
            this.props.getUsers(users.page.skip, users.page.limit, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
        });
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }


    /**
     * Bind Html to DOM 
     * 
     * [1]  -   Modified to display delete for Inactive
     */
    render() {
        const { fieldFilter, openHelp } = this.state;
        const { userRole, userOrgId } = this;
        let { users, columnsMap, visibleColumns, needPadding, classes, searchData: { sFields, sValues }, location: { pathname }, removeActions, pageAccess, isSubPage, helpData } = this.props;
        let fieldFilters = users.page.fieldFilters;
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        pageAccess = _.difference(pageAccess, removeActions);
        //PageAccess for superfunders
        if(getUserRole()==='superFunder' || getUserRole()==='superFunderWithHO'){
        pageAccess = ['FILTER','CLEAR','RETRIEVE','HELP','ARCHUSR','DELETE']
        }else{
            pageAccess = ['FILTER','CLEAR','HELP']
        }
        
        if (users && users.data && users.data.length > 0) {
            // delete icon visibility only for logged funder and non funder 
            users.data.forEach((elem, i) => {
                //Deleting users in contacts tab only for Super Funders
                if (getUserOrgId() === 1 && this.props.page === 'orgs' && users['data'][i].isActive === true) {
                    users['data'][i].allowDelete = true
                }
                else if (getUserOrgId() === 2 && this.props.page === 'orgs' && users['data'][i].isActive === true) {
                    users['data'][i].allowDelete = true
                }
                else if (users['data'][i].org_id.organizationType.indexOf("Funder") > -1) {
                    users['data'][i].allowDelete = false
                }
                else if (users['data'][i].org_id._id === getUserOrgId()) {
                    users['data'][i].allowDelete = true
                }
                else {
                    users['data'][i].allowDelete = false
                }
            })
        }
        users&&users.data.map(item=>{
            //calculate age of delete
            let currentDate = item['currentDate']&&item['currentDate'].split("T")[0];
            let isDeletedAt = item['isDeletedAt']&&item['isDeletedAt'].split("T")[0];
            console.log('currentDate',currentDate)
            console.log('isDeletedAt',isDeletedAt)
            console.log(moment(currentDate).diff(moment(isDeletedAt)))
            item['age']=`${isNaN(moment(currentDate).diff(moment(isDeletedAt), "days"))?0:moment(currentDate).diff(moment(isDeletedAt), "days")} days`;
        })
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                isSubPage={true}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
            >
                <TableComponent
                    page={users.page.skip}
                    rowsPerPage={users.page.limit}
                    count={users.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    actions={pageAccess}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    links={['name']}
                    order={users.page.order}
                    orderBy={users.page.orderBy}
                    data={users.data}
                    isLoading={users.isLoading}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    getDeleteTitle={this.getDeleteTitle}
                    handleTableDeleteAction={this.handleRetriveClickOpen}
                    handleRetriveClickOpen={this.handleRetriveClickOpen}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                    removedSortingRow={['Total # Associated Projects']}
                    currentPage={'users'}
                    moreInfoIcons={['ACTIVE', 'ROLE']}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    prevNext={false}
                    paginationVisible={true}
                    disablePagination={users.page.lastPage}
                    columnsMap={columnsMap}
                    showLoading={isSubPage}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                <DeleteDialog
                    open={this.state.inActiveUserId !== null}
                    content={"This Contact is associated with one or more projects. Are you sure want to inactivate this contact?"}
                    handleDeleteAction={() => {
                        const { id, status } = this.state.inActiveUserId;
                        this._setUserActiveStatus(id, status);
                        this.setState({ inActiveUserId: null });
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ inActiveUserId: null });
                    }}
                />
                <AlertDialog
                        open={this.state.openRetriveAlert}
                        title={'Warning:'}
                        onClose={(event, reason) => {
                            if (reason !== 'backdropClick') {
                                this.setState({ openRetriveAlert:false })
                            }


                            // history.goBack();
                        }}
                        onSave={()=>{
                            this.setState({openRetriveAlert:false})
                            this.props.retrieveUser(this.state.retriveUserId,()=>{
                                this.props.getUsers(1, globalVariable.tableRowSize-4, 'name', 'asc', '', '', false, this.props.orgId);
                            })
                        }}
                        closeIconVisible={true}
                        saveVisible={true}
                        cancelVisible={false}
                        saveText="Okay"
                        page="org"
                    >
                    <div dangerouslySetInnerHTML={{ __html: `Retrieving the user might cause a change in role access. Please re-login for updated role access.`}}></div>
                    </AlertDialog>
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Archive Users"
                />}
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
UsersList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};
/**
 * Default Props 
 */
UsersList.defaultProps = {
    onRef: () => { },
    needPadding: false,
    removeActions: ["UPDATE"],
    isSubPage: false
}
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 * @param {*} state 
 */
const getPageState = function (page, state) {
    switch (page) {
        case 'users': return state.users;
        case 'orgs': return state.organizations.contacts;
        default: break;
    }
}
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 */
const getPageAction = function (page) {
    switch (page) {
        case 'users': return userActions.getAll;
        case 'orgs': return orgActions.getContacts;
        default: break;
    }
}

/**
 * A method to map the search actions from different pages for same containers
 * @param {*} page 
 */

const getSearchAction = function (page) {
    switch (page) {
        case 'users': return searchActions.users;
        case 'orgs': return searchActions.contacts;
        default: break;
    }
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter["usersArchive"] || {};
    const users = getPageState(ownProps.page, state);
    const pageAccess = state.pageAccess['usersList'] || [];
    const searchData = ownProps.page === 'users' ? state.search['users'] : state.search['contact'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    return {
        users,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData,
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @param {*} ownProps 
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // You can now say this.props.createBook
        getUsers: (skip, limit, orderBy, order, sField, sValue, autoComplete, orgId) => {
            const actionToCall = getPageAction(ownProps.page);
            dispatch(actionToCall(skip, limit, orderBy, order, sField, sValue,'archive', autoComplete, orgId))
        },
        resetFilter: () => dispatch(userActions.resetFilter()),
        retrieveUser: (id,successCallback) => dispatch(userActions.retreiveUser(id,successCallback)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        alertError: (text) => dispatch(alertActions.error(text)),
        searchActions: (data) => {
            const searchToCall = getSearchAction(ownProps.page)
            dispatch(searchToCall(data))
        },
        getHelp: () => dispatch(helpActions.getAll({ _id: 26 })),
        getPageAccess: () => dispatch(pageAccessActions.getAccess('usersList',''))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(UsersList));