import React, { Component } from "react";
import PropTypes from "prop-types";

// Material UI
import Grid from "@material-ui/core/Grid";
import { NegativePrefixHandler } from "../../../../helpers";

// Import Components
import TextBox from "../../../../components/TextBox/TextBox";
import NumberField from "../../../../components/NumberField/NumberField";

// Helpers
import {
  getReplacementReservePerUnit,
  getOperatingReservePerUnit,
  getDebtCoverage,
} from "../../../../helpers/Table4Calc";
/**
 * PerformanceMeasures Component
 * @class PerformanceMeasures
 * @extends {Component}
 */
class PerformanceMeasures extends Component {
  render() {
    const { data, classes } = this.props;
    let {
      vacancyRate,
      occupancyRate,
      averageUnitTurnAround,
      averageUnitTurnAround_CALC,
      replacementReservePerUnit,
      operatingReservePerUnit,
      debtCoverageRatio,
      performanceMeasure,
    } = data;
    // calc
    occupancyRate = performanceMeasure ? performanceMeasure.occupancyRate : 0.0;
    vacancyRate = performanceMeasure ? performanceMeasure.vacancyRate : 0.0;
    replacementReservePerUnit = getReplacementReservePerUnit(data);
    operatingReservePerUnit = getOperatingReservePerUnit(data);
    debtCoverageRatio = getDebtCoverage(data);
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextBox
              name="vacancyRate"
              value={isNaN(vacancyRate) ? "0%" : `${vacancyRate}%`}
              label="Calculated Vacancy Rate"
              className={classes.textField}
              margin="dense"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextBox
              name="occupancyRate"
              value={isNaN(occupancyRate) ? "0%" : `${occupancyRate}%`}
              label="Calculated Occupancy Rate"
              className={classes.textField}
              margin="dense"
              disabled={true}
            />
          </Grid>
          {performanceMeasure.debtRatio && (
            <Grid item xs={12} sm={4}>
              <TextBox
                name="averageUnitTurnAround"
                value={`${averageUnitTurnAround_CALC ? averageUnitTurnAround_CALC: averageUnitTurnAround}`}
                label="Average Unit Turn Around"
                className={classes.textField}
                margin="dense"
                disabled={true}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <NumberField
              name="replacementReservePerUnit"
              value={replacementReservePerUnit}
              label="Replacement Reserve Per Unit"
              className={classes.textField}
              margin="dense"
              disabled={true}
              prefix={NegativePrefixHandler(replacementReservePerUnit)}
              thousandSeparator={true}
              decimalScale={0}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberField
              name="operatingReservePerUnit"
              value={operatingReservePerUnit}
              label="Operating Reserve Per Unit"
              className={classes.textField}
              margin="dense"
              disabled={true}
              prefix={NegativePrefixHandler(operatingReservePerUnit)}
              thousandSeparator={true}
              decimalScale={0}
            />
          </Grid>
          {performanceMeasure.debtRatio && (
            <Grid item xs={12} sm={4}>
              <NumberField
                name="debtCoverageRatio"
                value={debtCoverageRatio}
                label="Debt Coverage Ratio"
                className={classes.textField}
                margin="dense"
                disabled={true}
                prefix={""}
                thousandSeparator={true}
                allowNegative={true}
              />
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
/** Props Types */
PerformanceMeasures.propTypes = {
  classes: PropTypes.object.isRequired,
};
/** Default Props */
PerformanceMeasures.defaultProps = {
  data: {},
  config: {},
  classes: {},
  pageAccess: [],
};
/** Export Component */
export default PerformanceMeasures;
