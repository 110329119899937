import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Components
import UnitsEdit from './UnitsEdit';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TabContainer from '../../components/TabContainer/TabContainer';
import UnitsHistory from './UnitsHistory';

// Import Actions
import { unitsActions } from '../../actions/units';
import { sitesActions } from '../../actions/sites';
import { buildingActions } from '../../actions/building';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserAccessForPage, history } from '../../helpers';
import { getTitle } from '../../helpers/setTitle';
/**
 * UnitsView Component
 * @class UnitsView
 * @extends {Component}
 */
class UnitsView extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.userRef = React.createRef();
        this.accessAction = getUserAccessForPage('unitview');
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { siteId, buildingId, match: { path } } = this.props;
        if (siteId) {
            this.props.getById(siteId, path.includes('archives') ? 'archives' : null);
        }
        if (buildingId) {
            this.props.getBuildingById(buildingId, path.includes('archives') ? 'archives' : null);
        }
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.unitRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData,table1_id,bedroomSizeChanged) => {

        console.log("----- handleFormSaveActionClick ------",table1_id)
        if (formData) {
            this.props.updateUnit(formData, formData._id,table1_id,bedroomSizeChanged);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Table Navigation Event - Projects View Page
     * @param {*} unitid 
     */
    handleNavigateAction = (unitid) => {
        const { match } = this.props;
        history.push({
            pathname: '/units/' + unitid,
            state: {
                page: 'unitsList',
                pageDate: {
                    isArchived: match.path.includes('archives')
                }
            }
        });
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match } = this.props;
        switch (value) {
            case 1:
                history.push(`${match.url}/unit-history`);
                break;
            default:
                history.push(`${match.url}`);
                break;
        }
    };
    /**
     * Bind Html to DOM
     */
    render() {
        let { match: { params }, match, location: { pathname }, pageAccess, isSubPage, classes, pageTitle } = this.props;
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        const currentTab = pathname.includes('/unit-history') ? 1 : 0;
        pageAccess = isSubPage ? pageAccess : [...pageAccess, 'BACK'];
        return (
            <ContentWrapper
                classes={classes}
                isSubPage={isSubPage}
                pageAccess={pageAccess}
                title={isSubPage ? '' : pageTitle.title}
                subTitle={isSubPage ? '' : pageTitle.subTitle}
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="General" value={0} />
                        <Tab label="Unit History" value={1} />
                    </Tabs>
                </AppBar>
                <TabContainer>
                    <Switch>
                        <Route
                            exact
                            path={`${match.url}/unit-history`}

                            render={(props) => {
                                return (
                                    <UnitsHistory
                                        pageAccess={pageAccess}
                                        unitid={parseInt(params.unitid, 10)}
                                        innerRef={(node) => { this.unitRef = node; }}
                                        isReadOly={isReadOly}
                                        isArchived={pathname.includes('archives')}
                                    />
                                )
                            }}
                        />
                        <Route
                            exact
                            path={`${match.url}`}
                            render={(props) => {
                                return (
                                    <UnitsEdit
                                        match={match}
                                        pageAccess={pageAccess}
                                        unitid={parseInt(params.unitid, 10)}
                                        innerRef={(node) => { this.unitRef = node; }}
                                        isReadOly={isReadOly}
                                        handleFormSaveActionClick={(formData,table1_id,bedroomSizeChanged) => this.handleFormSaveActionClick(formData,table1_id,bedroomSizeChanged)}
                                        isArchived={pathname.includes('archives')}
                                    />
                                )
                            }}
                        />
                    </Switch>
                </TabContainer>
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
UnitsView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool
};
/** Default Props */
UnitsView.defaultProps = {
    isSubPage: false,
    pageAccess: [],
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const { units, pageAccess } = state;
    const pageTitle = getTitle(history.location.pathname, state);
    return {
        getOne: units.getOne,
        pageAccess: pageAccess['unitEdit'] || [],
        pageTitle
    }
};
/**
 *  Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        updateUnit: (formData, id,table1_id,bedroomSizeChanged) => dispatch(unitsActions.updateUnit(formData, id,table1_id,bedroomSizeChanged)),
        getById: (id, type) => dispatch(sitesActions.getById(id, type)),
        getBuildingById: (id, type) => dispatch(buildingActions.getById(id, type)),
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(UnitsView));





