import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Tooltip, Typography } from '@material-ui/core';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Redux Actions and Helper Variable
import { history, formatDate } from '../../helpers'

// Import Components
import NumberField from '../../components/NumberField/NumberFieldToolTip';
import TextBox from '../../components/TextBox/TextBoxToolTip';
import AutoComplete from '../../components/AutoComplete/AutoCompleteToolTip';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import DatePicker from '../../components/DatePicker/DatePickerTooltip';
import CheckboxValidator from "../../components/CheckBox/CheckBox";

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";
import { configurationConstants, contractActivityContants } from "../../helpers/appConstants";
import FieldValidationConstants from "../../helpers/fieldValidationConstants"
import ContractActivityValidations from '../../helpers/TableValidations/contractActivity';

// import actions
import { reportActivityActions } from '../../actions/reportActivity';
import { validationActions } from '../../actions/validation';
import { confActions } from "../../actions/configuration";
import { alertActions } from "../../actions/alert";
import { focusHelper } from '../../helpers/global';

class ContractActivityView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loanNumber: '',
            isFundsFromMutipleHTFContracts: '',
            isLoan: '',
            homeType: '',
            numberOfBedrooms: '',
            address: '',
            city: '',
            county: null,
            ethincity: '',
            race: '',
            sweatEquity: '',
            landTrust: '',
            populationCategory: '',
            typeHTFActivity: '',
            dateOfPurchase: null,
            householdSize: null,
            maxAllowedIncome: '',
            annualGrossIncome: '',
            purchasePrice: '',
            interestRateHTFLoan: '',
            assistanceHTF: '',
            otherPublicAssistance: '',
            otherFundersAndAmt: '',
            homeOwnerCashInvestment: '',
            totalMarketValue: '',
            totalRestrictiveValue: '',
            homeOwnerPurchasePrice: '',
            firstMortageAmount: '',
            firstMortageMonthlyPayment: '',
            secondMortageAmount: '',
            secondMortageMonthlyPayment: 0,
            totalMortageMonthlyPayment: '',
            totalPITIU: '',
            affordabilityRatio: '',
            homeOwnerPriorResidenceStatus: '',
            homeOwnerPriorHousingCost: '',
            isPropertyForclosure: '',
            isHTFLoanStatisfied: '',
            ami: '',
            incomeLimitData: {},
            updateState: true,
            snapshotData: false,
            onChangeFlag: false,
            isClosedLoan: false,
            isFormNotSaved: false,
            isIncomeLimitDataUpdated: false,
            isMigrated:false
        };

        this.onwerName = "";

    }

    componentDidMount() {
        const { match, contractActivity } = this.props;
        let loan_id = match.params.id;
        let urlData = match.path.split('/');
        const reportYear_id = urlData.length === 8 ? urlData[4] : null;

        this.props.getConfiguration(configurationConstants.counties);
        this.props.getConfiguration(configurationConstants.cities);
        this.props.getConfiguration(configurationConstants.homeType);
        this.props.getConfiguration(configurationConstants.bedrooms_ho);
        this.props.getConfiguration(configurationConstants.race);
        this.props.getConfiguration(configurationConstants.populationCategoryHO);
        this.props.getConfiguration(configurationConstants.typeHTFActivityHO);
        this.props.getConfiguration(configurationConstants.HOPriorResidenceStatus);
        this.props.getConfiguration(configurationConstants.setAsideType_ho);
        this.props.getByIdContractActivity(loan_id);
        this.props.getReportActivityAccess(reportYear_id, () => { })

        if (contractActivity) {
            const { location: { pathname } } = this.props;
            let tabUrl = pathname.split('/');
            let generalData = {
                county: contractActivity && contractActivity.address && contractActivity.address.county && contractActivity.address.county,
                householdSize: contractActivity.householdSize,
                setAside: contractActivity.ami,
                year: tabUrl.length > 0 ? tabUrl[5] : 0
            }
            this.props.getIncomeLimit(generalData);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { contractActivity } = nextProps;
        const { isIncomeLimitDataUpdated, isFormNotSaved } = this.state;
        if (contractActivity) {
            if (!isFormNotSaved) {
                this.setState({
                    loanNumber: this.state.loanNumber !== '' && typeof this.state.loanNumber !== 'undefined' ? this.state.loanNumber : contractActivity.loanNumber,
                    isFundsFromMutipleHTFContracts: contractActivity.isFundsFromMutipleHTFContracts,
                    isLoan: contractActivity.isLoan,
                    homeType: contractActivity.homeType,
                    numberOfBedrooms: contractActivity.numberOfBedrooms,
                    address: contractActivity && contractActivity.address && contractActivity.address.street,
                    city: contractActivity && contractActivity.address && contractActivity.address.city,
                    //county: contractActivity && contractActivity.address && contractActivity.address.county,
                    ethincity: contractActivity.ethincity,
                    race: contractActivity.race,
                    sweatEquity: contractActivity.sweatEquity,
                    landTrust: contractActivity.landTrust,
                    populationCategory: contractActivity.populationCategory,
                    typeHTFActivity: contractActivity.typeHTFActivity,
                    dateOfPurchase: contractActivity.dateOfPurchase,
                    //householdSize: this.state.householdSize===''?contractActivity.householdSize:this.state.householdSize,
                    maxAllowedIncome: contractActivity.maxAllowedIncome,
                    annualGrossIncome: contractActivity.annualGrossIncome,
                    purchasePrice: contractActivity.purchasePrice,
                    interestRateHTFLoan: contractActivity.interestRateHTFLoan,
                    assistanceHTF: contractActivity.assistanceHTF,
                    otherPublicAssistance: contractActivity.otherPublicAssistance,
                    otherFundersAndAmt: contractActivity.otherFundersAndAmt,
                    homeOwnerCashInvestment: contractActivity.homeOwnerCashInvestment,
                    totalMarketValue: contractActivity.totalMarketValue,
                    totalRestrictiveValue: contractActivity.totalRestrictiveValue,
                    homeOwnerPurchasePrice: contractActivity.homeOwnerPurchasePrice,
                    firstMortageAmount: contractActivity.firstMortageAmount,
                    firstMortageMonthlyPayment: contractActivity.firstMortageMonthlyPayment,
                    secondMortageAmount: contractActivity.secondMortageAmount === null ? 0 : contractActivity.secondMortageAmount,
                    secondMortageMonthlyPayment: contractActivity.secondMortageMonthlyPayment === null ? 0 : contractActivity.secondMortageMonthlyPayment,
                    totalMortageMonthlyPayment: contractActivity.totalMortageMonthlyPayment,
                    totalPITIU: contractActivity.totalPITIU,
                    affordabilityRatio: contractActivity.affordabilityRatio,
                    homeOwnerPriorResidenceStatus: contractActivity.homeOwnerPriorResidenceStatus,
                    homeOwnerPriorHousingCost: contractActivity.homeOwnerPriorHousingCost,
                    isPropertyForclosure: contractActivity.isPropertyForclosure,
                    isHTFLoanStatisfied: contractActivity.isHTFLoanStatisfied,
                    ami: contractActivity.ami,
                    updateState: false,
                    snapshotData: contractActivity.snapshotData,
                    onChangeFlag: true,
                    isClosedLoan: contractActivity.closedLoan,
                    isMigrated: contractActivity.isMigrated?contractActivity.isMigrated:this.state.isMigrated
                })
            }
            if (!isIncomeLimitDataUpdated && contractActivity.address && contractActivity.address.county && contractActivity.householdSize && contractActivity.ami && contractActivity.dateOfPurchase) {
                this.setState({ isIncomeLimitDataUpdated: true })
                let generalData = {
                    county: contractActivity.address.county,
                    householdSize: contractActivity.householdSize,
                    setAside: contractActivity.ami,
                    dateOfPurchase: contractActivity.dateOfPurchase
                }
                this.props.getIncomeLimit(generalData, (incomeData) => {
                    this.setState({
                        incomeLimitData: incomeData.data
                    });
                    setTimeout(() => {
                        this.getMaxIncomeTitle()
                    }, 3000)
                });
            }
        }
    }

    handleFocus = (name) => {
        this.setState({ updateColor: true })
    }
    handleOutFocus = (name) => {
        this.setState({ updateColor: false })
    }

    handleChange = (name, value) => {
        let { isFundsFromMutipleHTFContracts, ethincity, sweatEquity, landTrust, otherPublicAssistance, isPropertyForclosure, isHTFLoanStatisfied, isLoan } = this.state;
        if (value !== '')
            switch (name) {
                case "isFundsFromMutipleHTFContracts":
                    this.setState({
                        isFundsFromMutipleHTFContracts: isFundsFromMutipleHTFContracts === "" ? value : !isFundsFromMutipleHTFContracts,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                case "isLoan":
                    this.setState({
                        isLoan: isLoan === "" ? value : !isLoan,
                        isFormNotSaved: true
                    });
                    break;
                case "ethincity":
                    this.setState({
                        ethincity: ethincity === "" ? value : !ethincity,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                case "sweatEquity":
                    this.setState({
                        sweatEquity: sweatEquity === "" ? value : !sweatEquity,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                case "landTrust":
                    this.setState({
                        landTrust: landTrust === "" ? value : !landTrust,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                case "otherPublicAssistance":
                    this.setState({
                        otherPublicAssistance: otherPublicAssistance === "" ? value : !otherPublicAssistance,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                case "isPropertyForclosure":
                    this.setState({
                        isPropertyForclosure: isPropertyForclosure === "" ? value : !isPropertyForclosure,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                case "isHTFLoanStatisfied":
                    this.setState({
                        isHTFLoanStatisfied: isHTFLoanStatisfied === "" ? value : !isHTFLoanStatisfied,
                        isFormNotSaved: true
                        // focusField: ele, focusFieldName: name, focusVisible: true
                    });
                    break;
                default:
                    break;
            };
        if (name !== "isHTFLoanStatisfied" && name !== "isPropertyForclosure" && name !== "otherPublicAssistance" && name !== "landTrust" && name !== "sweatEquity" && name !== "ethincity" && name !== "isFundsFromMutipleHTFContracts" && name !== "isLoan") {
            this.setState({
                [name]: value,
                isFormNotSaved: true
            });
        }

    }

    getMaxIncomeTitle() {
        const { ami, dateOfPurchase, incomeLimitData } = this.state;
        const { setAsideType_ho } = this.props;
        let setAsideTypeloh = setAsideType_ho.length > 0 ? setAsideType_ho.filter(item => item.category_id === 1) : [];
        let year = incomeLimitData && incomeLimitData.year ? incomeLimitData.year : 'N/A'

        let aminew = setAsideTypeloh.length > 0 ? setAsideTypeloh.filter(item => item.key === ami).map(item => item.name) : ""
        return `Effective Date: ${formatDate(dateOfPurchase)}, year: ${year}, Effective RUP: ${aminew}, Limit Pool: Multi Family`;
    }

    /**** 
     *  Method when org got changed
     */
    handleAutoComplete_homeType = (selectedOption) => {
        this.setState({ homeType: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_bedrooms = (selectedOption) => {
        this.setState({ numberOfBedrooms: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_city = (selectedOption) => {
        this.setState({ city: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_race = (selectedOption) => {
        this.setState({ race: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_populationCategory = (selectedOption) => {
        this.setState({ populationCategory: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_typeHTFActivity = (selectedOption) => {
        this.setState({ typeHTFActivity: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_HOPriorResidenceStatus = (selectedOption) => {
        this.setState({ homeOwnerPriorResidenceStatus: selectedOption.value, isFormNotSaved: true });
    }

    handleAutoComplete_ethnicity = (selectedOption) => {
        this.setState({ ethincity: selectedOption.value?selectedOption.value:null, isFormNotSaved: true });
    }

    handleAutoComplete_county = (selectedOption) => {
        let { ami, householdSize, dateOfPurchase } = this.state;
        let { contractActivity } = this.props;
        //set prop value if state not updated
        dateOfPurchase = dateOfPurchase === null ? contractActivity && contractActivity.dateOfPurchase : dateOfPurchase;
        householdSize = householdSize === null ? contractActivity && contractActivity.householdSize : householdSize;

        if (ami !== '' && householdSize !== null && dateOfPurchase !== null) {
            let generalData = {
                county: selectedOption.value,
                householdSize: householdSize,
                setAside: ami,
                dateOfPurchase: dateOfPurchase
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount || 0,
                    incomeLimitData: incomeData.data
                });
            });
        }
        this.setState({
            county: selectedOption.value,
            isFormNotSaved: true
        });
    }

    handleChangeHouseholdSize = (name, value) => {
        let { county, ami, dateOfPurchase } = this.state;
        let { contractActivity } = this.props;
        //set prop value if state not updated
        dateOfPurchase = dateOfPurchase === null ? contractActivity && contractActivity.dateOfPurchase : dateOfPurchase;
        county = county !== null ? county : contractActivity && contractActivity.address && contractActivity.address.county;



        if (county !== null && ami !== "" && value !== "" && dateOfPurchase !== null) {
            let generalData = {
                county: county,
                householdSize: value,
                setAside: ami,
                dateOfPurchase: dateOfPurchase
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount || 0,
                    incomeLimitData: incomeData.data
                });
            });
        }

        this.setState({
            [name]: value,
            isFormNotSaved: true
        });
    }

    handleAutoComplete_ami = (selectedOption) => {
        let { county, householdSize, dateOfPurchase } = this.state;
        let { contractActivity } = this.props;
        //set prop value if state not updated
        dateOfPurchase = dateOfPurchase === null ? contractActivity && contractActivity.dateOfPurchase : dateOfPurchase;
        county = county !== null ? county : contractActivity && contractActivity.address && contractActivity.address.county;
        householdSize = householdSize === null ? contractActivity && contractActivity.householdSize : householdSize;

        if (county !== null && householdSize !== null && dateOfPurchase !== null) {
            let generalData = {
                county: county,
                householdSize: householdSize,
                setAside: selectedOption.value,
                dateOfPurchase: dateOfPurchase
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount || 0,
                    incomeLimitData: incomeData.data,
                    ami: selectedOption.value
                });
            });
        }
        this.setState({
            ami: selectedOption.value,
            isFormNotSaved: true
        });
    }
    /**
     * Handle Autocomplete DOP
     * @param {*} name 
     * @param {*} value 
     */
    handleAutoComplete_dop = (name, value) => {
        let { county, householdSize, ami } = this.state;
        let { contractActivity } = this.props;
        //set prop value if state not updated
        county = county !== null ? county : contractActivity && contractActivity.address && contractActivity.address.county;
        householdSize = householdSize === null ? contractActivity && contractActivity.householdSize : householdSize;

        if (county !== null && householdSize !== null && ami !== "" && value !== null) {
            let generalData = {
                county: county,
                householdSize: householdSize,
                dateOfPurchase: value.format('MM/DD/YYYY'),
                setAside: ami
            }
            this.props.getIncomeLimit(generalData, (incomeData) => {
                this.setState({
                    maxAllowedIncome: incomeData.data.amount || 0,
                    incomeLimitData: incomeData.data
                });
            });
        }
        this.setState({
            [name]: value,
            isFormNotSaved: true
        });
    }
    /**
     * Handle Auto complete fro HFT
     * @param {*} selectedOption 
     */
    handleAutoComplete_HFT = (selectedOption) => {
        this.setState({ isHTFLoanStatisfied: selectedOption.value });
    }
    /**
     * Handle submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        const { contractActivity } = this.props;
        let { county, householdSize, dateOfPurchase, isMigrated } = this.state;

        const isValRequired = !isMigrated || new Date(dateOfPurchase).getFullYear()>2009;
        //set prop value if state not updated
        let countyFromProps = contractActivity && contractActivity.address && contractActivity.address.county;
        let houseHoldSizeProps = contractActivity && contractActivity.householdSize;
        let dateOfPurchaseProps = contractActivity && contractActivity.dateOfPurchase;

        if (county === null) {
            county = countyFromProps;
        }
        if (householdSize === null) {
            householdSize = houseHoldSizeProps;
        }
        if (dateOfPurchase === null && isValRequired) {
            dateOfPurchase = dateOfPurchaseProps;
        }
        this.setState({ county: county, householdSize: householdSize, dateOfPurchase: dateOfPurchase, isFormNotSaved: false })
        this._saveForm();
    }

    getTooltipProps = (fieldName) => {
        return {
            focusVisible: this.state.focusVisible && this.state.focusFieldName === fieldName,
            onFocus: (ele) => {
                if (fieldName === "maxAllowedIncome" || fieldName === "annualGrossIncome" || fieldName === "purchasePrice" || fieldName === "interestRateHTFLoan" || fieldName === "assistanceHTF" || fieldName === "homeOwnerCashInvestment"
                    || fieldName === "totalMarketValue" || fieldName === "totalRestrictiveValue" || fieldName === "homeOwnerPurchasePrice" || fieldName === "firstMortageAmount" || fieldName === "firstMortageMonthlyPayment" || fieldName === "secondMortageAmount" || fieldName === "secondMortageMonthlyPayment" || fieldName === "totalMortageMonthlyPayment" || fieldName === "totalPITIU" || fieldName === "affordabilityRatio" || fieldName === "homeOwnerPriorHousingCost") {
                    this.setState({ focusField: ele.target, focusFieldName: fieldName, focusVisible: true })

                } else {
                    this.setState({ focusField: ele, focusFieldName: fieldName, focusVisible: true })
                }
            },
            onBlur: () => this.setState({ focusField: null, focusFieldName: "", focusVisible: false })                                        // validators={['required', 'isStringLength250', 'validAddress']}
        }
    }

    getFloat(value) {
        return value === '' || value === null || value === 'undefined' ? 0 : parseFloat(value, 10);
    }


    chechInView = (ele) => {
        var { focusField } = this.state
        if (focusField) {
            const { top: parentTop, bottom: parentBottom } = ele.getBoundingClientRect()
            const { top: childTop } = focusField.getBoundingClientRect()
            this.setState({ focusVisible: childTop + 25 > parentTop && childTop < parentBottom })
        }
    }
    /**
     * Handle Tab change
     */
    handleTabChange() {
        const { isFormNotSaved } = this.state;
        return isFormNotSaved
    }
    _saveForm() {
        const { contractActivity, match, AllContractActivity } = this.props;
        const loanId = contractActivity ? contractActivity._id : null;
        const { loanNumber, isFundsFromMutipleHTFContracts, ethincity, sweatEquity, landTrust, otherPublicAssistance, isPropertyForclosure, isHTFLoanStatisfied, firstMortageMonthlyPayment, secondMortageMonthlyPayment, totalPITIU, homeOwnerPriorHousingCost,isMigrated,dateOfPurchase,homeOwnerCashInvestment } = this.state;

        const isValRequired = !isMigrated || new Date(dateOfPurchase).getFullYear()>2009;
        const isLoanExist = AllContractActivity && AllContractActivity.filter(item => (item.loanNumber === loanNumber && parseInt(item._id, 10) !== parseInt(loanId, 10)));
        if (isLoanExist.length > 0) {
            this.props.showAlert("Record Number Already Exist");
            return
        } else {
            if (totalPITIU === "") {
                this.setState({ totalPITIU: null })
            }
            if (homeOwnerPriorHousingCost === "") {
                this.setState({ homeOwnerPriorHousingCost: null })
            }
            let urlData = match.path.split('/');
            const reportYear_id = urlData.length === 8 ? urlData[4] : null;
            this.setState({
                affordabilityRatio: (Math.round((this.state.totalPITIU / (this.state.annualGrossIncome / 12)) * 100))
            })
            let generalData = {
                ...this.state,
                totalMortageMonthlyPayment: parseInt(firstMortageMonthlyPayment, 10) + parseInt(secondMortageMonthlyPayment, 10),
                _id: contractActivity ? contractActivity._id : null,
                reportYear_id: reportYear_id,
                contractActivity: contractActivity ? contractActivity._id : null,
                homeOwnerCashInvestment: homeOwnerCashInvestment===null?0:homeOwnerCashInvestment
            }

            //check if date is valid
            //skip for null case - migrateddata
            if (this.state.dateOfPurchase&&this.state.dateOfPurchase!==null&&this.state.dateOfPurchase._isValid === false&&isValRequired) {
                return
            }

            //For grid save if only one loan is there for contract
            // let generalDataLoanSave = {}
            // let validationData = {}
            if (this.props.reportData.data && this.props.reportData.data.length === 1) {
                // let fielderrors = [];
                // fielderrors = ContractActivityValidations(this.props);
                ContractActivityValidations(this.props);

                // generalDataLoanSave = {
                //     reportYear_id: reportYear_id,
                //     fielderrors: fielderrors ? fielderrors.length : 0
                // }
                // validationData = {
                //     reportYear_id: reportYear_id,
                //     reportActivity: 3,
                //     errors: fielderrors
                // }
            }

            urlData.pop()
            let urlPath = urlData.join('/')

            if (isFundsFromMutipleHTFContracts === "" || ethincity === "" || sweatEquity === "" || landTrust === "" ||
                otherPublicAssistance === "" || isPropertyForclosure === "" || isHTFLoanStatisfied === "") {
                this.props.showErrorAlert("Select any option in the check box");
            } else {
                if (contractActivity && contractActivity._id) {
                    this.props.clearAlert()
                    this.props.updateContractActivity(generalData, () => {

                        //loan overall save
                        if (this.props.reportData.data && this.props.reportData.data.length === 1) {
                            // this.props.validationErrors(validationData);
                            // this.props.validationIssue(reportYear_id);
                            // this.props.saveContractActivity(generalDataLoanSave);
                        }

                        history.push({
                            pathname: `${urlPath}`,
                            reportYear_id: reportYear_id,
                            state: {
                                pageData: {
                                    reportYear_id: reportYear_id
                                }
                            }
                        });
                    });
                    // this.props.getByIdContractActivity(contractActivity._id);
                } else {
                    this.props.clearAlert()
                    this.props.createContractActivity(generalData, () => {

                        //loan overall save
                        if (this.props.reportData.data && this.props.reportData.data.length === 1) {
                            // this.props.validationErrors(validationData);
                            // this.props.validationIssue(reportYear_id);
                            // this.props.saveContractActivity(generalDataLoanSave);
                        }

                        history.push({
                            pathname: `${urlPath}`,
                            reportYear_id: reportYear_id,
                            state: {
                                pageData: {
                                    reportYear_id: reportYear_id
                                }
                            }
                        });
                    });
                }
                this.props.getByIdContractActivity(contractActivity._id);
            }
        }

    }

    render() {
        const { classes, pageAccess, counties, cities, homeTypes, bedrooms_ho, races, populationCategories, typeHTFActivities, HOPriorResidenceStatus, setAsideType_ho, location, contractActivity } = this.props;
        let setAsideType_hoLIH = setAsideType_ho.length > 0 ? setAsideType_ho.filter(item => item.category_id === 1) : [];
        let { loanNumber, isFundsFromMutipleHTFContracts, isLoan, homeType, numberOfBedrooms, address, city, county, ethincity, race, sweatEquity, landTrust, populationCategory, typeHTFActivity, dateOfPurchase, householdSize, maxAllowedIncome, annualGrossIncome, purchasePrice, interestRateHTFLoan, assistanceHTF, otherPublicAssistance, otherFundersAndAmt, homeOwnerCashInvestment, totalMarketValue, totalRestrictiveValue, homeOwnerPurchasePrice, firstMortageAmount, firstMortageMonthlyPayment, secondMortageAmount, secondMortageMonthlyPayment, totalMortageMonthlyPayment, totalPITIU, affordabilityRatio, homeOwnerPriorResidenceStatus, homeOwnerPriorHousingCost, isPropertyForclosure, isHTFLoanStatisfied, ami, snapshotData, isClosedLoan, isMigrated } = this.state;
        totalMortageMonthlyPayment = ((parseFloat(firstMortageMonthlyPayment) || 0) + (parseFloat(secondMortageMonthlyPayment) || 0)).toFixed(2);
        const isReadOnly = pageAccess.indexOf('SAVE') === -1;
        let isReadOly = !isReadOnly && snapshotData ? snapshotData : isReadOnly;
        otherFundersAndAmt = otherFundersAndAmt === 0 ? null : otherFundersAndAmt;
        const ethincities = contractActivityContants.ethincities
        const htfLoans = contractActivityContants.htfLoansSatisfied;

        //update value based on state
        dateOfPurchase = dateOfPurchase === null ? contractActivity && contractActivity.dateOfPurchase : dateOfPurchase;
        let countyValue = { value: county !== null ? county : contractActivity && contractActivity.address && contractActivity.address.county, id: county !== null ? county : contractActivity && contractActivity.address && contractActivity.address.county };
        console.log("🚀 ~ file: ContractActivityView.js:633 ~ ContractActivityView ~ render ~ dateOfPurchase:", dateOfPurchase)
        householdSize = householdSize === null ? this.props.contractActivity && this.props.contractActivity.householdSize : householdSize;

        //Remove field validations for migrated and dop less than 2009
        let isValRequired = !isMigrated?true:false;
        if(isValRequired===true){
        isValRequired = new Date(dateOfPurchase).getFullYear()>2009?true:false;
        }

        //update validation for PITI and homeownerpriorhousing for future years
        let homeownerPRSValidation = [];
        let homeownerPRSErrmsg = [];
        let homeownerPHCValidation = [];
        let homeownerPHCErrmsg = [];
        if (new Date(dateOfPurchase).getFullYear() >= 2022) {
            homeownerPRSValidation = ['required'];
            homeownerPRSErrmsg = [FieldValidationConstants.FINANCIAL_ACTIVITY.required];
            homeownerPHCValidation = ['required', 'dollarisValueTwoDecimal', 'greatthan0'];
            homeownerPHCErrmsg = [FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0];
        } else {
            homeownerPHCValidation = ['dollarisValueTwoDecimal'];
            homeownerPHCErrmsg = [FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal];
            if (totalPITIU === null) {
                totalPITIU = ""
            }
            if (homeOwnerPriorHousingCost === null) {
                homeOwnerPriorHousingCost = ""
            }
        }
        let selectedEthnicity = ethincities.filter(obj => obj.value === ethincity)
        if (selectedEthnicity[0])
            ethincity = selectedEthnicity[0]
        else
            ethincity = {}
        return (
            <Grid className={`${classes.activityContainer} loanContent`}>
                <ContentWrapper
                    // ref={(divElement) => { this.container = divElement }}
                    classes={classes}
                    // pageAccess={['DOWNLOAD']}
                    title={''}
                    needTitleBar={false}
                    isSubPage={true}
                    ischildPage={true}
                    needPadding={false}
                    titleBg={'white'}
                    pageName="reports"
                // onScrollChange={(ele) => { this.chechInView(ele) }}
                >
                    <div className={classes.rootEdit} >
                        <ValidatorForm
                            name="reportCoverPageForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={(e) => { this.handleSubmit(e) }}
                            onError={errors => console.log(errors)} >

                            {/* Grid Content */}
                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={12} align="left">
                                    <Grid item xs={3} sm={3}>
                                        <TextBox
                                            // type="number"
                                            toolTipClassName="topTextBox"
                                            name="loanNumber"
                                            label="Record Number"
                                            value={loanNumber}
                                            validators={['required', 'isStringLength30']}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN30, FieldValidationConstants.LOAN_ACTIVITY.validAddress1]}
                                            className={classes.textField}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("loanNumber")}
                                            toolTipTitle={focusHelper().loanNumber}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={isValRequired?"required-header":""} variant="body1">Was this activity funded with funds from multiple HTF contracts ?</Typography>
                                    <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                        <CheckboxValidator
                                            name={"isFundsFromMutipleHTFContracts"}
                                            checked={isFundsFromMutipleHTFContracts === true}
                                            // tooltipOnHover={!isReadOly}
                                            // focusVisible={this.state.focusVisible && this.state.focusFieldName === "isFundsFromMutipleHTFContracts"}
                                            // // {...this.getTooltipProps("isFundsFromMutipleHTFContracts")}
                                            toolTipTitle={focusHelper().wasThisLoanFunded}
                                            value={isFundsFromMutipleHTFContracts}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            label={"Yes"}
                                            color={"primary"}
                                        />
                                        <CheckboxValidator
                                            name={"isFundsFromMutipleHTFContracts"}
                                            checked={isFundsFromMutipleHTFContracts === false}
                                            value={isFundsFromMutipleHTFContracts}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                            disabled={isReadOly}
                                            toolTipTitle={focusHelper().wasThisLoanFunded}
                                            // tooltipOnHover={!isReadOly}
                                            // focusVisible={this.state.focusVisible && this.state.focusFieldName === "isFundsFromMutipleHTFContracts"}
                                            validators={isValRequired?['required']:[]}
                                            label={"No"}
                                            color={"primary"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className={isValRequired?"required-header":""} variant="body1">Is this a Loan or Grant?</Typography>
                                    <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                        <CheckboxValidator
                                            name={"isLoan"}
                                            checked={isLoan === true}
                                            toolTipTitle={focusHelper().loanOrgrant}
                                            value={isLoan}
                                            disabled={isReadOly}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                            validators={isValRequired?['required']:[]}
                                            label={"Loan"}
                                            color={"primary"}
                                        />
                                        <CheckboxValidator
                                            name={"isLoan"}
                                            checked={isLoan === false}
                                            value={isLoan}
                                            toolTipTitle={focusHelper().loanOrgrant}
                                            disabled={isReadOly}
                                            handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                            validators={isValRequired?['required']:[]}
                                            label={"Grant"}
                                            color={"primary"}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Type of Home"
                                            name="homeType"
                                            suggestions={homeTypes}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("homeType")}
                                            toolTipTitle={focusHelper().typeOfHome}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_homeType(selectedOption) }}
                                            selectedValue={{ value: homeType, id: homeType }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            toolTipClassName="topTextBoxLeftAlign"
                                            placeholder="Numbers of Bedrooms"
                                            name="numberOfBedrooms"
                                            suggestions={bedrooms_ho}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("numberOfBedrooms")}
                                            toolTipTitle={focusHelper().noOfBedroom}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_bedrooms(selectedOption) }}
                                            selectedValue={{ value: numberOfBedrooms, id: numberOfBedrooms }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            name="address"
                                            label="Street Address"
                                            value={address}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("address")}
                                            toolTipTitle={focusHelper().homeAddress}
                                            validators={isValRequired?['required', 'isStringLength500']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.STR_LEN500]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            placeholder="City"
                                            name="city"
                                            suggestions={cities}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_city(selectedOption) }}
                                            selectedValue={{ value: city, id: city }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={4} align="right" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            placeholder="County"
                                            name="county"
                                            suggestions={counties}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_county(selectedOption) }}
                                            selectedValue={countyValue}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Race"
                                            name="race"
                                            suggestions={races}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_race(selectedOption) }}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("race")}
                                            toolTipTitle={focusHelper().race}
                                            selectedValue={{ value: race, id: race }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} align="left" className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Ethnicity"
                                            name="ethincity"
                                            suggestions={ethincities}
                                            {...this.getTooltipProps("ethincity")}
                                            toolTipTitle={focusHelper().Ethnicity}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_ethnicity(selectedOption) }}
                                            selectedValue={ethincity}
                                            validators={isValRequired?['required']:[]}
                                            disabled={isReadOly}
                                            tooltipOnHover={!(isReadOly)}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={4} align="right" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} align="left" className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Population Category"
                                            name="populationCategory"
                                            suggestions={populationCategories}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("populationCategory")}
                                            toolTipTitle={focusHelper().populationCate}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_populationCategory(selectedOption) }}
                                            selectedValue={{ value: populationCategory, id: populationCategory }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"

                                            placeholder="Type of HTF activity"
                                            name="typeHTFActivity"
                                            suggestions={typeHTFActivities}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("typeHTFActivity")}
                                            toolTipTitle={focusHelper().HTFFunding}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_typeHTFActivity(selectedOption) }}
                                            selectedValue={{ value: typeHTFActivity, id: typeHTFActivity }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className={isValRequired?"required-header":""} variant="body1">Sweat Equity ?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"sweatEquity"}
                                                checked={sweatEquity === true}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "sweatEquity"}
                                                value={sweatEquity}
                                                // tooltipOnHover={!isReadOly}
                                                toolTipTitle={focusHelper().sweatEquity}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                disabled={isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"sweatEquity"}
                                                checked={sweatEquity === false}
                                                value={sweatEquity}
                                                toolTipTitle={focusHelper().sweatEquity}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "sweatEquity"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                disabled={isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className={isValRequired?"required-header":""} variant="body1">Land trust ?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"landTrust"}
                                                checked={landTrust === true}
                                                // tooltipOnHover={!isReadOly}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "landTrust"}
                                                toolTipTitle={focusHelper().landTrust}
                                                value={landTrust}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                disabled={isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"landTrust"}
                                                checked={landTrust === false}
                                                toolTipTitle={focusHelper().landTrust}
                                                value={landTrust}
                                                focusVisible={this.state.focusVisible && this.state.focusFieldName === "landTrust"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                disabled={isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/* Project contractor */}
                                <Grid item xs={12}>
                                    <h4 className="section_title contractActivitySecTitle">{'Date of purchase household size max allowed income and Annual gross income'}</h4>
                                </Grid>

                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <Tooltip disableHoverListener={true} className="tooltiptip" title={focusHelper().dateOfPurchase || ""} placement="top-start" arrow>
                                            <DatePicker
                                                name="dateOfPurchase"
                                                value={dateOfPurchase}
                                                label="Date of Purchase"
                                                className={classes.textField}
                                                dateOfPurchase
                                                tooltipOnHover={!(isReadOly)}
                                                {...this.getTooltipProps("dateOfPurchase")}
                                                toolTipTitle={focusHelper().dateOfPurchase}
                                                handleChange={(name, value) => { this.handleAutoComplete_dop(name, value) }}
                                                margin="dense"
                                                disabled={isReadOly} selectedOption
                                                validators={isValRequired?['required']:[]}
                                                errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.invalidDate]}

                                            />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10}>
                                        <AutoComplete
                                            placeholder="AMI"
                                            name="ami"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            toolTipTitle={focusHelper().AMI}
                                            {...this.getTooltipProps("AMI")}
                                            tooltipOnHover={!isReadOly}
                                            suggestions={setAsideType_hoLIH}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_ami(selectedOption) }}
                                            selectedValue={{ value: ami, id: ami }}
                                            disabled={isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="householdSize"
                                            label="Household Size"
                                            type="number"
                                            value={householdSize}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("householdSize")}
                                            toolTipTitle={focusHelper().houseHoldSize}
                                            validators={isValRequired?['required', 'isValue0To30NotDecimal']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.FINANCIAL_ACTIVITY.isValue0To30NotDecimal]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChangeHouseholdSize(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} >
                                    <Grid container direction="row" justifyContent="start" spacing={5}>

                                        <Grid item xs={12} sm={6} align="left" title={this.getMaxIncomeTitle()}>
                                            {/* <Grid item xs={12} sm={10} title={this.getMaxIncomeTitle()}> */}
                                            <NumberField
                                                // type="number"
                                                name="maxAllowedIncome"
                                                label="Max allowed income of household at time of purchase"
                                                value={maxAllowedIncome || maxAllowedIncome === 0 ? maxAllowedIncome : 'N/A'}
                                                tooltipOnHover={false}
                                                {...this.getTooltipProps("maxAllowedIncome")}
                                                toolTipTitle={focusHelper().maxAlloIncome}
                                                validators={isValRequired?['required']:[]}
                                                errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                                className={classes.textField}
                                                handleChange={(name, value) => { this.handleChange(name, value) }}
                                                margin="dense"
                                                disabled={true}
                                                style={{ width: "100%" }}
                                                prefix={maxAllowedIncome || maxAllowedIncome === 0 ? '$' : ''}
                                            />
                                        </Grid>
                                        {/* </Grid> */}
                                        <Grid item xs={12} sm={6} align="right">
                                            {/* <Grid item xs={12} sm={10}> */}
                                            <NumberField
                                                // type="number"
                                                name="annualGrossIncome"
                                                label="Actual Annual Gross income at time of purchase"
                                                value={annualGrossIncome}
                                                tooltipOnHover={!(isReadOly)}
                                                {...this.getTooltipProps("annualGrossIncome")}
                                                toolTipTitle={focusHelper().annualgross}
                                                validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                                errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                                className={classes.textField}
                                                handleChange={(name, value) => { this.handleChange(name, value) }}
                                                margin="dense"
                                                disabled={isReadOly}
                                                style={{ width: "100%" }}
                                                toolTipClassName="topTextBoxLeftAlign"
                                            />
                                            {/* </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>


                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="purchasePrice"
                                            label="Purchase Price"
                                            value={purchasePrice}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("purchasePrice")}
                                            toolTipTitle={focusHelper().purchasePrice}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="interestRateHTFLoan"
                                            label="Interest Rate of HTF Activity %"
                                            value={interestRateHTFLoan === 0 ? '0' : interestRateHTFLoan}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("interestRateHTFLoan")}
                                            toolTipTitle={focusHelper().HTFLoanInterest}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                            zeroFlag={true}
                                            suffix={"%"}
                                            prefix={""}
                                            thousandSeparator={''}
                                            toolTipClassName="topTextBoxLeftAlign"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="assistanceHTF"
                                            label="HTF Assistance"
                                            value={assistanceHTF}
                                            HTFAssistance
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("assistanceHTF")}
                                            toolTipTitle={focusHelper().HTFAssistance}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className={isValRequired?"required-header":""} variant="body1">Other Public Assistance</Typography>
                                        <Grid container alignItems="center" className={classes.inlineCheckBoxes}>
                                            <CheckboxValidator
                                                name={"otherPublicAssistance"}
                                                checked={otherPublicAssistance === true}
                                                toolTipTitle={focusHelper().otherPublicAssist}
                                                // tooltipOnHover={!isReadOly}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "otherPublicAssistance"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                value={otherPublicAssistance}
                                                disabled={isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"otherPublicAssistance"}
                                                checked={otherPublicAssistance === false}
                                                value={otherPublicAssistance}
                                                toolTipTitle={focusHelper().otherPublicAssist}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "otherPublicAssistance"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                disabled={isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={8} align="right">
                                    <Grid item xs={12} sm={12} className={classes.mw96}>
                                        <TextBox
                                            name="otherFundersAndAmt"
                                            label="Please identify other funder(s) and amount(s)"
                                            gotFocus={this.handleFocus}
                                            outFocus={this.handleOutFocus}
                                            className={this.state.otherPublicAssistance === false && this.state.updateColor === "" ? `${classes.textField} loanactNEW` : this.state.otherPublicAssistance === false && this.state.updateColor === true ? `${classes.textField} loanactNEWFocus` : this.state.otherPublicAssistance === true ? classes.textField : "norPUB"}
                                            value={otherFundersAndAmt === 0 ? '0' : otherFundersAndAmt}
                                            validators={isValRequired?otherPublicAssistance ? ['required'] : []:[]}
                                            errorMessages={otherPublicAssistance ? [FieldValidationConstants.FINANCIAL_ACTIVITY.required_publicassistance] : []}
                                            // className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                            maxLength={1000}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="homeOwnerCashInvestment"
                                            label="Homeowner Cash Investment"
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("homeOwnerCashInvestment")}
                                            toolTipTitle={focusHelper().homeBuyerCashAssist}
                                            focusVisible={this.state.focusVisible && this.state.focusFieldName === "homeOwnerCashInvestment"}
                                            // handleChange={(name, checked, ele) => { this.handleChange(name, checked ? false : true, ele) }}
                                            value={homeOwnerCashInvestment === 0 ? '0' : homeOwnerCashInvestment}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required_nocash, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalMarketValue"
                                            label="Total Market Value"
                                            value={totalMarketValue}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("totalMarketValue")}
                                            toolTipTitle={focusHelper().totalMarketValue}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                            toolTipClassName="topTextBoxLeftAlign"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="totalRestrictiveValue"
                                            label="Total Restrictive Value"
                                            value={totalRestrictiveValue}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("totalRestrictiveValue")}
                                            toolTipTitle={focusHelper().totalRestrictiveValue}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="homeOwnerPurchasePrice"
                                            label="Homeowner Purchase Price"
                                            value={homeOwnerPurchasePrice}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("homeOwnerPurchasePrice")}
                                            toolTipTitle={focusHelper().ownerPurchasePrice}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="firstMortageAmount"
                                            label="1st Mortage Amount"
                                            value={firstMortageAmount}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("firstMortageAmount")}
                                            toolTipTitle={focusHelper().firstMortageAmount}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="firstMortageMonthlyPayment"
                                            label="1st Mortage Monthly Payment"
                                            value={firstMortageMonthlyPayment}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("firstMortageMonthlyPayment")}
                                            toolTipTitle={focusHelper().monthlyFirstMortage}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="secondMortageAmount"
                                            label="2nd Mortage Amount"
                                            value={secondMortageAmount === 0 ? '0' : secondMortageAmount}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("secondMortageAmount")}
                                            toolTipTitle={focusHelper().secondMortage}
                                            validators={isValRequired?['dollarisValueTwoDecimal']:[]}
                                            errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                            zeroFlag={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="secondMortageMonthlyPayment"
                                            label="2nd Mortage Monthly Amount"
                                            value={secondMortageMonthlyPayment === 0 ? '0' : secondMortageMonthlyPayment}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("secondMortageMonthlyPayment")}
                                            toolTipTitle={focusHelper().monthlySecondMortage}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            validators={isValRequired?['dollarisValueTwoDecimal']:[]}
                                            errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal]}
                                            disabled={isReadOly}
                                            zeroFlag={true}
                                            toolTipClassName="topTextBoxLeftAlign"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalMortageMonthlyPayment"
                                            label="Total Monthly Mortage  Payment"
                                            value={totalMortageMonthlyPayment ? totalMortageMonthlyPayment : (parseFloat(firstMortageMonthlyPayment || 0) + parseFloat(secondMortageMonthlyPayment || 0))}
                                            tooltipOnHover={false}
                                            {...this.getTooltipProps("totalMortageMonthlyPayment")}
                                            toolTipTitle={focusHelper().totalMonthlyMortage}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="totalPITIU"
                                            label="Total Principle,Interest,Taxes&Insurance+Utilities"
                                            value={totalPITIU}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("totalPITIU")}
                                            toolTipTitle={focusHelper().totalPrincipleInterestTax}
                                            validators={isValRequired?['required', 'dollarisValueTwoDecimal', 'greatthan0']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required, FieldValidationConstants.LOAN_ACTIVITY.isValueTwoDecimal, FieldValidationConstants.LOAN_ACTIVITY.greatthan0]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10}>
                                        <TextBox
                                            // type="number"
                                            name="affordabilityRatio"
                                            label="Affordability Ratio %"
                                            value={annualGrossIncome && totalPITIU ? `${(Math.round((totalPITIU / (annualGrossIncome / 12)) * 100))}%` : `${affordabilityRatio}%`}
                                            tooltipOnHover={false}
                                            {...this.getTooltipProps("affordabilityRatio")}
                                            toolTipTitle={focusHelper().affordabilityRatio}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={true}
                                        // zeroFlag={true}
                                        // fixedDecimalScale={false}
                                        // thousandSeparator=""
                                        // decimalScale={0}
                                        // prefix=""
                                        // suffix="%"
                                        />
                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={12} sm={6} align="left" /> */}

                                <Grid item xs={12} sm={4} align="right" className={"autoCompleteTop"}>
                                    <Grid item xs={12} sm={10} className={classes.autoCompleteTop}>
                                        <AutoComplete
                                            placeholder="Homeowner Prior Residence Status"
                                            toolTipClassName="topTextBoxLeftAlign"
                                            name="homeOwnerPriorResidenceStatus"
                                            suggestions={HOPriorResidenceStatus}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("homeOwnerPriorResidenceStatus")}
                                            toolTipTitle={focusHelper().homeOwnerResidenceStatus}
                                            handleAutoCompolete={(selectedOption) => { this.handleAutoComplete_HOPriorResidenceStatus(selectedOption) }}
                                            selectedValue={{ value: homeOwnerPriorResidenceStatus, id: homeOwnerPriorResidenceStatus }}
                                            disabled={isReadOly}
                                            validators={homeownerPRSValidation}
                                            errorMessages={homeownerPRSErrmsg}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4} align="left">
                                    <Grid item xs={12} sm={10}>
                                        <NumberField
                                            // type="number"
                                            name="homeOwnerPriorHousingCost"
                                            label="Homeowner prior housing cost"
                                            value={homeOwnerPriorHousingCost}
                                            tooltipOnHover={!(isReadOly)}
                                            {...this.getTooltipProps("homeOwnerPriorHousingCost")}
                                            toolTipTitle={focusHelper().homeOwnerResidencePrior}
                                            validators={homeownerPHCValidation}
                                            errorMessages={homeownerPHCErrmsg}
                                            className={classes.textField}
                                            handleChange={(name, value) => { this.handleChange(name, value) }}
                                            margin="dense"
                                            disabled={isReadOly}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} sm={4}>
                            </Grid> */}
                                <Grid item xs={12} sm={4} align="center">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className={isValRequired?"required-header":""} variant="body1">Was Property Foreclosed?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"isPropertyForclosure"}
                                                checked={isPropertyForclosure === true}
                                                toolTipTitle={focusHelper().isPropertyForclosure}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "isPropertyForclosure"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                value={isPropertyForclosure}
                                                disabled={!isReadOnly && snapshotData ? false : isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"isPropertyForclosure"}
                                                checked={isPropertyForclosure === false}
                                                value={isPropertyForclosure}
                                                toolTipTitle={focusHelper().isPropertyForclosure}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "isPropertyForclosure"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                disabled={!isReadOnly && snapshotData ? false : isReadOly}
                                                validators={isValRequired?['required']:[]}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} sm={4} align="right">
                                    <Grid item xs={12} sm={10} align="left">
                                        <Typography className="required-header" variant="body1">HTF Loan Satisfied?</Typography>
                                        <Grid container alignItems="center" className={`${classes.inlineCheckBoxes} ethincityCheckbox`}>
                                            <CheckboxValidator
                                                name={"isHTFLoanStatisfied"}
                                                checked={isHTFLoanStatisfied === true}
                                                value={isHTFLoanStatisfied}
                                                toolTipTitle={focusHelper().isHTFLoanStatisfied}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "isHTFLoanStatisfied"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? true : false) }}
                                                disabled={!isReadOnly && snapshotData ? isClosedLoan : isReadOly}
                                                validators={['required']}
                                                label={"Yes"}
                                                color={"primary"}
                                            />
                                            <CheckboxValidator
                                                name={"isHTFLoanStatisfied"}
                                                checked={isHTFLoanStatisfied === false}
                                                value={isHTFLoanStatisfied}
                                                toolTipTitle={focusHelper().isHTFLoanStatisfied}
                                                // focusVisible={this.state.focusVisible && this.state.focusFieldName === "isHTFLoanStatisfied"}
                                                handleChange={(name, checked) => { this.handleChange(name, checked ? false : true) }}
                                                disabled={!isReadOnly && snapshotData ? isClosedLoan : isReadOly}
                                                validators={['required']}
                                                label={"No"}
                                                color={"primary"}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                                <Grid item xs={12} sm={4} align="right" className={"HTFContractActivity"}>
                                    <Grid item xs={12} sm={10} align="left" className={"autoCompleteTop"}>
                                        <AutoComplete
                                            toolTipClassName="autocomplteTextBox"
                                            placeholder="Is the HTF Loan Satisfied?"
                                            name="isHTFLoanStatisfied"
                                            suggestions={htfLoans}
                                            {...this.getTooltipProps("isHTFLoanStatisfied")}
                                            toolTipTitle={focusHelper().isHTFLoanStatisfied}
                                            handleAutoCompolete={this.handleAutoComplete_HFT}
                                            selectedValue={{ value: isHTFLoanStatisfied, id: isHTFLoanStatisfied }}
                                            disabled={!isReadOnly && snapshotData ? false : isReadOly}
                                            validators={isValRequired?['required']:[]}
                                            errorMessages={[FieldValidationConstants.FINANCIAL_ACTIVITY.required]}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {(!isReadOnly && snapshotData ? false : isReadOly) ? null : <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }} />}

                        </ValidatorForm>
                    </div>
                </ContentWrapper>
            </Grid >
        );
    }
}
/**
 * Bind Component Property Types
 */
ContractActivityView.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

ContractActivityView.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        contractActivity: state.reportActivity.contractActivityData.data,
        cities: state.configuration.configurations[configurationConstants.cities] || [],
        counties: state.configuration.configurations[configurationConstants.counties] || [],
        homeTypes: state.configuration.configurations[configurationConstants.homeType] || [],
        bedrooms_ho: state.configuration.configurations[configurationConstants.bedrooms_ho] || [],
        races: state.configuration.configurations[configurationConstants.race] || [],
        populationCategories: state.configuration.configurations[configurationConstants.populationCategoryHO] || [],
        typeHTFActivities: state.configuration.configurations[configurationConstants.typeHTFActivityHO] || [],
        HOPriorResidenceStatus: state.configuration.configurations[configurationConstants.HOPriorResidenceStatus] || [],
        setAsideType_ho: state.configuration.configurations[configurationConstants.setAsideType_ho] || [],
        incomeData: state.reportActivity.incomeData ? state.reportActivity.incomeData.data : 0,
        pageAccess: state.pageAccess['contractActivityView'] || [],
        reportCurrentLevel: state.reportActivity.reportTableAccess.reportLevel,
        reportData: state.reportActivity.contractActivity,
        programActivity: state.reportActivity.programActivity.data,
        financialActivity: state.reportActivity.financialActivity.data,
        AllContractActivity: state.reportActivity.contractActivity.data
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getReportActivityAccess: (reportYear_id, successCallBack) => dispatch(reportActivityActions.getReportActivityAccess(reportYear_id, successCallBack)),
        getByIdContractActivity: (loan_id) => dispatch(reportActivityActions.getByIdContractActivity(loan_id)),
        createContractActivity: (formData) => dispatch(reportActivityActions.createContractActivity(formData)),
        updateContractActivity: (formData, callback) => dispatch(reportActivityActions.updateContractActivity(formData, callback)),
        getConfiguration: (type) => dispatch(confActions.getConfigurationContractActivity(type)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearAlert: () => dispatch(alertActions.clear()),
        getIncomeLimit: (generalData, successCallBack) => dispatch(reportActivityActions.getIncomeLimit(generalData, successCallBack)),
        getContractActivity: (skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack) => dispatch(reportActivityActions.getContractActivity(skip, limit, orderBy, order, sField, sValue, reportYear_id, successCallBack)),
        getByIdProgramActivity: (reportYear_id) => dispatch(reportActivityActions.getByIdProgramActivity(reportYear_id)),
        getByIdFinancialActivity: (reportYear_id) => dispatch(reportActivityActions.getByIdFinancialActivity(reportYear_id)),
        saveContractActivity: (generalData, successCallBack) => dispatch(reportActivityActions.saveContractActivity(generalData, successCallBack)),
        validationErrors: (validationErrors) => dispatch(validationActions.activityValidations(validationErrors)),
        validationIssue: (reportYear_id) => dispatch(reportActivityActions.validationIssue(reportYear_id)),
        showAlert: (message) => dispatch(alertActions.error(message)),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(ContractActivityView));
