import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import UsersList from "./UsersList";
import UsersNew from "./UsersNew";
import UsersView from "./UsersView";

/**
 * UsersRouter Component
 * @class UsersRouter
 * @extends {Component}
 */
class UsersRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <UsersList {...props} page={'users'} />
                }} />
                <Route exact path={`${match.url}/new`} component={UsersNew} />
                <Route path={`${match.url}/:userId`} component={UsersView} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default UsersRouter;
