//import notification
import { authHeader, globalVariable, handleResponse } from '../helpers';

export const notificationService = {
    getByUserId,
    updateNotification,
    deleteNotification,
    getAlertByUserId,
    deleteAlertNotification,
    updateAlertNotification
};



/**
 * Get All notification Lists
 */
function getByUserId() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${globalVariable.apiURL}/notification`, requestOptions).then(handleResponse);
}

function getAlertByUserId(){
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() },
    };

    return fetch(`${globalVariable.apiURL}/notificationAlert`, requestOptions).then(handleResponse);
}


/**
 * A method to Update Notification
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
function updateNotification(formData) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        return fetch(`${globalVariable.apiURL}/notification/update`, requestOptions).then(handleResponse);
}


/**
 * A method to Update Alert Notification
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
 function updateAlertNotification(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    return fetch(`${globalVariable.apiURL}/notification/updatealert`, requestOptions).then(handleResponse);
}

/**
 * A method to Delete Notification
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
function deleteNotification() {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader() },
    };
    return fetch(`${globalVariable.apiURL}/notification/delete`, requestOptions).then(handleResponse);
}



/**
 * A method to Delete Alert Notification
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
 function deleteAlertNotification() {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader() },
    };
    return fetch(`${globalVariable.apiURL}/notification/deletealert`, requestOptions).then(handleResponse);
}