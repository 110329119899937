import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Materia lUI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helper Constants
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextBox from '../../../components/TextBox/TextBox';
import { alertActions } from '../../../actions';

const lenderData = {
    name: '',
    desc: '',
    isActive: true
};
/**
 * Editable text validation component
 * @class EditableTextValidation
 * @extends { Component}
 * @memberof Lenders
 */
class EditableTextValidation extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-value-' + rowIndex}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * EditableText component
 * @class EditableText
 * @extends { Component}
 * @memberof Lenders
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-desc-' + rowIndex}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};
/**
 * Status Component
 * @class StatusComponent
 * @extends { Component}
 * @memberof Lenders
 */
class StatusComponent extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (<Switch
            color="primary"
            checked={data[fieldName]}
            onChange={event => {
                onChange(fieldName, rowIndex, event.target.checked);
            }}
        />);
    }
}
/**
 * StatusComponent default props
 */
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    onChange: () => { },
    data: {}
}

const componentMap = {
    name: EditableTextValidation,
    desc: EditableText,
    isActive: StatusComponent
}
/**
 * Lenders component
 * @class Lenders
 * @extends { Component}
 */
class Lenders extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            lenders: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.lenders);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { lenders } = nextProps;
        this.setState({
            saved: true,
            lenders: lenders
        });
    }
    /**
     * handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate lender names
        let lenders = this.state.lenders;
        let duplicateName=''
        let lendersNames = lenders.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicateLenders = lendersNames.some(function(item, idx){ 
                    if(lendersNames.indexOf(item) !== idx){
                        duplicateName=lenders[lendersNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicateLenders){
            this.props.erroralert(`${duplicateName.toUpperCase()} lender already exists`);
        }else{
        this.props.updatedata(configurationConstants.lenders, this.state.lenders);
        }
    }
    /**
     * cell Component OnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { lenders } = this.state;
        switch (fieldName) {
            case "isCommentReq":
                lenders[rowIndex][fieldName] = value;
                this.setState({ lenders });
                this.props.updatedata(configurationConstants.lenders, this.state.lenders)
                break;
            default:
                lenders[rowIndex][fieldName] = value;
                this.setState({ lenders });
        }
    }

    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { lenders } = this.state;
        // [1]
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (

            <ValidatorForm
                name="lenders"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >

                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'LENDERS'}
                        headerCols={['NAME', 'DESCRIPTION', 'STATUS']}
                        requiredFields={['NAME']}
                        fieldCols={['name', 'desc', 'isActive']}
                        rowDatas={lenders}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        style={style}
                        staticHeader={true}
                        height={height}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (lenders.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    lenders: [
                                        {
                                            ...lenderData,
                                            key: Math.max(...lenders.map(elt => elt.key)) + 1,
                                            type: configurationConstants.lenders
                                        },
                                        ...lenders
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            if (item && item._id !== undefined)
                                this.props.deleteConfig(item.type, item._id);
                            else {
                                let changedArray = lenders.filter(i => i._id);
                                this.setState({ lenders: changedArray });
                            }
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.lenders) }}
                />
            </ValidatorForm>

        );
    }
}
/**
 * Props types
 */
Lenders.propTypes = {
    classes: PropTypes.object.isRequired,

};
/**
 * Lenders default props
 */
Lenders.defaultProps = {
    pageAccess: []
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        lenders: configuration.configurations[configurationConstants.lenders] || [],
        isLoading: configuration.isLoading

    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        getByName: (lenders) => dispatch(confActions.getByName(lenders)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) => dispatch(alertActions.error(msg))
    }
};
/**
 * Export Lenders
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Lenders));
