import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import Grid from '@material-ui/core/Grid';

// Import Components
import NumberField from '../../components/NumberField/NumberField';
import SaveCancel from "../../components/SaveCancel/SaveCancel";
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import TextBox from "../../components/TextBox/TextBox";

// Import Actions
import { buildingActions } from '../../actions/building';
import { confActions } from "../../actions/configuration";

// Import Helpers
import { configurationConstants } from '../../helpers/appConstants';
import { history } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";

/**
 *Buildings Edit page
 *
 * @class BuildingEdit
 * @extends {Component}
 */
class BuildingEdit extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            buildingid: '',
            buildingname: '',
            bin: '',
            address: '',
            zipcode: '',
            siteId: '',
            city: null,
            originalbuilddate: '',
            effectiveyearbuilt: '',
            units: '',
            funders: ''
        };
    }
    /**
     *Component Did Mount
     * @memberof BuildingEdit
     * 
     * [1]  -   Get building by id
     * [2]  -   Binding the innerRef
     * [3]  -   Get Cities
     */
    componentDidMount() {
        const { isArchived } = this.props
        // [1]
        if (this.props.buildingId) {
            this.props.getById(this.props.buildingId, isArchived ? 'archives' : null);
        }
        // [2]
        if (this.props.innerRef) {
            this.props.innerRef(this);
        }
        // [3]
        this.props.getConfiguration(configurationConstants.cities);
    }
    /**
     *Component Will Recieve Props
     * @param {*} nextProps
     * @memberof BuildingEdit
     * 
     * [1]  -   Set Building details to state
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne } = nextProps;
        if (getOne && getOne.data && this.props.buildingId) {
            this.setState({
                buildingid: getOne.data._id,
                buildingname: getOne.data.name,
                bin: getOne.data.bin === null ? '' : getOne.data.bin,
                address: getOne.data.address.line,
                city: getOne.data.address.city,
                zipcode: getOne.data.address.zipcode,
                originalbuilddate: getOne.data.originalBuildDate,
                effectiveyearbuilt: getOne.data.effectiveYearBuilt,
                units: getOne.data.units,
                funders: getOne.data.funders,
            })
        }
    }
    /**
     * Handle input change
     * @param {string} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle autocomplete change
     * @param {*} selectedOption 
     */
    _handleAutoComplete = (selectedOption) => {
        this.setState({ city: selectedOption.value });
    }
    /**
     * Hangle validationform submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this.saveForm();
    };
    /**
     * On form save
     */
    saveForm() {
        const generalData = {
            siteId: this.state.siteId,
            _id: this.state.buildingid,
            buildingname: this.state.buildingname,
            bin: this.state.bin,
            address: this.state.address,
            zipcode: this.state.zipcode,
            city: this.state.city,
            originalbuilddate: this.state.originalbuilddate,
            effectiveyearbuilt: this.state.effectiveyearbuilt,
            units: this.state.unit,
            funders: this.state.funders,
        };
        this.props.handleFormSaveActionClick(generalData);
    }
    /**
     *render html
     *
     * @returns
     * @memberof BuildingEdit
     */
    render() {

        const { buildingname, bin, address, city, zipcode, originalbuilddate, effectiveyearbuilt, units } = this.state;
        const { isReadOly, classes, pageAccess, cities } = this.props;
        let propOnly = pageAccess.indexOf('PROP_ONLY_FIELDS') !== -1;
        return (
            <div className={classes.rootEdit} >
                <ValidatorForm
                    name="BuildingForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="buildingname"
                                value={buildingname}
                                label="Building Name"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="bin"
                                value={bin}
                                label="BIN"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="address"
                                value={address}
                                label="Address"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                placeholder='City'
                                suggestions={cities}
                                handleAutoCompolete={this._handleAutoComplete}
                                selectedValue={{ value: city, id: city }}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="zipcode"
                                value={zipcode}
                                label="Zip Code"
                                className={`${classes.textField} builingZip m-t-2`}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="originalbuilddate"
                                value={originalbuilddate}
                                label="Original Build Year"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={!propOnly}
                                placeholder={'YYYY'}
                                format={'####'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="effectiveyearbuilt"
                                value={effectiveyearbuilt}
                                label="Effective Year Built"
                                placeholder={'YYYY'}
                                className={`${classes.textField} buildeffectiveyearbuilt`}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={!propOnly}
                                format={'####'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="units"
                                value={units}
                                label="No of units"
                                className={`${classes.textField} builingZip m-t--1`}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                    </Grid>
                    {
                        pageAccess.indexOf('SAVE') > -1 || propOnly ? (
                            <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }} />)
                            : null
                    }
                </ValidatorForm>
            </div>
        );
    }
}
// prop types
BuildingEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
}
// default props
BuildingEdit.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: []
}
/**
 * Map state to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        building: state.buildings,
        getOne: state.buildings.getOne,
        cities: state.configuration.configurations[configurationConstants.cities] || []
    }
};
/**
 * Map dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getById: (id, type) => dispatch(buildingActions.getById(id, type)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type))
    }
};
// export BuildingEdit
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BuildingEdit);
