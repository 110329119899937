import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { AppBar, Badge, Menu, MenuItem, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

//import Component
import ToolTip from '../../../components/ToolTip/ToolTip';
import Maintenance from '../../../containers/IncomeRent/IncomeRentProcess/Maintenence';
import Logs from '../../../containers/IncomeRent/Logs/Logs';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';

// SVG Icons
import { HelpIcon } from '../../../components/SvgIcons/SvgIcons';

// Import Helpers
import { history } from '../../../helpers';
import { getConfigurationContentHeight } from "../../../helpers/global";

// Import Actions
import { uploadActions } from '../../../actions/uploads';
import { maintenanceActions } from '../../../actions/maintenance';
import { searchActions } from '../../../actions';

/**
 * Upload Income and Rent Limit Component in Configurations
 * 
 * @class UploadRentIncomeLimit
 * @extends {React.Component}
 */
class UploadRentIncomeLimit extends Component {
    /**
     * Construnctor
     * @param {*} props 
     * 
     * @memberof UploadRentIncomeLimit
     */
    constructor(props) {
        super(props);
        this.state = {
            currentTab: '0',
            anchorEl: null
        }
    }

    /**
     * A Methos to change the tab
     * @param {*} event 
     * @param {*} newValue 
     * 
     * @memberof UploadRentIncomeLimit
     */
    handleTabChange = (event, newValue) => {
        this.setState({ currentTab: newValue })
    }

    /**
     * A method to dowmload templates
     * @param {*} type 
     * 
     * @memberof UploadRentIncomeLimit
     */
    handleTemplateDownload = (type) => {
        this.props.downloadTemplates('', type);
        this.setState({ anchorEl: null })
    }

    /**
     * Bind HTML to DOM
     * @memberof UploadRentIncomeLimit
     */
    render() {
        let { classes, location: { pathname } } = this.props;
        const { currentTab, anchorEl } = this.state;
        return (
            <ContentWrapper
                classes={classes}
                title="Import Income & Rent Limit"
                needPadding={false}
                pageAccess={currentTab === '1' ? ['CLEAR', 'DOWNLOAD'] : ['DOWNLOAD']}
                isConfigurationPage={true}
                downloadColor='secondary'
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleDownloadActionClick={(event) => this.setState({ anchorEl: event.currentTarget })}
            >
                <TabContext value={currentTab}>
                    <AppBar position="static" color="default">
                        <TabList
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab
                                label={
                                    <div className={'displayFlex width-100-per jcSpaceBetween p-l-r-24'}>
                                        <div><Badge badgeContent={1} color="primary" className={`badgeMedium ${currentTab === "0" ? "activeTab" : "inactiveTab"}`} /></div>
                                        <div className={"width-100-per"}>Maintenance & Backup </div>
                                        <div>
                                            <ToolTip title={"Turning on maintenance mode will shut down the server and initiate data backup. Only the user turning on the maintenance mode will be given read and write access. Once the files are uploaded successfully the user can verify the data and run the CRON."} placement="top">
                                                <Badge badgeContent={<HelpIcon fill='#fff' />} className={`badgeMedium activeTab  roundCircle`} />
                                            </ToolTip>
                                        </div>
                                    </div>
                                }
                                value={'0'}
                            />
                            <Tab
                                label={
                                    <div className={'displayFlex width-100-per jcSpaceBetween p-l-r-24'}>
                                        <div><Badge badgeContent={2} color="primary" className={`badgeMedium ${currentTab === "1" ? "activeTab" : "inactiveTab"}`} /></div>
                                        <div className={"width-100-per"}>Logs</div>
                                        <div>
                                            <ToolTip title={"Shows a list of all the imported limit files."} placement="top">
                                                <Badge badgeContent={<HelpIcon fill='#fff' />} className={`badgeMedium activeTab roundCircle`} />
                                            </ToolTip>
                                        </div>
                                    </div>
                                }
                                value={'1'}
                            />

                        </TabList>
                    </AppBar>
                    <TabPanel value={'0'} index={0} className={"whiteBG"} style={{ height: `${getConfigurationContentHeight() - 47}px` }}>
                        <Maintenance
                            handleTabNavigation={() => { this.setState({ currentTab: '1' }) }}
                        />
                    </TabPanel>
                    <TabPanel value={'1'} index={0} className={"whiteBG"} style={{ height: `${getConfigurationContentHeight() - 47}px` }}>
                        <Logs
                            {...this.props}
                            handleTabNavigation={() => { this.setState({ currentTab: '1' }) }}
                            needTitleBar={false}
                            ischildPage={true}
                            isSubPage={true}
                            isConfigurationPage={true}
                            isConfigurationPageTab={true}
                        />
                    </TabPanel>
                </TabContext>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    <MenuItem onClick={() => this.handleTemplateDownload('IncomeLimit')}>Income Limit</MenuItem>
                    <MenuItem onClick={() => this.handleTemplateDownload('RentLimit')}>Rent Limit</MenuItem>
                    <MenuItem onClick={() => this.handleTemplateDownload('WhatLimit')}>What Limit</MenuItem>
                </Menu>
            </ContentWrapper>
        );
    }
}

/**
 * Define Props type
 * @memberof UploadRentIncomeLimit
 */
UploadRentIncomeLimit.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array
}

/**
 * Default props
 * @memberof UploadRentIncomeLimit
 */
UploadRentIncomeLimit.defaultProps = {
    classes: {},
    pageAccess: [],
    isReadOly: false,
}

/**
 * A method to Map State of Redux to Props of Component
 * @param {*} state 
 * @param {*} ownProps 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        pageAccess: state.pageAccess['projectNew'] || [],
        errorStatus: state.bulkUploads.rentIncomeError,
        isLoading: state.bulkUploads.rentIncomeLoading,
        success: state.bulkUploads.rentIncomeLmt,
        rentIncomeImportStatus: state.bulkUploads.rentIncomeImportStatus,
        rentIncomeRentUpdate: state.bulkUploads.rentIncomeRentUpdate,
        listLogs: state.maintenance.listLogs
    }
};

/**
 * A method to dispatch actions
 * @param {*} dispatch 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        uploadIncomeRent: (generalData, uploadData) => dispatch(uploadActions.importIncomeRentLmt(generalData, uploadData)),
        importStatus: () => dispatch(uploadActions.importStatus()),
        getLogs: (data, skip, limit, orderBy, order) => dispatch(maintenanceActions.listLogs(data, skip, limit, orderBy, order)),
        triggerUpdate: () => dispatch(uploadActions.triggerUpdate()),
        downloadTemplates: (id, limitType) => dispatch(maintenanceActions.downloadTemplate(id, limitType)),
        searchActions: (data) => {
            return dispatch(searchActions.maintenanceSearch(data));
        },
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadRentIncomeLimit); 