import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

// Actions
import { confActions } from '../../../actions/configuration';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Constants
import { configurationConstants } from '../../../helpers';
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextBox from '../../../components/TextBox/TextBox';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { alertActions } from '../../../actions';

// neccessary db columns for a single race row
const raceData = {
    desc: '',
    value: '',
    isActive: true
};
/**
 * Status Component
 * @class StatusComponent
 * @extends { Component}
 */
class StatusComponent extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (<Switch
            color="primary"
            checked={data[fieldName]}
            size="small"
            disabled={true}
            onChange={event => {
                onChange(fieldName, rowIndex, event.target.checked);
            }}
        />);
    }
}
/**
 * Default props
 */
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    onChange: () => { },
    data: {}
}
/**
 * EditableText Component
 * @class EditableText
 * @extends { Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName] || ''}
                key={'tbox-desc-' + rowIndex}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    desc: EditableText,
    isActive: StatusComponent
}
/**
 * Races Containers
 * @class Races
 * @extends { Component}
 */
class Races extends Component {
    /**
     * Constructors
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            races: [],
            saved: false
        }
    }
    /**
     * Componet will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.race);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { races } = nextProps;
        this.setState({
            saved: true,
            races: races
        });
    }
    /**
     * Cell component on change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { races } = this.state;
        switch (fieldName) {
            case "isActive":
                races[rowIndex][fieldName] = value;
                this.setState({ races });
                this.props.updatedata(configurationConstants.race, this.state.races);
                break;
            default:
                races[rowIndex][fieldName] = value;
                this.setState({ races });
        }
    }
    /**
     * Handel submit
     */
    handleSubmit = () => {
        this.saveform()
    }
    /**
     * save form
     */
    saveform() {
        //Check for duplicate member race names
        let races = this.state.races;
        let duplicateName=''
        let racesNames = races.map(function(item){ return item.desc.trim().toLowerCase() });
        let duplicateLenders = racesNames.some(function(item, idx){ 
                    if(racesNames.indexOf(item) !== idx){
                        duplicateName=races[racesNames.indexOf(item)].desc
                        return item
                    }
            });
        if(duplicateLenders){
            this.props.erroralert(`${duplicateName.toUpperCase()} race already exists`);
        }else{
        this.props.updatedata(configurationConstants.race, this.state.races);
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { races } = this.state;
        // [1]
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="races"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'HOME Head of Household Race'}
                        headerCols={['DESCRIPTION', 'STATUS']}
                        requiredFields={['DESCRIPTION']}
                        fieldCols={['desc', 'isActive']}
                        rowDatas={races}
                        style={style}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        // style={style}
                        staticHeader={true}
                        height= {height}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (races.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    races: [
                                        {
                                            ...raceData,
                                            key: Math.max(...races.map(elt => elt.key)) + 1,
                                            type: configurationConstants.race
                                        },
                                        ...races
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            if (item && item._id !== undefined)
                                this.props.deleteConfig(item.type, item._id);
                            else {
                                let changedArray = races.filter(i => i._id);
                                this.setState({ races: changedArray });
                            }
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.race)}}
                />
            </ValidatorForm>
        );
    }
}
/**
 * Props types
 */
Races.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {

    return {
        races: configuration.configurations[configurationConstants.race] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByName: (races) => dispatch(confActions.getByName(races)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) =>  dispatch(alertActions.error(msg))
    }
};
/**
 * Export Races
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Races));
