import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Actions
import { userActions } from '../../actions/user'

// Import Images
import ClientLogo from '../../assets/img/client_logos.png';
import SponsorLogo1 from '../../assets/img/sponser_logo_1.png';
import SponsorLogo2 from '../../assets/img/sponser_logo_2.png';

//Import Documants
import StandardConfig from '../../assets/documents/Standard Configuration.pdf';
import Questions from '../../assets/documents/FOR QUESTIONS ABOUT USING WBARS_March2018.pdf';
import Tos from '../../assets/documents/TOS.pdf';

// Import Styles
import loginStyle from '../../assets/jss/containers/login';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import PageLoader from '../../components/PageLoader/PageLoader'

/**
 *Change password
 *
 * @class ChangePassword
 * @extends {Component}
 */
class ChangePassword extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            confirmPassword: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /**
     * Handle change
     * @param {*} name 
     * @param {*} value 
     * @memberof TextBox
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle submit
     * @param {*} e 
     * @memberof ValidatorForm
     * 
     * [1]  -   If new password and confirm password are same, then change password
     */
    handleSubmit(e) {
        e.preventDefault();
        const { newPassword, confirmPassword } = this.state;
        const { match: { params } } = this.props;
        // [1]
        if (newPassword && confirmPassword) {
            this.props.changePassword({ ...this.state, hash: decodeURIComponent(params.hash) }, () => { });
        }
    }
    /**
     *render html
     *
     * @returns
     * @memberof ChangePassword
     */
    render() {
        const { newPassword, confirmPassword } = this.state;
        const { authentication: { changePassword } } = this.props;
        const success = changePassword.success;
        return (
            <Grid container direction="row" alignItems="stretch" className="h-100">
                <Grid item xs={7} lg={8} className="banner_bg">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" className="h-100 banner_container" >
                        <Grid item className="banner_block">
                            <Typography variant="display2" gutterBottom> 2018 WBARS Improvements </Typography>
                            <Typography variant="headline" gutterBottom> we are making wbars easier to use </Typography>
                            <Button variant="outlined" className="btn_outline m-t-20" > Find out More! </Button>
                        </Grid>
                        <Grid item className="footer_links">
                            <p><a href={Questions} target="_blank" rel="noopener noreferrer"> Contact Us </a>
                                | <a target="_blank" href="http://www.wshfc.org/managers/wbars.htm" rel="noopener noreferrer"> Instructions </a>
                                | <a href={StandardConfig} target="_blank" rel="noopener noreferrer"> Standard Configuration </a>
                                | <a href={Tos} target="_blank" rel="noopener noreferrer"> Terms of Use </a></p>
                            <p> Washington State Housing Finance Commission. © 2018 All Rights Reserved. </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={5} lg={4} className="login_block" >
                    <Grid container direction="column" justifyContent="space-between" className="h-100">
                        <Grid item >
                            <img src={ClientLogo} alt="client logo" />
                        </Grid>
                        <Grid item >
                            {
                                !success ? (
                                    <ValidatorForm
                                        name="changePasswordForm"
                                        ref="form"
                                        autoComplete="off"
                                        onSubmit={this.handleSubmit}
                                        onError={errors => { }}
                                    >
                                        <TextBox
                                            id="newPassword"
                                            label="New Password"
                                            margin="normal"
                                            className="login_ip_field"
                                            name="newPassword"
                                            type="password"
                                            value={newPassword}
                                            handleChange={this.handleChange}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                        <TextBox
                                            id="confirmPassword"
                                            label="Confirm Password"
                                            margin="normal"
                                            className="login_ip_field"
                                            name="confirmPassword"
                                            type="password"
                                            value={confirmPassword}
                                            handleChange={this.handleChange}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                        <Button type="submit" variant="contained" color="primary" className="login_ip_field m-t-30" > Submit </Button>
                                        <Button color="default" className="login_ip_field m-t-20 " component={Link} to="/login"> Login </Button>
                                        {/*<Link to="/forgot-password" className="login_ip_field m-t-20">Forgot Password</Link>*/}
                                    </ValidatorForm>
                                ) : (
                                        <Grid item>
                                            <p>Password has been changed successfully.</p>
                                            <Button color="primary" className="login_ip_field m-t-20 " component={Link} to="/login"> Login </Button>
                                        </Grid>
                                    )
                            }

                        </Grid>
                        <Grid item >
                            <img src={SponsorLogo1} alt="Sponsor 1" />
                            <img src={SponsorLogo2} alt="Sponsor 2" />
                        </Grid>
                    </Grid>
                </Grid>
                <PageLoader show={changePassword.isLoading} />
            </Grid>
        );
    }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ authentication }, ownProps) => {
    return {
        authentication
    }
};
/**
 * Map actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        changePassword: (data, callBack) => dispatch(userActions.changePassword(data, callBack))
    }
};
// export ChangePassword
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(loginStyle)(ChangePassword));
