import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

//Import Components
import Projects from './tabs/Projects';
import Contracts from './tabs/Contracts';
import RecordNumber from './tabs/RecordNumber';
// import Units from './tabs/Units';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import TabContainer from '../../components/TabContainer/TabContainer';
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import PageActions from '../../components/PageTitle/PageTitle';

//Import Helpers
import { history } from '../../helpers';
import Grid from '@material-ui/core/Grid';

//Import Actions
import { mergeActions } from '../../actions/mergeActions';
import { alertActions } from '../../actions/alert';

/**
 * MergeContainer Component
 * @class MergeContainer
 * @extends {Component}
 */
class MergeHOContainer extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            merge: false,
            mergeData: null,
            fromProject: null,
            toProject: null,
            mergeSuccess:false
        };
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match } = this.props;
        switch (value) {
            case 0:
                history.push({
                    pathname: `${match.url}`,
                    state: {
                        page: 'mergeHOProjects',
                        pageData: {

                        }
                    }
                });
                this.setState({
                    mergeData: null,
                    mergeSuccess:false
                });
                break;
            case 1:
                history.push({
                    pathname: `${match.url}/contracts`,
                    state: {
                        page: 'mergeHOProjects',
                        pageData: {

                        }
                    }
                });
                this.setState({
                    mergeData: null,
                    mergeSuccess:false
                });
                break;
            case 2:
                history.push({
                    pathname: `${match.url}/records`,
                    state: {
                        page: 'mergeHOProjects',
                        pageData: {

                        }
                    }
                });
                this.setState({
                    mergeData: null,
                    mergeSuccess:false
                });
                break;
            default: break;
        }
    };
    /**
     * Handle Merge Click
     * @param {*} formData 
     * @param {*} Details 
     */
    handleMergeClick = (formData, Details) => {
        if (formData) {
            this.setState({
                mergeData: {
                    formData: formData,
                    details: Details
                },
            });
        }
    };
    /**
     * Get Merge Button Access
     * @param {*} currentTab 
     * @returns 
     */
    clearMergeData = () => {
        this.setState({ merge: false })
        this.setState({
            mergeData: null,
            mergeSuccess:false
        });
    }

    getMergeButtonAccess(currentTab) {
        const { pageAccess } = this.props;
        let { mergeData } = this.state;
        let formData = mergeData ? mergeData.formData : [];
        formData = formData || [];
        let details = mergeData ? mergeData.details : {};
        details = details || {};
        let selectedItems = formData.filter(f => f.project_id !== details.project2);
        let selectedItemsBuildings = formData.filter(f => f.site_id !== details.site2);
        let selectedItemsUnits = formData.filter(f => f.building_id !== details.building2);

        switch (currentTab) {
            case 0: return selectedItems.length > 0 ? pageAccess : [];
            case 1: return selectedItems.length > 0 ? pageAccess : [];
            case 2: return selectedItemsBuildings.length > 0 ? pageAccess : [];
            case 3: return selectedItemsUnits.length > 0 ? pageAccess : [];
            default: return [];
        }
    }
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { classes, pageAccess, title, match, location: { pathname }, isReadOly } = this.props;
        let { mergeData } = this.state;
        console.log('##########################mergeData',mergeData)
        match.url = match.url.replace(/\/$/, "");
        pageAccess = [...pageAccess, 'BACK']
        const currentTab = pathname.includes('/contracts') ? 1 : (pathname.includes('/records') ? 2 : 0);

        const tooltipTitle = currentTab == 0 ? "Merge Project" : currentTab == 1 ? "Merge Contract Number(s)" : currentTab == 2 ? "Merge Record Number(s)" : "Merge Project"
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                handleBackActionClick={this.handleBackActionClick}
                toolTipTitle={tooltipTitle}
                title={title}
                handleMergeActionClick={() => {
                    if (!mergeData)
                        this.props.showErrorAlert(`Please select the mandatory fields to merge`);
                    else if (currentTab === 0 && (mergeData.formData.length) > 2)
                        this.props.showErrorAlert("Do not allow more than two projects to merge");
                    else if (this.getMergeButtonAccess(currentTab).length === 0)
                        this.props.showErrorAlert("Please select the mandatory fields to merge")
                    else
                        this.setState({ merge: true })
                }}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="Projects" value={0} />
                        <Tab label="Contract Number(s)" value={1} />
                        <Tab label="Record Number(s)" value={2} />
                    </Tabs>

                </AppBar>

                <TabContainer>
                    <Route
                        exact
                        path={`${match.url}`}
                        render={(props) => {
                            return (
                                <Projects
                                    pageAccess={pageAccess}
                                    mergeSuccess={this.state.mergeSuccess}
                                    isReadOly={isReadOly}
                                    currentTab={currentTab}
                                    handleMergeClick={(formData, Details) => this.handleMergeClick(formData, Details)}
                                    clearMerge={() => this.clearMergeData()}
                                />
                            )
                        }}
                    />
                    <Route
                        exact
                        path={`${match.url}/contracts`}
                        render={(props) => {
                            return (
                                <Contracts
                                    {...props}
                                    isSubPage={true}
                                    pageAccess={pageAccess}
                                    mergeSuccess={this.state.mergeSuccess}
                                    currentTab={currentTab}
                                    handleMergeClick={(formData, Details) => this.handleMergeClick(formData, Details)}
                                    clearMerge={() => this.clearMergeData()}
                                />
                            )
                        }}
                    />
                    <Route
                        path={`${match.url}/records`}
                        render={(props) => {
                            return (
                                <RecordNumber
                                    isSubPage={true}
                                    currentTab={currentTab}
                                    mergeSuccess={this.state.mergeSuccess}
                                    handleMergeClick={(formData, Details) => this.handleMergeClick(formData, Details)}
                                    pageAccess={pageAccess}
                                    {...props}
                                />
                            )
                        }}
                    />
                </TabContainer>
                <DeleteDialog
                    open={this.state.merge !== false}
                    content={<div dangerouslySetInnerHTML={{ __html: (mergeData) ? mergeData.details.contentMsg : null }}></div>}
                    handleDeleteAction={() => {
                        this.setState({ merge: false });
                        if (currentTab === 0) {
                            this.props.projectMerge(this.state.mergeData, () => {
                                this.setState({
                                    mergeData: null,
                                    mergeSuccess:true
                                });
                                this.props.getProjectList();
                            });
                        }
                        else if (currentTab === 1) {
                            this.props.contractMerge(this.state.mergeData, () => {
                                this.setState({
                                    mergeData: null,
                                    mergeSuccess:true
                                });
                            })
                        }
                        else if (currentTab === 2) {
                            this.props.recordNumberMerge(this.state.mergeData, () => {
                                this.setState({
                                    mergeData: null,
                                    mergeSuccess:true
                                });
                            });
                        }
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ merge: false })
                        // this.setState({
                        //     mergeData: null
                        // });
                    }}
                />
            </ContentWrapper >
        )
    }
}
/**
 * Bind Proptypes
 */
MergeHOContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string,
    isReadOly: PropTypes.bool,
    pageAccess: PropTypes.array
};
/** Default Props */
MergeHOContainer.defaultProps = {
    pageAccess: [],
    title: "Merge",
    isReadOly: false
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const pageAccess = state.pageAccess['mergeHOProjects'] || [];
    return {
        pageAccess
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getProjectList: () => dispatch(mergeActions.getLoanProjectsByName()),
        projectMerge: (formdata, callback) => dispatch(mergeActions.projectHOMerge(formdata, callback)),
        contractMerge: (formdata, callback) => dispatch(mergeActions.contractMerge(formdata, callback)),
        recordNumberMerge: (formdata, callback) => dispatch(mergeActions.recordNumberMerge(formdata, callback)),
        showErrorAlert: (error) => dispatch(alertActions.error(error))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(MergeHOContainer));
