import { Grid, List, ListItem } from "@material-ui/core";
import React, { Component } from "react";
import "./style.css";

/**
 * Option Container
 * @class Option
 * @extends {Component}
 */
export class SuccessLog extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    const { fileName, fileSize } = this.props;

    return (
      <div
        style={{
          marginTop: "15px",
          backgroundColor: "#ffff",
          borderRadius: "7px",
          fontWeight: "600",
          height: "300px",
        }}
      >
        <div
          style={{
            height: "110px",
            width: "100%",
            border: "0.5px solid #7ceaf7",
            backgroundColor: "#f0feff",
            // display: "flex",
            flexDirection: "column",
            // gap: "20px",
          }}
        >
          <div
            style={{
              border: "0.5px solid #7ceaf7",
              backgroundColor:"#fff",
              height: "50px",
              width: "90%",
              marginTop: "20px",
              marginLeft: "5%",
            }}
          >
            <Grid item xs={12} sm={12} style={{ padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  {fileName}
                </Grid>
                <Grid item xs={12} sm={5}>
                  {fileSize.toFixed(2)} KB
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{
                    backgroundColor: "#d4f8fc",
                    color: "#79c4fc",
                    textAlign: "center",
                    height: '34px'
                  }}
                >
                  No Errors
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ textAlign: "left", marginLeft: "5%" ,marginTop:'10px'}}>
          “The file does not have any errors. Please select the “Submit” option to complete the import.”
          </div>
        </div>
      </div>
    );
  }
}
