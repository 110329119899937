import React, { Component } from 'react';
import CodeMirror from 'react-codemirror';

//Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';

// addons
import "codemirror/addon/fold/foldgutter.js"
import "codemirror/addon/fold/brace-fold.js"
import "codemirror/addon/fold/xml-fold.js"
import "codemirror/addon/fold/indent-fold.js"
import "codemirror/addon/fold/markdown-fold.js"
import "codemirror/addon/fold/comment-fold.js"
import "codemirror/mode/xml/xml.js"
import "codemirror/mode/markdown/markdown.js"
import "codemirror/addon/search/searchcursor"
import "codemirror/addon/search/search"

class XMLEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorIndex: 0,
            xmlString: '',
            isEdited: false,
            projectLines: [],
            errorWithProjectPos: [],
            projectIndex: 0,
        }
    }

    //Get CodeMirror text area
    getCM = () => {
        if (this.codeMirror && this.codeMirror.getCodeMirror()) {
            return this.codeMirror.getCodeMirror();
        }
        return null;
    }

    //Get Project lines
    getProjectsLine = () => {
        let { errors } = this.props

        //Get codeMirror text area and CodeMirror doc
        let cm = this.getCM();
        const doc = cm.getDoc();

        let projectLines = [];
        let openTags = [];
        let closeTags = [];

        //Tags that represents start and end of project
        let tags = ["<LoanImportRecord>", "</LoanImportRecord>"];

        //looping tags to find project lines
        tags.forEach( (tag, i) => {
            //Search the tag in CodeMirror text area
            const cursor = doc.getSearchCursor(tag, false);
            while (cursor.findNext()) {
                //Find start of the projects
                if(i === 0){
                    openTags.push({from: cursor.from(), to: cursor.to()})
                }
                //Find end of the projects
                if(i === 1){
                    closeTags.push({from: cursor.from(), to: cursor.to()})
                }
            }
        })
        //Mapping start and end of projects
        openTags.forEach( (tag, i) => {
            projectLines[i] = { from: tag.from, to: closeTags[i] ? closeTags[i].to : "</import>"}
        })
        //Map projects with lines
        errors.forEach( (error, i) => {
            errors[i].projectPos = projectLines[error.projectIndex]
        })
        this.setState({projectLines: projectLines, errorWithProjectPos: errors});
    }

    cm = () => {
        const { errors } = this.props;
        let { projectLines, projectIndex } = this.state;
        
        if (this.getCM()) {
            //Get codeMirror text area and CodeMirror doc
            let cm = this.getCM();
            const doc = cm.getDoc();

            //Get project lines
            projectLines.length === 0 && this.getProjectsLine();

            //Error classifications
            if(errors.length > 0 && projectLines.length > 0){
                let errorsWithLine = [];
                let errorsWithSearch = [];
                projectIndex < projectLines.length && errors.forEach((error, i) => {

                    //Error Text mark for report year
                    if(error.tag === 'ReportYear') {
                        error.search.forEach(tag => {
                            const cursor = doc.getSearchCursor(tag, false);
                            while (cursor.findNext()) {
                                doc.markText(cursor.from(), cursor.to(),
                                { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                { addToHistory: true }
                                );
                            }
                        })
                    }
                    if(error.line){
                        errorsWithLine.push({...error, errorIndex: i});
                    }
                    if(error.search && error.tag !== 'ReportYear') {
                        if(error.search[0]){
                            errorsWithSearch.push({...error, errorIndex: i});
                        }
                    }
                })

                //Error Text mark for errors with line number
                errorsWithLine.length > 0 && errorsWithLine.forEach( error => {
                    const start = errors[error.errorIndex].line - 1;
                    const lineContentLength = doc.getLine(start).length;
                    doc.markText(
                        { line: start, ch: 0 }, 
                        { line: start, ch: lineContentLength }, 
                        { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                        { addToHistory: true }
                    );
                })

                //Error Text mark for errors with search tags
                errorsWithSearch.length > 0 && errorsWithSearch.forEach( (error, i) => {
                    error.search && error.search.forEach( (tag, index) => {
                        let split1 = tag.split('<');
                        let split2 = split1[1].split('>');
                        let regexTag = RegExp(`<${split2[0]}[^>]*>${split2[1]}</${split2[0]}>`);
                        const cursor = doc.getSearchCursor(regexTag, false);
                        while (cursor.findNext()) {
                            let tagPos = {from: cursor.from(), to: cursor.to()};
                            if(tagPos.from.line >= error.projectPos.from.line && tagPos.to.line <= error.projectPos.to.line){
                                doc.markText(cursor.from(), cursor.to(),
                                { css: "background-color:#e32;color: white", attributes: { title: error.message } },
                                { addToHistory: true }
                                );
                            }
                        }
                    })
                })
            }
        }
    }

    /**
     * Handle Changes in editor
     */
    changeInEditor = (newData) => {
        this.setState({xmlString: newData, isEdited: true})
        let cm = this.getCM();
        //Get cursor position
        const cursorPos = cm.getCursor();
        //Marking the line
        this.changeMarkText(cursorPos);
    }

    //Mark the line that changes
    changeMarkText = (pos) => {
        const { errors } = this.props;

        //Get codeMirror text area and CodeMirror doc
        let cm = this.getCM();
        const doc = cm.getDoc();
        
        //Line Highlight
        if(errors.length > 0) {
            const start = pos.line;
            const lineContentLength = doc.getLine(start).length;
            doc.markText(
                { line: start, ch: 0 }, 
                { line: start, ch: lineContentLength }, 
                { css: "background-color:#4CAF50;color: white"},
                { addToHistory: true }
            );
        }
    }

    //Remove all marks
    removeAllMarks = () => {
        //Get codeMirror text area and CodeMirror doc
        let cm = this.getCM();
        const doc = cm.getDoc();

        //removing marks
        let markers = doc.getAllMarks();
        if(markers.length > 0 ){
            markers.map(marker => marker.clear());
        }
    }

    //Scroll to error line
    scrollToError = (error, index) => {
        let { errorWithProjectPos } = this.state;
        let { search, line, projectPos } = errorWithProjectPos[index] || error || null;

        //Get codeMirror text area and CodeMirror doc
        let cm = this.getCM();
        const doc = cm.getDoc();

        let pos = null;

        //Search the tag position
        if(projectPos || line){
            search && search.forEach((tag, index) => {
                const cursor = doc.getSearchCursor(tag, false);
                while (cursor.findNext()) {
                    let tagPos = {from: cursor.from(), to: cursor.to()};
                    if(index === 0 && tagPos.from.line >= projectPos.from.line && tagPos.to.line <= projectPos.to.line){
                        pos = tagPos;
                    }
                }
            })

            //Scroll to the tag line
            let scrollTo = line ? line : pos ? pos : projectPos;
            if(scrollTo){
                cm.scrollIntoView(scrollTo, 100);
            }
        }
    }

    //Error list
    renderErrors = (error, index) => {
        let message = error.error || error.message;
        return(
            <div 
            key={index} 
            className="error-list" 
            onClick={() => this.scrollToError(error, index)}>
                {message}
            </div>
        )
    }

    /**
     * Handle Save Click Action
     */
    handleSave = () => {
        const { xmlString } = this.state;
        this.removeAllMarks();
        this.setState({isEdited: false})
        this.props.onSave(xmlString)
    }

    render() {
        const { xmlFormattedString, errors } = this.props;
        const { isEdited } = this.state
        setTimeout(() => {
            if(!isEdited && this.getCM()){
                if(errors.length > 0){
                    this.cm();
                }
            }
        }, 500)
        return (
                <React.Fragment>
                    <div className="xml-preview ho-xml-editor">
                        {
                            errors.length > 0 && 
                            <div className="error-container">
                                <div className="error-content">
                                    { 
                                        errors.map((error, index) => {
                                            return this.renderErrors(error, index)
                                        })
                                    }
                                </div>
                            </div>
                        }       
                        <div className="xml-editor">
                            <CodeMirror
                                ref={(c) => { this.codeMirror = c }}
                                value={xmlFormattedString}
                                onChange={(newData) => this.changeInEditor(newData)}
                                options={{
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    // styleActiveLine: true,
                                    extraKeys: { "Ctrl-Q": function (cm) { cm.foldCode(cm.getCursor()); } },
                                    foldGutter: true,
                                    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
                                    foldOptions: {
                                        rangeFinder: (cm, pos) => {
                                            let start = pos.line;
                                            let lineContent = cm.getDoc().getLine(start);
                                            let end = start + 1;
                                            if (lineContent.indexOf("<LoanImportRecord>") >= 0) {
                                                let loop = true;
                                                do {    
                                                    lineContent = cm.getDoc().getLine(end);
                                                    if (!lineContent || lineContent.indexOf("</LoanImportRecord>") >= 0) {
                                                        loop = false;
                                                    } else {
                                                        end = end + 1;
                                                    }
                                                } while (loop);
                                                return { from: { line: start }, to: { line: end } };
                                            }
                                            return;
                                        }
                                    }
                                }}
                            />
                            <SaveCancel
                                handleSaveButtonClick={() => { this.handleSave() }}
                                handleCancelButtonClick={this.props.onCancel}
                                saveText={"Save"}
                                cancelText={"Cancel"}
                            />
                        </div>
                    </div>
                </React.Fragment>
        )
    }
}

XMLEditor.defaultProps = {
    errors: [],
}

export default XMLEditor; 