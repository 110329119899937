import React, { Component } from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { Grid } from "@material-ui/core";
import {
  validationHelpText,
  validationHelpTextHO,
} from "../../helpers/appConstants";
import { history, getUser } from "../../helpers";
import _ from "underscore";
import {
  exclamation,
  questionMark,
  downArrow,
  upArrow,
  errorSnackbar,
  downArrowSnackbar,
  upArrowSnackbar,
  arrowPopOver,
} from "../SvgIcons/SvgIcons";
import { CloseIcon, HelpIcon } from "../SvgIcons/SvgIcons";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
// styles
const styles = (theme) => ({
  action: {
    display: "inline-block",
    textAlign: "center",
    width: "100%",
    padding: "0px",
    margin: "0px",
  },
  actionOpen: {
    display: "inline-block",
    textAlign: "center",
    width: "100%",
    padding: "0px",
    margin: "0px",
    backgroundColor: "white",
    paddingBottom:"8px !important"
  },
  message: {
    flex: 1,
  },
  messageOpen: {
    flex: 1,
    backgroundColor: "white",
    paddingRight: "0px",
    marginTop:'-14px !important'
  },
  root: {
    marginBottom: "0px",
    borderRadius: "0px",
    background: "white",
    padding: "0px !important",
  },
  rootClose: {
    width: "500px",
    height: "100px",
  },
  unitsHeightLargeCount: {
    height: "200px",
  },
  unitsHeightLargeMessage: {
    height: "150px",
  },
  unitsHeightSmallerCount: {
    height: "100px",
  },
  unitListMargin: {
    marginTop: "18px !important",
  },
  errorCount: {
    // zIndex: 400000,
    // color:'white',
    // position:'absolute',
    // left:'32.3%',
    // top:'0px',
    // display:'flex',
    // backgroundColor:'black',
    // width:'655px',
    // height:'40px',
    // alignItems:'center',
    // paddingLeft:'10px',
    "@media (max-width:2032px)": {
      zIndex: 400000,
      color: "white",
      position: "absolute",
      left: "32.3%",
      top: "0px",
      display: "flex",
      backgroundColor: "black",
      width: "655px",
      height: "40px",
      alignItems: "center",
      paddingLeft: "10px",
    },
    "@media (min-width:2032px)": {
      zIndex: 400000,
      color: "white",
      position: "absolute",
      left: "34%",
      top: "0px",
      display: "flex",
      backgroundColor: "black",
      width: "655px",
      height: "40px",
      alignItems: "center",
      paddingLeft: "10px",
    },
  },
});

/**
 *Table errors
 *
 * @class TableErrors
 * @extends {Component}
 */
class TableErrors extends Component {
  /**
   * Constructors
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      expandUnits: false,
      expandIndex: null,
      openPopOver: false,
      popoverEnabled: false,
      helpIndex: null,
    };
  }
  /** Toggle Error */
  toggleErrors = () => {
    this.setState({ open: !this.state.open });
  };

  /** Get Validation Errors */
  getValidationErrors() {
    const { validations, fielderrors, errorPage } = this.props;
    return errorPage === "table1"
      ? [
          ...validations.map((v) => {
            return {
              msg: v.msg,
              errorType: v.type || v.errorType,
              units: v.units,
              expandUnit: false,
            };
          }),
          ...fielderrors.filter((f) => f.msg !== null),
        ]
      : [
          ...validations.map((v) => {
            return {
              msg: v.message,
              errorType: v.type || v.errorType,
              title: v.title,
            };
          }),
          ...fielderrors.filter((f) => f.msg !== null),
        ];
  }
  /**
   * Get Color Style
   * @param {*} err
   * @returns
   */
  getColorStyle(err) {
    if (err.errorType >= 3 || err.type >= 3 || err.errorType === 1) {
      return { color: "red" };
    } else {
      return { color: "#000" };
    }
  }
  /**
   * Render Errors
   * @returns
   */
  renderErrors = () => {
    const { expandUnits, expandIndex, anchorEl, popoverEnabled } = this.state;
    const { classes, errorPage } = this.props;
    let isOccupantPage = history.location.pathname.includes("occupants");
    let fielderrors = this.getValidationErrors();
    let userDetails = getUser();
    let unitClass =
      (userDetails && userDetails.type) === "Onsite Manager"
        ? "units"
        : "units-nonclickable";
    console.log("userDetails", userDetails, unitClass);
    fielderrors.map((item) => {
      if (history.location.pathname.includes("loanProjects")) {
        if (item.msg.includes("Section 3 - Number of resales")) {
          return (item["help"] =
            validationHelpTextHO["section3-numberofresales"]);
        } else {
          return (item["help"] =
            validationHelpTextHO[
              item.msg
                .toLowerCase()
                .trim()
                .replace(/\s/g, "")
                .replace(/[.\s]/g, "")
                .replace(/"/g, "'")
                .replace(/”/g, "'")
            ]);
        }
      } else {
        if (item.msg.indexOf("Section 1 - The total number") > -1) {
          return (item["help"] =
            validationHelpText["Section 1 - The total number"]);
        }

        if(item.msg.indexOf("The beginning report year date of operation") > -1){
          return (item["help"] =
            validationHelpText["The beginning report year date of operation"]);
        }

        if(item.msg.indexOf("The ending report year date of operation") > -1){
          return (item["help"] =
            validationHelpText["The ending report year date of operation"]);
        }

        if(item.msg.indexOf("HOME program requirements")>-1){
          return (item["help"] =
            validationHelpText["HOME program requirements"]);
        }
        
        return (item["help"] = validationHelpText[item.msg]);
      }
    });
    //remove duplicate units
    fielderrors.map((item) => {
      return (item.units = [...new Set(item.units)]);
    });
    const getPopOver = (page = "listPage") => {
      let { helpIndex } = this.state;
      let getHelpText = fielderrors[helpIndex].help;
      console.log('fielderrors[helpIndex]',fielderrors[helpIndex])
      let hasUnits = fielderrors[helpIndex].units.length>0;
      //let isNumberOfLoans = fielderrors[helpIndex].message.includes('Number of loans') || fielderrors[helpIndex].message.includes('Number of grants')
      console.log('hasUnits@@@@@',hasUnits,page,history.location.pathname)
      //handle navigation for openlink
      let urlToNavigate = history.location.pathname.includes("projects")
        ? `/help/compliance`
        : history.location.pathname.includes("programActivity")
        ? `/help/program%20activity`
        : history.location.pathname.includes("financialActivity")
        ? `/help/financial%20activity`
        : `/help/contract%20activity`;
      let applyStyle=null;
      if(hasUnits){
      applyStyle =
        (page === "detailsPage" && (history.location.pathname.includes('financialActivity') || history.location.pathname.includes('programActivity')))?
        {
          top: "0px",
          "& .MuiPaper-root": {
            boxShadow: "17px 16px 14px -18px rgba(66, 68, 90, 0.2)",
            overflow: "visible",
          },
          "& .MuiPopover-paper": {
            marginBottom: "1px !important",
            marginLeft: "4.5px !important",
            marginTop: "33px !important"
          },
        }:
        (page === "detailsPage" &&  !history.location.pathname.includes('loanProjects'))
          ? {
              top: "0px",
              "& .MuiPaper-root": {
                boxShadow: "17px 16px 14px -18px rgba(66, 68, 90, 0.2)",
                overflow: "visible",
              },
              "& .MuiPopover-paper": {
                marginBottom: "1px !important",
                marginLeft: "53.5px !important",
              },
            }
          : {
              top: "0px",
              "& .MuiPaper-root": {
                boxShadow: "17px 16px 14px -18px rgba(66, 68, 90, 0.2)",
                overflow: "visible",
              },
              "& .MuiPopover-paper": {
                marginTop: "30px !important",
                marginLeft: "3.5px !important",
              },
            };
          }else{
            applyStyle = { top: "0px",
            "& .MuiPaper-root": {
            boxShadow: "17px 16px 14px -18px rgba(66, 68, 90, 0.2)",
            overflow: "visible",
            },
            "& .MuiPopover-paper": {
              marginLeft: "54px !important",
            },
          }
        };
      return (
        <Popover
          id={id}
          open={openPopOver}
          anchorEl={anchorEl}
          onClose={() => handlePopOverClose()}
          classes={page === "detailsPage" ? "withoutUnits" : "withUnits"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={applyStyle}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <div
              className="modal-title-container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                background: "#36aebe",
                padding: "10px",
              }}
            >
              <div
                className="model-sub-title-container"
                style={{ display: "flex", gap: "10px" }}
              >
                <div
                  style={{
                    width: "20px",
                    position: "absolute",
                    top: "-8px",
                    left: "1px",
                  }}
                  dangerouslySetInnerHTML={{ __html: `${arrowPopOver}` }}
                ></div>
                <div
                  onClick={() => history.push(urlToNavigate)}
                  style={{
                    borderRadius: "48%",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    height: "20px",
                    width: "20px",
                  }}
                >
                  <QuestionMarkIcon
                    style={{
                      fontWeight: 200,
                      fontSize: "14px",
                      color: "#36aebe",
                    }}
                  />
                </div>
                <div
                  className="title"
                  style={{ fontWeight: 600, color: "white" }}
                >
                  HOW TO FIX
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handlePopOverClose()}
              >
                <CloseIcon fill="#fff" />
              </div>
            </div>
            <div className="modal-body-container" style={{ padding: "10px" }}>
              {getHelpText}
            </div>
          </div>
          {/* <span style={{ maxWidth: '50px' }}>
                        <span style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '11px', paddingTop: '6px', cursor: 'pointer' }} onClick={() => handlePopOverClose()}>
                            <CloseIcon/>
                        </span>
                        {/* <p style={{ paddingRight: '10px', paddingLeft: '10px', maxWidth: '300px' }}>
                            {getHelpText}
                        </p>
                        <p>
                            <p onClick={() => history.push(urlToNavigate)} style={{ color: 'blue', textDecoration: 'underline', paddingRight: '10px', paddingLeft: '10px', cursor: 'pointer' }}>Open link</p>
                        </p> 
                    </span> */}
        </Popover>
      );
    };

    let openPopOver = Boolean(anchorEl);
    let id = openPopOver ? "simple-popover" : undefined;

    const handlePopOverClick = (event, data = null) => {
      this.setState({
        anchorEl: event.currentTarget,
        popoverEnabled: true,
        helpIndex: data,
      });
    };

    const handlePopOverClose = () => {
      this.setState({ anchorEl: null, popoverEnabled: false });
    };

    let messageClass = "table-err-msg";
    let cardClass = "";
    if (errorPage === "table1" || errorPage === "reportActivity") {
      messageClass = "table1-err-msg table-err-msg";
      if (expandIndex !== null) {
        fielderrors[expandIndex].expandUnit = expandUnits;
      }
    } else {
      cardClass = "table-error-card";
    }
    const msg = [];
    let errorCount =
      history.location.pathname.includes("table1") ||
      history.location.pathname.includes("table2") ||
      history.location.pathname.includes("table3") ||
      history.location.pathname.includes("table4") ||
      history.location.pathname.includes("occupants")
        ? _.unique(fielderrors, "msg")
        : _.unique(fielderrors, "message");
    let err = fielderrors.map((err, index) => {
      if (
        (errorPage === "table1" || errorPage === "reportActivity") &&
        err.units &&
        err.units
      ) {
        err.expClassName = err.expandUnit
          ? `table1-units opened-card ${classes.unitListMargin}`
          : "table1-units closed-card";
      } else {
        _.unique(fielderrors, "msg");
        messageClass = "table-err-msg";
      }
      let unitsSize = err.units.length;
      let messageSize = err.msg.length;

      let cardHeightClass = classes.unitsHeightSmallerCount;
      if (unitsSize > 15 && expandIndex === index && expandUnits) {
        cardHeightClass = classes.unitsHeightLargeCount;
      }

      if (messageSize > 80 && expandIndex === index && expandUnits) {
        cardHeightClass = classes.unitsHeightLargeMessage;
      }

      //(unitsSize > 15 || messageSize>80) && expandIndex===index && expandUnits ? classes.unitsHeightLargeCount: classes.unitsHeightSmallerCount;
      if (msg.indexOf(err.msg) === -1) {
        msg.push(err.msg);
        return (
          <Paper className={classes.root} elevation={0} key={index}>
            <Typography component="span">
              <Grid
                container
                className={cardClass}
                style={this.getColorStyle(err)}
              >
                {index === 0 && !isOccupantPage ? (
                  <Grid
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      width: "100%",
                      paddingLeft: "10px",
                      marginLeft: "0%",
                      marginBottom: "1%",
                    }}
                  >
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: `${errorCount.length} COMPLIANCE ERROR(S)`,
                      }}
                    ></h4>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  container
                  justifyContent="space-between"
                  className={`${messageClass} ${cardHeightClass}`}
                  style={{
                    border: "2px solid white",
                    alignItems: "center",
                    paddingLeft: "0px",
                    background: "white",
                    boxShadow: "none",
                    margin:'10px'
                  }}
                >
                  <Grid
                    sm={1}
                    className={`${cardHeightClass}`}
                    style={{
                      backgroundColor: "#f7c9c3",
                      padding: "15px",
                      margin: "0px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px 0px 0px 10px",
                    }}
                  >
                    <div
                      style={{ textAlign: "center" }}
                      dangerouslySetInnerHTML={{ __html: `${exclamation}` }}
                    ></div>
                  </Grid>
                  {(errorPage === "table1" || errorPage === "reportActivity") &&
                  err.units &&
                  err.units.length > 0 ? (
                    <Grid
                      sm={11}
                      className={`${cardHeightClass}`}
                      style={{
                        backgroundColor: "#edebeb",
                        paddingTop: "35px",
                        paddingLeft: "10px",
                        borderRadius: "0px 10px 10px 0px",
                      }}
                    >
                      <div
                        style={{ width: "85%" }}
                        onClick={() => {
                          this.props.handleClickAction(err);
                        }}
                      >
                        {err.msg}
                      </div>
                      {(errorPage === "table1" ||
                        errorPage === "reportActivity") &&
                        err.units &&
                        err.units.length > 0 && (
                          <div
                            className={err.expClassName}
                            style={{
                              paddingBottom: "20px !important",
                              boxShadow: "none",
                            }}
                          >
                            {err.units.map((unit, i) => {
                              return (
                                <span
                                  className="units"
                                  style={
                                    (userDetails && userDetails.type) !==
                                      "Onsite Manager" &&
                                    err.msg.includes("90 days")
                                      ? {
                                          maxHeight: "50px !important",
                                          marginBottom: "white !important",
                                          boxShadow: "none",
                                          color: "black",
                                        }
                                      : {
                                          maxHeight: "50px !important",
                                          marginBottom: "white !important",
                                          boxShadow: "none",
                                          color: "#36aebe",
                                          cursor: "pointer",
                                        }
                                  }
                                  key={i}
                                  onClick={() =>
                                    this.props.handleUnitSelect(unit, err)
                                  }
                                >
                                  {unit}{" "}
                                  {err.units.length !== i + 1 ? " |" : ""}
                                </span>
                              );
                            })}
                          </div>
                        )}
                    </Grid>
                  ) : (
                    <Grid
                      sm={11}
                      style={{
                        backgroundColor: "#edebeb",
                        height: "100px",
                        paddingTop: "35px",
                        paddingLeft: "5px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        onClick={() => {
                          this.props.handleClickAction(err);
                        }}
                        dangerouslySetInnerHTML={{ __html: `${err.msg}` }}
                      ></span>
                      <span>
                        <span
                          aria-describedby={id}
                          style={{
                            paddingLeft: "50px",
                            paddingRight: "10px",
                            cursor: "pointer",
                          }}
                          variant="contained"
                          id={index}
                          onClick={(e) => handlePopOverClick(e, index)}
                          dangerouslySetInnerHTML={{
                            __html: `${questionMark}`,
                          }}
                        ></span>
                        {popoverEnabled === true ? (
                          <span>{getPopOver("detailsPage")}</span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </Grid>
                  )}
                  {errorPage === "table1" && err.units && err.units.length > 0 && (
                    <Grid sm={2} style={{display:'flex',justifyContent:'center'}}>
                      <span
                        className={`exp-name ${cardHeightClass}`}
                        style={{
                          paddingRight: "20px",
                          backgroundColor: "#edebeb",
                          paddingTop: "35px",
                          borderRadius: "0px 10px",
                        }}
                      >
                        <span
                          aria-describedby={id}
                          variant="contained"
                          id={index}
                          onClick={(e) => handlePopOverClick(e, index)}
                          dangerouslySetInnerHTML={{
                            __html: `${questionMark}`,
                          }}
                        ></span>
                        {err.expandUnit ? (
                          <span
                            onClick={() =>
                              this.setState({
                                expandUnits: !err.expandUnit,
                                expandIndex: index,
                              })
                            }
                            dangerouslySetInnerHTML={{ __html: `${upArrow}` }}
                          ></span>
                        ) : (
                          <span
                            onClick={() =>
                              this.setState({
                                expandUnits: !err.expandUnit,
                                expandIndex: index,
                              })
                            }
                            dangerouslySetInnerHTML={{ __html: `${downArrow}` }}
                          ></span>
                        )}
                        {popoverEnabled === true ? getPopOver() : <></>}
                      </span>
                    </Grid>
                  )}
                  {errorPage === "reportActivity" &&
                    err.units &&
                    err.units.length > 0 && (
                      <Grid sm={1}>
                        {" "}
                        <span
                          className={`exp-name ${cardHeightClass}`}
                          style={{
                            paddingRight: "20px",
                            backgroundColor: "#edebeb",
                            paddingTop: "35px",
                            borderRadius: "0px 10px",
                          }}
                        >
                          <span
                            aria-describedby={id}
                            variant="contained"
                            id={index}
                            onClick={(e) => handlePopOverClick(e, index)}
                            dangerouslySetInnerHTML={{
                              __html: `${questionMark}`,
                            }}
                          ></span>
                          {err.expandUnit ? (
                            <span
                              onClick={() =>
                                this.setState({
                                  expandUnits: !err.expandUnit,
                                  expandIndex: index,
                                })
                              }
                              dangerouslySetInnerHTML={{ __html: `${upArrow}` }}
                            ></span>
                          ) : (
                            <span
                              onClick={() =>
                                this.setState({
                                  expandUnits: !err.expandUnit,
                                  expandIndex: index,
                                })
                              }
                              dangerouslySetInnerHTML={{
                                __html: `${downArrow}`,
                              }}
                            ></span>
                          )}
                          {popoverEnabled === true ? getPopOver() : <></>}
                        </span>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Typography>
          </Paper>
        );
      }
      return null;
    });
    return <div style={{ maxHeight: "300px", overflow: "auto" }}>{err}</div>;
  };

  /**
   *render html
   *
   * @returns
   * @memberof TableErrors
   */
  render() {
    const { open } = this.state;
    const { data, classes } = this.props;
    const fielderrors = this.getValidationErrors();
    let reportYear = history.location.pathname.split("/")[5];
    let siteContent = reportYear?`site for the report year - ${reportYear}`:`site`;
    let contractContent=reportYear?`contract for the report year - ${reportYear}`:`contract`;
    let unitContent = data&&data.currentYear? `unit for the report year - ${data.currentYear}`:`unit`;

    //get unique error count
    let totalErrorsCount =
      history.location.pathname.includes("table1") ||
      history.location.pathname.includes("table2") ||
      history.location.pathname.includes("table3") ||
      history.location.pathname.includes("table4") ||
      history.location.pathname.includes("occupants")
        ? _.unique(fielderrors, "msg")
        : _.unique(fielderrors, "message");

    if (data && fielderrors.length > 0) {
      return (
        <>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            variant={"warning"}
            style={{ zIndex: 500000, padding: "2px !important"}}
            open={true}
            autoHideDuration={6000}
            onExited={this.handleExited}
            message={
              open ? (
                this.renderErrors()
              ) : (
                <div
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: `${errorSnackbar}<span>There are ${
                      totalErrorsCount.length
                    } compliance error(s) in the ${
                      history.location.pathname.includes("table1") ||
                      history.location.pathname.includes("table2") ||
                      history.location.pathname.includes("table3") ||
                      history.location.pathname.includes("table4")
                        ? `${siteContent}`
                        :history.location.pathname.includes("occupants")?
                        `${unitContent}`
                        :`${contractContent}`
                    }</span>`,
                  }}
                ></div>
              )
            }
            ContentProps={{
              classes: {
                action: open ? classes.actionOpen : classes.action,
                message: open ? classes.messageOpen : classes.message,
                root: open ? classes.root : classes.rootClose,
              },
            }}
            action={[
              <Button
                key="toggle"
                color="white"
                size="small"
                onClick={this.toggleErrors}
                style={
                  open
                    ? { backgroundColor: "#D6D6D6", color: "grey", paddingBottom:"8px !important" }
                    : { backgroundColor: "#36aebe", color: "white" }
                }
              >
                {open ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<span style="color:#202020">Hide <span>${upArrowSnackbar}`,
                    }}
                    style={{ textTransform: 'none',paddingTop:'2px',paddingBottom:'2px',paddingLeft:'2px' }}
                  ></div>
                ) : (
                  <div
                    style={{ textTransform: 'none',paddingLeft:'5px' }}
                    dangerouslySetInnerHTML={{
                      __html: `View Errors ${downArrowSnackbar}`,
                    }}
                  ></div>
                )}
              </Button>,
            ]}
          />
        </>
      );
    }
    return null;
  }
}
/** ̰
 * Bind Component Property Types
 */
TableErrors.propTypes = {
  classes: PropTypes.object.isRequired,
};
//default props
TableErrors.defaultProps = {
  fielderrors: [],
  data: null,
  classes: {},
  errorPage: "",
  validations: [],
  handleClickAction: () => {},
};
//export component
export default withStyles(styles)(TableErrors);
