//import constants
import { dashboardConstants } from '../constants';

//import services
import { dashboardService } from '../services';

//import actions
import { alertActions } from './';
import { saveAs } from 'file-saver';

export const dashboardActions = {
    getReportStatus,
    getProjectSummary,
    getComplianceIssue,
    getComplianceIssueSummary,
    downloadProjectSummaryReport,
    getProjectList,
    getPMEffectiveDate,
    getOccupancyRate,
    getPMProjectList,
    getFunderReports,
    viewProjectSummaryReport,
    viewandDownloadProjectSummaryReport,
    getPSBUCounts,
    getUsersSummary,
    downloadDashboardReport,
    getProjectsList
};


/**
 *Dashnoard Needs Action Section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
function getProjectList(skip, limit, orderBy, order, sfields, sValues) {

    return dispatch => {
        dispatch(request());

        dashboardService.getProjectList(skip, limit, orderBy, order, sfields, sValues)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_PROJECTSLIST_REQUEST } }
    function success(data) { return { type: dashboardConstants.GET_PROJECTSLIST_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.GET_PROJECTSLIST_FAILURE, error } }
}

/**
 *PM dashboard effective date section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
function getPMEffectiveDate(skip, limit, orderBy, order, sfields, sValues) {

    return dispatch => {
        dispatch(request());

        dashboardService.getPMEffectiveDate(skip, limit, orderBy, order, sfields, sValues)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_EFFECTIVELIST_REQUEST } }
    function success(data) { return { type: dashboardConstants.GET_EFFECTIVELIST_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.GET_EFFECTIVELIST_FAILURE, error } }
}

/**
 *PM Dashboard Needs Action Section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
function getPMProjectList(skip, limit, orderBy, order, sfields, sValues, selectedSites, reportYear, userRole, org_id) {
    return dispatch => {
        dispatch(request());

        dashboardService.getPMProjectList(skip, limit, orderBy, order, sfields, sValues, selectedSites, reportYear, userRole, org_id)
            .then(
                dashboard => dispatch(success(dashboard)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_PROJECTSLIST_REQUEST } }
    function success(data) { return { type: dashboardConstants.GET_PROJECTSLIST_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.GET_PROJECTSLIST_FAILURE, error } }
}


/**
 *Report Status in both dashboard section
 *
 * @param {*} year - selected year
 * @param {*} userRole - logged in user
 * @param {*} org_id - organization id
 * @returns
 */
function getReportStatus(year, userRole, org_id) {
    return dispatch => {
        dispatch(request());

        dashboardService.getReportStatus(year, userRole, org_id)
            .then(
                dashboard => dispatch(success(dashboard.data ? dashboard.data : [])),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GETALL_REPORT_STATUS_REQUEST } }
    function success(data) { return { type: dashboardConstants.GETALL_REPORT_STATUS_SUCCESS, data, year } }
    function failure(error) { return { type: dashboardConstants.GETALL_REPORT_STATUS_FAILURE, error } }
}

/**
 *Dropdown values in Dashboard
 *
 * @returns
 */
function getProjectSummary(limit, search) {
    return dispatch => {
        dispatch(request());
        dashboardService.getProjectSummary(limit, search)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GETALL_PROJECTSUMMARY_REQUEST } }
    function success(data) { return { type: dashboardConstants.GETALL_PROJECTSUMMARY_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.GETALL_PROJECTSUMMARY_FAILURE, error } }
}

/**
 *Compliance issue in both dashboard section
 *
 * @param {*} year - selected year
 * @param {*} userRole - logged in user
 * @param {*} org_id - organization id
 * @returns
 */
function getComplianceIssue(year, userRole, org_id) {
    return dispatch => {
        dispatch(request());
        dashboardService.getComplianceIssue(year, userRole, org_id)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GETALL_COMPLIANCE_ISSUE_REQUEST } }
    function success(data) { return { type: dashboardConstants.GETALL_COMPLIANCE_ISSUE_SUCCESS, data, year } }
    function failure(error) { return { type: dashboardConstants.GETALL_COMPLIANCE_ISSUE_FAILURE, error } }
}

function getComplianceIssueSummary(year, userRole, org_id, selectedSites) {
    return dispatch => {
        dispatch(request());
        dashboardService.getComplianceIssueSummary(year, userRole, org_id, selectedSites)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GETALL_COMPLIANCE_NEEDSACTION_REQUEST } }
    function success(data) { return { type: dashboardConstants.GETALL_COMPLIANCE_NEEDSACTION_SUCCESS, data, year, selectedSites } }
    function failure(error) { return { type: dashboardConstants.GETALL_COMPLIANCE_NEEDSACTION_FAILURE, error } }
}

/**
 *Occupancy Rate in PM dashboard section
 *
 * @param {*} year - selected year
 * @returns
 */
function getOccupancyRate(year) {
    return dispatch => {
        dispatch(request());
        dashboardService.getOccupancyRate(year)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GETALL_OCCUPANCY_RATE_REQUEST } }
    function success(data) { return { type: dashboardConstants.GETALL_OCCUPANCY_RATE_SUCCESS, data, year } }
    function failure(error) { return { type: dashboardConstants.GETALL_OCCUPANCY_RATE_FAILURE, error } }
}


/**
 * A dispatcher action method to call download pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function downloadProjectSummaryReport(data) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)
        dashboardService.downloadProjectSummaryReport(data)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, 'ProjectSummaryReport.pdf');
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_REQUEST } }
    function success(data) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_FAILURE, error } }
}

/**
 * A dispatcher action method to call all the funder export excel
 *
 * @param {*} data contains excel report name and key
 */
function getFunderReports() {
    return dispatch => {
        dispatch(request());

        dashboardService.getFunderReports()
            .then(
                funderDataExports => dispatch(success(funderDataExports.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.GET_REPORTNAME_REQUEST } }
    function success(data) { return { type: dashboardConstants.GET_REPORTNAME_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.GET_REPORTNAME_FAILURE, error } }
}

/**
 * A dispatcher action method to call view pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function viewProjectSummaryReport(data) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.clear());
        dashboardService.viewProjectSummaryReport(data)
            .then(
                details => {
                    dispatch(success(details.data))
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error('This Project does not exist for the entered year'));
                }
            )
    };

    function request() { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_REQUEST } }
    function success(data) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_FAILURE, error } }
}

/**
 * A dispatcher action method to call view pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function viewandDownloadProjectSummaryReport(data) {
    return dispatch => {
        dispatch(request());
        dashboardService.viewProjectSummaryReport(data)
            .then(
                details => {
                    if (details.data.source) {
                        dispatch(downloadProjectSummaryReport(details.data.source));
                        dispatch(success(details));
                    }
                },
                error => {
                    dispatch(alertActions.error('This Project does not exist for the entered year'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 3000);
                    dispatch(failure(error.toString()));
                }
            )
    };

    function request() { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_REQUEST } }
    function success(data) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_FAILURE, error } }
}


/**
 *PSBU counts in Dashboard
 *
 * @returns
 */
function getPSBUCounts(userRole, reportYear) {
    return dispatch => {
        dispatch(request());
        dashboardService.getPSBUCounts(userRole, reportYear)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.PSBU_COUNT_REQUEST } }
    function success(data) { return { type: dashboardConstants.PSBU_COUNT_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.PSBU_COUNT_FAILURE, error } }
}


/**
 *Users Summary in Dashboard
 *
 * @returns
 */
function getUsersSummary(userRole) {
    return dispatch => {
        dispatch(request());
        dashboardService.getUsersSummary(userRole)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.USERSSUMMARY_REQUEST } }
    function success(data) { return { type: dashboardConstants.USERSSUMMARY_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.USERSSUMMARY_FAILURE, error } }
}

/**
 * A dispatcher action method to call download pjt summar report api service
 *
 * @param {*} data contains project id, site id (optional), year
 */
function downloadDashboardReport(data) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)
        dashboardService.downloadDashboardReport(data)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, `THE_ANNUAL_REPORT_${data.reportYear}.pdf`);
                    dispatch(success());
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_REQUEST } }
    function success(data) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_FAILURE, error } }
}

function getProjectsList(userRole, org_id, year, callBack= ()=> {}) {
    return dispatch => {
        dispatch(request());
        dashboardService.getProjectsList(userRole, org_id, year)
            .then(
                data => {
                    dispatch(success(data));
                    callBack(data.data)
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request() { return { type: dashboardConstants.GET_PROJECT_LIST_REQUEST } }
    function success(data) { return { type: dashboardConstants.GET_PROJECT_LIST_SUCCESS, data } }
    function failure(error) { return { type: dashboardConstants.GET_PROJECT_LIST_FAILURE, error } }
}