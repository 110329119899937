/**
 * Check for Waring in report tables
 * @param {*} fielderrors 
 * @param {*} warningfieldname 
 * @returns 
 */
export const hasWarnings = (fielderrors, warningfieldname) => {
    const isExits = fielderrors.filter(fe => fe.fieldName === warningfieldname).length > 0;
    return isExits ? 'warning' : '';
}
/**
 * Check for Waring Section in report tables
 * @param {*} fielderrors 
 * @param {*} sectionIndex 
 * @returns 
 */
export const hasWarningsSection = (fielderrors, sectionIndex) => {
    let isExits = fielderrors.filter(fe => fe.sectionIndex === sectionIndex).length > 0;
    return isExits ? 'warning' : '';
}