import { getFloat } from "../";
import { Table2AlternateMessages } from "./messages";

const Table2AlternateValidations = (data) => {
    const errors = [];

    // error - 1
    const { singleBedUnits, householdUnits } = data;
    if (getFloat(singleBedUnits) === 0 && getFloat(householdUnits) === 0) {
        errors.push({
            msg: Table2AlternateMessages.singleBednights,
            fieldName: 'bedUnits',
            sectionIndex: 1
        });
    }

    // error - 2
    const { totalOccupiedBednights, totalBednightsAvailable } = data;
    if (totalBednightsAvailable) {
        if (totalOccupiedBednights > totalBednightsAvailable) {
            errors.push({
                msg: Table2AlternateMessages.bednightsUsed,
                fieldName: 'totalOccupiedBednights',
                sectionIndex: 2
            });
        }
    }

    // error - 3
    const { totalHouseholdsServed, Total } = data;
    if (totalHouseholdsServed !== (Total)) {
        errors.push({
            msg: Table2AlternateMessages.householdServed4,
            fieldName: 'sec4total',
            sectionIndex: 4
        });
    }

    // TotalNumberofRestrictedUnitsNotMatchWithSystem
    // const raceCount = validationErrors.filter(vr => vr.title === "RaceandEthnicityTotalServedNotEqualTotalHouseholdsServed").length > 0;
    // if (raceCount) {
    //     errors.push({
    //         msg: Table2AlternateMessages.householdServed4,
    //         fieldName: 'sec4total',
    //         sectionIndex: 4,
    //         type: 3
    //     });
    // }

    // error - 4
    const { totalHouseholdServedIncome } = data;
    if (totalHouseholdServedIncome !== totalHouseholdsServed) {
        errors.push({
            msg: Table2AlternateMessages.householdServed5,
            fieldName: 'sec5total',
            sectionIndex: 5
        });
    }

    // error - 5
    const { totalSingleParentHouseholds } = data;
    if (totalSingleParentHouseholds > totalHouseholdsServed) {
        errors.push({
            msg: Table2AlternateMessages.totalSingleParent,
            fieldName: 'sec6total',
            sectionIndex: 6
        });
    }

    return errors;
}

export default Table2AlternateValidations;
