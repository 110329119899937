import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// MAterial UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Actions
import { occupantsActions } from '../../../actions/occupants';
import { confActions } from '../../../actions/configuration';
import { alertActions } from '../../../actions/alert';

// Import Components
import TextBox from '../../../components/TextBox/TextBox';
import TextArea from '../../../components/TextArea/TextArea';
import DropDown from '../../../components/DropDown/DropDown';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import NumberField from '../../../components/NumberField/NumberField';
import DeleteDialog from "../../../components/DeleteDialog/DeleteDialog";
import TableErrors from '../../../components/TableErrors/TableErrors';

// import global validation requirements
import ValidatorForm from "../../../helpers/fieldValidations";
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import { occupantRequiredFields } from "./Validations";

// import helpers
import { history, getFloat, formatDate } from '../../../helpers';
import { configurationConstants, occupantsTabIndex } from '../../../helpers/appConstants';
import { Table1MRDValidations } from '../../../helpers/TableValidations/Table1';
import { Table4Service } from '../../../services';
/**
 * MonthlyRentData Container
 * @class MonthlyRentData
 * @extends {Component}
 */
class MonthlyRentData extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            setAsideType_id_LIHPct_MoveIn: '',
            setAsideType_id_LIHPct: '',
            percentageOfAreaMedianIncome_DENORM: '',
            annualGrossIncome: '',
            moveInAnnualGrossIncome: '',
            moveInMaximumAllowableIncome: '',
            residentPaymentAmount: '',
            maximumAllowableRent: '',
            subsidyAmount: '',
            RUPLIHPct: [],
            currentUASources: [],
            limits: {
                incomeAmounts: [],
                incomeAmountsMoveIn: [],
                rentAmounts: [],
                taxCreditEffectiveDate_id: null
            },
            canChangeMoveInDate: false,
            _id: null,
            selectedCUA: [],
            recentCertDate: '',
            UASources: [],
            isAfterPHASetAside: false,
            isHomeProjectByBedroom: false,
            disabledUA: [],
            phaNote: null,
            UAs: [22, 36, 37],
            subsidyType_id: null,
            unitType: null,
            isDisabledUASelected: false,
            householdSize_MoveIn: null,
            householdSize: null,
            isHigherRUP: 0,
            disableSave: false,
            isMoveinMatch: 0,
            annualGrossIncomeOld: 0,
            annualGrossInitialState:false,
            isCurrentYear:false,
            isImplementFromCurrentYear:false,
            moveInYear:null,
            currentYear:null
        };
        this.phaNote = "HOME/NHTF Projects cannot use Section 8 Utility Allowances. Please choose another UA Source";
    }
    /**
     * Component Will Mount
     * 
     * [1]  -   Validation rule for 'utility allowance source - when No UA Added'
     * [2]  -   Validation rule for 'disabled utility allowance source selected'
     */
    UNSAFE_componentWillMount() {
        // [1]
        ValidatorForm.addValidationRule('isNO_UA', (value) => {
            return this.state.UASources.length > 0;
        });
        // [2]
        ValidatorForm.addValidationRule('isDisabledUASelected', (value) => {
            return this.state.isDisabledUASelected === false;
        });
    }
    /**
     * Component Did Mount
     * 
     * [1]  -   get validation error for occupants
     * [2]  -   for new move in get config using getone site_id, building_id, unit_id
     * [3]  -   config
     */
    componentDidMount() {
        const { tableId, getOne, reportYear_id } = this.props;
        if (tableId) {
            this.props.getOccupantsDetails(tableId, reportYear_id);
            // [1]
            this.props.getOneValidationError(tableId, reportYear_id);
        } else {
            // [1]
            const { site_id, building_id, unit_id } = getOne.data || { site_id: { value: 0 }, building_id: { value: 0 }, unit_id: { value: 0 } };
            if (site_id.value && building_id.value && unit_id.value) {
                this.props.getMoveInConfig(site_id.value, building_id.value, unit_id.value, getOne.data);
            }
        }
        // [3]
        this.props.getConfiguration(configurationConstants.subsidyType);
        this.props.getConfiguration(configurationConstants.utilityAllowanceSource);
        this.props.getConfiguration(configurationConstants.setAsideType);
        this.props.getConfiguration(configurationConstants.rentLimitIncomeLimitType);
        this.props.getConfiguration(configurationConstants.bedrooms);
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne } = nextProps;
        if (getOne.data && (this.state._id === null || this.state._id === 1)) {
            const { isHomeProjectByBedroom, isAfterPHASetAside, unitType } = getOne.data;
            let selectedCUA = getOne.data.currentUASources.map(cua => cua.source_id);
            let UAs = [22, 36, 37];
            // Based on required validation for public authority ua has been removed
            // let isHOMENHTF = (unitType === 1 || unitType === 2);
            // let disabledUA = isHOMENHTF && isHomeProjectByBedroom && isAfterPHASetAside === false ? UAs : [];
            let disabledUA = [];

            let hasPAOption = selectedCUA.filter(s => UAs.indexOf(s) >= 0).length > 0;
            const isDisabledUASelected = disabledUA.indexOf(getOne.data.utilityAllowanceSource_id) >= 0;
            this.setState({
                ...getOne.data,
                annualGrossInitialState:true,
                utilityAllowance: getOne.data.utilityAllowance,
                selectedCUA,
                isHomeProjectByBedroom,
                isAfterPHASetAside,
                disabledUA,
                phaNote: disabledUA.length > 0 && selectedCUA.length > 0 && hasPAOption && isDisabledUASelected ? this.phaNote : null,
                isDisabledUASelected: isDisabledUASelected,
                annualGrossIncomeOld: getOne.data.annualGrossIncome,
                UAs
            });
        }
    }

    /**
     * Get Reduced Total
     * @param {*} arr 
     * @param {*} key 
     * @param {*} initialValue 
     * @returns 
     */
    getReduceTotal(arr, key, initialValue) {
        return arr.reduce((a, b) => {
            const cVal = b[key];
            const value = cVal === null || cVal === '' || cVal === 'undefined' ? 0 : cVal;
            return a + parseFloat(value);
        }, initialValue);
    }

    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {

        this.setState({ [name]: value });
        
        if(name==='annualGrossIncome') this.setState({annualGrossInitialState:false})

        const {isCurrentYear,isImplementFromCurrentYear,moveInYear,currentYear} = this.state
        if(name==='annualGrossIncome' && this.state.canChangeMoveInDate && !this.state.annualGrossInitialState){
            let isNewOccupant = history.location.pathname.includes('new')
            const { table1HouseholdMemberDC, table1HouseholdMemberDCIncomeFromAssets } = this.state;
            let table1HouseholdMemberDCValue = table1HouseholdMemberDC ? table1HouseholdMemberDC : []
            const totalEW = this.getReduceTotal(table1HouseholdMemberDCValue, 'employmentOrWages', 0);
            const totalSOC = this.getReduceTotal(table1HouseholdMemberDCValue, 'socSecPensions', 0);
            const totalPA = this.getReduceTotal(table1HouseholdMemberDCValue, 'publicAssistance', 0);
            const totalOT = this.getReduceTotal(table1HouseholdMemberDCValue, 'otherIncome', 0);
            const totalCV = this.getReduceTotal(table1HouseholdMemberDCIncomeFromAssets, 'cashValue', 0);
            const totalAI = this.getReduceTotal(table1HouseholdMemberDCIncomeFromAssets, 'annualAssetIncome', 0);
            const totalInc = totalEW + totalSOC + totalPA + totalOT;
            const imputedValue = totalCV > 5000 ? totalCV : 0;
            const imputedCalc = ((isCurrentYear===true || isImplementFromCurrentYear===true || isNewOccupant === true)&& (isNewOccupant === true? parseInt(moveInYear)>=2023 : parseInt(currentYear)>=2023))?(imputedValue * (0.06/ 100)).toFixed(0):(imputedValue * (2 / 100)).toFixed(0);
            const totalIncomeFromAssests = imputedCalc > totalAI ? parseFloat(imputedCalc) : totalAI;
            let allSourcesAmount = (totalIncomeFromAssests + totalInc) ||  0;
            let allSourcesTotal = allSourcesAmount;

        // this.state.table1HouseholdMemberDC.map(item => {
        //     allSourcesTotal += item["employmentOrWages"] + item["otherIncome"] + item["publicAssistance"] + item["socSecPensions"];
        // })

        // this.state.table1HouseholdMemberDCIncomeFromAssets.map(item => {
        //     allSourcesTotal += item["annualAssetIncome"];
        // })

        allSourcesTotal = Math.round(allSourcesTotal)
        if (isNaN(allSourcesTotal)) allSourcesTotal = 0

        setTimeout(() => {
            if (allSourcesTotal !== parseInt(this.state.annualGrossIncome)) {
                this.setState({ isMoveinMatch: 1 })
            }
        }, 3000);
    }
    }
    /**
     * Hanlde Submit
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
        console.log('***1',this.props)
        // Table4Service.getConvertedTable4Data(this.props.reportId, 'projects')
    };

    handlePrevButton(unitDesignation_id) {

        const generalData = {
            setAsideType_id_LIHPct_MoveIn: this.state.setAsideType_id_LIHPct_MoveIn === undefined ? null : this.state.setAsideType_id_LIHPct_MoveIn,
            setAsideType_id_LIHPct: this.state.setAsideType_id_LIHPct === undefined ? null : this.state.setAsideType_id_LIHPct,
            notes: this.state.notes,
            incomeLimit_id_MoveIn: this.getMoveInMaxIncome()._id,
            incomeLimit_id: this.getCurrentMaxIncome()._id,
            rentLimit_id: this.getMaxRent()._id,
            percentageOfAreaMedianIncome_DENORM: this.getFloat(this.getAreaMedianIncome()),
            utilityAllowanceSource_id: this.state.utilityAllowanceSource_id,
            utilityAllowance: this.state.utilityAllowance,
            subsidyType_id: this.state.subsidyType_id === undefined ? null : this.state.subsidyType_id,
            subsidyAmount: this.state.subsidyAmount,
            residentPaymentAmount: this.state.residentPaymentAmount,
            moveInAnnualGrossIncome: this.state.moveInAnnualGrossIncome,
            annualGrossIncome: this.state.annualGrossIncome,
            maximumAllowableRent: this.getMaxRent().amount || 0,
            maximumAllowableIncome: this.getCurrentMaxIncome().amount || 0,
            moveInMaximumAllowableIncome: this.getMoveInMaxIncome().amount || 0,
            project_id: this.state.project_id,
            site_id: this.state.site_id,
            reportYear_id: this.state.reportYear_id,
            maximumAllowableHOMERent: this.state.unitType === 1 ? this.getMaxRentHOME().amount || 0 : (this.state.unitType === 2 ? this.getMaxRentNHTF().amount || 0 : 0)
        };
        const { tableId, getOne } = this.props;

        if (tableId) {
            this.setState({ disableSave: true });
            this.props.updateOccupantsMRD(this.props.tableId, generalData, (data) => {
                // this.setState({ disableSave: false })
                
                console.log("UNIT DESIGNATION",unitDesignation_id)
                if (unitDesignation_id === 1) {
                    this.props.handleNav(null, 2);
                } else {
                    this.props.handleNav(null, 0);
                }
                this.props.getAlertOnSuccess("Updated Successfully")
                // setTimeout(() => {
                //     history.replace({
                //         pathname: `/occupants/${tableId}`
                //     });
                // }, 1000);
            });
            
        }else{
            if (unitDesignation_id === 1) {
                this.props.handleNav(null, 2);
            }
        }
    }
    /**
     * Save Form
     * 
     * [1]  -   set up api form submission datas
     */
    _saveForm() {
        // 
        localStorage.removeItem("specialNeedOption_id")
        const generalData = {
            setAsideType_id_LIHPct_MoveIn: this.state.setAsideType_id_LIHPct_MoveIn === undefined ? null : this.state.setAsideType_id_LIHPct_MoveIn,
            setAsideType_id_LIHPct: this.state.setAsideType_id_LIHPct === undefined ? null : this.state.setAsideType_id_LIHPct,
            notes: this.state.notes,
            incomeLimit_id_MoveIn: this.getMoveInMaxIncome()._id,
            incomeLimit_id: this.getCurrentMaxIncome()._id,
            rentLimit_id: this.getMaxRent()._id,
            percentageOfAreaMedianIncome_DENORM: this.getFloat(this.getAreaMedianIncome()),
            utilityAllowanceSource_id: this.state.utilityAllowanceSource_id,
            utilityAllowance: this.state.utilityAllowance,
            subsidyType_id: this.state.subsidyType_id === undefined ? null : this.state.subsidyType_id,
            subsidyAmount: this.state.subsidyAmount,
            residentPaymentAmount: this.state.residentPaymentAmount,
            moveInAnnualGrossIncome: this.state.moveInAnnualGrossIncome,
            annualGrossIncome: this.state.annualGrossIncome,
            maximumAllowableRent: this.getMaxRent().amount || 0,
            maximumAllowableIncome: this.getCurrentMaxIncome().amount || 0,
            moveInMaximumAllowableIncome: this.getMoveInMaxIncome().amount || 0,
            project_id: this.state.project_id,
            site_id: this.state.site_id,
            reportYear_id: this.state.reportYear_id,
            maximumAllowableHOMERent: this.state.unitType === 1 ? this.getMaxRentHOME().amount || 0 : (this.state.unitType === 2 ? this.getMaxRentNHTF().amount || 0 : 0)
        };
        const { tableId, getOne } = this.props;

        if (tableId) {
            this.setState({ disableSave: true });
            this.props.updateOccupantsMRD(this.props.tableId, generalData, (data) => {
                // this.setState({ disableSave: false })
                setTimeout(() => {
                Table4Service.getConvertedTable4Data(this.props.reportId || this.props.reportYear_id, 'projects')
                    history.replace({
                        pathname: `/occupants/${tableId}`,
                        state: {
                            // page: page,
                            projectData: {
                                table_id: data.id,
                                reportId:this.props.reportId
                            },
                            pageData: {
                                table_id: data.id,
                                backButton: 'true',
                            },

                        }
                    });
                }, 1000);
            });
            
        } else {
            const moveInFormData = {
                ...getOne.data,
                ...generalData
            };
            const { project_id, site_id, building_id, unit_id } = getOne.data;
            if (unit_id.value) {
                this.setState({ disableSave: true })
                this.props.moveInOccupant(unit_id.value, {
                    ...moveInFormData,
                    project_id: project_id.value,
                    site_id: site_id.value,
                    building_id: building_id.value
                }, (data) => {
                    this.setState({ disableSave: false })
                    const url = occupantsTabIndex[data.tabIndex];
                    const page = `occ${url}`;
                    Table4Service.getConvertedTable4Data(this.props.reportId || this.props.reportYear_id, 'projects')
                    setTimeout(() => {
                        history.replace({
                            pathname: `/occupants/${data.id}`,
                            state: {
                                page: page,
                                pageData: {
                                    table_id: data.id,
                                    backButton: 'true',
                                },
                                projectData: {
                                    table_id: data.id,
                                    reportId:this.props.reportId
                                },
                            }
                        });
                    }, 3000);
                });
            }
        }
    }
    /**
     * Get Float value
     * @param {*} value 
     * @returns 
     */
    getFloat(value) {
        return value === '' || value === null || value === 'undefined' ? 0 : parseFloat(value, 10);
    }
    /**
     * Get Amount Based on Funder Orgs
     * @param {*} setAsideType_id 
     * @param {*} amountsArr 
     * @returns 
     * 
     * [1]  -    if wshfc funder alone
     * [2]  -    if no wshfc and other funder set aside
     * [3]  -    no need to worry about HOME rent type it is already filtered from backend
     * [4]  -   if the site is multi family and other funders too
     * [5]  -   if rent
     * [6]  -   if setaside is WHSFC alone, then filter out other effectiveDate_ids other than
     * [7]  -   if wshfc
     * [8]  -   if less than 2008 use TC
     * [9]  -   check if it is HERA
     */
    getAmountsBasedOnFunderOrgs(setAsideType_id, amountsArr) {
        const { RUPLIHPct, isMultiFamily, moveInDate, limits: { taxCreditEffectiveDate_id, exactLimitWSHFC }, unitType, isWSHFC } = this.state;
        const setAsideOrgs = RUPLIHPct.filter(rup => rup.setAsideType_id === setAsideType_id).map(org => org.org_id);
        let limitHOMENHTF = unitType === 2 ? 5 : 4;
        let limitPoolToLook = [1, 2, 3, limitHOMENHTF];
        let wshfcPCTAlone = false;
        // [1]
        if (setAsideOrgs.indexOf(1) >= 0 && setAsideOrgs.length === 1) {
            limitPoolToLook = [1, 2, limitHOMENHTF];
            wshfcPCTAlone = true;
        }
        // [2]
        if (setAsideOrgs.indexOf(1) < 0 && setAsideOrgs.length > 0) {
            // [3]
            limitPoolToLook = [3, limitHOMENHTF];
        }

        // [4]
        if (isMultiFamily && (setAsideOrgs.length > 1 && setAsideOrgs.indexOf(1) >= 0)) limitPoolToLook.push(3);

        // if rent
        const isRent = amountsArr.filter(a => a.flag === "RentLimit").length > 0;

        if (limitPoolToLook.indexOf(exactLimitWSHFC) === -1 && isWSHFC && isRent) {
            limitPoolToLook.push(exactLimitWSHFC)
        }

        let filteredAmountArray = amountsArr.filter(rtype => limitPoolToLook.indexOf(rtype.type_id) >= 0);
        // [5]
        // const isRent = amountsArr.filter(a => a.flag === "RentLimit").length > 0;
        // // [6]
        if (wshfcPCTAlone && taxCreditEffectiveDate_id && isRent) {
            filteredAmountArray = filteredAmountArray.filter(f => {
                // [7]
                if (f.effectiveDate_id === taxCreditEffectiveDate_id) {
                    return true;
                } else if (limitPoolToLook.indexOf(limitHOMENHTF) >= 0 && f.type_id === limitHOMENHTF) {
                    return true;
                } else {
                    return false;
                }
            });
        }

        // [8]
        if (filteredAmountArray.length === 0 && new Date(moveInDate).getFullYear() < 2009) {
            return amountsArr;
        }

        // [9]
        if (limitPoolToLook.indexOf(3) < 0 && unitType !== 1) {
            const hasHERAVal = filteredAmountArray.filter(f => f.type_id === 2);
            if (hasHERAVal.length > 0) {
                return hasHERAVal;
            } else {
                return filteredAmountArray;
            }
        }

        return filteredAmountArray;
    }
    /**
     * Check if same amount exists from other limit pool
     * @param {*} amountData 
     * @param {*} amounts 
     * @param {*} setAsideType_id 
     * @returns 
     * 
     * [1]  -   if same amount exits in HOME / NHTF type
     * [2]  -   if not HOME unit then check for TC and Multifamily has same Amount
     *          if available then priority is Multifamily
     */
    checkIfSameAmountExitsfromOtherLimitPool(amountData, amounts, setAsideType_id) {
        const { unitType, isWSHFC, RUPLIHPct } = this.state;
        const isHOMEUnit = unitType === 1 || unitType === 2;
        let limitHOMENHTF = unitType === 2 ? 5 : 4;
        // [1]
        const ifHOMEAmount = amounts.filter(amt => isHOMEUnit && amountData.amount === amt.amount && amt.type_id === limitHOMENHTF);
        if (ifHOMEAmount.length > 0) {
            return ifHOMEAmount[0];
        }
        // [2]
        const setAsideOrgs = RUPLIHPct.filter(rup => rup.setAsideType_id === setAsideType_id).map(org => org.org_id);
        const isWSHFC_NTHome = (isHOMEUnit === false && isWSHFC) || (isHOMEUnit === true && isWSHFC && (setAsideOrgs.length > 1 && setAsideOrgs.indexOf(1) >= 0));
        const ifTCAmount = amounts.filter(amt => isWSHFC_NTHome && amountData.amount === amt.amount && amt.type_id === 3);
        if (ifTCAmount.length > 0) {
            return ifTCAmount[0];
        }

        return amountData;

    }
    /**
     * Apply Max HH size formula
     * @param {*} minData 
     * @param {*} householdSize 
     * @returns 
     */
    applyMaxHHSizeFormula(minData, householdSize) {
        let newAmount = { ...minData };
        if (newAmount) {
            if (newAmount._id !== null && householdSize > 8) {
                newAmount.amount = Math.round((((householdSize - 4) * 0.08) + 1) * minData.amount);
            }
        }
        return newAmount;
    }
    /**
     * Get Movein Max Income
     * @returns 
     */
    getMoveInMaxIncome() {
        const { limits: { incomeAmountsMoveIn }, setAsideType_id_LIHPct_MoveIn, unitType, householdSize_MoveIn } = this.state;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct_MoveIn, incomeAmountsMoveIn);
        let { minData, amountsToLook } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct_MoveIn, unitType);
        minData = this.checkIfSameAmountExitsfromOtherLimitPool(minData, amountsToLook, setAsideType_id_LIHPct_MoveIn);
        minData = this.applyMaxHHSizeFormula(minData, householdSize_MoveIn);
        return minData;
    }
    /**
     * Get Max Rent
     * @returns 
     */
    getMaxRent() {
        const { limits: { rentAmounts }, setAsideType_id_LIHPct, unitType } = this.state;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct, rentAmounts);
        let { minData, amountsToLook } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct, unitType);
        minData = this.checkIfSameAmountExitsfromOtherLimitPool(minData, amountsToLook, setAsideType_id_LIHPct);
        return minData;
    }
    /**
     * Get Current Max Income
     * @returns 
     */
    getCurrentMaxIncome() {
        const { limits: { incomeAmounts }, setAsideType_id_LIHPct, unitType, householdSize } = this.state;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct, incomeAmounts);
        let { minData, amountsToLook } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct, unitType);
        minData = this.checkIfSameAmountExitsfromOtherLimitPool(minData, amountsToLook, setAsideType_id_LIHPct);
        minData = this.applyMaxHHSizeFormula(minData, householdSize);
        return minData;
    }
    /**
     * Get Maxt Rent Home
     * @returns 
     */
    getMaxRentHOME() {
        const { limits: { rentAmounts }, setAsideType_id_LIHPct, unitType } = this.state;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct, rentAmounts);
        let { minData, amountsToLook } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct, unitType);
        const amountsLIHPCT = amountsToLook.filter(a => {
            return a.type_id === 4;
        });
        minData = amountsLIHPCT.length > 0 ? { ...amountsLIHPCT[0] } : { amount: 0, _id: null };
        amountsLIHPCT.forEach((a) => {
            if (a.amount > minData.amount) {
                minData = a;
            }
        });
        return minData;
    }
    /**
     * Get Max Rent NHTF
     * @returns 
     */
    getMaxRentNHTF() {
        const { limits: { rentAmounts }, setAsideType_id_LIHPct, unitType } = this.state;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct, rentAmounts);
        let { minData, amountsToLook } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct, unitType);
        const amountsLIHPCT = amountsToLook.filter(a => a.type_id === 5);
        minData = amountsLIHPCT.length > 0 ? { ...amountsLIHPCT[0] } : { amount: 0, _id: null };
        amountsLIHPCT.forEach((a) => {
            if (a.amount > minData.amount) {
                minData = a;
            }
        });
        return minData;
    }
    /**
     * Get area Median Income
     * @returns 
     */
    getAreaMedianIncome() {
        const { annualGrossIncome, setAsideType_id_LIHPct, unitType } = this.state;
        let currentMaxIncome = this.getCurrentMaxIncome().amount;

        if (currentMaxIncome === 0 || setAsideType_id_LIHPct === null) return '';
        let validPCT = this.getValidPCT(setAsideType_id_LIHPct, unitType);

        const { LIHPct } = this.props;
        const pctData = LIHPct.filter(lt => lt.key === validPCT);
        const pctValue = pctData.length > 0 ? (pctData[0].pctValue) * 100 : 1;
        currentMaxIncome = currentMaxIncome === '' ? 1 : currentMaxIncome;
        const maxIncome = (currentMaxIncome / pctValue);
        return `${((this.getFloat(annualGrossIncome) / maxIncome)).toFixed(0)}%`;
    }
    /**
     * Get Move-in Income Title
     * @returns 
     */
    getMoveinIncomeTitle() {
        const { limits: { incomeAmountsMoveIn }, moveInDate, setAsideType_id_LIHPct_MoveIn, unitType } = this.state;
        const { LIHPct, limitPool } = this.props;
        const { year, type_id } = this.getMoveInMaxIncome();
        if (year === undefined || type_id === undefined) return null;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct_MoveIn,
            incomeAmountsMoveIn);
        let { minData } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct_MoveIn, unitType);
        const rup = LIHPct.filter(l => l.key === minData.setAsideType_id)[0] || { name: '' };
        return `Effective Date: ${formatDate(moveInDate)}, year: ${year}, Effective RUP: ${rup.name}, Limit Pool: ${limitPool[type_id]}`;
    }
    /**
     * Get Income Tile
     * @returns 
     */
    getIncomeTitle() {
        const { limits: { incomeAmounts }, recentCertDate, setAsideType_id_LIHPct, unitType } = this.state;
        const { LIHPct, limitPool } = this.props;
        const { year, type_id } = this.getCurrentMaxIncome();
        if (year === undefined || type_id === undefined) return null;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct, incomeAmounts);
        let { minData } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct, unitType);
        const rup = LIHPct.filter(l => l.key === minData.setAsideType_id)[0] || { name: '' };
        return `Effective Date: ${formatDate(recentCertDate)}, year: ${year}, Effective RUP: ${rup.name}, Limit Pool: ${limitPool[type_id]}`;
    }
    /**
     * Get Rent Title
     * @returns 
     */
    getRentTitle() {
        const { limits: { rentAmounts }, setAsideType_id_LIHPct, unitType } = this.state;
        const { LIHPct, limitPool, bedrooms } = this.props;
        const { year, type_id, bedroom_id } = this.getMaxRent();
        const bd = bedrooms.filter(b => bedroom_id === b.bedCount)[0] || { bedCount: '' };
        if (year === undefined || type_id === undefined) return null;
        const filteredAmount = this.getAmountsBasedOnFunderOrgs(setAsideType_id_LIHPct, rentAmounts);
        let { minData } = this.getAvailablePCT(filteredAmount, setAsideType_id_LIHPct, unitType);
        const rup = LIHPct.filter(l => l.key === minData.setAsideType_id)[0] || { name: '' };
        return `Year: ${year}, Effective RUP: ${rup.name}, Bedroom Count: ${bd.bedCount} Limit Pool: ${limitPool[type_id]}`;
    }
    /**
     * Get Available PCT
     * @param {*} amounts 
     * @param {*} setAsideType_id 
     * @param {*} unitType 
     * @returns 
     * 
     * [1]  -   if available return as it is
     * [2]  -   if no setasides found get next least highest val
     *          leave home / NHTF type
     * [3]  -   if not available check for the least highest value
     * [4]  -   get pct value for setAsideType_id
     * [5]  -   assign name for amounts - pctvalue
     * [6]  -   get next least highest value
     *          for home only
     * [7]  -   assign the max value
     * [8]  -   get the last max values
     */
    getAvailablePCT(amounts, setAsideType_id, unitType) {
        const isPCTAvailable = amounts.filter(f => f.setAsideType_id === setAsideType_id)[0] || { _id: null };
        let setAsidestoLook = [];
        // [1]
        if (isPCTAvailable._id) {
            setAsidestoLook.push(isPCTAvailable.setAsideType_id);
        }
        // [2]
        let unitTypeToLook = [];
        if (setAsidestoLook.length === 0) {
            unitTypeToLook = [1, 2, 3];
        }
        // [3]
        const { LIHPct } = this.props;
        // [4]
        const pctValue = LIHPct.filter(f => f.key === setAsideType_id)[0] || { name: "0%" };
        let pctIntVal = parseInt(pctValue.name, 10);
        let nextHightVal = 0;
        // [5]
        const upNameAmounts = amounts.map(a => {
            const name = LIHPct.filter(f => f.key === a.setAsideType_id)[0] || { name: "0%" };
            return {
                ...a,
                name,
                pctIntVal: parseInt(name.name, 10)
            }
        });
        // [6]
        const dt = { '1': 4, '2': 5 };
        unitTypeToLook.push(dt[unitType]);
        const pctIntValsArr = global._.sortBy(upNameAmounts.filter(t => unitTypeToLook.indexOf(t.type_id) >= 0), 'pctIntVal');
        let maxVal = 0;
        let homeAmount = [];
        if (pctIntVal) {
            for (let i = 0; i < pctIntValsArr.length; i++) {
                maxVal = pctIntValsArr[i];
                if (pctIntValsArr[i].pctIntVal > pctIntVal) {
                    nextHightVal = pctIntValsArr[i];
                    maxVal = pctIntValsArr[i];
                    break;
                }
            }
            // [7]
            if (nextHightVal === 0) {
                // [8]
                nextHightVal = maxVal;
            }

            homeAmount = nextHightVal ? [...pctIntValsArr.filter(f => f.pctIntVal === nextHightVal.pctIntVal)] : [];
        }
        const otherAmounts = amounts.filter(a => setAsidestoLook.indexOf(a.setAsideType_id) >= 0);
        const amountsToLook = [...otherAmounts, ...homeAmount];
        const amountsLIHPCT = amountsToLook;
        let minData = amountsLIHPCT.length > 0 ? { ...amountsLIHPCT[0] } : { amount: 0, _id: null, setAsideType_id: 1 };
        amountsLIHPCT.forEach((a) => {
            if (a.amount < minData.amount) {
                minData = a;
            }
        });
        return {
            minData,
            amountsToLook,
            setAsidestoLook
        };
    }
    /**
     * Get Vaild PCT
     * @param {*} pctKey 
     * @param {*} unitType 
     * @returns 
     */
    getValidPCT(pctKey, unitType) {
        let validPCT = pctKey;
        if (pctKey === "" || pctKey === undefined || pctKey === null) return null;
        if (unitType === 1 || unitType === 2) {
            if (validPCT <= 10) {
                validPCT = 10
            } else {
                validPCT = 11
            }
        }
        return validPCT;
    }
    /**
     * Get UA Amount
     * @returns 
     */
    getUtillityAllowanceAmount() {
        const { utilityAllowanceSource_id, currentUASources } = this.state;
        let maxD = null;
        let UATotalAmt = 0;
        currentUASources.filter(ca => ca.source_id === utilityAllowanceSource_id).forEach(b => {
            let d = new Date(b.effectiveDate || b.dateUA);
            if (maxD === null || d > maxD) {
                maxD = d;
                UATotalAmt = b.amount;
            }
        });
        return getFloat(UATotalAmt);
    }
    /**
     * Get Previous RUP
     * @returns 
     */
    getPrevRUP() {
        const { previousRestrictedUnitPercentage } = this.state;
        const { LIHPct } = this.props;
        const rup = LIHPct.filter(lt => lt.key === previousRestrictedUnitPercentage)[0] || { name: 'N/A' };
        return rup.name;
    }
    /**
     * Get Menu Items
     * @returns 
     */
    getMenuItems() {
        const { selectedCUA, isAfterPHASetAside, unitType, UAs } = this.state;
        let { utilityAllowanceSourceOptions } = this.props;
        if (isAfterPHASetAside && unitType === 1) {
            utilityAllowanceSourceOptions = utilityAllowanceSourceOptions.filter(ua => {
                if (UAs.indexOf(ua.key) >= 0) {
                    return false;
                }
                return true;
            });
        }
        let MenuList = [];
        utilityAllowanceSourceOptions.forEach((item) => {
            if (selectedCUA.indexOf(item.key) >= 0) {
                MenuList.push(item)
            } else {
                return null;
            }
        })
        return MenuList;
    }
    /**
     * Get If Higher RUP
     * @param {*} chosenRUP 
     * @param {*} currentRUP 
     * @param {*} resetField 
     * @returns 
     */
    getIsHigherRUP(chosenRUP, currentRUP, resetField) {
        let { unitDesignation_id } = this.state;
        const { LIHPct } = this.props;
        let chosenPCTValue = LIHPct.filter(s => s.value === chosenRUP)[0];
        chosenPCTValue = chosenPCTValue ? chosenPCTValue.pctValue : 0;
        unitDesignation_id = unitDesignation_id ? unitDesignation_id : 0;
        let maxRUP = Math.max(...currentRUP.map(s => s.pctValue), 0);
        if (chosenPCTValue > maxRUP && this.state.isHigherRUP === 0 && unitDesignation_id === 1) {
            return resetField;
        } else {
            return 0;
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   get field level validations for table 3
     * [2]  -   get current RUP
     */
    render() {
        let { classes, pageAccess, LIHPct, subsidyTypeOptions, getOne, validationErrors } = this.props;
        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        const { setAsideType_id_LIHPct_MoveIn, setAsideType_id_LIHPct, moveInAnnualGrossIncome, annualGrossIncome, residentPaymentAmount, utilityAllowanceSource_id, subsidyAmount, subsidyType_id, notes, RUPLIHPct, canChangeMoveInDate, selectedCUA, disabledUA, phaNote, unitType, isHigherRUP, householdSize, householdSize_MoveIn, specialNeedOption_id, unitDesignation_id } = this.state;
        const utilityAllowance = this.getUtillityAllowanceAmount();
        const total = this.getFloat(residentPaymentAmount) + this.getFloat(utilityAllowance);
        // [1]
        const fielderrors = Table1MRDValidations({ ...this.state, total, validationErrors });
        // [2]
        let rupCurrent = LIHPct.map((item) => {
            if (RUPLIHPct.filter(rup => rup.setAsideType_id === item.key).length > 0) {
                return item;
            } else {
                return null;
            }
        });
        rupCurrent = rupCurrent.filter(f => f !== null);

        //handle error for largehousehold
        let valErrors = validationErrors;
        if (unitDesignation_id && unitDesignation_id === 1) {
            if (!canChangeMoveInDate) {
                if (specialNeedOption_id === 2 && householdSize_MoveIn < 4 && householdSize < 4) {
                    let index = valErrors.findIndex(x => x.message === "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                    if (index === -1) {
                        valErrors.push({
                            "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                            "errorType": 2, "tabIndex": 0
                        })
                    }
                }
                if (specialNeedOption_id === 2 && householdSize >= 4) {
                    if (valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")) > -1) {
                        valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")), 1)
                    }
                }
            } else {
                if (specialNeedOption_id === 2 && householdSize_MoveIn < 4 && householdSize < 4) {
                    let index = valErrors.findIndex(x => x.message === "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                    if (index === -1) {
                        valErrors.push({
                            "message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                            "errorType": 2, "tabIndex": 0
                        })
                    }
                }
                if (specialNeedOption_id === 2 && (householdSize_MoveIn >= 4 || householdSize >= 4)) {
                    if (valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")) > -1) {
                        valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")), 1)
                    }
                }
            }
        }
        return (
            <div className={classes.rootEdit} >
                <TableErrors
                    fielderrors={fielderrors}
                    validations={validationErrors}
                    data={getOne.data}
                    classes={classes}
                />
                <ValidatorForm
                    name="occupantsDetailsform"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                name={`setAsideType_id_LIHPct_MoveIn`}
                                placeholder="Move-In Restricted Unit Percentage"
                                suggestions={LIHPct}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        setAsideType_id_LIHPct_MoveIn: selectedOption.value,
                                        setAsideType_id_LIHPct: selectedOption.value,
                                        isHigherRUP: this.getIsHigherRUP(selectedOption.value, rupCurrent, 'setAsideType_id_LIHPct_MoveIn')
                                    });
                                }}
                                selectedValue={{ id: setAsideType_id_LIHPct_MoveIn }}
                                margin="dense"
                                disabled={isReadOly || !canChangeMoveInDate}
                                {...occupantRequiredFields.moveInRestrictedUnitPercentage(this.state)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AutoComplete
                                name={`setAsideType_id_LIHPct`}
                                placeholder="Restricted Unit Percentage"
                                suggestions={LIHPct}
                                // suggestions={rupCurrent}
                                handleAutoCompolete={(selectedOption) => {
                                    this.setState({
                                        setAsideType_id_LIHPct: selectedOption.value,
                                        isHigherRUP: this.getIsHigherRUP(selectedOption.value, rupCurrent, 'setAsideType_id_LIHPct')
                                    });
                                }}
                                selectedValue={{ id: setAsideType_id_LIHPct }}
                                margin="dense"
                                disabled={isReadOly}
                                {...occupantRequiredFields.restrictedUnitPercentage(this.state)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}  >
                            <TextBox
                                name="previousRestrictedUnitPercentage"
                                label={"Previous Restricted Unit Percentage"}
                                value={this.getPrevRUP()}
                                className={`${classes.textField} SARD m-t-3`}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="moveInAnnualGrossIncome"
                                label="Move-in Annual Gross Income"
                                value={moveInAnnualGrossIncome || 0}
                                className={classes.textField}
                                handleChange={(name, value) => {
                                    if (canChangeMoveInDate) {
                                        this.setState({
                                            moveInAnnualGrossIncome: value,
                                            annualGrossIncome: value
                                        });
                                    } else {
                                        this.setState({
                                            moveInAnnualGrossIncome: value
                                        });
                                    }

                                }}
                                handleBlur={() => {
                                    this.setState({ moveInAnnualGrossIncome: Math.round(moveInAnnualGrossIncome) })
                                }}
                                margin="dense"
                                disabled={isReadOly || canChangeMoveInDate === false}
                                prefix={'$'}
                                thousandSeparator={true}
                                {...occupantRequiredFields.moveInAnnualGrossIncome(this.state)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} title={this.getMoveinIncomeTitle()}>
                            <NumberField
                                name="moveInMaximumAllowableIncome"
                                label="Move-In Maximum Allowable Income"
                                value={this.getMoveInMaxIncome().amount === 0 ? 'N/A' : this.getMoveInMaxIncome().amount}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                                prefix={this.getMoveInMaxIncome().amount === 0 ? '' : '$'}
                                thousandSeparator={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="currentlyMonthlyGrossIncome"
                                label="Currently Monthly Gross Income"
                                value={annualGrossIncome / 12}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="annualGrossIncome"
                                label="Current Annual Gross Income"
                                value={annualGrossIncome || 0}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                handleBlur={() => {
                                    this.setState({ annualGrossIncome: Math.round(annualGrossIncome) })
                                }}
                                margin="dense"
                                disabled={isReadOly}
                                prefix={'$'}
                                thousandSeparator={true}
                                {...occupantRequiredFields.currentAnnualGrossIncome(this.state)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} title={this.getIncomeTitle()}>
                            <NumberField
                                name="currentMaximumAllowableIncome"
                                label="Current Maximum Allowable Income"
                                value={this.getCurrentMaxIncome().amount === 0 ? "N/A" : this.getCurrentMaxIncome().amount}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                                prefix={this.getCurrentMaxIncome().amount === 0 ? '' : '$'}
                                thousandSeparator={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="percentageOfAreaMedianIncome_DENORM"
                                label="Actual % of Median Area Income"
                                value={this.getAreaMedianIncome()}
                                className={`${classes.textField} SARD m-t-0`}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="residentPaymentAmount"
                                label="Resident Payment"
                                value={residentPaymentAmount}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                                prefix={'$'}
                                thousandSeparator={true}
                                {...occupantRequiredFields.residentPayment(this.state)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="utilityAllowance"
                                label="Utility Allowance"
                                value={utilityAllowance}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                                prefix={'$'}
                                thousandSeparator={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DropDown
                                name={`utilityAllowanceSource_id`}
                                value={(this.getMenuItems() != null && this.getMenuItems().filter(e => e.key === utilityAllowanceSource_id).length > 0) ? utilityAllowanceSource_id : ""}
                                select
                                label="Utility Allowance Source"
                                className={`${classes.textField} SARD m-t--3`}
                                handleChange={(name, value) => {
                                    const isDisabledUA = disabledUA.indexOf(value) >= 0;
                                    this.setState({
                                        utilityAllowanceSource_id: value,
                                        phaNote: isDisabledUA ? this.phaNote : null,
                                        isDisabledUASelected: isDisabledUA
                                    });
                                }}
                                margin="dense"
                                disabled={isReadOly}
                                {...occupantRequiredFields.utilityAllowanceSource(this.state)}
                            >
                                <MenuItem key={'null_uasources'} value={null}>{"Select"}</MenuItem>
                                {
                                    this.getMenuItems() != null && this.getMenuItems().map((item) => {
                                        const isDisabledUA = disabledUA.indexOf(item.key) >= 0;
                                        if (selectedCUA.indexOf(item.key) >= 0) {
                                            return <MenuItem
                                                style={isDisabledUA ? { color: '#ccc' } : {}}
                                                key={item.key}
                                                value={item.key}>{item.name}
                                            </MenuItem>;
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </DropDown>
                            <span style={{ color: 'red' }}>
                                {(disabledUA.indexOf(utilityAllowanceSource_id) >= 0 || phaNote !== null) && phaNote}
                            </span>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <NumberField
                                className={classes.textField}
                                name={`total`}
                                label={'Total Resident Payment'}
                                value={total}
                                disabled={true}
                                prefix={'$'}
                                thousandSeparator={true}
                                fielderrors={fielderrors}
                                warningfieldname={'total'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} title={this.getRentTitle()}>
                            <NumberField
                                name="maximumAllowableRent"
                                label={this.getMaxRent().amount !== '' ? "Maximum Allowed Rent:" : "Maximum Allowed Rent: N/A"}
                                value={this.getMaxRent().amount}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={true}
                                prefix={'$'}
                                thousandSeparator={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {
                                unitType === 1 && (
                                    <NumberField
                                        name="maximumAllowablHomeRent"
                                        label={this.getMaxRentHOME().amount !== '' ? "Maximum Allowed HOME Rent:" : "Maximum Allowed HOME Rent: N/A"}
                                        value={this.getMaxRentHOME().amount}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        prefix={'$'}
                                        thousandSeparator={true}
                                    />
                                )
                            }

                            {
                                unitType === 2 && (
                                    <NumberField
                                        name="maximumAllowableNHTFRent"
                                        label={this.getMaxRentNHTF().amount !== '' ? "Maximum Allowed NHTF Rent:" : "Maximum Allowed NHTF Rent: N/A"}
                                        value={this.getMaxRentNHTF().amount}
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        disabled={true}
                                        prefix={'$'}
                                        thousandSeparator={true}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberField
                                name="subsidyAmount"
                                label=" Total Subsidy Amount:"
                                value={subsidyAmount}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                                {...occupantRequiredFields.subsidyAmount(this.state)}
                                fielderrors={fielderrors}
                                warningfieldname={'subsidyAmount'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className="autocompleteList">
                                <AutoComplete
                                    name={`subsidyType_id`}
                                    placeholder="Primary Rent Subsidy Type:"
                                    suggestions={subsidyTypeOptions}
                                    className="SARD m-t--3 autocomplete-fixed"
                                    handleAutoCompolete={(selectedOption) => {
                                        this.setState({
                                            subsidyType_id: selectedOption.value,
                                        });
                                    }}
                                    selectedValue={{ id: subsidyType_id }}
                                    margin="dense"
                                    disabled={isReadOly}
                                    {...occupantRequiredFields.subsidyType(this.state)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextArea
                                name="notes"
                                label="Comment:"
                                value={notes}
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                disabled={isReadOly}
                                multiline={true}
                                {...occupantRequiredFields.isCommentRequired({
                                    ...this.state,
                                    isHigherRUP: this.getIsHigherRUP(setAsideType_id_LIHPct, rupCurrent, true),
                                    isHigherRUPMoveIn: this.getIsHigherRUP(setAsideType_id_LIHPct_MoveIn, rupCurrent, true)
                                })}
                            />
                        </Grid>
                    </Grid>
                    {
                        pageAccess.indexOf('SAVE') > -1 && !this.state.disableSave ? (
                            <SaveCancel
                                handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => {
                                    // const isWSHFC = getOne.data ? getOne.data.isWSHFC : true;
                                    const unitDesignation_id = getOne.data ? getOne.data.unitDesignation_id : 0;
                                    this.handlePrevButton(unitDesignation_id)
                                }}
                                cancelVisible={true}
                                swap={true}
                                cancelText={'Prev'}
                            />
                        ) : null
                    }
                    <DeleteDialog
                        open={isHigherRUP !== 0}
                        content={"Warning: You've chosen a set-aside that is higher than what is allowed by any funder in this project. Do you really want to choose this income set-aside?"}
                        handleDeleteAction={() => {
                            this.setState({ isHigherRUP: 0 });
                        }}
                        handleDeleteOnCloseAction={() => {
                            let rs = {};
                            rs = { [isHigherRUP]: null };
                            if (isHigherRUP === 'setAsideType_id_LIHPct_MoveIn') {
                                rs['setAsideType_id_LIHPct'] = null;
                            }
                            this.setState({ isHigherRUP: 0, ...rs });
                        }}
                    />
                    <DeleteDialog
                        open={this.state.isMoveinMatch !== 0}
                        content={'Warning: Amount in Total annual Household income-All sources field does not match the amount in current annual gross income'}
                        handleDeleteAction={() => {
                            this.setState({ isMoveinMatch: 0 });
                        }}
                        handleDeleteOnCloseAction={() => {
                            this.setState({ annualGrossIncome: this.state.annualGrossIncomeOld, isMoveinMatch: 0,annualGrossInitialState:true });
                        }}
                    />
                </ValidatorForm >

            </div >
        );
    }
}
/**
 * Bind Component Property Types
 */
MonthlyRentData.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}

MonthlyRentData.defaultProps = {
    isReadOly: false,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 */
const mapStateToProps = ({ occupants, configuration: { configurations }, pageAccess }) => {
    let LIHPct = configurations[configurationConstants.setAsideType].filter(lt => lt.category_id === 1);
    const limitPool = {};
    configurations[configurationConstants.rentLimitIncomeLimitType].map(lp => {
        limitPool[lp.key] = lp.name;
        return lp;
    });
    configurations[configurationConstants.bedrooms].map(bd => {
        return bd;
    });
    let subsidyTypes = configurations[configurationConstants.subsidyType].filter(st => st.subsidyTypetype_id === 2);
    return {
        getOne: occupants.getOne,
        LIHPct: global._.sortBy(LIHPct, 'name'),
        utilityAllowanceSourceOptions: global._.sortBy(configurations[configurationConstants.utilityAllowanceSource], 'name'),
        subsidyTypeOptions: global._.sortBy(subsidyTypes, 'name'),
        limitPool,
        bedrooms: configurations[configurationConstants.bedrooms],
        pageAccess: pageAccess['occmonthlyrentdata'] || [],
        validationErrors: occupants.validationErrors
    }
};
/**
 * Map Dispatch to Props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOccupantsDetails: (id, reportYear_id) => dispatch(occupantsActions.getOccupantsDetails(id, reportYear_id)),
        updateOccupantsMRD: (id, formData, callBack) => dispatch(occupantsActions.updateOccupantsMRD(id, formData, callBack)),
        getConfiguration: (name) => dispatch(confActions.getConfiguration(name)),
        getMoveInConfig: (site_id, building_id, unit_id, formData) => dispatch(occupantsActions.getMoveInConfig(site_id, building_id, unit_id, formData)),
        moveInOccupant: (unit_id, data, callBack) => dispatch(occupantsActions.moveInOccupant(unit_id, data, callBack)),
        getOneValidationError: (id, reportYear_id) => dispatch(occupantsActions.getOneValidationError(id, reportYear_id)),
        getAlertOnSuccess: (message) => dispatch(alertActions.success(message))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(MonthlyRentData));

