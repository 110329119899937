import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { Button } from '@material-ui/core';

// Import Components
import TextArea from '../../components/TextArea/TextArea';
import DropDown from '../../components/DropDown/DropDown';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import AutoCompleteMultiMUI from '../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import QueryBuilderComponent from '../../components/QueryBuilder/QueryBuilder';

//Import helpers
import ValidatorForm from '../../helpers/fieldValidations';
import { getChildCardHeight } from '../../helpers/global';
import { arithmeticOperators, ExploreDataConditions } from '../../helpers';

// Import Actions
import PageActions from '../../components/PageActions/PageActions';

// Import Styles
import exploreStyle from '../../assets/jss/containers/explore';


/*Data to insert in rows  */
const DATA = {
    module: {},
    attributes: {},
    condition: '',
    value: '',
    by: {}
}

/**
 * EditableOperatorDropDown component
 * @class EditableOperatorDropDown
 * @extends {Component}
 */
class EditableOperatorDropDown extends Component {
    /**
     * Render
     * @returns 
     * 
     * [1]  -   Filter non-arithmetic operations
     */
    render() {
        let { data, rowIndex, onChange, fieldName, config: { conditions }, disabled } = this.props;
        // [1]
        if (data['attributes']['isArithmetic'] === false) {
            conditions = conditions.filter(elem => !(elem.value === 'average' || elem.value === 'percentage' || elem.value === 'sum'))
        }
        return (
            <DropDown
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                label={'Condition'}
                disabled={disabled}
                validators={disabled ? [] : ['required']}
                errorMessages={disabled ? [] : ['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
                className="conditionDropdown"
            >
                {
                    conditions.map(elem => {
                        return (<MenuItem value={elem.value}>{elem.label}</MenuItem>)
                    })
                }
            </DropDown>

        )
    }
};
/**
 * EditableAttributeAutocomplete component
 * @class EditableAttributeAutocomplete
 * @extends {Component}
 */
class EditableAttributeAutocomplete extends Component {
    /**
     * render
     * @returns 
     * 
     * [1]  -   If fieldName is attributes, then filter the suggessions only for seletced module
     *          ElseIf modules has any value, then push it to suggessions
     * [2]  -   Set value and label foe suggessions
     */
    render() {
        const { data, rowIndex, onChange, fieldName, disabled, config: { explore, modules, reportFilter } } = this.props;
        let suggestions = [];
        let exploreDataClone = {...explore};

        if (explore && Object.keys(explore).length > 0) {

            if (exploreDataClone['Race-Ethnicity Report']) {
                let filterArray = ['City', 'County', 'Zipcode', 'Ownership Organization', 'Property Management Organization']
            //['City', 'County','State','Zipcode', 'Ownership Organization', 'Property Management Organization','Funder']
                const index = filterArray.indexOf(reportFilter);
                if (index > -1) {
                filterArray.splice(index,1)
                }
                exploreDataClone['Race-Ethnicity Report'] = exploreDataClone['Race-Ethnicity Report'].filter((el) => {return !filterArray.find((obj) => {         
                    return  el.title == obj
                  })})
            }

            if(exploreDataClone['Rent Burden Report']){
                let filterArray = ['City', 'County','State','Zipcode', 'Ownership Organization', 'Property Management Organization','Funder']
                const index = filterArray.indexOf(reportFilter);
                if (index > -1) {
                filterArray.splice(index,1)
                }
                exploreDataClone['Rent Burden Report'] = exploreDataClone['Rent Burden Report'].filter((el) => {return !filterArray.find((obj) => {         
                    return  el.title == obj
                  })})
            }
            
        }

        // [1]
        if (data.module && fieldName === 'attributes') {
            suggestions = exploreDataClone[data.module.value]
        } else if (modules.length > 0) {
            modules.forEach(elem => {
                suggestions.push(...exploreDataClone[elem.value])
            })
        }
        // [2]
        suggestions && suggestions.length > 0 && suggestions.forEach((item, index) => {
            suggestions[index] = {
                ...item,
                label: item.title,
                value: item.title
            }
        })
        if(suggestions && suggestions.length>1){
            suggestions = suggestions.sort((a, b) => {
                const ai = parseInt(a.label, 10), bi = parseInt(b.label, 10);
                return (b == null) - (a == null)
                  || (ai - bi)
                  || (a.label > b.label) - (b.label > a.label);
              }).map(x => x);
        }
        return (
            <div className="autocompleteList exploreColumnFilter">
                <AutoComplete
                    suggestions={suggestions}
                    name={`${fieldName}_${rowIndex}`}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption);
                    }}
                    selectedValue={{ id: data[fieldName].value }}
                    placeholder={'Columns'}
                    disabled={disabled}
                    validators={disabled ? [] : ['required']}
                    errorMessages={disabled ? [] : ['this field is required']}
                >
                </AutoComplete>
            </div>
        );
    }
}
/**
 * EditableTextValidation component
 * @class EditableTextValidation
 * @extends {Component}
 */
class EditableTextValidation extends Component {
    /**
     * render
     * @returns 
     * 
     * [1]  -   If fieldName is attributes, then filter the suggessions only for seletced module
     *          ElseIf modules has any value, then push it to suggessions
     * [2]  -   Filter arithmetic suggession if the selected condition is average, percetage or sum
     * [3]  -   Set value and label foe suggessions.
     * [4]  -   If coudition is arithmetic operation, then render By, else render TextArea.
     * [5]  -   If condition is not percentage, then render AutoCompleteMultiMUI, else AutoComplete
     */
    render() {
        const { data, rowIndex, onChange, fieldName, disabled, config: { explore, modules } } = this.props;
        let suggestions = [];
        // [1]
        if (data.module && fieldName === 'attributes') {
            suggestions = explore[data.module.value]
        } else if (modules.length > 0) {
            modules.forEach(elem => {
                suggestions.push(...explore[elem.value])
            })
        }
        // [2]
        if (suggestions && suggestions.length > 0 && (data.condition === 'average' || data.condition === 'percentage' || data.condition === 'sum')) {
            suggestions = suggestions.filter(elem => elem.isArithmetic === true)
        }
        // [3]
        suggestions && suggestions.length > 0 && suggestions.forEach((item, index) => {
            suggestions[index] = {
                ...item,
                label: item.title,
                value: item.title
            }
        })
        // [4]
        if (arithmeticOperators.includes(data.condition)) {
            if ((data.condition !== 'percentage')) {
                return (
                    <AutoCompleteMultiMUI
                        suggestions={suggestions}
                        name={`${fieldName}_${rowIndex}`}
                        handleAutoCompolete={(selectedOption) => {
                            onChange(fieldName, rowIndex, selectedOption);
                        }}
                        selectedValue={data[fieldName]}
                        validators={['required']}
                        placeholder={'By'}
                        label={'By'}
                        errorMessages={['This field is required']}
                        disabled={disabled}
                        needSelectAll={true}
                        needManualFocus={true}
                    />
                )
            } else {
                return (
                    <div className="autocompleteList">
                        <AutoComplete
                            suggestions={suggestions}
                            name={`${fieldName}_${rowIndex}`}
                            handleAutoCompolete={(selectedOption) => {
                                onChange(fieldName, rowIndex, selectedOption);
                            }}
                            selectedValue={{ id: data[fieldName].value }}
                            validators={['required']}
                            placeholder={'By'}
                            label={'By'}
                            errorMessages={['This field is required']}
                        />
                    </div>
                )
            }
        } else {
            return (
                <TextArea
                    name={`${fieldName}_${rowIndex}`}
                    value={data[fieldName]}
                    validators={disabled ? [] : ['required']}
                    errorMessages={disabled ? [] : ['This field is required']}
                    label={'Value'}
                    handleChange={(name, value) => {
                        onChange(fieldName, rowIndex, value);
                    }}
                    multiline={true}
                    disabled={disabled}
                    className="valueDropdown"
                />
            )
        }
    }
};
/**
 * EditableModuleAutocomplete Component
 * @class EditableModuleAutocomplete
 * @extends {Component}
 */
class EditableModuleAutocomplete extends Component {
    /**
     * render
     * @returns 
     * 
     * [1]  -   Set label for suggession
     */
    render() {
        const { data, rowIndex, onChange, fieldName, config: { modules } } = this.props;
        // [1]
        modules.forEach((item, index) => {
            if (!modules[index].label) {
                modules[index] = {
                    label: item,
                    value: item
                }
            }
        })
        return (
            <div className="autocompleteList">
                <AutoComplete
                    suggestions={modules}
                    name={`${fieldName}_${rowIndex}`}
                    handleAutoCompolete={(selectedOption) => {
                        onChange(fieldName, rowIndex, selectedOption);
                    }}
                    selectedValue={{ id: data[fieldName].value }}
                    validators={['required']}
                    placeholder={'Module'}
                    label={'Module'}
                    errorMessages={['this field is required']}
                    className="moduleAutocomplete"
                >
                </AutoComplete>
            </div>
        )
    }
}

/*Mapping  box with the fields */
const componentMap = {
    module: EditableModuleAutocomplete,
    attributes: EditableAttributeAutocomplete,
    condition: EditableOperatorDropDown,
    value: EditableTextValidation,
}
/**
 * FilterAttribute Container
 * @class FilterAttribute
 * @extends {Component}
 */
class FilterAttribute extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            filterType: 'and',
            report_name: '',
            reportYear: 2019,
            attributeSuggessions: [],
            _id: null,
            reportFilter: ''
        }
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        let { filterAttributes } = this.props
        filterAttributes.forEach((item, index) => {
            filterAttributes[index] = {
                ...item,
                module: {
                    label: item.attributes.collection,
                    value: item.attributes.collection
                },
                attributes: {
                    ...item.attributes,
                    label: item.attributes.title,
                    value: item.attributes.title,
                    isArithmetic: item.attributes.isArithmetic
                },
                value: typeof item.value === 'object' ? item.value.length ? item.value : {
                    ...item.value,
                    label: item.value.title,
                    value: item.value.title,
                    isArithmetic: item.attributes.isArithmetic
                } : item.value,
                by: item.by ? {
                    ...item.by,
                    label: item.by.title,
                    value: item.by.title,
                    isArithmetic: item.attributes.isArithmetic
                } : {}
            }
        })
        if (filterAttributes.length === 0) {
            filterAttributes.unshift({
                ...DATA,
                _id: filterAttributes.length > 0 ? Math.max(...filterAttributes.map(elt => elt._id)) + 1 : 0
            })
        }
        this.setState({ rows: filterAttributes })
    }
    /**
     * Handle TextBox 
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    }
    /** 
     * Save form
     * @param {*} type 
     * 
     * [1]  -   Save or preview the form
     */
    saveForm(type) {
        const { filterType } = this.state;
        const { reportFilter } = this.props;
        const reportData = {
            filterType: filterType,
            reportFilter: reportFilter,
            rows: this.state.rows.map((row) => {
                return {
                    attributes: {
                        title: row.attributes.title,
                        header: row.attributes.header,
                        collection: row.attributes.collection,
                        field: row.attributes.field,
                    },
                    condition: row.condition,
                    value: typeof row.value === 'object' ? row.value.length ? row.value : {
                        title: row.value.title,
                        header: row.value.header,
                        collection: row.value.collection,
                        field: row.value.field
                    } : row.value,
                    by: row.by,
                    _id: row._id
                }
            }),
        };
        // [1]
        this.props.updateData(reportData, type);
    }
    /**
     * Binding value to Name
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     * 
     * [1]  -   Set value and label for selectedFields
     * [2]  -   Set value for filter attribute
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        let { selectedFields } = this.props
        // [1]
        selectedFields.map(elem => {
            elem.label = elem.title;
            elem.value = elem.title;
            return elem
        })
        const { rows } = this.state;
        // [2]
        rows[rowIndex][fieldName] = value;
        if (fieldName === 'condition') {
            switch (value) {
                case 'sum':
                    rows[rowIndex].value = [];
                    break;
                case 'count':
                    rows[rowIndex].value = selectedFields || [];
                    break;
                case 'percentage':
                    rows[rowIndex].value = {};
                    break;
                case 'average':
                    rows[rowIndex].value = {};
                    break;
                default:
                    rows[rowIndex].value = ''
                    break;
            }
        }
        this.setState({ rows });
    }
    /**
     * Handle filter - For preview and save
     * @param {*} type 
     */
    handleFilter = (type) => {
        this.refs.form.submit()
        if (type === 'preview') {
            setTimeout(() => {
                if (this.refs.form.errors.length === 0) {
                    this.saveForm('preview')
                }
            }, 1000)
        } else {
            setTimeout(() => {
                if (this.refs.form.errors.length === 0) {
                    this.saveForm('save');
                }
            }, 1000)
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        let { rows, filterType } = this.state;
        let { classes, explore, modules, needArithmeticOperation, reportFilter } = this.props;
        let pageActions = ['ADD']
        return (
            <div className={`${classes.previewContainer} filterexplorePreview`} style={{ height: getChildCardHeight() - 67 }}>
                <ValidatorForm
                    name="filterattribute"
                    ref="form"
                    autoComplete="off"
                    onSubmit={() => { }}
                    onError={errors => { }}
                >
                    <div className={classes.previewHeaderContainer}>
                        <Grid container>
                            <Grid item xs={12} sm={8} style={{ alignSelf: 'center' }}>
                                <div className={classes.previewHeader}>Filter</div>
                            </Grid>
                            <Grid item xs={12} sm={4} className="filterAttriContainer">
                                <PageActions
                                    actions={pageActions}
                                    handleFilterActionClick={() => {
                                        this.props.onFilterAction(false, true)
                                    }}
                                    handleAddActionClick={() => {
                                        rows &&
                                            this.setState({
                                                rows: [
                                                    {
                                                        ...DATA,
                                                        _id: Math.max(...rows.map(elt => elt._id)) + 1
                                                    },
                                                    ...rows
                                                ]
                                            });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={2}>
                        <Grid item sm={12}>
                            <QueryBuilderComponent
                                componentMap={componentMap}
                                rowDatas={rows}
                                filterType={filterType}
                                config={{ explore: explore, reportFilter: reportFilter, modules: modules, conditions: needArithmeticOperation ? ExploreDataConditions : ExploreDataConditions.filter(elem => !arithmeticOperators.includes(elem.value)) }}
                                actions={['DELETE']}
                                handleFilterTypeChange={(type) => { this.setState({ filterType: type }) }}
                                cellComponentOnChange={this.cellComponentOnChange}
                                onDelete={(item) => {
                                    this.setState({
                                        rows: this.state.rows.filter((el, index) => { return index !== item.rowIndex })
                                    })
                                    this.props.updateData({ filterType: filterType, rows: this.state.rows.filter((el, index) => { return index !== item.rowIndex }) }, 'preview');
                                }}
                            />
                        </Grid>

                    </Grid>
                    <div className="textRight">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            component="span"
                            className={classes.button}
                            onClick={() => this.handleFilter("preview")}>
                            Filter
                            </Button>
                    </div>
                    {/* </Grid> */}
                </ValidatorForm>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
FilterAttribute.propTypes = {
    classes: PropTypes.object.isRequired,
    explore: PropTypes.object.isRequired,
    getOne: PropTypes.object,
    pageAccess: PropTypes.array,
    years: PropTypes.array
}
/*  default props  */
FilterAttribute.defaultProps = {
    explore: {},
    getOne: {},
    pageAccess: [],
    updateData: () => { },
    actions: [],
    years: [],
    modules: [],
    needArithmeticOperation: false,
    selectedFields: [],
    reportFilter: ''
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ state }, ownProps) => {
    return {

    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {

    }
};
//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(exploreStyle)(FilterAttribute));
