import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { Card, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Styles
import dashboardStyle from '../../../assets/jss/containers/dashboard';

// Components
import GroupBarChart from '../../../components/Charts/GroupBarChart/GroupBarChart';
import { SvgLoadIcon } from '../../../components/SvgIcons/SvgIcons';

/**
 *ReportingStatus
 *
 * @class ReportingStatus
 * @extends {Component}
 */
class ReportingStatus extends Component {
    /**
     *Creates an instance of ReportingStatus.
     * @param {*} props
     * @memberof ReportingStatus
     */
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof ReportingStatus
     */
    render() {
        let { classes, reportYear, reportStatus } = this.props
        return (
            <Grid item xs={12} sm={12}>
                <Card className={classes.reportingStatusContainer}>
                    <div className={classes.cardHeader}>
                        <div className="header">Reporting Status of the year {reportYear}</div>
                    </div>
                    <div className='reportingStatusCard'>
                        <Grid item xs={12} sm={12} className="cardContainer">
                            <div className="innerCard">
                                {
                                    reportStatus.isLoading ?
                                        <div className='reportingStatusLoader'>
                                            <SvgLoadIcon className="greyLoader" />
                                        </div>
                                        :
                                        <GroupBarChart data={reportStatus.data} />
                                }
                            </div>
                        </Grid>
                    </div>
                </Card>
            </Grid>
        )
    }
}
// prop types
ReportingStatus.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
ReportingStatus.defaultProps = {
    classes: {}
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};
// export ReportingStatus
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(ReportingStatus));