export const userConstants = {

    // Login
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    // Logout
    LOGOUT: 'USERS_LOGOUT',

    // Reset Password
    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    // Change password
    CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    // users listing
    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    // user details
    GETONE_REQUEST: 'USERS_GETONE_REQUEST',
    GETONE_SUCCESS: 'USERS_GETONE_SUCCESS',
    GETONE_FAILURE: 'USERS_GETONE_FAILURE',

    // create new user
    ADD_USER_REQUEST: 'USERS_NEW_REQUEST',
    ADD_USER_SUCCESS: 'USERS_NEW_SUCCESS',
    ADD_USER_FAILURE: 'USERS_NEW_FAILURE',

    // update user
    UPDATE_USER_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_USER_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_USER_FAILURE: 'USERS_UPDATE_FAILURE',

    // delete user
    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    // set or reset filter
    CLEAR_FILTER_SET: 'USERS_CLEAR_FILTER',
    CLEAR_FILTER_RESET: 'USERS_CLEAR_FILTER_RESET',

    // organization list in users
    GETALL_ORGANIZATION_REQUEST: 'USERS_ORGANIZATION_REQUEST',
    GETALL_ORGANIZATION_SUCCESS: 'USERS_ORGANIZATION_SUCCESS',
    GETALL_ORGANIZATION_FAILURE: 'USERS_ORGANIZATION_FAILURE',

    // Associated projects of a user
    GET_ASSTDPROJECTS_REQUEST: 'USERS_ASSTDPROJECTS_REQUEST',
    GET_ASSTDPROJECTS_SUCCESS: 'USERS_ASSTDPROJECTS_SUCCESS',
    GET_ASSTDPROJECTS_FAILURE: 'USERS_ASSTDPROJECTS_FAILURE',

    //Associated Loan Projects of a user
    GET_ASSTDLOANPROJECTS_REQUEST: 'USERS_ASSTDLOANPROJECTS_REQUEST',
    GET_ASSTDLOANPROJECTS_SUCCESS: 'USERS_ASSTDLOANPROJECTS_SUCCESS',
    GET_ASSTDLOANPROJECTS_FAILURE: 'USERS_ASSTDLOANPROJECTS_FAILURE',

    // user profile picture
    GET_USER_PICTURE_REQUEST: "USER_IMAGE_GET_REQUEST",
    GET_USER_PICTURE_SUCCESS: "USER_IMAGE_GET_SUCCESS",
    GET_USER_PICTURE_FAILURE: "USER_IMAGE_GET_FAILURE",

    // get user picture
    GET_MY_PROFILE_PICTURE_REQUEST: "USER_MY_PROFILE_PICTURE_REQUEST",
    GET_MY_PROFILE_PICTURE_SUCCESS: "USER_MY_PROFILE_PICTURE_SUCCESS",
    GET_MY_PROFILE_PICTURE_FAILURE: "USER_MY_PROFILE_PICTURE_FAILURE",

    // update user picture
    UPDATE_USER_PICTURE_REQUEST: "USER_IMAGE_UPDATE_REQUEST",
    UPDATE_USER_PICTURE_SUCCESS: "USER_IMAGE_UPDATE_SUCCESS",
    UPDATE_USER_PICTURE_FAILURE: "USER_IMAGE_UPDATE_FAILURE",

    // accept terms
    TOGGLE_ACCEPT_TERMS: 'USERS_TOGGLE_ACCEPT_TERMS',
    ACCEPT_TERMS_REQUEST: 'USERS_ACCEPT_TERMS_REQUEST',
    ACCEPT_TERMS_SUCCESS: 'USERS_ACCEPT_TERMS_SUCCESS',
    ACCEPT_TERMS_FAILURE: 'USERS_ACCEPT_TERMS_FAILURE',

    // associated projects available or not
    HAS_ASSOC_PJT_REQUEST: 'USERS_HAS_ASSOC_PJT_REQUEST',
    HAS_ASSOC_PJT_SUCCESS: 'USERS_HAS_ASSOC_PJT_SUCCESS',
    HAS_ASSOC_PJT_FAILURE: 'USERS_HAS_ASSOC_PJT_FAILURE',

    RETRIEVE_USER_REQUEST: 'RETRIEVE_USER_REQUEST',
    RETRIEVE_USER_SUCCESS: 'RETRIEVE_USER_SUCCESS',
    RETRIEVE_USER_FAILURE: 'RETRIEVE_USER_FAILURE'

};
