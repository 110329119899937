import React, { Component } from "react";
import PropTypes from 'prop-types';

// Material UI
import { TextValidator } from 'react-material-ui-form-validator';
// Table validations warning helper
import { hasWarnings } from '../../helpers/TableValidations/warnings';
import CustomToolTip from "../ToolTip/CustomToolTip";


/**
 *Text Area Component
 *
 * @class TextArea
 * @extends {Component}
 */
class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setOpen: false,
            focus: false
        }
    }

    /**
     * Handle change
     * @param {*} value 
     * @returns 
     */
    handleChange = value => event => {
        this.props.handleChange(this.props.name, event.target.value)
    };

    handleonFocus = (event) => {
        this.setState({ focus: true })
    };

    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }

    /**
     *render html
     *
     * @returns
     * @memberof TextArea
     */
    render() {
        let { name, value, label, type, className, margin, validators, errorMessages, disabled, multiline, inputProps, fielderrors, warningfieldname, onFocus, onBlur, needCustomToolTip } = this.props;
        let customClass = validators && (validators.indexOf('required') >= 0) ? className + ' required' : className;
        if (validators.indexOf('isEmail') >= 0 && validators.length === 1) {
            customClass = className;
        }
        const warningClass = hasWarnings(fielderrors, warningfieldname);
        value = value === null ? '' : value;
        if (needCustomToolTip) {
            return (
                <CustomToolTip
                    {...this.props}
                    focus={this.state.focus}
                >
                    <TextValidator
                        name={name}
                        value={value}
                        label={label}
                        type={type}
                        variant="standard"
                        className={`${customClass} ${warningClass}`}
                        onChange={this.handleChange()}
                        validators={validators}
                        margin={margin}
                        // onFocus={(e) => this.handleonFocus(e)}
                        onBlur={onBlur}
                        errorMessages={errorMessages}
                        disabled={disabled}
                        multiline={multiline}
                        inputProps={inputProps}
                        onFocus={onFocus}
                    />
                </CustomToolTip>
            );
        } else {
            return (
                <TextValidator
                    name={name}
                    value={value}
                    label={label}
                    type={type}
                    variant="standard"
                    className={`${customClass} ${warningClass}`}
                    onChange={this.handleChange()}
                    validators={validators}
                    margin={margin}
                    // onFocus={(e) => this.handleonFocus(e)}
                    onBlur={onBlur}
                    errorMessages={errorMessages}
                    disabled={disabled}
                    multiline={multiline}
                    inputProps={inputProps}
                    onFocus={onFocus}
                />
            )
        }
    }
}
//default props
TextArea.defaultProps = {
    classes: {},
    name: '',
    value: '',
    label: "",
    type: "text",
    className: '',
    errorMessages: [],
    validators: [],
    disabled: false,
    margin: "dense",
    handleOnChange: () => { },
    multiline: false,
    inputProps: {},
    fielderrors: [],
    warningfieldname: '',
    onBlur: () => { },
    onFocus: () => { },
    toolTipTitle: "",
    tooltipOnHover: true,
    needCustomToolTip: false

}
//prop types
TextArea.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    handleOnChange: PropTypes.func.isRequired,
    multiline: PropTypes.bool,
    inputProps: PropTypes.object,
    toolTipTitle: PropTypes.string,
    onFocus: PropTypes.func,
    tooltipOnHover: PropTypes.bool,

};
//export component
export default TextArea;