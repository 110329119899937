import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Link } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CloseIcon } from '../SvgIcons/SvgIcons';
import moment from 'moment';

// Import Styles
import textBoxStyle from '../../assets/jss/components/textBox';
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { TextValidator } from 'react-material-ui-form-validator';
import CustomToolTip from '../../components/ToolTip/CustomToolTip';

// import { ValidatorComponent, TextValidator } from 'react-material-ui-form-validator';

/**
 * Text Area Outline Box component
 */
class MultipleTextArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openCommentBox: false
        }
    }
    /**
     * Handle Events 
     */
    handleChange = value => event => {
        this.props.handleChange(this.props.name, event.target.value)
    };

    openAlertBox = () => {
        this.setState({ openCommentBox: true })
    }

    onClose = () => {
        this.setState({ openCommentBox: false })
    }

    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }

    handleonFocus = (event) => {
        this.setState({ focus: true })
    };
    handleonBlur = (event) => {
        this.setState({ focus: false })
    };

    /**
     * Bind
     * HTML to DOM
     */
    render() {
        const { margin, value, name, disabled, fullWidth, classes, dataArray, validators, errorMessages } = this.props;
        const { openCommentBox } = this.state
        const defaultTheme = createTheme({
            overrides: {
                MuiFormLabel: {
                    root: {
                        "&$focused": {
                            color: "#36aebe ",
                        }
                    }
                },
                MuiOutlinedInput: {
                    root: {
                        // bottom: "4px !important",
                        // paddingLeft: showAdornment ? "45px !important" : "0px",
                        "&$focused $notchedOutline": {
                            borderColor: "#36aebe !important",
                        }
                    }
                },
                MuiFormHelperText: {
                    contained: {
                        marginLeft: 0,
                        marginRight: 0
                    }
                },
                MuiTooltip: {
                    tooltip: {
                        backgroundColor: "grey",
                        color: "white",
                        fontSize: 13
                    },
                    arrow: {
                        left: "5% !important",
                        marginLeft: "-5px"
                    }
                },

            }
        });
        return (
            <React.Fragment>
                {dataArray.length > 1 &&
                    <Typography className={classes.linkText}>
                        <Link onClick={this.openAlertBox} variant="body2">
                            {'View all Comments'}
                        </Link>
                    </Typography>
                }
                <MuiThemeProvider theme={defaultTheme}>
                    <CustomToolTip
                        {...this.props}
                        focus={this.state.focus}
                    >
                        <TextValidator
                            multiline
                            name={name}
                            value={value}
                            margin={margin}
                            fullWidth={fullWidth}
                            disabled={disabled}
                            rowsMax={4}
                            rows={2}
                            validators={validators}
                            errorMessages={errorMessages}
                            onFocus={(e) => this.handleonFocus(e)}
                            onChange={this.handleChange()}
                            className="outlineMulti"
                            onBlur={(e) => this.handleonBlur(e)}
                            variant={'outlined'}

                        />
                    </CustomToolTip>
                </MuiThemeProvider>
                {dataArray.length > 0 &&
                    <Grid className={classes.root}>
                        <Typography variant="body1">
                            {dataArray[0].value}
                        </Typography>
                        <Typography variant="body2" className={classes.subText}>
                            {`${dataArray[0].user}, ${moment(dataArray[0].createAt).format('LLL')}`}
                        </Typography>
                    </Grid>
                }
                <Dialog
                    open={openCommentBox}
                    onClose={this.onClose}
                    fullWidth={true}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="multiTextArea" id="alert-dialog-title">Comments
                        {/* <Typography className="commentsFinancial" >Comments</Typography> */}
                        <CloseIcon onClick={this.onClose} />
                    </DialogTitle>
                    <DialogContent >
                        {dataArray.map((obj) => (
                            <Grid className={`${classes.root} marginMultipletextArea`}>
                                <Typography variant="body1">
                                    {obj.value}
                                </Typography>
                                <Typography variant="body2" className={classes.subText}>
                                    {`${obj.user}, ${moment(obj.createAt).format('LLL')}`}
                                </Typography>
                            </Grid>
                        ))}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

//default props
MultipleTextArea.defaultProps = {
    name: '',
    value: '',
    disabled: false,
    fullWidth: false,
    classes: {},
    margin: 'dense',
    dataArray: [],
    handleChange: () => { },
    onInputChangeRequest: () => { },
    toolTipTitle: "",
    tooltipOnHover: true,
    onFocus: () => { },
    required: false
}

//prop types
MultipleTextArea.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    dataArray: PropTypes.array,
    margin: PropTypes.string,
    onInputChangeRequest: PropTypes.func.isRequired,
    toolTipTitle: PropTypes.string,
    onFocus: PropTypes.func,
    tooltipOnHover: PropTypes.bool,
    required: PropTypes.bool
};

//export component
export default withStyles(textBoxStyle)(MultipleTextArea);
