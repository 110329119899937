import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from "react-redux";

// Material UI
import AppBar from '@material-ui/core/AppBar';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import UsersEdit from './UsersEdit';
import Projects from './Projects';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions and Helper Files
import { userActions } from "../../actions/user";

// Import Helpers
import { history, getUserRole, getUserId, getUserOrgId, getUserAccessForPage, constants, getOrgType } from '../../helpers';

// Custom Variables
let sFields = [];
let sValues = [];
/**
 * UsersView Component
 * @class UsersView
 * @extends {Component}
 */
class UsersView extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
        this.pageAccess = getUserAccessForPage('usersView');
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match } = this.props;
        switch (value) {
            case 0 :
                history.push({
                    pathname: match.url,
                    state: {
                        page: 'userEdit',
                        navFrom:'',
                        pageData: {
                            user_id: match.params.userId
                        }
                    }
                });
                break;
            case 1:
                history.push({
                    pathname: `${match.url}/projects`,
                    state: {
                        page: 'projectsList',
                        pageData: {},
                    }
                });
                break;
            default: break;
        }
    };
    /**
     * Handle Tab Index Change Event
     * @param {*} index 
     */
    handleTabIndexChange = index => {
        this.setState({ tab: index });
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     * @param {*} imageData 
     */
    handleFormSaveActionClick = (formData, imageData) => {
        if (formData) {
            this.props.updateUser(formData, this.props.match.params.userId, imageData);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Table Navigation Event - Projects View Page
     * @param {*} id 
     */
    handleNavigateAction = (id) => {
        history.push('/projects/' + id);
    }
    /**
     * Bind Html to DOM
     * @returns
     * 
     * [1]  -   disable assoc pjt tab button for non funder users who need to access the other org users assoc projects
     */
    render() {
        const { users, match, location: { pathname }, pageAccess, classes, isSubPage, orgId } = this.props;
        const { userId, userRole } = this;
        const orgAccess = users.getOne.data ? users.getOne.data.orgAccess : null;
        const currentTab = pathname.includes('/projects') ? 1 : 0
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        let isLoggedInUser, isReadOly, isFunderNonOA = false;
        let disableAssocPJTBtn = false;
        if (users.getOne.data) {
            // [1]
            if (users.getOne.data.org_id) {
                disableAssocPJTBtn = getOrgType() !== constants.funder && getUserOrgId() !== users.getOne.data.org_id ? true : false;
            }
        }
        if (users && users.getOne && users.getOne.data && users.getOne.data.org
            && users.getOne.data.org.id && users.getOne.data.org.id.organizationType) {
            isLoggedInUser = users.getOne.data._id === userId;
            switch (userRole) {
                case constants.funderWithoutOA:
                    if (pageAccess.indexOf('NONE') >= 0) {
                        isFunderNonOA = true;
                    } else
                        isFunderNonOA = isLoggedInUser;
                    break;
                case constants.funderWithoutOAandROU:
                case constants.readOnlyFunderWithoutOA:
                case constants.nonFunderWithOA:
                case constants.nonFunderWithoutOA:
                case constants.onsite:
                    isFunderNonOA = true;
                    break;
                default:
                    break;
            }
        }
        // Home
        // isReadOly = getUserOrgId() !== 2;
        
        // Multi family
        isReadOly = pageAccess.indexOf('SAVE') === -1;
        return (
            <div className = 'usersView'>
            <ContentWrapper
                classes={classes}
                pageAccess={match.url === pathname ? pageAccess : ['BACK']}
                title={users && users.getOne.data ? users.getOne.data.firstName : ''}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleBackActionClick={this.handleBackActionClick}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="General" value={0} />
                        <Tab label="Associated Projects" value={1} disabled={disableAssocPJTBtn} />
                    </Tabs>
                </AppBar>
                <div className ='userEdit'> 
                <TabContainer>
                    <Route
                        exact
                        path={match.url}
                        render={(props) => {
                            return (
                                <UsersEdit
                                    isSubPage={isSubPage}
                                    pageAccess={pageAccess}
                                    orgId={orgId}
                                    {...this.props.location.state}
                                    isFunderNonOA={isFunderNonOA}
                                    userId={this.props.match.params.userId}
                                    isReadOly={isReadOly}
                                    handleFormSaveActionClick={(formData, imageData = null) => this.handleFormSaveActionClick(formData, imageData)}
                                    handleBackActionClick={this.handleBackActionClick} />
                            )
                        }}
                    />
                    <Route
                        // exact
                        path={`${match.url}/projects`}
                        render={(props) => {
                            return (
                                <Projects
                                    isSubPage={isSubPage}
                                    title={''}
                                    userId={match.params.userId}
                                    orgAccess={orgAccess}
                                    match={props.match}
                                    isReadOly={isReadOly}
                                    handleFormSaveActionClick={(formData, imageData = null) => this.handleFormSaveActionClick(formData, imageData)}
                                    handleBackActionClick={this.handleBackActionClick}
                                    tableActionRequired={false} />
                            )
                        }}
                    />

                </TabContainer>
                </div>
            </ContentWrapper>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
UsersView.propTypes = {
    isSubPage: PropTypes.bool,
    pageAccess: PropTypes.array
};
/**
 * Default Props Values
 */
UsersView.defaultProps = {
    isSubPage: false,
    pageAccess: []
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        users: state.users,
        pageAccess: state.pageAccess['userEdit'] || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        updateUser: (formData, userId, imageData) => dispatch(userActions.updateUser(formData, userId, imageData))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(UsersView));
