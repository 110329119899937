export const orgConstants = {

    // organization listing page
    GETALL_REQUEST: 'ORGANIZATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORGANIZATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORGANIZATIONS_GETALL_FAILURE',

    // organization details page
    GETONE_REQUEST: 'ORGANIZATIONS_GETONE_REQUEST',
    GETONE_SUCCESS: 'ORGANIZATIONS_GETONE_SUCCESS',
    GETONE_FAILURE: 'ORGANIZATIONS_GETONE_FAILURE',

    // organization contact
    GET_ORG_CONTACT_REQUEST: 'ORGANIZATIONS_GET_ORG_CONTACT_REQUEST',
    GET_ORG_CONTACT_SUCCESS: 'ORGANIZATIONS_GET_ORG_CONTACT_SUCCESS',
    GET_ORG_CONTACT_FAILURE: 'ORGANIZATIONS_GET_ORG_CONTACT_FAILURE',

    // create organization
    ADD_ORGANIZATIONS_REQUEST: 'ORGANIZATIONS_NEW_REQUEST',
    ADD_ORGANIZATIONS_SUCCESS: 'ORGANIZATIONS_NEW_SUCCESS',
    ADD_ORGANIZATIONS_FAILURE: 'ORGANIZATIONS_NEW_FAILURE',

    // updateorganization
    UPDATE_ORGANIZATIONS_REQUEST: 'ORGANIZATIONS_UPDATE_REQUEST',
    UPDATE_ORGANIZATIONS_SUCCESS: 'ORGANIZATIONS_UPDATE_SUCCESS',
    UPDATE_ORGANIZATIONS_FAILURE: 'ORGANIZATIONS_UPDATE_FAILURE',

    // delete organization
    DELETE_REQUEST: 'ORGANIZATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'ORGANIZATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ORGANIZATIONS_DELETE_FAILURE',

    // active or deactive organization
    ACTIVE_UPDATE_REQUEST: 'ORGANIZATIONS_ACTIVE_UPDATE_REQUEST',
    ACTIVE_UPDATE_SUCCESS: 'ORGANIZATIONS_ACTIVE_UPDATE_SUCCESS',
    ACTIVE_UPDATE_FAILURE: 'ORGANIZATIONS_ACTIVE_UPDATE_FAILURE',

    // set or reset filter
    CLEAR_FILTER_SET: 'ORGANIZATIONS_CLEAR_FILTER',
    CLEAR_FILTER_RESET: 'ORGANIZATIONS_CLEAR_FILTER_RESET'
};
