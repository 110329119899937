export const projectsConstants = {

    // create new project
    CREATE_PROJECT_REQUEST: 'page/projects/create/request',
    CREATE_PROJECT_SUCCESS: 'page/projects/create/success',
    CREATE_PROJECT_FAILURE: 'page/projects/create/failure',

    // update existing project
    UPDATE_PROJECT_REQUEST: 'page/projects/update/request',
    UPDATE_PROJECT_SUCCESS: 'page/projects/update/success',
    UPDATE_PROJECT_FAILURE: 'page/projects/update/failure',

    // delete project
    DELETE_PROJECT_REQUEST: 'page/projects/delete/request',
    DELETE_PROJECT_SUCCESS: 'page/projects/delete/success',
    DELETE_PROJECT_FAILURE: 'page/projects/delete/failure',

    // project listing
    PAGE_GETALL_PROJECTS_REQUEST: 'page/projects/request',
    PAGE_GETALL_PROJECTS_SUCCESS: 'page/projects/success',
    PAGE_GETALL_PROJECTS_FAILURE: 'page/projects/failure',

    // project edit page
    GET_ONE_PJT_REQUEST: 'PROJECTS_GET_ONE_PJT_REQUEST',
    GET_ONE_PJT_SUCCESS: 'PROJECTS_GET_ONE_PJT_SUCCESS',
    GET_ONE_PJT_FAILURE: 'PROJECTS_GET_ONE_PJT_FAILURE',

    // set or reset filter
    PROJECTS_CLEAR_FILTER: 'PROJECTS_CLEAR_FILTER',
    PROJECTS_CLEAR_FILTER_RESET: 'PROJECTS_CLEAR_FILTER_RESET',

    // get organization details
    GET_ALL_ORGS_REQUEST: 'PROJECTS_GET_ALL_ORGS_REQUEST',
    GET_ALL_ORGS_SUCCESS: 'PROJECTS_GET_ALL_ORGS_SUCCESS',
    GET_ALL_ORGS_FAILURE: 'PROJECTS_GET_ALL_ORGS_FAILURE',

    // get funder history details
    GET_FUNDER_HISTORY_REQUEST: 'GET_FUNDER_HISTORY_REQUEST',
    GET_FUNDER_HISTORY_SUCCESS: 'GET_FUNDER_HISTORY_SUCCESS',
    GET_FUNDER_HISTORY_FAILURE: 'GET_FUNDER_HISTORY_FAILURE',

    // read only organization
    GET_READ_ONLY_ORGS_REQUEST: 'PROJECTS_GET_READ_ONLY_ORGS_REQUEST',
    GET_READ_ONLY_ORGS_SUCCESS: 'PROJECTS_GET_READ_ONLY_ORGS_SUCCESS',
    GET_READ_ONLY_ORGS_FAILURE: 'PROJECTS_GET_READ_ONLY_ORGS_FAILURE',

    // Funders data in project
    GET_ALL_FUNDERS_REQUEST: 'PROJECTS_GET_ALL_FUNDERS_REQUEST',
    GET_ALL_FUNDERS_SUCCESS: 'PROJECTS_GET_ALL_FUNDERS_SUCCESS',
    GET_ALL_FUNDERS_FAILURE: 'PROJECTS_GET_ALL_FUNDERS_FAILURE',

    // organization users in project
    GET_ORG_USERS_REQUEST: 'PROJECTS_GET_ORG_USERS_REQUEST',
    GET_ORG_USERS_SUCCESS: 'PROJECTS_GET_ORG_USERS_SUCCESS',
    GET_ORG_USERS_FAILURE: 'PROJECTS_GET_ORG_USERS_FAILURE',

    // is existing project
    IS_PROJECT_NAME_EXISTS_REQUEST: 'IS_PROJECT_NAME_EXISTS_REQUEST',
    IS_PROJECT_NAME_EXISTS_SUCCESS: 'IS_PROJECT_NAME_EXISTS_SUCCESS',
    IS_PROJECT_NAME_EXISTS_FAILURE: 'IS_PROJECT_NAME_EXISTS_FAILURE',

    // sites list in projects tab
    GET_PROJECT_SITES_REQUEST: 'page/project/list/sites_REQUEST',
    GET_PROJECT_SITES_SUCCESS: 'page/project/list/sites_SUCCESS',
    GET_PROJECT_SITES_FAILURE: 'page/project/list/sites_FAILURE',

    // delete funder
    DELETE_FUNDER_REQUEST: 'DELETE_FUNDER_REQUEST',
    DELETE_FUNDER_SUCCESS: 'DELETE_FUNDER_SUCCESS',
    DELETE_FUNDER_FAILURE: 'DELETE_FUNDER_FAILURE',

    // reports tab in a project
    GET_PROJECT_REPORT_REQUEST: 'page/project/list/report_request',
    GET_PROJECT_REPORT_SUCCESS: 'page/project/list/report_success',
    GET_PROJECT_REPORT_FAILURE: 'page/project/list/report_failure',

    // funder settings data
    GET_FUNDER_BASIC_SETTINGS_REQUEST: 'GET_PAGE_FUNDER_BASIC_SETTINGS_REQUEST',
    GET_FUNDER_BASIC_SETTINGS_SUCCESS: 'GET_PAGE_FUNDER_BASIC_SETTINGS_SUCCESS',
    GET_FUNDER_BASIC_SETTINGS_FAILURE: 'GET_PAGE_FUNDER_BASIC_SETTINGS_SUCCESS',

    // funder report table
    GET_FUNDER_REPORT_TABLE_REQUEST: 'GET_PAGE_FUNDER_REPORT_TABLE_REQUEST',
    GET_FUNDER_REPORT_TABLE_SUCCESS: 'GET_PAGE_FUNDER_REPORT_TABLE_SUCCESS',
    GET_FUNDER_REPORT_TABLE_FAILURE: 'GET_PAGE_FUNDER_REPORT_TABLE_SUCCESS',

    // funder settings configuration
    GET_FUNDER_CONFIG_SETTINGS_REQUEST: 'GET_PAGE_FUNDER_CONFIG_SETTINGS_REQUEST',
    GET_FUNDER_CONFIG_SETTINGS_SUCCESS: 'GET_PAGE_FUNDER_CONFIG_SETTINGS_SUCCESS',
    GET_FUNDER_CONFIG_SETTINGS_FAILURE: 'GET_PAGE_FUNDER_CONFIG_SETTINGS_FAILURE',

    // project report 
    GET_PROJECT_ONE_REPORT_REQUEST: 'GET_PROJECT_ONE_REPORT_REQUEST',
    GET_PROJECT_ONE_REPORT_SUCCESS: 'GET_PROJECT_ONE_REPORT_SUCCESS',
    GET_PROJECT_ONE_REPORT_FAILURE: 'GET_PROJECT_ONE_REPORT_FAILURE',

    // update funder settings
    UPDATE_FUNDER_SETTINGS_REQUEST: 'UPDATE_PAGE_FUNDER_SETTINGS_REQUEST',
    UPDATE_FUNDER_SETTINGS_SUCCESS: 'UPDATE_PAGE_FUNDER_SETTINGS_SUCCESS',
    UPDATE_FUNDER_SETTINGS_FAILURE: 'UPDATE_PAGE_FUNDER_SETTINGS_FAILURE',

    // create new funder settings
    CREATE_FUNDER_SETTINGS_REQUEST: 'CREATE_PAGE_FUNDER_SETTINGS_REQUEST',
    CREATE_FUNDER_SETTINGS_SUCCESS: 'CREATE_PAGE_FUNDER_SETTINGS_SUCCESS',
    CREATE_FUNDER_SETTINGS_FAILURE: 'CREATE_PAGE_FUNDER_SETTINGS_FAILURE',

    // table 2 data
    GET_TABLE2_REQUEST: 'GET_TABLE2_REQUEST',
    GET_TABLE2_SUCCESS: 'GET_TABLE2_SUCCESS',
    GET_TABLE2_FAILURE: 'GET_TABLE2_FAILURE',

    // table 3 data
    GET_TABLE3_REQUEST: 'GET_TABLE3_REQUEST',
    GET_TABLE3_SUCCESS: 'GET_TABLE3_SUCCESS',
    GET_TABLE3_FAILURE: 'GET_TABLE3_FAILURE',

    // report cover page
    UPDATE_REPORTCOVERPAGE_REQUEST: 'PROJECT_UPDATE_REPORTCOVERPAGE_REQUEST',
    UPDATE_REPORTCOVERPAGE_SUCCESS: 'PROJECT_UPDATE_REPORTCOVERPAGE_SUCCESS',
    UPDATE_REPORTCOVERPAGE_FAILURE: 'PROJECT_UPDATE_REPORTCOVERPAGE_FAILURE',

    // Get Funder Org
    GET_FUNDERORG_REQUEST: 'GET_FUNDERORG_REQUEST',
    GET_FUNDERORG_SUCCESS: 'GET_FUNDERORG_SUCCESS',
    GET_FUNDERORG_FAILURE: 'GET_FUNDERORG_FAILURE',

    // add Funder Org
    ADD_FUNDERORG_REQUEST: 'ADD_FUNDERORG_REQUEST',
    ADD_FUNDERORG_SUCCESS: 'ADD_FUNDERORG_SUCCESS',
    ADD_FUNDERORG_FAILURE: 'ADD_FUNDERORG_FAILURE',

    // Get projects name list
    GET_PROJECTNAME_REQUEST: 'GET_PROJECTNAME_REQUEST',
    GET_PROJECTNAME_SUCCESS: 'GET_PROJECTNAME_SUCCESS',
    GET_PROJECTNAME_FAILURE: 'GET_PROJECTNAME_FAILURE',

     // Get projects table1 list
     GET_PROJECT_TABLE1_REQUEST: 'GET_PROJECT_TABLE1_REQUEST',
     GET_PROJECT_TABLE1_SUCCESS: 'GET_PROJECT_TABLE1_SUCCESS',
     GET_PROJECT_TABLE1_FAILURE: 'GET_PROJECT_TABLE1_FAILURE',
}