import React, { Component } from 'react';

//Material UI
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

//Component Imports
import AlertDialog from '../AlertDialog/AlertDialog';

//Expansion Panel Styles
const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

//Expansion Panel Summary Styles
const ExpansionPanelSummary = withStyles({
  root: {
    padding: '0px',
    backgroundColor: ' #eee',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 40,
    '&$expanded': {
      minHeight: 40,
      margin: 0
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
      // margin: '12px 0',
    },
    "&:last-child": {
      paddingRight: '0'
    }
  },
  expanded: {},
})(MuiExpansionPanelSummary);

//Expnsion Panel Details Styles
const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: "20px",
    overflow: 'auto'
  },
}))(MuiExpansionPanelDetails);

//Edit Icon Styles
// const EditButton = withStyles({
//   root: {
//     height: '18px',
//     width: '18px'
//   }
// })(EditIcon)

/**
 * Export Expansion Panel Component
 * @class ExpansionPanelComponent
 * @extends {Component}
 */
export class ExpansionPanelComponent extends Component {
  /**
   * Constructor
   * @param {*} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      resetData: false,
    }
  }
  /**
   * Function that handles edit icon actions
   * @param {*} e 
   */
  onEdit(e) {
    if (this.props.expanded || (!this.props.expanded && this.props.disableEdit)) {
      e.stopPropagation();
    }
    if (this.props.disableEdit) {
      this.props.handleData({ disabled: !this.props.disableEdit, panel: this.props.expandedPanel, edit: true })
    } else {
      this.setState({ resetData: true })
    }
  }
  /**
   * Bind HTML to reactDOM
   * @returns 
   */
  render() {
    let { expandedPanel, controls, id, title, editAccess, titleNo, expanded, disableEdit, errorSection, warningSection, needTitleNo } = this.props;
    const handleChange = panel => (event, newExpanded) => {
      newExpanded ? this.props.handleData({ panel, newExpanded }) : this.props.handleData({ panel, newExpanded })
    };
    let className = !disableEdit ? 'expansion_edit_bg edit_title' : '';
    let classNameBr = !disableEdit ? 'expansion_edit_br' : '';
    let classNameErr = errorSection ? 'expansion_error' : warningSection ? 'expansion_warning' : '';
    return (
      <div style={{ width: '100%' }}>
        <ExpansionPanel square expanded={expanded} onChange={handleChange(expandedPanel)}>
          <ExpansionPanelSummary aria-controls={controls} id={id} className={className}>
            <h4 className={`section_title ${className}`}>
              {needTitleNo && <span className={`title_issues ${className} ${classNameBr} ${classNameErr}`}>{titleNo}</span>}{title}</h4>
            {
              editAccess ? <span className={`expansion-edit-icon ${className}`} onClick={(e) => this.onEdit(e)}>
                {!disableEdit ? <span className="expansion-edit-text">Reset</span> : <span className="expansion-edit-text">Edit</span>}
              </span>
                : null
            }
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>{this.props.children}</Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {
          this.state.resetData && (
            //Alert Dialog box to reset datas confirmation
            <AlertDialog
              open={true}
              title={'Warning:'}
              onClose={() => {
                this.setState({ disabled: true, resetData: false })
                this.props.handleData({ disabled: false });
              }}
              onSave={() => {
                this.setState({ disabled: true, resetData: false });
                this.props.handleData({ disabled: true, resetData: true, resetKey: expandedPanel });
                this.setState({ disabled: false });
              }}
              saveText={'Yes'}
              cancelText={'No'}
            >
              Are you sure you want remove the changes in {title} section?
            </AlertDialog>
          )
        }
      </div>
    )
  }
}
//Expansion panel default Props
ExpansionPanelComponent.defaultProps = {
  id: '',
  controls: '',
  title: '',
  expandedPanel: false,
  editAccess: false,
  expandOnError: false,
  disableEdit: true,
  needTitleNo: true,
  handleData: () => { }
}