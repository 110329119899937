export const uploadConstants = {

  // bulk import
  BULK_IMPORT_REQUEST: 'BULK_IMPORT_REQUEST',
  BULK_IMPORT_SUCCESS: 'BULK_IMPORT_SUCCESS',
  BULK_IMPORT_FAILURE: 'BULK_IMPORT_FAILURE',

  // income and rent limit import
  IMPORT_INCOMERENTLMT_REQUEST: 'IMPORT_INCOMERENTLMT_REQUEST',
  IMPORT_INCOMERENTLMT_SUCCESS: 'IMPORT_INCOMERENTLMT_SUCCESS',
  IMPORT_INCOMERENTLMT_FAILURE: 'IMPORT_INCOMERENTLMT_FAILURE',

  // Table 1 import
  TABLE1_IMPORT_REQUEST: 'TABLE1_IMPORT_REQUEST',
  TABLE1_IMPORT_SUCCESS: 'TABLE1_IMPORT_SUCCESS',
  TABLE1_IMPORT_FAILURE: 'TABLE1_IMPORT_FAILURE',

  RESET_TO_INITIAL_STATE: 'RESET_TO_INITIAL_STATE',
  // income and rent limit import status
  IMPORT_INCOMERENTLMT_STATUS_REQUEST: 'IMPORT_INCOMERENTLMT_STATUS_REQUEST',
  IMPORT_INCOMERENTLMT_STATUS_SUCCESS: 'IMPORT_INCOMERENTLMT_STATUS_SUCCESS',
  IMPORT_INCOMERENTLMT_STATUS_FAILURE: 'IMPORT_INCOMERENTLMT_STATUS_FAILURE',

  // income and rent limit import action
  ACTION_IMPORT_INCOMERENTLMT_REQUEST: 'ACTION_IMPORT_INCOMERENTLMT_REQUEST',
  ACTION_IMPORT_INCOMERENTLMT_SUCCESS: 'ACTION_IMPORT_INCOMERENTLMT_SUCCESS',
  ACTION_IMPORT_INCOMERENTLMT_FAILURE: 'ACTION_IMPORT_INCOMERENTLMT_FAILURE',

  //REPORT_ACTIVITY_IMPORT
  REPORT_ACTIVITY_IMPORT_REQUEST:"REPORT_ACTIVITY_IMPORT_REQUEST",
  REPORT_ACTIVITY_IMPORT_SUCCESS:"REPORT_ACTIVITY_IMPORT_SUCCESS",
  REPORT_ACTIVITY_IMPORT_FAILURE:"REPORT_ACTIVITY_IMPORT_FAILURE",

  //ERROR_LOG_REQUEST
  ERROR_LOG_REQUEST: 'ERROR_LOG_REQUEST',
  ERROR_LOG_SUCCESS: 'ERROR_LOG_SUCCESS',
  ERROR_LOG_FAILURE: 'ERROR_LOG_FAILURE',

    //UPDATE_TEMPLATE_REQUEST
    UPDATE_TEMPLATE_REQUEST: 'UPDATE_TEMPLATE_REQUEST',
    UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
    UPDATE_TEMPLATE_FAILURE: 'UPDATE_TEMPLATE_FAILURE',
}