import React, { Component } from 'react';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

//import Components
import DeleteDialog from "../DeleteDialog/DeleteDialog";
import PageActions from '../PageActions/PageActions';
import PageTitle from '../PageTitle/PageTitle';
import ToolTip from '../../components/ToolTip/ToolTip';
import { DeleteIcon } from '../SvgIcons/SvgIcons';

// Import Style
import tableStyle from '../../assets/jss/components/table';
import { relationshipHOH, ethinicity, discloseType} from '../../helpers';
// Map Icon
const actionsMap = {
    DELETE: {
        title: 'Delete',
        className: 'delete_icon',
        iClass: <DeleteIcon />
    }
};
/**
 * Tables can be edited
 *
 * @class EditableTable
 * @extends {Component}
 */
class EditableTable extends Component {
    /**
     *Creates an instance of EditableTable.
     * @param {*} props
     * @memberof EditableTable
     */
    constructor(props) {
        super(props);
        //state declaration
        this.state = {
            open: false,
            deleteItem: null
        };
    }
    /**
     * Get Field Config
     * @param {*} fieldName 
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     * 
     * [1]  -   if any compoent
     */
    getFieldConfig(fieldName, item, rowIndex) {
        const { componentMap, cellComponentOnChange, config, classes, isReadOnly, isDisabled } = this.props;
        const CellComponent = componentMap[fieldName];
        // [1]
        if (componentMap[fieldName]) {
            return <CellComponent fieldName={fieldName} data={item} onChange={cellComponentOnChange} rowIndex={rowIndex} config={config} classes={classes} isReadOnly={isReadOnly} isDisabled={isDisabled} />
        } else {
            return item[fieldName];
        }
    }
    /**
     * Binding ToolTip Value
     * @param {*} item 
     * @param {*} pathArr 
     * @returns 
     */
    bindToolTipValue = (item, pathArr) => {
        let arr = pathArr.split('.');
        return arr.reduce((obj, key) => (obj && obj[key] !== 'undefined')
            ?
            (key === 'isActive' ? (obj[key] === false ? 'InActive' : 'Active') : obj[key] === false ? 'Read/Write' : (obj[key] === true ? 'Read' : obj[key]))
            : undefined,
            item
        );
    }
    /**
     * Render Table Cell
     * @param {*} fieldName 
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     */
    renderTableCell(fieldName, item, rowIndex) {
        let cellContent = '';
        if (item[fieldName] !== undefined) {
            cellContent = this.getFieldConfig(fieldName, item, rowIndex);
        }
        return cellContent;
    }
    /**
     * Handle Delete Click Open
     * @param {*} item 
     * @param {*} rowIndex 
     */
    handleDeleteClickOpen = (item, rowIndex) => {
        this.setState({
            open: true,
            deleteItem: { ...item, rowIndex }
        });
    }
    /**
     * Render Actions Button
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     */
    renderActionsButton(item, rowIndex) {
        const { actions, deleteTooltip } = this.props;
        return actions.map((action, index) => {
            const { title, className, iClass } = actionsMap[action];
            let buttonTitle = deleteTooltip ? deleteTooltip : title
            return (
                <ToolTip title={buttonTitle} placement="bottom" key={index}>
                    <IconButton
                        className={className}
                        color="primary"
                        aria-label={buttonTitle}
                        onClick={() => this.handleDeleteClickOpen(item, rowIndex)}>
                        {iClass}
                    </IconButton>
                </ToolTip>
            )
        })
    }
    /**
     * Rende rTable Row
     * @param {*} item 
     * @param {*} rowIndex 
     * @returns 
     * 
     * [1]  -   add actions
     */
    renderTableRow(item, rowIndex) {
        const { fieldCols, actions, section } = this.props;
        if(item.balance===undefined){
            item.balance="0.00"
        }

        //copy item for tooltip - modified for compliance setting page
        let tooltipitem = {...item};
        //handle tooltip logic if fulltimestudent type is null
        if(item.fullTimeStudentType_id!==null && item.fullTimeStudentType_id!==0){
        if (tooltipitem.familyRelationshipType_id || tooltipitem.householdMemberEthnicityType_id || tooltipitem.fullTimeStudentType_id 
            || tooltipitem.disabilityType_id ) {
                let {familyRelation,ethinicityType,FTstudent,disability}=[];
                familyRelation = tooltipitem.familyRelationshipType_id != null && relationshipHOH.filter(x => x.value == tooltipitem.familyRelationshipType_id || x.label == tooltipitem.familyRelationshipType_id );
                ethinicityType = tooltipitem.householdMemberEthnicityType_id != null && ethinicity.filter(x => x.value == tooltipitem.householdMemberEthnicityType_id );
                FTstudent = tooltipitem.fullTimeStudentType_id != null && discloseType.filter(x => x.value == tooltipitem.fullTimeStudentType_id);
                disability = tooltipitem.disabilityType_id != null && discloseType.filter(x => x.value == tooltipitem.disabilityType_id);

                tooltipitem.familyRelationshipType_id = familyRelation.length >0 && familyRelation[0].label;
                tooltipitem.householdMemberEthnicityType_id = ethinicityType.length >0 && ethinicityType[0].label;
                tooltipitem.fullTimeStudentType_id = FTstudent.length>0 && FTstudent[0].label;
                tooltipitem.disabilityType_id = disability.length >0 && disability[0].label;
                
                // tooltipitem.familyRelationshipType_id = tooltipitem.familyRelationshipType_id != null && relationshipHOH.filter(x => x.value == tooltipitem.familyRelationshipType_id )[0].label;
                // tooltipitem.householdMemberEthnicityType_id = tooltipitem.householdMemberEthnicityType_id != null && ethinicity.filter(x => x.value == tooltipitem.householdMemberEthnicityType_id )[0].label;
                // tooltipitem.fullTimeStudentType_id = tooltipitem.fullTimeStudentType_id != null && discloseType.filter(x => x.value == tooltipitem.fullTimeStudentType_id)[0].label;
                // tooltipitem.disabilityType_id = tooltipitem.disabilityType_id != null && discloseType.filter(x => x.value == tooltipitem.disabilityType_id)[0].label;
            }
        }
        
        if(tooltipitem.fieldA&&tooltipitem.fieldA.title&&tooltipitem.fieldA!==undefined){
            tooltipitem.fieldA = tooltipitem.fieldA.title
        }

        if(tooltipitem.fieldB&&tooltipitem.fieldB.title&&tooltipitem.fieldB!==undefined){
            tooltipitem.fieldB = tooltipitem.fieldB.title
        }

        const tableCells = fieldCols.map((fieldName, colIndex) => {
        //-----check value is null then display this as $0.00
        for(const [key,val] of Object.entries(item)){
            if(val===null){
                item[key]=0
            }
        }
            return <TableCell title={this.bindToolTipValue(tooltipitem, fieldName)} key={`col_${colIndex}`}>{this.renderTableCell(fieldName, item, rowIndex)}</TableCell>;
        });
        // [1]
        if (actions.length > 0) {
            if (section === 'reserve') {
                if (rowIndex >= 2) {
                    tableCells.push(<TableCell key={`col_${tableCells.length}`}>{this.renderActionsButton(item, rowIndex)}</TableCell>);
                }
            } else if (section === "memberRaces") {
                if (item.key !== 9) {
                    tableCells.push(<TableCell key={`col_${tableCells.length}`}>{this.renderActionsButton(item, rowIndex)}</TableCell>);
                } else {
                    tableCells.push(<TableCell key={`col_${tableCells.length}`}></TableCell>);
                }
            }
            else tableCells.push(<TableCell key={`col_${tableCells.length}`}>{this.renderActionsButton(item, rowIndex)}</TableCell>);
        }
        return <TableRow key={`row_${rowIndex}`}>{tableCells}</TableRow>;
    }
    /**
     * Render HF Table Row
     * @param {*} item 
     * @param {*} rowIndex 
     * @param {*} placement 
     * @returns 
     * 
     * [1]  -   add dummy actions column
     */
    renderHFTableRow(item, rowIndex, placement) {
        const { fieldCols, actions } = this.props;
        const tableCells = fieldCols.map((fieldName, colIndex) => {
            return <TableCell key={`col_${placement}_${colIndex}`}>{item[`${fieldName}_${placement}`]}</TableCell>;
        });
        // [1]
        if (actions.length > 0) {
            tableCells.push(<TableCell key={`col_${tableCells.length}`}>{item[`actions_${placement}`]}</TableCell>);
        }
        return <TableRow className={`${placement}-row`} key={`row_${placement}_${rowIndex}`}>{tableCells}</TableRow>;
    }
    /**
     *render html
     *
     * @returns
     * @memberof EditableTable
     */
    render() {
        const { headerCols, rowDatas, classes, actions, style, pageTitle, page, pageActions, needTitle, footerDatas, tableClass, headerDatas, staticHeader, needActions, requiredFields } = this.props;
        const staticHeaderCls = staticHeader === true ? 'staticHeader' : '';
        return (
            <React.Fragment>
                <div className={style ? classes.width100 : classes.root}>
                    {
                        needTitle && (
                            <Grid container>
                                <Grid item xs={12} sm={8} style={{ alignSelf: 'center' }}>
                                    {
                                        page === 'explore' ? <div className={classes.exploreEditTableTitle}>Filter</div> :
                                            <PageTitle
                                                title={pageTitle}
                                                subTitle={''}
                                                actions={[]}
                                            />
                                    }
                                </Grid>
                                {needActions && (
                                    <Grid item xs={12} sm={4}>
                                        <PageActions
                                            actions={pageActions}
                                            handleAddActionClick={() => {
                                                this.props.onAdd();
                                            }}
                                            handlePreviewActionClick={() => {
                                                this.props.handlePreview();
                                            }}
                                        />
                                    </Grid>
                                )
                                }
                            </Grid>
                        )
                    }
                    <div style={staticHeader === false ? style : {}}>
                        <Table className={`${classes.editTable} ${tableClass} ${staticHeaderCls}`}>
                            <TableHead>
                                <TableRow>
                                    {
                                        headerCols.map((header) => {
                                            return <TableCell key={header} className={requiredFields.indexOf(header) >= 0 ? 'required-header' : 'normal-header'}>{header}</TableCell>
                                        })
                                    }
                                    {
                                        actions.length > 0 && <TableCell key={'actions'}>{'ACTIONS'}</TableCell>
                                    }

                                </TableRow>
                                <TableRow className={'loaderRow'}>
                                    <TableCell rowSpan={headerCols.length + 1} colSpan={headerCols.length + 1}>
                                        {this.props.isLoading === true &&
                                            <div className={'linearProgressContainer'}>
                                                <LinearProgress color="primary" />
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={staticHeader === true ? style : {}}>
                                {
                                    headerDatas.map((item, index) => {
                                        return this.renderHFTableRow(item, index, 'header');
                                    })
                                }
                                {
                                    rowDatas.map((item, index) => {
                                        return this.renderTableRow(item, index);
                                    })
                                }
                                {
                                    rowDatas.length === 0 && (
                                        <TableRow className={'children-row'}>
                                            <TableCell colSpan={headerCols.length + 1} style={{ padding: "10px", textAlign: "center" }}>
                                                <span style={{ fontSize: "14px", color: "#ccc" }}>{"No Records"}</span>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                                {
                                    <TableRow className={'children-row'}>
                                        <TableCell>
                                            {this.props.children}
                                        </TableCell>
                                    </TableRow>
                                }
                                {
                                    footerDatas.map((item, index) => {
                                        return this.renderHFTableRow(item, index, 'footer');
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>
                    <DeleteDialog
                        open={this.state.open}
                        content={"Are you sure you want to delete this record?"}
                        handleDeleteAction={() => {
                            this.props.onDelete(this.state.deleteItem);
                            this.setState({
                                open: false,
                                deleteItem: null
                            })
                        }}
                        id={this.state.deleteItemId}
                        handleDeleteOnCloseAction={() => {
                            this.setState({
                                open: false,
                                deleteItem: null
                            })
                        }}
                    />
                </div>
            </React.Fragment>
        )
    }
}
//default props
EditableTable.defaultProps = {
    componentMap: {},
    onDelete: () => { },
    cellComponentOnChange: () => { },
    headerCols: [],
    requiredFields: [],
    fieldCols: [],
    rowDatas: [],
    isLoading: false,
    actions: [],
    classes: {},
    style: {},
    pageTitle: '',
    needTitle: true,
    config: {},
    footerDatas: [],
    tableClass: '',
    headerDatas: [],
    isReadOnly: false,
    staticHeader: false,
    height: '',
    needActions: true,
    deleteTooltip: '',
    page: '',
    pageActions: ['ADD'],
    handleFilter: () => { }
}
/** Export components */
export default withStyles(tableStyle)(EditableTable);