import { alertActions } from "../actions";
import { DefaultRowDatas, DefaultStateDatas } from "../containers/Reports/tabs/Table4Sections/DefaultData";
import { configurationConstants, getFloat } from "../helpers";
import { formatExpenseCategory, getDebtCoverage, getOperatingReservePerUnit, getReplacementReservePerUnit } from "../helpers/Table4Calc";
import { confService } from "./configuration";
import { reportTablesServices } from "./reportTables";


export const Table4Service = {
    getConvertedTable4Data
};


/**
 * Method to get the table 4 datas
 *
 * @param {*} id report year table _id value
 */
async function getConvertedTable4Data(RPid, type) {
    // console.log('***1',RPid,type)
    let configData=[]
    function getConfig(type){
        confService.getDataByName(type)
            .then(
                configuration => {
                    let data = configuration.data.map((data) => {
                        data.label = data.abbrev ? data.abbrev : data.name;
                        if (type === 'Race') { data.label = data.desc; }
                        // for bedroom
                        if (type === 'Bedrooms') { data.bedCount = data.value; }
                        data.value = data.key;
                        return data;
                    });
                    configData.push(data)
                },
                error =>{ return error }
            );
    }
    await getConfig(configurationConstants.expenseCategory)
    // await getConfig(configurationConstants.otherIncome)
    // await getConfig(configurationConstants.subsidyType)
    // await getConfig(configurationConstants.lenders)
        
    reportTablesServices.getTable4Data(RPid, type).then(table4 => {
                const id = table4.data ? table4.data._id : null;
                if (table4.data) {
                    // merge the expense category
                    const preExpenses = table4.data.previousExpenseCategory.expenseCategory || [];
                    let expenseCategory = formatExpenseCategory(table4.data.expenseCategory || [], configData[0], preExpenses,DefaultRowDatas);
                    // add withdrawAmt and depositAmt fields to transaction data
                    let transactions = table4.data.abTransaction || [];
                    const availBalance = {
                        1: table4.data.replacementReserveBeginningBalance || 0,
                        0: table4.data.operatingReserveBeginningBalance || 0
                    }
                    // upload static replacement transaction
                    transactions = [
                        ...transactions
                    ];
                    transactions = transactions.map((trans) => {
                        // Replacing balance calculation
                        const amt = availBalance[trans.replacementFlag];
                        const tAmt = trans.amount || 0;
                        let balance = trans.depositFlag === 0 ? amt - tAmt : amt + tAmt;
                        availBalance[trans.replacementFlag] = balance;
                        balance = getFloat(balance);
                        return {
                            ...trans,
                            withdrawAmt: trans.depositFlag === 0 ? tAmt : '', // for withdraw field
                            depositAmt: trans.depositFlag === 1 ? tAmt : '', // for deposit field
                            balance: balance ? balance.toFixed(2) : 0
                        }
                    });
                    // split the transactions for 4a and 4b
                    let aTransaction = transactions.filter(aT => aT.replacementFlag === 1); // for 4a
                    let bTransaction = transactions.filter(bT => bT.replacementFlag === 0); // for 4b
                    
                    if (id == undefined) {
                        aTransaction.map((el) => {
                             el.depositAmt = "0.00"
                             el.withdrawAmt = "0.00"
                        })
                        bTransaction.map((el) => {el.depositAmt = "0.00"})
                    }
        
                    const stData = {
                        ...DefaultStateDatas,
                        ...table4.data,
                        expenseCategory,
                        aTransaction,
                        bTransaction
                    };
                    let PM = {
                        occupancyRate: getFloat(stData.performanceMeasure.occupancyRate),
                        vacancyRate:  getFloat(stData.performanceMeasure.vacancyRate),
                        replacementReservePerUnit: getReplacementReservePerUnit(stData),
                        operatingReservePerUnit: getOperatingReservePerUnit(stData),
                        debtCoverageRatio: getDebtCoverage(stData),
                        "vacancyRate_CALC": getFloat(stData.performanceMeasure.vacancyRate),
                        "calculatedRateOccupancy_CALC": getFloat(stData.performanceMeasure.occupancyRate),
                        averageUnitTurnAround: stData.averageUnitTurnAround,
                        "averageUnitTurnAround_CALC": stData.averageUnitTurnAround,
                        "actualPotentialRent_CALC": 0,
                        "calculatedCollectionRate_CALC": 0,
                        "replacementReservePerUnit_CALC": getReplacementReservePerUnit(stData),
                        "operatingReservePerUnit_CALC": getOperatingReservePerUnit(stData),
                    }

                    let generalData={
                        ...stData,
                        PM
                    }
                    console.log('***1',generalData)

                    if(table4.data._id != undefined){
                        reportTablesServices.updateTable4(table4.data._id, generalData).then(data=>console.log('****Table4Update',data))
                   }else{
                        reportTablesServices.createTable4({...generalData,reportYear_id: parseInt(RPid)}).then(data=>console.log('****Table4Create',data))
                   }
                }
            },
            error => {
                alertActions.error(error.toString());
            }
        );
}