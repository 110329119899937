import { UnitsConstants } from "../constants/units";
import { unitsService } from "../services/units";
import { alertActions, validationActions } from "./";
import { history } from "../helpers";
import { occupantsActions } from "./occupants";
import { Table4Service, UAService,incomeRentService, projectsService } from "../services";
export const unitsActions = {
    getAll,
    resetFilter,
    addNewUnit,
    updateUnit,
    getById,
    delete: _delete,
    getByUHId,
    getBuildingslist,
    getUnitsBulkList,
    bulkUpdate,
    getDelMsg,
    getUnitByTable1List
};

/**
 * A method to get the units list
 *
 * @param {*} skip  page to display. if 1 first page
 * @param {*} limit no of recorrd to fetch on the given page
 * @param {*} orderBy sort order column name string
 * @param {*} order sorttypr asc or desc
 * @param {*} sFields search fields column name
 * @param {*} sValues search field column values
 * @param {*} building_id building id to fecth the units under it
 * @returns
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, building_id, query) {
    return dispatch => {
        dispatch(request())

        unitsService.getAll(skip, limit, orderBy, order, sFields, sValues, building_id, false, query)
            .then(
                units => {
                    dispatch(success(units));
                    const { page } = units;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: UnitsConstants.GETALL_REQUEST } }
    function success(units) { return { type: UnitsConstants.GETALL_SUCCESS, units } }
    function failure(error) { return { type: UnitsConstants.GETALL_FAILURE, error } }
}

/**
 * Clear Filter
 */
function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: UnitsConstants.CLEAR_FILTER_SET } }
    function reset() { return { type: UnitsConstants.CLEAR_FILTER_RESET } }
}

/**
 * Create new unit
 * 
 * @param {*} formData 
 */
function addNewUnit(formData,callback = () => {}) {
    return dispatch => {
        dispatch(request());

        unitsService.addNewUnit(formData)
            .then(
                unit => {
                    projectsService.getProjectReport(1, 1, 'year', 'desc', '', '', unit.project_id,false,{isDelete: false}).then((res)=>{
                    Table4Service.getConvertedTable4Data(res.data&&res.data[0]._id, 'projects')
                    })
                    dispatch(success(unit));
                    dispatch(alertActions.success("Units Added Successfully"));
                    callback(unit);
                    setTimeout(() => {
                        history.goBack();
                    }, 2000);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };


    function request() { return { type: UnitsConstants.ADD_UNIT_REQUEST } }
    function success(unit) { return { type: UnitsConstants.ADD_UNIT_SUCCESS, unit } }
    function failure(error) { return { type: UnitsConstants.ADD_UNIT_FAILURE, error } }

}

/**
 * Update unit details page by using unit id
 * 
 * @param {*} formData - update data
 * @param {*} id - unit_id
 */
function updateUnit(formData, id, table1_id,  bedroomSizeChanged) {
    return dispatch => {
        dispatch(request());
        console.log("----------------------- BedroomSize Changed ----------------",bedroomSizeChanged)
        unitsService.updateUnit(formData, id)
            .then(
                unit => {
                    dispatch(success(unit));
                    dispatch(alertActions.success("Updated Successfully"));
                    if (bedroomSizeChanged) {
                        dispatch(alertActions.success("As you have updated the Unit details, your Income and rent limit values are processing. You will be notified once Table 1 is updated successfully."))
                        incomeRentService.triggerIncomeRent(0,0,table1_id,0,0,0).then(
                            data => {
                                dispatch(alertActions.success("Income and rent limit values are updated successfully in Table 1."));
                            }
                        )
                    }
                    setTimeout(()=>{
                        history.goBack()
                    },2000)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: UnitsConstants.UPDATE_UNIT_REQUEST } }
    function success(unit) { return { type: UnitsConstants.UPDATE_UNIT_SUCCESS, unit } }
    function failure(error) { return { type: UnitsConstants.UPDATE_UNIT_FAILURE, error } }

}

/**
 * A method to get the unit details
 *
 * @param {*} id
 * @returns
 */
function getById(id, type) {
    return dispatch => {
        dispatch(request());

        unitsService.getById(id, type)
            .then(
                unit => {
                    dispatch(success(unit))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: UnitsConstants.GETONE_REQUEST } }
    function success(unit) { return { type: UnitsConstants.GETONE_SUCCESS, unit } }
    function failure(error) { return { type: UnitsConstants.GETONE_FAILURE, error } }
}

/**
 * Delete a unit by using unit id
 * 
 * @param {*} unitid - unit_id
 */
function _delete(unitid,item, cb = () => { }) {
    return dispatch => {
        dispatch(request(unitid));

        unitsService.delete(unitid,item)
            .then(
                unit => {
                    projectsService.getProjectReport(1, 1, 'year', 'desc', '', '', unit.data?.project_id,false,{isDelete: false}).then((res)=>{
                    Table4Service.getConvertedTable4Data(res.data&&res.data[0]._id, 'projects')
                    })
                    dispatch(success(unitid));
                    dispatch(alertActions.success('Deleted Successfully'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                        dispatch(validationActions.removeValidationOnDelete(0,unitid))
                    }, 1000);
                    cb();
                },
                error => {
                    dispatch(failure(unitid, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: UnitsConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: UnitsConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: UnitsConstants.DELETE_FAILURE, id, error } }
}

/**
 * Delete a unit by using unit id
 * 
 * @param {*} unitid - unit_id
 */
 function getDelMsg(unitid,scb) {
    return dispatch => {
        dispatch(request());

        unitsService.getDelMsg(unitid)
            .then(
                data => {
                    dispatch(success(data));
                    scb(data);
                },
                error => {
                    dispatch(failure(unitid, error.toString()));
                }
            );
    }
    function request() { return { type: UnitsConstants.DELETE_MSG_REQUEST} }
    function success() { return { type: UnitsConstants.DELETE_MSG_SUCCESS} }
    function failure(error) { return { type: UnitsConstants.DELETE_MSG_FAILURE, error } }
};

/**
 * A method to get the unit history for the unit
 *
 * @param {*} id contains the id of the unit
 * @returns
 */
function getByUHId(orderBy, order, unitid) {
    return dispatch => {
        dispatch(request());

        unitsService.getByUHId(orderBy, order, unitid)
            .then(
                unit => dispatch(success(unit)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: UnitsConstants.GETONE_UH_REQUEST } }
    function success(data) { return { type: UnitsConstants.GETONE_UH_SUCCESS, data } }
    function failure(error) { return { type: UnitsConstants.GETONE_UH_FAILURE, error } }
}

/**
 * A method to get the building name list for the autocomplete used for add unit from main menu page
 *
 * @param {*} site_id contains the site id to get the buildings
 * @returns
 */
function getBuildingslist(site_id) {
    return dispatch => {
        dispatch(request());

        unitsService.getBuildingsList(site_id)
            .then(
                buildings => dispatch(success(buildings.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: UnitsConstants.GET_BUILDINGS_FOR_SITE_REQUEST } }
    function success(data) { return { type: UnitsConstants.GET_BUILDINGS_FOR_SITE_SUCCESS, data } }
    function failure(error) { return { type: UnitsConstants.GET_BUILDINGS_FOR_SITE_FAILURE, error } }
}

/**
 * A method to get building name list
 */
function getUnitsBulkList(skip, limit, orderBy, order, sFields, sValues, query) {
    return dispatch => {
        dispatch(request());
        unitsService.getUnitsBulkList(skip, limit, orderBy, order, sFields, sValues, query)
            .then(
                data => {
                    dispatch(success(data));
                    const { page } = data;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: UnitsConstants.GET_UNITSBULKLIST_REQUEST } }
    function success(data) { return { type: UnitsConstants.GET_UNITSBULKLIST_SUCCESS, data } }
    function failure(error) { return { type: UnitsConstants.GET_UNITSBULKLIST_FAILURE, error } }
}

/**
 * A method to bulk update
 */
function bulkUpdate(data, selectedBuilding, callback) {
    return dispatch => {
        dispatch(request());
        unitsService.bulkUpdate(data)
            .then(
                data => {
                    if (data.data && data.data.unitIds && data.data.unitIds.length > 0) {
                        dispatch(occupantsActions.updateIncomeRent(data.data));
                        UAService.updateUAInTable1(selectedBuilding)
                    }
                    dispatch(alertActions.success(data.message));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)
                    dispatch(success());
                    callback()
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)
                }
            );
    };

    function request() { return { type: UnitsConstants.UNITS_BULKUPDATE_REQUEST } }
    function success() { return { type: UnitsConstants.UNITS_BULKUPDATE_SUCCESS } }
    function failure(error) { return { type: UnitsConstants.UNITS_BULKUPDATE_FAILURE, error } }
}

/**
 * A method to get site table1 list
 */
function getUnitByTable1List(siteId) {
    return dispatch => {
        dispatch(request());
        unitsService.getUnitTable1List(siteId)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: UnitsConstants.GET_UNIT_TABLE1_REQUEST } }
    function success(data) { return { type: UnitsConstants.GET_UNIT_TABLE1_SUCCESS, data } }
    function failure(error) { return { type: UnitsConstants.GET_UNIT_TABLE1_FAILURE, error } }
}