export const confConstants = {
    fieldFilter: {
        // on user changes the show/hide of columns
        ON_CHANGE: "FIELD_FILTER_ON_CHANGE",
        // get user's field filters data
        GET_FIELDS_REQUEST: "FIELD_FILTER_GET_REQUEST",
        GET_FIELDS_SUCCESS: "FIELD_FILTER_GET_SUCCESS",
        GET_FIELDS_FAILURE: "FIELD_FILTER_GET_FAILURE",
        // update user's field filter data
        PUT_FIELDS_REQUEST: "FIELD_FILTER_PUT_REQUEST",
        PUT_FIELDS_SUCCESS: "FIELD_FILTER_PUT_SUCCESS",
        PUT_FIELDS_FAILURE: "FIELD_FILTER_PUT_FAILURE",
    },

    // configuration data constants
    GETONE_REQUEST: "CONFIGURATION_GETONE_REQUEST",
    GETONE_SUCCESS: "CONFIGURATION_GETONE_SUCCESS",
    GETONE_FAILURE: "CONFIGURATION_GETONE_FAILURE",

    //configuration data contants for contract activity page
    GETONE_REQUEST_CONTRACTACTIVITY:"GETONE_REQUEST_CONTRACTACTIVITY",
    GETONE_SUCCESS_CONTRACTACTIVITY:"GETONE_SUCCESS_CONTRACTACTIVITY",
    GETONE_FAILURE_CONTRACTACTIVITY:"GETONE_FAILURE_CONTRACTACTIVITY",




    //configuration get data
    GET_REQUEST: "CONFIGURATION_GET_REQUEST",
    GET_SUCCESS: "CONFIGURATION_GET_SUCCESS",
    GET_FAILURE: "CONFIGURATION_GET_FAILURE",

    // configuration update
    UPDATE_REQUEST: "CONFIGURATION_UPDATE_REQUEST",
    UPDATE_SUCCESS: "CONFIGURATION_UPDATE_SUCCESS",
    UPDATE_FAILURE: "CONFIGURATION_UPDATE_FAILURE",

    // comfiguration delete
    DELETE_REQUEST: "CONFIGURATION_DELETE_REQUEST",
    DELETE_SUCCESS: "CONFIGURATION_DELETE_SUCCESS",
    DELETE_FAILURE: "CONFIGURATION_DELETE_FAILURE",

    // funder report table 
    FUNDER_REPORT_TABLE_REQUEST: "FUNDER_REPORT_TABLE_REQUEST",
    FUNDER_REPORT_TABLE_SUCCESS: "FUNDER_REPORT_TABLE_SUCCESS",
    FUNDER_REPORT_TABLE_FAILURE: "FUNDER_REPORT_TABLE_FAILURE",

    // funder report table data
    GET_FUNDER_REPORT_TABLE_REQUEST: "GET_FUNDER_REPORT_TABLE_REQUEST",
    GET_FUNDER_REPORT_TABLE_SUCCESS: "GET_FUNDER_REPORT_TABLE_SUCCESS",
    GET_FUNDER_REPORT_TABLE_FAILURE: "GET_FUNDER_REPORT_TABLE_FAILURE",

    // Latest banner
    GET_LATEST_BANNER_REQUEST: "GET_LATEST_BANNER_REQUEST",
    GET_LATEST_BANNER_SUCCESS: "GET_LATEST_BANNER_SUCCESS",
    GET_LATEST_BANNER_FAILURE: "GET_LATEST_BANNER_FAILURE",


    //Moveout Reason Effective year
    GET_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST: "GET_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST",
    GET_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS: "GET_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS",
    GET_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE: "GET_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE",

    //Update moveout Reason Effective year
    UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST: "UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_REQUEST",
    UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS: "UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_SUCCESS",
    UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE: "UPDATE_MOVEOUTREASON_EFFECTIVE_YEAR_FAILURE",

    SHOW_LOADING: "SHOW_LOADING",
    HIDE_LOADING: "HIDE LOADING"
}