import React, { Component } from 'react';
import { connect } from 'react-redux';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

// Import Actions
import { userActions } from '../../actions/user'

// Import Images
import Seattle from '../../assets/img/seattle.png';
import Kingcounty from '../../assets/img/kingcounty.png';
import Cityofspokane from '../../assets/img/cityofspokane.png';
import Cityoftacoma from '../../assets/img/cityoftacoma.png';
import Snohomish from '../../assets/img/snohomish.png';
import Cityofbellingham from '../../assets/img/cityofbellingham.png';
import Housingfinancecommission from '../../assets/img/housingfinancecommission.png';
import Stateofwashington from '../../assets/img/stateofwashington.png';

//Import Documants
import StandardConfig from '../../assets/documents/Standard Configuration.pdf';
import Questions from '../../assets/documents/FOR QUESTIONS ABOUT USING WBARS_March2018.pdf';
import Tos from '../../assets/documents/TOS.pdf';

// Import Styles
import loginStyle from '../../assets/jss/containers/login';

// Import Components
import TextBox from '../../components/TextBox/TextBox';

/**
 * ForgotPassword Container
 * @class ForgotPassword
 * @extends {Component}
 */
class ForgotPassword extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };

        // reset login status
        // this.props.logout();

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Submit
     * @param {*} e 
     */
    handleSubmit(e) {
        e.preventDefault();
        const { email } = this.state;
        if (email) {
            this.props.resetPassword(email, () => { });
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { email } = this.state;
        const { authentication: { resetPassword } } = this.props;
        const success = resetPassword.success;
        return (
            <Grid container direction="row" alignItems="stretch" className="h-100">
                <Grid item xs={7} lg={8} className="banner_bg">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" className="h-100 banner_container" >
                        <Grid item className="banner_block">
                            <Typography variant="h3" gutterBottom color="textPrimary"> 2018 WBARS Improvements </Typography>
                            <Typography variant="h6" gutterBottom color="textPrimary"> we are making wbars easier to use </Typography>
                            <Button variant="outlined" className="btn_outline m-t-20" > Find out More! </Button>
                        </Grid>
                        <Grid item className="footer_links">
                            <p><a href={Questions} target="_blank" rel="noopener noreferrer"> Contact Us </a>
                                | <a target="_blank" href="http://www.wshfc.org/managers/wbars.htm" rel="noopener noreferrer"> Instructions </a>
                                | <a href={StandardConfig} target="_blank" rel="noopener noreferrer"> Standard Configuration </a>
                                | <a href={Tos} target="_blank" rel="noopener noreferrer"> Terms of Use </a></p>
                            <p> Washington State Housing Finance Commission. © 2018 All Rights Reserved. </p>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={5} lg={4} className="login_block" >
                    <Grid container direction="column" justifyContent="space-between" className="h-100">
                        <Grid item>
                            <Grid container direction="row">
                                <Grid item>
                                    <a target="_blank" href="http://www.wshfc.org/managers/" rel="noopener noreferrer">
                                        <img src={Housingfinancecommission} alt="Housing Financecommission" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="https://www.commerce.wa.gov/building-infrastructure/housing/" rel="noopener noreferrer">
                                        <img src={Stateofwashington} alt="State of Washington" />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            {
                                !success ? (
                                    <ValidatorForm
                                        name="forgetPasswordForm"
                                        ref="form"
                                        autoComplete="off"
                                        onSubmit={this.handleSubmit}
                                        onError={errors => { }}
                                    >
                                        <TextBox
                                            id="email"
                                            label="E-mail"
                                            margin="normal"
                                            className="login_ip_field"
                                            name="email"
                                            value={email}
                                            handleChange={this.handleChange}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                        <Button type="submit" variant="contained" color="primary" className="login_ip_field m-t-30" > Submit </Button>
                                        <Button color="default" className="login_ip_field m-t-20 " component={Link} to="/login"> Login </Button>
                                        {/*<Link to="/forgot-password" className="login_ip_field m-t-20">Forgot Password</Link>*/}
                                    </ValidatorForm>
                                ) : (
                                        <Grid item>
                                            <p>Reset Password link has been sent to your e-mail.</p>
                                            <Button color="primary" className="login_ip_field m-t-20 " component={Link} to="/login"> Login </Button>
                                        </Grid>
                                    )
                            }
                        </Grid>
                        <Grid item >
                            <Grid container direction="row">
                                <Grid item>
                                    <a target="_blank" href="http://www.seattle.gov/housing/property-managers" rel="noopener noreferrer">
                                        <img src={Seattle} alt="Seattle" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="https://www.kingcounty.gov/depts/community-human-services/housing.aspx" rel="noopener noreferrer">
                                        <img src={Kingcounty} alt="Kingcounty" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="https://my.spokanecity.org/chhs/programs/" rel="noopener noreferrer">
                                        <img src={Cityofspokane} alt="Cityofspokane" />
                                    </a>
                                </Grid>
                            </Grid>
                            <Grid container direction="row">
                                <Grid item>
                                    <a target="_blank" href="http://www.cityoftacoma.org/cms/one.aspx?objectId=22068" rel="noopener noreferrer">
                                        <img src={Cityoftacoma} alt="cityoftacoma" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="https://snohomishcountywa.gov/429/Housing-and-Community-Services" rel="noopener noreferrer">
                                        <img src={Snohomish} alt="snohomish" />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a target="_blank" href="https://www.cob.org/services/housing" rel="noopener noreferrer">
                                        <img src={Cityofbellingham} alt="cityofbellingham" />
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ authentication }, ownProps) => {
    return {
        authentication
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        resetPassword: (email, callBack) => dispatch(userActions.resetPassword(email, callBack))
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(loginStyle)(ForgotPassword));
