import { userConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const resetPassword = {
    resetPassword: {
        isLoading: false,
        success: false
    },
    changePassword: {
        isLoading: false,
        success: false
    }
};
const initialState = user ? { loggedIn: true, user, ...resetPassword, isLoading: false } : {...resetPassword, isLoading: false};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
          ...state,
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {...state, user: {}};
    case userConstants.LOGOUT:
      return {...state, user: {}};
    case userConstants.RESET_PASSWORD_REQUEST:
      return {
          ...state,
          resetPassword: {
              ...state.resetPassword,
              isLoading: true,
          }
      }
  case userConstants.RESET_PASSWORD_SUCCESS:
      return {
          ...state,
          resetPassword: {
              ...state.resetPassword,
              success: true,
              isLoading: false,
          }
      };
  case userConstants.RESET_PASSWORD_FAILURE:
      return {
          ...state,
          resetPassword: {
              ...state.resetPassword,
              success: false,
              error: action.error,
              isLoading: false,
          }
      };
    case userConstants.CHANGE_PASSWORD_REQUEST:
      return {
          ...state,
          changePassword: {
              ...state.changePassword,
              isLoading: true,
          }
      }
  case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
          ...state,
          changePassword: {
              ...state.changePassword,
              success: true,
              isLoading: false,
          }
      };
  case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
          ...state,
          changePassword: {
              ...state.changePassword,
              success: false,
              error: action.error,
              isLoading: false,
          }
      };
      case userConstants.TOGGLE_ACCEPT_TERMS:
            return {
                ...state,
                isTermsPopupAck: action.status,
            };
    case userConstants.ACCEPT_TERMS_REQUEST:
        return {
            ...state,
            isLoading: true
        };
    case userConstants.ACCEPT_TERMS_SUCCESS:
        return {
            ...state,
            isLoading: false
        };
    case userConstants.ACCEPT_TERMS_FAILURE:
        return {
            ...state,
            isLoading: false,
            error: action.error
        }
    default:
      return state
  }
}