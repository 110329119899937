//import helper
import { authHeader,globalVariable,handleResponse } from "../helpers";

export const globalSearchServices = {
    getGlobalSearch
}

/**
 *Get Search data
 *
 * @param {*} value - search data
 * @param {*} searchKey - dropdown index
 * @returns
 */
function getGlobalSearch(value, searchKey) {
    let data = {key:value,value:searchKey}
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body:JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/global-search`, requestOptions).then(handleResponse);
}