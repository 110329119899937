import { Component } from "react";
import PropTypes from 'prop-types';
import { Element } from 'react-faux-dom';
import * as d3 from "d3";

// Import Components
import ResponsiveWrapper from '../ResponsiveWrapper';

/**
 *Group Chart Component
 *
 * @class GroupBarChart
 * @extends {Component}
 */

class HOReportStatusBarChart extends Component{

    drawChart() {
        const el = new Element('div');
        const svgDimensions = {
            //selects the maximum width from the two parameters passed
            width: Math.max(this.props.parentWidth, 300),
            height: 338
        };

        d3.select('.barChart').selectAll("*").remove();

        let svg = d3.select(el)
            .append('svg')
            .attr("width", svgDimensions.width)
            .attr("height", svgDimensions.height);

        let margin = { top: 20, right: 100, bottom: 40, left: 100 },
            width = +svgDimensions.width - margin.left - margin.right,
            height = svgDimensions.height - margin.top - margin.bottom;

        let chart = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        this.plot(chart, width, height, margin);

        return el.toReact();
    }

    plot(chart, width, height, margin) {
        var colorScale = {
            Preparer: "#6EE4D8",
            Contractor: "#678BFE",
            Funder: "#EF9FB7",
            Owner: "#678BFE",
        }
        let data = this.props.data;
        var divTooltip = d3.select(".toolTip")
        var xScale = d3.scaleBand().range([0, width]).padding(0.8),
            yScale = d3.scaleLinear().range([height, 0]);
        
        xScale.domain(data.map(function (d) { return d.label; }));
        yScale.domain([0, d3.max(data, function (d) { return d.value; })]);

        let chargroup = chart.append("g")
            .attr("samp", function (d) { return "something" });

        chargroup.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(xScale));

        chargroup.append("g")
            .call(d3.axisLeft(yScale).tickFormat(function (d) {
                return d+"%";
            }).ticks(5));

        chargroup.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("x", function (d) { return xScale(d.label); })
            .attr("y", function (d) { return yScale(d.value); })
            .attr('value', function (d) { return d.label + " : " + d.value + "%" })
            .attr("width", xScale.bandwidth())
            .attr("height", function (d) { return height - yScale(d.value); })
            .attr("fill", function (d) { return colorScale[d.label] })
            .on("mousemove", function (d) {
                divTooltip.style("z-index", '99999');
                divTooltip.style("left", d3.event.pageX - 35 + "px");
                divTooltip.style("top", d3.event.pageY - 50 + "px");
                divTooltip.style("display", "inline-block");
                let elements = document.querySelectorAll(':hover');
                let l = elements.length;
                l = l - 1;
                const value = elements[l].getAttribute("value");
                if (value )
                    divTooltip.html(value);
            })
            .on("mouseout", function (d) {
                divTooltip.style("display", "none");
            });
    }
    /**
     *render html
     * @returns
     * @memberof GroupBarChart
     */
    render() {
        return this.drawChart();
    }
}

//Prop Types
HOReportStatusBarChart.propTypes = {
    data: PropTypes.array.isRequired
};

//Default Props
HOReportStatusBarChart.defaultProps = {
    data: []
}

/**Export Component */
export default ResponsiveWrapper(HOReportStatusBarChart);