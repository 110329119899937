import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { TableHead, TableRow, TableCell, TableBody, Table } from '@material-ui/core';
import { Input } from '@material-ui/core';

// React DND
import { DragDropContext } from 'react-beautiful-dnd';

//Helpers
import { history } from '../../helpers';
import ValidatorForm from "../../helpers/fieldValidations";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

//Import Components
import OrganizationMerge from "../../components/MergeTool/OrganizationMerge";
import AlertDialog from "../../components/AlertDialog/AlertDialog";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import AutoComplete from '../../components/AutoComplete/AutoComplete';

//Import Actions
import { mergeActions } from '../../actions/mergeActions';
import { alertActions, pageAccessActions } from '../../actions';
/**
 * HTML tooltip
 * @param {*} props 
 * @returns 
 */
function HTMLTooltip(props) {
    let header = ["Organization Type", "Address"];
    let orgDetails = props ? props : [];
    return (
        <Table >
            <TableHead>
                <TableRow className={'tooltiphead'} >
                    {header.map((header, index) => {
                        return <TableCell>{header}</TableCell>
                    })}
                </TableRow>
            </TableHead>

            <TableBody className={'tooltipbody'} >
                <TableRow>
                    <TableCell>{orgDetails.organizationType.map(i => i).join(", ")}</TableCell>
                    <TableCell>{orgDetails.address && orgDetails.address.line &&
                        orgDetails.address.line !== null ? orgDetails.address.line : ''}&nbsp;&nbsp;</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}
/**
 * OrganizationsMerge Container
 * @class OrganizationsMerge
 * @extends {Component}
 */
class OrganizationsMerge extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selected: [],
            filteredItems1: [],
            filteredItems2: [],
            droppedItems1: [],
            droppedItems2: [],
            formdata: {},
            orgType: [],
            openAlert: false,
            alertText: '',
            selectedOrg: [],
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        this.props.getPageAccess()
        setTimeout(() => {
            this.props.getOrganizationList();
        }, 100);
    }
    /**
     * Component will receive props
     * @param {*} props 
     */
    UNSAFE_componentWillReceiveProps(props) {
        const { organizations } = props
        this.setState({ items: organizations })

        if (this.state.droppedItems2.length <= 0) {
            this.setState({ filteredItems1: organizations })
        }
    }
    /**
     * Search handler for left pane
     * @param {*} event 
     */
    DragsearchHandlerLeft(event) {
        let { droppedItems2, items } = this.state;
        if (droppedItems2.length > 0) {
            droppedItems2.forEach(drop2 => {
                items = items.filter(elem => drop2._id !== elem._id);
            })
        }
        let search = event.target.value.toLowerCase();
        let searchValue = items.filter((el) => el.name.toLowerCase().includes(search))
        this.setState({ filteredItems1: searchValue })
    }
    /**
     * Search handler for right pane
     * @param {*} event 
     */
    DragsearchHandlerRight(event) {
        let search = event.target.value.toLowerCase();
        let searchValue = this.state.droppedItems2.filter((el) => el.name.toLowerCase().includes(search));
        this.setState({ filteredItems2: searchValue })
    }
    /**
     * Submit
     * @returns 
     */
    submit() {
        const { formdata } = this.state
        const { organizations } = this.props
        this.props.mergeOrganizations(formdata, () => {
            this.props.showSuccessAlert('Organization merged successfully')
            this.props.getOrganizationList()
            this.setState({ openAlert: false, alertText: '', filteredItems1: organizations, filteredItems2: [], droppedItems2: [] })
        })
        return
    }
    /**
     * Handle Back Action Click
     */
    handleBackActionClick() {
        history.goBack()
    }
    /**
     * Handle autocomplete
     * @param {*} value 
     */
    handleAuto(value) {
        var filterselected = this.state.filteredItems1.filter(org => org._id !== value._id)
        this.setState({ droppedItems2: value.length === 0 ? value : [value], filteredItems1: filterselected })
    }
    /**
     * Handle Close
     */
    handleClose() {
        this.setState({ openAlert: false });
    }
    /**
     * Handle Merge
     * @returns 
     * 
     * [1]  -   Index 0 to org
     * [2]  -   Data to update
     * [3]  -   Setting the id for toOrg,fromorg and setting emails for notification
     * [4]  -   check organization type
     * [5]  -   Data for submission
     * [6]  -   Alert Dialog
     */
    handleMerge = () => {
        let toOrganization = []
        let fromOrganization = []

        const { droppedItems2 } = this.state
        if (droppedItems2.length < 2) {
            this.props.showErrorAlert('Please select the mandatory fields to merge')
            return
        }
        // [1]
        if (droppedItems2.length >= 2) {
            droppedItems2.forEach((item, index) => {
                if (index === 0) {
                    toOrganization.push(item)
                } else {
                    fromOrganization.push(item)
                }
            })
        }
        // [2]
        let toOrg = null
        let fromOrg = []
        let organizationType = [...toOrganization[0].organizationType]
        let orgType = []
        // [3]
        toOrg = toOrganization[0]._id
        fromOrganization.forEach(item => {
            fromOrg.push(item._id)
        })
        // [4]
        var result = []
        if (fromOrganization && toOrganization && fromOrganization.length > 0 && toOrganization.length > 0) {
            fromOrganization.forEach((item, index) => {
                item.organizationType.forEach(elem => {
                    if (!organizationType.some(type => type === elem)) {
                        organizationType.push(elem)
                    }
                    if (!toOrganization[0].organizationType.includes(elem)) orgType.push(elem);
                    result.push(toOrganization[0].organizationType.includes(elem))
                })
            })
        }
        // [5]
        const formdata = {
            toOrg: toOrg,
            fromOrg: fromOrg,
            orgType: organizationType,
            toOrgName: toOrganization[0].name,
            notificationText: `The organization ${fromOrganization.map(org => org.name).join(', ')} has been merged with 
            ${toOrganization[0].name}.Please contact WBARS administrator for any concerns`
        }
        this.setState({ formdata: formdata })
        // [6]
        if (result.includes(false)) {
            this.setState({ alertText: `<b>${toOrganization[0].name}</b> Type will be changed to <b>${organizationType.join(', ')}</b> . Do you want to merge the Organizations from <b>${fromOrganization.map(org => org.name).join(', ')}</b> to <b>${toOrganization[0].name}</b>?` })
            this.setState({ openAlert: true })
        }
        if (!result.includes(false)) {
            this.setState({ alertText: `Do you want to merge the Organizations from <b>${fromOrganization.map(org => org.name).join(', ')}</b> to <b>${toOrganization[0].name}</b>?` })
            this.setState({ openAlert: true })
        }

    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   update the list by removing the dragged item
     */
    render() {
        let { classes, organizations, currentTab, pageAccess, title } = this.props;
        let { organization1, organization2, filteredItems1, items, droppedItems2 } = this.state;
        let selectedColumns = [];
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                handleMergeActionClick={() => {
                    this.handleMerge()
                }}
                handleBackActionClick={() => {
                    this.handleBackActionClick()
                }}
            >
                <AlertDialog
                    open={this.state.openAlert}
                    saveText={'Merge Organizations'}
                    onSave={() => {
                        this.submit()
                    }}
                    onClose={() => {
                        this.handleClose()
                    }}
                    title={'Organization merge confirmation'}
                >
                    <div dangerouslySetInnerHTML={{ __html: this.state.alertText }}></div>
                </AlertDialog>
                <div className={classes.rootEdit}>
                    <DragDropContext
                        onDragEnd={(result) => {
                            const { destination, draggableId } = result;
                            if (!destination) {
                                return;
                            }
                            if (destination.droppableId && destination.droppableId === 'droppable1') {
                                if (!this.state.filteredItems1.find(item => item._id === +draggableId)) {
                                    var getItemToFilter = filteredItems1
                                    getItemToFilter.push(items.find(item => item._id === +draggableId))
                                    var sortedList = getItemToFilter.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                    filteredItems1 = sortedList;

                                    var removeItem = droppedItems2.filter(item => item._id !== +draggableId)
                                    droppedItems2 = removeItem;
                                    this.setState({ filteredItems1, droppedItems2 })
                                }
                            }
                            let drop = this.state.droppedItems2
                            if (drop.length <= 0) {
                                this.props.showErrorAlert('Please select the mandatory fields to merge')
                                return
                            }

                            if (destination.droppableId === 'droppable2') {
                                if (!this.state.droppedItems2.find(item => item._id === +draggableId)) {
                                    drop.push(this.state.items.find(item => item._id === +draggableId))
                                    // [1]
                                    let updatesource = this.state.filteredItems1.filter(item => item._id !== +draggableId)
                                    this.setState({ droppedItems2: drop, filteredItems1: updatesource })
                                }
                            }
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} className={'toorgmerge'}>
                                <Grid item>
                                    <Input
                                        className={'searchipmer'}
                                        onChange={(event, name) => {
                                            this.DragsearchHandlerLeft(event, name)
                                        }}
                                        placeholder={'Search From Organization *'}
                                    />
                                </Grid>
                                <OrganizationMerge
                                    isDisableFirst={this.state.noRecordsFlag}
                                    suggestions={organizations}
                                    value={organization1}
                                    name={'organizationName1'}
                                    itemsOrganization={this.state.filteredItems1}
                                    seleted={selectedColumns}
                                    droppableId={'droppable1'}
                                    projectId={this.state.organization1}
                                    currentTab={currentTab}
                                    pageAccess={pageAccess}
                                    toolTipHtmlContent={(item) => {
                                        return <HTMLTooltip {...item} />
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <ValidatorForm
                                    name="userForm"
                                    ref="form"
                                    autoComplete="off"
                                    onSubmit={this.handleSubmitMerge}
                                    onError={errors => { }} >
                                    <Grid item spacing={12}className={'toorgmerges'}>
                                        <AutoComplete
                                            className={'searchipmer'}
                                            suggestions={organizations}
                                            name={'Orgnaization Merge'}
                                            handleAutoCompolete={(selectedOption) => {
                                                this.handleAuto(selectedOption)
                                            }}
                                            selectedValue={droppedItems2.length > 0 ? droppedItems2[0] : {}}
                                            placeholder={"Select To Organization"}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                        />
                                    </Grid>
                                    <OrganizationMerge
                                        disableProject={organization2}
                                        suggestions={organizations}
                                        value={organization2}
                                        name={'organizationName2'}
                                        itemsOrganization={droppedItems2}
                                        selected={selectedColumns}
                                        droppableId={'droppable2'}
                                        projectId={this.state.organization2}
                                        currentTab={currentTab}
                                        pageAccess={pageAccess}
                                        toolTipHtmlContent={(item) => {
                                            return <HTMLTooltip {...item} />
                                        }}
                                    />
                                </ValidatorForm>
                            </Grid>
                        </Grid>
                    </DragDropContext>
                </div>
            </ContentWrapper>
        )
    }
}
/**
 * Bind Proptypes
 */
OrganizationsMerge.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    handleMergeClick: PropTypes.func,
    title: PropTypes.string
};
/**
 * Default PropTypes
 */
OrganizationsMerge.defaultProps = {
    pageAccess: [],
    currentTab: 4,
    handleMergeClick: () => { },
    title: "Merge Organizations"
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        organizations: state.merge.organizations,
        organization1: state.merge.organizations.value,
        pageAccess: state.pageAccess.organizationMerge
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationList: () => dispatch(mergeActions.getOrganizationList()),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        showSuccessAlert: (message) => dispatch(alertActions.success(message)),
        getPageAccess: () => dispatch(pageAccessActions.getAccess('organizationMerge')),
        mergeOrganizations: (formdata, callback) => dispatch(mergeActions.organizationMerge(formdata, callback)),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(OrganizationsMerge));