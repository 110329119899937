import { dashboardConstants } from '../constants';

const initialState = {
    projectSummary: {
        projects: [],
        projectsCount: 0,
        years: [],
        summaryDetails: {},
        isLoading: false
    },
    occupancyRate: {
        data: {
            occupancyRate: [
                {
                    name: "Occupancy Rate",
                    value: "0",
                },
                {
                    name: "Vacancy Rate",
                    value: "100",
                }
            ],
            occupiedUnits: 0,
            totalUnits: 0
        },
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    reportStatus: {
        data: [
            {
                'Contractor / Owner': 0,
                'Funder(s)': 0,
                'Never Submitted': 0,
                'Not Required': 0,
                'Property Manager': 0,
                'TableName': "Table 1"
            },
            {
                'Contractor / Owner': 0,
                'Funder(s)': 0,
                'Never Submitted': 0,
                'Not Required': 0,
                'Property Manager': 0,
                'TableName': "Table 2"
            },
            {
                'Contractor / Owner': 0,
                'Funder(s)': 0,
                'Never Submitted': 0,
                'Not Required': 0,
                'Property Manager': 0,
                'TableName': "Table 3"
            },
            {
                'Contractor / Owner': 0,
                'Funder(s)': 0,
                'Never Submitted': 0,
                'Not Required': 0,
                'Property Manager': 0,
                'TableName': "Table 4"
            }
        ],
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    complianceIssue: {
        data: {
            donutChartData: [
                [
                    {
                        title: "Table 1",
                        value: 0,
                    },
                    {
                        title: "Table 1",
                        value: 100,
                    }
                ],
                [
                    {
                        title: "Table 2",
                        value: 0,
                    },
                    {
                        title: "Table 2",
                        value: 100,
                    }
                ],
                [
                    {
                        title: "Table 3",
                        value: 0,
                    },
                    {
                        title: "Table 3",
                        value: 100,
                    }
                ],
                [
                    {
                        title: "Table 4",
                        value: 0,
                    },
                    {
                        title: "Table 4",
                        value: 100,
                    }
                ]
            ]
        },
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    complianceNeedsAction: {
        data: {},
        selectedSites: [],
        year: new Date().getFullYear()-1,
        isLoading: false
    },
    dashboard: {
        isLoading: false,
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: ['project.name'],
                searchCols: []
            }
        }
    },
    effectiveDate: {
        isLoading: false,
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: ['project.name'],
                searchCols: []
            }
        }
    },
    funderDataExports: {
        data: [],
        isLoading: false
    },
    PSBUCounts: {
        data: {
            'Total Projects': 0,
            'Total Sites': 0,
            'Total Buildings': 0,
            'Total Units': 0
        },
        isLoading: false
    },
    usersSummary: {
        data: {
            totalUsers: {
                title: 'Total Users',
                count: 0
            },
            totalOrganizations: {
                title: 'Total Organizations',
                count: 0
            },
            organizationAdmin: {
                title: 'Organization Admin',
                count: 0,
                status: [
                    {
                        title: 'Active',
                        value: 0
                    },
                    {
                        title: 'Inactive',
                        value: 0
                    }
                ]
            },
            onsiteManager: {
                title: 'Onsite Manager',
                count: 0,
                status: [
                    {
                        title: 'Active',
                        value: 0
                    },
                    {
                        title: 'Inactive',
                        value: 0
                    }
                ]
            },
            none: {
                title: 'Others',
                count: 0,
                status: [
                    {
                        title: 'Active',
                        value: 0
                    },
                    {
                        title: 'Inactive',
                        value: 0
                    }
                ]
            }
        },
        isLoading: false
    },
    projectList: {
        data: {},
        isLoading: false
    },
}

export function dashboard(state = { ...initialState }, action) {
    switch (action.type) {
        case dashboardConstants.GET_PROJECTSLIST_REQUEST:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    isLoading: true
                }
            };
        case dashboardConstants.GET_PROJECTSLIST_SUCCESS:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    ...action.data,
                    hadData: state.dashboard.hadData !== undefined ? state.dashboard.hadData : action.data.data.length > 0,
                    isLoading: false
                }
            };
        case dashboardConstants.GET_PROJECTSLIST_FAILURE:
            return {
                ...state,
                dashboard: {
                    ...state.dashboard,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.GET_EFFECTIVELIST_REQUEST:
            return {
                ...state,
                effectiveDate: {
                    ...state.effectiveDate,
                    isLoading: true
                }
            };
        case dashboardConstants.GET_EFFECTIVELIST_SUCCESS:
            return {
                ...state,
                effectiveDate: {
                    ...state.effectiveDate,
                    ...action.data,
                    hadData: state.effectiveDate.hadData !== undefined ? state.effectiveDate.hadData : action.data.data.length > 0,
                    isLoading: false
                }
            };
        case dashboardConstants.GET_EFFECTIVELIST_FAILURE:
            return {
                ...state,
                effectiveDate: {
                    ...state.effectiveDate,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_PROJECTSUMMARY_REQUEST:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    summaryDetails: {},
                    isLoading: true,
                }
            };
        case dashboardConstants.GETALL_PROJECTSUMMARY_SUCCESS:
            return {
                ...state,
                projectSummary: {
                    projects: action.data.projects,
                    projectsCount: action.data.projectsCount,
                    years: action.data.years,
                    summaryDetails: action.data.summaryDetails || state.projectSummary.summaryDetails,
                    isLoading: false,
                }
            };
        case dashboardConstants.GETALL_PROJECTSUMMARY_FAILURE:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_COMPLIANCE_ISSUE_REQUEST:
            return {
                ...state,
                complianceIssue: {
                    ...state.complianceIssue,
                    isLoading: true,
                }
            };
        case dashboardConstants.GETALL_COMPLIANCE_ISSUE_SUCCESS:
            return {
                ...state,
                complianceIssue: {
                    data: action.data,
                    year: action.year,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_COMPLIANCE_ISSUE_FAILURE:
            return {
                ...state,
                complianceIssue: {
                    ...state.complianceIssue,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_COMPLIANCE_NEEDSACTION_REQUEST:
            return {
                ...state,
                complianceNeedsAction: {
                    ...state.complianceNeedsAction,
                    isLoading: true,
                }
            };
        case dashboardConstants.GETALL_COMPLIANCE_NEEDSACTION_SUCCESS:
            return {
                ...state,
                complianceNeedsAction: {
                    data: action.data,
                    year: action.year,
                    selectedSites: action.selectedSites,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_COMPLIANCE_NEEDSACTION_FAILURE:
            return {
                ...state,
                complianceNeedsAction: {
                    ...state.complianceNeedsAction,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_OCCUPANCY_RATE_REQUEST:
            return {
                ...state,
                occupancyRate: {
                    ...state.occupancyRate,
                    isLoading: true,
                }
            };
        case dashboardConstants.GETALL_OCCUPANCY_RATE_SUCCESS:
            return {
                ...state,
                occupancyRate: {
                    data: action.data,
                    year: action.year,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_OCCUPANCY_RATE_FAILURE:
            return {
                ...state,
                occupancyRate: {
                    ...state.occupancyRate,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_REPORT_STATUS_REQUEST:
            return {
                ...state,
                reportStatus: {
                    ...state.reportStatus,
                    isLoading: true,
                }
            };
        case dashboardConstants.GETALL_REPORT_STATUS_SUCCESS:
            return {
                ...state,
                reportStatus: {
                    data: action.data,
                    year: action.year,
                    isLoading: false
                }
            };
        case dashboardConstants.GETALL_REPORT_STATUS_FAILURE:
            return {
                ...state,
                reportStatus: {
                    ...state.reportStatus,
                    error: action.error,
                    isLoading: false
                }
            };
        case dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_REQUEST:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    summaryDetails: {},
                    error: null,
                    isLoading: true,
                }
            };
        case dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_SUCCESS:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    summaryDetails: action.data,
                    isLoading: false,
                }
            };
        case dashboardConstants.PROJECT_SUMMARY_REPORT_DOWNLOAD_FAILURE:
        case dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_FAILURE:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    error: action.error,
                    isLoading: false,
                }
            };
        case dashboardConstants.GET_REPORTNAME_REQUEST:
            return {
                ...state,
                funderDataExports: {
                    ...action.data,
                    isLoading: true,
                }
            };
        case dashboardConstants.GET_REPORTNAME_SUCCESS:
            return {
                ...state,
                funderDataExports: {
                    ...action.data,
                    isLoading: false,
                }
            };
        case dashboardConstants.GET_REPORTNAME_FAILURE:
            return {
                ...state,
                funderDataExports: {
                    ...action.data,
                    error: action.error,
                    isLoading: false,
                }
            };

        case dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_REQUEST:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    isLoading: true,
                }
            };

        case dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_SUCCESS:
        case dashboardConstants.PROJECT_SUMMARY_REPORT_VIEW_DOWNLOAD_FAILURE:
            return {
                ...state,
                projectSummary: {
                    ...state.projectSummary,
                    isLoading: false,
                }
            };
        case dashboardConstants.PSBU_COUNT_REQUEST:
            return {
                ...state,
                PSBUCounts: {
                    ...state.PSBUCounts,
                    isLoading: true,
                }
            };

        case dashboardConstants.PSBU_COUNT_SUCCESS:
            return {
                ...state,
                PSBUCounts: {
                    data: action.data,
                    isLoading: false
                }
            };
        case dashboardConstants.PSBU_COUNT_FAILURE:
            return {
                ...state,
                PSBUCounts: {
                    ...state.PSBUCounts,
                    error: action.error,
                    isLoading: false,
                }
            };
        case dashboardConstants.USERSSUMMARY_REQUEST:
            return {
                ...state,
                usersSummary: {
                    ...state.usersSummary,
                    isLoading: true,
                }
            };

        case dashboardConstants.USERSSUMMARY_SUCCESS:
            return {
                ...state,
                usersSummary: {
                    data: action.data,
                    isLoading: false
                }
            };
        case dashboardConstants.USERSSUMMARY_FAILURE:
            return {
                ...state,
                usersSummary: {
                    ...state.usersSummary,
                    error: action.error,
                    isLoading: false,
                }
            };
        case dashboardConstants.GET_PROJECT_LIST_REQUEST:
            return {
                ...state,
                projectList: {
                    data: {},
                    isLoading: true,
                }
            };

        case dashboardConstants.GET_PROJECT_LIST_SUCCESS:
            return {
                ...state,
                projectList: {
                    data: action.data,
                    isLoading: false
                }
            };
        case dashboardConstants.GET_PROJECT_LIST_FAILURE:
            return {
                ...state,
                projectList: {
                    ...state.projectList,
                    error: action.error,
                    isLoading: false,
                }
            };

        default: return state;
    }
}
