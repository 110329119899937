export const rowDataFields = {
    otherIncomeRow: {
        _id: '',
        otherIncome_id: '',
        amount: '',
        comment: ''
    },
    subsidyTypesRow: {
        _id: '',
        subsidyType_id: '',
        operatingAmt: '',
        servicesAmt: '',
        comment: ''
    },
    expenseCategory: {
        operatingAmt: 0,
        servicesAmt: 0,
        comment: ''
    },
    debtServices: {
        _id: '',
        lender_id: '',
        otherFunderComment: '',
        monthyLoanPayment: '',
        rate: '',
        amortizationYears: '',
        termYears: '',
        fundsPaid: ''
    },
    aTransaction: {
        _id: '',
        replacementFlag: 1, // for 4a
        date: '',
        description: '',
        amount: '',
        withdrawAmt: '',
        depositAmt: '',
        depositFlag: '',
        editable: true,
        customError: false,
    },
    bTransaction: {
        _id: '',
        replacementFlag: 0, // for 4b
        date: '',
        description: '',
        amount: '',
        withdrawAmt: '',
        depositAmt: '',
        depositFlag: '',
        editable: true,
        customError: false,
    }
}

export const DefaultRowDatas = {
    otherIncome: rowDataFields.otherIncomeRow,
    subsidyTypes: rowDataFields.subsidyTypesRow,
    expenseCategory: rowDataFields.expenseCategory,
    debtServices: rowDataFields.debtServices,
    aTransaction: rowDataFields.aTransaction,
    bTransaction: rowDataFields.bTransaction
}

export const DefaultStateDatas = {
    otherIncome: [],
    subsidyTypes: [],
    expenseCategory: [],
    debtServices: [],
    previousExpenseCategory: {
        reportYearData: { year: null }
    },
    aTransaction: [{...DefaultRowDatas.aTransaction, _id: 0}],
    bTransaction: [{...DefaultRowDatas.bTransaction, _id: 0}],
    performanceMeasure: {
        totalUnits: 0
    },
    transactionOperatingNotes: "",
    transactionReplacementNotes: "",
    negativeCashFlowNotes: ""
}

export const DEFAULT_CONFIG = {
    othersIncome: [],
    subsidyType: [],
    expenseCategory: [],
    lenders: []
};

export const ErrorNames = [
    {
        name: 'actualRentIncome',
        type: 'income',
        label: 'Income'
    },
    {
        name: 'otherIncome',
        type: 'income',
        label: 'Income'
    },
    {
        name: 'amount',
        type: 'income',
        label: 'Income'
    },
    {
        name: /comment_+\d+_otherIncome/,
        type: 'income',
        label: 'Income'
    },
    {
        name: /comment_+\d+_subsidytype/,
        type: 'income',
        label: 'Income'
    },
    {
        name: 'subsidyType',
        type: 'income',
        label: 'Income'
    },
    {
        name: 'operatingAmt',
        type: 'income',
        label: 'Income'
    },
    {
        name: 'servicesAmt',
        type: 'income',
        label: 'Income'
    },
    {
        name: 'commercialNetIncome',
        type: 'income',
        label: 'Income'
    },
    {
        name: 'householdSize-error-display',
        type: 'expense',
        label: 'Expense'
    },
    {
        name: 'oprAmt',
        type: 'expense',
        label: 'Expense'
    },
    {
        name: 'serAmt',
        type: 'expense',
        label: 'Expense'
    },
    {
        name: 'expComment',
        type: 'expense',
        label: 'Expense'
    },
    {
        name: 'comment',
        type: 'expense',
        label: 'Expense'
    },
    {
        name: 'lender',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'otherFunderComment',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'monthyLoanPayment',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'rate',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'amortizationYears',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'termYears',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'fundsPaid',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'negativeCashFlowNotes',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'table4Form',
        type: 'debtService',
        label: 'Debt Services Payment'
    },
    {
        name: 'transactionReplacementNotes',
        type: 'replacementReserve',
        label: 'Replacement Reserve'
    },
    {
        name: 'replacement_reserve_date',
        type: 'replacementReserve',
        label: 'Replacement Reserve'
    },
    {
        name: 'replacement_reserve_description',
        type: 'replacementReserve',
        label: 'Replacement Reserve'
    },
    {
        name: 'replacement_reserve_withdrawAmt',
        type: 'replacementReserve',
        label: 'Replacement Reserve'
    },
    {
        name: 'replacement_reserve_depositAmt',
        type: 'replacementReserve',
        label: 'Replacement Reserve'
    },
    {
        name: 'replacementReserveBeginningBalance',
        type: 'replacementReserve',
        label: 'Replacement Reserve'
    },
    {
        name: 'operatingReserveBeginningBalance',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'transactionOperatingNotes',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'operating_reserve_date',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'operating_reserve_description',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'operating_reserve_withdrawAmt',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'operating_reserve_depositAmt',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'withdrawAmt',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'operating_reserve_depositAmt',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'description',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'depositAmt',
        type: 'operatingReserve',
        label: 'Operating Reserve'
    },
    {
        name: 'notes',
        type: 'comments',
        label: 'Comments'
    }
]

export const WarningNames = [
    {
        title: 'NoReserveBalance',
        type: 'expense',
    },
    {
        title: 'ReserveBalanceNotEqual4B',
        type: 'operatingReserve',
    },
    {
        title: 'ReserveBalanceNotEqual4A',
        type: 'replacementReserve',
    }
]