import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import _ from 'underscore';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

// Import Components
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Import Redux Actions
import { userActions } from '../../actions/user';
import { confActions } from '../../actions/configuration';
import { alertActions } from '../../actions/alert';
import { searchActions } from '../../actions/search';
import { orgActions, helpActions } from '../../actions';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { globalVariable, history, getUserOrgId, getUserRole, filterSearchFields } from '../../helpers';

/**
 * StatusComponent Component
 * @class StatusComponent
 * @extends {Component}
 */
class StatusComponent extends Component {
    /**
     * Handle change for status component
     * @param {*} event 
     */
    handleChange = event => {
        const { data } = this.props;
        this.props.handleChange("isActive", { id: data._id, status: event.target.checked, totalProjects: data.totalProjects });
    };

    /**
     * Bind HTML
     */
    render() {
        const { data } = this.props;
        return (<Switch
            disabled={data.disabled}
            color="primary"
            size="small"
            checked={data.isActive}
            onChange={this.handleChange}
        />);
    }
}
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

const componentMap = {
    isActive: StatusComponent
}
/**
 * UsersList Component
 * @class UsersList
 * @extends {Component}
 */
class UsersList extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
            inActiveUserId: null,
            openHelp: false,
            isActiveInitialMessage:false,
            userData:null
        }
        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }
    /**
     * Component Did Mount Event
     */
    UNSAFE_componentWillMount() {
        const { page } = this.props.users;
        let ContactsTabRecordcount = history.location.pathname.includes('organizations');
        if(ContactsTabRecordcount){
        let getOrgId = parseInt(history.location.pathname.split("/")[2]);
        this.props.getById(getOrgId);
        }
        this.props.getHelp();
        // retain old search values
        if (page.skip) {
            this.props.getUsers(page.skip, ContactsTabRecordcount ? globalVariable.subPageTabtableRowSize : globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, false, this.props.orgId);
        } else {
            this.props.page === 'users' ?
                this.props.getUsers(1, globalVariable.tableRowSize, 'name', 'asc', '', '', false, this.props.orgId) :
                this.props.getUsers(1, globalVariable.subPageTabtableRowSize, 'name', 'asc', '', '', false, this.props.orgId)

        }
        setTimeout(() => {
            this.props.getFieldFilter(this.props.page === 'orgs' ? 'contacts' : "users");
        }, 100);
    }

    /**
     * Component Did update
     */
    componentDidUpdate() {
        //To clear the filters in the table
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { users, orgId, searchData: { sFields, sValues } } = this.props;
        this.props.getUsers(skip, limit, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
    };

    // set alert title for delete
    getDeleteTitle = (item) => {
        return <p>Are you sure you want to delete <b>User: {item.firstName} {item.lastName}</b>?</p>
    }
    /**
     * Handle Table Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { users, orgId, searchData: { sFields, sValues } } = this.props;
        this.props.getUsers(users.page.skip, users.page.limit, orderBy, order, sFields, sValues, false, orgId);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        this.setState({ reset: false });
        const { users, orgId, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getUsers(1, globalVariable.tableRowSize, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
    };

    onDropDownChangeRequest = (searchField, searchValue) =>{
        if(searchValue === undefined){
            searchValue = 1
        }
        let searchKey = searchValue===1?'Active':searchValue===0?'Disabled':'';
        this.setState({ reset: false });
        const { users, orgId, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getUsers(1, globalVariable.tableRowSize, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);

        //to avoid catch issue
        setTimeout(
            ()=>{
                this.props.searchActions({ sFields, sValues });
                this.props.getUsers(1, globalVariable.tableRowSize, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
            }
        ,500)
    }
    /**
     * Handle Add Action Navigates to new user page
     */
    handleAddActionClick = () => {
        history.push({
            pathname: `${this.props.match.url}/new`,
            state: {
                page: 'userNew',
                pageData: {}
            }
        });
    };
    /**
     * Handle Clear Fileter Action
     * 
     */
    handleClearFilterActionClick = () => {
        const { users, orgId } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getUsers(users.page.skip, users.page.limit, users.page.orderBy, users.page.order, [], [], false, orgId);
    };

    /**
     * Handle Field Filter Action Event
     * @memberof Users
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter(this.props.page === 'orgs' ? 'contacts' : "users", this.props.users.page.fieldFilters.visibleColumns);
    };
    /**
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter(this.props.page === 'orgs' ? 'contacts' : "users", this.props.users.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }

    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter(this.props.page === 'orgs' ? 'contacts' : "users", updatedColumns);
        } else {
            this.props.changeFieldFilter(this.props.page === 'orgs' ? 'contacts' : "users", [...this.props.visibleColumns, key]);
        }
    }
    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter(this.props.page === 'orgs' ? 'contacts' : "users", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { users, orgId, searchData: { sFields, sValues } } = this.props;
            this.props.getUsers(users.page.skip, users.page.limit, users.page.orderBy, users.page.order, sFields, sValues, false, orgId);
        });
    }
    /**
     * Handle Table Delete Action
     * @param {*} id 
     * @param {*} item 
     */
    handleTableDeleteAction = (id, item) => {
        if (item.totalProjects === 0) {
            let userName = this.props.users.data.filter(item=>item._id===id)[0].name
            // this.props.deleteUser(id,item, () => {
            this.props.deleteUser(id,`A User: ${userName} has been deleted successfully.`,() => {
                const { page } = this.props.users;
                // retain old search values
                if (page.skip) {
                    this.props.getUsers(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, false, this.props.orgId);
                } else {
                    this.props.getUsers(1, globalVariable.tableRowSize, 'name', 'asc', '', '', false, this.props.orgId);
                }
            })
        } else {
            this.props.alertError(`A <b>User: ${this.props.users.data.filter(item=>item._id===id)[0].name}</b> is associated with one or more active Projects and cannot be deleted. Please inactivate this contact, then choose different contact in the associated Project record(s). Then this user's contact record can be deleted`)
            // this.props.alertError(<p>A <b>User: {item.firstName} {item.lastName}</b> associated with a Project cannot be deleted – please inactive this contact record, then update the contact's name in the associated Project record.</p>)
        }
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     */
    handleNavigateAction = (id) => {
        const { orgId } = this.props;
        if (orgId) {
            history.push({
                pathname: `/users/${id}`,
                state: {
                    page: 'userEdit',
                    orgId,
                    pageData: {
                        user_id: parseInt(id, 10)
                    }
                }
            });
        } else {
            history.push({
                pathname: `/users/${id}`,
                state: {
                    page: 'userEdit',
                    pageData: {
                        user_id: parseInt(id, 10)
                    }
                }
            });
        }
    }
    activeComponentOnchange = (name,data) =>{
        let dataNew = data;
        dataNew['name'] = this.props.users.data.filter(item=>item._id===data.id)[0].name;
        switch (name) {
            case "isActive":
                if (!data.status) {
                    this.setState({isActiveInitialMessage:true,userData:dataNew})
                } else {
                    this._setUserActiveStatus(dataNew.id, dataNew.status);
                    this.setState({
                        isActiveInitialMessage:false
                    });
                }
                break;
            default:
            }
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        let dataNew = this.state.userData;
        
        dataNew['name'] = this.props.users.data.filter(item=>item._id===data.id)[0].name;
        switch (name) {
            case "isActive":
                if (dataNew.totalProjects > 0 && !dataNew.status) {
                    this.setState({
                        inActiveUserId: dataNew,
                        isActiveInitialMessage:false
                    });
                } else {
                    this._setUserActiveStatus(dataNew.id, dataNew.status);
                    this.setState({
                        isActiveInitialMessage:false
                    });
                }
                break;
            default:
        }
    }
    /**
     * Handles users active status
     * @param {*} id 
     * @param {*} status 
     */
    _setUserActiveStatus = (id, status) => {
        let userName = this.props.users&&this.props.users.data.filter(item=>item._id===id)[0].name;
        this.props.activeStatus(id, status,userName, () => {
            const { users, orgId, searchData: { sFields, sValues } } = this.props;
            console.log(orgId,history.location.pathname.split("/")[2])
            //let navPage = history.location.pathname.includes('contacts')?'contacts':'users'
            this.props.getUsers(users.page.skip, users.page.limit, users.page.orderBy, users.page.order, sFields, sValues,false, orgId);
        });
    }
    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
     * Bind Html to DOM 
     * 
     * [1]  -   Modified to display delete for Inactive
     */
    render() {
        const { fieldFilter, openHelp } = this.state;
        const { userRole, userOrgId } = this;
        let { users, columnsMap, visibleColumns, needPadding, classes, searchData: { sFields, sValues }, location: { pathname }, removeActions, pageAccess, isSubPage, helpData, orgData } = this.props;
        let fieldFilters = users.page.fieldFilters;
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        pageAccess = _.difference(pageAccess, removeActions);
        pageAccess = [...pageAccess, 'HELP']
        if (users && users.data && users.data.length > 0) {
            // delete icon visibility only for logged funder and non funder 
            users.data.forEach((elem, i) => {
                //Deleting users in contacts tab only for Super Funders
                if (getUserOrgId() === 1 && this.props.page === 'orgs' && users['data'][i].isActive === true) {
                    users['data'][i].allowDelete = true
                }
                else if (getUserOrgId() === 2 && this.props.page === 'orgs' && users['data'][i].isActive === true) {
                    users['data'][i].allowDelete = true
                }
                else if (users['data'][i].org_id.organizationType.indexOf("Funder") > -1) {
                    users['data'][i].allowDelete = false
                }
                else if (users['data'][i].org_id._id === getUserOrgId()) {
                    users['data'][i].allowDelete = true
                }
                // else if (getUserRole() === "funderWithOA") {
                //     users['data'][i].allowDelete = !users['data'][i].disabled
                // }
                else {
                    users['data'][i].allowDelete = false
                }
                // if (elem._id === 17040 && getUserRole() === "superFunder") {
                //     users['data'][i].disabled = true
                // }
                // if (elem._id === 17039 && getUserRole() === "superFunderWithHO") {
                //     users['data'][i].disabled = true
                // }
            })
        }
        let pageAccessForContacts = pageAccess;
        let orgActiveStatus = orgData&&orgData.isActive;
        if(!orgActiveStatus && history.location.pathname.includes('contacts')){
            pageAccessForContacts = pageAccess.filter(item=>item!=='ADD')
        }

        users.data.map(item=>{
            if(item['userStatus']==='Disabled'){
                item['userStatus']='Inactive'
            }
        })
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccessForContacts}
                title={!isSubPage ? "All Users" : ""}
                isSubPage={isSubPage}
                needPadding={needPadding}
                handleAddActionClick={this.handleAddActionClick}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
            >
                <TableComponent
                    page={users.page.skip}
                    rowsPerPage={users.page.limit}
                    count={users.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    actions={pageAccess}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    links={['name']}
                    order={users.page.order}
                    orderBy={users.page.orderBy}
                    data={users.data}
                    isLoading={users.isLoading}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    onDropDownChangeRequest={this.onDropDownChangeRequest}
                    getDeleteTitle={this.getDeleteTitle}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                    removedSortingRow={['Total # Associated Projects']}
                    currentPage={'users'}
                    moreInfoIcons={['ACTIVE', 'ROLE']}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.activeComponentOnchange}
                    prevNext={false}
                    paginationVisible={true}
                    disablePagination={users.page.lastPage}
                    columnsMap={columnsMap}
                    showLoading={isSubPage}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                <DeleteDialog
                    open={this.state.inActiveUserId !== null}
                    content={<div dangerouslySetInnerHTML={{ __html: `A <b>User: ${this.state.inActiveUserId&&this.state.inActiveUserId.name&&this.state.inActiveUserId.name}</b> is associated with one or more active Projects. Please choose different contact in the associated Project record(s).`}}></div>}
                    handleDeleteAction={() => {
                        const { id, status } = this.state.inActiveUserId;
                        this._setUserActiveStatus(id, status);
                        this.setState({ inActiveUserId: null,isActiveInitialMessage:false });
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ inActiveUserId: null,isActiveInitialMessage:false });
                    }}
                />
                <DeleteDialog
                    open={this.state.isActiveInitialMessage}
                    content={<div dangerouslySetInnerHTML={{ __html: `Are you sure you want to inactive the <b>User: ${this.state.userData&&this.state.userData.name&&this.state.userData.name}</b>?`}}></div>}
                    handleDeleteAction={() => {
                        this.fieldComponentOnChange("isActive",this.state.userData)
                        this.setState({isActiveInitialMessage:false})
                    }}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ isActiveInitialMessage: false });
                    }}
                />
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Users"
                />}
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
UsersList.propTypes = {
    classes: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};
/**
 * Default Props 
 */
UsersList.defaultProps = {
    onRef: () => { },
    needPadding: false,
    removeActions: ["UPDATE"],
    isSubPage: false
}
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 * @param {*} state 
 */
const getPageState = function (page, state) {
    switch (page) {
        case 'users': return state.users;
        case 'orgs': return state.organizations.contacts;
        default: break;
    }
}
/**
 * A method to map the actions from different pages for same containers
 * @param {*} page 
 */
const getPageAction = function (page) {
    switch (page) {
        case 'users': return userActions.getAll;
        case 'orgs': return orgActions.getContacts;
        default: break;
    }
}

/**
 * A method to map the search actions from different pages for same containers
 * @param {*} page 
 */

const getSearchAction = function (page) {
    switch (page) {
        case 'users': return searchActions.users;
        case 'orgs': return searchActions.contacts;
        default: break;
    }
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter[ownProps.orgId ? 'contacts' : "users"] || {};
    const users = getPageState(ownProps.page, state);
    const orgData = state.organizations.getOne.data;
    const pageAccess = state.pageAccess['usersList'] || [];
    const searchData = ownProps.page === 'users' ? state.search['users'] : state.search['contact'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    return {
        users,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess,
        searchData,
        helpData,
        orgData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @param {*} ownProps 
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        // You can now say this.props.createBook
        getUsers: (skip, limit, orderBy, order, sField, sValue, autoComplete, orgId) => {
            const actionToCall = getPageAction(ownProps.page);
            dispatch(actionToCall(skip, limit, orderBy, order, sField, sValue,'users', autoComplete, orgId))
        },
        getById: (id) => dispatch(orgActions.getById(id)),
        resetFilter: () => dispatch(userActions.resetFilter()),
        //deleteUser: (id,item, successCallback) => dispatch(userActions.delete(id,item, successCallback)),
        deleteUser: (id,userName, successCallback) => dispatch(userActions.delete(id,userName,successCallback)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        alertError: (text) => dispatch(alertActions.error(text)),
        alertSuccess: (text) =>dispatch(alertActions.success(text)),
        activeStatus: (id, isActive,userName, callBack) => dispatch(userActions.activeStatus(id, isActive,userName, callBack)),
        searchActions: (data) => {
            const searchToCall = getSearchAction(ownProps.page)
            dispatch(searchToCall(data))
        },
        getHelp: () => dispatch(helpActions.getAll({ _id: 5 })),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(UsersList));
