import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';


// Material UI
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Tab components
import General from './tabs/General';
import HouseHoldInfo from './tabs/HouseHoldInfo';
import HouseHoldMemberIncome from './tabs/HouseHoldMemberIncome';
import MonthlyRentData from './tabs/MonthlyRentData';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { history, getUserRole, getUserId, getUserOrgId } from '../../helpers';
import { occupantsActions } from '../../actions/occupants';

/**
 * OccupantsNew Container
 * @class OccupantsNew
 * @extends {Component}
 */
class OccupantsNew extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            unitDesignation_id: 1
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
    }
    /**
     * Change Unit Designation
     * @param {*} unitDesignation_id 
     */
    changeUnitDesignation = (unitDesignation_id) => {
        this.setState({
            unitDesignation_id
        });
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match, location: { state }} = this.props;
        const pData = state || { projectData: {} };
        switch (value) {
            case 0:
                history.replace({
                    pathname: `${match.url}`,
                    state: {
                        page: 'occgeneral',
                        pageData: {
                            reportYear_id: null,
                            table_id: null
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            case 1:
                history.replace({
                    pathname: `${match.url}/householdinfo`,
                    state: {
                        page: 'occhouseholdinfo',
                        pageData: {
                            reportYear_id: null,
                            table_id: null
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            case 2:
                history.replace({
                    pathname: `${match.url}/householdmemberincome`,
                    state: {
                        page: 'occhouseholdmemberincome',
                        pageData: {
                            reportYear_id: null,
                            table_id: null
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            case 3:
                history.replace({
                    pathname: `${match.url}/monthlyrentdata`,
                    state: {
                        page: 'occmonthlyrentdata',
                        pageData: {
                            reportYear_id: null,
                            table_id: null
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            default:
                break;
        }
    };
    /**
   * Handle Back Action Click
   */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, match, location: { pathname, state: pageParam }, title,
            isSubPage, needPadding, transferMessage } = this.props;
        let { pageAccess } = this.props;
        const currentTab = pathname.includes('/householdinfo') ? 1 : (pathname.includes('/sites') ? 2 : (pathname.includes('/householdmemberincome') ? 2 : (pathname.includes('/monthlyrentdata') ? 3 : 0)))
        const isReadOly = false;
        if (match.params.id) {
            pageAccess = [...pageAccess, 'BACK']
        }
        const pData = pageParam || {};
        const { reportYear_id } = pData.pageData || { reportYear_id: 0 };
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={[...pageAccess, 'BACK']}
                title={title}
                isSubPage={isSubPage}
                needPadding={needPadding}
                handleBackActionClick={this.handleBackActionClick}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        color="secondary"
                        variant="fullWidth"
                    >
                        <Tab label="GENERAL" value={0} disabled={true} />
                        <Tab label="HOUSEHOLD MEMBER INFO" value={1} disabled={true} />
                        <Tab label="HOUSEHOLD MEMBER INCOME" value={2} disabled={true} />
                        <Tab label="Set-Aside and Rent Data" value={3} disabled={true} />
                    </Tabs>
                </AppBar>
                <TabContainer spacing={3}>
                    {
                        transferMessage && (
                            <div className="textCenter">
                                <Chip
                                    label={transferMessage}
                                    className={classes.chip}
                                    color="secondary"
                                    style={{margin: "10px 8px"}}
                                />
                            </div>
                        )
                    }
                    <Route
                        exact
                        path={`${match.url}`}
                        render={(props) => {
                            return (
                                <General
                                    pageAccess={pageAccess}
                                    isReadOly={isReadOly}
                                    tableId={null}
                                    reportYear_id={reportYear_id}
                                    innerRef={(node) => { this.projectRef = node; }}
                                    changeUnitDesignation={this.changeUnitDesignation}
                                    handleNav={this.handleTabChange}
                                    vacantUnitData={props.location.state ? props.location.state.vacantUnitData : null}
                                    reportId={this.props.location.state?.projectData?.reportId}
                                    // reportId={this.props.location.state&&this.props.location.state.projectData.reportId}
                                />
                            )
                        }}
                    />
                    <Route
                        exact
                        path={`${match.url}/householdinfo`}
                        render={(props) => {
                            return (
                                <HouseHoldInfo
                                    pageAccess={pageAccess}
                                    isReadOly={isReadOly}
                                    tableId={null}
                                    reportYear_id={reportYear_id}
                                    innerRef={(node) => { this.projectRef = node; }}
                                    handleNav={this.handleTabChange}
                                />
                            )
                        }}
                    />
                    <Route
                        exact
                        path={`${match.url}/householdmemberincome`}
                        render={(props) => {
                            return (
                                <HouseHoldMemberIncome
                                    pageAccess={pageAccess}
                                    isReadOly={isReadOly}
                                    tableId={null}
                                    reportYear_id={reportYear_id}
                                    innerRef={(node) => { this.projectRef = node; }}
                                    handleNav={this.handleTabChange}
                                />
                            )
                        }}
                    />
                    <Route
                        exact
                        path={`${match.url}/monthlyrentdata`}
                        render={(props) => {
                            return (
                                <MonthlyRentData
                                    pageAccess={pageAccess}
                                    isReadOly={isReadOly}
                                    tableId={null}
                                    reportYear_id={reportYear_id}
                                    innerRef={(node) => { this.projectRef = node; }}
                                    handleNav={this.handleTabChange}
                                    reportId={this.props.location.state?.projectData?.reportId}
                                />
                            )
                        }}
                    />
                </TabContainer>
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
OccupantsNew.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool
};
/** Defaul props */
OccupantsNew.defaultProps = {
    title: "Occupants Details",
    classes: {},
    pageAccess: [],
    isSubPage: false,
    needPadding: false
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ occupants: { getOne } }, ownProps) => {
    let { data } = getOne;
    let transferMessage = null;
    let d = data || {};
    transferMessage = d.transferToMoveIn || null;

    return {
        sequenceTab: data ? data.sequenceTab : false,
        transferMessage
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOccupantsDetails: (id) => dispatch(occupantsActions.getOccupantsDetails(id))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(OccupantsNew));
