import { helpConstants } from '../constants/help';
// import { stat } from 'fs';

const initialState = {
    isLoading: false,
    data: [],
    getOne: {
        getData: [],
        isLoading: false,
    },
    getHelp:{
        getHelpData:{},
        isLoading:false
    }
}

export function help(state = initialState, action) {
    switch (action.type) {
        case helpConstants.ADD_HELP_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case helpConstants.ADD_HELP_SUCCESS:
            return {
                ...state,
                // data: action.help.data,
                isLoading: false
            };
        case helpConstants.CLEAR_HELP_DATA:
            return{
                ...state,
                getHelp:{
                    getHelpData:{}
                }
            };
        case helpConstants.ADD_HELP_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case helpConstants.GETALL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case helpConstants.GETALL_SUCCESS:
            return {
                ...state,
                data: action.help.data,
                isLoading: false
            };
        case helpConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case helpConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    getData: [],
                    isLoading: true
                },
            };
        case helpConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    getData: action.help.data,
                    isLoading: false
                },
            };
        case helpConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case helpConstants.DELETE_HELP_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case helpConstants.DELETE_HELP_SUCCESS:
            return {
                ...state,
                data: state.data.filter((help) => {
                    return help.helpTopics.filter(r =>
                        r._id !== action.id)
                }),
                // data :state.data.filter((help) =>{
                //     return help.id!==action.id
                // }),
                isLoading: false
            };
        case helpConstants.DELETE_HELP_FAILURE:
            return {
                ...state.page,
                error: action.error,
                isLoading: false
            };
        case helpConstants.GETONE_HELP_REQUEST:
            return {
                ...state,
                getHelp: {
                    ...state.getHelp,
                },
                isLoading: true
            };
        case helpConstants.GETONE_HELP_SUCCESS:
            return {
                ...state,
                getHelp: {
                    ...state.getHelp,
                    getHelpData: action.help.data
                },
                isLoading: false
            };
        case helpConstants.GETONE_HELP_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case helpConstants.VIEW_HELPFILE_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case helpConstants.VIEW_HELPFILE_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case helpConstants.VIEW_HELPFILE_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}