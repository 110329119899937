import React, { Component } from 'react';
import PropTypes from "prop-types";

// DND
import { arrayMoveImmutable } from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

// Material UI
import Grid from "@material-ui/core/Grid";
import { TextField } from '@material-ui/core';

// Components
import SaveCancel from '../SaveCancel/SaveCancel';
/**
  * Sortable Item
  */
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});
/**
 * Drage Handle
 */
const DragHandle = SortableHandle(() => <i className="fa fa-th drag-icon p-r-24" aria-hidden="true" alt={"Menu"} draggable={false}></i>);
/**
* Sortable List
*/
const SortableList = SortableContainer(props => {
    const { items, handleInputChange } = props;
    /**
     * Handle Change
     * @param {*} event 
     */
    const handleChange = (event) => {
        handleInputChange(event.target.name, event.target.value)
    }
    return (
        <Grid container spacing={2}>
            {
                items && items.length > 0 &&
                items.map((elem, index) => (
                    <SortableItem
                        key={`sort-item-${index}`}
                        index={index}
                    >
                        <Grid item sm={3} className="displayFlex alignItemsCenter" style={{ padding: 12, zIndex: 99999 }}
                            index={index}
                            key={index}>
                            <DragHandle
                                index={index}
                                key={index} />
                            <TextField
                                name={`${index}`}
                                value={elem.header}
                                onChange={handleChange}
                                margin="dense"
                                disabled={false}
                            />
                        </Grid>

                    </SortableItem>
                ))
            }
        </Grid>
    );
});
/**
 * DragandDrop For All Directions component
 * @class DragandDropInAllDirection
 * @extends {Component}
 */
class DragandDropInAllDirection extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        //declare state
        this.state = {
            items: props.items,
            isColumnModified:false
        };
    }
    /**
     *component will recieve props
     *
     * @param {*} nexProps
     * @memberof DragandDrop
     */
    UNSAFE_componentWillReceiveProps(nexProps) {
        const { items } = nexProps;
        this.setState({
            items: items
        });
    }
    /**
     * Handle Sort End Event
     * @param {*} param0 
     */
    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({ items }) => ({
            items: arrayMoveImmutable(items, oldIndex, newIndex),isColumnModified : true
        }));
    };
    /**
     * Handle Input Change
     * @param {*} name 
     * @param {*} value 
     */
    handleInputChange = (name, value) => {
        const { items } = this.state;
        items[+name].header = value;
        this.setState({ items: items });
    }
    /**
     * Handle Change
     */
    handleSave = () => {
        const { items } = this.state
        this.props.handleSave(items)
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { items } = this.state;
        return (
            <Grid item sm={12}>
                <Grid container spacing={3} className="pl-5">
                    <Grid item sm={12}>
                        <SortableList
                            useDragHandle
                            axis="xy"
                            enableUserSelectHack={false}
                            items={items}
                            onSortEnd={this.onSortEnd}
                            handleInputChange={this.handleInputChange}
                        ></SortableList>
                    </Grid>
                    <SaveCancel
                        handleSaveButtonClick={() => { this.props.handleSave(items,this.state.isColumnModified) }}
                        handleCancelButtonClick={() => { this.props.handleCancel() }}
                    />
                </Grid>
            </Grid>
        );
    }
}
/**
 * Bind Component Property Types
 */
DragandDropInAllDirection.defaultProps = {
    items: [],
    classes: {},
    handleSave: () => { },
};
/*Default Props */
DragandDropInAllDirection.propTypes = {
    items: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    // noDataErrorMessage: PropTypes.string.isRequired,
};
//Export Component
export default DragandDropInAllDirection;
