//import constants
import { orgConstants } from '../constants';

//import servives
import { orgService, userService } from '../services';

//import actions
import { alertActions } from './';

//import helper
import { history } from '../helpers';

export const orgActions = {
    getAll,
    getContacts,
    getById,
    resetFilter,
    getOrganizations,
    addNewOrg,
    updateOrg,
    delete: _delete,
    setActiveStatus
};

function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: orgConstants.CLEAR_FILTER_SET  } }
    function reset() { return { type: orgConstants.CLEAR_FILTER_RESET } }
}

/**
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 */
function getAll(skip, limit, orderBy, order, sFields, sValues) {
    return dispatch => {
        dispatch(request());

        orgService.getAll(skip, limit, orderBy, order, sFields, sValues)
            .then(
                orgs => {
                    dispatch(success(orgs));
                    const { page } = orgs;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: orgConstants.GETALL_REQUEST } }
    function success(orgs) { return { type: orgConstants.GETALL_SUCCESS, orgs } }
    function failure(error) { return { type: orgConstants.GETALL_FAILURE, error } }
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function getById(id) {
    return dispatch => {
        dispatch(request());

        orgService.getById(id)
            .then(
                org => {
                    dispatch(success(org))
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: orgConstants.GETONE_REQUEST } }
    function success(org) { return { type: orgConstants.GETONE_SUCCESS, org } }
    function failure(error) { return { type: orgConstants.GETONE_FAILURE, error } }
}

/**
 * Add new organization
 * 
 * @param {*} org 
 */
function addNewOrg(org) {
    return dispatch => {
        dispatch(request(org));

        orgService.addNewOrg(org)
            .then(
                org => {
                    dispatch(success());
                    dispatch(alertActions.success('Organization created successfully'));
                    setTimeout(() => {
                        history.push('/organizations');
                    }, 800);
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(org) { return { type: orgConstants.ADD_ORGANIZATIONS_REQUEST, org } }
    function success(org) { return { type: orgConstants.ADD_ORGANIZATIONS_SUCCESS, org } }
    function failure(error) { return { type: orgConstants.ADD_ORGANIZATIONS_FAILURE, error } }
}

/**
 * Update organization data 
 * 
 * @param {*} orgData - organization Data
 * @param {*} orgId - organization id
 */
function updateOrg(orgData, orgId) {
    return dispatch => {
        dispatch(request(orgData));

        orgService.update(orgData, orgId)
            .then(
                org => {
                    dispatch(success(org));
                    // history.push('/organizations');
                    dispatch(alertActions.success('Updated successfully'));
                   
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(org) { return { type: orgConstants.UPDATE_ORGANIZATIONS_REQUEST, org } }
    function success(org) { return { type: orgConstants.UPDATE_ORGANIZATIONS_SUCCESS, org } }
    function failure(error) { return { type: orgConstants.UPDATE_ORGANIZATIONS_FAILURE, error } }
}

/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
function getOrganizations(text, page) {
    return dispatch => {
        dispatch(request());

        orgService.getOrganizationsBySearchKey(text, page)
            .then(
                org => dispatch(success(org)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: orgConstants.GETALL_ORGANIZATION_REQUEST } }
    function success(org) { return { type: orgConstants.GETALL_ORGANIZATION_SUCCESS, org } }
    function failure(error) { return { type: orgConstants.GETALL_ORGANIZATION_FAILURE, error } }
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id 
 */
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        orgService.delete(id)
            .then(
                org =>{
                     dispatch(success(id));
                     dispatch(alertActions.success('Deleted successfully'));
                     setTimeout(() => {
                        dispatch(alertActions.clear());
                     }, 800);
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: orgConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: orgConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: orgConstants.DELETE_FAILURE, id, error } }
}

/**
 * A method to activate / deactivate the org
 *
 * @param {*} id contains org id
 * @param {*} status contains boolean value of org to update. e-g true - active, false - deactive
 * @param {*} callBack contains a method to call back of successfull response
 * @returns
 */
function setActiveStatus(id, status, callBack) {
    return dispatch => {
        dispatch(request(id));

        orgService.setActivateStatus(id, status)
            .then(
                org =>{
                     dispatch(success(id, org));
                     dispatch(alertActions.success('Updated successfully'));
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: orgConstants.ACTIVE_UPDATE_REQUEST, id } }
    function success(id,org) { return { type: orgConstants.ACTIVE_UPDATE_SUCCESS, id, org } }
    function failure(id, error) { return { type: orgConstants.ACTIVE_UPDATE_FAILURE, id, error } }
}

/**
 * A method to get users list for the particular organazations
 *
 * @param {*} orgId contains the organization's id to retrive the contact list
 */
function getContacts(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, orgId = null) {
    return dispatch => {
        dispatch(request());

        userService.getAll(skip, limit, orderBy, order, sfields, sValues,'contacts', isAutoComplete, history.location.pathname.split("/")[2])
            .then(
                contacts =>{
                     dispatch(success(contacts));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: orgConstants.GET_ORG_CONTACT_REQUEST } }
    function success(contacts) { return { type: orgConstants.GET_ORG_CONTACT_SUCCESS, contacts } }
    function failure(error) { return { type: orgConstants.GET_ORG_CONTACT_FAILURE, error } }
}