//import helper
import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

export const confService = {
    getFieldFilter,
    putFieldFilter,
    getByName,
    updatedata,
    getDataByName,
    delete: _delete,
    updateReport,
    getFunderReportTable,
    getBanner,
    getMoveOutReasonEffectiveYear,
    updateMoveOutReasonEffectiveYear
};

/**
 *Get Field Filter data
 *
 * @param {*} table
 * @returns
 */
function getFieldFilter(table,year = 0) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${globalVariable.apiURL}/config/field/filter/${table}/${year}`, requestOptions)
        .then(handleResponse)
        .then(fieldFilter => {
            return fieldFilter;
        });
}

/**
 *Change field filter 
 *
 * @param {*} table
 * @param {*} visibleColumns
 * @returns
 */
function putFieldFilter(table, visibleColumns) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ table, visibleColumns })
    };
    return fetch(`${globalVariable.apiURL}/config/field/filter/${table}`, requestOptions)
        .then(handleResponse)
        .then(fieldFilter => {
            return fieldFilter;
        });
}

/**
 *Get Each Configuration data
 *
 * @param {*} type - configuration type
 * @returns
 */
function getByName(config_names) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ config_names })
    };
    return fetch(`${globalVariable.apiURL}/config/fields`, requestOptions).then(handleResponse);
}

/**
 *Updating Configuration data
 *
 * @param {*} type - configuration type
 * @param {*} values - configuration data
 * @returns
 */
function updatedata(type, values) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
    };
    return fetch(`${globalVariable.apiURL}/config/fields/${type}?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleResponse);
}

    /**
     *Get config data
    *
    * @param {*} type
    * @returns
    */
    function getDataByName(type) {
        const requestOptions = {
            method: 'GET',
            headers: { ...authHeader(), 'Content-Type': 'application/json' }
        };
        return fetch(`${globalVariable.apiURL}/config/fields/${type}?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleResponse);
    }

/**
 *Delete one value of selected configuration
 *
 * @param {*} type - config type
 * @param {*} id - row index
 * @returns
 */
function _delete(type, id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id, type: type })
    };

    return fetch(`${globalVariable.apiURL}/configuration?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleResponse);
}

/**
 *Get Default reporting forms data
 *
 * @param {*} org_id - organization id
 * @returns
 */
function getFunderReportTable(orgId) {
    if (orgId) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${globalVariable.apiURL}/funderreporttable/${orgId}?timeZone=${momentTZ.tz.guess(true)}`, requestOptions).then(handleResponse);
    }
}

/**
 *Update default reporting forms
 *
 * @param {*} report - report data
 * @param {*} org_id - organization id
 * @returns
 */
function updateReport(report, org_id) {

    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(report)
    };
    return fetch(`${globalVariable.apiURL}/funderreporttable/update/${org_id}`, requestOptions).then(handleResponse);
}


/**
*Get Current Banner
*
* @param {*} 
* @returns
*/
function getBanner() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${globalVariable.apiURL}/config/latest-banner`, requestOptions).then(handleResponse);
}

/**
*Get Move Out Reason Effective year
*
* @param {*} 
* @returns
*/
function getMoveOutReasonEffectiveYear() {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };
    return fetch(`${globalVariable.apiURL}/config/moveoutreason`, requestOptions).then(handleResponse);
}

/**
 *Update move out reason effective year
 *
 * @param {*} year - effective year
 * @returns
 */
function updateMoveOutReasonEffectiveYear(year) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(year)
    };
    return fetch(`${globalVariable.apiURL}/config/moveoutreason`, requestOptions).then(handleResponse);
}