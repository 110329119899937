//import constants
import { maintenanceConstants } from '../constants';

//import servives
import { maintenanceService } from '../services';

//import actions
import { alertActions } from './';

//import helper
import { history } from '../helpers';

// File saver
import { saveAs } from 'file-saver';

export const maintenanceActions = {
    getMaintenanceStatus,
    saveMaintenanceStatus,
    importLimitFiles,
    startCron,
    goLive,
    verifyData,
    updateMaintenanceStatus,
    downloadTemplate,
    listLogs,
    reUpload,
    getCronStatus,
    restoreAndGoLive
};

function getMaintenanceStatus() {
    return dispatch => {
        dispatch(request());
        maintenanceService.getMaintenanceStatus()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.GET_ONE_REQUEST } }
    function success(data) { return { type: maintenanceConstants.GET_ONE_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.GET_ONE_FAILURE, error } }
}

function reUpload() {
    return dispatch => {
        dispatch(request());
        maintenanceService.reUpload()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.REUPLOAD_REQUEST } }
    function success(data) { return { type: maintenanceConstants.REUPLOAD_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.REUPLOAD_FAILURE, error } }
}

function saveMaintenanceStatus(data) {
    return dispatch => {
        dispatch(request());
        maintenanceService.saveMaintenanceStatus(data)
            .then(
                data => {
                    dispatch(success(data))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.ADD_MAINTENANCE_REQUEST } }
    function success(data) { return { type: maintenanceConstants.ADD_MAINTENANCE_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.ADD_MAINTENANCE_FAILURE, error } }
}

function importLimitFiles(data) {
    return dispatch => {
        dispatch(request());
        maintenanceService.importLimitFiles(data)
            .then(
                data => {
                    dispatch(success(data))
                },
                error => {
                    if (error === 'Internal server error!') {
                        dispatch(alertActions.error(error))
                    } else {
                        dispatch(failure(error.toString()))
                    }
                }
            );
    };

    function request() { return { type: maintenanceConstants.IMPORT_FILES_REQUEST } }
    function success(data) { return { type: maintenanceConstants.IMPORT_FILES_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.IMPORT_FILES_FAILURE, error } }
}

function startCron() {
    return dispatch => {
        dispatch(request());
        maintenanceService.startCron()
            .then(
                data => {
                    dispatch(success(data))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.GET_ONE_REQUEST } }
    function success(data) { return { type: maintenanceConstants.GET_ONE_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.GET_ONE_FAILURE, error } }
}

function goLive() {
    return dispatch => {
        dispatch(request());
        maintenanceService.goLive()
            .then(
                data => {
                    history.replace('/configurations/incomerent')
                    dispatch(success(data))
                },
                error => dispatch(failure(error.toString()))
            );
        function request() { return { type: maintenanceConstants.GET_ONE_REQUEST } }
        function success(data) { return { type: maintenanceConstants.GET_ONE_SUCCESS, data } }
        function failure(error) { return { type: maintenanceConstants.GET_ONE_FAILURE, error } }
    }
}

function restoreAndGoLive() {
    return dispatch => {
        dispatch(request());
        maintenanceService.restoreAndGoLive()
            .then(
                data => {
                    // history.replace('/configurations/incomerent')
                    dispatch(success(data))
                },
                error => dispatch(failure(error.toString()))
            );
        function request() { return { type: maintenanceConstants.GET_ONE_REQUEST } }
        function success(data) { return { type: maintenanceConstants.GET_ONE_SUCCESS, data } }
        function failure(error) { return { type: maintenanceConstants.GET_ONE_FAILURE, error } }
    }
}

function verifyData(data, limitType, skip = 0, limit = 10, orderBy = 'county', order = 'asc') {
    return dispatch => {
        dispatch(request());
        maintenanceService.verifyData(data, limitType, skip, limit, orderBy, order)
            .then(
                data => {
                    dispatch(success(data))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.VERIFY_DATA_MAINTENANCE_REQUEST } }
    function success(data) { return { type: maintenanceConstants.VERIFY_DATA_MAINTENANCE_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.VERIFY_DATA_MAINTENANCE_FAILURE, error } }
}


// from socket
function updateMaintenanceStatus(data) {
    return { type: maintenanceConstants.GET_ONE_SUCCESS, data: { data } }
}

/**
 * Download Template
 * 
 * @param {*} reportTable - table number 
 * @param {*} data - table data
 */
function downloadTemplate(id, limitType) {
    return dispatch => {
        dispatch(request());
        dispatch(alertActions.success('Please wait...'));
        setTimeout(() => {
            dispatch(alertActions.clear());
        }, 3000)
        maintenanceService.downloadTemplate(id, limitType)
            .then(res => res.blob())
            .then(
                blob => {
                    saveAs(blob, id ? `${limitType}.xlsx` : `Template_${limitType}.xlsx`);
                    dispatch(success());
                    dispatch(alertActions.success('Successfully downloaded'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(reportTable) { return { type: maintenanceConstants.DOWNLOAD_TEMPLATE_REQUEST, reportTable } }
    function success(reportTable, data) { return { type: maintenanceConstants.DOWNLOAD_TEMPLATE_SUCCESS, data, reportTable } }
    function failure(reportTable, error) { return { type: maintenanceConstants.DOWNLOAD_TEMPLATE_FAILURE, error, reportTable } }
}

function listLogs(data, skip = 0, limit = 10, orderBy = 'county', order = 'asc') {
    return dispatch => {
        dispatch(request());
        maintenanceService.listLogs(data, skip, limit, orderBy, order)
            .then(
                data => {
                    dispatch(success(data))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.GET_LIST_LOGS_REQUEST } }
    function success(data) { return { type: maintenanceConstants.GET_LIST_LOGS_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.GET_LIST_LOGS_FAILURE, error } }
}

function getCronStatus(type = '') {
    return dispatch => {
        dispatch(request());
        maintenanceService.getCronStatus(type)
            .then(res => type !== 'view' ? res.blob() : res)
            .then(
                data => {
                    if (type !== 'view') {
                        saveAs(data, `Failed_Projects.xlsx`);
                        dispatch(success({}))
                        dispatch(alertActions.success('Successfully downloaded'));
                        dispatch(alertActions.clear());
                    } else {
                        dispatch(success(data))
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: maintenanceConstants.GET_CRON_STATUS_REQUEST } }
    function success(data) { return { type: maintenanceConstants.GET_CRON_STATUS_SUCCESS, data } }
    function failure(error) { return { type: maintenanceConstants.GET_CRON_STATUS_FAILURE, error } }
}
