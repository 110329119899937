import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import UABulkUpload from "./UABulkUpload";
import UAView from "./UAView";
/**
 * UARouter Component
 * @class UARouter
 * @extends {Component}
 */
class UARouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match } = this.props;
        return (
            <Switch >
                <Route exact path={`${match.url}/bulk-upload`} component={UABulkUpload} />
                <Route exact path={`${match.url}/:buildingId`} render={(props) => {
                    return <UAView {...props} page={'ua'} />
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default UARouter;
