import React, { Component } from 'react';
import { connect } from 'react-redux';

// Material UI
import { withStyles } from '@material-ui/core';

//Assets
import pageStyle from '../../../assets/jss/containers/common';

//import components
import TableComponent from '../../../components/Table/TableComponent';
import HelpFloater from '../../../components/HelpFloater/HelpFloater';
import ContentWrapper from '../../../components/ContentWrapper/ContentWrapper';

// helpers
import { globalVariable, filterSearchFields, history } from '../../../helpers';
import { getConfigurationContentHeight } from '../../../helpers/global';

// Actions
import { searchActions } from '../../../actions/search';
import { maintenanceActions } from '../../../actions/maintenance';
import { helpActions } from '../../../actions';

/**
 * Files Component
 * @class FilesComponent
 * @extends {Component}
 */
class FilesComponent extends Component {
    render() {
        const { data } = this.props;
        return (
            data.files && data.files.length > 0 ? (data.files || []).map(file => {
                return (
                    <div className="files-list">
                        {
                            // eslint-disable-next-line
                            <a
                            className="filesListATag"
                                onClick={() => this.props.handleChange('files', file, data)}
                            >
                                {file}
                            </a>
                        }
                        <br />
                    </div>
                )
            })
                :
                <div className="custom-label" style={{ padding: 'unset' }}>No Files available</div>
        )
    }
}

const componentMap = {
    "files": FilesComponent
}

/**
 * Logs Container
 * @class Logs
 * @extends {Component}
 */
class Logs extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            alertPopup: {
                open: false,
            },
            bannerText: '',
            maintenanceDate: new Date(),
            openHelp: false
        }
    }
    /**
     * Component Did mount
     */
    componentDidMount() {
        const { location: { pathname } } = this.props;
        this.props.getHelp();
        this.getList({
            sFields: [],
            sValues: []
        }, 1, pathname.includes('configurations') ? Math.ceil((getConfigurationContentHeight() - 300) / 30) - 1 : globalVariable.tableRowSize - 2, 'county', 'asc')
    }
    /**
     * Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Component Will unmount
     */
    componentWillUnmount() {
        this.props.searchActions({ sFields: [], sValues: [] });
    }
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const { listLogs } = this.props;
        const { page } = listLogs;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.getList({
            sFields: [],
            sValues: []
        }, page.skip, page.limit, page.orderBy, page.order);
    };
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        this.props.handleTabNavigation();
    }
    /**
     * Handle Get logs list
     * @param {*} data 
     * @param {*} skip 
     * @param {*} limit 
     * @param {*} orderBy 
     * @param {*} order 
     */
    getList = (data, skip, limit, orderBy, order) => {
        this.props.getList({
            ...data,
            fields: data.sFields,
            values: data.sValues
        }, skip, limit, orderBy, order);
    }
    /**
     * Handle Field component change Click Action
     * @param {*} name 
     * @param {*} val 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, val, data) => {
        switch (name) {
            case 'files':
                this.props.downloadTemplates(data._id, val);
                break;
            default: break;
        }
    }
    /**
    * Handle Help Click Action
    */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * Handle Table Pagination On Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { listLogs, searchData } = this.props;
        const { page } = listLogs;
        this.getList(searchData, skip, limit, page.orderBy, page.order);
    };
    /**
     * Handle Table Sort Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { listLogs, searchData } = this.props;
        const { page } = listLogs;
        this.getList(searchData, page.skip, page.limit, orderBy, order);
    };
    /**
     * Handle Table Search Input Box Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { listLogs, searchData } = this.props;
        const { page } = listLogs;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.getList({ sFields, sValues }, page.skip, page.limit, page.orderBy, page.order);
    }
    /**
     *render HTML
     */
    render() {
        const { classes, listLogs, searchData, helpData, needTitleBar, ischildPage, location: { pathname }, isConfigurationPage, isSubPage, isConfigurationPageTab } = this.props;
        const { openHelp } = this.state;
        const { page: { fieldFilters } } = listLogs;
        const { sFields, sValues } = searchData;

        return (
            <React.Fragment>
                <ContentWrapper
                    classes={classes}
                    title="Logs"
                    needPadding={false}
                    ischildPage={ischildPage}
                    needTitleBar={needTitleBar}
                    isSubPage={isSubPage}
                    isConfigurationPage={isConfigurationPage}
                    isConfigurationPageTab={isConfigurationPageTab}
                    pageAccess={["CLEAR", "HELP"]}
                    downloadColor="secondary"
                    handleClearFilterActionClick={() => {
                        history.replace(`${pathname}#clear`);
                    }}
                    handleBackActionClick={() => { history.goBack() }}
                    handleHelpActionClick={this.handleHelpActionClick}
                >
                    <TableComponent
                        className="logs_Table m_table"
                        page={listLogs.page.skip}
                        rowsPerPage={listLogs.page.limit}
                        count={listLogs.page.count}
                        header={fieldFilters.headerCols}
                        field={fieldFilters.fieldCols}
                        search={fieldFilters.searchCols}
                        order={listLogs.page.order}
                        orderBy={listLogs.page.orderBy}
                        data={listLogs.data}
                        componentMap={componentMap}
                        fieldComponentOnChange={this.fieldComponentOnChange}
                        onChangePageTable={this.onChangePageTable}
                        onSortRequest={this.onSortRequest}
                        onInputChangeRequest={this.onInputChangeRequest}
                        sFields={filterSearchFields(sFields)}
                        sValues={sValues}
                        paginationVisible={true}
                        isLoading={false}
                        noDataErrorMessage='No Records Found'
                    />
                </ContentWrapper>
                { openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Income and Rent limit"
                />}
            </React.Fragment>
        );
    }
}
// prop types
Logs.propTypes = {
};
/**
 * Default Props
 */
Logs.defaultProps = {
    needTitleBar: true,
    ischildPage: false,
    isConfigurationPage: false,
    isConfigurationPageTab: false,
    isSubPage: false
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @returns 
 */
const mapStateToProps = ({ maintenance: { listLogs }, search: { maintenance }, help }) => {
    const helpData = help.data[0]
    return {
        listLogs: { ...listLogs },
        searchData: { ...maintenance },
        helpData
    }
};
/**
 *  Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        searchActions: (data) => {
            return dispatch(searchActions.maintenanceSearch(data));
        },
        getList: (data, skip, limit, orderBy, order) => dispatch(maintenanceActions.listLogs(data, skip, limit, orderBy, order)),
        downloadTemplates: (id, limitType) => dispatch(maintenanceActions.downloadTemplate(id, limitType)),
        getHelp: () => dispatch(helpActions.getAll({ _id: null }))
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(Logs));