import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from "d3";

// Import Components
import ResponsiveWrapper from '../ResponsiveWrapper';

/**
 *Donut Chart Component
 *
 * @class DonutChart
 * @extends {Component}
 */
class DonutChart extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { data } = this.props
        this.setState({
            data: data
        }, () => {
            this.donutChart();
        })
    }
    /**
     * Component Will Recieve Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        let { data } = nextProps;
        this.setState({
            data: data
        }, () => {
            this.donutChart();
        })
    }
    /**
     * Donut Chart Function
     */
    donutChart = () => {
        const { index, occupiedUnits, totalUnits, handleClickAction, isCurrentYear, chartType } = this.props
        let { data } = this.state;
        let colorSet = [];
        switch (index) {
            case 0:
                colorSet = ['#FAC962', '#F4F4F4'];
                break;
            case 1:
                colorSet = ['#678BFE', '#F4F4F4'];
                break;
            case 2:
                colorSet = ['#6EE4D8', '#F4F4F4'];
                break;
            case 3:
                colorSet = ['#EF9FB7', '#F4F4F4'];
                break;

            default:
                colorSet = ['#6EE4D8', '#F4F4F4'];
                // colorSet = ['#14B0F3', '#FDAB00'];
                break;
        }
        const svgDimensions = {
            //selects the maximum width from the two parameters passed
            width: Math.max(this.props.parentWidth, !(index || index === 0) ? 240 : 100),
            height: !(index || index === 0) ? 200 : 80,
            thickness: !(index || index === 0) ? 25 : 12
        };

        let radius = Math.min(svgDimensions.width, svgDimensions.height) / 2;
        let color = d3.scaleOrdinal().range(colorSet);

        d3.select(`#donutChart_${index}`).selectAll("*").remove();

        var divTooltip = d3.select(".toolTip")

        let svg = d3.select(`#donutChart_${index}`)
            .append('svg')
            .attr('class', 'pie')
            .attr('width', svgDimensions.width)
            .attr('height', svgDimensions.height);

        let g = svg.append('g')
            .attr('transform', 'translate(' + (svgDimensions.width / 2) + ',' + (svgDimensions.height / 2) + ')');
        if (data && data.length > 0 && data[0].value != null) {
            let text = "";
            let arc = d3.arc()
                .innerRadius(radius - svgDimensions.thickness)
                .outerRadius(radius);

            let pie = d3.pie()
                .value(function (d) { return d.value; })
                .sort(null);

            (index || index === 0) && g.append("text")
                .attr("class", "main-value-text")
                .text(`${data[0].value + " %"}`)
                .attr('text-anchor', 'middle')
                .attr('dy', '.3em')
                .style('font-weight', '600')
                .style('font-size', '14px')

            !(index || index === 0) && g.append("text")
                .attr("class", "main-value-text")
                .text(`${data[0].value + " %"}`)
                .attr('text-anchor', 'middle')
                .attr('dy', '.3em')
                .style('font-weight', '600')
                .style('font-size', '18px')

            g.selectAll('path')
                .data(pie(data))
                .enter()
                .append("g")
                .append('path')
                .attr('d', arc)
                .attr('fill', (d, i) => color(i))
                .on("mouseover", function (d, i) {
                    if (!index && index !== 0) {
                        d3.select(this)
                            .style("cursor", "alias")
                            .style("fill", "#666666");
                        isCurrentYear && d3.select(this)
                            .style("cursor", "pointer")
                        divTooltip.style("z-index", '99999');
                        divTooltip.style("left", d3.event.pageX - 35 + "px");
                        divTooltip.style("top", d3.event.pageY - 50 + "px");
                        divTooltip.style("display", "inline-block");
                        if (d.data.name === "Occupancy Rate") {
                            divTooltip.html(`Occupied Units : <b>${occupiedUnits}</b>`);
                        } else if (d.data.name === "Vacancy Rate") {
                            divTooltip.html(`Vacant Units : <b>${+totalUnits - +occupiedUnits}</b>`);
                        }else if(chartType==="loanStatus"){
                            divTooltip.html(`${d.data.name} : ${d.data.count}`);
                        } else {
                            divTooltip.html(`${d.data.name} : ${d.data.value}`);
                        }
                    }
                })
                .on("mouseout", function (d) {
                    if (!index && index !== 0) {
                        d3.select(this)
                            .style("cursor", "none")
                            .style("fill", color(this._current));
                        divTooltip.style("display", "none");
                    }
                })
                .on('click', function (d) {
                    if (!index && index !== 0 && isCurrentYear) {
                        handleClickAction(d);
                        d3.select(this)
                            .style("cursor", "none")
                            .style("fill", color(this._current));
                        divTooltip.style("display", "none");
                    }
                })
                .each(function (d, i) { this._current = i; });

            g.append('text')
                .attr('text-anchor', 'middle')
                .attr('dy', '.35em')
                .text(text);

        } else {
            let text = "No Records Found";
            g.append('text')
                .style('font-size', '14px')
                .attr('text-anchor', 'middle')
                .attr('dy', '.35em')
                .text(text);
        }
    }
    /**
     * render HTML
     */
    render() {
        const { index } = this.props
        return (
            <div id={`donutChart_${index}`}></div>
        );
    }
}

//Prop Types
DonutChart.propTypes = {
    data: PropTypes.array.isRequired
};

//Default Props
DonutChart.defaultProps = {
    data: [],
    index: null,
    isCurrentYear: false,
    handleClickAction: () => { },
    chartType:""
}

/**Export Component */
export default ResponsiveWrapper(DonutChart);