import { getFloat } from "../";
import { Table3Messages } from "./messages";

// const setAsideMap = {
//     16: 2,
//     13: 57
// }

const Table3Validations = (data) => {
    const errors = [];
    let {desc} = data;
    let { table2 } = data;
    table2 = table2 || {};

    // error - 1
    let { TotalRestrictedUnitsIndividualHouseholdsServed, totalRestrictedUnitsFamilyHouseholdsServed, totalIndividualHouseholdsServed, totalFamilyHouseholdsServed } = table2;
    let { totalSpecialNeedsUnits } = data;

    TotalRestrictedUnitsIndividualHouseholdsServed = (!TotalRestrictedUnitsIndividualHouseholdsServed || TotalRestrictedUnitsIndividualHouseholdsServed===undefined)?0:TotalRestrictedUnitsIndividualHouseholdsServed;
    totalRestrictedUnitsFamilyHouseholdsServed = (!totalRestrictedUnitsFamilyHouseholdsServed || totalRestrictedUnitsFamilyHouseholdsServed===undefined)?0:totalRestrictedUnitsFamilyHouseholdsServed;
    totalSpecialNeedsUnits = (!totalSpecialNeedsUnits || totalSpecialNeedsUnits===undefined)?0:totalSpecialNeedsUnits;

    let totalServe = TotalRestrictedUnitsIndividualHouseholdsServed + totalRestrictedUnitsFamilyHouseholdsServed;
    
    if (desc === "Alternate" || desc === "Farmworker") {
        totalIndividualHouseholdsServed = (totalIndividualHouseholdsServed == null || totalIndividualHouseholdsServed == undefined) ? 0 : totalIndividualHouseholdsServed;
        totalFamilyHouseholdsServed = (totalFamilyHouseholdsServed == null || totalFamilyHouseholdsServed == undefined) ? 0 : totalFamilyHouseholdsServed;
        totalServe = totalIndividualHouseholdsServed + totalFamilyHouseholdsServed;
    }

    if (parseInt(totalSpecialNeedsUnits) > parseInt(totalServe)) {
        errors.push({
            msg: Table3Messages.totalHousehold,
            fieldName: 'totalHomelessUnits',
            sectionIndex: 2
        });
    }

    // error - 2
    let { total } = data;
    total = (!total && total===undefined)?0:total;
    if (parseInt(total) > parseInt(totalServe)) {
        errors.push({
            msg: Table3Messages.totalSpecialNeeds,
            fieldName: 'sec1total',
            sectionIndex: 1
        });
    }

    // error - 3 elderly & disabled
    const { setAsideType, sIdUnitCounts } = data;
    // let elCount = setAsideType.filter(st => st.setAsideType_id === 16)[0];
    // let diCount = setAsideType.filter(st => st.setAsideType_id === 13)[0];
    // elCount = elCount ? elCount.count : 0;
    // diCount = diCount ? diCount.count : 0;

    // check special need errors
    setAsideType.forEach(st => {
        // const mId = setAsideMap[st._id] || st._id;
        const count = sIdUnitCounts[st._id] || 0;
        if (getFloat(st.count) < count) {
            errors.push({
                msg: `Reported ${st.desc} set aside is less than required.`,
                fieldName: `${st.setAsideType_id}`, // set Aside id
                sectionIndex: 1
            });
        }
    });

    return errors;
}

export default Table3Validations;
