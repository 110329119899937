
// import constant
import { contractConstant } from "../constants/contract";
const initialState = {
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['_id'],
            searchCols: []
        }
    },
    fullUpdateDetails: [],
    getOne: {
        orgs: [],
        praparerByOrg: []
    }
}

export function contract(state = initialState, action) {

    switch (action.type) {
        case contractConstant.GET_CONTRACT_DETAILS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case contractConstant.GET_CONTRACT_DETAILS_SUCCESS:
            return {
                ...state,
                count: action.allContract.count,
                data: [...action.allContract.contractData],
                page: {
                    ...state.page,
                    fieldFilters: {
                        ...state.page.fieldFilters,
                        skip: action.allContract.skip,
                        order: action.allContract.order,
                        orderBy: action.allContract.orderBy,
                        lastPage: action.allContract.lastPage,
                        limit: action.allContract.limit,
                        fieldCols: [...action.allContract.fieldCols],
                        searchCols: [...action.allContract.searchCols],
                        headerCols: [...action.allContract.headerCols],
                        selectedCols: { ...action.allContract.selectedCols },
                        visibleColumns: [...action.allContract.visibleColumns]
                    }
                },
                pageData: {
                    createdBy: action.allContract.createdBy,
                    modifiedBy: action.allContract.createdBy
                },
                isLoading: false
            };
        case contractConstant.GETALL_ORGANIZATION_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case contractConstant.GETALL_ORGANIZATION_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    orgs: action.suggestions.orgList
                },
                isLoading: false,
            };
        case contractConstant.GETALL_PREPARER_ORGANIZATION_REQUEST:
            return {
                ...state,
                isLoading: true,
            }
        case contractConstant.GETALL_PREPARER_ORGANIZATION_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    praparerByOrg: action.suggestions
                },
                isLoading: false,
            };
        case contractConstant.GET_PROJECTS_DETAILS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    lastId: action.suggestions.projectLastId,
                    projectName: action.suggestions.projectName.projectName,
                    createdBy: action.suggestions.createdBy,
                    modifiedBy: action.suggestions.createdBy
                },
                isLoading: false,
            };

        case contractConstant.GET_ONE_PREPARER_DETAILS_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    selectedPreparer: action.suggestions.singlePreparer
                },
                isLoading: false,
            };
        case contractConstant.GET_CONTRACT_DEATILS_SUCCESS:
            return {
                ...state,
                fullUpdateDetails: [
                    ...action.contractDetails.fullUpdateDetails
                ],
                getOne: {
                    ...state.getOne
                },
                isLoading: false,
            };
        case contractConstant.GET_UPDATE_CONTRACT_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case contractConstant.GET_UPDATE_CONTRACT_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case contractConstant.CLEAR_CONTRACT_DETAILS:
            return {
                ...state,
                fullUpdateDetails: []
            }
        case contractConstant.GETALL_ORGANIZATION_FAILURE:
        case contractConstant.GET_ONE_PREPARER_DETAILS_FAILURE:
        case contractConstant.GETALL_PREPARER_ORGANIZATION_FAILURE:
        case contractConstant.GET_CONTRACT_DEATILS_FAILURE:
        case contractConstant.GET_PROJECTS_DETAILS_FAILURE:
        case contractConstant.GET_UPDATE_CONTRACT_FAILURE:
        case contractConstant.PAGE_GETALL_CONTRACT_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            }
        default:
            return state;
    }
};
