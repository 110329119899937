import { uploadConstants } from '../constants';

const initialState = {
  isLoading: false,
  data: null,
  error: null,
  rentIncomeLmt: null,
  rentIncomeLoading: false,
  rentIncomeError: null,
  rentIncomeImportStatus: 0,
  rentIncomeRentUpdate: null,
  table1: {
    isLoading: false,
    data: null,
    error: null
  },
  reportActivity:{
    isLoading: false,
    data: null,
    error: null
  }
}

export function bulkUploads(state = { ...initialState }, action) {
  switch (action.type) {
    case uploadConstants.BULK_IMPORT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null
      }
    case uploadConstants.BULK_IMPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        data: null
      }
    case uploadConstants.BULK_IMPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.data
      }
      //Income rent limit
    case uploadConstants.IMPORT_INCOMERENTLMT_REQUEST:
      return {
        ...state,
        rentIncomeLoading: true,
        rentIncomeError: null,
        rentIncomeLmt: null
      }
    case uploadConstants.IMPORT_INCOMERENTLMT_FAILURE:
      return {
        ...state,
        rentIncomeLoading: false,
        rentIncomeError: action.error,
        rentIncomeLmt: null
      }
    case uploadConstants.IMPORT_INCOMERENTLMT_SUCCESS:
      return {
        ...state,
        rentIncomeLoading: false,
        rentIncomeError: null,
        rentIncomeLmt: action.data
      }
    // table 1 import
    case uploadConstants.TABLE1_IMPORT_REQUEST:
      return {
        ...state,
        table1: {
          ...state.table1,
          isLoading: true,
          data: null
        }
      }
    case uploadConstants.TABLE1_IMPORT_SUCCESS:
      return {
        ...state,
        table1: {
          ...state.table1,
          data: action.data,
          isLoading: false,
          error: null
        }
      }
    case uploadConstants.TABLE1_IMPORT_FAILURE:
      return {
        ...state,
        table1: {
          ...state.table1,
          data: action.data,
          isLoading: false,
          error: action.error
        }
      }
    case uploadConstants.IMPORT_INCOMERENTLMT_STATUS_REQUEST:
      return {
        ...state,
        rentIncomeImportStatus: 0,
        rentIncomeError: null
      }     
    case uploadConstants.IMPORT_INCOMERENTLMT_STATUS_SUCCESS:
      return {
        ...state,
        rentIncomeImportStatus: action.data,
        rentIncomeError: null
      }     
    case uploadConstants.IMPORT_INCOMERENTLMT_STATUS_FAILURE:
      return {
        ...state,
        rentIncomeImportStatus: 0,
        rentIncomeError: null
      }   
    case uploadConstants.ACTION_IMPORT_INCOMERENTLMT_REQUEST:
      return {
        ...state,
        rentIncomeLoading: true,
        rentIncomeRentUpdate: false,
        rentIncomeError: null
      }
    case uploadConstants.ACTION_IMPORT_INCOMERENTLMT_SUCCESS:
      return {
        ...state,
        rentIncomeLoading: false,
        rentIncomeRentUpdate: action.data,
        rentIncomeError: null
      }
    case uploadConstants.ACTION_IMPORT_INCOMERENTLMT_FAILURE:
      return {
        ...state,
        rentIncomeLoading: false,
        rentIncomeRentUpdate: false,
        rentIncomeError: null
      }

    // Report activity import
    case uploadConstants.REPORT_ACTIVITY_IMPORT_REQUEST:
      return {
        ...state,
        reportActivity: {
          ...state.reportActivity,
          isLoading: true,
          data: null
        }
      }
    case uploadConstants.REPORT_ACTIVITY_IMPORT_SUCCESS:
      return {
        ...state,
        reportActivity: {
          ...state.reportActivity,
          data: action.data,
          isLoading: false,
          error: null
        }
      }
    case uploadConstants.REPORT_ACTIVITY_IMPORT_FAILURE:
      return {
        ...state,
        reportActivity: {
          ...state.reportActivity,
          data: action.data,
          isLoading: false,
          error: action.error
        }
      }
    case uploadConstants.RESET_TO_INITIAL_STATE: {
      return initialState;
    }
    default: return state;   
  }
}