import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

// Styles
import dashboardStyle from '../../../assets/jss/containers/dashboard';

// Components
import DonutChart from '../../../components/Charts/Donut Chart/DonutChart';
import { SvgLoadIcon } from '../../../components/SvgIcons/SvgIcons';

/**
 *ComplianceIssues
 *
 * @class ComplianceIssues
 * @extends {Component}
 */
class ComplianceIssues extends Component {
    /**
     *Creates an instance of ComplianceIssues.
     * @param {*} props
     * @memberof ComplianceIssues
     */
    constructor(props) {
        super(props);
        this.state = {

        };

    }
    /**
     *render HTML
     *
     * @returns
     * @memberof ComplianceIssues
     */
    render() {
        let { classes, complianceIssues, reportYear } = this.props;
        return (
            <Grid item xs={12} sm={12}>
                <Card className={classes.complianceIssueContainer}>
                    <div className={classes.cardHeader}>
                        <div className="header">Compliance Issues for the year {reportYear}</div>
                        {/* <div className="actions" onClick={() => this.props.handleActions('Explore More')}>Explore More</div> */}
                    </div>
                    <div className='complianceIssueCard'>
                        {
                            complianceIssues.data.donutChartData.map((item, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={3} className="cardContainer">
                                        <div>
                                            {
                                                complianceIssues.isLoading ?
                                                    <div className='complianceIssueLoader'>
                                                        <SvgLoadIcon className= "greyLoader" />
                                                    </div> : 
                                                    <React.Fragment>
                                                        <DonutChart data={item} index={index} />
                                                        <div className="cardText">{item[0].title}</div>
                                                    </React.Fragment>
                                            }
                                        </div>
                                    </Grid>
                                )
                            })
                        }
                    </div>
                </Card>
            </Grid>
        )
    }
}
// prop types
ComplianceIssues.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Default Props
 */
ComplianceIssues.defaultProps = {
    classes: {},
    data: []
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        complianceIssue: state.dashboard.complianceIssue
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
    }
};
// export ComplianceIssues
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(dashboardStyle)(ComplianceIssues));