import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// Import Styles
import exploreStyle from '../../assets/jss/containers/explore';

//Import Components
import MaterialTableComponent from '../../components/Table/MaterialTable';
import PageActions from '../../components/PageActions/PageActions';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import DragandDropInAllDirection from '../../components/DragandDrop/DragandDropInAllDirection';

// global variables
import { getChildCardHeight } from '../../helpers/global'

/**
 * ExplorePreview Container
 * @class ExplorePreview
 * @extends {COmponent}
 */
class ExplorePreview extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: true,
            isFilterOpen: false,
            openReorder: false
        }
        this.dragDropRef = React.createRef();
    }
    /**
     * Bind Html to DOM 
     * @returns 
     * 
     * [1]  -   Set ATTRIBUTEFILTER if FilterAttribute component is not open
     * [2]  -   Set EDITTABLEHEADERS if any modules selected
     */
    render() {
        const { openReorder } = this.state;
        let { classes, headers, previewData, isFilterOpen, isMenuOpen, previewFields, selectedFields, template, reportFilter } = this.props;

        if (template == 5 && previewFields.length > 0 && reportFilter != '') {
            let filterArray = ['City', 'County', 'Zipcode', 'Ownership Organization', 'Property Management Organization'];

            const index = filterArray.indexOf(reportFilter);
            if (index > -1) {
              filterArray.splice(index,1)
            }
            
            headers = headers.filter((el) => {return !filterArray.find((obj) => {         
                return  el== obj
              })})

            previewFields = previewFields.filter((el) => {return !filterArray.find((obj) => {         
                return  el== obj
              })})

            selectedFields = selectedFields.filter((el) => {return !filterArray.find((obj) => {
                  return obj == el.title
            })})
        }

        if (template == 26 && previewFields.length > 0 && reportFilter != '') {
            let filterArray = ['City', 'County','Funder', 'Zipcode', 'Ownership Organization', 'Property Management Organization','State'];

            const index = filterArray.indexOf(reportFilter);
            if (index > -1) {
              filterArray.splice(index,1)
            }
            
            headers = headers.filter((el) => {return !filterArray.find((obj) => {         
                return  el== obj
              })})

            previewFields = previewFields.filter((el) => {return !filterArray.find((obj) => {         
                return  el== obj
              })})

            selectedFields = selectedFields.filter((el) => {return !filterArray.find((obj) => {
                  return obj == el.title
            })})
        }
        if(template===26 && (previewFields.length===0 || previewFields.indexOf(reportFilter)===-1)){
            previewFields.push(reportFilter)
            headers.push(reportFilter)
        }

        let selectedFieldsDup = JSON.parse(JSON.stringify(selectedFields))
        // [1]
        let pageActions = isFilterOpen ? [] : ['ATTRIBUTEFILTER'];
        // [2]
        pageActions = headers.length > 0 && template === 0 ? [...pageActions, 'EDITTABLEHEADERS'] : pageActions;
        // for setting scroll in preview container
        let setclassName = isFilterOpen  ? classes.previewContainer : classes.beforeContainer ;

        return (
            <div className={`${setclassName} explorePreview`} style={{ height: getChildCardHeight()-67 }}>
                <div className={classes.previewHeaderContainer}>
                    <Grid container>
                        <Grid item xs={12} sm={8} style={{ alignSelf: 'center' }}>
                            <div className={classes.previewHeader}>Preview</div>
                        </Grid>
                        <Grid item xs={12} sm={4} className="ExpPreviewContainer">
                            <PageActions
                                actions={pageActions}
                                handleFilterActionClick={() => {
                                    this.props.onFilterAction(false, true)
                                }}
                                handleEditTableHeadersActionClick={() => this.setState({ openReorder: true })}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div style={{ height: '90%' }}>
                    {
                        console.log("==== Preview Fields =======",previewFields) ||
                        <MaterialTableComponent
                        rowsPerPage={10}
                        header={headers}
                        data={previewData.data ? previewData.data : null}
                        field={previewFields}
                        noDataErrorMessage={"No Records Found"}
                        showLoading={false}
                        isLoading={previewData.isLoading}
                        searchVisible={false}
                     />
                    }
                    
                </div>
                {isFilterOpen && <div className={classes.rightSliderIcon}>
                    {
                        isFilterOpen ? <div className={classes.rightSliderIconContainer} onClick={() => this.props.onFilterAction(false, false)}><i className="fa fa-chevron-right flexIcon" aria-hidden="true"></i></div>
                            : <div className={classes.leftSliderIconContainer} onClick={() => this.props.onFilterAction(false, true)}><i className="fa fa-chevron-left flexIcon" aria-hidden="true" ></i></div>
                    }
                </div>}
                <div className={classes.leftSliderIcon}>
                    {
                        isMenuOpen ? <div className={classes.leftSliderIconContainer} onClick={() => this.props.onFilterAction(false, false)}><i className="fa fa-chevron-left flexIcon" aria-hidden="true" ></i></div>
                            : <div className={classes.rightSliderIconContainer} onClick={() => this.props.onFilterAction(true, false)}><i className="fa fa-chevron-right flexIcon" aria-hidden="true" ></i> </div>
                    }
                </div>
                <AlertDialog
                    open={openReorder}
                    title="Modify Columns"
                    saveVisible={false}
                    cancelVisible={false}
                    maxWidth="md"
                    className="overFlowAuto"
                >
                    <DragandDropInAllDirection
                        items={selectedFieldsDup}
                        handleSave={(items,checkModified) => {
                            this.props.handleSelectedFieldsUpdate(items,checkModified);
                            this.setState({ openReorder: false });
                        }}
                        handleCancel={() => { 
                            this.props.handleSelectedFieldsUpdate(selectedFields);
                            this.setState({ openReorder: false }) }
                        }
                    />
                </AlertDialog>
            </div>
        )
    }
}
/**
 * Bind Component Property Types
 */
ExplorePreview.propTypes = {
    noDataErrorMessage: PropTypes.string.isRequired,
};
/*Default Props */
ExplorePreview.defaultProps = {
    headers: [],
    previewData: {},
    template: 0,
    onChange: () => { },
    noDataErrorMessage: '',
    isFilterOpen: false,
    isMenuOpen: true,
    previewFields: [],
    handleSelectedFieldsUpdate: () => { },
}
//Export Component
export default (withStyles(exploreStyle)(ExplorePreview));