//import constants
import { dashboardHOConstants } from '../constants';

//import services
import { dashboardHOService } from '../services';

//import actions
import { alertActions } from './';
import { saveAs } from 'file-saver';

export const dashboardHOActions = {
    getPCLCounts,
    getUsersSummary,
    getProjectSummary,
    downloadReport,
    getLoanRate,
    getReportStatus,
    getLoanComplianceIssue,
    getProjectsList,
    getComplianceIssueSummary,
    getPMProjectList
};


/**
 *Report Status in both dashboard section
 *
 * @param {*} year - selected year
 * @param {*} userRole - logged in user
 * @param {*} org_id - organization id
 * @returns
 */
function getReportStatus(year, userRole) {
    return dispatch => {
        dispatch(request());

        dashboardHOService.getReportStatus(year, userRole)
            .then(
                dashboard => dispatch(success(dashboard.data ? dashboard.data : [])),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.GETALL_HO_REPORT_STATUS_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GETALL_HO_REPORT_STATUS_SUCCESS, data, year } }
    function failure(error) { return { type: dashboardHOConstants.GETALL_HO_REPORT_STATUS_FAILURE, error } }
}

/**
 *download report
 *
 * @param {*} id report year table _id value
 * @param {*} formData form datas to update
 */
function downloadReport(project, site, year) {
    return dispatch => {
        dispatch(request());

        dashboardHOService.downloadReport(project, site, year)
            .then(res => res.blob())
            .then(
                data => {
                    saveAs(data, `${year}_Homeownership Annual Report.xlsx`);
                    dispatch(success({}))
                    dispatch(alertActions.success('Successfully downloaded'));
                    dispatch(alertActions.clear());
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error('This project does not exist for selected year'));
                }
            );
    };

    function request() { return { type: dashboardHOConstants.DOWNLOAD_HO_REPORT_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.DOWNLOAD_HO_REPORT_SUCCESS, data } }
    function failure(error) { return { type: dashboardHOConstants.DOWNLOAD_HO_REPORT_FAILURE, error } }

}

/**
 *Dropdown values in Dashboard
 *
 * @returns
 */
function getProjectSummary(limit, search) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getProjectSummary(limit, search)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.GETALL_HO_PROJECTSUMMARY_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GETALL_HO_PROJECTSUMMARY_SUCCESS, data } }
    function failure(error) { return { type: dashboardHOConstants.GETALL_HO_PROJECTSUMMARY_FAILURE, error } }
}

/**
 *PSBU counts in Dashboard
 *
 * @returns
 */
function getPCLCounts(userRole, reportYear) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getPCLCounts(userRole, reportYear)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.PCL_COUNT_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.PCL_COUNT_SUCCESS, data } }
    function failure(error) { return { type: dashboardHOConstants.PCL_COUNT_FAILURE, error } }
}

/**
 *Users Summary in Dashboard
 *
 * @returns
 */
function getUsersSummary(userRole) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getUsersSummary(userRole)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.USERSSUMMARY_HO_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.USERSSUMMARY_HO_SUCCESS, data } }
    function failure(error) { return { type: dashboardHOConstants.USERSSUMMARY_HO_FAILURE, error } }
}

/**
 * Action for Loan Rate
 */
 function getLoanRate(userRole, year) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getLoanRate(userRole, year)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.GETALL_HO_OCCUPANCY_RATE_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GETALL_HO_OCCUPANCY_RATE_SUCCESS, data, year } }
    function failure(error) { return { type: dashboardHOConstants.GETALL_HO_OCCUPANCY_RATE_FAILURE, error } }
}

/**
 * Action for Compliance Issue
 */
 function getLoanComplianceIssue(userRole, year) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getLoanComplianceIssue(userRole, year)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.GETALL_HO_COMPLIANCE_ISSUE_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GETALL_HO_COMPLIANCE_ISSUE_SUCCESS, data, year } }
    function failure(error) { return { type: dashboardHOConstants.GETALL_HO_COMPLIANCE_ISSUE_FAILURE, error } }
}

function getProjectsList(userRole, org_id, year) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getProjectsList(userRole, org_id, year)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };
    function request() { return { type: dashboardHOConstants.GET_PROJECT_LIST_HO_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GET_PROJECT_LIST_HO_SUCCESS, data } }
    function failure(error) { return { type: dashboardHOConstants.GET_PROJECT_LIST_HO_FAILURE, error } }
}

function getComplianceIssueSummary(year, userRole, org_id, selectedSites) {
    return dispatch => {
        dispatch(request());
        dashboardHOService.getComplianceIssueSummary(year, userRole, org_id, selectedSites)
            .then(
                details => dispatch(success(details.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.GETALL_HO_COMPLIANCE_NEEDSACTION_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GETALL_HO_COMPLIANCE_NEEDSACTION_SUCCESS, data, year, selectedSites } }
    function failure(error) { return { type: dashboardHOConstants.GETALL_HO_COMPLIANCE_NEEDSACTION_FAILURE, error } }
}

/**
 *PM Dashboard Needs Action Section
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sfields
 * @param {*} sValues
 * @returns
 */
 function getPMProjectList(skip, limit, orderBy, order, sfields, sValues, selectedSites, reportYear, userRole, org_id) {
    return dispatch => {
        dispatch(request());

        dashboardHOService.getPMProjectList(skip, limit, orderBy, order, sfields, sValues, selectedSites, reportYear, userRole, org_id)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: dashboardHOConstants.GET_HO_PROJECTSLIST_REQUEST } }
    function success(data) { return { type: dashboardHOConstants.GET_HO_PROJECTSLIST_SUCCESS, data } }
    function failure(error) { return { type: dashboardHOConstants.GET_HO_PROJECTSLIST_FAILURE, error } }
}