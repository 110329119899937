import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Table5Edit from './Table5Edit';

/**
 * ReportsRouter Component
 * @class ReportsRouter
 * @extends {Component}
 */
class Table5Router extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, projectId, isSubPage, page } = this.props;
        return (
            <Switch >
                <Route path={match.url} render={(props) => {
                    return <Table5Edit 
                    {...props} 
                    page={page} />
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default Table5Router;
