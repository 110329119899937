import { ValidatorForm } from 'react-material-ui-form-validator';

// custom phone validation rule
// const phoneRegEx = /^[0-9]{5}?[-\s.]?[-/\s.]?[0-9]{5}$/;
// const phoneRegEx = /^[0-9]{3}[-\s.][0-9]{3}[-\s.][0-9]{4}$/;
const phoneRegEx = /^[0-9-\s.()+/]+$/;
// eslint-disable-next-line
const numberFieldRegEx = /^\-?\d+((\.|\,)\d+)?$/;
ValidatorForm.addValidationRule('isPhone', (value) => {

    // if phone is empty return true
    if (!value.trim()) {
        return true
    }

    return phoneRegEx.test(value.trim());
});

// min number greater than 1
ValidatorForm.addValidationRule('gtZero', (value) => {
    value = value.replace("$", '');
    let floatValue = parseFloat(value);
    if (floatValue === "NaN" || floatValue <= 0 || floatValue === null) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('islastYeargtFirstYear', (value) => {

});

ValidatorForm.addValidationRule('isDuplicateActivity', (value) => {

});

ValidatorForm.addValidationRule('isStringLength250', (value) => {
    let valueLength = value.length;
    if (valueLength > 250) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isStringLength500', (value) => {
    let valueLength = value.length;
    if (valueLength > 500) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isStringLength50', (value) => {
    let valueLength = value ? value.length : 0;
    if (valueLength > 50) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isStringLength45', (value) => {
    let valueLength = value.length;
    if (valueLength > 45) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isMaxLen1000', (value) => {
    let valueLength = value.length;
    if (valueLength > 1000) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isMaxLen100', (value) => {
    let valueLength = value.length;
    if (valueLength > 100) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isValue0To99', (value) => {
    if (parseInt(value) < 0 || parseInt(value) > 99) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isValue0To30NotDecimal', (value) => {
    if (value) {
        let valuconvert = value.toString()
        if (parseInt(value) < 1 || parseInt(value) > 30 || valuconvert.includes(".")) {
            return false
        }
    }
    return true
});

ValidatorForm.addValidationRule('isValue0To999', (value) => {
    if (parseInt(value) < 0 || parseInt(value) > 999) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('isValue0To999NotDecimal', (value) => {
    if (value) {
        let valuconvert = value.toString()
        if (parseInt(value) < 0 || parseInt(value) > 99 || valuconvert.includes(".") || isNaN(value)) {
            return false
        }
    }
    return true
});





ValidatorForm.addValidationRule('isValueTwoDecimal', (value) => {
    if (value) {
        let input = parseFloat(value)
        const regexTest = /^\d+(?:\.\d\d?)?$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});

ValidatorForm.addValidationRule('dollarisValueTwoDecimal', (value) => {
    if (value) {
        value = value.substring(1)
        let input = parseFloat(value)
        const regexTest = /^\d+(?:\.\d\d?)?$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});


ValidatorForm.addValidationRule('greatthan0', (value) => {
    let checkValue = parseFloat(value.replace(/\$/g, ''));
    if (checkValue <= 0) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('currency', (value) => {
    if (value) {
        //const regexTest = /^[1-9,]\d*(?:\.\d\d)?$/;
        const regexTest = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/
        const ok = regexTest.test(value);
        return ok
    }
    return true
});

ValidatorForm.addValidationRule('isNumeric', (value) => {
    if (value) {
        let input = parseFloat(value)
        const regexTest = /^[1-9,]\d*(?:\.\d\d)?$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});

ValidatorForm.addValidationRule('isValueAlphanumericandSpecialChars', (value) => {
    if (value) {
        let input = value
        const regexTest = /^[A-Za-z0-9_*#&/ -]*$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});
ValidatorForm.addValidationRule('isValueAlphanumericandSpecialCharsIncludeDot', (value) => {
    if (value) {
        let input = value
        const regexTest = /^[A-Za-z0-9_*#&/. -]*$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});

ValidatorForm.addValidationRule('validAddress', (value) => {
    if (value) {
        let input = value
        const regexTest = /^[A-Za-z -]*$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});

ValidatorForm.addValidationRule('validAddress1', (value) => {
    if (value) {
        let input = value
        const regexTest = /^[A-Za-z0-9 -]*$/;
        const ok = regexTest.test(input);
        return ok
    }
    return true
});

ValidatorForm.addValidationRule('isStringLength30', (value) => {
    let valueLength = value.length;
    if (valueLength > 30) {
        return false
    }
    return true
});

ValidatorForm.addValidationRule('gtEqZero', (value) => {
    let intValue = parseInt(value, 10);
    if (intValue === "NaN" || intValue < 0 || intValue === null) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('notEqualMoveInHouseHold', (value) => {
    return false;
})

ValidatorForm.addValidationRule('negativeFloatingOnly', (value) => {
    if (numberFieldRegEx.test(value))
        return true;
    return false;
});

//value b/w 1 to 15
ValidatorForm.addValidationRule('lessThan15', (value) => {
    let intValue = parseInt(value, 10);
    if (intValue === "NaN" || intValue > 15 || intValue === null) {
        return false;
    }
    return true;
});

//value b/w 0 to 5Lak
ValidatorForm.addValidationRule('lessThan5Lak', (value) => {
    value = value.replace("$", '');
    value = value.replace(/,/g, '');
    let floatValue = parseFloat(value)
    if (floatValue === "NaN" || floatValue > 500000 || floatValue === null) {
        return false;
    }
    return true;
});

//value b/w 0 to 5Lak
ValidatorForm.addValidationRule('lessThan10Lak', (value) => {
    value = value.replace("$", '');
    value = value.replace(/,/g, '');
    let floatValue = parseFloat(value)
    if (floatValue === "NaN" || floatValue > 1000000 || floatValue === null) {
        return false;
    }
    return true;
});

//value between 0 to 30Lak
ValidatorForm.addValidationRule('lessThan10Lak', (value) => {
    value = value.replace("$", '');
    value = value.replace(/,/g, '');
    let floatValue = parseFloat(value)
    if (floatValue === "NaN" || floatValue > 3000000 || floatValue === null) {
        return false;
    }
    return true;
});

// export the validations
export default ValidatorForm;

export const FieldValidations = {
    name: {
        validation: ['minStringLength: 3', 'maxStringLength: 50'],
        errorMsg: ['should contains atleast 3 characters', 'should not more than 50 characters']
    },
    username: {
        validation: ['minStringLength: 3', 'maxStringLength: 50'],
        errorMsg: ['should contains atleast 3 characters', 'should not more than 50 characters']
    },
    password: {
        validation: ['minStringLength: 7', 'maxStringLength: 30'],
        errorMsg: ['should contains atleast 7 characters', 'should not more than 30 characters']
    },
    /*     phone: {
            validation: ['isPhone', 'minStringLength: 7', 'maxStringLength: 12'],
            errorMsg: ['e-g : 123-456-7890', 'Phone number should be aleast 7 characters', 'Phone number should not exceed 12 characters']
        } */
    phone: {
        validation: ['isPhone', 'maxStringLength: 20'],
        errorMsg: ['Numbers: 0 to 9, Special Characters: -.()+/ are allowed', 'Phone number should not exceed 20 characters']
    }
};