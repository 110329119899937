export const printConstants = {
    // get table 1 print data
    PRINT_TABLE1_DATA_REQUEST: 'PRINT_TABLE1_DATA_REQUEST',
    PRINT_TABLE1_DATA_SUCCESS: 'PRINT_TABLE1_DATA_SUCCESS',
    PRINT_TABLE1_DATA_FAILURE: 'PRINT_TABLE1_DATA_FAILURE',

    // get table 5 print data
    PRINT_TABLE5_DATA_REQUEST: 'PRINT_TABLE5_DATA_REQUEST',
    PRINT_TABLE5_DATA_SUCCESS: 'PRINT_TABLE5_DATA_SUCCESS',
    PRINT_TABLE5_DATA_FAILURE: 'PRINT_TABLE5_DATA_FAILURE'
}