//import constants
import { UAConstants } from '../constants';

//import services
import { UAService, projectsService } from '../services';

//import actions
import { alertActions } from './';

export const UAActions = {
    getConfigDatas,
    getUADetails,
    updateUADetails,
    bulkUpload,
    getMonitoringProjects
};

/**
 * An action to get config datas for UA
 *
 * @returns
 */
function getConfigDatas() {
    return dispatch => {
        dispatch(request());

        UAService.getConfigDatas()
            .then(
                datas => dispatch(success(datas.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: UAConstants.GET_UA_CONFIG_DATAS_REQUEST } }
    function success(data) { return { type: UAConstants.GET_UA_CONFIG_DATAS_SUCCESS, data } }
    function failure(error) { return { type: UAConstants.GET_UA_CONFIG_DATAS_FAILURE, error } }
}

/**
 * An action to get UA details for the  builiding
 *
 * @param {*} buildingId contains building id for the UA details
 * @returns
 */
function getUADetails(buildingId, type) {
    return dispatch => {
        dispatch(request());

        UAService.getUADetails(buildingId, type)
            .then(
                datas => dispatch(success(datas.data)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: UAConstants.GET_UA_DETAILS_REQUEST } }
    function success(data) { return { type: UAConstants.GET_UA_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: UAConstants.GET_UA_DETAILS_FAILURE, error } }
}

/**
 * A action to update UA details to the building
 *
 * @param {*} buildingId contains building id where UA to update
 * @param {*} formData contains UA datas to update
 * @returns
 */
function updateUADetails(buildingId, formData) {
    return dispatch => {
        dispatch(request());

        UAService.updateUADetails(buildingId, formData)
            .then(
                datas => {
                    dispatch(success(datas));
                    dispatch(alertActions.success("Updated Successfully"));
                    // A microservice call to update all latest UA to table 1 records
                    UAService.updateUAInTable1(buildingId);
                },
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: UAConstants.UPDATE_UA_DETAILS_REQUEST } }
    function success(data) { return { type: UAConstants.UPDATE_UA_DETAILS_SUCCESS, data } }
    function failure(error) { return { type: UAConstants.UPDATE_UA_DETAILS_FAILURE, error } }
}

/**
 * An action to call the bulk upload process
 *
 * @param {*} projectId contains project id to upload in sites buildings
 * @param {*} [siteId=null] contains site id to bulk upload for buildings in a particular site, 
 * if null it will update for all buildings in all sites
 * @param {*} formData contains UA details to update in each building
 * @returns
 */
function bulkUpload(projectId, siteId = null, formData) {
    return dispatch => {
        dispatch(request());

        UAService.bulkUpload(projectId, siteId, formData)
            .then(
                datas => {
                    dispatch(success(datas));
                    UAService.updateUABulkInTable1({ projectIds: projectId });
                    dispatch(alertActions.success("Updated Successfully"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: UAConstants.UA_BULK_UPDATE_REQUEST } }
    function success(data) { return { type: UAConstants.UA_BULK_UPDATE_SUCCESS, data } }
    function failure(error) { return { type: UAConstants.UA_BULK_UPDATE_FAILURE, error } }

}

/**
 * Get list of monitoring projects for bulk upload
 *
 */
function getMonitoringProjects() {
    return dispatch => {
        dispatch(request());

        projectsService.getMonitoringProjects()
            .then(
                data => {
                    dispatch(success(data.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: UAConstants.GET_MONITORING_PJTS_UA_REQUEST } }
    function success(data) { return { type: UAConstants.GET_MONITORING_PJTS_UA_SUCCESS, data } }
    function failure(error) { return { type: UAConstants.GET_MONITORING_PJTS_UA_FAILURE, error } }
}