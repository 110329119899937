import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

// Material UI
import { TextValidator } from 'react-material-ui-form-validator';

// Table validations warning helper
import { hasWarnings } from '../../helpers/TableValidations/warnings';

/**
 *Number field component
 *
 * @class NumberField
 * @extends {Component}
 */
class NumberField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setOpen: false
        }
    }

    setHoverState = () => {
        const { tooltipOnHover, focusVisible } = this.props
        if (!tooltipOnHover && !focusVisible) {
            this.setState({ setOpen: true })
        }
    }

    handleonFocus = (event) => {
        if (this.props.onFocus) {
            this.props.onFocus(event)
        }
    };


    /**
     *render html
     *
     * @returns
     * @memberof NumberField
     */
    render() {
        let { name, value, className, validators, fielderrors, warningfieldname, allowNegative, displayType, variant, thousandSeparator, decimalScale, errorMessages, label, disabled, prefix, placeholder, format } = this.props;
        value = value === '' || value === null || value === 'undefined' ? '' : value;
        const hasRequired = validators.indexOf('required') >= 0;
        let customClass = validators && validators.length > 0 && hasRequired ? className + ' required' : className;
        const warningClass = hasWarnings(fielderrors, warningfieldname);
        return (
            <NumberFormat
                name={name}
                thousandSeparator={thousandSeparator}
                decimalSeparator='.'
                variant={variant}
                fixedDecimalScale={value === 'N/A' ? false : true}
                decimalScale={value === 'N/A' ? null : decimalScale}
                customInput={TextValidator}
                isNumericString={true}
                onValueChange={(values) => {
                    this.props.handleChange(name, values.value);
                }}
                validators={validators}
                errorMessages={errorMessages}
                // onFocus={(e) => this.handleonFocus(e)}
                inputProps={{
                    onFocus: (e) => { this.handleonFocus(e) }
                }}
                onBlur={() => {
                    this.props.handleBlur();
                }}
                // {...this.props}
                disabled={disabled}
                value={value}
                className={`${customClass} ${warningClass}`}
                allowNegative={displayType === "text" ? true : allowNegative}
                label={label}
                displayType={displayType}
                prefix={prefix}
                placeholder={placeholder}
                format={format}
            />
        );
    }
}

//default props
NumberField.defaultProps = {
    classes: {},
    name: 'no-name',
    value: '',
    label: "",
    type: "text",
    className: '',
    errorMessages: [],
    validators: [],
    disabled: false,
    margin: 'dense',
    maxLength: 50,
    SelectProps: {},
    handleChange: () => { },
    handleBlur: () => { },
    prefix: '$',
    fielderrors: [],
    warningfieldname: '',
    allowNegative: false,
    placeholder: '',
    format: null,
    variant: 'standard',
    thousandSeparator: ",",
    decimalScale: 2,
    displayType: 'number'
}

//prop types
NumberField.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    disabled: PropTypes.bool,
    margin: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
    variant: PropTypes.string,
    thousandSeparator: PropTypes.any,
    decimalScale: PropTypes.any
};

//export component
export default NumberField;