//import constants
import { globalSearchConstants } from '../constants/globalSearch';

//import services
import { globalSearchServices } from "../services/globalSearch";

export const globalSearchActions = {
    getGlobalSearch
}

/**
 *Get Search data
 *
 * @param {*} value - search data
 * @param {*} searchKey - dropdown index
 * @returns
 */
function getGlobalSearch(value, searchKey) {
    if (value && value.trim()) {
        return dispatch => {
            dispatch(request());
            globalSearchServices.getGlobalSearch(value, searchKey)
                .then(
                    globalSearch => dispatch(success(globalSearch)),
                    error => dispatch(failure(error.toString()))
                );
        };
        function request() { return { type: globalSearchConstants.GETALL_REQUEST } }
        function success(globalSearch) { return { type: globalSearchConstants.GETALL_SUCCESS, globalSearch } }
        function failure(error) { return { type: globalSearchConstants.GETALL_FAILURE, error } }
    } else {
        return dispatch => {
            const globalSearch = { data: [] };
            dispatch(success(globalSearch));
            function success(globalSearch) { return { type: globalSearchConstants.GETALL_SUCCESS, globalSearch } }
        }
    }
}