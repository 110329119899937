import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid } from '@material-ui/core';
import TextBox from '../../../../components/TextBox/TextBox';
import SaveCancel from '../../../../components/SaveCancel/SaveCancel';
import { withStyles } from '@material-ui/core/styles';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import DatePickerInfo from '../../../../components/DatePicker/DatePickerInfo';
import NumberField from '../../../../components/NumberField/NumberField';
import moment from 'moment';

import OutlineTextBox from '../../../../components/OutlineTextBox/OutlineTextBox';
import OutlineTextArea from '../../../../components/OutlineTextArea/OutlineTextArea';
import MultipleTextArea from '../../../../components/MultipleTextAreaHO/MultipleTextArea';
// Import Styles
import pageStyle from '../../../../assets/jss/containers/common';
import {Typography} from '@material-ui/core';
import { reportTablesActions } from '../../../../actions/reportTables';

import { getDateDifference } from '../../../../helpers';

import ToolTip from '../../../../components/ToolTip/ToolTip';
import { InfoIcon } from '../../../../components/SvgIcons/SvgIcons';

const calculateDateDifference = (date1, date2) => {
    const diffTime = Math.abs(new Date(date1) - new Date(date2));
    return Math.floor(diffTime / (1000 * 60 * 60 * 24));
};

class Table5Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
        building_id: 0,
        site_id: 0,
        unit_id: 0,
        reportYearId: 0,
        table1_id: 0,
        siteName : '',
        buildingName : '',
        buildingBIN: '',
        unitName:'',
        moveInDate: null,
        moveOutDate: null,
        rentReadyDate: null,
        totalDaysToRentReady: null,
        reOccupiedDate: null,
        totalDaysVacant: null,
        '30daysExp' : '',
        '90daysExp' : '',
        isCreated: false,
        is30DaysDiff: false,
        is90DaysDiff: false,
        isNextMoveInPresent: false,
        occNextMoveInDate: null,
        is30DaysExpRequired: false,
        prev30DaysExplanations: [],
        prev90DaysExplanations: [],
        unitDesignation:null,
        tracked: false,
        toolTipTitle: 'If a new tenant moved into this unit in current reporting year, please create new household record in Table 1.  The new household’s move-in date will be carried over to the Re-Occupied Date field in Table 5.',
        _id: 0,
        labelWidth: 0
    };
  }

  UNSAFE_componentWillMount() {

    ValidatorForm.addValidationRule('isLessThanMoveOut', (value) => {
        if (value === '' || value === null) return true;
        const moveOutDate = new Date(this.state.moveOutDate).getTime();
        const readyDate = new Date(value).getTime();
        return moveOutDate < readyDate;
    });
    
    ValidatorForm.addValidationRule('isGreaterThanNextMoveIn', (value) => {
        if (value === '' || value === null) return true;
        const moveInDate = new Date(this.state.occNextMoveInDate).getTime();
        const readyDate = new Date(value).getTime();
        return moveInDate >= readyDate;
    });

    ValidatorForm.addValidationRule('isEqualToNextMoveIn', (value) => {
        if (value === '' || value === null) return true;
        const moveInDate = new Date(this.state.occNextMoveInDate).getTime();
        const readyDate = new Date(value).getTime();
        return moveInDate == readyDate;
    })
  }
  
  componentDidMount() {
    const { location: { state } } = this.props;
    let data = state?.pageData?.data;
    
    if (data){
        let reOccupiedDate = data?.isNextMoveInPresent ? data?.occNextMoveInDate : data?.reOccupiedDate;
        let dateDiff = null;
        if (data?.isNextMoveInPresent) {
            dateDiff = getDateDifference(global.moment(data?.occNextMoveInDate, 'MM/DD/YYYY'), global.moment(data?.moveOutDate, 'MM/DD/YYYY'));
            dateDiff = dateDiff ? Math.abs(dateDiff) : null
        }
        this.setState({ ...data, table1_id: data?.table_id, unitName: data?.unit?.common_id, is30DaysDiff: data?.is30daydiff, is90DaysDiff: data?.is90daydiff, 
            reOccupiedDate: reOccupiedDate,
            // totalDaysVacant: dateDiff,
            is30DaysExpRequired: data?.totalDaysToRentReady ? data?.totalDaysToRentReady > 30 : false
        })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
  }

  getLabelWidth = (label, width) => {
        if (label == 'Re-Occupied Date:') {
            this.setState({ labelWidth: width })
        }
  }

  handleSubmit = () => {
    if (this.state.isCreated) {
        this.props.updateTable5(this.state._id, {
            rentReadyDate: this.state.rentReadyDate,
            totalDaysToRentReady: this.state.totalDaysToRentReady,
            reOccupiedDate: this.state.reOccupiedDate,
            totalDaysVacant: this.state.totalDaysVacant,
            '30daysExp' : this.state['30daysExp'],
            '90daysExp' : this.state['90daysExp'],
        }, () => {
            this.props.history.goBack()
        })
    } else {
        this.props.createTable5({ ...this.state, reportYear_id: this.state.reportYearId }, () => { 
            this.props.history.goBack()
        });
    }
  }

  handleChange = (name,value) => {
        this.setState({ [name] : value })
        if ((name == 'rentReadyDate') && moment(value, 'MM/DD/YYYY').isValid) {
            let dateDiff = calculateDateDifference(global.moment(value, 'MM/DD/YYYY'),global.moment(this.state.moveOutDate, 'MM/DD/YYYY'))
           // let dateDiff = getDateDifference(global.moment(value, 'MM/DD/YYYY'), global.moment(this.state.moveOutDate, 'MM/DD/YYYY'));
            dateDiff = dateDiff ? Math.abs(dateDiff) : null
            this.setState({ 
                totalDaysToRentReady: dateDiff,
                is30DaysExpRequired : dateDiff ? dateDiff > 30 : false
            })
        }

        if ((name == 'reOccupiedDate') && moment(value, 'MM/DD/YYYY').isValid) {
            // this.setState((prevState) => ({
            //     tracked: true, 
            // }));
            this.setState(
                { totalDaysVacant: calculateDateDifference( global.moment(value, 'MM/DD/YYYY'), global.moment(this.state.moveOutDate, 'MM/DD/YYYY')) })
        }
  }

  handleSave = () => {
    const { data } = this.state;
    this.props.updateData(data);
  };

  render() {
    let {classes, history, pageAccess} = this.props;
    let isReadOly = this.props.pageAccess.indexOf('SAVE') === -1;
    let { siteName, buildingName, buildingBIN, unitName, moveInDate, moveOutDate, rentReadyDate, totalDaysToRentReady, reOccupiedDate, totalDaysVacant, '30daysExp': days30Exp, '90daysExp': days90Exp,unitDesignation } = this.state;
    return (
        < React.Fragment >
                <ContentWrapper
                    pageAccess={['HELP', 'BACK']}
                    title={unitName}
                    classes={classes}
                    needTitleBar={true}
                    needPadding={true}
                    handleBackActionClick={() => {history.goBack();}}
                >
                 <div className={classes.rootEdit} >
                    <ValidatorForm
                        name="table5Form"
                        ref="form"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }} >

                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={4}>
                                <TextBox
                                    name="siteName"
                                    value={siteName}
                                    label="Site Name"
                                    className={classes.TextBox}
                                    handleChange={this.handleChange}
                                    style={{width: '100%'}}
                                    margin="dense"
                                    // validators={['required']}
                                    // errorMessages={['this field is required']}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextBox
                                    name="buildingName"
                                    value={buildingName}
                                    label="Building Name"
                                    className={classes.TextBox}
                                    handleChange={this.handleChange}
                                    style={{width: '100%'}}
                                    margin="dense"
                                    // validators={['required']}
                                    // errorMessages={['this field is required']}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextBox
                                    name="buildingBIN"
                                    value={buildingBIN}
                                    label="Building BIN"
                                    className={classes.TextBox}
                                    handleChange={this.handleChange}
                                    style={{width: '100%'}}
                                    margin="dense"
                                    // validators={['required']}
                                    // errorMessages={['this field is required']}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextBox
                                    name="unitName"
                                    value={unitName}
                                    label="Unit"
                                    className={classes.TextBox}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    // validators={['required']}
                                    style={{width: '100%'}}
                                    // errorMessages={['this field is required']}
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextBox
                                    name="unitDesignation"
                                    value={unitDesignation}
                                    label="Unit Designation"
                                    className={classes.TextBox}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    style={{width: '100%'}}
                                    disabled={true}
                                />
                            </Grid>
                            
                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                        name="moveInDate"
                                        value={moveInDate}
                                        format={"MM/DD/YYYY"}
                                        label={`Previous Resident Move-In Date:`}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        className={classes.textField}
                                        validators={[]}
                                        errorMessages={[]}
                                        disabled={true}
                                        notRequired={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                        name="moveOutDate"
                                        value={moveOutDate}
                                        format={"MM/DD/YYYY"}
                                        label={`Move-Out Date:`}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        className={classes.textField}
                                        validators={[]}
                                        errorMessages={[]}
                                        disabled={true}
                                        notRequired={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <DatePicker
                                        name="rentReadyDate"
                                        value={rentReadyDate}
                                        format={"MM/DD/YYYY"}
                                        label={`Rent Ready Date:`}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        className={classes.textField}
                                        validators={this.state.isNextMoveInPresent ? ['required', 'isLessThanMoveOut', 'isGreaterThanNextMoveIn'] : ['isLessThanMoveOut']}
                                        errorMessages={ this.state.isNextMoveInPresent ? ['This field is required','Date should be greater than Move-Out Date.', 'Date should be lesser than next Move-In Date.'] : ['Date should be greater than Move-Out Date.']}
                                        disabled={isReadOly}
                                        // validations={{
                                        //     minDate: new Date(this.state.moveOutDate),
                                        // }},
                                        notRequired={ !this.state.isNextMoveInPresent ? true : false}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} style={{ marginTop: '6px', marginBottom: '4px' }}>
                                <NumberField
                                    className={classes.textField}
                                    label="Total Days to Rent-Ready"
                                    name="totalDaysToRentReady"
                                    value={totalDaysToRentReady}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                    prefix={''}
                                    decimalScale={0}
                                />
                            </Grid>

                            < Grid item xs={12} sm={12}>
                                <div>
                                    <div className="section_title">
                                       <span> Explanation if 30+ Days to Rent Ready </span> 
                                       <div className="section_title_edit">(Provide a timeline of work completed in unit & reason for delayed rent ready status)</div>
                                    </div>
                                </div>
                                <div style={{ border: '2px solid #eeeeee', padding: '10px' }}>
                                    <Typography className={`${ (this.state.is30DaysExpRequired || (this.state.is30DaysDiff && !this.state.rentReadyDate ))? "required-header" : ""}`} variant="body1">Comments</Typography>
                                    <MultipleTextArea
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        name="30daysExp"
                                        value={days30Exp}
                                        fullWidth={true}
                                        validators={(this.state.is30DaysExpRequired || (this.state.is30DaysDiff && !this.state.rentReadyDate )) ?['required'] : []}
                                        errorMessages={['This field is required']}
                                        disabled={isReadOly}
                                        dataArray={this.state.prev30DaysExplanations}
                                    />
                                </div>
                            </ Grid>
                           
                                <Grid item xs={12} sm={4}>
                                <div className={'posRelative'}>
                                    <DatePickerInfo
                                        name="reOccupiedDate"
                                        value={reOccupiedDate}
                                        format={"MM/DD/YYYY"}
                                        label={`Re-Occupied Date:`}
                                        handleChange={this.handleChange}
                                        margin="dense"
                                        // onBlur={() => { this.setState({ tracked: false }) }}
                                        className={classes.textField}
                                        validators={this.state.isNextMoveInPresent ? ['required', 'isLessThanMoveOut', 'isEqualToNextMoveIn'] :  ['isLessThanMoveOut']}
                                        errorMessages={ this.state.isNextMoveInPresent ? ['This field is required','Date should be greater than Move-Out Date.', 'Date should be equal to next Move-In Date.'] : ['Date should be greater than Move-Out Date.']}
                                        disabled={isReadOly || this.state.isNextMoveInPresent || true}
                                        notRequired={ !this.state.isNextMoveInPresent ? true : false }
                                        getLabelWidth={this.getLabelWidth}
                                    />
                                     {
                                        !this.state.isNextMoveInPresent &&  <span className={'ddTooltip_table5'} style={{left: this.state.labelWidth}}>
                                            <ToolTip title={this.state.toolTipTitle} placement="top">
                                                <span><InfoIcon /></span>
                                            </ToolTip>
                                       </span>
                                    }
                                    </div>
                                </Grid>
                          
                           
                            <Grid item xs={12} sm={4} style={{ marginTop: '6px', marginBottom: '4px' }}>
                                <NumberField
                                    className={classes.textField}
                                    label="Total Days Vacant"
                                    name="totalDaysVacant"
                                    value={totalDaysVacant}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    prefix={''}
                                    decimalScale={0}
                                    disabled={true}
                                />
                            </Grid>

                            { < Grid item xs={12} sm={12}>
                                <div>
                                    <div className="section_title">
                                      <span> Explanation if 90+ Days Vacant </span>
                                      <div className="section_title_edit">(Provide an explanation of delayed vacancy and advertising/marketing efforts)</div>
                                    </div>
                                </div>
                                <div style={{ border: '2px solid #eeeeee', padding: '10px' }}>
                                    <Typography className={`${this.state.is90DaysDiff ? "required-header" : ""}`} variant="body1">Comments</Typography>
                                    <MultipleTextArea
                                        className={classes.textField}
                                        handleChange={this.handleChange}
                                        name="90daysExp"
                                        value={days90Exp}
                                        margin="dense"
                                        fullWidth={true}
                                        validators={this.state.is90DaysDiff ?['required'] : []}
                                        errorMessages={['This field is required']}
                                        disabled={isReadOly}
                                        dataArray={this.state.prev90DaysExplanations}
                                    />
                                </div>
                            </ Grid>
                        }

                        </Grid>
                        {
                          pageAccess.indexOf('SAVE') > -1 ? (
                                <SaveCancel
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.goBack() }}
                                    saveText={'SAVE'}
                                    cancelVisible={true}
                                />
                            )  : null
                        }
                    </ValidatorForm>
                 </div>
                </ContentWrapper>
           
        </React.Fragment >
    );
  }
}

Table5Edit.propTypes = {
 
};

const mapStateToProps = (state) => {
  return {
    pageAccess: state.pageAccess['table5'] || [],
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTable5: (formData, clbk) => dispatch(reportTablesActions.createTable5(formData,clbk)),
    updateTable5: (id, formData, clbk) => dispatch(reportTablesActions.updateTable5(id, formData, clbk))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(Table5Edit));
