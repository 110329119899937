import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const occupantServices = {
    getAll,
    getOccupantsDetails,
    updateOccupantsHHIDetails,
    updateOccupantsHHINCOMEDetails,
    updateOccupantsMRD,
    updateOccupantsGeneral,
    getAssocPjtConfig,
    getEmptyUnits,
    moveInOccupant,
    getVacantUnit,
    tranferUnit,
    getMoveInConfig,
    getOneValidationError,
    deleteOccupant,
    updateIncomeRent,
    getFunderDetails
};

/**
 *Get occupants list data
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [isAutoComplete=false]
 * @param {*} projectData
 * @returns
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, projectData = {}) {
    let data = {
        fields: sfields,
        values: sValues,
        isAutoComplete,
        projectData: projectData
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/occupants/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 *Get single occupants detail by using occupant id
 *
 * @param {*} id - occupant_id
 * @param {*} reportYear_id - reportYear_id
 * @returns
 */
function getOccupantsDetails(id, reportYear_id = null) {
    if (id) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${globalVariable.apiURL}/occupants/${id}/${reportYear_id}?timeZone=${timeZone}`, requestOptions).then(handleResponse);
    }
}

/**
 *Get single occupants detail by using occupant id
 *
 * @param {*} id - occupant_id
 * @param {*} reportYear_id - reportYear_id
 * @returns
 */
 function getFunderDetails(project_id) {

        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${globalVariable.apiURL}/occupants/getFunders/${project_id}`,requestOptions).then(handleResponse);
}

/**
 * A method to get occupants HHI details from service api
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
function updateOccupantsHHIDetails(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/occupants/hhi/${id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A method to get occupants HHINCOME details from service api
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
function updateOccupantsHHINCOMEDetails(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/occupants/hhincome/${id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A method to update occupants HHINCOME details from service api
 *
 * @param {*} id contains id  of occupants
 * @returns
 */
function updateOccupantsMRD(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/occupants/mrd/${id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A service to call update occupants api
 *
 * @param {*} id contains _dof table 1
 * @param {*} formData contains update fields and values object
 * @returns
 */
function updateOccupantsGeneral(id, formData) {
    if (id) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/occupants/${id}?timeZone=${timeZone}`, requestOptions).then(handleResponse);
    }
}


/**
 * A service memthod to get associated projects, sites and buildings to add new occupants
 *
 */
function getAssocPjtConfig() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/occupants/new/config`, requestOptions).then(handleResponse);
}

/**
 * A service call method to get vacan / empty units
 *
 * @param {*} data object that contains { project_id, site_id, building_id - * }
 */
function getEmptyUnits(data) {
    const bid = data.building_id || 0;
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/units/vacant-search/${bid}`, requestOptions).then(handleResponse);
}

/**
 * A service call method to create / update (empty unit) unit occupants
 *
 * @param {*} unit_id
 * @param {*} data
 */
function moveInOccupant(unit_id, data) {
    if (unit_id) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${globalVariable.apiURL}/occupants/move-in/${unit_id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A method to get vacant units in the site
 *
 * @param {*} site_id
 * @returns
 */
function getVacantUnit(site_id) {
    if (site_id) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(site_id)
        };

        return fetch(`${globalVariable.apiURL}/occupants/vacant-units/${site_id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A service call method to create / update (empty unit) unit occupants
 *
 * @param {*} unit_id
 * @param {*} data
 */
function tranferUnit(table_id, data) {
    if (table_id) {
        const requestOptions = {
            method: 'POST',
            headers: { ...authHeader(), 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };

        return fetch(`${globalVariable.apiURL}/occupants/transfer/${table_id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A service call method to create / update (empty unit) unit occupants
 *
 * @param {*} unit_id
 * @param {*} data
 */
function getMoveInConfig(site_id = 0, building_id = 0, unit_id = 0, data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/movein-config/${site_id}/${building_id}/${unit_id}?timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 * A service call method to get occupant validation errors
 *
 * @param {*} table1_id
 * @param {*} reportYear_id
 */
function getOneValidationError(table1_id = null, reportYear_id = null) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/occupants/get-errors/${table1_id}/${reportYear_id}`, requestOptions).then(handleResponse);
}

/**
 * A service call method to get occupant validation errors
 *
 * @param {*} id contains talbe1 id to delete
 */
function deleteOccupant(id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    return fetch(`${globalVariable.apiURL}/occupants/delete/${id}`, requestOptions).then(handleResponse);
}

/**
 *  A service call method to Update IR
 * @param {*} project_id 
 * @param {*} site_id 
 * @param {*} building_id 
 * @param {*} table1_id 
 * @param {*} reportYear_id 
 * @returns 
 */
function updateIncomeRent(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.ms_apiURL}/update/income-rent-limit`, requestOptions).then(handleResponse);
}