import React from 'react';
import PropTypes from 'prop-types';
import { ValidatorComponent } from 'react-material-ui-form-validator';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Tooltip } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';



/**
 *Text Box Component
 *
 * @class CheckBox
 * @extends {Component}
 */
class CheckboxValidator extends ValidatorComponent {

    /**
     * Handle
     * TextField Events
     */
    handleChange = (event) => {
        let value = event.target.value?event.target.value:true;
        this.props.handleChange(this.props.name,value)
    };

    /**
     * Bind
     * HTML to DOM
     */
     renderValidatorComponent() {
        // State is form ValidatorComponent
        const { isValid } = this.state;
        let { name, value, checked, label, color, className, validators, disabled, toolTipTitle } = this.props;
        validators = validators || [];
        let customClass = validators && validators.length > 0 ? className : className;
        const errorClass = !isValid ? 'required-checkbox' : '';
        const defaultTheme = createTheme({
            overrides: {
                MuiCheckbox: {
                    root: {
                        '&$checked': {
                            color:'#36aebe !important',
                        }
                    }},
                    MuiTooltip: {
                        tooltip: {
                            backgroundColor: "grey",
                            color: "white",
                            fontSize: 13,
                            top:"50% !important",
                            marginBottom:"23px !important"
                        },
                        arrow: {
                            left: "5% !important",
                            marginLeft: "-5px"
                        }
                    }
                }
            });
        return (
            <MuiThemeProvider theme={defaultTheme}>
                <Tooltip
                    // open={focusVisible ? focusVisible : this.state.setOpen}
                    PopperProps={{
                        popperOptions: {
                            modifiers: {
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent" // where "window" is the boundary
                                }
                            }
                        }
                    }}
                    className="tooltiptip" title={toolTipTitle || ""} placement="top-start" arrow>

                    <FormControl required error={!isValid} className={`${customClass} ${errorClass}`}>
                        <FormControlLabel
                            control={

                                <Checkbox
                                    name={name}
                                    checked={checked}
                                    value={value}
                                    onChange={(e)=>this.handleChange(e)}
                                    disabled={disabled}
                                    color={color}
                                />

                            }
                            label={label}
                        />
                        <FormHelperText>{this.getErrorMessage()}</FormHelperText>
                    </FormControl>
                </Tooltip>
            </MuiThemeProvider>
        );
    }
}

// set default props
CheckboxValidator.defaultProps = {
    classes: {},
    name: '',
    value: null,
    checked: null,
    label: "",
    className: '',
    errorMessages: [],
    validators: [],
    disabled: false,
    handleChange: () => { },
    validatorListener: () => { }
}

//prop types
CheckboxValidator.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    disabled: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
};

//export component
export default CheckboxValidator