import { Grid, List, ListItem, Modal , Box ,Button, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { DisclaimerIcon } from "../../../../components/SvgIcons/SvgIcons";
import React, { Component } from "react";
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import "./style.css";
import { history } from "../../../../helpers";
import zIndex from "@mui/material/styles/zIndex";
import Upload from "./Upload";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

/**
 * Option Container
 * @class Option
 * @extends {Component}
 */
export class ErrorLog extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
      groupedErrorIndex: 0
    };
  }


  onDownloadError = (data) => {
    // console.log(this.props.uploadStatus.error.file);
    this.props.stateRes(this.props.uploadStatus.error.file);
  };
  render() {
    const { fileTypeError, uploadStatus, errorStatus, fileName, fileSize } =
      this.props;
    const groupByCategory =
      uploadStatus &&
      typeof uploadStatus?.error?.data === "object" &&
      uploadStatus.error?.data !== undefined &&
      uploadStatus.error?.data.length > 0 &&
      uploadStatus.error?.data.reduce((group, product) => {
        const { rowMsg } = product;
        group[rowMsg] = group[rowMsg] ?? [];
        group[rowMsg].push(product);
        return group;
      }, {});

      if (uploadStatus?.error?.data == undefined) {
        return <Redirect to='/configurations/upload/1' />
      }

    var errorList = [];
    
    var groupedError = [];
    for (var error in groupByCategory) {
      errorList.push(error);
    }
    errorList.map((data) => {
      groupedError.push({
        rowMsg: data,
        inst: groupByCategory[data][0].inst,
        solution: groupByCategory[data][0].solution,
        list: groupByCategory[data][0].list,
        rowNo: [],
        showMsg: groupByCategory[data][0].showMsg,
        count: groupByCategory[data].length,
        titleMsg: groupByCategory[data][0].titleMsg
      });

      function colConverter(number) {
        let result = "";
        // number = number - 1; // If starting from 1
        do {
          const letter = String.fromCharCode(65 + (number % 26));
          result = letter + result;
          number = Math.floor(number / 26) - 1;
        } while (number >= 0);
        return result;
      }

      groupByCategory[data].map((item) => {
        // item.rowNo
        groupedError[
          groupedError.findIndex((row) => row.rowMsg === data)
        ].rowNo.push(
          colConverter(item.position.column) +
            "-" +
            item.position.rowNo +
            ":" +
            item.position.cellData
        );
      });
    });

    const stripHtml = html => html.replace(/<[^>]*>?/gm, '');

    function getErrorBasedOnCategory (index){
        const titleMsg = groupedError[index]?.titleMsg;
        const arrayFromGroupCategory = groupByCategory[groupedError[index]?.rowMsg];
        const listFromGroupCategory = arrayFromGroupCategory.filter((x) => stripHtml(x.inst) != titleMsg).map((el) => stripHtml(el.inst));
        console.log(listFromGroupCategory)
        return listFromGroupCategory;
    }

    //----------------------
    uploadStatus?.error?.data !== undefined &&
      uploadStatus?.error?.data?.length === undefined &&
      groupedError.push({
        description: uploadStatus?.error?.data?.description,
        lineNumber: uploadStatus?.error?.data?.lineNumber,
        column: uploadStatus?.error?.data?.column,
      });

    return  (
      <div
        style={{
          marginTop: "30px",
          backgroundColor: "#ffff",
          borderRadius: "10px",
          fontWeight: "600",
          minHeight: "300px",
        }}
      >
        {/* blue box */}
        
        <div
          style={{
            minHeight: "110px",
            width: "100%",
            border: "0.5px solid #7ceaf7",
            backgroundColor: "#f0feff",
            // display: "flex",
            flexDirection: "column",
            // gap: "20px",
          }}
        >
          <div
            style={{
              border: "0.5px solid red",
              minHeight: "50px",
              width: "90%",
              marginTop: "20px",
              marginLeft: "5%",
              backgroundColor:'#fff'
            }}
          >
            <Grid item xs={12} sm={12} style={{ padding: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  {fileName}
                </Grid>
                <Grid item xs={12} sm={5}>
                  {fileSize.toFixed(2)} KB
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  style={{
                    backgroundColor: "#ffd9d9",
                    color: "red",
                    textAlign: "center",
                    height:'33px'
                  }}
                >
                  Validation Error
                  {/* &#9888;  */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-exclamation-circle"
                    viewBox="0 0 16 16"
                    style={{ marginBottom: "-3px", marginLeft: "10px" }}
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ textAlign: "left", marginLeft: "5%", marginTop: '10px' }}>
            The file uploaded contains validation errors. Please resolve and re-upload
          </div>
        </div>
        
        <div>
          <Grid item xs={12} sm={12} style={{ padding: "15px 0px 0px 20px", overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: "grey white" ,height :"373px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                ERROR DETAILS{" "}
                {fileTypeError === null &&
                  typeof errorStatus?.data !== "string" &&
                  groupedError &&
                  "(" + groupedError.length + ")"}
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                style={{
                  textAlign: "right",
                }}
              >
                <button
                  style={{
                    color: "#36aebe",
                    border: "none",
                    backgroundColor: "#ffff",
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginRight: "35px",
                    fontWeight: "700",
                    minWidth: "110px",
                    // marginTop:'20px'
                  }}
                  onClick={() => this.onDownloadError()}
                >
                  Download Error Log
                </button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <List
                  style={{
                    overflowY: 'auto',
                    height:'250px'
                  }}
                >
                  {fileTypeError === null &&
                    errorStatus &&
                    typeof errorStatus === "object" &&
                    groupedError.length > 0 &&
                    groupedError.map((err, index) => {
                        return (
                          <ListItem >
                            <div style={{ display: 'flex', justifyContent: 'space-between', borderRadius: '10px', flex: "1", }}>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#f2cbd1', width: '43px', alignItems: 'center', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-exclamation-circle"
                                  viewBox="0 0 16 16"
                                  style={{
                                    marginBottom: "-3px",
                                    color: "red",
                                    flex: "none",
                                  }}
                                >
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                              </div>
                              <div style={{ backgroundColor: '#fae8eb', width: "100%", borderTopRightRadius: '10px' }}>
                                <div style={{  padding: '10px' }}>
                                  { console.log("ERROR MSG",err)}
                                {
                                  err.rowMsg ? 
                                  (
                                  <>
                                    <span style={{ fontStyle : "italic", fontWeight: '600' }} dangerouslySetInnerHTML={{ __html: err?.titleMsg }} /> 
                                    {
                                        getErrorBasedOnCategory(index).slice(0,2).map((err,index) => {
                                        if (index == 1) {
                                          return <div style={{paddingLeft: '9px',fontStyle: 'italic',fontWeight: '500', display: 'inline'}}>{index+1}. {err}</div>
                                        }else {
                                          return <div style={{paddingLeft: '9px',fontStyle: 'italic',fontWeight: '500'}}>{index+1}. {err}</div>
                                        }
                                      })
                                    }
                                    {
                                      err.count > 2 && (<button
                                        style={{
                                          color: "#36aebe",
                                          border: "none",
                                          backgroundColor: "#fae8eb",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                          marginLeft: "25px",
                                          fontWeight: "700",
                                          fontStyle : "italic",
                                          display: 'inline'
                                        }}
                                        onClick={() => this.setState({ showMore: true, groupedErrorIndex: index })}
                                      >
                                      Show More
                                      </button>)
                                   }
                                  </>
                                  )
                                  : "Failed to import"
                                  
                                }

                                {/* {
                                  getErrorBasedOnCategory(index).slice(0,2).map((err,index) => {
                                    if (index == 1) {
                                      return <div style={{paddingLeft: '9px',fontStyle: 'italic',fontWeight: '500', display: 'inline'}}>{index+1}. {err}</div>
                                    }else {
                                      return <div style={{paddingLeft: '9px',fontStyle: 'italic',fontWeight: '500'}}>{index+1}. {err}</div>
                                    }
                                  })
                                } */}

                                {/* {
                                  err.count > 2 && (<button
                                    style={{
                                      color: "#36aebe",
                                      border: "none",
                                      backgroundColor: "#fae8eb",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      marginLeft: "25px",
                                      fontWeight: "700",
                                      fontStyle : "italic",
                                      display: 'inline'
                                    }}
                                    onClick={() => this.setState({ showMore: true, groupedErrorIndex: index })}
                                  >
                                   Show More
                                  </button>)
                                } */}

                                {/* {err.showMsg ? <span style={{ fontStyle : "italic", fontWeight: '400'}} dangerouslySetInnerHTML={{ __html: err.showMsg }} /> : "Failed to import"} 
                                  { err.count > 2 && (<button
                                      style={{
                                        color: "#36aebe",
                                        border: "none",
                                        backgroundColor: "#fae8eb",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        marginLeft: "25px",
                                        fontWeight: "700",
                                        fontStyle : "italic",
                                        display: 'inline'
                                      }}
                                      onClick={() => this.setState({ showMore: true, groupedErrorIndex: index })}
                                    >
                                     Show More
                                  </button>)} */}
                                </div>

                                {err.rowNo && (
                                  <div style={{ borderTop: '1px solid #e3dcde', padding: '7px' }}>
                                    <span style={{fontWeight:'700', fontStyle : "italic"}}>Solution: </span>
                                    <span style={{fontWeight:'500', fontStyle : "italic"}} dangerouslySetInnerHTML={{ __html: err.solution }} />
                                  </div>
                                )}
                              </div>
                            </div>
                          </ListItem>
                        );
                    })}
                </List>
              </Grid>
          </Grid>
          
        </Grid>
        
        </div>
        
        <Dialog
          open={this.state.showMore}
          fullWidth
          maxWidth="sm"
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
              <div style={{display:'flex', fontStyle: 'italic', justifyContent:'space-between'}}>
                <div style={{display: 'flex', gap: '10px'}}>
                  <ErrorOutlineIcon style={{fontSize: '18px', color: 'red'}} />
                  <span style={{fontSize: '15px', fontWeight: 600}} dangerouslySetInnerHTML={{ __html: groupedError[this.state.groupedErrorIndex]?.titleMsg }} />
                </div>
                
                <div style={{ cursor:'pointer'}} onClick={() => { this.setState({showMore: false}) }}>
                      <CloseIcon style={{fontSize:'18px', color: '#000'}} />
                </div>
              </div>
            </DialogTitle>

            <DialogContent>
              <div style={{background: '#FAE8EB',padding:'4px', fontSize: '13.5px', fontStyle: 'italic'}}>
                <div style={{paddingBottom: '8px',paddingTop:'8px'}}>
                  {
                    getErrorBasedOnCategory(this.state.groupedErrorIndex).map((err,index) => {
                        return <div style={{paddingLeft: '9px'}}>{index+1}. {err}</div>
                    })
                  }
                 </div>
                <div style={{ borderTop: '2px solid #e3dcde', paddingTop: '10px', paddingBottom: '30px'}}>
                   <span style={{fontWeight: '700'}}> Solution: </span>
                   <span dangerouslySetInnerHTML={{ __html: groupedError[this.state.groupedErrorIndex]?.solution }} />
                </div>
              </div>
            </DialogContent>
        </Dialog>
      </div>
    )
  }
}
