import { Component } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-faux-dom';
import * as d3 from "d3";

// Import Style
import './HorizontalBarChart.css';

// Import Components
import ResponsiveWrapper from '../ResponsiveWrapper';

/**
 *Bar chart component
 *
 * @class HorizontalBarChart
 * @extends {Component}
 */
class HorizontalBarChart extends Component {
    /**
     * chart plot marking
     * @param {*} chart 
     * @param {*} width 
     * @param {*} height 
     * @param {*} margin 
     */
    plot(chart, width, height, margin) {

        height = height - 150;
        let data = this.props.data;

        var lablePos = [ 'translate(-80, 90)rotate(-90)', 'translate(-54, 111)rotate(-90)']
        let tooltip = d3.select(".toolTip");

        let xScale = d3.scaleBand().rangeRound([0, width]).padding(0.3),
            yScale = d3.scaleLinear().rangeRound([height, 0]);

        let colours = d3.scaleOrdinal().range(['#6EE4D8', "#C7C8CE"]);

        xScale.domain(data.map(function (d) { return d.title; }));
        yScale.domain([0, d3.max(data, function (d) { return d.value; }) + 2]);

        data.forEach(function (d) {
            d.xValue = d.title;
            d.yValue = +d.value;
        });
        chart.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", "translate(0," + height + ")")
            // .call(d3.axisBottom(xScale))
            .selectAll("text")
            .attr("y", 0)
            .attr("x", 9)
            .attr("dy", ".35em")
            .style("transform", "rotate(300deg) translate(-60px,0)")
            .style("text-anchor", "start")
            .style('font-size', '12px');

        chart.selectAll(".bar")
            .data(data)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function (d) { return xScale(d.xValue); })
            .attr("y", function (d) { return yScale(d.yValue); })
            .attr("width", xScale.bandwidth() - 4)
            .attr("height", function (d) { return height - yScale(d.yValue) })
            .attr("transform", "translate(0, 150)")
            .on("mousemove", function (d) {
                tooltip
                    .style("z-index", '99999')
                    .style("left", d3.event.pageX - 35 + "px")
                    .style("top", d3.event.pageY - 70 + "px")
                    .style("display", "inline-block")
                    .html(d.xValue + "<br><span>" + d.yValue + "</span>");
            })
            .on("mouseout", function (d) { tooltip.style("display", "none"); });

        chart.selectAll(".bar")
            .attr("fill", function (d) { return colours(d.xValue); });

        // Controls the text labels at the top of each bar. Partially repeated in the resize() function below for responsiveness.
        chart.selectAll(".text")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("x", function (d) { return xScale(d.xValue); })
            .attr("y", function (d) { return 100; })
            .attr("width", xScale.bandwidth() - 4)
            .attr("height", function (d) { return height - yScale(d.yValue) })
            .text(function (d) { return d.value + ' ' + d.title; })
            .attr("text-anchor", "middle")
            .attr('transform', function(d,i) {
                return lablePos[i]
            })
            .style('font-size', '.875rem')

    }
    /**
     * Draw Chart
     * @returns 
     */
    drawChart() {

        const el = new Element('div');
        const svgDimensions = {
            //selects the maximum width from the two parameters passed
            width: Math.max(this.props.parentWidth, window.innerWidth / 7.7),
            height: 60
        };

        d3.select('.horizontalBarChart').selectAll("*").remove();

        let svg = d3.select(el)
            .append('svg')
            .attr("width", svgDimensions.width)
            .attr("height", svgDimensions.height);

        let margin = { top: 0, right: 0, bottom: 0, left: 0 },
            height = +svgDimensions.width - margin.left  - margin.right,
            width = svgDimensions.height - margin.top - margin.bottom;

        let chart = svg.append("g")
            .attr("transform", "translate("+ height + ", 10)rotate(90)");

        this.plot(chart, width, height, margin);

        return el.toReact();
    }

    /**
     *render html
     *
     * @returns
     * @memberof HorizontalBarChart
     */
    render() {
        return this.drawChart();
    }
}

//prop types
HorizontalBarChart.propTypes = {
    data: PropTypes.array.isRequired
};

//default props
HorizontalBarChart.defaultProps = {
    data: []
}

/*Export Component*/
export default ResponsiveWrapper(HorizontalBarChart);
