import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//import components
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import FilterAttribute from './FilterAttribute';
import ExploreMenu from './ExploreMenu';
import ExplorePreview from './Preview';
import PageLoader from '../../components/PageLoader/PageLoader';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import ExploreForm from './ExploreForm';

// Actions
import { exploreActions } from '../../actions/explore';
import { alertActions, dashboardActions } from '../../actions';

//Import Styles
import exploreStyle from '../../assets/jss/containers/explore';

//Import helpers
import { getUserType, getUserOrgId, getUserOrgAccess ,getUserId} from '../../helpers';
import { capitalizeFirstLetter, getSubChildCardHeight } from '../../helpers/global';
import { arithmeticOperators, history } from '../../helpers';
import { arrayMoveImmutable } from 'array-move';

/**
 * Explore Container
 * @class Explore
 * @extends {Component}
 */
class Explore extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            selectedColumns: [],
            _id: null,
            template: 0,
            reportName: '',
            reportYear: new Date().getFullYear(),
            headers: [],
            isMenuOpen: true,
            isPreviewOpen: true,
            isFilterOpen: false,
            checkedSystemFields: [],
            selectedFields: [],
            selectedMenuGroupKey: [],
            filterAttribute: [],
            previewFields: [],
            arithmeticColumns: [],
            dataSet: {},
            isTemplateAsset: null,
            canDownload: false,
            filterType: 'and',
            checkModified: false,
            reportFilter: 'Ownership Organization'
        }
        this.userRole = getUserType();
        this.org_id = getUserOrgId();
        this.formRef = React.createRef();
        this.filterRef = React.createRef();
        this.org_access = getUserOrgAccess()
        this.userId = getUserId();
    }
    /**
     * Component Did Mount Event
     * 
     * [1]  -   Get Predefined template
     * [2]  -   Get Select Columns
     * [3]  -   Clear already fetched preview data
     */
    componentDidMount() {
        // [1]
        this.props.getFunderReports();
        // [2]
        this.props.getCustomReportsMenu(this.state.reportYear);
        // [3]
        this.props.explorePreview(null)
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps
     * 
     * [1]  -   Set report templates to state
     * [2]  -   Remove predefined tempalte for non-funder users
     * [3]  -   To remove rent burden report from funder reports
     * [4]  -   Set the state value if it is edit
     * [5]  -   Push selectedColumn into selectedFields if field in not in the array
     *          Push the collection in selectedColumn into checkedSystemFields if collection is not found in the array
     * [6]  -   Assign header in selectedColumn to headers
     * [7]      If the selected explore data is predefined template, the assign field for previewFields, else assign header.
     * [8]  -   Push arithmeticColumns header and field to headers and previewFields
     * [9]  -   With first value of checkedSystemFields(Collection name), defined the selected menu group
     * [10] -   Preview route is called
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, selectedExplore } = nextProps;
        const { userRole, org_id, org_access } = this;
        let { _id, reportName, template, reportYear, checkedSystemFields, selectedFields, dataSet, filterAttribute, reportFilter } = this.state;
        // [1]
        if (getOne.result !== "" && getOne.result !== undefined && getOne.result !== null) {
            !getOne.result.some(elem => elem.key === 0) && getOne.result.push(
                {
                    key: 25,
                    title: 'PSRC Report'
                },
                {
                    key: 29,
                    title: 'Unit Vacancy Details'
                },
                {
                    key: 0,
                    title: 'Custom Template'
                }
            );
            // [2]
            if (userRole !== 'Funder') {
                getOne.result = getOne.result.filter(item => item.key === 18 || item.key === 0);
            }
             else {
                // [3]
                //getOne.result = getOne.result.filter(item => item.key !== 26);
            }
            if (org_access === 2) {
                getOne.result = [
                    {
                        key: 0,
                        title: 'Custom Template'
                    },{
                        key: 18,
                        title: 'Homeownership Submission Report'
                    }
                ]
            }

            this.setState({
                reportsName: getOne.result,
                yearField: getOne.year
            });
        }
        _id = this.props._id;
        // [4]
        if (this.props._id && Object.keys(selectedExplore).length !== 0) {
            this.setState({ reportFilter: selectedExplore.reportFilter}) 
            let headers = [];
            let previewFields = [];
            let stateVariables = {};
            if (Object.keys(dataSet).length === 0) {
                _id = selectedExplore._id
                reportName = selectedExplore.report_name;
                reportYear = selectedExplore.reportYear;
                template = selectedExplore.template === null ? 0 : selectedExplore.template;
                filterAttribute = selectedExplore.filterAttribute;
                reportFilter = selectedExplore.reportFilter;

                console.log("========== REPORT FILTER ======", this.state.reportFilter)
                // [5]
                const selectedDataSets = {
                    "projects_hos": "Homeownership Projects",
                    "contracts_hos": "Homeownership Contracts",
                    "reportYears_hos": "Homeownership Report Activity",
                    "funderProjects_hos": "Homeownership Funder Settings",
                    "programactivity_hos": "Homeownership Program Activity",
                    "financialactivity_hos": "Homeownership Financial Activity",
                    "contractactivity_hos": "Homeownership Contract Activity"
                }
                selectedExplore.selectedColumns.forEach(field => {
                    !field.collection ? field.collection = field.collectionName : field.collectionName = field.collection
                    let index = selectedFields.findIndex(elem => elem.title === field.title);
                    let cIndex = checkedSystemFields.findIndex(elem => elem === field.collection);
                    index === -1 && selectedFields.push(field);
                    if (cIndex === -1) {
                        if (selectedDataSets[field.collection]) {
                            if (!checkedSystemFields.includes(selectedDataSets[field.collection])) {
                                checkedSystemFields.push(selectedDataSets[field.collection])
                            }
                        }
                        else
                            checkedSystemFields.push(field.collection)
                    }
                })
                // [6]
                headers = selectedFields.map(item => item.header);
                // [7]
                if (selectedExplore.template) {
                    previewFields = selectedFields.map(item => item.field)
                } else {
                    previewFields = selectedFields.map(item => item.header)
                }
                // [8]
                if (selectedExplore.arithmeticColumns && selectedExplore.arithmeticColumns.length > 0) {
                    selectedExplore.arithmeticColumns.forEach(elem => {
                        headers.push(elem.header)
                        if (selectedExplore.template) {
                            previewFields.push(elem.field)
                        } else {
                            previewFields.push(elem.header)
                        }
                    })
                }
                // [9]
                this.selectedMenuGroup(checkedSystemFields[0], true)
                dataSet = {
                    _id: _id,
                    reportName: reportName,
                    reportYear: reportYear,
                    template: template || '',
                    selectedColumns: selectedExplore.selectedColumns,
                    arithmeticColumns: selectedExplore.arithmeticColumns,
                    filterAttribute: filterAttribute,
                    userRole: userRole,
                    org_id: org_id,
                    filterType: selectedExplore.filterType,
                    reportFilter: reportFilter
                }

                stateVariables = {
                    _id: _id,
                    reportName: reportName,
                    reportYear: reportYear,
                    template: template,
                    filterAttribute: filterAttribute,
                    checkedSystemFields: checkedSystemFields,
                    selectedColumns: selectedExplore.selectedColumns,
                    arithmeticColumns: selectedExplore.arithmeticColumns,
                    headers: headers,
                    previewFields: previewFields,
                    dataSet: dataSet,
                    canDownload: selectedExplore.downloadStatus === 'Completed' ? true : false,
                    filterType: selectedExplore.filterType,
                    reportFilter: reportFilter
                    // reportFilter: this.props.reportFilter ? this.props.reportFilter : this.state.reportFilter
                }
                this.setState(stateVariables)
                    // [10]
                this.props.explorePreview(dataSet)

                console.log("============= THIS STATE REPORT FILTER ========",this.state)
            }
        }
    }
    /**
     * Handle Back Action Click
     * @memberof ContentWrapper
     */
    handleBackActionClick = () => {
        history.goBack();
    };
        /**
     * Updated data from Filter
     * @param {*} updatedData - Object
     * @param {*} type - String
     * @memberof FilterAttribute
     * 
     * [1]  -   Update newDataSet only if updatedData has any filterAttribute
     * [2]  -   If the updatedData has arithmeticOperators, then push the arithmeticConditions into newDataSet else empty the arithmeticConditions
     * [3]  -   Assign header in selectedColumn to headers
     * [4]      If the selected explore data is predefined template, the assign field for previewFields, else assign header.
     * [5]  -   Push arithmeticColumns header and field to headers and previewFields
     * [6]  -   If update type is preview, then call preview api, else call submit function
     */
    updatedPreviewData = (updatedData, type) => {
        let { reportName, reportYear, template, _id, selectedFields, dataSet, isTemplateAsset, previewFields, headers, arithmeticColumns } = this.state
        const { userRole, org_id } = this;
        let newDataSet = {
            _id: _id || dataSet._id,
            report_name: reportName || dataSet.reportName,
            reportYear: reportYear || dataSet.reportYear,
            template: template || dataSet.template,
            selectedColumns: selectedFields || dataSet.selectedColumns,
            arithmeticColumns: arithmeticColumns,
            filterAttribute: updatedData.rows,
            isTemplateAsset: isTemplateAsset,
            userRole: userRole,
            org_id: org_id,
            filterType: updatedData.filterType,
            reportFilter: updatedData.reportFilter || ''
        }
        // [1]
        if (updatedData.rows.length > 0) {
            // [2]
            if (updatedData.rows.some(elem => arithmeticOperators.includes(elem.condition))) {
                let arithmeticConditions = updatedData.rows.filter(elem => arithmeticOperators.includes(elem.condition))
                newDataSet.arithmeticColumns = [];
                arithmeticConditions.forEach((item, index) => {
                    newDataSet.arithmeticColumns[index] = {
                        "title": `${item.attributes.title} ${capitalizeFirstLetter(item.condition)}`,
                        "header": `${item.attributes.header} ${capitalizeFirstLetter(item.condition)}`,
                        "collection": item.attributes.collection,
                        "field": `${item.attributes.title} ${capitalizeFirstLetter(item.condition)}`,
                        "collectionName": item.attributes.collection
                    }
                })
            } else {
                newDataSet.arithmeticColumns = [];
            }
            // [3]
            headers = selectedFields.map(item => item.header)
            // [4]
            if (template) {
                previewFields = selectedFields.map(item => item.field)
            } else {
                previewFields = selectedFields.map(item => item.header)
            }
            // [5]
            if (newDataSet.arithmeticColumns && newDataSet.arithmeticColumns.length > 0) {
                newDataSet.arithmeticColumns.forEach(elem => {
                    headers.push(elem.header)
                    if (template) {
                        previewFields.push(elem.field)
                    } else {
                        previewFields.push(elem.header)
                    }
                })
            }
        }
        this.setState({ dataSet: newDataSet, selectedFields: newDataSet.selectedColumns, previewFields: previewFields, arithmeticColumns: newDataSet.arithmeticColumns, headers: headers, filterAttribute: updatedData.rows, filterType: updatedData.filterType });
        // [6]
        if (newDataSet.selectedColumns.length > 0)
            type === 'preview' ? this.props.explorePreview(newDataSet) : this.handleSubmit();
        else
            this.props.showErrorAlert("Please select atleast one column.");
    }
    /**
     * Handle Download Action Click
     * @memberof ContentWrapper
     * 
     * [1]  -   Explore file can be downloaded only if download status is 'Completed'
     */
    handleDownloadActionClick = () => {
        const { reportName, canDownload } = this.state;
        // [1]
        if (!canDownload) {
            this.props.showErrorAlert("Please wait... Download is in Progress...");
        }
        else {
            this.props.clearErrorAlert()
            if (this.props._id) {
                this.props.downloadExploreReport(this.props._id, null, reportName);
            }
        }
    }
    /**
     * Handle Change
     * @param {*} name 
     * @param {*} value 
     * @memberof ExploreForm
     * 
     * [1]  -   If the name is 'template', then clear the filterAttribute, update selectedGroupKey, and clear checkedSystemFields, selectedFields, previewFields, headers if any.
     * [2]  -   If the name is 'reportYear, then updated the fields which are based on report year in selectedFields.
     */
    handleChange = (name, value) => {
        let { explore } = this.props;
        let { checkedSystemFields, selectedFields, headers, previewFields, selectedMenuGroupKey, filterAttribute } = this.state;
        let selectedGroupKey = [];

        if (name === 'reportFilter'){
            this.setState({reportFilter:value})
        }

        // [1]
        if (name === 'template') {
            this.setState({template:value})
            filterAttribute = [];
            switch (value) {
                case 22:
                    selectedGroupKey = ['group3'];
                    this.handleKeyCheckBox('Asset Management Report', true)
                    break;
                case 29:
                    selectedGroupKey = ['group9'];
                    this.handleKeyCheckBox('Unit Vacancy Details', true)
                    break;
                case 6:
                    selectedGroupKey = ['group4'];
                    this.handleKeyCheckBox('Table4 Performance Measures - For Funder', true)
                    break;
                case 8:
                    selectedGroupKey = ['group5'];
                    this.handleKeyCheckBox('Table4 Performance Measures - All Funders', true)
                    break;
                case 13:
                    selectedGroupKey = ['group6'];
                    this.handleKeyCheckBox('Submission Status for Funder v1 (Current Funder)', true)
                    break;
                case 14:
                    selectedGroupKey = ['group7'];
                    this.handleKeyCheckBox('Tables 2 - 4 Report Summary', true)
                    break;
                case 15:
                    selectedGroupKey = ['group8'];
                    this.handleKeyCheckBox('Table 1 - Export All for Year', true)
                    break;
                case 25:
                    selectedGroupKey = ['group11'];
                    this.handleKeyCheckBox('PSRC Report', true)
                    break;
                case 12:
                    selectedGroupKey = ['group14'];
                    this.handleKeyCheckBox('Submission Status v2 (All Funders)', true);
                    break;
                case 28:
                    selectedGroupKey = ['group15'];
                    this.handleKeyCheckBox('Commerce Portfolio', true);
                    break;
                case 5:
                    selectedGroupKey = ['group16'];
                    this.handleKeyCheckBox('Race-Ethnicity Report', true);
                    break;
                case 26:
                    selectedGroupKey = ['group17'];
                    this.handleKeyCheckBox('Rent Burden Report',true);
                    break;
                case 18:
                    selectedGroupKey = ['group18'];
                    this.handleKeyCheckBox('Homeownership Submission Report', true);
                    break;
    
                default:
                    selectedGroupKey = ['group1', 'group2', 'group10', 'group12', 'group13'];
                    this.handleKeyCheckBox('', true);
                    break;
            }
            if (headers.length > 0) {
                this.setState({ [name]: value, selectedMenuGroupKey: selectedGroupKey, filterAttribute: filterAttribute });
            } else {
                this.setState({ [name]: value, selectedMenuGroupKey: selectedGroupKey, filterAttribute: filterAttribute });
            }
        } else if (name === 'reportYear') {
            // [2]
            selectedGroupKey = selectedMenuGroupKey;

            if (selectedMenuGroupKey.length > 0 && selectedMenuGroupKey.indexOf('group16') != -1 ) {
                
                let test = /Total Number Of Occupied Units/i;

                let fieldName = `Total Number Of Occupied Units (as of 12/31/${value.toString().slice(-2)})`

                let index = headers.indexOf(headers.filter(x => test.test(x))[0]);

                if (index != -1) {
                    headers[index] = fieldName;
                }

            }

            this.props.getCustomReportsMenu(value)
            this.setState({ [name]: value, selectedMenuGroupKey: selectedGroupKey, headers: headers });
        } else {
            selectedGroupKey = selectedMenuGroupKey
            this.setState({ [name]: value, selectedMenuGroupKey: selectedGroupKey });
        }
    }

    getTemplate = (dataSet, template) =>  {
        return template || template === 0 ? template : dataSet.template;
    }
    /**
     * Handle Submit
     * 
     * [1]  -   If the dataSet has arithmeticOperators, then push the arithmeticConditions into dataSet else empty the arithmeticConditions
     * [2]  -   Assign header in selectedColumn to headers
     * [3]      If the selected explore data is predefined template, the assign field for previewFields, else assign header.
     * [4]  -   Push arithmeticColumns header and field to headers and previewFields
     * [5]  -   If predefined template call saveTemplateData api else saveCustomTemplateData api
     */
    handleSubmit = () => {
        let { dataSet, reportName, reportYear, template, _id, selectedFields, filterAttribute, isTemplateAsset, filterType, previewFields, headers, arithmeticColumns, reportFilter } = this.state;
        if(template===26 && previewFields.indexOf(reportFilter)===-1){
            previewFields.push(reportFilter)
            headers.push(reportFilter)
            selectedFields.push({
                "title": reportFilter,
                "collection": "Rent Burden Report",
                "field": reportFilter,
                "isArithmetic": false,
                "header": reportFilter,
                "collectionName": "Rent Burden Report"
            })
        }
        const { userRole, org_id , userId } = this;
        dataSet = {
            _id: _id || dataSet._id,    
            report_name: reportName || dataSet.reportName,
            reportYear: reportYear || dataSet.reportYear,
            template: template || template === 0 ? template : dataSet.template,
            selectedColumns: selectedFields || dataSet.selectedColumns,
            arithmeticColumns: arithmeticColumns || dataSet.arithmeticColumns,
            filterAttribute: filterAttribute,
            isTemplateAsset: isTemplateAsset,
            userRole: userRole,
            org_id: org_id,
            filterType: filterType,
            userId: this.getTemplate(dataSet, template) === 18 ? userId : null
        }

        let hasNoError = false
        // [1]
        if (dataSet.filterAttribute.length > 0) {
            if (dataSet.filterAttribute.some(elem => arithmeticOperators.includes(elem.condition))) {
                let arithmeticConditions = dataSet.filterAttribute.filter(elem => arithmeticOperators.includes(elem.condition))
                arithmeticConditions.forEach((item, index) => {
                    dataSet.arithmeticColumns[index] = {
                        "title": `${item.attributes.title} ${capitalizeFirstLetter(item.condition)}`,
                        "header": `${item.attributes.header} ${capitalizeFirstLetter(item.condition)}`,
                        "collection": item.attributes.collection,
                        "field": `${item.attributes.title} ${capitalizeFirstLetter(item.condition)}`,
                        "collectionName": item.attributes.collection
                    }
                })
            } else {
                dataSet.arithmeticColumns = [];
            }
            // [2]
            headers = selectedFields.map(item => item.header)
            // [3]
            if (template) {
                previewFields = selectedFields.map(item => item.field);
            } else {
                previewFields = selectedFields.map(item => item.header);
            }
            // [4]
            if (dataSet.arithmeticColumns && dataSet.arithmeticColumns.length > 0) {
                dataSet.arithmeticColumns.forEach(elem => {
                    headers.push(elem.header)
                    if (template) {
                        previewFields.push(elem.field)
                    } else {
                        previewFields.push(elem.header)
                    }
                })
            }

            if (!dataSet.filterAttribute.some(elem => Object.keys(elem.attributes).length > 0 && elem.condition && elem.value.length > 0)) {
                hasNoError = true
            }
        }

        if (template == 5 || template === 26) {
            dataSet.reportFilter = reportFilter;
        }
        
        // [5]
        if (!hasNoError) {
            if (dataSet.template) {
                console.log('Ecntered1#########')
                console.log('DATASET%%%%%%%%%%%%%%%%%%%%%',dataSet)
                this.props.saveTemplateData(dataSet)
                history.push(`/explore`);
            } else if (dataSet.selectedColumns.length > 0) {
                console.log('Ecntered2#########')
                this.props.saveCustomTemplateData(dataSet);
                history.push(`/explore`);
            } else {
                this.props.showErrorAlert("Please select atleast one column.");
            }
        } else {
            this.props.showErrorAlert("Filter fields can't be empty")
        }

        //dataSet.template ? this.props.saveTemplateData(dataSet) : this.props.saveCustomTemplateData(dataSet);
        //history.goBack();
    }

    /**
     * Handle selected Fields on handleKeyCheckBox or handleFieldCheckBox
     * @param {array} datas
     * 
     * [1]  -   Set headers and previewFields
     *          If predefined template, then push field as previewFields, else push header as previewFields
     */
    handleSelectedFields = (datas) => {
        let { reportName, reportYear, template, _id, dataSet, isTemplateAsset } = this.state;
        let headers = [];
        let previewFields = [];
        // [1]
        datas.forEach(item => {
            headers.push(item.header)
            if (template) {
                previewFields.push(item.field)
            } else {
                previewFields.push(item.header)
            }
        })
        dataSet = {
            _id: _id,
            reportName: reportName,
            reportYear: reportYear,
            template: template,
            selectedColumns: datas,
            filterAttribute: dataSet.filterAttribute || [],
            arithmeticColumns: dataSet.arithmeticColumns || [],
            isTemplateAsset: isTemplateAsset
        }
        if (headers.length === 0) {
            this.props.explorePreview(null)
        }
        this.setState({ headers: headers, previewFields: previewFields, dataSet: dataSet })
    }
    /**
     * Handle preview button click
     * @memberof ExploreMenu
     * 
     * [1]  -   Call preview api on preview button click
     */
    handlePreview = () => {
        const { userRole, org_id } = this;
        const { dataSet, reportYear, isTemplateAsset,template,reportFilter } = this.state;
        dataSet.reportYear = reportYear;
        dataSet.isTemplateAsset = isTemplateAsset;
        dataSet.userRole = userRole;
        dataSet.org_id = org_id;
        dataSet.template = template;

        if (template == 5){
            dataSet.reportFilter = reportFilter;
        }
        // [1]
        if (dataSet.selectedColumns && dataSet.selectedColumns.length > 0)
            this.props.explorePreview(dataSet);
        else
            this.props.showErrorAlert("Please select atleast one column.");
    }

    /**
     * Handle Selected group key from Menu
     * @param {string} key
     * @memberof ExploreMenu
     * 
     * [1]  -   If key is found in checkedSystemFields, then remove in from the array, else push it into the array.
     * [2]  -   Remove the field in selectedFields
     * [3]  -   Push the field into selectedFields
     * [4]  -   If checkedSystemFields has any value handle selectedMenuGroup with true, else false.
     *          true - seleceted
     *          false - deselected
     * [5]  -   call handleSelectedFields 
     */
    handleKeyCheckBox = (key, reset = false) => {
        let { explore } = this.props;
        let { checkedSystemFields, selectedFields, checkModified } = this.state;
        // [1]
        let index = checkedSystemFields.findIndex(elem => elem === key);
        if (index > -1) {
            checkedSystemFields.splice(index, 1);
            // [2]
            key && explore[key].forEach(data => {
                let fieldIndex = selectedFields.findIndex(elem => elem.field === data.field && elem.collection === data.collection);
                if (fieldIndex > -1) {
                    let fieldFilters = ['City','County','Funder','Zipcode', 'Ownership Organization', 'Property Management Organization']
                    let fieldFiltersRentBurden = ['City', 'County','Funder', 'Zipcode', 'Ownership Organization', 'Property Management Organization','State'];
                    if (data.collection != 'Race-Ethnicity Report' || fieldFilters.indexOf(data.field) === -1) {
                        selectedFields.splice(fieldIndex, 1)
                    }

                    if (data.collection != 'Rent Burden Report' || fieldFiltersRentBurden.indexOf(data.field) === -1) {
                        selectedFields.splice(fieldIndex, 1)
                    }
                    
                }
            })
        } else {
            if (reset) {
                checkedSystemFields = [];
                selectedFields = [];
            }
            key && checkedSystemFields.push(key)
            // [3]
            key && explore[key].forEach(data => {
                let fieldIndex = selectedFields.findIndex(elem => elem.field === data.field && elem.collection === data.collection);
                if (fieldIndex === -1) {
                    selectedFields.push(data)
                }
            })
        }
        // [4]
        checkedSystemFields.length > 0 ? this.selectedMenuGroup(checkedSystemFields[0], true) : this.selectedMenuGroup(key, false)
        const homePurchasedAddressIndex = selectedFields.findIndex(elem => elem.header === 'Home purchased address')
        if (homePurchasedAddressIndex > -1) {
            if (!checkModified) {
                selectedFields = arrayMoveImmutable(selectedFields, homePurchasedAddressIndex, -1);
            }
        } else {
            checkModified = false
        }
        this.setState({ checkedSystemFields: checkedSystemFields, selectedFields: selectedFields, checkModified: checkModified });
        // [5]
        this.handleSelectedFields(selectedFields)
    }

    /**
     * Handle selected Fields of group from Menu
     * @param {object} item
     * @memberof ExploreMenu
     * 
     * [1]  -   If item is found in selectedFields, then remove in from the array, else push it into the array.
     * [2]  -   Remove the collection in checkedSystemFields
     * [3]  -   Push the collection into checkedSystemFields
     * [4]  -   If selectedFields has any value, then handle selectedMenuGroup with true, else false.
     *          true - seleceted
     *          false - deselected
     * [5]  -   call handleSelectedFields 
     */
    handleFieldCheckBox = (item) => {
        const selectedDataSets = {
            "projects_hos": "Homeownership Projects",
            "contracts_hos": "Homeownership Contracts",
            "reportYears_hos": "Homeownership Report Activity",
            "funderProjects_hos": "Homeownership Funder Settings",
            "programactivity_hos": "Homeownership Program Activity",
            "financialactivity_hos": "Homeownership Financial Activity",
            "contractactivity_hos": "Homeownership Contract Activity"
        }
        let { checkedSystemFields, selectedFields, checkModified } = this.state;
        // [1]
        let index = selectedFields.findIndex(elem => elem.field === item.field && elem.collection === item.collection);
        let cIndex = checkedSystemFields.findIndex(elem => elem === item.collection);
        if (selectedDataSets[item.collection]) {
            cIndex = checkedSystemFields.findIndex(elem => elem === selectedDataSets[item.collection]);
            // [2]
            if (index > -1) {
                selectedFields.splice(index, 1)
                if (!selectedFields.some(elem => elem.collection === item.collection) && checkedSystemFields.some(elem => elem === selectedDataSets[item.collection])) {
                    checkedSystemFields.splice(cIndex, 1);
                }
            } else {
                // [3]
                selectedFields.push(item);
                if (cIndex === -1) {
                    checkedSystemFields.push(selectedDataSets[item.collection])
                }
            }
            checkedSystemFields.length > 0 ? this.selectedMenuGroup(checkedSystemFields[checkedSystemFields.length - 1], true) : this.selectedMenuGroup(selectedDataSets[item.collection], false)
        } else {
            // [2]
            if (index > -1) {
                selectedFields.splice(index, 1)
                if (!selectedFields.some(elem => elem.collection === item.collection) && checkedSystemFields.some(elem => elem === item.collection)) {
                    checkedSystemFields.splice(cIndex, 1);
                }
            } else {
                // [3]
                selectedFields.push(item);
                if (cIndex === -1) {
                    checkedSystemFields.push(item.collection)
                }
            }
            checkedSystemFields.length > 0 ? this.selectedMenuGroup(checkedSystemFields[checkedSystemFields.length - 1], true) : this.selectedMenuGroup(item.collection, false)
        }
        //To move home purchased address to the last
        const homePurchasedAddressIndex = selectedFields.findIndex(elem => elem.header === 'Home purchased address')
        if (homePurchasedAddressIndex > -1) {
            if (!checkModified) {
                selectedFields = arrayMoveImmutable(selectedFields, homePurchasedAddressIndex, -1);
            }
        } else {
            checkModified = false
        }
        // [4]
        checkedSystemFields.length > 0 ? this.selectedMenuGroup(checkedSystemFields[checkedSystemFields.length - 1], true) : this.selectedMenuGroup(item.collection, false)
        this.setState({ checkedSystemFields: checkedSystemFields, selectedFields: selectedFields, checkModified: checkModified });
        // [5]
        this.handleSelectedFields(selectedFields)
    }

    /**
     * Handle selected menu group from Menu
     * @param {string} key
     * @param {boolean} value
     * 
     * group1   -   users, organizations
     * group2   -   projects, sites, buildings, units, reportYears, funderSettings, table2, table3, table4
     * group3   -   Asset Management Report
     * group4   -   Table4 Performance Measures - For Funder
     * group5   -   Table4 Performance Measures - All Funders
     * group6   -   Submission Status for Funder v1 (Current Funder)
     * group7   -   Tables 2 - 4 Report Summary
     * group8   -   Table 1 - Export All for Year
     * group9   -   Unit Vacancy Details
     * group10   -   table1
     * group11   -   PSRC Report
     * group12   -   utilityAllowance
     * group14   -   Submission Status for All Funder
     * 
     * CombinedGroup    -   group3, group9
     * 
     * [1]  -   If selectedGroupKey is empty, then clear all fieldFilters
     */
    selectedMenuGroup = (key, value) => {
        let { isTemplateAsset, filterAttribute, dataSet } = this.state;
        let selectedGroupKey = [];
        switch (key) {
            case 'users':
                selectedGroupKey = value ? ['group1'] : [];
                break;
            case 'organizations':
                selectedGroupKey = value ? ['group1'] : [];
                break;
            case 'projects':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'sites':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'buildings':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'units':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'utilityAllowance':
                selectedGroupKey = value ? ['group12'] : [];
                break;
            case 'reportYears':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'funderSettings':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'table1':
                selectedGroupKey = value ? ['group10'] : [];
                break;
            case 'table5':
                selectedGroupKey = value ? ['group10'] : [];
                break;
            case 'table2':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'table3':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'table4':
                selectedGroupKey = value ? ['group2'] : [];
                break;
            case 'PSRC Report':
                selectedGroupKey = ['group11'];
                break;
            case 'Asset Management Report':
                selectedGroupKey = ['group3'];
                isTemplateAsset = value ? true : null;
                break;
            case 'Unit Vacancy Details':
                selectedGroupKey = ['group9'];
                isTemplateAsset = value ? false : null;
                break;
            case 'Table4 Performance Measures - For Funder':
                selectedGroupKey = ['group4'];
                break;
            case 'Table4 Performance Measures - All Funders':
                selectedGroupKey = ['group5'];
                break;
            case 'Submission Status for Funder v1 (Current Funder)':
                selectedGroupKey = ['group6'];
                break;
            case 'Submission Status v2 (All Funders)':
                selectedGroupKey = ['group14'];
                break;
            case 'Tables 2 - 4 Report Summary':
                selectedGroupKey = ['group7'];
                break;
            case 'Table 1 - Export All for Year':
                selectedGroupKey = ['group8'];
                break;
            case 'Race-Ethnicity Report':
                selectedGroupKey = ['group16'];
                break;
            case 'Homeownership Projects':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Homeownership Contracts':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Homeownership Funder Settings':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Homeownership Report Activity':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Homeownership Program Activity':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Homeownership Financial Activity':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Homeownership Contract Activity':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'projects_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'contracts_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'reportYears_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'funderProjects_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'programactivity_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'financialactivity_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'contractactivity_hos':
                selectedGroupKey = value ? ['group13'] : [];
                break;
            case 'Commerce Portfolio':
                selectedGroupKey = ['group15'];
                break;
            case 'Rent Burden Report':
                selectedGroupKey = ['group17'];
                break;
            case 'Homeownership Submission Report':
                selectedGroupKey = ['group18'];
                break;
            default:
                selectedGroupKey = [];
                break;
        }
        // [1]
        if (selectedGroupKey.length === 0) {
            filterAttribute = [];
            dataSet.filterAttribute = [];
            this.props.explorePreview(null);
        }
        this.setState({ selectedMenuGroupKey: selectedGroupKey, isTemplateAsset: isTemplateAsset, filterAttribute: filterAttribute, dataSet: dataSet })
    }
    /**
     * Handle Submit Using refs -   This help to validate explore form and filter attributes in the same time
     * @memberof SaveCancel
     */
    handleSubmitForRefs = () => {
                this.formRef.current.handleSubmit();
            }
    /**
     * Handle selected fields update    -   Helps to update the columns in the preview fields
     * @param {*} items 
     * 
     * [1]  -   Assign header in items to headers
     * [2]  -   If the selected explore data is predefined template, the assign field for previewFields, else assign header.
     */
    handleSelectedFieldsUpdate = (items, checkModified) => {
        let { headers, previewFields, template } = this.state;
        // [1]
        headers = items.map(item => item.header);
        // [2]
        if (template) {
            previewFields = items.map(item => item.field)
        } else {
            previewFields = items.map(item => item.title)
        }
        if (checkModified) {
            this.setState({ selectedFields: items, headers: headers, previewFields: previewFields, checkModified });
        } else {
            this.setState({ selectedFields: items, headers: headers, previewFields: previewFields, checkModified: this.state.checkModified });
        }
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set size of the container grid for selectedColumns, preview and fieldFilter
     *          If selectedColumns && fieldFilter are closed, then preview container will have grid size of 12
     *          ElseIf  selectedColumns is open, then preview container will have grid size of 8 and selectedColumns will have 4
     *          ElseIf fieldFilter is open, then preview container will have grid size of 6 and fieldFilter will have 6
     * [2]  -   Set Initial selectedMenuGroupKey only if custom template and selectedMenuGroupKey is empty
     * [3]  -   Update explore keys whisch are based on report year -   Replace 'year_1' with selected report year
     * [4]  -   Set value and label for modules using checkedSystemFields
     */
    render() {
        let { classes, pageAccess, title, isSubPage, needPadding, explore, getOne, previewData, exploreMenuGroups, isLoading } = this.props;
        let { reportsName, yearField, reportName, template, reportYear, headers, isMenuOpen, isFilterOpen, isPreviewOpen, checkedSystemFields, selectedFields, selectedMenuGroupKey, previewFields, filterAttribute, arithmeticColumns, reportFilter } = this.state;
        // [1]

        console.log("====== INSIDE RENDER REPORTFILTER", reportFilter)
        let smPreviewWidth = !isFilterOpen && !isMenuOpen ? 12 : isMenuOpen ? 8 : 6;
        let exploreDataKeys = [];
        const { userRole, org_access } = this;
        let modules = [];
        // [2]
        if (template === 0 && selectedMenuGroupKey.length === 0) {
            selectedMenuGroupKey = ['group1', 'group2', 'group10', 'group12', 'group13']
        }

        if (userRole !== 'Funder' || org_access === 2) {
            delete explore['Asset Management Report'];
            delete explore['Unit Vacancy Details'];
            delete explore['Table4 Performance Measures - For Funder'];
            delete explore['Table4 Performance Measures - All Funders'];
            delete explore['Submission Status for Funder v1 (Current Funder)'];
            delete explore['Submission Status v2 (All Funders)'];
            delete explore['Tables 2 - 4 Report Summary'];
            delete explore['Table 1 - Export All for Year'];
            delete explore['PSRC Report'];
            delete explore['Race-Ethnicity Report'];
            delete explore['Rent Burden Report'];
        }

        if(reportYear < 2023){
        // headers=headers.filter(data=> data != 'Move-out Reason')
        
          var remove_item = [
            'Move-out Reason',
            "Eviction Reason",
            "New Housing Situation",
          ];
          headers= headers.filter(
            (item) => !remove_item.includes(item)
          );
          previewFields=headers;
        }

        if (userRole !== 'Funder' || org_access === 1) {
            delete explore['Commerce Portfolio'];
        }
        if(org_access === 1){
            delete explore['Homeownership Submission Report'];
        }

        if (org_access === 1) {
            delete explore['Homeownership Projects'];
            delete explore['Homeownership Contracts'];
            delete explore['Homeownership Funder Settings'];
            delete explore['Homeownership Report Activity'];
            delete explore['Homeownership Program Activity'];
            delete explore['Homeownership Financial Activity'];
            delete explore['Homeownership Contract Activity'];

        } else if (org_access === 2) {
            delete explore['projects'];
            delete explore['sites'];
            delete explore['buildings'];
            delete explore['units'];
            delete explore['utilityAllowance'];
            delete explore['reportYears'];
            delete explore['funderSettings'];
            delete explore['table1'];
            delete explore['table2'];
            delete explore['table3'];
            delete explore['table4'];
            delete explore['table5'];
        }

        if (Object.keys(explore).length > 0) {
            exploreDataKeys = Object.keys(explore)
            let year_1 = reportYear
            let year_2 = reportYear - 1
            let year_3 = reportYear - 2
            let year_4 = reportYear - 3
            let year_5 = reportYear - 4
            exploreDataKeys.forEach(key => {
                explore[key].forEach((item, index) => {
                    /* eslint-disable no-template-curly-in-string */
                    explore[key][index].title = item.title.replace('${year_1}', year_1)
                    explore[key][index].header = item.header.replace('${year_1}', year_1)
                    explore[key][index].title = item.title.replace('${year_2}', year_2)
                    explore[key][index].header = item.header.replace('${year_2}', year_2)
                    explore[key][index].title = item.title.replace('${year_3}', year_3)
                    explore[key][index].header = item.header.replace('${year_3}', year_3)
                    explore[key][index].title = item.title.replace('${year_4}', year_4)
                    explore[key][index].header = item.header.replace('${year_4}', year_4)
                    explore[key][index].title = item.title.replace('${year_5}', year_5)
                    explore[key][index].header = item.header.replace('${year_5}', year_5)
                    explore[key][index].collectionName = item.collection

                    if (key == 'Race-Ethnicity Report') {
                        
                        let test = /Total Number Of Occupied Units/i;

                        if (test.test(explore[key][index].title) || test.test(explore[key][index].header) ){
                            explore[key][index].title = item.title.replace('Total Number of Occupied Units', `Total Number of Occupied Units (as of 12/31/${year_1.toString().slice(-2)})`).slice(0,47);

                            explore[key][index].field = item.field.replace('Total Number of Occupied Units', `Total Number of Occupied Units (as of 12/31/${year_1.toString().slice(-2)})`).slice(0,47);

                            explore[key][index].header = item.header.replace('Total Number of Occupied Units', `Total Number of Occupied Units (as of 12/31/${year_1.toString().slice(-2)})`).slice(0,47)
                        }
                    }
                })
            })
        }
        // [4]
        if (checkedSystemFields.length > 0) {
            checkedSystemFields.forEach(item => {
                modules.push({
                    label: item.replace(/^\w/, (c) => c.toUpperCase()),
                    value: item
                })
            })
        }
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                handleBackActionClick={this.handleBackActionClick}
                handleDownloadActionClick={this.handleDownloadActionClick}
                isSubPage={isSubPage}
                needPadding={needPadding}
            >
                <ExploreForm
                    innerRef={this.formRef}
                    templates={reportsName}
                    reportYears={yearField}
                    template={template}
                    reportName={reportName}
                    reportYear={reportYear}
                    onChange={this.handleChange}
                    reportFiltr={reportFilter}
                    handleSave={() => isFilterOpen ? this.filterRef.current.handleFilter("save") : this.handleSubmit("form")}
                />
                <div className="explore" style={{ height: getSubChildCardHeight() + 36 }}>
                    <Grid container spacing={3} className={classes.container}>
                        {isMenuOpen ? <Grid item xs={12} sm={4} className={classes.innerContainer}>
                            <ExploreMenu
                                exploreKeys={exploreDataKeys}
                                exploreData={explore}
                                exploreMenuGroups={exploreMenuGroups}
                                checkedSystemFields={checkedSystemFields}
                                selectedFields={selectedFields}
                                reportFilter={reportFilter}
                                selectedMenuGroupKey={selectedMenuGroupKey}
                                handleKeyCheckBox={(key) => this.handleKeyCheckBox(key)}
                                handleFieldCheckBox={(item) => this.handleFieldCheckBox(item)}
                                handleselectedMenuGroup={(key, value) => this.selectedMenuGroup(key, value)}
                                handlePreview={() => this.handlePreview()}
                            />
                        </Grid> : null}
                        {isPreviewOpen && <Grid item xs={12} sm={smPreviewWidth} className={classes.innerContainer}>
                            <ExplorePreview
                                headers={headers}
                                previewFields={previewFields}
                                previewData={previewData}
                                reportFilter={this.state.reportFilter}
                                template={template}
                                isFilterOpen={isFilterOpen}
                                isMenuOpen={isMenuOpen}
                                selectedFields={[...selectedFields, ...arithmeticColumns]}
                                onFilterAction={(menu, filter) => this.setState({ isMenuOpen: menu, isFilterOpen: filter })}
                                handleSelectedFieldsUpdate={(items, checkModified) => { this.handleSelectedFieldsUpdate(items, checkModified) }}
                            />
                        </Grid>}
                        {isFilterOpen && <Grid item xs={12} sm={6} className={classes.innerContainer}>
                            <FilterAttribute
                                innerRef={this.filterRef}
                                _id={this.props._id}
                                explore={explore}
                                modules={modules}
                                exploreKeys={exploreDataKeys}
                                selectedFields={selectedFields}
                                getOne={getOne}
                                updateData={this.updatedPreviewData}
                                pageAccess={pageAccess}
                                filterAttributes={filterAttribute}
                                needArithmeticOperation={template === 0}
                                reportFilter= {this.state.reportFilter}
                            />
                        </Grid>}
                    </Grid>
                </div>
                <SaveCancel
                    handleSaveButtonClick={() => { this.handleSubmitForRefs() }}
                    handleCancelButtonClick={() => { history.goBack() }}
                />
                <PageLoader show={isLoading} />
            </ContentWrapper >
        );
    }
}
/**
 * Bind Component Property Types
 */
Explore.propTypes = {
    classes: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    title: PropTypes.string,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool,
    getOne: PropTypes.object,
    reportFilter: PropTypes.string
};

/* default Props */
Explore.defaultProps = {
    title: "New Report",
    pageAccess: [],
    isSubPage: false,
    needPadding: true,
    getOne: {},
    _id: null,
    reportFilter:''
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ explore, pageAccess, dashboard }, ownProps) => {
    pageAccess = ownProps.pageAccess ? ownProps.pageAccess : pageAccess['exploreNew'] || [];
    let isLoading = dashboard.isLoading || explore.isLoading;
    return {
        getOne: dashboard.funderDataExports,
        pageAccess,
        explore: explore.data && explore.data.fields ? explore.data.fields.systemfields : {},
        exploreMenuGroups: explore.data && explore.data.fields ? explore.data.fields.systemFieldGroups : {},
        years: explore.data && explore.data.years ? explore.data.years : [],
        previewData: explore.preview ? explore.preview : {},
        selectedExplore: explore.getOne && explore.getOne.data ? explore.getOne.data : {},
        isLoading: isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getFunderReports: (data) => dispatch(dashboardActions.getFunderReports(data)),
        getCustomReportsMenu: (reportYear) => dispatch(exploreActions.getCustomReportsMenu(reportYear)),
        explorePreview: (data) => dispatch(exploreActions.previewExploreReport(data)),
        updateData: (reportData) => dispatch(exploreActions.updateData(reportData)),
        saveTemplateData: (reportData) => dispatch(exploreActions.saveTemplateData(reportData)),
        saveCustomTemplateData: (reportData) => dispatch(exploreActions.saveCustomTemplateData(reportData)),
        downloadExploreReport: (id, reportData, reportName) => dispatch(exploreActions.downloadExploreReport(id, reportData, reportName)),
        showErrorAlert: (error) => dispatch(alertActions.error(error)),
        clearErrorAlert: () => dispatch(alertActions.clear()),
    }
};
//Export Component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(exploreStyle)(Explore));