import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

/**
 *Delete Dialog Component
 *
 * @class DeleteDialog
 * @extends {React.Component}
 */
class DeleteDialog extends React.Component {
    /**Handle Dialog close Action  */
    handleClose = () => {
        this.props.handleDeleteOnCloseAction()
    };
    /**Handle Dialog Delete Action */
    handleDelete = () => {
        this.props.handleDeleteAction(this.props.id)
    }
    /**
     *render html
     *
     * @returns
     * @memberof DeleteDialog
     */
    render() {
        const { open, content } = this.props;
        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
              <div className="DIA-pop-container">  <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle> </div>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description"> */}
                    {content}
                    {/* 
                    </DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDelete} color="primary">
                        Yes
                    </Button>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        No
                        </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

//default props
DeleteDialog.defaultProps = {
    open: false,
    id: '',
    content: "Are you sure you want to delete this record?",
    /** Handle Delete Action for Dialog */
    handleDeleteAction: () => { },
    handleClose: () => { }
}

//prop types
DeleteDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.any.isRequired,
    handleDeleteAction: PropTypes.func.isRequired,
    handleDeleteOnCloseAction: PropTypes.func.isRequired
};

/**Export Component */
export default DeleteDialog;