import React from 'react';
import { Route } from 'react-router-dom';

// Component
import PublicLayout from "../../layouts/public";

/**
 * Public Route Component
 * @param {*} param0 
 * @returns 
 * 
 * Routes the page without any authentication by the user
 */
export const PublicRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <PublicLayout>
            <Component {...props} />
        </PublicLayout>
    )} />
);
