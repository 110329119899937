const inputStyle = theme => ({
    searchip: {
        margin: theme.spacing(1),
        marginTop: 7,
        border: "1px solid #ddd",
        borderBottom:"0",
        fontSize:"13px",
        padding:"0px 10px",
        marginLeft:"0",
        fontWeight: 500,
        // width: 100
    },
    accordionsearchip: {
        // margin: theme.spacing(1),
        marginTop: 10,
        marginBottom: 10,
        border: "1px solid #ddd",
        borderBottom:"0",
        fontSize:"0.85rem",
        padding:"0px 10px",
        fontWeight: 500,
        backgroundColor: 'white'
        // width: 100
    },
});

export default inputStyle;
