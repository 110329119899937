//import services
import { buildingService } from '../services/building';

//import constants
import { buildingConstants } from '../constants/building';

//import helper
import { alertActions, pageAccessActions, validationActions } from './';
import { history } from '../helpers';
import { Table4Service, projectsService } from '../services';

export const buildingActions = {
    getAll,
    resetFilter,
    addNewBuilding,
    getById,
    updateBuilding,
    delete: _delete,
    updateClaimStatus,
    getBuildingNameList,
    bulkUpdate
}


/**
 *Get data for building list
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {*} siteId - site_id
 * @returns
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, siteId, query) {
    return dispatch => {
        dispatch(request());
        buildingService.getAll(skip, limit, orderBy, order, sFields, sValues, siteId, query)
            .then(
                building => {
                    dispatch(success(building));
                    const { page } = building;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: buildingConstants.GETALL_REQUEST } }
    function success(building) { return { type: buildingConstants.GETALL_SUCCESS, building } }
    function failure(error) { return { type: buildingConstants.GETALL_FAILURE, error } }
}


/**
 *Reset Filter in building list
 *
 * @returns
 */
function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: buildingConstants.CLEAR_FILTER_SET } }
    function reset() { return { type: buildingConstants.CLEAR_FILTER_RESET } }
}

/**
 *Adding new building
 *
 * @param {*} building - new building data 
 * @returns
 */
function addNewBuilding(building) {
    return dispatch => {
        dispatch(request(building));

        buildingService.addNewBuilding(building)
            .then(
                building => {
                    projectsService.getProjectReport(1, 1, 'year', 'desc', '', '', building.project_id,false,{isDelete: false}).then((res)=>{
                    Table4Service.getConvertedTable4Data(res.data&&res.data[0]._id, 'projects')
                    })
                    dispatch(success(building));
                    history.goBack();
                    dispatch(alertActions.success("Building Added Successfully"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: buildingConstants.ADD_BUILDING_REQUEST } }
    function success(building) { return { type: buildingConstants.ADD_BUILDING_SUCCESS, building } }
    function failure(error) { return { type: buildingConstants.ADD_BUILDING_FAILURE, error } }
}

/**
 *Get single building information
 *
 * @param {*} id - building_id
 * @returns
 */
function getById(id, type) {
    return dispatch => {
        dispatch(request());
        buildingService.getById(id, type)
            .then(
                building => dispatch(success(building)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: buildingConstants.GETONE_REQUEST } }
    function success(building) { return { type: buildingConstants.GETONE_SUCCESS, building } }
    function failure(error) { return { type: buildingConstants.GETONE_FAILURE, error } }
}

/**
 *Update one particular building
 *
 * @param {*} formData - update data
 * @param {*} id - building_id
 * @returns
 */
function updateBuilding(formData, id) {
    return dispatch => {
        dispatch(request());

        buildingService.updateBuilding(formData, id)
            .then(
                building => {
                    dispatch(success(building));
                    history.goBack();
                    dispatch(alertActions.success("Updated Successfully"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: buildingConstants.UPDATE_BUILDING_REQUEST } }
    function success(building) { return { type: buildingConstants.UPDATE_BUILDING_SUCCESS, building } }
    function failure(error) { return { type: buildingConstants.UPDATE_BUILDING_FAILURE, error } }

}

/**
 *Delete a building
 *
 * @param {*} id - building_id
 * @returns
 */
function _delete(id,site_id) {
    return dispatch => {
        dispatch(request(id));

        buildingService.delete(id)
            .then(
                building => {
                    projectsService.getProjectReport(1, 1, 'year', 'desc', '', '', building.data?.project_id,false,{isDelete: false}).then((res)=>{
                    Table4Service.getConvertedTable4Data(res.data&&res.data[0]._id, 'projects')
                    })
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted Successfully'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                        dispatch(validationActions.updateValidations(0,site_id,0,0))
                    }, 1000);
                },
                error => {
                    dispatch(failure(id, error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(id) { return { type: buildingConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: buildingConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: buildingConstants.DELETE_FAILURE, id, error } }
}

/**
 * An action method to call a service method to change the building claim status true / false
 *
 * @param {*} building_id building_id to change
 * @returns
 */
function updateClaimStatus(building_id, site_id) {
    return dispatch => {
        dispatch(request());

        buildingService.updateClaimStatus(building_id, site_id)
            .then(
                claimRes => {
                    dispatch(success(claimRes.data));
                    dispatch(alertActions.success(claimRes.message));
                    // update page access
                    dispatch(pageAccessActions.getAccess('buildingEdit', { building_id: building_id, site_id: site_id }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: buildingConstants.UPDATE_BUILDING_CALIM_STATUS_REQUEST } }
    function success(data) { return { type: buildingConstants.UPDATE_BUILDING_CALIM_STATUS_SUCCESS, data } }
    function failure(error) { return { type: buildingConstants.UPDATE_BUILDING_CALIM_STATUS_FAILURE, error } }
}


/**
 * A method to get building name list
 */
function getBuildingNameList(data) {
    return dispatch => {
        dispatch(request());
        buildingService.getBuildingNameList(data)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: buildingConstants.GET_BUILDINGNAME_REQUEST } }
    function success(data) { return { type: buildingConstants.GET_BUILDINGNAME_SUCCESS, data } }
    function failure(error) { return { type: buildingConstants.GET_BUILDINGNAME_FAILURE, error } }
}


/**
 * A method to bulk update
 */
function bulkUpdate(data) {
    return dispatch => {
        dispatch(request());
        buildingService.bulkUpdate(data)
            .then(
                data => {
                    dispatch(alertActions.success(data.message));
                    dispatch(success());
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                    setTimeout(() => {
                        dispatch(alertActions.clear())
                    }, 3000)
                }
            );
    };

    function request() { return { type: buildingConstants.BULKUPDATE_REQUEST } }
    function success() { return { type: buildingConstants.BULKUPDATE_SUCCESS } }
    function failure(error) { return { type: buildingConstants.BULKUPDATE_FAILURE, error } }
}