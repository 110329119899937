export const DefaultFields = {
    renterBedCount:  0,
    ownerBedCount: 0,
    femaleSingleParentServedCount:  0,
    maleSingleParentServedCount:  0,
    renterTotalCount:  0,
    ownerTotalCount:   0,
    notes:  0,
    farmworkerBedCount:   0,
    reportYearDateOfOperationFirstDate:   0,
    reportYearDateOfOperationLastDate: 0,
    totalOccupiedBedNights:  0,
    totalBedNightsAvailable:   0,
    averageLengthOfStay:   0,
    maximumLengthOfStay:  0,
    rentPeriod_id:   0,
    individualRentChargedToTennants:  0,
    individualRentChargedToEmployer:  0,
    householdRentChargedToTennants:  0,
    householdRentChargedToEmployer:  0,
    totalFamilyHouseholdsServed:   0,
    totalIndividualHouseholdsServed:   0,
    householdIncome0To30Percent:   0,
    householdIncome31To50Percent:   0,
    householdIncome51To80Percent:  0,
    householdIncomeAbove80Percent:   0,
    totalNumberOccupants:  0,
    totalNumberMinors:   0,
    singleBedUnits:  0,
    householdUnits:  0,
    totalUnits:  0,
    totalRestrictedUnits:   0,
    totalCommonAreaUnits:   0,
    totalMarketRateUnits:  0,
    totalRestrictedOccupied:  0,
    totalRestrictedVacant:  0,
    totalRestrictedUnitsFamilyHouseholdsServed:   0,
    TotalRestrictedUnitsIndividualHouseholdsServed:  0,
    totalRestrictedUnitsHouseholdsServed:   0,
    totalRestrictedUnitsServedByPercent_0To30:  0,
    totalRestrictedUnitsServedByPercent_31To50:   0,
    totalRestrictedUnitsServedByPercent_51To80:  0,
    totalRestrictedUnitsServedByPercent_Over80:   0,
    totalRestrictedUnitsServedByPercent_Total:  0,
    table2FemaleSingleParentServedCount: 0,
    table2MaleSingleParentServedCount:  0,
    table2FarmworkerBedCount:   0,
    table2ReportYearDateOfOperationFirstDate:  0,
    table2ReportYearDateOfOperationLastDate: 0,
    table2TotalOccupiedBedNights: 0,
    table2AverageLengthOfStay: 0,
    table2MaximumLengthOfStay:  0,
    rentPeriodName:   0,
    table2IndividualRentChargedToTennants:   0,
    table2IndividualRentChargedToEmployer:  0,
    table2HouseholdRentChargedToTennants:   0,
    table2HouseholdRentChargedToEmployer:  0,
    table2TotalFamilyHouseholdsServed: 0,
    table2TotalIndividualHouseholdsServed:   0,
    table2TotalNumberOccupants:  0,
    table2TotalNumberMinors: 0,
    table2SingleBedUnits:  0,
    table2HouseholdUnits:  0,
    table2RaceEthnicity: []
};

export const WarningNames = [
    {
        title: "TotalNumberofRestrictedUnitsNotMatchWithSystem",
        fieldName: "bedUnits",
        type: "section1"
    },
    {
        title: "bedUnits",
        type: "section1"
    },
    {
        title: "HomelessCountGreaterThanTable2TotalHouseholds",
        type: "section2"
    },
    {
        title: "RaceEthnicityNotMatchHouseholdsServed",
        type: "section4"
    },
    {
        title: "RaceandEthnicityTotalServedNotEqualTotalHouseholdsServed",
        type: "section4"
    },
    {
        title: "RaceandEthnicityTotalServedNotEqualTotalHouseholdsServed",
        type: "section5"
    },
    {
        title: "sec4total",
        type: "section4"
    },
    {
        title: "SingleParentHOHGreaterThanHouseholdsServed",
        type: "section6"
    },
    {
        title: "TotalHouseholdIncomeServedNotEqualTotalHouseholdServed",
        type: "section6"
    },
    {
        title: "TotalNumberofOccupantsGreaterThanTotalHouseholdsServed",
        type: "section6"
    },
    {
        title: "TotalSingleParentHouseholdServedNotEqualTotalHouseholdServed",
        type: "section6"
    },
    {
        title: "HouseholdsInYearNotEqualToTotalHouseholdsServed",
        type: "section7"
    },
    {
        title: "NumberOfMinorsGreaterThenTotalHouseholdsServedSestion5",
        type: "section8"
    },
    {
        title: "TotalOccupiedBedNightsUsedGreaterThenTotalBednightsAvailable",
        type: "section2"
    }
]
