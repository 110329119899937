import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ValidatorForm from "../../helpers/fieldValidations";

// Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromHTML } from 'draft-convert';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import AutoCompleteMultiMUI from '../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';
import CustomDialog from "../../components/CustomDialog/CustomDialog";

// Import Actions 
import { mailTemplateActions } from '../../actions/mailTemplate';
import { dashboardActions } from '../../actions/dashboard';
import { alertActions } from '../../actions/alert';

// Helpers
import { getUserRole } from '../../helpers';
import { templateCategoryConstants, templateUserCategoryConstants } from '../../helpers/appConstants';
/**
 * MailTemplateSend Container
 * @class MailTemplateSend
 * @extends {Component}
 */
class MailTemplateSend extends Component {
    /**
     * Init Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            templateName: "",
            subject: "",
            templateType: "",
            isProject: true,
            selectedUserType: [],
            selectedProject: [],
            selectedUser: [],
            bodyContent: EditorState.createEmpty(),
            signatureContent: EditorState.createEmpty(),
            footerContent: EditorState.createEmpty()
        };

        this.userRole = getUserRole();
        this.onBodyContentChange = this.onBodyContentChange.bind(this);
        this.onSignatureContentChange = this.onSignatureContentChange.bind(this);
        this.onFooterContentChange = this.onFooterContentChange.bind(this);
    }
    /**
     * omponet Did Mount
     */
    componentDidMount() {
        const { itemData } = this.props;
        this.setState({
            templateName: itemData.name,
            subject: itemData.subject,
            bodyContent: this.convertFromHTMLString(itemData.body),
            signatureContent: this.convertFromHTMLString(itemData.signature),
            footerContent: this.convertFromHTMLString(itemData.footer)
        });
    }
    /**
     * Convert from HTML to String
     * @param {*} sampleMarkup 
     * @returns 
     */
    convertFromHTMLString(sampleMarkup) {
        sampleMarkup = sampleMarkup || '';
        const blocksFromHTML = convertFromHTML(sampleMarkup);
        return EditorState.createWithContent(blocksFromHTML);
    }
    /**
     * Body Content Change
     * @param {*} bodyContent 
     */
    onBodyContentChange(bodyContent) {
        this.setState({ bodyContent });
    }
    /**
     * On Signature Content Change
     * @param {*} signatureContent 
     */
    onSignatureContentChange(signatureContent) {
        this.setState({ signatureContent });
    }
    /**
     * On Footer Content Change
     * @param {*} footerContent 
     */
    onFooterContentChange(footerContent) {
        this.setState({ footerContent });
    }
    /**
     * Handle Close Action
     */
    handleCloseAction = () => {
        this.props.handleCloseAction()
    }
    /**
     * Handle TextBox and DropDown Change Event
     * @param {*} name 
     * @param {*} value 
     */
    handleChange = (name, value) => {
        this.setState({ [name]: value });
    };
    /**
     * Handle Change template type
     * @param {*} name 
     * @param {*} value 
     */
    handleChangeTemplateType = (name, value) => {
        if (value === "project") {
            this.props.getProjectSummary(null, '');
            this.setState({
                [name]: value,
                selectedUserType: [],
                selectedProject: [],
                selectedUser: [],
                isProject: false
            });
        } else {
            this.props.getUserList([], [], null, '');
            this.setState({
                [name]: value,
                selectedUserType: [],
                selectedProject: [],
                selectedUser: [],
                isProject: true
            });
        }
    };
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        this._saveForm();
    };
    /**
     * Hanlde Okay Action
     */
    handleOkayAction = () => {
        const { templateName, bodyContent, signatureContent, footerContent, subject, selectedProject, selectedUser } = this.state;
        this.refs.form.submit();
            if (this.refs.form.errors && this.refs.form.errors.length === 0) {
                let bodyHtml = stateToHTML(bodyContent.getCurrentContent());
                let signatureHtml = stateToHTML(signatureContent.getCurrentContent());
                let footerHtml = stateToHTML(footerContent.getCurrentContent());
                const templateInfo = {
                    templateName,
                    subject,
                    bodyContent: bodyHtml,
                    signatureContent: signatureHtml,
                    footerContent: footerHtml,
                    selectedProject: selectedProject,
                    userDetails: selectedUser
                };
                this.props.handleFormSaveActionClick(templateInfo);
                //this.props.handleOkayAction();
            }
        }
    /**
     * Handle Project Autocomplete
     * @param {*} selectedOption 
     */
    handleProjectAutoCompolete = (selectedOption) => {
        let { selectedUserType } = this.state;
        let userType = [];
        selectedUserType.length > 0 && selectedUserType.forEach(item => {
            userType.push(item.value)
        })
        this.setState({ selectedProject: selectedOption, selectedUserType: [], selectedUser: [] });
    }
    /**
     * Handle User Type Autocomplete
     * @param {*} selectedOption 
     */
    handleUserTypeAutoCompolete = (selectedOption) => {
        let { selectedProject } = this.state;
        this.setState({ selectedUserType: selectedOption, selectedUser: [] });
        let userType = [];
        selectedOption.length > 0 && selectedOption.forEach(item => {
            userType.push(item.value)
        })
        this.props.getUserList(selectedProject, userType, null, '');
    }
    /**
     * Handle User Autocomplete
     * @param {*} selectedOption 
     * @param {*} isAllSelected 
     */
    handleUserAutoCompolete = (selectedOption, isAllSelected) => {
        let {  selectedUser } = this.state;
        const { userList } = this.props;
        if (isAllSelected) {
            selectedUser = userList.data;
        } else {
            selectedUser = selectedOption;
        }
        this.setState({ selectedUser: selectedUser })
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { templateName, templateType, selectedProject, subject, bodyContent, signatureContent, footerContent, isProject, selectedUserType, selectedUser } = this.state;
        const { classes, projectList, userList } = this.props;
        return (
            <CustomDialog
                title="Send Email Notification"
                saveVisible={true}
                cancelVisible={true}
                handleOkayAction={this.handleOkayAction}
                handleCloseAction={this.handleCloseAction}
                confirmationButtonLabel={"Send"}
                closeButtonLabel={"Cancel"}
                className={"sendMailNotify"}
            >
                <ValidatorForm
                    name="newSendMailForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >
                    <Grid container spacing={2} >
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <TextBox
                                                name="templateName"
                                                value={templateName}
                                                label="Template Name"
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextBox
                                                name="subject"
                                                value={subject}
                                                label="Subject"
                                                className={classes.textField}
                                                handleChange={this.handleChange}
                                                margin="dense"
                                                disabled={true}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <DropDown
                                                name="templateType"
                                                value={templateType}
                                                select
                                                label="Select Type"
                                                className={classes.textField}
                                                handleChange={this.handleChangeTemplateType}
                                                margin="dense"
                                                validators={['required']}
                                                errorMessages={['this field is required']}
                                            >
                                                {
                                                    templateCategoryConstants.map((item) => {
                                                        return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                                                    })
                                                }
                                            </DropDown>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {!isProject &&
                                            <React.Fragment>
                                                <Grid item xs={4}>
                                                    <AutoCompleteMultiMUI
                                                        name="emailSelectedProject"
                                                        selectedValue={isProject ? [] : selectedProject}
                                                        suggestions={projectList.projects}
                                                        placeholder="Select Project"
                                                        className={classes.textField}
                                                        handleAutoCompolete={this.handleProjectAutoCompolete}
                                                        margin="dense"
                                                        disabled={isProject}
                                                        validators={!isProject ? ['required'] : []}
                                                        errorMessages={!isProject ? ['this field is required'] : []}
                                                        needSelectAll={true}
                                                    >
                                                    </AutoCompleteMultiMUI>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <AutoCompleteMultiMUI
                                                        name="emailSelectedUserType"
                                                        selectedValue={isProject ? [] : selectedUserType}
                                                        suggestions={templateUserCategoryConstants}
                                                        placeholder="Select User Type"
                                                        className={classes.textField}
                                                        handleAutoCompolete={this.handleUserTypeAutoCompolete}
                                                        margin="dense"
                                                        disabled={selectedProject.length === 0}
                                                        validators={!isProject ? ['required'] : []}
                                                        errorMessages={!isProject ? ['this field is required'] : []}
                                                        needSelectAll={true}
                                                    >
                                                    </AutoCompleteMultiMUI>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        <Grid item xs={4}>
                                            <AutoCompleteMultiMUI
                                                name="emailSelectedUser"
                                                selectedValue={selectedUser}
                                                suggestions={userList.data}
                                                placeholder="Select User"
                                                className={classes.textField}
                                                handleAutoCompolete={this.handleUserAutoCompolete}
                                                margin="dense"
                                                validators={!templateType ? [] : ['required']}
                                                errorMessages={!templateType ? [] : ['this field is required']}
                                                disabled={!templateType || (!isProject && selectedUserType.length === 0)}
                                                needSelectAll={true}
                                            >
                                            </AutoCompleteMultiMUI>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="custom-label">Body</div>
                                    <Editor
                                        editorState={bodyContent}
                                        editorClassName="mailTemplateEditor sendMailBody"
                                        onEditorStateChange={this.onBodyContentChange}
                                        toolbarHidden
                                        placeholder={'Body'}
                                    />
                                    <div className="cutom-editor-gap"></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="custom-label">Signature</div>
                                    <Editor
                                        editorState={signatureContent}
                                        editorClassName="mailTemplateEditor sendnotifycontainer"
                                        onEditorStateChange={this.onSignatureContentChange}
                                        toolbarHidden
                                        placeholder={'Signature'}
                                    />
                                    <div className="cutom-editor-gap"></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="custom-label">Footer</div>
                                    <Editor
                                        editorState={footerContent}
                                        editorClassName="mailTemplateEditor sendnotifycontainer"
                                        onEditorStateChange={this.onFooterContentChange}
                                        toolbarHidden
                                        placeholder={'Footer'}
                                    />
                                    <div className="cutom-editor-gap"></div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </CustomDialog>
        );
    }
}
/**
 * Bind Component Property Types
 */
MailTemplateSend.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    orgId: PropTypes.string,
    isReadOly: PropTypes.bool.isRequired,
    pageAccess: PropTypes.array.isRequired
};
/**
 * Default Props
 */
MailTemplateSend.defaultProps = {
    pageAccess: [],
    isReadOly: true
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const projectList = state.dashboard.projectSummary;
    const userList = {
        data: state.mailTemplates.usersList.data || [],
        usersCount: state.mailTemplates.usersList.usersCount || 0
    }
    return {
        projectList,
        userList
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        handleFormSaveActionClick: (templateInfo) => dispatch(mailTemplateActions.sendTemplate(templateInfo)),
        getProjectSummary: (limit, search) => dispatch(dashboardActions.getProjectSummary(limit, search)),
        getUserList: (projects, userType, limit, search) => dispatch(mailTemplateActions.getUserList(projects, userType, limit, search)),
        //showAlert: (message) => dispatch(alertActions.success(message)),
        clearAlert: () => dispatch(alertActions.clear()),
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(MailTemplateSend));
