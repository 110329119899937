import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const unitsService = {
    getAll,
    addNewUnit,
    updateUnit,
    getById,
    delete: _delete,
    getByUHId,
    getBuildingsList,
    getUnitsBulkList,
    bulkUpdate,
    getDelMsg,
    getUnitTable1List
};

/**
 * A method to get the units list
 *
 * @param {*} skip  page to display. if 1 first page
 * @param {*} limit no of recorrd to fetch on the given page
 * @param {*} orderBy sort order column name string
 * @param {*} order sorttypr asc or desc
 * @param {*} sFields search fields column name
 * @param {*} sValues search field column values
 * @param {*} building_id building id to fecth the units under it
 * @returns
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, building_id = null, exactMatch = false, query) {

    let data = {
        fields: sFields,
        values: sValues,
        exactMatch,
        building_id,
        query
    };

    const requestOptions = {
        method: 'Post',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/units/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 * Create new unit
 * 
 * @param {*} formData 
 */
function addNewUnit(unit) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(unit)
    };

    return fetch(`${globalVariable.apiURL}/units`, requestOptions).then(handleResponse);
}

/**
 * Update unit details page by using unit id
 * 
 * @param {*} formData - update data
 * @param {*} id - unit_id
 */
function updateUnit(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/units/${id}`, requestOptions).then(handleResponse);
}

/**
 * Delete a unit by using unit id
 * 
 * @param {*} unitid - unit_id
 */
function _delete(unitid, item) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(item)
    };

    return fetch(`${globalVariable.apiURL}/units/${unitid}`, requestOptions).then(handleResponse);
}

/**
 * A method to get the unit details
 *
 * @param {*} id
 * @returns
 */
function getById(id, type = null) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/units/${id}/${type}`, requestOptions).then(handleResponse);
}

function getDelMsg(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/unitsmsg/${id}`, requestOptions).then(handleResponse);
}

/**
 * A method to get the unit history id
 *
 * @param {*} id
 * @returns
 */
function getByUHId(orderBy, order, id) {
    if (id) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        return fetch(`${globalVariable.apiURL}/units/unit-history/${id}?orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
    }
}

/**
 * A service call method to get the buildings list for autocomplete
 *
 * @param {*} site_id contains site id to get the building
 * @returns
 */
function getBuildingsList(site_id) {
    if (site_id) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };
        return fetch(`${globalVariable.apiURL}/buildings/list/${site_id}`, requestOptions).then(handleResponse);
    }
}

/**
 * A method to get building name list
 */
function getUnitsBulkList(skip, limit, orderBy, order, sFields, sValues, query) {

    let data = {
        fields: sFields,
        values: sValues,
        query
    };
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/units/bulk-list?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 * A method to bulk update
 */
function bulkUpdate(data = null) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/units/bulk-update`, requestOptions).then(handleResponse);
}

/**
 * A method to get units table1 list
 */
function getUnitTable1List(table1Id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    return fetch(`${globalVariable.apiURL}/units/${table1Id}/table1-list`, requestOptions).then(handleResponse);
}