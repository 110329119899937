import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';

// Import Form Elements
import ValidatorForm from "../../../helpers/fieldValidations";
import FieldValidationConstants from "../../../helpers/fieldValidationConstants"

//import component
import TextBox from '../../../components/TextBox/TextBox';
import DeleteDialog from '../../../components/DeleteDialog/DeleteDialog';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextArea from '../../../components/TextArea/TextArea';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helpers
import { history } from '../../../helpers';
import { getUserId, getUserOrgId, getUserName, getOrgType } from '../../../helpers';
import { confActions } from '../../../actions/configuration';
import { loanProjectActions } from '../../../actions/loanProjects'

// Import Constants
import { configurationConstants } from '../../../helpers/appConstants';

const address = {
    line: '',
    city: null,
    state: null,
    postalCode: ''
};

class General extends Component {

    /**
     * Init Constructor
     * @param {C} props 
     */
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            projectName: '',
            totalContractNumbers: '',
            totalNumberLoans: '',
            orgInformation: '',
            generalAddress: '',
            state: '',
            generalzipcode: '',
            isConfidentialFlag: false,
            comments: '',
            org: {
                id: null,
                name: '',
                address: {
                    ...address
                },
            },
            user: {
                id: null,
                name: '',
                phone: '',
                email: '',
                address: {
                    ...address
                },
            },
            isApplied: false,
            confirmPopup: false,
            readOnlyFunders: [],
            isProjectNameChanged: false
        };

        this.userId = getUserId();
        this.userOrgId = getUserOrgId();
        this.loggedUserName = getUserName();
        this.userType = getOrgType();
    }

    /**
    * Component Did Mount Event
    */
    componentDidMount() {
        const id = this.props.projectId || this.props.copyId;

        // Get State and City        
        this.props.getConfiguration(configurationConstants.state);
        this.props.getConfiguration(configurationConstants.cities);

        this.props.getAllOrgs('', 'loanProjects');

        if (id) {
            this.props.getById(id);
        }
    }

    /**
     * 
     * @param {*} newProps 
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        const { details, projectId, copyId } = newProps;

        if (projectId !== this.props.projectId) {
            this.props.getById(projectId);
            this.setState({ isApplied: false });
        }

        const { data } = details;
        let project = data;
        const id = projectId || copyId;
        if (project && id) {
            if (id && !this.state.isApplied) {
                let projectDetails = { ...project };
                projectDetails.owners.user_id = projectDetails.owners.user_id || { address: { ...address } };
                let contactName = `${projectDetails.owners.user_id.firstName} ${projectDetails.owners.user_id.lastName}`;
                contactName = projectDetails.owners.user_id.isActive === false ? `*${contactName}` : contactName;
                this.setState({
                    tab: 0,
                    projectName: projectId ? projectDetails.name : '',
                    totalContractNumbers: projectId ? projectDetails.contracts : '',
                    totalNumberLoans: projectId ? projectDetails.totalNumberLoans : '',
                    orgInformation: projectId ? projectDetails.orgInformation : '',
                    generalAddress: projectDetails.generalAddress,
                    comments: projectDetails.comments,
                    org: {
                        ...this.state.org,
                        ...projectDetails.owners.org_id,
                        id: projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null,
                        value: projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null,
                        label: projectDetails.owners.org_id ? projectDetails.owners.org_id.name : null,
                    },
                    user: {
                        ...projectDetails.owners.user_id,
                        id: projectDetails.owners.user_id._id,
                        value: projectDetails.owners.user_id._id,
                        name: contactName,
                        label: contactName,
                    },
                    isApplied: true,
                    readOnlyFunders: projectDetails.readOnlyFunders ? projectDetails.readOnlyFunders : []
                });

                this.props.getAllUsersInOrg(projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null);
            }
        }
    }

    /**
     * Handle TextBox and DropDown Change Event
     */
    handleChange = (name, value) => {
        //update project name changed to check duplicates
        if (name === 'projectName') {
            this.setState({ isProjectNameChanged: true })
        }
        this.setState({ [name]: value });
    };

    /**
     * City
     */
    _handleAutoComplete = (selectedOption) => {
        this.setState({ city: selectedOption.value });
    }

    /**
     * State
     */
    handleAutoCompleteState = (selectedOption) => {
        this.setState({ state: selectedOption.value });
    }

    /**
   * Handle AutoComplete Event
   */
    handleAutoCompleteUser = (selectedOption) => {
        if (selectedOption.length === 0) {
            this.setState({ user: { id: null, name: '', value: '', label: '', address: { ...address } } });
        } else {
            this.setState({ user: { ...selectedOption } });
        }
    }

    /**
    * Handle Form Submit Event
    */
    handleSubmit = (e) => {
        let isnewProject = history.location.pathname.split("/")[2] === "new"
        // check if project name already Exists
        if (isnewProject || this.state.isProjectNameChanged) {
            this.props.isProjectNameExists({ name: this.state.projectName, id: this.props.projectId }, (isExists) => {
                if (isExists) {
                    this.setState({
                        confirmPopup: true,
                        isProjectNameChanged: false
                    })
                } else {
                    this._saveForm();
                }
            });
        } else {
            this._saveForm();
        }

    };

    handleCancel = () => {
        history.push("/loanProjects")
    }

    /**
     * Handle AutoComplete Event
     */
    handleAutoCompleteOrg = (selectedOption) => {
        if (selectedOption.length === 0) {
            this.setState({ org: { id: null, name: '', address: { ...address } }, user: { id: null, name: '', value: '', label: '', address: { ...address } } });
        } else {
            this.setState({ org: { ...selectedOption }, user: { id: null, name: '', value: '', label: '', address: { ...address } } });
            if (selectedOption._id) {
                this.props.getAllUsersInOrg(selectedOption._id);
            }
        }
    }

    /**
     * 
     */
    _saveForm() {
        let creator = {
            userId: this.userId,
            userName: this.loggedUserName,
            userOrgId: this.userOrgId
        }
        let generalData = { ...this.state, name: this.state.projectName, creator };
        const selectedROF = generalData.readOnlyFunders.map(item => item.value);
        generalData['readOnlyFunders'] = selectedROF;

        // no need to chec copy id
        if (this.props.projectId) {
            this.props.handleFormSaveActionClick(this.props.projectId, generalData);
        } else {
            this.props.handleFormSaveActionClick(generalData);
        }
    }


    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, details, states, cities, pageAccess } = this.props;
        const { orgs, users } = details;
        const { projectName, totalContractNumbers, totalNumberLoans, user, comments, org } = this.state
        const isReadOnly = pageAccess.indexOf('SAVE') <= -1;
        const isFunder = pageAccess.indexOf('FNDR_ACCESS') <= -1;

        //let isFunder = getUserRole() === 'funderWithOA' || getUserRole() === 'funderWithOAandHO' || getUserRole() === 'funderWithOAandBoth' || getUserRole() === 'funderWithoutOA' || getUserRole() === 'funderWithoutOAandHO' || getUserRole() === 'funderWithoutOAandBoth' || getUserRole() === 'superFunderWithHO' || getUserRole() === 'superFunder';
        // let isNonFundersWithOA = getUserRole() === 'nonFunderWithOA' || getUserRole() === 'nonFunderWithOAandHO' || getUserRole() === 'nonFunderWithOAandBoth';
        // let isContractor = getUserType() === 'Contractor' && isNonFundersWithOA;

        return (
            <div className={`${classes.rootEdit} loanProjGeneralContainer`}>
                <ValidatorForm
                    name="userForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)} >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="projectName"
                                    value={projectName}
                                    label="Name"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required', 'isStringLength250']}
                                    errorMessages={['this field is required', 'Character length cannot be greater than 250']}
                                    disabled={isFunder}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="center">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    type="number"
                                    name="totalContractNumbers"
                                    value={totalContractNumbers}
                                    label="Total Contract Numbers"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    type="number"
                                    name="totalNumberLoans"
                                    value={totalNumberLoans}
                                    label="Total Number of Records"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    //disabled={projectId ? false : true}
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    name="org"
                                    className="autocomplete-fixed"
                                    suggestions={orgs}
                                    handleAutoCompolete={this.handleAutoCompleteOrg}
                                    selectedValue={org}
                                    placeholder="Contractor Organization"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    disabled={isFunder}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Grid item xs={12} sm={12} className={"sm_EightBar"}>
                                <TextBox
                                    name="org.address.line"
                                    value={org.address.line || ''}
                                    label="Address"
                                    className={classes.textField}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    placeholder='City'
                                    className="autocomplete-fixed"
                                    suggestions={cities}
                                    handleAutoCompolete={this._handleAutoComplete}
                                    selectedValue={{ value: org.address.city, id: org.address.city }}
                                    disabled={true}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="center">
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    placeholder='State'
                                    className="autocomplete-fixed"
                                    suggestions={states}
                                    selectedValue={{ value: org.address.state, id: org.address.state }}
                                    disabled={true}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="org.address.postalCode"
                                    value={org.address.postalCode || ''}
                                    label="Zip Code"
                                    className={classes.textField}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    suggestions={users}
                                    name="autocompleteuser"
                                    className="autocomplete-fixed"
                                    handleAutoCompolete={this.handleAutoCompleteUser}
                                    selectedValue={user}
                                    placeholder="Contractor Name"
                                    validators={['required']}
                                    errorMessages={['this field is required']}
                                    disabled={isReadOnly}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="center">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="phoneNumber"
                                    value={user.phone || ''}
                                    label="Phone"
                                    className={classes.textField}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    name="email"
                                    value={user.email || ''}
                                    label="Email"
                                    className={classes.textField}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} align="center">
                            <Grid item xs={12} sm={12}>
                                <TextBox
                                    name="user.address.line"
                                    value={user.address.line || ''}
                                    label="Address"
                                    className={classes.textField}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="left">
                            <Grid item xs={12} sm={10}>
                                <AutoComplete
                                    placeholder='City'
                                    className="autocomplete-fixed"
                                    suggestions={cities}
                                    handleAutoCompolete={this._handleAutoComplete}
                                    selectedValue={{ value: user.address.city, id: user.address.city }}
                                    disabled={true}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid item xs={12} sm={10} align="center">
                                <AutoComplete
                                    placeholder='State'
                                    className="autocomplete-fixed"
                                    suggestions={states}
                                    selectedValue={{ value: user.address.state, id: user.address.state }}
                                    disabled={true}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <Grid item xs={12} sm={10}>
                                <TextBox
                                    type="number"
                                    name="user.address.postalCode"
                                    value={user.address.postalCode || ''}
                                    label="Zip Code"
                                    className={classes.textField}
                                    margin="dense"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} align="center">
                            <Grid item xs={12} sm={12}>
                                <TextArea
                                    name="comments"
                                    value={comments || ''}
                                    label="Comments"
                                    className={classes.textField}
                                    margin="dense"
                                    handleChange={this.handleChange}
                                    disabled={isFunder}
                                    multiline={true}
                                    validators={['isMaxLen1000']}
                                    errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.STR_LEN1000]}
                                />
                            </Grid>
                        </Grid>
                        {(!isFunder || !isReadOnly) ?
                            <Grid item xs={12}>
                                <SaveCancel
                                    saveText="Save"
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { this.handleCancel() }}
                                />
                            </Grid> : null
                        }
                    </Grid>


                </ValidatorForm>
                <DeleteDialog
                    open={this.state.confirmPopup}
                    content={"A Project with this name already exists. Do you still wish to continue?"}
                    handleDeleteAction={() => {
                        this._saveForm();
                        this.setState({ confirmPopup: false })
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ confirmPopup: false })
                    }}
                />
                {/* <AlertDialog
                open={this.state.confirmPopup}
                saveVisible={this.state.isProjectNameChanged?true:false}
                cancelVisible={true}
                cancelText={"Cancel"}
                saveText={"Save"}
                //className={'loanprojalert'}
                title={'Confirmation'}
                onClose={(event,reason) => {
                    if (reason !== 'backdropClick') {
                        this.setState({ confirmPopup: false,projectName:this.props.projectId?this.props.details.data.name:this.state.projectName})
                      }   
                }}
                onSave={()=>{
                    this._saveForm();
                    this.setState({ confirmPopup: false })
                }}
                >
                <p>{this.state.isProjectNameChanged===false?'A Project with this name already exists':'A Project with this name already exists. Do you still wish to continue?'}</p>
                </AlertDialog> */}
            </div>
        );
    }
}

/**
 *
 */
General.defaultProps = {
    isReadOly: false,
    projectId: null,
    copyId: null,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}


/**
 * Bind Component Property Types
 */
General.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

/**
 * Maps state from store to props
 */
const mapStateToProps = (state) => {
    return {
        projects: state.loanProjects,
        details: state.loanProjects.getOne,
        states: state.configuration.configurations[configurationConstants.state] || [],
        cities: state.configuration.configurations[configurationConstants.cities] || [],
        pageAccess: history.location.pathname.split("/")[2] === "new" ? state.pageAccess['loanProjectsNew'] || [] : state.pageAccess['loanProjectEdit'] || [],
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        isProjectNameExists: (data, callBack) => dispatch(loanProjectActions.isProjectNameExists(data, callBack)),
        getById: (id) => dispatch(loanProjectActions.getById(id)),
        getAllOrgs: (text, page) => dispatch(loanProjectActions.getAllOrg(text, page)),
        getAllUsersInOrg: (orgId) => dispatch(loanProjectActions.getUsersInOrg(orgId)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type))
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(General));