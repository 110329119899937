import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// UA Fields
import * as UAFields from "./UATableFields";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions
import { UAActions } from "../../actions/UA";
import { confActions } from '../../actions/configuration';
import { sitesActions } from '../../actions/sites';
import { buildingActions } from '../../actions/building';

// Import Helpers
import { getUserRole, getUserOrgId, history } from '../../helpers';
import { configurationConstants } from '../../helpers/appConstants';
import ValidatorForm from "../../helpers/fieldValidations";
import { getParentCardHeight } from '../../helpers/global';

// Import Components
import TableComponent from '../../components/Table/TableComponent';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import SaveCancel from '../../components/SaveCancel/SaveCancel';

const headerCols = ["Implementation Date", "Effective Date", "#BDRMS", "Allowance Source", "Utility Type", "Amount", "Comment"];

const UARowData = {
    effectiveDate: '',
    dateUA: '',
    bedroom: '',
    utilitySource: '',
    utilityType: '',
    amount: '',
    comments: ''
};

const componentMap = {
    effectiveDate: UAFields.EffectiveDate,
    dateUA: UAFields.DateUA,
    bedroom: UAFields.BedRooms,
    utilitySource: UAFields.AllowanceSource,
    utilityType: UAFields.UtilityType,
    amount: UAFields.Amount,
    comments: UAFields.Comment
}
/**
 * UAView Component
 * @class UAView
 * @extends {Component}
 */
class UAView extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            utilityAllowance: [{ ...UARowData, disabled: false, _id: 0, index: 0 }],
            _id: null
        };
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { isArchived } = this.props;
        // config
        this.props.getConfiguration(configurationConstants.bedrooms);
        this.props.getConfiguration(configurationConstants.utilityAllowanceSource);
        this.props.getConfiguration(configurationConstants.utilityAllowanceType);

        if (this.props.buildingId) {
            this.props.getBuildingById(this.props.buildingId, isArchived ? 'archives' : null);
            this.props.getById(this.props.buildingId, isArchived ? 'archives' : null);
        }

        if (this.props.siteId) {
            this.props.getSiteById(this.props.siteId, isArchived ? 'archives' : null);
        }
    }
    /**
     * Component Will Receive Props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { getOne, utilityAllowanceType, utilityAllowanceSource, bedrooms } = nextProps;
        // update get one data
        //year added for sorting
        getOne.utilityAllowance&&getOne.utilityAllowance.map(item=>{
            if(!item['year']){
                item['year'] = new Date(item.effectiveDate).getFullYear()
            }
        })
        let sortedUtility = getOne.utilityAllowance&&getOne.utilityAllowance.sort((a,b) => (a.year > b.year) ? -1 : ((b.year < a.year) ? 1 : 0));
        if (getOne._id && this.props.buildingId) {
            if (getOne._id !== this.state._id) {
                this.setState({
                    _id: getOne._id,
                    utilityAllowance: sortedUtility.map((ut) => {
                        if (ut.utilityType_id) {
                            if (typeof (ut.utilityType_id) === 'string') {
                                let uaArray = ut.utilityType_id.split(',');
                                uaArray.forEach((ua, index) => {
                                    utilityAllowanceType.forEach(uaType => {
                                        if (uaType.key === +ua) {
                                            ut.utilityType = (index !== 0 && ut.utilityType) ? `${ut.utilityType}, ${uaType.name}` : uaType.name
                                        }
                                    })
                                })
                            } else {
                                ut.utilityType_id.forEach((uaTypeId, index) => {
                                    utilityAllowanceType.forEach(uaType => {
                                        if (uaType.key === +uaTypeId) {
                                            ut.utilityType = (index !== 0 && ut.utilityType) ? `${ut.utilityType}, ${uaType.name}` : uaType.name
                                        }
                                    })
                                })
                            }
                        }
                        if (ut.source_id) {
                            utilityAllowanceSource.forEach(uaSource => {
                                if (uaSource.key === +ut.source_id) {
                                    ut.utilitySource = uaSource.name
                                }
                            })
                        }
                        if (ut.bedroom_id) {
                            bedrooms.forEach(bedRoom => {
                                if (bedRoom.key === +ut.bedroom_id) {
                                    ut.bedroom = bedRoom.desc
                                }
                            })
                        }
                        return ut
                    })
                });
            }
        }
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        let { utilityAllowance } = this.state;
        const { utilityAllowanceType, utilityAllowanceSource, bedrooms } = this.props;
        let utIndex = utilityAllowance.filter(ut => ut._id === data.id)[0];
        utIndex[name] = data.value;
        utilityAllowance = utilityAllowance.map((ut) => {
            if (ut.utilityType_id) {
                if (typeof (ut.utilityType_id) === 'string') {
                    let uaArray = ut.utilityType_id.split(',');
                    uaArray.forEach((ua, index) => {
                        utilityAllowanceType.forEach(uaType => {
                            if (uaType.key === +ua) {
                                ut.utilityType = (index !== 0 && ut.utilityType) ? `${ut.utilityType}, ${uaType.name}` : uaType.name
                            }
                        })
                    })
                } else {
                    ut.utilityType_id.forEach((uaTypeId, index) => {
                        utilityAllowanceType.forEach(uaType => {
                            if (uaType.key === +uaTypeId) {
                                ut.utilityType = (index !== 0 && ut.utilityType) ? `${ut.utilityType}, ${uaType.name}` : uaType.name
                            }
                        })
                    })
                }
            }
            if (ut.source_id) {
                utilityAllowanceSource.forEach(uaSource => {
                    if (uaSource.key === +ut.source_id) {
                        ut.utilitySource = uaSource.name
                    }
                })
            }
            if (ut.bedroom_id) {
                bedrooms.forEach(bedRoom => {
                    if (bedRoom.key === +ut.bedroom_id) {
                        ut.bedroom = bedRoom.desc
                    }
                })
            }
            return ut
        })
        this.setState({ utilityAllowance });
    }
    /**
     * A method for handle table row delete onclick
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {

        if (this.props.match && this.props.match.path.includes('bulk-upload')) {
            let utilityAllowance = this.state.utilityAllowance.filter((ua) => {
                return ua._id !== id;
            })
            this.setState({
                utilityAllowance: utilityAllowance
            });
            let disableUpload = utilityAllowance.length <= 0 ? true : false;
            this.props.disableUploadBtn(disableUpload)
        }
        else {
            let utilityAllowance = this.state.utilityAllowance.filter((ua) => {
                return ua._id !== id;
            })
            this.setState({
                utilityAllowance: utilityAllowance
            });
        }
    }
    /**
     * Handle Form Submit Event
     * @param {*} e 
     */
    handleSubmit = (e) => {
        const { isBulkUpload, projectId, buildingId } = this.props;
        const formData = { utilityAllowance: this.state.utilityAllowance, buildingId: buildingId };
        if (!isBulkUpload) {
            this.props.updateUADetails(buildingId, formData);
        } else {
            this.props.bulkUpload(projectId, null, formData);
        }
    };
    /**
     * Get Change Status
     * @returns 
     */
    getChangedStatus = () => {
        // return JSON.stringify(this.props.getOne.utilityAllowance) === JSON.stringify(this.state.utilityAllowance);
        return false;
    }
    /**
     * Bind HTML to ReactDOM
     * @returns 
     */
    render() {
        let { isBulkUpload, pageAccess, classes, bedrooms, utilityAllowanceType, utilityAllowanceSource, isSubPage } = this.props;
        if(history.location.pathname.includes('archives')){
            pageAccess.push('BACK')
        }
        return (
            <div className="uacustom" >
                <ContentWrapper
                    classes={classes}
                    isSubPage={isSubPage}
                    ischildPage={false}
                    isUABulkUploadPage={true}
                    pageAccess={isBulkUpload && pageAccess.indexOf('ADD') >= 0 ? ['ADD', 'SAVE', 'UPDATE'] : pageAccess}
                    title=""
                    titleBg={'grey'}
                    handleAddActionClick={() => {

                        if (this.props.match && this.props.match.path.includes('bulk-upload')) {
                            const index = Math.max(...this.state.utilityAllowance.map(r => r._id), 0) + 1;
                            this.setState({
                                utilityAllowance: [
                                    { ...UARowData, _id: index, index },
                                    ...this.state.utilityAllowance,
                                ]
                            });
                            this.props.disableUploadBtn(false)
                        } else {
                            const index = Math.max(...this.state.utilityAllowance.map(r => r._id), 0) + 1;
                            this.setState({
                                utilityAllowance: [
                                    { ...UARowData, _id: index, index },
                                    ...this.state.utilityAllowance,
                                ]
                            });
                        }
                    }}
                    handleUploadActionClick={() => {
                        history.push({
                            pathname: '/ua/bulk-upload',
                            state: {
                                pageAccess: this.state.pageAccess
                            }
                        })
                    }}
                    handleBackActionClick={()=>{
                        history.goBack()
                    }}
                >
                    <ValidatorForm
                        name="uaForm"
                        ref="uaForm"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                        onError={errors => { }}>
                        <Grid container spacing={3} className="uascroll" >
                            <Grid item xs={12} sm={12} >
                                <div className='UAtable' style={{ height: isSubPage ? getParentCardHeight() - 200 : getParentCardHeight() - 180 }}>
                                    <TableComponent
                                        header={headerCols}
                                        field={Object.keys(UARowData)}
                                        search={[]}
                                        componentMap={componentMap}
                                        fieldComponentOnChange={this.fieldComponentOnChange}
                                        actions={pageAccess}
                                        links={[]}
                                        isLoading={false}
                                        data={this.state.utilityAllowance}
                                        handleTableDeleteAction={this.handleTableDeleteAction}
                                        paginationVisible={false}
                                        removedSortingRow={headerCols}
                                        searchVisible={false}
                                        userOrgId={this.userOrgId}
                                        userRole={this.userRole}
                                        config={{
                                            classes,
                                            bedrooms,
                                            utilityAllowanceType,
                                            utilityAllowanceSource
                                        }}
                                        noDataErrorMessage={"No Records Found"}
                                        style={{
                                            overflowX: 'auto'
                                        }}
                                        innerStyle={{
                                            overflow: 'visible'
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                    {
                        pageAccess.indexOf('SAVE') > -1 && !isBulkUpload ? (
                            <div className='UAV-btn-container'>
                                <SaveCancel
                                    handleSaveButtonClick={() => {
                                        this.refs.uaForm.submit()
                                    }}
                                    handleCancelButtonClick={() => { history.goBack() }}
                                    disableSave={this.getChangedStatus()}
                                />
                            </div>
                        ) : null
                    }
                </ContentWrapper>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
UAView.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired
}
/** Default Props */
UAView.defaultProps = {
    pageAccess: [],
    buildingId: null,
    isReadOly: false,
    isBulkUpload: false,
    projectId: null,
    siteId: null,
    isSubPage: true
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ utilityAllowance, pageAccess, configuration: { configurations } }, ownProps) => {
    return {
        getOne: utilityAllowance.getOne,
        pageAccess: ownProps.pageAccess ? ownProps.pageAccess : pageAccess['uaEdit'] || [],
        bedrooms: configurations[configurationConstants.bedrooms],
        utilityAllowanceType: configurations[configurationConstants.utilityAllowanceType],
        utilityAllowanceSource: configurations[configurationConstants.utilityAllowanceSource]
    }
};
/**
 * Maps Dispatch to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getConfiguration: (name) => dispatch(confActions.getConfiguration(name)),
        getById: (id, type) => dispatch(UAActions.getUADetails(id, type)),
        updateUADetails: (id, formData) => dispatch(UAActions.updateUADetails(id, formData)),
        bulkUpload: (projectId, siteId, formData) => dispatch(UAActions.bulkUpload(projectId, siteId, formData)),
        getSiteById: (siteId, type) => dispatch(sitesActions.getById(siteId, type)),
        getBuildingById: (buildingId, type) => dispatch(buildingActions.getById(buildingId, type))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(UAView));

