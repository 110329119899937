import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";

// import child router components
import OrganizationsList from "./OrganizationsList";
import OrganizationsView from "./OrganizationsView";
import OrganizationsNew from "./OrganizationsNew";
import OrganizationsMerge from "./Merge";

/**
 * organizationsRouter Container
 * @class organizationsRouter
 * @extends {Component}
 */
class organizationsRouter extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            reset: false,
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { match } = this.props;
        return (
            <Switch>
                <Route exact path={match.url} render={(props) => {
                    return <OrganizationsList {...props} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <OrganizationsNew {...this.props} match={props.match} />
                }} />
                <Route exact path={`${match.url}/merge`} render={(props) => {
                    return <OrganizationsMerge {...this.props} match={props.match} />
                }} />
                <Route path={`${match.url}/:orgId`} render={(props) => {
                    return <OrganizationsView {...this.props} match={props.match} />
                }} />

            </Switch>

        );
    }
}
/**
 * Bind Component Property Types
 */
organizationsRouter.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Bind Component Property Types
 */
organizationsRouter.defaultProps = {
    classes: {},
};
/**
 * Export Component
 */
export default organizationsRouter;
