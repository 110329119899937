import { sitesConstants } from '../constants';
import { sitesService, orgService, projectsService, incomeRentService } from '../services';
import { alertActions, pageAccessActions, validationActions } from './';
import { history } from '../helpers';

export const sitesActions = {
    getAll,
    getById,
    addNewSite,
    delete: _delete,
    resetFilter,
    getUsersInOrg,
    getAllOrg,
    getOrganizations,
    getUsersInOrgwithRole,
    getProjectsByName,
    updateSite,
    getOrganizationsOnsite,
    updateClaimStatus,
    getSiteNameList,
    getSiteByTable1List
};

/**
 * Get all sites 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 * @param {*} projectId 
 */
function getAll(skip, limit, orderBy, order, sFields, sValues, projectId = null) {
    return dispatch => {
        dispatch(request());

        sitesService.getAll(skip, limit, orderBy, order, sFields, sValues, projectId)
            .then(
                sites => {
                    dispatch(success(sites));
                    const { page } = sites;
                    if (page) {
                        if (page.isLastPage) {
                            dispatch(alertActions.error('No more records to show.'));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GETALL_REQUEST } }
    function success(sites) { return { type: sitesConstants.GETALL_SUCCESS, sites } }
    function failure(error) { return { type: sitesConstants.GETALL_FAILURE, error } }
}

/**
 * Get site details page
 * 
 * @param {*} id - site_id
 */
function getById(id, type) {
    return dispatch => {
        dispatch(request());

        sitesService.getById(id, type)
            .then(
                site => {
                    dispatch(success(site));
                    // populate drop down for project and onsite users
                    const { data } = site;
                    if (data !== undefined) {
                        const { onsiteManager, projectManager } = data;
                        if (projectManager.org_id !== null) {
                            dispatch(getUsersInOrg(projectManager.org_id._id));
                        }
                        if (onsiteManager.org_id !== null) {
                            dispatch(getUsersInOrgwithRole(onsiteManager.org_id._id));
                        }
                    }
                },
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: sitesConstants.GETONE_REQUEST } }
    function success(site) { return { type: sitesConstants.GETONE_SUCCESS, site } }
    function failure(error) { return { type: sitesConstants.GETONE_FAILURE, error } }
}

/**
 * clear filter
 */
function resetFilter() {
    return dispatch => {
        dispatch(set());
        setTimeout(() => {
            dispatch(reset());
        }, 100);
    };

    function set() { return { type: sitesConstants.CLEAR_FILTER_SET } }
    function reset() { return { type: sitesConstants.CLEAR_FILTER_RESET } }
}

/**
 * Create new site
 * @param {*} formData - new site data
 */
function addNewSite(formData,successCallback,errorCallback) {
    return dispatch => {
        dispatch(request());

        sitesService.addNewSite(formData)
            .then(
                site => {
                    dispatch(success(site));
                    successCallback(site)
                    // history.push("/sites");
                    dispatch(alertActions.success("Site Added Successfully"));
                    history.goBack();
                },
                error => {
                    dispatch(failure(error.toString()));
                    errorCallback(error)
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: sitesConstants.ADD_SITE_REQUEST } }
    function success(site) { return { type: sitesConstants.ADD_SITE_SUCCESS, site } }
    function failure(error) { return { type: sitesConstants.ADD_SITE_FAILURE, error } }

}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id - site_id
 */
function _delete(id,siteData, cb = () => { }) {
    return dispatch => {
        dispatch(request(id));
        let project_id = siteData.length>0?parseInt(siteData[0].project_id):0;
        let reportYear_id = siteData.length>0?parseInt(siteData[0].reportYear_id):0;
        sitesService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                    dispatch(alertActions.success('Deleted Successfully'));
                    setTimeout(() => {
                        dispatch(alertActions.clear());
                    }, 1000);
                    cb();
                    dispatch(validationActions.removeValidationOnDelete(id));
                },
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: sitesConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: sitesConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: sitesConstants.DELETE_FAILURE, id, error } }
}

/**
 * Get users in organization
 * 
 * @param {*} orgId  
 */
function getUsersInOrg(orgId, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        sitesService.getByOrgId(orgId)
            .then(
                user => {
                    let users = user.data.map((data) => {
                        data.label = `${data.firstName} ${data.lastName}`;
                        // add star before  inactive
                        data.label = data.isActive ? data.label : `*${data.label}`;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GET_ORG_USERS_REQUEST } }
    function success(users) { return { type: sitesConstants.GET_ORG_USERS_SUCCESS, users } }
    function failure(error) { return { type: sitesConstants.GET_ORG_USERS_FAILURE, error } }
}

/**
 * Get users in organization by role
 * 
 * @param {*} orgId 
 */
function getUsersInOrgwithRole(orgId, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        sitesService.getByOrgIdRole(orgId)
            .then(
                user => {
                    let users = user.data.map((data) => {
                        data.label = `${data.firstName} ${data.lastName}`;
                        // add star before  inactive
                        data.label = data.isActive ? data.label : `*${data.label}`;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(users));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GET_ORG_USERS_ROLE_REQUEST } }
    function success(users) { return { type: sitesConstants.GET_ORG_USERS_ROLE_SUCCESS, users } }
    function failure(error) { return { type: sitesConstants.GET_ORG_USERS_ROLE_FAILURE, error } }
}

/**
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} isAutoComplete 
 */
function getAllOrg(text, callBack = () => { }) {
    return dispatch => {
        dispatch(request());

        orgService.getAll(1, 0, 'name', 'desc', "", "", true)
            .then(
                org => {
                    let orgs = org.data.map((data) => {
                        data.label = data.name;
                        data.value = data._id;
                        return data;
                    });
                    dispatch(success(orgs));
                    callBack();
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GET_ALL_ORGS_REQUEST } }
    function success(orgs) { return { type: sitesConstants.GET_ALL_ORGS_SUCCESS, orgs } }
    function failure(error) { return { type: sitesConstants.GET_ALL_ORGS_FAILURE, error } }
}

/**
 * other pages organization deopdown value
 * 
 * @param {*} text 
 * @param {*} page 
 */
function getOrganizations(text, page) {
    return dispatch => {
        dispatch(request());

        orgService.getOrganizationsBySearchKey(text, page)
            .then(
                org => {
                    let suggestions = []
                    org.data.map(suggestion => (
                        suggestions.push({
                            value: suggestion._id,
                            label: suggestion.name,
                        })
                    ));

                    dispatch(success(suggestions));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GETALL_ORGANIZATION_REQUEST } }
    function success(suggestions) { return { type: sitesConstants.GETALL_ORGANIZATION_SUCCESS, suggestions } }
    function failure(error) { return { type: sitesConstants.GETALL_ORGANIZATION_FAILURE, error } }
}

function getOrganizationsOnsite(text, page) {
    return dispatch => {
        dispatch(request());

        orgService.getOrganizationsBySearchKey(text, page)
            .then(
                org => {
                    let suggestions = []
                    org.data.map(suggestion => (
                        suggestions.push({
                            value: suggestion._id,
                            label: suggestion.name,
                        })
                    ));

                    dispatch(success(suggestions));
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GETALL_ORGANIZATION_ONSITE_REQUEST } }
    function success(suggestions) { return { type: sitesConstants.GETALL_ORGANIZATION_ONSITE_SUCCESS, suggestions } }
    function failure(error) { return { type: sitesConstants.GETALL_ORGANIZATION_ONSITE_FAILURE, error } }
}

/**
 * A method to get all project name lists
 *
 */
function getProjectsByName(text, type) {
    return dispatch => {
        dispatch(request());

        projectsService.getProjectsByName(text, type)
            .then(
                projects => {                   
                    dispatch(success(projects.data))
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: sitesConstants.GETALL_PROJECT_LISTS_REQUEST } }
    function success(suggestions) { return { type: sitesConstants.GETALL_PROJECT_LISTS_SUCCESS, suggestions } }
    function failure(error) { return { type: sitesConstants.GETALL_PROJECT_LISTS_FAILURE, error } }
}

/**
 * Update a site based on site  id
 * 
 * @param {*} formData - updated value
 * @param {*} id - site_id
 */
function updateSite(formData, id,checkFieldsChanged = false) {
    return dispatch => {
        dispatch(request());
        sitesService.updateSite(formData, id)
            .then(
                site => {
                    dispatch(success(site));
                    if (!checkFieldsChanged){
                        dispatch(alertActions.success("Updated Successfully"));
                    }
                    if (checkFieldsChanged){
                        dispatch(alertActions.success("As you have updated the Site details, your Income and rent limit values are processing. You will be notified once Table 1 is updated successfully."))
                        incomeRentService.triggerIncomeRent(0,id,0,0,0,0).then(
                            site => {
                                dispatch(alertActions.success("Income and rent limit values are updated successfully in Table 1."));
                            }
                        )
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: sitesConstants.UPDATE_SITE_REQUEST } }
    function success(site) { return { type: sitesConstants.UPDATE_SITE_SUCCESS, site } }
    function failure(error) { return { type: sitesConstants.UPDATE_SITE_FAILURE, error } }

}

/**
 * An action method to call a service method to change the site claim status true / false
 *
 * @param {*} site_id site_id to change
 * @returns
 */
function updateClaimStatus(site_id, callBack) {
    return dispatch => {
        dispatch(request());

        sitesService.updateClaimStatus(site_id)
            .then(
                claimRes => {
                    dispatch(success(claimRes.data));
                    dispatch(alertActions.success(claimRes.message));
                    callBack()
                    // update page access
                    dispatch(pageAccessActions.getAccess('siteEdit', { site_id: site_id }));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: sitesConstants.UPDATE_SITE_CALIM_STATUS_REQUEST } }
    function success(data) { return { type: sitesConstants.UPDATE_SITE_CALIM_STATUS_SUCCESS, data } }
    function failure(error) { return { type: sitesConstants.UPDATE_SITE_CALIM_STATUS_FAILURE, error } }
}

/**
 * A method to get site name list
 */
function getSiteNameList(data) {
    return dispatch => {
        dispatch(request());
        sitesService.getSiteNameList(data)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: sitesConstants.GET_SITENAME_REQUEST } }
    function success(data) { return { type: sitesConstants.GET_SITENAME_SUCCESS, data } }
    function failure(error) { return { type: sitesConstants.GET_SITENAME_FAILURE, error } }
}

/**
 * A method to get site table1 list
 */
function getSiteByTable1List(siteId) {
    return dispatch => {
        dispatch(request());
        sitesService.getSiteTable1List(siteId)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: sitesConstants.GET_SITE_TABLE1_REQUEST } }
    function success(data) { return { type: sitesConstants.GET_SITE_TABLE1_SUCCESS, data } }
    function failure(error) { return { type: sitesConstants.GET_SITE_TABLE1_FAILURE, error } }
}