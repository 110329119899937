import { authHeader, globalVariable, handleResponse } from '../helpers';

export const loanProjectsService = {
    getAll,
    create,
    delete:_delete,
    getReportActivity,
    updateFunderSettings,
    createFunderSettings,
    isProjectNameExists,
    getById,
    update,
    checkLoanProjectFunder,
    getFunderSettingsConfigDatas,
    getBasicFunderSettings,
    getAllFunders
}

/**
 * A service call to check whether the project name is exists or not
 * 
 * @param {*} data 
 * @param {*} callBack 
 */
function checkLoanProjectFunder(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/loanFunder-settings/funder-exists`, requestOptions).then(handleResponse);
}

/**
 * A service call to check whether the project name is exists or not
 * 
 * @param {*} data 
 * @param {*} callBack 
 */
function isProjectNameExists(data) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/name-exists`, requestOptions).then(handleResponse);
}

/**
 * Get all funder listing page
 * 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sfields 
 * @param {*} sValues 
 * @param {*} projectId - Loanproject_id
 * @param {*} exactMatch 
 */
 function getAllFunders(skip, limit, orderBy, order, sfields, sValues, projectId, exactMatch = false) {
    let data = {
        fields: sfields,
        values: sValues,
        exactMatch,
        projectId: projectId
    };

    const requestOptions = {
        method: 'Post',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/funder?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 *Get single project view
 *
 * @param {*} id - project_id
 * @returns
 */
function getById(id, page) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/${id}/${page}`, requestOptions).then(handleResponse);
}

/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - project_id
 * @returns
 */
function _delete(id, page) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/${id}/${page}`, requestOptions).then(handleResponse);
}

/**
 *Get project listing page details
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [exactMatch=false]
 * @param {*} [userId=null] - default value null
 * @returns
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, exactMatch = false, userId = null, query = { isDelete: false }) {

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch,
        userId: userId, // to get user's associated projects
        query: query // query to fetct project
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 * A method call API to create the loan project details
 *
 * @param {*} formData contains the loan project details data to create
 * @returns
 */
function create(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/`, requestOptions).then(handleResponse);
}

/**
 * Get Report Listing Page
 */
function getReportActivity(skip, limit, orderBy, order, sfields, sValues, projectId) {

    let data = {
        fields: sfields,
        values: sValues,
        projectId: projectId
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/reportActivity/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}

/**
 * A Service method to get the funder basic settings for the project
 *
 * @param {*} formData contains the data to get the settings
 * @param {*} funderId contains the fudner id to update the settings
 */
function updateFunderSettings(formData, funderId) {
    if (funderId) {
        const requestOptions = {
            method: 'PUT',
            headers: { ...authHeader(), "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        };

        return fetch(`${globalVariable.apiURL}/loanFunder-settings/${funderId}`, requestOptions).then(handleResponse);
    }
}

/**
 * A Service method to create the funder basic settings for the project
 *
 * @param {*} formData the data to create the settings
 */
function createFunderSettings(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(formData)
    };

    return fetch(`${globalVariable.apiURL}/loanFunder-settings`, requestOptions).then(handleResponse);
}

/**
 * A method call API to update the project details
 *
 * @param {*} id contains the id of project to update
 * @param {*} formData contains the project details data to update
 * @returns
 */
function update(id, formData) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    return fetch(`${globalVariable.apiURL}/loanProjects/${id}`, requestOptions).then(handleResponse);
}

/**
 * A service method to get funder settings basic config datas
 *
 */
function getFunderSettingsConfigDatas(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader() }
    };

    return fetch(`${globalVariable.apiURL}/config/loanFunder-settings`, requestOptions).then(handleResponse);
}

/**
 * A Service method to get the funder basic settings for the project
 *
 * @param {*} funderId contains the funder id to get the settings
 */
function getBasicFunderSettings(funderId, orgId) {
    if (funderId) {
        const requestOptions = {
            method: 'GET',
            headers: authHeader()
        };

        return fetch(`${globalVariable.apiURL}/loanFunder-settings/${orgId}/${funderId}`, requestOptions).then(handleResponse);
    }
}