import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import SitesList from "./SitesList";
import SitesNew from "./SitesNew";
import SitesView from "./SitesView";
/**
 * SiteRouter Component
 * @class SiteRouter
 * @extends {Component}
 */
class SiteRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <SitesList {...props} page={'sites'} />
                }} />
                <Route exact path={`${match.url}/new`} component={SitesNew} />
                <Route path={`${match.url}/:siteId`} component={SitesView} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default SiteRouter;
