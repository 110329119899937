import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

// Import Components
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import HelpFloater from '../../components/HelpFloater/HelpFloater';

// Import Redux Actions
import { orgActions, helpActions } from '../../actions';
import { confActions } from '../../actions/configuration';
import { searchActions } from '../../actions/search';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { getUserRole, getUserOrgId, getUserAccessForPage, globalVariable, history, filterSearchFields } from '../../helpers';

/**
 * Status Component
 * @class StatusComponent
 * @extends {Component}
 */
class StatusComponent extends Component {
    /**
     * Handle Change
     * @param {*} event 
     */
    handleChange = event => {
        const { data } = this.props;
        this.props.handleChange("isActive", { id: data._id, status: event.target.checked, hasAssocProjects: true });
    };

    /**
     * Render HTML
     * @returns 
     */
    render() {
        const { data } = this.props;
        //edit option for logged in user's org
        // if (role === 'readOnlyFunderWithOA' || role === 'readOnlyFunderWithOAandHO' || role === 'readOnlyFunderWithOAandBoth') {
        //     return (
        //         orgId === data._id &&
        //         <Switch
        //             disabled={false}
        //             color="primary"
        //             size="small"
        //             checked={data.isActive}
        //             onChange={this.handleChange}
        //         />)
        // } else {
        return (
            <Switch
                disabled={data.disabled}
                color="primary"
                size="small"
                checked={data.isActive}
                onChange={this.handleChange}
            />)

        // }
    }
}

/** Default props */
StatusComponent.defaultProps = {
    checked: false,
    disabled: true,
    handleChange: () => { },
    data: {}
}

/**
 * Website Component
 * @class WebsiteComponent
 * @extends {Component}
 */
class WebsiteComponent extends Component {
    render() {
        const { data } = this.props;
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        const isValidUrl = pattern.test(data.website)

        let hasProtocol = data.website ? data.website.includes('http') : false;
        data.href = hasProtocol ? data.website : `//${data.website}`
        return (
            isValidUrl ? <a href={`${data.href}`} className="websiteLink" target="_blank" rel="noopener noreferrer">{data.website}</a> : <span>{data.website}</span>
        )
    }
}
const componentMap = {
    isActive: StatusComponent,
    website: WebsiteComponent
}
/**
 * OrganizationsList Component
 * @class OrganizationsList
 * @extends {Component}
 */
class OrganizationsList extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            inActiveOrgId: null,
            reset: false,
            openHelp: false
        }
        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
        this.pageAccess = getUserAccessForPage('organizations');
    }

    /**
     * Handle Component Will Mount 
     */
    UNSAFE_componentWillMount() {
        const { organizations } = this.props;
        const { page } = organizations;
        this.props.getHelp();
        if (page.skip) {
            this.props.getOrganizations(page.skip, page.limit, page.orderBy, page.order, page.sFields, page.sValues);
        } else {
            this.props.getOrganizations(1, globalVariable.tableRowSize, 'name', 'asc', '', '');
        }
        setTimeout(() => {
            this.props.getFieldFilter('organizations');
        }, 100);
    }

    /**
     * Handle Component Did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }

    /**
     * Handle Table Component On Page Change Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { organizations, searchData: { sFields, sValues } } = this.props;
        this.props.getOrganizations(skip, limit, organizations.page.orderBy, organizations.page.order, sFields, sValues);
    };

    /**
     * Handle Table Component On Sort Request Event
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { organizations, searchData: { sFields, sValues } } = this.props;
        this.props.getOrganizations(organizations.page.skip, organizations.page.limit, orderBy, order, sFields, sValues);
    };

    /**
     * Handle Table Component Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { organizations, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getOrganizations(1, globalVariable.tableRowSize, organizations.page.orderBy, organizations.page.order, sFields, sValues);
    };

    onDropDownChangeRequestforOrg = (searchField, searchValue) =>{
        if(searchValue === undefined){
            searchValue = 1
        }
        let searchKey = searchValue===1?'Active':searchValue===0?'Disabled':'';
        this.setState({ reset: false });
        const { organizations, searchData } = this.props;
        let { sFields, sValues } = searchData;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }

        this.props.searchActions({ sFields, sValues });
        this.props.getOrganizations(1, globalVariable.tableRowSize, organizations.page.orderBy, organizations.page.order, sFields, sValues,[],[]);

        //to avoid catch issue
        setTimeout(
            ()=>{
                this.props.searchActions({ sFields, sValues });
                this.props.getOrganizations(1, globalVariable.tableRowSize, organizations.page.orderBy, organizations.page.order, sFields, sValues,[],[]);
            }
        ,500)
    }


    /**
     * Handle Add Action Click Event
     */
    handleAddActionClick = () => {
        history.push({
            pathname: '/organizations/new',
            state: {
                page: 'organizationNew',
                pageData: {}
            }
        });
    };

    /**
     * Handle Merge Action Click
     */
    handleMergeActionClick = () => {
        history.push({
            pathname: '/organizations/merge',
            state: {
                page: 'organizationMerge',
                pageData: {}
            }
        });
    }

    /**
     * Handle Clear Filter Action Click Event
     */
    handleClearFilterActionClick = () => {
        const { organizations } = this.props;
        this.props.searchActions({ sFields: [], sValues: [] });
        this.props.getOrganizations(organizations.page.skip, organizations.page.limit, organizations.page.orderBy, organizations.page.order, [], []);
    };

    /**
    * Handle Table Navigation Event
    * @param {*} id
    */
    handleNavigateAction = (id) => {
        history.push({
            pathname: '/organizations/' + id,
            state: {
                page: 'organizationEdit',
                pageData: {
                    org_id: parseInt(id, 10)
                }
            }
        });
    }

    /**
    * Handle Select the Column Filter Button Action
    */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // update visible columns
        this.props.changeFieldFilter("organizations", this.props.organizations.page.fieldFilters.visibleColumns);
    };

    /**
     * Handle Field Filter Popup On Close Event
     */
    handleFieldFilterOnClose = () => {
        // reset to current table column values if user not save the changes
        this.props.changeFieldFilter("organizations", this.props.organizations.page.fieldFilters.visibleColumns);
        // close the popup
        this.setState({ fieldFilter: false });
    }

    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("organizations", updatedColumns);
        } else {
            this.props.changeFieldFilter("organizations", [...this.props.visibleColumns, key]);
        }
    }

    /**
     * Handle Table Delete Action
    * @param {*} id
     */
    handleTableDeleteAction = (id) => {
        this.props.deleteUser(id)
    };

    /**
     * Handle Field Filter On Save Event
     */
    handleFieldFilterOnSave = () => {
        this.props.updateFieldFilter("organizations", this.props.visibleColumns, () => {
            // on api call back close the popup and call the table view api again
            this.setState({ fieldFilter: false });
            const { organizations, searchData: { sFields, sValues } } = this.props;
            this.props.getOrganizations(organizations.page.skip, organizations.page.limit, organizations.page.orderBy, organizations.page.order, sFields, sValues);
        });
    }

    /**
     * Handle Field Component On Change Event
     */
    fieldComponentOnChange = (name, data) => {
        const { organizations, searchData } = this.props;
        let { sFields, sValues } = searchData;
        this.props.getContacts(1, globalVariable.tableRowSize, 'name', 'asc', '', '', false, data.id)
        switch (name) {
            case "isActive":
                if (!data.status && data.hasAssocProjects) {
                    this.setState({ inActiveOrgId: { name, data } });
                } else {
                    this._setOrgActiveStatus(name, data);
                    setTimeout(
                        ()=>{
                            this.props.searchActions({ sFields, sValues });
                            this.props.getOrganizations(organizations.page.skip, organizations.page.limit, organizations.page.orderBy, organizations.page.order, sFields, sValues);
                        }
                    ,500)
                }
                break;
            default:
        }
    }

    /**
     * Set Organization Action Status
     */
    _setOrgActiveStatus(name, data) {
        const { organizations, searchData } = this.props;
        let { sFields, sValues } = searchData;
        this.props.setStatus(data.id, data.status, () => { 
            const { organizations, searchData: { sFields, sValues } } = this.props;
            this.props.getOrganizations(organizations.page.skip, organizations.page.limit, organizations.page.orderBy, organizations.page.order, sFields, sValues);
        },
        setTimeout(
            ()=>{
                this.props.searchActions({ sFields, sValues });
                this.props.getOrganizations(organizations.page.skip, organizations.page.limit , organizations.page.orderBy, organizations.page.order, sFields, sValues);
            }
        ,500));
        // close the popup
        this.setState({ inActiveOrgId: null })
    }

    /**
     * Handle Help Click Action
     */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }

    /**
     * Render Html
     * 
     * [1]  -   Modified to display delete for Inactive
     */
    render() {
        const { fieldFilter, openHelp } = this.state;
        const { userRole, userOrgId } = this;
        let { organizations, columnsMap, visibleColumns, needPadding,
            location: { pathname }, pageAccess, classes, searchData: { sFields, sValues }, helpData } = this.props;
        const fieldFilters = organizations.page ? organizations.page.fieldFilters : {};
        //----Hide Status Icon
        // console.log('fieldFilters***',fieldFilters,JSON.parse(localStorage.user).org)
        if(JSON.parse(localStorage.user).org.role!="superFunderWithHO" && JSON.parse(localStorage.user).org.role!="superFunder" ){
            fieldFilters.headerCols=fieldFilters.headerCols.filter(data=> data!="Status")
            fieldFilters.fieldCols=fieldFilters.fieldCols.filter(data=> data!="isActive")
            delete columnsMap.isActive
        }
        //---------------------
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        pageAccess = [...pageAccess, 'HELP'];
        // [1]
        if (organizations && organizations.data && organizations.data.length > 0) {
            organizations.data.forEach((elem, i) => {
                if (organizations['data'][i].organizationType.split(',').indexOf("Funder") === -1) {
                    organizations['data'][i].allowDelete = true
                } else {
                    if (organizations['data'][i]._id === getUserOrgId()) {
                        organizations['data'][i].allowDelete = true
                    } else {
                        organizations['data'][i].allowDelete = false
                    }
                }
            })
        }

        organizations.data.map(item=>{
            if(item['orgStatus']==='Disabled'){
                item['orgStatus']='Inactive'
            }
        })

        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title="Organizations"
                handleAddActionClick={this.handleAddActionClick}
                handleMergeActionClick={this.handleMergeActionClick}
                needPadding={needPadding}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleHelpActionClick={this.handleHelpActionClick}
            >
                <TableComponent
                    page={organizations.page.skip}
                    rowsPerPage={organizations.page.limit}
                    count={organizations.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    actions={pageAccess}
                    links={['name']}
                    order={organizations.page.order}
                    orderBy={organizations.page.orderBy}
                    isLoading={organizations.isLoading}
                    data={organizations.data}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    onDropDownChangeRequestforOrg={this.onDropDownChangeRequestforOrg}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    paginationVisible={true}
                    disablePagination={organizations.page.lastPage}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    noDataErrorMessage={"No Records Found"}
                    currentPage={'organizations'}
                    prevNext={false}
                    showLoading={false}
                />
                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                <DeleteDialog
                    open={this.state.inActiveOrgId !== null}
                    content={this.props.contacts && this.props.contacts.data.length === 0 ? "Are you sure you want to in activate the organization?" : "This org is associated with at least one project.Setting this org to not active will deactivate all contacts in this org ?"}
                    handleDeleteAction={() => {
                        const { name, data } = this.state.inActiveOrgId;
                        this._setOrgActiveStatus(name, data);
                    }}
                    id={this.state.deleteItemId}
                    handleDeleteOnCloseAction={() => {
                        this.setState({ inActiveOrgId: null })
                    }}
                />
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Organizations"
                />}
            </ContentWrapper>
        );
    }
}

/**
 * Set Props Types
 */
OrganizationsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

/**
 * Default Props
 */
OrganizationsList.defaultProps = {
    needPadding: false
};

/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['organizations'] || {};
    const searchData = state.search['organizations'] || { sFields: [], sValues: [] };
    const helpData = state.help.data[0]
    return {
        organizations: state.organizations,
        contacts: state.organizations.contacts,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess: state.pageAccess['organizationsList'] || [],
        searchData,
        helpData
    }
};

/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        getOrganizations: (skip, limit, orderBy, order, sField, sValue) => dispatch(orgActions.getAll(skip, limit, orderBy, order, sField, sValue)),
        resetFilter: () => dispatch(orgActions.resetFilter()),
        deleteUser: (id) => dispatch(orgActions.delete(id)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        setStatus: (id, status, callBack) => dispatch(orgActions.setActiveStatus(id, status, callBack)),
        searchActions: (data) => dispatch(searchActions.organizations(data)),
        getHelp: () => dispatch(helpActions.getAll({ _id: 11 })),
        getContacts: (skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, orgId = null) => dispatch(orgActions.getContacts(skip, limit, orderBy, order, sfields, sValues, isAutoComplete, orgId)),
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(OrganizationsList));