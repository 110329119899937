import React, { Component } from 'react'

export default ChartComponent => (
  /**
   * ResponsiveChart Component 
   * @class ResponsiveChart
   * @extends {Component}
   */
  class ResponsiveChart extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
      super(props)
      this.state = {
        containerWidth: null,
      }

      this.fitParentContainer = this.fitParentContainer.bind(this)
    }
    /**
     * Component Did Mount Event
     */
    componentDidMount() {
      this.fitParentContainer()
      window.addEventListener('resize', this.fitParentContainer)
    }
    /**
      * componentWillUnmount() is invoked immediately before a component 
      * is unmounted and destroyed. 
      * Perform any necessary cleanup in this method such as invalidating timers so on.
      */
    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer)
    }
    /**
     * Component Will Receive Props
     * @param {*} newProps 
     */
    UNSAFE_componentWillReceiveProps(newProps) {
      this.fitParentContainer();
    }
    /**
     * Fit Parent Container
     */
    fitParentContainer() {
      const { containerWidth } = this.state
      const currentContainerWidth = this.chartContainer
        /**The ‘.getBoundingClientRect’ method returns an object of the positions
         *  of the element’s left, right, top, and bottom border.*/
        .getBoundingClientRect().width

      const shouldResize = containerWidth !== currentContainerWidth

      if (shouldResize) {
        this.setState({
          containerWidth: currentContainerWidth,
        })
      }
    }
    /**
     * Render Chart
     * @returns 
     */
    renderChart() {
      const parentWidth = this.state.containerWidth

      return (
        <ChartComponent {...this.props} parentWidth={parentWidth} />
      )
    }
    /**
     *render html
     *
     * @returns
     */
    render() {
      const { containerWidth } = this.state
      const shouldRenderChart = containerWidth !== null

      return (
        <div
          ref={(el) => { this.chartContainer = el }}
          className="Responsive-wrapper"
        >
          {shouldRenderChart && this.renderChart()}
        </div>
      )
    }
  }
)
