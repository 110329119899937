const tableStyle = theme => ({
    root: {
        width: '100%',
        //marginTop: theme.spacing.unit * 1,
        overflowX: 'auto',
        position: "relative"
    },
    container: {
        // maxHeight: 575,
    },
    table: {
        width: 'max-content',
        minWidth: '100%'
    },
    editTable: {
        width: '100%',
    },
    width100: {
        width: '100%'
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    MuiIconButton: {
        root: {
            fontSize: "13px",
        },
    },
    searchRow: {
        borderBottomStyle: 'solid'
    },
    head: {
        backgroundColor: "#fff",
        position: "sticky",
        top: 0
    },
    lastCol: {
        '&:last-child': {
            paddingRight: "12px",
            textAlign: 'center'
        },
    },
    exploreEditTableTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        alignSelf: 'center',
    }
});

export default tableStyle;
