import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Actions
import { confActions } from '../../../actions/configuration';

// Helpers
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Constants and helpers
import { configurationConstants } from '../../../helpers';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextBox from '../../../components/TextBox/TextBox';
import { alertActions } from '../../../actions';

// neccessary db columns for a single bedroom row
const bedRoomData = {
    desc: '',
    value: ''
};

/**
 *Editable text Component
 * @class EditableText
 * @extends {Component}
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        
        //To display the 0 in frontend for 0 value given in db
        data['value'] = data['value'] === 0 ? "0":data['value'];
        return (
            <TextBox
                className={'textBox--bordered m-t-b-4'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

// component map
const componentMap = {
    desc: EditableText,
    value: EditableText
}

/**
 *Bedrooms Component
 * @class Bedrooms
 * @extends {Component}
 */
class Bedrooms extends Component {

    /**
     *Creates an instance of Bedrooms.
     * @param {*} props
     * @memberof Bedrooms
     */
    constructor(props) {
        super(props);
        this.state = {
            bedrooms: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.bedrooms);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { bedrooms } = nextProps;

        this.setState({
            saved: true,
            bedrooms: bedrooms
        })
    }
    /**
     * Handle submit
     */
    handleSubmit = () => {
        //check for duplicate bedrooms
        let bedrooms = this.state.bedrooms;
        let duplicateName=''
        let bedroomsName = bedrooms.map(function(item){ return item.desc.trim().toLowerCase() });
        let duplicateBedrooms = bedroomsName.some(function(item, idx){ 
                    if(bedroomsName.indexOf(item) !== idx){
                        duplicateName=bedrooms[bedroomsName.indexOf(item)].desc
                        return item
                    }
            });
        if(duplicateBedrooms){
            this.props.erroralert(`${duplicateName.toUpperCase()} bedroom already exists`);
        }else{
        this.saveForm();
        }
    };
    /**
     * Save form
     */
    saveForm() {
        const bedRooms = this.state.bedrooms.map((bd) => {
            return {
                ...bd,
                value: parseInt(bd.value, 10)
            }
        });
        this.props.updatedata(configurationConstants.bedrooms, bedRooms);
    }
    /**
     * Cell component on change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { bedrooms } = this.state;
        bedrooms[rowIndex][fieldName] = value;
        this.setState({ bedrooms });
    }
    /**
     *render HTML
     *
     * @returns
     * @memberof Bedrooms
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { bedrooms } = this.state;
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="bedrooms"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'BEDROOMS'}
                        headerCols={['DESCRIPTION', 'VALUE']}
                        requiredFields={['DESCRIPTION', 'VALUE']}
                        fieldCols={['desc', 'value']}
                        rowDatas={bedrooms}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        actions={['DELETE']}
                        style={style}
                        staticHeader={true}
                        height={height}
                        onAdd={() => {
                            if (bedrooms.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    bedrooms: [
                                        {
                                            ...bedRoomData,
                                            key: Math.max(...bedrooms.map(elt => elt.key)) + 1,
                                            type: configurationConstants.bedrooms
                                        },
                                        ...bedrooms
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            this.props.deleteConfig(item.type, item._id);
                        }}

                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    disableSave={isLoading === true}
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.bedrooms) }}
                />
            </ValidatorForm>
        );
    }
}

// prop types
Bedrooms.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        bedrooms: configuration.configurations[configurationConstants.bedrooms] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {

    return {
        getByName: (bedrooms) => dispatch(confActions.getByName(bedrooms)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert:(msg)=>dispatch(alertActions.error(msg))
    }
};
// export Bedrooms
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(Bedrooms));
