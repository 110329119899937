import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import FundersList from "./FundersList";
import FundersView from "./FundersView";
import FundersNew from "./FundersNew";

/**
 * FundersRouter Container
 * @class FundersRouter
 * @extends {Component}
 */
class FundersRouter extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, projectId, isSubPage, location } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <FundersList projectId={projectId} isSubPage={isSubPage} {...props} page={'funders'} />
                }} />
                <Route exact path={`${match.url}/new`} render={(props) => {
                    return <FundersNew {...props} projectId={projectId} isSubPage={isSubPage} />
                }} />
                <Route exact path={`${match.url}/:funderId`} render={(props) => {
                    return <FundersView {...props} projectId={projectId} orgId={location.state.pageData.orgId} isSubPage={isSubPage} />
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default FundersRouter;
