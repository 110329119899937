import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const Table1LogService = {
    getAll,
    // getById,
    // create,
    // update,
    delete: _delete,
    sendTemplate,
    getErrorFile
    // getProjectReport,
    // getProjectsByName,
    // getFunderSettingsConfigDatas,
    // getBasicFunderSettings,
    // getReportOne,
    // updateFunderSettings,
    // createFunderSettings,
    // getMonitoringProjects,
    // isProjectNameExists,
    // getTable2,
    // getTable3,
    // updateReportCoverPage,
    // getFunderReportTable,
    // findFunderHistory,
    // getFunderOrganizations,
    // addFunderOrganizations,
    // getProjectNameList
};

/**
 *Get project listing page details
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [exactMatch=false]
 * @param {*} [userId=null] - default value null
 * @returns
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, exactMatch = false, userId = null, query = { isDelete: false }) {
    console.log("services")

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch,
        userId: userId, // to get user's associated projects
        query: query // query to fetct project
    };

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/table1Log/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
}


function sendTemplate(template) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(template)
    };

    return fetch(`${globalVariable.ms_apiURL}/sendXmlMail`, requestOptions).then(handleResponse);
}

//Api to get the response from the server
function getErrorFile(fileName) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader()},
        responseType: 'arraybuffer'
    };

    return fetch(`${globalVariable.ms_apiURL}/table1-htm-error-file/${fileName.fileName.split(".")[0]}`, requestOptions)
}




/**
 *Get single project view
 *
 * @param {*} id - project_id
 * @returns
 */
// function getById(id, page) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${globalVariable.apiURL}/projects/${id}/${page}`, requestOptions).then(handleResponse);
// }

// /**
//  * A method call API to create the project details
//  *
//  * @param {*} formData contains the project details data to create
//  * @returns
//  */
// function create(formData) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData)
//     };

//     return fetch(`${globalVariable.apiURL}/projects/`, requestOptions).then(handleResponse);
// }


// /**
//  * A method call API to update the project details
//  *
//  * @param {*} id contains the id of project to update
//  * @param {*} formData contains the project details data to update
//  * @returns
//  */
// function update(id, formData) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(formData)
//     };

//     return fetch(`${globalVariable.apiURL}/projects/${id}`, requestOptions).then(handleResponse);
// }

/**
 *prefixed function name with underscore because delete is a reserved word in javascript
 *
 * @param {*} id - project_id
 * @returns
 */
function _delete(id, page) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/table1Log/${id}/${page}`, requestOptions).then(handleResponse);
}

// /**
//  * Get project report data listed
//  * 
//  * @param {*} skip 
//  * @param {*} limit 
//  * @param {*} orderBy 
//  * @param {*} order 
//  * @param {*} sfields 
//  * @param {*} sValues 
//  * @param {*} projectId 
//  * @param {*} exactMatch 
//  */
// function getProjectReport(skip, limit, orderBy, order, sfields, sValues, projectId = null, exactMatch = false, query = {}) {

//     let data = {
//         fields: sfields,
//         values: sValues,
//         exactMatch,
//         projectId: projectId,
//         query: query
//     };

//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader() },
//         body: JSON.stringify(data)
//     };

//     return fetch(`${globalVariable.apiURL}/projects/reports?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}`, requestOptions).then(handleResponse);
// }

// function getProjectsByName(text, type) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader() }
//     };

//     return fetch(`${globalVariable.apiURL}/projects/search/${text}/${type}`, requestOptions).then(handleResponse);
// }

// /**
//  * A service method to get funder settings basic config datas
//  *
//  */
// function getFunderSettingsConfigDatas(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader() }
//     };

//     return fetch(`${globalVariable.apiURL}/config/funder-settings`, requestOptions).then(handleResponse);
// }

// /**
//  * A Service method to get the funder basic settings for the project
//  *
//  * @param {*} funderId contains the funder id to get the settings
//  */
// function getBasicFunderSettings(funderId, orgId) {
//     if (funderId) {
//         const requestOptions = {
//             method: 'GET',
//             headers: authHeader()
//         };

//         return fetch(`${globalVariable.apiURL}/funder-settings/${orgId}/${funderId}?timeZone=${timeZone}`, requestOptions).then(handleResponse);
//     }
// }

// /**
//  * Get funder report table data
//  * 
//  * @param {*} funderId 
//  */
// function getFunderReportTable(orgId) {
//     if (orgId) {
//         const requestOptions = {
//             method: 'GET',
//             headers: authHeader()
//         };

//         return fetch(`${globalVariable.apiURL}/funderreporttable/${orgId}`, requestOptions).then(handleResponse);
//     }
// }

// /**
//  * Get report settings
//  * 
//  * @param {*} id 
//  */
// function getReportOne(id) {
//     if (id) {
//         const requestOptions = {
//             method: 'GET',
//             headers: authHeader()
//         };

//         return fetch(`${globalVariable.apiURL}/projects/reports/${id}`, requestOptions).then(handleResponse);
//     }
// }
// /**
//  * A Service method to get the funder basic settings for the project
//  *
//  * @param {*} formData contains the data to get the settings
//  * @param {*} funderId contains the fudner id to update the settings
//  */
// function updateFunderSettings(formData, funderId) {
//     if (funderId) {
//         const requestOptions = {
//             method: 'PUT',
//             headers: { ...authHeader(), "Content-Type": "application/json" },
//             body: JSON.stringify(formData)
//         };

//         return fetch(`${globalVariable.apiURL}/funder-settings/${funderId}`, requestOptions).then(handleResponse);
//     }
// }

// /**
//  * A Service method to create the funder basic settings for the project
//  *
//  * @param {*} formData the data to create the settings
//  */
// function createFunderSettings(formData) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader(), "Content-Type": "application/json" },
//         body: JSON.stringify(formData)
//     };

//     return fetch(`${globalVariable.apiURL}/funder-settings`, requestOptions).then(handleResponse);
// }

// /**
//  * A Service method to get monitoring Projects
//  *
//  */
// function getMonitoringProjects() {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader() }
//     };

//     return fetch(`${globalVariable.apiURL}/projects/monitoring`, requestOptions).then(handleResponse);
// }

// /**
//  * A service call to check whether the project name is exists or not
//  * 
//  * @param {*} data 
//  * @param {*} callBack 
//  */
// function isProjectNameExists(data) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader(), "Content-Type": "application/json" },
//         body: JSON.stringify(data)
//     };

//     return fetch(`${globalVariable.apiURL}/projects/name-exists`, requestOptions).then(handleResponse);
// }

// /*** A service call Table1 */
// function getTable2(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader(), "Content-Type": "application/json" }
//     };
//     return fetch(`${globalVariable.apiURL}/projects/reports/table2/${id}`, requestOptions).then(handleResponse);
// }


// /*** A service call Table1 */
// function getTable3(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader(), "Content-Type": "application/json" }
//     };
//     return fetch(`${globalVariable.apiURL}/projects/reports/table3/${id}`, requestOptions).then(handleResponse);
// }

// /**
//  * A method to get the update report cover page
//  *
//  * @param {*} id contains report year id
//  * @param {*} data contains form datas to update
//  * @returns
//  */
// function updateReportCoverPage(id, data) {
//     if (id) {
//         const requestOptions = {
//             method: 'POST',
//             headers: { ...authHeader(), "Content-Type": "application/json" },
//             body: JSON.stringify(data)
//         };

//         return fetch(`${globalVariable.apiURL}/projects/reports/${id}`, requestOptions).then(handleResponse);
//     }
// }

// /**
//  *A method to find funder fistory details
//  *
//  * @param {*} projectId
//  */
// function findFunderHistory(projectId, org_id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader(), "Content-Type": "application/json" }
//     };

//     return fetch(`${globalVariable.apiURL}/funderhistory/${projectId}/${org_id}`, requestOptions).then(handleResponse);
// }

// /**
//  *A method to get funder orgs
//  *
//  * @param {*} 
//  */
// function getFunderOrganizations() {
//     const requestOptions = {
//         method: 'GET',
//         headers: { ...authHeader(), "Content-Type": "application/json" }
//     };

//     return fetch(`${globalVariable.apiURL}/organizations/funders`, requestOptions).then(handleResponse);
// }

// /**
//  *A method to add funder orgs
//  *
//  * @param {*} 
//  */
// function addFunderOrganizations(data) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader(), "Content-Type": "application/json" },
//         body: JSON.stringify(data)
//     };

//     return fetch(`${globalVariable.apiURL}/funders/add`, requestOptions).then(handleResponse);
// }

// /**
//  * A method to get projects name list
//  */
// function getProjectNameList(data = null) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { ...authHeader(), "Content-Type": "application/json" },
//         body: JSON.stringify(data)
//     };

//     return fetch(`${globalVariable.apiURL}/projects/name-list`, requestOptions).then(handleResponse);
// }