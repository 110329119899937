//import constants
import { printConstants } from '../constants/print';

//import services
import { printService } from '../services/print';

export const printActions = {
    printTable1Data,
    printTable5Data
};

/**
 *Print table 1 data
 *
 * @param {*} skip
 * @param {*} limit
 * @param {*} orderBy
 * @param {*} order
 * @param {*} sFields
 * @param {*} sValues
 * @param {boolean} [isAutoComplete=false]
 * @param {*} projectData - projectid, project_reportid
 * @returns
 */
function printTable1Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete = false, projectData) {
    return dispatch => {
        dispatch(request());

        printService.printTable1Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete, projectData)
            .then(
                printData => {
                    dispatch(success(printData));
                    // some interval to render dom
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: printConstants.PRINT_TABLE1_DATA_REQUEST } }
    function success(printData) { return { type: printConstants.PRINT_TABLE1_DATA_SUCCESS, printData, printComponent: 'table1' } }
    function failure(error) { return { type: printConstants.PRINT_TABLE1_DATA_FAILURE, error } }
}

function printTable5Data (skip, limit, orderBy, order, sFields, sValues, isAutoComplete = false, projectData) {
    return dispatch => {
        dispatch(request());

        printService.printTable5Data(skip, limit, orderBy, order, sFields, sValues, isAutoComplete, projectData)
            .then(
                printData => {
                    dispatch(success(printData));
                    // some interval to render dom
                    setTimeout(() => {
                        window.print();
                    }, 1000);
                },
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: printConstants.PRINT_TABLE5_DATA_REQUEST } }
    function success(printData) { return { type: printConstants.PRINT_TABLE5_DATA_SUCCESS, printData, printComponent: 'table5' } }
    function failure(error) { return { type: printConstants.PRINT_TABLE5_DATA_FAILURE, error } }
}