import { complianceConstants } from "../constants/compliance";

const initialState = {
    isLoading: false,
    data: [],
    fields: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['name'],
            searchCols: []
        }
    },
}

export function compliance(state = initialState, action) {
    switch (action.type) {
        case complianceConstants.GETALL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case complianceConstants.GETALL_SUCCESS:
            return {
                ...state,
                ...action.compliance,
                isLoading: false
            };
        case complianceConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case complianceConstants.GETFIELD_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case complianceConstants.GETFIELD_SUCCESS:

            return {
                ...state,
                data: action.compliance.fields,
                isLoading: false
            };
        case complianceConstants.GETFIELD_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case complianceConstants.GETFIELDDATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case complianceConstants.GETFIELDDATA_SUCCESS:

            return {
                ...state,
                fields: action.compliance.data,
                isLoading: false
            };
        case complianceConstants.GETFIELDDATA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        case complianceConstants.UPDATEDATA_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case complianceConstants.UPDATEDATA_SUCCESS:

            return {
                ...state,
                fields: action.compliance.data,
                isLoading: false
            };
        case complianceConstants.UPDATEDATA_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        default:
            return (state);
    }
}