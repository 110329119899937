import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { Switch, Route } from 'react-router-dom';

//Material UI
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';

// Import Components
import SitesEdit from './SitesEdit';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import BuildingRouter from "../Buildings/Building.Router";
import AlertDialog from '../../components/AlertDialog/AlertDialog';

// Import Helpers
import { getUserRole, constants, history } from '../../helpers';
import { getTitle } from '../../helpers/setTitle';

// Import Actions
import { sitesActions } from '../../actions/sites';
import { confActions } from "../../actions/configuration";
import { projectsActions } from '../../actions/projects';

import { configurationConstants, SitesConstants } from "../../helpers/appConstants";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';
/**
 * SitesView Component
 * @class SitesView
 * @extends {Component}
 */
class SitesView extends Component {
    /**
     * Init Constructor 
     * @param {*} props 
     */
    constructor(props) {
        super(props)
        this.userRef = React.createRef();
        this.state = {
            claimType: null
        }
        this.userRole = getUserRole();
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { projectId } = this.props;
        if (projectId) {
            this.props.getById(projectId);
        }
    }
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.siteRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData,checkFieldsChanged) => {
        if (formData) {
            this.props.updateSite(formData, formData._id,checkFieldsChanged);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Claim Action Click
     */
    handleClaimActionClick = () => {
        this.setState({
            claimType: 'claim'
        })
    }
    /**
     * Handle UnClaim Action Click
     */
    handleUnClaimActionClick = () => {
        this.setState({
            claimType: 'unclaim'
        })
    }
    /**
    * Go to buildings list
    */
    handleGotoBuildingListActionClick = () => {
        const { match, getOne } = this.props;
        if (getOne.data._id) {
            history.push({
                pathname: `${match.url}/buildings`,
                state: {
                    // since building list uses the same users access as sites level
                    page: 'buildingsList',
                    pageData: {
                        siteId: getOne.data._id,
                        siteName: getOne.data.name,
                        isArchived: match.path.includes('archives')
                    }
                }
            });
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        let { match: { params }, match, classes, location: { pathname }, removeActions, pageAccess, isSubPage, pageTitle, getOne } = this.props;
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        pageAccess = _.difference(pageAccess, removeActions);
        let validationMsg = "";
        if ([constants.superFunder,constants.superFunderWithHO, constants.funderWithOA, constants.funderWithoutOA, constants.funder].indexOf(this.userRole) >= 0 && getOne.data && getOne.data.funderProject && !getOne.data.funderProject.expirationDate) {
            validationMsg = "Please fill the expiration date and Claim the Site to edit the site";
        } else if ([constants.superFunder, constants.funderWithOA, constants.funderWithoutOA, constants.funder].indexOf(this.userRole) >= 0 && getOne.data && !getOne.data.funders && getOne.data.funderProject && getOne.data.funderProject.expirationDate) {
            validationMsg = "Please Claim the Site to edit the site";
        }
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={match.url === pathname ? pageAccess : ['BACK']}
                isSubPage={isSubPage}
                title={isSubPage ? '' : pageTitle.title}
                subTitle={isSubPage ? '' : pageTitle.subTitle}
                handleBackActionClick={this.handleBackActionClick}
                handleSaveActionClick={this.handleSaveActionClick}
                handleClaimActionClick={this.handleClaimActionClick}
                handleUnClaimActionClick={this.handleUnClaimActionClick}
                handleGotoBuildingListActionClick={this.handleGotoBuildingListActionClick}
            >
                {validationMsg && !pathname.includes('archives') &&
                    <div className="textCenter">
                        <Chip
                            label={validationMsg}
                            className={`${classes.chip} cliamedChip`}
                            color="secondary"
                        />
                    </div>
                }
                <Switch>
                    <Route
                        exact
                        path={match.url}
                        render={(props) => {
                            //handled for nav from archive
                            let siteID = !params.siteId?history.location.pathname.split("/")[4]:params.siteId
                            return (
                                <SitesEdit
                                    isSubPage={isSubPage}
                                    pageAccess={pageAccess}
                                    siteId={parseInt(siteID, 0)}
                                    {...props}
                                    projectId={this.props.projectId}
                                    innerRef={(node) => { this.siteRef = node; }}
                                    isReadOly={isReadOly}
                                    // pageAccess={pageAccess}
                                    handleFormSaveActionClick={(formData,checkFieldsChanged) => this.handleFormSaveActionClick(formData,checkFieldsChanged)}
                                    isArchived={pathname.includes('archives')}
                                />
                            )
                        }}
                    />
                    <Route
                        path={`${match.url}/buildings`}
                        render={(props) => {
                            return (
                                <BuildingRouter
                                    isSubPage={true}
                                    siteId={parseInt(params.siteId, 0)}
                                    {...props}
                                    projectId={this.props.projectId}
                                    isReadOly={isReadOly}
                                />
                            )
                        }}
                    />
                </Switch>

                <AlertDialog
                    open={this.state.claimType ? true : false}
                    saveText={this.state.claimType === 'claim' ? 'Claim' : 'Unclaim'}
                    onSave={() => {
                        this.setState({ claimType: null });
                        const urlParams = params || {};
                        const site_id = urlParams.siteId ? parseInt(urlParams.siteId, 10) : null;
                        if (site_id) {
                            this.props.updateClaimStatus(site_id, () => {
                                this.props.getSitesById(site_id, 'projects');
                                this.props.getAllOrg('', 'sites');
                                this.props.getAllOrgOnsite('', 'sites');
                                this.props.getProjectsByName(null,null);

                                //Get Config Values
                                this.props.getConfiguration(configurationConstants.counties);
                                this.props.getConfiguration(configurationConstants.siteTypeForSorting);
                            });
                        } else {
                            alert("Site ID not found.")
                        }
                    }}
                    onClose={() => {
                        this.setState({ claimType: null });
                    }}
                    title={this.state.claimType === 'claim' ? 'Site Claim Confirmation' : 'Site Unclaim Confirmation'}
                >
                    <React.Fragment>
                        {this.state.claimType === 'claim' ?
                            'Are you sure you want to Cliam this Site. If you do not want to do this, click Cancel' :
                            "WARNING! Unclaiming this Site will imply that this Site was never monitored by this Funder, and will also unclaim all Buildings under it. This will affect validation on all reports that use this Site's set aside requirements, as well as what information this Funder will download for reporting. If you do not want to do this, click Cancel"
                        }
                    </React.Fragment>
                </AlertDialog>
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
SitesView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    removeActions: PropTypes.array,
    isSubPage: PropTypes.bool
};
/** Default Props */
SitesView.defaultProps = {
    removeActions: [],
    isSubPage: false,
    title: ''
};
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const { sites, pageAccess } = state;
    const pageTitle = getTitle(history.location.pathname, state);
    return {
        getOne: sites.getOne,
        pageAccess: pageAccess['siteEdit'] || [],
        pageTitle
    }
};
/**
 * Maps state from store to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        updateSite: (formData, id,checkFieldsChanged) => dispatch(sitesActions.updateSite(formData, id,checkFieldsChanged)),
        updateClaimStatus: (site_id, callBack) => dispatch(sitesActions.updateClaimStatus(site_id, callBack)),
        getById: (id) => dispatch(projectsActions.getById(id)),
        getSitesById: (id, type) => dispatch(sitesActions.getById(id, type)),
        getById: (id, type) => dispatch(sitesActions.getById(id, type)),
        getAllOrg: (text, page) => dispatch(sitesActions.getOrganizations(text, page)),
        getAllUsersInOrg: (orgId) => dispatch(sitesActions.getUsersInOrg(orgId)),
        getAllUsersInOrgwithrole: (orgId) => dispatch(sitesActions.getUsersInOrgwithRole(orgId)),
        getProjectsByName: (text, type) => dispatch(sitesActions.getProjectsByName(text, type)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getAllOrgOnsite: (text, page) => dispatch(sitesActions.getOrganizationsOnsite(text, page)),
        getSiteByTable1List: (siteId) => dispatch(sitesActions.getSiteByTable1List(siteId))
    }
};
/**
 * Export Component
 */
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle, { withTheme: true })(SitesView));





