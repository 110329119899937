import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

//Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Actions
import { confActions } from '../../../actions/configuration';

// Helpers
import ValidatorForm from '../../../helpers/fieldValidations';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Constants
import { configurationConstants } from '../../../helpers';

// Import Components
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import TextBox from '../../../components/TextBox/TextBox';
import DragableEditTable from '../../../components/EditableTable/DragableEditTable';
import { alertActions } from '../../../actions';

// neccessary db columns for help category
const helpCategoryData = {
    name: ''
};
/**
 * Sort Component
 * @class Sort
 * @extends {Component}
 * @memberof HelpCategory
 */
class Sort extends Component {
    render() {
        return (
            <div style={{ cursor: 'grab', display: 'flex', justifyContent: 'center' }}>
                <i className="fa fa-th drag-icon" aria-hidden="true"></i>
            </div>
        )
    }
}
/**
 * EditableText Component
 * @class EditableText
 * @extends {Component}
 * @memberof HelpCategory
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    sort: Sort,
    name: EditableText
}
/**
 * HelpCategory Component
 * @class HelpCategory
 * @extends {COmponent}
 */
class HelpCategory extends Component {
    /**
     * Construnctor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            helps: [],
            saved: false
        }
    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.HelpCategory);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { HelpCategory } = nextProps;

        if (!nextProps.isLoading) {

            HelpCategory.forEach((elem, index) => {
                if (elem.sort === undefined) {
                    HelpCategory[index].sort = index;
                }
            })

            this.setState({
                saved: true,
                helps: HelpCategory
            })
        }
    }
    /**
     * Handle Submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate help names
        let help = this.state.helps;
        let duplicateName=''
        let helpNames = help.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicateHelp = helpNames.some(function(item, idx){ 
                    if(helpNames.indexOf(item) !== idx){
                        duplicateName=help[helpNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicateHelp){
            this.props.erroralert(`${duplicateName.toUpperCase()} help already exists`);
        }else{
        this.props.updatedata(configurationConstants.HelpCategory, this.state.helps);
        }
    }
    /**
     * Cell Component on change
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { helps } = this.state;
        helps[rowIndex][fieldName] = value;
        this.setState({ helps });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { helps } = this.state;
        // [1]
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };
        return (
            <ValidatorForm
                name="helps"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <DragableEditTable
                        pageTitle={'Help Category'}
                        headerCols={['', 'NAME']}
                        requiredFields={['NAME']}
                        fieldCols={['sort', 'name']}
                        rowDatas={helps}
                        isLoading={isLoading}
                        componentMap={componentMap}
                        actions={['DELETE']}
                        style={style}
                        staticHeader={true}
                        height={height}
                        onAdd={() => {
                            if (helps.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    helps: [
                                        {
                                            ...helpCategoryData,
                                            key: Math.max(...helps.map(elt => elt.key)) + 1,
                                            sort: Math.max(...helps.map(elt => elt.key)) + 1,
                                            type: configurationConstants.HelpCategory
                                        },
                                        ...helps
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            if (item._id) {
                                this.props.deleteConfig(item.type, item._id);
                            } else {
                                helps.splice(item.rowIndex, 1)
                                this.setState({ helps: helps })
                            }
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                        onDragEnd={(items) => {
                            items.map((elem, index) => elem.sort = index + 1);
                            this.setState({ helps: items })
                        }}
                    ></DragableEditTable>
                </Grid>
                <SaveCancel
                    disableSave={isLoading === true}
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => { this.props.getByName(configurationConstants.HelpCategory) }}
                />
            </ValidatorForm>
        );
    }
}
/**
 * Props types
 */
HelpCategory.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        HelpCategory: configuration.configurations[configurationConstants.HelpCategory] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByName: (HelpCategory) => dispatch(confActions.getByName(HelpCategory)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert:(msg)=>dispatch(alertActions.error(msg))
    }
};
/**
 * Export HelpCategory
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(HelpCategory));
