import { incomeRentConstants } from '../constants/incomeRent';

const initialState = {}

export function incomeRent(state = initialState, action) {
    switch (action.type) {
        case incomeRentConstants:
            return {
                ...state,
            };
        default:
            return state
    }
}