/*!

 =========================================================
 * Material Dashboard React - v1.3.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 257;
const themeSpacingSize = 8; // In Pixel

const defaultFont = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em"
};

const paper = {
    paddingTop: themeSpacingSize,
    paddingBottom: themeSpacingSize,
};

const toolbar = {
    minHeight: 50
};

export {
    //variables
    drawerWidth,
    themeSpacingSize,
    defaultFont,
    paper,
    toolbar
};
