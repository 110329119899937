import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { confActions } from '../../../actions/configuration';
import ValidatorForm from '../../../helpers/fieldValidations';
import Grid from '@material-ui/core/Grid';


// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Constants
import { configurationConstants } from '../../../helpers';

// Import Components

import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import EditableTable from '../../../components/EditableTable/EditableTable';
import TextBox from '../../../components/TextBox/TextBox';
import { alertActions } from '../../../actions';

const UASData = {
    name: ''
};
/**
 * EditableText container
 * @class EditableText
 * @extends {Component}
 * @memberof UtilityAllowanceSources
 */
class EditableText extends Component {
    render() {
        const { data, rowIndex, onChange, fieldName } = this.props;
        return (
            <TextBox
                className={'textBox--bordered'}
                name={`${fieldName}_${rowIndex}`}
                value={data[fieldName]}
                validators={['required']}
                errorMessages={['this field is required']}
                handleChange={(name, value) => {
                    onChange(fieldName, rowIndex, value);
                }}
            />
        )
    }
};

const componentMap = {
    name: EditableText
}
/**
 * UtilityAllowanceSources container
 * @class UtilityAllowanceSources
 * @extends {Component}
 */
class UtilityAllowanceSources extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            utilitysources: [],
            saved: false
        }

    }
    /**
     * Component will mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.utilityAllowanceSource);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { utilityAllowanceSource } = nextProps;

        this.setState({
            saved: true,
            utilitysources: utilityAllowanceSource
        })

    }
    /**
     * Handle submit
     */
    handleSubmit = () => {
        this.saveForm();
    };
    /**
     * Save form
     */
    saveForm() {
        //Check for duplicate utilitysources names
        let utilitysources = this.state.utilitysources;
        let duplicateName=''
        let utilitysourcesNames = utilitysources.map(function(item){ return item.name.trim().toLowerCase() });
        let duplicateutilitysourcesNames = utilitysourcesNames.some(function(item, idx){ 
                    if(utilitysourcesNames.indexOf(item) !== idx){
                        duplicateName=utilitysources[utilitysourcesNames.indexOf(item)].name
                        return item
                    }
            });
        if(duplicateutilitysourcesNames){
            this.props.erroralert(`${duplicateName.toUpperCase()} utility allowance source already exists`);
        }else{
        this.props.updatedata(configurationConstants.utilityAllowanceSource, this.state.utilitysources);
        }
    }
    /**
     * cellComponentOnChange
     * @param {*} fieldName 
     * @param {*} rowIndex 
     * @param {*} value 
     */
    cellComponentOnChange = (fieldName, rowIndex, value) => {
        const { utilitysources } = this.state;
        utilitysources[rowIndex][fieldName] = value;
        this.setState({ utilitysources });
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     * 
     * [1]  -   Set Table Content Height
     */
    render() {
        const { utilitysources } = this.state;
        // [1]
        const { height, isLoading } = this.props;
        const style = { "height": height, "overflow": "auto" };

        return (
            <ValidatorForm
                name="utilitysources"
                ref="form"
                autoComplete="off"
                onSubmit={this.handleSubmit}
                onError={errors => { }}
            >
                <Grid item xs={12}>
                    <EditableTable
                        pageTitle={'Utility Allowance Source'}
                        headerCols={['NAME']}
                        requiredFields={['NAME']}
                        fieldCols={['name']}
                        rowDatas={utilitysources}
                        componentMap={componentMap}
                        style={style}
                        staticHeader={true}
                        height= {height}
                        isLoading={isLoading}
                        actions={['DELETE']}
                        onAdd={() => {
                            if (utilitysources.filter(i => !i._id).length <= 0) {
                                this.setState({
                                    utilitysources: [
                                        {
                                            ...UASData,
                                            key: Math.max(...utilitysources.map(elt => elt.key)) + 1,
                                            type: configurationConstants.utilityAllowanceSource
                                        },
                                        ...utilitysources
                                    ]
                                });
                            }
                        }}
                        onDelete={(item) => {
                            this.props.deleteConfig(item.type, item._id);
                        }}
                        cellComponentOnChange={this.cellComponentOnChange}
                    />
                </Grid>
                <SaveCancel
                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                    handleCancelButtonClick={() => {  this.props.getByName(configurationConstants.utilityAllowanceSource)}}
                />
            </ValidatorForm>
        );
    }
}
/** Props types */
UtilityAllowanceSources.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        utilityAllowanceSource: configuration.configurations[configurationConstants.utilityAllowanceSource] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getByName: (utilityAllowanceSource) => dispatch(confActions.getByName(utilityAllowanceSource)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id)),
        erroralert: (msg) => dispatch(alertActions.error(msg))
    }
};
/** Export UtilityAllowanceSources */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(UtilityAllowanceSources));
