import React, { useState, forwardRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar, SnackbarContent } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility'
import { getUser, history } from '../../helpers';
import PropTypes from "prop-types";
import { exploreActions, notificationActions, bulkDataActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux'


const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            minWidth: '344px !important',
            maxWidth: '344px !important',
        },
    },
    card: {
        backgroundColor: '#43a047',
        width: '100%',
    },
    typography: {
        fontWeight: 'regular',
        color: 'white',
        overflowWrap:'anywhere'
        },
    typography1: {
        fontWeight: 'bold',
        color: 'white'
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
        height:'20px'
    },
    icons: {
        marginLeft: 'auto',
        color: 'white',
        paddingTop:'15px'
    },
    expand: {
        padding: '8px 8px',
        transform: 'rotate(0deg)',
        color: 'white',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: {
        paddingRight: 18,
        paddingLeft: 15,
        backgroundColor: '#43a047'
    },
    checkIcon: {
        fontSize: 20,
        color: 'white',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
        color: 'white'
    },
    a: {
        color: '#000000DE',
        textDecoration: 'none'
    },
    a1: {
        paddingLeft: 10,
        color: 'white'
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    typo: {
        paddingRight: '15px',
        paddingLeft: '15px',
        paddingBottom:'15px',
        paddingTop:'11px'
    },
    svg: {
        marginRight: '4px'
    }
}));

const SnackMessage = forwardRef((props, ref) => {
    const { message } = props;
    const parsedMessage = JSON.parse(message);
    const classes = useStyles();
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const user = getUser();

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
        dispatch(notificationActions.updateAlertNotification({ _id: parsedMessage._id }, () => {
            closeSnackbar(props.id);
            dispatch(notificationActions.getNotificaionAlertByUserId((alertData) => {
                alertData.forEach(elem => {
                    enqueueSnackbar(`{\"title\": \"${elem.title}\", \"description\": \"${elem.description}\" ,\"_id\": \"${elem._id}\",\"module\": \"${elem.module}\",\"exploreId\": \"${elem.exploreId}\"}`)
                })
            }))
        }))
    }, [props.id, closeSnackbar]);

    const handleDownloadClick = (msg) => {
        dispatch(notificationActions.updateAlertNotification({ _id: msg._id }, () => {
            closeSnackbar(props.id);
            let fileToDownload = msg.description.replace(' is ready to download','')
            if (msg.module && msg.module == 'bulkData'){
                if (msg.description) {
                    fileToDownload = msg.description.split('export')[1].split(' ')[1] + ' ' + "data export";
                }
                dispatch(bulkDataActions.downloadBulkData(msg.exploreId,fileToDownload))
            } else {
                dispatch(exploreActions.downloadExploreReport(msg.exploreId, null, fileToDownload))
            }
            dispatch(notificationActions.getNotificaionAlertByUserId((alertData) => {
                alertData.forEach(elem => {
                    enqueueSnackbar(`{\"title\": \"${elem.title}\", \"description\": \"${elem.description}\" ,\"_id\": \"${elem._id}\",\"module\": \"${elem.module}\",\"exploreId\": \"${elem.exploreId}\"}`)
                })
            }))
        }))
        
    }

    const handleView = (moduleName) => {
        if (moduleName === 'dashBoardReport') {
            history.push({
                pathname: `/dashboard`,
                state: {
                    page: 'dashboard',
                    pageData: {
                        naviagteToDashboardReports: true
                    },
                }
            })
        } else if (moduleName === 'bulkData') {
            history.push("/configurations/bulk-export")
        } else {
            history.push("/explore")
        }
        dispatch(notificationActions.updateAlertNotification({ module: moduleName }, () => {
            closeSnackbar(props.id);
            dispatch(notificationActions.getNotificaionAlertByUserId((alertData) => {
                alertData.forEach(elem => {
                    enqueueSnackbar(`{\"title\": \"${elem.title}\", \"description\": \"${elem.description}\" ,\"_id\": \"${elem._id}\",\"module\": \"${elem.module}\",\"exploreId\": \"${elem.exploreId}\"}`)
                })
            }))
        }))
    }

    let yearFinal = '';
    let fileName='';
    let htmlMessage = '';
    //message split
    if (parsedMessage.module != 'bulkData') {
        let description = parsedMessage.description.split(' for report');
        fileName = description[0];
        let yearMessage = description.length>=3?description[2]:description[1];
        let year = yearMessage.split('is');
        let yearFinal = year[0];
        htmlMessage = `Your <b>${fileName}</b> for the<b> report ${yearFinal}</b>is ready to download`
    } else {
        htmlMessage = parsedMessage.description;
    }
    
    return (
        <SnackbarContent ref={ref} className={classes.root}>
            <Card className={classes.card}>
                <CardActions classes={{ root: classes.actionRoot }}>
                    {/* <Typography variant="subtitle2" className={classes.typography1}>{parsedMessage.module === 'dashBoardReport' ? 'Dashboard' : 'Explore Data'} Report Download
                    </Typography> */}
                    <div className={classes.icons}>
                        {/* <IconButton
                            aria-label="Show more"
                            className={classnames(classes.expand, { [classes.expandOpen]: expanded })}
                            onClick={handleExpandClick}
                        >
                            <ExpandMoreIcon />
                            {/* { { <Typography>{'View File'}</Typography>}} */}
                        {/* </IconButton> */}
                        <IconButton className={classes.expand} onClick={handleDismiss}>
                            <CloseIcon />
                        </IconButton>
                    </div>

                </CardActions>
                <div className={classes.typo}>
                    <Typography variant="subtitle2" className={classes.typography}>{<div dangerouslySetInnerHTML={{ __html: htmlMessage}}></div>}</Typography>
                </div>
                {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
                <Paper className={classes.collapse}>
                    {/* <Typography gutterBottom>{parsedMessage.description}</Typography> */}
                    <div className={classes.buttons}>
                        <Button size="medium" className={classes.button} onClick={() => handleDownloadClick(parsedMessage)}>
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" height="14px" width="14px" viewBox="0 0 24 30" className={classes.svg}><path id="Path_47" fill="white" d="M24,10.7h-6.9V0.4H6.9v10.3H0l12,12L24,10.7z M0,26.1v3.4h24v-3.4H0z"></path><path id="Path_47_1_" fill="white" d="M-5.9,10.7h-6.9V0.4H-23v10.3h-6.9l12,12L-5.9,10.7z M-29.9,26.1v3.4h24v-3.4H-29.9z"></path></svg>
                            Download
                        </Button>
                        <Button size="medium" className={classes.button} onClick={() => handleView(parsedMessage.module)}>
                            <Visibility className={classes.checkIcon} />
                            <a>View file</a>
                        </Button>
                    </div>
                </Paper>
                {/* </Collapse> */}
            </Card>
        </SnackbarContent>
    );
});

SnackMessage.defaultProps = {
    handleDownload: () => { },
    message: {},
    navigationOptions: {}

}

SnackMessage.propTypes = {
    handleDownload: PropTypes.func,
    message: PropTypes.object,
    navigationOptions: PropTypes.object

}


export default SnackMessage;
