import React from "react";
import { Grid,Button,Dialog, DialogTitle, DialogActions, IconButton, Typography, Modal } from "@material-ui/core";
import ToolTip from '../ToolTip/ToolTip';
import { CloseIcon } from '../SvgIcons/SvgIcons';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    tableCell: {
      padding: '10px',
      border: '1px solid grey', 
      textAlign: 'left',
      borderCollapse: 'collapse'
    },
});

class CustomGrid extends React.Component {
    render() {
      const { classes } = this.props;
      return (
        <table style={{paddingLeft: '2%'}}>
            <tr>
                <th className={classes.tableCell}>
                    <Typography>Building</Typography>
                </th>
                <th className={classes.tableCell}>
                    <Typography>Unit</Typography>
                </th>
                <th className={classes.tableCell}>
                    <Typography>Move-In Date</Typography>
                </th>
                <th className={classes.tableCell}>
                    <Typography>Household Name</Typography>
                </th>
            </tr>
        </table>
      );
    }
  }
  
const StyledCustomGrid = withStyles(styles)(CustomGrid);
  

class XMLSkipAlert extends React.Component {

    handleClose = () => {
        this.props.handleClose()
    };

   render() {
    
    const { open,content, saveVisible,saveText, cancelText, buttonDisable, cancelVisible, className, confirmationButtonLabel, closeButtonLabel, onSave, onCancel } = this.props;
    return (
        <Dialog
            PaperProps={{
                style: {
                width: '35%', // Custom width
                maxWidth: 'none', // Override maxWidth
                },
            }}
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            >

            {/* <StyledCustomGrid /> */}

            <Grid container flexDirection='column' justifyContent="center" spacing={2} style={{ padding: '22px 40px' }}> 

                <Grid item style={{fontSize: '20px', fontWeight: 900}}>
                    Confirmation
                </Grid>

                <Grid item style={{ textAlign: 'justify' }}>
                    {content}
                </Grid>

                {(saveVisible || cancelVisible) && 
                <Grid item>
                    <DialogActions>
                        {
                            saveVisible && (
                                <Button variant="contained" disabled={buttonDisable} onClick={onSave} color="primary" style={{ width: '88px' }}>
                                    {saveText}
                                </Button>
                            )
                        }
                        {
                            cancelVisible && (
                                <Button variant="contained" disabled={buttonDisable} onClick={onCancel} color="default" autoFocus>
                                    {cancelText}
                                </Button>
                            )
                        }
                    </DialogActions>
                </Grid>
                }
                 
            </Grid>
        </Dialog >
    )
   }
}

XMLSkipAlert.defaultProps = {
    open: true,
    handleClose: () => { },
    maxWidth: 'sm',
    onSave: () => {},
    onCancel: () => {}
}

export default XMLSkipAlert;