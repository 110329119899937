const pageActionsStyle = theme => ({
    root: {
        // paddingTop: theme.spacing.unit * 1,
       // paddingBottom: theme.spacing.unit * 1,
        textAlign:'right',
        // marginTop: 6,
        // marginBottom: 10,
        height:"100%",
        display: 'flex',
        justifyContent : 'flex-end',
        alignItems: 'center'
    },
    subPage: {
        textAlign:'right',
        // marginTop: 4,
        height:"100%"
    },
    button: {
        //marginBottom: theme.spacing.unit * 1,
        marginLeft: theme.spacing(1),
    },
    textAlignLeft: {
        // paddingTop: theme.spacing.unit * 1,
       // paddingBottom: theme.spacing.unit * 1,
        textAlign:'left'
    },
});

export default pageActionsStyle;
