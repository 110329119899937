import React, { Component } from "react";
import propTypes from 'prop-types';
import { connect } from "react-redux";

// Material UI
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Button, IconButton } from "@material-ui/core";

//Helper
import { getOrgType, getUser, history } from '../../helpers';


//Actions
import { notificationActions } from "../../actions/notification";
import { helpActions , alertActions  } from '../../actions';
import { Table1LogActions } from "../../actions/Table1Log";

//Helpers
import { socket } from "../../helpers/global";

//Assets
import notificationSound from "../../assets/audio/iphoneTone.mp3"
import AvatarImage from '../../assets/img/default_user.jpg';

//Components
import { CloseIcon, XmlIcon, TableIcon, ExploreDataIcon, MailTemplateIcon, AssignFunder, OrganizationIcon, DashboardReport,UsersIcon } from "../../components/SvgIcons/SvgIcons";
import { withSnackbar } from "notistack";

/** Icons */
const icons = {
    dashBoardReport: <DashboardReport className="modileIconsReport" fill="#36aebe" />,
    exploreReport: <DashboardReport className="modileIconsReport" fill="#36aebe" />,
    explore: <ExploreDataIcon className="modileIcons" fill="#36aebe" />,
    reportTable: <TableIcon className="modileIcons" />,
    table1Import: <XmlIcon />,
    email: <MailTemplateIcon className="modileIcons" fill="#36aebe" />,
    organization: <OrganizationIcon className="modileIcons" fill="#36aebe" />,
    assignFunder: <AssignFunder className="modileIcons" fill="#36aebe" />,
    bulkData: <DashboardReport className="modileIcons" />,
    users: <UsersIcon className="modileIcons" fill="#36aebe" />
}
/**
 * Styles
 * @param {*} theme 
 * @returns 
 */
const styles = theme => ({
    notificationContainer: {
        fontSize: 13,
        paddingBottom: 100,
        // padding: '0px 16px',
        width: 455,
        position: 'relative',
        top: 60,
        // overflowY: 'auto',
        '& .drawerTitle': {
            fontSize: 15,
            color: 'gray',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 16px',
            marginTop: '-1px !important',
            '& .left-title': {
                display: 'flex',
                alignItems: 'center',
                '& .read-button': {
                    paddingLeft: 8,
                    '& .MuiButton-root': {
                        minHeight: 14,
                        fontSize: 10,
                    }
                },
                '& .notificationCount': {
                    color: 'white',
                    marginLeft: 12,
                    padding: 4,
                    fontWeight: 600,
                    backgroundColor: '#4eaebf',
                    borderRadius: "50%",
                    height: 24,
                    width: 24,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    lineHeight: '1rem'
                },
            },
            '& .right-title': {
                display: 'flex',
                alignItems: 'center',
                '& .clear': {
                    '& .MuiButton-root': {
                        minHeight: 24
                    }
                },
                '& .close-icon': {
                    color: '#1c1c1c',
                    // fontSize: 18,
                    // cursor: 'pointer'
                }
            }
        },
        '& .resultFound': {
            fontSize: 13,
            color: ' #36aebe',
            textAlign: 'right'
        },
        '& .notificationList': {
            overFlow: 'auto',
            // paddingTop: 12,
            '& .read': {
                color: '#999'
            },
            '& .notification': {
                display: 'flex',
                padding: '0px 16px',
                '& .content': {
                    width: '100%',
                    // paddingLeft: 12,
                    '& .titleContainer': {
                        display: 'flex',
                        '& .image': {
                            width: '10%',
                            display: 'flex',
                            justifyContent: 'center',
                            '& .iconWrapper': {
                                // boxShadow: '0px 3px 9px rgba(0, 0, 0, .15)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // borderRadius: '50%',
                                // width: 32
                                '& .modileIcons': {
                                    color: '#36aebe',
                                    fontSize: 32,
                                    height: 32,
                                    width: 32
                                },
                                '& .modileIconsReport': {
                                    color: '#36aebe',
                                    fontSize: 32,
                                    height: 32,
                                    width: 32,
                                    marginLeft: -6
                                },
                                '& .avatar': {
                                    height: 32,
                                    width: 32,
                                    border: '1px solid rgb(238, 238, 238)',
                                    '& img': {
                                        height: 32,
                                        width: 32
                                    }
                                }
                            },
                        },
                        '& .header': {
                            paddingLeft: 8,
                            width: '100%',
                            '& .title-header': {
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                '& .title': {
                                    fontWeight: 600,
                                },
                                '& .read-button': {
                                    '& .MuiButton-root': {
                                        minHeight: 14,
                                        fontSize: 10,
                                    }
                                }
                            },
                            '& .timeStamp': {
                                color: '#999999',
                                // display: 'flex',
                                // justifyContent: 'space-between',
                                // alignItems: 'center',
                            }
                        }
                    },
                    '& .description': {
                        paddingTop: 8
                    },
                    '& .description-ellipse': {
                        paddingTop: 8,
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    '& .footer': {
                        display: 'flex',
                        justifyContent: 'flex-end',
                        '& .read-button': {
                            '& .MuiButton-root': {
                                minHeight: 14,
                                fontSize: 10,
                            }
                        }
                    }
                }
            },
            '& .noRecords': {
                color: 'gray',
                textAlign: 'center'
            }
        }
    }
})
/**
 * Notification Comtainer
 * @class Notification
 * @extends {Component}
 */
class Notification extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            SearchText: '',
            SearchDropDown: 1,
            typing: false,
            viewIndex: {},
            isEnqueTriggred: false,
            alertList: [],
            alertpassed: false
        };
        this.typingTimeout = null;

        this.orgType = getOrgType();
        this.user = getUser()
        this.audio = new window.Audio(notificationSound)

        // socket.api.on('notification', (data) => {
        //     let userData = data.filter(elem => elem.user === this.user.id)
        //     if (userData.length > 0) {
        //         this.audio.play();
        //         this.props.updateNotificationFromSocket(userData);
        //     }
        // })

        // socket.ms.on('notification', (data) => {
        //     let userData = data.filter(elem => elem.user === this.user.id)
        //     if (userData.length > 0) {
        //         this.audio.play();
        //         this.props.updateNotificationFromSocket(userData);
        //         let isAlertData = userData.filter(elem => elem.type === 'report').length > 0
        //         if (isAlertData) {
        //             if((history.location.pathname.includes("/explore")&&userData[0].module=='exploreReport') || (history.location.hash==='#viewdownloadreports'&&userData[0].module==='dashBoardReport')){
        //                 this.props.updateAlertNotification({ _id: userData[0]._id }, () => {
        //                     this.props.getNotificationAlert((alertData) => {
        //                         alertData.forEach(elem => {
        //                             this.props.enqueueSnackbar(`{\"title\": \"${elem.title}\", \"description\": \"${elem.description}\" ,\"_id\": \"${elem._id}\",\"module\": \"${elem.module}\",\"exploreId\": \"${elem.exploreId}\"}`)
        //                         })
        //                     })
        //                 })
        //             }else{
        //                 if (this.props.notificationAlertList.length === 0) {
        //                     this.props.updateNotificationAlertFromSocket(userData);
        //                     userData.forEach(elem => {
        //                         this.props.enqueueSnackbar(`{\"title\": \"${elem.title}\", \"description\": \"${elem.description}\" ,\"_id\": \"${elem._id}\",\"module\": \"${elem.module}\",\"exploreId\": \"${elem.exploreId}\"}`)
        //                     })
        //                 }
        //             }
        //             // }

        //         }
        //     }
        // })
    }
    /**
     * Handle Component Will Mount 
     */
    UNSAFE_componentWillMount() {
        const { user } = this;
        this.props.getNotification(user.id);
        this.props.getNotificationAlert((data) => { })
    }
    /**
     * Component Did Mount
     */
    componentDidMount() { }

    UNSAFE_componentWillReceiveProps(props) {
        let { notificationAlertList } = props;
        const { isEnqueTriggred } = this.state;
        if (notificationAlertList.length > 0 && !isEnqueTriggred) {
            this.setState({ isEnqueTriggred: true })          // notifications = notifications.slice(3, 3)
            notificationAlertList.forEach(elem => {
                this.props.enqueueSnackbar(`{\"title\": \"${elem.title}\", \"description\": \"${elem.description}\" ,\"_id\": \"${elem._id}\",\"module\": \"${elem.module}\",\"exploreId\": \"${elem.exploreId}\"}`)
            })
        }
    }
    /**
     * View More
     * @param {*} index 
     */
    viewMore = (index) => {
        const { viewIndex } = this.state
        this.setState({ viewIndex: { index: index, open: viewIndex.open || true } })
    }
    //Error file download
    handleDownloadHtmActionClick = (item) => {
        this.props.Table1ErrorLog(item)
    };
    /**
     * render
     */
    render() {
        const { anchor, variant, open, notifications, avatar, classes, notReadNotifications } = this.props;
        const { viewIndex } = this.state;
        return (
            <>
                <Drawer
                    anchor={anchor}
                    variant={variant}
                    open={open}
                >
                    <div className={classes.notificationContainer}>
                        <div className='drawerTitle'>
                            <div className="left-title">Notifications
                                {notReadNotifications && notReadNotifications.length > 0 &&
                                    < div className="read-button" onClick={() => { this.props.updateNotification() }}>
                                        <Button size="small" color="primary">
                                            Mark All as Read
                                        </Button>
                                    </div>
                                }
                            </div>
                            <div className="right-title">
                                {notifications && notifications.length > 0 &&
                                    <span className="clear">
                                        <Button size="small" color="primary" onClick={() => { this.props.deleteNotification() }}>
                                            clear
                                        </Button>
                                    </span>
                                }
                                <IconButton size="small" onClick={() => this.props.toggleDrawer(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <hr style={{ border: '1px solid #EEEEEE' }} />
                        <div className="notificationList">
                            {notifications && notifications.length > 0 ?
                                notifications.map((notification, index) => {
                                    notification.description = notification.description.charAt(0).toUpperCase() + notification.description.slice(1);
                                    notification.title = notification.title.charAt(0).toUpperCase() + notification.title.slice(1);
                                    return (<div key={index}>
                                        <div className={`notification ${notification.isRead ? 'read' : ''}`} >
                                            <div className="content">
                                                <div className="titleContainer">
                                                    <div className="image">
                                                        <div className="iconWrapper">
                                                            {notification.module !== 'user' ?   
                                                                <span>{icons[notification.module]}</span>
                                                                : <Avatar alt="Profile Image"  src={avatar} className='avatar'/>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="header">
                                                        <div className="title-header">
                                                            <div className="title">{notification.title}</div>
                                                                {(`${notification.title}` === "WBAR XML Import Error" || (notification?.type === "table1Import" && notification?.isError))
                                                                        ? (!notification.isRead && (
                                                                            <div className="read-button">
                                                                                <Button size="small" color="primary" onClick={() => { this.handleDownloadHtmActionClick({ fileName: notification.fileName }) }}>
                                                                                    Download
                                                                                </Button>
                                                                                <Button size="small" color="primary" onClick={() => { this.props.updateNotification({ _id: notification._id }) }}>
                                                                                    Mark as Read
                                                                                </Button>
                                                                            </div>
                                                                        ))
                                                                        : (!notification.isRead && (
                                                                            <div className="read-button" onClick={() => { this.props.updateNotification({ _id: notification._id }) }}>
                                                                                <Button size="small" color="primary">
                                                                                    Mark as Read
                                                                                </Button>
                                                                            </div>
                                                                        ))
                                                                    }
                                                            </div>
                                                        <div className="timeStamp">
                                                            <div>{notification.createdAt}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${viewIndex.index === index && viewIndex.open ? 'description' : 'description-ellipse'}`} id="description">
                                                    {notification.title === "WBAR XML Import Error" ? notification.description : notification.description}
                                                </div>
                                                {notification.description.length > 128 && <div className="footer">
                                                    {((Object.keys(viewIndex).length === 0) || (viewIndex && (viewIndex.index !== index)) || (viewIndex && !(viewIndex.index === index && viewIndex.open))) &&
                                                        <div className="read-button" onClick={() => { this.viewMore(index) }}>
                                                            <Button size="small" color="primary">
                                                                View More
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>}
                                            </div>
                                        </div>
                                        <hr style={{ border: '1px solid #EEEEEE' }} />
                                    </div>)
                                })
                                : <div className="noRecords">No Notifications Found</div>
                            }
                        </div>
                    </div>
                </Drawer>
            </>
        );
    }
}
/** Default props */
Notification.defaultProps = {
    anchor: 'right',
    variant: 'persistent',
    open: false,
    toggleDrawer: () => { },
}
/** Prop Types */
Notification.propTypes = {
    anchor: propTypes.string,
    variant: propTypes.string,
    open: propTypes.bool,
    classes: propTypes.object,
    toggleDrawer: propTypes.func,
    onKeyDown: propTypes.func
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownprops 
 * @returns 
 */
const mapStateToProps = ({ users, notification: { notificationList, notificationAlertList } }, ownprops) => {
    return {
        avatar: users.myimage || AvatarImage,
        notifications: notificationList || [],
        notReadNotifications: (notificationList && notificationList.filter(elem => elem.isRead === false)) || [],
        notificationAlertList: notificationAlertList || []
    }
}
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getNotification: () => dispatch(notificationActions.getByUserId()),
        getNotificationAlert: (callBack) => dispatch(notificationActions.getNotificaionAlertByUserId(callBack)),
        updateNotificationFromSocket: (data) => dispatch(notificationActions.updateNotificationFromSocket(data)),
        updateNotificationAlertFromSocket: (data) => dispatch(notificationActions.updateNotificationAlertFromSocket(data)),
        updateNotification: (data) => dispatch(notificationActions.updateNotification(data)),
        deleteNotification: (data) => dispatch(notificationActions.delteteNotification(data)),
        updateAlertNotification: (data,callBack) => dispatch(notificationActions.updateAlertNotification(data,callBack)),
        Table1ErrorLog: (fileName) => dispatch(Table1LogActions.getErrorLogFile(fileName))       
    }
}
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(withSnackbar(Notification)));
// )(withStyles(styles)(withSnackbar(Notification)));