import { confConstants } from '../constants';
import { reportActivityConstants } from '../constants/reportActivity';

const initialState = {
    reportTableAccess: {
        reportTables: {
            programActivity: true,
            financialActivity: true,
            contractActivity: true,
        }
    },
    certificationForm: {
        data: {},
        validations: null,
        isLoading: false
    },
    programActivity: {
        data: null,
        validations: null,
        isLoading: false
    },
    financialActivity: {
        data: null,
        validations: null,
        isLoading: false
    },
    tableHistory: {
        headerCols: ['Date', 'Activity', 'Activity From Level', 'Activity To Level', 'Activity By'],
        fieldCols: ['date', 'type_id', 'levelFrom_id', 'levelTo_id', 'user_id']
    },
    contractActivity: {
        data: [],
        page: {
            // ...pageDefaults,
            fieldFilters: {
                headerCols: [],
                fieldCols: ["_id"],
                searchCols: []
            }
        },
    },
    contractActivityData: {
        data: null,
        validations: null,
        isLoading: false
    },
    contractActivityReportData: {

    },
    contractActivitySave: {

    },
    incomeData: null,
    validations: {}
};

export function reportActivity(state = { ...initialState }, action) {

    switch (action.type) {
        case reportActivityConstants.GET_PAGE_ACCESS:
            return {
                ...state,
                pageAccess: {

                }
            }
        // Certification Form
        case reportActivityConstants.GET_CERTIFICATION_FORM_REQUEST:
            return {
                ...state,
                certificationForm: {

                },
                isLoading: true
            }
        case reportActivityConstants.GET_REPORTYEAR_REQUEST:
            return {
                ...state,
                certificationForm: {

                },
                isLoading: true
            }

        case reportActivityConstants.GET_CERTIFICATION_FORM_SUCCESS:
            return {
                ...state,
                certificationForm: {
                    // ...state.certificationForm,
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.GET_CERTIFICATION_FORM_FAILURE:
            return {
                ...state,
                certificationForm: {
                    // ...state.programActivity,
                    error: action.error
                },
                isLoading: false,
            }
        case confConstants.GETONE_REQUEST:
            return {
                ...state,
                incomeData: null,
                isLoading: true
            }
        case confConstants.GETONE_SUCCESS:
        case confConstants.GETONE_FAILURE:
            {
                return {
                    ...state,
                    isLoading: false
                }
            }
        // Program Activity
        case reportActivityConstants.GET_PROGRAM_ACTIVITY_REQUEST:
            return {
                ...state,
                programActivity: {

                },
                isLoading: true
            }

        case reportActivityConstants.GET_PROGRAM_ACTIVITY_SUCCESS:
            return {
                ...state,
                programActivity: {
                    ...state.programActivity,
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.GET_PROGRAM_ACTIVITY_FAILURE:
            return {
                ...state,
                programActivity: {
                    ...state.programActivity,
                    error: action.error
                },
                isLoading: false,
            }

        // Activity access
        case reportActivityConstants.GET_ACTIVITY_ACCESS_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case reportActivityConstants.GET_ACTIVITY_ACCESS_SUCCESS:
            return {
                ...state,
                reportTableAccess: {
                    ...action.data.data
                },
                isLoading: false
            }

        case reportActivityConstants.GET_ACTIVITY_ACCESS_FAILURE:
            return {
                ...state,
                reportTableAccess: {
                    ...state.reportTableAccess,
                    error: action.error
                },
                isLoading: false,
            }

        // Financial Activity
        case reportActivityConstants.GET_FINANCIAL_ACTIVITY_REQUEST:
            return {
                ...state,
                financialActivity: {

                },
                isLoading: true
            }

        case reportActivityConstants.GET_FINANCIAL_ACTIVITY_SUCCESS:
            return {
                ...state,
                financialActivity: {
                    ...state.financialActivity,
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.GET_FINANCIAL_ACTIVITY_FAILURE:
            return {
                ...state,
                financialActivity: {
                    ...state.financialActivity,
                    error: action.error
                },
                isLoading: false,
            }

        // Contract Activity
        case reportActivityConstants.GETALL_LOAN_ACTIVITY_REQUEST:
            return {
                ...state,
                contractActivity: {
                    ...state.contractActivity
                },
                isLoading: true
            }

        case reportActivityConstants.GETALL_LOAN_ACTIVITY_SUCCESS:
            return {
                ...state,
                contractActivity: {
                    // ...state.contractActivity,
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.GETALL_LOAN_ACTIVITY_FAILURE:
            return {
                ...state,
                contractActivity: {
                    ...state.contractActivity,
                    error: action.error
                },
                isLoading: false,
            }
        case reportActivityConstants.CLEAR_LOAN_ACTIVITY:
            return {
                ...state,
                contractActivityData: {
                    data: null,
                    validations: null,
                    isLoading: false
                }
            }

        // Contract Activity
        case reportActivityConstants.GET_LOAN_ACTIVITY_REQUEST:
            return {
                ...state,
                contractActivityData: {
                    ...state.contractActivityData
                },
                isLoading: true
            }

        case reportActivityConstants.GET_LOAN_ACTIVITY_SUCCESS:
            return {
                ...state,
                contractActivityData: {
                    // ...state.contractActivity,
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.GET_LOAN_ACTIVITY_FAILURE:
            return {
                ...state,
                contractActivityData: {
                    ...state.contractActivityData,
                    error: action.error
                },
                isLoading: false,
            }

        // save contract activity
        case reportActivityConstants.SAVE_LOAN_REQUEST:
            return {
                ...state,
                contractActivitySave: {
                    ...state.contractActivitySave
                },
                isLoading: true
            }

        case reportActivityConstants.SAVE_LOAN_SUCCESS:
            return {
                ...state,
                contractActivitySave: {
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.SAVE_LOAN_FAILURE:
            return {
                ...state,
                contractActivitySave: {
                    ...state.contractActivitySave,
                    error: action.error
                },
                isLoading: false,
            }

        // income limit
        case reportActivityConstants.GET_INCOMELIMIT_REQUEST:
            return {
                ...state,
                contractActivityData: {
                    ...state.contractActivityData,
                    data: {
                        ...state.contractActivityData.data,
                        ami: action.generalData.setAside
                    }
                },
                incomeData: {
                    ...state.incomeData
                },
                //isLoading: true
            }

        case reportActivityConstants.GET_INCOMELIMIT_SUCCESS:
            return {
                ...state,
                incomeData: {
                    ...action.data
                },
                contractActivityData: {
                    ...state.contractActivityData,
                    data: {
                        ...state.contractActivityData.data,
                        maxAllowedIncome: ((action && action.data && action.data.data && action.data.data.amount && action.data.data.amount) || 0)
                    }
                },
                //isLoading: false
            }

        case reportActivityConstants.GET_INCOMELIMIT_FAILURE:
            return {
                ...state,
                incomeData: {
                    ...state.incomeData,
                    error: action.error
                },
                //isLoading: false,
            }
        case reportActivityConstants.GETALL_BYREPORT_LOAN_ACTIVITY_REQUEST:
            return {
                ...state,
                contractActivityReportData: {
                    ...state.contractActivityReportData
                },
                isLoading: true
            }

        case reportActivityConstants.GETALL_BYREPORT_LOAN_ACTIVITY_SUCCESS:
            return {
                ...state,
                contractActivityReportData: {
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.GETALL_BYREPORT_LOAN_ACTIVITY_FAILURE:
            return {
                ...state,
                contractActivityReportData: {
                    ...state.contractActivityReportData,
                    error: action.error
                },
                isLoading: false,
            }
        case reportActivityConstants.CREATE_LOAN_ACTIVITY_REQUEST:
        case reportActivityConstants.CREATE_FINANCIAL_ACTIVITY_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case reportActivityConstants.CREATE_LOAN_ACTIVITY_SUCCESS:
        case reportActivityConstants.CREATE_LOAN_ACTIVITY_FAILURE:
        case reportActivityConstants.CREATE_FINANCIAL_ACTIVITY_SUCCESS:
        case reportActivityConstants.CREATE_FINANCIAL_ACTIVITY_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        case reportActivityConstants.SUBMIT_LOAN_REQUEST:
        case reportActivityConstants.SUBMIT_LOAN_EDIT_REQUEST:
            return {
                ...state,
                isLoading: true
            }

        case reportActivityConstants.SUBMIT_LOAN_SUCCESS:
        case reportActivityConstants.SUBMIT_LOAN_EDIT_SUCCESS:
            return {
                ...state,
                submissionData: {
                    ...action.data
                },
                isLoading: false
            }
        case reportActivityConstants.UNSUBMIT_LOAN_REQUEST: {
            return {
                ...state,
                isLoading: true
            }
        }
        case reportActivityConstants.UNSUBMIT_LOAN_SUCCESS:
        case reportActivityConstants.UNSUBMIT_LOAN_FAILURE:
            {
                return {
                    ...state,
                    isLoading: false
                }
            }
        // validation issue
        case reportActivityConstants.VALIDATION_ISSUE_REQUEST:
            return {
                ...state,
                validations: {
                    ...state.validations
                },
                isLoading: true
            }

        case reportActivityConstants.VALIDATION_ISSUE_SUCCESS:
            return {
                ...state,
                validations: {
                    ...action.data
                },
                isLoading: false
            }

        case reportActivityConstants.VALIDATION_ISSUE_FAILURE:
            return {
                ...state,
                validations: {
                    ...state.validations,
                    error: action.error
                },
                isLoading: false,
            }

        default: return state;
    }
}