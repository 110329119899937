import React, { Component } from 'react';

// Material UI
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

// import components
import PageActions from '../../../components/PageActions/PageActions';
import ToolTip from '../../../components/ToolTip/ToolTip';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import NumberField from '../../../components/NumberField/NumberField';
import { DeleteIcon } from '../../../components/SvgIcons/SvgIcons';

/**
 * SectionTable Component
 * @class SectionTable
 * @extends {Component}
 */
class SectionTable extends Component {
    /**
     * Validate Effective year
     * @param {*} row 
     * @returns 
     * 
     * [1]  -   If firstYear is grater that or equal to lastYear, then add required & islastYeargtFirstYear validators, else add required validator
     */
    validateEffectiveYear(row) {
        const firstYear = parseInt(row.firstEffectiveYear);
        const lastYear = parseInt(row.lastEffectiveYear);
        if (firstYear >= lastYear)
            return {
                validators: ['required', 'islastYeargtFirstYear'],
                errorMessages: ['This field is required', 'Last Year should be greater than First Year']
            }
        else return {
            validators: ['required'],
            errorMessages: ['This field is required']
        }
    }
    /**
     * Bind HTML to reactDOM]
     * @returns 
     * 
     * [1]  -   Filter required setAsideType by categoryId
     * [2]  -   Sort setAsideTypeMenu bu name
     * [3]   -  Assign label and key to setAsideTypeMenu
     * [4]  -   Sort bedrooms bu desc
     * [5]   -  Assign label and key to bedrooms
     */
    render() {
        let { config: { setAsideType, bedrooms }, classes, isReadOly, claimedSite, setAsideBy, headers, categoryId, title, setAsideTypes, isConfigByBedRoom, childrenAlign } = this.props;
        // [1]
        let setAsideTypeMenu = setAsideType.filter((type) => type.category_id === categoryId);
        // [2]
        setAsideTypeMenu = global._.sortBy(setAsideTypeMenu, function (o) { return o.name; });
        // [3]
        setAsideTypeMenu = setAsideTypeMenu.map((s) => {
            return {
                ...s,
                label: s.label || s.name || s.desc,
                value: s.key
            }
        });
        // [4]
        bedrooms = global._.sortBy(bedrooms, function (o) { return o.desc; });
        // [5]
        bedrooms = bedrooms.map((s) => {
            return {
                ...s,
                label: s.desc,
                value: s.key
            }
        });
        return (
            <React.Fragment>
                <h4 className="section_title" style={{ margin: '30px 0px 12px' }}>
                    {title}
                </h4>
                {childrenAlign === 'top' && this.props.children}
                <Table className="funderTaxTable">
                    <TableHead>
                        <TableRow>
                            {
                                headers.map((header, indexH) => {
                                    // action does not need *
                                    return <TableCell key={indexH}><span className={indexH === headers.length - 1 ? '' : 'required-header'}>{header}</span></TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {setAsideTypes.map((row, index) => {
                            if ((setAsideBy === 1 && claimedSite !== row.site_id) || categoryId !== row.category_id) {
                                return null;
                            };

                            return (
                                <TableRow key={`tr_st_${index}`}>
                                    {
                                        (categoryId === 1 || categoryId === 2 || categoryId === 3 || categoryId === 4 || categoryId === 5) ? (
                                            <TableCell>
                                                <AutoComplete
                                                    name={`setAsideType_id_${index}`}
                                                    suggestions={setAsideTypeMenu}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.props.handleSetAsideChangeMultiSelect('setAsideType_id', index, selectedOption.value);
                                                    }}
                                                    selectedValue={{ id: row.setAsideType_id }}
                                                    margin="dense"
                                                    disabled={isReadOly}
                                                    validators={['required']}
                                                    errorMessages={['this field is required']}
                                                />
                                            </TableCell>
                                        ) : null
                                    }
                                    {
                                        ((categoryId === 5 && isConfigByBedRoom) || (categoryId === 6 && isConfigByBedRoom) || (categoryId === 7 && isConfigByBedRoom)) ? (
                                            <TableCell>
                                                <AutoComplete
                                                    name={`bedroom_id_${index}`}
                                                    suggestions={bedrooms}
                                                    handleAutoCompolete={(selectedOption) => {
                                                        this.props.handleSetAsideChangeMultiSelect('bedroom_id', index, selectedOption.value);
                                                    }}
                                                    selectedValue={{ id: row.bedroom_id }}
                                                    margin="dense"
                                                    disabled={isReadOly}
                                                    validators={['required']}
                                                    errorMessages={['this field is required']}
                                                />
                                            </TableCell>
                                        ) : null
                                    }
                                    <TableCell>
                                        <NumberField
                                            name={`unitCount_${index}`}
                                            value={row.unitCount}
                                            label=""
                                            className={classes.textField}
                                            handleChange={this.props.handleSetAsideChange('unitCount', index)}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            disabled={isReadOly}
                                            allowNegative={false}
                                            prefix={''}
                                            thousandSeparator={false}
                                            decimalScale={0}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name={`firstEffectiveYear_${index}`}
                                            value={row.firstEffectiveYear}
                                            label=""
                                            className={classes.textField}
                                            handleChange={this.props.handleSetAsideChange('firstEffectiveYear', index)}
                                            margin="dense"
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            disabled={isReadOly}
                                            allowNegative={false}
                                            prefix={''}
                                            thousandSeparator={false}
                                            decimalScale={0}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <NumberField
                                            name={`lastEffectiveYear_${index}`}
                                            value={row.lastEffectiveYear}
                                            label=""
                                            className={classes.textField}
                                            handleChange={this.props.handleSetAsideChange('lastEffectiveYear', index)}
                                            margin="dense"
                                            // validators={['required']}
                                            // errorMessages={['this field is required']}
                                            {...this.validateEffectiveYear(row)}
                                            disabled={isReadOly}
                                            allowNegative={false}
                                            prefix={''}
                                            thousandSeparator={false}
                                            decimalScale={0}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <ToolTip title="Delete" placement="bottom">
                                                <IconButton
                                                    className="delete_icon"
                                                    color="primary"
                                                    aria-label="View"
                                                    disabled={isReadOly}
                                                    onClick={() => {
                                                        this.props.onDelete(index);
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                        </ToolTip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {childrenAlign === 'bottom' && this.props.children}
                <Grid item xs={12} sm={12}>
                    <PageActions
                        isSubPage={true}
                        actions={isReadOly ? [] : ['ADDLINK']}
                        handleAddActionClick={() => {
                            const siteId = claimedSite === 0 ? 0 : claimedSite;
                            this.props.onAdd(siteId, categoryId);
                        }}
                    />
                </Grid>

            </React.Fragment>
        );
    }
}

SectionTable.defaultProps = {
    isConfigByBedRoom: false
};

export default SectionTable;