import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

// Material ui
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Grid } from '@material-ui/core';

//Import Components
import TableComponent from '../../components/Table/TableComponent';
import FieldFilter from "../../components/FieldFilter/FieldFilter";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import AutoCompleteMultiMUI from '../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI'
import SaveCancel from '../../components/SaveCancel/SaveCancel'

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions
import { projectsActions } from '../../actions/projects';
import { confActions } from '../../actions/configuration';

// Import Helpers
import { getUserOrgId, getUserRole, globalVariable, history, filterSearchFields } from '../../helpers';

let sFields = [];
let sValues = [];
/**
 * FundersList Container
 * @class FundersList
 * @extends {Component}
 */
class FundersList extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            fieldFilter: false,
            deleteFunderFlag: false,
            deleteFunderId: null,
            openAssignFunders: false,
            selectedFunderOrgs: []
        };

        this.userOrgId = getUserOrgId();
        this.userRole = getUserRole();
    }

    /**
     * Component Did Mount Event
     */
    UNSAFE_componentWillMount() {
        const { page } = this.props.funders;
        const projectId = this.props.projectId;
        // retain old search values
        if (page.skip) {
            sFields = page.sFields;
            sValues = page.sValues;
            this.props.getAllFunders(page.skip, globalVariable.tableRowSize, page.orderBy, page.order, page.sFields, page.sValues, projectId);
        } else {
            sFields = [];
            sValues = [];
            this.props.getAllFunders(1, globalVariable.tableRowSize, 'org_name.name', 'asc', '', '', projectId);
        }
        setTimeout(() => {
            this.props.getFieldFilter('projectfunders');
            this.props.getFunderOrgs();
        }, 100);
    }

    /**
     * Component did Update
     */
    componentDidUpdate() {
        switch (this.props.location.hash) {
            case "#clear":
                this.handleClearFilterActionClick();
                history.replace(this.props.location.pathname);
                break;
            default:
                break;
        }
    }
    /**
     * Handle Table Tagination Event
     * @param {*} skip 
     * @param {*} limit 
     */
    onChangePageTable = (skip, limit) => {
        const { funders } = this.props;
        const projectId = this.props.projectId || this.props.copyId;
        this.props.getAllFunders(skip, limit, funders.page.orderBy, funders.page.order, sFields, sValues, projectId);
    };
    /**
     * Handle Tasites
     * @param {*} orderBy 
     * @param {*} order 
     */
    onSortRequest = (orderBy, order) => {
        const { funders } = this.props;
        const projectId = this.props.projectId || this.props.copyId;
        this.props.getAllFunders(funders.page.skip, funders.page.limit, orderBy, order, sFields, sValues, projectId);
    };
    /**
     * Handle Table Search Input Change Event
     * @param {*} searchField 
     * @param {*} searchKey 
     */
    onInputChangeRequest = (searchField, searchKey) => {
        const { funders } = this.props;
        const projectId = this.props.projectId || this.props.copyId;

        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === '') {
                let index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        this.props.getAllFunders(1, globalVariable.tableRowSize, funders.page.orderBy, funders.page.order, sFields, sValues, projectId);
    };
    /**
     * Handle Add Action
     */
    handleAddActionClick = () => {
        const { match, funders } = this.props;
        if (funders && funders.data && funders.data.length > 0) {
            let funderOrgData = funders.data.filter(elem => elem.org_name._id === getUserOrgId())
            if (funderOrgData.length > 0) {
                history.push({
                    pathname: `${match.url}/${funderOrgData[0]._id}`,
                    state: {
                        page: 'funderEdit',
                        pageData: {
                            funder_id: funderOrgData[0]._id,
                            orgId: funderOrgData[0].org_name._id,
                            isArchived: match.path.includes('archives')
                        },
                    }
                });
            } else {
                history.push(`${match.url}/new`);
            }
        } else {
            history.push(`${match.url}/new`);
        }
    };
    /**
     * Handle Clear Fileter Action
     */
    handleClearFilterActionClick = () => {
        const projectId = this.props.projectId || this.props.copyId;
        sFields = [];
        sValues = [];
        const { funders } = this.props;
        this.props.getAllFunders(funders.page.skip, funders.page.limit, funders.page.orderBy, funders.page.order, sFields, sValues, projectId);
    };
    /**
     * Handle Field Filter Action Event
     * 
     * [1]  -   update visible columns
     */
    handleFieldFilterActionClick = () => {
        this.setState({
            fieldFilter: !this.state.fieldFilter
        });
        // [1]
        this.props.changeFieldFilter("projectfunders", this.props.funders.page.fieldFilters.visibleColumns);
    };

    /**
     * Handle Field Filter Popup On Close Event
     * 
     * [1]  -   reset to current table column values if user not save the changes
     * [2]  -   close the popup
     */
    handleFieldFilterOnClose = () => {
        // [1]
        this.props.changeFieldFilter("projectfunders", this.props.funders.page.fieldFilters.visibleColumns);
        // [2]
        this.setState({ fieldFilter: false });
    }
    /**
     * A method to update visible columns
     * @param {*} key 
     * @param {*} value 
     */
    handleFieldFilterOnChange = (key, value) => {
        if (!value) {
            const updatedColumns = this.props.visibleColumns.filter((value) => {
                return value !== key;
            });
            this.props.changeFieldFilter("projectfunders", updatedColumns);
        } else {
            this.props.changeFieldFilter("projectfunders", [...this.props.visibleColumns, key]);
        }
    }

    /**
     * Handle Field Filter On Save Event
     * 
     * [1]  -   on api call back close the popup and call the table view api again
     */
    handleFieldFilterOnSave = () => {
        const projectId = this.props.projectId || this.props.copyId;
        this.props.updateFieldFilter("projectfunders", this.props.visibleColumns, () => {
            // [1]
            this.setState({ fieldFilter: false });
            const { funders } = this.props;
            this.props.getAllFunders(funders.page.skip, funders.page.limit, funders.page.orderBy, funders.page.order, sFields, sValues, projectId);
        });
    }
    /**
     * Handle Table Delete Action
     * @param {*} id 
     */
    handleTableDeleteAction = (id) => {
        this.setState({ deleteFunderFlag: true, deleteFunderId: id })
    };
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        const { match } = this.props;
        switch (field) {
            case "org_name.name":
                history.push({
                    pathname: `${match.url}/${id}`,
                    state: {
                        page: 'funderEdit',
                        pageData: {
                            funder_id: id,
                            orgId: item.org_name._id,
                            isArchived: match.path.includes('archives')
                        },
                    }
                });
                break;
            default: break;
        }

    }
    /**
     * handleFunderOrgsAutocomplete
     * @param {*} selectedOption 
     */
    handleFunderOrgsAutocomplete = (selectedOption) => {
        this.setState({ selectedFunderOrgs: selectedOption })
    }
    /**
     * handleAssignFunderSubmit
     */
    handleAssignFunderSubmit = () => {
        const { selectedFunderOrgs } = this.state;
        const { projectId } = this.props

        let funderOrgIds = []
        selectedFunderOrgs.forEach(org => {
            funderOrgIds.push(org._id);
        })
        this.setState({ openAssignFunders: false })
        this.setState({ selectedFunderOrgs: [] })
        this.props.addFunderOrgs({ funderOrgIds: funderOrgIds, projectId: projectId })
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { fieldFilter, openAssignFunders, selectedFunderOrgs } = this.state;
        const { userRole, userOrgId } = this;
        let { funders, columnsMap, visibleColumns, needPadding, location: { pathname }, isSubPage, classes, title, pageAccess, funderOrgs } = this.props;
        let funderList = [];
        let funderOrgsData = [];
        const fieldFilters = funders.page ? funders.page.fieldFilters : {};
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];

        if (funderOrgs && funderOrgs.length > 0) {
            funderOrgs = funderOrgs.map(org => {
                org.value = org._id;
                org.label = org.name;
                return org
            })
        }

        funders.data.forEach(item => {
            funderList.push(item.org_name._id);
        });

        funderOrgsData = funderOrgs.filter(item => !funderList.includes(item._id));
        funderOrgsData = funderOrgsData.filter(item => item._id !== 1 && item._id !== 2);
        return (

            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={title}
                isSubPage={isSubPage}
                handleAddActionClick={this.handleAddActionClick}
                needPadding={needPadding}
                handleClearFilterActionClick={() => {
                    history.replace(`${pathname}#clear`);
                }}
                handleFieldFilterActionClick={this.handleFieldFilterActionClick}
                handleAssignFunderActionClick={() => { this.setState({ openAssignFunders: true }) }}
            >
                <TableComponent
                    page={funders.page.skip}
                    rowsPerPage={funders.page.limit}
                    count={funders.page.count}
                    header={fieldFilters.headerCols}
                    field={fieldFilters.fieldCols}
                    search={fieldFilters.searchCols}
                    actions={pageAccess}
                    userOrgId={userOrgId}
                    userRole={userRole}
                    links={fieldFilters.fieldCols[0]}
                    order={funders.page.order}
                    orderBy={funders.page.orderBy}
                    data={funders.data}
                    disablePagination={funders.lastPage}
                    // isLoading={funders.isLoading}
                    onChangePageTable={this.onChangePageTable}
                    onSortRequest={this.onSortRequest}
                    onInputChangeRequest={this.onInputChangeRequest}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    resetSearchContent={this.props.location.hash === "#clear"}
                    paginationVisible={true}
                    sFields={filterSearchFields(sFields)}
                    sValues={sValues}
                    handleNavigateAction={this.handleNavigateAction}
                    noDataErrorMessage={"No Records Found"}
                />

                <FieldFilter
                    open={fieldFilter}
                    handleOnClose={this.handleFieldFilterOnClose}
                    handleOnChange={this.handleFieldFilterOnChange}
                    handleOnSave={this.handleFieldFilterOnSave}
                    columnsMap={columnsMap}
                    visibleColumns={visibleColumns}
                />
                <AlertDialog
                    open={this.state.deleteFunderFlag !== false}
                    title={'Warning:'}
                    onClose={() => {
                        this.setState({ deleteFunderFlag: false })
                    }}
                    onSave={() => {
                        this.setState({ deleteFunderFlag: false });
                        this.props.deleteFunder(this.state.deleteFunderId, this.props.projectId, () => {
                            const projectId = this.props.projectId || this.props.copyId;
                            const { funders } = this.props;
                            this.props.getAllFunders(funders.page.skip, funders.page.limit, funders.page.orderBy, funders.page.order, sFields, sValues, projectId);
                        });
                    }}
                    saveText={'Unclaim'}
                    cancelText={'Cancel'}
                >
                    Unclaiming this Project will imply that this Project was never monitored by this Funder, and will also unclaim all Sites and Buildings under it. This will affect validation on all reports that use this Project's set asise requirements. If you do not want to delete this, click Cancel.
                </AlertDialog>

                <AlertDialog
                    open={openAssignFunders}
                    title="Assign Funder"
                    // onClose={() => { this.setState({ alertPopup: { ...alertPopup, open: false } }) }}
                    // onSave={() => { this.refs.form.submit() }}
                    // saveText={'Assign'}
                    saveVisible={false}
                    cancelVisible={false}
                    maxWidth='sm'
                    className="p-0-24"
                >
                    <div className="funder_user">
                        <ValidatorForm
                            name="assignFunderForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleAssignFunderSubmit}
                            onError={errors => { }}
                        >
                            <Grid container spacing={4} align="left" className="" style={{marginBottom:'70%'}}>
                                <Grid item xs={12}>
                                    <AutoCompleteMultiMUI
                                        placeholder='Funders'
                                        suggestions={funderOrgsData}
                                        handleAutoCompolete={this.handleFunderOrgsAutocomplete}
                                        selectedValue={selectedFunderOrgs}
                                        disabled={false}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        needSelectAll={true}
                                        needManualFocus={true}
                                    />
                                </Grid>
                            </Grid>
                            <SaveCancel
                                className
                                saveText="Assign"
                                justifyContent="flex-end"
                                handleSaveButtonClick={() => {
                                    this.refs.form.submit()
                                }
                                }
                                handleCancelButtonClick={() => { this.setState({ openAssignFunders: false, selectedFunderOrgs: [] }) }}
                            />
                        </ValidatorForm>
                    </div>
                </AlertDialog>
            </ContentWrapper>
        );
    }
}

/**
 * Bind Component Property Types
 */
FundersList.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    needPadding: PropTypes.bool,
    isSubPage: PropTypes.bool,
    title: PropTypes.string
};

FundersList.defaultProps = {
    projectId: null,
    needPadding: true,
    isSubPage: false,
    title: 'All Funders'
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    const columnsData = state.configuration.fieldFilter['projectfunders'] || {};
    return {
        funders: state.projects.getOne.funders,
        columnsMap: columnsData.columnsMap || {},
        visibleColumns: columnsData.visibleColumns || [],
        pageAccess: state.pageAccess['fundersList'] || [],
        projects: state.projects,
        funderOrgs: state.projects.funderOrgs
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getAllFunders: (skip, limit, orderBy, order, sField, sValue, projectId) => dispatch(projectsActions.getAllFunders(skip, limit, orderBy, order, sField, sValue, projectId)),
        deleteFunder: (id, projectId, callBack) => dispatch(projectsActions.deleteFunder(id, projectId, callBack)),
        getFieldFilter: (table) => dispatch(confActions.getFieldFilter(table)),
        updateFieldFilter: (table, visibleColumns, clback) => dispatch(confActions.updateFieldFilter(table, visibleColumns, clback)),
        changeFieldFilter: (table, visibleColumns) => dispatch(confActions.changeFieldFilter(table, visibleColumns)),
        getFunderOrgs: () => dispatch(projectsActions.getFunderOrganizations()),
        addFunderOrgs: (funderOrgIds) => dispatch(projectsActions.addFunderOrganizations(funderOrgIds))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(FundersList));
