import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';

// Tab Components
import LoanProjectsList from '../LoanProjects/LoanProjectsList';
import ProjectsList from '../Projects/ProjectsList';

// import child router components
// import OccupantsRouter from "../../containers/Occupants/Occupants.Router";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

import { Route, Switch } from 'react-router-dom';
import { history } from '../../helpers';

class AssociatedProjects extends Component {

    /** 
     * Component Will Mount Event
     */
    UNSAFE_componentWillMount() {

    }

    /**
     * Component Did Mount Event
     */
    componentDidMount() {

    }


    /**
    * Handle Tab Change Event
    */
    handleTabChange = (event, value) => {
        const { match, pageData, pageTitle } = this.props;
        switch (value) {
            case 1:
                history.push({
                    pathname: `${match.url}`,
                    state: {
                        page: 'projectsList',
                        pageData: {
                            // project_id: projectId,
                            reportYear_id: match.params.id,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            case 2:
                history.push({
                    pathname: `${match.url}/homeOwnership`,
                    state: {
                        page: 'loanProjects',
                        pageData: {
                            // project_id: projectId,
                            // reportYear_id: match.params.id,
                            ...pageData
                        },
                        pageTitle
                    }
                });
                break;
            default:
                history.push(`${match.url}`);
                break;
        }
    };

    /**
     * 
     * @param {*} pathname 
     */
    getTabIndex(pathname) {
        const tabs = ["", "homeOwnership"];
        const tabUrl = pathname.split('/').pop();
        const currentIndex = tabs.indexOf(tabUrl);
        return currentIndex === -1 ? 1 : currentIndex + 1;
    }

    componentWillUnmount() {
        // clear table 1 searches
        // this.props.clearTables();
    }

    /**
     * Bind Html to DOM
     */
    
    render() {
        let { classes, match, location: { pathname }, users, pageAccess, title, userId, tableActionRequired } = this.props;
        const orgAccess = users.getOne.data.orgAccess;
        match.url = match.url.replace(/\/$/, "");
        const currentTab = this.getTabIndex(pathname);
        let isReadOly = pageAccess.indexOf('SAVE') === -1;
        return (
            <div className='reports'>
                <ContentWrapper
                    classes={classes}
                    pageAccess={pageAccess}
                    title={title}
                    isSubPage={true}
                    needTitleBar={title ? true : false}
                    needPadding={false}
                    handleClearFilterActionClick={() => {
                        history.replace(`${pathname}#clear`);
                    }}
                    handleBackActionClick={this.handleBackActionClick}
                    handleSaveActionClick={this.handleSaveActionClick}
                >
                    {
                        orgAccess === 3 &&
                        <AppBar position="static" color="secondary">
                            <Tabs
                                name={'tab'}
                                value={currentTab}
                                onChange={this.handleTabChange}
                                indicatorColor={'primary'}
                                textColor="primary"
                                variant="fullWidth"
                                color="secondary"
                                className={'reportTab'}
                            >
                                <Tab className="headerUserLoan" classes={{ selected: "reportTabSelected" }} label="Multi Family" value={1} />
                                <Tab className="headerUserLoan" classes={{ selected: "reportTabSelected" }} label="Home Ownership" value={2} />
                            </Tabs>
                        </AppBar>
                    }
                    {
                        orgAccess === 1 &&
                        <AppBar position="static" color="secondary">
                            <Tabs
                                name={'tab'}
                                value={1}
                                onChange={this.handleTabChange}
                                indicatorColor={'primary'}
                                textColor="primary"
                                variant="fullWidth"
                                color="secondary"
                                className={'reportTab'}
                            >
                                <Tab classes={{ selected: "reportTabSelected" }} label="Multi Family" value={1} />
                                {/* <Tab classes={{ selected: "reportTabSelected" }} label="Home Ownership" value={2} /> */}
                            </Tabs>
                        </AppBar>
                    }
                    {
                        orgAccess === 2 &&
                        <AppBar position="static" color="secondary">
                            <Tabs
                                name={'tab'}
                                value={2}
                                onChange={this.handleTabChange}
                                indicatorColor={'primary'}
                                textColor="primary"
                                variant="fullWidth"
                                color="secondary"
                                className={'reportTab'}
                            >
                                {/* <Tab classes={{ selected: "reportTabSelected" }} label="Multi Family" value={1}/> */}
                                <Tab classes={{ selected: "reportTabSelected" }} label="Home Ownership" value={2} />
                            </Tabs>
                        </AppBar>
                    }
                    <TabContainer>
                        <Switch>
                            { 
                                orgAccess !== 2 &&
                                <Route
                                    exact
                                    path={`${match.url}`}
                                    render={(props) => {
                                        return (
                                            <ProjectsList
                                                {...props}
                                                title=""
                                                userId={userId}
                                                isReadOly={isReadOly}
                                                page='users'
                                                isSubPage={true}
                                                removeActions={['ADD', 'UPLOAD', 'MERGE', 'TABLE1_IMPORT', 'UPLOAD_BULK_UTILITYALLOWANCE']}
                                                tableActionRequired={tableActionRequired}
                                            />
                                        )
                                    }}
                                />
                            }
                            {
                                orgAccess !== 1 &&
                                <Route
                                    exact
                                    path={`${match.url}/homeOwnership`}
                                    render={(props) => {
                                        return (
                                            <LoanProjectsList
                                                {...props}
                                                // pageAccess={pageAccess}
                                                title=""
                                                isReadOly={isReadOly}
                                                fromPage="users"
                                                userId={userId}
                                                page='users'
                                                removeActions={['COPY','DELETE','ADD', 'UPLOAD', 'MERGE', 'TABLE1_IMPORT', 'UPLOAD_BULK_UTILITYALLOWANCE']}
                                                isSubPage={true}
                                            />
                                        )
                                    }}
                                />
                            }
                        </Switch>
                    </TabContainer>
                </ContentWrapper>
                {/* <PageLoader show={reportSubmission.isLoading} /> */}
            </div>
        );
    }
}

/**
 * Bind Component Property Types
 */
AssociatedProjects.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    isContactPage: PropTypes.bool
};

/**
 * Default Props
 */
AssociatedProjects.defaultProps = {
    needPadding: false,
    isSubPage: false,
    title: '',
    pageAccess: [],
    isContactPage: false
};


/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps) => {
    return {
        users: state.users
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {

};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(AssociatedProjects));