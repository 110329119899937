import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Components
import ReportsActivityList from "./ReportsActivityList";
import ReportsActivityView from "./ReportsActivityView";

class ReportsActivityRouter extends Component {

    /**
 * Bind Html to DOM 
 */
    render() {
        const { match, projectId, isSubPage, page, innerRef } = this.props;
        return (
            <Switch >
                <Route exact path={match.url} render={(props) => {
                    return <ReportsActivityList 
                    {...props} 
                    page={page} 
                    projectId={projectId} 
                    isSubPage={isSubPage}/>
                }} />
                <Route path={`${match.url}/:reportYearId/:year`} render={props => {
                    return (
                        <ReportsActivityView
                            {...props}
                            innerRef={innerRef}
                            // site_id={props.location.state ? props.location.state.site_id : null}
                            pageData={
                                props.location.state ? props.location.state.pageData : {}
                            }
                            projectId={projectId}
                            isSubPage={isSubPage}
                        />
                    )
                }} />

            </Switch>
        );
    }
}


/**
 * Export Component
 */
export default ReportsActivityRouter;