import React, { Component } from 'react';
import { connect } from 'react-redux';

// Material UI
import Grid from '@material-ui/core/Grid';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { AppBar, Tab, Badge, withStyles, Typography, Menu, MenuItem } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';

//import styles
import './../incomeRentStyle.css'
import pageStyle from '../../../assets/jss/containers/common';

//import components
import PageTitle from '../../../components/PageTitle/PageTitle';
import PageActions from '../../../components/PageActions/PageActions';
import Maintenance from './Maintenence';
import ImportFiles from './ImportFiles'
import VerifyData from './VerifyData';
import RunCron from './RunCron'
import ToolTip from '../../../components/ToolTip/ToolTip';
import HelpFloater from '../../../components/HelpFloater/HelpFloater';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';
import { HelpIcon } from '../../../components/SvgIcons/SvgIcons';

// Actions
import { maintenanceActions } from '../../../actions/maintenance';
import { alertActions, helpActions } from '../../../actions';

// helpers
import { socket } from '../../../helpers/global'
import { history } from '../../../helpers';
import { getChildCardHeight } from '../../../helpers/global'

//Images
import Caution from '../../../assets/img/alertCaution.svg'
/**
 * IncomeRent component
 * @class IncomeRent
 * @extends {Component}
 */
class IncomeRent extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            currentTab: '0',
            childCardHeight: getChildCardHeight() + 28,
            openDownload: false,
            openHelp: false,
            anchorEl: null,
            alertPopup: {
                open: false
            }
        }

    }
    /**
     * Component Will Mount
    */
    UNSAFE_componentWillMount() {
        this.props.maintanance();
        this.props.getHelp();
    }
    /**
     * Component Did Mount
     */
    componentDidMount() {
        // socket.ms.on('MaintenanceUpdate', (data) => {
        //     if (!data) {
        //         history.replace('/configurations/incomerent')
        //     }
        //     this.props.updateMaintenanceStatus(data)
        // })
    }
    /**
   * Handle Help Click Action
   */
    handleHelpActionClick = () => {
        this.setState({ openHelp: true })
    }
    /**
     * change tab value
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });
    }
    /**
     * Handle Download Action Click
     */
    handleDownloadActionClick = () => {
        this.setState({ openDownload: true })
    }
    /**
     * Handle Template Download
     * @param {*} type 
     */
    handleTemplateDownload = (type) => {
        this.props.downloadTemplates('', type);
        this.setState({ anchorEl: null })
        setTimeout(() => {
            this.props.clearAlerts()
        }, 3000)
    }
    /**
     * render HTML
     * @returns 
     */
    render() {
        const { maintenance, helpData, location } = this.props;
        const { childCardHeight, openHelp, anchorEl, alertPopup } = this.state;
        const currentTab = maintenance ? maintenance.processIndex ? `${maintenance.processIndex}` : '0' : '0'
        return (
            <Grid container spacing={2} className={'p-0-14'}>
                <Grid container xs={12} className={'p-10-8-0'}>
                    <Grid item xs={8} className={'p-6-8-4-8'}>
                        <PageTitle title="Import Income & Rent Limit" />
                    </Grid>
                    <Grid item xs={4} className={'p-6-8-4-8'}>
                        <PageActions
                            actions={(currentTab === '1' && !maintenance.pending) ? ["OFFMAINTENANCE", "DOWNLOAD", "HELP"] : ["DOWNLOAD", "HELP"]}
                            downloadColor='secondary'
                            handleDownloadActionClick={(event) => this.setState({ anchorEl: event.currentTarget })}
                            handleLogsActionClick={() => history.push('/incomerent/logs')}
                            handleHelpActionClick={this.handleHelpActionClick}
                            handleOffMaintenanceActionClick={() => this.setState({ alertPopup: { ...this.state.alertPopup, open: true } })}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'p-6-8-4-8'}>
                    <TabContext value={currentTab}>
                        <AppBar position="static" color="default">
                            <TabList
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                            >
                                <Tab
                                    label={
                                        <div className={'displayFlex width-100-per jcSpaceBetween p-l-r-24'}>
                                            <div><Badge badgeContent={1} color="primary" className={`badgeMedium ${currentTab === "0" ? "activeTab" : "inactiveTab"}`} /></div>
                                            <div className={"width-100-per"}>Maintenance & Backup </div>
                                            <div>
                                                <ToolTip title={"Turning on maintenance mode will shut down the server and initiate data backup. Only the user turning on the maintenance mode will be given read and write access. Once the files are uploaded successfully the user can verify the data and run the CRON."} placement="top">
                                                    <Badge badgeContent={<HelpIcon />} className={`badgeMedium activeTab`} />
                                                </ToolTip>
                                            </div>
                                        </div>
                                    }
                                    value={'0'}
                                // disabled
                                />
                                <Tab
                                    label={
                                        <div className={'displayFlex width-100-per jcSpaceBetween p-l-r-24'}>
                                            <div><Badge badgeContent={2} color="primary" className={`badgeMedium ${currentTab === "1" ? "activeTab" : "inactiveTab"}`} /></div>
                                            <div className={"width-100-per"}>Import Files</div>
                                            <div>
                                                <ToolTip title={"Allows you to import  Income Limit, Rent Limit & What Limit files."} placement="top">
                                                    <Badge badgeContent={<HelpIcon />} className={`badgeMedium activeTab`} />
                                                </ToolTip>
                                            </div>
                                        </div>
                                    }
                                    value={'1'}
                                // disabled
                                />
                                <Tab
                                    label={
                                        <div className={'displayFlex width-100-per jcSpaceBetween p-l-r-24'}>
                                            <div><Badge badgeContent={3} color="primary" className={`badgeMedium ${currentTab === "2" ? "activeTab" : "inactiveTab"}`} /></div>
                                            <div className={"width-100-per"}>Verify Data</div>
                                            <div>
                                                <ToolTip title={"Allows you to verify the imported data from the  Income Limit, Rent Limit & What Limit files."} placement="top">
                                                    <Badge badgeContent={<HelpIcon />} className={`badgeMedium activeTab`} />
                                                </ToolTip>
                                            </div>
                                        </div>
                                    }
                                    value={'2'}
                                // disabled
                                />
                                <Tab
                                    label={
                                        <div className={'displayFlex width-100-per jcSpaceBetween p-l-r-24'}>
                                            <div><Badge badgeContent={4} color="primary" className={`badgeMedium ${currentTab === "3" ? "activeTab" : "inactiveTab"}`} /></div>
                                            <div className={"width-100-per"}>Run</div>
                                            <div>
                                                <ToolTip title={"Run the CRON for Income Limit, Rent Limit & What Limit files."} placement="top">
                                                    <Badge badgeContent={<HelpIcon />} className={`badgeMedium activeTab`} />
                                                </ToolTip>
                                            </div>
                                        </div>
                                    }
                                    value={'3'}
                                // disabled
                                />
                            </TabList>

                        </AppBar>
                        <TabPanel value={'0'} index={0} className={"whiteBG overFlowAuto"} style={{ height: `${childCardHeight}px` }}>
                            <Maintenance
                                handleTabNavigation={() => { this.setState({ currentTab: '1' }) }}
                            />
                        </TabPanel>
                        <TabPanel value={'1'} index={1} className={"whiteBG overFlowAuto"} style={{ height: `${childCardHeight}px` }}>
                            <ImportFiles
                                handleTabNavigation={() => { this.setState({ currentTab: '2' }) }}
                            />
                        </TabPanel>
                        <TabPanel value={'2'} index={2} className={"whiteBG overFlowAuto"} style={{ height: `${childCardHeight}px` }}>
                            <VerifyData
                                location={location}
                                handleTabNavigation={() => { this.setState({ currentTab: '3' }) }}
                            />
                        </TabPanel>
                        <TabPanel value={'3'} index={3} className={"whiteBG overFlowAuto"} style={{ height: `${childCardHeight}px` }}>
                            <RunCron
                                handleTabNavigation={() => { this.setState({ currentTab: '1' }) }}
                            />
                        </TabPanel>
                    </TabContext>
                </Grid>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.setState({ anchorEl: null })}
                >
                    <MenuItem onClick={() => this.handleTemplateDownload('IncomeLimit')}>Income Limit</MenuItem>
                    <MenuItem onClick={() => this.handleTemplateDownload('RentLimit')}>Rent Limit</MenuItem>
                    <MenuItem onClick={() => this.handleTemplateDownload('WhatLimit')}>What Limit</MenuItem>
                </Menu>
                <AlertDialog
                    open={alertPopup.open}
                    title=""
                    onClose={() => { this.setState({ alertPopup: { ...alertPopup, open: false } }) }}
                    onSave={() => { this.props.goLive() }}
                    saveText={'Confirm'}
                    saveVisible={false}
                    cancelVisible={false}
                >
                    <div className="p-t-b-32 p-l-r-180">
                        <ValidatorForm
                            name="maintenanceForm"
                            ref="form"
                            autoComplete="off"
                            onSubmit={this.handleSubmit}
                            onError={errors => { }}
                        >
                            <Grid container spacing={4} align="center" className="displayFlex jcCenter">
                                <div className="w-100-per">
                                    <img className="max-h-110 max-w-200" src={Caution} alt="caution" />
                                </div>
                                <div>
                                    <Typography variant="body1" className={'pt-10 lineHeit'}>
                                        Do you want to turn OFF the Maintanence Mode and go live.
                                    </Typography>
                                </div>
                            </Grid>
                            <br />
                            <SaveCancel
                                saveText="Confirm"
                                justifyContent="center"
                                handleSaveButtonClick={() => { this.props.goLive() }}
                                handleCancelButtonClick={() => { this.setState({ alertPopup: { ...alertPopup, open: false } }) }}
                            />
                        </ValidatorForm>
                    </div>
                </AlertDialog>
                {openHelp && <HelpFloater
                    handleCloseFloater={() => this.setState({ openHelp: false })}
                    {...helpData}
                    title="Income and Rent limit"
                />}
            </Grid >
        );
    }
}
// prop types
IncomeRent.propTypes = {
};
/**
 * Default Props
 */
IncomeRent.defaultProps = {
};
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ maintenance: { data }, help }, ownProps) => {
    const helpData = help.data[0]
    return {
        maintenance: { ...data },
        helpData
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        maintanance: () => dispatch(maintenanceActions.getMaintenanceStatus()),
        updateMaintenanceStatus: (data) => dispatch(maintenanceActions.updateMaintenanceStatus(data)),
        downloadTemplates: (id, limitType) => dispatch(maintenanceActions.downloadTemplate(id, limitType)),
        getHelp: () => dispatch(helpActions.getAll({ _id: null })),
        goLive: () => dispatch(maintenanceActions.goLive()),
        clearAlerts: () => dispatch(alertActions.clear())
    }
};
/** Export Component */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(IncomeRent));