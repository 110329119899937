//import constants
import { pageAccessConstants } from '../constants';

//import services
import { pageAccessService } from '../services';

//import actions
import { alertActions } from './';

export const pageAccessActions = {
    getAccess,
    removeFromAccess
};

/**
 * An action method to call the service method to get the page access for the particular page passed
 *
 * @param {*} pageName contains page name to get the access list
 * @returns
 */
function getAccess(pageName, pageData) {
    return dispatch => {
        dispatch(request(pageName));

        pageAccessService.getAccess(pageName, pageData)
            .then(
                access => dispatch(success(pageName, access.data)),
                error => {
                    const err = error.toString();
                    dispatch(failure(err));
                    dispatch(alertActions.error(err));
                }
            );
    };

    function request(pageName) { return { type: pageAccessConstants.GET_USER_PAGE_ACCESS_REQUEST, pageName } }
    function success(pageName, access) { return { type: pageAccessConstants.GET_USER_PAGE_ACCESS_SUCCESS, pageName, access } }
    function failure(error) { return { type: pageAccessConstants.GET_USER_PAGE_ACCESS_FAILURE, error } }
}


/**
 * A method to remove access locally
 *
 * @param {*} name key of pageAccess e-g pageAccess.table1 - name = table1
 * @param {*} values array of string values to remove
 */
function removeFromAccess(name, values) {
    return { type: pageAccessConstants.REMOVE_ACCESS, name, values };
}