import { authHeader, globalVariable, handleResponse } from '../helpers';
import momentTZ from 'moment-timezone';

const timeZone = momentTZ.tz.guess(true)

export const sitesService = {
    getAll,
    getById,
    addNewSite,
    delete: _delete,
    getByOrgId,
    getByOrgIdRole,
    updateSite,
    updateClaimStatus,
    getSiteNameList,
    getSiteTable1List
};

/**
 * Get all sites 
 * @param {*} skip 
 * @param {*} limit 
 * @param {*} orderBy 
 * @param {*} order 
 * @param {*} sFields 
 * @param {*} sValues 
 * @param {*} projectId 
 */
function getAll(skip, limit, orderBy, order, sfields, sValues, projectId, exactMatch = false, query) {

    let data = {
        fields: sfields,
        values: sValues,
        exactMatch,
        projectId: projectId,
        query: query
    };

    const requestOptions = {
        method: 'Post',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/sites/search?skip=${skip}&limit=${limit}&orderBy=${orderBy}&order=${order}&timeZone=${timeZone}`, requestOptions).then(handleResponse);
}

/**
 * Get site details page
 * 
 * @param {*} id - site_id
 */
function getById(id, type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/sites/${id}/${type}`, requestOptions).then(handleResponse);
}

/**
 * Create new site
 * @param {*} formData - new site data
 */
function addNewSite(site) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(site)
    };

    return fetch(`${globalVariable.apiURL}/sites`, requestOptions).then(handleResponse);
}

/**
 * prefixed function name with underscore because delete is a reserved word in javascript
 * 
 * @param {*} id - site_id
 */
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/sites/${id}`, requestOptions).then(handleResponse);
}

/**
 * Get users in organization
 * 
 * @param {*} orgId  
 */
function getByOrgId(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/sites/search/org/${orgId}`, requestOptions).then(handleResponse);
}

/**
 * Get users in organization by role
 * 
 * @param {*} orgId 
 */
function getByOrgIdRole(orgId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${globalVariable.apiURL}/sites/search/role/${orgId}`, requestOptions).then(handleResponse);

}

/**
 * Update a site based on site  id
 * 
 * @param {*} formData - updated value
 * @param {*} id - site_id
 */
function updateSite(data, id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/sites/${id}`, requestOptions).then(handleResponse);
}

/** An api call method to update claim status of a site
 *
 *
 * @param {*} site_id
 * @returns
 */
function updateClaimStatus(site_id) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ site_id: site_id })
    };

    return fetch(`${globalVariable.apiURL}/site/claim-status/${site_id}`, requestOptions).then(handleResponse);
}

/**
 * A method to get sites name list
 */
function getSiteNameList(data = null) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), "Content-Type": "application/json" },
        body: JSON.stringify(data)
    };

    return fetch(`${globalVariable.apiURL}/sites/name-list`, requestOptions).then(handleResponse);
}

/**
 * A method to get sites table1 list
 */
function getSiteTable1List(siteId) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), "Content-Type": "application/json" },
    };

    return fetch(`${globalVariable.apiURL}/sites/${siteId}/table1-list`, requestOptions).then(handleResponse);
}