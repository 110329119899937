import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Import Form Elements
import ValidatorForm from "../../../helpers/fieldValidations";
import TextBox from '../../../components/TextBox/TextBox';
import AutoComplete from '../../../components/AutoComplete/AutoComplete';
import AutoCompleteMultiMUI from '../../../components/AutoCompleteMultiMUI/AutoCompleteMultiMUI';

// Import Styles
import pageStyle from '../../../assets/jss/containers/common';

// Import Helpers
import { history, getUser } from '../../../helpers';
import { getUserId, getUserOrgId, getUserName, getOrgType } from '../../../helpers';
import { confActions } from '../../../actions/configuration';
import { archivesActions } from '../../../actions/archives';
import SaveCancel from '../../../components/SaveCancel/SaveCancel';

// Import Constants
import { configurationConstants } from '../../../helpers/appConstants';

const address = {
    line: '',
    city: null,
    state: null,
    postalCode: ''
};

/**
 * Archive General container
 * @class General
 * @extends Component
 */
class General extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        const { org } = getUser();
        this.state = {
            orgDet: org,
            tab: 0,
            name: '',
            totalSites: '',
            totalBidgs: '',
            totalUnits: '',
            desc: '',
            address: '',
            city: '',
            state: '',
            zipcode: '',
            isConfidentialFlag: false,
            org: {
                id: null,
                name: '',
                address: {
                    ...address
                },
            },
            user: {
                id: null,
                name: '',
                phone: '',
                email: '',
                address: {
                    ...address
                },
            },
            isApplied: false,
            confirmPopup: false,
            readOnlyFunders: []
        };

        this.userId = getUserId();
        this.userOrgId = getUserOrgId();
        this.loggedUserName = getUserName();
        this.userType = getOrgType();
    }
    /**
     * Component Did Mount Event
     * 
     * [1]  -   Get project by ID
     */
    componentDidMount() {
        const id = this.props.projectId || this.props.copyId;

        // Get State and City        
        this.props.getConfiguration(configurationConstants.state);
        this.props.getConfiguration(configurationConstants.cities);

        this.props.getAllOrgs('', 'projects');

        // Get Read Only Org
        this.props.getReadOnlyOrg();

        // [1]
        if (id) {
            this.props.getById(id);
        }
    }
    /**
     * Component Will Receive Props
     * @param {*} newProps 
     * 
     * [1]  -   Get project by ID
     * [2]  -   Set project details to state
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        const { details, projectId, copyId } = newProps;
        // [1]
        if (projectId !== this.props.projectId) {
            this.props.getById(projectId);
            this.setState({ isApplied: false });
        }

        // [2]
        const { data } = details;
        let project = data;
        const id = projectId || copyId;
        if (project && id) {
            if (id && !this.state.isApplied) {
                let projectDetails = { ...project };
                projectDetails.owners.user_id = projectDetails.owners.user_id || { address: { ...address } };
                let contactName = `${projectDetails.owners.user_id.firstName} ${projectDetails.owners.user_id.lastName}`;
                contactName = projectDetails.owners.user_id.isActive === false ? `*${contactName}` : contactName;
                this.setState({
                    tab: 0,
                    name: projectId ? projectDetails.name : '',
                    totalSites: projectId ? projectDetails.sites : '',
                    totalBidgs: projectId ? projectDetails.buildings : '',
                    totalUnits: projectId ? projectDetails.units : '',
                    isConfidentialFlag: projectDetails.isConfidentialFlag,
                    desc: projectDetails.desc,
                    address: projectDetails.address.line,
                    city: projectDetails.address.city,
                    state: projectDetails.address.state,
                    zipcode: projectDetails.address.zipcode,
                    org: {
                        ...this.state.org,
                        ...projectDetails.owners.org_id,
                        id: projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null,
                        value: projectDetails.owners.org_id ? projectDetails.owners.org_id._id : null,
                        label: projectDetails.owners.org_id ? projectDetails.owners.org_id.name : null,
                    },
                    user: {
                        ...projectDetails.owners.user_id,
                        id: projectDetails.owners.user_id._id,
                        value: projectDetails.owners.user_id._id,
                        name: contactName,
                        label: contactName,
                    },
                    isApplied: true,
                    readOnlyFunders: projectDetails.readOnlyFunders ? projectDetails.readOnlyFunders : []
                });
            }
        }
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, isReadOly, pageAccess, details, states, cities } = this.props;
        const { orgs, users } = details;
        const { name, totalSites, totalBidgs, totalUnits, desc, address, city, state, zipcode, org, user, readOnlyFunders,
            orgDet, isConfidentialFlag } = this.state;
        const canEditContact = pageAccess.indexOf('EDIT_OWNER_CONTACT_NAME') >= 0 ? true : false;
        const { userType } = this;
        return (
            <div className={classes.rootEdit}>
                <ValidatorForm
                    name="userForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => { }} >

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="name"
                                value={name}
                                label="Name"
                                className={classes.textField}
                                margin="dense"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="totalSites"
                                value={totalSites}
                                label="Total Sites"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="totalBidgs"
                                value={totalBidgs}
                                label="Total Bldgs"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="totalUnits"
                                value={totalUnits}
                                label="Total Units"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextBox
                                name="desc"
                                value={desc || ''}
                                label="Description"
                                className={classes.textField}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextBox
                                name="address"
                                value={address || ''}
                                label="Address"
                                className={classes.textField}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='City'
                                suggestions={cities}
                                selectedValue={{ value: city, id: city }}
                                disabled={isReadOly}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                suggestions={states}
                                selectedValue={{ value: state, id: state }}
                                disabled={isReadOly}
                                placeholder="State"
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="zipcode"
                                value={zipcode || ''}
                                label="Zip Code"
                                className={classes.textField}
                                margin="dense"
                                disabled={isReadOly}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                name="org"
                                className="autocomplete-fixed"
                                suggestions={orgs}
                                selectedValue={org}
                                disabled={isReadOly}
                                placeholder="Contractor/Owner"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="org.address.line"
                                value={org.address.line || ''}
                                label="Address"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='City'
                                suggestions={cities}
                                selectedValue={{ value: org.address.city, id: org.address.city }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='State'
                                suggestions={states}
                                selectedValue={{ value: org.address.state, id: org.address.state }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="org.address.postalCode"
                                value={org.address.postalCode || ''}
                                label="Zip Code"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <AutoComplete
                                suggestions={users}
                                name="autocompleteuser"
                                className="autocomplete-fixed"
                                selectedValue={user}
                                disabled={canEditContact ? false : isReadOly}
                                placeholder="Owner Contact Name"
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="user.address.line"
                                value={user.address.line || ''}
                                label="Address"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='City'
                                suggestions={cities}
                                selectedValue={{ value: user.address.city, id: user.address.city }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <AutoComplete
                                placeholder='State'
                                suggestions={states}
                                selectedValue={{ value: user.address.state, id: user.address.state }}
                                disabled={true}
                                validators={[]}
                                errorMessages={[]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextBox
                                name="user.address.postalCode"
                                value={user.address.postalCode || ''}
                                label="Zip Code"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="user.address.phone"
                                value={user.phone || ''}
                                label="Phone Number"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextBox
                                name="user.address.email"
                                value={user.email || ''}
                                label="Email Id"
                                className={classes.textField}
                                margin="dense"
                                disabled={true}
                            />
                        </Grid>
                        {["superFunder", "superFunderWithHO", "funderWithOA", "funderWithOAandBoth"].indexOf(orgDet.role) >= 0 ?
                            <Grid item xs={12} sm={6} className={'readonlyorgAutocomplete'} >
                                <AutoCompleteMultiMUI
                                    placeholder='Other Associated Organizations'
                                    suggestions={details.readOnlyOrg}
                                    selectedValue={readOnlyFunders}
                                    disabled={isReadOly}
                                    validators={[]}
                                    errorMessages={[]}
                                />
                            </Grid> : ""}
                    </Grid>
                    {userType === "Read Only Organization" || userType === "Funder" ?
                        <Grid className={'confidentialProject'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isReadOly}
                                        checked={isConfidentialFlag}
                                        value={2}
                                        color="primary"
                                    />
                                }
                                label="Confidential Location?"
                            />
                        </Grid> : ""
                    }
                    {
                        pageAccess.indexOf('SAVE') > -1 || canEditContact === true ? (
                            <SaveCancel handleSaveButtonClick={() => { this.refs.form.submit() }}
                                handleCancelButtonClick={() => { history.goBack() }}
                            />
                        ) : null
                    }

                </ValidatorForm>
            </div>
        );
    }
}

/**
 * Default props
 */
General.defaultProps = {
    isReadOly: false,
    projectId: null,
    copyId: null,
    pageAccess: [],
    handleFormSaveActionClick: () => { }
}


/**
 * Bind Component Property Types
 */
General.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

/**
 * Maps state from store to props
 */
const mapStateToProps = (state, ownProps, projects) => {
    return {
        projects: state.archives,
        details: state.archives.getOne,
        states: state.configuration.configurations[configurationConstants.state] || [],
        cities: state.configuration.configurations[configurationConstants.cities] || []
    }
};

/**
 * Maps actions to props
 */
const mapDispatchToProps = (dispatch) => {
    return {
        isProjectNameExists: (data, callBack) => dispatch(archivesActions.isProjectNameExists(data, callBack)),
        getById: (id) => dispatch(archivesActions.getById(id)),
        getAllOrgs: (text, page) => dispatch(archivesActions.getAllOrg(text, page)),
        getAllUsersInOrg: (orgId) => dispatch(archivesActions.getUsersInOrg(orgId)),
        getConfiguration: (type) => dispatch(confActions.getConfiguration(type)),
        getReadOnlyOrg: () => dispatch(archivesActions.getReadOnlyOrg()),
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(General));
