export const reportTablesConstants = {
    // Report table 4 and 4a screen
    GET_TABLE4_DATA_REQUEST: 'REPORT_TABLES_4_DATA_REQUEST',
    GET_TABLE4_DATA_SUCCESS: 'REPORT_TABLES_4_DATA_SUCCESS',
    GET_TABLE4_DATA_FAILURE: 'REPORT_TABLES_4_DATA_FAILURE',

    //Report Table 2 Screening
    GET_TABLE1_DATA_REQUEST: 'REPORT_TABLES_1_DATA_REQUEST',
    GET_TABLE1_DATA_SUCCESS: 'REPORT_TABLES_1_DATA_SUCCESS',
    GET_TABLE1_DATA_FAILURE: 'REPORT_TABLES_1_DATA_FAILURE',

    // Table 2 data
    GET_TABLE2_DATA_REQUEST: 'REPORT_TABLES_2_DATA_REQUEST',
    GET_TABLE2_DATA_SUCCESS: 'REPORT_TABLES_2_DATA_SUCCESS',
    GET_TABLE2_DATA_FAILURE: 'REPORT_TABLES_2_DATA_FAILURE',

    //Report Table 2 Updating
    GET_TABLE2_UPDATE_REQUEST: 'REPORT_TABLES_2_UPDATE_REQUEST',
    GET_TABLE2_UPDATE_SUCCESS: 'REPORT_TABLES_2_UPDATE_SUCCESS',
    GET_TABLE2_UPDATE_FAILURE: 'REPORT_TABLES_2_UPDATE_FAILURE',

    // Report table 3
    GET_TABLE3_DATA_REQUEST: 'REPORT_TABLES_3_DATA_REQUEST',
    GET_TABLE3_DATA_SUCCESS: 'REPORT_TABLES_3_DATA_SUCCESS',
    GET_TABLE3_DATA_FAILURE: 'REPORT_TABLES_3_DATA_FAILURE',

    //Report Table 3 Updating
    GET_TABLE3_UPDATE_REQUEST: 'REPORT_TABLES_3_UPDATE_REQUEST',
    GET_TABLE3_UPDATE_SUCCESS: 'REPORT_TABLES_3_UPDATE_SUCCESS',
    GET_TABLE3_UPDATE_FAILURE: 'REPORT_TABLES_3_UPDATE_FAILURE',

    // submit table 1
    // submit table 1, 2, 3, 4
    SUBMIT_TABLE_ONE_REQUEST: 'REPORT_TABLES_SUBMIT_TABLE_ONE_REQUEST',
    SUBMIT_TABLE_ONE_SUCCESS: 'REPORT_TABLES_SUBMIT_TABLE_ONE_SUCCESS',
    SUBMIT_TABLE_ONE_FAILURE: 'REPORT_TABLES_SUBMIT_TABLE_ONE_FAILURE',

    // un-submit table 1, 2, 3, 4
    UNSUBMIT_TABLE_ONE_REQUEST: 'REPORT_TABLES_UNSUBMIT_TABLE_ONE_REQUEST',
    UNSUBMIT_TABLE_ONE_SUCCESS: 'REPORT_TABLES_UNSUBMIT_TABLE_ONE_SUCCESS',
    UNSUBMIT_TABLE_ONE_FAILURE: 'REPORT_TABLES_UNSUBMIT_TABLE_ONE_FAILURE',

    // update table 4 record
    UPDATE_TABLE4_REQUEST: 'REPORT_TABLES_UPDATE_TABLE4_REQUEST',
    UPDATE_TABLE4_SUCCESS: 'REPORT_TABLES_UPDATE_TABLE4_SUCCESS',
    UPDATE_TABLE4_FAILURE: 'REPORT_TABLES_UPDATE_TABLE4_FAILURE',

     // update table 5 record
     UPDATE_TABLE5_REQUEST: 'REPORT_TABLES_UPDATE_TABLE5_REQUEST',
     UPDATE_TABLE5_SUCCESS: 'REPORT_TABLES_UPDATE_TABLE5_SUCCESS',
     UPDATE_TABLE5_FAILURE: 'REPORT_TABLES_UPDATE_TABLE5_FAILURE',

    // create table 5 record
    CREATE_TABLE5_REQUEST: 'REPORT_TABLES_CREATE_TABLE5_REQUEST',
    CREATE_TABLE5_SUCCESS: 'REPORT_TABLES_CREATE_TABLE5_SUCCESS',
    CREATE_TABLE5_FAILURE: 'REPORT_TABLES_CREATE_TABLE5_FAILURE',

    // create table 4 record
    CREATE_TABLE4_REQUEST: 'REPORT_TABLES_CREATE_TABLE4_REQUEST',
    CREATE_TABLE4_SUCCESS: 'REPORT_TABLES_CREATE_TABLE4_SUCCESS',
    CREATE_TABLE4_FAILURE: 'REPORT_TABLES_CREATE_TABLE4_FAILURE',

    // create table 3 record
    CREATE_TABLE3_REQUEST: 'REPORT_TABLES_CREATE_TABLE3_REQUEST',
    CREATE_TABLE3_SUCCESS: 'REPORT_TABLES_CREATE_TABLE3_SUCCESS',
    CREATE_TABLE3_FAILURE: 'REPORT_TABLES_CREATE_TABLE3_FAILURE',

    // create table 2 record
    CREATE_TABLE2_REQUEST: 'REPORT_TABLES_CREATE_TABLE2_REQUEST',
    CREATE_TABLE2_SUCCESS: 'REPORT_TABLES_CREATE_TABLE2_SUCCESS',
    CREATE_TABLE2_FAILURE: 'REPORT_TABLES_CREATE_TABLE2_FAILURE',

    // Report Table Validations
    GET_REPORT_TABLES_VALIDATION_REQUEST: 'GET_REPORT_TABLES_VALIDATION_REQUEST',
    GET_REPORT_TABLES_VALIDATION_SUCCESS: 'GET_REPORT_TABLES_VALIDATION_SUCCESS',
    GET_REPORT_TABLES_VALIDATION_FAILURE: 'GET_REPORT_TABLES_VALIDATION_FAILURE',

    // Get table 1 errros
    GET_TABLE_1_ERROR_REQUEST: 'GET_TABLE_1_ERROR_REQUEST',
    GET_TABLE_1_ERROR_SUCCESS: 'GET_TABLE_1_ERROR_SUCCESS',
    GET_TABLE_1_ERROR_FAILURE: 'GET_TABLE_1_ERROR_FAILURE',

    // Clear Tables
    CLEAR_TABLES_REQUEST: 'CLEAR_TABLES_REQUEST',

    // GET ISSUES
    GET_VALIDATION_ISSUES_REQUEST: 'GET_VALIDATION_ISSUES_REQUEST',
    GET_VALIDATION_ISSUES_SUCCESS: 'GET_VALIDATION_ISSUES_SUCCESS',
    GET_VALIDATION_ISSUES_FAILURE: 'GET_VALIDATION_ISSUES_FAILURE',

    // Refresh Validations
    GET_REFRESH_VALIDATIONS_REQUEST: 'GET_REFRESH_VALIDATIONS_REQUEST',
    GET_REFRESH_VALIDATIONS_SUCCESS: 'GET_REFRESH_VALIDATIONS_SUCCESS',
    GET_REFRESH_VALIDATIONS_FAILURE: 'GET_REFRESH_VALIDATIONS_FAILURE',

    // Download Report Table 
    DOWNLOAD_REPORT_TABLE_REQUEST: 'DOWNLOAD_REPORT_TABLE_REQUEST',
    DOWNLOAD_REPORT_TABLE_SUCCESS: 'DOWNLOAD_REPORT_TABLE_SUCCESS',
    DOWNLOAD_REPORT_TABLE_FAILURE: 'DOWNLOAD_REPORT_TABLE_FAILURE',

    CHECK_IF_TABLE4_EXISTS_REQUEST: 'CHECK_IF_TABLE4_EXISTS_REQUEST',
    CHECK_IF_TABLE4_EXISTS_SUCCESS: 'CHECK_IF_TABLE4_EXISTS_SUCCESS',
    CHECK_IF_TABLE4_EXISTS_FAILURE: 'CHECK_IF_TABLE4_EXISTS_FAILURE',

     //Report Table 5 Screening
     GET_TABLE5_DATA_REQUEST: 'REPORT_TABLES_5_DATA_REQUEST',
     GET_TABLE5_DATA_SUCCESS: 'REPORT_TABLES_5_DATA_SUCCESS',
     GET_TABLE5_DATA_FAILURE: 'REPORT_TABLES_5_DATA_FAILURE',

         //Report Table 5 Updating
    GET_TABLE5_UPDATE_REQUEST: 'REPORT_TABLES_5_UPDATE_REQUEST',
    GET_TABLE5_UPDATE_SUCCESS: 'REPORT_TABLES_5_UPDATE_SUCCESS',
    GET_TABLE5_UPDATE_FAILURE: 'REPORT_TABLES_5_UPDATE_FAILURE'


}