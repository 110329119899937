import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

//Import helpers
import { elderlyConstants } from '../../helpers/appConstants';
import { history, getUserId, getUserOrgId, formatDate,getUserType } from '../../helpers';
import { pageAccessActions } from '../../actions/pageAccess';
import { FunderConstants } from '../../helpers';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Components
import TextBox from '../../components/TextBox/TextBox';
import DropDown from '../../components/DropDown/DropDown';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import DatePicker from '../../components/DatePicker/DatePicker';
import SetAside from './components/SetAside';

import FieldValidationConstants from "../../helpers/fieldValidationConstants"

// import global validation requirements
import ValidatorForm from "../../helpers/fieldValidations";

// Import Actions
import { loanProjectActions, alertActions } from '../../actions';
import SaveCancel from '../../components/SaveCancel/SaveCancel';
import TextArea from '../../components/TextArea/TextArea';
import NumberField from '../../components/NumberField/NumberField';
import AlertDialog from '../../components/AlertDialog/AlertDialog';

const setAsideRowData = {
    _id: '',
    contract_id: 0,
    setAsideType_id: 0,
    unitCount: '',
    firstEffectiveYear: '',
    lastEffectiveYear: '',
    bedroom_id: 0,
    elderlyType_id: 0
};

const pjtOptions = [
    "None",
    "Elderly Project",
    "ARRA Project"
];

class LoanProjectFundersEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            arraType_id: false,
            expirationDate: '',
            primary_contact: '',
            project_type: [],
            subsidy_OM_AwardsText: '',
            notes: '',
            config: {
                projectType: [],
                setAsideType: [],
                usersList: [],
                bedrooms: []
            },
            reports: [],
            projectContracts: [],
            headers: [],
            setAsideTypes: [],
            claimedSite: 0,
            user_id_FunderStaff: props.id ? 0 : getUserId(),
            org_id: props.id ? 0 : getUserOrgId(),
            isConfigByBedRoom: false,
            isElderly: false,
            elderlyType: 0,
            isClaimed: false,
            isConfigByBedRoomNHTF: false,
            isConfigByBedRoomPSH: false,
            isAfterPHA: false,
            claimTrue: false
        }

        this.userOrgId = getUserOrgId();
    }

    /**
     * Handle TextBox and DropDown Change Event
     */
    handleChange = (name, value) => {
        const isElderly = this.state.setAsideTypes.filter(st => st.setAsideType_id === 57 && st.category_id === 2).length >= 1;
        if (name === 'project_type' && value === 1 && !isElderly) {
            this.setState({
                [name]: value,
                setAsideTypes: [
                    ...this.state.setAsideTypes,
                    {
                        ...setAsideRowData,
                        firstEffectiveYear: new Date().getFullYear() - 1,
                        lastEffectiveYear: new Date().getFullYear() + 38,
                        setAsideType_id: 57,
                        contract_id: this.state.claimedSite,
                        category_id: 2,
                        unitCount: this.state.totalUnits,
                        _id: Math.max(...this.state.setAsideTypes.map(elt => elt._id)) + 1
                    }
                ]
            });

        } else {
            this.setState({ [name]: value });
        }
    };

    /**
     * Component Did Mount
     */
    componentDidMount() {
        const { id, orgId } = this.props;
        this.props.getFunderSettingsConfigDatas(id);
        this.props.getPageAccess('loanFunderEdit',{project_id:history.location.pathname.split("/")[2]});
        if (id) {
            this.props.getBasicFunderSettings(id, orgId);
        } else {
            //hide alert for non funders
            if(getUserType()!=="Contractor"){
            this.setState({ claimTrue: true })
            }
        }
    }

    /**
    * Handle Pop Up open
    */
    onPopupOk = () => {
        this.setState({ claimTrue: false })
    }

    /**
     * Component Will Receive Props
     * @param {*} nextprops 
     */
    UNSAFE_componentWillReceiveProps(nextprops) {
        const { funderSettings } = nextprops;
        if (funderSettings.basic) {
            if (funderSettings.basic._id !== this.state._id && this.props.id) {
                const { primary_contact } = funderSettings.basic;
                let pc = primary_contact || {};
                let name = `${pc.firstName}, ${pc.lastName}`;
                const setAsideTypes = global._.sortBy(funderSettings.basic.setAsideTypes, 'setAsideType_id');
                this.setState({
                    ...this.state,
                    ...funderSettings.basic,
                    reports: global._.sortBy(funderSettings.basic.reports, 'firstReportingYear').reverse(),
                    projectContracts: funderSettings.basic.contractDetails,
                    setAsideTypes,
                    isClaimed: funderSettings.basic.expirationDate !== null,
                    project_type: funderSettings.basic.project_type || funderSettings.basic.project_type === 0 ? this.converProjectTypeToArr(funderSettings.basic.project_type) : [],
                    primary_contact: pc.isActive ? `*${name}` : name
                })
            }

            //set claimedsite based on contractkey asc
            let projcontracts = funderSettings.basic.contractDetails.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))
            this.setState({claimedSite:projcontracts[0]._id})
        }

        if (funderSettings.config.usersList.length > 0) {
            this.setState({
                config: funderSettings.config
            });
        }
    }

    /**
     * A method to call when form got submit
     *
     * @memberof FundersEdit
     */
    handleSubmit = () => {
        let {
            user_id_FunderStaff, expirationDate, subsidy_OM_AwardsText,
            notes, project_type, arraType_id, setAsideTypes, reports
        } = this.state;
        let typeofProject = 0;
        // convert project type array to int
        if (project_type.indexOf(pjtOptions[1]) > -1 && project_type.indexOf(pjtOptions[2]) > -1) {

            typeofProject = 3;
        } else {
            typeofProject = pjtOptions.indexOf(project_type[0]);
        }

        // update funder project duedate changes
        const dueDates = [];
        reports.forEach(rp => {
            rp.cnvData.forEach(rcd => {
                rcd.forEach((rcdD) => {
                    if (rcdD.dbStatus !== rcdD.status) {
                        dueDates.push(rcdD);
                    }
                })
            });
        })

        const formData = {
            user_id_FunderStaff, expirationDate, subsidy_OM_AwardsText,
            notes, project_type: typeofProject, arraType_id, setAsideTypes, dueDates
        };

        if(expirationDate._isValid===false){
            return
        }

        if (this.props.id) {
            this.props.updateFunderSettings({
                ...formData,
                project_id: this.props.projectId,
                reports: reports.map((rp) => { return { contract_id: rp._id, firstReportingYear: rp.firstReportingYear } })
            }, this.state._id);
        } else {
            // create new funder
            this.props.createFunderSettings({ ...formData, project_id: Number(this.props.projectId) });
        }

        this.props.showSuccessAlert('Updated successfully')

    }

    /**
    * Convert Project Type to Array
    */
    converProjectTypeToArr(value) {
        if (value === 3) {
            return [pjtOptions[1], pjtOptions[2]];
        } else {
            return [pjtOptions[value]];
        }
    }

    /**
    * Get Checkbox action
    */
    getOptions() {
        let options = [pjtOptions[0], pjtOptions[1]];
        // if wshfc
        if (this.state.org_id === 1) {
            options = [...options, pjtOptions[2]];
        }

        const menuEle = options.map((opt, index) => {
            return (
                <MenuItem key={`pjt_type_${index}`} value={opt}>
                    <Checkbox checked={this.state.project_type.indexOf(opt) > -1} />
                    <ListItemText primary={opt} />
                </MenuItem>
            );
        });
        return menuEle;
    }

    handleClose(){        
        history.push(`${history.location.pathname.split("/funders")[0]}`)
    }

    /**
    * Render Due Date
    */
    renderDueDate = (colData, yearIndex, tableIndex, siteIndex) => {
        // no need to edit for previous years
        if (colData.date === null) return <TableCell key={yearIndex} style={{ textAlign: 'center' }}>{"N/A"}</TableCell>;
        return (
            <TableCell style={{ textAlign: 'center' }} key={yearIndex}>
                <Checkbox
                    checked={colData.status}
                    onChange={(value) => {
                        const updatedReports = [...this.state.reports];
                        const data = updatedReports[siteIndex].cnvData[tableIndex][yearIndex];
                        updatedReports[siteIndex].cnvData[tableIndex][yearIndex] = { ...data, status: !data.status };
                        this.setState({
                            reports: updatedReports
                        });
                    }}
                    value={`${colData.status}`}
                    color="primary"
                    disabled={colData.disabled || this.props.isReadOly}
                />
                {formatDate(colData.date)}
            </TableCell>
        );
    }

    /**
    * Bind HTML to DOM
    */
    render() {
        let { classes, pageAccess, isReadOly,id } = this.props;
        let { arraType_id, expirationDate, project_type,
             notes, config, reports, setAsideTypes, setAsideBy, claimedSite, user_id_FunderStaff,
            org_id, isConfigByBedRoom, isElderly, elderlyType,
            isClaimed, isConfigByBedRoomNHTF, isConfigByBedRoomPSH, projectContracts,
            isAfterPHA
        } = this.state;
        var activeUser=[];
        var inactiveUser=[];
        var primaryContactSuggestions=[]

        config.usersList.forEach((item,index)=>{
            if(item.label.charAt(0)==="*"){
                inactiveUser.push(item)
            }else{
                activeUser.push(item)
            }
        })
        primaryContactSuggestions=[...activeUser,...inactiveUser]

        var { primary_contact } = this.state
        let contractsDetails = {}
        projectContracts.forEach((report) => {
            contractsDetails[report._id] = report.contractNumber
        })
        const tableTitleConstant = {
            "programActivity": "Program Activity",
            "financialActivity": "Financial Activity",
            "contractActivity": "Contract Activity"
        }
        const tableTitleConstantArr = ["Program Activity", "Financial Activity", "Contract Activity"]

        //sort by contract key
         reports = reports.sort((a,b) => (a._id < b._id) ? 1 : ((b._id < a._id) ? -1 : 0))
         projectContracts = projectContracts.sort((a,b) => (a._id > b._id) ? 1 : ((b._id > a._id) ? -1 : 0))
        primary_contact = primary_contact.replace('*', '');

        //disable fields for non funder
        isReadOly = getUserType()==="Contractor"&&!id?true:isReadOly;

        //remove save cancel for non funder
        if(getUserType()==="Contractor"&&!id&&pageAccess.indexOf('SAVE')>-1){
            let index = pageAccess.indexOf('SAVE');
            pageAccess.splice(index,1)
        }

        return (
            <div className={`${classes.rootEdit} funderedit`}>
                <ValidatorForm
                    name="funderSettingsForm"
                    ref="form"
                    autoComplete="off"
                    onSubmit={this.handleSubmit}
                    onError={errors => console.log(errors)} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} className={classes.autoCompleteTop} align="left">
                        <Grid item xs={12} sm={10}>
                            {
                                getUserOrgId() === org_id || this.props.id === undefined ? (
                                    <AutoComplete
                                        suggestions={primaryContactSuggestions}
                                        handleAutoCompolete={(selectedOption) => {
                                            this.setState({
                                                user_id_FunderStaff: selectedOption.value
                                            })
                                        }}
                                        selectedValue={{ id: user_id_FunderStaff }}
                                        disabled={isReadOly}
                                        name="primaryContact"
                                        placeholder="Primary Contact on Project"
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                    />
                                ) : (
                                        <TextBox
                                            name="disabled_primaryContact"
                                            value={primary_contact}
                                            label="Primary Contact on Project"
                                            className={classes.textField}
                                            margin="dense"
                                            validators={['required']}
                                            disabled={true}
                                        />
                                    )
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} align="center">
                            <Grid item xs={12} sm={10}>
                                <DatePicker
                                    name={"expirationDate"}
                                    label="Project Expiration Date"
                                    value={expirationDate}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    disabled={isReadOly}
                                    className="expDateFunder"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>

                        </Grid>
                        {
                            (org_id === 1 && project_type.indexOf(pjtOptions[2]) > -1) && <Grid item xs={12} sm={4}>
                                <DropDown
                                    name={'arraType_id'}
                                    value={arraType_id}
                                    label="ARRA:"
                                    className={classes.textField}
                                    handleChange={this.handleChange}
                                    margin="dense"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                    disabled={isReadOly}
                                >
                                    {
                                        FunderConstants.arratype.map((item) => {
                                            return <MenuItem key={item.name} value={item.value}>{item.name}</MenuItem>
                                        })
                                    }
                                </DropDown>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12}>
                            <TextArea
                                name={"notes"}
                                value={notes}
                                label="Comments"
                                className={classes.textField}
                                handleChange={this.handleChange}
                                margin="dense"
                                validators={['isMaxLen1000']}
                                errorMessages={[FieldValidationConstants.LOAN_ACTIVITY.STR_LEN1000]}
                                disabled={isReadOly}
                                multiline={true}
                                needCustomToolTip={true}
                            />
                        </Grid>
                        {isClaimed &&

                            < Grid item xs={12} sm={12}>
                                {reports !== undefined && reports.length > 0 &&
                                    <React.Fragment>
                                        <h4 className="section_title">
                                            Reporting Forms
                                        </h4>

                                        {reports.map((item, rIndex) => (
                                            <div key={'contracts' + item._id} className={"m-t-b-15"}>
                                                <Grid container spacing={3} >
                                                    <Grid item xs={4} sm={6}>
                                                        <p>Contract Number: {contractsDetails[item._id]}</p>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4}>
                                                        <p>Contract Key : {item._id}</p>
                                                    </Grid>
                                                    <Grid item xs={4} sm={2}>
                                                        <NumberField
                                                            className={`firstrptYearFunder ${classes.textField}`}
                                                            name={`firstReportingYear_${item._id}`}
                                                            value={item.firstReportingYear}
                                                            label="First Reporting Year"
                                                            handleChange={(name, value) => {
                                                                reports[rIndex].firstReportingYear = value;
                                                                this.setState({
                                                                    reports: reports
                                                                });
                                                            }}
                                                            margin="dense"
                                                            validators={[ 'required','minNumber:1970', 'maxNumber:5000']}
                                                            errorMessages={['This field is required','Not less than 1970', 'Not greater than 5000']}
                                                            disabled={isReadOly}
                                                            allowNegative={false}
                                                            prefix={''}
                                                            thousandSeparator={false}
                                                            decimalScale={0}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Divider light={true} />

                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {item.headers.map((header, hi) => (
                                                                <TableCell key={hi} style={hi !== 0 ? { textAlign: 'center' } : {}}>{header}</TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {item.cnvData.map((colDatas, index) => {
                                                            return (
                                                                <TableRow key={'st' + index}>
                                                                    <TableCell component="th" scope="row">
                                                                        {colDatas[0] ? tableTitleConstant[colDatas[0].title] : tableTitleConstantArr[index]}
                                                                    </TableCell>
                                                                    {
                                                                        colDatas.map((colData, yearIndex) => {
                                                                            return this.renderDueDate(colData, yearIndex, index, rIndex)
                                                                        })
                                                                    }
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        ))}
                                    </React.Fragment>
                                }
                                <SetAside
                                    org_id={org_id}
                                    config={config}
                                    isElderly={isElderly}
                                    sites={projectContracts.map((report) => { return { value: report._id, label: report.contractNumber } })}
                                    setAsideTypes={setAsideTypes}
                                    setAsideBy={setAsideBy}
                                    isReadOly={isReadOly}
                                    isConfigByBedRoom={isConfigByBedRoom}
                                    isConfigByBedRoomNHTF={isConfigByBedRoomNHTF}
                                    isConfigByBedRoomPSH={isConfigByBedRoomPSH}
                                    isConfigByBedRoomChange={(status) => {
                                        this.setState({
                                            isConfigByBedRoom: status,
                                            setAsideTypes: setAsideTypes.map((st) => {
                                                if (st.category_id === 5) st.bedroom_id = null;
                                                return st;
                                            })
                                        });
                                    }}
                                    isConfigByBedRoomNHTFChange={(status) => {
                                        this.setState({
                                            isConfigByBedRoomNHTF: status,
                                            setAsideTypes: setAsideTypes.map((st) => {
                                                if (st.category_id === 6) st.bedroom_id = 0;
                                                return st;
                                            })
                                        });
                                    }}
                                    isConfigByBedRoomPSHChange={(status) => {
                                        this.setState({
                                            isConfigByBedRoomPSH: status,
                                            setAsideTypes: setAsideTypes.map((st) => {
                                                if (st.category_id === 7) st.bedroom_id = 0;
                                                return st;
                                            })
                                        });
                                    }}
                                    claimedSite={setAsideBy === 0 ? 0 : claimedSite}
                                    onSetAsideBy={(setAsideByValue) => {
                                        let updatedsetAsideTypes = setAsideTypes;
                                        let updateClaimedSite = 0
                                        if (setAsideByValue === 0) {
                                            // set to projects set site_id to null
                                            updatedsetAsideTypes = setAsideTypes.map((st) => {
                                                st.contract_id = null;
                                                return st
                                            });
                                        } else {
                                            // set to first site of the project
                                            if (reports[reports.length-1] !== undefined) {
                                                const _id = reports[reports.length-1]._id
                                                updateClaimedSite = _id
                                                updatedsetAsideTypes = setAsideTypes.map((st) => {
                                                    st.contract_id = _id;
                                                    return st
                                                });
                                            }
                                        }
                                        this.setState({ setAsideTypes: updatedsetAsideTypes, setAsideBy: setAsideByValue, claimedSite: updateClaimedSite });
                                    }}
                                    onSiteChange={(claimedSite) => {
                                        this.setState({ claimedSite });
                                    }}
                                    elderlyType={elderlyType}
                                    elderlyConstants={elderlyConstants}
                                    elderlyTypeOnChange={(type) => {
                                        const updatedSetAsideTypes = setAsideTypes.map((st) => {
                                            if (st.category_id === 2 && st.setAsideType_id === 57) {
                                                st.elderlyType_id = type;
                                            }
                                            return st;
                                        });
                                        this.setState({ setAsideTypes: updatedSetAsideTypes, elderlyType: type });
                                    }}
                                    onChange={(name, index, value) => {
                                        setAsideTypes[index][name] = value;
                                        this.setState({ setAsideTypes })
                                    }}
                                    onAdd={(siteId, category_id) => {
                                        const newSetAsideTypes = [
                                            ...setAsideTypes,
                                            {
                                                ...setAsideRowData,
                                                contract_id: siteId,
                                                category_id,
                                                _id: Math.max(...this.state.setAsideTypes.map(elt => elt._id)) + 1
                                            }
                                        ];
                                        this.setState({ "setAsideTypes": newSetAsideTypes });
                                    }}
                                    onDelete={(index) => {
                                        setAsideTypes.splice(index, 1);
                                        this.setState({ setAsideTypes });
                                    }}
                                    isAfterPHA={isAfterPHA}
                                    isAfterPHAChange={(value) => {
                                        const updatedSetAsideTypes = setAsideTypes.map((st) => {
                                            if (st.category_id === 5) {
                                                st.isAfterPHA = value;
                                            }
                                            return st;
                                        });
                                        this.setState({ setAsideTypes: updatedSetAsideTypes, isAfterPHA: value });
                                    }}
                                    validators={[]}
                                    disabled={[]}
                                />
                            </Grid>
                        }
                        {
                            pageAccess.indexOf('SAVE') > -1 ?
                                (<Grid item xs={12}><SaveCancel
                                    saveText="Save"
                                    handleSaveButtonClick={() => { this.refs.form.submit() }}
                                    handleCancelButtonClick={() => { history.push(`${history.location.pathname.split("/funders")[0]}/funders`) }}
                                /></Grid>)
                                : null
                        }
                        {
                            <AlertDialog
                                open={this.state.claimTrue}
                                title={'Warning:'}
                                onClose={() => {
                                    this.setState({ claimTrue: false })
                                    this.handleClose()
                                }}
                                onSave={() => { this.onPopupOk() }}
                                saveText={'Claim'}
                                cancelText={'Cancel'}
                            >
                                Claiming this Project will also claim all Contracts under it.  Verify all appropriate Contracts are claimed, as this will affect required reporting for this Project.  If you do not want to claim this Project, click Cancel.
                            </AlertDialog>
                        }
                    </Grid>
                </ValidatorForm>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
LoanProjectFundersEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    isReadOly: PropTypes.bool.isRequired,
    isSubPage: PropTypes.bool
}

LoanProjectFundersEdit.defaultProps = {
    pageAccess: [],
    isReadOly: false,
    isSubPage: false
}
/**
 * Maps state from store to props
 */
const mapStateToProps = ({ loanProjects }, ownProps) => {
    return {
        funderSettings: loanProjects.funderSettings
    }
};

/**
 *
* @param {*} dispatch
*/
const mapDispatchToProps = (dispatch) => {
    return {
        getFunderSettingsConfigDatas: (id) => dispatch(loanProjectActions.getFunderSettingsConfigDatas(id)),
        getBasicFunderSettings: (funderId, orgId) => dispatch(loanProjectActions.getBasicFunderSettings(funderId, orgId)),
        updateFunderSettings: (formData, funderId) => dispatch(loanProjectActions.updateFunderSettings(formData, funderId)),
        createFunderSettings: (formData) => dispatch(loanProjectActions.createFunderSettings(formData)),
        showSuccessAlert: (message) => dispatch(alertActions.success(message)),
        getPageAccess: (name, pageData) => dispatch(pageAccessActions.getAccess(name, pageData))
    }
};

/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(LoanProjectFundersEdit));

