import { exploreConstants } from '../constants';

const initialState = {
    data: [],
    exploreList: [],
    funderDataExportList: [],
    getOne: {
        data: null,
        isLoading: false,
    },
    isLoading: false,
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: ['report_name'],
            searchCols: []
        }
    },
    error: '',
    downloadStatus: {
        isLoading: false,
        error: ''
    }
}

export function explore(state = initialState, action) {
    switch (action.type) {
        case exploreConstants.GETALL_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case exploreConstants.GETALL_SUCCESS:
            return {
                ...state,
                data: action.explore,
                isLoading: false
            };
        case exploreConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };

        case exploreConstants.GETALL_REPORTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case exploreConstants.GETALL_REPORTS_SUCCESS:
            return {
                ...state,
                exploreList: action.explore.data,
                page: action.explore.page,
                isLoading: false,
            };
        case exploreConstants.GETALL_REPORTS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case exploreConstants.GETALL_FUNDERDATA_REPORTS_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case exploreConstants.GETALL_FUNDERDATA_REPORTS_SUCCESS:
            return {
                ...state,
                funderDataExportList: action.explore.data,
                page: action.explore.page,
                isLoading: false,
            };
        case exploreConstants.GETALL_FUNDERDATA_REPORTS_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };
        case exploreConstants.GETONE_REQUEST:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: {}
                },
                isLoading: true
            };
        case exploreConstants.GETONE_SUCCESS:
            return {
                ...state,
                getOne: {
                    ...state.getOne,
                    data: action.explore.data
                },
                isLoading: false
            };
        case exploreConstants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false
            };

        case exploreConstants.DELETE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case exploreConstants.DELETE_SUCCESS:
            return {
                ...state,
                exploreList: state.exploreList.filter((exploredata) => {
                    return exploredata._id !== action.id
                }),
                funderDataExportList: state.funderDataExportList.filter((exploredata) => {
                    return exploredata._id !== action.id
                }),
                isLoading: false
            };
        case exploreConstants.DELETE_FAILURE:
            return {
                ...state.page,
                error: action.error,
                isLoading: false
            };
        case exploreConstants.DOWNLOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
                downloadStatus: {
                    isLoading: true
                }
            };
        case exploreConstants.DOWNLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                downloadStatus: {
                    isLoading: false
                }
            };
        case exploreConstants.DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
                downloadStatus: {
                    isLoading: false
                }
            };
        case exploreConstants.PREVIEW_REQUEST:
            return {
                ...state,
                preview: {
                    isLoading: true
                },
                isLoading: true
            };
        case exploreConstants.PREVIEW_SUCCESS:
            return {
                ...state,
                preview: {
                    data: action.preview.data,
                    isLoading: false
                },
                isLoading: false
            };
        case exploreConstants.PREVIEW_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
            };
        default:
            return state
    }
}