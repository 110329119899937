import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import OrganizationsEdit from './OrganizationsEdit';
import Contacts from "./Contacts";
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Actions and Helper Files
import { orgActions } from "../../actions/organizations";

// Import Helpers
import { getUserRole, history, getUserOrgId } from '../../helpers';

// Custom Variables
let sFields = [];
let sValues = [];

/**
 * OrganizationsView Container
 * @class OrganizationsView
 * @extends {Component}
 */
class OrganizationsView extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0
        };
        this.orgRef = React.createRef();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();

    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match } = this.props;
        const org_id = parseInt(this.props.match.params.orgId, 10);
        if (value === 0) {
            history.push({
                pathname: `${match.url}`,
                state: {
                    page: 'organizationEdit',
                    pageData: {
                        org_id
                    }
                }
            });
        } else {
            history.push({
                pathname: `${match.url}/contacts`,
                state: {
                    page: 'usersList',
                    pageData: {
                        org_id
                    }
                }
            });
        }
    };
    /**
     * Handle Tab Index Change Event
     * @param {*} index 
     */
    handleTabIndexChange = index => {
        this.setState({ tab: index });
    };
    /**
     * Handle Save Action Click
     */
    handleSaveActionClick = () => {
        this.orgRef.refs.form.submit();
    };
    /**
     * Handle Form Save Action Click
     * @param {*} formData 
     */
    handleFormSaveActionClick = (formData) => {
        if (formData) {
            this.props.updateOrg(formData, this.props.match.params.orgId);
        }
    };
    /**
     * Handle Back Action Click
     */
    handleBackActionClick = () => {
        history.goBack();
    }
    /**
     * Handle Table Navigation Event - Users View Page
     * @param {*} id 
     */
    handleNavigateAction = (id) => {
        history.push({
            pathname: '/users/' + id,
            state: {
                page: 'userEdit',
                pageData: {}
            }
        });
    }
    /**
     * Bind Html to DOM
     * 
     * [1]  -   for contacts tab
     */
    render() {
        const { classes, theme, orgs, match, location: { pathname }, pageAccess, isSubPage, title, needPadding } = this.props;
        const orgAccess = this.props.orgs.getOne.data ? this.props.orgs.getOne.data.organizationAccess : 0

        const isReadOly = pageAccess.indexOf('SAVE') === -1;
        const currentTab = pathname.includes('/contacts') ? 1 : 0;
        // [1]
        sFields = Array.isArray(sFields) ? sFields : [];
        sValues = Array.isArray(sValues) ? sValues : [];
        return (
            <ContentWrapper
                classes={classes}
                pageAccess={pageAccess}
                title={orgs && orgs.getOne.data ? orgs.getOne.data.name : title}
                handleAddActionClick={this.handleAddActionClick}
                needPadding={needPadding}
                handleSaveActionClick={this.handleSaveActionClick}
                handleBackActionClick={this.handleBackActionClick}
            >
                <AppBar position="static" color="default">
                    <Tabs
                        name={'tab'}
                        value={currentTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="General" value={0} />
                        <Tab label="Contacts" value={1} />
                    </Tabs>
                </AppBar>
                <TabContainer dir={theme.direction}>
                    <Route
                        exact
                        path={match.url}
                        render={(props) => {
                            return (
                                <OrganizationsEdit
                                    isSubPage={isSubPage}
                                    pageAccess={pageAccess}
                                    orgId={this.props.match.params.orgId}
                                    isReadOly={isReadOly}
                                    innerRef={(node) => { this.orgRef = node; }}
                                    handleFormSaveActionClick={(formData) => this.handleFormSaveActionClick(formData)}
                                    handleBackActionClick={this.handleBackActionClick} />
                            )
                        }}
                    />
                    <Route
                        path={`${match.url}/contacts`}
                        render={(props) => {
                            return (
                                <Contacts
                                    orgId={this.props.match.params.orgId}
                                    isReadOly={isReadOly}
                                    match={props.match}
                                    orgAccess={orgAccess}
                                />
                            )
                        }}
                    />
                </TabContainer>
            </ContentWrapper>
        );
    }
}
/**
 * Bind Component Property Types
 */
OrganizationsView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    title: PropTypes.string,
    needPadding: PropTypes.bool
};
/** Default Data */
OrganizationsView.defaultProps = {
    isSubPage: false,
    pageAccess: [],
    title: '',
    needPadding: false
}
/**
 * Maps state from store to props
 * @param {*} state 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = (state, ownProps) => {
    return {
        orgs: state.organizations,
        contacts: state.organizations.contacts,
        pageAccess: state.pageAccess['organizationEdit'] || []
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        // You can now say this.props.createBook
        updateOrg: (formData, orgId) => dispatch(orgActions.updateOrg(formData, orgId)),
        getContacts: (skip, limit, orderBy, order, sfields, sValues, isAutoComplete = false, orgId = null) => dispatch(orgActions.getContacts(skip, limit, orderBy, order, sfields, sValues, isAutoComplete, orgId))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(OrganizationsView));
