import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'

// Import Component
import UsersList from '../Users/UsersList';
import UsersNew from "../Users/UsersNew";
import UsersView from "../Users/UsersView";
/**
 * Contacts Comtainer
 * @class Contacts
 * @extends {Component}
 */
class Contacts extends Component {
    /**
     * Bind Html to DOM 
     */
    render() {
        const { match, orgId, isReadOly, orgAccess } = this.props;
        return (
            <Switch >
                <Route exact path={`${match.url}/new`} render={props => {
                    return (
                        <div className="contactUser">
                            <UsersNew {...props}
                                orgId={orgId}
                                userId={props.match.params.userId}
                                isReadOly={isReadOly}
                                title={''}
                                isSubPage={true}
                                removeActions={["BACK"]} />
                        </div>
                    )
                }} />
                <Route exact path={`${match.url}/:userId`} render={props => {
                    return (
                        <UsersView
                            {...props}
                            orgId={orgId}
                            userId={props.match.params.userId}
                            isSubPage={true}
                            isReadOly={isReadOly} />
                    )
                }} />
                <Route exact path={match.url} render={props => {
                    return (
                        <UsersList
                            {...props}
                            orgId={orgId}
                            page={'orgs'}
                            isSubPage={true}
                            isContactPage={true}
                            title={''}
                            orgAccess={orgAccess}
                            removeActions={["UPLOAD", "DOWNLOAD"]} />
                    )
                }} />
            </Switch>
        );
    }
}
/**
 * Export Component
 */
export default Contacts;