import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

//Material UI
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

//Actions
import { confActions } from '../../../../actions/configuration';

// Import Styles
import pageStyle from '../../../../assets/jss/containers/common';

// Import Constants
import { configurationConstants, history, convertDateWithTimeZone } from '../../../../helpers';

// Import Components
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import TableComponent from '../../../../components/Table/TableComponent';

/**
 * Status Component
 * @class StatusComponent
 * @extends {Component}
 */
class StatusComponent extends Component {
    render() {
        const { data, path, handleChange } = this.props;
        return (<Switch
            color="primary"
            size="small"
            checked={data.isActive}
            onChange={() => handleChange(path, data)}
        />);
    }
}
/**
 * StatusComponent default props
 */
StatusComponent.defaultProps = {
    checked: false,
    handleChange: () => { },
    data: {}
}
const componentMap = {
    isActive: StatusComponent
}
/**
 * BannerTextList container
 * @class BannerTextList
 * @extends { Component}
 */
class BannerTextList extends Component {
    /**
     * Constructor
     * @param {*} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            bannerTextList: [],
            saved: false
        }
    }
    /**
     * Component Will Mount
     */
    UNSAFE_componentWillMount() {
        this.props.getByName(configurationConstants.bannerText);
        this.props.getConfiguration(configurationConstants.setAsideCategory);
    }
    /**
     * Component will receive props
     * @param {*} nextProps 
     */
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { bannerText } = nextProps;
        let bannerTextList = bannerText;
        bannerTextList.forEach((elem, index) => {
            bannerTextList[index].allowDelete = true;
        })
        this.setState({ bannerTextList: bannerTextList })
    }
    /**
     * Handle Table Navigation Event
     * @param {*} id 
     * @param {*} field 
     * @param {*} item 
     */
    handleNavigateAction = (id, field, item) => {
        switch (field) {
            case 'title':
                history.push({
                    pathname: `/configurations/banner-text/new`,
                    state: {
                        page: 'bannerTextEdit',
                        pageData: {
                            item
                        }
                    }
                });
                break;
            default:
        }
    }
    /**
     * Handle Field Component On Change Event
     * @param {*} name 
     * @param {*} data 
     */
    fieldComponentOnChange = (name, data) => {
        let { bannerTextList } = this.state
        switch (name) {
            case "isActive":
                let index = bannerTextList.findIndex(elem => elem._id === data._id);
                bannerTextList[index].isActive = !bannerTextList[index].isActive;
                this.setState({ bannerTextList: bannerTextList });
                this.props.updatedata('BannerText', bannerTextList)
                break;
            default:
        }
    }
    /**
     * Handle Table delete action
     * @param {*} id 
     * @param {*} item 
     */
    handleTableDeleteAction = (id, item) => {
        this.props.deleteConfig(item.type, id)
    }
    /**
     * Bind HTML to reactDOM
     * @returns 
     */
    render() {
        const { bannerTextList } = this.state;
        //convert date to timezone
        bannerTextList.map(item=>{
            item.fromDate = convertDateWithTimeZone(item.fromDate);
            item.toDate = convertDateWithTimeZone(item.toDate);
        })
        const { classes, isLoading } = this.props;
        return (
            <ContentWrapper
                classes={classes}
                title="Banner Text"
                needPadding={false}
                pageAccess={["ADD"]}
                downloadColor="secondary"
                ischildPage={true}
                handleAddActionClick={() => history.push('banner-text/new')}
            >
                <TableComponent
                    className="logs_Table m_table"
                    header={['Title', 'Text', 'From date & time', 'To date & time', 'Status']}
                    field={['title', 'desc', 'fromDate', 'toDate', 'isActive']}
                    actions={['DELETE']}
                    links={["title"]}
                    stickyHeaders={[]}
                    data={bannerTextList}
                    componentMap={componentMap}
                    fieldComponentOnChange={this.fieldComponentOnChange}
                    handleNavigateAction={this.handleNavigateAction}
                    handleTableDeleteAction={this.handleTableDeleteAction}
                    searchVisible={false}
                    paginationVisible={false}
                    noDataErrorMessage={isLoading ? "" : "No Records Found"}
                    removedSortingRow={['Title', 'Text', 'From date & time', 'To date & time', 'Status']}
                />
            </ContentWrapper>
        );
    }
}
// Props Types
BannerTextList.propTypes = {
    classes: PropTypes.object.isRequired,
};
/**
 * Map state to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ configuration }, ownProps) => {
    return {
        bannerText: configuration.configurations[configurationConstants.bannerText] || [],
        isLoading: configuration.isLoading
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getConfiguration: (setAsideCategory) => dispatch(confActions.getConfiguration(setAsideCategory)),
        getByName: (bannerText) => dispatch(confActions.getByName(bannerText)),
        updatedata: (type, values) => dispatch(confActions.updatedata(type, values)),
        deleteConfig: (type, id) => dispatch(confActions.delete(type, id))
    }
};
/**
 * Export BannerTextList
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle)(BannerTextList));
