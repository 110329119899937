const headerStyle = theme => ({
    flex: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 99999,
        justifyContent: 'center',
        boxShadow: 'none',
        '& .notification': {
            marginRight: 12,
            '& .MuiBadge-colorSecondary':{
                color: '#fff'
            }
        }
    },
    search: {
        width: 350
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    }
});

export default headerStyle;
