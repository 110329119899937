import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Route } from 'react-router-dom';

// Material Ui
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';

// Import Components
import TabContainer from '../../components/TabContainer/TabContainer';
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";

// Tab components
import General from './tabs/General';
import HouseHoldInfo from './tabs/HouseHoldInfo';
import HouseHoldMemberIncome from './tabs/HouseHoldMemberIncome';
import MonthlyRentData from './tabs/MonthlyRentData';

// Import Styles
import pageStyle from '../../assets/jss/containers/common';

// Import Helpers
import { history, getUserRole, getUserId, getUserOrgId } from '../../helpers';
import { occupantsActions } from '../../actions/occupants';
import { titleErrorMaptoTabs, tabErrorColors } from './tabs/Validations';

/**
 * OccupantsView Container
 * @class OccupantsView
 * @extends {Component}
 */
class OccupantsView extends Component {
    /**
     * Init Constructor
     * @param {C} props 
     */
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
            unitDesignation_id: 1,
            deleteOccupantId: null,
            deleteMsg:""
        };

        this.userRef = React.createRef();
        this.userId = getUserId();
        this.userRole = getUserRole();
        this.userOrgId = getUserOrgId();
    }
    /**
     * Change unit designation
     * @param {*} unitDesignation_id 
     */
    changeUnitDesignation = (unitDesignation_id) => {
        this.setState({
            unitDesignation_id
        });
    }

    /**
     * Component Did Mount Event
     */
    componentDidMount() {
        let data = this.props&&this.props.data;
        if(data&&data.unit_id){
        this.props.getOccupantsDetails(data.unit_id)
        }
    }
    /**
     * Handle Tab Change Event
     * @param {*} event 
     * @param {*} value 
     */
    handleTabChange = (event, value) => {
        const { match, projectId, location: { state } } = this.props;
        const pData = state || { projectData: {} };
        const { reportYear_id } = pData.pageData || { reportYear_id: 0 };
        switch (value) {
            case 0:
                history.replace({
                    pathname: `${match.url}`,
                    state: {
                        page: 'occgeneral',
                        pageData: {
                            project_id: projectId,
                            table_id: match.params.id,
                            reportYear_id,
                            from: pData.pageData ? pData.pageData.from : null
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            case 1:
                history.replace({
                    pathname: `${match.url}/householdinfo`,
                    state: {
                        page: 'occhouseholdinfo',
                        pageData: {
                            project_id: projectId,
                            table_id: match.params.id,
                            reportYear_id
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            case 2:
                history.replace({
                    pathname: `${match.url}/householdmemberincome`,
                    state: {
                        page: 'occhouseholdmemberincome',
                        pageData: {
                            project_id: projectId,
                            table_id: match.params.id,
                            reportYear_id
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            case 3:
                history.replace({
                    pathname: `${match.url}/monthlyrentdata`,
                    state: {
                        page: 'occmonthlyrentdata',
                        pageData: {
                            project_id: projectId,
                            table_id: match.params.id,
                            reportYear_id
                        },
                        projectData: {
                            ...pData.projectData
                        }
                    }
                });
                break;
            default:
                break;
        }
    };
    /**
     * Handle Table 1 Action Click
     */
    handleGotoTable1ListActionClick = () => {
        const { state } = this.props.location;
        let { projectData, pageData } = state || { projectData: {}, pageData: {} };
        projectData = projectData || {};
        if (projectData.project_id && projectData.reportId) {
            history.push({
                pathname: `/projects/${projectData.project_id}/reports/${projectData.reportId}/${projectData.reportYear}/table1`,
                state: {
                    page: 'table1',
                    projectData: {
                        ...projectData
                    },
                    pageData: {
                        ...pageData,
                        reportYear_id: projectData.reportId
                    }
                }
            });
        } else {
            history.push({
                pathname: '/occupants',
                state: { page: 'occupantsList' }
            });
        }
    }
    /**
   * Handle Back Action Click
   */
    handleBackActionClick = () => {
        let { location } = this.props;
        location = location || { state: { pageData: {} } };
        if (location.state && location.state.pageData) {
            if (location.state.pageData.from === 'occupants') {
                history.push({
                    pathname: '/occupants',
                    state: { page: 'occupantsList' }
                });
            } else {
                history.goBack();
            }
        } else {
            history.goBack();
        }
    }
    /**
     * Get ClassName for tab
     * @param {*} tabIndex 
     * @returns 
     */
    getClassNameforTab(tabIndex) {
        let { validationErrors } = this.props;
        
        let data = this.props&&this.props.data;
        //handle error for largehousehold
        let valErrors = validationErrors;
        if(this.props&&this.props.data){
        if(data.unitDesignation_id && data.unitDesignation_id===1&&data.moveOutDate === null){ 

        if(data.canChangeMoveInDate===false){
            if(data.specialNeedOption_id===2 && data.householdSize<4){
                let index = valErrors.findIndex(x => x.message ==="This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                if(index===-1){
                valErrors.push({"message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                "errorType": 2,"tabIndex":0})
                }
            }
            if(data.specialNeedOption_id===2 && data.householdSize_MoveIn>=4){
                if(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))>-1){
                    valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")),1)
                }
            }
            if(data.bedRoom < 3 && data.specialNeedOption_id === 2){
                let indexSH = valErrors.findIndex(x => x.message ==="This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.");
                if(indexSH===-1){
                    valErrors.push({"message": "This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.",
                    "errorType": 2,"tabIndex":0})
                    }
                }
        }else{
            if(data.specialNeedOption_id===2 && data.householdSize_MoveIn<4){
                let index = valErrors.findIndex(x => x.message ==="This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.");
                if(index===-1){
                valErrors.push({"message": "This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.",
                "errorType": 2,"tabIndex":0})
                }
            }
            if(data.specialNeedOption_id===2 && data.householdSize_MoveIn>=4){
                if(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger."))>-1){
                    valErrors.splice(valErrors.findIndex(x => x.message.includes("This household is too small to qualify for Large Household Special Needs set-aside.  Household must be 4 persons or larger.")),1)
                }
            }

            if(data.bedRoom < 3 && data.specialNeedOption_id === 2){
                let indexSH = valErrors.findIndex(x => x.message ==="This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.");
                if(indexSH===-1){
                    valErrors.push({"message": "This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.",
                    "errorType": 2,"tabIndex":0})
                    }
                }
        }

        if(data.bedRoom < 3 && data.specialNeedOption_id === 2){
            let indexSH = valErrors.findIndex(x => x.message ==="This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.");
            if(indexSH===-1){
                valErrors.push({"message": "This unit is too small to qualify household for Large Household Special Needs set-aside – unit must be at least 3 bdrms or larger.",
                "errorType": 2,"tabIndex":0})
                }
            }
    }
    }
        
        validationErrors = valErrors;
        const errorTypes = [...validationErrors.filter(v => v.tabIndex === tabIndex).map(v => v.errorType)];
        const maxError = Math.max(...errorTypes, 0);
        return tabErrorColors[maxError] || '';
    }
    /**
     * Handle delete Occupants Action Click
     */
    handleDeleteOccupantActionClick = () => {
        const { match } = this.props;
        if (match.params.id) {
            this.setState({ deleteOccupantId: match.params.id,deleteMsg:<p>Are you sure you want to delete the occupant <b>{this.props.data.headOfHouseholdName}</b> in <b>Unit: {this.props.data.unitNumber}</b>?</p> });
        }
    }
    /**
     * Handle Delete Occupant
     */
    handleDeleteOccupant = () => {
        this.props.deleteOccupant(this.state.deleteOccupantId, {}, () => {
            this.setState({ deleteOccupantId: null }, () => {
                this.handleGotoTable1ListActionClick();
            })
        });
    }
    /**
     * Bind Html to DOM
     */
    render() {
        const { classes, match, location: { pathname, state: pageParam }, title, data,
            needPadding, sequenceTab, transferMessage } = this.props;
        let { pageAccess } = this.props;
        const currentTab = pathname.includes('/householdinfo') ? 1 : (pathname.includes('/sites') ? 2 : (pathname.includes('/householdmemberincome') ? 2 : (pathname.includes('/monthlyrentdata') ? 3 : 0)))
        const isReadOly = false;
        let occupantsTitle = data !== null ? `${data.headOfHouseholdName} - ${title}` : title;
        let dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };
        let formatedDate = data ? new Date(data.updatedAt).toLocaleString('en-US', dateOptions) : '';
        let occupantsSubTitle = data ? `Last modified by: <b>${data.modifiedBy}</b> on <b>${formatedDate}</b>` : null;
        const pData = pageParam || { pageData: {} };
        const { reportYear_id } = pData.pageData || { reportYear_id: 0 };
        if (pData.pageData.backButton === "true") {
            pageAccess = [...pageAccess, 'BACK', 'GOTOTABLE1LIST']
        } else {
            pageAccess = [...pageAccess, 'BACK']
        }
        return (
            <div className='occupantsView'>
                <ContentWrapper
                    classes={classes}
                    pageAccess={[...pageAccess]}
                    title={occupantsTitle}
                    subTitle={occupantsSubTitle}
                    needPadding={needPadding}
                    handleBackActionClick={this.handleBackActionClick}
                    handleGotoTable1ListActionClick={this.handleGotoTable1ListActionClick}
                    handleDeleteOccupantActionClick={this.handleDeleteOccupantActionClick}
                >
                    <AppBar position="static" color="default">
                        <Tabs
                            name={'tab'}
                            value={currentTab}
                            onChange={this.handleTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                        >
                            <Tab
                                label="GENERAL"
                                classes={{ selected: "reportTabSelected" }}
                                value={0}
                                className={this.getClassNameforTab(0)}
                            />
                            <Tab
                                label="HOUSEHOLD MEMBER INFO"
                                value={1}
                                disabled={match.params.id === undefined || !sequenceTab}
                                className={this.getClassNameforTab(1)}
                            />
                            <Tab
                                label="HOUSEHOLD MEMBER INCOME"
                                value={2}
                                disabled={match.params.id === undefined || !sequenceTab}
                                className={this.getClassNameforTab(2)}
                            />
                            <Tab
                                label="Set-Aside and Rent Data"
                                value={3}
                                disabled={match.params.id === undefined}
                                className={this.getClassNameforTab(3)}
                            />
                        </Tabs>
                    </AppBar>

                    <div className='occupantsTab'>
                        <TabContainer>
                            {
                                transferMessage && (
                                    <div className="textCenter">
                                        <Chip
                                            label={transferMessage}
                                            className={classes.chip}
                                            color="secondary"
                                            style={{ margin: "10px 8px" }}
                                        />
                                    </div>
                                )
                            }
                            <Route
                                exact
                                path={`${match.url}`}
                                render={(props) => {
                                    return (
                                        <General
                                            pageAccess={pageAccess}
                                            isReadOly={isReadOly}
                                            tableId={match.params.id}
                                            reportYear_id={reportYear_id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            changeUnitDesignation={this.changeUnitDesignation}
                                            handleNav={this.handleTabChange}
                                            vacantUnitData={props.location.state ? props.location.state.vacantUnitData : null}
                                            handleBackActionClick={this.handleBackActionClick}
                                            reportId={this.props.location.state?.projectData?.reportId}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/householdinfo`}
                                render={(props) => {
                                    return (
                                        <HouseHoldInfo
                                            pageAccess={pageAccess}
                                            isReadOly={isReadOly}
                                            tableId={match.params.id}
                                            reportYear_id={reportYear_id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleNav={this.handleTabChange}
                                            handleBackActionClick={this.handleBackActionClick}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/householdmemberincome`}
                                render={(props) => {
                                    return (
                                        <HouseHoldMemberIncome
                                            pageAccess={pageAccess}
                                            isReadOly={isReadOly}
                                            tableId={match.params.id}
                                            reportYear_id={reportYear_id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleNav={this.handleTabChange}
                                            handleBackActionClick={this.handleBackActionClick}
                                        />
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={`${match.url}/monthlyrentdata`}
                                render={(props) => {
                                    return (
                                        <MonthlyRentData
                                            pageAccess={pageAccess}
                                            isReadOly={isReadOly}
                                            tableId={match.params.id}
                                            reportYear_id={reportYear_id}
                                            innerRef={(node) => { this.projectRef = node; }}
                                            handleNav={this.handleTabChange}
                                            handleBackActionClick={this.handleBackActionClick}
                                            reportId={this.props.location.state?.projectData?.reportId}
                                        />
                                    )
                                }}
                            />
                        </TabContainer>
                        <DeleteDialog
                            open={this.state.deleteOccupantId !== null}
                            content={this.state.deleteMsg}
                            handleDeleteAction={this.handleDeleteOccupant}
                            id={this.state.deleteOccupantId || ''}
                            handleDeleteOnCloseAction={() => {
                                this.setState({ deleteOccupantId: null });
                            }}
                        />
                    </div>
                </ContentWrapper>
            </div>
        );
    }
}
/**
 * Bind Component Property Types
 */
OccupantsView.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    pageAccess: PropTypes.array.isRequired,
    isSubPage: PropTypes.bool,
    needPadding: PropTypes.bool
};
/** Default Props */
OccupantsView.defaultProps = {
    title: "Occupants Details",
    classes: {},
    pageAccess: [],
    isSubPage: false,
    needPadding: false
}
/**
 * Maps state from store to props
 * @param {*} param0 
 * @param {*} ownProps 
 * @returns 
 */
const mapStateToProps = ({ occupants: { getOne, validationErrors }, pageAccess }, ownProps) => {
    let { data } = getOne;
    let transferMessage = null;
    let d = data || {};
    transferMessage = d.transferToMoveIn || null;
    let accessActions = pageAccess['occgeneral'] || [];
    accessActions = accessActions.indexOf('DELETE') >= 0 ? ['DELETE_OCCUPANT'] : [];
    return {
        sequenceTab: data ? data.sequenceTab : false,
        transferMessage,
        validationErrors: validationErrors.map(v => {
            v.tabIndex = titleErrorMaptoTabs[v.title] === undefined ? null : titleErrorMaptoTabs[v.title];
            return v;
        }),
        data,
        pageAccess: accessActions,
    }
};
/**
 * Maps actions to props
 * @param {*} dispatch 
 * @returns 
 */
const mapDispatchToProps = (dispatch) => {
    return {
        getOccupantsDetails: (id) => dispatch(occupantsActions.getOccupantsDetails(id)),
        deleteOccupant: (id, item, cb) => dispatch(occupantsActions.deleteOccupant(id, item, cb))
    }
};
/**
 * Export Component
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(pageStyle, { withTheme: true })(OccupantsView));
